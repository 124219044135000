import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { Icon } from '@hologram-dimension/icon';
import { FIND_SIM_ICCIDS_HELP } from 'common-js/constants/links';
import { useLogGlobalSimError } from 'activation/common/hooks/useLogGlobalSimError';
import { BUTTON_CLICK, DATA_ENTRY } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { clearValidationErrorState } from 'common-js/reducers/activation/actions/actions';
import {
  generateCheckDigit,
  removeDuplicates,
} from 'common-js/reducers/activation/actions/actionUtils';
import { calculateTotalDueToday } from 'common-js/reducers/activation/actions/calculateTotalDueToday';
import validateSimRange from 'common-js/reducers/activation/actions/validateSimRange';
import { selectSimErrors } from 'common-js/reducers/activation/selectors';
import AlreadyActivatedSims from './AlreadyActivatedSims';
import InvalidatedPreflightSims from './InvalidatedPreflightSims';
import SimRangeDisplay from './SimRangeDisplay';
import SimError from './SimError';
import SimInfo from './SimInfo';
import {
  getAlreadyActivatedSims,
  getDuplicateRangeWarning,
  getInvalidSims,
  isArrayValid,
} from './SimUtils';

const ACTIVATION_TYPE = 'range';

const isCheckDigitCorrect = (prefix) => (iccid) => {
  if (iccid.length < 19) return true;
  if (`${generateCheckDigit(iccid.slice(0, -1))}` === iccid.slice(-1)) return true;
  return `${prefix} SIM is invalid.  Please check that it has been typed in correctly.`;
};

function RangeOfSimsPanel({ hasBillingPermissions }) {
  const { getValues, setValue, trigger, watch } = useFormContext();

  const [duplicateRange, setDuplicateRange] = useState({});
  const duplicateRangeCallout = useMemo(
    () => getDuplicateRangeWarning(duplicateRange),
    [duplicateRange]
  );
  const [numSimsRemoved, setNumSimsRemoved] = useState(0);

  const currentSimRange = watch('sims.input.range');
  const rangesInForm = watch('sims.ranges');
  const currentTotalValidSims = getValues('sims.total');

  const startSimInputRef = useRef();
  const endSimInputRef = useRef();

  const simErrors = useAppSelector(selectSimErrors);
  const {
    invalid,
    globalError,
    alreadyActivated,
    preflightSims: invalidatedPreflightSims,
  } = simErrors;
  const dispatch = useAppDispatch();

  useLogGlobalSimError({ globalError, isOnboarding: false });

  useEffect(() => {
    setValue('sims.input.range', {
      start: currentSimRange?.start,
      end: currentSimRange?.end,
    });
  }, [currentSimRange?.start, currentSimRange?.end, setValue]);

  useEffect(() => {
    setValue('sims.inputOptionUsed', ACTIVATION_TYPE);
  }, [setValue]);

  const clearSelection = useCallback(() => {
    setValue('sims.ranges', []);
    setValue('sims.total', 0);
    setDuplicateRange({});
  }, [setValue, setDuplicateRange]);

  const validateRange = useCallback(
    async (start, end, activatedSims) => {
      dispatch(clearValidationErrorState());
      setDuplicateRange({});
      let isDuplicate = false;
      const ranges = getValues('sims.ranges');

      ranges.forEach(({ start: rangeInFormStart, end: rangeInFormEnd }) => {
        if (rangeInFormStart === start && rangeInFormEnd === end) {
          setDuplicateRange({ start, end });
          isDuplicate = true;
        }
      });

      if (!isDuplicate) {
        const response = await dispatch(
          validateSimRange({
            plan: {},
            start,
            end,
            invalidatePreflightSims: true,
          })
        );
        const { totalValidSims: numberOfValidSims, invalidSims: updatedInvalidSims } = response;

        const returnedActivatedSims = (updatedInvalidSims || []).reduce((acc, curr) => {
          if (curr?.value === 'SIM is already activated') {
            return [...acc, curr.key];
          }
          return acc;
        }, activatedSims || []);

        const dedupedActivatedSims = removeDuplicates(returnedActivatedSims);

        if (numberOfValidSims > 0) {
          const currentNumberOfSims = getValues('sims.total');
          setValue('sims.total', currentNumberOfSims + numberOfValidSims);

          const currentRanges = getValues('sims.ranges');
          setValue('sims.ranges', [...currentRanges, { start, end, total: numberOfValidSims }]);

          const config = {
            sims: getValues('sims.ranges'),
            plan: {},
            type: ACTIVATION_TYPE,
            excludeSims: dedupedActivatedSims,
            invalidatePreflightSims: true,
          };

          if (!hasBillingPermissions) {
            await dispatch(calculateTotalDueToday(config));
          }

          setValue('sims.input.range', {
            start: '',
            end: '',
          });
        }

        sendAnalyticsEvent({
          type: DATA_ENTRY,
          data: {
            name: 'Activation - Returning - Activation SIM Range',
            'ICCID numbers': {
              'First ICCID number': start,
              'Last ICCID number': end,
            },
            'Total ICCID numbers added': numberOfValidSims,
          },
        });
      }
    },
    [dispatch, getValues, setValue, setDuplicateRange, hasBillingPermissions]
  );

  useEffect(() => {
    dispatch(clearValidationErrorState());

    // If we're coming back to the SIM step, revalidate
    if (rangesInForm.length > 0) {
      const ranges = getValues('sims.ranges');
      clearSelection();
      ranges.forEach(({ start, end }) => setTimeout(() => validateRange(start, end), 0));
    }
    // One time only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    field: { onChange: onChangeStart },
    fieldState: { error: startError },
  } = useController({
    name: 'sims.input.range.start',
    defaultValue: null,
    rules: {
      validate: isCheckDigitCorrect('Start'),
      required:
        'Please enter the first ICCID number. Both the first and last ICCIDs are required to validate a SIM range.',
    },
  });

  const {
    field: { onChange: onChangeEnd },
    fieldState: { error: endError },
  } = useController({
    name: 'sims.input.range.end',
    defaultValue: null,
    rules: {
      validate: isCheckDigitCorrect('End'),
    },
  });
  const simError = globalError ?? startError?.message ?? endError?.message;

  const invalidSims = useMemo(() => getInvalidSims(invalid), [invalid]);
  const alreadyActivatedSims = useMemo(
    () => getAlreadyActivatedSims(alreadyActivated),
    [alreadyActivated]
  );

  const removeRange = useCallback(
    async (range) => {
      // `range` arrives in this format: `Box 1: 9094222928300000080 – 9094222928300000085`
      // so we need to parse out the start and end SIMs (later, we should modify Chip
      // to let us pass arbitrary data through)

      const regex = /Box \d+: (?<start>.+?) – (?<end>.+)$/;
      const {
        groups: { start, end },
      } = range.match(regex);

      const targetRange = rangesInForm.filter(
        (validatedRange) => validatedRange.start === start && validatedRange.end === end
      )[0];
      if (targetRange) {
        const rangeTotal = targetRange.total ?? 0;
        const currentTotal = getValues('sims.total');
        setValue('sims.total', currentTotal - rangeTotal);

        setValue(
          'sims.ranges',
          rangesInForm.filter(
            (validatedRange) => validatedRange.start !== start || validatedRange.end !== end
          )
        );

        sendAnalyticsEvent({
          type: BUTTON_CLICK,
          data: {
            name: 'Activation - Returning - Activation SIM Range Removal',
            'SIM removal count': numSimsRemoved + rangeTotal,
          },
        });
        setNumSimsRemoved(numSimsRemoved + rangeTotal);

        const config = {
          sims: getValues('sims.ranges'),
          plan: {},
          type: ACTIVATION_TYPE,
          excludeSims: alreadyActivatedSims,
          invalidatePreflightSims: true,
        };

        if (!hasBillingPermissions) {
          await dispatch(calculateTotalDueToday(config));
        }
      }
    },
    [
      dispatch,
      getValues,
      setValue,
      rangesInForm,
      alreadyActivatedSims,
      numSimsRemoved,
      hasBillingPermissions,
    ]
  );

  const totalSims = (alreadyActivatedSims?.length ?? 0) + currentTotalValidSims;

  const KEYS = {
    ENTER: 13,
  };

  const handleSubmit = () => {
    const { start, end } = currentSimRange;

    if (!start) {
      trigger('sims.input.range.start');
      startSimInputRef?.current?.focus();
    }

    if (start && end) {
      validateRange(start, end, alreadyActivatedSims);
    }

    startSimInputRef.current.value = '';
    endSimInputRef.current.value = '';
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === KEYS.ENTER) {
      e.preventDefault();
    }

    if (e.keyCode === KEYS.ENTER) {
      handleSubmit();
    }
  };

  return (
    <Panel
      header="Add SIMs"
      className="SimPanel"
      footer={
        <a href={FIND_SIM_ICCIDS_HELP} target="_blank" rel="noreferrer">
          Where do I find ICCIDs for my SIMs?
          <Icon name="ExternalLink" size="small" fill="DdsColorInteractivePrimaryDefault" />
        </a>
      }
      footerVariant="alternative"
    >
      <div className="SimPanel__content">
        <div className="RangeOfSims__wrapper">
          <div className="RangeOfSims__group">
            <TextInputField
              label="Start ICCID"
              helpText="First ID in the range"
              defaultValue={currentSimRange?.start}
              ref={startSimInputRef}
              inputProps={{
                onChange: onChangeStart,
                onKeyDown: handleKeyDown,
              }}
              error={startError}
            />
          </div>
          <div className="RangeOfSims__group">
            <TextInputField
              label="End ICCID"
              helpText="Last ID in the range"
              defaultValue={currentSimRange?.end}
              ref={endSimInputRef}
              inputProps={{
                onChange: onChangeEnd,
                onKeyDown: handleKeyDown,
              }}
              error={endError}
            />
          </div>
          <div className="RangeOfSims__group">
            <Button
              disabled={!(currentSimRange?.start && currentSimRange?.end)}
              onClick={handleSubmit}
              type="button"
            >
              Add SIMs
            </Button>
          </div>
        </div>

        <hr className="SimPanel__content__line" />

        {duplicateRange?.start && duplicateRange?.end && (
          <SimInfo messageList={duplicateRangeCallout} />
        )}

        {simError && <SimError error={simError} />}

        {isArrayValid(invalidSims) && <SimError errorList={invalidSims} />}

        {isArrayValid(invalidatedPreflightSims) && (
          <InvalidatedPreflightSims sims={invalidatedPreflightSims} />
        )}

        {alreadyActivatedSims && <AlreadyActivatedSims sims={alreadyActivatedSims} />}

        <SimRangeDisplay
          ranges={rangesInForm}
          numSims={currentTotalValidSims}
          totalSims={totalSims}
          onDelete={removeRange}
        />
      </div>
    </Panel>
  );
}

export default RangeOfSimsPanel;
