import { ToastNotification } from '@hologram-dimension/toast-notification';
import { SimInventoryBulkSendMessage as HyperBulkSendMessageModal } from '@hologram-hyper-dashboard/components';
import { ModalCommonProps } from 'common-js/components/Modal';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { fetchDevices } from 'common-js/reducers/devices/actions';
import { close } from 'common-js/reducers/modal/actions';
import { useCallback, type FC } from 'react';
import { toast } from 'react-toastify';

interface SimInventoryBulkSendMessageModalProps extends ModalCommonProps {
  sims: Set<HologramSimId>;
  onModalSuccess?: (sims: Array<HologramSimId>) => void;
}

const SimInventoryBulkSendMessageModal: FC<SimInventoryBulkSendMessageModalProps> = ({
  sims,
  onModalSuccess,
}) => {
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onSuccess = useCallback(
    (newSims: Array<HologramSimId>) => {
      // TODO: when we add our own task management UI to Hyper Dashboard, we should no longer update redux
      // TODO: This is a synchronous single SIM action, so no tasks will be initated. When we make changes to that update here.
      // dispatch(getTasks(TASK_START_DATE));
      dispatch(fetchDevices());

      toast(
        <ToastNotification variant="success" className="dimension-toastify">
          Message sent successfully.
        </ToastNotification>,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'dimension-toastify-wrapper',
          autoClose: 10000,
        }
      );

      if (onModalSuccess) {
        onModalSuccess(newSims);
      }
    },
    [dispatch, onModalSuccess]
  );

  const onError = useCallback(
    (error?: string) => {
      onClose();
      dispatch(fetchDevices());

      toast(
        <ToastNotification variant="error" className="dimension-toastify">
          {`Message not sent successfully: ${error ?? 'Unknown error'}`}
        </ToastNotification>,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'dimension-toastify-wrapper',
          autoClose: 10000,
        }
      );
    },
    [dispatch, onClose]
  );

  if (!sims || !sims.size || sims.size === 0) {
    return null;
  }

  return (
    <HyperBulkSendMessageModal
      onClose={onClose}
      onSuccess={onSuccess}
      onError={onError}
      className="HyperDashboardModal"
      defaultOpen
      sims={sims}
    />
  );
};
export default SimInventoryBulkSendMessageModal;
