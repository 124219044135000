import { Button } from '@hologram-dimension/button';
import { BILLING_FAQS } from 'common-js/constants/links';
import { close as closeModal_ } from 'common-js/reducers/modal/actions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeaderWithIcon } from '../components';

interface PauseSimModalProps {
  closeModal: typeof closeModal_;
  onConfirm: AnyFunction;
}

class PauseSimModal extends React.Component<PauseSimModalProps> {
  componentDidMount() {
    const MODAL_CONTAINER = document.getElementsByClassName('modal-container')[0];
    MODAL_CONTAINER.setAttribute('style', 'width:490px');
  }

  render() {
    const { closeModal, onConfirm } = this.props;

    return (
      <div className="ActivateResendEmailModal apn">
        <ModalHeaderWithIcon iconName="Pause" title="Pausing SIM Data" />
        <div>
          <p>
            A ‘Pause’ command will be issued to our carrier partners for your device. You should see
            the status update shortly. While paused, your device will be no longer use cellular data
            or send/receive SMS messages.
          </p>
          <p>
            <span>Monthly access fees for this SIM still apply while it is paused. </span>
            To decrease costs, consider switching to a Flexible Data plan. Learn more about paused
            SIMs and data plans{' '}
            <a href={BILLING_FAQS} target="_blank" rel="noreferrer">
              in our billing FAQs.
            </a>
          </p>
          <div className="modal-buttons dimension-modal-buttons">
            <Button
              onClick={() => {
                closeModal();
                onConfirm();
              }}
              variant="primary"
            >
              Pause SIM
            </Button>
            <Button className="talk-to-sales" onClick={closeModal} variant="secondary">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      closeModal: closeModal_,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PauseSimModal);
