import { Button } from '@hologram-dimension/button';
import { CheckboxField } from '@hologram-dimension/checkbox-field';
import { Form } from '@hologram-dimension/form';
import { Icon } from '@hologram-dimension/icon';
import { InlineNotification } from '@hologram-dimension/inline-notification';
import { Loader } from '@hologram-dimension/loader';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { ACCOUNT_FORGOT_PASSWORD } from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { login } from 'common-js/reducers/account/actions';
import { useEffect, useState, type FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import isValidEmailValidator from 'validator/lib/isEmail';

interface LoginFormProps {
  isLoading?: boolean;
}

interface LoginFormFormProps {
  email: string;
  password: string;
  rememberme: boolean;
}

const LoginForm: FC<LoginFormProps> = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState('');

  /* React Hook Form methods */
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setFocus,
  } = useForm<LoginFormFormProps>();

  useEffect(() => {
    if (!isLoading) {
      setFocus('email');
    }
  }, [isLoading, setFocus]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const loginOnSubmit: SubmitHandler<LoginFormFormProps> = async (data) => {
    setFormError('');
    try {
      await dispatch(login(data.email, data.password, data.rememberme, false));
    } catch (error) {
      if (error instanceof Error || typeof error === 'string') {
        setFormError(error.toString());
      } else {
        throw error;
      }
    }
  };

  if (isLoading) {
    return <Loader>Logging in...</Loader>;
  }

  return (
    <Form
      onSubmit={handleSubmit(loginOnSubmit)}
      footerActions={
        <>
          <Button type="submit" name="Log in" loading={isLoading || isSubmitting}>
            Log in
          </Button>
          <Button variant="tertiary" to={ACCOUNT_FORGOT_PASSWORD}>
            Forgot password?
          </Button>
        </>
      }
    >
      <div aria-live="assertive">
        {formError && (
          <InlineNotification variant="error" className="LoginForm__Error">
            {formError}
          </InlineNotification>
        )}
      </div>
      <TextInputField
        label="Email"
        type="email"
        invalid={!!errors.email}
        validationMessage={errors.email?.message}
        {...register('email', {
          required: 'An email address is required.',
          validate: {
            validEmail: (v) => isValidEmailValidator(v) || 'Please enter a valid email address.',
          },
        })}
        autocomplete="username"
      />
      <div className="password-input-with-mask-toggle">
        <TextInputField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          invalid={!!errors.password}
          validationMessage={errors.password?.message as string}
          {...register('password', {
            required: 'A password is required.',
          })}
          autocomplete="current-password"
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <Button
          aria-live="polite"
          className="password-input-with-mask-toggle__button"
          onClick={togglePasswordVisibility}
          variant="tertiary"
          iconStart={
            <Icon
              className="PasswordEye"
              size="medium"
              name={showPassword ? 'Visible' : 'Invisible'}
            />
          }
        />
      </div>

      <CheckboxField
        label="Stay logged in?"
        hiddenLabel
        options={[{ label: 'Keep me logged in' }]}
        {...register('rememberme')}
      />
    </Form>
  );
};

export default LoginForm;
