import { useCallback, useMemo } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from 'common-js/api/useApi';
import { DeviceModel } from 'common-js/models';

/**
 * A hook which returns a function that will fetch a given device from the API
 * @returns {function(*): *} Async function which takes the deviceId and returns the device as a DeviceModel
 */
const useGetDeviceById = () => {
  const orgId = useAppSelector((state) => state.organization.selectedContext);
  const searchParams = useMemo(() => {
    const queryParams = new URLSearchParams({ witheuicc: 1 });
    if (orgId) queryParams.append('orgid', orgId);
    return queryParams.toString();
  }, [orgId]);
  const apiCall = useApi.call(`/devices/:deviceId?${searchParams}`);

  return useCallback(
    async (deviceId) => {
      const { data } = await apiCall({ urlParams: { deviceId } });
      return new DeviceModel(data);
    },
    [apiCall]
  );
};

export default useGetDeviceById;
