import removeHtmlTagsFromString from './removeHtmlTagsFromString';

const highlightWordMatch = (string, query) => {
  const cleanedString = removeHtmlTagsFromString(string);
  const lowerCaseString = cleanedString.toLowerCase();

  if (!query || !lowerCaseString.includes(query.toString().toLowerCase().trim())) {
    return [{ text: cleanedString, highlight: false }];
  }

  const cleanedQuery = query.toString().trim().replace('(', '[(]').replace(')', '[)]');

  // This RegExp pattern is used to be able to call .split on a string while preserving the delimiter in the returned array
  const delimiterRegex = new RegExp(`(${cleanedQuery})`, 'ig');
  const segments = cleanedString.split(delimiterRegex);

  return segments
    .filter((segment) => segment)
    .map((segment) => ({
      text: segment,
      highlight: !!segment.match(delimiterRegex),
    }));
};

export default highlightWordMatch;
