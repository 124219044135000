import { Button } from '@hologram-dimension/button';
import analyticsEventBuilder from 'common-js/analytics';
import FullScreenModal from 'common-js/components/full-screen-modal';
import { DEVICES_DEVICES, DEVICES_PREFLIGHT } from 'common-js/constants/paths';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

interface OnboardingGetStartedProps {
  hasPostpay?: boolean;
  setNextStep: () => void;
  isPreflight?: boolean;
}

function OnboardingGetStarted({
  hasPostpay = false,
  setNextStep,
  isPreflight = false,
}: OnboardingGetStartedProps) {
  const onClose = () => {
    analyticsEventBuilder.buttonClick('Activation', 'Exit Flow').send({ step: 'Get Started' });
    browserHistory.push(isPreflight ? DEVICES_PREFLIGHT : DEVICES_DEVICES);
  };

  const handleClick = () => {
    analyticsEventBuilder
      .buttonClick(
        'Activation',
        '1st Time',
        `Existing ${hasPostpay ? 'Post' : 'Pre'}-Pay Org Invitee`,
        'Start SIM Activation'
      )
      .send();
    setNextStep();
  };

  return (
    <FullScreenModal title="Activate SIMs" showProgress={false} onClose={onClose}>
      <div className="GetStarted">
        <h1 className="GetStarted__title">Let&apos;s activate your SIMs</h1>
        <div className="GetStarted__content">
          Before you start, here&apos;s what you&apos;ll need on hand:
          <ul className="GetStarted__items">
            <li key="1">Your SIM cards</li>
            {!hasPostpay && <li key="2">A payment method (i.e. credit card)</li>}
          </ul>
        </div>
        <div className="GetStarted__btn">
          <Button onClick={handleClick}>Get started</Button>
        </div>
      </div>
    </FullScreenModal>
  );
}

const mapStateToProps = (state: any) => ({
  hasPostpay: state.organization.balance.hasPostpay,
  isPreflight: state.activation.flags.isPreflight,
});

export default connect(mapStateToProps)(OnboardingGetStarted);
