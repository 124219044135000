import type { Plan } from 'common-js/types/Plan';
import { FC, ReactNode } from 'react';
import PlanOptionCard from './PlanOptionCard';
import PlanTypes from './planTypes';

interface PlanTypeSectionProps {
  selectedPlan?: Plan;
  plans: Array<Plan>;
  planType: (typeof PlanTypes)[keyof typeof PlanTypes];
  onChange: (...args: Array<any>) => any;
  numSims: number;
  existingPlanId?: PlanId;
}

const PlanTypeSection: FC<PlanTypeSectionProps> = ({
  selectedPlan,
  plans,
  planType,
  onChange,
  numSims,
  existingPlanId,
}) => {
  if (!plans || plans.length === 0) {
    return null;
  }

  let HeaderColumn: ReactNode = null;
  switch (planType) {
    case PlanTypes.Custom:
      HeaderColumn = (
        <>
          <p className="ActivationPlans__planType--title">Custom plans</p>
          <p className="ActivationPlans__planType--description">
            These plans have been customized for your fleet.
          </p>
        </>
      );
      break;
    case PlanTypes.PayAsYouGo:
      HeaderColumn = (
        <>
          <p className="ActivationPlans__planType--title">Pay as you go</p>
          <p className="ActivationPlans__planType--subtitle">Ideal for data usage that varies.</p>
          <p className="ActivationPlans__planType--description">
            A flexible plan that offers the best available rate at a given time.
          </p>
        </>
      );
      break;
    case PlanTypes.Monthly:
      HeaderColumn = (
        <>
          <p className="ActivationPlans__planType--title">Pay monthly</p>
          <p className="ActivationPlans__planType--subtitle">
            Ideal for data usage you can predict.
          </p>
          <p className="ActivationPlans__planType--description">
            A high-volume plan with a lower fixed rate based on expected usage.
          </p>
        </>
      );
      break;

    case PlanTypes.Preflight:
      HeaderColumn = (
        <>
          <p className="ActivationPlans__planType--title">Preflight plan</p>
          <p className="ActivationPlans__planType--description">
            This plan has been preselected based on your organization&apos;s Preflight configuration
            preferences.
          </p>
        </>
      );
      break;

    default:
      break;
  }

  return (
    <div className="ActivationPlans__planWrapper">
      <div className="ActivationPlans__planTypeSection">{HeaderColumn}</div>
      <div className="ActivationPlans__list">
        {plans.map((plan) => (
          <PlanOptionCard
            key={plan.id}
            selected={selectedPlan?.id === plan.id}
            plan={plan}
            onChange={onChange}
            numSims={numSims}
            preflightMode={planType === 4}
            disabled={plan.id === existingPlanId}
            forceExpanded={plan.id === existingPlanId}
          />
        ))}
      </div>
    </div>
  );
};

export default PlanTypeSection;
