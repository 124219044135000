import {
  OrganizationProvider,
  SimInventoryCommonTypes,
  SimInventoryPage,
} from '@hologram-hyper-dashboard/components';
import { getUserContextDataMemoized } from 'common-js/api/util';
import { FullHeightCalculatorHOC } from 'common-js/components';
import {
  DEVICES_DEVICES,
  DEVICE_LANDING,
  SIM_DETAILS,
  SIM_INVENTORY_ACTIVE,
  SIM_INVENTORY_DEACTIVATED,
  SIM_INVENTORY_PREDEPLOYMENT,
  pathMatches,
  withContext,
} from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useOnAllTasksSuccessful from 'common-js/hooks/useOnAllTasksSuccessful';
import useUserRole from 'common-js/hooks/useUserRole';
import { selectIsSuperAdmin } from 'common-js/reducers/account/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import {
  selectHasEureka0Flag,
  selectHasManageSingleProfileSwap,
} from 'common-js/reducers/releaseFlag/selectors';
import ActivationButton from 'devices/components/ActivationButton';
import { useCallback, useEffect, useRef, useState, type FC } from 'react';
import { browserHistory } from 'react-router';
import type { OpenModalCallback } from 'types/hyper-dashboard';
import SubMenu from '../components/SubMenu';

const SimInventory: FC = () => {
  const userContext = useAppSelector(getUserContextDataMemoized);
  const dispatch = useAppDispatch();
  const hasManageSingleProfileSwap: boolean = useAppSelector(selectHasManageSingleProfileSwap);
  const hasInFlight: boolean = useAppSelector(selectHasEureka0Flag);
  const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;

  const [segmentName, setSimSegment] =
    useState<SimInventoryCommonTypes.SimInventorySegmentName | null>(null);

  useEffect(() => {
    const loadSegment = () => {
      if (pathMatches(SIM_INVENTORY_PREDEPLOYMENT, window.location.pathname)) {
        setSimSegment('preflight');
      } else if (pathMatches(SIM_INVENTORY_DEACTIVATED, window.location.pathname)) {
        setSimSegment('deactivated');
      } else if (pathMatches(SIM_INVENTORY_ACTIVE, window.location.pathname)) {
        setSimSegment('active');
      } else {
        setSimSegment('all');
      }
    };
    loadSegment();
  });

  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);

  useEffect(() => {
    if (!isSuperAdmin && (!segmentName || segmentName === 'all')) {
      browserHistory.push(withContext(SIM_INVENTORY_ACTIVE));
    }
  }, [isSuperAdmin, segmentName]);

  const handleLinkClick = useCallback((url: string, value: any = null) => {
    // this passing of state (read: device info) is passed to the sim details page as a temp method
    // we have to figure out how to fetch the new endpoint here to have the networkStatus, and deviceName which are both needed for the back breadcrumb nav
    // current method is to show the nav works!!
    browserHistory.push({ pathname: url, state: value });
  }, []);

  const handleModalOpen = useCallback<OpenModalCallback>(
    ({ modalName, modalProps }) => {
      dispatch(openModal(modalName, modalProps, 'narrow no-style'));
    },
    [dispatch]
  );

  const refreshRef = useRef<SimInventoryCommonTypes.OnSimsModified>(null);

  const onSimsModified = useCallback<SimInventoryCommonTypes.OnSimsModified>(
    (simsModified?: Array<HologramSimId>) => {
      if (refreshRef.current) {
        refreshRef.current(simsModified);
      }
    },
    []
  );

  useOnAllTasksSuccessful(onSimsModified);
  const userRole = useUserRole();

  const activateButtonRenderer = useCallback<SimInventoryCommonTypes.ActivateButtonRenderer>(
    ({ children, variant }) => (
      <ActivationButton deviceType={segmentName ?? 'all'} variant={variant}>
        {children}
      </ActivationButton>
    ),
    [segmentName]
  );

  return (
    <OrganizationProvider organizationId={orgId}>
      <SubMenu />
      <FullHeightCalculatorHOC accountForDrawer relative>
        <div className="container">
          <SimInventoryPage
            userRole={userRole}
            segmentName={segmentName}
            simDetailBaseUrl={SIM_DETAILS}
            deviceBaseUrl={DEVICE_LANDING}
            devicesBaseUrl={DEVICES_DEVICES}
            handleLinkClick={handleLinkClick}
            handleModalOpen={handleModalOpen}
            ref={refreshRef}
            hasInFlight={hasInFlight}
            profileSwapAllowed={hasManageSingleProfileSwap}
            activateButtonRenderer={activateButtonRenderer}
          />
        </div>
      </FullHeightCalculatorHOC>
    </OrganizationProvider>
  );
};

export default SimInventory;
