/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Modals from '../modals';
import * as HyperDashboardModals from '../modals/HyperDashboardModals';
import { close } from '../reducers/modal/actions';

export interface ModalCommonProps {
  params: any;
  modalProps?: Record<any, any>;
}

const Modal: React.FC<any> = (props) => {
  const { closeModal, currentModal, modalProps = {}, style, params } = props;

  let Component = HyperDashboardModals[currentModal];

  if (Component) {
    return <Component params={params} {...modalProps} />;
  }

  Component = Modals[currentModal];

  if (!Component) {
    return null;
  }

  return (
    <div
      className={`Modal${Component ? ' active' : ''} ${style}`}
      onClick={() => (modalProps && modalProps.preventClose ? false : closeModal())}
    >
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <Component params={params} {...modalProps} />
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    currentModal: state.modals.currentModal,
    modalProps: state.modals.modalProps,
    style: state.modals.style,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        closeModal: close,
      },
      dispatch
    )
)(Modal);
