import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Panel } from '@hologram-dimension/panel';
import { PopoverCover } from '@holokit/core';
import ClassNames from 'clsx';
import { REASON_USAGE_LIMIT } from 'common-js/constants/deviceStates';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { FC, useCallback, useState } from 'react';
import DataLimitsForm from './DataLimitsForm';
import selectLastPausedDate from './selectLastPausedDate';
import selectOverageLimitString from './selectOverageLimitString';

interface ChangeDataUsageLimitFormProps {
  close: any;
  props: any;
}

const ChangeDataUsageLimitForm: FC<ChangeDataUsageLimitFormProps> = ({ close, props }) => {
  const { onOverageUpdateSuccess, overageLimit, device } = props;
  return (
    <DataLimitsForm
      close={close}
      device={device}
      onCancel={close}
      onSuccess={onOverageUpdateSuccess}
      overageBytes={overageLimit}
    />
  );
};

interface ChangeDataUsageLimitButtonProps {
  open: any;
}

const ChangeDataUsageLimitButton: FC<ChangeDataUsageLimitButtonProps> = ({ open }) => (
  <Button iconEnd="ChevronSingleSouth" onClick={open} variant="secondary">
    Change data usage limit
  </Button>
);

const DataLimitsSection = () => {
  const device = useAppSelector(selectCurrentDevice);
  const datePaused = useAppSelector(selectLastPausedDate);
  const overageLimitString = useAppSelector(selectOverageLimitString);
  const [showDataLimitSuccessInlineAlert, setShowDataLimitSuccessInlineAlert] = useState(false);

  const hasMetDataLimit = device.sysState === REASON_USAGE_LIMIT;
  const showDataLimitWarningState = hasMetDataLimit && !showDataLimitSuccessInlineAlert;

  const onOverageUpdateSuccess = useCallback(() => {
    if (hasMetDataLimit) {
      setShowDataLimitSuccessInlineAlert(true);
    }
  }, [hasMetDataLimit, setShowDataLimitSuccessInlineAlert]);

  const renderChangeDataUsageLimitForm = useCallback(
    (close) => (
      <ChangeDataUsageLimitForm
        close={close}
        props={{
          onOverageUpdateSuccess,
          overageLimit: device.overagelimit,
          device,
        }}
      />
    ),
    [device, onOverageUpdateSuccess]
  );

  const renderChangeDataUsageLimitButton = useCallback(
    (open) => <ChangeDataUsageLimitButton open={open} />,
    []
  );

  return (
    <Panel
      className="DataLimits DataLimits__visibleOverflow"
      header="Set data usage limit"
      noContentPadding
    >
      <div
        className={ClassNames('DataLimits__descriptor', {
          'DataLimits__descriptor--warning': hasMetDataLimit,
        })}
      >
        When a device meets the data usage limit, it will be paused. A device may continue using
        data past the usage limit if it’s inside an active, or open, data session.
      </div>
      <div className="DataLimits__well-container">
        <div className="DataLimits__well">
          {showDataLimitWarningState ? (
            <>
              <Icon name="Gauge" fill="DdsColorFillNotificationSuccess" />
              <p className="paragraph--warning">
                Device paused at {overageLimitString} data usage limit {datePaused}
              </p>
            </>
          ) : (
            <>
              <Icon name="Gauge" fill="DdsColorTextPrimaryOndefault" />
              <p>
                This SIM will be paused at{' '}
                <span className="DataLimits__paragraph-emphasis">{overageLimitString}</span> of data
                usage.
              </p>
            </>
          )}
          <div className="DataLimits__button-wrapper">
            <div className="DataLimits__button-wrapper__button">
              <PopoverCover
                className="DataLimitsForm__popover-cover"
                selfAligned={false}
                trigger={renderChangeDataUsageLimitButton}
                content={renderChangeDataUsageLimitForm}
              />
            </div>
          </div>
        </div>
        {showDataLimitWarningState && (
          <div className="DataLimits__inline-alert DataLimits__inline-alert--warning">
            <Icon
              className="DataLimits__inline-alert__icon"
              name="Warning"
              size="large"
              fill="DdsColorFillNotificationCaution"
            />
            <p className="DataLimits__inline-alert__text">
              Change data usage limit to resume service. It may take up to 10 minutes to resume once
              the limit is lifted.
            </p>
          </div>
        )}
        {showDataLimitSuccessInlineAlert && (
          <div className="DataLimits__inline-alert DataLimits__inline-alert--success">
            <Icon
              className="DataLimits__inline-alert__icon"
              name="CircleCheckmark"
              size="large"
              fill="DdsColorFillNotificationSuccess"
            />
            <p className="DataLimits__inline-alert__text">
              Your new data usage limit is being configured. It may take up to 10 minutes for your
              device to become ready for use.{' '}
            </p>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default DataLimitsSection;
