import * as actionTypes from './actionTypes';

export const openModal = (
  modalName: string,
  modalProps: Record<string, any> = {},
  style = 'default'
) => ({
  type: actionTypes.OPEN,
  modalName,
  modalProps,
  style,
});

export const triggerModalQueue = () => (dispatch: any, state: any) => {
  const snapshot = state();
  const { queue } = snapshot.modals;

  if (queue[0] && snapshot.modals.currentModal !== queue[0].modalName) {
    dispatch(openModal(queue[0].modalName, queue[0].modalProps, queue[0].style));
  }
};

export const close = () => (dispatch: any, state: any) => {
  dispatch({ type: actionTypes.POP_QUEUE });
  dispatch({ type: actionTypes.CLOSE });
  triggerModalQueue()(dispatch, state);
};

export const pushToQueue = (modalName: string, modalProps: {}, style: string) => ({
  type: actionTypes.PUSH_QUEUE,
  modalName,
  modalProps,
  style,
});
