import { Button } from '@hologram-dimension/button';
import analyticsEventBuilder from 'common-js/analytics';
import { CONTACT_SALES } from 'common-js/constants/links';
import React from 'react';
import { ButtonVariantType } from 'types/dimension';

interface BMPContactUsButtonProps {
  variant?: ButtonVariantType;
  className?: string;
  analyticsName?: string;
  analyticsData?: Record<string, any>;
  flagLevel?: string;
}
const BMPContactUsButton = ({
  variant = 'secondary',
  className,
  analyticsName,
  analyticsData = {},
  flagLevel,
}: BMPContactUsButtonProps) => {
  const onClickHandler = () => {
    if (analyticsName) {
      analyticsEventBuilder.buttonClick('BMP', analyticsName, 'Upgrade CTA').send({
        ...analyticsData,
        ...(flagLevel ? { flag: flagLevel } : {}),
      });
    }

    window.open(CONTACT_SALES, '_blank');
  };

  return (
    <Button className={className} iconEnd="ExternalLink" variant={variant} onClick={onClickHandler}>
      Contact us
    </Button>
  );
};

export default BMPContactUsButton;
