import { Checkbox } from '@hologram-dimension/checkbox';
import { ComplexIcon, Dropdown } from '@holokit/core';
import _classNames from 'clsx';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import _ from 'lodash';
import React from 'react';
import {
  labelForRole,
  translatePermissionsToRole,
  translateRoleToPermissions,
} from '../../../../common/utils/permissions';

class CollaboratorItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  onChangeRole = (role) => {
    const { pushGlobalMessage, updateUserPermissions, col } = this.props;

    if (!col) {
      return;
    }

    this.setState({ isLoading: true });

    updateUserPermissions([col.id], translateRoleToPermissions(role))
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        pushGlobalMessage(e, 'error');
        this.setState({ isLoading: false });
      });
  };

  onRemove = () => {
    const { pushGlobalMessage, removeUsers, col } = this.props;

    if (!col) {
      return;
    }

    this.setState({ isLoading: true });

    removeUsers([col.id])
      .then(() => {})
      .catch((e) => {
        pushGlobalMessage(e, 'error');
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      isSelected = false,
      col,
      isOwner = false,
      onCollabSelectChange = _.noop,
      hasEditorLimited = false,
      isAdmin,
    } = this.props;
    const { isLoading } = this.state;

    if (!col) {
      return null;
    }

    const selectedRole = translatePermissionsToRole(col.permissions);
    const roleLabel = labelForRole(selectedRole);

    const showReadOnlyLabel = isOwner || !isAdmin;
    const readOnlyLabel = isOwner ? 'Owner' : roleLabel.label;

    const handleChangeRoleClick = (role) => {
      this.onChangeRole(role);
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Permissions - Individual Manage Access',
          role,
          origin: 'Settings Page List Dropdown',
        },
      });
    };

    return (
      <div
        className={_classNames('grid-row vertical-align collab-list-item', {
          active: isSelected,
        })}
      >
        {isAdmin ? (
          <Checkbox
            className="select-collab"
            defaultChecked={isSelected}
            disabled={isOwner}
            onChange={(e) => onCollabSelectChange(col.id, e.target.checked)}
            label=""
            hiddenLabel
          />
        ) : (
          <div className="select-collab-empty" />
        )}
        <div className="grid-item">
          <div className="collab-name">{col.name}</div>
          <div className="collab-email">{col.email}</div>
        </div>
        {showReadOnlyLabel ? (
          <div>{readOnlyLabel}</div>
        ) : (
          <>
            <Dropdown
              align="right"
              classes="collab-list-item__dropdown"
              dropdownText={roleLabel.label}
              items={[
                {
                  children: 'Admin',
                  caption:
                    'Assign roles, activate and deactivate devices, change plans, manage billing',
                  onClick: () => handleChangeRoleClick('admin'),
                  sectionId: 1,
                  closeAfterClick: true,
                },
                {
                  children: 'Manager',
                  caption:
                    'Activate and deactivate devices, change plans, access limited billing details',
                  onClick: () => handleChangeRoleClick('manager'),
                  sectionId: 2,
                  closeAfterClick: true,
                },
                {
                  children: 'Editor',
                  caption:
                    'View devices and cloud messaging, manage device settings and app integrations, access limited billing details',
                  onClick: () => handleChangeRoleClick('editor'),
                  sectionId: 3,
                  closeAfterClick: true,
                },
                ...(hasEditorLimited
                  ? [
                      {
                        children: 'Editor Limited',
                        caption:
                          'View devices and cloud messaging, manage device settings and app integrations',
                        onClick: () => handleChangeRoleClick('editor_limited'),
                        sectionId: 4,
                        closeAfterClick: true,
                      },
                    ]
                  : []),
                {
                  children: 'Remove',
                  destructive: true,
                  onClick: this.onRemove,
                  sectionId: hasEditorLimited ? 5 : 4,
                  closeAfterClick: true,
                },
              ]}
            />
            {isLoading && <ComplexIcon classes="CollaboratorList__loader" name="spinner" />}
          </>
        )}
      </div>
    );
  }
}

export default CollaboratorItem;
