import analyticsEventBuilder from 'common-js/analytics';
import FullScreenModal from 'common-js/components/full-screen-modal';
import {
  DEVICES_DEVICES,
  DEVICES_PREFLIGHT,
  SIM_INVENTORY_ACTIVE,
  SIM_INVENTORY_PREDEPLOYMENT,
} from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { resetActivationStoreToDefault } from 'common-js/reducers/activation/actions';
import { useEffect } from 'react';
import { browserHistory } from 'react-router';
import {
  selectHasSimsPages,
  selectPromoteSimsPages,
} from 'common-js/reducers/releaseFlag/selectors';
import Confirmation from './Confirmation';

interface ConfirmationScreenProps {
  success?: boolean;
  isOnboarding?: boolean;
  error?: any;
  changingPlan?: boolean;
  activatedPlan?: any;
}

const ConfirmationScreen = ({
  success,
  isOnboarding = false,
  error,
  changingPlan = false,
  activatedPlan,
}: ConfirmationScreenProps) => {
  const isPreflight: boolean = useAppSelector((state) => state.activation.flags.isPreflight);
  const hasPostpay: boolean = useAppSelector((state) => state.organization.balance.hasPostpay);
  const hasSimInventoryPage = useAppSelector(selectHasSimsPages);
  const promoteSimInventoryPage = useAppSelector(selectPromoteSimsPages);
  const dispatch = useAppDispatch();

  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';

  useEffect(
    () =>
      analyticsEventBuilder
        .pageView(analyticsTitle, 'Page load')
        .page('Confirmation screen')
        .send(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onClose = () => {
    analyticsEventBuilder
      .buttonClick(analyticsTitle, 'Exit Flow')
      .send({ step: 'Confirmation Screen' });
    dispatch(resetActivationStoreToDefault());

    let redirectUrl;
    if (isPreflight) {
      if (hasSimInventoryPage && promoteSimInventoryPage) {
        redirectUrl = SIM_INVENTORY_PREDEPLOYMENT;
      } else {
        redirectUrl = DEVICES_PREFLIGHT;
      }
    } else if (hasSimInventoryPage && promoteSimInventoryPage) {
      redirectUrl = SIM_INVENTORY_ACTIVE;
    } else {
      redirectUrl = DEVICES_DEVICES;
    }

    browserHistory.push(redirectUrl);
  };

  return (
    <FullScreenModal
      onClose={onClose}
      showProgress={false}
      title={changingPlan ? 'Change data plan' : 'Activate SIMs'}
    >
      {!isOnboarding && success && (
        <div className="Confirmation__header">Thank you for your payment</div>
      )}
      <Confirmation
        isOnboarding={isOnboarding}
        success={success}
        error={error}
        hasPostpay={hasPostpay}
        changingPlan={changingPlan}
        activatedPlan={activatedPlan}
      />
    </FullScreenModal>
  );
};

export default ConfirmationScreen;
