import './init';

import '@stripe/stripe-js';
import {
  auth,
  onPageRouteChange,
  redirectOnAuth,
  redirectToContext,
} from 'common-js/api/middleware';
import { Download } from 'common-js/components';
import HoloStore from 'common-js/components/HoloStore';
import * as Paths from 'common-js/constants/paths';
import { createRoot } from 'react-dom/client';
import { Redirect, Route, Router, browserHistory } from 'react-router';

import '../scss/index.scss';

import * as Account from './account';
import * as Activation from './activation';
import * as Admin from './admin';
import * as Apps from './apps';
import * as Billing from './billing';
import * as Device from './device';
import * as Devices from './devices';
import * as Home from './home';
import {
  Layout,
  LayoutAdmin,
  LayoutBilling,
  LayoutFullDrawer,
  LayoutHome,
  LayoutReports,
  LayoutRoutes,
  LayoutSettings,
  LayoutSingleDevice,
  LayoutUsage,
  LayoutUsageWithDateFilter,
} from './layouts';
import * as Orgs from './orgs';
import * as Reports from './reports';
import * as Settings from './settings';
import * as SIMs from './sims';
import * as Usage from './usage';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <HoloStore>
    <Router onUpdate={onPageRouteChange} history={browserHistory}>
      <Route component={Layout}>
        <Route onEnter={auth} path={Paths.ORGS_CREATE_STEP1} component={Orgs.CreateOrg} />
        <Route onEnter={auth} path={Paths.ORGS_CREATE_STEP2} component={Orgs.CreateOrg} />

        <Route onEnter={auth} path={Paths.SIM_INVENTORY} component={SIMs.SimInventory} />
        <Route onEnter={auth} path={Paths.SIM_INVENTORY_ACTIVE} component={SIMs.SimInventory} />
        <Route
          onEnter={auth}
          path={Paths.SIM_INVENTORY_PREDEPLOYMENT}
          component={SIMs.SimInventory}
        />
        <Route
          onEnter={auth}
          path={Paths.SIM_INVENTORY_DEACTIVATED}
          component={SIMs.SimInventory}
        />
        <Route onEnter={auth} path={Paths.SIM_DETAILS} component={SIMs.SimDetails} />
        <Route onEnter={auth} path={Paths.SIM_PROFILES} component={SIMs.SimDetails} />
        <Route onEnter={auth} path={Paths.SIM_SIMS_AND_DEVICES} component={SIMs.SimDetails} />
        <Route onEnter={auth} path={Paths.SIM_USAGE} component={SIMs.SimDetails} />
        <Route onEnter={auth} path={Paths.SIM_PLANS} component={SIMs.SimDataPlan} />
        <Route onEnter={auth} path={Paths.SIM_TAG_MANAGER} component={SIMs.SimTagManager} />
        <Route onEnter={auth} path={Paths.SIM_ACTIVITY} component={SIMs.SimActivityHistory} />
        <Route
          onEnter={auth}
          path={Paths.SIM_ACTIVITY_HISTORY_DETAILS}
          component={Devices.ActivityHistoryDetails}
        />

        {/* TODO: Refactor these pages so they can be included in the LayoutDevices block. */}
        <Route
          onEnter={(nextState) => {
            browserHistory.push(`${nextState.location.pathname}/status`);
          }}
          path={Paths.DEVICE_BASE}
        />
        <Route onEnter={auth} path={Paths.DEVICES_DEVICES} component={Devices.DevicesSegment} />
        <Route onEnter={auth} path={Paths.DEVICES_PREFLIGHT} component={Devices.DevicesSegment} />
        <Route onEnter={auth} path={Paths.DEVICES_DEACTIVATED} component={Devices.DevicesSegment} />
        <Route onEnter={auth} path={Paths.DEVICES_PLANS} component={Devices.Plans} />
        <Route onEnter={auth} path={Paths.DEVICES_TAG_MANAGER} component={Devices.TagManagerPage} />
        <Route onEnter={auth} path={Paths.DEVICES_ACTIVITY} component={Devices.Activity} />
        <Route
          onEnter={auth}
          path={Paths.DEVICES_ACTIVITY_HISTORY_DETAILS}
          component={Devices.ActivityHistoryDetails}
        />
      </Route>

      <Route component={LayoutAdmin}>
        <Route onEnter={auth} path={Paths.ADMIN_RELEASEFLAGS} component={Admin.ReleaseFlags} />
        <Route onEnter={auth} path={Paths.ADMIN_IMPERSONATE} component={Admin.Impersonate} />
        <Route path={Paths.ADMIN_SPOOF_LANDING} component={Admin.SpoofLanding} />
      </Route>

      <Route component={LayoutSingleDevice}>
        <Route onEnter={auth} path={Paths.DEVICE_DATA_LIMITS} component={Device.DataLimits} />
        <Route onEnter={auth} path={Paths.DEVICE_MESSAGE} component={Device.Message} />
        <Route onEnter={auth} path={Paths.DEVICE_PLAN} component={Device.Plan} />
        <Route onEnter={auth} path={Paths.DEVICE_ROUTES} component={Device.Routes} />
        <Route onEnter={auth} path={Paths.DEVICE_SPACEBRIDGE} component={Device.Spacebridge} />
        <Route onEnter={auth} path={Paths.DEVICE_STATUS} component={Device.Status} />
        <Route onEnter={auth} path={Paths.DEVICE_USAGE} component={Device.Usage} />
        <Route onEnter={auth} path={Paths.DEVICE_WEBHOOKS} component={Device.Webhooks} />
        <Route onEnter={auth} path={Paths.DEVICE_HYPER} component={Device.Hyper} />
        <Route onEnter={auth} path={Paths.DEVICE_PROFILES} component={Device.Hyper} />
      </Route>

      <Route component={LayoutReports}>
        <Route onEnter={auth} path={Paths.REPORTS_OVERVIEW} component={Reports.ReportsOverview} />
        <Route onEnter={auth} path={Paths.REPORTS_DETAIL} component={Reports.ReportDetail} />
      </Route>

      <Route component={LayoutUsage}>
        <Route onEnter={auth} path={Paths.USAGE_LIVE} component={Usage.LiveUsage} />
        <Route
          onEnter={auth}
          path={Paths.USAGE_PREDEPLOYMENT}
          component={Usage.UsagePredeployment}
        />
        <Route onEnter={auth} path={Paths.USAGE_BY_DAY} component={Usage.UsageOverviewWrapper} />
        <Route onEnter={auth} path={Paths.USAGE_BY_SIM} component={Usage.UsageOverviewWrapper} />
        <Route
          onEnter={auth}
          path={Paths.USAGE_BY_SIM_AND_DAY}
          component={Usage.UsageOverviewWrapper}
        />
        <Route
          onEnter={auth}
          path={Paths.USAGE_BY_SESSION}
          component={Usage.UsageOverviewWrapper}
        />
      </Route>

      <Route component={LayoutSettings}>
        <Route onEnter={auth} path={Paths.SETTINGS_ORG} component={Settings.SettingsOrg} />
        <Route onEnter={auth} path={Paths.SETTINGS_ACCOUNT} component={Settings.SettingsAccount} />
        <Route onEnter={auth} path={Paths.SETTINGS_API} component={Settings.SettingsApi} />
        <Route
          onEnter={auth}
          path={Paths.SETTINGS_SUBSCRIPTION}
          component={Settings.SettingsSubscription}
        />
      </Route>

      <Route component={LayoutBilling}>
        <Route onEnter={auth} path={Paths.BILLING_ACCOUNT} component={Billing.Account} />
        <Route onEnter={auth} path={Paths.BILLING_HISTORY} component={Billing.History} />
        <Route onEnter={auth} path={Paths.BILLING_ORDERS} component={Billing.Orders} />
        <Route onEnter={auth} path={Paths.BILLING_STATEMENTS} component={Billing.Statements} />
        <Route onEnter={auth} path={Paths.BILLING_INVOICES} component={Billing.Invoices} />
      </Route>

      <Route component={LayoutRoutes}>
        <Route onEnter={auth} path={Paths.APPS_OVERVIEW} component={Apps.Overview} />
        <Route onEnter={auth} path={Paths.APPS_CONFIGURE} component={Apps.Configure} />
        <Route onEnter={auth} path={Paths.APPS_ADD} component={Apps.Add} />
        <Route
          onEnter={redirectToContext}
          path={Paths.OLD_APPS_OVERVIEW}
          component={Apps.Overview}
        />
        <Route
          onEnter={redirectToContext}
          path={Paths.OLD_APPS_CONFIGURE}
          component={Apps.Configure}
        />
        <Route onEnter={redirectToContext} path={Paths.OLD_APPS_ADD} component={Apps.Add} />
      </Route>

      <Route component={LayoutUsageWithDateFilter}>
        {/* TODO: Legacy: remove after releasing the new reports */}
        <Route onEnter={auth} path={Paths.USAGE_OVERVIEW} component={Usage.UsageOverviewWrapper} />
        <Route onEnter={auth} path={Paths.USAGE_BREAKDOWN} component={Usage.UsageBreakdown} />
      </Route>

      <Route component={LayoutHome}>
        <Route onEnter={auth} path={Paths.HOME_OVERVIEW} component={Home.HomeWrapper} />
        <Route
          onEnter={auth}
          path={Paths.ACTIONS_ALERTS_DEVICE_TACCHANGE}
          component={Home.TacChange}
        />
        <Route
          onEnter={auth}
          path={Paths.ACTIONS_ALERTS_DEVICE_DATALIMIT}
          component={Home.DataLimit}
        />
        <Route
          onEnter={auth}
          path={Paths.ACTIONS_ALERTS_DEVICE_HIGHUSAGE}
          component={Home.HighUsage}
        />
      </Route>

      <Route component={LayoutFullDrawer}>
        <Route path={Paths.ACCOUNT_START} component={Account.Start} />
        <Route
          onEnter={auth}
          path={Paths.USAGE_INSPECT_DATAUSED}
          component={Usage.InspectDataUsed}
        />
        <Route
          onEnter={auth}
          path={Paths.DEVICE_INSPECT_DATAUSED}
          component={Device.InspectDataUsed}
        />
      </Route>

      <Route onEnter={auth} path={Paths.ACTIVATION} component={Activation.LandingPage} />

      <Route onEnter={auth} path={Paths.DOWNLOAD} component={Download} />

      <Redirect from={Paths.ACCOUNT_OLD_LOGIN} to={Paths.ACCOUNT_LOGIN} />
      <Route onEnter={redirectOnAuth} path={Paths.ACCOUNT_LOGIN} component={Account.Login} />
      <Route path={Paths.ACCOUNT_REGISTER} component={Account.Register} />
      <Route path={Paths.ACCOUNT_FORGOT_PASSWORD} component={Account.ForgotPassword} />
      <Route path={Paths.ACCOUNT_RESET_PASSWORD} component={Account.ResetPassword} />
      <Route path={Paths.ACCOUNT_VERIFY_EMAIL} component={Account.VerifyEmail} />

      <Redirect from="/" to={Paths.ACCOUNT_LOGIN} />
      <Route path="/*" component={Account.FourZeroFour} />
    </Router>
  </HoloStore>
);
