import React, { useState } from 'react';
import EmptyState from './EmptyState';
import ShowWebhook from './ShowWebhook';
import UpdateWebhookForm from './UpdateWebhookForm';

const ConfigureWebhookForm = ({ webhook, setWebhook }) => {
  const [isEditing, setIsEditing] = useState(false);
  const hasWebhook = webhook.url !== '';

  return (
    <div>
      {!hasWebhook && !isEditing && <EmptyState onGenerateWebhook={() => setIsEditing(true)} />}
      {hasWebhook && !isEditing && (
        <ShowWebhook
          webhook={webhook}
          setWebhook={setWebhook}
          onUpdate={() => setIsEditing(true)}
        />
      )}
      {isEditing && (
        <UpdateWebhookForm
          webhook={webhook}
          setWebhook={setWebhook}
          onCancelUpdate={() => setIsEditing(false)}
        />
      )}
    </div>
  );
};

export default ConfigureWebhookForm;
