import useStore from 'common-js/useStore';
import { useRef, type FC, type ReactNode } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import DeviceContextProvider from './DeviceContextProvider';
import HoloStoreContextProvider from './HoloStoreContextProvider';

interface HoloStoreProps {
  children?: ReactNode;
}

const HoloStore: FC<HoloStoreProps> = ({ children }) => {
  const { store, persistor } = useStore();

  const alertBannerContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="site-banners" ref={alertBannerContainerRef} />
        <div className="HoloStore full-height">
          <HoloStoreContextProvider alertBannerContainerRef={alertBannerContainerRef}>
            <DeviceContextProvider>{children}</DeviceContextProvider>
          </HoloStoreContextProvider>
        </div>
      </PersistGate>
    </Provider>
  );
};

export default HoloStore;
