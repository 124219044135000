import { useState, useEffect } from 'react';
import { inRange, throttle } from 'lodash';

const getDeviceBreakpoint = (width) => {
  const BREAKPOINTS = {
    PHONE: 520,
    TABLET: 768,
    DESKTOP: 1024,
  };

  return {
    isDesktop: width > BREAKPOINTS.DESKTOP,
    isTablet: inRange(width, BREAKPOINTS.PHONE, BREAKPOINTS.TABLET),
    isMobile: inRange(width, 0, BREAKPOINTS.PHONE),
  };
};

let defaultWidth;

if (typeof window !== 'undefined') {
  defaultWidth = window.screen.width;
}

export interface Breakpoint {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

const useWindowSize = (): Breakpoint => {
  const [breakpoint, setBreakpoint] = useState(() => getDeviceBreakpoint(defaultWidth));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBreakpoint(getDeviceBreakpoint(window.innerWidth));
    }, 200);

    window.addEventListener('resize', calcInnerWidth);

    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakpoint;
};

export default useWindowSize;
