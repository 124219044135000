import * as API from '../../../api';
import { clearMfaErrors, handleMfaError } from './actions';

export const resendTotp = () => async (dispatch, getState) => {
  const state = getState();
  const { email } = state.account.mfa.method;
  dispatch(clearMfaErrors());

  try {
    return await API.resendTotp(email);
  } catch (e) {
    return dispatch(handleMfaError(e));
  }
};

export default resendTotp;
