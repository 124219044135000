import React from 'react';

const SIZER_STYLES = {
  position: 'absolute',
  width: 0,
  height: 0,
  visibility: 'hidden',
  overflow: 'scroll',
  whiteSpace: 'pre',
};

const STYLE_PROPS = ['fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'letterSpacing'];

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputWidth: null };
  }

  componentDidMount() {
    if (this.props.autoresize) {
      this.copyInputStyles();
      this.updateInputWidth();
    }

    if (this.props.autofocus) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoresize && prevProps.value !== this.props.value) {
      this.updateInputWidth();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.input.value !== newProps.value) {
      this.input.value = newProps.value;
    }
  }

  copyInputStyles() {
    const inputStyle = window.getComputedStyle(this.input);

    STYLE_PROPS.forEach((prop) => {
      this.sizer.style[prop] = inputStyle[prop];
    });
  }

  updateInputWidth() {
    this.setState({ inputWidth: Math.ceil(this.sizer.scrollWidth) + 2 });
  }

  render() {
    const sizerText = this.props.value || this.props.placeholder;
    const { placeholder } = this.props;

    return (
      <div className="react-tags__search-input">
        <input
          ref={(c) => {
            this.input = c;
          }}
          role="combobox"
          placeholder={placeholder}
          style={{ width: this.state.inputWidth }}
          onFocus={this.props.onFocus}
          onChange={(e) => this.props.onChange(e.currentTarget.value, 'typing')}
          onKeyDown={(e) => this.props.handleKeyDown(e)}
          onBlur={this.props.onBlur}
        />
        <div
          ref={(c) => {
            this.sizer = c;
          }}
          style={SIZER_STYLES}
        >
          {sizerText}
        </div>
      </div>
    );
  }
}

export default Input;
