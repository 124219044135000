import useApi from 'common-js/api/useApi';
import { useCallback } from 'react';
import {
  API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER,
  apiToClientPasswordErrors,
} from 'common-js/constants/setAccountPasswordFormStrings';

type ApiErrorCause = 'password' | 'reqid' | 'userid' | 'token' | 'unknown';

export const API_RESPONSE_RESET_PASSWORD_INVALID_PASSWORD = 'Invalid password request';
export const API_RESPONSE_RESET_PASSWORD_TOKEN_INVALID_FOR_USER = 'Token invalid for user';
export const API_RESPONSE_RESET_PASSWORD_TOKEN_INVALID = 'Token invalid';
export const API_RESPONSE_RESET_PASSWORD_TOKEN_EXPIRED = 'Token expired';
export const API_RESPONSE_RESET_PASSWORD_REQID_MISSING = `Required field 'reqid' missing or empty`;
export const API_RESPONSE_RESET_PASSWORD_USERID_MISSING = `Required field 'userid' missing or empty`;
export const API_RESPONSE_RESET_PASSWORD_TOKEN_MISSING = `Required field 'token' missing or empty`;

// If the API error message should be reformatted, add it to this object
export const apiResponseToMessage: Record<string, string> = {
  [API_RESPONSE_RESET_PASSWORD_INVALID_PASSWORD]: `Something went wrong on our end.`,
  [API_RESPONSE_RESET_PASSWORD_TOKEN_INVALID_FOR_USER]: 'Something went wrong on our end.',
  [API_RESPONSE_RESET_PASSWORD_REQID_MISSING]: 'The password reset link is invalid.',
  [API_RESPONSE_RESET_PASSWORD_USERID_MISSING]: 'The password reset link is invalid.',
  [API_RESPONSE_RESET_PASSWORD_TOKEN_MISSING]: 'The password reset link is invalid.',
  [API_RESPONSE_RESET_PASSWORD_TOKEN_INVALID]: 'The password reset link is invalid.',
  [API_RESPONSE_RESET_PASSWORD_TOKEN_EXPIRED]: 'The password reset link has expired.',
  ...apiToClientPasswordErrors,
};

// Specify which part of the request triggered the API error
const apiResponseToCause: Record<string, ApiErrorCause> = {
  [API_RESPONSE_RESET_PASSWORD_INVALID_PASSWORD]: 'unknown',
  [API_RESPONSE_RESET_PASSWORD_TOKEN_INVALID_FOR_USER]: 'token',
  [API_RESPONSE_RESET_PASSWORD_REQID_MISSING]: 'reqid',
  [API_RESPONSE_RESET_PASSWORD_USERID_MISSING]: 'userid',
  [API_RESPONSE_RESET_PASSWORD_TOKEN_MISSING]: 'token',
  [API_RESPONSE_RESET_PASSWORD_TOKEN_INVALID]: 'token',
  [API_RESPONSE_RESET_PASSWORD_TOKEN_EXPIRED]: 'token',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER]: 'password',
};
interface ApiErrorResponse {
  success: false;
  error: Partial<Record<ApiErrorCause, string>>;
}

const useResetPassword = () => {
  const apiCall = useApi.call('/register/resetpassword', { method: 'POST' });

  return useCallback(
    async ({ password, reqid, userid, token }) => {
      try {
        return await apiCall({ body: { password, reqid, userid, token } });
      } catch (error) {
        if (apiResponseToMessage[error as string]) {
          // Error message should be formatted for better UI presentation
          const errorResponse: ApiErrorResponse = {
            success: false,
            error: {
              [apiResponseToCause[error as string]]: apiResponseToMessage[error as string],
            },
          };
          return errorResponse;
        }

        throw error; // Error message should be thrown as-is
      }
    },
    [apiCall]
  );
};

export default useResetPassword;
