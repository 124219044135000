import { Icon } from '@hologram-dimension/icon';
import type { SimInventoryApiTypes } from '@hologram-hyper-dashboard/components';
import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import TopMenu from 'common-js/components/menu/TopMenu';
import { deactivatedStates, preflightStates } from 'common-js/constants/deviceStates';
import * as Paths from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { getDeviceCount } from 'common-js/reducers/organization/selectors';
import {
  selectHasActivityHistory,
  selectHasPredeploymentTesting,
} from 'common-js/reducers/releaseFlag/selectors';
import { getDeviceActionString } from 'devices/util';
import { FC } from 'react';

interface SimSubMenuProps {
  simId?: string;
  deviceInfo?: SimInventoryApiTypes.SimsDaoDeviceOverview;
}

const SubMenu: FC<SimSubMenuProps> = ({ simId, deviceInfo }) => {
  const isAdmin = useAppSelector(
    (state) => state.account.role === 'adm' || state.account.role === 'hdm'
  );
  const hasActivityHistory = useAppSelector(selectHasActivityHistory);
  const taskDetails = useAppSelector((state) => state.devices?.tasks?.details ?? {});
  const hasPredeploymentTesting = useAppSelector(selectHasPredeploymentTesting);
  const orgDeviceCount = useAppSelector(getDeviceCount);
  const isOnboarding = !orgDeviceCount || orgDeviceCount === 0;

  const getTopMenuBackBreadcrumb = () => {
    if (isAdmin) {
      return { text: 'All SIMs', link: Paths.SIM_INVENTORY };
    }

    const state = deviceInfo?.networkState as any;

    if (state && preflightStates.includes(state)) {
      return { text: 'Pre-deployment SIMs', link: Paths.SIM_INVENTORY_PREDEPLOYMENT };
    }
    if (state && deactivatedStates.includes(state)) {
      return { text: 'Deactivated SIMs', link: Paths.SIM_INVENTORY_DEACTIVATED };
    }
    return { text: 'Active SIMs', link: Paths.SIM_INVENTORY_ACTIVE };
  };

  const topMenuBackBreadcrumb = getTopMenuBackBreadcrumb();

  if (simId) {
    return (
      <TopMenu rightContent={<SubMenuRightContent />}>
        <TopMenu.Item
          text={topMenuBackBreadcrumb.text}
          href={Paths.withContext(topMenuBackBreadcrumb.link)}
          className="grid-row v-align grid-gap-halfx"
        >
          <Icon
            name="ChevronSingleWest"
            size="small"
            fill="DdsColorPaletteWhite"
            style={{ transform: 'translateY(-0.5px)' }}
          />
        </TopMenu.Item>
        <TopMenu.Item active isDeviceName text={deviceInfo?.deviceName} />
      </TopMenu>
    );
  }

  // Activity history details, but only show this if the user has access to activity history
  if (Paths.pathMatchesWithIdsReplaced(Paths.SIM_ACTIVITY_HISTORY_DETAILS) && hasActivityHistory) {
    const numberDevices = taskDetails.deviceCount ?? 0;
    const deviceActionString =
      numberDevices > 0 ? getDeviceActionString(numberDevices, taskDetails.action) : 'Loading...';

    return (
      <TopMenu rightContent={<SubMenuRightContent />}>
        <TopMenu.Item
          text="Activity history"
          href={Paths.withContext(Paths.SIM_ACTIVITY)}
          className="grid-row v-align"
        >
          <Icon name="ChevronSingleWest" fill="DdsColorPaletteGreyneutral100" />
        </TopMenu.Item>
        <TopMenu.Item active isDeviceName text={deviceActionString} />
      </TopMenu>
    );
  }

  return (
    <TopMenu rightContent={<SubMenuRightContent />}>
      {isAdmin && (
        <TopMenu.Item
          text="All SIMs"
          active={Paths.pathMatches(Paths.SIM_INVENTORY)}
          href={Paths.withContext(Paths.SIM_INVENTORY)}
        />
      )}
      {hasPredeploymentTesting && (
        <TopMenu.Item
          text="Pre-deployment"
          active={Paths.pathMatches(Paths.SIM_INVENTORY_PREDEPLOYMENT)}
          href={Paths.withContext(Paths.SIM_INVENTORY_PREDEPLOYMENT)}
        />
      )}
      <TopMenu.Item
        text="Active"
        active={Paths.pathMatches(Paths.SIM_INVENTORY_ACTIVE)}
        href={Paths.withContext(Paths.SIM_INVENTORY_ACTIVE)}
      />
      {!isOnboarding && (
        <TopMenu.Item
          text="Deactivated"
          active={Paths.pathMatches(Paths.SIM_INVENTORY_DEACTIVATED)}
          href={Paths.withContext(Paths.SIM_INVENTORY_DEACTIVATED)}
        />
      )}
      <TopMenu.Item
        text="Data plans"
        active={Paths.pathMatches(Paths.SIM_PLANS)}
        href={Paths.withContext(Paths.SIM_PLANS)}
      />
      <TopMenu.Item
        text="Tags"
        active={Paths.pathMatches(Paths.SIM_TAG_MANAGER)}
        href={Paths.withContext(Paths.SIM_TAG_MANAGER)}
      />
      <TopMenu.Item
        text="Activity history"
        active={Paths.pathMatches(Paths.SIM_ACTIVITY)}
        href={Paths.withContext(Paths.SIM_ACTIVITY)}
      />
    </TopMenu>
  );
};

export default SubMenu;
