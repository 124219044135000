import { Icon } from '@hologram-dimension/icon';
import { Tooltip } from '@holokit/core';
import { PAUSE_SIM, RESUME_SIM } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import useGetDeviceById from 'common-js/api/devices/useGetDeviceById';
import DeviceStatusIndicator from 'common-js/components/DeviceStatusIndicator';
import { setCurrentDevice } from 'common-js/reducers/currentDevice/actions';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import { Device } from 'common-js/types/Device';
import React, { memo, useCallback, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import DeviceHyperIndicator from './DeviceHyperIndicator';
import DeviceNameInput from './DeviceNameInput';
import DeviceTagDropdown from './DeviceTagDropdown';
import DeviceTagInput from './DeviceTagInput';
import PauseResumeDevice from './PauseResumeDevice';
import selectShouldShowDeviceTagDropdown from './selectShouldShowDeviceTagDropdown';

// import Moment from 'moment-timezone';
// import * as Paths from 'common-js/constants/paths';
// import { buildSDPPath } from 'common-js/constants/paths';
// import { Callout, Icon, Tooltip } from '@holokit/core';
// import { Link } from 'react-router';
// import {
//   selectCurrentDevice,
//   selectProfileHistory,
// } from 'common-js/reducers/currentDevice/selectors'

const HyperICCIDTooltip = memo(() => (
  <Tooltip
    content="This identifier represents the active profile on this SIM card. For Hyper SIMs receiving remote coverage updates, this number changes when a new profile is enabled. For non-Hyper SIMs, it is the same as the SIM number."
    title="Active SIM Number (ICCID)"
    classes="DeviceHeader__tooltip"
  >
    <span>
      <Icon name="CircleInfo" size="small" fill="DdsColorPalettePurple50" />
    </span>
  </Tooltip>
));

// const getProfileSwapDate = (profileHistory) => {
//   const profileSwapDate = profileHistory.length > 0 ? profileHistory[0].timestamp : undefined;
//   const API_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
//   const TIME_FORMAT = 'MMM D, YYYY';
//   const timeZone = Moment.tz.guess();
//   const formattedProfileSwapDate = profileSwapDate
//     ? Moment.utc(profileSwapDate, API_TIME_FORMAT).tz(timeZone).format(TIME_FORMAT)
//     : undefined;

//   return formattedProfileSwapDate;
// };

interface DeviceHeaderProps {
  deviceId?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DeviceHeader = ({ deviceId }: DeviceHeaderProps) => {
  const device: Device = useAppSelector(selectCurrentDevice);
  // const profileHistory = useAppSelector(selectProfileHistory);
  const releaseFlags = useAppSelector(getReleaseFlags);
  const shouldShowDeviceTagDropdown = useAppSelector(selectShouldShowDeviceTagDropdown);
  const DeviceTags = shouldShowDeviceTagDropdown ? DeviceTagDropdown : DeviceTagInput;

  const hasHyperFlags = releaseFlags.euicc && releaseFlags.hyper_sdp_improvements;
  const hyperDevice = hasHyperFlags && device.isHyper;
  const simLabel = hyperDevice ? 'Active SIM number (ICCID)' : 'SIM number (ICCID)';
  const linkIdLabel = hyperDevice ? 'Active link ID' : 'Link ID';

  // const profileSwapDate = getProfileSwapDate(profileHistory);
  // const showProfileSwapCallout = hyperDevice && profileHistory.length > 0 && profileSwapDate;

  // This needs to be completely refactored for the whole SDP page... Or switch back to using bulk actions when those work for devices
  // TODO: Move this up, or further refactor the devices page to handle this for us. Setup a more robust way to get the next state (perhaps by
  // editing how the rest API endpoints return: Maybe they should return the new pending state?)
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const getDevice = useGetDeviceById();
  const onStateChange = useCallback(
    async (newState: 'PAUSE' | 'RESUME') => {
      setLoading(true);

      sendAnalyticsEvent({
        type: newState === 'RESUME' ? RESUME_SIM : PAUSE_SIM,
        data: {
          page: 'Single Device Page',
        },
      });

      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 3000);
      });

      const newDevice = await getDevice(device.id);
      dispatch(setCurrentDevice(newDevice));
      setLoading(false);
    },
    [dispatch, getDevice, device.id]
  );

  return (
    <div className="DeviceHeader">
      <div className="DeviceHeader__toprow">
        <div className="inner-container DeviceHeader__inner-container">
          <div>
            <DeviceNameInput device={device} useBmpStyles={shouldShowDeviceTagDropdown} />
          </div>
          <div>
            <DeviceTags device={device} />
          </div>
        </div>
      </div>

      <div className="DeviceHeader__bottomrow">
        <div className="inner-container DeviceHeader__inner-container">
          <DeviceStatusIndicator device={device} />

          <div className="DeviceHeader__metadata-wrapper">
            <DeviceHyperIndicator device={device} />

            <div className="DeviceHeader__info">
              <div className="DeviceHeader__label">{simLabel}</div>
              <div className="DeviceHeader__value">
                {device.sim_number}
                {hyperDevice && <HyperICCIDTooltip />}
              </div>
            </div>

            <div className="DeviceHeader__info">
              <div className="DeviceHeader__label">{linkIdLabel}</div>
              <div className="DeviceHeader__value">{device.linkId ? device.linkId : '—'}</div>
            </div>

            <div className="DeviceHeader__info">
              <div className="DeviceHeader__label">IMEI</div>
              <div className="DeviceHeader__value">{device.imei ? device.imei : '—'}</div>
            </div>

            <div className="DeviceHeader__info">
              <div className="DeviceHeader__label">Device ID</div>
              <div className="DeviceHeader__value">{device.id}</div>
            </div>
          </div>

          <PauseResumeDevice device={device} onStateChange={onStateChange} loading={loading} />
        </div>

        {/* TODO: Uncomment after we figure out how to only show this for devices that have had a profile swap */}
        {/* {showProfileSwapCallout && (
          <div className="inner-container">
            <Callout
              className="DeviceHeader__callout"
              text={
                <>
                  This device on {profileSwapDate} switched to a new Active profile. Some unique
                  identifiers have changed.{' '}
                  <Link
                    className="DeviceHeader__callout-link"
                    href={buildSDPPath(deviceId, Paths.DEVICE_PROFILES)}
                    onClick={Paths.onInternalLinkClick}
                  >
                    Review Profiles tab for details
                  </Link>
                </>
              }
              type={Callout.TYPE.INFORMATIONAL}
              defaultIcon
              iconColor={Callout.THEME.COLOR}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default DeviceHeader;
