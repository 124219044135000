import strength from 'strength';

const minScore = 2 as const;

const validatePassword = (password) =>
  (/[0-9]/.test(password) &&
    /[a-zA-Z]/i.test(password) &&
    password.length >= 6 &&
    parseInt(strength(password), 10) >= minScore) ||
  'Please enter a valid password.';

export default validatePassword;
