import Message from './message';
import { OUTBOUND } from './types';

export default class OutboundMessage extends Message {
  type = OUTBOUND;

  deviceName = '';

  deviceId = 0;

  data = '';

  triggeredApps = [];

  heartbeatApp = null;

  topicsPublished = [];

  logId = null;

  constructor(log, apps) {
    super(log);

    try {
      this.deviceName = window.devicesCacheById[log.deviceid].name;
    } catch (e) {
      this.deviceName = `Device #${log.deviceid}`;
    }

    this.logId = log.id;
    this.deviceId = log.deviceid;
    this.data = log.data;
    this.triggeredApps = this.findTriggeredApps(log, apps);
    if (log.tags) this.topicsPublished = log.tags;
  }
}
