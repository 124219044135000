import useAppDispatch from 'common-js/hooks/useAppDispatch';
import React from 'react';
import { ModalHeaderWithIcon } from '../components';
import AddCustomInvoiceInfoForm from '../forms/AddCustomInvoiceInfoForm';
import { close as closeModal } from '../reducers/modal/actions';

const AddCustomInvoiceInfoModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="AddCustomInvoiceInfoModal">
      <ModalHeaderWithIcon iconName="Document" title="Custom Invoice Details" />
      <div className="modal-content">
        <p className="section modal-description">
          You can add custom information such as a tax ID, VAT ID, or other company details to all
          Hologram invoices and billing notifications.
        </p>
        <AddCustomInvoiceInfoForm
          closeModal={() => {
            dispatch(closeModal());
          }}
        />
      </div>
    </div>
  );
};

export default AddCustomInvoiceInfoModal;
