import { supported } from '@mapbox/mapbox-gl-supported';
import { ALERT_TYPE_TO_STATE } from 'common-js/reducers/alerts/reducer';
import { getActiveAlertByType } from 'common-js/reducers/alerts/selectors';
import type MapboxGL from 'mapbox-gl';
import type { GeoJSONSource, Map, Marker, Popup } from 'mapbox-gl';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';

class AlertsMap extends Component<any, any> {
  map: Map | null;

  activeMarker: Marker | null;

  activeMarkerPopup: Popup | null;

  mapboxgl: typeof MapboxGL | null;

  popup: any;

  constructor(props) {
    super(props);
    this.state = {
      noDeviceLocation: false,
    };
    this.map = null;
    this.activeMarker = null;
    this.activeMarkerPopup = null;
    this.mapboxgl = null;
  }

  componentDidMount() {
    this.renderMapOnScriptLoad();
  }

  componentDidUpdate(prevProps) {
    // can't set or zoom data if map doesn't exist.
    if (!this.map) {
      return;
    }

    const { activeAlert, activePin, mapData } = this.props;

    const currentFeatures = !!mapData && !!mapData.features ? mapData.features : [];

    const previousFeatures =
      !!prevProps.mapData && !!prevProps.mapData.features ? prevProps.mapData.features : [];

    // if map features changed... set the source data for the map & zoom out.
    if (previousFeatures.length !== currentFeatures.length) {
      const source = this.map.getSource('alerts') as GeoJSONSource;

      // in case the source hasn't been setup...
      if (typeof source !== 'undefined') {
        source.setData(mapData);
        this.boundTo(null, 11, true); // zoom out after setting map data.
      }
    }

    // If just adjusting the active pin...
    else {
      // Zoom in if adding an active pin
      if (activePin && (!prevProps.activePin || prevProps.activePin !== activePin)) {
        this.boundTo(activeAlert, 16, true);
      }

      // Zoom out if removing the active pin
      if (prevProps.activePin && !activePin) {
        this.boundTo(null, 11, true);
      }
    }
  }

  renderMapOnScriptLoad = () => {
    const { mapData } = this.props;

    if (!supported) {
      return;
    }

    import('mapbox-gl').then((mapboxgl) => {
      this.mapboxgl = mapboxgl as any;
      this.renderMap();
    });

    if (mapData && mapData.features) {
      this.renderMap();
    } else {
      setTimeout(() => {
        this.renderMapOnScriptLoad();
      }, 1000);
    }
  };

  renderMap = () => {
    const { mapData, zeroSims } = this.props;

    const container = document.getElementById('alertsmap');
    if (!container || !this.mapboxgl) {
      return;
    }

    try {
      this.map = new this.mapboxgl.Map({
        center: zeroSims ? [11.601447941477318, 44.65877661411943] : [0, 0],
        container,
        style: 'mapbox://styles/hologramengineering/clwuqkc7p00ce01qp0li6dsb4',
        renderWorldCopies: zeroSims,
        zoom: zeroSims ? 1.5 : 11,
        interactive: true,
        accessToken: process.env.VITE_MAPBOX_TOKEN,
      });
      this.map.on('style.load', () => {
        if ('features' in mapData && mapData.features.length) {
          this.loadMapData(mapData);
        }
      });
      this.checkIsMapLoaded();
      // Ignore Mapboxgl errors, as they are likely WebGL init errors that we can do nothing about
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(`Mapbox not available: ${ex}`);
    }
  };

  loadMapData = (mapData) => {
    if (this.popup) {
      this.popup.remove();
    }

    if (!this.map) {
      return;
    }

    this.map.addSource('alerts', {
      type: 'geojson',
      data: mapData,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });

    this.map.addLayer({
      id: 'unclusteredPoint',
      type: 'circle',
      source: 'alerts',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#ffffff',
        'circle-radius': 3,
        'circle-stroke-width': 5,
        'circle-stroke-color': 'rgba(242,69,105,0.9)',
      },
    });

    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'alerts',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          'rgba(242,69,105,0.9)',
          100,
          'rgba(242,69,105,0.9)',
          750,
          'rgba(242,69,105,0.9)',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 12, 10, 16, 100, 20, 1000, 28],
      },
    });

    this.map.addLayer({
      id: 'clusterCount',
      type: 'symbol',
      source: 'alerts',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 14,
        'text-letter-spacing': 0.1,
      },
      paint: {
        'text-color': '#ffffff',
      },
    });
  };

  checkIsMapLoaded = () => {
    if (this.map?.loaded()) {
      this.boundTo();
    } else {
      setTimeout(() => {
        this.checkIsMapLoaded();
      }, 500);
    }
  };

  boundTo(
    pin: {
      latitude?: number;
      longitude?: number;
      whencreated?: any;
      device_name?: string;
    } | null = null,
    zoom = 11,
    animate = false
  ) {
    const { mapData } = this.props;

    if (!this.mapboxgl || !this.map || !('features' in mapData) || !mapData.features.length) {
      return;
    }

    const lngLatBounds = new this.mapboxgl.LngLatBounds();
    const alertsElement = document.getElementById('AlertsTable__wrapper');
    const leftPadding = alertsElement ? alertsElement.getBoundingClientRect().right + 50 : 0;
    // IF THERE IS AN ACTIVE MARKER ON THE MAP ALWAYS RESET EVERYTHING TO DEFAULT BY REMOVING THE ACTIVE MARKER AND POPUP AND SHOWING ALL CLUSTERS AND MARTKERS
    if (this.activeMarker) {
      this.activeMarker.remove();
      this.activeMarkerPopup?.remove();
      this.activeMarker = null;
      this.activeMarkerPopup = null;
      this.map.setLayoutProperty('unclusteredPoint', 'visibility', 'visible');
      this.map.setLayoutProperty('clusters', 'visibility', 'visible');
      this.map.setLayoutProperty('clusterCount', 'visibility', 'visible');
    }
    // IF THERE IS AN ACTIVE ALERT
    if (pin && 'latitude' in pin && 'longitude' in pin && pin.longitude && pin.latitude) {
      // HIDE ALL CLUSTERS AND MARKERS
      this.map.setLayoutProperty('unclusteredPoint', 'visibility', 'none');
      this.map.setLayoutProperty('clusters', 'visibility', 'none');
      this.map.setLayoutProperty('clusterCount', 'visibility', 'none');
      this.setState({ noDeviceLocation: false });

      // EXTEND THE BOUNDS TO THE ACTIVE ALERTS COORDINATES
      const coordinates: [number, number] = [pin.longitude, pin.latitude];
      lngLatBounds.extend(coordinates);

      // CREATE A NEW MARKER FOR THE ACTIVE ALERT
      const markerElement = document.createElement('div');
      markerElement.className = 'AlertsMap__active-marker';
      this.activeMarker = new this.mapboxgl.Marker({
        anchor: 'center',
        element: markerElement,
      })
        .setLngLat(coordinates)
        .addTo(this.map);

      // CREATE A NEW POPUP FOR THE ACTIVE ALERT
      const date = moment(pin.whencreated, 'YYYY-MM-DD HH:mm:ss').format(
        'M/D/YY[ · ]HH:mm A [UTC]'
      );
      this.activeMarkerPopup = new this.mapboxgl.Popup({
        closeButton: false,
        offset: [0, -20],
      })
        .setLngLat(coordinates)
        .setHTML(
          `<div class="AlertsMap__popup">` +
            `<h6 class="AlertsMap__popup__headline">${pin.device_name}</h6>` +
            `<div class="AlertsMap__popup__body">${date}<span>Approx Location</span></div>` +
            `</div>`
        )
        .addTo(this.map);
      // IF THERE IS AN ACTIVE ALERT WITHOUT LOCATION DATA
    } else if (pin && !('latitude' in pin) && !('longitude' in pin)) {
      this.setState({ noDeviceLocation: true });
      mapData.features.forEach((feature) => {
        lngLatBounds.extend(feature.geometry.coordinates);
      });
    }
    // IF THERE ISNT AN ACTIVE ALERT
    else {
      // EXTEND THE BOUNDS TO INCLUDE ALL ALERTS
      this.setState({ noDeviceLocation: false });
      mapData.features.forEach((feature) => {
        lngLatBounds.extend(feature.geometry.coordinates);
      });
    }

    this.map.fitBounds(lngLatBounds, {
      duration: animate ? 500 : 0,
      padding: {
        top: 50,
        bottom: 50,
        left: leftPadding,
        right: 50,
      },
      maxZoom: zoom,
    });
  }

  render() {
    const { alertsMapHeight, mapboxgl } = this.props;
    const { noDeviceLocation } = this.state;

    return (
      <div
        className="AlertsMap AlertsMap--has_submenu AlertsMap--alerts"
        style={{ height: alertsMapHeight }}
      >
        {noDeviceLocation ? (
          <div className="AlertsMap AlertsMap__missing-graphic">
            <svg className="AlertsMap__missing-graphic__svg">
              &gt;
              <use href="#eureka-compass" />
            </svg>
            <p className="AlertsMap__missing-graphic__message">No device location to report</p>
          </div>
        ) : null}

        <div className="AlertsMap__map" id="alertsmap" style={{ height: alertsMapHeight }}>
          {!mapboxgl && 'Loading...'}
        </div>
      </div>
    );
  }
}

const createMapStateToProps = (state, props) => ({
  activePin: state.alerts[ALERT_TYPE_TO_STATE[props.alertType]].activePin,
  activeAlert: getActiveAlertByType(state, props.alertType),
});

const AlertsMapHoC = connect(createMapStateToProps)(AlertsMap);

export default AlertsMapHoC;
