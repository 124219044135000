import { createSelector } from 'reselect';

const selectSlice = createSelector(
  (state) => state.currentDevice,
  (currentDevice) => currentDevice
);

export const selectCurrentDeviceLoading = createSelector(selectSlice, ({ loading }) => loading);
export const selectCurrentDeviceError = createSelector(selectSlice, ({ error }) => error);

export const selectCurrentDevice = createSelector(selectSlice, ({ device }) => device);

export const selectCurrentDeviceEuiccHistory = createSelector(
  selectSlice,
  (state) => state?.euiccHistory ?? []
);

export const selectSessionSample = createSelector(
  selectSlice,
  ({ sessionSample }) => sessionSample
);

// This is an old selector which seems to be used sparingly.  It just returns the first status history entry.
export const selectStatusInfo = createSelector(selectCurrentDevice, (device) =>
  device?.statusHistory?.length ? device.statusHistory[0] : null
);

export const selectLiveUsage = createSelector(selectSlice, ({ liveUsage }) => liveUsage);

export const selectCurrentDeviceTimeFilter = createSelector(
  selectSlice,
  ({ timeFilter }) => timeFilter
);

export const selectCurrentDeviceTimeQuickFilter = createSelector(
  selectSlice,
  ({ timeQuickFilter }) => timeQuickFilter
);

export const selectCurrentDeviceChartFilters = createSelector(
  selectSlice,
  (state, deviceId) => deviceId,
  ({ timeFilter }, deviceId) => {
    const { startDate: timeStart, endDate: timeEnd } = timeFilter;

    return {
      ...(timeStart ? { timestart: parseInt(timeStart / 1000, 10) } : {}),
      ...(timeEnd ? { timeend: parseInt(timeEnd / 1000, 10) } : {}),
      deviceid: deviceId,
    };
  }
);

export const selectCurrentDevicePollingPause = createSelector(
  selectSlice,
  ({ pollingPause }) => pollingPause
);

// TODO: anything below this line is moved over from singleDevice and should be refactored
// DataLimit tab
export const selectDataThreshold = createSelector(
  selectCurrentDevice,
  (device) => device?.data_threshold ?? -1
);

// Hyper tab
export const selectProfileHistory = createSelector(
  selectCurrentDeviceEuiccHistory,
  (euiccHistory = []) => {
    const testRequestIds = euiccHistory
      .filter((item) => item.action === 'EUICC_TEST')
      .map((item) => item.request_id);

    const euiccEnabledOperations = euiccHistory.filter(
      (item) =>
        item.action === 'EUICC_OPERATION' &&
        item.state === 'ENABLED' &&
        !testRequestIds.includes(item.request_id)
    );

    // we always want to include the initial profile history event (with the bootstrap profile) as the last item
    const initialBootstrapEntry = euiccHistory.find(
      ({ id, type }) => id === null && type === 'BOOTSTRAP'
    );

    return [
      ...euiccEnabledOperations,
      // only add the initial bootstrap entry if we can find it in the API response....
      ...(initialBootstrapEntry ? [initialBootstrapEntry] : []),
    ];
  }
);

export const selectProfiles = createSelector(selectSlice, ({ profiles }) => profiles);

export const selectCurrentDeviceEuiccTestHistory = createSelector(
  selectSlice,
  (state) => state?.euiccTestHistory ?? []
);

// DeviceHeader
export const selectUpdatingTags = createSelector(selectSlice, ({ updatingTags }) => updatingTags);
