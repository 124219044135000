import * as API from 'common-js/api';
import { getUserContextData } from 'common-js/api/util';
import * as actionTypes from './actionTypes';

const fetchReportDetails =
  ({ reportId }) =>
  (dispatch, getState) => {
    const state = getState();
    const userContextData = getUserContextData(state);

    dispatch({
      type: actionTypes.FETCH_REPORT_DETAILS_REQUEST,
    });

    return API.getEmbeddedReportInfo({ userContextData, reportId })
      .then((data) => {
        dispatch({
          type: actionTypes.FETCH_REPORT_DETAILS_SUCCESS,
          payload: data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_REPORT_DETAILS_ERROR,
          error,
        });
      });
  };

export default fetchReportDetails;
