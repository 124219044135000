import moment from 'moment';
import {
  TIMEQUICKFILTER_24HOURS,
  TIMEQUICKFILTER_7DAYS,
  TIMEQUICKFILTER_LASTMONTH,
  TIMEQUICKFILTER_THISMONTH,
  TIMEQUICKFILTER_CUSTOM,
} from '../../constants/quickFilters';

export function buildChartFilters(state, storeFiltersKey = 'filters') {
  const stateValues = typeof state === 'function' ? state() : state;

  // tags not affected by storeFiltersKey because it's source is outside the usage reducer.
  const { selectedTags } = stateValues.deviceFilters;

  const filters = {};
  const filterSource = stateValues.usage[storeFiltersKey];

  const timeStart = filterSource.startDate;
  const timeEnd = filterSource.endDate;

  if (selectedTags.length > 0) filters.tagids = selectedTags;
  if (timeStart) filters.timestart = parseInt(timeStart / 1000, 10);
  if (timeEnd) filters.timeend = parseInt(timeEnd / 1000, 10);

  return filters;
}

export function getReportDateTitleString(
  _startDate,
  _endDate,
  timeQuickFilter = TIMEQUICKFILTER_CUSTOM
) {
  const startDate = new Date(_startDate);
  const endDate = new Date(_endDate);

  if (timeQuickFilter === TIMEQUICKFILTER_24HOURS) return 'Last 24 hours';
  if (timeQuickFilter === TIMEQUICKFILTER_7DAYS) return 'Last 7 days';
  if (timeQuickFilter === TIMEQUICKFILTER_THISMONTH) return 'This month';
  if (timeQuickFilter === TIMEQUICKFILTER_LASTMONTH) return 'Last month';

  return `${moment(startDate).utc().format('LL')} - ${moment(endDate).utc().format('LL')}`;
}
