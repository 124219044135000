import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const toggleFilter =
  (filterKey, filterValue, filterComparator = 'EQ') =>
  (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_FILTER,
      filterKey,
      filterValue,
      filterComparator,
    });

    dispatch({
      type: actionTypes.RESET_PAGING,
    });

    dispatch(fetchDevices());
  };
