import React from 'react';
import { Callout } from '@holokit/core';

function SimError({ error, errorList }) {
  return (
    <div className="SimPanel__errors">
      <Callout
        type={Callout.TYPE.ERROR}
        text={error || <>{errorList}</>}
        defaultIcon
        iconColor={Callout.THEME.COLOR}
      />
    </div>
  );
}

export default SimError;
