import {
  EmptyState,
  Field,
  Table,
  TableTypes,
  getUserFriendlyBytes,
} from '@hologram-hyper-dashboard/components';
import { useAppSelector } from 'common-js/hooks';
import {
  selectAllPlans,
  selectCurrentPool,
  selectDevicePlanSummary,
} from 'common-js/reducers/activation/selectors';
import { DateTime } from 'luxon';
import { FC, ReactNode, useMemo } from 'react';
import Bytes from './Bytes';
import Money from './Money';

export const ActivePlanList: FC = () => {
  const plans: ReturnType<typeof selectAllPlans> = useAppSelector(selectAllPlans);
  const devicePlanSummary: ReturnType<typeof selectDevicePlanSummary> =
    useAppSelector(selectDevicePlanSummary);
  const currentPool: ReturnType<typeof selectCurrentPool> = useAppSelector(selectCurrentPool);

  const headers: Array<TableTypes.HeaderItem> = useMemo(
    () => [
      { children: 'Data plan name', key: 'planName' },
      { children: 'Per SIM', className: 'ActivePlanList__numeric', key: 'perSim' },
      { children: 'Included data', className: 'ActivePlanList__numeric', key: 'includedData' },
      { children: 'Per MB', className: 'ActivePlanList__numeric', key: 'perMB' },
      { children: 'Active SIMs', className: 'ActivePlanList__numeric', key: 'activeSIMs' },
    ],
    []
  );

  const devicePlanSummaries = useMemo(
    () =>
      devicePlanSummary
        ?.map((summary) => {
          const plansById = plans.filter((searchPlan) => searchPlan.id === summary.planid);
          const plan = plansById.find((searchPlan) => searchPlan.zone === summary.zone);

          return { ...summary, plan, hasMultipleZones: plansById.length > 1 };
        })
        .sort((a, b) => {
          const aIsCustom = a.plan?.displayCategory === 'custom';
          const bIsCustom = b.plan?.displayCategory === 'custom';

          if (aIsCustom === bIsCustom) {
            if (a.planid === b.planid) {
              return a.zone.localeCompare(b.zone);
            }
            return a.planid < b.planid ? 1 : -1;
          }

          return aIsCustom ? -1 : 1;
        }),
    [devicePlanSummary, plans]
  );

  const rows: Array<TableTypes.TableRowItem> | undefined = useMemo(
    () =>
      devicePlanSummaries?.sort().map((summary) => {
        const { plan } = summary;

        if (!plan) {
          return { key: `emptyPlan-${summary.planid}` };
        }

        const hasMultipleTiers = Object.keys(plan.tiers ?? {}).length > 1;

        const perDevice = plan.perLineCharge;
        const addlData = plan.overageCharge;
        const dataIncluded = plan.dataLimit;

        const subtext: Array<ReactNode> = [];

        if (currentPool && currentPool.dataplansubscriptionid === summary.planid) {
          subtext.push(
            <>
              {currentPool.plan.name}
              {currentPool.lastbilled
                ? ` last renewed on ${DateTime.fromJSDate(new Date(currentPool.lastbilled)).toFormat('LLL dd, yyyy')}`
                : ''}{' '}
              for <Money amount={currentPool.plan.amount} showLongFloats />
            </>
          );
        }

        const subtextPlanLine: Array<ReactNode> = [];

        if (dataIncluded > 0) {
          subtextPlanLine.push(`Includes ${getUserFriendlyBytes(plan.dataLimit)}`);
        }

        if (summary.hasMultipleZones) {
          subtextPlanLine.push(`${plan.zone} zone`);
        }

        if (hasMultipleTiers) {
          subtextPlanLine.push('Includes volume discounting');
        }

        subtext.push(subtextPlanLine.join(', '));

        const cells: Array<TableTypes.TableCellItem> = [
          {
            key: `planId-${plan.id}`,
            children:
              subtext.length > 0 ? (
                <Field
                  direction="column"
                  className="ActivePlanList__plan"
                  labelClassName="ActivePlanList__planName"
                  valueClassname="ActivePlanList__subText"
                  label={plan.planName ?? ''}
                  value={subtext.map((text) => (
                    <div>{text}</div>
                  ))}
                />
              ) : (
                <div className="ActivePlanList__planName">{plan.planName}</div>
              ),
          },
          {
            key: `perDevice-${perDevice}`,
            className: 'ActivePlanList__numeric',
            children: perDevice ? <Money amount={perDevice} showLongFloats /> : <EmptyState />,
          },
          {
            key: `dataIncluded-${dataIncluded}`,
            className: 'ActivePlanList__numeric',
            children: dataIncluded ? (
              <Bytes amount={dataIncluded} />
            ) : (
              <div className="ActivePlanList__payAsYouGo">Pay as you go</div>
            ),
          },
          {
            key: `addlData-${addlData}`,
            className: 'ActivePlanList__numeric',
            children: addlData ? [<Money amount={addlData} key={0} showLongFloats />] : '',
          },
          {
            key: `summaryCount-${summary.count}`,
            className: 'ActivePlanList__numeric ActivePlanList__count',
            children: summary.count,
          },
        ];

        return { key: `${plan.id}-${plan.zone}`, cells };
      }),
    [currentPool, devicePlanSummaries]
  );

  return (
    <Table
      headers={headers}
      rows={rows}
      theadClassName="ActivePlanList__tableHeader"
      tbodyClassName="ActivePlanList__table"
      wrapperClassName="ActivePlanList__tableWrapper"
    />
  );
};

export default ActivePlanList;
