import { Icon } from '@holokit/core';
import _classnames from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalActions from '../reducers/modal/actions';

function ModalHeaderWithIcon({ classes, closeModal, iconName, title, color = '#8008F7' }) {
  return (
    <div
      className={_classnames('ModalHeader ModalHeaderWithIcon', classes, {
        'no-title': !title,
      })}
    >
      {iconName && (
        <div className="title-icon">
          <Icon name={iconName} size="major" svgProps={{ style: { fill: color } }} />
        </div>
      )}
      {title && <div className="f2 title">{title}</div>}
      <div
        className="close-button"
        onClick={closeModal}
        onKeyUp={closeModal}
        role="button"
        tabIndex={0}
      >
        <Icon name="Close" size="minor" svgProps={{ style: { fill: '#606677' } }} />
      </div>
    </div>
  );
}

ModalHeaderWithIcon.propTypes = {
  title: PropTypes.string,
};

ModalHeaderWithIcon.defaultProps = {};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      closeModal: modalActions.close,
    },
    dispatch
  )
)(ModalHeaderWithIcon);
