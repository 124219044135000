import { Icon } from '@holokit/core';
import LiveUsageChartD3 from './LiveUsageChartD3';

function LiveUsageChart(props) {
  const { loading = false, error = null, data = [] } = props;

  return (
    <div className="LiveUsageChart">
      {error ? (
        <div className="UsageTable-nodata">
          <Icon
            classes="UsageTable-nodata-icon"
            name="Circle--info"
            size="major"
            svgProps={{ style: { fill: '#8008f7' } }}
          />
          <div className="UsageTable-nodata-message">
            We had a problem loading data usage for these devices.
          </div>
        </div>
      ) : (
        <div className="grid-row">
          <div className={`loading-overlay barchart${loading ? ' active' : ''}`}>
            {loading && 'Loading...'}
          </div>
          {!loading ? <LiveUsageChartD3 data={data} /> : <div style={{ height: '200px' }} />}
        </div>
      )}
    </div>
  );
}

export default LiveUsageChart;
