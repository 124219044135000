import { Button } from '@hologram-dimension/button';
import { FC, useCallback } from 'react';
import ReportsError from '../ReportsError';

const ReportError: FC = () => {
  const handleClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ReportsError
      caption="Unable to load report"
      message="Please check your connection and try again."
      button={
        <Button variant="secondary" size="small" onClick={handleClick}>
          Reload Page
        </Button>
      }
    />
  );
};

export default ReportError;
