import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectLiveUsage } from 'common-js/reducers/currentDevice/selectors';
import NetworkConnectionsChartD3 from './NetworkConnectionsChartD3';

const NetworkConnectionsChart = () => {
  const { data, loading } = useAppSelector(selectLiveUsage); // do we need an error state for this?

  return (
    <div className="NetworkConnectionsChart">
      <div className="grid-row">
        {loading && (
          <div className="loading-overlay">
            <div className="DimensionLoader__legacy-padding-override">Loading...</div>
          </div>
        )}

        {!loading ? <NetworkConnectionsChartD3 data={data} /> : <div style={{ height: '200px' }} />}
      </div>
    </div>
  );
};

export default NetworkConnectionsChart;
