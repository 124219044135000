import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';
import { WebhookModel } from 'common-js/models';

const useUpdateWebhook = () => {
  const apiCall = useApi.call('/devices/:deviceId/webhook', { method: 'PUT' });

  return useCallback(
    async (deviceId, port, protocol) => {
      const { data, error } = await apiCall({
        urlParams: { deviceId },
        body: {
          port,
          protocol,
        },
      });
      if (error) throw new Error(error);
      return new WebhookModel(data);
    },
    [apiCall]
  );
};

export default useUpdateWebhook;
