import React from 'react';
import { DeepMenu, DeepMenuItem } from '@holokit/core';
import * as Paths from '../../common/constants/paths';

function UsageModeSelector(props) {
  const { mode = 'overview' } = props;

  return (
    <DeepMenu className="UsageModeSelector">
      <DeepMenuItem
        href={Paths.withContext(Paths.USAGE_OVERVIEW)}
        onClick={Paths.onInternalLinkClick}
        active={mode === 'overview'}
        text="Overview"
      />
      <DeepMenuItem
        href={Paths.withContext(Paths.USAGE_BREAKDOWN)}
        onClick={Paths.onInternalLinkClick}
        active={mode === 'breakdown'}
        text="Device Breakdown"
      />
    </DeepMenu>
  );
}

export default UsageModeSelector;
