import { convertApiPlanToReduxPlan } from '@hologram-hyper-dashboard/components';
import { GET_ALL_SUCCESS } from 'common-js/reducers/organization/actionTypes';
import {
  CLEAR_MFA,
  CLEAR_MFA_ERROR,
  CONFIGURE_ERROR,
  CONFIGURE_SUCCESS,
  FINISH_MFA_FLOW,
  GENERATE_KEY_SUCCESS,
  GET_APIKEY_SUCCESS,
  GET_ORDERS_SUCCESS,
  GET_PENDING_ORGS_SUCCESS,
  GET_PLANS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  GET_USER_INFO_SUCCESS,
  HANDLE_MFA_ERROR,
  INIT_MFA_FLOW,
  LOGIN_CLEAR_LOADER,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  SET_DISMISSED_ALERTS,
  SET_SEEN_FLEET_HOME,
  SET_SHOW_ALL_DEVICES,
  SET_USER_INFO,
  SET_USER_SETTING,
  START_MFA_AUTHENTICATION,
  UNMOUNT_ORDERS,
  UPDATE_SETTINGS_ERROR,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
} from './actionTypes';

export const initialState = {
  apiKey: '',
  email: null,
  firstName: null,
  isVerified: false,
  isViewingAllBalanceHistory: false,
  lastName: null,
  loading: false,
  loggingOut: false,
  orders: [],
  orgId: null,
  pendingOrgs: [],
  plans: [],
  registered: new Date(),
  role: null,
  settings: {
    'Default Org': null,
    'Pre Edition': null,
    'Accepted TOS EULA': 'no',
    onboarding_budget: null,
    onboarding_devices: null,
    onboarding_how_far_along: null,
    onboarding_what_kind_of_project: null,
    modal_apn_seen: 'no',
    modal_apn_show: 'yes',
    menu_collapsed: 'no',
    edu_home_dlimit_show: 'yes',
    edu_home_tacchange_show: 'yes',
    edu_home_usage_show: 'yes',
    edu_sdp_dlimit_big_show: 'yes',
    edu_sdp_dlimit_small_show: 'no',
  },
  showAllDevices: false,
  userId: null,
  uiState: {
    hasSeenFleetHome: false,
    dismissedAlerts: [],
  },
  registrationResponse: null,
  registrationError: null,
  configureAccountError: null,
  mfa: {
    showModal: false,
    error: undefined,
  },
};

function accountReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        userId: action.userId,
        role: action.role,
        orgId: action.orgId ? action.orgId : state.orgId,
        registered: action.registered,
        isVerified: action.isVerified,
        impersonator: action.impersonator,
      };

    case GET_USER_INFO_SUCCESS:
      return { ...state, ...action.userInfo };

    case SET_SHOW_ALL_DEVICES:
      return { ...state, showAllDevices: action.showAllDevices };

    case GET_ALL_SUCCESS: {
      const personalOrg = action.orgs.filter((org) => !!org.is_personal)[0];
      return { ...state, orgId: personalOrg.id };
    }

    case GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: convertApiPlanToReduxPlan(action.plans),
      };

    case GET_ORDERS_SUCCESS:
      return { ...state, orders: action.orders };

    case GET_APIKEY_SUCCESS:
    case GENERATE_KEY_SUCCESS:
      return { ...state, apiKey: action.apiKey };

    case GET_PENDING_ORGS_SUCCESS:
      return { ...state, pendingOrgs: action.pendingOrgs };

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...action.settings,
        },
      };

    case UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings,
        },
      };

    case SET_USER_SETTING: {
      const updatedSettings = action.keyValues.reduce(
        (acc, userSetting) => ({
          ...acc,
          [userSetting.key]: userSetting.value,
        }),
        {}
      );

      return {
        ...state,
        settings: {
          ...state.settings,
          ...updatedSettings,
        },
      };
    }

    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: { ...action.settings },
      };

    case UPDATE_SETTINGS_ERROR:
      return {
        ...state,
        settings: { ...action.settings },
      };

    case LOGOUT_REQUEST:
      return { ...state, loggingOut: true };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
        loading: state.loading,
        uiState: { ...state.uiState },
      };

    case LOGIN_ERROR:
      return { ...state, ...initialState };

    case LOGIN_REQUEST:
      return {
        ...state,
        ...initialState,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return { ...state, loading: true };

    case LOGIN_CLEAR_LOADER:
      return { ...state, loading: false };

    case UNMOUNT_ORDERS:
      return { ...state, orders: [] };

    case SET_SEEN_FLEET_HOME:
      return {
        ...state,
        uiState: {
          ...state.uiState,
          hasSeenFleetHome: action.hasBeenSeen,
        },
      };

    case SET_DISMISSED_ALERTS: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          dismissedAlerts: action.dismissedAlerts,
        },
      };
    }

    case REGISTER_REQUEST:
      return {
        ...state,
        registrationLoading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registrationResponse: action.payload.response,
        registrationError: null,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        registrationError: action.payload.error,
      };
    case CONFIGURE_SUCCESS: {
      return {
        ...state,
        registrationResponse: null,
        registrationError: null,
        configureAccountError: null,
      };
    }
    case CONFIGURE_ERROR: {
      return {
        ...state,
        registrationResponse: null,
        registrationError: null,
        configureAccountError: action.payload.error,
      };
    }

    case INIT_MFA_FLOW: {
      return {
        ...state,
        mfa: {
          method: action.method,
          showModal: true,
          mfaRequired: true,
        },
      };
    }

    case HANDLE_MFA_ERROR: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          isLoading: false,
          error: action.error,
        },
      };
    }

    case START_MFA_AUTHENTICATION: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          isLoading: true,
        },
      };
    }

    case CLEAR_MFA_ERROR: {
      const { error, ...rest } = state.mfa;
      return {
        ...state,
        mfa: rest,
      };
    }

    case CLEAR_MFA: {
      return {
        ...state,
        loading: false,
        mfa: {},
      };
    }

    case FINISH_MFA_FLOW: {
      return {
        ...state,
        mfa: { mfaRequired: true },
      };
    }

    default:
      return state;
  }
}

export default accountReducer;
