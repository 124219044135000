import { API_URL } from 'common-js/api/config';
import { getCurrentOrgId } from 'common-js/api/util';
import { DEVICES_DEVICES, HOME_OVERVIEW, withContext } from 'common-js/constants/paths';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

/*
This component handles forwarding the user to an API URl to download a document.
This component requires authentication, so the proper way to use it is to provide
a `redirectto` parameter. The `redirectto` parameter is used in our login flow.
If the user is not logged in and attempts to download a document, they will be sent
into the login flow, which will redirect them here once they are authenticated.
The URL below is an example of the proper format:

http://www-dashboard.teamhologram.ninja:8080/org/3981/documents/235201/download?redirectto=%2Fdocuments%2F235201%2Fdownload
*/

// TODO: This appears deprecated in favor of `attemptDocumentDownload` which serves the same purpose
class Download extends React.Component<any, any> {
  componentDidMount() {
    const { orgId, releaseFlags, routeParams } = this.props;

    if (orgId && routeParams.documentId) {
      const link = document.createElement('a');

      link.style.display = 'none';
      link.href = `${API_URL}/documents/${routeParams.documentId}/download`;
      link.target = '_blank';

      // allow the redirection to a new page and then kick off the downloading.
      // this allows all of the page load logic to happen, and then for us to
      // go into the loading sequence.
      setTimeout(() => {
        document.body.appendChild(link);
        link.click();
        link.remove();
      }, 1500);
    }

    const hasFleet = releaseFlags.fleet;
    const futurePath = hasFleet ? HOME_OVERVIEW : DEVICES_DEVICES;
    browserHistory.push(withContext(futurePath, orgId));
  }

  render() {
    return null;
  }
}

const DownloadHoC = connect((state: any) => ({
  orgId: getCurrentOrgId(state),
  releaseFlags: state.releaseFlag,
}))(Download);

export default DownloadHoC;
