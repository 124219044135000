import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const resetQueryAndSorting = () => (dispatch) => {
  dispatch({ type: actionTypes.RESET_QUERY });
  dispatch({ type: actionTypes.RESET_SORTING });
  dispatch({ type: actionTypes.RESET_PAGING });

  return dispatch(fetchDevices());
};
