import { InlineNotification } from '@hologram-dimension/inline-notification';
import { Chip } from '@hologram-dimension/chip';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import classNames from 'clsx';
import Section from './Section';

interface LinksSectionProps {
  title: string;
  warning?: string | boolean;
  devices?: Array<string | number>;
  total: number;
  limit: number;
}

const LinksSection = ({ title, warning, devices, total, limit }: LinksSectionProps) => {
  const hasWarningStyle = warning;
  const warningMessage = typeof warning === 'string' ? warning : '';

  return (
    <Section title={title}>
      {hasWarningStyle && warningMessage && (
        <InlineNotification variant="caution">{warningMessage}</InlineNotification>
      )}

      <div className="BulkDataActionModal__links">
        {devices?.slice(0, Math.min(limit, total)).map((device, idx) => (
          <Chip
            // Have to use IDX regardless of performance, since for the duration of the parent compnoent this will be unchanged anyway
            // And we only have the name, which isn't always unique
            // eslint-disable-next-line react/no-array-index-key
            key={`${device}-${idx}`}
            // type={hasWarningStyle ? 'warning' : 'default'}
            className={classNames(
              'BulkDataActionModal__item',
              hasWarningStyle && 'BulkDataActionModal__item--warning'
            )}
            size="medium"
            colorVariant={hasWarningStyle ? 'yellow' : 'default'}
          >
            {`${device}`}
          </Chip>
        ))}
        {total > limit && (
          <span className="BulkDataActionModal__links__more">
            + {addCommasToNumber(total - limit, false)} more
          </span>
        )}
      </div>
    </Section>
  );
};

export default LinksSection;
