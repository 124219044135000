import React from 'react';
import { ModalHeader } from '../components';
import OrgInviteCollaboratorsForm from '../forms/org/OrgInviteCollaboratorsForm';

function InviteCollaboratorsModal() {
  return (
    <div className="InviteCollaboratorsModal__wrapper">
      <ModalHeader title="Invite collaborators" />
      <OrgInviteCollaboratorsForm />
    </div>
  );
}

export default InviteCollaboratorsModal;
