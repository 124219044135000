import { datadogRum } from '@datadog/browser-rum';
import { UserInfo } from 'common-js/types/User';
import {
  getCookieScriptAcceptanceValue,
  setCookieScriptAcceptanceCallback,
} from 'common-js/utils/cookies/cookieScript';
import getIsLegacyChunk from 'common-js/utils/getIsLegacyChunk';

export const dataDogInit = () => {
  const dataDogEnv = process.env.VITE_ENV_NAME ?? 'staging';
  const hasAcceptedFunctionalityCookies = getCookieScriptAcceptanceValue('functionality');

  /* eslint-disable no-console */
  if (!window.dataDogHasBeenInit) {
    window.dataDogHasBeenInit = true;

    try {
      const commitTag = process.env.VITE_CI_COMMIT_TAG ?? 'develop';
      const commitSha = process.env.VITE_CI_COMMIT_SHORT_SHA ?? 'develop';
      let version = dataDogEnv === 'production' ? commitTag : commitSha;

      if (!version || version.length === 0) {
        version = 'development';
      }

      const initParams: Parameters<typeof datadogRum.init>[0] = {
        applicationId: 'd9486d55-5cd7-4456-af63-67744c509ea0',
        clientToken: 'pub33c2176c2bb3eddf59b4bbccc7ecba1c',
        site: 'datadoghq.com',
        service: 'dashboard',
        env: dataDogEnv,
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        startSessionReplayRecordingManually: true,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      };
      if (dataDogEnv !== 'production') {
        console.log('Initializing DataDog...');
        console.table({ dataDogEnv, commitTag, commitSha });
        console.table(initParams);
      }
      datadogRum.init(initParams);

      // Conditionally start session recording if consent has been given
      if (hasAcceptedFunctionalityCookies) {
        datadogRum.startSessionReplayRecording();
      } else {
        // Only start recording if/when a user accepts cookies
        setCookieScriptAcceptanceCallback(() => {
          datadogRum.startSessionReplayRecording();
        }, 'functionality');
      }
    } catch (err) {
      console.log('Failed to start DataDog service');
      console.error(err);
    }
  }

  /* eslint-enable no-console */
};

export const setDataDogUserConfiguration = (userInfo: UserInfo, orgContext = null) => {
  if (!window.dataDogHasBeenInit) {
    return;
  }

  const isImpersonating = (userInfo.impersonator?.email?.length ?? 0) > 0;

  datadogRum.setUser({
    id: (isImpersonating ? userInfo.impersonator.userId : userInfo.userId)?.toString(),
    email: (isImpersonating ? userInfo.impersonator.email : userInfo.email) ?? undefined,
    orgContext,
    impersonatingId: isImpersonating ? userInfo.userId : undefined,
    impersonatingEmail: isImpersonating ? userInfo.email : undefined,
    isLegacy: getIsLegacyChunk,
  });
};

export const sendDatadogError = (...args: Parameters<typeof datadogRum.addError>) => {
  if (!window.dataDogHasBeenInit) {
    return;
  }

  datadogRum.addError(...args);
};

export const sendDatadogAction = (...args: Parameters<typeof datadogRum.addAction>) => {
  if (!window.dataDogHasBeenInit) {
    return;
  }

  datadogRum.addAction(...args);
};
