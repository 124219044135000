import { Loader } from '@hologram-dimension/loader';
import { Icon } from '@holokit/core';
import { toByteString } from 'common-js/utils/numberFormatter';
import Moment from 'moment-timezone';
import React from 'react';

const addRowDate = (row) => {
  const isLongTerm = row.hour === 'undefined';

  return {
    mDate: isLongTerm
      ? Moment.utc(row.date, 'YYYY-MM-DD')
      : Moment.utc(`${row.date}-${row.hour}`, 'YYYY-MM-DD-H'),
    isLongTerm,
    ...row,
  };
};

const sortByDate = (rowA, rowB) => (rowA.mDate.unix() < rowB.mDate.unix() ? 1 : -1);

const DeviceBreakdownTableRow = ({ row }) => (
  <tr className="body-data">
    <td className="left-padded align-top">
      <div>{row.mDate.format('MMMM D, YYYY')}</div>
      {!row.isLongTerm && <div className="hour">{`${row.mDate.format('h:ssA')} UTC`}</div>}
    </td>
    <td>{toByteString(row.total_bytes / row.sessions)}</td>
    <td>{toByteString(row.total_bytes)}</td>
    <td>{row.sessions}</td>
    <td>{row.sms}</td>
  </tr>
);

const NoData = () => (
  <div className="UsageTable-nodata">
    <Icon
      classes="UsageTable-nodata-icon"
      name="Circle--info"
      size="major"
      svgProps={{ style: { fill: '#8008f7' } }}
    />
    <div className="UsageTable-nodata-message">
      There are no data sessions to display for this set of devices and specific time frame. Try
      adjusting tags or the selected date range.
    </div>
  </div>
);

const DeviceBreakdownTableInspect = ({ data = [], loading }) => {
  if (loading) {
    return (
      <div className="UsageTable DeviceBreakdownTable">
        <div className="table-loading">
          <Loader className="DimensionLoader__legacy-padding-override" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="UsageTable DeviceBreakdownTable">
        <NoData />
      </div>
    );
  }

  return (
    <div className="UsageTable DeviceBreakdownTable">
      <div>
        <table className="UsageTable">
          <thead>
            <tr className="header">
              <th className="left-padded" style={{ width: '22%' }}>
                date
              </th>
              <th style={{ width: '12%' }}>avg. daily usage</th>
              <th style={{ width: '12%' }}>total used</th>
              <th style={{ width: '8%' }}>sessions</th>
              <th style={{ width: '8%' }}>sms</th>
            </tr>
          </thead>
          <tbody>
            {data
              .map(addRowDate)
              .sort(sortByDate)
              .map((row, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <DeviceBreakdownTableRow row={row} key={`${row.mDate}-${index}`} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeviceBreakdownTableInspect;
