import { createSelector } from 'reselect';
import * as DeviceStates from 'common-js/constants/deviceStates';
import selectDeviceMetaInfo from './selectDeviceMetaInfo';

const BLUE = 'blue';
const LIGHTBLUE = 'light-blue';
const GRAY = 'gray';
const RED = 'red';
const PURPLE = 'purple';
const LIGHTPURPLE = 'light-purple';
const GREEN = 'green';
const LIGHTGREEN = 'light-green';
const ORANGE = 'orange';
const MIDNIGHT = 'midnight';

const allLinesSameColor = (color) => [color, color, color];

// maps device state to either an array of colors or a function that returns an array of colors
const deviceStateToLineColors = {
  [DeviceStates.LIVE_PENDING]: allLinesSameColor(ORANGE),
  [DeviceStates.LIVE]: ({ hasSession, onlyEmptySessions, lastSession30DaysOld }) => [
    hasSession ? GREEN : LIGHTGREEN,
    hasSession ? GREEN : LIGHTGREEN,
    onlyEmptySessions || lastSession30DaysOld ? LIGHTGREEN : GREEN,
  ],
  [DeviceStates.INACTIVE]: allLinesSameColor(GRAY),
  [DeviceStates.PAUSED_USER]: allLinesSameColor(RED),
  [DeviceStates.PAUSED_PENDING_USER]: allLinesSameColor(ORANGE),
  [DeviceStates.PAUSED_PENDING_SYS]: allLinesSameColor(ORANGE),
  [DeviceStates.PAUSED_SYS]: allLinesSameColor(RED),
  [DeviceStates.TEST_HIBERNATE_PENDING]: ({ hasSession, onlyEmptySessions }) => [
    onlyEmptySessions ? LIGHTPURPLE : PURPLE,
    onlyEmptySessions ? LIGHTPURPLE : PURPLE,
    hasSession && !onlyEmptySessions ? PURPLE : LIGHTPURPLE,
  ],
  [DeviceStates.TEST_HIBERNATE]: ({ hasSession, onlyEmptySessions }) => [
    onlyEmptySessions ? LIGHTPURPLE : PURPLE,
    onlyEmptySessions ? LIGHTPURPLE : PURPLE,
    hasSession && !onlyEmptySessions ? PURPLE : LIGHTPURPLE,
  ],
  [DeviceStates.TEST_AUTOACTIVATE_PENDING]: allLinesSameColor(LIGHTPURPLE),
  [DeviceStates.TEST_AUTOACTIVATE]: allLinesSameColor(LIGHTPURPLE),
  [DeviceStates.TEST_ACTIVATE_PENDING]: ({ hasSession, onlyEmptySessions, hasEmptySession }) =>
    allLinesSameColor(hasSession && !onlyEmptySessions && !hasEmptySession ? PURPLE : LIGHTPURPLE),
  [DeviceStates.TEST_ACTIVATE]: ({ hasSession, onlyEmptySessions, hasEmptySession }) =>
    allLinesSameColor(hasSession && !onlyEmptySessions && !hasEmptySession ? PURPLE : LIGHTPURPLE),
  [DeviceStates.INACTIVE_TESTED]: allLinesSameColor(GRAY),
  [DeviceStates.DEAD_PENDING]: [RED, MIDNIGHT, MIDNIGHT],
  [DeviceStates.DEAD]: [RED, MIDNIGHT, MIDNIGHT],
};

const getLineColors = (deviceMetaInfo) => {
  const { deviceState, lastSessionIsActive } = deviceMetaInfo;
  if (lastSessionIsActive) {
    // if there's an active session, we show blue lines, but the last line is light blue if no data has been sent yet
    return [BLUE, BLUE, deviceMetaInfo.onlyEmptySessions ? LIGHTBLUE : BLUE];
  }
  // By default, show all orange lines
  const fn = deviceStateToLineColors[deviceState] ?? allLinesSameColor(ORANGE);
  return typeof fn === 'function' ? fn(deviceMetaInfo) : fn;
};

// Since all of the information we need to determine the colors of the device status lights is entirely derived from
// the device state in redux, let's just encapsulate this logic in a selector function.
// As a bonus, now the derived state is memoized for better performance.
const selectLineColors = createSelector(selectDeviceMetaInfo, getLineColors);

export default selectLineColors;
