import { type SimInventoryCommonTypes } from '@hologram-hyper-dashboard/components';
import { ACTIVATE_INITIATE, BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import sendAnalyticsEvent from 'common-js/analytics/analytics';
import { ACTIVATION } from 'common-js/constants/paths';
import { drawerTakerOverLink } from 'common-js/reducers/drawer/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import {
  getBalance,
  getDeviceCount,
  getUserPermissions,
} from 'common-js/reducers/organization/selectors';
import { BILLING } from 'common-js/utils/allPermissions';
import { useCallback } from 'react';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import useCanActivate from './useCanActivate';

interface UseCanActivateCallbackProps {
  deviceType?: SimInventoryCommonTypes.SimInventorySegmentName;
}

const useActivateCallback = ({ deviceType = 'active' }: UseCanActivateCallbackProps) => {
  const { canActivate, errorMessage, hide } = useCanActivate({ deviceType });

  const dispatch = useAppDispatch();
  const isVerified = useAppSelector((state: any) => state.account.isVerified);

  const balance = useAppSelector(getBalance);
  const hasPostpay = balance?.hasPostpay;
  const userPermissions = useAppSelector(getUserPermissions);
  const hasBilling = userPermissions.includes(BILLING);
  const orgDeviceCount = useAppSelector(getDeviceCount);

  const onActivate = useCallback(() => {
    if (!canActivate) {
      return;
    }

    sendAnalyticsEvent({ type: ACTIVATE_INITIATE });

    const isOnboarding = !orgDeviceCount || orgDeviceCount === 0;

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: [
          'Activation',
          isOnboarding ? '1st Time' : 'Returning',
          'SIM Activation Initiated',
        ].join(' - '),
        billing: hasBilling,
        postpay: hasPostpay,
      },
    });

    if (isVerified) {
      dispatch(drawerTakerOverLink(ACTIVATION));
    } else {
      dispatch(openModal('ActivateResendEmailVerifyModal'));
    }
  }, [canActivate, dispatch, hasBilling, hasPostpay, isVerified, orgDeviceCount]);

  return { canActivate, errorMessage, hide, onActivate };
};

export default useActivateCallback;
