import { SubMenu as AdminSubMenu } from 'admin/components';
import { FullHeightCalculatorHOC } from 'common-js/components';
import React from 'react';
import BaseLayout from './BaseLayout';

interface LayoutSettingsProps {
  children?: React.ReactNode;
}

const LayoutSettings: React.FC<LayoutSettingsProps> = ({ children }) => (
  <BaseLayout>
    <AdminSubMenu />
    <FullHeightCalculatorHOC accountForDrawer>
      <div className="container">
        <FullHeightCalculatorHOC classNames="fullHeight-admin" accountForDrawer relative>
          <div className="billing-container">{children}</div>
        </FullHeightCalculatorHOC>
      </div>
    </FullHeightCalculatorHOC>
  </BaseLayout>
);

export default LayoutSettings;
