import React from 'react';
import { Button } from '@hologram-dimension/button';
import classNames from 'clsx';

interface BackButtonProps {
  onClick?: GetReactParameterBag<typeof Button>['onClick'];
}
export function BackButton({ onClick }: BackButtonProps) {
  return (
    <Button variant="secondary" className="ActivationStep__footer__back-button" onClick={onClick}>
      Back
    </Button>
  );
}

interface ContinueButtonProps {
  onClick?: GetReactParameterBag<typeof Button>['onClick'];
  disabled?: boolean;
  text?: string;
}

export function ContinueButton({ onClick, disabled, text }: ContinueButtonProps) {
  return (
    <Button variant="primary" disabled={disabled} onClick={onClick}>
      {text ?? 'Continue'}
    </Button>
  );
}

interface SkipButtonProps {
  onClick?: GetReactParameterBag<typeof Button>['onClick'];
}
export function SkipButton({ onClick }: SkipButtonProps) {
  return (
    <Button variant="secondary" onClick={onClick}>
      Skip this step
    </Button>
  );
}

interface StatusTextProps {
  children?: React.ReactNode;
  error?: boolean;
}
export function StatusText({ children, error }: StatusTextProps) {
  return (
    <span
      className={classNames(
        'ActivationStep__footer__status-text',
        error && 'ActivationStep__footer__status-text--error'
      )}
    >
      {children}
    </span>
  );
}

interface FooterProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}
function Footer({ left, right }: FooterProps) {
  return (
    <footer className="ActivationStep__footer">
      <nav className="ActivationStep__footer__left">{left}</nav>
      <nav className="ActivationStep__footer__right">{right}</nav>
    </footer>
  );
}

export default Footer;
