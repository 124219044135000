import React from 'react';
import { useFormContext } from 'react-hook-form';
import submitDashboardMarketoForm, { sendGaEvent } from 'common-js/utils/marketing';
import { IconCard } from '../../components';

function Step1(props) {
  const { handleAnalyticsCall, configureAccount, nextStep, submitRegistrationForm } = props;
  const { setValue, getValues } = useFormContext();

  const handleClick = (purpose) => async () => {
    setValue('purpose', purpose);
    handleAnalyticsCall('purpose', purpose);

    // send GA event
    const gaEventName =
      purpose === 'personal' ? 'dashboardSignupPersonal' : 'dashboardSignupCompany';
    sendGaEvent(gaEventName);

    await configureAccount();
    if (purpose === 'personal') {
      // Personal users don't go through the rest of the steps.  We create their account and send them to dashboard.
      submitRegistrationForm();
    } else {
      submitDashboardMarketoForm(getValues());
      nextStep();
    }
  };

  return (
    <div className="Step NewForm">
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__Header">
            <h2>Let&apos;s take a moment to setup your account</h2>
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__SubHeader">Who is this account for?</div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q1_personal.svg"
            onClick={handleClick('personal')}
            mainText="Personal"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q1_business.svg"
            onClick={handleClick('company')}
            mainText="Business"
          />
        </div>
      </div>
    </div>
  );
}

export default Step1;
