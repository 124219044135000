import { createSelector } from 'reselect';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';

const getOptionsFromData = (device) => [
  { value: '-1', label: 'Unlimited data' },
  ...(device.data !== 0 ? [{ value: '0', label: 'No additional usage' }] : []),
  { value: 'custom', label: 'Custom amount' },
];

const selectDataLimitOptions = createSelector(selectCurrentDevice, getOptionsFromData);

export default selectDataLimitOptions;
