import PropTypes from 'prop-types';
import React from 'react';

class TextArea extends React.PureComponent {
  render() {
    const {
      value,
      name,
      onChange,
      classNames,
      rows = 4,
      error = null,
      showValidation = true,
    } = this.props;

    return (
      <div className={classNames}>
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          rows={rows}
          className={error ? 'error' : null}
        />
        {showValidation && error && <div className="inline-error">{error}</div>}
      </div>
    );
  }
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
  error: PropTypes.string,
  showValidation: PropTypes.bool,
  classNames: PropTypes.string,
};

TextArea.defaultProps = {
  classNames: '',
};

export default TextArea;
