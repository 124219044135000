import React from 'react';

function markIt(input, value) {
  const escaped = encodeURIComponent(value);
  const regex = RegExp(escaped, 'gi');

  return {
    __html: input.replace(regex, '<mark>$&</mark>'),
  };
}

function filterSuggestions(value, suggestions, length) {
  const regex = new RegExp(`${value}`, 'ig');
  return suggestions.filter((item) => regex.test(item.name)).slice(0, length);
}

class Suggestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: filterSuggestions(
        this.props.value,
        this.props.suggestions,
        this.props.maxSuggestionsLength
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      options: filterSuggestions(
        newProps.value,
        newProps.suggestions,
        newProps.maxSuggestionsLength
      ),
    });
  }

  render() {
    if (!this.props.expandable || !this.state.options.length) {
      return null;
    }

    const options = this.state.options.map((item, i) => {
      const key = `${this.props.listboxId}-${i}`;
      const classNames = [];

      if (this.props.selectedIndex === i) {
        classNames.push('is-active');
      }
      if (item.disabled) {
        classNames.push('is-disabled');
      }

      return (
        <li
          id={key}
          key={key}
          role="option"
          className={classNames.join(' ')}
          onMouseDown={() => this.props.setValue(item.name)}
        >
          <span dangerouslySetInnerHTML={markIt(item.name, this.props.value)} />
        </li>
      );
    });

    return (
      <div className="react-tags__suggestions">
        <ul role="listbox" id={this.props.listboxId}>
          {options}
        </ul>
      </div>
    );
  }
}

export default Suggestions;
