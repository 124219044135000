import React from 'react';

const SidebarValue = ({ value }) => (
  <div className="ActivationSidebar__item__subtitle__value">{value}</div>
);

interface SidebarAttributeProps {
  name: string;
  value: Array<string> | string | React.ReactNode;
}

const SidebarAttribute = ({ name, value }: SidebarAttributeProps) => (
  <div className="ActivationSidebar__item__subtitle">
    <div className="ActivationSidebar__item__subtitle__key">{name}</div>
    {Array.isArray(value) ? (
      value.map((item) => <SidebarValue key={item} value={item} />)
    ) : (
      <SidebarValue value={value} />
    )}
  </div>
);
export default SidebarAttribute;
