import { DdsColorTextDisabledOndefault } from '@hologram-dimension/tokens';
import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import TopMenu from 'common-js/components/menu/TopMenu';
import {
  USAGE,
  USAGE_BREAKDOWN,
  USAGE_BY_DAY,
  USAGE_LIVE,
  USAGE_OVERVIEW,
  USAGE_PREDEPLOYMENT,
  pathMatches,
  pathMatchesNewUsageTab,
  withContext,
} from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  selectHasPredeploymentTestingFlags,
  selectHasSimsPages,
} from 'common-js/reducers/releaseFlag/selectors';
import { type FC, type ReactNode } from 'react';

interface UsageMenuProps {
  children?: ReactNode;
}

const UsageMenu: FC<UsageMenuProps> = ({ children }) => {
  const isPreflight = useAppSelector(selectHasPredeploymentTestingFlags);
  const hasPageSimInventory = useAppSelector(selectHasSimsPages);
  const newUsageTabActive = pathMatchesNewUsageTab();

  return (
    <>
      <TopMenu titleHref={withContext(USAGE)} rightContent={<SubMenuRightContent />}>
        {hasPageSimInventory && (
          <TopMenu.Item
            text={
              <>
                Usage reports{' '}
                <span
                  style={{
                    color: newUsageTabActive
                      ? DdsColorTextDisabledOndefault
                      : 'rgba(255,255,255,0.7)',
                  }}
                >
                  BETA
                </span>
              </>
            }
            active={newUsageTabActive}
            href={withContext(USAGE_BY_DAY)}
          />
        )}
        <TopMenu.Item
          text="Usage reports"
          active={pathMatches(USAGE_OVERVIEW) || pathMatches(USAGE_BREAKDOWN)}
          href={withContext(USAGE_OVERVIEW)}
        />
        <TopMenu.Item
          text="Live sessions"
          active={pathMatches(USAGE_LIVE)}
          href={withContext(USAGE_LIVE)}
        />
        {isPreflight && (
          <TopMenu.Item
            text="Pre-deployment testing"
            active={pathMatches(USAGE_PREDEPLOYMENT)}
            href={withContext(USAGE_PREDEPLOYMENT)}
          />
        )}
      </TopMenu>
      {children}
    </>
  );
};

export default UsageMenu;
