import React from 'react';
import { connect } from 'react-redux';
import SubMenuRightContent from '../../common/components/menu/SubMenuRightContent';
import TopMenu from '../../common/components/menu/TopMenu';
import * as Paths from '../../common/constants/paths';

function SubMenu({ pathname, releaseFlags, hasPostpay }) {
  return (
    <TopMenu
      titleHref={Paths.withContext(Paths.BILLING_ACCOUNT)}
      rightContent={<SubMenuRightContent />}
    >
      <TopMenu.Item
        text="Account"
        active={Paths.pathMatches(Paths.BILLING_ACCOUNT, pathname)}
        href={Paths.withContext(Paths.BILLING_ACCOUNT)}
      />
      {hasPostpay && (
        <TopMenu.Item
          text="Invoices"
          active={Paths.pathMatches(Paths.BILLING_INVOICES, pathname)}
          href={Paths.withContext(Paths.BILLING_INVOICES)}
        />
      )}
      {releaseFlags.billing_statements ? (
        <TopMenu.Item
          text="Statements"
          active={Paths.pathMatches(Paths.BILLING_STATEMENTS, pathname)}
          href={Paths.withContext(Paths.BILLING_STATEMENTS)}
        />
      ) : null}
      <TopMenu.Item
        text="Transaction history"
        active={Paths.pathMatches(Paths.BILLING_HISTORY, pathname)}
        href={Paths.withContext(Paths.BILLING_HISTORY)}
      />
      <TopMenu.Item
        text="Orders"
        active={Paths.pathMatches(Paths.BILLING_ORDERS, pathname)}
        href={Paths.withContext(Paths.BILLING_ORDERS)}
      />
    </TopMenu>
  );
}

export default connect((state) => ({
  releaseFlags: state.releaseFlag,
  hasPostpay: state.organization.balance.hasPostpay,
}))(SubMenu);
