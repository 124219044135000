import ClassNames from 'clsx';
import {
  ACTIONS_ALERTS_DEVICE_DATALIMIT,
  ACTIONS_ALERTS_DEVICE_HIGHUSAGE,
  ACTIONS_ALERTS_DEVICE_TACCHANGE,
  pathMatches,
  withContext,
} from 'common-js/constants/paths';
import { Link } from 'react-router';

function SubMenu({ pathname }) {
  return (
    <div className="AlertsMenu__section AlertsMenu__section--secondary">
      <ul className="AlertsMenu__nav AlertsMenu__nav--secondary">
        <li
          className={ClassNames('AlertsMenu__nav__item', {
            'AlertsMenu__nav__item--active': pathMatches(ACTIONS_ALERTS_DEVICE_TACCHANGE, pathname),
          })}
        >
          <Link
            className="AlertsMenu__nav__item__link"
            to={withContext(ACTIONS_ALERTS_DEVICE_TACCHANGE)}
          >
            SIMs moved
          </Link>
        </li>
        <li
          className={ClassNames('AlertsMenu__nav__item', {
            'AlertsMenu__nav__item--active': pathMatches(ACTIONS_ALERTS_DEVICE_DATALIMIT, pathname),
          })}
        >
          <Link
            className="AlertsMenu__nav__item__link"
            to={withContext(ACTIONS_ALERTS_DEVICE_DATALIMIT)}
          >
            Data limits reached
          </Link>
        </li>
        <li
          className={ClassNames('AlertsMenu__nav__item', {
            'AlertsMenu__nav__item--active': pathMatches(ACTIONS_ALERTS_DEVICE_HIGHUSAGE, pathname),
          })}
        >
          <Link
            className="AlertsMenu__nav__item__link"
            to={withContext(ACTIONS_ALERTS_DEVICE_HIGHUSAGE)}
          >
            Usage alerts
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default SubMenu;
