import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { updateUserSettings } from 'common-js/reducers/account/actions';

// This is the same interface as useState, applied to the user's settings
const useAccountSetting = (accountSetting: string) => {
  const dispatch = useAppDispatch();
  const settingValue: AccountSettingValue = useAppSelector(
    (state: { account: { settings: AccountSettings } }) => state.account.settings[accountSetting]
  );
  const updateSetting = useCallback(
    (value: AccountSettingValue) => {
      dispatch(
        updateUserSettings({
          [accountSetting]: value,
        })
      );
    },
    [accountSetting, dispatch]
  );

  return [settingValue, updateSetting] as const;
};

export default useAccountSetting;
