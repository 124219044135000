import _classNames from 'clsx';
import Moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Icon, Tooltip } from '@holokit/core';
import DataUsedChartD3 from '../../usage/components/charts/DataUsedChartD3';
import * as alertActions from '../../common/reducers/alerts/actions';
import {
  getDataUsed,
  registerDeviceId,
  setCustomDate,
} from '../../common/reducers/singleDevice/actions';
import * as Paths from '../../common/constants/paths';

class TacChangeAlertsDrawer extends Component {
  static generateChip = (iconName, text, classModifier) => (
    <span
      className={_classNames('AlertsDrawer__chip', {
        [`AlertsDrawer__chip--${classModifier}`]: classModifier,
      })}
    >
      <span className={`AlertsDrawer__chip__icon AlertsDrawer__chip__icon--${iconName}`}>
        <Icon name={iconName} minor="minor" svgProps={{ style: { fill: '#ffffff' } }} />
      </span>
      <span className="AlertsDrawer__chip__text">{text}</span>
    </span>
  );

  static trimPrettyName = (prettyName) => {
    if (prettyName.length > 15) {
      return `${prettyName.substring(0, 12)}...`;
    }
    return prettyName;
  };

  componentDidMount() {
    const { deviceid, deviceRegistered, registerDeviceId_ } = this.props;

    if (!deviceRegistered) {
      registerDeviceId_(deviceid).then(() => {
        this.getDeviceData();
      });
    } else {
      this.getDeviceData();
    }
  }

  getDeviceData() {
    const { alert, deviceid, getDataUsed_, onDrawerLoad, setCustomDate_ } = this.props;

    const startDate = Moment(alert.whencreated, 'YYYY-MM-DD HH:mm:ss');
    startDate.subtract(3, 'days');
    const endDate = Moment(alert.whencreated, 'YYYY-MM-DD HH:mm:ss');
    endDate.add(3, 'days');
    setCustomDate_(
      {
        startDate: parseInt(startDate.format('x'), 10),
        endDate: parseInt(endDate.format('x'), 10),
      },
      deviceid
    ).then(() => {
      getDataUsed_(deviceid).then(() => {
        onDrawerLoad();
      });
    });
  }

  render() {
    const {
      alert,
      dataUsed,
      deviceid,
      handleAcknowledgeAlert,
      handleUnacknowledgeAlert,
      handleDeactivate,
      handlePause,
      handleResume,
      isDeactivated,
      isLoading,
      isPaused,
      timeFilter,
    } = this.props;

    let oldTacPrettyName = 'Unknown';
    let newTacPrettyName = 'Unknown';
    if (alert.old_manufacturer !== 'Unknown') {
      oldTacPrettyName = `${alert.old_manufacturer} ${alert.old_model}`;
    }
    if (alert.new_manufacturer !== 'Unknown') {
      newTacPrettyName = `${alert.new_manufacturer} ${alert.new_model}`;
    }

    return (
      <div className="AlertsDrawer AlertsDrawer--tac-change">
        {!isLoading && (
          <>
            <div className="AlertsDrawer__panel">
              <div className="AlertsDrawer__tac-header">
                <div className="AlertsDrawer__tac">
                  <div className="AlertsDrawer__tac__name">
                    {alert.old_model === 'Unknown' ? `TAC ${alert.old_tac}` : oldTacPrettyName}
                    {alert.old_model === 'Unknown' ? (
                      <div className="AlertsDrawer__tooltip">
                        <Tooltip
                          content="The Type Allocation Code (TAC) is the first 8 digits of an IMEI used to uniquely identify wireless device types."
                          title="TAC Number"
                        >
                          <Icon
                            classes="AlertsTable__header--help"
                            name="Circle--info"
                            size="minor"
                            svgProps={{ style: { fill: '#8008f7' } }}
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                  <div className="AlertsDrawer__tac__descrip">Original Device Type</div>
                </div>
                <div className="AlertsDrawer__tac-header__arrow">
                  <Icon
                    name="Chevron--double--east"
                    size="minor"
                    svgProps={{ style: { fill: '#ffffff' } }}
                  />
                </div>
                <div className="AlertsDrawer__tac">
                  <div className="AlertsDrawer__tac__name">
                    {alert.new_model === 'Unknown' ? `TAC ${alert.new_tac}` : newTacPrettyName}
                    {alert.new_model === 'Unknown' ? (
                      <div className="AlertsDrawer__tooltip">
                        <Tooltip
                          content="The Type Allocation Code (TAC) is the first 8 digits of an IMEI used to uniquely identify wireless device types."
                          title="TAC Number"
                        >
                          <Icon
                            classes="AlertsTable__header--help"
                            name="Circle--info"
                            size="minor"
                            svgProps={{ style: { fill: '#8008f7' } }}
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                  <div className="AlertsDrawer__tac__descrip">New Device Type</div>
                </div>
              </div>
              <DataUsedChartD3
                classes="DataUsedChartD3--alerts DataUsedChartD3--alerts-drawer"
                data={dataUsed}
                defaultToDaily
                emptyWhenEmpty
                timeFilter={timeFilter}
                useTimeFilter
              />
            </div>
            <div className="AlertsDrawer__panel">
              <div className="AlertsDrawer__panel__header">
                <div className="AlertsDrawer__panel__header__headline">What happened here?</div>
              </div>
              <div className="AlertsDrawer__panel__body AlertsDrawer__panel__body--chip-paragraph">
                On {Moment(alert.whencreated).format('MMMM D')},{' '}
                {TacChangeAlertsDrawer.generateChip('SIM', alert.device_name)} was moved from a
                {TacChangeAlertsDrawer.generateChip(
                  'Chip',
                  alert.old_model === 'Unknown'
                    ? alert.old_tac
                    : TacChangeAlertsDrawer.trimPrettyName(oldTacPrettyName)
                )}
                device type to a
                {TacChangeAlertsDrawer.generateChip(
                  'Chip',
                  alert.new_model === 'Unknown'
                    ? alert.new_tac
                    : TacChangeAlertsDrawer.trimPrettyName(newTacPrettyName),
                  'no-right-margin'
                )}
                . Follow up with your team to determine if this is expected behavior and how to
                respond to this event.
              </div>
            </div>
            <div className="AlertsDrawer__footer">
              <Button
                href={Paths.withContext(
                  Paths.DEVICE_LANDING.replace(`:${Paths.DEVICE_PARAM_NAME}`, deviceid)
                )}
                iconRight="Arrow--northeast"
                type="reversed"
              >
                {alert.device_name}
              </Button>
              <div className="AlertsDrawer__footer__btn-wrapper">
                <Button
                  buttonProps={{
                    'data-linkid': alert.linkid,
                    'data-alertid': alert.id,
                  }}
                  disabled={isDeactivated}
                  iconLeft={isPaused ? 'Play' : 'Pause'}
                  onClick={isPaused ? handleResume : handlePause}
                  type="reversed"
                >
                  {isPaused ? 'Resume' : 'Pause'}
                </Button>
                <Button
                  disabled={isDeactivated}
                  iconLeft="Lightning--slash"
                  onClick={() => handleDeactivate(alert.id)}
                  type="reversed"
                >
                  Deactivate
                </Button>
                <Button
                  iconLeft="Checkmark--double"
                  onClick={alert.acked ? handleUnacknowledgeAlert : handleAcknowledgeAlert}
                  type="reversed"
                >
                  {alert.acked ? 'Unacknowledge' : 'Acknowledge'}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  dataUsed: props.deviceid in state.singleDevice ? state.singleDevice[props.deviceid].dataUsed : [],
  deviceRegistered: !!state.singleDevice[props.deviceid],
  timeFilter:
    props.deviceid in state.singleDevice ? state.singleDevice[props.deviceid].timeFilter : [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...alertActions,
      getDataUsed_: getDataUsed,
      registerDeviceId_: registerDeviceId,
      setCustomDate_: setCustomDate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TacChangeAlertsDrawer);
