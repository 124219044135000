import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Icon } from '@hologram-dimension/icon';
import { Link } from '@hologram-dimension/link';
import { Panel } from '@hologram-dimension/panel';
import { Tooltip } from '@holokit/core';
import analyticsEventBuilder from 'common-js/analytics';
import useAllReports from 'common-js/api/reports/useAllReports';
import { HoloHelmet } from 'common-js/components';
import {
  ACCOUNT_LOGIN,
  USAGE_BY_DAY,
  USAGE_OVERVIEW,
  withContext,
} from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useRedirectIfFlagNotSet from 'common-js/hooks/useRedirectIfFlagNotSet';
import {
  selectIsBmpCustomer,
  selectPromoteSimsPages,
} from 'common-js/reducers/releaseFlag/selectors';
import { FC, useEffect } from 'react';
import { browserHistory } from 'react-router';
import ReportsLoadError from './ReportsLoadError';
import ReportsTableBody from './ReportsTableBody';
import SkeletonTableBody from './SkeletonTableBody';

const useRedirectIfBmpCustomer = () => {
  const isBmpCustomer = useAppSelector(selectIsBmpCustomer);

  useEffect(() => {
    if (isBmpCustomer) {
      browserHistory.push(ACCOUNT_LOGIN);
    }
  }, [isBmpCustomer]);
};

const ReportsOverview: FC = () => {
  useRedirectIfFlagNotSet('embedded_looker_reporting', ACCOUNT_LOGIN);
  useRedirectIfBmpCustomer();
  const { loading, data: reports, error } = useAllReports();
  const shouldShowError = !loading && !!error;

  const hasPromoteSimInventoryFlag = useAppSelector(selectPromoteSimsPages);

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Reports', 'Reports Index').send();
  }, []);

  return (
    <div className="ReportsOverview">
      <HoloHelmet title="Reports" />
      <BannerNotification
        prefixOverride="Beta ending"
        variant="caution"
        className="ReportsOverview__banner"
      >
        We will be sunsetting this page on <strong>August 30, 2024</strong>. All of the content in
        these reports can now be found in our new{' '}
        <Link
          to={hasPromoteSimInventoryFlag ? withContext(USAGE_BY_DAY) : withContext(USAGE_OVERVIEW)}
          type="inline"
        >
          Usage page reports
        </Link>
        . Thank you for participating in this beta!
      </BannerNotification>
      <Panel
        header={
          <span>
            Reports
            <Tooltip
              content="Get more insights into your fleet with interactive reports powered by Looker, our data visualization tool."
              position="top"
              boundary="viewport"
            >
              <Icon
                name="CircleInfo"
                className="ReportsOverview__tooltipIcon"
                size="medium"
                fill="DdsColorInteractivePrimaryDefault"
              />
            </Tooltip>
          </span>
        }
        noContentPadding
        className="ReportsOverview__panel"
        isLoading={loading}
      >
        {shouldShowError && <ReportsLoadError />}
        {!shouldShowError && (
          <table className="Table">
            <thead className="Table__header Table__header--sticky">
              <tr className="Table__row">
                <th className="Table__cell--header">
                  <div className="Table__cell-content">Report Name</div>
                </th>
                <th className="Table__cell--header">
                  <div className="Table__cell-content">Description</div>
                </th>
              </tr>
            </thead>
            {loading && <SkeletonTableBody />}
            {!loading && !error && <ReportsTableBody reports={reports} />}
          </table>
        )}
      </Panel>
    </div>
  );
};

export default ReportsOverview;
