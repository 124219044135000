import { Loader } from '@hologram-dimension/loader';
import { MetricButton } from '@holokit/core';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as Paths from 'common-js/constants/paths';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router';
import { createPillProps } from '../util/index';

const HorizontalStackedBarChart = React.lazy(() =>
  import('@holokit/charts').then((module) => ({ default: module.HorizontalStackedBarChart }))
);

class MetricPanelFullWidth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChartIndex: null,
    };
  }

  handleMetricHover = (barIndex) => {
    this.setState({ activeChartIndex: barIndex });
  };

  handleMetricButtonClick = (segment) => {
    const { router } = this.props;
    router.push(
      segment
        ? {
            pathname: Paths.DEVICES_DEVICES,
            state: {
              setFiltersOnLoad: [
                {
                  key: 'state',
                  values: segment.deviceStateFilters,
                },
              ],
            },
          }
        : { pathname: Paths.DEVICES_DEVICES }
    );
    sendAnalyticsEvent({
      type: analyticsTypes.FLEET_HOME_METRIC_BUTTON_CLICK,
      data: segment
        ? {
            button_name: segment.name,
          }
        : 'totalDevices',
    });
  };

  render() {
    const { deviceSegments, isLoading, keyMetric, comparingDataFromPeriod, historicFlag } =
      this.props;
    const { activeChartIndex } = this.state;

    const keyMetricPillProps = createPillProps(keyMetric, comparingDataFromPeriod);

    return (
      <section>
        <div className="MetricPanel__chart">
          <Suspense fallback={<Loader />}>
            <HorizontalStackedBarChart
              data={deviceSegments}
              activeIndex={activeChartIndex}
              isLoading={isLoading}
            />
          </Suspense>
        </div>
        <section className="MetricPanel__buttons MetricPanel__buttons--fullWidth">
          <div className="MetricPanel__buttons__button MetricPanel__buttons__button--fullWidth MetricPanel__buttons__button--keyMetric">
            <MetricButton
              number={keyMetric.number}
              text={keyMetric.name}
              isLoading={isLoading}
              onClick={() => this.handleMetricButtonClick(null)}
              pillProps={historicFlag ? keyMetricPillProps : null}
            />
          </div>
          <div className="MetricPanel__buttons__divider">
            <div className="MetricPanel__buttons__divider__line" />
          </div>
          {deviceSegments.map((segment, i) => {
            const pillProps = createPillProps(segment, comparingDataFromPeriod);

            return (
              <div
                key={`MetricPanel__button--${segment.name}`}
                className="MetricPanel__buttons__button MetricPanel__buttons__button--fullWidth"
                onMouseOver={() => {
                  this.handleMetricHover(i);
                }}
                onFocus={() => {
                  this.handleMetricHover(i);
                }}
                onMouseLeave={() => {
                  this.setState({ activeChartIndex: null });
                }}
              >
                <MetricButton
                  isLoading={isLoading}
                  number={segment.number}
                  text={segment.name}
                  orbColor={segment.color}
                  onClick={() => this.handleMetricButtonClick(segment)}
                  pillProps={historicFlag ? pillProps : null}
                />
              </div>
            );
          })}
        </section>
      </section>
    );
  }
}

MetricPanelFullWidth.propTypes = {
  comparingDataFromPeriod: PropTypes.string,
  keyMetric: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.number,
  }),
  deviceSegments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.number,
      color: PropTypes.string,
      deviceStateFilters: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object.isRequired,
};

MetricPanelFullWidth.defaultProps = {
  deviceSegments: [],
  keyMetric: {
    name: '',
    number: 0,
  },
};

const MetricPanelFullWidthHoC = withRouter(MetricPanelFullWidth);

export default MetricPanelFullWidthHoC;
