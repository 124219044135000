import { createSelector } from 'reselect';
import * as DeviceStates from 'common-js/constants/deviceStates';
import {
  selectCurrentDevice,
  selectSessionSample,
  selectStatusInfo,
} from 'common-js/reducers/currentDevice/selectors';
import { toByteString } from 'common-js/utils/numberFormatter';
import _ from 'lodash';
import Moment from 'moment-timezone';

const THIRTY_DAYS = 2529000 * 1000; // 30 days in milliseconds

// This derives a lot of data about the current device and places it into an object.
// It seems easier to do this in one go than to have myriad small selectors.
const selectDeviceMetaInfo = createSelector(
  selectCurrentDevice,
  selectSessionSample,
  selectStatusInfo,
  (device, sessionSample, statusInfo) => ({
    onlyEmptySessions:
      sessionSample.every((sample) => sample.bytes === 0) && (device.lastsession.bytes ?? 0) === 0,
    hasEmptySession: sessionSample.some((sample) => sample.bytes === 0),
    hasSession: !_.isEmpty(device.lastsession),
    lastSession30DaysOld:
      Date.now() - Moment.utc(device.lastsession.session_end).unix() * 1000 > THIRTY_DAYS,
    deviceWasPaused: [DeviceStates.PAUSED_SYS, DeviceStates.PAUSED_USER].includes(
      statusInfo?.oldstate
    ),
    pausedForPrepay:
      device.statusReason === DeviceStates.REASON_BILLING ||
      device.statusReason === DeviceStates.REASON_USAGE_BILLING,
    pausedForUsageLimit: device.statusReason === DeviceStates.REASON_USAGE_LIMIT,
    overageLimitString: toByteString(device.overagelimit, 2),
    kbUsed: sessionSample.reduce((totalBytes, sample) => totalBytes + sample.bytes, 0) / 1000,
    deactivatedDate: statusInfo?.timestamp ? Moment.utc(statusInfo.timestamp) : null,
    lastSessionIsActive: device.lastsession.active,
    deviceState: device.sysState,
  })
);

export default selectDeviceMetaInfo;
