import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Textarea } from '@hologram-dimension/textarea';
import {
  BILLING_ACCOUNT_ADD_CUSTOM_INVOICE_DETAILS,
  BILLING_ACCOUNT_EDIT_CUSTOM_INVOICE_DETAILS,
  BILLING_ACCOUNT_REMOVE_CUSTOM_INVOICE_DETAILS,
} from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { removeInvoiceInfo, updateInvoiceInfo } from 'common-js/reducers/account/actions';
import { close } from 'common-js/reducers/modal/actions';
import { getAllBilling } from 'common-js/reducers/organization/actions';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const AddCustomInvoiceInfoForm: React.FC<any> = ({ closeModal }) => {
  const [serverRequest, setServerRequest] = useState({
    isSubmitting: false,
    isRemoving: false,
    serverError: null,
  });

  const currentInvoiceText = useAppSelector(
    (state) => state.organization.billingInformation.billingAddress.invoice_text
  );
  const dispatch = useAppDispatch();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      invoiceText: currentInvoiceText,
    },
  });

  const onSubmit = ({ invoiceText }) => {
    setServerRequest({
      isSubmitting: true,
      isRemoving: false,
      serverError: null,
    });

    dispatch(updateInvoiceInfo(invoiceText))
      .then(() => dispatch(getAllBilling()))
      .then(() => dispatch(close()))
      .then(() => {
        sendAnalyticsEvent({
          type: currentInvoiceText
            ? BILLING_ACCOUNT_EDIT_CUSTOM_INVOICE_DETAILS
            : BILLING_ACCOUNT_ADD_CUSTOM_INVOICE_DETAILS,
        });

        toast(
          <HeadlineToast
            headline="Custom invoice details"
            body="Your custom invoice details have been saved."
            icon="Checkmark--single"
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toastify-content--burnt toastify-content--success',
            autoClose: 10000,
          }
        );
      })
      .catch((err) => {
        setServerRequest({
          isSubmitting: false,
          isRemoving: false,
          serverError: err,
        });
      });
  };

  const onRemoveCustomInvoice = () => {
    setServerRequest({
      isSubmitting: false,
      isRemoving: true,
      serverError: null,
    });

    dispatch(removeInvoiceInfo())
      .then(() => dispatch(getAllBilling()))
      .then(() => dispatch(close()))
      .then(() => {
        sendAnalyticsEvent({
          type: BILLING_ACCOUNT_REMOVE_CUSTOM_INVOICE_DETAILS,
        });

        toast(
          <HeadlineToast
            headline="Custom invoice details"
            body="Your custom invoice details have been removed."
            icon="Checkmark--single"
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toastify-content--burnt toastify-content--success',
            autoClose: 10000,
          }
        );
      })
      .catch((err) => {
        setServerRequest({
          isSubmitting: false,
          isRemoving: false,
          serverError: err,
        });
      });
  };

  const { isRemoving, isSubmitting, serverError } = serverRequest;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form form-horizontal add-invoice-info-form">
      <Textarea
        {...register('invoiceText')}
        name="invoiceText"
        placeholder="Add VAT IDs, tax info, internal reference numbers."
        id="CustomInvoiceDetails"
      />
      {serverError && <BannerNotification variant="error">{serverError}</BannerNotification>}

      <div className="EditBillingInfoForm--footer">
        <div className="EditBillingInfoForm--footer-left">
          {currentInvoiceText && (
            <Button
              loading={isRemoving}
              disabled={isSubmitting}
              variant="delete"
              onClick={onRemoveCustomInvoice}
            >
              Remove custom invoice details
            </Button>
          )}
        </div>
        <div className="EditBillingInfoForm--footer-right">
          <Button onClick={closeModal} disabled={isRemoving || isSubmitting} variant="secondary">
            Cancel
          </Button>
          <Button loading={isSubmitting} disabled={isRemoving} type="submit">
            {currentInvoiceText ? 'Save custom invoice details' : 'Add custom invoice details'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddCustomInvoiceInfoForm;
