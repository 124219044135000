import { updateUserSettings as updateUserSettingsApi } from 'common-js/api';
import {
  UPDATE_SETTINGS_ERROR,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
} from '../actionTypes';

const updateUserSettings = (settings) => (dispatch, state) => {
  const store = state();
  const previousSettingsEntry = store.account.settings;

  dispatch({
    type: UPDATE_SETTINGS_REQUEST,
    settings,
  });

  return updateUserSettingsApi(settings)
    .then((response) => {
      dispatch({
        type: UPDATE_SETTINGS_SUCCESS,
        settings: response.data,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_SETTINGS_ERROR,
        error,
        previousSettingsEntry,
      });
      return Promise.reject(error);
    });
};

export default updateUserSettings;
