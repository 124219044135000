import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Link } from '@hologram-dimension/link';
import { REGISTRATION_ONBOARDING_QUESTION } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { CheckList } from 'common-js/components';
import { Input, PasswordStrengthInput } from 'common-js/components/form';
import FullScreenModal from 'common-js/components/full-screen-modal';
import { ACCOUNT_LOGIN } from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  configureAccount as configureAccountAction,
  registerAccount,
} from 'common-js/reducers/account/actions';
import submitDashboardMarketoForm from 'common-js/utils/marketing';
import logoWhiteImage from 'img/logo/logo_white.svg';
import { useCallback, useState, type FC } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import isEmail from 'validator/lib/isEmail';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import validatePassword from './utils/validatePassword';

const SlideTransition = (props: GetReactParameterBag<typeof CSSTransition>) => (
  <CSSTransition
    classNames="slide"
    timeout={{ exit: 500, enter: 500, appear: 500 }}
    appear
    {...props}
  />
);

interface RegisterFormProps {
  onSuccessfulRegister: (data: FormProps) => void;
  orgIdToJoin?: OrgId;
  orgInviteId?: string;
  orgInviteToken?: string;
  source?: string;
  passedValues?: RegisterPassedValues;
}

interface FormProps {
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  email: string;
  terms: boolean;
  useCase: string;
  endUserSubIndustry: string;
  marketingEmailOptIn: boolean;
  phoneCallOptIn: boolean;
  userId: string;
  purpose: string;
}

const RegisterForm: FC<RegisterFormProps> = ({
  onSuccessfulRegister,
  orgIdToJoin,
  orgInviteId,
  orgInviteToken,
  source,
  passedValues,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [clickedCreate, setClickedCreate] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);

  const registrationError = useAppSelector((state) => state?.account?.registrationError);

  const methods = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      phoneNumber: '',
      email: passedValues?.email ?? '',
      terms: false,
      useCase: '',
      endUserSubIndustry: '',
      marketingEmailOptIn: false,
      phoneCallOptIn: false,
      userId: '',
      purpose: '',
    },
  });
  const dispatch = useAppDispatch();

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    trigger,
    formState: { isDirty, isValid, errors },
  } = methods;

  const configureAccount = useCallback(() => {
    try {
      dispatch(
        configureAccountAction(
          getValues('purpose') === 'company' ? 'my company' : 'myself',
          getValues('terms'),
          orgInviteId ?? '',
          orgIdToJoin,
          getValues('purpose'),
          source
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [dispatch, getValues, orgIdToJoin, orgInviteId, source]);

  const handleFormSubmit: SubmitHandler<FormProps> = useCallback(
    (data) => {
      configureAccount();
      onSuccessfulRegister(data);
    },
    [configureAccount, onSuccessfulRegister]
  );

  const openModal = useCallback(
    (event) => {
      event.preventDefault();
      setClickedCreate(true);
      setCreatingAccount(true);
      const fieldValues = {
        email: getValues('email'),
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        password: getValues('password'),
        terms: getValues('terms'),
        orgInviteId,
        orgInviteToken,
      };
      if (isDirty && isValid) {
        dispatch(registerAccount(fieldValues)).then((item) => {
          if (item?.user) {
            configureAccount();
            setValue('userId', item.user.userid);
            if (source === 'orginviteemail') {
              onSuccessfulRegister(getValues());
            } else {
              submitDashboardMarketoForm(getValues());
              setModalOpen(true);
            }
          } else {
            setCreatingAccount(false);
          }
        });
      } else {
        trigger();
        setCreatingAccount(false);
      }
    },
    [
      configureAccount,
      dispatch,
      getValues,
      isDirty,
      isValid,
      onSuccessfulRegister,
      orgInviteId,
      orgInviteToken,
      setValue,
      source,
      trigger,
    ]
  );

  const nextStep = useCallback(() => setCurrentStep((prevCurrentStep) => prevCurrentStep + 1), []);

  const errorSummaryMessage =
    errors.firstName?.message ||
    errors.lastName?.message ||
    errors.email?.message ||
    errors.password?.message ||
    errors.terms?.message ||
    registrationError;

  const handleAnalyticsCall = useCallback((prompt, response) => {
    sendAnalyticsEvent({
      type: REGISTRATION_ONBOARDING_QUESTION,
      data: { prompt, response },
    });
  }, []);

  return (
    <div className="Register__Form">
      <FormProvider {...methods}>
        <form className="NewForm" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="NewForm__Row">
            <div className="NewForm__DoubleColumn">
              <div className="NewForm__Header">
                <h1>Create a free account</h1>
              </div>
            </div>
          </div>
          <div className="NewForm__Row">
            <div className="NewForm__DoubleColumn">
              <div className="NewForm__SubHeader">
                Hologram connects IoT devices of every kind and size.
              </div>
            </div>
          </div>
          <div className="NewForm__RowLeftAligned">
            <CheckList
              items={[
                'Get online and stay online, anywhere in the world',
                'Maintain visibility into device performance and take action',
                'Optimize your fleet with real-time insights',
              ]}
            />
          </div>
          <div className="NewForm__RowLeftAligned">
            <div className="NewForm__Column">
              <div className="NewForm__EntryField">
                <label htmlFor="firstName">
                  First name
                  <Input
                    error={errors.firstName}
                    showValidation={false}
                    showValidIcon
                    type="text"
                    autoComplete="given-name"
                    {...register('firstName', {
                      required: 'Please enter a first and last name.',
                    })}
                  />
                </label>
              </div>
            </div>
            <div className="NewForm__Column">
              <div className="NewForm__EntryField">
                <label htmlFor="lastName">
                  Last name
                  <Input
                    error={errors.lastName}
                    showValidation={false}
                    showValidIcon
                    type="text"
                    autoComplete="family-name"
                    {...register('lastName', {
                      required: 'Please enter a first and last name.',
                    })}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="NewForm__RowLeftAligned">
            <div className="NewForm__DoubleColumn">
              <div className="NewForm__EntryField">
                <label htmlFor="email">
                  Enter your work email
                  <Input
                    error={errors.email && errors.email.message}
                    showValidIcon
                    type="email"
                    showValidation
                    alwaysRenderValidation
                    autoComplete="username"
                    {...register('email', {
                      required: 'Please enter a valid email address.',
                      validate: {
                        checkEmail: (v) =>
                          (v && v.length > 0 && isEmail(v)) || 'This is not a valid email address',
                      },
                    })}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="NewForm__RowLeftAligned">
            <div className="NewForm__DoubleColumn">
              <div className="NewForm__EntryField">
                <label htmlFor="password">
                  Create a password
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'Please enter a valid password.',
                      validate: validatePassword,
                    }}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <PasswordStrengthInput
                        onChange={onChange}
                        value={value}
                        inputProps={{ ref, name, onBlur }}
                        showValidation
                        showNewValidation
                        showEye
                        forceValidationCheckState={clickedCreate}
                      />
                    )}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="NewForm__RowLeftAligned">
            <div className="NewForm__DoubleColumn">
              <div className="NewForm__EntryFieldRow NewForm__TextContent">
                <Controller
                  name="terms"
                  control={control}
                  rules={{
                    validate: (value) =>
                      value || 'Please agree to our Terms of Use and Privacy Policy.',
                  }}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <>
                      <Input
                        type="checkbox"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        innerRef={ref}
                        name={name}
                        id={name}
                        error={errors.terms}
                        minScore
                        showValidation={false}
                      />
                      <label htmlFor={name}>
                        <span className="NewForm__Terms">
                          I agree to Hologram&apos;s{' '}
                          <a
                            href="https://www.hologram.io/terms-of-use/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms of Use
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://www.hologram.io/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </span>
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="NewForm__RowLeftAligned">
            <div className="NewForm__DoubleColumn">
              <div className="NewForm__EntryFieldRow NewForm__TextContent">
                <Controller
                  name="marketingEmailOptIn"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <label className="NewForm__EntryFieldRow" htmlFor={name}>
                      <Input
                        type="checkbox"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        innerRef={ref}
                        name={name}
                        id={name}
                        minScore
                      />
                      <span className="NewForm__Terms">
                        Yes, I’d like to receive personalized marketing communications from Hologram
                        about its products, solutions, and events via the email address I’ve
                        provided. I understand that I can opt-out any time and that my information
                        will be processed according to Hologram’s{' '}
                        <span className="NewForm__Terms">
                          <a
                            href="https://www.hologram.io/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </span>
                        <span className="NewForm__Terms__Italic"> (optional)</span>
                      </span>
                    </label>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="newForm__RowLeftAligned">
            <div className="NewForm__DoubleColumn">
              <div
                className={`NewForm__ErrorBanner${
                  clickedCreate && (!isValid || registrationError)
                    ? ' NewForm__ErrorBanner--Invalid'
                    : ''
                }`}
              >
                <Icon name="CircleAlert" size="medium" fill="DdsColorPaletteRed43" />
                <span>{errorSummaryMessage}</span>
              </div>
            </div>
          </div>
          <div className="NewForm__RowLeftAligned">
            <div className="NewForm__DoubleColumn">
              <Button className="NewButton-Primary" loading={creatingAccount} onClick={openModal}>
                Create account
              </Button>
            </div>
          </div>
          <div className="NewForm__RowLeftAligned">
            <div className="NewForm__DoubleColumn">
              <div className="NewForm__TextContent">
                <span>Already have an account? </span>
                <Link to={ACCOUNT_LOGIN + window.location.search}>Sign in</Link>
              </div>
            </div>
          </div>
          {modalOpen && (
            <div className="PageContainer">
              <FullScreenModal
                title={
                  <a href="https://www.hologram.io/" className="Modal__logo">
                    <img src={logoWhiteImage} alt="Hologram logo" />
                  </a>
                }
                showQuit={false}
                currentStep={currentStep}
                showProgress={currentStep < 7}
                totalSteps={8}
                progressIndicatorType="bar"
              >
                <div className="animation-window">
                  <TransitionGroup>
                    {currentStep === 0 && (
                      <SlideTransition>
                        <Step1
                          nextStep={nextStep}
                          configureAccount={configureAccount}
                          submitRegistrationForm={handleSubmit(handleFormSubmit)}
                          handleAnalyticsCall={handleAnalyticsCall}
                          key="step1"
                        />
                      </SlideTransition>
                    )}
                    {currentStep === 1 && (
                      <SlideTransition>
                        <Step2
                          nextStep={nextStep}
                          key="step2"
                          handleAnalyticsCall={handleAnalyticsCall}
                        />
                      </SlideTransition>
                    )}
                    {currentStep === 2 && (
                      <SlideTransition>
                        <Step3
                          nextStep={nextStep}
                          key="step3"
                          handleAnalyticsCall={handleAnalyticsCall}
                        />
                      </SlideTransition>
                    )}
                    {currentStep === 3 && (
                      <SlideTransition>
                        <Step4
                          nextStep={nextStep}
                          key="step4"
                          handleAnalyticsCall={handleAnalyticsCall}
                        />
                      </SlideTransition>
                    )}
                    {currentStep === 4 && (
                      <SlideTransition>
                        <Step5
                          nextStep={nextStep}
                          key="step5"
                          handleAnalyticsCall={handleAnalyticsCall}
                        />
                      </SlideTransition>
                    )}
                    {currentStep === 5 && (
                      <SlideTransition>
                        <Step6
                          nextStep={nextStep}
                          key="step6"
                          handleAnalyticsCall={handleAnalyticsCall}
                        />
                      </SlideTransition>
                    )}
                    {currentStep === 6 && (
                      <SlideTransition>
                        <Step7
                          nextStep={nextStep}
                          key="step7"
                          handleAnalyticsCall={handleAnalyticsCall}
                        />
                      </SlideTransition>
                    )}
                    {currentStep === 7 && (
                      <SlideTransition>
                        <Step8 key="step8" handleAnalyticsCall={handleAnalyticsCall} />
                      </SlideTransition>
                    )}
                  </TransitionGroup>
                </div>
              </FullScreenModal>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default RegisterForm;
