import * as API from 'common-js/api';
import { getUserContextData } from 'common-js/api/util';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import * as actionTypes from './actionTypes';

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: {
    loading,
  },
});

export const setCurrentDevice = (currentDevice) => ({
  type: actionTypes.SET_CURRENT_DEVICE,
  payload: {
    currentDevice,
  },
});

export const setCurrentDeviceWithMetadata = ({
  device,
  euiccHistory,
  euiccTestHistory,
  profiles,
  sessionSample,
  statusHistory,
}) => ({
  type: actionTypes.SET_CURRENT_DEVICE_WITH_METADATA,
  payload: {
    device,
    euiccHistory,
    euiccTestHistory,
    profiles,
    sessionSample,
    statusHistory,
  },
});

export const setLiveUsage = (liveUsage) => ({
  type: actionTypes.SET_CURRENT_DEVICE_LIVE_USAGE,
  payload: {
    ...liveUsage,
  },
});

export const setTimeQuickFilter = (quickFilter) => ({
  type: actionTypes.SET_TIME_QUICK_FILTER,
  payload: {
    quickFilter,
  },
});

export const setCustomDate = (startDate, endDate) => ({
  type: actionTypes.SET_CUSTOM_DATE,
  payload: {
    startDate,
    endDate,
  },
});

export const setPollingPause = (pause) => ({
  type: actionTypes.SET_POLLING_PAUSE,
  payload: {
    pause,
  },
});

// TODO: anything below this line was moved over from singleDevice and should be simplified to avoid redux

// spacebridge tab
export function setTunnelable(deviceId, isTunnelable) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.SET_TUNNELABLE_REQUEST,
      deviceId,
    });

    return API.setTunnelable(deviceId, isTunnelable, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.SET_TUNNELABLE_SUCCESS,
          isTunnelable,
          id: deviceId,
          deviceId,
        });

        sendAnalyticsEvent({
          type: isTunnelable
            ? analyticsTypes.SPACEBRIDGE_TUNNELING_ENABLED
            : analyticsTypes.SPACEBRIDGE_TUNNELING_DISABLED,
        });

        return Promise.resolve(null);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_TUNNELABLE_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

// message tab
export function sendSmsToDevice(deviceIds, message, fromnumber) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SEND_SMS_TO_DEVICE_REQUEST,
    });

    return API.sendSmsToDevice(deviceIds, message, fromnumber)
      .then(() => {
        dispatch({
          type: actionTypes.SEND_SMS_TO_DEVICE_SUCCESS,
        });

        sendAnalyticsEvent({
          type: analyticsTypes.DATA_MESSAGE_SENT,
          data: {
            method: 'sms',
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SEND_SMS_TO_DEVICE_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function sendDataToDevice(deviceIds, payload, port, protocol) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SEND_DATA_TO_DEVICE_REQUEST,
    });

    return API.sendDataToDevice(deviceIds, payload, port, protocol)
      .then((data) => {
        dispatch({
          type: actionTypes.SEND_DATA_TO_DEVICE_SUCCESS,
          orders: data,
        });

        sendAnalyticsEvent({
          type: analyticsTypes.DATA_MESSAGE_SENT,
          data: {
            method: 'cloud data',
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SEND_DATA_TO_DEVICE_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function simMessageFromDevice(deviceId, data = '', topics = []) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SIM_MESSAGE_REQUEST,
    });

    return API.simMessageFromDevice(deviceId, data, topics)
      .then(() => {
        dispatch({ type: actionTypes.SIM_MESSAGE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SIM_MESSAGE_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

// DeviceHeader
export function addTagToDevice(tagId, deviceId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.ADD_TAG_TO_DEVICE_REQUEST,
      tagId,
      deviceId,
    });

    return API.addTagToDevices(tagId, { deviceids: [deviceId] }, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.ADD_TAG_TO_DEVICE_SUCCESS,
          deviceId,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_TAG_TO_DEVICE_ERROR,
          error,
          deviceId,
        });
        return Promise.reject(error);
      });
  };
}

export function removeTagFromDevice(tagId, deviceId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.REMOVE_TAG_FROM_DEVICE_REQUEST,
      tagId,
      deviceId,
    });

    return API.removeTagFromDevices(tagId, { deviceids: [deviceId] }, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_TAG_FROM_DEVICE_SUCCESS,
          deviceId,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REMOVE_TAG_FROM_DEVICE_ERROR,
          error,
          deviceId,
        });
        return Promise.reject(error);
      });
  };
}

export function changeDeviceName(deviceId, name) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.CHANGE_DEVICE_NAME_REQUEST,
      name,
      deviceId,
    });

    return API.changeDeviceName(deviceId, name, getUserContextData(state))
      .then((device) => {
        dispatch({
          type: actionTypes.CHANGE_DEVICE_NAME_SUCCESS,
          device,
          deviceId,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CHANGE_DEVICE_NAME_ERROR,
          error,
          deviceId,
        });
        return Promise.reject(error);
      });
  };
}
