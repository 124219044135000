import { API_URL, headers } from '../config';
import { fetchHandler, fetchErrorHandler } from '../middleware';
import queryString from 'query-string';

export function getPools(userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPool(poolId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolId}?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPoolPlans(userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/plans?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function suscribeToPool(poolPlanId, isPreview, userContextData) {
  const query = {
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  };

  const body = {
    poolplanid: poolPlanId,
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  };

  if (isPreview) query.preview = 1;

  return fetch(`${API_URL}/pools?${queryString.stringify(query)}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  })
    .then(
      fetchHandler((body) => {
        if (isPreview) {
          return Promise.resolve(body.order_data);
        }
        return Promise.resolve(body.data);
      })
    )
    .catch(fetchErrorHandler);
}

export function suscribeToPendingPool(poolPlanId, isPreview, userContextData) {
  const query = {
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  };

  const body = {
    poolplanid: poolPlanId,
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  };

  if (isPreview) query.preview = 1;

  return fetch(`${API_URL}/pools/pending?${queryString.stringify(query)}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  })
    .then(
      fetchHandler((body) => {
        if (isPreview) {
          return Promise.resolve(body.order_data);
        }
        return Promise.resolve(body.data);
      })
    )
    .catch(fetchErrorHandler);
}

export function changePoolSize(poolId, poolPlanId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolId}?${query}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      poolplanid: poolPlanId,
      newstate: 'changeplan',
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function renewPool(poolId, poolPlanId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolId}?${query}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      poolplanid: poolPlanId,
      newstate: 'changeplan',
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function unsubscribeToPool(poolPlanId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolPlanId}?${query}`, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function unsubscribeToPending(poolPlanId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolPlanId}/changes?${query}`, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPoolUsage(poolId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolId}/usage?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPoolDevices(poolId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolId}/devices?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPoolChanges(poolId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolId}/changes?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPoolPendingChanges(poolId, userContextData) {
  const query = queryString.stringify({
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
  });

  return fetch(`${API_URL}/pools/${poolId}/changes/pending?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}
