import _ from 'lodash';

import * as accountActionTypes from '../account/actionTypes';
import * as orgActionTypes from '../organization/actionTypes';
import * as actionTypes from './actionTypes';

// Rather than hard-code all the individual Better Monetize the Product (BMP) flags, we want to generate the combinations programmatically. (So this creates `bmp_feature_lite_bulk_actions`, `bmp_feature_plus_bulk_actions`, etc.)
const BMPPackages = ['lite', 'plus', 'pro'];
const BMPFeatures = [
  'activity_history',
  'api',
  'bulk_actions',
  'data_usage_limits',
  'device_tunneling',
  'fleet_status',
  'predeployment_testing',
  'tags',
];
const BMPFlags = Object.fromEntries(
  BMPPackages.reduce((flags, pkg) => {
    BMPFeatures.forEach((feature) => flags.push([`bmp_feature_${pkg}_${feature}`, false]));
    return flags;
  }, [])
);

export const initialState = {
  ...BMPFlags,
  activity_history_details: false,
  activity_history_export_csv: false,
  add_editor_limited_role: false,
  billing_statements: false,
  biz_layer_pause_resume: false,
  bulk_rename: false,
  bulk_tag: false,
  csrrdm_allowed: false,
  custom_billing: false,
  embedded_looker_reporting: false,
  eureka0: false,
  fleet: false,
  fleet_historic: false,
  gdpr: false,
  hyper_sdp_improvements: false,
  hyper_search_improvements: false,
  legacy_plans: false,
  liveusage: false,
  negative_balance: false,
  preflight: false,
  preflight_api: false,
  preflight_tab: false,
  proplan: false,
  proxy_analytics: false,
  sdp_new_lights: false,
  service_advisory: false,
  service_outage: false,
  service_planned: false,
  stellar_design_system: false,
  support_gui: false,
};

function featureReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.CLEAR:
    case accountActionTypes.LOGIN_REQUEST:
    case accountActionTypes.REGISTER_REQUEST:
    case accountActionTypes.LOGOUT_REQUEST:
    case orgActionTypes.CHANGE_CONTEXT:
      return {
        ...state,
        ...initialState,
      };

    case actionTypes.GET_PUBLIC_RELEASEFLAGS_SUCCESS:
    case actionTypes.GET_RELEASEFLAGS_SUCCESS: {
      // Flags may come in as an array or an object.
      const flags = _.isArray(action.flags)
        ? action.flags
        : Object.keys(action.flags).map((key) => action.flags[key]);

      const newFeatures = {};

      flags.forEach((flag) => {
        if (!state[flag]) newFeatures[flag] = true;
      });

      return { ...state, ...newFeatures };
    }

    default:
      return state;
  }
}

export default featureReducer;
