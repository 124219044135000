import { createSelector } from '@reduxjs/toolkit';

export const selectLogFilters = createSelector(
  (state: any) => state.logFilters,
  (logFilters) => logFilters
);

export const selectAllLogFilters = createSelector(
  selectLogFilters,
  (logFilters) => logFilters.allLogFilters
);

export const selectIsViewingAllLogs = createSelector(
  selectAllLogFilters,
  (allLogFilters) => allLogFilters.isViewingAllLogs
);
export const selectLogCategory = createSelector(
  selectAllLogFilters,
  (allLogFilters) => allLogFilters.logCategory
);
export const selectDeviceFilter = createSelector(
  selectAllLogFilters,
  (allLogFilters) => allLogFilters.deviceFilter
);
export const selectSearchQuery = createSelector(
  selectAllLogFilters,
  (allLogFilters) => allLogFilters.searchQuery
);

export const selectSelectedTagIds = createSelector(
  selectDeviceFilter,
  (deviceFilter) => deviceFilter?.selectedTags
);

export const selectSelectedTags = createSelector(
  [selectDeviceFilter, selectSelectedTagIds],
  (deviceFilter, selectedTagIds) =>
    deviceFilter?.tags?.filter((tag) => selectedTagIds?.includes(tag.id))
);
