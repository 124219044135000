import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import LoggedOutTemplate from 'account/containers/LoggedOutTemplate';
import FormPanel from 'common-js/components/FormPanel';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { clearMfa, resendTotp } from 'common-js/reducers/account/actions';
import authenticateTotp from 'common-js/reducers/account/actions/authenticateTotp';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from '@hologram-dimension/link';
import mfaValidationMessages from './utils/mfaValidationMessages';

type ResendCodeState = 'idle' | 'sent' | 'sending';

const ResendCodeFooter: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sendState, setSendState] = useState<ResendCodeState>('idle');

  const resendCode = async () => {
    setSendState('sending');
    await dispatch(resendTotp());
    setSendState('sent');
    toast(<HeadlineToast icon="Checkmark--single" headline="Verification code sent!" />, {
      position: toast.POSITION.TOP_RIGHT,
      className:
        'toastify-content--burnt toastify-content--success toastify-content--headline-only',
      autoClose: 10000,
      hideProgressBar: true,
    });
  };
  return (
    <>
      {sendState === 'sent' && (
        <p>
          Still having trouble with your code?{' '}
          <Link
            href="https://support.hologram.io/hc/en-us/requests/new"
            target="_blank"
            rel="noreferrer"
            type="inline"
          >
            Contact support
          </Link>
        </p>
      )}
      {sendState === 'sending' && <p>Sending&hellip;</p>}
      {sendState === 'idle' && (
        <p className="login--footer">
          Didn&apos;t receive a code?{' '}
          <Button variant="tertiary" onClick={resendCode}>
            Send new code
          </Button>
        </p>
      )}
      <ToastContainer />
    </>
  );
};

interface MfaFormProps {
  passcode: string;
}

const MfaLoginModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const email = useAppSelector((state) => state.account?.mfa?.method?.email);

  const [formError, setFormError] = useState(email ? '' : mfaValidationMessages.emailNotFound);

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
    setFocus,
  } = useForm<MfaFormProps>();

  useEffect(() => {
    if (email) {
      setFocus('passcode');
    }
  }, [setFocus, email]);

  const renderEmail = () => {
    const at = email.indexOf('@');
    const domain = email.slice(at);
    const stars = Array(email.slice(0, at - 1).length)
      .fill('*')
      .join('');
    const firstLetter = email[0];

    return `${firstLetter}${stars}${domain}`;
  };

  const resetMfa = (e) => {
    e.preventDefault();
    dispatch(clearMfa());
  };

  const authenticateCode: SubmitHandler<MfaFormProps> = async (data) => {
    if (formError?.length > 0) {
      setFormError('');
    }

    try {
      const response = await dispatch(authenticateTotp(data.passcode));
      if (response.error) {
        if (response.error.totpCode) {
          // If the API response was related to the code provided in the request, display the API error as a field-level error
          setError('passcode', {
            type: 'custom',
            message: response.error.totpCode,
          });
        } else {
          throw response.error;
        }
      }
    } catch (errorMessage) {
      setFormError(errorMessage as string);
    }
  };

  return (
    <LoggedOutTemplate backLinkText="Log in" backLinkOnclick={resetMfa} className="MFA_Login_Modal">
      <FormPanel
        title="Verify it's you"
        message={formError}
        messageType="error"
        footer={<ResendCodeFooter />}
      >
        {email && (
          <>
            <p>
              To complete your login, enter the 6-digit code sent to{' '}
              <strong>{renderEmail()}</strong>
            </p>
            <Form
              onSubmit={handleSubmit(authenticateCode)}
              footerActions={
                <Button type="submit" loading={isSubmitting} name="Submit">
                  Submit
                </Button>
              }
            >
              <TextInputField
                className="MFA_Login_Modal_input"
                type="tel"
                id="form-input-passcode"
                pattern="[0-9]{6}"
                label="Verification code"
                placeholder="123456"
                invalid={!!errors.passcode}
                validationMessage={errors.passcode?.message?.toString()}
                inputMode="numeric"
                autocomplete="off"
                inputProps={{ maxLength: 6 }}
                {...register('passcode', {
                  required: mfaValidationMessages.codeBlank,
                  pattern: {
                    value: /^\d\d\d\d\d\d$/,
                    message: mfaValidationMessages.codeNumeric,
                  },
                  maxLength: {
                    value: 6,
                    message: mfaValidationMessages.codeLength,
                  },
                  minLength: {
                    value: 6,
                    message: mfaValidationMessages.codeLength,
                  },
                })}
                data-1p-ignore
              />
            </Form>
          </>
        )}
        {!email && (
          <p>
            Please contact <a href="mailto:support@hologram.io">support@hologram.io</a> for help.
          </p>
        )}
      </FormPanel>
    </LoggedOutTemplate>
  );
};

export default MfaLoginModal;
