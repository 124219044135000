import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'clsx';

class ExpandCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.expander = React.createRef();
  }

  componentDidMount() {
    const { initialExpanded } = this.props;

    if (!initialExpanded && this.expander) {
      this.expander.current.style.height = `${0}px`;
    }
  }

  componentDidUpdate(prevProps) {
    const { expanded } = this.props;

    if (expanded !== prevProps.expanded) {
      if (expanded) {
        this.expand();
      } else {
        this.collapse();
      }
    }
  }

  collapse = () => {
    if (this.expander) {
      const sectionHeight = this.expander.current.scrollHeight;
      const elementTransition = this.expander.current.style.transition;
      this.expander.current.style.transition = '';

      requestAnimationFrame(() => {
        this.expander.current.style.height = `${sectionHeight}px`;
        this.expander.current.style.transition = elementTransition;

        requestAnimationFrame(() => {
          this.expander.current.style.height = `${0}px`;
        });
      });

      this.expander.current.setAttribute('data-collapsed', 'true');
    }
  };

  expand = () => {
    if (this.expander) {
      const sectionHeight = this.expander.current.scrollHeight;
      this.expander.current.style.height = `${sectionHeight}px`;
      this.expander.current.addEventListener('transitionend', this.onExpandEnd);
      this.expander.current.setAttribute('data-collapsed', 'false');
    }
  };

  onExpandEnd = () => {
    this.expander.current.removeEventListener('transitionend', this.onExpandEnd);
    this.expander.current.style.height = null;
  };

  render() {
    const { expanded, initialExpanded } = this.props;

    return (
      <div
        className={ClassNames('ExpandCollapse', {
          'ExpandCollapse--expanded': expanded,
        })}
        ref={this.expander}
      >
        {this.props.children}
      </div>
    );
  }
}

ExpandCollapse.propTypes = {
  expanded: PropTypes.bool,
  initialExpanded: PropTypes.bool,
};

ExpandCollapse.defaultProps = {
  expanded: true,
  initialExpanded: true,
};

export default ExpandCollapse;
