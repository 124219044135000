import { findIndex } from 'lodash';
import type { Action, Reducer } from 'redux';
import * as accountActionTypes from '../account/actionTypes';
import * as actionTypes from './actionTypes';
import type { GlobalMessageType } from './actions';

const initialState = {
  messages: [],
};

type ActionType =
  | GetObjectValueType<typeof accountActionTypes>
  | GetObjectValueType<typeof actionTypes>;

interface MessageReducerAction extends Action {
  type: ActionType;
  message: string;
  id: number;
  messageType: GlobalMessageType;
}

const messageReducer = ((
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: any = initialState,
  action: MessageReducerAction
) => {
  switch (action.type) {
    case accountActionTypes.LOGIN_REQUEST:
    case accountActionTypes.LOGOUT_REQUEST:
      return { ...state, ...initialState };

    case actionTypes.ADD_ONE: {
      state.messages.push({
        text: action.message,
        id: action.id,
        messageType: action.messageType,
      });

      return { ...state, messages: state.messages.slice(0) };
    }

    case actionTypes.DISMISS_ALL:
      return { ...state, messages: [] };

    case actionTypes.DISMISS_ONE: {
      const indexOfMessage = findIndex(state.messages, { id: action.id });

      if (indexOfMessage > -1) {
        state.messages.splice(indexOfMessage, 1);
      }

      return { ...state, messages: state.messages.slice(0) };
    }

    default:
      return state;
  }
}) as Reducer<any>; // This is necessary, because redux is odd with their typings

export default messageReducer;
