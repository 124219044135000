import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  DEAD,
  DEAD_PENDING,
  LIVE,
  PAUSED_SYS,
  PAUSED_USER,
} from 'common-js/constants/deviceStates';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';
import { getDeviceStatusText } from 'common-js/utils/deviceStatus';
import Light from './Light';
import Line from './Line';
import selectLineColors from './selectLineColors';
import selectStatusMessage from './selectStatusMessage';
import selectDeviceMetaInfo from './selectDeviceMetaInfo';
import BMPUpsellCallout from './BMPUpsellCallout';

interface DeviceStatusOverviewLightsProps {
  expanded: boolean;
  small?: boolean;
}

const DeviceStatusOverviewLights = ({ expanded, small }: DeviceStatusOverviewLightsProps) => {
  const deviceMetaInfo = useAppSelector(selectDeviceMetaInfo);
  const lineColors = useAppSelector(selectLineColors);
  const message = useAppSelector(selectStatusMessage);

  const messageHeading = getDeviceStatusText(
    deviceMetaInfo.deviceState,
    deviceMetaInfo.lastSessionIsActive
  );

  return (
    <div
      className={applyConditionalClassNames({
        'DeviceStatusOverview-lights-wrapper': true,
        'DeviceStatusOverview-lights-wrapper-collapsed': small,
        'DeviceStatusOverview-lights-wrapper-collapsed--hidden': small && expanded,
      })}
    >
      <div
        className={applyConditionalClassNames({
          'DeviceStatusOverview-lights': true,
          expanded: !small,
        })}
      >
        <div className="light-section">
          <Light
            icon="SIM"
            tooltip="SIM: This represents your hardware device"
            label="SIM"
            small={small}
            first
          />
          <Line
            color={lineColors[0]}
            small={small}
            deactivated={[DEAD_PENDING, DEAD].includes(deviceMetaInfo.deviceState)}
          />
        </div>
        <div className="light-section">
          <Light
            icon="CellTower"
            tooltip="Local Tower: Indicates whether your device can communicate with a local tower of a partner operator."
            label="Local Tower"
            small={small}
          />
          <Line color={lineColors[1]} small={small} />
        </div>
        <div className="light-section">
          <Light
            icon="Hologram"
            tooltip="Global Network: your device successfully connected from a local carrier partner to Hologram's global network and opened a data session."
            label="Global Network"
            small={small}
          />
          <Line
            color={lineColors[2]}
            small={small}
            paused={[PAUSED_USER, PAUSED_SYS].includes(deviceMetaInfo.deviceState)}
            timedOut={deviceMetaInfo.deviceState === LIVE && deviceMetaInfo.lastSession30DaysOld}
          />
        </div>
        <div className="light-section light-section--last">
          <Light
            icon="Cloud"
            tooltip="Internet Access: your device has successfully transmitted data."
            label="Internet Access"
            small={small}
            last
          />
        </div>
      </div>
      {message && !small && (
        <div className="DeviceStatusOverview-message">
          <div className="DeviceStatusOverview-message-divline" />
          <div className="DeviceStatusOverview-message-state">{messageHeading}</div>
          {message}
          <BMPUpsellCallout />
        </div>
      )}
    </div>
  );
};

export default DeviceStatusOverviewLights;
