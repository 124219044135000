import React from 'react';
import { browserHistory } from 'react-router';
import Moment from 'moment';
import _classNames from 'clsx';
import { Icon } from '@hologram-dimension/icon';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as Paths from 'common-js/constants/paths';
import { translateDeviceAction } from 'devices/util';
import ActivitySkeletonTable from './ActivitySkeletonTable';

function ActivityTableHeaderCell({ children, classes, column }) {
  return (
    <th
      className={_classNames(
        'Table__cell--header ActivityTable__cell--header',
        `ActivityTable__cell--${column}`,
        {
          [classes]: classes,
        }
      )}
    >
      <div className="Table__cell-content ActivityTable__cell-content--header">{children}</div>
    </th>
  );
}

function ActivityTableCell({ children, classes }) {
  return (
    <td
      className={_classNames('Table__cell', 'ActivityTable__cell', {
        [classes]: classes,
      })}
    >
      <div className="Table__cell-content ActivityTable__cell-content">{children}</div>
    </td>
  );
}

function ActivityTableRow({
  action,
  deviceCount,
  includeLink,
  jobId,
  requesterFirstName,
  requesterLastName,
  requesterOrgName,
  source,
  timestampEnd,
  simInventory,
}) {
  const utcTime = Moment.utc(timestampEnd);
  const formattedTime = utcTime.format('YYYY-MM-DD HH:mm:ss');

  const linkUrl = Paths.withContext(
    simInventory
      ? Paths.SIM_ACTIVITY_HISTORY_DETAILS.replace(`:${Paths.BATCH_JOB_PARAM_NAME}`, jobId)
      : Paths.DEVICES_ACTIVITY_HISTORY_DETAILS.replace(`:${Paths.BATCH_JOB_PARAM_NAME}`, jobId)
  );

  const { actionPastTenseCapitalized } = translateDeviceAction(action);

  let teamMember;
  if (source === 'api') {
    teamMember = `${requesterOrgName} team via API`;
  } else if (requesterOrgName === 'Hologram') {
    teamMember = 'Hologram team member';
  } else {
    teamMember = `${requesterFirstName} ${requesterLastName}`;
  }

  const onDetailsLinkClick = (e) => {
    e.preventDefault();

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        page: `${simInventory ? 'Sims' : 'Devices'} - Activity History - View Details`,
      },
    });

    browserHistory.push(linkUrl);

    return false;
  };

  return (
    <tr
      className={_classNames('Table__row', {
        'ActivityTable__row--with-link': includeLink,
      })}
    >
      <ActivityTableCell>{formattedTime}</ActivityTableCell>
      <ActivityTableCell>{actionPastTenseCapitalized}</ActivityTableCell>
      <ActivityTableCell>{deviceCount}</ActivityTableCell>
      <ActivityTableCell classes="ActivityTable__cell--name">{teamMember}</ActivityTableCell>
      <ActivityTableCell>
        {includeLink && (
          <a className="ActivityTable__link" onClick={onDetailsLinkClick} href={linkUrl}>
            View details
            <Icon name="ChevronSingleEast" fill="DdsColorInteractivePrimaryDefault" />
          </a>
        )}
      </ActivityTableCell>
    </tr>
  );
}

function ActivityTable({ completedTasks, includeLinks, isLoading, lastPage, simInventory }) {
  return (
    <table className="Table ActivityTable">
      <thead className="Table__header Table__header--sticky">
        <tr>
          <ActivityTableHeaderCell column="when">Date & time</ActivityTableHeaderCell>
          <ActivityTableHeaderCell column="action_type">Action type</ActivityTableHeaderCell>
          <ActivityTableHeaderCell column="devices">Devices</ActivityTableHeaderCell>
          <ActivityTableHeaderCell column="team_member">Collaborator</ActivityTableHeaderCell>
          <ActivityTableHeaderCell column="link" />
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <ActivitySkeletonTable rowCount={16} />
        ) : (
          completedTasks.map((task) => (
            <ActivityTableRow
              {...task}
              key={`ActivityTableRow-${task.jobId}`}
              includeLink={includeLinks}
              simInventory={simInventory}
            />
          ))
        )}
        {lastPage && completedTasks && completedTasks.length > 0 && (
          <tr className="Table__row">
            <td className="Table__cell ActivityTable__cell" colSpan="5">
              <div className="Table__cell-content ActivityTable__cell-content ActivityTable__cell--startrow">
                No device history data before this point
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default ActivityTable;
