import { Checkbox } from '@hologram-dimension/checkbox';
import { ComplexIcon, Dropdown } from '@holokit/core';
import React from 'react';

class PendingUserItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  onResendInvite = () => {
    const { resendInvitation, pendingUser, pushGlobalMessage, getOrganizations } = this.props;

    this.setState({ isLoading: true });

    resendInvitation(pendingUser.inviteid)
      .then(() => {
        this.setState({ isLoading: false });
        return getOrganizations();
      })
      .then(() => {
        pushGlobalMessage('Invitation resent', 'success');
      })
      .catch((e) => {
        pushGlobalMessage(e, 'error');
        this.setState({ isLoading: false });
      });
  };

  onCancelInvite = () => {
    const { cancelInvitation, pendingUser, pushGlobalMessage, getOrganizations } = this.props;

    this.setState({ isLoading: true });

    cancelInvitation(pendingUser.inviteid)
      .then(() => {
        this.setState({ isLoading: false });
        return getOrganizations();
      })
      .then(() => {
        pushGlobalMessage('Invitation cancelled', 'success');
      })
      .catch((e) => {
        pushGlobalMessage(e, 'error');
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isAdmin, pendingUser } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="grid-row vertical-align collab-list-item">
        {isAdmin ? (
          <Checkbox className="select-collab" disabled label="" hiddenLabel />
        ) : (
          <div className="select-collab-empty" />
        )}
        <div>
          <div className="user-logo-container">
            <div className="user-logo">{pendingUser.initial}</div>
          </div>
        </div>
        <div className="grid-item">
          <div className="f4 f-very-subdued">{pendingUser.email}</div>
        </div>
        {isAdmin ? (
          <Dropdown
            align="right"
            dropdownText="Awaiting response..."
            items={[
              {
                onClick: this.onResendInvite,
                children: 'Resend invite',
                closeAfterClick: true,
              },
              {
                onClick: this.onCancelInvite,
                children: 'Cancel invite',
                closeAfterClick: true,
              },
            ]}
          />
        ) : (
          <div>Awaiting response...</div>
        )}
        {isLoading && <ComplexIcon classes="CollaboratorList__loader" name="spinner" />}
      </div>
    );
  }
}

export default PendingUserItem;
