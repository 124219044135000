import { Icon } from '@hologram-dimension/icon';
import { Button } from '@hologram-dimension/button';
import classnames from 'clsx/lite';
import PropTypes from 'prop-types';
import React from 'react';
import strength from 'strength';

function validationIcon(state) {
  let stateClassName;
  let iconName;

  if (state === 'valid') {
    stateClassName = 'PasswordStrengthValidation__Icon--valid';
    iconName = 'CircleCheckmark';
  } else if (state === 'error') {
    stateClassName = 'PasswordStrengthValidation__Icon--error';
    iconName = 'CircleAlert';
  } else {
    stateClassName = 'PasswordStrengthValidation__Icon--info';
    iconName = 'CircleInfo';
  }

  return (
    <Icon
      name={iconName}
      className={classnames('PasswordStrengthValidation__Icon', stateClassName)}
      size="medium"
    />
  );
}
export default class PasswordStrengthInput extends React.Component {
  static checkForNumber = (password) => /[0-9]/.test(password);

  static checkForLetter = (password) => /[a-zA-Z]/i.test(password);

  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
      validLength: false,
      validForNumber: false,
      validForLetter: false,
      showPassword: false,
      score: 0,
    };
  }

  checkForLength = (password) => {
    const { minLength } = this.props;
    return password.length >= minLength;
  };

  handleChange = (e) => {
    const { onChange, minScore } = this.props;
    const password = e.currentTarget.value;

    const validLength = this.checkForLength(password);
    const validForNumber = PasswordStrengthInput.checkForNumber(password);
    const validForLetter = PasswordStrengthInput.checkForLetter(password);

    let score = 0;

    if (!(validLength && validForNumber && validForLetter)) {
      score = minScore - 1; // Can never pass without one of these
    } else {
      score = parseInt(strength(password), 10);
    }

    this.setState({
      isValid: score >= minScore,
      score,
      validLength,
      validForNumber,
      validForLetter,
      hasChanged: true,
    });

    onChange(e);
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }));
  };

  render() {
    const { validLength, validForNumber, validForLetter, isValid, score } = this.state;

    let { hasChanged } = this.state;
    const { showPassword } = this.state;
    const { minLength } = this.props;

    const {
      inputProps,
      className,
      style,
      value,
      showValidation,
      error,
      scoreWords,
      showNewValidation,
      showEye,
      forceValidationCheckState,
    } = this.props;

    if (forceValidationCheckState) {
      hasChanged = true;
    }

    const wrapperClasses = [
      'PasswordStrength',
      'input-container',
      className || '',
      !showNewValidation && (value.length > 0 ? `is-strength-${score}` : ''),
    ];

    const inputClasses = ['PasswordStrength-input'];

    if (isValid ?? false) {
      inputClasses.push('is-password-valid');
    } else if (value.length > 0) {
      inputClasses.push('is-password-invalid');
      inputClasses.push('error');
    } else if (hasChanged) {
      inputClasses.push('error');
    }

    if (inputProps && inputProps.className) {
      inputClasses.push(inputProps.className);
    }

    let lengthValidationState;
    let letterValidationState;
    let numberValidationState;
    let strengthDesc = '';

    if (showNewValidation) {
      if (!hasChanged) {
        lengthValidationState = 'info';
        letterValidationState = 'info';
        numberValidationState = 'info';
      } else {
        lengthValidationState = validLength ? 'valid' : 'error';
        letterValidationState = validForLetter ? 'valid' : 'error';
        numberValidationState = validForNumber ? 'valid' : 'error';
      }
    } else if (!this.checkForLength(value)) {
      strengthDesc = 'too short.';
    } else if (!this.checkForNumber(value)) {
      strengthDesc = 'number required.';
    } else if (!this.checkForLetter(value)) {
      strengthDesc = 'letter required.';
    } else {
      strengthDesc = scoreWords[score];
    }

    return (
      <div className={wrapperClasses.join(' ')} style={style}>
        <input
          type={showPassword ? 'text' : 'password'}
          {...inputProps}
          className={inputClasses.join(' ')}
          onChange={this.handleChange}
          value={value}
          placeholder=""
          autoComplete="new-password"
        />
        {showEye && (
          <Button
            onClick={this.togglePasswordVisibility}
            variant="tertiary"
            className="PasswordEye"
            iconStart={<Icon size="medium" name={showPassword ? 'Visible' : 'Invisible'} />}
          />
        )}
        {!showNewValidation && (
          <>
            <div className="strength-bar" />
            <span className="strength-desc">{strengthDesc}</span>
            {showValidation && error && <div className="inline-error">{error}</div>}
          </>
        )}
        {showNewValidation && showValidation && (
          <div className="PasswordStrengthValidation">
            <div className="PasswordStrengthValidation__Row">
              <div className="PasswordStrengthValidation__Column">
                {validationIcon(lengthValidationState)}
                Minimum {minLength} characters
              </div>
              <div className="PasswordStrengthValidation__Column">
                {validationIcon(numberValidationState)}
                Must contain a number
              </div>
            </div>
            <div className="PasswordStrengthValidation__Row">
              <div className="PasswordStrengthValidation__Column">
                {validationIcon(letterValidationState)}
                Must contain a letter
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PasswordStrengthInput.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  minLength: PropTypes.number,
  minScore: PropTypes.number,
  scoreWords: PropTypes.array,
  value: PropTypes.string,
  style: PropTypes.object,
  showValidation: PropTypes.bool,
  showNewValidation: PropTypes.bool,
  showEye: PropTypes.bool,
  forceValidationCheckState: PropTypes.bool,
};

PasswordStrengthInput.defaultProps = {
  onChange: () => {},
  className: '',
  minLength: 6,
  minScore: 2,
  showValidation: false,
  showNewValidation: false,
  showEye: false,
  scoreWords: ['too weak', 'weak', 'good', 'great', 'whoa!', 'daaaang!'],
  forceValidationCheckState: false,
};
