import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const setFilter =
  (filterKey, filterValues, filterComparator = 'EQ') =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_FILTER,
      filterKey,
      filterValues,
      filterComparator,
    });

    dispatch({
      type: actionTypes.RESET_PAGING,
    });

    dispatch(fetchDevices());
  };
