import { ModalHeader } from 'common-js/components';
import BulkUpdateOverageForm from 'common-js/forms/BulkUpdateOverageForm';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { LinksSection } from 'common-js/modals/devices';
import { getSelectedLoadedDevices } from 'common-js/reducers/devices/selectors';
import { close as closeModal } from 'common-js/reducers/modal/actions';
import { Device } from 'common-js/types/Device';
import React from 'react';

interface BulkDataUsageLimitsModalProps {
  deviceOverrides?: Array<DeviceId>;
}

const BulkDataUsageLimitsModal: React.FC<BulkDataUsageLimitsModalProps> = ({ deviceOverrides }) => {
  const dispatch = useAppDispatch();
  const selectedDevices = useAppSelector(getSelectedLoadedDevices);
  const loadedDevices: Record<DeviceId, Device> | undefined = useAppSelector(
    (state) => state.devices?.devicesCache
  );

  let devicesFromOverrides: Array<Device> | undefined;
  if (deviceOverrides && loadedDevices) {
    devicesFromOverrides = Object.entries(loadedDevices)
      .filter(([id]) => deviceOverrides.includes(parseInt(id, 10)))
      .map(([, device]) => device);
  }

  const devices = devicesFromOverrides ?? selectedDevices;

  return (
    <>
      <ModalHeader title="Update data usage limit" icon="Gauge" />

      <div className="BulkDataActionModal__contents">
        {devices && devices.length > 0 && (
          <>
            <LinksSection
              title="Selected devices"
              devices={devices.map((device: Device) => device.name)}
              total={devices.length}
              limit={10}
            />
            <BulkUpdateOverageForm
              devices={devices}
              onCancel={() => {
                dispatch(closeModal());
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default BulkDataUsageLimitsModal;
