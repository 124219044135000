import * as DeviceStates from './deviceStates';

type DeviceStatusMap = Partial<Record<DeviceStates.DeviceState, string>>;

export const deviceStatusTooltipStrings: DeviceStatusMap = {
  [DeviceStates.LIVE_PENDING]:
    'This device is being activated on the Hologram network. Activation can take up to 15 minutes. Timing varies based on network partner activity.',
  [DeviceStates.PAUSED_PENDING_SYS]:
    'This device is being paused due to an insufficient account balance, data usage limit, or SMS usage. Once paused, it will not be able to transmit data or SMS services.',
  [DeviceStates.PAUSED_PENDING_USER]:
    'This device is being paused by a member of your organization. Once paused, it will not be able to transmit data or SMS services.',
  [DeviceStates.DEAD_PENDING]:
    'This device is being permanently deactivated. Once deactivated, it will no longer be able to transmit data or SMS services.',
  [DeviceStates.PAUSED_SYS]:
    'This device has been paused due to an insufficient account balance, data usage limit, or SMS usage. Once resolved, it may take up to 10 minutes to resume activity on the Hologram network.',
  [DeviceStates.PAUSED_USER]:
    'This device has been manually paused by a member of your organization. While paused, it cannot use data or SMS services.',
  [DeviceStates.LIVE]:
    'This device is activated and has access to the Hologram network. It is not currently transmitting data.',
  [DeviceStates.CONNECTED]:
    'This device is currently connected to the Hologram network in an active data session.',
  [DeviceStates.DEAD]:
    'This device has been permanently deactivated from use on the Hologram network.',
  [DeviceStates.INACTIVE]:
    'This device is assigned to your organization, but has not been activated. When ready, activate this device for use on the Hologram network.',
  [DeviceStates.FROZEN]:
    'This device is currently frozen and not using data or SMS services. You will not be charged for data usage, but charges associated with your device still apply.',
  [DeviceStates.TEST_ACTIVATE_PENDING]:
    'This device is being configured for pre-deployment testing. Configuration can take up to 15 minutes. Timing varies based on network partner activity.',
  [DeviceStates.TEST_AUTOACTIVATE_PENDING]:
    'This device is being configured for pre-deployment testing. Configuration can take up to 15 minutes. Timing varies based on network partner activity.',
  [DeviceStates.TEST_HIBERNATE_PENDING]:
    'This device is being configured for pre-deployment testing. Configuration can take up to 15 minutes. Timing varies based on network partner activity.',
  [DeviceStates.TEST_ACTIVATE]:
    'This device is in pre-deployment testing and is not currently transmitting data. After your testing period ends, the device will auto-activate.',
  [DeviceStates.TEST_AUTOACTIVATE]:
    'This device is in pre-deployment testing and is not currently transmitting data. After your testing period ends, the device will auto-activate.',
  [DeviceStates.TEST_HIBERNATE]:
    'This device is in pre-deployment testing and is not currently transmitting data. Once your testing period ends, the device will hibernate until you’re ready to deploy.',
  [DeviceStates.INACTIVE_TESTED]:
    'This device has used all available test data. You may activate this device for use on the Hologram network.',
};
