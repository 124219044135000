import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from '@holokit/core';
import Moment from 'moment-timezone';
import { Loader } from '@hologram-dimension/loader';
import { toByteString } from '../../../../common/utils/numberFormatter';
import { setCustomDateInspect } from '../../../../common/reducers/usage/actions';
import * as Paths from '../../../../common/constants/paths';

class DataUsedInspectTable extends React.Component {
  buildRowSet(group, idx) {
    let isLongTerm;
    let groupDate;
    let fulldate;

    if (typeof group.hour === 'undefined') {
      isLongTerm = true;
      groupDate = group.date;
      fulldate = Moment.utc(group.date, 'YYYY-MM-DD');
    } else {
      isLongTerm = false;
      groupDate = `${group.date}-${group.hour}`;
      fulldate = Moment.utc(groupDate, 'YYYY-MM-DD-H');
    }

    return [
      <tr className="body-data" key={idx}>
        <td className="left-padded align-top" rowSpan={group.top_devices.length + 1}>
          <div>
            <div target="_blank">
              <div>{fulldate.format('MMMM D, YYYY')}</div>
              {isLongTerm === false && (
                <div className="hour">{`${fulldate.format('h:ssA')} UTC`}</div>
              )}
            </div>
          </div>
        </td>
        <td>
          <div>
            {group.total_devices} Device{group.total_devices > 1 ? 's' : ''}
          </div>
        </td>
        <td>{toByteString(group.total_bytes / group.sessions)}</td>
        <td>{toByteString(group.total_bytes)}</td>
        <td>{toByteString(group.sessions)}</td>
        <td>{group.sms}</td>
        <td className="right-padded inspectbutton-td">
          <div>
            <Button
              iconLeft="Review"
              onClick={() => this.openDeviceBreakdown(groupDate, isLongTerm)}
              small
              type="secondary"
            >
              Review
            </Button>
          </div>
        </td>
      </tr>,
    ].concat(
      group.top_devices.map((deviceRow, uIdx) => (
        <tr
          className={`body-data row-subdued${uIdx === group.top_devices.length - 1 ? ' last' : ''}`}
        >
          <td>
            <div>
              <a
                className="link-out"
                target="_blank"
                href={Paths.withContext(
                  Paths.DEVICE_LANDING.replace(`:${Paths.DEVICE_PARAM_NAME}`, deviceRow.deviceid)
                )}
                rel="noreferrer"
              >
                {deviceRow.devicename}
                <Icon
                  name="Arrow--northeast"
                  size="minor"
                  svgProps={{ style: { fill: '#04c0ff' } }}
                />
              </a>
            </div>
            {uIdx === group.top_devices.length - 1 &&
              group.top_devices.length < group.total_devices && (
                <div className="subheader">
                  Top 5 of {group.total_devices} active devices shown.
                </div>
              )}
          </td>
          <td>{toByteString(deviceRow.bytes / deviceRow.sessions)}</td>
          <td>{toByteString(deviceRow.bytes)}</td>
          <td>{toByteString(deviceRow.sessions)}</td>
          <td>{deviceRow.sms}</td>
          <td />
        </tr>
      ))
    );
  }

  openDeviceBreakdown(date, isLongTerm) {
    const { setCustomDateInspect_, containerContext, openModal } = this.props;

    if (isLongTerm === true) {
      const newDateStart = Moment.utc(date).startOf('day');
      const newDateEnd = Moment.utc(date).endOf('day');
      setCustomDateInspect_(
        { startDate: newDateStart.valueOf(), endDate: newDateEnd.valueOf() },
        containerContext
      );
    } else {
      const newDateStart = Moment.utc(date, 'YYYY-MM-DD-H').startOf('hour');
      const newDateEnd = Moment.utc(date, 'YYYY-MM-DD-H').endOf('hour');
      setCustomDateInspect_(
        { startDate: newDateStart.valueOf(), endDate: newDateEnd.valueOf() },
        containerContext
      );
    }

    openModal(isLongTerm);
  }

  render() {
    const { data, loading } = this.props;

    if (loading) {
      return (
        <div className="UsageTable DataUsedInspectTable">
          <div className="table-loading">
            <Loader className="DimensionLoader__legacy-padding-override" />
          </div>
        </div>
      );
    }

    return (
      <div className="UsageTable DataUsedInspectTable">
        {data.length === 0 ? (
          <div className="UsageTable-nodata">
            <Icon name="Circle--info" size="major" svgProps={{ style: { fill: '#8008f7' } }} />
            <div>
              There are no data sessions to display for this set of devices and specific time frame.
              Try adjusting tags or the selected date range.
            </div>
          </div>
        ) : (
          <div>
            <table className="UsageTable">
              <tr className="header">
                <th className="left-padded" style={{ width: '22%' }}>
                  date
                </th>
                <th style={{ width: '22%' }}>devices</th>
                <th style={{ width: '12%' }}>avg. daily usage</th>
                <th style={{ width: '12%' }}>total used</th>
                <th style={{ width: '8%' }}>sessions</th>
                <th style={{ width: '8%' }}>sms</th>
                <th style={{ width: '1%' }}> </th>
              </tr>
              {data.map((d, idx) => this.buildRowSet(d, idx))}
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    data: state.usage.charts.datausedinspect.data,
    loading: state.usage.charts.datausedinspect.loading,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setCustomDateInspect_: setCustomDateInspect,
      },
      dispatch
    )
)(DataUsedInspectTable);
