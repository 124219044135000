import { useEffect, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useGetWebhook from 'common-js/api/devices/useGetWebhook';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { WebhookModel } from 'common-js/models';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';

// loads the device's webhook into local state on render
// shares an API with useState - provides a setter for when updates are made to the webhook
// also returns loading state as the third item in the return array
const useGetCurrentDeviceWebhook = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const [webhook, setWebhook] = useState(new WebhookModel());
  const [loading, setLoading] = useState(false);
  const getWebhook = useGetWebhook();

  useEffect(() => {
    if (device.id) {
      // only load the webhook if the current device is already loaded; we need to know the current device's ID
      setLoading(true);
      getWebhook(device.id)
        .then((hook) => setWebhook(hook)) // getWebhook already returns WebhookModel, no need to wrap it
        .catch((error) => dispatch(pushGlobalMessage(error, 'error')))
        .finally(() => setLoading(false));
    }
  }, [device.id, dispatch, getWebhook, setLoading]);
  return [webhook, setWebhook, loading];
};

export default useGetCurrentDeviceWebhook;
