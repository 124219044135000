import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import {
  TIMEQUICKFILTER_THISMONTH,
  TIMEQUICKFILTER_CUSTOM,
} from 'common-js/constants/quickFilters';
import { DeviceModel } from 'common-js/models';
import { getQuickFilter } from 'common-js/utils/quickFilter';
import * as actionTypes from './actionTypes';
import * as deviceFilterActionType from '../deviceFilter/actionTypes';

export const initialState = {
  loading: false,
  error: null,
  device: new DeviceModel(),

  // device metadata
  euiccHistory: [],
  euiccTestHistory: [],
  profiles: [],
  sessionSample: [],

  // status tab
  liveUsage: {
    loading: false,
    error: null,
    data: [],
  },

  statusHistory: [],

  timeFilter: {
    startDate: moment.utc().startOf('month').valueOf(),
    endDate: moment.utc().endOf('day').valueOf(),
  },
  timeQuickFilter: TIMEQUICKFILTER_THISMONTH,

  pollingPaused: false,

  // DeviceHeader
  updatingTags: false,
};

const handleSetLoading = (state, action) => ({
  ...state,
  loading: action.payload?.loading ?? true,
});

const handleSetCurrentDevice = (state, action) => {
  const newDevice = action.payload?.currentDevice ?? new DeviceModel();

  // we need to copy over statusHistory / statusReason from the old state
  // those come from a separate API call, and so they are not refreshed when just fetching the device
  newDevice.statusHistory = state.device.statusHistory;
  newDevice.statusReason = state.device.statusReason;

  return {
    ...state,
    device: newDevice,
  };
};

const handleSetCurrentDeviceWithMetadata = (state, action) => {
  const { device, error, euiccHistory, euiccTestHistory, profiles, sessionSample, statusHistory } =
    action.payload ?? {};

  const newState = cloneDeep(state);
  newState.loading = false;

  if (error !== undefined) {
    newState.error = error;
  }
  if (device !== undefined) {
    newState.device = device;
  }
  if (euiccHistory !== undefined) {
    newState.euiccHistory = euiccHistory;
  }
  if (euiccTestHistory !== undefined) {
    newState.euiccTestHistory = euiccTestHistory;
  }
  if (profiles !== undefined) {
    newState.profiles = profiles;
  }
  if (sessionSample !== undefined) {
    newState.sessionSample = sessionSample;
  }
  if (statusHistory !== undefined) {
    // some fields in the DeviceModel come from the statusHistory metadata, let's set those now
    newState.device.statusHistory = statusHistory;
    newState.device.statusReason = statusHistory[0] ? statusHistory[0].reason : null;
  }

  return newState;
};

const handleSetLiveUsage = (state, action) => ({
  ...state,
  liveUsage: {
    data: action.payload.data ?? [],
    loading: action.payload.loading ?? false,
    error: action.payload.error ?? null,
  },
});

const handleSetTimeQuickFilter = (state, action) => {
  const { quickFilter } = action.payload ?? {};
  const { startDate, endDate } = getQuickFilter(quickFilter);

  return {
    ...state,
    timeFilter: {
      ...state.timeFilter,
      startDate,
      endDate,
    },
    timeQuickFilter: quickFilter,
  };
};

const handleSetCustomDate = (state, action) => {
  const { startDate, endDate } = action.payload ?? {};

  return {
    ...state,
    timeFilter: {
      ...state.timeFilter,
      startDate,
      endDate,
    },
    timeQuickFilter: TIMEQUICKFILTER_CUSTOM,
  };
};

const handleSetPollingPause = (state, action) => ({
  ...state,
  pollingPaused: action.payload?.pause ?? false,
});

// TODO: anything below this line is the result of moving over the rest of singleDevice actions
// it should be refactored to simplify redux usage where possible

// spacebridge tab
const handleSetTunnelable = (state, action) => {
  const newDevice = cloneDeep(state.device);
  newDevice.tunnelable = action?.isTunnelable;
  return {
    ...state,
    device: newDevice,
  };
};

// data limits tab
const handleSetDataUsage = (state, action) => {
  const newDevice = cloneDeep(state.device);
  newDevice.data_threshold = action.threshold;
  return {
    ...state,
    device: newDevice,
  };
};

// DeviceHeader
const handleAddTagToDevice = (state, action) => {
  const newDevice = cloneDeep(state.device);
  newDevice.tags = newDevice.tags.concat([action.tagId]);
  return {
    ...state,
    device: newDevice,
    updatingTags: true,
  };
};

const handleRemoveTagFromDevice = (state, action) => {
  const newDevice = cloneDeep(state.device);
  newDevice.tags = newDevice.tags.filter((tag) => tag !== action.tagId);
  return {
    ...state,
    device: newDevice,
    updatingTags: true,
  };
};

const handleTagsFinishedUpdating = (state) => ({
  ...state,
  updatingTags: false,
});

const handleAddingNewTag = (state) => ({
  ...state,
  updatingTags: true,
});

const handleNewTagAdded = (state, action) => {
  const newDevice = cloneDeep(state.device);
  newDevice.tags.push(action.newTag.id);
  return {
    ...state,
    device: newDevice,
    updatingTags: false,
  };
};

const handleChangeDeviceNameRequest = (state, action) => {
  const newDevice = cloneDeep(state.device);
  newDevice.name = action.name;
  return {
    ...state,
    device: newDevice,
  };
};

const handleChangeDeviceNameSuccess = (state, action) => {
  const newDevice = cloneDeep(state.device);
  newDevice.name = action.device.name;
  return {
    ...state,
    device: newDevice,
  };
};

// This is the end of the reducer functions which were moved over from singleDevice

const actionMap = {
  [actionTypes.SET_LOADING]: handleSetLoading,
  [actionTypes.SET_CURRENT_DEVICE]: handleSetCurrentDevice,
  [actionTypes.SET_CURRENT_DEVICE_WITH_METADATA]: handleSetCurrentDeviceWithMetadata,
  [actionTypes.SET_CURRENT_DEVICE_LIVE_USAGE]: handleSetLiveUsage,
  [actionTypes.SET_TIME_QUICK_FILTER]: handleSetTimeQuickFilter,
  [actionTypes.SET_CUSTOM_DATE]: handleSetCustomDate,
  [actionTypes.SET_POLLING_PAUSE]: handleSetPollingPause,

  // TODO: anything below this line was moved over from singleDevice and should be refactored

  [actionTypes.SET_TUNNELABLE_SUCCESS]: handleSetTunnelable,
  [actionTypes.SET_DATA_USAGE_ALERT_SUCCESS]: handleSetDataUsage,
  [actionTypes.ADD_TAG_TO_DEVICE_REQUEST]: handleAddTagToDevice,
  [actionTypes.REMOVE_TAG_FROM_DEVICE_REQUEST]: handleRemoveTagFromDevice,
  [deviceFilterActionType.ADD_NEW_TAG_REQUEST]: handleAddingNewTag,
  [deviceFilterActionType.ADD_NEW_TAG_SUCCESS]: handleNewTagAdded,
  [actionTypes.ADD_TAG_TO_DEVICE_SUCCESS]: handleTagsFinishedUpdating,
  [actionTypes.ADD_TAG_TO_DEVICE_ERROR]: handleTagsFinishedUpdating,
  [actionTypes.REMOVE_TAG_FROM_DEVICE_SUCCESS]: handleTagsFinishedUpdating,
  [actionTypes.REMOVE_TAG_FROM_DEVICE_ERROR]: handleTagsFinishedUpdating,
  [deviceFilterActionType.ADD_NEW_TAG_ERROR]: handleTagsFinishedUpdating,
  [actionTypes.CHANGE_DEVICE_NAME_REQUEST]: handleChangeDeviceNameRequest,
  [actionTypes.CHANGE_DEVICE_NAME_SUCCESS]: handleChangeDeviceNameSuccess,

  // This is the end of actions which were moved over from singleDevice
};

/* TODO: this should probably be called singleDeviceReducer, but the DataUsageAlerts on the home page still
   make use of the singleDevice redux slice, so we must choose a new name for now. */
const currentDeviceReducer = (state = initialState, action = {}) => {
  const reducer = actionMap[action.type];
  return reducer ? reducer(state, action) : state;
};

export default currentDeviceReducer;
