import PropTypes from 'prop-types';
import React from 'react';
import onClickOutsideHOC from 'react-onclickoutside';

import { Icon } from '@holokit/core';

class DropDownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      containerTop: 0,
    };
  }

  handleClickOutside() {
    this.props.onClose();

    this.setState({
      isOpen: false,
    });
  }

  UNSAFE_componentWillMount() {
    this.onBodyKeyDown = function (e) {
      if (e.keyCode === 27) {
        this.setState({
          isOpen: false,
        });
      }
    }.bind(this);

    document.addEventListener('keydown', this.onBodyKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onBodyKeyDown);
  }

  openMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      containerTop: this.refs.dropdownbutton.offsetHeight + 2,
    });
    this.props.onOpen();
  };

  render() {
    const {
      button,
      buttonClasses,
      buttonLabel,
      classNames,
      direction,
      disabled,
      emptyButtonLabel,
      iconName,
      placement,
    } = this.props;

    const { containerTop, isOpen } = this.state;
    return (
      <div className={`dropdown ${classNames}`} ref="dropdownbutton">
        {direction === 'up' ? (
          <div
            className={`dropdown-container direction-${direction}${
              this.state.isOpen ? ' active' : ''
            }`}
            onClick={() => this.handleClickOutside()}
          >
            {this.props.children}
          </div>
        ) : null}

        <div
          className={`dropdown-trigger ${buttonClasses}${isOpen ? ' active' : ''}`}
          onMouseDown={disabled ? null : this.openMenu}
        >
          <div className="grid-row">
            {button && button}
            {iconName ? (
              <Icon name={iconName} size="minor" svgProps={{ style: { fill: '#2F6AFF' } }} />
            ) : null}
            {buttonLabel ? (
              <div className="btn-label">{buttonLabel}</div>
            ) : (
              <div className="btn-label dimmed">{emptyButtonLabel}</div>
            )}
          </div>
        </div>

        {(direction === 'down' || direction === 'down-left') && isOpen ? (
          <div
            className={`dropdown-container direction-${direction}${isOpen ? ' active' : ''}`}
            onClick={() => this.handleClickOutside()}
            style={{ top: containerTop }}
          >
            {this.props.children}
          </div>
        ) : null}

        {direction === 'down-cover' ? (
          placement === 'right' ? (
            <div
              className={`dropdown-container direction-${direction}${isOpen ? ' active' : ''}`}
              onClick={() => this.handleClickOutside()}
              style={{ top: '5px', right: '0' }}
            >
              {this.props.children}
            </div>
          ) : (
            <div
              className={`dropdown-container direction-${direction}${isOpen ? ' active' : ''}`}
              onClick={() => this.handleClickOutside()}
              style={{ top: '5px', left: '5px' }}
            >
              {this.props.children}
            </div>
          )
        ) : null}
      </div>
    );
  }
}

DropDownMenu.propTypes = {
  buttonClasses: PropTypes.string,
  classNames: PropTypes.string,
  iconName: PropTypes.string,
  buttonLabel: PropTypes.string,
  emptyButtonLabel: PropTypes.string,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  buttonElementType: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};
DropDownMenu.defaultProps = {
  buttonClasses: 'btn btn-primary btn-small btn-dropdown',
  iconName: null,
  buttonLabel: null,
  emptyButtonLabel: null,
  direction: 'down',
  disabled: false,
  onClose: () => {},
  onOpen: () => {},
  classNames: '',
  button: null,
};

export default onClickOutsideHOC(DropDownMenu);
