import _ from 'lodash';

import * as logActionTypes from '../log/actionTypes';
import * as Messages from './models';
import * as accountActionTypes from '../account/actionTypes';

const initialState = {
  messages: [],
};

function consoleReducer(state = initialState, action) {
  switch (action.type) {
    case logActionTypes.GET_ALL_REQUEST: {
      if (!action.isContinuing && !action.isUpdating) {
        return { ...state, messages: [] };
      }

      return { ...state };
    }

    case logActionTypes.GET_ALL_SUCCESS: {
      if (action.isContinuing) {
        // infinite scrolling here.  append them to the current logs.
        return {
          ...state,
          messages: state.messages.concat(action.logs.map(buildMessageFromLog(action.currentApps))),
          hasMoreLogs: action.hasMoreLogs,
          isContinuing: false,
          isUpdating: false,
        };
      }
      if (action.isUpdating) {
        // logs auto updating.  find new logs, convert to console messages, and prepend to messages array marked as unRevealed
        const newLogs = action.logs
          .filter((log) => _.findIndex(action.currentLogs, { id: log.id }) === -1)
          .map((log) => ({ ...log, unRevealed: true }));

        return {
          ...state,
          messages: newLogs.map(buildMessageFromLog(action.currentApps)).concat(state.messages),
          hasMoreLogs: action.hasMoreLogs,
          isLoadingLogs: false,
          isContinuing: false,
          isUpdating: false,
        };
      }
      // logs loading for first time.
      return {
        ...state,
        messages: action.logs.map(buildMessageFromLog(action.currentApps)),
        hasMoreLogs: action.hasMoreLogs,
        isLoadingLogs: false,
        isContinuing: false,
        isUpdating: false,
      };
    }

    case logActionTypes.REVEAL_LOGS:
      return {
        ...state,
        messages: state.messages.map((message) => ({
          ...message,
          unRevealed: false,
        })),
      };

    case accountActionTypes.LOGOUT_REQUEST:
    case accountActionTypes.LOGIN_REQUEST:
      return { ...state, ...initialState };

    default:
      return state;
  }
}

function buildMessageFromLog(apps) {
  return function (log) {
    switch (Messages.detectMessageType(log)) {
      case Messages.OUTBOUND:
        return new Messages.OutboundMessage(log, apps);
      case Messages.HEARTBEAT:
        return new Messages.HeartbeatMessage(log, apps);
      case Messages.MODIFIER:
        return new Messages.ModifierMessage(log, apps);
      default:
        return new Messages.Message();
    }
  };
}

export default consoleReducer;
