import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useOnResumeEvent from './useOnResumeEvent';

interface UseHandlePauseProps {
  onStateChange?: () => void;
}

const useHandleResume = ({ onStateChange }: UseHandlePauseProps) => {
  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const onResumeEvent = useOnResumeEvent();

  const onConfirm = useCallback(async () => {
    await onResumeEvent();
    if (onStateChange) {
      onStateChange();
    }
  }, [onResumeEvent, onStateChange]);

  return useCallback(() => {
    dispatch(
      openModal('ResumeSimModal', {
        devices: [device],
        onConfirm,
      })
    );
  }, [dispatch, device, onConfirm]);
};

export default useHandleResume;
