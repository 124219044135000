import { Loader } from '@hologram-dimension/loader';
import { Panel } from '@hologram-dimension/panel';
import logoBlackWithText from 'img/logo/hologram_logo_black.svg';
import React from 'react';

const Loading: React.FC = () => (
  <div className="page-register Register">
    <div className="success-container">
      <img src={logoBlackWithText} alt="Hologram logo" className="logo" />
      <div className="title-wrapper">
        <h2 className="title">Building your IoT dashboard</h2>
      </div>
      <Panel className="loaderPanel" header="">
        <Loader>You’ll be online in just a moment.</Loader>
      </Panel>
    </div>
  </div>
);

export default Loading;
