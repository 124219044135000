import queryString from 'query-string';
import { browserHistory } from 'react-router';

export const setActivityTableQueryString = (pageInfo) => {
  const page = pageInfo.currentPage;
  const parsed = queryString.parse(window.location.search);

  if (page) {
    parsed.page = page;
  } else {
    delete parsed.page;
  }

  browserHistory.replace({
    pathname: window.location.pathname,
    search: `?${queryString.stringify(parsed)}`,
  });
};

export default setActivityTableQueryString;
