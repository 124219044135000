import React from 'react';
import classNames from 'clsx';

function SummaryTableTotalRow({ label, total, isGreen, isBold }) {
  return (
    <tr className="SummaryTable__row">
      <td
        colSpan={3}
        className={classNames('SummaryTable__cell', 'SummaryTable__cell--total-label', {
          'SummaryTable__cell--bold': isBold,
        })}
      >
        {label}
      </td>
      <td
        className={classNames('SummaryTable__cell', 'SummaryTable__cell--total', {
          'SummaryTable__cell--bold': isBold,
          'SummaryTable__cell--green': isGreen,
        })}
      >
        {total}
      </td>
    </tr>
  );
}

export default SummaryTableTotalRow;
