import React from 'react';
import { Icon } from '@holokit/core';
import classNames from 'clsx';

function Tag(props) {
  const tagClassNames = classNames({
    selected: props.isSelected,
    isValid: props.isValidClassPresent,
    isInvalid: props.isInvalidClassPresent,
  });

  return (
    <div className={`${props.classNames.selectedTag} ${tagClassNames}`} onClick={props.onSelectTag}>
      {props.tag.name}
      <button className="Button--unstyled" onClick={props.onDelete}>
        <Icon
          classes="tag-delete-icon"
          name="Close"
          size="micro"
          svgProps={{ style: { fill: '#000000' } }}
        />
      </button>
    </div>
  );
}

export default Tag;
