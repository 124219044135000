import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { openModal } from 'common-js/reducers/modal/actions';
import { setTunnelableBulk } from 'common-js/reducers/devices/actions';
import { getSelectedDeviceIds } from 'common-js/reducers/devices/selectors';
import { selectHasTunneling } from 'common-js/reducers/releaseFlag/selectors';

const useEnableTunnelingItem = () => {
  const dispatch = useAppDispatch();
  const selectedDeviceIds = useAppSelector(getSelectedDeviceIds);
  const hasTunneling = useAppSelector(selectHasTunneling);

  const onClick = useCallback(() => {
    dispatch(
      openModal('BulkActionModal', {
        actionTitle: 'Enable tunneling',
        buttonLabel: 'Enable tunneling',
        deviceIds: selectedDeviceIds,
        action: () => dispatch(setTunnelableBulk(selectedDeviceIds, true)),
      })
    );
  }, [dispatch, selectedDeviceIds]);

  return {
    label: 'Enable tunneling',
    icon: 'Spacebridge',
    include: hasTunneling,
    selectionLimit: 10,
    onClick,
  };
};

export default useEnableTunnelingItem;
