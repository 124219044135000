import { AppNotificationBanner, useStatusPage } from '@hologram-hyper-dashboard/components';
import useDebounceCallback from 'activation/common/hooks/useDebounceCallback';
import { ACCOUNT_BILLING, withContext } from 'common-js/constants/paths';
import { useAppDispatch, useAppSelector } from 'common-js/hooks';
import { setDismissedAlerts } from 'common-js/reducers/account/actions/actions';
import { selectDismissedAlerts } from 'common-js/reducers/account/selectors';
import {
  getDeviceCount,
  getPostPay,
  getUserPermissions,
  selectBillingInformation,
} from 'common-js/reducers/organization/selectors';
import { BILLING_VISIBLE, type Permission } from 'common-js/utils/permissions';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState, type FC } from 'react';

interface AlertBannerProps {
  unauthenticatedOnly?: boolean;
}

const AlertBanner: FC<AlertBannerProps> = ({ unauthenticatedOnly = false }) => {
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector(getUserPermissions) as Array<Permission>;
  const hasBillingVisible = userPermissions.includes(BILLING_VISIBLE);

  const billingInformation = useAppSelector(selectBillingInformation);

  // Status page
  const { status, isLoading: isLoadingStatusPage, error: statusPageError } = useStatusPage();

  // Billing address
  const isBillingAddressSet = billingInformation?.isBillingAddressSet ?? false;
  const orgDeviceCount = useAppSelector(getDeviceCount) ?? 0;

  // Failed charges
  const hasPostpay = useAppSelector(getPostPay);
  const failedBillingAttempts = billingInformation?.failedBillingAttempts ?? 0;

  // Dismiss handling
  const storedSkippedIds = useAppSelector(selectDismissedAlerts);
  const skippedIds = useMemo(() => new Set(storedSkippedIds), [storedSkippedIds]);

  const onDismiss = useCallback(
    (id: string) => {
      const newSkippedIds = new Set(skippedIds.add(id));
      dispatch(setDismissedAlerts([...newSkippedIds.values()]));
    },
    [dispatch, skippedIds]
  );

  // TODO: Check for in-progress actions related to these instead of just debouncing?
  const [allowUnauthenticatedErrors, setAllowUnauthenticatedErrors] = useState(false);

  const handleShowAuthenticatedErrors = useCallback(() => {
    setAllowUnauthenticatedErrors(true);
  }, []);

  useDebounceCallback(handleShowAuthenticatedErrors, 2000);

  // Build out notifications
  const notifications = useMemo(() => {
    const newNotifications: GetReactParameterBag<typeof AppNotificationBanner>['notifications'] =
      [];

    if (!isLoadingStatusPage && !statusPageError && status) {
      const { incidents, scheduled_maintenances: maintenances } = status;

      incidents
        ?.filter((incident) => incident.status !== 'resolved' && !skippedIds.has(incident.id))
        ?.sort((a, b) => {
          if (a.impact === b.impact) {
            return (
              DateTime.fromISO(b.created_at).toMillis() - DateTime.fromISO(a.created_at).toMillis()
            );
          }

          if (a.impact === 'critical') {
            return -1;
          }
          if (b.impact === 'critical') {
            return 1;
          }

          if (a.impact === 'major') {
            return -1;
          }

          if (b.impact === 'major') {
            return 1;
          }

          return 0;
        })
        .forEach((incident) => {
          newNotifications.push({
            type:
              incident.impact === 'critical' || incident.impact === 'major' ? 'danger' : 'warning',
            children: incident.name,
            dismissable: !unauthenticatedOnly,
            onDismiss: () => {
              onDismiss(incident.id);
            },
            action: 'Check system status',
            href: process.env.VITE_STATUSPAGE_URL,
          });
        });

      maintenances
        ?.filter(
          (maintenance) => maintenance.status !== 'resolved' && !skippedIds.has(maintenance.id)
        )
        .forEach((maintenance) => {
          newNotifications.push({
            type: 'warning',
            children: maintenance.name,
            dismissable: !unauthenticatedOnly,
            onDismiss: () => {
              onDismiss(maintenance.id);
            },
            action: 'View system status',
            href: process.env.VITE_STATUSPAGE_URL,
          });
        });
    }

    if (!unauthenticatedOnly && allowUnauthenticatedErrors) {
      if (failedBillingAttempts > 0 && !hasPostpay) {
        newNotifications.push({
          type: 'danger',
          children:
            'Your account could not be charged which may result in your SIMs being paused. Add a balance to unpause your SIMs.',
          action: 'Add account balance',
          to: withContext(ACCOUNT_BILLING),
        });
      }

      if (hasBillingVisible && !isBillingAddressSet && orgDeviceCount > 0) {
        newNotifications.push({
          type: 'warning',
          children:
            'A billing address is required to refill your account, pay your balance, activate SIMs, and change data plans.',
          action: 'Add a billing address',
          to: withContext(ACCOUNT_BILLING),
        });
      }
    }

    return newNotifications;
  }, [
    allowUnauthenticatedErrors,
    failedBillingAttempts,
    hasBillingVisible,
    hasPostpay,
    isBillingAddressSet,
    isLoadingStatusPage,
    onDismiss,
    orgDeviceCount,
    skippedIds,
    status,
    statusPageError,
    unauthenticatedOnly,
  ]);

  return (
    <div id="site-banners">
      {notifications.length > 0 && <AppNotificationBanner notifications={notifications} />}
    </div>
  );
};

export default AlertBanner;
