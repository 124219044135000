const PREFIX = 'SINGLEDEVICE_';

export const VIEWMORE_LIVEUSAGE = `${PREFIX}VIEWMORE_LIVEUSAGE`;
export const REGISTER_DEVICE = `${PREFIX}REGISTER_DEVICE`;
export const UNREGISTER_DEVICE = `${PREFIX}UNREGISTER_DEVICE`;
export const SET_QUICK_FILTER = `${PREFIX}SET_QUICK_FILTER`;
export const SET_CUSTOM_DATE = `${PREFIX}SET_CUSTOM_DATE`;
export const RUN_REPORT_START = `${PREFIX}RUN_REPORT_START`;
export const RUN_REPORT_FINISH = `${PREFIX}RUN_REPORT_FINISH`;
export const SET_REPORT_DATE_TITLE = `${PREFIX}SET_REPORT_DATE_TITLE`;
export const RUN_INSPECT_REPORT_START = `${PREFIX}RUN_INSPECT_REPORT_START`;
export const RUN_INSPECT_REPORT_FINISH = `${PREFIX}RUN_INSPECT_REPORT_FINISH`;

export const GET_DEVICE_REQUEST = `${PREFIX}GET_DEVICE_REQUEST`;
export const GET_DEVICE_SUCCESS = `${PREFIX}GET_DEVICE_SUCCESS`;
export const GET_DEVICE_ERROR = `${PREFIX}GET_DEVICE_ERROR`;

export const GET_DATAUSED_REQUEST = `${PREFIX}GET_DATAUSED_REQUEST`;
export const GET_DATAUSED_SUCCESS = `${PREFIX}GET_DATAUSED_SUCCESS`;
export const GET_DATAUSED_ERROR = `${PREFIX}GET_DATAUSED_ERROR`;

export const GET_DATAUSED_INSPECT_REQUEST = `${PREFIX}GET_DATAUSED_INSPECT_REQUEST`;
export const GET_DATAUSED_INSPECT_SUCCESS = `${PREFIX}GET_DATAUSED_INSPECT_SUCCESS`;
export const GET_DATAUSED_INSPECT_ERROR = `${PREFIX}GET_DATAUSED_INSPECT_ERROR`;

export const GET_SESSIONSAMPLE_REQUEST = `${PREFIX}GET_SESSIONSAMPLE_REQUEST`;
export const GET_SESSIONSAMPLE_SUCCESS = `${PREFIX}GET_SESSIONSAMPLE_SUCCESS`;
export const GET_SESSIONSAMPLE_ERROR = `${PREFIX}GET_SESSIONSAMPLE_ERROR`;

export const GET_DEVICEEUICCHISTORY_REQUEST = `${PREFIX}GET_DEVICEEUICCHISTORY_REQUEST`;
export const GET_DEVICEEUICCHISTORY_SUCCESS = `${PREFIX}GET_DEVICEEUICCHISTORY_SUCCESS`;
export const GET_DEVICEEUICCHISTORY_ERROR = `${PREFIX}GET_DEVICEEUICCHISTORY_ERROR`;

export const GET_DEVICEEUICCTESTHISTORY_REQUEST = `${PREFIX}GET_DEVICEEUICCTESTHISTORY_REQUEST`;
export const GET_DEVICEEUICCTESTHISTORY_SUCCESS = `${PREFIX}GET_DEVICEEUICCTESTHISTORY_SUCCESS`;
export const GET_DEVICEEUICCTESTHISTORY_ERROR = `${PREFIX}GET_DEVICEEUICCTESTHISTORY_ERROR`;

export const GET_DEVICEPROFILES_REQUEST = `${PREFIX}GET_DEVICEPROFILES_REQUEST`;
export const GET_DEVICEPROFILES_SUCCESS = `${PREFIX}GET_DEVICEPROFILES_SUCCESS`;
export const GET_DEVICEPROFILES_ERROR = `${PREFIX}GET_DEVICEPROFILES_ERROR`;

export const GET_LIVEUSAGE_REQUEST = `${PREFIX}GET_LIVEUSAGE_REQUEST`;
export const GET_LIVEUSAGE_SUCCESS = `${PREFIX}GET_LIVEUSAGE_SUCCESS`;
export const GET_LIVEUSAGE_ERROR = `${PREFIX}GET_LIVEUSAGE_ERROR`;

export const GET_DEVICEBREAKDOWN_REQUEST = `${PREFIX}GET_DEVICEBREAKDOWN_REQUEST`;
export const GET_DEVICEBREAKDOWN_SUCCESS = `${PREFIX}GET_DEVICEBREAKDOWN_SUCCESS`;
export const GET_DEVICEBREAKDOWN_ERROR = `${PREFIX}GET_DEVICEBREAKDOWN_ERROR`;

export const GET_PHONE_NUMBER_COST_REQUEST = `${PREFIX}GET_PHONE_NUMBER_COST_REQUEST`;
export const GET_PHONE_NUMBER_COST_SUCCESS = `${PREFIX}GET_PHONE_NUMBER_COST_SUCCESS`;
export const GET_PHONE_NUMBER_COST_ERROR = `${PREFIX}GET_PHONE_NUMBER_COST_ERROR`;

export const ADD_PHONE_NUMBER_REQUEST = `${PREFIX}ADD_PHONE_NUMBER_REQUEST`;
export const ADD_PHONE_NUMBER_SUCCESS = `${PREFIX}ADD_PHONE_NUMBER_SUCCESS`;
export const ADD_PHONE_NUMBER_ERROR = `${PREFIX}ADD_PHONE_NUMBER_ERROR`;

export const TEST_DEVICE_REQUEST = `${PREFIX}TEST_DEVICE_REQUEST`;
export const TEST_DEVICE_SUCCESS = `${PREFIX}TEST_DEVICE_SUCCESS`;
export const TEST_DEVICE_ERROR = `${PREFIX}TEST_DEVICE_ERROR`;

export const REQUEST_DEVICE_TEST_REQUEST = `${PREFIX}REQUEST_DEVICE_TEST_REQUEST`;
export const REQUEST_DEVICE_TEST_SUCCESS = `${PREFIX}REQUEST_DEVICE_TEST_SUCCESS`;
export const REQUEST_DEVICE_TEST_ERROR = `${PREFIX}REQUEST_DEVICE_TEST_ERROR`;

export const START_DEVICE_POLLING = `${PREFIX}START_DEVICE_POLLING`;
export const STOP_DEVICE_POLLING = `${PREFIX}STOP_DEVICE_POLLING`;
export const VIEW_CONNECTION_INFO = `${PREFIX}VIEW_CONNECTION_INFO`;

export const EXPORT_DATAUSED_REQUEST = `${PREFIX}EXPORT_DATAUSED_REQUEST`;
export const EXPORT_DATAUSED_BATCH_SUCCESS = `${PREFIX}EXPORT_DATAUSED_BATCH_SUCCESS`;
export const EXPORT_DATAUSED_SUCCESS = `${PREFIX}EXPORT_DATAUSED_SUCCESS`;
export const EXPORT_DATAUSED_ERROR = `${PREFIX}EXPORT_DATAUSED_ERROR`;
export const EXPORT_DATAUSED_TOOLARGE = `${PREFIX}EXPORT_DATAUSED_TOOLARGE`;

export const EXPORT_DATAUSEDINSPECT_REQUEST = `${PREFIX}EXPORT_DATAUSEDINSPECT_REQUEST`;
export const EXPORT_DATAUSEDINSPECT_BATCH_SUCCESS = `${PREFIX}EXPORT_DATAUSEDINSPECT_BATCH_SUCCESS`;
export const EXPORT_DATAUSEDINSPECT_SUCCESS = `${PREFIX}EXPORT_DATAUSEDINSPECT_SUCCESS`;
export const EXPORT_DATAUSEDINSPECT_ERROR = `${PREFIX}EXPORT_DATAUSEDINSPECT_ERROR`;
export const EXPORT_DATAUSEDINSPECT_TOOLARGE = `${PREFIX}EXPORT_DATAUSEDINSPECT_TOOLARGE`;

export const EXPORT_DEVICEBREAKDOWN_REQUEST = `${PREFIX}EXPORT_DEVICEBREAKDOWN_REQUEST`;
export const EXPORT_DEVICEBREAKDOWN_BATCH_SUCCESS = `${PREFIX}EXPORT_DEVICEBREAKDOWN_BATCH_SUCCESS`;
export const EXPORT_DEVICEBREAKDOWN_SUCCESS = `${PREFIX}EXPORT_DEVICEBREAKDOWN_SUCCESS`;
export const EXPORT_DEVICEBREAKDOWN_ERROR = `${PREFIX}EXPORT_DEVICEBREAKDOWN_ERROR`;
export const EXPORT_DEVICEBREAKDOWN_TOOLARGE = `${PREFIX}EXPORT_DEVICEBREAKDOWN_ERROR`;

export const SOFT_PAUSE_POLLING = `${PREFIX}SOFT_PAUSE_POLLING`;
