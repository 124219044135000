import useGetDeviceById from 'common-js/api/devices/useGetDeviceById';
import useOnUpdatePlan from 'device/Plan/useOnUpdatePlan';

const withPlanHooksHoc = (Component: any) => (props: any) => {
  const getDevice = useGetDeviceById();
  const onUpdatePlan = useOnUpdatePlan();
  return <Component onUpdatePlan={onUpdatePlan} getDevice={getDevice} {...props} />;
};

export default withPlanHooksHoc;
