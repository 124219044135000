import { API_URL, headers } from '../config';
import { fetchHandler, fetchErrorHandler } from '../middleware';

export function getUserById(userId) {
  return fetch(`${API_URL}/users/${userId}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data), true, false))
    .catch(fetchErrorHandler);
}

export function getAllReleaseFlags() {
  return fetch(`${API_URL}/releaseflags?showAll=true`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data), true, false))
    .catch(fetchErrorHandler);
}

export function makeReleaseFlagPublic(flag) {
  return fetch(`${API_URL}/releaseflags/public`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      name: flag,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data), true, false))
    .catch(fetchErrorHandler);
}

export function removeReleaseFlagFromPublic(flag) {
  return fetch(`${API_URL}/releaseflags/public`, {
    method: 'DELETE',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      name: flag,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data), true, false))
    .catch(fetchErrorHandler);
}

export function setReleaseFlagForOrg(orgid, flag, state) {
  return fetch(`${API_URL}/releaseflags/${orgid}`, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      name: flag,
      state,
    }),
  })
    .then(
      fetchHandler(
        (body) => {
          const orgModels = body.data.map((o) => ({
            id: o.orgid,
            name: o.name,
          }));
          return Promise.resolve(orgModels);
        },
        true,
        false
      )
    )
    .catch(fetchErrorHandler);
}

export function impersonateUser({ email, userid, orgId }) {
  return fetch(`${API_URL}/auth/su`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      ...(email && { email }),
      ...(userid && { userid }),
      ...(orgId && { orgid: orgId }),
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function stopImpersonating() {
  return fetch(`${API_URL}/auth/su/exit`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getAllCarriers() {
  return fetch(`${API_URL}/carrier`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data), true, false))
    .catch(fetchErrorHandler);
}
