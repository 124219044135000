import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { FullHeightCalculatorHOC, HoloHelmet } from 'common-js/components';
import LandingPageEmpty from 'common-js/components/LandingPageEmpty';
import { activeStates, deactivatedStates, preflightStates } from 'common-js/constants/deviceStates';
import { DEVICES_DEACTIVATED, DEVICES_PREFLIGHT, pathMatches } from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useAutoAcceptOrgInvite from 'common-js/hooks/useAutoAcceptOrgInvite';
import useShowOrgIntroModal from 'common-js/hooks/useShowOrgIntroModal';
import { getOrgHasZeroDevices } from 'common-js/reducers/organization/selectors';
import {
  selectHasFleetStatus,
  selectHasPredeploymentTesting,
} from 'common-js/reducers/releaseFlag/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import SubMenu from '../components/SubMenu';
import Devices from './Devices';

interface DevicesSegmentProps {
  location: RouterLocation;
}

const DevicesSegment: React.FC<DevicesSegmentProps> = ({ location }) => {
  // Redux state
  const hasFleetStatus = useAppSelector(selectHasFleetStatus);
  const hasPredeploymentTesting = useAppSelector(selectHasPredeploymentTesting);
  const orgHasZeroDevices = useAppSelector(getOrgHasZeroDevices);
  const showNotificationBanner = useAppSelector(
    (state) => state.devices?.uiState?.activateNotification?.showNotificationBanner
  );
  const { deviceCountActive, deviceCountDeactivated, deviceCountPreflight } =
    useAppSelector((state) => state.organization) ?? {};

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [stateSegment, setStateSegment] = useState<Readonly<Array<string>>>([]);
  const [stateSegmentName, setStateSegmentName] = useState<string>('');
  const [stateSegmentDeviceCount, setStateSegmentDeviceCount] = useState<string>('');
  const { from } = location.query;

  // On mount
  useEffect(() => {
    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Devices - Table' } });

    // Redirect to preflight tab if stars align...
    if (
      hasPredeploymentTesting &&
      (from === 'login' || from === 'orgchange') &&
      deviceCountPreflight > 0 &&
      deviceCountActive === 0
    ) {
      browserHistory.push(DEVICES_PREFLIGHT);
    }
    // Forcing this to only first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAutoAcceptOrgInvite({ location });
  useShowOrgIntroModal({ location });

  const loadSegment = useCallback(() => {
    let newStateSegment: Readonly<Array<string>>;
    let newStateSegmentName: string;
    let newStateSegmentDeviceCount: string;

    if (pathMatches(DEVICES_PREFLIGHT, location.pathname)) {
      newStateSegment = preflightStates;
      newStateSegmentName = 'preflight';
      newStateSegmentDeviceCount = deviceCountPreflight;
    } else if (pathMatches(DEVICES_DEACTIVATED, location.pathname)) {
      newStateSegment = deactivatedStates;
      newStateSegmentName = 'deactivated';
      newStateSegmentDeviceCount = deviceCountDeactivated;
    } else {
      newStateSegment = activeStates;
      newStateSegmentName = 'active';
      newStateSegmentDeviceCount = deviceCountActive;
    }
    setIsLoading(false);
    setStateSegment(newStateSegment);
    setStateSegmentName(newStateSegmentName);
    setStateSegmentDeviceCount(newStateSegmentDeviceCount);
  }, [deviceCountActive, deviceCountDeactivated, deviceCountPreflight, location.pathname]);

  useEffect(() => {
    loadSegment();
  }, [loadSegment, stateSegment]);

  return (orgHasZeroDevices && !showNotificationBanner && !hasFleetStatus) || isLoading ? (
    <>
      <HoloHelmet title="Active Devices" />
      <SubMenu />
      <FullHeightCalculatorHOC accountForDrawer relative>
        {isLoading ? null : <LandingPageEmpty />}
      </FullHeightCalculatorHOC>
    </>
  ) : (
    <Devices
      deviceCount={stateSegmentDeviceCount}
      deviceCountActive={deviceCountActive}
      deviceCountDeactivated={deviceCountDeactivated}
      deviceCountPreflight={deviceCountPreflight}
      stateSegment={stateSegment}
      stateSegmentName={stateSegmentName}
    />
  );
};

export default DevicesSegment;
