const PREFIX = 'POOLS_';

export const GET_POOL_PLANS_REQUEST = `${PREFIX}GET_POOL_PLANS_REQUEST`;
export const GET_POOL_PLANS_SUCCESS = `${PREFIX}GET_POOL_PLANS_SUCCESS`;
export const GET_POOL_PLANS_ERROR = `${PREFIX}GET_POOL_PLANS_ERROR`;

export const SUBSCRIBE_POOL_REQUEST = `${PREFIX}SUBSCRIBE_POOL_REQUEST`;
export const SUBSCRIBE_POOL_SUCCESS = `${PREFIX}SUBSCRIBE_POOL_SUCCESS`;
export const SUBSCRIBE_POOL_ERROR = `${PREFIX}SUBSCRIBE_POOL_ERROR`;

export const GET_POOLS_REQUEST = `${PREFIX}GET_POOLS_REQUEST`;
export const GET_POOLS_SUCCESS = `${PREFIX}GET_POOLS_SUCCESS`;
export const GET_POOLS_ERROR = `${PREFIX}GET_POOLS_ERROR`;

export const GET_ALL_POOL_INFO_REQUEST = `${PREFIX}GET_ALL_POOL_INFO_REQUEST`;
export const GET_ALL_POOL_INFO_SUCCESS = `${PREFIX}GET_ALL_POOL_INFO_SUCCESS`;
export const GET_ALL_POOL_INFO_ERROR = `${PREFIX}GET_ALL_POOL_INFO_ERROR`;

export const SUBSCRIBE_PEND_POOL_REQUEST = `${PREFIX}SUBSCRIBE_PEND_POOL_REQUEST`;
export const SUBSCRIBE_PEND_POOL_SUCCESS = `${PREFIX}SUBSCRIBE_PEND_POOL_SUCCESS`;
export const SUBSCRIBE_PEND_POOL_ERROR = `${PREFIX}SUBSCRIBE_PEND_POOL_ERROR`;

export const UNSUBSCRIBE_POOL_REQUEST = `${PREFIX}UNSUBSCRIBE_POOL_REQUEST`;
export const UNSUBSCRIBE_POOL_SUCCESS = `${PREFIX}UNSUBSCRIBE_POOL_SUCCESS`;
export const UNSUBSCRIBE_POOL_ERROR = `${PREFIX}UNSUBSCRIBE_POOL_ERROR`;

export const UNSUBSCRIBE_POOL_PENDING_REQUEST = `${PREFIX}UNSUBSCRIBE_POOL_PENDING_REQUEST`;
export const UNSUBSCRIBE_POOL_PENDING_SUCCESS = `${PREFIX}UNSUBSCRIBE_POOL_PENDING_SUCCESS`;
export const UNSUBSCRIBE_POOL_PENDING_ERROR = `${PREFIX}UNSUBSCRIBE_POOL_PENDING_ERROR`;

export const RENEW_POOL_REQUEST = `${PREFIX}RENEW_POOL_REQUEST`;
export const RENEW_POOL_SUCCESS = `${PREFIX}RENEW_POOL_SUCCESS`;
export const RENEW_POOL_ERROR = `${PREFIX}RENEW_POOL_ERROR`;

export const CHANGE_SIZE_REQUEST = `${PREFIX}CHANGE_SIZE_REQUEST`;
export const CHANGE_SIZE_SUCCESS = `${PREFIX}CHANGE_SIZE_SUCCESS`;
export const CHANGE_SIZE_ERROR = `${PREFIX}CHANGE_SIZE_ERROR`;

export const QUOTE_REQUEST = `${PREFIX}QUOTE_REQUEST`;
export const QUOTE_SUCCESS = `${PREFIX}QUOTE_SUCCESS`;
export const QUOTE_ERROR = `${PREFIX}QUOTE_ERROR`;

export const GET_POOL_USAGE_REQUEST = `${PREFIX}GET_POOL_USAGE_REQUEST`;
export const GET_POOL_USAGE_SUCCESS = `${PREFIX}GET_POOL_USAGE_SUCCESS`;
export const GET_POOL_USAGE_ERROR = `${PREFIX}GET_POOL_USAGE_ERROR`;
