import React, { useCallback, useEffect, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import analyticsEventBuilder from 'common-js/analytics';
import { DEVICES_DEVICES } from 'common-js/constants/paths';
import useVerifyAccount, {
  API_RESPONSE_VERIFY_ACCOUNT_USER_VERIFIED,
  API_RESPONSE_VERIFY_ACCOUNT_CODE_EXPIRED,
  API_RESPONSE_VERIFY_ACCOUNT_CODE_INCORRECT,
  API_RESPONSE_VERIFY_ACCOUNT_PASSWORD_NOT_SET,
} from 'common-js/api/account/useVerifyAccount';
import { switchToDefaultOrg } from 'common-js/reducers/account/actions/switchToDefaultOrg';
import { Link } from 'react-router';
import { ComplexIcon } from '@holokit/core';
import FormPanel, { MessageType } from 'common-js/components/FormPanel';
import HoloHelmet from 'common-js/components/HoloHelmet';
import ResendEmailVerification from 'common-js/components/ResendEmailVerification';
import SetAccountPasswordForm from 'common-js/forms/SetAccountPasswordForm';
import {
  setAccountPasswordSubmitLabel,
  pageTitles,
  panelTitles,
  messages,
} from 'common-js/constants/verifyEmailPageStrings';
import LoggedOutTemplate from './LoggedOutTemplate';

export type PageState =
  | 'confirmation'
  | 'invalidVerificationLink'
  | 'passwordNotSet'
  | 'processing';

const apiResponseToPageStateMap: Record<string, PageState> = {
  [API_RESPONSE_VERIFY_ACCOUNT_USER_VERIFIED]: 'confirmation',
  [API_RESPONSE_VERIFY_ACCOUNT_CODE_EXPIRED]: 'invalidVerificationLink',
  [API_RESPONSE_VERIFY_ACCOUNT_CODE_INCORRECT]: 'invalidVerificationLink',
  [API_RESPONSE_VERIFY_ACCOUNT_PASSWORD_NOT_SET]: 'passwordNotSet',
};

/* several flows through this page:
  1.  user has password and valid verification token.                   UI: confirmation
  2.  user is already verified                                          UI: confirmation
  3.  user doesn't have password, but has a valid verification token.   UI: passwordNotSet
  4.  invalid verification token                                        UI: invalidVerificationLink
  5.  expired verification token.                                       UI: invalidVerificationLink
  6.  unknown API error                                                  UI: invalidVerificationLink
*/

const VerifyEmail = ({ location }) => {
  const { code, userid } = location.query;

  /* API CALL */
  const verifyAccountApiCall = useVerifyAccount();

  /* REDUX HOOK */
  const dispatch = useAppDispatch();

  /* Page state */
  const [pageState, setPageState] = useState<PageState>(
    !(code && userid) ? 'invalidVerificationLink' : 'processing'
  );
  const [pageLevelError, setPageLevelError] = useState('');

  /* Set Password error handler */
  const handleSetPasswordError = useCallback((error) => {
    setPageState('passwordNotSet');

    if (typeof error === 'object' && error.password) {
      // If the error is related to the password field, format it for display by the SetAccountPasswordForm
      return { setAccountPasswordFieldError: error.password };
    }
    setPageLevelError(error?.unknown || error);
    return false;
  }, []);

  /* API Call - called on page load AND when the Set Password form is submitted */
  const verifyAccount = useCallback(
    async (password?) => {
      try {
        const response = await verifyAccountApiCall({ code, userid, password });
        if (response.success) {
          // User is verifying their account for the first time, or they just set their account password
          setPageLevelError(''); // Clear any page level errors
          setPageState('confirmation');
          dispatch(switchToDefaultOrg()); // Trigger redux action
          return true;
        }

        if (password) {
          // An error occurred when a password setting attempt was made
          return handleSetPasswordError(response.error);
        }
        // If the API error is known, set the page state and error message appropriately, otherwise assume the verification link is invalid
        setPageState(apiResponseToPageStateMap[response.error] || 'invalidVerificationLink');
        return false;
      } catch (error) {
        if (password) {
          return handleSetPasswordError(error);
        }
        // An unknown server side error occurred, the verification link is probably invalid
        setPageState('invalidVerificationLink');
        setPageLevelError(error as string);
        return false;
      }
    },
    [code, dispatch, userid, handleSetPasswordError, verifyAccountApiCall]
  );

  /* ON PAGE LOAD, Log analytics view and call the verifyAccount API to determine page state */
  useEffect(() => {
    analyticsEventBuilder.pageView().page('Verify Email').send();
    if (code && userid) {
      verifyAccount();
    }
  }, [code, userid, verifyAccount]); // Call this effect on every page load

  const setPasswordSubmitHandler = async (data) => verifyAccount(data.password);

  return (
    <LoggedOutTemplate>
      <HoloHelmet title={pageTitles[pageState]} />
      {pageState === 'invalidVerificationLink' ? (
        <ResendEmailVerification error={pageLevelError} userId={userid} />
      ) : (
        <FormPanel
          title={panelTitles[pageState]}
          message={pageLevelError || messages[pageState]?.message}
          messageType={(messages[pageState]?.messageType as MessageType) || 'error'}
        >
          {pageState === 'processing' && <ComplexIcon classes="loader-centered" name="spinner" />}
          {pageState === 'passwordNotSet' && (
            <SetAccountPasswordForm
              onSubmit={setPasswordSubmitHandler}
              submitLabel={setAccountPasswordSubmitLabel}
            />
          )}
          {pageState === 'confirmation' && <Link to={DEVICES_DEVICES}>Go to dashboard</Link>}
        </FormPanel>
      )}
    </LoggedOutTemplate>
  );
};

export default VerifyEmail;
