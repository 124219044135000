import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const setCurrentPage =
  (page, blockFetch = false) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_PAGE,
      page,
    });

    if (!blockFetch) {
      dispatch(fetchDevices());
    }
  };
