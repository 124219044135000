import { API_URL, getHeaders } from 'common-js/api/config';
import { fetchHandler, fetchErrorHandler } from 'common-js/api/middleware';
import { queryStringFromContextData } from 'common-js/api/util';

export function getDevicesReport(userContextData, startDate, endDate, tagIds = []) {
  let queryString = '';

  if (userContextData.isInOrgContext) {
    queryString += `?orgid=${userContextData.orgId}`;
  } else {
    queryString += `?orgid=${userContextData.userOrgId}`;
  }

  if (startDate) queryString += `&timestart=${startDate}`;
  if (endDate) queryString += `&timeend=${endDate}`;
  if (tagIds.length > 0) {
    queryString += '&tagids=';
    tagIds.forEach((tagId, idx) => {
      queryString += idx === 0 ? tagId : `,${tagId}`;
    });
  }

  return fetch(`${API_URL}/reports/devices${queryString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getBillingReport(userContextData, startDate, endDate, tagIds, device) {
  let queryString = '';

  if (userContextData.isInOrgContext) {
    queryString += `?orgid=${userContextData.orgId}`;
  } else {
    queryString += `?orgid=${userContextData.userOrgId}`;
  }

  if (startDate) queryString += `&timestart=${startDate}`;
  if (endDate) queryString += `&timeend=${endDate}`;
  if (device) queryString += `&deviceid=${device.id}`;
  if (tagIds.length > 0) {
    queryString += '&tagids=';
    tagIds.forEach((tagId, idx) => {
      queryString += idx === 0 ? tagId : `,${tagId}`;
    });
  }

  return fetch(`${API_URL}/reports/billing${queryString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPlanDeviceSummary(userContextData) {
  const orgId = userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId;

  return fetch(`${API_URL}/organizations/${orgId}/devices/plans`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getEmbeddedReportInfo({ userContextData, reportId }) {
  const queryString = queryStringFromContextData({ userContextData });

  return fetch(`${API_URL}/reports/embedded/${reportId}/?${queryString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}
