import { useCallback, useState } from 'react';
import { setCurrentDevice } from 'common-js/reducers/currentDevice/actions';
import useUpdateUsageAlert from 'common-js/api/devices/useUpdateUsageAlert';
import useGetDeviceById from 'common-js/api/devices/useGetDeviceById';
import useAppDispatch from 'common-js/hooks/useAppDispatch';

const useSubmitUsageAlertForm = ({ device, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const updateUsageAlert = useUpdateUsageAlert();
  const getDeviceById = useGetDeviceById();
  const handleSubmit = useCallback(
    async ({ dataAlertAmount, amountUnit }) => {
      const limit = dataAlertAmount === -1 ? -1 : dataAlertAmount * amountUnit;
      setLoading(true);
      try {
        if (await updateUsageAlert(device.id, limit)) {
          // update the device in redux now, even though we're polling every 10 seconds
          const updatedDevice = await getDeviceById(device.id);
          if (updatedDevice.id) {
            dispatch(setCurrentDevice(updatedDevice));
            onSuccess();
            setError(null);
          }
        }
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [device.id, dispatch, getDeviceById, onSuccess, updateUsageAlert]
  );

  return {
    loading,
    error,
    handleSubmit,
  };
};

export default useSubmitUsageAlertForm;
