import { API_URL, headers } from '../config';
import { fetchHandler, fetchErrorHandler } from '../middleware';
import { getElapsedDays } from '../../utils/time';
import queryString from 'query-string';

export function buildReportHref(subPathname, userContextData, filters = {}) {
  /*
  Possible filters:
    tagIds: [],
    timestart: <unix timestamp number in seconds>,
    timeend: <unix timestamp number in seconds>
  */

  const elapsedDays = getElapsedDays(filters.timestart, filters.timeend);
  const term = elapsedDays > 0 && elapsedDays < 2 ? 'shortterm' : 'longterm';

  const querystring = queryString.stringify(
    {
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
      ...filters,
    },
    { arrayFormat: 'bracket' }
  );

  return `${API_URL}/reports/${term}${subPathname}?${querystring}`;
}

export function getDataUsed(userContextData, filters) {
  const url = buildReportHref('/usage', userContextData, filters);

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getUsageSummary(userContextData, term = 'shortterm', filters = {}) {
  /*
  Possible filters:
    tagIds: [],
    timestart: <unix timestamp number in seconds>,
    timeend: <unix timestamp number in seconds>
  */

  const querystring = queryString.stringify(
    {
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
      ...filters,
    },
    { arrayFormat: 'bracket' }
  );

  return fetch(
    `${API_URL}/reports/${term === 'longterm' ? 'longterm/' : ''}summaryactivity?${querystring}`,
    {
      method: 'GET',
      credentials: 'include',
      headers,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getLiveUsage(userContextData, filters = {}, limit = 2000) {
  /*
  Possible filters:
    tagids: [],
    timestart: <unix timestamp number in seconds>,
    timeend: <unix timestamp number in seconds>
  */

  const querystring = queryString.stringify(
    {
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
      limit,
      getdeviceinfo: 1,
      ...filters,
    },
    { arrayFormat: 'bracket' }
  );

  return fetch(`${API_URL}/usage/data?${querystring}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function buildDeviceBreakdownHref(userContextData, filters = {}, limit = 2000, offset = 0) {
  /*
  Possible filters:
    tagIds: [],
    timestart: <unix timestamp number in seconds>,
    timeend: <unix timestamp number in seconds>
  */

  const daysSinceStartTime = getElapsedDays(filters.timestart);
  const subPathname = daysSinceStartTime < 90 ? '/network' : '';

  const querystring = queryString.stringify(
    {
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
      limit,
      offset,
      ...filters,
    },
    { arrayFormat: 'bracket' }
  );

  return `${API_URL}/reports/longterm/usageperdevice${subPathname}?${querystring}`;
}

export function getDeviceBreakdown(userContextData, filters, limit = 2000, offset = 0) {
  return fetch(buildDeviceBreakdownHref(userContextData, filters, limit, offset), {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);
}

export function getDataUsedInspect(userContextData, filters = {}) {
  /*
  Possible filters:
    tagIds: [],
    timestart: <unix timestamp number in seconds>,
    timeend: <unix timestamp number in seconds>
  */

  const elapsedDays = getElapsedDays(filters.timestart, filters.timeend);
  const term = elapsedDays > 0 && elapsedDays < 2 ? 'shortterm' : 'longterm';

  const querystring = queryString.stringify(
    {
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
      ...filters,
    },
    { arrayFormat: 'bracket' }
  );

  return fetch(`${API_URL}/reports/${term}/usageperdevice/top?${querystring}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export const requestUsageReport = (contextData, filters) => {
  const { isInOrgContext, orgId, userOrgId } = contextData;
  const orgid = isInOrgContext ? orgId : userOrgId;

  return fetch(`${API_URL}/reports/longterm/usage/export`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({ orgid, ...filters }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
};

export const requestDeviceBreakdownUsageReport = (contextData, filters) => {
  const { isInOrgContext, orgId, userOrgId } = contextData;
  const orgid = isInOrgContext ? orgId : userOrgId;

  return fetch(`${API_URL}/reports/longterm/usageperdevice/export`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({ orgid, ...filters }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
};
