import React from 'react';
import _classnames from 'clsx';
import { Button } from '@holokit/core';
import { connect } from 'react-redux';
import { clearFilters, clearSelection } from 'common-js/reducers/devices/actions';
import { getFilterCount } from 'common-js/reducers/devices/selectors';
import { bindActionCreators } from 'redux';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

function SidebarHeader({ clearFilters_, clearSelection_, filterCount, stateSegment }) {
  return (
    <div
      className={_classnames(
        'DevicesSidebar__header DevicesSidebar__header--updatedActivation22A',
        {
          'DevicesSidebar__header--unfiltered': filterCount === 0,
        }
      )}
    >
      <div className="DevicesSidebar__header__title">Filter</div>
      <div className="DevicesSidebar__header__caption">{filterCount} Applied</div>
      <Button
        classes="Button--minimal DevicesSidebar__header__action"
        onClick={() => {
          clearFilters_(stateSegment);
          clearSelection_();
          sendAnalyticsEvent({
            type: analyticsTypes.FILTERS_CLEAR_ALL,
            data: {
              total_filters_cleared: filterCount,
            },
          });
        }}
        small
      >
        {filterCount > 1 ? 'Clear all' : 'Clear'}
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filterCount: getFilterCount(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFilters_: clearFilters,
      clearSelection_: clearSelection,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
