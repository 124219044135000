import _classNames from 'clsx';
import * as d3 from 'd3';
import { regressionLinear, regressionQuad } from 'd3-regression';
import { DebouncedFunc, cloneDeep, maxBy, minBy, throttle } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { RefObject } from 'react';
import { getBytePostfix } from 'common-js/utils/numberFormatter';
import { getUserFriendlyBytes } from '@hologram-hyper-dashboard/components';

const MARGIN = {
  top: 30,
  right: 20,
  bottom: 50,
  left: 60,
};
const HEIGHT = 230;

const PREDICTION_TYPES = {
  SLOPE: 'SLOPE',
  LINEAR_REGRESSION: 'LINEAR_REGRESSION',
  QUADRATIC_REGRESSION: 'QUADRATIC_REGRESSION',
};

const PRIMARY_COLORS = {
  ORANGE: 'ORANGE',
  RED: 'RED',
};

interface DataLimitChartDataValue {
  value: number;
  date: Moment;
}

class DataLimitChartD3 extends React.Component<any> {
  static PREDICTION_TYPES = PREDICTION_TYPES;

  static PRIMARY_COLORS = PRIMARY_COLORS;

  static calculateLineOfFitHighValue = (data: Array<DataLimitChartDataValue>, maxValue) => {
    // we need to clone here, moment will mutate the object which throws the chart off.
    const max = cloneDeep(maxBy(data, 'value'));
    const min = cloneDeep(minBy(data, 'value'));

    if (!max || !min) {
      return { value: undefined, date: undefined };
    }

    const daysElapsed = max.date.diff(min.date, 'days');
    const slope = max.value / daysElapsed;

    const daysPast = maxValue / slope;
    const maxDay = min.date.add(daysPast, 'days').startOf('day');
    return { value: maxValue, date: maxDay };
  };

  containerRef: RefObject<any>;

  chartRef: RefObject<any>;

  updateChartThrottled: DebouncedFunc<() => void>;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.chartRef = React.createRef();
    this.updateChartThrottled = throttle(this.updateChart);
  }

  componentDidMount() {
    this.buildChart();
    window.addEventListener('resize', this.updateChartThrottled);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateChartThrottled);
  }

  calculateChartWidth = () => {
    const containerRef = this.containerRef.current;
    const clientWidth = containerRef && containerRef.clientWidth;
    return (clientWidth && clientWidth - MARGIN.left - MARGIN.right) || 0;
  };

  updateChart = () => {
    const chart = d3.select(this.chartRef.current);
    chart.selectAll('g').remove();
    chart.selectAll('rect').remove();
    this.buildChart();
  };

  formatDataUsed = (startTime, endTime) => {
    const { dataUsed } = this.props;

    const data = [...dataUsed];

    // fill in gaps in the data.
    const datesWithUsage = data.map((dU) => dU.dateofusage);
    const timeCursor = startTime.clone();
    let iterationIdx = 0;
    const ITERATION_MAX = 1000;

    while (
      timeCursor.valueOf() <= endTime.valueOf() && // fill in gaps only within this period.
      timeCursor.valueOf() < moment.utc().valueOf() && // only fill days that are in the past.
      iterationIdx < ITERATION_MAX // Prevent any runaway situation.
    ) {
      const cursorDayStamp = timeCursor.format('YYYY-MM-DD');

      if (!datesWithUsage.includes(cursorDayStamp)) {
        data.splice(iterationIdx, 0, {
          dateofusage: cursorDayStamp,
          datausage: 0,
        });
      }
      timeCursor.add(1, 'day');
      iterationIdx += 1;
    }

    return data.reduce((res, oldData) => {
      const lastDataPoint = res[res.length - 1];
      const lastDataValue = (lastDataPoint && lastDataPoint.value) || 0;
      const currentDataUsed = parseInt(oldData.datausage, 10) + lastDataValue;
      return [
        ...res,
        {
          value: currentDataUsed,
          date: moment.utc(oldData.dateofusage).startOf('day'),
        },
      ];
    }, []);
  };

  drawPredictionLine = (d3Deps) => {
    const { predictionType, showFullPredictionLine, showProjectedDataUsage } = this.props;
    const { x, y, svg, startTime, endTime, maxDataDate, maxDataValue, data, dataHighValue } =
      d3Deps;

    if (predictionType === PREDICTION_TYPES.SLOPE) {
      const extendedEndPoint = showProjectedDataUsage
        ? DataLimitChartD3.calculateLineOfFitHighValue(data, dataHighValue)
        : { date: endTime, value: maxDataValue };

      const extendedDataSetGenerator = d3
        .line<DataLimitChartDataValue>()
        .x((d) => x(d.date))
        .y((d) => y(d.value));

      const extendedDataSet = [{ date: maxDataDate, value: maxDataValue }, extendedEndPoint];

      svg
        .append('path')
        .data([extendedDataSet])
        .attr(
          'class',
          _classNames('DataLimitChartD3__data-extended-path', {
            'DataLimitChartD3__data-extended-path--color': showProjectedDataUsage,
          })
        )
        .attr('d', extendedDataSetGenerator);
    } else if (predictionType === PREDICTION_TYPES.LINEAR_REGRESSION) {
      const linearRegression = regressionLinear()
        .x((d) => d.date)
        .y((d) => d.value)
        .domain([startTime, endTime]);

      const predictionValue = linearRegression(data)[1][1];
      const lastDataPoint = data[data.length - 1];

      // do not show prediction line if below the last data point.
      if (predictionValue < lastDataPoint.value) return;

      if (showFullPredictionLine) {
        svg
          .append('line')
          .attr('class', 'DataLimitChartD3__data-extended-path')
          .datum(linearRegression(data))
          .attr('x1', (d) => x(d[0][0]))
          .attr('x2', (d) => x(d[1][0]))
          .attr('y1', (d) => y(d[0][1]))
          .attr('y2', (d) => y(d[1][1]));
      } else {
        svg
          .append('line')
          .attr(
            'class',
            'DataLimitChartD3__data-extended-path DataLimitChartD3__data-extended-path'
          )
          .attr('x1', () => x(lastDataPoint.date))
          .attr('x2', () => x(endTime))
          .attr('y1', () => y(lastDataPoint.value))
          .attr('y2', () => y(predictionValue));
      }
    } else if (predictionType === PREDICTION_TYPES.QUADRATIC_REGRESSION) {
      const quadraticRegression = regressionQuad()
        .x((d) => d.date)
        .y((d) => d.value)
        .domain([startTime, endTime]);

      const regressionLineGenerator = d3
        .line()
        .x((d) => x(d[0]))
        .y((d) => y(d[1]));

      const predictionValue = quadraticRegression(data)[1][1];
      const lastDataPoint = data[data.length - 1];

      if (showFullPredictionLine) {
        svg
          .append('path')
          .attr('class', 'DataLimitChartD3__data-extended-path')
          .datum(quadraticRegression(data))
          .attr('d', regressionLineGenerator);
      } else {
        svg
          .append('line')
          .attr(
            'class',
            'DataLimitChartD3__data-extended-path DataLimitChartD3__data-extended-path'
          )
          .attr('x1', () => x(lastDataPoint.date))
          .attr('x2', () => x(endTime))
          .attr('y1', () => y(lastDataPoint.value))
          .attr('y2', () => y(predictionValue));
      }
    }
  };

  buildChart = () => {
    const {
      showTodayMarker,
      threshold,
      thresholdMetDate,
      showDataHighValue,
      hideDataAfterThreshMetDate,
      timeFilter,
      dataUsed,
    } = this.props;

    const dateMet = moment.utc(thresholdMetDate).startOf('day');
    const startTime = moment.utc(timeFilter.startDate).startOf('day');
    const endTime = moment.utc(timeFilter.endDate).startOf('day');

    let data: Array<DataLimitChartDataValue> = this.formatDataUsed(startTime, endTime);

    if (dataUsed.length === 0) {
      return;
    }

    const chartContainer = d3.select(this.containerRef.current);

    // set the dimensions and margins of the graph
    const width = this.calculateChartWidth();
    const height = HEIGHT - MARGIN.top - MARGIN.bottom;

    // add the zero data point
    data = [{ value: 0, date: startTime }, ...data];

    const now = moment.utc().valueOf();

    // hack to make staging look nicer since usage data is always streaming in even if a device is paused.
    if (hideDataAfterThreshMetDate && endTime.valueOf() > now) {
      data = data.filter((d) => d.date.valueOf() <= dateMet.valueOf());
    }

    // add "now" data point
    if (now < endTime.valueOf()) {
      data = [...data, { value: data[data.length - 1].value, date: moment.utc() }];
    }

    const minDataValue = 0;
    const maxDataValue = d3.max(data, (d) => d.value);
    const maxDataDate = d3.max(data, (d) => d.date);
    const dataHighValue = (maxDataValue ?? 0) * 1.3;

    const elapsedPercentage =
      (dateMet.diff(startTime).valueOf() / endTime.diff(startTime).valueOf()) * 100;

    const dataLabelPosition = (threshold / dataHighValue) * 100;

    const thresholdDataSet = [
      { date: startTime, value: threshold },
      { date: endTime, value: threshold },
    ];

    const overagePointDataSet = [
      { date: dateMet, value: minDataValue },
      { date: dateMet, value: dataHighValue },
    ];

    // set the ranges
    const x = d3.scaleTime().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    // Scale the range of the data
    x.domain([startTime, endTime]);
    y.domain([minDataValue, dataHighValue]);

    // line template
    const lineGenerator = d3
      .line<DataLimitChartDataValue>()
      .curve(d3.curveStepAfter)
      .x((d) => x(d.date))
      .y((d) => y(d.value));

    const gridGenerator = d3
      .axisTop(x)
      .ticks(d3.utcDay, 1)
      .tickSize(-height)
      .tickFormat('' as any);

    // add the svg to the page
    const svg = d3
      .select(this.chartRef.current)
      .attr('width', width + MARGIN.left + MARGIN.right)
      .attr('height', height + MARGIN.top + MARGIN.bottom)
      .attr('class', 'DataLimitChartD3__container')
      .append('g')
      .attr('transform', `translate(${MARGIN.left}, ${MARGIN.top})`);

    // and the outer rectangle
    svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('rx', '4')
      .attr('class', 'DataLimitChartD3__outline');

    // build canvas
    // add the x grid
    svg.append('g').attr('class', 'DataLimitChartD3__x-grid').call(gridGenerator);

    // add path for the data used
    svg
      .append('path')
      .data([data])
      .attr('class', 'DataLimitChartD3__data-used-path')
      .attr('d', lineGenerator);

    if (endTime.valueOf() > moment.utc().valueOf())
      this.drawPredictionLine({
        svg,
        data,
        startTime,
        endTime,
        x,
        y,
        maxDataDate,
        maxDataValue,
        dataHighValue,
      });

    // add the path for the data threshold
    svg
      .append('path')
      .data([thresholdDataSet])
      .attr('class', 'DataLimitChartD3__data-threshold-path')
      .attr('d', lineGenerator);

    svg
      .append('path')
      .data([overagePointDataSet])
      .attr('class', 'DataLimitChartD3__overage-date-line')
      .attr('d', lineGenerator);

    chartContainer.select('#DataLimitChartD3__date-start-text').text(startTime.format('MMM D'));

    chartContainer.select('#DataLimitChartD3__date-end-text').text(endTime.format('MMM D'));

    chartContainer
      .select('.DataLimitChartD3__date-hit-time')
      .style('left', `${elapsedPercentage}%`);

    chartContainer
      .select('.DataLimitChartD3__date-hit-marker')
      .style('left', `${elapsedPercentage}%`);

    chartContainer.select('#DataLimitChartD3__date-hit-text').text(dateMet.format('MMM D'));

    chartContainer
      .select('.DataLimitChartD3__label_left__container--limit')
      .style('bottom', `${dataLabelPosition}%`)
      .text(`${getUserFriendlyBytes(threshold)} Limit`);

    if (showTodayMarker) {
      const today = moment();
      const todayDataSet = [
        { date: today, value: minDataValue },
        { date: today, value: dataHighValue },
      ];

      const todayPercentage = today.diff(startTime).valueOf() / endTime.diff(startTime).valueOf();
      const todayPosition = todayPercentage * width;

      svg
        .append('path')
        .data([todayDataSet])
        .attr('class', 'DataLimitChartD3__today-path')
        .attr('d', lineGenerator);

      chartContainer.select('.DataLimitChartD3__today-marker').style('left', `${todayPosition}px`);
    }

    if (showDataHighValue) {
      chartContainer
        .select('.DataLimitChartD3__label_left__container--max')
        .text(getUserFriendlyBytes(dataHighValue) ?? '');
    }
  };

  render() {
    const { className, dataUsed, limitTypeLabel, primaryColor, showDataHighValue, threshold } =
      this.props;

    if (dataUsed.length === 0) {
      return null;
    }

    const labelPostfix = getBytePostfix(threshold);

    return (
      <div
        className={_classNames('DataLimitChartD3', className, {
          'DataLimitChartD3--primary-red': primaryColor === PRIMARY_COLORS.RED,
          'DataLimitChartD3--primary-orange': primaryColor === PRIMARY_COLORS.ORANGE,
        })}
        ref={this.containerRef}
      >
        <svg ref={this.chartRef}>
          <linearGradient id="linePathGradient" x1="0%" x2="100%" y1="0%" y2="0%">
            <stop className="DataLimitChartD3__start-0" offset="0" />
            <stop className="DataLimitChartD3__start-1" offset="100%" />
          </linearGradient>
        </svg>
        <div
          className="DataLimitChartD3__label_top"
          style={{
            width: `calc(100% - ${MARGIN.left + MARGIN.right}px)`,
            height: MARGIN.top,
            left: MARGIN.left,
          }}
        >
          <div className="DataLimitChartD3__label_top__container">
            <div className="DataLimitChartD3__date-hit-marker">
              <svg height="8" width="10">
                <polygon points="5,8 0,0 10,0" />
              </svg>
            </div>
          </div>
        </div>
        <div
          className="DataLimitChartD3__label_left"
          style={{
            width: MARGIN.left,
            height: HEIGHT - MARGIN.bottom - MARGIN.top,
            top: MARGIN.top,
          }}
        >
          <div className="DataLimitChartD3__label_left__container">
            {showDataHighValue && (
              <div className="DataLimitChartD3__label_left__container--max DataLimitChartD3__label-value" />
            )}
            <div className="DataLimitChartD3__label_left__container--limit DataLimitChartD3__label-value" />
            <div className="DataLimitChartD3__label_left__container--zero DataLimitChartD3__label-value">
              0 {labelPostfix}
            </div>
          </div>
        </div>
        <div
          className="DataLimitChartD3__label_bottom"
          style={{
            width: `calc(100% - ${MARGIN.left + MARGIN.right}px)`,
            height: MARGIN.bottom,
            top: HEIGHT - MARGIN.bottom,
            left: MARGIN.left,
          }}
        >
          <div className="DataLimitChartD3__label_bottom__container">
            <div className="DataLimitChartD3__label_bottom-start">
              <div
                id="DataLimitChartD3__date-start-text"
                className="DataLimitChartD3__label-value"
              />
              <div className="DataLimitChartD3__label-subtext">Period Start</div>
            </div>
            <div className="DataLimitChartD3__date-hit-time">
              <div id="DataLimitChartD3__date-hit-text" className="DataLimitChartD3__label-value" />
              <div className="DataLimitChartD3__label-subtext">{limitTypeLabel}</div>
            </div>
            <div>
              <div id="DataLimitChartD3__date-end-text" className="DataLimitChartD3__label-value" />
              <div className="DataLimitChartD3__label-subtext">Period End</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * dataUsed - array of objects that are the result of the data summary.
 * limitTypeLabel - Text to use for marking the date of hitting the threshold.
 * predictionType - Line model to use for prediction of future usage.
 * primaryColor - Color to use for accents.
 * showDataHighValue - Do we show the label of the highest point of the chart?
 * hideDataAfterThreshMetDate - Hide any datapoints that occur after the thresholdMetDate
 * showFullPredictionLine
 * showProjectedDataUsage - Do we show a projected value for data usage?
 * showTodayMarker - Do we draw a line on the chart that represents the current time?
 * threshold - Value of the threshold.
 * thresholdMetDate - Date the threshold was hit.
 * timeFilter - start and end date to show on the chart.
 * */
(DataLimitChartD3 as any).propTypes = {
  className: PropTypes.string,
  dataUsed: PropTypes.arrayOf(
    PropTypes.shape({
      datausage: PropTypes.string,
      dateofusage: PropTypes.string,
    })
  ),
  limitTypeLabel: PropTypes.string,
  predictionType: PropTypes.oneOf(Object.keys(PREDICTION_TYPES)),
  primaryColor: PropTypes.oneOf(Object.keys(PRIMARY_COLORS)),
  showDataHighValue: PropTypes.bool,
  hideDataAfterThreshMetDate: PropTypes.bool,
  showFullPredictionLine: PropTypes.bool,
  showProjectedDataUsage: PropTypes.bool,
  showTodayMarker: PropTypes.bool,
  threshold: PropTypes.number,
  thresholdMetDate: PropTypes.string,
  timeFilter: PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }).isRequired,
};

(DataLimitChartD3 as any).defaultProps = {
  className: '',
  limitTypeLabel: 'Limit Reached',
  predictionType: PREDICTION_TYPES.LINEAR_REGRESSION,
  primaryColor: PRIMARY_COLORS.RED,
  showFullPredictionLine: false,
  hideDataAfterThreshMetDate: false,
};

export default DataLimitChartD3;
