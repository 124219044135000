import React from 'react';
import { Button, Icon } from '@holokit/core';
import { CheckList } from '..';

function IconCard(props) {
  const {
    active,
    buttonHref,
    buttonText,
    checkListItems,
    detailText,
    disabled,
    handleButtonClick,
    height,
    iconAlt = '',
    iconName = '',
    imgPath,
    mainText,
    cardSelectable = true,
    onClick,
    originalPriceText,
    priceText,
    checklistSelectable,
    subText,
    titleText,
    width,
  } = props;
  const clickWrapper = (event) => {
    event.preventDefault();

    const card = event.currentTarget;
    card.classList.add('NewCard__Card--clicked');

    setTimeout(() => {
      card.classList.remove('NewCard__Card--clicked');
    }, 100);

    if (onClick) {
      onClick();
    }
  };

  let icon;
  if (imgPath && imgPath.length > 0) {
    icon = (
      <img className={`NewCard__Icon${disabled ? '--disabled' : ''}`} src={imgPath} alt={iconAlt} />
    );
  } else {
    icon = <Icon classes={`NewCard__Icon${disabled ? '--disabled' : ''}`} name={iconName} />;
  }

  const wrapperClasses = ['NewCard__Card'];

  if (active) {
    wrapperClasses.push('NewCard__Card--active');
  }
  if (disabled) {
    wrapperClasses.push('NewCard__Card--disabled');
  }
  if (!cardSelectable) {
    wrapperClasses.push('NewCard__Card--notSelectable');
  }

  let subTextComponent = null;
  if (priceText && priceText.length > 0) {
    // To keep things simple, subtext is ignored if a price/discount is shown.
    subTextComponent = (
      <div className="NewCard__SubContentPriceWrapper">
        {originalPriceText && originalPriceText.length > 0 && (
          <span className="NewCard__SubContent--originalPrice">{originalPriceText}</span>
        )}
        <span className="NewCard__SubContent--newPrice">{priceText}</span>
      </div>
    );
  } else if (subText && subText.length > 0) {
    subTextComponent = <span className="NewCard__SubContent">{subText}</span>;
  }

  return (
    <div
      className={wrapperClasses.join(' ')}
      onClick={disabled || !cardSelectable ? null : clickWrapper}
      onKeyUp={disabled || !cardSelectable ? null : clickWrapper}
      style={{ height, width }}
      tabIndex={-1}
    >
      <div
        className="NewCard__TitleText"
        style={{
          visibility: titleText && titleText.length > 0 ? 'visible' : 'hidden',
        }}
      >
        {titleText}
      </div>
      {icon}

      <div
        className={`NewCard__TextContentWrapper${
          disabled ? ' NewCard__TextContentWrapper--disabled' : ''
        }`}
      >
        <span className="NewCard__MainContent">{mainText}</span>
        {subTextComponent}
      </div>
      {detailText && (
        <>
          <div className="NewCard__Divider" />
          <div className="NewCard__DetailText">{detailText}</div>
        </>
      )}
      {checkListItems && checkListItems.length > 0 && (
        <>
          <div className="NewCard__Divider" />
          <CheckList items={checkListItems} selectable={checklistSelectable} active={active} />
        </>
      )}
      {buttonText && buttonHref && (
        <Button type="secondary" small onMouseDown={handleButtonClick} href={buttonHref} fullWidth>
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export default IconCard;
