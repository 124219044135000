import useApi from 'common-js/api/useApi';
import {
  BulkDeviceStateChangeResult,
  DeviceFilters,
  DeviceSelection,
  DeviceStateChangeAllowedStates,
} from 'common-js/types/Device';
import { useCallback } from 'react';

// To later be replaced by SIM IDs
// const selectedDeviceIds = Object.keys(selection?.byId || {});
// const legacyIds = selectedDeviceIds.map((deviceId) => ({ deviceId: Number(deviceId) }));

interface UseBulkDeviceStateChangeCallbackProps {
  useSearch?: boolean;
  filters?: DeviceFilters;
  selection?: DeviceSelection;
  simObjs?: Array<Sim>;
}
interface UseBulkDeviceStateChangeProps {
  newState: DeviceStateChangeAllowedStates;
  orgId?: OrgId;
  preview?: boolean;
  useBusinessLayer?: boolean;
}

const useBulkDeviceStateChange = ({
  newState,
  orgId,
  preview = false,
  useBusinessLayer = false,
}: UseBulkDeviceStateChangeProps) => {
  let endpoint = useBusinessLayer ? `/sims/state` : '/devices/batch/state';
  // useApi.call memoizes for us
  const useApiParams = useBusinessLayer ? { method: 'POST' } : { method: 'POST' };

  const queryParams: Record<string, string> = orgId ? { orgid: orgId.toString() } : {};
  if (!useBusinessLayer) {
    queryParams.preview = preview ? '1' : '0';
  }
  endpoint += `?${new URLSearchParams(queryParams)}`;

  const apiCall = useApi.call(endpoint, useApiParams);

  return useCallback(
    async ({ selection, filters, useSearch, simObjs }: UseBulkDeviceStateChangeCallbackProps) => {
      const deviceids = Object.keys(selection?.byId || {}).map((id) => Number(id));
      const excludedDeviceIds = Object.keys(selection?.excludedIds || {}).map((id) => Number(id));

      const params: any = useBusinessLayer
        ? { body: { orgId, sims: simObjs } }
        : {
            body: {
              state: newState,
              deviceids,
              excludedDeviceIds,
              allSelected: selection?.allSelected,
              pagesSelected: selection?.pagesSelected,
              useSearch,
              ignoreInvalidLinks: !preview,
              deviceFilters: {
                ...filters,
                hitsPerPage: selection?.allSelected ? 10000 : 250,
                orgid: orgId,
              },
            },
          };

      const { data } = (await apiCall(params)) as BulkDeviceStateChangeResult;
      return data;
    },
    [apiCall, newState, orgId, preview, useBusinessLayer]
  );
};

export default useBulkDeviceStateChange;
