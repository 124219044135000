import { Loader } from '@hologram-dimension/loader';
import { AdminFlagPanel } from 'admin/components';
import { HoloHelmet } from 'common-js/components';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { getAllReleaseFlags } from 'common-js/reducers/admin/actions';
import { selectAdminFlags, selectNonBMPFlagKeys } from 'common-js/reducers/admin/selectors';
import { FC, useEffect } from 'react';

const ReleaseFlags: FC = () => {
  const isAdmin: boolean = useAppSelector(
    (state) => !!state.account && (state.account.role === 'adm' || state.account.role === 'hdm')
  );
  const loading: boolean = useAppSelector((state) => !!state.admin?.loadingFlags);
  const nonBMPFlagKeys = useAppSelector(selectNonBMPFlagKeys);
  const adminFlags = useAppSelector(selectAdminFlags);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllReleaseFlags());
  }, [dispatch]);

  if (!isAdmin) {
    return <div />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="admin padded-page-wide">
      <HoloHelmet title="Admin - Release Flags" />

      {nonBMPFlagKeys.map((flag) => (
        <AdminFlagPanel
          key={flag}
          flag={flag}
          activeOrganizations={adminFlags[flag].organizations}
          isPublic={adminFlags[flag].public}
        />
      ))}
    </div>
  );
};

export default ReleaseFlags;
