import React from 'react';
import classNames from 'clsx';

class HyperDrawerExpander extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ expanded: true }), 10);
  }

  render() {
    const { expanded } = this.state;
    const { children } = this.props;

    return (
      <div
        className={classNames('HyperTestingTable__drawer', {
          'HyperTestingTable__drawer--active': expanded,
        })}
      >
        {children}
      </div>
    );
  }
}

export default HyperDrawerExpander;
