import React from 'react';

function AlertsActionItemsAd() {
  return (
    <div className="AlertsActionItemsAd">
      <img
        className="AlertsActionItemsAd__image"
        src="https://cdn1.hologram.io/dashboard/eureka-action.items.jpg"
        alt=""
      />
      <p className="AlertsActionItemsAd__text">
        Action items notify you when devices in your fleet require attention.
      </p>
    </div>
  );
}

export default AlertsActionItemsAd;
