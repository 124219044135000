import { Icon } from '@hologram-dimension/icon';
import { FC } from 'react';

const ReportDetailLoader: FC = () => (
  <div className="ReportDetailLoader">
    <Icon name="Console" size="large" className="ReportDetailLoader__icon" />

    <h1 className="ReportDetailLoader__text">
      We’re preparing your report. This takes just a few moments...
    </h1>
  </div>
);

export default ReportDetailLoader;
