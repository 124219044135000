import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { openModal } from 'common-js/reducers/modal/actions';
import { setTunnelableBulk } from 'common-js/reducers/devices/actions';
import { getSelectedDeviceIds } from 'common-js/reducers/devices/selectors';
import { selectHasTunneling } from 'common-js/reducers/releaseFlag/selectors';

const useDisableTunnelingItem = () => {
  const dispatch = useAppDispatch();
  const selectedDeviceIds = useAppSelector(getSelectedDeviceIds);
  const hasTunneling = useAppSelector(selectHasTunneling);

  const onClick = useCallback(() => {
    dispatch(
      openModal('BulkActionModal', {
        actionTitle: 'Disable tunneling',
        buttonLabel: 'Disable tunneling',
        deviceIds: selectedDeviceIds,
        action: () => dispatch(setTunnelableBulk(selectedDeviceIds, false)),
      })
    );
  }, [dispatch, selectedDeviceIds]);

  return {
    label: 'Disable tunneling',
    icon: 'Spacebridge--slash',
    include: hasTunneling,
    selectionLimit: 10,
    onClick,
  };
};

export default useDisableTunnelingItem;
