export type MktoForm = {
  onSuccess: (callback: () => void) => void;
  setValues: (object) => void;
  submit: () => void;
};
export type MktoFormJsSdk = {
  loadForm: (
    formUrl: string,
    munchkinId: string,
    formId: number,
    onLoad: (form: MktoForm) => void
  ) => void;
  onSuccess: (callback: () => void) => void;
  getForm: (formId: number) => MktoForm;
  getFormElem: () => [HTMLFormElement];
};

const getCookie = (cookieName) => {
  if (!cookieName) {
    return null;
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${encodeURIComponent(cookieName).replace(
            /[-.+*]/g,
            '\\$&'
          )}\\s*=\\s*([^;]*).*$)|^.*$`
        ),
        '$1'
      )
    ) || null
  );
};

const getGaId = () => getCookie('_ga') || 'GA ID Not Found';

export const sendGaEvent = (event = 'leadGenerated') => {
  if (window.dataLayer) {
    window.dataLayer.push({ event });
  }
};

const getUtmParams = () => ({
  utmcampaign: getCookie('_utm_campaign'),
  utmcontent: getCookie('_utm_content'),
  utmmedium: getCookie('_utm_medium'),
  utmsource: getCookie('_utm_source'),
  utmterm: getCookie('_utm_term'),
});

const submitDashboardMarketoForm = (formValues) => {
  const { location, MktoForms2 } = window;
  const form = MktoForms2?.getForm(process.env.VITE_MKTO_REGISTER_FORM_ID ?? 1002);
  if (form) {
    form.onSuccess(() => false);
    const searchValues = new URLSearchParams(location.search);
    const userIsNew = !!searchValues.get('userisnew');
    const fromInviteEmail = searchValues.get('origin') === 'orginviteemail';
    const regSourceDetail = userIsNew && fromInviteEmail ? 'Org Invite' : 'New Org';

    const marketoValues = {
      FirstName: formValues.firstName,
      LastName: formValues.lastName,
      Email: formValues.email,
      Registration_Source_Detail__c: regSourceDetail,
      Registration_Source__c: 'Dashboard Sign Up',
      Client_ID__c: getGaId(),
      Company: formValues.company,
      GA_Client_ID__c: getGaId(),
      Hologram_ID__c: formValues.userId,
      Personal_or_work_project__c: formValues.purpose,
      Coverage_Requested__c: formValues.coverageRequested,
      Countries_Where_Need_Coverage__c: formValues.countriesNeedingCoverage,
      Estimated_Monthly_Data_Usage__c: formValues.usage,
      Current_Customer_Need__c: formValues.customerNeed,
      Estimated_Number_of_Devices_New__c: formValues.devices,
      Use_Case__c: formValues.useCase,
      End_User_Sub_Industry__c: formValues.endUserSubIndustry,
      Marketing_Email_Opt_in__c: formValues.marketingEmailOptIn,
      Phone_Call_Opt_in__c: formValues.phoneCallOptIn,
      Phone: formValues.phoneNumber,
    };

    const utmCookies = getUtmParams();
    form.setValues({ ...marketoValues, ...utmCookies });
    form.submit();
  }
};

export default submitDashboardMarketoForm;
