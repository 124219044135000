import { FC, useMemo } from 'react';
import isNumber from 'lodash/isNumber';

interface MoneyProps {
  currency?: string;
  amount?: string | number;
  showLongFloats?: boolean;
}

const Money: FC<MoneyProps> = ({ currency = '$', amount = 0, showLongFloats = false }) => {
  const amountToShow = useMemo(() => {
    if (!showLongFloats) {
      return isNumber(amount) ? amount.toFixed(2) : amount;
    }

    let formattedAmount: number;
    if (typeof amount === 'string') {
      formattedAmount = parseFloat(amount);
    } else {
      formattedAmount = amount;
    }

    try {
      // Dan's note: .. Wat ... Didn't we ... just change this to a number?
      const stringAmount = formattedAmount?.toString();

      // '0' to change to '0.00'
      // '1' to change to '1.00'
      // '1.0' to change to '1.00'
      // '0.0000' to change to '0.00'
      // '1.0000' to change to '1.00'
      // '1.0010' to change to '1.001'
      // '1.0001' to change to '1.0001'

      if (stringAmount.indexOf('.') === -1 || stringAmount.split('.')[1].length < 2) {
        return formattedAmount.toFixed(2);
      }
      const integers = stringAmount.split('.')[0];
      let decimals = stringAmount.split('.')[1];

      while (decimals.length > 2 && decimals.charAt(decimals.length - 1) === '0') {
        decimals = decimals.substring(0, decimals.length - 1);
      }

      return `${integers}.${decimals}`;
    } catch (e) {
      return formattedAmount;
    }
  }, [amount, showLongFloats]);

  return (
    <span>
      {currency}
      {amountToShow}
    </span>
  );
};

export default Money;
