import _ from 'lodash';
import moment from 'moment-timezone';

import * as actionTypes from './actionTypes';
import * as accountActionTypes from '../account/actionTypes';
import { getQuickFilter } from '../../utils/quickFilter';
import { TIMEQUICKFILTER_THISMONTH, TIMEQUICKFILTER_CUSTOM } from '../../constants/quickFilters';

export const VIEWMORE_ROWS = 100;

const initialState = {
  runningReport: false,

  charts: {
    dataused: {
      loading: false,
      error: null,
      data: [],
    },
    usagesummary: {
      loading: false,
      error: null,
      data: {},
    },
    liveusage: {
      loading: false,
      error: null,
      data: [],
      viewCount: VIEWMORE_ROWS,
    },
    devicebreakdown: {
      exporting: false,
      exportError: null,
    },
    datausedinspect: {
      loading: false,
      error: null,
      data: [],
    },
  },
  billingReport: {},
  devicesReport: {},
  timeQuickFilter: TIMEQUICKFILTER_THISMONTH,
  filters: {
    startDate: moment().startOf('month').valueOf(),
    endDate: moment().valueOf(),
  },
  filtersDataUsedDetails: {
    startDate: moment().startOf('month').valueOf(),
    endDate: moment().valueOf(),
  },
};

function setChartState(state, chartName, newState) {
  return {
    ...state,
    charts: {
      ...state.charts,
      [chartName]: { ...state.charts[chartName], ...newState },
    },
  };
}

// eslint-disable-next-line default-param-last
function usageReducer(state = initialState, action) {
  switch (action.type) {
    case accountActionTypes.LOGOUT_REQUEST:
      return { ...state, ...initialState };

    case actionTypes.SET_CUSTOM_DATE: {
      const newFilters = _.pick(action.filters, 'startDate', 'endDate');

      return {
        ...state,
        filters: { ...state.filters, ...newFilters },
        timeQuickFilter: TIMEQUICKFILTER_CUSTOM,
      };
    }

    case actionTypes.SET_FILTERS_DATAUSEDDETAILS: {
      const filtersDataUsedDetails = _.pick(action.filters, 'startDate', 'endDate');

      return {
        ...state,
        filtersDataUsedDetails: {
          ...state.filtersDataUsedDetails,
          ...filtersDataUsedDetails,
        },
        timeQuickFilter: TIMEQUICKFILTER_CUSTOM,
      };
    }

    case actionTypes.PATCH_FILTERS: {
      const updatedFilters = _.pick(action.filters, 'startDate', 'endDate');

      return { ...state, filters: { ...state.filters, ...updatedFilters } };
    }

    case actionTypes.SET_QUICK_FILTER: {
      const quickFilters = getQuickFilter(action.quickFilter);

      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: quickFilters.startDate,
          endDate: quickFilters.endDate,
        },
        timeQuickFilter: action.quickFilter,
      };
    }

    case actionTypes.GET_DATAUSED_REQUEST:
      return setChartState(state, 'dataused', {
        loading: true,
        data: initialState.charts.dataused.data,
        error: null,
      });

    case actionTypes.GET_DATAUSED_SUCCESS:
      return setChartState(state, 'dataused', {
        loading: false,
        data: action.data,
        error: null,
      });

    case actionTypes.GET_DATAUSED_ERROR:
      return setChartState(state, 'dataused', {
        loading: false,
        data: initialState.charts.dataused.data,
        error: action.error,
      });

    case actionTypes.GET_USAGESUMMARY_REQUEST:
      return setChartState(state, 'usagesummary', {
        loading: true,
        data: initialState.charts.usagesummary.data,
        error: null,
      });

    case actionTypes.GET_USAGESUMMARY_SUCCESS:
      return setChartState(state, 'usagesummary', {
        loading: false,
        data: action.data,
        error: null,
      });

    case actionTypes.GET_USAGESUMMARY_ERROR:
      return setChartState(state, 'usagesummary', {
        loading: false,
        data: initialState.charts.usagesummary.data,
        error: action.error,
      });

    case actionTypes.GET_LIVEUSAGE_REQUEST:
      return setChartState(state, 'liveusage', {
        viewCount: VIEWMORE_ROWS,
        loading: true,
        data: initialState.charts.liveusage.data,
        error: null,
      });

    case actionTypes.GET_LIVEUSAGE_SUCCESS:
      return setChartState(state, 'liveusage', {
        loading: false,
        data: action.data,
        error: null,
      });

    case actionTypes.GET_LIVEUSAGE_ERROR:
      return setChartState(state, 'liveusage', {
        loading: false,
        data: initialState.charts.liveusage.data,
        error: action.error,
      });

    case actionTypes.EXPORT_DEVICEBREAKDOWN_REQUEST:
      return setChartState(state, 'devicebreakdown', {
        exporting: true,
        exportError: null,
      });

    case actionTypes.EXPORT_DEVICEBREAKDOWN_SUCCESS:
      return setChartState(state, 'devicebreakdown', {
        exporting: false,
        exportError: null,
      });

    case actionTypes.EXPORT_DEVICEBREAKDOWN_ERROR:
      return setChartState(state, 'devicebreakdown', {
        exporting: false,
        exportError: action.error,
      });

    case actionTypes.EXPORT_DEVICEBREAKDOWN_TOOLARGE:
      return setChartState(state, 'devicebreakdown', {
        exporting: false,
        exportError: 'Too much data to export.',
      });

    case actionTypes.VIEWMORE_LIVEUSAGE:
      return setChartState(state, 'liveusage', {
        viewCount: state.charts.liveusage.viewCount + VIEWMORE_ROWS,
      });

    case actionTypes.GET_DATAUSED_INSPECT_REQUEST:
      return setChartState(state, 'datausedinspect', {
        loading: true,
        data: initialState.charts.datausedinspect.data,
        error: null,
      });

    case actionTypes.GET_DATAUSED_INSPECT_SUCCESS:
      return setChartState(state, 'datausedinspect', {
        loading: false,
        data: action.data,
        error: null,
      });

    case actionTypes.GET_DATAUSED_INSPECT_ERROR:
      return setChartState(state, 'datausedinspect', {
        loading: false,
        data: initialState.charts.datausedinspect.data,
        error: action.error,
      });

    case actionTypes.SET_REPORT_DATE_TITLE:
      return { ...state, reportDateTitle: action.reportDateTitle };

    case actionTypes.RUN_REPORT_START:
      return { ...state, runningReport: true };

    case actionTypes.RUN_REPORT_FINISH:
      return { ...state, runningReport: false };

    case actionTypes.GET_BILLING_REPORT_SUCCESS:
      return { ...state, billingReport: action.billingReport };

    case actionTypes.GET_DEVICES_REPORT_SUCCESS:
      return { ...state, devicesReport: action.devicesReport };

    default:
      return state;
  }
}

export default usageReducer;
