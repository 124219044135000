import { Button } from '@holokit/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeaderWithIcon } from '../components';
import * as accountActions from '../reducers/account/actions';
import * as messageActions from '../reducers/message/actions';
import * as modalActions from '../reducers/modal/actions';

class ActivateResendEmailVerifyModal extends React.Component {
  componentDidMount() {
    const MODAL_CONTAINER = document.getElementsByClassName('modal-container')[0];
    MODAL_CONTAINER.setAttribute('style', 'width:490px');
  }

  onSendAgain() {
    const { pushGlobalMessage, resendEmailVerification, userId } = this.props;

    resendEmailVerification(userId)
      .then(() => {
        pushGlobalMessage(
          'Email verification successfully resent. Please check your inbox',
          'success'
        );
      })
      .catch((e) => {
        pushGlobalMessage(e, 'error');
      });
  }

  render() {
    const { email, closeModal } = this.props;

    return (
      <div className="ActivateResendEmailModal">
        <ModalHeaderWithIcon iconName="Envelope" title="Email Verification Required" />
        <p>
          Before you can continue with activating your first device, please confirm your email
          address by clicking the link in the email we sent to your address:
        </p>
        <div className="well well-dark">{email}</div>
        <div className="modal-buttons">
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.onSendAgain();
            }}
            type="primary"
          >
            Resend Verification
          </Button>
          <Button onClick={closeModal} type="secondary">
            {' '}
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    email: state.account.email,
    userId: state.account.userId,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        pushGlobalMessage: messageActions.pushGlobalMessage,
        resendEmailVerification: accountActions.resendEmailVerification,
        closeModal: modalActions.close,
      },
      dispatch
    )
)(ActivateResendEmailVerifyModal);
