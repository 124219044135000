import React from 'react';
import { Button } from '@holokit/core';

function SelectMultipleToolbar({
  selectedAlerts,
  alertCount,
  loaded,
  setAllUnloadedAlertsSelected,
  selectAll,
  deselectAlerts,
  allUnloadedAlertsSelected,
}) {
  const handleSelectAllEvents = () => {
    setAllUnloadedAlertsSelected(true);
    if (selectedAlerts.length !== loaded) {
      selectAll();
    }
  };

  const handleClearSelection = () => {
    setAllUnloadedAlertsSelected(false);
    deselectAlerts(selectedAlerts);
  };

  let subHeaderText;
  if (selectedAlerts.length === loaded && allUnloadedAlertsSelected && alertCount !== 0) {
    subHeaderText = `All ${alertCount} events selected`;
  } else if (selectedAlerts.length === loaded) {
    subHeaderText = `All ${loaded} loaded events selected`;
  } else {
    subHeaderText = `${selectedAlerts.length} loaded events selected `;
  }

  return (
    <div className="AlertsTable__multi-select-toolbar">
      <div className="AlertsTable__multi-select-toolbar__sub-header">{subHeaderText}</div>

      {allUnloadedAlertsSelected ? (
        <Button
          classes="AlertsTable__multi-select-toolbar__st-btn"
          small
          onClick={handleClearSelection}
          type="reversed"
        >
          Clear Selection
        </Button>
      ) : (
        <Button
          classes="AlertsTable__multi-select-toolbar__st-btn"
          onClick={handleSelectAllEvents}
          small
          type="reversed"
        >
          {alertCount === 1 ? `Select ${alertCount} event` : `Select all ${alertCount} events`}
        </Button>
      )}
    </div>
  );
}

export default SelectMultipleToolbar;
