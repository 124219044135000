import { getOrgIdFromContextData, getUserContextData } from 'common-js/api/util';
import { NEW_SUPPORT_TICKET } from 'common-js/constants/links';

interface SupportUrlProps {
  message: string;
  subject?: string;
  email?: string;
  orgId?: number | null;
}

export const supportUrl = ({ message, subject, email, orgId }: SupportUrlProps) =>
  `${NEW_SUPPORT_TICKET}?${new URLSearchParams({
    tf_subject: subject ?? 'Support request',
    tf_description: encodeURIComponent(message ?? ''),
    tf_anonymous_requester_email: encodeURIComponent(email ?? ''),
    tf_1900007427745: orgId?.toString() ?? '',
  }).toString()}`;

export const supportMessage =
  (message: string, subject?: string) => (dispatch: unknown, state: any) => {
    const userContext = getUserContextData(state);
    const orgId = getOrgIdFromContextData(userContext);
    window.open(supportUrl({ message, subject, email: userContext.userEmail, orgId }), '_blank');
  };
