import { Button } from '@hologram-dimension/button';
import { ComplexIcon } from '@holokit/core';
import { ModalHeader } from 'common-js/components';
import React from 'react';

interface ErrorModalProps {
  onClose: AnyFunction;
}

const ErrorModal = ({ onClose }: ErrorModalProps) => (
  <>
    <ModalHeader title="Operation failed" />

    <div className="BulkDataActionModal__error">
      <ComplexIcon name="error" />

      <div className="BulkDataActionModal__error__message">
        We&apos;ve encountered an unexpected error
      </div>

      <div className="BulkDataActionModal__error__detail">
        We are working on addressing the problem and apologize for any inconvenience. If you need
        immediate assistance, please contact support at{' '}
        <a href="mailto:support@hologram.io">support@hologram.io</a>.
      </div>
    </div>

    <div className="BulkDataActionModal__footer">
      <Button onClick={onClose}>Back to dashboard</Button>
    </div>
  </>
);

export default ErrorModal;
