import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from '@holokit/core';
import { LogDateRangeDropdown, SearchAutoComplete } from 'common-js/components';
import * as Paths from 'common-js/constants/paths';
import * as logFilterActions from 'common-js/reducers/logFilter/actions';

class ConsoleFilters extends React.Component {
  static onPageWithTagFilters() {
    return (
      window.location.pathname === Paths.DEVICES_DEVICES ||
      window.location.pathname === Paths.DEVICES_USAGE
    );
  }

  onTopicSearchChange(newValue, source) {
    const { setSearchQuery } = this.props;

    if (source === 'typing') {
      setSearchQuery('allLogs', newValue);
    } else {
      setSearchQuery('allLogs', newValue, false);
    }
  }

  render() {
    const {
      logStartDate,
      logEndDate,
      setViewingAllLogs,
      viewDateRange,
      clearAll,
      isViewingAllLogs,
      setSearchQuery,
      searchQuery,
      showFilters,
      deviceFilter,
      setDeviceFilter,
      topics,
    } = this.props;

    return (
      <div className="console-filters">
        {showFilters && (
          <div className="filters">
            {deviceFilter && (
              <div className="filter device">
                <div className="filter-name">Device {deviceFilter}</div>
                <div
                  className="clear-filter"
                  onClick={() => setDeviceFilter(null)}
                  onKeyUp={() => setDeviceFilter(null)}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="Close" size="micro" svgProps={{ style: { fill: '#7d7d7d' } }} />
                </div>
                <div className="arrowhead" />
              </div>
            )}
            <div className="filter dates">
              <LogDateRangeDropdown
                startDate={logStartDate}
                endDate={logEndDate}
                direction="down"
                isViewingAllLogs={isViewingAllLogs}
                onAllLogsSelected={() => setViewingAllLogs('allLogs', true)}
                onDateRangeSelected={() => setViewingAllLogs('allLogs', false)}
                onDateRangeChange={(startDate, endDate) =>
                  viewDateRange('allLogs', startDate, endDate)
                }
                onClear={() => clearAll('allLogs')}
                showApplyButtons={false}
              />
              {!isViewingAllLogs && (
                <div
                  className="clear-filter"
                  onClick={() => clearAll('allLogs')}
                  onKeyUp={() => clearAll('allLogs')}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="Close" size="micro" svgProps={{ style: { fill: '#7d7d7d' } }} />
                </div>
              )}
              <div className="arrowhead" />
            </div>
            <div className="filter last-filter search">
              <SearchAutoComplete
                onChange={(newValue, source) => this.onTopicSearchChange(newValue, source)}
                showClearButton={false}
                value={searchQuery}
                placeholder="Search by topics published"
                alwaysFocused
                autoFocus={false}
                topics={topics}
              />
              {searchQuery.length > 0 && (
                <div
                  className="clear-filter"
                  onClick={() => {
                    setSearchQuery('allLogs', '', false);
                  }}
                  onKeyUp={() => {
                    setSearchQuery('allLogs', '', false);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="Close" size="micro" svgProps={{ style: { fill: '#8008f7' } }} />
                </div>
              )}
              <div className="arrowhead" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    searchQuery: state.logFilters.allLogFilters.searchQuery,
    deviceFilter: state.logFilters.allLogFilters.deviceFilter,
    logStartDate: state.logFilters.allLogFilters.startDate,
    logEndDate: state.logFilters.allLogFilters.endDate,
    isViewingAllLogs: state.logFilters.allLogFilters.isViewingAllLogs,
    topics: state.topic.allTopics,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        viewDateRange: logFilterActions.viewDateRange,
        setViewingAllLogs: logFilterActions.setViewingAllLogs,
        clearAll: logFilterActions.clearAll,
        setSearchQuery: logFilterActions.setSearchQuery,
        setDeviceFilter: logFilterActions.setDeviceFilter,
      },
      dispatch
    )
)(ConsoleFilters);
