import useApi from 'common-js/api/useApi';
import { DeviceStateChangeResult } from 'common-js/types/Device';
import { useCallback } from 'react';

interface UseDeviceStateChangeCallbackProps {
  deviceId?: DeviceId;
  simObj?: Sim;
  orgId?: OrgId;
}
interface UseDeviceStateChangeProps {
  newState: 'pause' | 'deactivate' | 'live';
  useBusinessLayer?: boolean;
}

const useDeviceStateChange = ({
  newState,
  useBusinessLayer = false,
}: UseDeviceStateChangeProps) => {
  const endpoint = useBusinessLayer ? `/sims/${newState}` : '/devices/:deviceId/state';
  // useApi.call memoizes for us
  const useApiParams = useBusinessLayer
    ? { method: 'POST' }
    : { method: 'POST', body: { state: newState } };

  const apiCall = useApi.call<DeviceStateChangeResult>(endpoint, useApiParams);

  return useCallback(
    async ({ deviceId, simObj, orgId }: UseDeviceStateChangeCallbackProps) => {
      const params = useBusinessLayer
        ? { body: { orgId, sims: [simObj?.simId] } }
        : { urlParams: { deviceId } };

      const { data, error } = await apiCall(params);
      if (error) throw new Error(error);
      return data;
    },
    [apiCall, useBusinessLayer]
  );
};

export default useDeviceStateChange;
