import { Callout } from '@holokit/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Submit } from '../../components/form';
import { CreditCardModel, OrgModel } from '../../models';
import * as organizationActions from '../../reducers/organization/actions';
import { getSelectedOrg } from '../../reducers/organization/selectors';
import { required } from '../../utils/validation/validators';
import FormHOC from '../FormHOC';
import { formGenerator } from '../formGenerator';

// TODO: Consider updating the prop types below
/* eslint-disable react/forbid-prop-types */

class OrgCreationForm extends React.PureComponent {
  render() {
    const {
      form,
      name,
      migrateBilling,
      migrateAssets,
      onFormSubmit,
      formProcessing = false,
      showValidationErrors = false,
      serverError = null,
      isSuccessful,
      selectedOrg,
    } = this.props;

    return (
      <form className="form form-horizontal OrgCreationForm" onSubmit={onFormSubmit}>
        <div className="OrgCreationForm__wrapper">
          <div className="form-row">
            <div className="label">
              <div className="label-text OrgCreationForm__heading">Choose organization name</div>
              <div className="form-sub-row">
                <Input
                  {...name}
                  type="text"
                  name="name"
                  error={form.name.error}
                  showValidation={showValidationErrors}
                  placeholder="Enter new organization name"
                />
              </div>
            </div>
            {selectedOrg.isMyAccount && (
              <div className="label">
                <div className="label-text" />
                <div className="form-sub-row">
                  <label htmlFor="migrateAssets">
                    <Input
                      {...migrateAssets}
                      type="checkbox"
                      name="migrateAssets"
                      error={form.migrateAssets.error}
                      showValidation={showValidationErrors}
                    />
                    Transfer my account data
                    <div className="radio-subtext f6 f-subdued">
                      This will permanently move all of the assets you have (Devices, settings, app
                      integrations) from your existing account to the new organization account.
                    </div>
                  </label>
                  <label
                    className={!migrateAssets.value ? 'disabled' : ''}
                    htmlFor="migrateBilling"
                  >
                    <Input
                      {...migrateBilling}
                      disabled={!migrateAssets.value}
                      type="checkbox"
                      name="migrateBilling"
                      error={form.migrateBilling.error}
                      showValidation={showValidationErrors}
                    />
                    Transfer my billing information
                    <div className="radio-subtext f6 f-subdued">
                      This will permanently move your credit card, account balance, and payment
                      settings from your existing account to the new organization account.
                    </div>
                  </label>
                </div>
              </div>
            )}
          </div>

          {serverError && (
            <Callout
              text={serverError}
              type={Callout.TYPE.ERROR}
              iconColor={Callout.THEME.COLOR}
              defaultIcon
              className="OrgCreationForm__callout"
            />
          )}
        </div>

        <footer className="OrgCreationForm__footer">
          <Submit
            formProcessing={formProcessing}
            formSuccessful={isSuccessful}
            label="Create Organization"
          />
        </footer>
      </form>
    );
  }
}

OrgCreationForm.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.object.isRequired,
  migrateBilling: PropTypes.object.isRequired,
  migrateAssets: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  formProcessing: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  isDirty: PropTypes.bool,
  serverError: PropTypes.string,
};

export const config = formGenerator({
  form: 'OrgCreationForm',
  fields: ['name', 'migrateAssets', 'migrateBilling'],
  validators: {
    name: [required('Organization name required')],
  },

  onSubmit(form, actionTypes, dispatch, state, HOC) {
    const migrateAssets = !!form.migrateAssets.value && HOC.props.selectedOrg.isMyAccount;
    const migrateBilling = !!form.migrateBilling.value && HOC.props.selectedOrg.isMyAccount;
    const newOrgName = form.name.value;

    if (migrateAssets) {
      HOC.props
        .convertPersonalToOrg(newOrgName, migrateBilling)
        .then(() => {
          HOC.props.onOrgCreation();

          dispatch({
            type: actionTypes.SUCCESS,
          });

          dispatch({
            type: actionTypes.RESET,
          });
        })
        .catch((errorMessage) => {
          dispatch({
            type: actionTypes.ERROR,
            errorMessage,
          });

          return Promise.reject(errorMessage);
        });
    } else {
      HOC.props
        .createOrganization(form.name.value)
        .then(() => {
          HOC.props.onOrgCreation();

          dispatch({
            type: actionTypes.SUCCESS,
          });

          dispatch({
            type: actionTypes.RESET,
          });
        })
        .catch((errorMessage) => {
          dispatch({
            type: actionTypes.ERROR,
            errorMessage,
          });

          return Promise.reject(errorMessage);
        });
    }
  },
});

const OrgCreationFormHoC = connect(
  (state) => ({
    creditCard: state.organization.billingInformation.creditCard || new CreditCardModel(),
    selectedOrg: getSelectedOrg(state) || new OrgModel(),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        createOrganization: organizationActions.createOrganization,
        convertPersonalToOrg: organizationActions.convertPersonalToOrg,
      },
      dispatch
    )
)(FormHOC(OrgCreationForm, config));

export default OrgCreationFormHoC;
