import React from 'react';
import classNames from 'clsx';
import { Callout, Panel } from '@holokit/core';

export function BetaCallout({ children, icon, rightElement, className }) {
  return (
    <Panel noBodyPadding>
      <Callout
        className={classNames('BetaCallout__callout', className)}
        iconLeft={icon}
        rightElement={rightElement}
        size={Callout.SIZE.LARGE}
        text={children}
        type="beta"
      />
    </Panel>
  );
}

export default BetaCallout;
