import React, { useEffect } from 'react';
import { Button } from '@holokit/core';
import { ERROR_RETURN } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

function ActivityHistoryDetailsError({ error }) {
  useEffect(() => {
    sendAnalyticsEvent({
      type: ERROR_RETURN,
      data: {
        name: 'Devices - Activity History - Details page empty',
        error: error?.toString(),
      },
    });
  }, [error]);
  return (
    <div className="ActivityHistoryDetailsError">
      <div className="ActivityHistoryDetailsError__wrapper">
        <h2>There was an error loading this data</h2>
        <Button onClick={() => window.location.reload()}>Reload activity history</Button>
      </div>
    </div>
  );
}

export default ActivityHistoryDetailsError;
