import { useEffect, useRef, useState } from 'react';

/**
 * Returns a ref and the dimensions of the element that ref is pointing to.
 * This will update anytime the element is resized, so the width and height it provides will always be accurate.
 * First render will always be width:0, height:0
 * We should strive to not use this hook often.  In MOST cases CSS can solve the problems we face without needing
 * access to component size in javascript.  However, in the rare cases we do need component size, this hook will work.
 */
const useContainerSize = () => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        setDimensions({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      }
    });
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [containerRef]);
  return [containerRef, dimensions];
};

export default useContainerSize;
