import React from 'react';

interface ActivationSidebarProps {
  children?: React.ReactNode;
}

function ActivationSidebar({ children }: ActivationSidebarProps) {
  return (
    <div className="ActivationSidebar">
      <div className="ActivationSidebar__wrapper">{children}</div>
    </div>
  );
}

export default ActivationSidebar;
