import { ADD_ONE, DISMISS_ALL, DISMISS_ONE } from './actionTypes';

export const dismissOne = (id: number) => ({
  type: DISMISS_ONE,
  id,
});

export type GlobalMessageType = 'success' | 'error';

export const pushGlobalMessage =
  (message: string, messageType: GlobalMessageType) => (dispatch: any) => {
    const id = Date.now();

    setTimeout(() => {
      dispatch(dismissOne(id));
    }, 8000);

    dispatch({
      type: ADD_ONE,
      message,
      messageType,
      id,
    });
  };

export const dismissAll = () => ({
  type: DISMISS_ALL,
});
