import { Loader } from '@hologram-dimension/loader';
import { Icon, MetricButton, Panel, Tooltip } from '@holokit/core';
import _classnames from 'clsx';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as DeviceStates from 'common-js/constants/deviceStates';
import * as Paths from 'common-js/constants/paths';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router';
import { createPillProps } from '../util/index';

const HorizontalStackedBarChart = React.lazy(() =>
  import('@holokit/charts').then((module) => ({ default: module.HorizontalStackedBarChart }))
);

class MetricPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChartIndex: null,
    };
  }

  handleMetricHover = (barIndex) => {
    this.setState({ activeChartIndex: barIndex });
  };

  handleMetricButtonClick = (segment) => {
    const { router } = this.props;
    const preflightStates = [
      DeviceStates.TEST_ACTIVATE,
      DeviceStates.TEST_AUTOACTIVATE,
      DeviceStates.TEST_HIBERNATE,
      DeviceStates.INACTIVE,
      DeviceStates.INACTIVE_TESTED,
    ];
    router.push({
      pathname: preflightStates.includes(segment.deviceStateFilters[0])
        ? Paths.DEVICES_PREFLIGHT
        : Paths.DEVICES_DEVICES,
      state: {
        setFiltersOnLoad: [{ key: 'state', values: segment.deviceStateFilters }],
      },
    });
    sendAnalyticsEvent({
      type: analyticsTypes.FLEET_HOME_METRIC_BUTTON_CLICK,
      data: {
        button_name: segment.name,
      },
    });
  };

  render() {
    const {
      buttonClasses,
      comparingDataFromPeriod,
      deviceSegments,
      isLoading,
      metricTitle,
      toolTipText,
      historicFlag,
    } = this.props;
    const { activeChartIndex } = this.state;

    return (
      <Panel
        title={
          <div className="MetricPanel__title">
            <div className="MetricPanel__title__text">
              {metricTitle}
              <div className="MetricPanel__info-icon">
                <Tooltip light content={toolTipText} position="top">
                  <Icon
                    name="Circle--info"
                    size="minor"
                    svgProps={{ style: { fill: '#ffffff' } }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        }
        dark
        bodyClasses="MetricPanel__body"
        smallTitle
      >
        <div className="MetricPanel__chart">
          <Suspense fallback={<Loader />}>
            <HorizontalStackedBarChart
              data={deviceSegments}
              activeIndex={activeChartIndex}
              isLoading={isLoading}
            />
          </Suspense>
        </div>
        <section
          className={_classnames('MetricPanel__buttons', {
            'MetricPanel__buttons--threeColumns': deviceSegments.length > 2,
          })}
        >
          {deviceSegments.map((segment, i) => {
            const pillProps = createPillProps(segment, comparingDataFromPeriod);

            return (
              <div
                className={_classnames('MetricPanel__buttons__button', buttonClasses)}
                key={`MetricPanel__button--${segment.name}`}
                onMouseOver={() => {
                  this.handleMetricHover(i);
                }}
                onFocus={() => {
                  this.handleMetricHover(i);
                }}
                onMouseLeave={() => {
                  this.setState({ activeChartIndex: null });
                }}
              >
                <MetricButton
                  number={segment.number}
                  text={segment.name}
                  orbColor={segment.color}
                  onClick={() => this.handleMetricButtonClick(segment)}
                  isLoading={isLoading}
                  pillProps={historicFlag ? pillProps : null}
                />
              </div>
            );
          })}
        </section>
      </Panel>
    );
  }
}

MetricPanel.propTypes = {
  comparingDataFromPeriod: PropTypes.string,
  metricTitle: PropTypes.string,
  deviceSegments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.number,
      color: PropTypes.string,
    })
  ),
  toolTipText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object.isRequired,
};

MetricPanel.defaultProps = {
  metricTitle: '',
  toolTipText: '',
  deviceSegments: [],
};

const MetricPanelHOC = withRouter(MetricPanel);

export default MetricPanelHOC;
