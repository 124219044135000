import React from 'react';
import submitDashboardMarketoForm from 'common-js/utils/marketing';
import { useFormContext } from 'react-hook-form';
import { IconCard } from '../../components';

function Step3(props) {
  const { handleAnalyticsCall, nextStep } = props;
  const { setValue, getValues } = useFormContext();
  const handleClick = (projectType) => () => {
    handleAnalyticsCall('projectType', projectType);
    setValue('customerNeed', projectType);
    submitDashboardMarketoForm(getValues());
    nextStep();
  };

  return (
    <div className="Step NewForm">
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__Header">
            <h2>Tell us about your project</h2>
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__SubHeader">
            Please select the option that best describes your current needs
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q3_prototyping.svg"
            onClick={handleClick('Prototyping')}
            mainText="Prototyping"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q3_ready-to-launch.svg"
            onClick={handleClick('Ready to Launch')}
            mainText="Ready to launch"
          />
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q3_new-provider.svg"
            onClick={handleClick('New Provider')}
            mainText="Considering a new provider"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q3_scaling.svg"
            onClick={handleClick('Scaling to new areas')}
            mainText="Scaling to new areas"
          />
        </div>
      </div>
      <div className="NewForm__BottomRow">
        <div className="NewForm__DoubleColumn">
          <IconCard mainText="Not sure" onClick={handleClick('Not Sure')} />
        </div>
      </div>
    </div>
  );
}

export default Step3;
