import { Button } from '@hologram-dimension/button';
import ReportsError from '../ReportsError';

const handleClick = () => window.location.reload();

function ReportsLoadError() {
  return (
    <ReportsError
      caption="Unable to load reports"
      message="Please check your connection and try again."
      button={
        <Button variant="secondary" size="small" onClick={handleClick}>
          Reload Page
        </Button>
      }
    />
  );
}

export default ReportsLoadError;
