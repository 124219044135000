import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const setMultipleFilters = (filterKey, comparatorsToValues) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_MULTIPLE_FILTERS,
    filterKey,
    comparatorsToValues,
  });

  dispatch({
    type: actionTypes.RESET_PAGING,
  });

  dispatch(fetchDevices());
};
