import _ from 'lodash';
import moment from 'moment';
import { getOperatorDisplayName } from '../utils/operatorDisplayName';
import { eUICCActions, eUICCStates } from '../constants/deviceStates';

export default class eUICCTestModel {
  action = null;

  deviceid = 0;

  iccid = null;

  id = 0;

  imei = null;

  linkid = 0;

  metadata = {};

  state = null;

  timestamp = null;

  #actionName = null;

  #currentCycle = 0;

  #concurrentWith = [];

  #duration = null;

  #formattedTimestamp = null;

  #iccidName = null;

  #operations = [];

  #requestedCycles = 0;

  #stateName = null;

  constructor(test, operations = []) {
    if (!test) return;

    this.action = test.action;
    this.deviceid = test.deviceid;
    this.#duration = test.duration || 0;
    this.iccid = test.iccid;
    this.id = test.id;
    this.imei = test.imei;
    this.linkid = test.linkid;
    this.metadata = test.metadata || {};
    this.state = test.state;
    this.timestamp = moment.utc(test.timestamp, 'YYYY-MM-DD HH:mm:ss');

    if (operations && Array.isArray(operations) && operations.length) {
      const euiccOperations = operations.map((op) =>
        // eslint-disable-next-line new-cap
        op instanceof eUICCTestModel ? op : new eUICCTestModel(op)
      );
      this.#operations = euiccOperations;

      let inCycle = this.currentCycle;

      for (let x = this.#operations.length - 1; x >= 0; x--) {
        const t = this.#operations[x];
        if (t.currentCycle < inCycle) {
          // console.debug(
          //   `Test #${t.id} current cycle = ${t.currentCycle}, which is less than ${inCycle} - changing to match`
          // )
          t.metadata.cur_cycle = inCycle;
          if (t.action === 'EUICC_TEST' && t.state === 'COMPLETE') {
            // console.debug(
            //   `\tTest is in ${t.action} - ${t.state}, bumping cycle up to ${t
            //     .metadata.cur_cycle + 1}`
            // )
            t.metadata.cur_cycle += 1;
          }
        }
        inCycle = t.currentCycle;

        if (t.requestedCycles < this.requestedCycles) {
          t.metadata.requested_cycles = this.requestedCycles;
        }
      }
    }
    this.#operations.push(this);
  }

  get actionName() {
    if (!this.#actionName && this.action) {
      this.#actionName = eUICCTestModel.formatAction(this.action);
    }
    return this.#actionName;
  }

  get concurrentWith() {
    return this.#concurrentWith;
  }

  get concurrentGroupKey() {
    return [this, ...this.#concurrentWith]
      .map((t) => t.id)
      .sort((a, b) => a - b)
      .join('::');
  }

  get currentCycle() {
    let result = this.#currentCycle;

    if (
      this.#currentCycle === 0 &&
      ![null, undefined].includes(this.metadata.cur_cycle) &&
      this.metadata.cur_cycle !== this.#currentCycle
    ) {
      result = parseInt(this.metadata.cur_cycle, 10);
      if (!Number.isNaN(result)) {
        this.#currentCycle = result;
      }
    }
    return this.#currentCycle;
  }

  get currentState() {
    return this.metadata.cur_state;
  }

  get duration() {
    return this.#duration || moment.utc(this.latestOperation.timestamp.diff(this.timestamp));
  }

  get errorMessage() {
    return { ...this.metadata.error_data }.message;
  }

  get errorReason() {
    return this.metadata.error_reason;
  }

  get formattedErrorMessage() {
    const parts = [];
    if (!this.errorReason) {
      return '';
    }
    parts.push(this.errorReason);
    if (this.errorMessage) {
      parts.push(this.errorMessage);
    }
    return parts.join(' - ');
  }

  get formattedTimestamp() {
    if (!this.#formattedTimestamp && this.timestamp) {
      this.#formattedTimestamp = eUICCTestModel.formatTimestamp(this.timestamp);
    }
    return this.#formattedTimestamp;
  }

  get hasError() {
    return (
      this.state === 'ERROR' ||
      (typeof this.errorReason === 'string' && this.errorReason.length > 0)
    );
  }

  get iccidName() {
    if (!this.#iccidName && this.iccid) {
      this.#iccidName = eUICCTestModel.formatICCID(this.iccid);
    }
    return this.#iccidName;
  }

  get isRollbackPending() {
    return this.metadata.is_rollback_pending === true;
  }

  get latestOperation() {
    if (this.#operations.length < 1) {
      return this;
    }

    if (this.#operations.length > 1) {
      this.#operations.sort((a, b) => -1 * (a.timestamp - b.timestamp));
    }
    return this.#operations[0];
  }

  get operations() {
    return this.#operations;
  }

  get requestedCycles() {
    if (this.#requestedCycles === 0) {
      let result = this.#requestedCycles;
      if (![null, undefined].includes(this.metadata.requested_cycles)) {
        result = parseInt(this.metadata.requested_cycles, 10);
      } else if (![null, undefined].includes(this.metadata.orig_cycles)) {
        result = parseInt(this.metadata.orig_cycles, 10);
      }
      if (!Number.isNaN(result)) {
        this.#requestedCycles = result;
      }
    }
    return this.#requestedCycles;
  }

  get stateName() {
    if (!this.#stateName && this.state) {
      this.#stateName = eUICCTestModel.formatState(this.state);
    }
    return this.#stateName;
  }

  /**
   * Formats the provided eUICC Profile History action into a human-readable display form
   * @param {string} action A eUICC Profile History "action" returned as part of an individual
   *                        record from the Hologram API.
   * @returns {string}
   * @example
   * console.log(eUICCTestModel.formatAction('EUICC_TEST'))
   * > eUICC Test
   */
  static formatAction(action) {
    if (!eUICCActions.includes(action)) {
      // console.debug(
      //   `Attempting for format an unknown eUICC Action: "${action}". Not in the list of:`,
      //   eUICCActions
      // )
    }
    const [euicc, ...rest] = _.words(action);
    return [_.lowerFirst(euicc), ...rest.map(_.capitalize)].join(' ');
  }

  /**
   * Formats a numeric ICCID string into a human-readable cellular operator name
   * @param {string} iccid
   * @returns {string}
   */
  static formatICCID(iccid) {
    return getOperatorDisplayName(iccid);
  }

  /**
   * Formats the provided eUICC Profile History state into a human-readable display form
   * @param {string} state A eUICC Profile History "state" returned as part of an individual
   *                        record from the Hologram API.
   * @returns {string}
   * @example
   * console.log(eUICCTestModel.formatAction('TEST_REQUESTED'))
   * > Test requested
   */
  static formatState(state) {
    if (!eUICCStates.includes(state)) {
      // console.debug(
      //   `Attempting for format an unknown eUICC State: "${state}". Not in the list of:`,
      //   eUICCStates
      // )
    }
    return _.capitalize(state.replace('_', ' '));
  }

  /**
   * Formats the provided timestamp into a "MM/DD/YYYY H:mm A z" output
   * @param {string} timestamp A "YYYY-MM-DD HH:mm:ss"-formated timestamp
   * @param {string} format An optional `momentjs` display formatting template string to use to
   *                        customize the resulting output. Defaults to `MM/DD/YYYY H:mm A z`
   * @returns {string}
   * @example
   * console.log(eUICCTestModel.formatTimestamp('2021-03-30 12:20:20'))
   * > 03/30/2021 12:20 UTC
   */
  static formatTimestamp(timestamp, format = 'MM/DD/YYYY H:mm A z') {
    return moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss').format(format);
  }

  /**
   *
   * @param {Array[Object[]]} euiccTestHistory
   * @param {Object[]} euiccTestHistory[].testGroup
   * @param {string} euiccTestHistory[].testGroup[].action
   * @param {Object} euiccTestHistory[].testGroup[].metadata
   * @param {string} euiccTestHistory[].testGroup[].state
   * @param {string|Date} euiccTestHistory[].testGroup[].timestamp
   * @returns {eUICCTestModel[]}
   */
  static groupByTest(euiccTestHistory) {
    const results = euiccTestHistory.map((group) => {
      if (!Array.isArray(group)) {
        // eslint-disable-next-line no-console
        console.error('For some reason, euicc test history was not an array:', group);
        return undefined;
      }
      const models = group.reduce((batch, test, index) => {
        // eslint-disable-next-line new-cap
        const model = new eUICCTestModel(test);
        const lastModel = index > 0 ? batch[index - 1] : null;
        if (lastModel && model.timestamp.isSame(lastModel.timestamp)) {
          lastModel.concurrentWith.push(model);
          model.concurrentWith.push(lastModel);
        }
        return [...batch, model];
      }, []);
      const testStart = models.pop();
      if (models.length > 0) {
        testStart.operations.push(...models);
      }
      return testStart;
    });
    return results;
  }
}
