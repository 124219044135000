import { BILLING_ACCOUNT_UPDATE_ADDRESS, ERROR_RETURN } from 'common-js/analytics/actionTypes';
import sendAnalyticsEvent from 'common-js/analytics/analytics';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { updateBillingAddress as updateBillingAddressAction } from 'common-js/reducers/account/actions';
import { getAllBilling } from 'common-js/reducers/organization/actions';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

const useUpdateBillingAddress = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (updatedAddress) =>
      dispatch(updateBillingAddressAction(updatedAddress))
        .then(() => {
          dispatch(getAllBilling());
          sendAnalyticsEvent({ type: BILLING_ACCOUNT_UPDATE_ADDRESS });

          toast(
            <HeadlineToast
              headline="Billing address"
              body="Billing address saved successfully."
              icon="Checkmark--single"
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              className: 'toastify-content--burnt toastify-content--success',
              autoClose: 10000,
            }
          );
        })
        .catch((error) => {
          sendAnalyticsEvent({
            type: ERROR_RETURN,
            data: {
              name: 'Billing address update - Operation Failure',
              error,
            },
          });

          toast(
            <HeadlineToast
              headline="Billing address could not be saved"
              body="Our team has been notified and is working on a fix. Please contact success@hologram.io with questions."
              icon="Circle--alert"
            />,
            {
              className: 'toastify-content--burnt toastify-content--error',
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }),
    [dispatch]
  );
};

export default useUpdateBillingAddress;
