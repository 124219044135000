import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Link } from '@hologram-dimension/link';
import { useAppDispatch, useAppSelector } from 'common-js/hooks';
import {
  acceptInvitation,
  getPendingOrgs,
  resendEmailVerification,
} from 'common-js/reducers/account/actions';
import { selectAccount } from 'common-js/reducers/account/selectors';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import { changeContext, getOrganizations } from 'common-js/reducers/organization/actions';
import { selectOrganization } from 'common-js/reducers/organization/selectors';
import { useCallback, type FC } from 'react';

const MyOrganizations: FC = () => {
  const dispatch = useAppDispatch();

  const { userId, isVerified, pendingOrgs } = useAppSelector(selectAccount) ?? {};
  const { orgs } = useAppSelector(selectOrganization) ?? {};

  const onResendVerify = useCallback(() => {
    dispatch(resendEmailVerification(userId))
      .then(() => {
        dispatch(pushGlobalMessage('Verification email resent.', 'success'));
      })
      .catch((e) => {
        dispatch(pushGlobalMessage(e, 'error'));
      });
  }, [dispatch, userId]);

  const onAcceptInvitation = useCallback(
    async (pendingOrg) => {
      try {
        await dispatch(acceptInvitation(pendingOrg.inviteid, pendingOrg.orgid));

        dispatch(pushGlobalMessage('You have joined an organization.', 'success'));
        dispatch(getOrganizations());
        dispatch(getPendingOrgs());
      } catch (e: any) {
        if (e === 'Please verify your email address before joining org') {
          dispatch(openModal('OrgResendEmailVerifyModal', undefined, 'narrow'));
        } else {
          dispatch(pushGlobalMessage(e, 'error'));
        }
      }
    },
    [dispatch]
  );

  return (
    <div className="MyOrganizations">
      {!isVerified && (
        <BannerNotification variant="verify" className="MyOrganizations__verifyBanner">
          Before you can join an organization you need to verify your email.{' '}
          <Link onClick={onResendVerify} type="inline">
            Resend the verification email
          </Link>
        </BannerNotification>
      )}
      {orgs.length === 0 && pendingOrgs.length === 0 ? (
        <div className="MyOrganizations__content">You don’t belong to any organizations yet.</div>
      ) : (
        <div className="MyOrganizations__content">
          {orgs
            .filter((org) => !org.isMyAccount)
            .map((org) => (
              <div key={org.id} className="org-row invite">
                <div className="org-name">{org.name}</div>
                <Button
                  onClick={() => {
                    dispatch(changeContext(org.id, true, null));
                  }}
                  variant="secondary"
                >
                  Switch organization
                </Button>
              </div>
            ))}
          {pendingOrgs.map((pendingOrg) => (
            <div key={pendingOrg.orgname} className="org-row invite">
              <div className="org-name">{pendingOrg.orgname}</div>
              <Button
                onClick={() => {
                  onAcceptInvitation(pendingOrg);
                }}
              >
                Accept invitation
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyOrganizations;
