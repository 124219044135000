import _classNames from 'clsx';
import {
  ContextSwitcherOverlay,
  Drawer,
  GlobalMessage,
  MainMenu,
  Modal,
} from 'common-js/components';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { getSelectedOrgIndex } from 'common-js/reducers/organization/selectors';
import { selectSearchModalOpen } from 'common-js/reducers/search/selectors';
import CloseButton from 'common-js/toasts/CloseButton';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import SearchModal from 'search/components/SearchModal';
import { Console, ConsoleFilters } from '../console';

interface LayoutProps {
  children?: React.ReactNode;
  params?: any;
}

const Layout: React.FC<LayoutProps> = ({ children, params }) => {
  const isSearchModalOpen = useAppSelector(selectSearchModalOpen);
  const isDrawerMinimized = useAppSelector((state) => !!state.drawer?.isMinimized);
  const isMenuCollapsed = useAppSelector(
    (state) => state.account?.settings?.menu_collapsed === 'yes'
  );
  const drawerTakeover = useAppSelector((state) => !!state.drawer?.drawerTakeover);
  const selectedOrgIndex = useAppSelector(getSelectedOrgIndex);

  return (
    <ContextSwitcherOverlay>
      <div className={`container Layout selectedOrgIdx${selectedOrgIndex % 7}`}>
        <Modal params={params} />
        {isSearchModalOpen && <SearchModal />}
        {!drawerTakeover && (
          <div
            className={_classNames('main-sidebar', {
              'main-sidebar--collapsed': isMenuCollapsed,
            })}
          >
            <MainMenu params={params} />
          </div>
        )}
        <div className="main-content">
          <GlobalMessage />
          {children}
          <Drawer>
            <ConsoleFilters />
            <Console pollLogData={!isDrawerMinimized} />
          </Drawer>
        </div>
        <div>
          <ToastContainer
            position="bottom-right"
            type="warning"
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            pauseOnHover
            closeButton={<CloseButton />}
          />
        </div>
      </div>
    </ContextSwitcherOverlay>
  );
};

export default Layout;
