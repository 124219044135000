import _classnames from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { ComplexIcon, Icon } from '@holokit/core';
import { TOPBAR_HEIGHT } from '../../common/reducers/console/dimensions';
import { setDrawerTakeover } from '../../common/reducers/drawer/actions';

class InspectDrawer extends React.Component {
  componentDidMount() {
    this.debouncedOnResized = _.debounce(this.onResize.bind(this), 16);
    window.addEventListener('resize', this.debouncedOnResized, false);

    const { setDrawerTakeover_ } = this.props;

    setDrawerTakeover_(true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedOnResized, false);
  }

  onResize() {
    this.forceUpdate();
  }

  getDrawerPosition() {
    const { isMinimized, isFullscreen } = this.props;

    if (isFullscreen) {
      return 0;
    }
    if (isMinimized) {
      return window.innerHeight - TOPBAR_HEIGHT;
    }
    return window.innerHeight / 2;
  }

  render() {
    const { children, className, isFullscreen, onClose, topFloat } = this.props;

    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        visibleWindowHeight: window.innerHeight - this.getDrawerPosition(),
      })
    );

    return (
      <div
        className={_classnames('InspectDrawer', 'drawer-container', {
          [className]: !!className,
        })}
      >
        <div
          // eslint-disable-next-line react/no-string-refs
          ref="drawer"
          className={_classnames('drawer', { fullscreen: isFullscreen })}
          style={{
            transform: `translate3d(0, ${this.getDrawerPosition()}px, 0)`,
          }}
        >
          {/* eslint-disable-next-line react/no-string-refs */}
          <div className="top-bar" ref="topBar">
            <ComplexIcon classes="inspect-logo" name="logo-rainbow" />
            <div className="grid-item h3 inspect-header">Inspect</div>
            <div
              className="close-btn"
              onClick={onClose}
              onKeyUp={onClose}
              role="button"
              tabIndex={0}
            >
              <Icon name="Close" size="minor" svgProps={{ style: { fill: '#000000' } }} />
            </div>
          </div>
          <div className="float-bar">{topFloat}</div>
          {/* eslint-disable-next-line react/no-string-refs */}
          <div className="drawer-window" ref="drawerWindow">
            {childrenWithProps}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isMinimized: state.drawer.isMinimized,
    isFullscreen: state.drawer.isFullscreen,
  }),
  (dispatch) => bindActionCreators({ setDrawerTakeover_: setDrawerTakeover }, dispatch)
)(InspectDrawer);
