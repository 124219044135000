import { Button } from '@hologram-dimension/button';
import { Loader } from '@holokit/core';
import _classnames from 'clsx';
import analyticsEventBuilder from 'common-js/analytics';
import removeHtmlTagsFromString from 'common-js/api/search/removeHtmlTagsFromString';
import useFetchSearchResults from 'common-js/api/search/useFetchSearchResults';
import { ClickOutside } from 'common-js/components';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useControlledInput from 'common-js/hooks/useControlledInput';
import { closeSearchModal as closeSearchModalAction } from 'common-js/reducers/search/actions';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { browserHistory } from 'react-router';
import { useAppSelector } from 'common-js/hooks';
import {
  selectHasSimsPages,
  selectPromoteSimsPages,
} from 'common-js/reducers/releaseFlag/selectors';
import SearchModalContent from './SearchModalContent';
import KEY_TRANSLATIONS from './keyTranslations';
import getResultLink, { MatchedDevice } from './searchUtils';

const handleSubmit = (e) => {
  e.preventDefault();
};

const SearchModal: FC = () => {
  const dispatch = useAppDispatch();
  const fetchSearchResults = useFetchSearchResults();

  const [results, setResults] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useControlledInput('');
  const [activeResult, setActiveResult] = useState<number | null>(null);
  const [showError, setShowError] = useState(false);
  const timeoutId = useRef<number | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const hasSimInventoryPage = useAppSelector(selectHasSimsPages);
  const promoteSimInventoryPage = useAppSelector(selectPromoteSimsPages);

  const closeSearchModal = useCallback(() => {
    dispatch(closeSearchModalAction());
  }, [dispatch]);

  const sendCloseAnalytics = useCallback(() => {
    analyticsEventBuilder.buttonClick('Search', 'Exit Modal ESC Top CTA').send();
  }, []);

  const handleSelectResult = useCallback(
    (id?: number) => {
      let deviceId: DeviceId | undefined;

      if (id) {
        deviceId = id;
      } else if (activeResult !== null && activeResult >= 0 && results[activeResult]?.device?.id) {
        deviceId = results[activeResult].device.id;
      }

      const device: MatchedDevice | undefined =
        deviceId === undefined
          ? undefined
          : results.find((result) => result?.device?.id === deviceId)?.device;

      // this should always be called, but we want to guard against sending events with no data
      if (device) {
        const matchedFields = device.matchedFields || [];

        const highlightedFields = Object.fromEntries([
          ...Object.entries(KEY_TRANSLATIONS).map(([key, value]) => [
            value,
            matchedFields.some((matchedField) => matchedField.key === key),
          ]),
          // the backend can return either key for ICCID, we need to check if either key is present
          ['ICCID', matchedFields.some((field) => field.key === 'sim' || field.key === 'iccid')],
        ]);

        analyticsEventBuilder.buttonClick('Search', 'Results Selection').send({
          'Device ID': device.id,
          'SIM ID': device.simcardid,
          'Query Highlighted Fields': highlightedFields,
          'eUICC Status': device.isHyper ? 'eUICC' : 'UICC',
          'Enabled Profile': matchedFields.some(({ showEnabled }) => showEnabled),
          'Disabled Profile': matchedFields.some(({ showDisabled }) => showDisabled),
          'Exact Match': matchedFields.some(
            ({ match }) => removeHtmlTagsFromString(match) === inputValue
          ),
          Query: inputValue,
        });

        browserHistory.push(
          getResultLink(device, hasSimInventoryPage && promoteSimInventoryPage && device.simcardid)
        );
      }

      closeSearchModal();
    },
    [
      activeResult,
      results,
      closeSearchModal,
      inputValue,
      hasSimInventoryPage,
      promoteSimInventoryPage,
    ]
  );

  const resetTimeout = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  }, [timeoutId]);

  const handleSearch = useCallback(
    async (s) => {
      try {
        setResults(await fetchSearchResults(s));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setShowError(true);
      }
    },
    [fetchSearchResults, setLoading, setResults, setShowError]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (['Enter', 'ArrowDown', 'ArrowUp', 'Escape'].includes(e.key)) {
        e.preventDefault();
      }
      if (e.key === 'Enter') {
        if (activeResult !== null) {
          handleSelectResult();
        } else {
          // clear any pending searches, we're doing the search now
          resetTimeout();
          handleSearch(inputValue);
        }
      }
      if (e.key === 'ArrowDown') {
        setActiveResult((n) => (n === null ? 0 : Math.min(n + 1, results.length - 1)));
      }
      if (e.key === 'ArrowUp') {
        setActiveResult((n) => (!n ? null : n - 1));
      }
      if (e.key === 'Escape') {
        closeSearchModal();
        sendCloseAnalytics();
      }
    },
    [
      setActiveResult,
      results.length,
      handleSelectResult,
      resetTimeout,
      handleSearch,
      activeResult,
      inputValue,
      closeSearchModal,
      sendCloseAnalytics,
    ]
  );

  const handleDismissClick = (e) => {
    e.preventDefault();
    closeSearchModal();
    sendCloseAnalytics();
  };

  const handleRowHover = useCallback((row) => setActiveResult(row), [setActiveResult]);

  useEffect(() => {
    if (inputValue === '') {
      timeoutId.current = null;
      setLoading(false);
      setResults([]);
      return () => {};
    }

    // debounce searching when inputValue changes
    setLoading(true);
    setActiveResult(null);
    timeoutId.current = window.setTimeout(async () => {
      await handleSearch(inputValue);
      timeoutId.current = null;
    }, 300);

    return resetTimeout;
  }, [inputValue, timeoutId, handleSearch, resetTimeout, setActiveResult]);

  useEffect(() => {
    analyticsEventBuilder.buttonClick('Search', 'Open Search Modal').send();
    inputRef.current?.focus();
  }, []);

  useEffect(
    () =>
      browserHistory.listen(() => {
        closeSearchModal();
      }),
    [closeSearchModal]
  );

  return (
    <div className="SearchModal__container">
      <ClickOutside callback={closeSearchModal}>
        <div
          className={_classnames('SearchModal', {
            'SearchModal--loading': loading,
          })}
        >
          <Loader isLoading={loading} />
          <form className="SearchModal__form" onSubmit={handleSubmit}>
            <input
              className="SearchModal__input"
              onChange={setInputValue}
              onKeyDown={handleKeyDown}
              placeholder="Search device name, full or partial IMEI, device ID, ICCID, and more..."
              ref={inputRef}
              type="text"
              value={inputValue}
            />
            <Button onClick={handleDismissClick} variant="tertiary" type="submit">
              Dismiss (ESC)
            </Button>
          </form>
          <div className="SearchModal__body">
            <SearchModalContent
              results={results}
              loading={loading}
              inputValue={inputValue}
              showError={showError}
              activeResult={activeResult}
              onRowHover={handleRowHover}
              onSelectResult={handleSelectResult}
            />
          </div>
        </div>
      </ClickOutside>
    </div>
  );
};

export default SearchModal;
