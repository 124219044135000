import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button } from '@holokit/core';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import { getDeviceActionString } from 'devices/util';

function ActivityHistoryDetailsTitle({
  numDevices,
  action,
  date,
  teamMember,
  isExporting,
  onExportCsv,
}) {
  const { activity_history_export_csv: activityHistoryExportCsvFlag } =
    useAppSelector(getReleaseFlags);

  return (
    <div className="ActivityHistoryDetails__title">
      <div className="ActivityHistoryDetails__title-metadata">
        {getDeviceActionString(numDevices, action)}

        <div className="ActivityHistoryDetails__byline">
          {date} by {teamMember}
        </div>
      </div>
      {activityHistoryExportCsvFlag && (
        <div className="ActivityHistoryDetails__title-buttons">
          <Button
            type="secondary"
            onClick={onExportCsv}
            loading={isExporting}
            disabled={isExporting}
          >
            Export CSV
          </Button>
        </div>
      )}
    </div>
  );
}

export default ActivityHistoryDetailsTitle;
