import { Checkbox } from '@hologram-dimension/checkbox';
import { Button, ClickOutside, Icon, InputGroup, Tooltip } from '@holokit/core';
import { AppDispatch } from 'common-js/configureStore';
import { addNewTag, changeTagName, removeTag } from 'common-js/reducers/deviceFilter/actions';
import { close, openModal } from 'common-js/reducers/modal/actions';
import { pluralize } from 'common-js/utils/language';
import React, { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

type TagManagerRowPropsFromRedux = ConnectedProps<typeof connector>;
interface TagManagerRowProps extends TagManagerRowPropsFromRedux {
  tag?: Tag;
  checked?: boolean;
  allowDeletingActiveTags: boolean;
  showCheckbox?: boolean;

  toggleAddTagForm?: () => void;
  onToggleCheckbox?: (tag: Tag, checked: boolean) => void;
  onCreate?: () => void;
  onEdit?: () => void;
  onDelete?: (tagId: number) => void;
  showToast: (message: string, success: boolean) => void;
}

interface TagManagerRowState {
  error: string;
  isForm: boolean;
  isLoading: boolean;
  inputValue: string;
}
class TagManagerRow extends Component<TagManagerRowProps, TagManagerRowState> {
  constructor(props: TagManagerRowProps) {
    super(props);

    this.state = {
      error: '',
      isForm: false,
      isLoading: false,
      inputValue: '',
    };
  }

  handleInputChange = (e: any) => {
    const { value } = e.currentTarget;

    this.setState({ error: '', inputValue: value });
  };

  handleShowForm = () => {
    const { tag } = this.props;

    this.setState({
      isForm: true,
      inputValue: tag?.name ?? '',
    });
  };

  handleCancelForm = () => {
    const { tag, toggleAddTagForm } = this.props;
    this.setState({
      isForm: false,
      inputValue: '',
    });
    if (!tag && toggleAddTagForm) {
      toggleAddTagForm();
    }
  };

  handleCheckboxClick = () => {
    const { checked, onToggleCheckbox, tag } = this.props;

    // The `checked` state is managed in RHF in the Tags component
    if (onToggleCheckbox && tag) {
      onToggleCheckbox(tag, !checked);
    }
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      this.handleCancelForm();
    }
  };

  handleSubmit = (e: any) => {
    const { addNewTag_, changeTagName_, onCreate, onEdit, showToast, tag, toggleAddTagForm } =
      this.props;
    e.preventDefault();
    e.stopPropagation();
    const { inputValue } = this.state;
    if (inputValue !== '') {
      this.setState({ isLoading: true }, () => {
        if (tag?.id) {
          changeTagName_(tag.id, inputValue)
            .then(() => {
              this.setState({
                isLoading: false,
                isForm: false,
                inputValue: '',
              });
              showToast('Tag name was updated successfully.', true);
              if (onEdit) {
                onEdit();
              }
            })
            .catch((error) => {
              this.setState({
                error,
                isLoading: false,
              });
            });
        } else {
          addNewTag_(inputValue)
            .then(() => {
              this.setState({
                isLoading: false,
              });
              if (toggleAddTagForm) {
                toggleAddTagForm();
              }
              showToast('New tag was added successfully.', true);
              if (onCreate) {
                onCreate();
              }
            })
            .catch((error) => {
              this.setState({
                error,
                isLoading: false,
              });
            });
        }
      });
    }
  };

  handleDelete = () => {
    const {
      allowDeletingActiveTags,
      closeModal_,
      onDelete,
      openModal_,
      removeTag_,
      showToast,
      tag,
    } = this.props;
    const { deviceids, id, name } = tag ?? {};
    const devicesCount = deviceids?.length ?? 0;

    if (id) {
      if (allowDeletingActiveTags) {
        openModal_(
          'DeleteTagModal',
          {
            action: () => {
              removeTag_(id)
                .then(() => {
                  showToast('Tag was deleted successfully.', true);
                  closeModal_();
                })
                .catch((deletionError) => {
                  showToast(deletionError, false);
                  closeModal_();
                });
            },
            tagName: name,
            tagId: id,
            devicesCount,
          },
          'narrow'
        );
      } else {
        removeTag_(id)
          .then(() => {
            showToast('Tag was deleted successfully.', true);
          })
          .catch((deletionError) => {
            showToast(deletionError, false);
          });
      }

      if (onDelete) {
        onDelete(id);
      }
    }
  };

  render() {
    const { allowDeletingActiveTags, checked, showCheckbox = false, tag } = this.props;
    const { error, isForm, isLoading, inputValue } = this.state;

    if (isForm || !tag) {
      return (
        <tr className="Table__row TagManager__row">
          <td className="Table__cell Table__cell--no-border-right" colSpan={3}>
            <div className="Table__cell-content">
              <Icon
                classes="TagManager__row__tag-icon"
                name="Tag"
                size="minor"
                svgProps={{ fill: '#8008F7' }}
              />
              <ClickOutside callback={this.handleCancelForm}>
                <form className="TagManager__form" onSubmit={this.handleSubmit}>
                  <InputGroup
                    errorText={error}
                    inputProps={{
                      autoFocus: true,
                      onKeyDown: this.handleKeyPress,
                    }}
                    iconRight={error ? 'Warning' : 'Arrow--bend--west'}
                    iconRightColor="#8008F7"
                    iconRightText={!error && 'Save'}
                    iconRightTextColor="#8008F7"
                    iconRightOnClick={!error && this.handleSubmit}
                    isLoading={isLoading}
                    onChange={this.handleInputChange}
                    status={!!error && 'warning'}
                    type="text"
                    value={inputValue}
                  />
                </form>
              </ClickOutside>
            </div>
          </td>
        </tr>
      );
    }
    const { deviceids, id, name } = tag;
    const devicesCount = deviceids?.length ?? 0;

    return (
      <tr key={id} className="Table__row TagManager__row">
        <td className="Table__cell Table__cell--no-border-right">
          <div className="Table__cell-content TagManager__cell-content">
            <Icon
              classes="TagManager__row__tag-icon"
              name="Tag"
              size="minor"
              svgProps={{ fill: '#8008F7' }}
            />
            {showCheckbox ? (
              <Checkbox
                className="TagManager__checkbox"
                label={name}
                onChange={this.handleCheckboxClick}
                defaultChecked={checked}
              />
            ) : (
              name
            )}
          </div>
        </td>
        <td className="Table__cell Table__cell--no-border-right">
          <div className="Table__cell-content">
            {devicesCount > 0 ? pluralize('device', devicesCount) : '-'}
          </div>
        </td>
        <td className="Table__cell Table__cell--no-border-right TagManager__action-cell">
          <div className="Table__cell-content Table__cell-content--align-right">
            <div className="TagManager__tooltip-button" aria-label={`Edit tag ${name}`}>
              <Tooltip content="Edit tag name" small>
                <Button iconLeft="Pencil" onClick={this.handleShowForm} small type="secondary" />
              </Tooltip>
            </div>
            {(allowDeletingActiveTags || devicesCount === 0) && (
              <div className="TagManager__tooltip-button" aria-label={`Delete tag ${name}`}>
                <Tooltip content="Delete tag" small>
                  <Button iconLeft="Trash" onClick={this.handleDelete} small type="secondary" />
                </Tooltip>
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      addNewTag_: addNewTag,
      changeTagName_: changeTagName,
      openModal_: openModal,
      closeModal_: close,
      removeTag_: removeTag,
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);
export default connector(TagManagerRow);
