import React from 'react';
import { Icon, Tooltip } from '@holokit/core';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';

const Light = ({ icon, label, small, tooltip, first, last }) => (
  <div
    className={applyConditionalClassNames({
      lightInfo: true,
      'lightInfo--first': first,
      'lightInfo--last': last,
    })}
  >
    <div
      className={applyConditionalClassNames({
        light: true,
        expanded: !small,
      })}
    >
      <Tooltip block content={tooltip}>
        <div className="lightInfo__icon">
          <Icon name={icon} size="minor" svgProps={{ style: { fill: '#0a1435' } }} />
        </div>
      </Tooltip>
    </div>
    {!small && <div className="light-label">{label}</div>}
  </div>
);

export default Light;
