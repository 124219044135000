import isString from 'lodash/isString';
import queryString from 'query-string';
import getStore from '../../configureStore';
import { OrgModel } from '../../models';
import { API_URL, getHeaders, headers } from '../config';
import { fetchErrorHandler, fetchHandler } from '../middleware';

export function createOrganization(name) {
  return fetch(`${API_URL}/organizations`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      name,
    }),
  })
    .then(
      fetchHandler((body) => {
        const { userId } = getStore().getState().account;

        return Promise.resolve(new OrgModel(body.data, userId));
      })
    )
    .catch(fetchErrorHandler);
}

export function convertPersonalToOrg(name, convertBilling) {
  return fetch(`${API_URL}/organizations/convertpersonal`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      newname: name,
      billingstayspersonal: convertBilling ? 0 : 1,
    }),
  })
    .then(
      fetchHandler((body) => {
        const { userId } = getStore().getState().account;
        return Promise.resolve(new OrgModel(body.data, userId));
      })
    )
    .catch(fetchErrorHandler);
}

export function getOrganizations(userId, limit = 1000) {
  const qString = queryString.stringify({
    limit,
    userid: userId,
  });

  return fetch(`${API_URL}/organizations?${qString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler((body) => {
        const { userId: accountUserId } = getStore().getState().account;
        const orgModels = body.data.map((org) => new OrgModel(org, accountUserId));
        return Promise.resolve({ orgs: orgModels });
      })
    )
    .catch(fetchErrorHandler);
}

export function getOrganizationsByIds(orgIds: Array<OrgId>, limit = 1000) {
  const qString = queryString.stringify({
    limit,
    ids: orgIds,
  });

  return fetch(`${API_URL}/organizations?${qString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler((body) => {
        const { userId: accountUserId } = getStore().getState().account;
        const orgModels = body.data.map((org) => new OrgModel(org, accountUserId));
        return Promise.resolve({ orgs: orgModels });
      })
    )
    .catch(fetchErrorHandler);
}

export function searchOrganizations(query, prettifyPersonal = true, limit = 100) {
  return fetch(`${API_URL}/organizations?q=${query}&limit=${limit}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler((body) => {
        const { userId } = getStore().getState().account;
        const orgModels = body.data.map((org) => new OrgModel(org, userId, prettifyPersonal));

        return Promise.resolve({ orgs: orgModels });
      })
    )
    .catch(fetchErrorHandler);
}

// return fetch(`${API_URL}/organizations/${id}/groups`, {

export function updateOrganization(id, name, ccemails) {
  const body: any = {};

  if (isString(ccemails)) body.ccemails = ccemails;
  if (name) body.name = name;

  return fetch(`${API_URL}/organizations/${id}`, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  })
    .then(
      fetchHandler((resBody) => {
        const { userId } = getStore().getState().account;
        return Promise.resolve(new OrgModel(resBody.data, userId));
      })
    )
    .catch(fetchErrorHandler);
}

export function addUsersToOrg(orgId, emails, permissions) {
  const updatedUsers = emails.map((email) => ({
    email,
    permissions,
  }));

  return fetch(`${API_URL}/organizations/${orgId}/users`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      users: updatedUsers,
    }),
  })
    .then(
      fetchHandler((body) => {
        const updatedOrg = new OrgModel(body.data);
        return Promise.resolve(updatedOrg);
      })
    )
    .catch(fetchErrorHandler);
}

export function updateUserPermissions(orgId, userIds, permissions) {
  const updatedUsers = userIds.map((userId) => ({
    userid: userId,
    permissions,
  }));

  return fetch(`${API_URL}/organizations/${orgId}/users`, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      users: updatedUsers,
    }),
  })
    .then(
      fetchHandler((body) => {
        const updatedOrg = new OrgModel(body.data);
        return Promise.resolve(updatedOrg);
      })
    )
    .catch(fetchErrorHandler);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function resendInvitation(inviteId, _userContextData) {
  return fetch(`${API_URL}/organizations/resendinvite/${inviteId}`, {
    method: 'POST',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export function cancelInvitation(inviteId) {
  return fetch(`${API_URL}/organizations/cancelinvite/${inviteId}`, {
    method: 'POST',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export function removeUsers(orgId, userIds) {
  return fetch(`${API_URL}/organizations/${orgId}/users`, {
    method: 'DELETE',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      users: userIds,
    }),
  })
    .then(
      fetchHandler((body) => {
        const updatedOrg = new OrgModel(body.data);
        return Promise.resolve(updatedOrg);
      })
    )
    .catch(fetchErrorHandler);
}

export function getOrgGroups(orgId) {
  return fetch(`${API_URL}/organizations/${orgId}/groups`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function updateOrgGroup(orgId) {
  return fetch(`${API_URL}/organizations/${orgId}/groups`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function updatePlanUpgrade(userContextData) {
  const orgId = userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId;

  return fetch(`${API_URL}/organizations/${orgId}/upgrade`, {
    method: 'PUT',
    credentials: 'include',
    headers,
  })
    .then(
      fetchHandler((body) => {
        const updatedOrg = new OrgModel(body.data);
        return Promise.resolve(updatedOrg);
      })
    )
    .catch(fetchErrorHandler);
}
