import { Button } from '@hologram-dimension/button';
import { close as closeModal_ } from 'common-js/reducers/modal/actions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeaderWithIcon } from '../components';

interface ResumeSimModalProps {
  closeModal: typeof closeModal_;
  onConfirm: Function;
}

class ResumeSimModal extends React.Component<ResumeSimModalProps> {
  componentDidMount() {
    const MODAL_CONTAINER = document.getElementsByClassName('modal-container')[0];
    MODAL_CONTAINER.setAttribute('style', 'width:490px');
  }

  render() {
    const { closeModal, onConfirm } = this.props;

    return (
      <div className="ActivateResendEmailModal apn">
        <ModalHeaderWithIcon iconName="Resume" title="Resuming SIM Data" />
        <div>
          <p>
            A ‘Resume’ command will be issued to our carrier partners for your device. You should
            see the status update shortly.
          </p>
          <div className="modal-buttons dimension-modal-buttons">
            <Button
              onClick={() => {
                closeModal();
                onConfirm();
              }}
              variant="primary"
            >
              Resume SIM
            </Button>
            <Button className="talk-to-sales" onClick={closeModal} variant="secondary">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      closeModal: closeModal_,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ResumeSimModal);
