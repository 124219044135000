import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import { getOrgIdFromContextData, getUserContextData } from 'common-js/api/util';
import {
  getSelectedDeviceState,
  selectDeviceFilters,
  selectIsLoadingPauseResume,
} from 'common-js/reducers/devices/selectors';
import { selectPlanSelectionLimit } from 'common-js/reducers/releaseFlag/selectors';
import { useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useBulkModal from '../useBulkModal';
import { useBulkChangeStateEvent, useBulkChangeStatePreviewEvent } from './useBulkChangeState';

interface UsePauseItemProps {
  deviceType: string;
  totalSelected: number;
}

const usePauseItem = ({ deviceType, totalSelected }: UsePauseItemProps) => {
  const selection = useAppSelector(getSelectedDeviceState);
  const isLoadingPauseResume = useAppSelector(selectIsLoadingPauseResume);
  const { filters, shouldUseSearch } = useAppSelector(selectDeviceFilters);
  const planSelectionLimit = useAppSelector(selectPlanSelectionLimit);
  const userContextData = useAppSelector(getUserContextData);
  const orgId = getOrgIdFromContextData(userContextData)!;

  const pauseDataBulkPreview = useBulkChangeStatePreviewEvent({
    selection,
    filters,
    useSearch: shouldUseSearch,
    orgId,
    newState: 'pause',
  });

  const pauseDataBulk = useBulkChangeStateEvent({
    selection,
    filters,
    useSearch: shouldUseSearch,
    orgId,
    newState: 'pause',
  });

  const openModal = useBulkModal('BulkPauseResumeModal');

  const onClick = useCallback(() => {
    if (totalSelected > 10_000) {
      openModal({ actionType: 'pause', overLimit: true });
      analyticsEventBuilder.errorReturn('Bulk Action', '10k Error').send();
      return;
    }

    openModal({
      actionType: 'pause',
      getPreview: pauseDataBulkPreview,
      action: pauseDataBulk,
    });
  }, [openModal, pauseDataBulk, pauseDataBulkPreview, totalSelected]);

  return {
    label: 'Pause data',
    icon: 'Pause',
    selectionLimit: planSelectionLimit,
    ignore10kLimit: true,
    disabled: isLoadingPauseResume || deviceType === 'preflight',
    onClick,
  };
};

export default usePauseItem;
