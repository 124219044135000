import { Button } from '@hologram-dimension/button';
import ClassNames from 'clsx';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectDataThreshold } from 'common-js/reducers/currentDevice/selectors';
import React from 'react';

interface UsageAlertButtonProps {
  open: () => void;
}

const UsageAlertButton: React.FC<UsageAlertButtonProps> = ({ open }) => {
  const dataThreshold: number = useAppSelector(selectDataThreshold);
  const hasDataThreshold = !!dataThreshold && dataThreshold > 0;

  return (
    <Button
      className={ClassNames({
        'st-btn--caret-down-indigo': hasDataThreshold,
        'st-btn--caret-down-white': !hasDataThreshold,
      })}
      iconEnd="ChevronSingleSouth"
      onClick={open}
      variant={hasDataThreshold ? 'secondary' : 'primary'}
    >
      {hasDataThreshold ? 'Edit data threshold' : 'Set usage alert'}
    </Button>
  );
};

export default UsageAlertButton;
