import { useId } from '@floating-ui/react';
import { Button } from '@hologram-dimension/button';
import { Popover } from '@hologram-dimension/popover';
import { Icon } from '@holokit/core';
import classNames from 'clsx';
import { DEVICE_LANDING, DEVICE_PARAM_NAME } from 'common-js/constants/paths';
import { TOPIC_TYPES, getTopicInfo } from 'common-js/constants/sysTopics';
import { useAppSelector } from 'common-js/hooks';
import { getCachedDevices } from 'common-js/reducers/devices/selectors';
import _ from 'lodash';
import { type FC } from 'react';

interface TopicProps {
  name?: string;
  onClick?: () => void;
  onRemove?: () => void;
  selected?: boolean;
  suggested?: boolean;
  wrapperClasses?: string;
}

const Topic: FC<TopicProps> = ({
  name = '',
  onClick = _.noop,
  onRemove = _.noop,
  selected = false,
  suggested = false,
  wrapperClasses = '',
}) => {
  const cachedDevices = useAppSelector(getCachedDevices);
  const tags = useAppSelector((state) => state.deviceFilters.tags);

  const topicInfo = getTopicInfo(name, tags, cachedDevices);

  const derivedClassnames = classNames(wrapperClasses, {
    topic: true,
    'recent-topic': suggested,
    'selected-topic': selected,
    'has-sugar': !!topicInfo.prettyName,
  });

  const popoverId = useId();

  return selected ? (
    <div className={derivedClassnames}>
      <Icon name="check" />
      {topicInfo.type === TOPIC_TYPES.SYSTEM && (
        <Icon name="System" size="minor" svgProps={{ style: { fill: '#ffffff' } }} />
      )}
      {topicInfo.type === TOPIC_TYPES.DEVICE && (
        <Icon name="Outbound" size="minor" svgProps={{ style: { fill: '#ffffff' } }} />
      )}
      {topicInfo.type === TOPIC_TYPES.TAG && (
        <Icon name="OutboundTag" size="minor" svgProps={{ style: { fill: '#ffffff' } }} />
      )}
      <div className="grid-item">{topicInfo.prettyName || name}</div>
      <div className="remove-topic" onClick={onRemove}>
        <Icon name="close-thin" />
      </div>
    </div>
  ) : (
    <div className={derivedClassnames} onClick={onClick}>
      {topicInfo.prettyName && (
        <div className="overlay">
          <div className="handle">
            <Icon
              name="Chevron--single--east"
              size="minor"
              svgProps={{ style: { fill: '#000000' } }}
            />
          </div>
          <div className="grid-row v-align">
            <Icon name="System" size="minor" svgProps={{ style: { fill: '#000000' } }} /> {name}
          </div>
        </div>
      )}
      <div className="grid-row v-align">
        {topicInfo.type === TOPIC_TYPES.SYSTEM && (
          <div>
            <Icon name="System" size="minor" svgProps={{ style: { fill: '#000000' } }} />
          </div>
        )}
        {topicInfo.type === TOPIC_TYPES.DEVICE && (
          <div>
            <Icon name="Outbound" size="minor" svgProps={{ style: { fill: '#000000' } }} />
          </div>
        )}
        {topicInfo.type === TOPIC_TYPES.TAG && (
          <div>
            <Icon name="OutboundTag" size="minor" svgProps={{ style: { fill: '#000000' } }} />
          </div>
        )}
        <Popover id={popoverId} className="pretty-name" content={topicInfo.prettyName || name}>
          <span>{topicInfo.prettyName || name}</span>
        </Popover>
        {topicInfo.type === TOPIC_TYPES.DEVICE && topicInfo.deviceId && (
          <Button
            variant="tertiary"
            iconStart={
              <Icon name="Project" size="minor" svgProps={{ style: { fill: '#04a7ff' } }} />
            }
            className="link-to-device"
            to={DEVICE_LANDING.replace(`:${DEVICE_PARAM_NAME}`, topicInfo.deviceId)}
          />
        )}
      </div>
    </div>
  );
};

export default Topic;
