import { addCommasToNumber } from 'common-js/utils/numberFormatter';

export const createTooltipString = (
  numberOfDevices,
  deviceStatus,
  comparingDataFromPeriod,
  newDevices
) => {
  let numberString;
  const deviceStatusString = deviceStatus.toLowerCase().replace(' devices', '');
  const comparingFromLowerCase = comparingDataFromPeriod.toString().toLowerCase();

  if (newDevices && numberOfDevices === 1) {
    return `There is ${numberOfDevices} new ${deviceStatusString} device compared to this time ${comparingFromLowerCase}.`;
  }
  if (newDevices) {
    return `There are ${addCommasToNumber(
      numberOfDevices,
      false
    )} new ${deviceStatusString} devices compared to this time ${comparingFromLowerCase}.`;
  }
  if (numberOfDevices === 0) {
    return `No change in the number of ${deviceStatusString} devices compared to this time ${comparingFromLowerCase}.`;
  }
  if (numberOfDevices > 0) {
    numberString = `${addCommasToNumber(numberOfDevices, false)} more`;
  } else if (numberOfDevices < 0) {
    const numWithoutNegative = Math.abs(numberOfDevices);
    numberString = `${addCommasToNumber(numWithoutNegative, false)} fewer`;
  }

  return `${numberString} devices are ${deviceStatusString} compared to this time ${comparingFromLowerCase}.`;
};

export const createPillProps = (segment, comparingDataFromPeriod) => {
  let pillProps = null;
  let numDevicesChanged = 0;
  const deviceStatusString = segment.name.toLowerCase().replace(' devices', '');

  if (segment.historicDevicesNumber === 0 && segment.number === 0) {
    pillProps = {
      changeAmount: 0,
      period: comparingDataFromPeriod,
      tooltip: {
        content: createTooltipString(numDevicesChanged, segment.name, comparingDataFromPeriod),
        title: `${deviceStatusString} devices`,
      },
    };
  } else if (segment.historicDevicesNumber === 0) {
    numDevicesChanged = parseInt(addCommasToNumber(segment.number, false), 10);
    pillProps = {
      text: `${numDevicesChanged} new / ${comparingDataFromPeriod}`,
      tooltip: {
        content: createTooltipString(
          numDevicesChanged,
          segment.name,
          comparingDataFromPeriod,
          true
        ),
        title: `${deviceStatusString} devices`,
      },
    };
  } else if (segment.historicDevicesNumber) {
    numDevicesChanged = segment.number - segment.historicDevicesNumber;
    const percentageChange = (numDevicesChanged / segment.historicDevicesNumber) * 100;
    pillProps = {
      changeAmount: parseInt(addCommasToNumber(Math.round(percentageChange * 10) / 10, false), 10),
      period: comparingDataFromPeriod,
      tooltip: {
        content: createTooltipString(numDevicesChanged, segment.name, comparingDataFromPeriod),
        title: `${deviceStatusString} devices`,
      },
    };
  }

  return pillProps;
};
