import { Panel } from '@hologram-dimension/panel';
import { Panel as HolokitPanel } from '@holokit/core';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { DateTagFilter } from 'common-js/components';
import {
  deselectAllTags,
  getTags,
  selectAllTags,
  toggleTag,
} from 'common-js/reducers/deviceFilter/actions';
import {
  getLiveUsage,
  runLiveUsageReport,
  setCustomDate,
  syncFiltersToQueryString,
} from 'common-js/reducers/usage/actions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import { LiveUsageChart, LiveUsageTable } from './parts';

class LiveUsage extends React.Component<any, any> {
  constructor(props) {
    super(props);

    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Usage - Live' } });
    this.state = {
      redirecting: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { syncFiltersToQueryString_, getLiveUsage_ } = this.props;

    syncFiltersToQueryString_().then(() => {
      this.setState({ redirecting: false });
      return getLiveUsage_();
    });
  }

  render() {
    const { redirecting } = this.state;
    if (redirecting) return null;

    const {
      deselectAllTags_,
      loading,
      runLiveUsageReport_,
      runningReport,
      selectAllTags_,
      selectedTags,
      tags,
      toggleTag_,
    } = this.props;

    return (
      <div className="usage-container">
        <HolokitPanel
          title="Live"
          largeTitle
          headerBorder={false}
          setCustomDate={setCustomDate}
          noBodyPadding
          well
          classes="Panel__noBottomMargin"
        >
          <DateTagFilter
            deselectAllTags={deselectAllTags_}
            hideDatePicker
            onRunReport={runLiveUsageReport_}
            runningReport={runningReport}
            selectAllTags={selectAllTags_}
            selectedTags={selectedTags}
            tags={tags}
            toggleTag={toggleTag_}
          />
        </HolokitPanel>
        <Panel isLoading={loading} noContentPadding header="Recent data usage">
          <LiveUsageChart />
          <div className="Panel__footer">
            These reports are based on the most recent data sessions completed by the selected
            devices. Use these charts to troubleshoot devices connecting right now.
          </div>
        </Panel>
        <Panel isLoading={loading} noContentPadding header="Data usage history">
          <LiveUsageTable />
        </Panel>
      </div>
    );
  }
}

const LiveUsageHoC = connect(
  (state: any) => ({
    startDate: new Date(state.usage.filters.startDate),
    endDate: new Date(state.usage.filters.endDate),
    selectedTags: state.deviceFilters.selectedTags,
    tags: state.deviceFilters.tags,
    runningReport: state.usage.runningReport,
    loading: state.usage.charts.liveusage.loading,
    error: state.usage.charts.liveusage.error,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        deselectAllTags_: deselectAllTags,
        getLiveUsage_: getLiveUsage,
        getTags,
        runLiveUsageReport_: runLiveUsageReport,
        selectAllTags_: selectAllTags,
        setCustomDate,
        syncFiltersToQueryString_: syncFiltersToQueryString,
        toggleTag_: toggleTag,
      },
      dispatch
    )
)(LiveUsage);

export default LiveUsageHoC;
