import { useEffect, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useGetRouterCreds from 'common-js/api/devices/useGetRouterCreds';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { RouterCredModel } from 'common-js/models';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';

// very similar to useGetCurrentDeviceWebhook, but loads routerCreds instead of webhook
const useGetCurrentDeviceRouterCreds = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const [routerCreds, setRouterCreds] = useState(new RouterCredModel());
  const [loading, setLoading] = useState(false);
  const getRouterCreds = useGetRouterCreds();

  useEffect(() => {
    if (device.id) {
      // only load the router creds if the current device is already loaded; we need to know the current device's ID
      setLoading(true);
      getRouterCreds(device.id)
        // getRouterCreds already returns RouterCredModel, no need to wrap it
        .then((creds) => setRouterCreds(creds))
        .catch((error) => dispatch(pushGlobalMessage(error, 'error')))
        .finally(() => setLoading(false));
    }
  }, [device.id, dispatch, getRouterCreds, setLoading]);
  return [routerCreds, setRouterCreds, loading];
};

export default useGetCurrentDeviceRouterCreds;
