export function setFormFields(formName, fields) {
  return (dispatch) => {
    dispatch({
      type: `${formName.toUpperCase()}_` + `SET_FIELDS`,
      fields,
    });
  };
}

export function resetForm(formName) {
  return (dispatch) => {
    dispatch({
      type: `${formName.toUpperCase()}_` + `RESET`,
    });
  };
}

export function flashForm(formName, amountOfFlashes = 4, timing = 75) {
  return (dispatch) => {
    const POLL_INTERVAL = 100;
    const MAX_WAIT = 3000;
    const currentAttempt = 0;
    const maxAttempts = parseInt(MAX_WAIT / POLL_INTERVAL);
    let currentFlash = 0;
    const spreadFlashes = amountOfFlashes * 2;
    const _formName = formName;

    function flash() {
      dispatch({
        type: `${formName.toUpperCase()}_` + `FLASH`,
        isFlashing: currentFlash % 2 !== 0,
      });

      if (currentFlash < spreadFlashes) {
        setTimeout(flash, timing);
      }

      currentFlash++;
    }

    function waitForFormPresent() {
      const formElement = document.querySelector(`.${_formName}`);

      if (formElement) {
        flash();
      } else if (currentAttempt <= maxAttempts) {
        setTimeout(waitForFormPresent, POLL_INTERVAL);
      } else {
        flash(); // last ditch effort to flash.
      }
    }

    waitForFormPresent();
  };
}
