import { Icon } from '@hologram-dimension/icon';
import { ComplexIcon } from '@holokit/core';
import { changeDeviceName as changeDeviceNameActionCreator } from 'common-js/reducers/currentDevice/actions';
import type { pushGlobalMessage } from 'common-js/reducers/message/actions';
import * as messageActions from 'common-js/reducers/message/actions';
import { Device } from 'common-js/types/Device';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditableInput from './EditableInput';

// TODO: Consider putting these in a shared location
// It would be better if we could infer from redux, but that's out of scope for some time
interface DeviceNameInputProps {
  changeDeviceName: (deviceId: DeviceId, newDeviceName: string) => Promise<void>;
  device: Device;
  pushGlobalMessage: typeof pushGlobalMessage;
  useBmpStyles: boolean;
}

interface DeviceNameInputState {
  processing: boolean;
}

class DeviceNameInput extends React.Component<DeviceNameInputProps, DeviceNameInputState> {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  onNameChange(value: string) {
    const { changeDeviceName, device, pushGlobalMessage } = this.props;

    if (value === device.name) return;

    this.setState({ processing: true });

    changeDeviceName(device.id, value)
      .then(() => {})
      .catch((e: string) => {
        pushGlobalMessage(e, 'error');
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  }

  render() {
    const { device, useBmpStyles } = this.props;
    const { processing } = this.state;
    const baseClassName = `DeviceNameInput${useBmpStyles ? 'Bmp' : ''}`;
    return (
      <div className={`${baseClassName}${processing ? ' processing' : ''}`}>
        <div className="floating-label">NAME</div>
        <Icon
          className={`${baseClassName}__icon`}
          name="SIM"
          size="small"
          fill="DdsColorPaletteTeal40"
        />
        <EditableInput
          value={device.name}
          onChange={(value) => this.onNameChange(value)}
          maxLength={32}
        />
        {processing && <ComplexIcon classes={`${baseClassName}__loader`} name="spinner" />}
      </div>
    );
  }
}

export default connect(null, (dispatch: any) =>
  bindActionCreators(
    {
      changeDeviceName: changeDeviceNameActionCreator,
      pushGlobalMessage: messageActions.pushGlobalMessage,
    },
    dispatch
  )
)(DeviceNameInput);
