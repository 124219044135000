import analyticsEventBuilder from 'common-js/analytics';
import useDeviceChangePlan, {
  UseDeviceChangePlanResult,
} from 'common-js/api/devices/useDeviceChangePlan';
import { getUserContextDataMemoized } from 'common-js/api/util';
import {
  DEVICES_DEVICES,
  DEVICES_PREFLIGHT,
  SIM_INVENTORY_ACTIVE,
  SIM_INVENTORY_PREDEPLOYMENT,
} from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useConfirmOnPageUnload from 'common-js/hooks/useConfirmOnPageUnload';
import { resetActivationStoreToDefault } from 'common-js/reducers/activation/actions';
import {
  buildUsageLimit,
  getErrorMessage,
} from 'common-js/reducers/activation/actions/actionUtils';
import { activateSims } from 'common-js/reducers/activation/actions/activateSims';
import { Device } from 'common-js/types/Device';
import { cloneDeep, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { browserHistory } from 'react-router';
import Sidebar from './ActivationSidebar';
import AutoRefill from './autorefill';
import { useStepCounter } from './common/hooks';
import { ActivationStep } from './common/scaffolding';
import DevicePrefix from './deviceprefix';
import Limits from './limits';
import Plan from './plan';
import Region from './region';
import Review from './review';
import Sims from './sims';
import Tags from './tags';

const allSteps = [Sims, Region, Plan, Limits, DevicePrefix, Tags, AutoRefill, Review];

function ActivationForm({
  hasPostpay,
  autoRefillSettings,
  isOnboarding,
  setActivationSuccess,
  setActivationError,
  hasBillingPermissions,
  isPreflight,
  hasSimInventoryPage,
  promoteSimInventoryPage,
  hasCustomPlans,
  hasDataUsageLimits,
  changingPlan,
  setActivatedPlan,
}) {
  useConfirmOnPageUnload();

  // Redux
  const dispatch = useAppDispatch();

  // Internal state
  const steps = useMemo(
    () =>
      allSteps
        .map((f) =>
          f({
            isOnboarding,
            hasPostpay,
            autoRefillSettings,
            hasBillingPermissions,
            isPreflight,
            hasCustomPlans,
            hasDataUsageLimits,
            changingPlan,
          })
        )
        .filter(({ include }: any) => include ?? true),
    [
      isOnboarding,
      hasPostpay,
      autoRefillSettings,
      hasBillingPermissions,
      isPreflight,
      hasCustomPlans,
      hasDataUsageLimits,
      changingPlan,
    ]
  );
  const { step, nextStep, prevStep, resetMaxStep } = useStepCounter(1);
  const defaults = useMemo(
    () =>
      Object.assign(
        {},
        ...steps.map(({ defaultValues = {}, formState }) =>
          formState
            ? {
                [formState]: defaultValues,
              }
            : {}
        )
      ),
    [steps]
  );
  // a copy of the state tree, frozen when the user completes a step
  // used to see if a user leaving a previously-completed step has changed something on that step
  const previousValues = useRef(defaults);

  // derived state
  // Can remove this any if we add a unified base interface for all Steps/Footers/Sidebars
  const fixture: any = steps[step.current - 1];
  const CurrentStep = fixture?.component;
  const stepName = fixture?.stepName;
  const Footer = fixture?.footer;
  const title = fixture?.title || '';
  const subtitle = fixture?.subtitle || '';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';

  useEffect(() => {
    analyticsEventBuilder.pageView(analyticsTitle, 'Page load').page(stepName).send({
      postpay: hasPostpay,
      billing: hasBillingPermissions,
      preflight: isPreflight,
    });
  }, [analyticsTitle, hasBillingPermissions, hasPostpay, isPreflight, stepName]);

  // form configuration
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaults,
      hasDataUsageLimits,
    },
  });

  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;
  const devices: Array<Device> = useAppSelector((state) => state.activation?.changePlan?.devices);
  const deviceIds = useMemo(
    () => (changingPlan ? devices?.map((device) => device.id) : []),
    [changingPlan, devices]
  );
  const changePlanCallback = useDeviceChangePlan({
    deviceIds,
  });

  const onSubmit = useCallback(
    async (values) => {
      if (changingPlan) {
        // Change plan

        let error: any;
        let result: UseDeviceChangePlanResult | undefined;
        try {
          result = await changePlanCallback({
            newPlanId: values.plan?.id,
            orgId,
            zone: values.plan?.zone,
            useAccountBalance: values.creditCardInfo?.paymentType === 'balance',
            overageLimit: buildUsageLimit(values.plan?.dataLimit, values.usageLimit),
          });
        } catch (ex) {
          error = getErrorMessage(ex as any);
        }

        const { warnings, devices_modified: devicesModified } = result ?? {};

        if (error) {
          setActivationSuccess(false);
          setActivationError(getErrorMessage(error));
        } else if (!devicesModified || devicesModified.length === 0) {
          setActivationError(
            `No updates were made. ${warnings?.map(
              (warning) =>
                `Device ${warning.deviceid} and profile ${warning.linkid}: ${warning.message}. `
            )}`
          );
          setActivationSuccess(false);
        } else {
          setActivationSuccess(true);
        }
      } else {
        setActivatedPlan(values?.plan);
        // Activation
        dispatch(activateSims(values))
          .then(() => {
            setActivationSuccess(true);
          })
          .catch((error) => {
            setActivationError(getErrorMessage(error));
            setActivationSuccess(false);
          });
      }
    },
    [
      changePlanCallback,
      changingPlan,
      dispatch,
      orgId,
      setActivatedPlan,
      setActivationError,
      setActivationSuccess,
    ]
  );

  // form navigation
  const goToNextStep = useCallback(() => {
    const currentStepFormState = fixture.formState;
    const currentStepValues = methods.getValues(currentStepFormState);
    if (
      currentStepFormState &&
      !isEqual(currentStepValues, previousValues.current[currentStepFormState])
    ) {
      // we create a deep clone of the form values in order to "freeze" them in their current state
      previousValues.current[currentStepFormState] = cloneDeep(currentStepValues);

      // If a step is changed, reset any step which comes later...
      if (step.current !== step.max) {
        const stepToStartReset = Math.max(step.current);
        steps.slice(stepToStartReset).forEach(({ defaultValues, formState }) => {
          if (!formState || !defaultValues) return;
          methods.setValue(formState, defaultValues);
          previousValues.current[formState] = cloneDeep(defaultValues);
        });
        resetMaxStep();
      }
    }
    analyticsEventBuilder.buttonClick(analyticsTitle, 'Continue Button').send({ step: stepName });
    nextStep();
  }, [fixture.formState, methods, analyticsTitle, stepName, nextStep, step, steps, resetMaxStep]);

  const goToPrevStep = useCallback(() => {
    analyticsEventBuilder.buttonClick(analyticsTitle, 'Back Button').send({ step: stepName });
    prevStep();
  }, [analyticsTitle, prevStep, stepName]);

  const skipStep = useCallback(() => {
    analyticsEventBuilder.buttonClick(analyticsTitle, 'Skip Step').send({ step: stepName });

    methods.setValue(fixture.formState, fixture.defaultValues, {
      shouldTouch: false,
    });
    // skipping a step should _not_ reset future steps, so update our copy of the values without resetting anything else
    if (fixture.formState && fixture.defaultValues) {
      previousValues.current[fixture.formState] = fixture.defaultValues;
    }
    nextStep();
  }, [analyticsTitle, stepName, methods, fixture.formState, fixture.defaultValues, nextStep]);

  const onClose = useCallback(() => {
    const confirmText = changingPlan
      ? 'Are you sure you want to stop changing your data plan? Any selections and information you have provided will not be saved.'
      : 'Are you sure you want to leave Activation? Any selections and information you have provided will not be saved.';

    // eslint-disable-next-line no-alert
    const confirmedLeave = window.confirm(confirmText);
    analyticsEventBuilder
      .buttonClick(
        analyticsTitle,
        confirmedLeave ? 'OK Leave Attempt (user left)' : 'Cancel Leave Attempt'
      )
      .send({ step: stepName });

    if (!methods.formState.isDirty || confirmedLeave) {
      let redirectUrl;
      if (isPreflight) {
        if (hasSimInventoryPage && promoteSimInventoryPage) {
          redirectUrl = SIM_INVENTORY_PREDEPLOYMENT;
        } else {
          redirectUrl = DEVICES_PREFLIGHT;
        }
      } else if (hasSimInventoryPage && promoteSimInventoryPage) {
        redirectUrl = SIM_INVENTORY_ACTIVE;
      } else {
        redirectUrl = DEVICES_DEVICES;
      }
      browserHistory.push(redirectUrl);
      dispatch(resetActivationStoreToDefault());
      analyticsEventBuilder.buttonClick(analyticsTitle, 'Exit Flow').send({ step: stepName });
    }
  }, [
    changingPlan,
    analyticsTitle,
    stepName,
    methods.formState.isDirty,
    isPreflight,
    dispatch,
    hasSimInventoryPage,
    promoteSimInventoryPage,
  ]);

  // JSX
  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%', height: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
        <ActivationStep
          title={title}
          subtitle={subtitle}
          currentStep={step.current}
          totalSteps={steps.length}
          onClose={onClose}
          optional={fixture?.optional ?? false}
          changingPlan={changingPlan}
          sidebar={
            <Sidebar>
              {steps.map(({ stepName: sidebarItemName, sidebarItem: SidebarItem }, i) => (
                <SidebarItem
                  key={sidebarItemName}
                  currentStep={step.current}
                  step={i + 1}
                  isOnboarding={isOnboarding}
                  hasPostpay={hasPostpay}
                  autoRefillSettings={autoRefillSettings}
                  hasCustomPlans={hasCustomPlans}
                  changingPlan={changingPlan}
                />
              ))}
            </Sidebar>
          }
          footer={
            Footer && (
              <Footer
                nextStep={goToNextStep}
                skipStep={skipStep}
                prevStep={goToPrevStep}
                hasBillingPermissions={hasBillingPermissions}
                isOnboarding={isOnboarding}
                changingPlan={changingPlan}
                hasCustomPlans={hasCustomPlans}
              />
            )
          }
        >
          {CurrentStep && (
            <CurrentStep
              isOnboarding={isOnboarding}
              hasPostpay={hasPostpay}
              autoRefillSettings={autoRefillSettings}
              hasBillingPermissions={hasBillingPermissions}
              isPreflight={isPreflight}
              goToPrevStep={goToPrevStep}
              hasCustomPlans={hasCustomPlans}
              hasDataUsageLimits={hasDataUsageLimits}
              changingPlan={changingPlan}
            />
          )}
        </ActivationStep>
      </form>
    </FormProvider>
  );
}

export default ActivationForm;
