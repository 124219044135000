import { SubMenu as BillingSubMenu } from 'billing/components';
import { FullHeightCalculatorHOC } from 'common-js/components';
import React from 'react';
import BaseLayout from './BaseLayout';

interface LayoutBillingProps {
  children?: React.ReactNode;
}
const LayoutBilling: React.FC<LayoutBillingProps> = ({ children }) => (
  <BaseLayout>
    <BillingSubMenu pathname={window.location.pathname} />
    <FullHeightCalculatorHOC accountForDrawer>
      <div className="container">
        <FullHeightCalculatorHOC classNames="fullHeight-billing" accountForDrawer relative>
          <div className="billing-container">{children}</div>
        </FullHeightCalculatorHOC>
      </div>
    </FullHeightCalculatorHOC>
  </BaseLayout>
);

export default LayoutBilling;
