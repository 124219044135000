import { Button, Icon } from '@holokit/core';
import { browserHistory, Link } from 'react-router';
import React, { useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import analyticsEventBuilder from 'common-js/analytics';
import { BetaCallout } from 'common-js/components';
import * as Paths from 'common-js/constants/paths';
import useYesNoAccountSetting from 'common-js/hooks/useYesNoAccountSetting';
import { getReleaseFlags, selectIsBmpCustomer } from 'common-js/reducers/releaseFlag/selectors';

function UsageBetaCallout() {
  const [show, setShow] = useYesNoAccountSetting('usage_beta_callout_show');

  const releaseFlags = useAppSelector(getReleaseFlags);
  const isBmpCustomer = useAppSelector(selectIsBmpCustomer);

  const dismissCallout = useCallback(() => {
    analyticsEventBuilder.buttonClick('Reports', 'Dismiss Usage to Reports Beta').send();

    setShow(false);
  }, [setShow]);

  if (!releaseFlags.embedded_looker_reporting || !show || isBmpCustomer) {
    return null;
  }

  const onExploreClick = (e) => {
    analyticsEventBuilder.buttonClick('Reports', 'Usage to Reports Beta').send();

    browserHistory.push(Paths.withContext(Paths.REPORTS_OVERVIEW));

    e.preventDefault();
    return false;
  };

  const label = (
    <div className="UsageBetaCallout__wrapper">
      <span>Get deeper insights with Reports (Beta).</span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link href="#" onClick={onExploreClick} className="UsageBetaCallout__link">
        Explore new reports
      </Link>
    </div>
  );

  const closeButton = (
    <Button classes="UsageBetaCallout__close-button" unstyled onClick={dismissCallout}>
      <Icon name="Close" size="minor" />
    </Button>
  );

  return (
    <BetaCallout icon="Performance" rightElement={closeButton} className="UsageBetaCallout">
      {label}
    </BetaCallout>
  );
}

export default UsageBetaCallout;
