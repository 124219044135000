import { Button, Icon } from '@holokit/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Bytes, ModalHeader } from '../components';
import * as modalActions from '../reducers/modal/actions';
import * as poolsActions from '../reducers/pools/actions';

class DisableDataPoolModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      serverError: null,
    };
  }

  onSubmit = () => {
    const {
      currentPool,
      unsubscribeToPool,
      unsubscribeToPending,
      getAllPoolInfo,
      closeModal,
      cancellingPending = false,
    } = this.props;

    this.setState({ isProcessing: true });

    if (cancellingPending) {
      unsubscribeToPending(currentPool.id)
        .then(() => getAllPoolInfo(currentPool.id))
        .then(() => closeModal())
        .catch((e) => this.setState({ serverError: e, isProcessing: false }));
    } else {
      unsubscribeToPool(currentPool.id)
        .then(() => getAllPoolInfo(currentPool.id))
        .then(() => closeModal())
        .catch((e) => this.setState({ serverError: e, isProcessing: false }));
    }
  };

  render() {
    const { closeModal, currentPool = {} } = this.props;
    const { isProcessing, serverError } = this.state;

    return (
      <div className="DisableDataPoolModal">
        <ModalHeader title="Disable data pool" />
        <p>
          If disabled, your data pool will not renew at the start of next month. This month’s data
          pool of <Bytes amount={currentPool.plan.data} /> will remain through the end of this
          month.
        </p>
        {serverError && (
          <div className="message message-error full-width align-left section section-med">
            <div className="message-panel">
              <div className="message-icon">
                <Icon name="Circle--info" size="minor" svgProps={{ style: { fill: '#8008f7' } }} />
              </div>
              <div className="message-text">{serverError}</div>
            </div>
          </div>
        )}
        <div className="grid-row">
          <div className="grid-item" />
          <div>
            <Button onClick={closeModal} type="secondary">
              Keep data pool
            </Button>
            <Button disabled={isProcessing} onClick={this.onSubmit} type="primary">
              {isProcessing ? 'Disabling...' : 'Disable Data Pool'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentPool: state.pools.currentPool,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        closeModal: modalActions.close,
        unsubscribeToPool: poolsActions.unsubscribeToPool,
        unsubscribeToPending: poolsActions.unsubscribeToPending,
        getAllPoolInfo: poolsActions.getAllPoolInfo,
      },
      dispatch
    )
)(DisableDataPoolModal);
