import { toByteStringFormatted } from 'common-js/utils/numberFormatter';

export const amountCopy = {
  equal: 'Equal to plan allowance',
  '1mb': '1 MB over plan allowance',
  '1.5mb': '1.5 MB over plan allowance',
  '5mb': '5 MB over plan allowance',
  '10mb': '10 MB over plan allowance',
  unlimited: 'Unlimited data usage',
  custom: 'Custom amount',
} as const;

export const getCalloutCopy = (limit: string, planDataLimit: number) => {
  switch (limit) {
    case 'equal':
      return `will be paused at ${toByteStringFormatted(planDataLimit)} of data usage.`;
    case '1mb':
      return `will be paused at ${toByteStringFormatted(
        planDataLimit + 1000000,
        3
      )} of data usage.`;
    case '1.5mb':
      return `will be paused at ${toByteStringFormatted(
        planDataLimit + 1500000,
        3
      )} of data usage.`;
    case '5mb':
      return `will be paused at ${toByteStringFormatted(
        planDataLimit + 5000000,
        3
      )} of data usage.`;
    case '10mb':
      return `will be paused at ${toByteStringFormatted(
        planDataLimit + 10000000,
        3
      )} of data usage.`;
    case 'unlimited':
      return 'will never be paused.';
    case 'custom':
    case 'notSet':
      return 'will continue to use data unless a data usage limit is set.';
    default:
      return '';
  }
};
