import React from 'react';

function ActivityHistoryDetailsSkeletonTableRow() {
  return (
    <tr className="Table__row" data-testid="table-row-skeleton">
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          <div className="ActivityHistoryDetailsTable__skeleton" />
          <div className="ActivityHistoryDetailsTable__skeleton" />
        </div>
      </td>
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          <div className="ActivityHistoryDetailsTable__skeleton" />
          <div className="ActivityHistoryDetailsTable__skeleton--medium" />
        </div>
      </td>
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          <div className="ActivityHistoryDetailsTable__skeleton--small" />
        </div>
      </td>
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          <div className="ActivityHistoryDetailsTable__skeleton--small" />
        </div>
      </td>
    </tr>
  );
}

export default ActivityHistoryDetailsSkeletonTableRow;
