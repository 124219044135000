import React from 'react';
import { Panel } from '@holokit/core';

interface OuterContainerProps {
  children: React.ReactNode;
  classes?: string;
  title?: string;
}

// Sometimes upsell content is shown in a panel, sometimes it is inline.  This covers both cases based on title prop.
const OuterContainer = ({ children, classes, title }: OuterContainerProps) => {
  const className = `BMPUpsellContent${classes ? ` ${classes}` : ''}`;
  return title ? (
    <Panel title={title} bodyClasses={className}>
      {children}
    </Panel>
  ) : (
    <div className={className}>{children}</div>
  );
};

interface BMPUpsellPanelProps {
  classes?: string;
  title?: string;
  label?: string;
  headline: string;
  copy: string;
  buttons: React.ReactNode;
}

// Shown when a user doesn't have the right BMP feature level for content
const BMPUpsellPanel = ({
  title,
  label,
  headline,
  copy,
  buttons,
  classes,
}: BMPUpsellPanelProps) => (
  <OuterContainer title={title} classes={classes}>
    {label && <div className="BMPUpsellContent__plan-label">{label}</div>}

    <div className="BMPUpsellContent__headline">{headline}</div>

    <div className="BMPUpsellContent__copy">{copy}</div>

    <div className="BMPUpsellContent__buttons">{buttons}</div>
  </OuterContainer>
);

export default BMPUpsellPanel;
