import _ from 'lodash';
import { generate as generateId } from 'shortid';

import * as actionTypes from './actionTypes';
import * as API from '../../api';
import { getUserContextData } from '../../api/util';
import { LogFilterModel } from '../../models';

let currentLogRequest;

export function getLogs(isContinuing = false, isUpdating = false) {
  return (dispatch, state) => {
    let startAt = null;
    const store = state();
    const currentLogs = store.log.logs;

    if (isContinuing) {
      startAt = currentLogs[currentLogs.length - 1].id;
    }

    dispatch({
      type: actionTypes.GET_ALL_REQUEST,
      isContinuing,
      isUpdating,
    });

    const logFilters = new LogFilterModel(store.logFilters.allLogFilters);

    if (store.deviceFilters.isAllDevices) {
      logFilters.setDevices([]);
    } else {
      const deviceIds = store.deviceFilters.selectedTags
        .map((tagId) => _.find(store.deviceFilters.tags, { id: tagId }))
        .reduce((last, next) => last.concat(next.deviceids), []);

      logFilters.setDevices(deviceIds);
    }

    currentLogRequest = generateId();

    return API.getLogs(logFilters, 30, startAt, getUserContextData(state), currentLogRequest)
      .then((data) => {
        if (data.requestId === currentLogRequest) {
          dispatch({
            type: actionTypes.GET_ALL_SUCCESS,
            logs: data.logs,
            isContinuing,
            isUpdating,
            hasMoreLogs: data.continues,
            currentApps: store.apps.apps,
            currentLogs,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_ALL_ERROR, error });
        return Promise.reject(error);
      });
  };
}

export function retryLoad() {
  return getLogs(false, false);
}

export function revealLogs() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVEAL_LOGS,
    });
  };
}

export function revealLogsForOne(deviceId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REVEAL_LOGS_FOR_ONE,
      deviceId,
    });
  };
}

export function unmountLogs() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UNMOUNT_LOGS,
    });
  };
}

export function toggleLogRowView(viewState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_LOGROW_VIEW,
      viewState,
    });
  };
}

export function getLogsForOne(deviceId, isContinuing = false, isUpdating = false) {
  return (dispatch, store) => {
    let startAt = null;
    const userContextData = getUserContextData(store);

    if (isContinuing) {
      const currentLogs = store().log.logsPerDevice[deviceId];
      startAt = currentLogs[currentLogs.length - 1].id;
    }

    dispatch({
      type: actionTypes.GET_ONE_REQUEST,
      isContinuing,
      isUpdating,
    });

    const logFilters = new LogFilterModel(store().logFilters.logFiltersByDevice[deviceId]);

    return API.getLogsForOne(deviceId, logFilters, 50, startAt, userContextData)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_ONE_SUCCESS,
          deviceId,
          hasMoreLogs: data.continues,
          isContinuing,
          isUpdating,
          logs: data.logs,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ONE_ERROR,
          error,
        });
      });
  };
}
