import { Panel } from '@hologram-dimension/panel';
import formatCurrencyWithRounding from 'common-js/utils/formatCurrencyWithRounding';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import Moment from 'moment-timezone';
import React from 'react';
import SummaryTable from './SummaryTable';
import SummaryTableLineItemRow from './SummaryTableLineItemRow';
import SummaryTableTotalRow from './SummaryTableTotalRow';

export interface SummaryPanelProps {
  autoRefillCharge: number;
  hasPostpay?: boolean;
  lineItems: Array<any>;
  promoBalance: number;
  availableBalanceChange?: number;
  upchargeToCreditCardMin?: number;
  subtotal: number;
  total: number;
  changingPlan?: boolean;
}

function SummaryPanel({
  autoRefillCharge,
  hasPostpay = false,
  lineItems,
  promoBalance,
  availableBalanceChange = 0,
  upchargeToCreditCardMin = 0,
  subtotal,
  total,
  changingPlan = false,
}: SummaryPanelProps) {
  const nextMonth = Moment().utc().format('MMMM');

  let totalDueText: string;

  if (changingPlan) {
    if (hasPostpay) {
      totalDueText = 'Pro-rated total due';
    } else {
      totalDueText = 'Pro-rated total due today';
    }
  } else if (hasPostpay) {
    totalDueText = 'Total due';
  } else {
    totalDueText = 'Total due today';
  }

  return (
    <Panel
      header={hasPostpay ? 'No payment is due at this time' : 'Total due today'}
      className="Review__SummaryTable"
      footer={
        hasPostpay
          ? `Platform fees are charged every 30 days, starting today. Your ${nextMonth} invoice will include the total due.`
          : 'Platform fees are charged every 30 days, starting today. Payment is due at time of activation.'
      }
      noContentPadding
      footerVariant="alternative"
    >
      <SummaryTable>
        {lineItems?.map((item, idx) => (
          <SummaryTableLineItemRow
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            description="Platform fee"
            quantity={`${addCommasToNumber(item.quantity, false)} ${
              item.quantity === 1 ? 'SIM' : 'SIMs'
            }`}
            recurringCharge={formatCurrencyWithRounding(item.unit_cost, 4)}
            total={formatCurrencyWithRounding(item.total_cost, 4)}
          />
        ))}
        {lineItems.length > 1 && (
          <SummaryTableTotalRow label="Subtotal" total={formatCurrencyWithRounding(subtotal, 4)} />
        )}
        {autoRefillCharge > 0 && (
          <SummaryTableTotalRow
            label="Auto-Refill charge"
            total={formatCurrencyWithRounding(autoRefillCharge, 4)}
          />
        )}
        {upchargeToCreditCardMin > 0 && (
          <SummaryTableTotalRow
            label="Balance added to meet $5 credit card minimum"
            total={formatCurrencyWithRounding(upchargeToCreditCardMin, 4)}
          />
        )}
        {promoBalance > 0 && (
          <SummaryTableTotalRow
            label="Promotional credit"
            total={<>–{formatCurrencyWithRounding(promoBalance, 4)}</>}
            isBold
            isGreen
          />
        )}
        {availableBalanceChange > 0 && (
          <SummaryTableTotalRow
            label="Available balance"
            total={<>–{formatCurrencyWithRounding(availableBalanceChange, 4)}</>}
            isBold
            isGreen
          />
        )}
        <SummaryTableTotalRow
          label={totalDueText}
          total={formatCurrencyWithRounding(hasPostpay ? 0 : total, 4)}
          isBold
        />
      </SummaryTable>
    </Panel>
  );
}

export default SummaryPanel;
