import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { ACTIVATION } from 'common-js/constants/paths';
import { DeviceModel } from 'common-js/models';
import { setChangePlanDevices, setIsChangePlanFlag } from 'common-js/reducers/activation/actions';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { browserHistory } from 'react-router';

const useOnUpdatePlan = (page: string = 'Devices Page') => {
  const dispatch = useAppDispatch();
  const device: DeviceModel = useAppSelector(selectCurrentDevice);

  return useCallback(() => {
    dispatch(setIsChangePlanFlag());
    dispatch(setChangePlanDevices([device]));
    browserHistory.push(ACTIVATION);
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: `${page} - Change plan`,
      },
    });
  }, [page, device, dispatch]);
};

export default useOnUpdatePlan;
