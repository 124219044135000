import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Select } from '@hologram-dimension/select';
import { TextInput } from '@hologram-dimension/text-input';
import _classnames from 'clsx';
import analyticsEventBuilder from 'common-js/analytics';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectPlanData } from 'common-js/reducers/activation/selectors';
import { addCommasToNumber, toByteStringFormatted } from 'common-js/utils/numberFormatter';
import { useCallback, useEffect, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { getCalloutCopy } from './LimitTranslations';

const formatText = (text: string) => (
  <p className="UsageLimit__usageLimitCallout__content">
    Your devices <span className="UsageLimit__usageLimitCallout__content__bold">{text}</span>
  </p>
);

const multipliers = {
  KB: 1_000,
  MB: 1_000_000,
  GB: 1_000_000_000,
};
interface DataUsageLimitCalloutProps {
  changingPlan?: boolean;
}

const DataUsageLimitCallout = ({ changingPlan = false }: DataUsageLimitCalloutProps) => {
  const {
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext();

  const {
    field: { onChange: onUnitChange, value: unit },
  } = useController({
    name: 'usageLimit.unit',
  });

  const {
    field: { onChange: onTypeChange, value: limitType },
  } = useController({
    name: 'usageLimit.type',
    rules: { required: true },
  });

  const { id: planId } = getValues('plan');
  const planData = useAppSelector((state) => selectPlanData(state, planId));
  const planDataLimit = planData?.dataLimit;
  const planDataLimitInUnit = Math.floor(planDataLimit / (multipliers[unit] ?? 1));
  const flexiblePlan = planDataLimit === 0;
  const fixedPlan = planDataLimit > 0;
  const planDataLimitByteString = toByteStringFormatted(planDataLimit);
  const isPlanGB = planDataLimitByteString.includes('GB');
  const underPlanDataLimitErrorMessage = `Please enter a data usage limit value greater than or equal to your ${planDataLimitByteString} allowance.`;
  const minimumCustomAmount = flexiblePlan ? 1 : Math.max(1, planDataLimitInUnit);
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';

  const {
    field: { onChange: onAmountChange, value: amount },
  } = useController({
    name: 'usageLimit.amount',
    rules: {
      min: minimumCustomAmount,
      validate: (value) => value !== '',
    },
  });

  const handleUnitChange = useCallback(
    (e) => {
      const { value: selectedUnit } = e.target;
      onUnitChange(selectedUnit);
      // We need to trigger this so that when you change the unit, it revalidates
      trigger('usageLimit');
    },
    [onUnitChange, trigger]
  );

  const trackAnalyticsError = useCallback(() => {
    analyticsEventBuilder
      .errorReturn(analyticsTitle, `${analyticsTitle} Error`, 'Usage Limit')
      .send({ 'Last entered value': `${amount} ${unit}` });
  }, [amount, analyticsTitle, unit]);

  const handleAmountChange = useCallback(
    (e) => {
      const { value } = e.target;
      const expression = /^\d+((\.|,)\d?\d?)?$/;
      if (expression.test(value) || value === '') {
        onAmountChange(value);

        // Amounts are only for the 'custom' type; if we have one, make sure we're on custom
        // (with flexible plans there's no dropdown, so we have to set the type manually like this)
        if (value) {
          onTypeChange('custom');
        }
      }
    },
    [onAmountChange, onTypeChange]
  );

  const trackAnalyticsEvent = useCallback(
    (selectedUsageLimit) =>
      analyticsEventBuilder
        .buttonClick(analyticsTitle, 'Returning', `${analyticsTitle} Usage Limit Selection`)
        .send({ 'Selected usage limit': selectedUsageLimit }),
    [analyticsTitle]
  );

  const items = useMemo(
    () => [
      {
        label: 'Set a data usage limit',
        disabled: true,
        value: '',
      },
      {
        label: 'Data usage limit equal to plan allowance',
        value: 'equal',
      },
      {
        label: `${isPlanGB ? '1 MB' : '5 MB'} over plan allowance`,
        value: isPlanGB ? '1mb' : '5mb',
      },
      {
        label: `${isPlanGB ? '1.5 MB' : '10 MB'} over plan allowance`,
        value: isPlanGB ? '1.5mb' : '10mb',
      },
      {
        label: 'Unlimited data usage',
        value: 'unlimited',
      },
      {
        label: 'Custom amount',
        value: 'custom',
      },
    ],
    [isPlanGB]
  );

  const handleTypeChange = useCallback(
    (e) => {
      const { value: typeString } = e.target;
      const selectedUsageLimit = items.find((item) => item.value === typeString)?.label;
      onTypeChange(typeString);
      trackAnalyticsEvent(selectedUsageLimit);
    },
    [items, onTypeChange, trackAnalyticsEvent]
  );

  let customAmountErrorMessage;
  if (fixedPlan && amount < minimumCustomAmount) {
    customAmountErrorMessage = underPlanDataLimitErrorMessage;
  } else if (amount <= 0) {
    customAmountErrorMessage = 'Please enter a custom overage limit higher than 0.';
  }

  const shouldShowError =
    (errors as any).usageLimit?.amount && limitType === 'custom' && customAmountErrorMessage;

  useEffect(() => {
    if (shouldShowError) {
      trackAnalyticsError();
    }
  }, [shouldShowError, trackAnalyticsError]);

  return (
    <>
      <div className="UsageLimit__usageLimitCallout">
        <div>
          {amount && limitType === 'custom'
            ? formatText(
                `will be paused at ${addCommasToNumber(amount, false)} ${unit} of data usage.`
              )
            : formatText(getCalloutCopy(limitType || 'notSet', planDataLimit))}
        </div>
        <div className="UsageLimit__usageLimitCallout__dropdown">
          {fixedPlan && (
            <Select
              id="limitSelect"
              className={_classnames(
                flexiblePlan ? 'UsageLimit__dropdown--flexible' : 'UsageLimit__dropdown--fixed'
              )}
              options={items}
              onChange={handleTypeChange}
              defaultValue={limitType}
              data-testid="limitSelect"
            />
          )}
          {(flexiblePlan || limitType === 'custom') && (
            <div className="UsageLimit__usageLimitCallout__custom">
              <TextInput
                id="limitAmount"
                inputProps={{ min: minimumCustomAmount }}
                placeholder={minimumCustomAmount.toString()}
                type="number"
                defaultValue={amount}
                onChange={handleAmountChange}
                className={_classnames('UsageLimit__usageLimitCallout__custom__amount', {
                  UsageLimit__usageLimitCallout__custom__error: (errors as any).usageLimit?.amount,
                })}
              />
              <Select
                id="limitUnit"
                className="UsageLimit__usageLimitCallout__custom__unit"
                onChange={handleUnitChange}
                defaultValue={unit}
                options={[
                  {
                    value: 'KB',
                  },
                  {
                    value: 'MB',
                  },
                  {
                    value: 'GB',
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      {shouldShowError && (
        <BannerNotification className="UsageLimit__usageLimitCallout__error" variant="error">
          {customAmountErrorMessage}
        </BannerNotification>
      )}
    </>
  );
};

export default DataUsageLimitCallout;
