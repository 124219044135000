import { Callout, InputGroup, Panel } from '@holokit/core';
import classNames from 'clsx';
import analyticsEventBuilder from 'common-js/analytics';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Picture } from '@hologram-hyper-dashboard/components';
import autoRefillImg from 'img/billing/auto-refill_diagram.webp?&imagetools';
import useDebouncedShowError from '../common/hooks/useDebouncedShowError';

function AutoRefill({ isOnboarding = false }) {
  const {
    formState: { errors },
    getValues,
    trigger,
    getFieldState,
  } = useFormContext();

  const {
    field: { onChange: onChangeEnabled },
  } = useController({
    name: 'autoRefillParams.enabled',
  });
  const { enabled, amountToAdd, threshold } = getValues('autoRefillParams');
  const balance = useAppSelector((state) => state.organization.balance);
  const orgHasEnabledAutoRefill = !!balance.minBalance && !!balance.topOffAmount;

  // HOLOKIT BUG: Panel calls callback before state update is complete, so the callback value is always whether or not
  // the panel was expanded _when the user clicks the Switch_.  I'm just going to rename it to 'collapsed' because
  // for all intents and purposes, that is the true state this callback value represents.*/
  const onToggleAutoRefill = useCallback(
    ({ expanded: collapsed }) => {
      onChangeEnabled(!collapsed);
      trigger(['autoRefillParams.amountToAdd', 'autoRefillParams.threshold']);
      analyticsEventBuilder
        .buttonClick(
          'Activation',
          isOnboarding ? '1st Time' : 'Returning',
          'Activation Autorefill Enablement Toggle'
        )
        .send({
          'Toggle selection': collapsed ? 'Autorefill Disabled' : 'Autorefill Enabled',
        });
    },
    [isOnboarding, onChangeEnabled, trigger]
  );

  // Validation
  const amountToAddF = parseFloat(amountToAdd);
  const thresholdF = parseFloat(threshold);
  const { underFiveError, amountToAddLessThanThresholdError } = useMemo(
    () => ({
      underFiveError: amountToAddF < 5 || thresholdF < 5,
      amountToAddLessThanThresholdError: amountToAddF < thresholdF,
    }),
    [amountToAddF, thresholdF]
  );
  const { invalid: amountToAddInvalid } = getFieldState('autoRefillParams.amountToAdd');
  const { invalid: thresholdInvalid } = getFieldState('autoRefillParams.threshold');

  const showError = useDebouncedShowError(
    underFiveError || amountToAddLessThanThresholdError || amountToAddInvalid || thresholdInvalid
  );

  useEffect(() => {
    if (showError) {
      analyticsEventBuilder
        .errorReturn(
          'Activation',
          isOnboarding ? '1st Time' : 'Returning',
          'Activation Error',
          'Autorefill'
        )
        .send({
          'Amount add value': amountToAdd,
          'Threshold value': threshold,
        });
    }
  }, [amountToAdd, isOnboarding, showError, threshold]);

  const panelFooter = useMemo(
    () => (
      <div className="AutoRefillPanel__footer">
        <div className="AutoRefillPanel__footer__educationalContent">
          Why auto-refill?
          <span className="AutoRefillPanel__footer__educationalContent__desc">
            Enabling auto-refill is the best way to protect you against any balance-related service
            interruptions.
          </span>
        </div>
        <Picture
          image={autoRefillImg}
          alt="Auto refill illustration"
          imgClassName="AutoRefillPanel__footer__img"
          setDimensions={false}
        />
      </div>
    ),
    []
  );

  const calloutCopy = useMemo(() => {
    if (isOnboarding) {
      return 'Your auto-refill balance will be deducted using a credit card on file chosen on the next step.';
    }

    if (orgHasEnabledAutoRefill) {
      return 'Your team has already enabled auto-refill for your organization. If you need to turn off auto-refill, this can be done in your Billing settings.';
    }

    return 'Your auto-refill amount will be deducted from your account balance using the same credit card on file. You can edit the credit card information on the next step. Once auto-refill is enabled, future disabling can be done in your Billing settings.';
  }, [isOnboarding, orgHasEnabledAutoRefill]);

  const isValidAmount = useCallback(
    (v) => !enabled || (v >= 5 && /^\d+((\.|,)\d\d)?$/.test(v)),
    [enabled]
  );

  const isAmountToAddGreaterOrEqualToThreshold = useCallback(
    (value) => {
      const currentThreshold = getValues('autoRefillParams.threshold');
      return !enabled || parseFloat(value) >= parseFloat(currentThreshold);
    },
    [enabled, getValues]
  );

  const handleChange = useCallback(
    (e, onChange, triggerValidation) => {
      const { value } = e.target;
      const expression = /^\d+((\.|,)\d?\d?)?$/;
      if (expression.test(value) || value === '') {
        onChange(value);
      }
      if (triggerValidation) {
        trigger(['autoRefillParams.amountToAdd', 'autoRefillParams.threshold']);
      }
    },
    [trigger]
  );

  return (
    <Panel
      title={orgHasEnabledAutoRefill ? 'Auto-refill amount' : 'Turn on auto-refill'}
      classes="AutoRefillPanel"
      hasExpandableContent={!orgHasEnabledAutoRefill}
      defaultExpanded={!!amountToAdd && !!threshold}
      onToggleExpand={onToggleAutoRefill}
      footer={panelFooter}
    >
      <div className="AutoRefillPanel__interactiveContent">
        <div className="AutoRefillPanel__interactiveContent__balanceInput">
          Automatically add
          <Controller
            name="autoRefillParams.amountToAdd"
            rules={{
              validate: (v) => isValidAmount(v) && isAmountToAddGreaterOrEqualToThreshold(v),
            }}
            render={({ field: { onChange, value } }) => (
              <InputGroup
                onChange={(e) => handleChange(e, onChange, false)}
                inputProps={{ min: 5, step: 'any' }}
                value={value}
                placeholder="0.00"
                classes={classNames('AutoRefillPanel__interactiveContent__balanceInput__group', {
                  'AutoRefillPanel__interactiveContent__balanceInput__group--error':
                    showError && (errors.autoRefillParams as any)?.amountToAdd,
                })}
                type="number"
              />
            )}
          />
          when the account balance is below
          <Controller
            name="autoRefillParams.threshold"
            rules={{
              validate: isValidAmount,
            }}
            render={({ field: { onChange, value } }) => (
              <InputGroup
                onChange={(e) => handleChange(e, onChange, true)}
                inputProps={{ min: 5, step: 'any' }}
                value={value}
                placeholder="0.00"
                classes={classNames('AutoRefillPanel__interactiveContent__balanceInput__group', {
                  'AutoRefillPanel__interactiveContent__balanceInput__group--error':
                    showError && (errors as any).autoRefillParams?.threshold,
                })}
                type="number"
              />
            )}
          />
        </div>

        {showError && underFiveError && (
          <div className="AutoRefillPanel__interactiveContent__callout__error">
            <Callout
              type={Callout.TYPE.ERROR}
              defaultIcon
              iconColor={Callout.THEME.COLOR}
              text="Values should be greater than or equal to $5.00."
            />
          </div>
        )}

        {showError && amountToAddLessThanThresholdError && (
          <div className="AutoRefillPanel__interactiveContent__callout__error">
            <Callout
              type={Callout.TYPE.ERROR}
              defaultIcon
              iconColor={Callout.THEME.COLOR}
              text="Re-fill add value cannot be less than account balance amount."
            />
          </div>
        )}
        <hr className="AutoRefillPanel__interactiveContent__line" />
        <div className="AutoRefillPanel__interactiveContent__callout">
          <Callout type="informational" text={calloutCopy} />
        </div>
      </div>
    </Panel>
  );
}

export default AutoRefill;
