/* eslint-disable react/no-string-refs */
import { Icon } from '@holokit/core';
import * as currentDeviceActions from 'common-js/reducers/currentDevice/actions';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import DayPickerInput from 'react-day-picker-legacy/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker-legacy/moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class DateRangePicker extends React.Component {
  /*
   * README
   * react-day-picker only displays dates in the browser's local timezone.
   * We do something *TRICKY* to the JS Date objects we exhange
   * with react-day-picker, to make it _seem_ like they are displayed in
   * UTC.
   *
   * Specifically, we alter the Unix time of each input so that its representation
   * in the local time zone is the same as the UTC representaiton of the un-altered
   * time. We make the reverse alteration to Unix times that react-day-picker outputs,
   * so that their UTC representation is the same as their previous representation in
   * local time.
   *
   * The utility functions localDateMatchingUTCDate and utcDateMatchingLocalDate perform these
   * two transformations.
   *
   * For example, imagine that the Unix time we want to show is 2020/03/13 00:00 UTC.
   * In UTC, the representation of this time is 2020/03/13 00:00. That is the time we want
   * to display.
   *
   * In the CST (-5) timezone, our Unix time is represented as 2020/03/12 19:00.
   * We subtract the timezone's offset (00:00 - (-5:00) = 05:00) to get a new Unix time,
   * 2020/03/13 05:00 UTC. The CST representation of the new Unix time is 2020/03/13 00:00:00.
   * So we'll pass Unix time 2020/03/13 05:00 UTC into react-day-picker, and it will display
   * 2020/03/13 00:00, which is what we wanted.
   *
   * In the reverse operation, we take a Unix time output from react-day-picker,
   * and we want to alter it so that its UTC representation is the same as the local time zone
   * representation it had inside react-day-picker.
   *
   * For example, imagine that we get the Unix time 2020/03/15 07:00 UTC from react-day-picker,
   * and the user's time zone is PST (-7). We infer that this time was represented to the user
   * as 2020/03/15 00:00. We add the PST offset to our Unix time (07:00 + (-7) = 00:00) to get
   * the Unix time 2020/03/15 00:00 UTC. Our new Unix time's representation in UTC is
   * 2020/03/15 00:00, which is the same as the representation picked by the user.
   */

  // Here be dragons! Please refer to the README at the top of this class.
  static localDateMatchingUTCDate(jsDate) {
    const localOffset = moment(jsDate).utcOffset();
    return moment(jsDate).subtract(localOffset, 'minutes').toDate();
  }

  // Here be dragons! Please refer to the README at the top of this class.
  static utcDateMatchingLocalDate(jsDate) {
    const localOffset = moment(jsDate).utcOffset();
    return moment(jsDate).add(localOffset, 'minutes').toDate();
  }

  constructor(props) {
    super(props);

    const { startDate, endDate } = this.props;

    this.state = {
      from: startDate,
      to: endDate,
    };
  }

  // This is required for Quick Filters to work
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.getPropsDifferState(nextProps)) {
      this.setState({
        from: nextProps.startDate,
        to: nextProps.endDate,
      });
    }
  }

  onFromChange(newLocalDate) {
    const { onDateChange = _.noop, setPollingPause } = this.props;
    const { from, to } = this.state;

    setPollingPause(true);

    const newDate = DateRangePicker.utcDateMatchingLocalDate(newLocalDate);
    if (newDate.valueOf() < to.valueOf()) {
      this.setState({
        from: moment.utc(newDate).startOf('day').toDate(),
      });
      this.to.getInput().focus();
    } else {
      this.setState({
        to: moment.utc(newDate).endOf('day').toDate(),
      });
    }

    onDateChange({ from, to });
  }

  onToChange(newLocalDate) {
    const { onDateChange = _.noop, setPollingPause } = this.props;
    const { from, to } = this.state;

    setPollingPause(true);

    const newDate = DateRangePicker.utcDateMatchingLocalDate(newLocalDate);
    if (newDate.valueOf() > from.valueOf()) {
      this.setState({
        to: moment.utc(newDate).endOf('day').toDate(),
      });
      this.from.getInput().focus();
    } else {
      this.setState({
        from: moment.utc(newDate).startOf('day').toDate(),
      });
    }

    onDateChange({ from, to });
  }

  onDayPickerShow() {
    this.calcMonthsToShow();
  }

  onDayPickerHide() {
    setTimeout(() => {
      const { onDateChange = _.noop } = this.props;
      const { from, to } = this.state;

      if (this.refs.InputFromTo) {
        const daypicker = this.refs.InputFromTo.querySelector('.DayPickerInput-Overlay');
        if (!daypicker) {
          if (this.getPropsDifferState(this.props)) onDateChange({ from, to });
        }
      }
    }, 20);
  }

  getPropsDifferState(props) {
    const incomingStartDate = props.startDate.valueOf();
    const incomingEndDate = props.endDate.valueOf();

    const { from, to } = this.state;
    const currentStartDate = from.valueOf();
    const currentEndDate = to.valueOf();

    return incomingStartDate !== currentStartDate || incomingEndDate !== currentEndDate;
  }

  calcMonthsToShow() {
    const { from, to } = this.state;
    if (!this.to || !this.to.getDayPicker()) return;
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  render() {
    let { from, to } = this.state;
    from = DateRangePicker.localDateMatchingUTCDate(from);
    to = DateRangePicker.localDateMatchingUTCDate(to);
    const { setQuickFilter, quickFilter } = this.props;
    const modifiers = { start: from, end: to };
    const today = DateRangePicker.localDateMatchingUTCDate(moment.utc().toDate());

    function renderDay(day) {
      return (
        <div className="DayPicker-Day-Inner">
          <div className="DayPicker-Day-HalfBG" />
          <div className="DayPicker-Day-InnerText">{day.getDate()}</div>
        </div>
      );
    }

    return (
      <div className="DateRangePicker">
        <Icon
          classes="calendar-icon"
          name="Calendar"
          size="minor"
          svgProps={{ style: { fill: '#8008f7' } }}
        />
        <div className="InputFromTo" ref="InputFromTo">
          <DayPickerInput
            // showOverlay={true} // for styling
            value={from}
            // eslint-disable-next-line no-return-assign
            ref={(el) => (this.from = el)}
            placeholder="From"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            hideOnDayClick={false}
            onDayPickerHide={() => this.onDayPickerHide()}
            onDayPickerShow={() => this.onDayPickerShow()}
            dayPickerProps={{
              renderDay,
              selectedDays: [{ from, to }],
              disabledDays: { after: today },
              initialMonth: from,
              modifiers,
              numberOfMonths: 2,
              // eslint-disable-next-line react/no-unstable-nested-components
              navbarElement: (props) => (
                <Navbar {...props} onSetQuickFilter={setQuickFilter} quickFilter={quickFilter} />
              ),
              showOutsideDays: true,
            }}
            onDayChange={(fromEvent) => this.onFromChange(fromEvent)}
          />
          <div className="InputArrow">
            <Icon
              classes="arrow-icon"
              name="Arrow--long--east"
              size="minor"
              svgProps={{ style: { fill: '#8008f7' } }}
            />
          </div>
          <span className="InputFromTo-to">
            <DayPickerInput
              // showOverlay={true} // for styling
              // eslint-disable-next-line no-return-assign
              ref={(el) => (this.to = el)}
              value={to}
              placeholder="To"
              format="LL"
              formatDate={formatDate}
              parseDate={parseDate}
              hideOnDayClick={false}
              onDayPickerHide={() => this.onDayPickerHide()}
              onDayPickerShow={() => this.onDayPickerShow()}
              dayPickerProps={{
                renderDay,
                selectedDays: [{ from, to }],
                disabledDays: { after: today },
                initialMonth: from,
                modifiers,
                numberOfMonths: 2,
                // eslint-disable-next-line react/no-unstable-nested-components
                navbarElement: (props) => (
                  <Navbar {...props} onSetQuickFilter={setQuickFilter} quickFilter={quickFilter} />
                ),
                showOutsideDays: true,
              }}
              onDayChange={(toEvent) => this.onToChange(toEvent)}
            />
          </span>
        </div>
      </div>
    );
  }
}

function Navbar({ onPreviousClick, onNextClick, className, onSetQuickFilter, quickFilter }) {
  return (
    <div className={`Navbar ${className}`}>
      {onSetQuickFilter && (
        <div className="relative-date-container">
          <button
            className={`relative-date${
              quickFilter === 'TIMEQUICKFILTER_24HOURS' ? ' selected' : ''
            }`}
            onClick={() => onSetQuickFilter('TIMEQUICKFILTER_24HOURS')}
            type="button"
          >
            Past 24 hours
          </button>
          <button
            className={`relative-date${quickFilter === 'TIMEQUICKFILTER_7DAYS' ? ' selected' : ''}`}
            onClick={() => onSetQuickFilter('TIMEQUICKFILTER_7DAYS')}
            type="button"
          >
            Past 7 days
          </button>
          <button
            className={`relative-date${
              quickFilter === 'TIMEQUICKFILTER_THISMONTH' ? ' selected' : ''
            }`}
            onClick={() => onSetQuickFilter('TIMEQUICKFILTER_THISMONTH')}
            type="button"
          >
            This month
          </button>
          <button
            className={`relative-date${
              quickFilter === 'TIMEQUICKFILTER_LASTMONTH' ? ' selected' : ''
            }`}
            onClick={() => onSetQuickFilter('TIMEQUICKFILTER_LASTMONTH')}
            type="button"
          >
            Last month
          </button>
        </div>
      )}
      <button className="previous" onClick={() => onPreviousClick()} type="button">
        <Icon
          classes="arrow-icon"
          name="Arrow--long--west"
          size="minor"
          svgProps={{ style: { fill: '#ffffff' } }}
        />
      </button>
      <button className="next" onClick={() => onNextClick()} type="button">
        <Icon
          classes="arrow-icon"
          name="Arrow--long--east"
          size="minor"
          svgProps={{ style: { fill: '#ffffff' } }}
        />
      </button>
    </div>
  );
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setPollingPause: currentDeviceActions.setPollingPause,
    },
    dispatch
  )
)(DateRangePicker);
