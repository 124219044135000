import { DeviceModel } from 'common-js/models';
import type { Plan } from 'common-js/types/Plan';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import numericSort from 'common-js/utils/numericSort';
import { filterUndefined } from 'common-js/utils/typeSafety/utils';
import { createSelector } from 'reselect';
import type {
  ReduxDeviceSummaryPlan,
  ReduxLink,
  ReduxPlan,
  ReduxPool,
  ReduxSelectorProps,
} from 'types/redux';

export const selectActivation = createSelector(
  (state: any) => state.activation,
  (activation) => activation
);

export const selectActivationGeneral = createSelector(
  selectActivation,
  (activation) => activation.general
);

export const selectActivationSelection = createSelector(
  selectActivation,
  (activation) => activation.selection
);
export const selectActivationFlags = createSelector(
  selectActivation,
  (activation) => activation.flags
);
export const selectActivationSims = createSelector(
  selectActivation,
  (activation) => activation.sims
);

// Flags

export const selectIsPreflight = createSelector(
  [selectActivationFlags],
  (activationFlags) => activationFlags.isPreflight
);

// SIMs

export const selectSimErrors = createSelector(
  [selectActivationSims],
  (activationSims) => activationSims.errors
);

// Plans

const selectReduxPlans = createSelector(
  [(state: any) => state.account?.plans],
  (plans: Array<ReduxPlan>) => plans ?? []
);

const sortCustomPlans = (a: Plan, b: Plan) => {
  // First put the fixed-data plans at the top and flexible at the bottom
  if (a.dataLimit > 0 && b.dataLimit === 0) {
    return -1;
  }
  if (a.dataLimit === 0 && b.dataLimit > 0) {
    return 1;
  }

  // Next sort the fixed-data plans by dataLimit ascending
  if (a.dataLimit < b.dataLimit) {
    return -1;
  }
  if (a.dataLimit > b.dataLimit) {
    return 1;
  }

  // Next sort the fixed-data plans by perLineCharge ascending
  if (a.perLineCharge < b.perLineCharge) {
    return -1;
  }
  if (a.perLineCharge > b.perLineCharge) {
    return 1;
  }

  // Next sort the flexible-data plans by overageCharge ascending
  if (a.overageCharge < b.overageCharge) {
    return -1;
  }
  if (a.overageCharge > b.overageCharge) {
    return 1;
  }

  return 0;
};

const sortRegions = (a: Region, b: Region) => (a.id > b.id ? 1 : -1);

const zoneHasAllCarrierIds = (zoneCarriers: Array<CarrierId>, carrierIds: Array<CarrierId>) =>
  !carrierIds.some((id) => !zoneCarriers[id]);

const reducePlans = (
  plansAcc: Array<Plan>,
  plan: ReduxPlan,
  carrierIds: Array<CarrierId> = [],
  createDisplayName = (pl: ReduxPlan) => pl.name
): Array<Plan> => {
  if (!plan?.zones) {
    return plansAcc;
  }

  const plansByZone: Array<Plan> | undefined = filterUndefined(
    plan.zones.map((zone) => {
      if (!zone) {
        return undefined;
      }

      const currentTier = plan.tiers[plan.current_tier] ?? {};
      const currentZone = currentTier?.zones[zone] ?? {};
      const planName = plan.name;

      if (carrierIds && carrierIds.length > 0) {
        const zoneCarriers = plan.zoneDetails[zone].carriers;
        if (!zoneCarriers || zoneCarriers.length === 0) {
          // We were asked to filter data, but there was nothing on which to filter.
          // Either we should ignore that "bad data" as eventually this sim would
          // fail to activate (current) or show it anyway and let downstream activation handle it
          return undefined;
        }
        if (!zoneHasAllCarrierIds(zoneCarriers, carrierIds)) {
          return undefined;
        }
      }

      return {
        id: plan.id,
        groupId: plan.groupid,
        planName,
        dataLimit: plan.data,
        displayName: createDisplayName(plan),
        displayCategory: plan.display_category,
        zone,
        perLineCharge: parseFloat(currentZone?.amount),
        overageCharge: parseFloat(currentZone?.overage),
        outboundSms: parseFloat(currentZone?.sms),
        cellularCoverageRegion: plan.cellular_coverage_region,
        tiers: plan.tiers,
        apns: [
          ...new Set(Object.values(currentZone?.carriers ?? {}).map((carrier) => carrier.apn)),
        ],
      };
    })
  );

  if (!plansByZone) {
    return plansAcc;
  }
  return [...plansAcc, ...plansByZone];
};

export const selectAllPlans = createSelector([selectReduxPlans], (plans) =>
  plans.reduce<Array<Plan>>((acc, plan) => reducePlans(acc, plan), [])
);

export const selectCurrentPool = createSelector(
  [(state: any) => state.pools?.currentPool],
  (pool?: ReduxPool) => pool
);

export const selectDevicePlanSummary = createSelector(
  [(state: any) => state.organization.devicePlanSummary],
  (plans?: Array<ReduxDeviceSummaryPlan>) => plans
);

const reduceDeviceToLinks = (links: Array<ReduxLink>, device: DeviceModel): Array<ReduxLink> => [
  ...links,
  ...(device.links?.cellular ?? []),
];

export const selectChangePlanDevices = createSelector(
  (state: any) => state.activation?.changePlan?.devices,
  (devices?: Array<DeviceModel>) => devices
);

export const selectChangePlanLinks = createSelector(
  [selectChangePlanDevices],
  (devices) => devices?.reduce<Array<ReduxLink>>(reduceDeviceToLinks, []) as Array<ReduxLink>
);

const reducePlansToRegions = (regions: Array<Region>, plan: Plan) =>
  plan?.cellularCoverageRegion &&
  !regions.find((region) => plan.cellularCoverageRegion?.id === region.id)
    ? [...regions, plan.cellularCoverageRegion]
    : regions;

export const selectAllStandardPlanIds = createSelector([selectReduxPlans], (accountPlans) =>
  accountPlans
    .filter((plan) => plan.display_category === 'standard')
    .map((plan) => plan.id)
    .sort(numericSort)
);

export const selectAllowedRegions = createSelector(
  [
    selectReduxPlans,
    selectAllStandardPlanIds,
    (_: any, carrierIds: Array<CarrierId>) => carrierIds,
  ],
  (accountPlans, allowedPlanIds, carrierIds?: Array<CarrierId>) => {
    if (accountPlans.length === 0 || !allowedPlanIds || allowedPlanIds.length === 0) {
      return [];
    }

    return filterUndefined(
      accountPlans
        .filter((plan) => plan?.id && allowedPlanIds.some((id) => id === plan.id))
        .reduce<Array<Plan>>((acc, plan) => reducePlans(acc, plan, carrierIds), [])
        .reduce<Array<Region>>((acc, plan) => reducePlansToRegions(acc, plan), [])
    ).sort(sortRegions);
  }
);

const createDisplayName = (plan: ReduxPlan) => {
  if (plan.data === 0) {
    return 'Pay as you go';
  }
  return `${toByteStringFormatted(plan.data)} included`;
};

export const selectAllowedStandardPlans = createSelector(
  [
    selectReduxPlans,
    selectAllStandardPlanIds,
    (_: any, props: ReduxSelectorProps<{ carrierIds?: Array<CarrierId>; regionId?: RegionId }>) =>
      props,
  ],
  (accountPlans, allowedPlanIds, props) => {
    const { carrierIds, regionId = -1 } = props ?? {};
    if (
      accountPlans.length === 0 ||
      !carrierIds ||
      carrierIds.length === 0 ||
      !allowedPlanIds ||
      allowedPlanIds.length === 0
    ) {
      return [];
    }

    return accountPlans
      .filter((plan: ReduxPlan) => {
        if (!plan?.id || !allowedPlanIds.some((id) => id === plan.id)) {
          return false;
        }

        if (regionId > -1 && plan.cellular_coverage_region?.id !== regionId) {
          return false;
        }

        return true;
      })
      .reduce<Array<Plan>>(
        (acc, plan) => reducePlans(acc, plan, carrierIds, createDisplayName),
        []
      );
  }
);

export const selectCustomPlans = createSelector(
  [
    selectReduxPlans,
    selectAllStandardPlanIds,
    (_: any, props: ReduxSelectorProps<{ carrierIds?: Array<CarrierId> }>) => props,
  ],
  (accountPlans, standardPlanIds, props) => {
    const { carrierIds } = props ?? {};
    let customPlans: Array<ReduxPlan> = accountPlans;

    if (standardPlanIds && standardPlanIds.length > 0) {
      customPlans = customPlans.filter(
        (plan) => plan.groupid !== 0 && !standardPlanIds.some((id) => id === plan.id)
      );
    } else {
      customPlans = customPlans.filter(
        (plan) => plan.groupid !== 0 && plan.display_category === 'custom'
      );
    }

    return customPlans
      .reduce<Array<Plan>>((acc, plan) => reducePlans(acc, plan, carrierIds), [])
      .sort(sortCustomPlans);
  }
);

interface ActivationPlan {
  id?: number;
  name?: string;
  dataLimit?: number;
  zone?: string;
  zoneCount?: number;
  perLineCharge?: number;
  overageCharge?: number;
  outboundSms?: number;
}

export const selectPlanData = createSelector<
  any,
  number | undefined,
  ReduxPlan[],
  number | undefined,
  ActivationPlan
>(
  [selectReduxPlans, (_: any, planId?: PlanId) => planId],
  (accountPlans, planId): ActivationPlan => {
    if (!planId || accountPlans.length === 0) return {};

    const plan = accountPlans.find((accountPlan) => accountPlan.id === planId);
    if (!plan) return {};

    const firstZone = plan?.zones[0] ?? '';
    const currentTier = plan?.tiers[plan?.current_tier] ?? {};
    const currentZone = (currentTier?.zones && currentTier?.zones[firstZone]) ?? {};

    return {
      id: plan?.id,
      name: plan?.name,
      dataLimit: plan?.data,
      zone: firstZone,
      zoneCount: plan?.zones?.length,
      perLineCharge: parseFloat(currentZone?.amount),
      overageCharge: parseFloat(currentZone?.overage),
      outboundSms: parseFloat(currentZone?.sms),
    };
  }
);
