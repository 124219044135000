import { useEffect, useMemo } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from 'common-js/api/useApi';
import { selectOrgId } from 'common-js/api/util';
import { setLiveUsage } from 'common-js/reducers/currentDevice/actions';

const useGetLiveUsage = (deviceId: DeviceId) => {
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(selectOrgId);
  const queryParams = useMemo(
    () =>
      new URLSearchParams({
        orgid: orgId,
        limit: '100',
        deviceid: deviceId.toString(),
        getdeviceinfo: '1',
      }).toString(),
    [deviceId, orgId]
  );
  const apiCall = useApi.call(`/usage/data?${queryParams}`);

  useEffect(() => {
    const effect = async () => {
      if (!deviceId) return;
      dispatch(setLiveUsage({ loading: true }));
      const liveUsage: any = { loading: false };
      try {
        const apiResponse = await apiCall();
        liveUsage.data = apiResponse.data;
      } catch (e) {
        liveUsage.error = e;
      }
      dispatch(setLiveUsage(liveUsage));
    };
    effect();
  }, [apiCall, deviceId, dispatch]);
};

export default useGetLiveUsage;
