import type { MessageType } from 'common-js/components/FormPanel';

export type QueryStringMessageCodes = 'orginvite' | 'adminfail' | 'download';

const getQueryStringMessages: Record<
  QueryStringMessageCodes,
  { message: string; messageType: MessageType }
> = {
  orginvite: {
    message: `Please login or register an account before joining this organization.`,
    messageType: `success`,
  },
  adminfail: {
    message: `Please sign in as admin.`,
    messageType: `success`,
  },
  download: {
    message: `Please sign in to download this document.`,
    messageType: `error`,
  },
} as const;

export default getQueryStringMessages;
