type FieldLabelKey = 'newPassword' | 'confirmPassword';

export const fieldLabels: Record<FieldLabelKey, string> = {
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
};

export const passwordHelpText = `Your password must be at least 6 characters and include 1 number and 1 letter.`;

type ValidationMessageKey =
  | 'passwordBlank'
  | 'passwordEmailMatch'
  | 'passwordFieldMismatch'
  | 'passwordLetterRequirement'
  | 'passwordMaxLength'
  | 'passwordMinLength'
  | 'passwordNumberRequirement';

export const validationMessages: Record<ValidationMessageKey, string> = {
  passwordBlank: 'Please enter a new password.',
  passwordEmailMatch: 'Your password must be different than your email.',
  passwordFieldMismatch: 'Passwords must match',
  passwordLetterRequirement: 'Include at least 1 letter in your password.',
  passwordMaxLength: `Your password cannot be more than 256 characters.`,
  passwordMinLength: `Your password must be at least 6 characters.`,
  passwordNumberRequirement: 'Include at least 1 number in your password.',
};

export const API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH =
  'Email and password cannot be the same';
export const API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER =
  'Password needs to have at least 1 letter';
export const API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER =
  'Password needs to have at least 1 number';
export const API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH =
  'Password cannot be more than 256 characters long';
export const API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH =
  'Password needs to be at least 6 characters long';

type ApiResponseKey =
  | typeof API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH
  | typeof API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER
  | typeof API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER
  | typeof API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH
  | typeof API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH;

export const apiToClientPasswordErrors: Record<
  ApiResponseKey,
  (typeof validationMessages)[keyof typeof validationMessages]
> = {
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH]: validationMessages.passwordEmailMatch,
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER]: validationMessages.passwordLetterRequirement,
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH]: validationMessages.passwordMaxLength,
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH]: validationMessages.passwordMinLength,
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER]: validationMessages.passwordNumberRequirement,
};
