import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@holokit/core';
import ClassNames from 'clsx';

export default class DataPercentTrend extends React.Component {
  static safeToFixed(num, fixed) {
    try {
      return num.toFixed(fixed);
    } catch (e) {
      return 0;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      isPositive: true,
      percent: 0,
    };
  }

  componentDidMount() {
    const { hasData } = this.props;

    if (hasData) {
      this.getAverage();
    }
  }

  getAverage() {
    const { current, previous } = this.props;
    const parsedCurrent = parseInt(current, 10);
    const parsedPrevious = parseInt(previous, 10);

    if (current > previous) {
      let percentChange = ((parsedCurrent - parsedPrevious) / parsedPrevious) * 100;
      percentChange = DataPercentTrend.safeToFixed(percentChange, 1);
      this.setState({ percent: percentChange });
    } else {
      let percentChange = ((parsedPrevious - parsedCurrent) / parsedPrevious) * 100;
      percentChange = DataPercentTrend.safeToFixed(percentChange, 1);
      if (percentChange > 0) {
        this.setState({ isPositive: false, percent: percentChange });
      } else {
        this.setState({ isPositive: true, percent: percentChange });
      }
    }
  }

  render() {
    const { classModifier, dataNumber, hasData, isOverviewPage } = this.props;
    const { isPositive, percent } = this.state;

    return (
      <div
        className={ClassNames('DataPercentTrend', {
          'DataPercentTrend--positive': isPositive,
          'DataPercentTrend--negative': !isPositive,
          [`DataPercentTrend${classModifier}`]: classModifier,
        })}
      >
        {hasData ? percent > 0 && (isPositive ? '+' : '-') : null}
        {percent}%
        {hasData ? (
          <span>
            {hasData && dataNumber && (
              <span className="DataPercentTrend__data_number">{dataNumber}</span>
            )}
            {hasData &&
              percent > 0 &&
              !isOverviewPage &&
              (isPositive ? (
                <span className="positive">
                  <Icon
                    name="Arrow--long--northeast"
                    size="minor"
                    svgProps={{ style: { fill: '#2F6AFF' } }}
                  />
                </span>
              ) : (
                <span className="negative">
                  <Icon
                    name="Arrow--long--southeast"
                    size="minor"
                    svgProps={{ style: { fill: '#A80000' } }}
                  />
                </span>
              ))}
          </span>
        ) : null}
      </div>
    );
  }
}

DataPercentTrend.propTypes = {
  classModifier: PropTypes.string,
  dataNumber: PropTypes.number,
  hasData: PropTypes.bool,
};

DataPercentTrend.defaultProps = {
  classModifier: null,
  dataNumber: null,
  hasData: true,
};
