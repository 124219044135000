import { DeepMenu, DeepMenuItem } from '@holokit/core';
import { DEAD } from 'common-js/constants/deviceStates';
import {
  DEVICE_DATA_LIMITS,
  DEVICE_HYPER,
  DEVICE_MESSAGE,
  DEVICE_PARAM_NAME,
  DEVICE_PLAN,
  DEVICE_PROFILES,
  DEVICE_ROUTES,
  DEVICE_SPACEBRIDGE,
  DEVICE_STATUS,
  DEVICE_USAGE,
  DEVICE_WEBHOOKS,
  buildSDPPath,
  onInternalLinkClick,
  pathMatches as pathsPathMatches,
} from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import { getReleaseFlags, selectIsBmpCustomer } from 'common-js/reducers/releaseFlag/selectors';
import { BILLING_VISIBLE } from 'common-js/utils/permissions';
import { useCallback } from 'react';

const paramName = `:${DEVICE_PARAM_NAME}`;

const pathMatches = (path: string, currentPath: string, deviceId?: number): boolean =>
  pathsPathMatches(path.replace(paramName, (deviceId ?? '').toString()), currentPath);

interface DeviceMenuProps {
  currentPath: string;
  deviceId?: number;
}

const DeviceMenu = ({ currentPath, deviceId }: DeviceMenuProps) => {
  const device = useAppSelector(selectCurrentDevice);
  const releaseFlags = useAppSelector(getReleaseFlags);
  const userPermissions = useAppSelector(getUserPermissions);
  const isBmpCustomer = useAppSelector(selectIsBmpCustomer);

  const { sysState: deviceStatus, euicc_type: euiccType, isHyper } = device;
  const euiccCapable = euiccType !== 'UICC';

  const hasBillingVisible = userPermissions.includes(BILLING_VISIBLE);

  const DeviceMenuItem = useCallback(
    ({ path, text, ...rest }) => (
      <DeepMenuItem
        active={pathMatches(path, currentPath, deviceId)}
        href={buildSDPPath((deviceId ?? '').toString(), path)}
        onClick={onInternalLinkClick}
        text={text}
        {...rest}
      />
    ),
    [currentPath, deviceId]
  );

  const showProfiles = releaseFlags.euicc && releaseFlags.hyper_sdp_improvements && isHyper;
  const showHyper = releaseFlags.euicc && !releaseFlags.hyper_sdp_improvements;
  const showBilling = hasBillingVisible;
  const showRoutes = releaseFlags?.routes;
  const showDataLimits = deviceStatus !== DEAD && (isBmpCustomer || releaseFlags.eureka0);

  return (
    <DeepMenu className="DeviceMenu">
      <DeviceMenuItem path={DEVICE_STATUS} text="Status" />
      <DeviceMenuItem path={DEVICE_USAGE} text="Usage" />
      {showProfiles && <DeviceMenuItem path={DEVICE_PROFILES} text="Profiles" />}
      {showHyper && <DeviceMenuItem path={DEVICE_HYPER} text="Hyper" disabled={!euiccCapable} />}
      <DeviceMenuItem path={DEVICE_MESSAGE} text="Messaging" />
      {showBilling && <DeviceMenuItem path={DEVICE_PLAN} text="Data plan" />}
      {showDataLimits && <DeviceMenuItem path={DEVICE_DATA_LIMITS} text="Data usage limits" />}
      <DeviceMenuItem path={DEVICE_SPACEBRIDGE} text="Device tunneling" />
      <DeviceMenuItem path={DEVICE_WEBHOOKS} text="Webhooks" />
      {showRoutes && <DeviceMenuItem path={DEVICE_ROUTES} text="Routes" />}
    </DeepMenu>
  );
};

export default DeviceMenu;
