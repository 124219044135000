import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';

// a nice, memoized callback to un-hide an element, select its text, copy to clipboard, and hide once more
const useCopyElement = (ref, displayString) => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    const copyTextarea = ref.current;
    copyTextarea.classList.remove('hidden');
    copyTextarea.select();
    try {
      document.execCommand('copy');
      dispatch(pushGlobalMessage(`${displayString} copied to clipboard.`));
    } catch (err) {
      dispatch(pushGlobalMessage(err, 'error'));
    }
    copyTextarea.classList.add('hidden');
  }, [dispatch, displayString, ref]);
};

export default useCopyElement;
