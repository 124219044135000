import React from 'react';

const OpenCellIdAttribution = () => (
  <div className="OpenCellIdAttribution">
    <div>Tower Location data is provided by OpenCelliD</div>
    <div>
      <a href="https://opencellid.org/" target="_blank" rel="noreferrer">
        Open CelliD Project
      </a>{' '}
      is licensed under a{' '}
      <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" rel="noreferrer">
        Creative Commons Attribution-ShareAlike 4.0 International License
      </a>
    </div>
  </div>
);

export default OpenCellIdAttribution;
