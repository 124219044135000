import { useCallback } from 'react';
import { analyticsEventBuilder } from './analytics';
import { AnalyticsSendParams } from './analyticsTypes';

interface UseOnButtonClickWithAnalyticsProps {
  pageName?: string;
  sendParams?: AnalyticsSendParams;
}

const useOnButtonClickWithAnalytics = ({
  pageName,
  sendParams,
}: UseOnButtonClickWithAnalyticsProps) =>
  useCallback(
    (buttonName: string, url?: string | URL, target?: string, external?: boolean) => {
      analyticsEventBuilder.buttonClick(pageName, buttonName).send(sendParams);

      if (url) {
        window.open(url, target ?? '_blank', external ? 'nofollow noopener noreferrer' : undefined);
      }
    },
    [pageName, sendParams]
  );

export default useOnButtonClickWithAnalytics;
