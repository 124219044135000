import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Link } from '@hologram-dimension/link';
import { Panel } from '@hologram-dimension/panel';
import classNames from 'clsx';
import analyticsEventBuilder from 'common-js/analytics';
import { HoloHelmet } from 'common-js/components';
import {
  ACCOUNT_LOGIN,
  USAGE_BY_DAY,
  USAGE_OVERVIEW,
  withContext,
} from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useRedirectIfFlagNotSet from 'common-js/hooks/useRedirectIfFlagNotSet';
import { selectPromoteSimsPages } from 'common-js/reducers/releaseFlag/selectors';
import { fetchReportDetails } from 'common-js/reducers/reports/actions';
import { selectReportDetails, selectReportError } from 'common-js/reducers/reports/selectors';
import { useEffect, useState } from 'react';
import ReportDetailLoader from './ReportDetailLoader';
import ReportError from './ReportError';
import ReportNotFoundError from './ReportNotFoundError';

const IFRAME_TIMEOUT_WAIT = 7000; // 7 seconds

function ReportDetail({ params: { reportId }, iframeTimeoutWait = IFRAME_TIMEOUT_WAIT }) {
  useRedirectIfFlagNotSet('embedded_looker_reporting', ACCOUNT_LOGIN);

  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();

  const reportDetails = useAppSelector(selectReportDetails);
  const reportName = reportDetails?.name;
  const reportError = useAppSelector(selectReportError);
  const hasPromoteSimInventoryFlag = useAppSelector(selectPromoteSimsPages);

  useEffect(() => {
    if (reportId !== undefined) {
      dispatch(fetchReportDetails({ reportId }));
    }
  }, [dispatch, reportId]);

  useEffect(() => {
    if (reportName !== undefined) {
      analyticsEventBuilder.pageView().page('Reports', reportName).send();
    }
  }, [reportName]);

  useEffect(() => {
    let timeoutId: number | undefined;
    if (loading) {
      timeoutId = window.setTimeout(() => {
        setLoading(false);

        analyticsEventBuilder
          .systemError('Reports', 'Looker iframe not loaded in 7 seconds')
          .send({ location: 'frontend' });
      }, iframeTimeoutWait);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [iframeTimeoutWait, loading, setLoading]);

  useEffect(() => {
    if (loading && reportError) {
      setLoading(false);
    }
  }, [loading, reportError, setLoading]);

  const iframePostLoad = () => {
    setLoading(false);
  };

  if (!loading && reportError) {
    const errorName =
      reportError === 'Report Not Found' ? 'Report not found in backend' : 'Report backend error';

    analyticsEventBuilder.systemError(errorName).send({
      location: 'backend',
      error: reportError,
    });

    return (
      <div className="ReportDetail">
        <Panel>
          {reportError === 'Report Not Found' ? <ReportNotFoundError /> : <ReportError />}
        </Panel>
      </div>
    );
  }

  return (
    <>
      <HoloHelmet title={loading ? 'Loading report details...' : reportName} />
      <div className="ReportDetail">
        <BannerNotification
          prefixOverride="Beta ending"
          variant="caution"
          className="ReportDetail__banner"
        >
          We will be sunsetting this page on <strong>August 30, 2024</strong>. All of the content in
          these reports can now be found in our new{' '}
          <Link
            to={
              hasPromoteSimInventoryFlag ? withContext(USAGE_BY_DAY) : withContext(USAGE_OVERVIEW)
            }
            type="inline"
          >
            Usage page reports
          </Link>
          . Thank you for participating in this beta!
        </BannerNotification>
        <div className="ReportDetail__panel-wrapper">
          <Panel
            isLoading={loading}
            className="ReportDetail__panel"
            contentClassName="ReportDetail__panel-body"
            noContentPadding
          >
            <>
              {loading && <ReportDetailLoader />}
              <iframe
                title={reportName}
                className={classNames('ReportDetail__iframe', {
                  'ReportDetail__iframe--loading': loading,
                })}
                src={reportDetails?.url}
                onLoad={iframePostLoad}
              />
            </>
          </Panel>
        </div>
      </div>
    </>
  );
}

export default ReportDetail;
