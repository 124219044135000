import _ from 'lodash';

export const SYS_TOPICS = [
  '_SIMPLESTRING_',
  '_SMS_',
  '_SMS_DO_',
  '_SOCKETAPI_',
  '_RESTAPI_',
  '_SMSOVERIP_',
  '_JSONSTRING_',
];

export const TOPIC_TYPES = {
  SYSTEM: 'SYSTEM',
  DEVICE: 'DEVICE',
  TAG: 'TAG',
  CUSTOM: 'CUSTOM',
} as const;

type TopicType = keyof typeof TOPIC_TYPES;

interface TopicInfo {
  type: TopicType;
  name?: string;
  prettyName?: string;
  deviceId?: string;
  tagId?: string;
}

export function getTopicInfo(topic: string, tags?, cachedDevices?): TopicInfo {
  if (SYS_TOPICS.includes(topic) || topic.includes('MOD_') || topic.includes('HEARTBEAT_')) {
    return {
      type: TOPIC_TYPES.SYSTEM,
    };
  }

  if (topic.includes('_DEVICE_')) {
    const result: TopicInfo = { type: TOPIC_TYPES.DEVICE };

    try {
      const matches = topic.match(/(\\_DEVICE\\_)(.+)(\\_)/);
      const deviceId = matches && matches.length > 2 ? matches[2] : undefined;

      if (deviceId && cachedDevices[deviceId]) {
        result.prettyName = cachedDevices[deviceId].name;
      }

      result.deviceId = deviceId;
    } catch (error) {
      // noop
    }

    return result;
  }

  if (topic.includes('_TAG_')) {
    const result: TopicInfo = { type: TOPIC_TYPES.TAG };

    try {
      const matches = topic.match(/(\\_TAG\\_)(.+)(\\_)/);
      const tagId = matches && matches.length > 2 ? matches[2] : undefined;

      if (tagId) {
        result.prettyName = _.find(tags, { id: parseInt(tagId, 10) }).name;
      }
      result.tagId = tagId;
    } catch (error) {
      // noop
    }

    return result;
  }

  return {
    type: TOPIC_TYPES.CUSTOM,
    name: topic,
  };
}

export const isSysTopic = (topic) => getTopicInfo(topic).type === TOPIC_TYPES.SYSTEM;
