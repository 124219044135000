import { API_URL, headers, getHeaders } from '../config';
import { fetchHandler, fetchErrorHandler } from '../middleware';
import { UserInfoModel } from '../../models';

export const login = async (email: string, password: string, rememberMe: boolean) => {
  try {
    const response = await fetch(`${API_URL}/auth/session?rememberme=${rememberMe ? 1 : 0}`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      const { data } = jsonResponse;

      return data?.mfa ? data : { userInfo: new UserInfoModel(data) };
    }

    const jsonResponse = await response.json();
    const { error } = jsonResponse;
    throw new Error(error);
  } catch (e) {
    return fetchErrorHandler(e as FetchError);
  }
};

export function logout(redirectOnForbidden = false) {
  return fetch(`${API_URL}/auth/sessiondestroy`, {
    method: 'POST',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((resp) => Promise.resolve(resp), undefined, redirectOnForbidden))
    .catch(fetchErrorHandler);
}
interface RegisterRequestBody {
  email: string;
  first: string;
  last: string;
  password: string;
  verifyurl: string;
  inviteid?: string;
  token?: string;
}

export function register(
  email: string,
  first: string,
  last: string,
  password: string,
  orgInviteId: Nullable<string> = null,
  orgInviteToken: Nullable<string> = null
) {
  const requestBody: RegisterRequestBody = {
    email,
    first,
    last,
    password,
    verifyurl: `${window.location.origin}/verify`,
  };

  if (orgInviteId) {
    requestBody.inviteid = orgInviteId;
  }

  if (orgInviteToken) {
    requestBody.token = orgInviteToken;
  }

  return fetch(`${API_URL}/register`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(requestBody),
  })
    .then(fetchHandler((responseBody) => Promise.resolve({ user: responseBody.data })))
    .catch(fetchErrorHandler);
}

export function verifyAccountStatus(userId: string) {
  return fetch(`${API_URL}/register/verifystatus/${userId}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export function isUserVerified(userId: string): Promise<boolean> {
  return fetch(`${API_URL}/register/verifystatus/${userId}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then((response) => {
      if (response.ok) {
        return response.json().then(() => Promise.resolve(false));
      }
      return response.json().then(() => Promise.resolve(true));
    })
    .catch(fetchErrorHandler);
}

export function changePassword(newPassword: string, userContext: { userId: string }) {
  return fetch(
    `${API_URL}/users/${userContext.userId}/password?_id=${userContext.userId}&withCredentials=true`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        password: newPassword,
        password_confirmation: newPassword,
      }),
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);
}

export function getOrders(orgId: string) {
  return fetch(`${API_URL}/orders?orgid=${orgId}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getUserInfo() {
  return fetch(`${API_URL}/users/me`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler(
        (body) => Promise.resolve({ userInfo: new UserInfoModel(body.data) }),
        true,
        false
      )
    )
    .catch(fetchErrorHandler);
}

export function updateUserInformation(firstName: string, lastName: string, email: string) {
  return fetch(`${API_URL}/users/me`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      first: firstName,
      last: lastName,
      email,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getApiKey() {
  return fetch(`${API_URL}/users/me/apikey`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function generateApiKey() {
  return fetch(`${API_URL}/users/me/apikey`, {
    method: 'POST',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}
/* eslint-disable @typescript-eslint/default-param-last */
export function getPlans(
  partnerId: string,
  carrierId = null,
  limit = 1000,
  userContextData: { isInOrgContext: boolean; orgId: string; userOrgId: string }
) {
  const orgId = userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId;

  return fetch(
    `${API_URL}/plans?enabled=1&partnerid=${partnerId}&${
      carrierId ? `network=${carrierId}` : null
    }&limit=${limit}&orgid=${orgId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}
/* eslint-enable @typescript-eslint/default-param-last */

export function getCarriers() {
  return fetch(`${API_URL}/links/cellular/images`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPendingOrgs(contextData: { userId: string }) {
  return fetch(`${API_URL}/organizations/pending?userid=${contextData.userId}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function acceptInvitation(inviteId: string, emailAcceptToken?: string) {
  const body: { token?: string } = {};

  if (emailAcceptToken) {
    body.token = emailAcceptToken;
  }

  return fetch(`${API_URL}/organizations/acceptinvite/${inviteId}/`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  })
    .then(fetchHandler(() => Promise.resolve()))
    .catch(fetchErrorHandler);
}

export function resendEmailVerification(userId: string) {
  return fetch(`${API_URL}/register/resendverify/`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      userid: userId,
    }),
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export function getUserSettings() {
  return fetch(`${API_URL}/settings/user`, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body), true, false))
    .catch(fetchErrorHandler);
}

export function updateUserSettings(settings: any) {
  return fetch(`${API_URL}/settings/user`, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({ settings }),
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export const authenticateTotp = async (
  email: string,
  methodId: string,
  totpCode: string,
  rememberme: boolean
) => {
  // These API responses are related to the totpCode and that should be communicated to the client so the error can be displayed appropriately
  // In some cases, the API response is altered to be more user-friendly
  const totpCodeErrors = {
    [`Required field 'totpCode' missing or empty`]: `Please enter the code sent to your email.`,
    [`This code is invalid. Confirm it matches the code in your email.`]: `This code is invalid. Confirm it matches the code in your email.`,
  };

  try {
    const response = await fetch(`${API_URL}/auth/totp/authenticate`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        email,
        methodId,
        totpCode,
        rememberme,
      }),
    });
    const jsonResponse = await response.json();
    if (response.status === 200) {
      const { data } = jsonResponse;
      return { userInfo: new UserInfoModel(data) };
    }
    const { error } = jsonResponse;

    if (totpCodeErrors[error]) {
      // The API responses above are related to the totpCode data
      // Return a custom failure message for the UI to handle
      return { success: false, error: { totpCode: totpCodeErrors[error] } };
    }

    throw new Error(error);
  } catch (e) {
    return fetchErrorHandler(e as FetchError);
  }
};

export const resendTotp = async (email: string) => {
  try {
    const response = await fetch(`${API_URL}/auth/totp/resend`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        email,
      }),
    });
    const jsonResponse = await response.json();
    const { data } = jsonResponse;
    return data;
  } catch (e) {
    return fetchErrorHandler(e as FetchError);
  }
};
