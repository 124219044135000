import {
  OrganizationProvider,
  PredeploymentTestingPage,
} from '@hologram-hyper-dashboard/components';
import { getOrgIdFromContextData, getUserContextDataMemoized } from 'common-js/api/util';
import { HoloHelmet } from 'common-js/components';
import { ACCOUNT_LOGIN } from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectHasPredeploymentTestingFlags } from 'common-js/reducers/releaseFlag/selectors';
import { useEffect, type FC } from 'react';
import { browserHistory } from 'react-router';

interface UsagePredeploymentProps {}

const UsagePredeployment: FC<UsagePredeploymentProps> = () => {
  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = getOrgIdFromContextData(userContext);
  const isPreflight = useAppSelector(selectHasPredeploymentTestingFlags);

  useEffect(() => {
    if (!orgId || !isPreflight) {
      browserHistory.push(ACCOUNT_LOGIN);
    }
  }, [isPreflight, orgId]);

  if (!orgId) {
    return null;
  }

  return (
    <>
      <HoloHelmet title="Usage Reports - Pre-deployment testing" />
      <OrganizationProvider organizationId={orgId}>
        <PredeploymentTestingPage className="UsagePage" />
      </OrganizationProvider>
    </>
  );
};

export default UsagePredeployment;
