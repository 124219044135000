const PREFIX = 'DRAWER_' as const;

export const CHANGE_TAB = `${PREFIX}CHANGE_TAB` as const;
export const CHANGE_VIEWMODE = `${PREFIX}CHANGE_VIEWMODE` as const;
export const CHANGE_MINIMIZED = `${PREFIX}CHANGE_MINIMIZED` as const;
export const CHANGE_FULLSCREEN = `${PREFIX}CHANGE_FULLSCREEN` as const;
export const VIEW_FILTERS = `${PREFIX}VIEW_FILTERS` as const;
export const HASH_CHANGE = `${PREFIX}HASH_CHANGE` as const;
export const SET_LOCKED = `${PREFIX}SET_LOCKED` as const;
export const SET_TAKEOVER = `${PREFIX}SET_TAKEOVER` as const;
