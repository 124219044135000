import { getCompletedTasks } from './getCompletedTasks';
import { setCurrentTasksPage } from './setCurrentTasksPage';
import * as actionTypes from '../actionTypes';
import { getActivityHistorySort } from '../selectors';

export function updateActivityHistorySort(newColumn) {
  return (dispatch, getState) => {
    const state = getState();
    const sortInfo = getActivityHistorySort(state) ?? {};

    const columnIsSame = newColumn === sortInfo.column;
    const { direction } = sortInfo;

    let nextColumn = newColumn;
    let nextDirection = 'asc';

    if (columnIsSame) {
      if (direction === 'desc') {
        nextColumn = null;
        nextDirection = null;
      } else if (direction === 'asc') {
        nextDirection = 'desc';
      }
    }

    dispatch({
      type: actionTypes.UPDATE_ACTIVITY_HISTORY_SORT,
      column: nextColumn,
      direction: nextDirection,
    });

    dispatch(setCurrentTasksPage(1));
    dispatch(getCompletedTasks());

    return Promise.resolve();
  };
}

export default updateActivityHistorySort;
