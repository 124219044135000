import { Radio } from '@hologram-dimension/radio';
import _classNames from 'clsx';
import type { Plan } from 'common-js/types/Plan';
import { useCallback } from 'react';
import formatCurrencyWithRounding from '../../common/utils/formatCurrencyWithRounding';
import { addCommasToNumber } from '../../common/utils/numberFormatter';

interface PlanOptionCardTableProps {
  selected: boolean;
  plan: Plan;
  numSims: number;
  forceExpanded: boolean;
}

const PlanOptionCardTable = ({
  selected,
  plan,
  numSims,
  forceExpanded,
}: PlanOptionCardTableProps) => {
  const { dataLimit, perLineCharge, overageCharge } = plan;
  const perDeviceTotal = perLineCharge * numSims;
  const monthlyTotal = perDeviceTotal;
  const formattedNumSims = addCommasToNumber(numSims, false);

  if (!dataLimit) {
    if (selected || forceExpanded) {
      return (
        <div className="PlanOptionCard__option--details">
          <table>
            <tbody className="PlanOptionCard__option--paygo">
              <tr>
                <td>Per device</td>
                <td>
                  {formatCurrencyWithRounding(perLineCharge ?? 0, 4)} x {formattedNumSims} SIMs
                </td>
                <td>{formatCurrencyWithRounding(perDeviceTotal ?? 0, 4)}</td>
              </tr>
              <tr>
                <td>Per MB</td>
                <td>{formatCurrencyWithRounding(overageCharge ?? 0, 4)}</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }
  return (
    <div className="PlanOptionCard__option--details">
      {(selected || forceExpanded) && (
        <table>
          <tbody>
            <tr>
              <td>Per device</td>
              <td>
                {formatCurrencyWithRounding(perLineCharge ?? 0, 4)} x {formattedNumSims} SIMs
              </td>
              <td>{formatCurrencyWithRounding(perDeviceTotal ?? 0, 4)}</td>
            </tr>
            <tr>
              <td>Per additional MB</td>
              <td>{formatCurrencyWithRounding(overageCharge ?? 0, 4)}</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="PlanOptionCard__option--cost">
        <div>Estimated monthly total</div>
        <div>{formatCurrencyWithRounding(monthlyTotal ?? 0, 4)}</div>
      </div>
    </div>
  );
};

interface PlanOptionCardProps {
  plan: Plan;
  selected?: boolean;
  onChange: (plan: Plan) => void;
  numSims: number;
  preflightMode?: boolean;
  disabled?: boolean;
  forceExpanded?: boolean;
}

const PlanOptionCard = ({
  plan,
  selected = false,
  onChange,
  numSims,
  preflightMode = false,
  disabled = false,
  forceExpanded = false,
}: PlanOptionCardProps) => {
  const { id } = plan;

  const handleChange = useCallback(() => {
    onChange(plan);
  }, [onChange, plan]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor={`plan_select_${id}`}>
      <div
        className={_classNames(
          'PlanOptionCard__planOption',
          selected ? 'PlanOptionCard__planOption--selected' : null,
          disabled ? 'PlanOptionCard__planOption--disabled' : null
        )}
      >
        <Radio
          name="plan"
          id={`plan_select_${id}`}
          value={id.toString()}
          className="PlanOptionCard__radioButton"
          onChange={handleChange}
          defaultChecked={preflightMode ? true : selected}
          label=""
          hiddenLabel
          disabled={disabled}
        />
        <div className="PlanOptionCard__option--info">
          <div
            className={_classNames(
              'PlanOptionCard__option--name',
              disabled ? 'PlanOptionCard__option--disabled' : null
            )}
          >
            {plan.planName}
          </div>
          <PlanOptionCardTable
            selected={selected}
            plan={plan}
            numSims={numSims}
            forceExpanded={forceExpanded}
          />
        </div>
      </div>
    </label>
  );
};

export default PlanOptionCard;
