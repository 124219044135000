import React from 'react';
import { useFormContext } from 'react-hook-form';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import CompletedStepHeader from '../common/scaffolding/CompletedStepHeader';
import SidebarAttribute from '../common/scaffolding/SidebarAttribute';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';

const STEP_NAME = 'Add SIMs';

const sideBarAttrNameMap = {
  list: 'Uploaded',
  individual: 'Added',
  range: 'SIM Range',
};

function SimsSidebarItem({ step, currentStep }) {
  const { total, inputOptionUsed } = useFormContext().getValues('sims') ?? {};

  const sidebarAttributeText = total === 1 ? 'SIM' : 'SIMs';

  if (currentStep > step) {
    return (
      <CompletedStepHeader name={STEP_NAME}>
        <SidebarAttribute
          name={sideBarAttrNameMap[inputOptionUsed] ?? 'Added'}
          value={`${addCommasToNumber(total, false)} ${sidebarAttributeText}`}
        />
      </CompletedStepHeader>
    );
  }

  return <IncompleteStepHeader step={step} currentStep={currentStep} name="SIM selection" />;
}

export default SimsSidebarItem;
