import React from 'react';
import SearchModalError from './SearchModalError';
import SearchModalSkeleton from './SearchModalSkeleton';
import SearchModalNoResults from './SearchModalNoResults';
import SearchModalResults from './SearchModalResults';

const SearchModalContent = ({
  loading,
  results,
  inputValue,
  showError,
  activeResult,
  onSelectResult,
  onRowHover,
}) => {
  if (loading) {
    return <SearchModalSkeleton />;
  }
  if (showError) {
    return <SearchModalError />;
  }
  // we're not loading results and there is no error from searching
  if (results.length === 0 && inputValue !== '') {
    return <SearchModalNoResults />;
  }
  return results.length === 0 ? null : (
    <SearchModalResults
      results={results}
      activeResult={activeResult}
      onSelectResult={onSelectResult}
      onRowHover={onRowHover}
    />
  );
};

export default SearchModalContent;
