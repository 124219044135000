import React from 'react';
import classNames from 'clsx';
import { Icon } from '@holokit/core';

function CheckList(props) {
  const { items, selectable, active } = props;

  const iconClassNames = classNames({
    NewCheckList__Icon: true,
    selectable,
    active: selectable && active,
  });
  const icon = selectable ? 'Circle--check' : 'Checkmark--single';

  // This could easily be updated to accept "active" as an array
  // of active states, then use that instead for each item

  return (
    items &&
    items.length > 0 && (
      <ul className="NewCheckList__List">
        {items.map((item) => (
          <li key={item}>
            <Icon classes={iconClassNames} name={icon} size="minor" />
            <span>{item}</span>
          </li>
        ))}
      </ul>
    )
  );
}

export default CheckList;
