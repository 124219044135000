import analyticsEventBuilder from 'common-js/analytics';
import { ACCOUNT_LOGIN } from 'common-js/constants/paths';
import ForgotPasswordForm from 'common-js/forms/ForgotPasswordForm';
import { useEffect } from 'react';
import LoggedOutTemplate from './LoggedOutTemplate';

const ForgotPassword: React.FC = () => {
  useEffect(() => {
    analyticsEventBuilder.pageView().page('Forgot Password').send();
  }, []);

  return (
    <LoggedOutTemplate backLinkText="Log in" backLinkUrl={ACCOUNT_LOGIN}>
      <ForgotPasswordForm />
    </LoggedOutTemplate>
  );
};

export default ForgotPassword;
