import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import classNames from 'clsx';
import { DeviceUsageData } from 'common-js/types/Device';
import Moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LiveUsageTableWindowCell from 'usage/components/LiveUsageTableWindowCell';
import * as Paths from '../../../../common/constants/paths';
import { viewMoreLiveUsage } from '../../../../common/reducers/usage/actions';
import { toByteStringFormatted } from '../../../../common/utils/numberFormatter';

interface LiveUsageTableProps {
  data: Array<DeviceUsageData>;
  viewCount: number;
  viewMoreLiveUsage_: typeof viewMoreLiveUsage;
  loading?: boolean;
}

class LiveUsageTable extends React.Component<LiveUsageTableProps> {
  static getDuration(starttime, endtime) {
    const startTime = starttime.split(' ')[1];
    const endTime = endtime.split(' ')[1];
    const startMoment = Moment(startTime, 'HH:mm:ss');
    const endMoment = Moment(endTime, 'HH:mm:ss');
    const seconds = Moment(endMoment).diff(Moment(startMoment));
    return Moment.utc(seconds).format('HH:mm:ss');
  }

  render() {
    const { data, viewCount, viewMoreLiveUsage_, loading } = this.props;

    if (loading) {
      return (
        <div className="UsageTable LiveUsageTable">
          <div className="table-loading">Loading...</div>
        </div>
      );
    }

    return (
      <div className="UsageTable LiveUsageTable">
        {data.length === 0 ? (
          <div className="UsageTable-nodata">
            <Icon
              className="UsageTable-nodata-icon"
              name="CircleInfo"
              size="large"
              fill="DdsColorInteractivePrimaryDefault"
            />
            <div className="UsageTable-nodata-message">
              There are no data sessions to display for this set of devices and specific time frame.
              Try adjusting tags or the selected date range.
            </div>
          </div>
        ) : (
          <div>
            <table className="UsageTable">
              <tr className="header">
                <th style={{ width: '20%' }} className="left-padded">
                  Start / end time
                </th>
                <th>Device name / tags</th>
                <th style={{ width: '19%' }}>Active ICCID / IMEI</th>
                <th style={{ width: '9%' }}>Data usage</th>
                <th style={{ width: '19%' }}>Type</th>
                <th style={{ width: '8%' }}>Duration</th>
                <th style={{ width: '15%' }} className="right-padded">
                  Carrier / RAT
                </th>
              </tr>
              {data
                .filter((d, i) => i < viewCount)
                .map((d, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr className="body-data" key={idx}>
                    <td className="left-padded">
                      <div>{d.session_begin}</div>
                      <div className="subheader">{d.timestamp}</div>
                    </td>
                    <td>
                      <div>
                        <a
                          target="_blank"
                          href={Paths.withContext(
                            Paths.DEVICE_LANDING.replace(
                              `:${Paths.DEVICE_PARAM_NAME}`,
                              d.deviceid.toString()
                            )
                          )}
                          rel="noreferrer"
                        >
                          {d.devicename}
                        </a>
                      </div>
                      <div className="subheader">{d.tags.join(', ')}</div>
                    </td>
                    <td>
                      <div>{d.sim}</div>
                      <div className="subheader">{d.imei}</div>
                    </td>
                    <td>{toByteStringFormatted(d.bytes)}</td>
                    <td>
                      <LiveUsageTableWindowCell isWindow={d.is_reporting_window_with_usage === 1} />
                    </td>
                    <td
                      className={classNames({
                        'UsageTableCell--diminished': d.is_reporting_window_with_usage === 1,
                      })}
                    >
                      {LiveUsageTable.getDuration(d.session_begin, d.timestamp)}
                    </td>
                    <td className="right-padded">
                      <div>{d.network_name}</div>
                      <div className="subheader">{d.radio_access_technology}</div>
                    </td>
                  </tr>
                ))}
            </table>
            {viewCount < data.length && (
              <div className="load-more-container">
                <Button onClick={() => viewMoreLiveUsage_()} variant="secondary">
                  Load 50 more sessions
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    data: state.usage.charts.liveusage.data,
    loading: state.usage.charts.liveusage.loading,
    viewCount: state.usage.charts.liveusage.viewCount,
  }),
  (dispatch: any) =>
    bindActionCreators(
      {
        viewMoreLiveUsage_: viewMoreLiveUsage,
      },
      dispatch
    )
)(LiveUsageTable);
