/* eslint-disable no-param-reassign */
import { actionTypes as storageActionTypes } from 'redux-localstorage';
import { bindRedux } from 'vendor/redux-form-utils';
import * as accountActionTypes from '../reducers/account/actionTypes';
import createObjectValidator from '../utils/validation/objectValidatorFactory';
import { composeValidationMessages, scrollToFirstError } from '../utils/validation/validationUtils';

export function formGenerator(formConfig) {
  const PREFIX = `${formConfig.form.toUpperCase()}_`;

  const actionTypes = {
    START: `${PREFIX}START`,
    CANCEL: `${PREFIX}CANCEL`,
    VALIDATE: `${PREFIX}VALIDATE`,
    ERROR: `${PREFIX}ERROR`,
    RESET: `${PREFIX}RESET`,
    SUCCESS: `${PREFIX}SUCCESS`,
    CLEAR_FIELDS: `${PREFIX}CLEAR_FIELDS`,
    SET_FIELDS: `${PREFIX}SET_FIELDS`,
    FLASH: `${PREFIX}FLASH`,
  };

  const formValidator = createObjectValidator(formConfig.validators);

  const actions = {
    startSubmit: () => (dispatch) => {
      dispatch({
        type: actionTypes.START,
      });

      return Promise.resolve();
    },
    setFields: (fields) => ({
      type: actionTypes.SET_FIELDS,
      fields,
    }),
    cancelSubmit: () => ({
      type: actionTypes.CANCEL,
    }),
    validateForm:
      (form, scrollToError = false) =>
      (dispatch, getState) => {
        const validationResult = formValidator.validateObject(form);
        const validations = composeValidationMessages(
          getState().forms[formConfig.form],
          formConfig.fields,
          validationResult
        );

        dispatch({
          type: actionTypes.VALIDATE,
          validations,
        });

        if (!validationResult.isValid) {
          if (scrollToError) {
            scrollToFirstError(validations);
          }
          return Promise.reject();
        }

        return Promise.resolve();
      },
    resetState: () => ({
      type: actionTypes.RESET,
    }),
    submit: (form, HOC) => (dispatch, getState) => {
      if (formConfig.onSubmit) {
        return formConfig.onSubmit(form, actionTypes, dispatch, getState(), HOC);
      }
      return undefined;
    },
    _setServerError: (errorMessage) => ({
      type: actionTypes.ERROR,
      errorMessage,
    }),
  };

  const { state: formState, reducer: formReducer } = bindRedux(formConfig);

  const initialState = {
    showValidationErrors: false,
    formProcessing: false,
    serverError: null,
    isDirty: false,
    isSuccessful: false,
    successObject: {},
    ...formState,
  };

  // eslint-disable-next-line default-param-last
  const reducer = function formGeneratorReducer(state = initialState, action) {
    switch (action.type) {
      case storageActionTypes.INIT:
      case accountActionTypes.LOGIN_SUCCESS:
      case accountActionTypes.CONFIGURE:
        if (formConfig.initialState) {
          state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;

          return {
            ...state,
            formProcessing: false,
            form: { ...state.form, ...formConfig.initialState(state.form) },
          };
        }
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        return { ...state, formProcessing: false };

      case actionTypes.START:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        return {
          ...state,
          showValidationErrors: true,
          formProcessing: true,
          serverError: initialState.serverError,
        };
      case actionTypes.CANCEL:
      case accountActionTypes.CLEAR_MFA:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        return { ...state, formProcessing: false };
      case actionTypes.VALIDATE:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        return { ...state, form: { ...state.form, ...action.validations } };
      case actionTypes.ERROR:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        return {
          ...state,
          serverError: action.errorMessage,
          formProcessing: false,
        };
      case actionTypes.CLEAR_FIELDS:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;

        action.fields.forEach((field) => {
          state.form[field].value = '';
        });

        return { ...state, form: { ...state.form } };

      case actionTypes.SET_FIELDS:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in action.fields) {
          state.form[i].value = action.fields[i];
        }

        return { ...state, form: { ...state.form } };

      case actionTypes.FLASH:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        state.isFlashing = action.isFlashing;

        return { ...state, form: { ...state.form } };

      case actionTypes.RESET:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        if (formConfig.initialState) {
          return {
            ...state,
            ...initialState,
            form: { ...state.form, ...formConfig.initialState(state.form) },
          };
        }
        return { ...state, ...initialState };

      case accountActionTypes.LOGOUT_REQUEST:
        return { ...state, ...initialState };
      case actionTypes.SUCCESS:
        state = formConfig.customReducers ? formConfig.customReducers(state, action) : state;
        return {
          ...state,
          isDirty: false,
          formProcessing: false,
          isSuccessful: true,
          successObject: action.successObject,
        };
      case '@@form/VALUE_CHANGE':
        if (action.meta.form === formConfig.form) {
          state.isDirty = true;
        }

        if (formConfig.customReducers) {
          const newState = formReducer(state, action);

          return {
            ...newState,
            ...formConfig.customReducers(newState, action),
          };
        }
        return formReducer(state, action);

      default:
        if (formConfig.customReducers) {
          return formConfig.customReducers(state, action);
        }
        return formReducer(state, action);
    }
  };

  return {
    actions,
    reducer,
    actionTypes,
    ...formConfig,
  };
}

export default formGenerator;
