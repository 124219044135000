import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openSearchModal, closeSearchModal } from 'common-js/reducers/search/actions';
import { getModalState } from 'common-js/reducers/search/selectors';
import { CommandKey } from 'common-js/components';
import { Icon } from '@holokit/core';

class SearchButton extends React.Component<any> {
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { closeSearchModal_, isOtherModalOpened, isSearchModalOpened, openSearchModal_ } =
      this.props;
    if ((!isOtherModalOpened && e.keyCode === 75 && e.metaKey) || (e.keyCode === 75 && e.ctrlKey)) {
      e.preventDefault();
      if (isSearchModalOpened) {
        closeSearchModal_();
      } else {
        openSearchModal_();
      }
    }
  };

  render() {
    const { openSearchModal_ } = this.props;

    return (
      <button
        className="Button--unstyled GlobalSearchButton"
        onClick={openSearchModal_}
        type="submit"
      >
        <Icon
          classes="GlobalSearchButton__icon"
          name="Search"
          size="minor"
          svgProps={{ style: { fill: '#ffffff' } }}
        />
        <span className="GlobalSearchButton__text">Search...</span>
        <CommandKey className="GlobalSearchButton__keyShortcut" actionKey="K" />
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  isOtherModalOpened: state.modals.currentModal.length > 0,
  isSearchModalOpened: getModalState(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSearchModal_: openSearchModal,
      closeSearchModal_: closeSearchModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchButton);
