import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { Panel } from '@hologram-dimension/panel';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { getOrganizationsByIds, searchOrganizations } from 'common-js/api';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import {
  makeReleaseFlagPublic,
  removeReleaseFlagFromPublic,
  setReleaseFlagForOrg,
} from 'common-js/reducers/admin/actions';
import parseInt from 'lodash/parseInt';
import { ChangeEventHandler, FC, FormEventHandler, useCallback, useRef, useState } from 'react';

interface OrgRowProps {
  id: OrgId;
  name: string;
  flag: string;
  add?: boolean;
}

const OrgRow: FC<OrgRowProps> = ({ id, name, flag, add = false }) => {
  const dispatch = useAppDispatch();

  const setForOrg = useCallback(() => {
    dispatch(setReleaseFlagForOrg(id, flag, add ? 'on' : 'off'));
  }, [add, dispatch, flag, id]);

  return (
    <li className="AdminFlagPanel__organization-item">
      {name} (id: {id}){' '}
      <Button iconStart={add ? 'Plus' : 'Close'} onClick={setForOrg} variant="tertiary" />
    </li>
  );
};

interface AdminFlagPanelSearchProps {
  flag: string;
}

const AdminFlagPanelSearch: FC<AdminFlagPanelSearchProps> = ({ flag }) => {
  const timeoutIdRef = useRef<number>();

  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);

  const orgSearch = useCallback(
    (query) => {
      setLoadingSearchResults(true);

      if (!query || query === '') {
        setSearchResults([]);
        setLoadingSearchResults(false);
        return;
      }

      const orgId = parseInt(query);
      if (!Number.isNaN(orgId)) {
        getOrganizationsByIds([orgId])
          .then((result) => {
            const { orgs } = result;
            setSearchResults(orgs);
            setLoadingSearchResults(false);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });

        return;
      }

      const encoded = encodeURIComponent(query);
      searchOrganizations(encoded, false)
        .then((result) => {
          const { orgs } = result;
          setSearchResults(orgs);
          setLoadingSearchResults(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    [setSearchResults, setLoadingSearchResults]
  );

  const onOrgSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const query = e.target.value;
      // Debounce so we don't make too many API calls
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = window.setTimeout(() => {
        orgSearch(query);
      }, 300);
    },
    [orgSearch]
  );

  const preventSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();
  }, []);

  return (
    <>
      <Form onSubmit={preventSubmit}>
        <TextInputField
          id={`${flag}-search`}
          label="Add flag to organization"
          onChange={onOrgSearchChange}
        />
      </Form>
      {loadingSearchResults && 'Loading...'}
      <ul className="AdminFlagPanel__organization-list">
        {searchResults.map((r) => (
          <OrgRow key={r.id} add id={r.id} name={r.name} flag={flag} />
        ))}
      </ul>
    </>
  );
};

interface AdminFlagPanelProps {
  flag: string;
  isPublic: boolean;
  activeOrganizations: Array<any>;
}

const AdminFlagPanel: FC<AdminFlagPanelProps> = ({ flag, isPublic, activeOrganizations = [] }) => {
  const dispatch = useAppDispatch();

  const makePublic = useCallback(() => {
    dispatch(makeReleaseFlagPublic(flag));
  }, [dispatch, flag]);

  const removeFromPublic = useCallback(() => {
    dispatch(removeReleaseFlagFromPublic(flag));
  }, [dispatch, flag]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const [queryText, setQueryText] = useState('');

  const onFilterTextChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const query = e.target.value;

    setQueryText(query.toLocaleLowerCase());
  }, []);
  const activeOrgCount = activeOrganizations?.length ?? 0;
  const invalidSearch = queryText.length < 3 && queryText.length > 0;
  const queryId = queryText.length > 0 ? parseInt(queryText, 10) : null;

  const preventSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();
  }, []);

  return (
    <Panel
      className="AdminFlagPanel"
      header={flag}
      headerActions={
        <div className="AdminFlagPanel__headerActions">
          {isPublic ? (
            <>
              <b>Active for all users</b>
              <Button onClick={removeFromPublic} variant="secondary">
                Restrict to specific orgs
              </Button>
            </>
          ) : (
            <>
              <span>Only for specific organizations</span>
              <Button onClick={makePublic} variant="secondary">
                Activate for all users
              </Button>
            </>
          )}
        </div>
      }
    >
      {!isPublic && (
        <div className="AdminFlagPanel__body">
          <div className="AdminFlagPanel__search">
            <AdminFlagPanelSearch flag={flag} />
          </div>
          <div className="AdminFlagPanel__organizations">
            <div className="AdminFlagPanel__organizations-header">
              <h2>Organizations with flag ({activeOrgCount})</h2>
              <Button variant="secondary" onClick={toggleExpanded}>
                {expanded ? 'Collapse' : 'Expand'}
              </Button>
            </div>
            {activeOrgCount > 50 && (
              <Form onSubmit={preventSubmit}>
                <TextInputField
                  id={`${flag}-org-list-search`}
                  label=""
                  onChange={onFilterTextChange}
                  defaultValue={queryText}
                  placeholder="Filter organizations"
                  validationMessage={
                    invalidSearch ? 'Need at least three characters to filter' : ''
                  }
                  invalid={invalidSearch}
                />
              </Form>
            )}
            {(expanded || queryText.length >= 3) && (
              <ul className="AdminFlagPanel__organization-list AdminFlagPanel__existing-org-list">
                {queryText.length > 0
                  ? activeOrganizations
                      .filter(
                        (org) =>
                          (queryId !== null && queryId === org.id) ||
                          org.name.toLocaleLowerCase().includes(queryText)
                      )
                      .map((org) => (
                        <OrgRow key={flag + org.id} id={org.id} name={org.name} flag={flag} />
                      ))
                  : activeOrganizations.map((org) => (
                      <OrgRow key={flag + org.id} id={org.id} name={org.name} flag={flag} />
                    ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </Panel>
  );
};

export default AdminFlagPanel;
