export interface CreditCard {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  expirationmonth: string;
  expirationyear: string;
  issuer: string;
  last4: string;
  name: string;
}

export default class CreditCardModel {
  address1 = '';

  address2 = '';

  city = '';

  state = '';

  zip = '';

  expirationMonth = '';

  expirationYear = '';

  issuer = '';

  shortNumber = '';

  name = '';

  cvc = '';

  longNumber = '';

  country = '';

  hasHydrated = false;

  constructor(data?: CreditCard) {
    if (!data) return;

    this.address1 = data.address1;
    this.address2 = data.address2;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.expirationMonth = data.expirationmonth;
    this.expirationYear = data.expirationyear;
    this.issuer = data.issuer;
    this.shortNumber = data.last4;
    this.name = data.name;
    this.hasHydrated = true;
  }

  hydrateWithForm(form) {
    this.shortNumber = '';
    this.name = form.nameOnCard.value;
    this.longNumber = form.cardNumber.value;
    this.expirationMonth = form.expirationMonth.value;
    this.expirationYear = form.expirationYear.value;
    this.cvc = form.cvc.value;
    this.country = form.country.value;
    this.zip = form.zip.value;
    this.hasHydrated = true;

    return this;
  }

  hydrateMinimally(data) {
    this.name = data.name;
    this.longNumber = data.longNumber;
    this.expirationMonth = data.expirationMonth;
    this.expirationYear = data.expirationYear;
    this.cvc = data.cvc;
    this.country = data.country;
    this.zip = data.zip;
  }

  toStripesModel() {
    return {
      address_city: this.city,
      address_line1: this.address1,
      address_line2: this.address2,
      address_state: this.state,
      address_zip: this.zip,
      cvc: this.cvc,
      exp_month: this.expirationMonth,
      exp_year: this.expirationYear,
      name: this.name,
      number: this.longNumber,
    };
  }
}
