import { Message } from 'common-js/components/FormPanel';

export type SentState = 'default' | 'sending' | 'sent' | 'failed';

export const messages: Record<SentState, Message> = {
  default: {
    message: `This URL is no longer valid.`,
    messageType: 'error',
  },
  sent: {
    message: 'Check your inbox for a verification email.',
    messageType: 'success',
  },
  failed: {
    message: 'Failed to resend verification email.',
    messageType: 'error',
  },
  sending: {
    message: '',
    messageType: 'error',
  },
};
export const submitButtonText = 'Resend verification email';
