import { round, isNull } from 'lodash';

const toByteStringInternal = (amount: number, roundToDecimal = 2, returnObject = false) => {
  let byteValue = '';
  let unit = '';
  const num = Number(amount);
  if (num < 1000) {
    byteValue = round(num * 1, roundToDecimal).toString();
    unit = 'B';
  } else if (num >= 1000 && num < 1000000) {
    byteValue = round(num / 1000, roundToDecimal).toString();
    unit = 'KB';
  } else if (num >= 1000000 && num < 1000000000) {
    byteValue = round(num / 1000000, roundToDecimal).toString();
    unit = 'MB';
  } else if (num >= 1000000000) {
    byteValue = round(num / 1000000000, roundToDecimal).toString();
    unit = 'GB';
  }
  return returnObject ? { byteValue, unit } : `${byteValue}  ${unit}`;
};

/**
 * Format an amount in bytes
 * @param amount - Amount in bytes to format
 * @param roundToDecimal - Number of decimal points to round to after
 * @param returnObject - To return an object or not
 * @deprecated Use toByteStringObject or toByteStringFormatted
 */
export const toByteString = (amount: number, roundToDecimal = 2, returnObject = false) =>
  toByteStringInternal(amount, roundToDecimal, returnObject);

/**
 * Format an amount in bytes
 * @param amount - Amount in bytes to format
 * @param roundToDecimal - Number of decimal points to round to after conversion
 */
export const toByteStringObject = (amount: number, roundToDecimal = 2) =>
  toByteStringInternal(amount, roundToDecimal, true) as {
    byteValue: string;
    unit: string;
  };

/**
 * Format an amount in bytes
 * @param amount - Amount in bytes to format
 * @param roundToDecimal - Number of decimal points to round to after conversion
 */
export const toByteStringFormatted = (amount: number, roundToDecimal = 2) =>
  toByteStringInternal(amount, roundToDecimal) as string;

export const getBytePostfix = (amount) => {
  if (amount < 1000) return 'B';
  if (amount >= 1000 && amount < 1000000) return 'KB';
  if (amount >= 1000000 && amount < 1000000000) return 'MB';
  if (amount >= 1000000000) return 'GB';
  return '';
};

export const addCommasToNumber = (amount: any, currency = true) => {
  // This is very important to leave, since the behavior of Number.isNaN is different
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(amount) || isNull(amount)) return '-';
  if (currency) {
    const splitTotal = String(amount).split('.');
    const dollarsWithCommas = Number(splitTotal[0]).toLocaleString();
    const cents = String(parseFloat(amount).toFixed(2)).split('.')[1];
    return `${dollarsWithCommas}.${cents}`;
  }
  return Number(amount).toLocaleString();
};

export const getLimitValue = (usageLimitObject: UsageLimitObject, planDataLimit: number) => {
  const { type, amount, unit } = usageLimitObject;

  switch (type) {
    case 'equal':
      return toByteStringFormatted(planDataLimit);
    case '1mb':
      return toByteStringFormatted(planDataLimit + 1000000, 3);
    case '1.5mb':
      return toByteStringFormatted(planDataLimit + 1500000, 3);
    case '5mb':
      return toByteStringFormatted(planDataLimit + 5000000, 3);
    case '10mb':
      return toByteStringFormatted(planDataLimit + 10000000, 3);
    case 'unlimited':
      return 'Never';
    case 'custom':
      return `${amount} ${unit}`;
    default:
      return '';
  }
};
