import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataUsedChart from './DataUsedChart';

interface DataUsedChartWrapperProps {
  data?: Array<{ datausage: string }>;
  loading?: boolean;
  error?: any;
  fromInspect?: boolean;
  loadingHeight?: number;
}
const DataUsedChartWrapper = ({
  loading = false,
  error = null,
  data = [],
  loadingHeight = 170,
  fromInspect = false,
}: DataUsedChartWrapperProps) => (
  <DataUsedChart
    data={data}
    loading={loading}
    error={error}
    loadingHeight={loadingHeight}
    fromInspect={fromInspect}
  />
);

export default connect(
  (state: any) => ({
    loading: state.usage.charts.dataused.loading,
    error: state.usage.charts.dataused.error,
    data: state.usage.charts.dataused.data,
  }),
  (dispatch: any) => bindActionCreators({}, dispatch)
)(DataUsedChartWrapper);
