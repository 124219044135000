import React from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button, Callout, ComplexIcon, Icon, Tooltip } from '@holokit/core';
import { openModal } from 'common-js/reducers/modal/actions';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import {
  selectCurrentDevice,
  selectCurrentDeviceEuiccHistory,
  selectLiveUsage,
} from 'common-js/reducers/currentDevice/selectors';

const euiccState2Hyper = {
  TEST_PENDING: {
    iconColor: '#A3AEB9',
    status: 'Pending Compatibility Test',
    tooltip:
      'You or a team member have requested that Hologram Success run the Hyper hardware compatibility tests. These tests will be run remotely, and results will be provided by email in a few days. ',
  },
  ERROR: {
    iconColor: '#A80000',
    status: 'Errors Found',
    tooltip:
      'During our tests, we discovered errors that prevented this hardware from responding properly to eUICC commands. Hologram Success will be in touch by email with details and next steps.',
  },
  RUNNING: {
    iconColor: '#A3AEB9',
    status: 'Compatibility Test In-Progress',
    tooltip: 'The test is currently running, we will get back to you once it completes',
  },
  WARNING: {
    iconColor: '#A80000',
    status: 'Compatibility Warning',
    tooltip:
      'During our tests, your device successfully completed at least one full cycle of eUICC actions but encountered errors in other cycles. Hologram Success will be in touch by email with more details and next steps.',
  },
  COMPLETE: {
    status: 'Compatible',
    iconColor: '#A3AEB9',
    tooltip:
      'This device was tested and passed all of our hardware compatibility tests over multiple cycles of downloading and enabling connectivity eUICC profiles.',
  },
  CAPABLE: {
    iconColor: '#A3AEB9',
    status: 'Capable',
    tooltip:
      'This device has a Hyper capable eUICC SIM card installed. No Hyper hardware compatibility tests have been run with this device yet. Request a compatibility test to validate full Hyper services.',
  },
};

const HyperHardware = () => {
  const usage = useAppSelector(selectLiveUsage);
  const device = useAppSelector(selectCurrentDevice);
  const euiccHistory = useAppSelector(selectCurrentDeviceEuiccHistory);
  const releaseFlags = useAppSelector(getReleaseFlags);
  const dispatch = useAppDispatch();

  const renderTestButton = () => {
    let type = 'primary';
    let disabled = false;
    let text = 'Request Hyper Compatibility Test';

    switch (device.euicc_tested) {
      case 'TEST_PENDING':
      case 'RUNNING':
        type = 'secondary';
        disabled = true;
        text = 'Pending Test';
        break;
      case 'COMPLETE':
      case 'ERROR':
      case 'WARNING':
        type = 'secondary';
        text = 'Request New Compatibility Test';
        break;
      default:
        break;
    }

    return (
      <Button
        disabled={disabled}
        iconLeft="Chip"
        onClick={() => {
          dispatch(openModal('RequestHyperCompatTestModal', { device }, 'narrow'));
        }}
        type={type}
      >
        {text}
      </Button>
    );
  };

  const hardware = device.model ? device.model : '—';
  const tac = device.imei ? String(device.imei).substring(0, 8) : '—';
  const lastEUICCResult = euiccHistory.find((event) => event.action === 'EUICC_TEST');
  const isRollbackPending =
    lastEUICCResult && lastEUICCResult.metadata && lastEUICCResult.metadata.is_rollback_pending;
  const hyperState = isRollbackPending
    ? euiccState2Hyper.RUNNING
    : euiccState2Hyper[device.euicc_tested] || euiccState2Hyper.CAPABLE;

  const callouts = (
    <>
      {(Object.keys(device.lastsession).length > 0 ||
        (!device.euicc_tested && usage?.length === 0)) && (
        <Callout
          type={Callout.TYPE.WARNING}
          text="Please ensure your device has successfully connected to the internet with this SIM prior to requesting a compatibility test."
          defaultIcon
          iconColor={Callout.THEME.COLOR}
          className="HyperHardware__inline-alert"
        />
      )}
      {(device.euicc_tested === 'TEST_PENDING' ||
        device.euicc_tested === 'RUNNING' ||
        isRollbackPending) && (
        <Callout
          type={Callout.TYPE.INFORMATIONAL}
          text="Keep your device powered on. We'll run the compatibility test in the next few days and email you the results."
          defaultIcon
          iconColor={Callout.THEME.COLOR}
          className="HyperHardware__inline-alert"
        />
      )}
    </>
  );

  return (
    <div className="HyperHardware">
      <div className="Panel__info HyperPanel__info">
        {!releaseFlags.hyper_sdp_improvements && callouts}

        <div className="HyperPanel__row">
          <div className="HyperPanel__row__item">
            <div className="HyperPanel__data-label">Hardware</div>
            <div className="HyperPanel__data-value">{hardware}</div>
          </div>

          <div className="HyperPanel__row__item">
            <div className="HyperPanel__data-label">IMEI TAC</div>
            <div className="HyperPanel__data-value">{tac}</div>
          </div>

          {!releaseFlags.hyper_sdp_improvements && (
            <>
              <div className="HyperPanel__row__item">
                <div className="HyperPanel__data-label">Hyper Status</div>
                <div className="HyperPanel__data-value HyperPanel__data-value--has-tooltip">
                  {device.euicc_tested === 'COMPLETE' && !isRollbackPending ? (
                    <ComplexIcon
                      classes="HyperPanel__inline-icon HyperPanel__inline-icon--complex"
                      name="hyper-minor"
                    />
                  ) : (
                    <Icon
                      classes="HyperPanel__inline-icon"
                      name="Hyper"
                      size="major"
                      svgProps={{ style: { fill: hyperState.iconColor } }}
                    />
                  )}
                  <span className="HyperPanel__tooltipped">{hyperState.status}</span>
                  <Tooltip
                    content={hyperState.tooltip}
                    title={`Hyper Status: ${hyperState.status}`}
                  >
                    <Icon
                      name="Circle--info"
                      size="minor"
                      svgProps={{ style: { fill: '#8008f7' } }}
                    />
                  </Tooltip>
                </div>
              </div>

              <div className="HyperPanel__row__item HyperPanel__row__item--button">
                {renderTestButton()}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HyperHardware;
