import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import TopMenu from 'common-js/components/menu/TopMenu';
import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import * as Paths from 'common-js/constants/paths';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import * as Permissions from 'common-js/utils/permissions';

const SubMenu = (): JSX.Element => {
  const userPermissions = useAppSelector(getUserPermissions);
  const hasBillingVisible = userPermissions.includes(Permissions.BILLING_VISIBLE);

  return (
    <TopMenu
      titleHref={Paths.withContext(Paths.SETTINGS_ORG)}
      rightContent={<SubMenuRightContent />}
    >
      <TopMenu.Item
        text="This Organization"
        active={Paths.pathMatches(Paths.SETTINGS_ORG)}
        href={Paths.withContext(Paths.SETTINGS_ORG)}
      />
      <TopMenu.Item
        text="Subscription"
        active={Paths.pathMatches(Paths.SETTINGS_SUBSCRIPTION)}
        href={Paths.withContext(Paths.SETTINGS_SUBSCRIPTION)}
      />
      <TopMenu.Item
        text="Your Account"
        active={Paths.pathMatches(Paths.SETTINGS_ACCOUNT)}
        href={Paths.withContext(Paths.SETTINGS_ACCOUNT)}
      />
      {hasBillingVisible && (
        <TopMenu.Item
          text="API"
          active={Paths.pathMatches(Paths.SETTINGS_API)}
          href={Paths.withContext(Paths.SETTINGS_API)}
        />
      )}
    </TopMenu>
  );
};

export default SubMenu;
