import * as alertTypes from '../../constants/alertTypes';
import * as accountActionTypes from '../account/actionTypes';
import * as actionTypes from './actionTypes';

const initialDataSubTree = {
  activeAlert: null,
  count: 0,
  data: [],
  hasFetched: false,
  mapData: {},
  activePin: null,
  error: null,
  filterData: {
    offset: 0,
    continues: null,
  },
  loading: true,
};

export const LOADMORE_PAGELENGTH = 50;

const initialState = {
  deviceDataLimits: { ...initialDataSubTree },
  deviceUsage: { ...initialDataSubTree },
  tacChanges: { ...initialDataSubTree },
};

export const ALERT_TYPE_TO_STATE = {
  [alertTypes.DEVICE_DATA_LIMIT]: 'deviceDataLimits',
  [alertTypes.DEVICE_USAGE]: 'deviceUsage',
  [alertTypes.TAC_CHANGE]: 'tacChanges',
};

const buildMapData = (res, alert) => {
  // we don't always return with map data, make sure to ignore when we don't have it
  if (alert.longitude) {
    return [
      ...res,
      {
        type: 'Feature',
        properties: {
          name: alert.deviceName,
          id: alert.id,
        },
        geometry: {
          type: 'Point',
          coordinates: [alert.longitude, alert.latitude, 0.0],
        },
      },
    ];
  }
  return res;
};

export default function alertsReducer(state = initialState, action) {
  switch (action.type) {
    case accountActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      };

    case actionTypes.GET_ALERTS_BY_TYPE_REQUEST: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          loading: true,
          error: null,
        },
      };
    }

    case actionTypes.GET_ALERTS_BY_TYPE_SUCCESS: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      const mapFeatures = action.data.reduce(buildMapData, []);
      const existingMapFeatures = state[stateSection].mapData.features || [];
      const mapData = {
        type: 'FeatureCollection',
        features: [...existingMapFeatures, ...mapFeatures],
      };

      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          loading: false,
          error: null,
          data: [...state[stateSection].data, ...action.data],
          hasFetched: true,
          mapData,
          filterData: {
            ...state[stateSection].filterData,
            continues: action.continues,
            offset: action.offset,
          },
        },
      };
    }

    case actionTypes.GET_ALERTS_BY_TYPE_ERROR: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          loading: false,
          error: action.error,
        },
      };
    }

    case actionTypes.GET_ALERT_COUNT_BY_TYPE_SUCCESS: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          loading: false,
          error: null,
          count: action.count,
        },
      };
    }

    case actionTypes.GET_ALERT_COUNT_BY_TYPE_ERROR:
    case actionTypes.ACKNOWLEDGE_ALERT_ERROR:
    case actionTypes.ACKNOWLEDGE_ALERTS_ERROR:
    case actionTypes.ACKNOWLEDGE_ALL_ALERTS_ERROR:
    case actionTypes.UNACKNOWLEDGE_ALERT_ERROR:
    case actionTypes.PAUSE_ALERT_DEVICES_ERROR:
    case actionTypes.RESUME_ALERT_BULK_ERROR:
    case actionTypes.RESUME_ALERT_DEVICE_ERROR: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          loading: false,
          error: action.error,
        },
      };
    }

    case actionTypes.ACKNOWLEDGE_ALERT_REQUEST:
    case actionTypes.ACKNOWLEDGE_ALERTS_REQUEST: {
      const alertIds = action.acknowledgedAlertIds;
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      const currentState = state[stateSection].data;
      const activeAlertAcknowledged = alertIds.includes(state[stateSection].activeAlert);

      const updatedAlerts = currentState.map((a) => {
        if (alertIds.includes(a.id)) {
          return {
            ...a,
            acked: true,
          };
        }
        return a;
      });
      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          activeAlert: activeAlertAcknowledged ? '' : state[stateSection].activeAlert,
          data: updatedAlerts,
          loading: false,
          count: state[stateSection].count - action.acknowledgedAlertIds.length,
        },
      };
    }

    case actionTypes.ACKNOWLEDGE_ALL_ALERTS_SUCCESS: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      const currentState = state[stateSection].data;

      const updatedAlerts = currentState.map((alert) => ({
        ...alert,
        acked: true,
      }));

      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          activeAlert: state[stateSection].activeAlert,
          data: updatedAlerts,
          loading: false,
          count: 0, // this count needs to be hardcoded to zero as there's no way to do state[stateSection].count - action.acknowledgedAlertIds.length with unloaded alerts
        },
      };
    }

    case actionTypes.CLEAR_ACKNOWLEDGED_ALERTS: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      const currentState = state[stateSection].data;
      const mapState = state[stateSection].mapData.features;

      const ackedAlertIds = [];
      const newAlerts = [];

      currentState.forEach((a) => {
        if (a.acked) {
          ackedAlertIds.push(a.id);
        } else {
          newAlerts.push(a);
        }
      });

      const updatedMapFeatures = mapState.reduce((res, data) => {
        if (!ackedAlertIds.includes(data.properties.id)) {
          return [...res, data];
        }
        return res;
      }, []);

      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          data: newAlerts,
          mapData: {
            ...state[stateSection].mapData,
            features: updatedMapFeatures,
          },
        },
      };
    }

    case actionTypes.UNACKNOWLEDGE_ALERT_REQUEST: {
      const { alertId } = action;
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      const currentState = state[stateSection].data;
      const updatedState = currentState.map((a) => {
        if (a.id === alertId) {
          return {
            ...a,
            acked: false,
          };
        }

        return a;
      });

      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          data: updatedState,
          count: state[stateSection].count + 1,
          loading: false,
        },
      };
    }

    case actionTypes.RESUME_ALERT_DEVICE_REQUEST:
    case actionTypes.RESUME_ALERT_BULK_REQUEST:
    case actionTypes.PAUSE_ALERT_DEVICES_REQUEST: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];

      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          loading: true,
        },
      };
    }

    case actionTypes.RESUME_ALERT_DEVICE_SUCCESS:
    case actionTypes.RESUME_ALERT_BULK_SUCCESS:
    case actionTypes.PAUSE_ALERT_DEVICES_SUCCESS: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];

      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          data: action.alerts,
          loading: false,
        },
      };
    }

    case actionTypes.DEACTIVATE_ALERT_DEVICES_SUCCESS:
      return {
        ...state,
        tacChanges: {
          ...state.tacChanges,
          data: action.alerts,
          loading: false,
          selectedAlerts: [],
        },
      };

    case actionTypes.SET_ACTIVE_ALERT: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          activeAlert: action.alertId,
        },
      };
    }

    case actionTypes.SET_ACTIVE_PIN: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      return {
        ...state,
        [stateSection]: {
          ...state[stateSection],
          activePin: action.alertId,
        },
      };
    }

    case actionTypes.CLEAR_ALERTS_BY_TYPE: {
      const stateSection = ALERT_TYPE_TO_STATE[action.alertType];
      return {
        ...state,
        [stateSection]: { ...initialDataSubTree },
      };
    }

    default:
      return state;
  }
}
