import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Panel } from '@holokit/core';
import { getSelectedOrg } from '../../../common/reducers/organization/selectors';
import { OrgModel } from '../../../common/models';

function OrgIdPanel(props) {
  const { selectedOrg } = props;

  return (
    <div className="OrgIdPanel">
      <Panel>
        <div>
          {selectedOrg.isMyAccount && 'Personal '}Organization ID {selectedOrg.id}
        </div>
      </Panel>
    </div>
  );
}

export default connect(
  (state) => ({
    selectedOrg: getSelectedOrg(state) || new OrgModel(),
  }),
  (dispatch) => bindActionCreators({}, dispatch)
)(OrgIdPanel);
