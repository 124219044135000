import React from 'react';
import { ACTIVATING_PREFLIGHT_HELP } from 'common-js/constants/links';
import SimError from './SimError';

function InvalidatedPreflightSims({ sims }) {
  const firstSim = sims[0];
  const simsMessage = [
    firstSim,
    'and',
    sims.length - 1,
    'other',
    sims.length > 2 ? 'SIMs' : 'SIM',
  ].join(' ');
  const message = (
    <>
      <b className="InvalidatedPreflightSims__simCallout">
        {sims.length > 1 ? simsMessage : firstSim}
      </b>{' '}
      {sims.length > 1 ? 'are' : 'is'} configured for pre-deployment testing and cannot be activated
      in this step.{' '}
      <a
        className="InvalidatedPreflightSims__helpLink"
        href={ACTIVATING_PREFLIGHT_HELP}
        target="_blank"
        rel="noreferrer"
      >
        Learn how to activate these SIMs
      </a>
      .
    </>
  );
  return <SimError error={message} />;
}

export default InvalidatedPreflightSims;
