const PREFIX = 'ORGS_' as const;

export const GET_ALL_REQUEST = `${PREFIX}GET_ALL_REQUEST` as const;
export const GET_ALL_SUCCESS = `${PREFIX}GET_ALL_SUCCESS` as const;
export const GET_ALL_ERROR = `${PREFIX}GET_ALL_ERROR` as const;

export const CREATE_ORG_REQUEST = `${PREFIX}CREATE_ORG_REQUEST` as const;
export const CREATE_ORG_SUCCESS = `${PREFIX}CREATE_ORG_SUCCESS` as const;
export const CREATE_ORG_ERROR = `${PREFIX}CREATE_ORG_ERROR` as const;

export const CONVERT_PERSONAL_TO_ORG_REQUEST = `${PREFIX}CONVERT_PERSONAL_TO_ORG_REQUEST` as const;
export const CONVERT_PERSONAL_TO_ORG_SUCCESS = `${PREFIX}CONVERT_PERSONAL_TO_ORG_SUCCESS` as const;
export const CONVERT_PERSONAL_TO_ORG_ERROR = `${PREFIX}CONVERT_PERSONAL_TO_ORG_ERROR` as const;

export const UPDATE_ORG_REQUEST = `${PREFIX}UPDATE_ORG_REQUEST` as const;
export const UPDATE_ORG_SUCCESS = `${PREFIX}UPDATE_ORG_SUCCESS` as const;
export const UPDATE_ORG_ERROR = `${PREFIX}UPDATE_ORG_ERROR` as const;

export const ADD_USER_REQUEST = `${PREFIX}ADD_USER_REQUEST` as const;
export const ADD_USER_SUCCESS = `${PREFIX}ADD_USER_SUCCESS` as const;
export const ADD_USER_ERROR = `${PREFIX}ADD_USER_ERROR` as const;

export const UPDATE_PERMISSIONS_REQUEST = `${PREFIX}UPDATE_PERMISSIONS_REQUEST` as const;
export const UPDATE_PERMISSIONS_SUCCESS = `${PREFIX}UPDATE_PERMISSIONS_SUCCESS` as const;
export const UPDATE_PERMISSIONS_ERROR = `${PREFIX}UPDATE_PERMISSIONS_ERROR` as const;

export const GET_ALL_BILLING_REQUEST = `${PREFIX}GET_ALL_BILLING_REQUEST` as const;
export const GET_ALL_BILLING_SUCCESS = `${PREFIX}GET_ALL_BILLING_SUCCESS` as const;
export const GET_ALL_BILLING_ERROR = `${PREFIX}GET_ALL_BILLING_ERROR` as const;

export const ADD_BALANCE_REQUEST = `${PREFIX}ADD_BALANCE_REQUEST` as const;
export const ADD_BALANCE_SUCCESS = `${PREFIX}ADD_BALANCE_SUCCESS` as const;
export const ADD_BALANCE_ERROR = `${PREFIX}ADD_BALANCE_ERROR` as const;

export const GET_BALANCEHISTORY_REQUEST = `${PREFIX}GET_BALANCEHISTORY_REQUEST` as const;
export const GET_BALANCEHISTORY_SUCCESS = `${PREFIX}GET_BALANCEHISTORY_SUCCESS` as const;
export const GET_BALANCEHISTORY_ERROR = `${PREFIX}GET_BALANCEHISTORY_ERROR` as const;

export const GET_BILLING_ADDRESS = `${PREFIX}GET_BILLING_ADDRESS` as const;
export const GET_BILLING_ADDRESS_SUCCESS = `${PREFIX}GET_BILLING_ADDRESS_SUCCESS` as const;
export const GET_BILLING_ADDRESS_ERROR = `${PREFIX}GET_BILLING_ADDRESS_ERROR` as const;

export const GET_BILLING_INFORMATION = `${PREFIX}GET_BILLING_INFORMATION` as const;
export const GET_BILLING_INFORMATION_SUCCESS = `${PREFIX}GET_BILLING_INFORMATION_SUCCESS` as const;
export const GET_BILLING_INFORMATION_ERROR = `${PREFIX}GET_BILLING_INFORMATION_ERROR` as const;

export const UPDATE_BALANCE_PARAMS_REQUEST = `${PREFIX}UPDATE_BALANCE_PARAMS_REQUEST` as const;
export const UPDATE_BALANCE_PARAMS_SUCCESS = `${PREFIX}UPDATE_BALANCE_PARAMS_SUCCESS` as const;
export const UPDATE_BALANCE_PARAMS_ERROR = `${PREFIX}UPDATE_BALANCE_PARAMS_ERROR` as const;

export const RESEND_INVITE_REQUEST = `${PREFIX}RESEND_INVITE_REQUEST` as const;
export const RESEND_INVITE_SUCCESS = `${PREFIX}RESEND_INVITE_SUCCESS` as const;
export const RESEND_INVITE_ERROR = `${PREFIX}RESEND_INVITE_ERROR` as const;

export const CANCEL_INVITE_REQUEST = `${PREFIX}CANCEL_INVITE_REQUEST` as const;
export const CANCEL_INVITE_SUCCESS = `${PREFIX}CANCEL_INVITE_SUCCESS` as const;
export const CANCEL_INVITE_ERROR = `${PREFIX}CANCEL_INVITE_ERROR` as const;

export const REMOVE_USERS_REQUEST = `${PREFIX}REMOVE_USERS_REQUEST` as const;
export const REMOVE_USERS_SUCCESS = `${PREFIX}REMOVE_USERS_SUCCESS` as const;
export const REMOVE_USERS_ERROR = `${PREFIX}REMOVE_USERS_ERROR` as const;

export const AUTO_JOIN_ORG_REQUEST = `${PREFIX}AUTO_JOIN_ORG_REQUEST` as const;
export const AUTO_JOIN_ORG_SUCCESS = `${PREFIX}AUTO_JOIN_ORG_SUCCESS` as const;
export const AUTO_JOIN_ORG_ERROR = `${PREFIX}AUTO_JOIN_ORG_ERROR` as const;

export const GET_BALANCE_REQUEST = `${PREFIX}GET_BALANCE_REQUEST` as const;
export const GET_BALANCE_SUCCESS = `${PREFIX}GET_BALANCE_SUCCESS` as const;
export const GET_BALANCE_ERROR = `${PREFIX}GET_BALANCE_ERROR` as const;

export const GET_ORGGROUPS_REQUEST = `${PREFIX}GET_ORGGROUPS_REQUEST` as const;
export const GET_ORGGROUPS_SUCCESS = `${PREFIX}GET_ORGGROUPS_SUCCESS` as const;
export const GET_ORGGROUPS_ERROR = `${PREFIX}GET_ORGGROUPS_ERROR` as const;

export const GET_DEVICE_COUNT_REQUEST = `${PREFIX}GET_DEVICE_COUNT_REQUEST` as const;
export const GET_DEVICE_COUNT_SUCCESS = `${PREFIX}GET_DEVICE_COUNT_SUCCESS` as const;
export const GET_DEVICE_COUNT_ERROR = `${PREFIX}GET_DEVICE_COUNT_ERROR` as const;

export const GET_TOTAL_DEVICE_COUNT_REQUEST = `${PREFIX}GET_TOTAL_DEVICE_COUNT_REQUEST` as const;
export const GET_TOTAL_DEVICE_COUNT_SUCCESS = `${PREFIX}GET_TOTAL_DEVICE_COUNT_SUCCESS` as const;
export const GET_TOTAL_DEVICE_COUNT_ERROR = `${PREFIX}GET_TOTAL_DEVICE_COUNT_ERROR` as const;

export const GET_PLANDEVICE_SUMMARY_REQUEST = `${PREFIX}GET_PLANDEVICE_SUMMARY_REQUEST` as const;
export const GET_PLANDEVICE_SUMMARY_SUCCESS = `${PREFIX}GET_PLANDEVICE_SUMMARY_SUCCESS` as const;
export const GET_PLANDEVICE_SUMMARY_ERROR = `${PREFIX}GET_PLANDEVICE_SUMMARY_ERROR` as const;

export const CHANGE_CONTEXT = `${PREFIX}CHANGE_CONTEXT` as const;

export const PUT_UPGRADE_REQUEST = `${PREFIX}PUT_UPGRADE_REQUEST` as const;
export const PUT_UPGRADE_SUCCESS = `${PREFIX}PUT_UPGRADE_SUCCESS` as const;
export const PUT_UPGRADE_ERROR = `${PREFIX}PUT_UPGRADE_ERROR` as const;

export const DEVICES_SAMPLE_REQUEST = `${PREFIX}DEVICES_SAMPLE_REQUEST` as const;
export const DEVICES_SAMPLE_SUCCESS = `${PREFIX}DEVICES_SAMPLE_SUCCESS` as const;
export const DEVICES_SAMPLE_ERROR = `${PREFIX}DEVICES_SAMPLE_ERROR` as const;

export const GET_BILLING_STATEMENTS_SUCCESS = `${PREFIX}GET_BILLING_STATEMENTS_SUCCESS` as const;
export const GET_INVOICES_SUCCESS = `${PREFIX}GET_INVOICES_SUCCESS` as const;
