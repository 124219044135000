/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
// this file needs to be linted, but I want to write a test first to
// make sure the nested terinaries get removed right. Will do this in a follow up PR.

import { Button, ComplexIcon, Icon } from '@holokit/core';
import { MessagePanel, RadioSwitch, TagComplete, Topic } from 'common-js/components';
import * as Paths from 'common-js/constants/paths';
import * as appsActions from 'common-js/reducers/apps/actions';
import * as messageActions from 'common-js/reducers/message/actions';
import * as modalActions from 'common-js/reducers/modal/actions';
import { getTopics } from 'common-js/reducers/topic/selectors';
import Formsy from 'formsy-react19';
import _ from 'lodash';
import Moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { generate as generateId } from 'shortid';
import { AceEditorInput, Input, ListInput, TimeDurationInput, TopicInput } from './inputs';

const INPUT_PROP_SEPARATOR = '_*_';

class AppForm extends React.Component {
  static buildAppChainFromFromValues(formValues) {
    const appChain = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const formKey in formValues) {
      if (formKey.includes(INPUT_PROP_SEPARATOR)) {
        const keyTokens = formKey.split(INPUT_PROP_SEPARATOR);
        let appName = keyTokens[0];
        let appIndex;
        let valueName;

        if (appName === 'action') {
          // eslint-disable-next-line prefer-destructuring
          valueName = keyTokens[1];
        } else {
          appIndex = parseInt(keyTokens[1], 10);
          // eslint-disable-next-line prefer-destructuring
          valueName = keyTokens[2];
          appName += appIndex;
        }

        const existingApp = _.find(appChain, { name: appName });

        if (!existingApp) {
          appChain.push({
            name: appName,
            index: appIndex,
            values: { [valueName]: formValues[formKey] },
          });
        } else {
          existingApp.values[valueName] = formValues[formKey];
          existingApp.index = appIndex;
        }
      }
    }

    return appChain;
  }

  static getAppValue(app, property, valueIfNull = null) {
    if (app.values && app.values[property]) return app.values[property];
    return valueIfNull;
  }

  static onInvalidSubmit() {
    setTimeout(() => {
      const errors = document.querySelectorAll('.inline-error');

      if (errors.length > 0) {
        const firstError = document.querySelectorAll('.inline-error')[0].parentNode;
        firstError.offsetParent.scrollTop = firstError.offsetTop - 150;
        firstError.querySelector('input').focus();
      }
    }, 16);
  }

  constructor(props) {
    super(props);

    this.state = {
      hasSubmitted: false,
      isUpserting: false,
      isDeleting: false,
      deleteError: null,
      upsertError: null,
      createEnabled: true,
      isEditingSelectedTopics: props.selectedTopics.length === 0,
      isUpdatingStatus: false,
    };

    this.onFormChange = _.debounce(this.onFormChange, 50);
  }

  onChangeEnabled() {
    const { actionApp, isEditingApp, operationApps, disableApp, enableApp, pushGlobalMessage } =
      this.props;
    const { createEnabled } = this.state;

    if (isEditingApp) {
      const appIds = operationApps.map((app) => app.appId).concat([actionApp.appId]);

      this.setState({ isUpdatingStatus: true });
      (actionApp.enabled ? disableApp(appIds) : enableApp(appIds))
        .then(() => this.setState({ isUpdatingStatus: false }))
        .catch((e) => {
          this.setState({ isUpdatingStatus: false });
          pushGlobalMessage(e, 'error');
        });
    } else {
      this.setState({ createEnabled: !createEnabled });
    }
  }

  // eslint-disable-next-line no-unused-vars
  onChangeMatchAllTopics(selected) {
    const { setMatchAllTopics, matchAllTopics } = this.props;
    setMatchAllTopics(!matchAllTopics);
  }

  onRemoveSelectedTopic(topic) {
    const { removeSelectedTopic, selectedTopics } = this.props;
    removeSelectedTopic(topic);
    if (selectedTopics.length <= 1) this.setState({ isEditingSelectedTopics: true });
  }

  onFormChange(formValues) {
    const { updateChainChangeValues } = this.props;
    updateChainChangeValues(AppForm.buildAppChainFromFromValues(formValues), formValues.nickname);
  }

  onOperationSchemaRemove(indexOfOperation) {
    const { removeOperationApp } = this.props;
    removeOperationApp(indexOfOperation);
  }

  onDeleteApp() {
    if (!window.confirm('Are you sure you want to delete this route?')) return;

    const { deleteApp, actionApp, operationApps } = this.props;

    this.setState({ isDeleting: true });

    deleteApp(operationApps.map((app) => app.appId).concat([actionApp.appId]))
      .then(() => {
        this.setState({ isDeleting: false });
        browserHistory.push(Paths.APPS_OVERVIEW);
      })
      .catch(() => {
        this.setState({ isDeleting: false });
      });
  }

  onSuccessfulSubmit() {
    const CALL_MIN_TIME = 500;
    const then = Date.now();

    if (!this.validateAllAppsPresent()) return;

    const { addApp, editApp, isEditingApp, pushGlobalMessage } = this.props;
    const { createEnabled } = this.state;
    const serializableAppChain = this.getSerializableAppChain();

    this.setState({ isUpserting: true });

    if (isEditingApp) {
      editApp(serializableAppChain)
        .then(() => {
          const durationOfCall = Date.now() - then;

          if (durationOfCall < CALL_MIN_TIME)
            setTimeout(() => {
              this.setState({ isUpserting: false });
            }, CALL_MIN_TIME - durationOfCall);
          else this.setState({ isUpserting: false });

          pushGlobalMessage('Route successfully updated.', 'success');
        })
        .catch(() => this.setState({ isUpserting: false }));
    } else {
      addApp(serializableAppChain, createEnabled)
        .then(() => {
          const durationOfCall = Date.now() - then;

          if (durationOfCall < CALL_MIN_TIME)
            setTimeout(() => {
              this.setState({ isUpserting: false });
            }, CALL_MIN_TIME - durationOfCall);
          else this.setState({ isUpserting: false });

          browserHistory.push(Paths.APPS_OVERVIEW);
        })
        .catch(() => this.setState({ isUpserting: false }));
    }
  }

  getSchemaById(schemaId) {
    const { appSchemas } = this.props;
    return _.find(appSchemas, { id: schemaId });
  }

  getSerializableAppChain() {
    // we need to tie together all the individual apps into a "chain" of apps bound together by pub/subbed topics
    const {
      selectedTopics = [],
      operationApps,
      actionApp,
      nickname,
      appChainId,
      isEditingApp,
      matchAllTopics,
    } = this.props;
    const thisThis = this;
    const actionChainId = isEditingApp ? appChainId : generateId();

    const stagedAppsToSave = operationApps.map((opApp) => {
      const publishTo = opApp.values.publish_to;
      const defaultTopicPrefix = _.find(thisThis.getSchemaById(opApp.schemaId).userpropertieslist, {
        property: 'publish_to',
      }).default_topic_prefix;

      if (!publishTo || (publishTo && publishTo.length === 0)) {
        // eslint-disable-next-line no-param-reassign
        opApp.values.publish_to = [`${defaultTopicPrefix}_${opApp.defaultRandom}`];
      }

      return { ...opApp };
    });

    stagedAppsToSave.push({ ...actionApp, type: 'action' });

    const appChain = stagedAppsToSave.map((app, idx) => {
      let userprops = {
        action_chain_id: actionChainId,
        action_chain_index: idx,
      };

      if (idx === 0) {
        userprops = { ...userprops, ...app.values };

        return {
          appId: app.appId,
          enabled: false,
          csrappid: app.schemaId,
          nickname,
          match_all_tags: matchAllTopics,
          tags: selectedTopics.map((sT) => sT.name),
          userproperties: JSON.stringify(userprops), // action_chain_id is a way for us to reconstruct the chain on the overview page.
        };
      }
      const lastApp = stagedAppsToSave[idx - 1];
      const lastAppPublishTo = lastApp.values.publish_to;
      let defaultTopic;
      let topics = [];

      if (!lastAppPublishTo) {
        const appSchema = thisThis.getSchemaById(lastApp.schemaId);
        const publishToProp = _.find(appSchema.userpropertieslist, {
          property: 'publish_to',
        });
        defaultTopic = `${publishToProp.default_topic_prefix}_${lastApp.defaultRandom}`;
        topics.push(defaultTopic);
      } else {
        topics = lastAppPublishTo;
      }

      userprops = { ...app.values, ...userprops };

      return {
        appId: app.appId,
        enabled: false,
        csrappid: app.schemaId,
        nickname,
        match_all_tags: false,
        tags: topics,
        userproperties: JSON.stringify(userprops),
      };
    });

    return appChain;
  }

  addOperationAt(index) {
    const { addOperationApp } = this.props;
    addOperationApp(index, { schemaId: null, minimized: false });
  }

  validateAllAppsPresent() {
    const { operationApps, actionApp } = this.props;

    let allAppsPresent = true;

    operationApps.forEach((opApp) => {
      if (!opApp.schemaId) allAppsPresent = false;
    });

    if (!actionApp.schemaId) allAppsPresent = false;

    return allAppsPresent;
  }

  render() {
    const {
      isUpserting,
      isDeleting,
      deleteError,
      upsertError,
      isEditingSelectedTopics,
      createEnabled,
      isUpdatingStatus,
      hasSubmitted,
    } = this.state;
    const { addSelectedTopic, updateOperationApp, udpateActionApp } = this.props;
    const {
      appSchemas,
      topics,
      selectedTopics = [],
      operationApps,
      actionApp,
      topicPreviews,
      isLoadingPreview,
      nickname,
      isEditingApp,
      openModal,
      matchAllTopics,
      recentTopics: recentTopicsProp,
    } = this.props;

    const recentTopics = recentTopicsProp.map((topic) => ({ name: topic }));
    const actionAppSchemas = appSchemas.filter(
      (schema) => _.findIndex(schema.userpropertieslist, { property: 'publish_to' }) === -1
    );
    const operationAppSchemas = appSchemas.filter(
      (schema) => _.findIndex(schema.userpropertieslist, { property: 'publish_to' }) > -1
    );
    const defaultAppIcon = (
      <div className="default-app-container">
        <Icon
          name="Routes"
          size="major"
          svgProps={{ style: { fill: '#091335', stroke: '#091335' } }}
        />
      </div>
    );

    return (
      <Formsy.Form
        // eslint-disable-next-line react/jsx-no-bind
        onValidSubmit={this.onSuccessfulSubmit.bind(this)}
        onInvalidSubmit={AppForm.onInvalidSubmit}
        className="form NewRouteForm"
        onSubmit={() => this.setState({ hasSubmitted: true })}
        ref="form"
        // eslint-disable-next-line react/jsx-no-bind
        onChange={this.onFormChange.bind(this)}
      >
        <div className="section section-med">
          <div className="form-row form-row-dark">
            <div className="grid-row">
              <div className="grid-item">
                <Input
                  name="nickname"
                  required
                  requiredMessage="This field is required"
                  labelName="Route nickname"
                  showErrors={hasSubmitted}
                  value={nickname}
                />
              </div>
              <div className="grid-row grid-column status-section">
                <div className="label-text">status</div>
                <div className="grid-item grid-row vertical-align">
                  <RadioSwitch
                    onChange={() => this.onChangeEnabled()}
                    checked={isEditingApp ? actionApp.enabled : createEnabled}
                    isProcessing={isUpdatingStatus}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Panel__padding">
          <div className="ingress-section grid-row">
            <div className="indicator">
              <div className="indicator-circle">in</div>
            </div>
            <div className="grid-item">
              <div
                className={`select-topics-section${!isEditingSelectedTopics ? ' minimized' : ''}`}
              >
                <div
                  className="edit-label f4 f-subdued"
                  onClick={() => {
                    this.setState({
                      isEditingSelectedTopics: !isEditingSelectedTopics,
                    });
                  }}
                >
                  edit
                </div>
                {selectedTopics.length > 0 && (
                  <div
                    className="min-section"
                    onClick={() =>
                      this.setState({
                        isEditingSelectedTopics: !isEditingSelectedTopics,
                      })
                    }
                  >
                    <Icon
                      name="Chevron--single--south"
                      size="major"
                      svgProps={{ style: { fill: '#d0cfcf' } }}
                    />
                  </div>
                )}
                {isEditingSelectedTopics && (
                  <div>
                    <div className="f1 section section">Select topics</div>
                    <div className="section">
                      <TagComplete
                        suggestions={topics.map((suggestion) => ({
                          name: suggestion,
                        }))}
                        onTagAdded={(topic) => {
                          addSelectedTopic(topic);
                        }}
                        allowNew
                        allowDuplicates
                        autofocus={false}
                        autoresize
                        placeholder="Search by topic name"
                      />
                    </div>
                    <div className="f5 f-subdued section section-sm">
                      Select topics from recent messages
                    </div>
                    <div className="grid-row grid-wrap">
                      {recentTopics
                        .filter(
                          (recentTopic) =>
                            _.findIndex(selectedTopics, {
                              name: recentTopic.name,
                            }) === -1
                        )
                        .filter((t, i) => i < 8)
                        .map((recentTopic, idx) => (
                          <Topic
                            name={recentTopic.name}
                            key={idx}
                            suggested
                            onClick={() => {
                              addSelectedTopic(recentTopic);
                            }}
                          />
                        ))}
                    </div>
                  </div>
                )}
                {selectedTopics.length > 0 && (
                  <div>
                    {isEditingSelectedTopics && <hr />}
                    <div className="grid-row grid-wrap selected-topics">
                      {selectedTopics.map((selectedTopic, idx) => (
                        <Topic
                          selected
                          key={idx}
                          name={selectedTopic.name}
                          onRemove={() => {
                            this.onRemoveSelectedTopic(selectedTopic);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div>
                  <div className="label-text">Topic Match Logic</div>
                  <div className="grid-row v-align match-all-topics">
                    OR
                    <RadioSwitch
                      onChange={(selected) => this.onChangeMatchAllTopics(selected)}
                      checked={matchAllTopics}
                    />
                    AND
                  </div>
                </div>
                {topicPreviews.length > 0 && (
                  <div className="selected-topics-preview">
                    {isLoadingPreview && (
                      <div className="log-preview-spinner">
                        <ComplexIcon name="spinner" />
                      </div>
                    )}
                    {topicPreviews.map((topicPreview, idx) => (
                      <div key={idx} className="grid-row preview-row">
                        <div className="grid-item">
                          {Moment.utc(topicPreview.logged)
                            .tz(Moment.tz.guess())
                            .format('MMM D YYYY HH:mm:ss')}
                        </div>
                        <div className="grid-item">{topicPreview.previewForTopic}</div>
                        <div className="grid-item data-section">
                          {topicPreview.data.length < 500
                            ? topicPreview.data
                            : `${topicPreview.data.substring(0, 500)} ... (data truncated)`}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {topicPreviews.length === 0 && isLoadingPreview && (
                  <div className="log-preview-spinner">
                    <ComplexIcon name="spinner" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="add-operator-section grid-row">
            <div className="indicator" onClick={() => this.addOperationAt(0)}>
              <div className="indicator-circle">
                <div className="indicator-circle-text">+</div>
              </div>
              <div className="add-operator-label">Insert operation (optional)</div>
            </div>
          </div>
          {operationApps.map((operationApp, indexOfOperationApp) => [
            <div key={indexOfOperationApp} className="operator-section grid-row">
              <div className="indicator">
                <div className="indicator-circle">op</div>
              </div>
              <div className="grid-item">
                {!operationApp.schemaId ? (
                  <div className="select-operator-section">
                    <div className="f1 section section">Select an operation</div>
                    <div
                      className="close-section"
                      onClick={() => this.onOperationSchemaRemove(indexOfOperationApp)}
                    >
                      <Icon name="Close" size="minor" svgProps={{ style: { fill: '#d0cfcf' } }} />
                    </div>
                    <div className="actions">
                      {operationAppSchemas.map((appSchema, i) => (
                        <div
                          key={i}
                          className="action"
                          onClick={() =>
                            updateOperationApp(indexOfOperationApp, {
                              schemaId: appSchema.id,
                              viewingAdvanced: false,
                            })
                          }
                        >
                          <div className="icon-con">
                            {appSchema.icon ? <img src={appSchema.icon} alt="" /> : defaultAppIcon}
                          </div>
                          <div className="grid-item">
                            <div className="action-title">{appSchema.name}</div>
                            <div className="action-description">{appSchema.description}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`select-operator-section${
                      operationApp.minimized ? ' minimized' : ''
                    }`}
                    onClick={() => {
                      if (operationApp.minimized) {
                        updateOperationApp(indexOfOperationApp, {
                          minimized: false,
                        });
                      }
                    }}
                  >
                    <div
                      className="min-section"
                      onClick={() =>
                        updateOperationApp(indexOfOperationApp, {
                          minimized: !operationApp.minimized,
                        })
                      }
                    >
                      <Icon
                        name="Chevron--single--north"
                        size="major"
                        svgProps={{ style: { fill: '#d0cfcf' } }}
                      />
                    </div>
                    {operationApp.minimized ? (
                      <div className="grid-row vertical-align">
                        <div className="icon-con">
                          {this.getSchemaById(operationApp.schemaId).icon ? (
                            <img src={this.getSchemaById(operationApp.schemaId).icon} alt="" />
                          ) : (
                            defaultAppIcon
                          )}
                        </div>
                        <div className="grid-item">
                          <div className="action-title">
                            {this.getSchemaById(operationApp.schemaId).name}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="f1 section">Configure operation</div>
                        {this.getSchemaById(operationApp.schemaId).documentation && (
                          <div className="section">
                            <a
                              className="help-link"
                              href={this.getSchemaById(operationApp.schemaId).documentation}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon
                                classes="help-link-icon"
                                name="Document"
                                size="minor"
                                svgProps={{ style: { fill: '#04a7ff' } }}
                              />
                              Learn more about this route type.
                            </a>
                          </div>
                        )}
                        <div className="section">
                          <div className="select-wrapper ">
                            <select
                              value={operationApp.schemaId}
                              onChange={(e) =>
                                updateOperationApp(indexOfOperationApp, {
                                  schemaId: parseInt(e.currentTarget.value, 10),
                                })
                              }
                            >
                              {operationAppSchemas.map((appSchema, i) => (
                                <option key={i} value={appSchema.id}>
                                  {appSchema.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {this.getSchemaById(operationApp.schemaId)
                          .userpropertieslist.filter((input) => input.property !== 'publish_to')
                          .map((input, idx) => (
                            <div key={idx} className="section">
                              {input.type === 'time_duration' ? (
                                <TimeDurationInput
                                  name={`op${INPUT_PROP_SEPARATOR}${indexOfOperationApp}${INPUT_PROP_SEPARATOR}${input.property}`}
                                  required={input.mandatory}
                                  requiredMessage="This field is required"
                                  showErrors={hasSubmitted}
                                  validationError="This is not a valid entry"
                                  labelName={input.name}
                                  description={input.description}
                                  type={input.type}
                                  value={
                                    AppForm.getAppValue(operationApp, input.property) ||
                                    input.default ||
                                    ''
                                  }
                                />
                              ) : input.type === 'list' ? (
                                <ListInput
                                  name={`op${INPUT_PROP_SEPARATOR}${indexOfOperationApp}${INPUT_PROP_SEPARATOR}${input.property}`}
                                  required={input.mandatory}
                                  requiredMessage="This field is required"
                                  showErrors={hasSubmitted}
                                  validationError="This is not a valid entry"
                                  labelName={input.name}
                                  property={input.property}
                                  description={input.description}
                                  type={input.type}
                                  openModal={openModal}
                                  value={
                                    AppForm.getAppValue(operationApp, input.property) ||
                                    input.default ||
                                    []
                                  }
                                />
                              ) : (
                                <Input
                                  name={`op${INPUT_PROP_SEPARATOR}${indexOfOperationApp}${INPUT_PROP_SEPARATOR}${input.property}`}
                                  required={input.mandatory}
                                  requiredMessage="This field is required"
                                  showErrors={hasSubmitted}
                                  validationError="This is not a valid entry"
                                  labelName={input.name}
                                  description={input.description}
                                  type={input.type}
                                  value={
                                    AppForm.getAppValue(operationApp, input.property) ||
                                    input.default ||
                                    ''
                                  }
                                />
                              )}
                            </div>
                          ))}
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            updateOperationApp(indexOfOperationApp, {
                              viewingAdvanced: !operationApp.viewingAdvanced,
                            });
                          }}
                        >
                          Advanced settings
                        </a>
                        {operationApp.viewingAdvanced && (
                          <div>
                            {this.getSchemaById(operationApp.schemaId)
                              .userpropertieslist.filter((input) => input.property === 'publish_to')
                              .map((input, idx) => (
                                <div key={idx} className="section">
                                  <TopicInput
                                    name={`op${INPUT_PROP_SEPARATOR}${indexOfOperationApp}${INPUT_PROP_SEPARATOR}${input.property}`}
                                    defaultTopic={`${input.default_topic_prefix}_${operationApp.defaultRandom}`}
                                    required={input.default_topic_prefix ? false : input.mandatory}
                                    requiredMessage="This field is required"
                                    showErrors={hasSubmitted}
                                    validationError="This is not a valid entry"
                                    labelName={input.name}
                                    description={input.description}
                                    type={input.type}
                                    suggestions={topics}
                                    value={AppForm.getAppValue(operationApp, input.property, [])}
                                  />
                                </div>
                              ))}
                          </div>
                        )}

                        <div
                          className="remove-op-section"
                          onClick={() => this.onOperationSchemaRemove(indexOfOperationApp)}
                        >
                          <Icon
                            name="Trash"
                            size="minor"
                            svgProps={{ style: { fill: '#cf3c00' } }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>,
            <div className="add-operator-section grid-row">
              <div
                className="indicator"
                onClick={() => this.addOperationAt(indexOfOperationApp + 1)}
              >
                <div className="indicator-circle">
                  <div className="indicator-circle-text">+</div>
                </div>
                <div className="add-operator-label">Insert operation (optional)</div>
              </div>
            </div>,
          ])}
          <div className="egress-section grid-row">
            <div className="indicator">
              <div className="indicator-circle">out</div>
            </div>
            <div className="grid-item">
              {!actionApp.schemaId ? (
                <div className="select-action-section">
                  <div className="f1 section section">Select an action</div>
                  <div className="actions">
                    {actionAppSchemas.map((appSchema, i) => (
                      <div
                        key={i}
                        className="action"
                        onClick={() => {
                          udpateActionApp({ schemaId: appSchema.id });
                        }}
                      >
                        {appSchema.icon ? (
                          <div className="icon-con">
                            <img src={appSchema.icon} alt="" />
                          </div>
                        ) : (
                          defaultAppIcon
                        )}
                        <div className="grid-item">
                          <div className="action-title">{appSchema.name}</div>
                          <div className="action-description">{appSchema.description}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="select-action-section">
                  <div
                    className="close-section"
                    onClick={() => {
                      udpateActionApp({ schemaId: null });
                    }}
                  >
                    <Icon name="Close" size="minor" svgProps={{ style: { fill: '#d0cfcf' } }} />
                  </div>
                  <div>
                    <div className="grid-row vertical-align section section-med">
                      <div className="icon-con">
                        {this.getSchemaById(actionApp.schemaId).icon ? (
                          <img src={this.getSchemaById(actionApp.schemaId).icon} alt="" />
                        ) : (
                          defaultAppIcon
                        )}
                      </div>
                      <div className="grid-item">
                        <div className="action-title">
                          {this.getSchemaById(actionApp.schemaId).name}
                        </div>
                      </div>
                    </div>
                    <div className="f1 section section">Configure action</div>
                    <div className="section">
                      {this.getSchemaById(actionApp.schemaId).documentation && (
                        <span className="help-link">
                          <Icon
                            classes="help-link-icon"
                            name="Document"
                            size="minor"
                            svgProps={{ style: { fill: '#04a7ff' } }}
                          />
                          <a
                            href={this.getSchemaById(actionApp.schemaId).documentation}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn more about this route type.
                          </a>
                        </span>
                      )}
                    </div>
                    <div className="label">
                      <div className="form-sub-row">
                        {this.getSchemaById(actionApp.schemaId).userpropertieslist.map(
                          (input, idx) => (
                            <div key={idx} className="section section-med">
                              {input.type === 'time_duration' ? (
                                <TimeDurationInput
                                  name={`action${INPUT_PROP_SEPARATOR}${input.property}`}
                                  required={input.mandatory}
                                  requiredMessage="This field is required"
                                  showErrors={hasSubmitted}
                                  validationError="This is not a valid entry"
                                  labelName={input.name}
                                  description={input.description}
                                  type={input.type}
                                  value={
                                    AppForm.getAppValue(actionApp, input.property) ||
                                    input.default ||
                                    ''
                                  }
                                />
                              ) : input.type === 'list' ? (
                                <ListInput
                                  name={`action${INPUT_PROP_SEPARATOR}${input.property}`}
                                  required={input.mandatory}
                                  requiredMessage="This field is required"
                                  showErrors={hasSubmitted}
                                  validationError="This is not a valid entry"
                                  labelName={input.name}
                                  property={input.property}
                                  description={input.description}
                                  type={input.type}
                                  openModal={openModal}
                                  value={
                                    AppForm.getAppValue(actionApp, input.property) ||
                                    input.default ||
                                    []
                                  }
                                />
                              ) : input.type === 'json' ? (
                                <AceEditorInput
                                  name={`action${INPUT_PROP_SEPARATOR}${input.property}`}
                                  required={input.mandatory}
                                  requiredMessage="This field is required"
                                  showErrors={hasSubmitted}
                                  validationError="This is not a valid entry"
                                  labelName={input.name}
                                  description={input.description}
                                  type={input.type}
                                  value={
                                    AppForm.getAppValue(actionApp, input.property) ||
                                    input.default ||
                                    ''
                                  }
                                />
                              ) : (
                                <Input
                                  name={`action${INPUT_PROP_SEPARATOR}${input.property}`}
                                  required={input.mandatory}
                                  requiredMessage="This field is required"
                                  showErrors={hasSubmitted}
                                  validationError="This is not a valid entry"
                                  labelName={input.name}
                                  description={input.description}
                                  type={input.type}
                                  value={
                                    AppForm.getAppValue(actionApp, input.property) ||
                                    input.default ||
                                    ''
                                  }
                                />
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {upsertError && (
            <MessagePanel fullWidth messageType="error">
              {upsertError}
            </MessagePanel>
          )}
          {deleteError && (
            <MessagePanel fullWidth messageType="error">
              {deleteError}
            </MessagePanel>
          )}
          {isEditingApp ? (
            <Button
              disabled={!this.validateAllAppsPresent()}
              buttonProps={{ type: 'submit' }}
              type="primary"
            >
              {isUpserting ? 'Updating...' : 'Update route'}
            </Button>
          ) : (
            <Button
              disabled={!this.validateAllAppsPresent()}
              buttonProps={{ type: 'submit' }}
              type="primary"
            >
              {isUpserting ? 'Adding...' : 'Add route'}
            </Button>
          )}
          <Link className="Button Button--secondary" to={Paths.withContext(Paths.APPS_OVERVIEW)}>
            <div className="Button__content">
              <span className="Button__text">Cancel</span>
            </div>
          </Link>
          {isEditingApp && (
            <Button onClick={() => this.onDeleteApp()} type="destructiveMajor">
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          )}
        </div>
      </Formsy.Form>
    );
  }
}

export default connect(
  (state) => ({
    appSchemas: state.apps.appSchemas,
    appChains: state.apps.appChains,
    selectedTopics: state.apps.selectedTopics,
    operationApps: state.apps.operationApps,
    actionApp: state.apps.actionApp || {},
    nickname: state.apps.nickname,
    matchAllTopics: state.apps.matchAllTopics,
    topics: getTopics(state),
    recentTopics: state.topic.recentTopics,
    topicPreviews: state.apps.topicPreviews,
    isLoadingPreview: state.apps.isLoadingPreview,
    appChainId: state.apps.appChainId,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        addApp: appsActions.addApp,
        deleteApp: appsActions.deleteApp,
        editApp: appsActions.editApp,
        addSelectedTopic: appsActions.addSelectedTopic,
        disableApp: appsActions.disableApp,
        enableApp: appsActions.enableApp,
        removeSelectedTopic: appsActions.removeSelectedTopic,
        addActionApp: appsActions.addActionApp,
        udpateActionApp: appsActions.udpateActionApp,
        removeActionApp: appsActions.removeActionApp,
        addOperationApp: appsActions.addOperationApp,
        updateOperationApp: appsActions.updateOperationApp,
        removeOperationApp: appsActions.removeOperationApp,
        clearAllAppSelections: appsActions.clearAllAppSelections,
        updateChainChangeValues: appsActions.updateChainChangeValues,
        setMatchAllTopics: appsActions.setMatchAllTopics,
        pushGlobalMessage: messageActions.pushGlobalMessage,
        openModal: modalActions.openModal,
      },
      dispatch
    )
)(AppForm);
