import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import useCanActivate from 'common-js/hooks/useCanActivate';
import { preflightActivationPreview } from 'common-js/reducers/activation/actions';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import {
  selectHasPredeploymentTesting,
  selectPlanSelectionLimit,
} from 'common-js/reducers/releaseFlag/selectors';
import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useBulkModal from '../useBulkModal';

const simsAreAllOnSamePlan = (sims: any[] = []) =>
  !!sims.length && sims.every(({ planId }) => planId === sims[0].planId);

const useActivateSIMItem = ({ deviceType, totalSelected }) => {
  const dispatch = useAppDispatch();
  const hasPredeployment = useAppSelector(selectHasPredeploymentTesting);
  const planSelectionLimit = useAppSelector(selectPlanSelectionLimit);

  const { canActivate } = useCanActivate({ deviceType });

  const openModal = useBulkModal('BulkActivatePreflightModal');

  const onClick = useCallback(async () => {
    analyticsEventBuilder
      .buttonClick('Devices', 'Manage Dropdown', 'Activate Preflight SIMs')
      .send();

    if (totalSelected > 10_000) {
      openModal({ overLimit: true });
      analyticsEventBuilder.errorReturn('Bulk Action', '10k Error').send();
      return;
    }

    const preflightPreview = await dispatch(preflightActivationPreview());
    const { sims } = preflightPreview ?? {};

    if (!simsAreAllOnSamePlan(sims)) {
      dispatch(
        pushGlobalMessage('Choose devices on the same plan to activate Preflight SIMs.', 'error')
      );
    } else {
      openModal();
    }
  }, [dispatch, openModal, totalSelected]);

  return {
    label: `Activate ${totalSelected === 1 ? 'SIM' : 'SIMs'}`,
    icon: 'Lightning',
    selectionLimit: planSelectionLimit,
    ignore10kLimit: true,
    onClick,
    include: deviceType === 'preflight' && hasPredeployment,
    disabled: !canActivate,
  };
};

export default useActivateSIMItem;
