import _ from 'lodash';
import queryString from 'query-string';

export default function buildDevicesQueryFiltersQuerystring(filters, userContextData) {
  const { isInOrgContext, orgId, userOrgId } = userContextData;

  const filterDefaults = {
    states: [],
    tagids: [],
    startafter: null,
    slim: null,
    witheuicc: 1, // this field will show euicc states for device profiles
    orgid: isInOrgContext ? orgId : userOrgId,
  };

  const pendingFilters = {
    ...filterDefaults,
    ...filters,
  };

  const filtersWithoutNullValues = _.omitBy(pendingFilters, _.isNil);

  return queryString.stringify(filtersWithoutNullValues, {
    arrayFormat: 'bracket',
  });
}
