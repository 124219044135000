import { DeviceModel } from 'common-js/models';
import { getAndReduceDevicesToFields } from 'common-js/reducers/devices/actions';
import * as actionTypes from '../actionTypes';

export const validateSimsRequest = () => ({
  type: actionTypes.VALIDATE_SIMS_REQUEST,
});

export const validateSimsSuccess = (sims) => ({
  type: actionTypes.VALIDATE_SIMS_SUCCESS,
  payload: {
    sims,
  },
});

export const validateSimsError = ({ error, invalidSims }) => ({
  type: actionTypes.VALIDATE_SIMS_ERROR,
  payload: {
    invalidSims,
    error,
  },
});

export const validateSimRangeRequest = () => ({
  type: actionTypes.VALIDATE_SIM_RANGE_REQUEST,
});

export const validateSimRangeSuccess = (start, end, totalValidSims) => ({
  type: actionTypes.VALIDATE_SIM_RANGE_SUCCESS,
  start,
  end,
  totalValidSims,
});

export const validateSimRangeError = ({ error, invalidSims }) => ({
  type: actionTypes.VALIDATE_SIM_RANGE_ERROR,
  invalidSims,
  error,
});

export const activateSimsRequest = () => ({
  type: actionTypes.ACTIVATE_SIMS_REQUEST,
});

export const activateSimsSuccess = (orgId) => ({
  type: actionTypes.ACTIVATE_SIMS_SUCCESS,
  payload: {
    orgId,
  },
});

export const activateSimsError = (error) => ({
  type: actionTypes.ACTIVATE_SIMS_ERROR,
  payload: {
    error,
  },
});

export const clearIndividualSimSelection = () => ({
  type: actionTypes.CLEAR_INDIVIDUAL_SIM_SELECTION,
});

export const clearValidationErrorState = () => ({
  type: actionTypes.CLEAR_VALIDATION_ERROR_STATE,
});

export const updateTotalDueToday = (total) => ({
  type: actionTypes.UPDATE_TOTAL_DUE_TODAY,
  payload: {
    total,
  },
});

export const calculateTotalDueTodayRequest = () => ({
  type: actionTypes.CALCULATE_TOTAL_DUE_TODAY_REQUEST,
});

export const calculateTotalDueTodaySuccess = () => ({
  type: actionTypes.CALCULATE_TOTAL_DUE_TODAY_SUCCESS,
});

export const calculateTotalDueTodayError = (error) => ({
  type: actionTypes.CALCULATE_TOTAL_DUE_TODAY_ERROR,
  payload: {
    error,
  },
});

export const setIsPreflightFlag = () => ({
  type: actionTypes.SET_ISPREFLIGHT_FLAG,
});

export const setIsChangePlanFlag = () => ({
  type: actionTypes.SET_ISCHANGEPLAN_FLAG,
});

export const setChangePlanDevices = (devices: Array<DeviceModel>) => ({
  type: actionTypes.SET_CHANGEPLAN_DEVICES,
  devices,
});

export const setPreloadedActivationDevice = (device: Array<DeviceModel>) => ({
  type: actionTypes.SET_PRELOADED_ACTIVATION_DEVICES,
  device,
});

export const resetActivationStoreToDefault = () => ({
  type: actionTypes.RESET_ACTIVATION_STORE,
});

export const preflightActivationPreview = () => async (dispatch: any) => {
  dispatch({
    type: actionTypes.PREFLIGHT_ACTIVATION_PREVIEW_REQUEST,
  });

  try {
    const devices = await dispatch(getAndReduceDevicesToFields());

    dispatch({
      type: actionTypes.PREFLIGHT_ACTIVATION_PREVIEW_SUCCESS,
      devices,
    });

    return await Promise.resolve({ sims: devices });
  } catch (error) {
    dispatch({
      type: actionTypes.PREFLIGHT_ACTIVATION_PREVIEW_ERROR,
      error,
    });

    return Promise.reject(error);
  }
};
