import { Icon } from '@hologram-dimension/icon';
import { Device } from 'common-js/types/Device';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import Moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';

type UsageAlerts = {
  id: number;
  text: string;
  acked: number;
  acked_at: string;
  acked_by: number;
  cur_billing_data_used: number;
  data_threshold: number;
  device_name: string;
  deviceid: number;
  expires: string;
  last_billing_data_used: number;
  latitude: number;
  linkid: number;
  longitude: number;
  orgid: number;
  plan_amount: number;
  planid: number;
  sim: string;
  state: string;
  type: string;
  whencreated: string;
};

interface UsageAlertElementProps {
  dataThreshold: number;
  usageAlertData: Array<UsageAlerts>;
  device: Device;
}

const handlePercentageStringZero = (percentString: string | number) =>
  percentString === 0 ? '< 1' : percentString;

const buildAlertDate = (alert: UsageAlerts) =>
  Moment(alert.whencreated, 'YYYY-MM-DD HH:mm:ss').format('[on] MMM D, YYYY [at] H:mm [UTC.]');

const UsageAlertElement: FC<UsageAlertElementProps> = ({
  dataThreshold,
  usageAlertData,
  device,
}) => {
  const [triggeredUsageAlert, setTriggeredUsageAlert] = useState<boolean>(false);
  const [configureUsageAlerts, setConfigureUsageAlerts] = useState('');
  const dataThresholdByteString = toByteStringFormatted(dataThreshold);
  const hasDataThreshold = !!dataThreshold && dataThreshold > -1;

  useEffect(() => {
    if (hasDataThreshold && (usageAlertData?.length ?? 0) > 0) {
      // compare both the device id, as well as the amount of the threshold to determine if
      // the user has changed the threshold since an alert was created.
      const triggeredAlert = usageAlertData.find(
        (alert) => alert.deviceid === device.id && alert.data_threshold === device.data_threshold
      );

      if (triggeredAlert) {
        setTriggeredUsageAlert(true);
        setConfigureUsageAlerts(buildAlertDate(triggeredAlert));
      } else {
        setTriggeredUsageAlert(false);
        setConfigureUsageAlerts('');
      }
    }
  }, [device.data_threshold, device.id, hasDataThreshold, usageAlertData]);

  const percentageString =
    hasDataThreshold && !!device.data ? Math.round((dataThreshold / device.data) * 100) : '';

  const configuredPercentageString = handlePercentageStringZero(percentageString);

  if (!hasDataThreshold) {
    return (
      <>
        <Icon name="CircleInfo" fill="DdsColorTextPrimaryOndefault" />
        <p className="DataLimits__highlight">
          Get notified when devices cross specific usage thresholds.
        </p>
      </>
    );
  }
  if (triggeredUsageAlert) {
    return (
      <>
        <Icon name="Performance" fill="DdsColorFillNotificationCaution" />
        <p className="paragraph--warning">
          {dataThresholdByteString} threshold reached {configureUsageAlerts?.toString()}
        </p>
      </>
    );
  }
  return (
    <>
      <Icon name="Performance" fill="DdsColorTextPrimaryOndefault" />
      <p>
        Alert when data usage exceeds{' '}
        <span className="DataLimits__paragraph-emphasis">{dataThresholdByteString}</span>{' '}
        {configuredPercentageString ? `(${configuredPercentageString}% of plan).` : ''}
      </p>
    </>
  );
};

export default UsageAlertElement;
