import { getDeviceInitialState } from './reducer';

export const getSingleDevice = (state, deviceId) =>
  state.singleDevice[deviceId] || getDeviceInitialState();

export function buildChartFilters(state, deviceId) {
  const thisState = typeof state === 'function' ? state() : state;
  const filters = {};
  const filterSource = thisState.singleDevice[deviceId].timeFilter;

  const timeStart = filterSource.startDate;
  const timeEnd = filterSource.endDate;

  if (timeStart) filters.timestart = parseInt(timeStart / 1000, 10);
  if (timeEnd) filters.timeend = parseInt(timeEnd / 1000, 10);
  filters.deviceid = deviceId;

  return filters;
}

export function getStatusInfo(device) {
  if (device && device.statusHistory.length > 0) {
    return device.statusHistory[0];
  }
  return null;
}

export const getDataThreshold = (device) =>
  'data_threshold' in device ? device.data_threshold : -1;
