import { actionTypes as storageActionTypes } from 'redux-localstorage';

const initialState = {
  isRehydrated: false,
};

function app(state = initialState, action) {
  switch (action.type) {
    case storageActionTypes.INIT:
      return { ...state, isRehydrated: true };

    default:
      return state;
  }
}

export default app;
