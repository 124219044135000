import React, { useMemo } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { useFormContext } from 'react-hook-form';
import { useLogGlobalSimError } from 'activation/common/hooks/useLogGlobalSimError';
import { calculateTotalDueToday } from 'common-js/reducers/activation/actions/calculateTotalDueToday';
import SimsDisplay from './SimsDisplay';
import AlreadyActivatedSims from './AlreadyActivatedSims';
import InvalidatedPreflightSims from './InvalidatedPreflightSims';
import SimError from './SimError';
import SimInfo from './SimInfo';
import { getInvalidSims, getAlreadyActivatedSims, isArrayValid } from './SimUtils';

function SimListDisplay({ simErrors, duplicateSimsList, hasBillingPermissions, availableBalance }) {
  const { getValues, setValue, watch } = useFormContext();
  const simsInForm = watch('sims.sims');

  const {
    invalid,
    globalError,
    alreadyActivated,
    preflightSims: invalidatedPreflightSims,
  } = simErrors;

  useLogGlobalSimError({ globalError, isOnboarding: false });

  const invalidSims = useMemo(() => getInvalidSims(invalid), [invalid]);
  const alreadyActivatedSims = useMemo(
    () => getAlreadyActivatedSims(alreadyActivated),
    [alreadyActivated]
  );

  const dispatch = useAppDispatch();

  const removeSim = async (sim) => {
    setValue(
      'sims.sims',
      simsInForm.filter((validateSim) => validateSim !== sim)
    );
    const currentTotal = getValues('sims.total');
    setValue('sims.total', currentTotal - 1);

    const config = {
      sims: getValues('sims.sims'),
      plan: getValues('plan'),
      type: 'list',
      excludeSims: alreadyActivatedSims,
      availableBalance,
    };

    if (!hasBillingPermissions) {
      await dispatch(calculateTotalDueToday(config));
    }
  };

  return (
    <>
      {isArrayValid(duplicateSimsList) && <SimInfo messageList={duplicateSimsList} />}
      {globalError && <SimError error={globalError} />}

      {isArrayValid(invalidSims) && <SimError error={invalidSims} />}

      {isArrayValid(invalidatedPreflightSims) && (
        <InvalidatedPreflightSims sims={invalidatedPreflightSims} />
      )}

      {alreadyActivatedSims && <AlreadyActivatedSims sims={alreadyActivatedSims} />}

      <SimsDisplay sims={simsInForm} onDelete={removeSim} text="added for activation" />
    </>
  );
}

export default SimListDisplay;
