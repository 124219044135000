import { Panel } from '@hologram-dimension/panel';
import { DateTagFilter, FullHeightCalculatorHOC } from 'common-js/components';
import { deselectAllTags, selectAllTags, toggleTag } from 'common-js/reducers/deviceFilter/actions';
import { getSelectedOrgIndex } from 'common-js/reducers/organization/selectors';
import { selectHasSimsPages } from 'common-js/reducers/releaseFlag/selectors';
import { runReport, setCustomDate, setQuickFilter } from 'common-js/reducers/usage/actions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UsageBetaCallout from 'usage/components/UsageBetaCallout';
import UsageMenu from 'usage/components/UsageMenu';
import BaseLayout from './BaseLayout';

class LayoutUsageWithDateFilter extends React.PureComponent {
  render() {
    const {
      children,
      deselectAllTags_,
      endDate,
      reportDateTitle,
      runningReport,
      runReport_,
      selectAllTags_,
      selectedTags,
      setCustomDate_,
      setQuickFilter_,
      startDate,
      tags,
      timeQuickFilter,
      toggleTag_,
    } = this.props;

    return (
      <BaseLayout>
        <UsageMenu>
          <FullHeightCalculatorHOC classNames="fullHeight-usage" accountForDrawer relative>
            <div className="usage-container">
              <UsageBetaCallout />
              <Panel header={reportDateTitle} largeTitle headerBorder={false} noBodyPadding well>
                <DateTagFilter
                  deselectAllTags={deselectAllTags_}
                  disabled={runningReport}
                  endDate={endDate}
                  onRunReport={runReport_}
                  runningReport={runningReport}
                  selectAllTags={selectAllTags_}
                  selectedTags={selectedTags}
                  setCustomDate={setCustomDate_}
                  setTimeQuickFilter={setQuickFilter_}
                  startDate={startDate}
                  tags={tags}
                  timeQuickFilter={timeQuickFilter}
                  toggleTag={toggleTag_}
                />
              </Panel>
              {children}
            </div>
          </FullHeightCalculatorHOC>
        </UsageMenu>
      </BaseLayout>
    );
  }
}

const LayoutUsageWithDateFilterHOC = connect(
  (state) => ({
    isDrawerMinimized: state.drawer.isMinimized,
    selectedOrgIndex: getSelectedOrgIndex(state),
    drawerTakeover: state.drawer.drawerTakeover,
    hasPageSimInventoryFlag: selectHasSimsPages(state),
    startDate: new Date(state.usage.filters.startDate),
    endDate: new Date(state.usage.filters.endDate),
    timeQuickFilter: state.usage.timeQuickFilter,
    runningReport: state.usage.runningReport,
    reportDateTitle: state.usage.reportDateTitle,
    selectedTags: state.deviceFilters.selectedTags,
    tags: state.deviceFilters.tags,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        deselectAllTags_: deselectAllTags,
        runReport_: runReport,
        selectAllTags_: selectAllTags,
        setCustomDate_: setCustomDate,
        setQuickFilter_: setQuickFilter,
        toggleTag_: toggleTag,
      },
      dispatch
    )
)(LayoutUsageWithDateFilter);

export default LayoutUsageWithDateFilterHOC;
