import React from 'react';
import PropTypes from 'prop-types';
import { toByteString } from 'common-js/utils/numberFormatter';
import EmptyCell from './EmptyCell';

function CellDeviceUsage({ dataUsed }) {
  return (
    <div className="DevicesTable__cell">{dataUsed ? toByteString(dataUsed, 2) : <EmptyCell />}</div>
  );
}

CellDeviceUsage.propTypes = {
  dataUsed: PropTypes.number,
};

export default CellDeviceUsage;
