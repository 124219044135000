import { ModalHeaderWithIcon } from 'common-js/components';
import { useAppDispatch } from 'common-js/hooks';
import useCountries from 'common-js/hooks/useCountries';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { close } from 'common-js/reducers/modal/actions';
import { FC, useCallback } from 'react';
import PurchasePhoneNumberForm from '../forms/PurchasePhoneNumberForm';

interface PurchasePhoneNumberModalProps {
  deviceId: string;
}

const PurchasePhoneNumberModal: FC<PurchasePhoneNumberModalProps> = ({ deviceId }) => {
  const countries = useCountries();
  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onSuccess = useCallback(() => {
    dispatch(pushGlobalMessage('Successfully added phone number to device.', 'success'));
    closeModal();
  }, [closeModal, dispatch]);

  return (
    <div className="PurchasePhoneNumberModal">
      <ModalHeaderWithIcon title="Purchase phone number" iconName="Phone" />
      <PurchasePhoneNumberForm
        close={closeModal}
        deviceId={deviceId}
        onCancelClick={closeModal}
        onSuccess={onSuccess}
        countries={countries}
      />
    </div>
  );
};

export default PurchasePhoneNumberModal;
