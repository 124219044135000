import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { hideActivationNotification } from 'common-js/reducers/devices/actions';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import * as Permissions from 'common-js/utils/permissions';
import _ from 'lodash';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import * as API from '../../api';
import { getUserContextData } from '../../api/util';
import { upsertStore } from '../../configureStore';
import * as DeviceStates from '../../constants/deviceStates';
import * as Paths from '../../constants/paths';
import { BalanceModel, BillingInformationModel } from '../../models';
import * as accountActions from '../account/actions';
import { getAllAppData } from '../apps/actions';
import { getLogs } from '../log/actions';
import { getAllReleaseFlags } from '../releaseFlag/actions';
import { getTopics } from '../topic/actions';
import * as actionTypes from './actionTypes';
import { getUserPermissions } from './selectors';

export const getOrganizations = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_ALL_REQUEST,
  });

  return API.getOrganizations(state().account.userId)
    .then((data) => {
      dispatch({
        type: actionTypes.GET_ALL_SUCCESS,
        orgs: data.orgs,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_ALL_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getBalance = () => (dispatch, state) => {
  const userContextData = getUserContextData(state());

  dispatch({
    type: actionTypes.GET_BALANCE_REQUEST,
  });

  return API.getBalance(userContextData)
    .then((data) => {
      dispatch({
        type: actionTypes.GET_BALANCE_SUCCESS,
        balance: new BalanceModel(data),
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_BALANCE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getBalanceHistory = (filterOptions) => (dispatch, state) => {
  dispatch({ type: actionTypes.GET_BALANCEHISTORY_REQUEST });

  return API.getBalanceHistory(getUserContextData(state), filterOptions)
    .then((data) => {
      dispatch({
        type: actionTypes.GET_BALANCEHISTORY_SUCCESS,
        balanceHistory: data,
      });

      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_BALANCEHISTORY_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export const getAllBilling = () => (dispatch, getState) => {
  const userContextData = getUserContextData(getState());
  const userPermissions = getUserPermissions(getState());

  const hasBillingPermission = userPermissions.includes(Permissions.BILLING);
  const hasBillingVisiblePermission = userPermissions.includes(Permissions.BILLING_VISIBLE);

  const promises = [
    API.getBillingInformation(userContextData),
    API.getPackage(userContextData),
    API.getAllPackages(),
    ...(hasBillingPermission ? [API.getBalanceHistory(userContextData)] : []),
    ...(hasBillingVisiblePermission ? [API.getBalance(userContextData)] : []),
  ];

  // Because the promise array is dynamic, we need to adjust the balance index based on whether the balance history was in the list
  const balanceIndex = hasBillingPermission ? 4 : 3;

  dispatch({
    type: actionTypes.GET_ALL_BILLING_REQUEST,
  });

  return Promise.all(promises)
    .then((data) => ({
      billingInformation: new BillingInformationModel(data[0]),
      package: data[1][0] ?? {}, // API returns a list, we want the first one
      allPackages: data[2],
      balanceHistory: hasBillingPermission ? data[3] : [],
      balance: new BalanceModel(hasBillingVisiblePermission ? data[balanceIndex] : {}),
    }))
    .then((transformedData) => {
      dispatch({
        type: actionTypes.GET_ALL_BILLING_SUCCESS,
        billingInformation: transformedData.billingInformation,
        balance: transformedData.balance,
        balanceHistory: transformedData.balanceHistory,
        package: transformedData.package,
        allPackages: transformedData.allPackages,
      });

      return Promise.resolve({
        billingInformation: transformedData.billingInformation,
        balance: transformedData.balance,
        balanceHistory: transformedData.balanceHistory,
        package: transformedData.package,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_ALL_BILLING_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getBillingInformation = () => (dispatch, state) => {
  dispatch({ type: actionTypes.GET_BILLING_INFORMATION });

  return API.getBillingInformation(getUserContextData(state()))
    .then((data) => {
      dispatch({
        type: actionTypes.GET_BILLING_INFORMATION_SUCCESS,
        billingInformation: new BillingInformationModel(data),
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_BILLING_INFORMATION_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const createOrganization = (name) => (dispatch, state) => {
  const store = state();
  const previousOrgCount = store.organization.orgs.length;

  dispatch({
    type: actionTypes.CREATE_ORG_REQUEST,
  });

  return API.createOrganization(name)
    .then((org) => {
      dispatch({
        type: actionTypes.CREATE_ORG_SUCCESS,
        org,
      });

      dispatch({
        type: actionTypes.CHANGE_CONTEXT,
        org,
      });

      const settings = {
        'Default Org': org.id,
      };

      let promises: Array<any> = [];

      if (previousOrgCount === 1) {
        promises = [accountActions.updateUserSettings(settings)(dispatch, state)];
      }

      sendAnalyticsEvent({
        type: analyticsTypes.ORG_CREATED,
        data: {
          name,
          date: Date.now(),
        },
      });

      return Promise.all(promises);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE_ORG_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const convertPersonalToOrg = (name, convertBilling) => (dispatch, store) => {
  dispatch({
    type: actionTypes.CONVERT_PERSONAL_TO_ORG_REQUEST,
  });

  let orgToConverTo;

  return API.convertPersonalToOrg(name, convertBilling)
    .then((org) => {
      dispatch({
        type: actionTypes.CONVERT_PERSONAL_TO_ORG_SUCCESS,
        org,
      });

      orgToConverTo = org;

      sendAnalyticsEvent({
        type: analyticsTypes.ORG_CREATED,
        data: {
          name: org.name,
          date: Date.now(),
        },
      });

      return getOrganizations()(dispatch, store);
    })
    .then(() => {
      dispatch({
        type: actionTypes.CHANGE_CONTEXT,
        org: orgToConverTo,
      });

      return undefined;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CONVERT_PERSONAL_TO_ORG_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export const updateOrganization = (id, name, ccemails?: string) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_ORG_REQUEST,
  });

  return API.updateOrganization(id, name, ccemails)
    .then((org) => {
      dispatch({
        type: actionTypes.UPDATE_ORG_SUCCESS,
        org,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_ORG_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const addUsersToOrg = (emails, permissions) => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.ADD_USER_REQUEST,
    emails: emails.join(', '),
  });

  return API.addUsersToOrg(userContextData.orgId, emails, permissions)
    .then((updatedOrg) => {
      dispatch({
        type: actionTypes.ADD_USER_SUCCESS,
        updatedOrg,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.ADD_USER_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export const updateUserPermissions = (userIds, permissions) => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.UPDATE_PERMISSIONS_REQUEST,
  });

  return API.updateUserPermissions(userContextData.orgId, userIds, permissions)
    .then((updatedOrg) => {
      dispatch({
        type: actionTypes.UPDATE_PERMISSIONS_SUCCESS,
        updatedOrg,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_PERMISSIONS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const removeUsers = (userIds) => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.REMOVE_USERS_REQUEST,
  });

  return API.removeUsers(userContextData.orgId, userIds)
    .then((updatedOrg) => {
      dispatch({
        type: actionTypes.REMOVE_USERS_SUCCESS,
        updatedOrg,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REMOVE_USERS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const resendInvitation = (inviteId) => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.RESEND_INVITE_REQUEST,
  });

  return API.resendInvitation(inviteId, userContextData)
    .then(() => {
      dispatch({
        type: actionTypes.RESEND_INVITE_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.RESEND_INVITE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const cancelInvitation = (inviteId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.CANCEL_INVITE_REQUEST,
  });

  return API.cancelInvitation(inviteId)
    .then(() => {
      dispatch({
        type: actionTypes.CANCEL_INVITE_SUCCESS,
      });
      return getOrganizations()(dispatch, state);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CANCEL_INVITE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getDeviceCount = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_DEVICE_COUNT_REQUEST,
  });

  const userContextData = getUserContextData(state);

  return Promise.all([
    API.getDevicesCount({ states: DeviceStates.activeStates }, userContextData),
    API.getDevicesCount({ states: DeviceStates.preflightStates }, userContextData),
    API.getDevicesCount({ states: DeviceStates.deactivatedStates }, userContextData),
  ])
    .then((results) => {
      const activeResults = results[0].data;
      const preflightResults = results[1].data;
      const deactivatedResults = results[2].data;
      const totalDevices = activeResults + preflightResults + deactivatedResults;

      const resultSet = {
        deviceCount: totalDevices,
        deviceCountActive: activeResults,
        deviceCountPreflight: preflightResults,
        deviceCountDeactivated: deactivatedResults,
      };

      dispatch({
        type: actionTypes.GET_DEVICE_COUNT_SUCCESS,
        ...resultSet,
      });

      return resultSet;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_DEVICE_COUNT_ERROR,
        error,
      });
    });
};

export const changeContext = (org, doRedirect?: any, path?: any) => (dispatch, state) => {
  let newOrg = _.find(state().organization.orgs, { id: parseInt(org, 10) });

  if (!newOrg) newOrg = _.find(state().organization.orgs, { is_personal: 1 });

  if (!newOrg?.id) {
    return undefined;
  }

  const { persistor } = upsertStore(newOrg.id);

  return persistor.flush().then(() => {
    dispatch({
      type: actionTypes.CHANGE_CONTEXT,
      org: newOrg,
    });

    const actions = bindActionCreators(
      {
        getOrganizations,
        getAllReleaseFlags,
        getAllAppData,
        getLogs,
        getTopics,
        hideActivationNotification,
      },
      dispatch
    );

    const nonBlocking = [
      actions.hideActivationNotification(),
      actions.getAllAppData(),
      actions.getLogs(),
      actions.getTopics(),
    ];

    const blocking = [
      actions.getOrganizations(),
      actions.getAllReleaseFlags(),
      getDeviceCount()(dispatch, state),
    ];

    Promise.all(nonBlocking);

    return Promise.all(blocking).then(() => {
      if (doRedirect && path) {
        browserHistory.push(Paths.withContext(path, newOrg.id));
      } else if (doRedirect) {
        const { releaseFlag } = state();
        const hasEureka = releaseFlag.eureka0;
        const hasPromoteSims = releaseFlag.promote_sims_pages;

        let futurePath;

        if (hasEureka) {
          futurePath = Paths.HOME_OVERVIEW;
        } else if (hasPromoteSims) {
          futurePath = Paths.SIM_INVENTORY;
        } else {
          futurePath = Paths.DEVICES_DEVICES;
        }

        browserHistory.push(Paths.withContext(futurePath, newOrg.id));
      }
    });
  });
};

// These actions aren't used by the reducer
export function addBalance(amount) {
  return (dispatch, state) => {
    const userContextData = getUserContextData(state);

    dispatch({
      type: actionTypes.ADD_BALANCE_REQUEST,
      amount,
    });

    return API.addBalance(amount, userContextData)
      .then((balance) => {
        dispatch({
          type: actionTypes.ADD_BALANCE_SUCCESS,
          balance,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_BALANCE_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export const updateBalanceParams = (amountToAdd, threshold) => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.UPDATE_BALANCE_PARAMS_REQUEST,
  });

  return API.updateBalanceParams(amountToAdd, threshold, userContextData)
    .then((data) => {
      dispatch({
        type: actionTypes.UPDATE_BALANCE_PARAMS_SUCCESS,
        topOffAmount: parseFloat(data.topoffamount),
        minBalance: parseFloat(data.minbalance),
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_BALANCE_PARAMS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const autoJoinOrg = (orgId, inviteId, emailVerifyToken) => (dispatch, state) => {
  dispatch({
    type: actionTypes.AUTO_JOIN_ORG_REQUEST,
  });

  return API.acceptInvitation(inviteId, emailVerifyToken)
    .then(() => getOrganizations()(dispatch, state))
    .then(() => {
      const orgToJoin = _.find(state().organization.orgs, {
        id: parseInt(orgId, 10),
      });

      if (orgToJoin) {
        changeContext(orgToJoin)(dispatch, state);
        const { userEmail, userId } = getUserContextData(state);

        dispatch({
          type: actionTypes.AUTO_JOIN_ORG_SUCCESS,
          orgId,
          userId,
          userEmail,
        });

        openModal('OrgIntroModal', undefined, 'narrow no-style');
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.AUTO_JOIN_ORG_ERROR,
        error,
      });
      pushGlobalMessage(error, 'error');
    });
};

export const getOrgGroups = () => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.GET_ORGGROUPS_REQUEST,
  });

  return API.getOrgGroups(
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  )
    .then((groups) => {
      dispatch({
        type: actionTypes.GET_ORGGROUPS_SUCCESS,
        groups,
      });

      return Promise.resolve(groups);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_ORGGROUPS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getTotalDeviceCount = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_TOTAL_DEVICE_COUNT_REQUEST,
  });

  const userContextData = getUserContextData(state);

  return API.getDevicesCount({}, userContextData)
    .then((result) => {
      dispatch({
        type: actionTypes.GET_TOTAL_DEVICE_COUNT_SUCCESS,
        deviceCount: result.data,
      });

      return {
        deviceCount: result.data,
      };
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_TOTAL_DEVICE_COUNT_ERROR,
        error,
      });
    });
};

export const getPlanDeviceSummary = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_PLANDEVICE_SUMMARY_REQUEST,
  });

  return API.getPlanDeviceSummary(getUserContextData(state))
    .then((devicePlanSummary) => {
      dispatch({
        type: actionTypes.GET_PLANDEVICE_SUMMARY_SUCCESS,
        devicePlanSummary,
      });
      return Promise.resolve(devicePlanSummary);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_PLANDEVICE_SUMMARY_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const updatePlanUpgrade = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.PUT_UPGRADE_REQUEST,
  });

  return API.updatePlanUpgrade(getUserContextData(state))
    .then(() => {
      dispatch({
        type: actionTypes.PUT_UPGRADE_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.PUT_UPGRADE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getBillingStatements = () => (dispatch, state) =>
  API.getBillingStatements(getUserContextData(state))
    .then((statements) => {
      const sortedStatements = statements.sort((a, b) =>
        moment(b.report_start_date).diff(moment(a.report_start_date))
      );
      dispatch({
        type: actionTypes.GET_BILLING_STATEMENTS_SUCCESS,
        statements: sortedStatements,
      });
    })
    .catch((error) => Promise.reject(error));

export const getActiveInvoices = () => (dispatch, state) =>
  // Stripe & our API don't offer multiple status filters, so we must make a call for each
  // status that is considered "active"
  Promise.all([
    API.getInvoices(getUserContextData(state), { status: 'open' }),
    API.getInvoices(getUserContextData(state), { status: 'paid' }),
  ])
    .then((results) => {
      dispatch({
        type: actionTypes.GET_INVOICES_SUCCESS,
        invoices: results[0].concat(results[1]),
      });
    })
    .catch((error) => Promise.reject(error));
