import { API_URL, headers, getHeaders } from '../config';
import { fetchHandler, fetchErrorHandler } from '../middleware';

export function addTag(name, deviceIds, userContextData) {
  let dynamicQuery = '';

  if (userContextData.orgId) {
    dynamicQuery += `?orgid=${userContextData.orgId}`;
  }

  return fetch(`${API_URL}/devices/tags${dynamicQuery}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      name,
      deviceids: deviceIds,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data.tags[0]), true, false))
    .catch(fetchErrorHandler);
}

export function getTags(userContextData) {
  let userQueryParams = '';

  if (userContextData.showAllDevices) {
    // nothing!
  } else if (!userContextData.isInOrgContext) {
    userQueryParams = `?orgid=${userContextData.userOrgId}`;
  } else {
    userQueryParams = `?orgid=${userContextData.orgId}`;
  }

  return fetch(`${API_URL}/devices/tags${userQueryParams}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler((body) => {
        body.data.tags.forEach((tag) => {
          tag.name = tag.name.replace('_P_', '');
        });

        return Promise.resolve({ tags: body.data.tags });
      })
    )
    .catch(fetchErrorHandler);
}

export function updateTagName(tagId, newTagName, userContextData) {
  return fetch(
    `${API_URL}/devices/tags/${tagId}/updatetagname/${
      userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'PUT',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        name: newTagName,
      }),
    }
  )
    .then(
      fetchHandler((body) => {
        const updatedTag = body.data.tags[0];
        return Promise.resolve({ ...updatedTag, id: tagId });
      })
    )
    .catch(fetchErrorHandler);
}

export function removeTag(tagId, userContextData) {
  return fetch(
    `${API_URL}/devices/tags/${tagId}${
      userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'DELETE',
      credentials: 'include',
      headers,
    }
  )
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}
