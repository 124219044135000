export default function createObjectValidator(validators = {}) {
  function validateObject(object) {
    // Create the initial validation context object.
    const validationContext = { isValid: true };

    // Iterate through the validators and compose a validation state.
    Object.keys(validators).reduce((currentContext, propName) => {
      currentContext[propName] = validateProperty(propName, object[propName], object);

      if (!currentContext[propName].isValid) {
        currentContext.isValid = false;
      }

      return currentContext;
    }, validationContext);

    return validationContext;
  }

  // Get validation state that represents form that is not dirty, in this scenario user has just arrived
  // and no validation errors should show yet.
  function getCleanState() {
    const validationContext = { isValid: true };

    // Iterate through the validators and compose a clean validation state.
    Object.keys(validators).reduce((currentContext, propName) => {
      currentContext[propName] = [];
      currentContext[propName].isValid = true;
    }, validationContext);

    return validationContext;
  }

  function validateProperty(propName, propValue, object) {
    // If we have validators for this property name, run through them,
    // otherwise return an empty valid state for this property.
    if (validators[propName]) {
      const state = validators[propName]
        .map((validator) => validator(propValue, object))
        .filter((resolve) => !resolve.isValid);

      // If the array is not empty, we have errors and it's not valid.
      state.isValid = !state.length;

      return state;
    }
    const state = [];
    state.isValid = true;

    return state;
  }

  return {
    validations: validators,
    validateObject,
    validateProperty,
    getCleanState,
  };
}
