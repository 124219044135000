import React from 'react';
import { Button } from '@holokit/core';

const EmptyState = ({ onGenerateWebhook }) => (
  <div className="webhook-empty">
    <div className="Panel__info">
      Send messages directly to your device by issuing a POST request to a sharable endpoint.
    </div>
    <div className="setup Panel__padding">
      <p>Setup</p>
      <Button onClick={onGenerateWebhook} type="secondary">
        Generate Webhook
      </Button>
    </div>
  </div>
);

export default EmptyState;
