import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectPlanData } from 'common-js/reducers/activation/selectors';
import { getLimitValue } from 'common-js/utils/numberFormatter';
import { useFormContext } from 'react-hook-form';
import CompletedStepHeader from '../common/scaffolding/CompletedStepHeader';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';
import SidebarAttribute from '../common/scaffolding/SidebarAttribute';
import { amountCopy } from './LimitTranslations';

const STEP_NAME = 'Set data usage limits';

interface LimitsSidebarItemProps extends SidebarItemProps {}

function LimitsSidebarItem({ step, currentStep }: LimitsSidebarItemProps) {
  const { getValues } = useFormContext();
  const usageLimit: UsageLimitObject = getValues('usageLimit');
  const { type: limitType } = usageLimit;
  const planData = useAppSelector((state) => selectPlanData(state, getValues('plan.id')));

  const planDataLimit = planData?.dataLimit;

  if (currentStep > step) {
    return (
      <CompletedStepHeader name={STEP_NAME}>
        <SidebarAttribute name="Data Usage Limit" value={limitType ? 'Yes' : 'Not set'} />
        <SidebarAttribute
          name="Amount"
          value={limitType ? amountCopy[limitType] : 'Unlimited data usage'}
        />
        <SidebarAttribute
          name="Devices Paused At"
          value={limitType ? getLimitValue(usageLimit, planDataLimit) : 'Never'}
        />
      </CompletedStepHeader>
    );
  }

  return <IncompleteStepHeader step={step} currentStep={currentStep} name={STEP_NAME} />;
}

export default LimitsSidebarItem;
