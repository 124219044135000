import React from 'react';
import { SubMenu as RoutesSubMenu } from '../apps/components';
import { FullHeightCalculatorHOC } from '../common/components';
import BaseLayout from './BaseLayout';

interface LayoutRoutesProps {
  children?: React.ReactNode;
}

const LayoutRoutes: React.FC<LayoutRoutesProps> = ({ children }) => (
  <BaseLayout layoutName="routes">
    <RoutesSubMenu />
    <FullHeightCalculatorHOC accountForDrawer>{children}</FullHeightCalculatorHOC>
  </BaseLayout>
);

export default LayoutRoutes;
