import { RouterCredModel, WebhookModel } from '.';

export default class DeviceModel {
  activating = false;

  apn = null;

  carrierid = null;

  cur_billing_data_used = 0;

  has_sim = false;

  id = 0;

  isHyper = false;

  last_connect_time = null;

  last_network_used = null;

  linkId = null;

  orgId = null;

  overagelimit = null;

  overagelimitRaw = null;

  sim_number = null;

  state = null;

  sysState = null;

  whenclaimed = null;

  whencreated = null;

  whenexpires = null;

  cost = null;

  data = null;

  overage = null;

  plan = null;

  planId = null;

  sms = null;

  data_threshold = null;

  hasHydrated = false;

  name = null;

  phonenumber = null;

  routerCreds = new RouterCredModel();

  selected = null;

  statusHistory = [];

  statusReason = null;

  tags = [];

  tunnelable = false;

  webhook = new WebhookModel();

  zone = null;

  model = null;

  manufacturer = null;

  lastsession = {};

  isPausing = false;

  hologramSimId = 0;

  constructor(device) {
    if (!device) return;

    this.has_sim = device.links.cellular.length > 0;
    this.name = device.name;
    this.phonenumber = device.phonenumber;
    this.whencreated = device.whencreated;
    this.id = device.id;
    this.orgId = device.orgid;
    this.tunnelable = device.tunnelable === 1;
    this.imei = device.imei;
    this.euicc_tested = device.euicc_tested;
    this.model = device.model;
    this.manufacturer = device.manufacturer;
    this.isHyper = device.is_hyper;
    this.hologramSimId = device.simcardid;

    if (this.has_sim) {
      this.links = device.links;
      device.links.cellular.forEach((sim) => {
        this.cur_billing_data_used += Number(sim.cur_billing_data_used);

        if (device.links.cellular.length === 1 || sim.profile_state === 'ENABLED') {
          this.state = sim.state ? sim.state : null;
          this.last_connect_time = sim.last_connect_time ? sim.last_connect_time : null;
          this.apn = sim.apn ? sim.apn : null;
          this.carrierid = sim.carrierid ? sim.carrierid : null;
          this.last_network_used = sim.last_network_used ? sim.last_network_used : null;
          this.sim_number = sim.sim ? sim.sim : null;
          this.whenclaimed = sim.whenclaimed ? sim.whenclaimed : null;
          this.whenexpires = sim.whenexpires ? sim.whenexpires : null;

          // to-do: overage limit should be device-level attribute not sim-level
          switch (sim.overagelimit) {
            case 0:
              this.overagelimitLabel = 'No Overage';
              break;
            case -1:
              this.overagelimitLabel = 'Unlimited';
              break;
            default:
              this.overagelimitLabel = `${sim.overagelimit} Bytes`;
              break;
          }
          this.overagelimit = sim.overagelimit;

          if (sim.id) {
            this.linkId = sim.id;
          }

          if (sim.plan) {
            this.planId = sim.plan.id;
            this.plan = sim.plan.name;
            this.data = sim.plan.data;
            this.cost = sim.plan.cost;
            this.overage = sim.plan.overage;
            this.sms = sim.plan.sms;
          }

          this.zone = sim.plan ? sim.plan.zone : null;
          this.data_threshold =
            'data_threshold' in sim && sim.data_threshold ? sim.data_threshold : -1;
          this.euicc_type = sim.euicc_type;
          this.profile_state = sim.profile_state;
        }

        if (sim.eid) {
          this.eid = sim.eid;
        }
      });
    } else {
      this.cur_billing_data_used = null;
    }

    // Band-aid for overridden state. TODO: Remove upon completion of (https://app.asana.com/0/1107519327935707/1122446018608589)
    this.sysState = this.state;

    if (device.lastsession) {
      this.lastsession = device.lastsession;
      this.lastsession.imei = device.imei;

      if (device.lastsession.active) {
        this.state = 'CONNECTED';
      }
    }

    this.tags = device.tags.map((tag) => tag.id);
  }
}
