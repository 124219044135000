import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { attemptDocumentDownload } from 'common-js/api';
import { buildReportHref } from 'common-js/api/usage';
import { getUserContextData } from 'common-js/api/util';
import * as Paths from 'common-js/constants/paths';
import { drawerTakerOverLink, setDrawerTakeover } from 'common-js/reducers/drawer/actions';
import {
  getDataUsed,
  getUsageSummary,
  requestUsageReport,
  setReportDateTitle,
  syncFiltersToQueryString,
} from 'common-js/reducers/usage/actions';
import { buildChartFilters } from 'common-js/reducers/usage/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import UsageModeSelector from '../components/UsageModeSelector';
import { UsageOverview } from './parts';
import DataUsedChartWrapper from './parts/charts/DataUsedChartWrapper';

interface UsageOverviewPageProps {
  getDataUsed_: typeof getDataUsed;
  getUsageSummary_: typeof getUsageSummary;
  setDrawerTakeover_: typeof setDrawerTakeover;
  setReportDateTitle_: typeof setReportDateTitle;
  syncFiltersToQueryString_: typeof syncFiltersToQueryString;
  requestUsageReport_: typeof requestUsageReport;
  userContextData: any;
  drawerTakerOverLink_: typeof drawerTakerOverLink;
  error?: any;
  loading: boolean;
  reportFilters: unknown;
}

interface UsageOverviewPageState {
  isExportingCsv: boolean;
  redirecting: boolean;
}

class UsageOverviewPage extends React.Component<UsageOverviewPageProps, UsageOverviewPageState> {
  static showErrorToast() {
    toast(
      <HeadlineToast
        headline="Export Error"
        body="This report could not be generated automatically. Please contact success@hologram.io."
        icon="Circle--alert"
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toastify-content--burnt toastify-content--error',
        autoClose: 10000,
      }
    );
  }

  constructor(props: UsageOverviewPageProps) {
    super(props);

    this.state = {
      isExportingCsv: false,
      redirecting: true,
    };
    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Usage - Overview' } });
  }

  componentDidMount() {
    const {
      getDataUsed_,
      getUsageSummary_,
      setDrawerTakeover_,
      setReportDateTitle_,
      syncFiltersToQueryString_,
    } = this.props;

    setDrawerTakeover_(false);

    syncFiltersToQueryString_().finally(() => {
      setReportDateTitle_();
      getDataUsed_();
      getUsageSummary_();
      this.setState({ redirecting: false });
    });
  }

  onExportCSV = () => {
    const { requestUsageReport_ } = this.props;

    this.setState({ isExportingCsv: true }, () => {
      requestUsageReport_()
        .then(this.handleDocumentRequestReturn)
        .catch(UsageOverviewPage.showErrorToast);
    });
  };

  handleDocumentRequestReturn = (data) => {
    const { userContextData } = this.props;
    const { userEmail } = userContextData;

    if (data.finishedGenerating && data.documentId) {
      try {
        attemptDocumentDownload(data.documentId);
      } catch {
        UsageOverviewPage.showErrorToast();
      }
    } else {
      toast(
        <HeadlineToast
          headline="Generating CSV"
          body={`Your download is being prepared. We'll email ${userEmail} when it's ready.`}
          icon="Checkmark--single"
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toastify-content--burnt toastify-content--success',
          autoClose: 10000,
        }
      );
    }
    this.setState({ isExportingCsv: false });
  };

  render() {
    const { isExportingCsv, redirecting } = this.state;
    if (redirecting) return null;

    const { drawerTakerOverLink_, error, loading, reportFilters, userContextData } = this.props;

    return (
      <>
        <UsageModeSelector />
        <UsageOverview />
        <Panel
          isLoading={loading}
          header="Data Used"
          headerActions={
            <>
              <Button loading={isExportingCsv} onClick={this.onExportCSV} variant="secondary">
                Export CSV
              </Button>
              <Button
                href={buildReportHref('/usage', userContextData, reportFilters)}
                variant="secondary"
                target="_blank"
              >
                View JSON
              </Button>
              <Button
                className="inspect"
                iconStart="Inspect"
                onClick={() =>
                  drawerTakerOverLink_(
                    Paths.withContext(Paths.USAGE_INSPECT_DATAUSED),
                    Paths.USAGE_OVERVIEW
                  )
                }
                variant="secondary"
              >
                Inspect
              </Button>
            </>
          }
          footer="Use this chart to see trends on data consumption across selected devices. Unexpected changes to data usage patterns could indicate device issues."
          footerVariant="alternative"
        >
          <div className={(loading ? '' : 'Panel__padding') + (error ? ' error' : '')}>
            <DataUsedChartWrapper loadingHeight={210} />
          </div>
        </Panel>
      </>
    );
  }
}

const UsageOverviewHOC = connect(
  (state: any) => ({
    error: state.usage.charts.dataused.error,
    loading: state.usage.charts.dataused.loading,
    reportFilters: buildChartFilters(state),
    userContextData: getUserContextData(state),
  }),
  (dispatch: any) =>
    bindActionCreators(
      {
        drawerTakerOverLink_: drawerTakerOverLink,
        setDrawerTakeover_: setDrawerTakeover,
        syncFiltersToQueryString_: syncFiltersToQueryString,
        getUsageSummary_: getUsageSummary,
        getDataUsed_: getDataUsed,
        setReportDateTitle_: setReportDateTitle,
        requestUsageReport_: requestUsageReport,
      },
      dispatch
    )
)(UsageOverviewPage);

export default UsageOverviewHOC;
