import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Checkbox } from '@holokit/core';
import { clearSelection, toggleFilter } from 'common-js/reducers/devices/actions';
import { getFilters } from 'common-js/reducers/devices/selectors';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

class DataPlanFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTruncated: true,
      updateAccordionHeight: false,
    };
  }

  handleExpand = () => {
    this.setState({
      isTruncated: false,
      updateAccordionHeight: true,
    });
    sendAnalyticsEvent({
      type: analyticsTypes.FILTERS_SEE_ALL_OPTIONS,
      data: {
        filter_name: 'plan_name',
      },
    });
  };

  updateAccordionHeightCallback = () => {
    this.setState({ updateAccordionHeight: false });
  };

  render() {
    const { clearSelection_, filters, dataPlans, toggleFilter_ } = this.props;
    const { isTruncated, updateAccordionHeight } = this.state;
    const hasDataPlans = !!dataPlans.length;
    if (!hasDataPlans) return null;

    const isCollapsed = isTruncated && dataPlans.length > 7;
    const dataPlansToDisplay = hasDataPlans && isCollapsed ? dataPlans.slice(0, 7) : dataPlans;

    return (
      <div className="DevicesFilter">
        <Accordion
          header="Data plan"
          iconLeft="SIM"
          isFiltered={'plan_name' in filters}
          updateAccordionHeight={updateAccordionHeight}
          updateAccordionHeightCallback={this.updateAccordionHeightCallback}
          accordionToggleCallback={({ open }) => {
            sendAnalyticsEvent({
              type: analyticsTypes.FILTERS_TOGGLE_ACCORDION,
              data: {
                accordion_name: 'Data plan filter',
                accordion_open: open,
              },
            });
          }}
        >
          {hasDataPlans && (
            <>
              <ul className="DevicesFilter__list">
                {dataPlansToDisplay.map((plan) => {
                  const textLabel =
                    plan.value.charAt(0).toUpperCase() + plan.value.substr(1).toLowerCase();
                  const active = filters.plan_name?.[plan.value];

                  return (
                    <li
                      className="DevicesFilter__list-item"
                      key={`${plan.value}${plan.num_devices}`}
                    >
                      <Checkbox
                        classes="DevicesFilter__checkbox DevicesFilter__checkbox--titlecase"
                        checked={active}
                        onChange={() => {
                          toggleFilter_('plan_name', plan.value);
                          clearSelection_();
                          sendAnalyticsEvent({
                            type: analyticsTypes.FILTERS_SET_FILTER,
                            data: {
                              filter_name: 'Data plan',
                              filter_value: plan.value,
                            },
                          });
                        }}
                        textLabel={textLabel}
                        name={plan.value}
                      />
                    </li>
                  );
                })}
              </ul>
              {isCollapsed && (
                <Button
                  classes="DevicesFilter__expand-toggle Button--minimal"
                  onClick={this.handleExpand}
                >
                  See all {dataPlans.length} Plans
                </Button>
              )}
            </>
          )}
        </Accordion>
      </div>
    );
  }
}

DataPlanFilter.defaultProps = {
  dataPlans: [],
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  dataPlans: state.deviceFilters.plan_name,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      toggleFilter_: toggleFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DataPlanFilter);
