import Script from 'next/script';
import { useCallback, useId, type FC } from 'react';

interface MktoFormProps {
  onFormReady?: () => void;
}

const MarketoForm: FC<MktoFormProps> = ({ onFormReady }) => {
  const munchkinId = process.env.VITE_MKTO_MUNCHKIN_ID;
  const regFormId = process.env.VITE_MKTO_REGISTER_FORM_ID;

  const handleFormLoad = useCallback(() => {
    if (onFormReady) {
      onFormReady();
    }
  }, [onFormReady]);

  const handleScriptLoaded = useCallback(() => {
    window.MktoForms2?.loadForm(
      `//${munchkinId}.mktoweb.com`,
      munchkinId!,
      regFormId!,
      handleFormLoad
    );
  }, [handleFormLoad, munchkinId, regFormId]);

  const marketoScriptId = useId();

  return (
    <>
      <Script
        src={`//${munchkinId}.mktoweb.com/js/forms2/js/forms2.min.js`}
        id={marketoScriptId}
        onLoad={handleScriptLoaded}
      />
      <form id={`mktoForm_${regFormId}`} />
    </>
  );
};

export default MarketoForm;
