import { getUserFriendlyBytes } from '@hologram-hyper-dashboard/components';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import * as d3 from 'd3';
import _ from 'lodash';
import Moment from 'moment-timezone';
import React, { RefObject } from 'react';

interface LiveUsageChartD3Props {
  data: Array<{
    linkid: LinkId;
    sim: string;
    record_id: number;
    session_begin: string;
    timestamp: string;
    bytes: number;
    imeisv: string;
    cellid: number;
    lac: number;
    radio_access_technology: string;
    network_name: string;
    devicename: string;
    deviceid: DeviceId;
    tags: Array<any>;
    is_reporting_window_with_usage: number;
    imei: string;
  }>;
}

class LiveUsageChartD3 extends React.Component<LiveUsageChartD3Props> {
  chartContainerRef: RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.chartContainerRef = React.createRef();
  }

  componentDidMount() {
    const getChartContainer = d3.select('.LiveUsageChart').node();

    const { data } = this.props;
    const timeZone = Moment.tz.guess();

    const mappedData = data.map((d) => {
      const fmt = 'YYYY-MM-DD HH:mm:ss';
      const getStartTime = d.session_begin;
      const m = Moment.utc(getStartTime, fmt);
      const localDate = m.local().format(fmt);
      const getDate = localDate.split(' ')[0];
      const getTime = localDate.split(' ')[1].split(':');
      const fullTime = {
        hour: getTime[0],
        minutes: getTime[1],
        seconds: getTime[2],
      };
      return {
        deviceid: d.deviceid,
        value: d.bytes,
        date: Moment(getDate)
          .hour(parseInt(fullTime.hour, 10))
          .minutes(parseInt(fullTime.minutes, 10))
          .tz(timeZone),
        parsedDate: d3.timeParse('%Y-%m-%d %H:%M:%S')(localDate),
      };
    });

    // nest data by time - same day, hour, minute
    const dataByDate = [
      ...d3
        .rollup(
          mappedData,
          (v) => ({
            count: v.length,
            total: d3.sum(v, (d) => d.value),
            avg: d3.mean(v, (d) => d.value),
            date: v[0].date,
          }),
          (d) => d.date.valueOf()
        )
        .entries(),
    ]
      .map(([key, value]) => ({ key, value }))
      .reverse();

    const dataArea = d3.select(this.chartContainerRef.current);

    // Define the div for the tooltip
    const tooltip = dataArea.append('div').attr('class', 'tooltip').style('opacity', 0);
    const tooltip2 = dataArea.append('div').attr('class', 'tooltip tooltip2').style('opacity', 0);

    const DATE_TOOLTIP_FORMAT = 'h:mma, zz';
    const DAY_TOOLTIP_FORMAT = 'MMM DD, YYYY';

    const now = Moment();
    let tooltipLocked = false;
    let hoveringOverBar = false;

    const debouncedCloseTooltip = _.debounce(() => {
      if (!tooltipLocked && !hoveringOverBar) {
        tooltip.transition().duration(200).style('opacity', 0);
      }
    }, 30);

    const svg = d3
      .select('svg.LiveUsageChart')
      .attr('width', (getChartContainer as any).getBoundingClientRect().width - 50)
      .on('mousemove', () => {
        debouncedCloseTooltip();
      })
      .on('click', () => {
        const bars = document.querySelectorAll('.bar-container');
        [].forEach.call(bars, (el: HTMLElement) => {
          el.classList.remove('active');
        });

        tooltip2.transition().duration(100).style('opacity', 0);
      });

    function getHighestY(highestBytes) {
      if (highestBytes < 1000) {
        return highestBytes + 100;
      }
      if (highestBytes >= 1000 && highestBytes < 1000000) {
        return highestBytes + 1000;
      }
      if (highestBytes >= 1000000 && highestBytes < 1000000000) {
        return highestBytes + 100000;
      }
      return highestBytes + 100000;
    }

    const margin = { top: 20, right: 0, bottom: 25, left: 60 };
    const width = +svg.attr('width') - margin.left - margin.right;
    const height = +svg.attr('height') - margin.top - margin.bottom;

    let x0: [Moment.Moment | undefined, Moment.Moment | undefined];
    let y0: [number, number];

    if (mappedData.length === 0) {
      x0 = [Moment(now).subtract(1, 'day'), now];
      y0 = [0, 10000000];
    } else {
      x0 = [
        d3.min(dataByDate, (d) => Moment(d.value.date).subtract(3, 'minutes')),
        d3.max(dataByDate, (d) => d.value.date),
      ];
      y0 = [0, d3.max(dataByDate, (d) => getHighestY(d.value.total))];
    }
    const x = d3.scaleTime().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    if (mappedData.length === 0) {
      x.domain([Moment(now).subtract(1, 'day'), now]);
      y.domain([0, 10000000]);
    } else {
      x.domain([
        d3.min(dataByDate, (d) => Moment(d.value.date).subtract(3, 'minutes'))!,
        d3.max(dataByDate, (d) => Moment(d.value.date).add(3, 'minutes'))!,
      ]);
      y.domain([0, d3.max(dataByDate, (d) => getHighestY(d.value.total))]);
    }

    const currentTZ = Moment.tz(timeZone).zoneAbbr();
    const formatMillisecond = d3.timeFormat('.%L');
    const formatSecond = d3.timeFormat(':%S');
    const formatMinute = d3.timeFormat(`%I:%M ${currentTZ}`);
    const formatHour = d3.timeFormat(`%I%p ${currentTZ}`);
    const formatDay = d3.timeFormat('%a %d');
    const formatWeek = d3.timeFormat('%b %d');
    const formatMonth = d3.timeFormat('%B');
    const formatYear = d3.timeFormat('%Y');

    function multiFormatDate(date) {
      if (d3.timeSecond(date) < date) {
        return formatMillisecond(date);
      }

      if (d3.timeMinute(date) < date) {
        return formatSecond(date);
      }

      if (d3.timeHour(date) < date) {
        return formatMinute(date);
      }

      if (d3.timeDay(date) < date) {
        return formatHour(date);
      }

      if (d3.timeMonth(date) < date && d3.timeWeek(date) < date) {
        return formatDay(date);
      }

      if (d3.timeMonth(date) < date) {
        return formatWeek(date);
      }

      if (d3.timeYear(date) < date) {
        return formatMonth(date);
      }

      return formatYear(date);
    }

    const xAxis = d3.axisBottom(x).tickFormat(multiFormatDate);
    const yAxis = d3
      .axisLeft(y)
      .ticks(6)
      .tickSize(-width)
      .tickFormat(getUserFriendlyBytes as any);

    const getMaxValueBetweenTimes = (time1: number, time2: number) => {
      const dataInView = dataByDate.filter((datum) => {
        const datumTime = datum.key;
        return datumTime >= time1 && datumTime <= time2;
      });

      if (dataInView.length === 0) return 10000000;

      const max = d3.max(dataInView, (datum) => datum.value.total);

      return getHighestY(max);
    };

    function zoom() {
      const t = svg.transition().duration(300);

      svg
        .select('.axis--x')
        .transition(t)
        .call(xAxis as any);
      svg
        .select('.axis--y')
        .transition(t)
        .call(yAxis as any);

      // transition the bars/spikes
      svg
        .selectAll('rect.bar')
        .transition(t)
        .attr('x', (d: any) => x(d.value.date))
        .attr('y', (d: any) => y(d.value.total) + 5)
        .attr('height', (d: any) =>
          height - y(d.value.total) - 5 < 0 ? 0 : height - y(d.value.total) - 5
        )
        .attr('opacity', (d: any) => (x(d.value.date) < 0 ? 0 : 1));

      svg
        .selectAll('circle.svg-circle')
        .style('opacity', (d: any) =>
          y(d.value.total) > 154 || x(d.value.date) + 0.5 < 0 ? 0 : 1
        );

      svg
        .selectAll('circle.svg-circle')
        .transition(t)
        .attr('cx', (d: any) => x(d.value.date) + 0.5)
        .attr('cy', (d: any) => y(d.value.total));

      svg
        .selectAll('text.number')
        .transition(t)
        .attr('x', (d: any) => (d.value.count < 10 ? x(d.value.date) - 1.2 : x(d.value.date) - 3))
        .attr('y', (d: any) => y(d.value.total) + 2);
    }

    let idleTimeout;

    function idled() {
      idleTimeout = null;
    }

    const idleDelay = 350;

    const brush = d3.brushX();

    // eslint-disable-next-line prefer-arrow-callback
    brush.on('end', function brushended(event) {
      const s = event.selection;

      if (!s) {
        if (!idleTimeout) {
          idleTimeout = setTimeout(idled, idleDelay);
          return idleTimeout;
        }
        x.domain(x0 as any);
        y.domain(y0);
      } else {
        x.domain([s[0], s[1]].map(x.invert, x));

        const time1 = x.invert(s[0] - 500); // This is weird, I know... but to get the date selection correct, we need an offset of around 500px.
        const time2 = x.invert(s[1] + 500);

        y.domain([0, getMaxValueBetweenTimes(time1.valueOf(), time2.valueOf())]);

        svg.select('.brush').call(brush.move as any, null);
      }

      zoom();
      return undefined;
    });

    const focus = svg
      .append('g')
      .attr('class', 'focus')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    focus.append('g').attr('class', 'brush').call(brush);

    focus
      .selectAll('circle')
      .data(dataByDate)
      .enter()
      .append('g')
      .attr('class', 'bar-container')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '7px') // hitbox for mouseover.
      .attr('cursor', 'pointer')
      // eslint-disable-next-line prefer-arrow-callback
      .on('click', function onCircleClick(event, d) {
        event.stopPropagation();

        const hoveredBar = d3
          .select(this)
          .node()
          ?.querySelector<SVGCircleElement>('circle.svg-circle');

        const bars = document.querySelectorAll('.bar-container');
        [].forEach.call(bars, (el: HTMLElement) => {
          el.classList.remove('active');
        });

        if (hoveredBar) {
          const parentRect = hoveredBar.viewportElement?.getBoundingClientRect()!;
          const rect = hoveredBar.getBoundingClientRect();

          if (parentRect && rect) {
            hoveredBar.parentElement?.classList.add('active');
            tooltip2
              .html(
                `<div><div class="tooltip-date"><span>${d.value.date.format(
                  DAY_TOOLTIP_FORMAT
                )}</span><span>${d.value.date.format(
                  DATE_TOOLTIP_FORMAT
                )}</span></div><hr><div class="tooltip-data">${toByteStringFormatted(
                  d.value.total
                )}</div><div class="tooltip-count">${d.value.count} ${
                  d.value.count === 1 ? 'session' : 'sessions'
                }</div></div>`
              )
              .style('left', `${Math.round(rect.left - parentRect.left + 13)}px`)
              .style('top', `${Math.round(rect.top - parentRect.top - 10)}px`);

            tooltip2.transition().duration(100).style('opacity', 1);
          }
        }
      })
      .on('mouseover', function onCircleMouseOver(event, d) {
        hoveringOverBar = true;

        tooltip.transition().duration(100).style('opacity', 1);

        const hoveredBar = d3
          .select(this)
          .node()
          ?.querySelector<SVGCircleElement>('circle.svg-circle');

        if (hoveredBar) {
          const parentRect = hoveredBar.viewportElement?.getBoundingClientRect()!;
          const rect = hoveredBar.getBoundingClientRect();

          if (parentRect && rect) {
            tooltip
              .html(
                `<div><div class="tooltip-date"><span>${d.value.date.format(
                  DAY_TOOLTIP_FORMAT
                )}</span><span>${d.value.date.format(
                  DATE_TOOLTIP_FORMAT
                )}</span></div><hr><div class="tooltip-data">${toByteStringFormatted(
                  d.value.total
                )}</div><div class="tooltip-count">${d.value.count} ${
                  d.value.count === 1 ? 'session' : 'sessions'
                }</div></div>`
              )
              .style('left', `${Math.round(rect.left - parentRect.left + 13)}px`)
              .style('top', `${Math.round(rect.top - parentRect.top - 10)}px`)
              .on('mouseover', () => {
                tooltipLocked = true;
                setTimeout(() => {
                  tooltipLocked = false;
                }, 100);
              });
          }
        }
      })
      .on('mouseout', () => {
        hoveringOverBar = false;
      })
      .append('circle')
      .attr('class', 'svg-circle')
      .attr('cx', (d) => x(d.value.date) + 1)
      .attr('cy', (d) => y(d.value.total))
      .attr('r', 2.5)
      .attr('fill', '#2F6AFF')
      .attr('opacity', 1);

    focus
      .selectAll('g.bar-container')
      .data(dataByDate)
      .append('rect')
      .attr('class', 'bar')
      // .style("fill", "#2F6AFF")
      .attr('x', (d) => x(d.value.date))
      // .attr("width", "1px")
      .attr('y', (d) => y(d.value.total) + 5)
      .attr('height', (d) =>
        height - y(d.value.total) - 5 < 0 ? 0 : height - y(d.value.total) - 5
      )
      .attr('opacity', 1);

    focus
      .selectAll('g.bar-container')
      .data(dataByDate)
      .append('text')
      .attr('class', 'number')
      .attr('x', (d) => (d.value.count < 10 ? x(d.value.date) - 1.2 : x(d.value.date) - 3))
      .attr('y', (d) => y(d.value.total) + 2)
      .text((d) => d.value.count);

    focus
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis);

    focus.append('g').attr('class', 'axis axis--y').call(yAxis);
  }

  render() {
    return (
      <div className="LiveUsageChartD3" ref={this.chartContainerRef}>
        <svg className="LiveUsageChart" width="986" height="200" />
      </div>
    );
  }
}

export default LiveUsageChartD3;
