import { selectHasBizLayerDeactivateFlag } from 'common-js/reducers/releaseFlag/selectors';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useDeviceStateChange from './useDeviceStateChange';

const useDeactivateDevice = () => {
  const useBusinessLayer: boolean = useAppSelector(selectHasBizLayerDeactivateFlag);

  return useDeviceStateChange({
    newState: 'deactivate',
    useBusinessLayer,
  });
};

export default useDeactivateDevice;
