import DevicePrefix from './DevicePrefix';
import DevicePrefixSidebarItem from './DevicePrefixSidebarItem';
import DevicePrefixFooter from './DevicePrefixFooter';

const devicePrefixFixture = ({ isOnboarding, isPreflight, changingPlan }) => ({
  stepName: 'Name devices',
  component: DevicePrefix,
  sidebarItem: DevicePrefixSidebarItem,
  footer: DevicePrefixFooter,
  title: 'Create custom device names',
  include: !isOnboarding && !isPreflight && !changingPlan,
  optional: true,
  formState: 'devicePrefix',
  defaultValues: {
    prefix: '',
  },
});

export default devicePrefixFixture;
