import { type FC, type ReactNode, type RefObject } from 'react';
import HoloStoreContext from './HoloStoreContext';

interface HoloStoreContextProviderProps {
  children?: ReactNode;
  alertBannerContainerRef: RefObject<HTMLDivElement>;
}

const HoloStoreContextProvider: FC<HoloStoreContextProviderProps> = ({
  children,
  alertBannerContainerRef,
}) => (
  <HoloStoreContext.Provider value={alertBannerContainerRef}>{children}</HoloStoreContext.Provider>
);

export default HoloStoreContextProvider;
