import React from 'react';
import PropTypes from 'prop-types';
import _classnames from 'clsx';
import { Icon, Panel } from '@holokit/core';
import { toByteString } from '../../common/utils/numberFormatter';
import DataPercentTrend from './DataPercentTrend';

function UsageOverview({ classModifier, data, error, loading }) {
  const {
    numdevices_usingdata_last: numDevicesUsingDataLast,
    numdevices_usingdata_prev: numDevicesUsingDataPrev,
    dataused_last: dataUsedLast,
    dataused_prev: dataUsedPrev,
    averageperdevice_last: averagePerDeviceLast,
    averageperdevice_prev: averagePerDevicePrev,
  } = { ...data };
  const usageDataExists = Object.values(data).some((datum) => datum);

  return (
    <div
      className={_classnames('UsageOverview', {
        [`UsageOverview${classModifier}`]: classModifier,
      })}
    >
      <Panel isLoading={loading} noBodyPadding title="Usage Overview">
        <div className={_classnames('Panel__padding', { error })}>
          {error && (
            <div className="UsageTable-nodata">
              <Icon
                classes="UsageTable-nodata-icon"
                name="Warning"
                size="major"
                svgProps={{ style: { fill: '#A80000' } }}
              />
              <div className="UsageTable-nodata-message">
                We had a problem loading data usage for these devices.
              </div>
            </div>
          )}
          {!error && usageDataExists && (
            <div className="UsageOverview-body">
              <div className="UsageOverview-body-section">
                <div className="UsageOverview-body-section-top">
                  <div className="UsageOverview-body-section-top-data">
                    {numDevicesUsingDataLast || 0}
                  </div>
                  {!loading && numDevicesUsingDataLast !== 0 && numDevicesUsingDataPrev !== 0 && (
                    <div className="UsageOverview-body-section-top-average">
                      <DataPercentTrend
                        current={numDevicesUsingDataLast}
                        previous={numDevicesUsingDataPrev}
                      />
                    </div>
                  )}
                </div>
                <div className="UsageOverview-body-section-bottom">Devices actively using data</div>
              </div>
              <div className="UsageOverview-body-section">
                <div className="UsageOverview-body-section-top">
                  <div className="UsageOverview-body-section-top-data">
                    {toByteString(dataUsedLast || 0)}
                  </div>
                  {!loading && dataUsedLast !== 0 && dataUsedPrev !== 0 && (
                    <div className="UsageOverview-body-section-top-average">
                      <DataPercentTrend current={dataUsedLast} previous={dataUsedPrev} />
                    </div>
                  )}
                </div>
                <div className="UsageOverview-body-section-bottom">Data used</div>
              </div>
              <div className="UsageOverview-body-section">
                <div className="UsageOverview-body-section-top">
                  <div className="UsageOverview-body-section-top-data">
                    {toByteString(averagePerDeviceLast || 0)}
                  </div>
                  {!loading && averagePerDeviceLast !== 0 && averagePerDevicePrev !== 0 && (
                    <div className="UsageOverview-body-section-top-average">
                      <DataPercentTrend
                        current={averagePerDeviceLast}
                        previous={averagePerDevicePrev}
                      />
                    </div>
                  )}
                </div>
                <div className="UsageOverview-body-section-bottom">Avg per device</div>
              </div>
            </div>
          )}
        </div>
        {!error && !usageDataExists && !loading && (
          <div className="UsageTable-nodata">
            <Icon
              classes="UsageTable-nodata-icon"
              name="Circle--info"
              size="major"
              svgProps={{ style: { fill: '#8008f7' } }}
            />
            <div className="UsageTable-nodata-message">
              There are no data sessions to display for this set of devices and specific time frame.
              Try adjusting tags or the selected date range.
            </div>
          </div>
        )}
      </Panel>
    </div>
  );
}

UsageOverview.propTypes = {
  classModifier: PropTypes.string,
  data: PropTypes.shape({
    numdevices_usingdata_last: PropTypes.number,
    numdevices_usingdata_prev: PropTypes.number,
    dataused_last: PropTypes.number,
    dataused_prev: PropTypes.number,
    averageperdevice_last: PropTypes.number,
    averageperdevice_prev: PropTypes.number,
  }),
  error: PropTypes.bool,
  loading: PropTypes.bool,
};

UsageOverview.defaultProps = {
  classModifier: null,
  data: {},
  error: null,
  loading: false,
};

export default UsageOverview;
