import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { Device } from 'common-js/types/Device';
import { createSelector } from 'reselect';
import selectTagsForDropdown from './selectTagsForDropdown';

// The DeviceModel for the currentDevice stores only the IDs for which tags are on the device
// this selector returns the full tag object for each of these tags, sorted most recent to oldest
const selectCurrentDeviceTags = createSelector(
  selectTagsForDropdown,
  selectCurrentDevice,
  (allTags, currentDevice: Device) => allTags.filter(({ id }) => currentDevice.tags.includes(id))
);

export default selectCurrentDeviceTags;
