import { Button } from '@hologram-dimension/button';
import { Callout, ComplexIcon, Icon, Panel } from '@holokit/core';
import _classnames from 'clsx';
import { BMPContactUsButton, BMPUpsellPanel } from 'common-js/components';
import { getTags } from 'common-js/reducers/deviceFilter/actions';
import { selectBmpTagsFeatureLevel } from 'common-js/reducers/releaseFlag/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { Component, MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import TagManagerRow from './TagManagerRow';

const UPSELL_COPY = {
  lite: 'Create up to 3 tags. To unlock more tags, upgrade your Hologram plan.',
  plus: 'Create up to 10 tags. To unlock more tags, upgrade your Hologram plan.',
  pro: 'You have unlimited access to tags.',
};

const enum TagSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  UNSORTED = 'UNSORTED',
}

type DdsButtonVariant = GetReactParameterBag<typeof Button>['variant'];

interface CreateTagButtonProps {
  onClick: MouseEventHandler;
  variant?: DdsButtonVariant;
}
const CreateTagButton = ({ onClick, variant = 'primary' }: CreateTagButtonProps) => (
  <Button iconStart="Plus" onClick={onClick} variant={variant}>
    Create new tag
  </Button>
);

const atBMPLimit = (bmpLevel: any, numTags: number) =>
  (bmpLevel === 'lite' && numTags >= 3) || (bmpLevel === 'plus' && numTags >= 10);

interface ContactUsButtonProps {
  bmpLevel: any;
  numTags: number;
  variant?: DdsButtonVariant;
}
const ContactUsButton = ({ bmpLevel, numTags, variant }: ContactUsButtonProps) => (
  <BMPContactUsButton
    flagLevel={bmpLevel}
    variant={variant}
    analyticsName="Tags Manager"
    analyticsData={{ limit: atBMPLimit(bmpLevel, numTags) ? 'at' : 'under' }}
  />
);

interface NoTagsContentProps {
  isLoading: boolean;
  onCreateTagClick: MouseEventHandler;
  bmpLevel: any;
}
export const NoTagsContent = ({ onCreateTagClick, bmpLevel, isLoading }: NoTagsContentProps) => {
  if (bmpLevel === 'none') {
    return (
      <>
        <div className="TagManager__empty">
          <ComplexIcon classes="TagManager__empty__image" name="tag-manager" />
          <div className="TagManager__empty__headline">Your organization currently has no tags</div>
          <p className="TagManager__empty__copy">
            Tags are used to help organize groups of devices within your organization.
          </p>
          <CreateTagButton onClick={onCreateTagClick} />
        </div>
        <div className="Panel__footer">
          <a
            className="TagManager__empty__link"
            href="https://support.hologram.io/hc/en-us/articles/360043510193-Tags"
            rel="noreferrer"
            target="_blank"
          >
            Learn more about Tags
          </a>
        </div>
      </>
    );
  }

  const copy = UPSELL_COPY[bmpLevel] ?? '';

  return (
    <BMPUpsellPanel
      headline="Keep devices organized with tags"
      copy={copy}
      buttons={
        <>
          {bmpLevel !== 'pro' && <ContactUsButton bmpLevel={bmpLevel} numTags={0} />}
          {!isLoading && <CreateTagButton onClick={onCreateTagClick} />}
        </>
      }
    />
  );
};

interface BMPUpsellCalloutProps {
  bmpLevel: any;
  numTags: number;
  error: boolean;
  showContactUs?: boolean;
}
const BMPUpsellCallout = ({
  bmpLevel,
  numTags,
  error,
  showContactUs = true,
}: BMPUpsellCalloutProps) => {
  // No callout shown for non-BMP customers or for pro customers
  if (bmpLevel === 'none' || bmpLevel === 'pro') {
    return null;
  }

  let copy = '';
  let calloutType = Callout.TYPE.DEFAULT;
  let variant: DdsButtonVariant = 'secondary';
  let limit: number = 0;

  if (bmpLevel === 'lite') {
    copy = 'Create up to 3 tags. To unlock more tags, upgrade your Hologram plan.';
    limit = 3;
  } else if (bmpLevel === 'plus') {
    copy = 'Create up to 10 tags. To unlock more tags, upgrade your Hologram plan.';
    limit = 10;
  }

  if (numTags >= limit) {
    calloutType = error ? Callout.TYPE.ERROR : Callout.TYPE.WARNING;
    variant = 'primary';
  }

  return (
    <Callout
      text={copy}
      type={calloutType}
      iconColor={Callout.THEME.COLOR}
      defaultIcon={calloutType !== Callout.TYPE.DEFAULT}
      className="TagManager__bmp-callout"
      rightElement={
        showContactUs && <ContactUsButton bmpLevel={bmpLevel} numTags={numTags} variant={variant} />
      }
    />
  );
};

const getButtonVariant = (bmpLevel: any, numTags: number) =>
  bmpLevel !== 'none' && atBMPLimit(bmpLevel, numTags) ? 'secondary' : 'primary';

const HeaderCreateButton = ({ bmpLevel, hasTags, numTags, onCreateTagClickHandler }) =>
  hasTags && (
    <CreateTagButton
      variant={getButtonVariant(bmpLevel, numTags)}
      onClick={onCreateTagClickHandler}
    />
  );

interface TagManagerProps {
  allowDeletingActiveTags?: boolean;
  bmpLevel: string;
  className: string;
  onCreate?: AnyFunction;
  noBodyPadding?: boolean;
  showContactUs?: boolean;
  tags: Array<Tag>;
  title?: string;
  getTags_: typeof getTags;
  onEdit?: () => void;
  onDelete?: (tagId: number) => void;
  onToggleCheckbox?: (tag: Tag, checked: boolean) => void;
  selectedTagIds?: Array<number>;
  showCheckboxes?: boolean;
}

interface TagManagerState {
  showAddTagForm: boolean;
  isLoading: boolean;
  sortColumn: string | null;
  sortDirection: TagSortDirection;
  bmpError: boolean;
}
class TagManager extends Component<TagManagerProps, TagManagerState> {
  static showToast = (message: string, success: boolean) => {
    toast(
      <HeadlineToast
        headline={success ? 'Success' : 'Something went wrong'}
        body={message}
        icon={success ? 'Checkmark--single' : 'Warning'}
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        className: _classnames('toastify-content--burnt', {
          'toastify-content--success': success,
          'toastify-content--error': !success,
        }),
        autoClose: 10000,
      }
    );
  };

  static getTagProperty = (sortColumn: string | null, tag: Tag) => {
    if (sortColumn === 'name') {
      return tag.name.toUpperCase();
    }
    if (sortColumn === 'deviceids') {
      return tag.deviceids?.length ?? 0;
    }

    return 0;
  };

  static returnLessThanOrder = (sortDirection: TagSortDirection) => {
    if (sortDirection === TagSortDirection.ASC) {
      return -1;
    }
    if (sortDirection === TagSortDirection.DESC) {
      return 1;
    }
    return 0;
  };

  static returnGreaterThanOrder = (sortDirection: TagSortDirection) => {
    if (sortDirection === TagSortDirection.ASC) {
      return 1;
    }
    if (sortDirection === TagSortDirection.DESC) {
      return -1;
    }
    return 0;
  };

  constructor(props: TagManagerProps) {
    super(props);

    this.state = {
      showAddTagForm: false,
      isLoading: true,
      sortColumn: null,
      sortDirection: TagSortDirection.UNSORTED,
      bmpError: false,
    };
  }

  componentDidMount() {
    const { getTags_ } = this.props;
    getTags_()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  toggleAddTagForm = () => {
    const { showAddTagForm } = this.state;

    this.setState({ showAddTagForm: !showAddTagForm });
  };

  updateSort = (column: string) => {
    const { sortColumn, sortDirection } = this.state;
    if (sortColumn !== column || sortDirection === TagSortDirection.UNSORTED) {
      this.setState({
        sortColumn: column,
        sortDirection: TagSortDirection.ASC,
      });
    } else if (sortDirection === TagSortDirection.ASC) {
      this.setState({
        sortColumn: column,
        sortDirection: TagSortDirection.DESC,
      });
    } else if (sortDirection === TagSortDirection.DESC) {
      this.setState({
        sortColumn: column,
        sortDirection: TagSortDirection.UNSORTED,
      });
    }
  };

  renderSortIcon = (column: string) => {
    const { sortColumn, sortDirection } = this.state;

    let iconName = 'Arrow--triangle--northsouth';
    let iconSize = 'minor';
    let iconClasses = '';
    if (sortColumn === column) {
      if (sortDirection === TagSortDirection.ASC) {
        iconName = 'Arrow--triangle--north';
        iconSize = 'micro';
        iconClasses = 'Table__cell-content__icon--sorted';
      } else if (sortDirection === TagSortDirection.DESC) {
        iconName = 'Arrow--triangle--south';
        iconSize = 'micro';
        iconClasses = 'Table__cell-content__icon--sorted';
      }
    }

    return (
      <Icon
        classes={_classnames('Table__cell-content__icon', iconClasses)}
        name={iconName}
        size={iconSize}
      />
    );
  };

  renderTags = () => {
    const {
      allowDeletingActiveTags = true,
      onCreate,
      onDelete,
      onEdit,
      onToggleCheckbox,
      selectedTagIds = [],
      showCheckboxes,
      tags,
    } = this.props;

    const sortedTags = [...tags];
    const { sortColumn, sortDirection } = this.state;

    if (sortDirection === TagSortDirection.ASC || sortDirection === TagSortDirection.DESC) {
      sortedTags.sort((a, b) => {
        const tagPropertyA = TagManager.getTagProperty(sortColumn, a);
        const tagPropertyB = TagManager.getTagProperty(sortColumn, b);

        if (tagPropertyA < tagPropertyB) {
          return TagManager.returnLessThanOrder(sortDirection);
        }
        if (tagPropertyA > tagPropertyB) {
          return TagManager.returnGreaterThanOrder(sortDirection);
        }
        return 0;
      });
    }

    return sortedTags
      .filter((tag) => tag?.id)
      .map((tag) => (
        <TagManagerRow
          allowDeletingActiveTags={allowDeletingActiveTags}
          checked={selectedTagIds.includes(tag!.id!)}
          key={tag.id}
          onCreate={onCreate}
          onDelete={onDelete}
          onEdit={onEdit}
          onToggleCheckbox={onToggleCheckbox}
          showCheckbox={showCheckboxes}
          showToast={TagManager.showToast}
          tag={tag}
        />
      ));
  };

  onCreateTagClickHandler = () => {
    const { bmpLevel, tags } = this.props;

    if (bmpLevel !== 'none' && atBMPLimit(bmpLevel, tags?.length)) {
      this.setState({ bmpError: true });
    } else {
      this.setState({ showAddTagForm: true });
    }
  };

  render() {
    const {
      allowDeletingActiveTags = true,
      bmpLevel,
      className,
      onCreate,
      noBodyPadding = true,
      showContactUs = true,
      tags,
      title = 'Tags',
    } = this.props;

    const { showAddTagForm, isLoading, bmpError } = this.state;
    const hasTags = tags.length > 0;

    return (
      <div className={_classnames('TagManager', className)}>
        <Panel
          bodyClasses="TagManager__panel-body"
          classes={_classnames({ TagManager__panel: hasTags })}
          headerBorder={!hasTags}
          isLoading={isLoading}
          title={title}
          headerButtons={
            <HeaderCreateButton
              bmpLevel={bmpLevel}
              hasTags={hasTags}
              numTags={tags.length}
              onCreateTagClickHandler={this.onCreateTagClickHandler}
            />
          }
          noBodyPadding={noBodyPadding}
        >
          {hasTags || showAddTagForm ? (
            <>
              <BMPUpsellCallout
                bmpLevel={bmpLevel}
                numTags={tags.length}
                error={bmpError}
                showContactUs={showContactUs}
              />

              <div className="TagManager__scroll-wrap">
                <table className="Table Table--fixed Table--short">
                  <thead className="Table__header Table__header--sticky">
                    <tr>
                      <th className="Table__cell--header">
                        <div
                          className="Table__cell-content Table__cell-content--sort"
                          onClick={() => this.updateSort('name')}
                          onKeyUp={() => this.updateSort('name')}
                          role="button"
                          tabIndex={0}
                        >
                          Tag Name
                          {this.renderSortIcon('name')}
                        </div>
                      </th>
                      <th className="Table__cell--header" colSpan={2}>
                        <div
                          className="Table__cell-content Table__cell-content--sort"
                          onClick={() => this.updateSort('deviceids')}
                          onKeyUp={() => this.updateSort('deviceids')}
                          role="button"
                          tabIndex={0}
                        >
                          Applied To
                          {this.renderSortIcon('deviceids')}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {showAddTagForm && (
                      <TagManagerRow
                        showToast={TagManager.showToast}
                        toggleAddTagForm={this.toggleAddTagForm}
                        allowDeletingActiveTags={allowDeletingActiveTags}
                        onCreate={onCreate}
                      />
                    )}
                    {this.renderTags()}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <NoTagsContent
              isLoading={isLoading}
              onCreateTagClick={this.onCreateTagClickHandler}
              bmpLevel={bmpLevel}
            />
          )}
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  bmpLevel: selectBmpTagsFeatureLevel(state),
  tags: state.deviceFilters.tags,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getTags_: getTags,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TagManager);
