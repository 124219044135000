import _ from 'lodash';
import { bindActionCreators } from 'redux';

import * as actionTypes from './actionTypes';
import * as API from '../../api';
import { getLogs } from '../log/actions';
import { getUserContextData } from '../../api/util';

export function getTags() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_TAGS_REQUEST });

    return API.getTags(getUserContextData(state))
      .then((data) => {
        dispatch({ type: actionTypes.GET_TAGS_SUCCESS, tags: data.tags });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_TAGS_ERROR, error });
        return Promise.reject(error);
      });
  };
}

export function getFilterValues(filters) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_FILTER_VALUES_REQUEST });

    return API.searchDevices(
      {
        counts: filters,
        hitsPerPage: 0,
      },
      getUserContextData(state)
    )
      .then((data) => {
        dispatch({
          type: actionTypes.GET_FILTER_VALUES_SUCCESS,
          filters: data.counts,
        });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_FILTER_VALUES_ERROR, error });
        return Promise.reject(error);
      });
  };
}

export function patchFilters(filters, containerSource = 'devices') {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.PATCH_FILTERS,
      filters,
    });

    respondToFilterChanges(containerSource)(dispatch, state);
  };
}

export function toggleTag(tagToToggle, containerSource = 'devices') {
  return (dispatch, state) => {
    const lowerSource = containerSource.toLowerCase();

    dispatch({
      type: actionTypes.FILTER_TOGGLE_TAG,
      tagToToggle,
    });

    respondToFilterChanges(lowerSource)(dispatch, state);
  };
}

export function toggleAllDevices(containerSource = 'devices') {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.FILTER_TOGGLE_ALL_DEVICES,
    });

    respondToFilterChanges(containerSource)(dispatch, state);
  };
}

export function changeTagName(id, newName) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.TAGNAME_CHANGE_REQUEST,
    });

    return API.updateTagName(id, newName, getUserContextData(state))
      .then((updatedTag) => {
        dispatch({
          type: actionTypes.TAGNAME_CHANGE_SUCCESS,
          updatedTag,
        });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.TAGNAME_CHANGE_ERROR, error });
        return Promise.reject(error);
      });
  };
}

export function addNewTag(name, devices = []) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.ADD_NEW_TAG_REQUEST,
      name,
      devices,
    });

    return API.addTag(name, devices, getUserContextData(state))
      .then((newTag) => {
        dispatch({
          type: actionTypes.ADD_NEW_TAG_SUCCESS,
          newTag,
          devices,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_NEW_TAG_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function removeTag(tagId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.REMOVE_TAG_REQUEST,
    });

    return API.removeTag(tagId, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_TAG_SUCCESS,
          tagId,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REMOVE_TAG_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function selectAllTags(containerSource = 'devices') {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.TAGS_SELECT_ALL,
    });

    respondToFilterChanges(containerSource)(dispatch, state);
  };
}

export function deselectAllTags(containerSource = 'devices') {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.TAGS_DESELECT_ALL,
    });

    respondToFilterChanges(containerSource)(dispatch, state);
  };
}

export function respondToFilterChanges(containerSource = 'devices') {
  return (dispatch, state) => {
    onFilterChange(containerSource)(dispatch, state);
  };
}

export function onFilterChange(containerSource) {
  return (dispatch, state) => {
    if (containerSource === 'devices') {
      updatePageData(dispatch, state);
    }
  };
}

function updatePageData(dispatch, store) {
  _debouncedUpdateLogs(dispatch, store);
}

var _debouncedUpdateLogs = _.debounce((dispatch) => {
  bindActionCreators({ getLogs }, dispatch).getLogs();
}, 200);
