import { getOrgIdFromContextData } from 'common-js/api/util';
import { TASK_PAGE_SIZE } from 'common-js/reducers/devices/reducer';
import { find } from 'lodash';
import queryString from 'query-string';
import { DeviceModel, LogModel, RouterCredModel, WebhookModel } from '../../models';
import { generateRandomLogData } from '../../utils/mockLogs';
import { API_URL, BIZ_API_URL, getHeaders, headers } from '../config';
import { extendedFetchErrorHandler, fetchErrorHandler, fetchHandler } from '../middleware';
import buildDevicesQueryFiltersQuerystring from './buildDevicesQueryFiltersQuerystring';

const GENERATE_RANDOM_LOG_INFO = false;
const GENERATE_RANDOM_LOG_ONE_INFO = false;

export function getDevice(deviceId, userContextData) {
  return fetch(
    `${API_URL}/devices/${deviceId}?witheuicc=1${
      userContextData.orgId ? `&orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(
      fetchHandler((body) => {
        const newDeviceModel = new DeviceModel(body.data);
        return Promise.resolve(newDeviceModel);
      })
    )
    .catch(fetchErrorHandler);
}

export function getRouterCreds(deviceId, userContextData) {
  return fetch(
    `${API_URL}/csr/sources/?deviceid=${deviceId}${
      userContextData.orgId ? `&orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(
      fetchHandler((body) => {
        const newRouterCred = new RouterCredModel(body.data[0]);
        return Promise.resolve(newRouterCred);
      })
    )
    .catch(fetchErrorHandler);
}

export function generateRouterCreds(deviceId, userContextData) {
  return fetch(
    `${API_URL}/csr/sources/${userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''}`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        deviceid: deviceId,
      }),
    }
  )
    .then(
      fetchHandler((body) => {
        const newRouterCreds = new RouterCredModel(body.data);
        return Promise.resolve(newRouterCreds);
      })
    )
    .catch(fetchErrorHandler);
}

export function setTunnelable(deviceId, isTunnelable, userContextData) {
  return fetch(
    `${API_URL}/devices/${deviceId}${
      userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'PUT',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        tunnelable: isTunnelable,
      }),
    }
  )
    .then(fetchHandler(() => Promise.resolve(isTunnelable)))
    .catch(fetchErrorHandler);
}

export function setTunnelableBulk(deviceIds, isTunnelable, userContextData, devicesCache) {
  const promises = deviceIds.map((deviceId) =>
    fetch(
      `${API_URL}/devices/${deviceId}${
        userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
      }`,
      {
        method: 'PUT',
        credentials: 'include',
        headers,
        body: JSON.stringify({
          tunnelable: isTunnelable,
        }),
      }
    ).catch((error) => error)
  );

  let responses;

  return Promise.all(promises)
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then((parsedBodies) => {
      const successes = [];
      const failures = [];

      responses.forEach((response, idx) => {
        const correspondingDevice = devicesCache[deviceIds[idx]];

        if (response.ok) {
          successes.push({
            item: correspondingDevice ? correspondingDevice.name : 'Unknown device',
          });
        } else {
          let reason = 'Unknown failure';
          let item = 'Unknown device';

          if (parsedBodies[idx].error) {
            reason = parsedBodies[idx].error;
          } else {
            reason = response.statusText;
          }

          if (correspondingDevice) item = correspondingDevice.name;

          failures.push({ reason, item });
        }
      });

      return Promise.resolve({ successes, failures });
    })
    .catch((error) => Promise.reject(error));
}

/**
 * TODO: This should be updated to use hooks, currently only used by the BulkUpdateOverageForm
 */
export function bulkUpdateOverageLimit(deviceIds, limit, devicesCache, userContextData) {
  const orgId = getOrgIdFromContextData(userContextData);
  const promises = deviceIds.map((deviceId) =>
    fetch(`${API_URL}/devices/${deviceId}/usagelimit?orgid=${orgId}`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        overagelimit: limit,
      }),
    }).catch((error) => error)
  );

  let responses;

  return Promise.all(promises)
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then((parsedBodies) => {
      const successes = [];
      const failures = [];

      responses.forEach((response, idx) => {
        const deviceId = deviceIds[idx];
        const correspondingDevice = devicesCache[deviceId];

        if (response.ok) {
          successes.push({
            deviceId,
            item: correspondingDevice ? correspondingDevice.name : deviceId,
          });
        } else {
          let reason = 'Unknown failure';
          let item = 'Unknown device';

          if (parsedBodies[idx].error) {
            reason = parsedBodies[idx].error;
          } else if (!deviceId) {
            reason = "Device doesn't have a SIM link specified.";
          } else {
            reason = response.statusText;
          }

          if (deviceId && correspondingDevice) item = correspondingDevice.name;

          failures.push({ reason, item });
        }
      });

      return Promise.resolve({ successes, failures });
    })
    .catch((error) => Promise.reject(error));
}

export function getLogs(logFilters, limit, startAt, userContextData, requestId) {
  let dynamicQuery = '';

  if (startAt) {
    dynamicQuery += `&startat=${startAt}`;
  }

  if (limit) {
    dynamicQuery += `&limit=${limit}`;
  }

  dynamicQuery += `&orgid=${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }`;

  if (GENERATE_RANDOM_LOG_INFO || window.location.search.includes('logsdata=true')) {
    const randomLogData = generateRandomLogData(limit);

    return Promise.resolve({
      logs: randomLogData.map((log) => new LogModel(log)),
      continues: true,
    });
  }

  return fetch(`${API_URL}/csr/rdm?${logFilters.toGetLogsQueryString()}${dynamicQuery}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler((body) =>
        Promise.resolve({
          logs: body.data.map((log) => new LogModel(log)),
          continues: body.continues,
          requestId,
        })
      )
    )
    .catch(fetchErrorHandler);
}

export function getLogsForOne(deviceId, logFilters, limit, startAt, userContextData) {
  let dynamicQuery = '';

  if (startAt) {
    dynamicQuery += `&startat=${startAt}`;
  }

  if (limit) {
    dynamicQuery += `&limit=${limit}`;
  }

  dynamicQuery += `&orgid=${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }`;

  if (GENERATE_RANDOM_LOG_ONE_INFO || window.location.search.includes('logsforonedata=true')) {
    const logs = [];

    for (let i = 0; i < limit; i++) {
      const newLog = new LogModel();
      logs.push(newLog.generateRandom());
    }

    return Promise.resolve({ logs, continues: true });
  }

  return fetch(
    `${API_URL}/csr/rdm?deviceid=${deviceId}&${logFilters.toGetLogsQueryString()}${dynamicQuery}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(
      fetchHandler((body) =>
        Promise.resolve({
          logs: body.data.map((log) => new LogModel(log)),
          continues: body.continues,
        })
      )
    )
    .catch(fetchErrorHandler);
}

export function addPhoneNumber(deviceId, areaCode, country, userContextData) {
  return fetch(
    `${API_URL}/devices/${deviceId}/addnumber?orgid=${
      userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
    }`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        areacode: areaCode,
        country,
      }),
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.order_data)))
    .catch(fetchErrorHandler);
}

export function getPhoneNumberCost(deviceId, country, userContextData) {
  return fetch(
    `${API_URL}/devices/${deviceId}/addnumber?preview=1&orgid=${
      userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
    }`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        areacode: '',
        country,
      }),
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.order_data)))
    .catch(fetchErrorHandler);
}

export function sendSmsToDevice(deviceIds, message, fromnumber) {
  const postBody = {
    deviceids: deviceIds,
    body: message,
  };

  if (fromnumber) {
    postBody.fromnumber = fromnumber;
  }

  return fetch(`${API_URL}/sms/incoming/`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(postBody),
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export function sendDataToDevice(deviceIds, payload, port, protocol) {
  const postBody = {
    deviceids: deviceIds,
    data: payload,
    port,
    protocol,
  };

  return fetch(`${API_URL}/devices/messages`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(postBody),
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export function simMessageFromDevice(deviceId, data, topics) {
  const postBody = {
    deviceid: deviceId,
    data,
    tags: topics,
  };

  return fetch(`${API_URL}/csr/rdm`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(postBody),
  })
    .then(fetchHandler())
    .catch(fetchErrorHandler);
}

export function addTagToDevices(tagId, requestBody, userContextData) {
  return fetch(
    `${API_URL}/devices/tags/${tagId}/link${
      userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify(requestBody),
    }
  )
    .then(fetchHandler((body) => Promise.resolve({ ...body.data.tags[0] })))
    .catch(fetchErrorHandler);
}

export function removeTagFromDevices(tagId, requestBody, userContextData) {
  return fetch(
    `${API_URL}/devices/tags/${tagId}/unlink${
      userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify(requestBody),
    }
  )
    .then(fetchHandler((body) => Promise.resolve({ ...body.data.tags[0] })))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated (private function used by other deprecated functions)
 */
const pauseResumeDataBulk = ({
  selection,
  filters,
  isPreview,
  actionType,
  userContextData,
  useSearch,
  useBizLayer,
}) => {
  const orgId = getOrgIdFromContextData(userContextData);

  if (useBizLayer) {
    const action = actionType === 'pause' ? 'pause' : 'live';

    // To later be replaced by SIM IDs
    const selectedDeviceIds = Object.keys(selection?.byId || {});
    const legacyIds = selectedDeviceIds.map((deviceId) => ({
      deviceId: Number(deviceId),
    }));

    return fetch(`${BIZ_API_URL}/sims/${action}${isPreview ? '/preview' : ''}`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        orgId,
        legacyIds,
        filters: {
          ...filters,
          all: selection?.allSelected,
        },
      }),
    })
      .then(
        fetchHandler((body) => {
          const { data } = body;
          const { successes, failures } = data;

          if (isPreview) {
            // Previews have a different response structure
            return Promise.resolve({
              validLinksCount: (successes ?? []).length,
              validLinksSample: (successes ?? []).slice(0, 9),
              invalidLinksCount: (failures ?? []).length,
              invalidLinksSample: (failures ?? []).slice(0, 9),
            });
          }

          return Promise.resolve(data);
        })
      )
      .catch(fetchErrorHandler);
  }

  return fetch(`${API_URL}/devices/batch/state?orgid=${orgId}&preview=${isPreview ? 1 : 0}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      state: actionType,
      deviceids: Object.keys(selection?.byId || {}).map((id) => Number(id)),
      excludedDeviceIds: Object.keys(selection?.excludedIds || {}).map((id) => Number(id)),
      linkids: Object.keys(selection?.byLinkId || {}).map((id) => Number(id)),
      allSelected: selection?.allSelected,
      pagesSelected: selection.pagesSelected,
      useSearch,
      ignoreInvalidLinks: !isPreview,
      deviceFilters: {
        ...filters,
        hitsPerPage: selection?.allSelected ? 10000 : 250,
        orgId,
      },
    }),
  })
    .then(
      fetchHandler((body) => {
        const { data } = body;

        if (isPreview) {
          const taskData = Object.values(data.valid_tasks).reduce(
            (accTaskData, currentTaskData) => ({
              validLinksCount:
                accTaskData.validLinksCount + currentTaskData.task_results.valid_links_count,
              validLinksSample: [
                ...accTaskData.validLinksSample,
                ...currentTaskData.task_results.valid_links_sample,
              ],
              invalidLinksCount:
                accTaskData.invalidLinksCount + currentTaskData.task_results.invalid_links_count,
              invalidLinksSample: [
                ...accTaskData.invalidLinksSample,
                ...currentTaskData.task_results.invalid_links_sample,
              ],
            }),
            {
              validLinksCount: 0,
              validLinksSample: [],
              invalidLinksCount: 0,
              invalidLinksSample: [],
            }
          );
          return Promise.resolve({
            ...taskData,
            validLinksSample: taskData.validLinksSample.slice(0, 9),
            invalidLinksSample: taskData.invalidLinksSample.slice(0, 9),
          });
        }

        return Promise.resolve({
          preview: data?.preview,
          validTasks: data?.valid_tasks,
          jobId: data?.job_id,
        });
      })
    )
    .catch(fetchErrorHandler);
};

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export const pauseDataBulk = ({
  selection,
  filters,
  isPreview,
  userContextData,
  useSearch,
  useBizLayer,
}) =>
  pauseResumeDataBulk({
    actionType: 'pause',
    selection,
    filters,
    isPreview,
    userContextData,
    useSearch,
    useBizLayer,
  });

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export function bulkDeactivateDevices(
  deviceIds,
  devicesCache,
  orgId = null,
  hasBusinessLayerDeactivateFlag = false
) {
  if (hasBusinessLayerDeactivateFlag) {
    const reformattedLinks = deviceIds.map((deviceId) => ({
      linkId: deviceId,
    }));

    return fetch(`${BIZ_API_URL}/sims/deactivate`, {
      method: 'POST',
      body: {
        orgId,
        sims: reformattedLinks,
      },
    })
      .then(
        fetchHandler((body) => {
          const { successes, failures } = body;
          return {
            successes,
            failures,
          };
        })
      )
      .catch(fetchErrorHandler);
  }

  const requests = deviceIds.map((deviceId) =>
    fetch(`${API_URL}/devices/${deviceId}/state`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        state: 'deactivate',
      }),
    })
  );

  let responses;
  return Promise.all(requests)
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then((parsedResponses) => {
      const successes = [];
      const failures = [];

      responses.forEach((response, idx) => {
        const deviceId = deviceIds[idx];
        const correspondingDevice = find(devicesCache, {
          id: deviceId,
        });

        if (response.ok) {
          successes.push({
            deviceId,
            item: correspondingDevice ? correspondingDevice.name : deviceId,
          });
        } else {
          let reason = 'Unknown failure';
          let item = 'Unknown device';

          if (parsedResponses[idx].error) {
            reason = parsedResponses[idx].error;
          } else if (!deviceId) {
            reason = 'SIM is missing an ID. ';
          } else {
            reason = response.statusText;
          }

          if (deviceId && correspondingDevice) item = correspondingDevice.name;

          failures.push({ reason, item });
        }
      });

      return Promise.resolve({ successes, failures });
    })
    .catch((error) => Promise.reject(error));
}

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export function resumeDevice(linkId) {
  return fetch(`${API_URL}/links/cellular/${linkId}/state`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      state: 'live',
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data.newstate)))
    .catch(fetchErrorHandler);
}

export function requestHyperTest(deviceId, userContextData) {
  return fetch(`${API_URL}/devices/${deviceId}/euicc/request`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
    }),
  })
    .then(
      fetchHandler(() =>
        // Just returns success or error at the moment
        Promise.resolve()
      )
    )
    .catch(fetchErrorHandler);
}

// eslint-disable-next-line default-param-last
export function testDevice(deviceId, testCycles = 1, carrierId) {
  return fetch(`${API_URL}/devices/${deviceId}/euicc/test`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      cycles: testCycles,
      carrierid: carrierId,
    }),
  })
    .then(fetchHandler(() => Promise.resolve()))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export function resumeBulk(deviceIds, devicesCache) {
  const promises = deviceIds.map((deviceId) =>
    fetch(`${API_URL}/devices/${deviceId}/state`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        state: 'live',
      }),
    }).catch((error) => error)
  );

  let responses;

  return Promise.all(promises)
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then((parsedBodies) => {
      const successes = [];
      const failures = [];

      responses.forEach((response, idx) => {
        const linkId = deviceIds[idx];
        const correspondingDevice = find(devicesCache, {
          links: {
            cellular: [
              {
                id: linkId,
              },
            ],
          },
        });

        if (response.ok) {
          successes.push({
            linkId,
            item: correspondingDevice ? correspondingDevice.name : linkId,
          });
        } else {
          let reason = 'Unknown failure';
          let item = 'Unknown device';

          if (parsedBodies[idx].error) {
            reason = parsedBodies[idx].error;
          } else if (!linkId) {
            reason = "Device doesn't have a SIM link specified. ";
          } else {
            reason = response.statusText;
          }

          if (linkId && correspondingDevice) item = correspondingDevice.name;

          failures.push({ reason, item });
        }
      });

      return Promise.resolve({ successes, failures });
    })
    .catch((error) => Promise.reject(error));
}

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export const resumeDataBulk = ({
  selection,
  filters,
  isPreview,
  userContextData,
  useSearch,
  useBizLayer,
}) =>
  pauseResumeDataBulk({
    actionType: 'live',
    selection,
    filters,
    isPreview,
    userContextData,
    useSearch,
    useBizLayer,
  });

export function changeDeviceName(deviceId, name, userContextData) {
  return fetch(
    `${API_URL}/devices/${deviceId}${
      userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
    }`,
    {
      method: 'PUT',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        name,
      }),
    }
  )
    .then(
      fetchHandler((body) => {
        const device = new DeviceModel(body.data);
        return Promise.resolve(device);
      })
    )
    .catch(fetchErrorHandler);
}

/* eslint-disable default-param-last */
export function batchActivation(
  tasks,
  isPreview = true,
  useAccountBalance = true,
  userContextData,
  invalidatePreflightSims
) {
  const body = {
    tasks,
    useacctbalance: useAccountBalance ? '1' : '0',
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
    invalidatePreflightSims,
  };

  return fetch(`${API_URL}/batchorder?preview=${isPreview ? '1' : '0'}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  })
    .then(fetchHandler((result) => Promise.resolve(result.data), true, false))
    .catch((data) => Promise.reject(data.error || data.message));
}
/* eslint-enable default-param-last */

export function getUsage(
  userContextData,
  limit = 2000,
  devices = [],
  tagIds = [],
  startDate = null,
  endDate = null,
  aggregateBy = null,
  startAt = null,
  aggregateAll = false
) {
  const query = {
    limit,
    orgid: userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId,
    getdeviceinfo: 1,
  };

  let aggregatePrefix = '';

  if (aggregateAll) query.aggregateall = '1';
  if (startDate) query.timestart = startDate;
  if (endDate) query.timeend = endDate;
  if (startAt) query.startat = startAt;
  if (aggregateBy && aggregateBy === 'month') aggregatePrefix = '/monthly';
  if (aggregateBy && aggregateBy === 'day') aggregatePrefix = '/daily';

  if (devices.length === 1) query.deviceid = devices[0].id;
  else if (devices.length > 1) {
    query.deviceid = devices.map((device) => device.id);
  }

  let nonEncodedQueryString = ''; // this is required because the tagids require commas, which will get encoded by the query-string library.

  if (tagIds.length > 0) {
    nonEncodedQueryString += '&tagids=';
    tagIds.forEach((tagId, idx) => {
      nonEncodedQueryString += idx === 0 ? tagId : `,${tagId}`;
    });
    query.tagsids = tagIds.join(',');
  }

  return fetch(
    `${API_URL}/usage/data${aggregatePrefix}?${queryString.stringify(query, {
      arrayFormat: 'bracket',
    })}${nonEncodedQueryString}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated in favor of `useGetDeviceProfileStatusHistory`
 */
export function getDeviceStatusHistory(linkId) {
  if (!linkId) return Promise.resolve([]);

  return fetch(`${API_URL}/links/cellular/${linkId}/history`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated in favor of `useGetDeviceEUICCHistory`
 */
export function getDeviceEUICCHistory(deviceId) {
  if (!deviceId) return Promise.resolve([]);

  return fetch(`${API_URL}/devices/${deviceId}/euicc/history`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated in favor of `useGetDeviceEUICCTestHistory`
 */
export function getDeviceEUICCTestHistory(deviceId) {
  if (!deviceId) return Promise.resolve([]);

  return fetch(`${API_URL}/devices/${deviceId}/euicc/test/history`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated In favor of `useGetWebhook`
 */
export function getWebhook(deviceId) {
  return fetch(`${API_URL}/devices/${deviceId}/webhook`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(new WebhookModel(body.data))))
    .catch(
      extendedFetchErrorHandler((e) => {
        if (e.response.status === 400) {
          const webhook = new WebhookModel();
          webhook.hasHydrated = true;
          return Promise.resolve(webhook);
        }

        return undefined;
      })
    );
}

/**
 * @deprecated In favor of `useCreateWebhook`
 */
export function createWebhook(deviceId, port, protocol) {
  return fetch(`${API_URL}/devices/${deviceId}/webhook`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      port,
      protocol,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(new WebhookModel(body.data))))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated In favor of `useRegenerateWebhook`
 */
export function regenerateWebhook(deviceId) {
  return fetch(`${API_URL}/devices/${deviceId}/webhook/guid`, {
    method: 'POST',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(new WebhookModel(body.data))))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated In favor of `useUpdateWebhook`
 */
export function updateWebhook(deviceId, port, protocol) {
  return fetch(`${API_URL}/devices/${deviceId}/webhook`, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      port,
      protocol,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(new WebhookModel(body.data))))
    .catch(fetchErrorHandler);
}

/**
 * @deprecated In favor of `useDeleteWebhook`
 */
export function deleteWebhook(deviceId) {
  return fetch(`${API_URL}/devices/${deviceId}/webhook`, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function bulkMoveDevices(deviceIds, destinationOrgId, userContextData, devicesCache) {
  const promises = deviceIds.map((deviceId) =>
    fetch(
      `${API_URL}/devices/${deviceId}${
        userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
      }`,
      {
        method: 'PUT',
        credentials: 'include',
        headers,
        body: JSON.stringify({
          orgid: destinationOrgId,
        }),
      }
    ).catch((error) => error)
  );

  let responses;

  return Promise.all(promises)
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then((parsedBodies) => {
      const successes = [];
      const failures = [];

      responses.forEach((response, idx) => {
        const deviceId = deviceIds[idx];
        const correspondingDevice = devicesCache[deviceId];

        if (response.ok) {
          successes.push({
            deviceId,
            item: correspondingDevice ? correspondingDevice.name : deviceId,
          });
        } else {
          let reason = 'Unknown failure';
          let item = 'Unknown device';

          if (parsedBodies[idx].error) {
            reason = parsedBodies[idx].error;
          } else {
            reason = response.statusText;
          }

          if (deviceId && correspondingDevice) item = correspondingDevice.name;

          failures.push({ reason, item });
        }
      });

      return Promise.resolve({ successes, failures });
    })
    .catch((error) => Promise.reject(error));
}

export function getTopics(userContextData) {
  return fetch(
    `${API_URL}/csr/tags?orgid=${
      userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
    }`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getProfiles(deviceid) {
  return fetch(`${API_URL}/links/cellular?witheuicc=1&deviceid=${deviceid}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

// here lie new actions for the megadevices epic. We should make sure to clean up
// once we can safely delete various parts of this file. bfallon, 11.17.2020
export const searchDevices = (filters, userContextData) => {
  const orgid = userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId;

  return fetch(`${API_URL}/devices/search`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      ...filters,
      orgid,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);
};

export const fetchSlimDevices = (deviceIds) => {
  const params = { ids: deviceIds, slim: true };
  const qString = queryString.stringify(params, { arrayFormat: 'bracket' });

  return fetch(`${API_URL}/devices?${qString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);
};

export const getDevices = (filters, userContextData) =>
  fetch(`${API_URL}/devices?${buildDevicesQueryFiltersQuerystring(filters, userContextData)}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);

export const getDevicesCount = (filters, userContextData) =>
  fetch(
    `${API_URL}/devices/count?${buildDevicesQueryFiltersQuerystring(filters, userContextData)}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);

export const getDevicesPages = (filters, userContextData) =>
  fetch(
    `${API_URL}/devices/pages?${buildDevicesQueryFiltersQuerystring(filters, userContextData)}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);

export const requestDevicesReport = (contextData, tagIds, states) => {
  const { isInOrgContext, orgId, userOrgId } = contextData;
  const orgid = isInOrgContext ? orgId : userOrgId;

  return fetch(`${API_URL}/reports/longterm/devices/export`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      orgid,
      tag_ids: tagIds,
      states,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);
};

export const attemptDocumentDownload = (id) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = `${API_URL}/documents/${id}/download`;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getHistoricDeviceCounts = (timestamp, deviceStates, userContextData) =>
  Promise.all(
    [
      `${API_URL}/links/cellular/statehistory?timestamp=${timestamp}&states=${deviceStates}${
        userContextData.orgId ? `&orgid=${userContextData.orgId}` : ''
      }`,
      `${API_URL}/devices/activecount?timestamp=${timestamp}${
        userContextData.orgId ? `&orgid=${userContextData.orgId}` : ''
      }`,
    ].map((url) =>
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: getHeaders,
      })
        .then(fetchHandler((body) => Promise.resolve(body.data)))
        .catch(fetchErrorHandler)
    )
  );

export const getTasks = ({
  userContextData,
  limit,
  startAfterId,
  status,
  timeStart,
  actionTypes,
  sortColumn,
  sortDirection,
}) => {
  const orgid = getOrgIdFromContextData(userContextData);

  const params = new URLSearchParams({ orgid });

  if (limit) {
    params.append('limit', limit);
  }
  if (startAfterId) {
    params.append('startafter', startAfterId);
  }
  if (status) {
    params.append('status', status);
  }
  if (timeStart) {
    params.append('timestart', timeStart);
  }
  if (actionTypes) {
    params.append('actionTypes', actionTypes);
  }
  if (sortColumn) {
    params.append('sort_by', sortColumn);
  }
  if (sortDirection) {
    params.append('sort_direction', sortDirection);
  }

  const url = `${API_URL}/devices/batch/state?${params.toString()}`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler((body) => {
        const { tasks, totalPages, totalTasks, startAfterIds } = body?.data ?? {};
        return Promise.resolve({
          page: {
            startAfterIds,
            totalPages,
            totalTasks,
          },
          tasks: tasks.map((task) => ({
            jobId: task?.job_id,
            action: task?.action,
            deviceCount: task?.device_count,
            status: task?.status,
            timestampStart: task?.timestamp_start,
            timestampEnd: task?.timestamp_end,
            requesterFirstName: task?.requester_first_name,
            requesterLastName: task?.requester_last_name,
            requesterOrgName: task?.requester_org_name,
            source: task?.source,
          })),
        });
      })
    )
    .catch(fetchErrorHandler);
};

export const getTaskProgress = (userContextData, timeStart) => {
  const orgid = getOrgIdFromContextData(userContextData);
  const params = new URLSearchParams({ orgid });
  if (timeStart) params.append('timestart', timeStart);

  return fetch(`${API_URL}/links/cellular/batch/state/progress?${params.toString()}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(
      fetchHandler((body) => {
        const { tasks } = body?.data ?? [];
        return Promise.resolve(
          tasks.map((task) => ({
            jobId: task?.job_id,
            progress: task?.progress,
          }))
        );
      })
    )
    .catch(fetchErrorHandler);
};

export const getSimsFromCSV = (csvFile) => {
  // FormData doesn't work outside of the browser context so ESLint will throw an error here
  const postBody = new FormData();
  postBody.append('simcsv', csvFile);
  postBody.append('fieldName', 'ICCID');

  return fetch(`${API_URL}/links/cellular/parsecsv`, {
    method: 'POST',
    credentials: 'include',
    getHeaders,
    body: postBody,
  })
    .then(fetchHandler((response) => Promise.resolve(response.data.ICCID)))
    .catch(fetchErrorHandler);
};

export const fetchBatchJobDetails = ({
  userContextData,
  batchJobId,
  startAfterId,
  devicesPerPage = TASK_PAGE_SIZE,
}) => {
  const searchParams = new URLSearchParams({
    orgid: getOrgIdFromContextData(userContextData),
    devicesPerPage,
  });
  if (startAfterId) {
    searchParams.append('startAfterId', startAfterId);
  }
  return fetch(`${API_URL}/devices/batch/${batchJobId}/details?${searchParams.toString()}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
};

export const fetchActivityHistoryDetailsCSVExportReport = ({ userContextData, batchJobId }) => {
  const { isInOrgContext, orgId, userOrgId } = userContextData;

  return fetch(`${API_URL}/reports/batchjob`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      orgid: isInOrgContext ? orgId : userOrgId,
      jobid: batchJobId,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body)))
    .catch(fetchErrorHandler);
};
