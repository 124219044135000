import { useMemo } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { getTotalSelectedDevices } from 'common-js/reducers/devices/selectors';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import useActivateSIMItem from './useActivateSIMItem';
import useDeactivateSIMItem from './useDeactivateSIMItem';
import useDisableTunnelingItem from './useDisableTunnelingItem';
import useEnableTunnelingItem from './useEnableTunnelingItem';
import usePauseItem from './usePauseItem';
import useRenameItem from './useRenameItem';
import useResumeItem from './useResumeItem';
import useSendSMSItem from './useSendSMSItem';
import useSetDataUsageLimitsItem from './useSetDataUsageLimitsItem';
import useTransferToOrganizationItem from './useTransferToOrganizationItem';

const header = ({ label, sectionId }) => ({
  children: label,
  sectionId,
  header: true,
});

// ignore10kLimit only applies to the 10k limit and not to the other plan limits
const itemDisabledBecauseOfLimit = (total, limit, ignore10kLimit) =>
  total > limit && (!ignore10kLimit || limit !== 10_000);

const itemsForDropdown = ({ items, sectionId, selectionLimit, totalSelected }) =>
  items.map(({ label, icon, destructive, onClick, disabled, ignore10kLimit }) => ({
    children: label,
    iconLeft: icon,
    closeAfterClick: true,
    disabled: disabled || itemDisabledBecauseOfLimit(totalSelected, selectionLimit, ignore10kLimit),
    destructive,
    onClick,
    sectionId,
  }));

// We only want to exclude items that have specifically set include to false; leaving it undefined (by leaving it out) means the item should still be included
const itemsUnderLimit = ({ items, sectionId, totalSelected }) =>
  itemsForDropdown({
    items: items.filter((item) => totalSelected <= item.selectionLimit && item.include !== false),
    sectionId,
    totalSelected,
  } as any);

const section = ({ items, label, sectionId, selectionLimit, totalSelected }) =>
  items.length > 0
    ? [
        header({ label, sectionId }),
        ...itemsForDropdown({
          items,
          sectionId,
          selectionLimit,
          totalSelected,
        }),
      ]
    : [];

// We only want to exclude items that have specifically set include to false; leaving it undefined (by leaving it out) means the item should still be included
const filterItemsToShow = ({ items, limit, totalSelected }) =>
  items.filter(
    (item) => item.selectionLimit === limit && totalSelected > limit && item.include !== false
  );

const limitedSection = ({ items, limit, sectionId, totalSelected }) =>
  section({
    label: `Only available for ${addCommasToNumber(limit, false)} devices or less`,
    items: filterItemsToShow({ items, limit, totalSelected }),
    sectionId,
    selectionLimit: limit,
    totalSelected,
  });

const useItems = ({ deviceType }) => {
  const totalSelected = useAppSelector(getTotalSelectedDevices);

  const selectionCountLabel = `${addCommasToNumber(totalSelected, false)} device${
    totalSelected !== 1 ? 's' : ''
  } selected`;

  const activateSIMItem = useActivateSIMItem({ deviceType, totalSelected });
  const deactivateSIMItem = useDeactivateSIMItem({ deviceType, totalSelected });
  const disableTunnelingItem = useDisableTunnelingItem();
  const enableTunnelingItem = useEnableTunnelingItem();
  const pauseItem = usePauseItem({ deviceType, totalSelected });
  const renameItem = useRenameItem({ totalSelected });
  const resumeItem = useResumeItem({ deviceType, totalSelected });
  const sendSMSItem = useSendSMSItem({ totalSelected });
  const setDataUsageLimitsItem = useSetDataUsageLimitsItem();
  const transferToOrganizationItem = useTransferToOrganizationItem();

  const items = useMemo(
    () => [
      activateSIMItem,
      pauseItem,
      resumeItem,
      sendSMSItem,
      renameItem,
      setDataUsageLimitsItem,
      transferToOrganizationItem,
      enableTunnelingItem,
      disableTunnelingItem,
      deactivateSIMItem,
    ],
    [
      activateSIMItem,
      pauseItem,
      resumeItem,
      sendSMSItem,
      renameItem,
      setDataUsageLimitsItem,
      transferToOrganizationItem,
      enableTunnelingItem,
      disableTunnelingItem,
      deactivateSIMItem,
    ]
  );

  const dropdownItems = useMemo(
    () => [
      header({ label: selectionCountLabel, sectionId: 1 }),
      ...itemsUnderLimit({ sectionId: 1, items, totalSelected }),
      ...limitedSection({ limit: 10_000, sectionId: 2, items, totalSelected }),
      ...limitedSection({ limit: 100, sectionId: 3, items, totalSelected }),
      ...limitedSection({ limit: 10, sectionId: 4, items, totalSelected }),
    ],
    [items, selectionCountLabel, totalSelected]
  );

  return dropdownItems;
};

export default useItems;
