import { Button } from '@hologram-dimension/button';
import { Link } from '@hologram-dimension/link';
import { Popover } from '@hologram-dimension/popover';
import { Icon } from '@holokit/core';
import { API_URL } from 'common-js/api/config';
import { Topic } from 'common-js/components';
import { APPS_ADD, APPS_CONFIGURE, APPS_PARAM_NAME, withContext } from 'common-js/constants/paths';
import Moment from 'moment-timezone';
import React from 'react';
import { browserHistory } from 'react-router';

const MAX_DATA_LENGTH = 500;
const MAX_TOPIC_DISPLAY = 2;

class HeartbeatMessage extends React.Component<any, any, any> {
  static buildLinkToApp(appId) {
    return withContext(APPS_CONFIGURE.replace(`:${APPS_PARAM_NAME}`, appId));
  }

  constructor(props) {
    super(props);

    this.state = {
      isRevealed: false,
    };
  }

  onForkClick() {
    const { minimizeDrawer, addSelectedTopic, message, clearAllAppSelections, builtInTopics } =
      this.props;
    minimizeDrawer(false);
    clearAllAppSelections();
    message.topicsPublished
      .filter((topic) => builtInTopics.indexOf(topic) === -1)
      .forEach((topicName) => {
        addSelectedTopic({ name: topicName });
      });
    browserHistory.push(APPS_ADD);
  }

  render() {
    const { routesReleaseFlag, message } = this.props;
    const routeErrors = message.triggeredApps.filter((rule) => rule.error);
    const { isRevealed } = this.state;

    return (
      <div className="message-row message-heartbeat grid-column">
        <div className="grid-row">
          <div className="message-icon">
            <Icon name="Heartbeat" size="minor" svgProps={{ style: { fill: '#000000' } }} />
          </div>
          <div className="message-title">
            {message.heartbeatApp ? (
              <div>
                <Link to={HeartbeatMessage.buildLinkToApp(message.heartbeatApp.id)}>
                  {`Heartbeat "${message.heartbeatApp.nickname}"`}
                </Link>{' '}
                triggered
              </div>
            ) : (
              <div>Heartbeat triggered</div>
            )}
          </div>
          <div className="message-data grid-column">
            <div className="subtitle">Data</div>
            <div className="font-mono data-area">
              {isRevealed || message.data.length < MAX_DATA_LENGTH ? (
                <div>{message.data}</div>
              ) : (
                <div>{message.data.substring(0, MAX_DATA_LENGTH)}</div>
              )}
            </div>
            <div>
              {message.data.length >= MAX_DATA_LENGTH &&
                (!isRevealed ? (
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      this.setState({ isRevealed: true });
                    }}
                  >
                    {' (view all)'}
                  </Button>
                ) : (
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      this.setState({ isRevealed: false });
                    }}
                  >
                    {' (hide)'}
                  </Button>
                ))}
            </div>
          </div>
          <div className="message-routes">
            <div className="subtitle">Topics</div>
            <div className="grid-row">
              <div className="topics">
                {message.topicsPublished
                  .filter((tag, idx) => idx < MAX_TOPIC_DISPLAY)
                  .map((topic) => (
                    <Topic name={topic} wrapperClasses="topic-big" key={topic} />
                  ))}
                {message.topicsPublished.length > MAX_TOPIC_DISPLAY && (
                  <Popover
                    id={`popover-${message.logId}`}
                    content={message.topicsPublished
                      .filter((_: string, idx: number) => idx >= MAX_TOPIC_DISPLAY)
                      .map((topic: string) => (
                        <Topic name={topic} wrapperClasses="topic-big" key={topic} />
                      ))}
                    background="light"
                    className="topic topic-more"
                  >
                    <span>{message.topicsPublished.length - MAX_TOPIC_DISPLAY} more...</span>
                  </Popover>
                )}
                {routesReleaseFlag && (
                  <button
                    type="button"
                    aria-label="fork"
                    className="fork-button"
                    onClick={() => this.onForkClick()}
                  >
                    <Icon
                      name="Arrow--branch"
                      size="major"
                      svgProps={{ style: { fill: '#0a1435' } }}
                    />
                  </button>
                )}
              </div>
              {routesReleaseFlag && (
                <div className="grid-item">
                  <button
                    type="button"
                    aria-label="fork"
                    className="fork-button"
                    onClick={() => this.onForkClick()}
                  >
                    <Icon
                      name="Arrow--branch"
                      size="major"
                      svgProps={{ style: { fill: '#0a1435' } }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
          {message.triggeredApps.length > 0 && routesReleaseFlag && (
            <div className="message-routes">
              <div className="subtitle">Triggered routes</div>
              <div className="triggers">
                {message.triggeredApps.map((app: any) => (
                  <span key={app.nickname ?? app.id}>
                    {app.nickname ? (
                      <Button
                        className={`trigger${app.error ? ' error' : ''}`}
                        to={HeartbeatMessage.buildLinkToApp(app.ruleid)}
                        variant="tertiary"
                        iconEnd={
                          <Icon
                            name="Routes"
                            size="major"
                            svgProps={{
                              style: { fill: '#0a1435', stroke: '#0a1435' },
                            }}
                          />
                        }
                      >
                        {app.nickname}
                      </Button>
                    ) : (
                      <span className={`trigger${app.error ? ' error' : ''}`}>
                        <Icon
                          name="Routes"
                          size="major"
                          svgProps={{
                            style: { fill: '#0a1435', stroke: '#0a1435' },
                          }}
                        />
                        App #{app.id}
                      </span>
                    )}
                  </span>
                ))}
              </div>
              {routeErrors.length > 0 && (
                <div>
                  <div className="subtitle">Errors</div>
                  <div className="route-errors">
                    {routeErrors.map((error) => (
                      <div key={error.id} className="message message-error">
                        <div className="message-panel">
                          <Icon
                            name="Warning"
                            size="major"
                            svgProps={{ style: { fill: '#A80000' } }}
                          />
                          <div className="message-text">
                            <span className="message-bold">
                              {error.nickname || `APP #${error.id}`} :
                            </span>{' '}
                            {error.result_data}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="message-date grid-column">
            <div className="section section-sm">
              {Moment.utc(message.date).tz(Moment.tz.guess()).format('MMM D YYYY, HH:mm:ss')}
            </div>
            <div className="grid-row r-align">
              <Button
                href={`${API_URL}/csr/rdm/${message.logId}`}
                target="_blank"
                size="small"
                variant="secondary"
              >
                View raw
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeartbeatMessage;
