import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import * as Paths from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectUserIsInternal } from 'common-js/reducers/account/selectors';
import { getAllCarriers } from 'common-js/reducers/admin/actions';
import {
  selectCurrentDevice,
  selectCurrentDeviceError,
  selectCurrentDeviceEuiccHistory,
  selectCurrentDeviceLoading,
  selectProfileHistory,
} from 'common-js/reducers/currentDevice/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import React, { useEffect } from 'react';
import { browserHistory } from 'react-router';
import HyperEducationalCallout from './HyperEducationalCallout';
import HyperHardware from './HyperHardware';
import HyperInfo from './HyperInfo';
import HyperProfileHistoryPanel from './HyperProfileHistoryPanel';
import HyperTestingHarness from './HyperTestingHarness';

const Hyper = () => {
  const euiccHistory = useAppSelector(selectCurrentDeviceEuiccHistory);
  const device = useAppSelector(selectCurrentDevice);
  const loading = useAppSelector(selectCurrentDeviceLoading);
  const error = useAppSelector(selectCurrentDeviceError);
  const profileHistory = useAppSelector(selectProfileHistory);
  const releaseFlags = useAppSelector(getReleaseFlags);
  const userIsInternal = useAppSelector(selectUserIsInternal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllCarriers());

    if (!releaseFlags.euicc || device.euicc_type === 'UICC') {
      const path = Paths.buildSDPPath(device.id, Paths.DEVICE_STATUS);
      browserHistory.push(path);
    }
  }, [device, dispatch, releaseFlags]);

  const lastEUICCResult = euiccHistory.find((event) => event.action === 'EUICC_TEST');
  const completedStates = ['COMPLETE', 'ERROR'];
  const testInProgress =
    lastEUICCResult &&
    ((lastEUICCResult.metadata && lastEUICCResult.metadata.is_rollback_pending) ||
      !completedStates.includes(lastEUICCResult.state));
  const testHarnessButtonText = testInProgress ? 'eUICC Test In Progress' : 'Test eUICC Services';

  return (
    <div className="ProfilesWrapper">
      <HyperEducationalCallout />

      <Panel header="Hyper eUICC" noContentPadding>
        <HyperInfo deviceId={device.id} />
      </Panel>

      {releaseFlags.hyper_sdp_improvements && (
        <HyperProfileHistoryPanel
          profileHistory={profileHistory}
          device={device}
          loading={loading}
          error={error}
        />
      )}

      {releaseFlags.hyper_sdp_improvements && userIsInternal && (
        <Panel header="Hardware" className="Hyper__admin-panel" noContentPadding>
          <HyperHardware deviceId={device.id} />
        </Panel>
      )}
      {!releaseFlags.hyper_sdp_improvements && (
        <Panel header="Hardware Compatibility" noContentPadding>
          <HyperHardware deviceId={device.id} />
        </Panel>
      )}

      {userIsInternal && (
        <Panel
          header="eUICC Testing Harness"
          className="Hyper__admin-panel"
          noContentPadding
          headerActions={
            <Button
              className="Hyper__test-button"
              disabled={testInProgress}
              iconStart="Chip"
              loading={testInProgress}
              onClick={() => {
                dispatch(
                  openModal(
                    'KickoffHyperCompatTestModal',
                    {
                      device,
                      carrierSelectionOptions: [
                        {
                          value: '2',
                          name: 'JT',
                        },
                        {
                          value: '8',
                          name: 'floLIVE',
                        },
                      ],
                      cycleSelectionOptions: [
                        {
                          value: '1',
                          name: 'Single (1 test)',
                        },
                        {
                          value: '3',
                          name: 'Multiple (3 tests)',
                        },
                        {
                          value: '5',
                          name: 'Multiple (5 tests)',
                        },
                        {
                          value: '10',
                          name: 'Extended (10 tests)',
                        },
                      ],
                    },
                    'narrow'
                  )
                );
              }}
            >
              {testHarnessButtonText}
            </Button>
          }
        >
          <HyperTestingHarness deviceId={device.id} />
        </Panel>
      )}
    </div>
  );
};

export default Hyper;
