const formatRequester = (source, requester) => {
  const { firstName, lastName, orgName } = requester || {};
  if (source === 'api') {
    return `${orgName} team via API`;
  }
  return orgName === 'Hologram'
    ? 'Hologram team member'
    : `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`;
};

export default formatRequester;
