import createReactClass from 'create-react-class';
import React from 'react';
import AceEditor from 'react-ace';
import { Mixin } from 'formsy-react19';
import 'brace/ext/language_tools';
import 'brace/mode/json';
import 'brace/theme/tomorrow';

// TODO: Changing this would require not using mixins (or finding a way to add them to the prototype)
// eslint-disable-next-line react/prefer-es6-class
const AceEditorInput = createReactClass({
  mixins: [Mixin],

  changeValue(event) {
    this.setValue(event);
  },

  render() {
    const { description, labelName } = this.props;

    let className;
    if (this.showRequired()) {
      className = 'required';
    } else if (this.showError()) {
      className = 'error';
    } else {
      className = null;
    }

    return (
      <div className={className}>
        <div className="label-text f5">{labelName}</div>
        {description && <div className="f6 section section-sm f-subdued">{description}</div>}
        <div className="code-wrapper">
          <AceEditor
            mode="json"
            theme="tomorrow"
            name="blah2"
            height="200px"
            onChange={this.changeValue}
            fontSize={14}
            showPrintMargin={false}
            showGutter
            highlightActiveLine={false}
            value={this.getValue()}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
          />
        </div>
      </div>
    );
  },
});

export default AceEditorInput;
