import React, { useState } from 'react';
import classNames from 'clsx';
import { Icon } from '@holokit/core';

function ExpandableWell({ title, content, callback, contentClassName }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="ExpandableWell">
      <div className="ExpandableWell--display">
        <Icon
          className="ExpandableWell__icon"
          name="Circle--info"
          size="minor"
          svgProps={{ style: { fill: '#8008f7' } }}
        />
        <p className="ExpandableWell__title">{title}</p>
        <button
          className="ExpandableWell__button"
          type="button"
          onClick={(e) => {
            e.preventDefault();

            setExpanded(!expanded);

            if (callback) {
              callback(!expanded);
            }
          }}
        >
          <Icon
            className="ExpandableWell__icon"
            name={expanded ? 'Chevron--single--north' : 'Chevron--single--south'}
            size="minor"
            svgProps={{ style: { fill: '#8008F7' } }}
          />
        </button>
      </div>
      {expanded && (
        <div className={classNames('ExpandableWell__content', contentClassName)}> {content}</div>
      )}
    </div>
  );
}

export default ExpandableWell;
