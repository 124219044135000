import { ComplexIcon, Tooltip } from '@holokit/core';
import { Icon } from '@hologram-dimension/icon';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';

const HyperTooltip = () => (
  <Tooltip
    content="This device is using an eUICC-capable Hyper SIM, which lets Hologram remotely update this device's coverage without swapping SIMs."
    title="Hyper SIM"
  >
    <span>
      <Icon name="CircleInfo" size="small" fill="DdsColorPalettePurple50" />
    </span>
  </Tooltip>
);

const DeviceHyperIndicator = ({ device }) => {
  const releaseFlags = useAppSelector(getReleaseFlags);

  if (!device.isHyper || !releaseFlags.euicc || !releaseFlags.hyper_sdp_improvements) {
    return null;
  }

  return (
    <div className="DeviceHeader__info DeviceHyperIndicator">
      <ComplexIcon name="hyper-minor" classes="DeviceHyperIndicator__icon" />

      <div className="DeviceHeader__label">
        Hyper SIM <HyperTooltip />
      </div>
    </div>
  );
};

export default DeviceHyperIndicator;
