/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import clsx from 'clsx';
import { HoloHelmet } from 'common-js/components';
import { useAppDispatch, useAppSelector } from 'common-js/hooks';
import { TOPBAR_HEIGHT } from 'common-js/reducers/console/dimensions';
import {
  changeViewFilters,
  fullscreenDrawer,
  minimizeDrawer,
  revealDrawer,
  setDrawerTakeover,
  setLocked,
} from 'common-js/reducers/drawer/actions';
import {
  selectDrawerIsFullscreen,
  selectDrawerIsMinimized,
  selectDrawerTakeover,
  selectIsLocked,
  selectViewFilters,
} from 'common-js/reducers/drawer/selectors';
import { setLogCategory } from 'common-js/reducers/logFilter/actions';
import {
  selectDeviceFilter,
  selectIsViewingAllLogs,
  selectLogCategory,
  selectSearchQuery,
  selectSelectedTags,
} from 'common-js/reducers/logFilter/selectors';
import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  type FC,
  type MouseEventHandler,
  type ReactNode,
} from 'react';

interface DrawerProps {
  children?: ReactNode;
  className?: string;
  fadetowhite?: boolean;
}

const Drawer: FC<DrawerProps> = ({ children, className, fadetowhite = false }) => {
  // Drawer
  const isMinimized = useAppSelector(selectDrawerIsMinimized);
  const isFullscreen = useAppSelector(selectDrawerIsFullscreen);
  const drawerTakeover = useAppSelector(selectDrawerTakeover);
  const isLocked = useAppSelector(selectIsLocked);
  const viewFilters = useAppSelector(selectViewFilters);

  // Log Filters
  const deviceFilter = useAppSelector(selectDeviceFilter);
  const logCategory = useAppSelector(selectLogCategory);
  const searchQuery = useAppSelector(selectSearchQuery);
  const isViewingAllLogs = useAppSelector(selectIsViewingAllLogs);
  const selectedTags = useAppSelector(selectSelectedTags);

  const dispatch = useAppDispatch();

  const onMinimizeClick = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      dispatch(minimizeDrawer());
      dispatch(setLocked(false));
    },
    [dispatch]
  );

  const onToggleViewModeClick = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();

      if (isFullscreen) {
        dispatch(revealDrawer());
      } else {
        dispatch(fullscreenDrawer());
      }
    },
    [dispatch, isFullscreen]
  );

  const onLockClick = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();

      if (!isLocked) {
        dispatch(revealDrawer());
      }
      dispatch(setLocked(!isLocked));
    },
    [dispatch, isLocked]
  );

  const onFilterClick = useCallback<MouseEventHandler>(
    (e) => {
      if (!isMinimized) {
        e.stopPropagation();
      }

      dispatch(changeViewFilters(!viewFilters));
    },
    [dispatch, isMinimized, viewFilters]
  );

  const onToggleMinimized = useCallback<MouseEventHandler>(() => {
    if (isFullscreen) {
      return;
    }
    if (isMinimized) {
      dispatch(revealDrawer());
    } else {
      dispatch(setLocked(false));
      dispatch(minimizeDrawer());
    }
  }, [dispatch, isFullscreen, isMinimized]);

  const { innerHeight } = window;

  const getDrawerPosition = useCallback(() => {
    if (isFullscreen) {
      return 0;
    }
    if (isMinimized) {
      return innerHeight - TOPBAR_HEIGHT;
    }
    return innerHeight / 2;
  }, [innerHeight, isFullscreen, isMinimized]);

  useEffect(() => {
    dispatch(setDrawerTakeover(false));
  }, [dispatch]);

  const childrenWithProps = useMemo(
    () =>
      Children.map(children, (child) =>
        cloneElement(child as any, {
          visibleWindowHeight: innerHeight - getDrawerPosition(),
          showFilters:
            viewFilters ||
            selectedTags?.length > 0 ||
            !isViewingAllLogs ||
            searchQuery?.length > 0 ||
            deviceFilter,
        })
      ),
    [
      children,
      deviceFilter,
      getDrawerPosition,
      innerHeight,
      isViewingAllLogs,
      searchQuery?.length,
      selectedTags?.length,
      viewFilters,
    ]
  );

  const onLogCategoryAll = useCallback<MouseEventHandler>(
    (e) => {
      if (!isMinimized) {
        e.stopPropagation();
      }
      dispatch(setLogCategory('all'));
    },
    [dispatch, isMinimized]
  );

  const onLogCategoryError = useCallback<MouseEventHandler>(
    (e) => {
      if (!isMinimized) {
        e.stopPropagation();
      }
      dispatch(setLogCategory('error'));
    },
    [dispatch, isMinimized]
  );

  const wrapperClassName = clsx('drawer-container', className, {
    fading: drawerTakeover,
    'fade-to-white': fadetowhite,
  });

  const classNames = clsx('drawer', {
    fullscreen: isFullscreen,
    minimized: isMinimized,
    open: !isFullscreen && !isMinimized,
    takeover: drawerTakeover,
  });

  return (
    <div className={wrapperClassName}>
      {isFullscreen && !drawerTakeover ? (
        <HoloHelmet
          title={logCategory === 'error' ? 'Console - Errors' : 'Console - All Activity'}
        />
      ) : null}
      <div className={classNames}>
        {!drawerTakeover ? (
          <div className={clsx('top-bar', { minimized: isMinimized })} onClick={onToggleMinimized}>
            <Icon className="top-bar__console-icon" name="Console" size="large" />
            <div
              className={`quick-filter-tab${
                logCategory === 'all' && !isMinimized ? ' active' : ''
              }`}
              onClick={onLogCategoryAll}
            >
              <div className="quick-filter-text">All activity</div>
            </div>
            <div
              className={`quick-filter-tab${
                logCategory === 'error' && !isMinimized ? ' active' : ''
              }`}
              onClick={onLogCategoryError}
            >
              <div className="quick-filter-text">Errors</div>
            </div>
            <div className="grid-item" />
            <div className="grid-row filter-container">
              <Button
                iconStart="Filter"
                onClick={onFilterClick}
                variant="tertiary"
                className="filter-button"
              >
                Filter
              </Button>
              {!isFullscreen && (
                <Button
                  variant="tertiary"
                  onClick={onLockClick}
                  className="filter-button"
                  iconStart={
                    <Icon name={isLocked ? 'Lock' : 'Unlock'} size="small" color="currentColor" />
                  }
                />
              )}
              <Button
                iconStart={isFullscreen ? 'Collapse' : 'Expand'}
                variant="tertiary"
                onClick={onToggleViewModeClick}
                className="filter-button"
              />
              <Button
                iconStart="Close"
                variant="tertiary"
                onClick={onMinimizeClick}
                className="filter-button"
              />
            </div>
          </div>
        ) : (
          <div className="top-bar" />
        )}
        <div className="drawer-window">{!drawerTakeover && childrenWithProps}</div>
      </div>
    </div>
  );
};

export default Drawer;
