import { createSelector } from 'reselect';

export const selectAdminFlags = createSelector(
  (state: any) => state.admin?.flags,
  (flags) => flags
);

export const selectNonBMPFlagKeys = createSelector(selectAdminFlags, (flags) =>
  Object.keys(flags).filter((flag) => !flag.startsWith('bmp_'))
);
