import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@hologram-dimension/button';
import { Callout, Panel } from '@holokit/core';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import {
  BMPContactUsButton,
  BMPUpsellPanel,
  HoloHelmet,
  PageLoadingErrorScreen,
} from 'common-js/components';
import * as accountActions from 'common-js/reducers/account/actions';
import * as messageActions from 'common-js/reducers/message/actions';
import { selectBmpAPIFeatureLevel, selectHasAPI } from 'common-js/reducers/releaseFlag/selectors';

const ContactUsButton = ({ flagLevel, variant }) => (
  <BMPContactUsButton variant={variant} flagLevel={flagLevel} analyticsName="Settings API Key" />
);

const UpsellContent = () => (
  <BMPUpsellPanel
    label="Plus and Pro feature"
    headline="Manage data from your own applications"
    copy="To unlock API integrations, upgrade your Hologram plan."
    buttons={<ContactUsButton variant="primary" flagLevel="lite" />}
  />
);

const UpsellCallout = ({ flagLevel }) => (
  <Callout
    iconLeft="Circle--info"
    rightElement={<ContactUsButton flagLevel={flagLevel} />}
    text="You have read-only access. To allow write and update access to the API, upgrade your Hologram plan."
  />
);

class SettingsApi extends React.Component {
  constructor(props) {
    super(props);

    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Settings - API' } });
    this.state = {
      pageError: null,
    };

    this.apiKeyRef = createRef();
  }

  UNSAFE_componentWillMount() {
    const { getApiKey, pushGlobalMessage } = this.props;

    try {
      getApiKey().catch((e) => {
        pushGlobalMessage(e, 'error');
        this.setState({ pageError: e });
      });
    } catch (e) {
      this.setState({ pageError: e });
    }
  }

  onCopyKey() {
    const { pushGlobalMessage } = this.props;

    const copyTextarea = this.apiKeyRef.current;

    if (copyTextarea) {
      copyTextarea.classList.remove('hidden');
      copyTextarea.select();

      try {
        document.execCommand('copy');
        pushGlobalMessage('API key copied to clipboard.');
      } catch (e) {
        this.setState({ pageError: e });
      }

      copyTextarea.classList.add('hidden');
    }
  }

  onGenerateNewKey() {
    const { generateApiKey } = this.props;

    if (window.confirm('Are you sure you want to generate a new API key?')) {
      this.setState({ isGeneratingNewKey: true });
      generateApiKey()
        .then(() => {
          this.setState({ isGeneratingNewKey: false });
        })
        .catch((e) => {
          throw new Error(e);
        });
    }
  }

  render() {
    const { apiKey, bmpLevel, hasAPI, orgId } = this.props;
    const { isGeneratingNewKey, pageError } = this.state;

    return (
      <div className="SettingsApi settings-page">
        <HoloHelmet title="Settings - API" />
        {pageError ? (
          <PageLoadingErrorScreen error={pageError} />
        ) : (
          <Panel title="API key" noBodyPadding classes="stellar-panel-form">
            {hasAPI ? (
              <div className="APIInfo form form-horizontal">
                {bmpLevel === 'plus' && (
                  <div className="form-row no-border">
                    <UpsellCallout flagLevel={bmpLevel} />
                  </div>
                )}
                <div className="form-row">
                  <div className="label">
                    <div className="label-text">Personal organization ID</div>
                    <div>
                      <div className="well">{orgId}</div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="label">
                    <div className="label-text">API key</div>
                    {apiKey ? (
                      <div className="api-key">
                        <div className="well grid-row">
                          <div className="grid-item">{apiKey}</div>
                          <textarea ref={this.apiKeyRef} value={apiKey} className="hidden" />
                          <Button onClick={() => this.onCopyKey()} variant="secondary">
                            Copy API key
                          </Button>
                          <Button onClick={() => this.onGenerateNewKey()} variant="secondary">
                            {' '}
                            {!isGeneratingNewKey ? 'Regenerate' : 'processing...'}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Button onClick={() => this.onGenerateNewKey()} variant="secondary">
                          {!isGeneratingNewKey ? 'Generate key' : 'processing...'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <UpsellContent />
            )}
          </Panel>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    apiKey: state.account.apiKey,
    orgId: state.account.orgId,
    bmpLevel: selectBmpAPIFeatureLevel(state),
    hasAPI: selectHasAPI(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getApiKey: accountActions.getApiKey,
        generateApiKey: accountActions.generateApiKey,
        pushGlobalMessage: messageActions.pushGlobalMessage,
      },
      dispatch
    )
)(SettingsApi);
