import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Loader } from '@hologram-dimension/loader';
import classNames from 'clsx';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectLiveUsage } from 'common-js/reducers/currentDevice/selectors';
import { DeviceUsageData } from 'common-js/types/Device';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import Moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import LiveUsageTableWindowCell from 'usage/components/LiveUsageTableWindowCell';

const getDuration = (starttime: string, endtime: string) => {
  const startTime = starttime.split(' ')[1];
  const endTime = endtime.split(' ')[1];
  const startMoment = Moment(startTime, 'HH:mm:ss');
  const endMoment = Moment(endTime, 'HH:mm:ss');
  const seconds = Moment(endMoment).diff(Moment(startMoment));
  return Moment.utc(seconds).format('HH:mm:ss');
};

const LiveUsageTable = () => {
  const { data, loading }: { data: Array<DeviceUsageData>; loading: boolean } =
    useAppSelector(selectLiveUsage);

  const [numberOfRows, setNumberOfRows] = useState(10);
  const viewMoreRows = useCallback(() => setNumberOfRows((rows) => rows + 50), [setNumberOfRows]);
  const rowsToShow = useMemo(
    () =>
      data
        .sort((a, b) => (new Date(b.session_begin) as any) - (new Date(a.timestamp) as any))
        .slice(0, numberOfRows),
    [data, numberOfRows]
  );

  return (
    <div className="UsageTable LiveUsageTable">
      {loading ? (
        <div className="table-loading">
          <Loader className="DimensionLoader__legacy-padding-override" />
        </div>
      ) : null}
      {!loading && rowsToShow.length === 0 && (
        <div className="UsageTable-nodata">
          <Icon
            className="UsageTable-nodata-icon"
            name="CircleInfo"
            fill="DdsColorInteractivePrimaryDefault"
            size="large"
          />
          <div className="UsageTable-nodata-message">
            There are no usage metrics to display for this set of devices and specific time frame.
            Try adjusting tags or the selected date range.
          </div>
        </div>
      )}
      {!loading && rowsToShow.length > 0 && (
        <div>
          <table>
            <tbody>
              <tr className="header">
                <th style={{ width: '15%' }} className="left-padded">
                  START TIME
                </th>
                <th style={{ width: '15%' }} className="left-padded">
                  END TIME
                </th>
                <th style={{ width: '10%' }} className="left-padded">
                  DURATION
                </th>
                <th style={{ width: '18%' }} className="left-padded">
                  TYPE
                </th>
                <th style={{ width: '9%' }}>Data usage</th>
                <th style={{ width: '10%' }}>Active ICCID / IMEI</th>
                <th style={{ width: '20%' }} className="right-padded">
                  CARRIER / RAT
                </th>
              </tr>
              {rowsToShow.map((d, idx) => (
                <tr className="body-data" key={d.record_id ?? idx}>
                  <td className="left-padded">{d.session_begin}</td>
                  <td className="left-padded">{d.timestamp}</td>

                  <td
                    className={classNames('left-padded', {
                      'UsageTableCell--diminished': d.is_reporting_window_with_usage === 1,
                    })}
                  >
                    {getDuration(d.session_begin, d.timestamp)}
                  </td>
                  <td className="left-padded">
                    <LiveUsageTableWindowCell isWindow={d.is_reporting_window_with_usage === 1} />
                  </td>
                  <td>{toByteStringFormatted(d.bytes)}</td>
                  <td>
                    <div>{d.sim}</div>
                    <div className="subheader">{d.imei}</div>
                  </td>
                  <td className="right-padded">
                    <div>{d.network_name}</div>
                    <div className="subheader">{d.radio_access_technology}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.length > rowsToShow.length && (
            <div className="load-more-container">
              <Button onClick={viewMoreRows} variant="secondary">
                Load 50 more records
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LiveUsageTable;
