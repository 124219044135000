import React from 'react';

const subIndustries = [
  'Advertising & Marketing',
  'Agriculture',
  'Business Services',
  'Cannabis',
  'Cloud Connectivity',
  'Communications Equipment',
  'Computer Hardware',
  'Computer Software',
  'Construction',
  'CPG',
  'Device charging',
  'Education',
  'Electronics',
  'Environmental Science',
  'Finance',
  'Fishing',
  'General Industrial',
  'Government',
  'Healthcare',
  'Hospitality',
  'IoT R&D',
  'IT Services',
  'Logistics & Supply Chain',
  'Manufacturing',
  'Micromobility',
  'Mobility',
  'Oil & Gas',
  'Research & Development',
  'Retail',
  'Safety',
  'Security',
  'Small Business',
  'Smart Home',
  'Smart Office',
  'Sports',
  'Stealth',
  'Tourism',
  'Utilities',
  'Waste Management',
];

function SubIndustryDropdown(props) {
  const { innerRef, ...rest } = props;
  return (
    <select {...rest} ref={innerRef}>
      <option value="">Choose an option</option>
      {subIndustries.map((subIndustry) => (
        <option key={subIndustry}>{subIndustry}</option>
      ))}
    </select>
  );
}

export default React.forwardRef((props, ref) => <SubIndustryDropdown innerRef={ref} {...props} />);
