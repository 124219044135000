import React from 'react';
import _ from 'lodash';
import classNames from 'clsx';
import onClickOutsideHOC from 'react-onclickoutside';

class DropdownSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.onBodyKeyDown = function (e) {
      if (e.keyCode === 27) {
        this.setState({
          isOpen: false,
        });
      }
    }.bind(this);

    document.addEventListener('keydown', this.onBodyKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onBodyKeyDown);
  }

  openMenu() {
    const { onOpen = _.noop } = this.props;
    this.setState({ isOpen: !this.state.isOpen });
    onOpen();
  }

  handleClickOutside() {
    const { onClose = _.noop } = this.props;
    onClose();
    this.setState({ isOpen: false });
  }

  render() {
    const { selectedIdx, options = [], className = '' } = this.props;
    const containerClasses = classNames('DropdownSelect', className);
    const dropdownClasses = classNames('dropdown-container', {
      active: this.state.isOpen,
    });

    return (
      <div className={containerClasses}>
        <a className="select-button" onMouseDown={() => this.openMenu()}>
          {options[selectedIdx]}
        </a>
        <div className={dropdownClasses} onClick={() => this.handleClickOutside()}>
          {options.map((option, idx) => (
            <div onMouseDown={() => this.onSelect(idx)} key={idx}>
              {option}
            </div>
          ))}
        </div>
      </div>
    );
  }

  onSelect(idx) {
    const { onSelect = _.noop } = this.props;
    onSelect(idx);
  }
}

export default onClickOutsideHOC(DropdownSelect);
