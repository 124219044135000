import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createForm } from 'vendor/redux-form-utils';

export default function (Wrapped, formConfig) {
  class FormHOC extends React.PureComponent {
    validateForm() {
      const { validateForm, form, showValidationErrors } = this.props;

      if (showValidationErrors) {
        return validateForm(form, true);
      }
    }

    submitForm(event) {
      event.preventDefault();

      const { startSubmit, cancelSubmit, submit, form } = this.props;

      startSubmit()
        .then(() => this.validateForm())
        .then(() => submit(form, this))
        .then(() => {
          if (this.props.onSuccess) {
            this.props.onSuccess();
          }
        })
        .catch((error) => {
          if (error instanceof Error) {
            throw new Error(error);
          }
          return cancelSubmit();
        });
    }

    setFields(fields) {
      const { setFields } = this.props;
      setFields(fields);
    }

    resetForm() {
      const { clearAll, resetState } = this.props;

      clearAll();
      resetState();

      return Promise.resolve();
    }

    render() {
      const { form, fields } = this.props;

      return (
        <Wrapped
          form={form}
          {...fields}
          {...this.props}
          onFormSubmit={(event) => this.submitForm(event)}
          resetForm={this.resetForm.bind(this)}
          setFields={this.setFields.bind(this)}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      ...state.forms[formConfig.form],
      reset: state.reset,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
      ...bindActionCreators(
        {
          startSubmit: formConfig.actions.startSubmit,
          cancelSubmit: formConfig.actions.cancelSubmit,
          validateForm: formConfig.actions.validateForm,
          resetState: formConfig.actions.resetState,
          setFields: formConfig.actions.setFields,
          submit: formConfig.actions.submit,
        },
        dispatch
      ),
    };
  }

  const EnhancedForm = createForm(formConfig)(FormHOC);
  return connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);
}
