import {
  calculateTotalDueTodayRequest,
  calculateTotalDueTodaySuccess,
  calculateTotalDueTodayError,
  updateTotalDueToday,
} from './actions';
import { isArrayEmpty, buildSimConfiguration, buildTasks } from './actionUtils';
import { getUserContextData } from '../../../api/util';
import { batchActivation } from '../../../api';

export const calculateTotalDueToday =
  ({ sims, plan, type = 'individual', excludeSims = [] }) =>
  async (dispatch, getState) => {
    const state = getState();

    const simRangeConfig = { ranges: sims };
    const simsConfig = { sims };

    const config = await buildSimConfiguration({
      plan: {
        id: plan?.id,
        zone: plan?.zone,
      },
      sims: type === 'range' ? simRangeConfig : simsConfig,
      ...(excludeSims && { excludeSims }),
    });

    dispatch(calculateTotalDueTodayRequest());

    if (isArrayEmpty(sims)) {
      dispatch(calculateTotalDueTodaySuccess());
      dispatch(updateTotalDueToday(0));

      return Promise.resolve({
        totalDueToday: null,
      });
    }

    try {
      const result = await batchActivation(
        buildTasks(state, config),
        true,
        false,
        getUserContextData(state)
      );
      const { subtotal: totalDueToday } = result;

      dispatch(calculateTotalDueTodaySuccess());
      dispatch(updateTotalDueToday(totalDueToday));

      return Promise.resolve({
        totalDueToday,
      });
    } catch (totalError) {
      const { error, data } = totalError;
      const totalDueToday = data?.subtotal;

      dispatch(calculateTotalDueTodayError(error));
      dispatch(updateTotalDueToday(totalDueToday));

      return Promise.resolve({
        error,
      });
    }
  };
