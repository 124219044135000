import _ from 'lodash';
import { generate } from 'shortid';
import * as API from 'common-js/api';
import { fetchBatchJobDetails } from 'common-js/api/devices';
import { getUserContextData } from 'common-js/api/util';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as filterComparators from 'common-js/constants/filterComparators';
import {
  serializeDeviceFilterTree,
  deviceDataAdapter,
  deviceFilterTreeToJson,
} from 'common-js/utils/devices';
import { setActivityHistoryDetailsQueryString, setDeviceTableQueryString } from 'devices/util';
import * as actionTypes from '../actionTypes';
import { PAGE_SIZE, TASK_PAGE_SIZE } from '../reducer';
import {
  getSelectedTagIds,
  selectActivityHistoryDetailsStartAfterId,
  selectBulkSelectionFields,
  shouldUseSearchEndpoint,
} from '../selectors';

const ES_SEARCH_RESULT_LIMIT = 10_000;

let latestFetchDevicesRequestId = 0;

const apiFetchDevices = () => (dispatch, getState) => {
  const state = getState();
  const userContextData = getUserContextData(state);
  const { devices } = state;
  const states = !_.isEmpty(devices.segments.state) ? Object.keys(devices.segments.state) : [];
  const tags = !_.isEmpty(devices.filters.tag_id) ? Object.keys(devices.filters.tag_id) : [];

  const promises = [];
  const startAfterId =
    devices.page.current > 1 ? devices.page.pageIds[devices.page.current - 2]?.startAfterId : null;

  promises.push(
    API.getDevices(
      {
        states,
        tagids: tags,
        limit: PAGE_SIZE,
        ...(startAfterId && { startafter: startAfterId }),
      },
      userContextData
    )
  );

  // pageIds will be null if a new filter has been selected, so get new pages & count
  if (_.isNil(devices.page.pageIds) || devices.page.pageIds.length === 0) {
    promises.push(API.getDevicesPages({ states, tagids: tags, limit: PAGE_SIZE }, userContextData));
    promises.push(API.getDevicesCount({ states, tagids: tags }, userContextData));
  }

  return Promise.all(promises).then((res) => {
    const devicesResult = res[0];
    const pagesResult = res[1];
    const countResult = res[2];
    const totalPages = pagesResult ? pagesResult.data.length + 1 : devices.page.total;
    const pageIds = pagesResult ? pagesResult.data : devices.page.pageIds;
    const totalHits = countResult ? countResult.data : devices.page.totalDeviceCount;

    dispatch({
      type: actionTypes.FETCH_DEVICES_SUCCESS,
      loadingKey: actionTypes.FETCH_DEVICES,
      hits: devicesResult.data.map(deviceDataAdapter),
      totalPages,
      pageIds,
      totalHits,
    });

    dispatch({
      type: actionTypes.SET_EXPORT_COUNT,
      exportCount: totalHits,
    });

    setDeviceTableQueryString(devices);
  });
};

export const fetchDevices = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_DEVICES });

  const requestId = generate();
  latestFetchDevicesRequestId = requestId;
  const state = getState();

  if (shouldUseSearchEndpoint(state)) {
    const userContextData = getUserContextData(state);
    const { devices } = state;
    const searchFilters = deviceFilterTreeToJson(devices);
    return API.searchDevices(searchFilters, userContextData)
      .then((res) => {
        if (latestFetchDevicesRequestId !== requestId) return;

        dispatch({
          type: actionTypes.FETCH_DEVICES_SUCCESS,
          loadingKey: actionTypes.FETCH_DEVICES,
          hits: res.hits,
          totalPages: res.totalPages,
          pageIds: null,
          totalHits: res.totalHits,
        });

        dispatch({
          type: actionTypes.SET_EXPORT_COUNT,
          exportCount: res.totalHits,
        });

        setDeviceTableQueryString(devices);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_DEVICES_ERROR,
          loadingKey: actionTypes.FETCH_DEVICES,
          error,
        });
      });
  }
  return apiFetchDevices()(dispatch, getState);
};

export const fetchSlimDevices = (deviceIds) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_SLIM_DEVICES });

  return API.fetchSlimDevices(deviceIds)
    .then((resp) => {
      dispatch({
        type: actionTypes.FETCH_SLIM_DEVICES_SUCCESS,
        loadingKey: actionTypes.FETCH_SLIM_DEVICES,
        devices: resp.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FETCH_SLIM_DEVICES_ERROR,
        loadingKey: actionTypes.FETCH_SLIM_DEVICES,
        error,
      });
    });
};

export const toggleSelection = (deviceId) => ({
  type: actionTypes.TOGGLE_SELECTION,
  deviceId,
});

export const clearSelection = () => ({
  type: actionTypes.CLEAR_SELECTION,
});

export const toggleTagSelectionForDevices = (tag, deviceIds) => (dispatch, getState) => {
  const state = getState();
  const { allSelected } = tag.deviceSelection;
  const apiAction = allSelected ? API.removeTagFromDevices : API.addTagToDevices;
  // if the user has bulk tagging, send bulk selection fields.  otherwise, we just send the list of device IDs
  const requestBody = state.releaseFlag.bulk_tag
    ? selectBulkSelectionFields(state)
    : { deviceids: deviceIds };

  dispatch({
    type: actionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES,
    tagId: tag.id,
  });

  return apiAction(tag.id, requestBody, getUserContextData(state))
    .then((updatedTag) => {
      dispatch({
        type: actionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES_SUCCESS,
        loadingKey: actionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES,
        tagId: tag.id,
        updatedTag,
        deviceIds: tag.deviceids,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES_ERROR,
        error,
      });
    });
};

export const toggleSelectionPage = () => ({
  type: actionTypes.TOGGLE_SELECTION_PAGE,
});

export const selectAllDevices = () => ({
  type: actionTypes.SELECT_ALL_DEVICES,
});

export const requestDevicesReport = (deviceStates) => (dispatch, getState) => {
  const state = getState();
  const tagIds = getSelectedTagIds(state);
  const userContextData = getUserContextData(state);

  return API.requestDevicesReport(userContextData, tagIds, deviceStates)
    .then((data) => Promise.resolve(data.data))
    .catch((error) => Promise.reject(error));
};

export const setSimsBeingActivated = (sims) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SIMS_BEING_ACTIVATED,
    sims,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.SET_SIM_ACTIVATION_TIME,
    });
  }, 30 * 1000);
};

export const checkForSimReadyState = () => (dispatch, getState) => {
  const state = getState();
  const { simsActivating } = state.devices.uiState.activateNotification;

  // for now, let's just check to see if the devices are in algolia by calling
  // and comparing results. In the future, we should use a not-yet-created
  // endpoint that returns the indexing state of a list of devices.
  const simNumbers = simsActivating.map((s) => s.name);
  const simFilters = simNumbers.reduce(
    (acc, simNumber) => ({
      ...acc,
      [simNumber]: { comparator: 'IN' },
    }),
    {}
  );
  const filterString = serializeDeviceFilterTree({
    ...state.devices,
    filters: { iccid: simFilters },
  });

  return API.searchDevices(filterString, getUserContextData(state)).then((response) => {
    if (response.totalHits === simsActivating.length) {
      dispatch({ type: actionTypes.SET_ACTIVATING_SIMS_ACTIVE });

      setTimeout(() => {
        dispatch({ type: actionTypes.HIDE_ACTIVATION_NOTIFICAITON });
      }, 10 * 1000);
    }
  });
};

export const setTunnelableBulk = (deviceIds, isTunnelable) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_TUNNELABLE_BULK_REQUEST,
  });

  const state = getState();
  return API.setTunnelableBulk(
    deviceIds,
    isTunnelable,
    getUserContextData(state),
    state.devices.devicesCache
  )
    .then((successResponse) => {
      dispatch({
        type: actionTypes.SET_TUNNELABLE_BULK_SUCCESS,
      });

      sendAnalyticsEvent({
        type: isTunnelable
          ? analyticsTypes.SPACEBRIDGE_TUNNELING_ENABLED
          : analyticsTypes.SPACEBRIDGE_TUNNELING_DISABLED,
      });

      return Promise.resolve(successResponse);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SET_TUNNELABLE_BULK_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

/**
 * TODO: This should be updated to use hooks, currently only used by the BulkUpdateOverageForm
 */
export const bulkUpdateOverage = (deviceIds, limit) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.UPDATE_OVERAGE_BULK_REQUEST,
  });

  const state = getState();
  return API.bulkUpdateOverageLimit(
    deviceIds,
    limit,
    state.devices.devicesCache,
    getUserContextData(state)
  )
    .then((data) => {
      dispatch({
        type: actionTypes.UPDATE_OVERAGE_BULK_SUCCESS,
        successes: data.successes,
        failures: data.failures,
      });

      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_OVERAGE_BULK_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export const sendSmsToDevice = (deviceIds, message, fromnumber) => (dispatch) => {
  dispatch({
    type: actionTypes.SEND_SMS_TO_DEVICE_REQUEST,
  });

  return API.sendSmsToDevice(deviceIds, message, fromnumber)
    .then(() => {
      dispatch({
        type: actionTypes.SEND_SMS_TO_DEVICE_SUCCESS,
      });

      sendAnalyticsEvent({
        type: analyticsTypes.DATA_MESSAGE_SENT,
        data: {
          method: 'sms',
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SEND_SMS_TO_DEVICE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const sendDataToDevice = (deviceIds, payload, port, protocol) => (dispatch) => {
  dispatch({
    type: actionTypes.SEND_DATA_TO_DEVICE_REQUEST,
  });

  return API.sendDataToDevice(deviceIds, payload, port, protocol)
    .then((data) => {
      dispatch({
        type: actionTypes.SEND_DATA_TO_DEVICE_SUCCESS,
        orders: data,
      });
      sendAnalyticsEvent({
        type: analyticsTypes.DATA_MESSAGE_SENT,
        data: {
          method: 'cloud data',
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SEND_DATA_TO_DEVICE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const bulkMoveDevices = (deviceIds, destinationOrgId) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.MOVE_BULK_REQUEST,
  });

  const state = getState();
  return API.bulkMoveDevices(
    deviceIds,
    destinationOrgId,
    getUserContextData(state),
    state.devices.devicesCache
  )
    .then((data) => {
      dispatch({
        type: actionTypes.MOVE_BULK_SUCCESS,
        successes: data.successes,
        failures: data.failures,
      });

      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.MOVE_BULK_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const hideActivationNotification = () => ({
  type: actionTypes.HIDE_ACTIVATION_NOTIFICAITON,
});

export const setFilterInputValue = (inputName, inputValue) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FILTER_INPUT_VALUE,
    inputName,
    inputValue,
  });

  return Promise.resolve();
};

export const clearFilterInputs = () => ({
  type: actionTypes.CLEAR_FILTER_INPUTS,
});

export const getHistoricDeviceCounts = (timestamp, deviceStates) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_HISTORIC_DEVICE_COUNT_REQUEST,
  });

  const state = getState();
  const userContextData = getUserContextData(state);

  return API.getHistoricDeviceCounts(timestamp, deviceStates, userContextData)
    .then((data) => {
      const counts = data[0];
      const connectedCount = data[1];

      dispatch({
        type: actionTypes.GET_HISTORIC_DEVICE_COUNT_SUCCESS,
        data: {
          ...counts,
          CONNECTED: connectedCount,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_HISTORIC_DEVICE_COUNT_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

const getDevicesFromSelectionWithFilters = async (state) => {
  const userContextData = getUserContextData(state);
  const { devices } = state;
  const { pagesSelected, byId, allSelected } = devices.selection;
  const deviceIds = Object.keys(byId);
  let reducedFields = [];

  const searchFilters = {
    ...deviceFilterTreeToJson(devices),
    filtered_response_fields: ['iccid', 'name', 'id', 'plan_id'],
    hitsPerPage: allSelected ? ES_SEARCH_RESULT_LIMIT : PAGE_SIZE,
  };

  if (deviceIds.length > 0) {
    const result = await API.searchDevices(
      {
        ...searchFilters,
        id: {
          [filterComparators.EQUAL]: deviceIds,
        },
      },
      userContextData
    );
    reducedFields = result.hits;
  }

  if (allSelected && pagesSelected.length === 0) {
    const result = await API.searchDevices(searchFilters, userContextData);
    reducedFields = [...reducedFields, ...result.hits];
  } else if (pagesSelected.length > 0) {
    const reducedField = await Promise.all(
      pagesSelected.map(async (page) => {
        const result = await API.searchDevices(
          {
            ...searchFilters,
            page,
          },
          userContextData
        );
        return result.hits;
      })
    );
    reducedFields = [...reducedFields, ...reducedField.flat()];
  }

  return reducedFields;
};

const getDevicesFromSelectionWithoutFilters = async (state) => {
  const userContextData = getUserContextData(state);
  const { devices } = state;
  const { pageIds } = devices.page;
  const { pagesSelected, byId, allSelected } = devices.selection;
  const states = !_.isEmpty(devices.segments.state) ? Object.keys(devices.segments.state) : [];
  const tags = !_.isEmpty(devices.filters.tag_id) ? Object.keys(devices.filters.tag_id) : [];
  const deviceIds = Object.keys(byId);
  const selectedStartAfterIds = pageIds
    ? pageIds.filter(
        (id, index) => pagesSelected.includes(index + 2) // pages selected don't start at 0
      )
    : [];

  const filters = {
    states,
    tagids: tags,
    limit: allSelected ? ES_SEARCH_RESULT_LIMIT : PAGE_SIZE,
    slim: 1,
  };

  let resultFields = [];

  if (deviceIds.length > 0) {
    const response = await API.fetchSlimDevices(deviceIds);
    resultFields = response.data;
  }

  // The pagesSelected.includes(1) case here is because page 1 of the devices does not have a corresponding startAfterID
  if ((allSelected && selectedStartAfterIds.length === 0) || pagesSelected.includes(1)) {
    const response = await API.getDevices(filters, userContextData);
    resultFields = [...resultFields, ...response.data];
  }

  if (selectedStartAfterIds.length > 0) {
    const allDevices = await Promise.all(
      selectedStartAfterIds.map(async ({ startAfterId }) => {
        const response = await API.getDevices(
          {
            ...filters,
            startafter: startAfterId,
          },
          userContextData
        );
        return response.data;
      })
    );
    resultFields = [...resultFields, ...allDevices.flat()];
  }

  return resultFields;
};

export const getAndReduceDevicesToFields = () => async (dispatch, getState) => {
  const state = getState();
  const useSearch = shouldUseSearchEndpoint(state);
  const { devices } = state;
  const { excludedIds } = devices.selection;
  const excludedDeviceIds = Object.keys(excludedIds);

  let deviceResults = [];

  dispatch({
    type: actionTypes.FETCH_DEVICE_FIELDS_REQUEST,
  });

  try {
    if (useSearch) {
      deviceResults = await getDevicesFromSelectionWithFilters(state);
    } else {
      deviceResults = await getDevicesFromSelectionWithoutFilters(state);
    }

    const filtered = deviceResults.filter(
      (device) => !excludedDeviceIds.includes(device.id.toString())
    );
    const formattedDevices = filtered.reduce(
      (accDevices, currDevice) => [
        ...accDevices,
        {
          iccid: useSearch ? currDevice.iccid : currDevice.links?.cellular?.[0].sim,
          planId: useSearch ? currDevice.plan_id : currDevice.links?.cellular?.[0].plan?.id,
          name: currDevice.name,
        },
      ],
      []
    );
    dispatch({
      type: actionTypes.FETCH_DEVICE_FIELDS_SUCCESS,
    });

    return formattedDevices;
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_DEVICE_FIELDS_ERROR,
      error,
    });
  }

  return undefined;
};

export const getBatchJobDetails =
  ({ batchJobId, currentPage, onlyDevices = false }) =>
  async (dispatch, getState) => {
    const state = getState();
    const userContextData = getUserContextData(state);

    const startAfterId = selectActivityHistoryDetailsStartAfterId(state, currentPage);

    dispatch({
      type: onlyDevices
        ? actionTypes.GET_BATCH_JOB_DETAILS_REQUEST_ONLY_DEVICES
        : actionTypes.GET_BATCH_JOB_DETAILS_REQUEST,
    });

    try {
      const response = await fetchBatchJobDetails({
        userContextData,
        batchJobId,
        startAfterId,
      });
      // The backend doesn't always report the correct number of pages
      // But it does report the currect number of devices, so we can calculate the correct pages here
      const deviceCount = response?.total_device_count || 0;
      const actualTotalPages = Math.ceil(deviceCount / TASK_PAGE_SIZE);
      const payload = {
        deviceCount,
        pages: (response.pages || []).slice(0, actualTotalPages),
        totalPages: actualTotalPages,
        action: response.action || '',
        requester: {
          firstName: response.requester_first_name || '',
          lastName: response.requester_last_name || '',
          orgName: response.requester_org_name || '',
        },
        devices: (response.devices || []).map((device) => ({
          deviceName: device.name,
          tags: device.tags,
          iccid: device.iccid,
          imei: device.imei,
          deviceId: device.deviceid,
          linkId: device.linkid,
        })),
        date: response.timestamp_end,
        source: response.source,
      };

      const onlyDevicesPayload = {
        devices: payload.devices,
      };

      dispatch({
        type: onlyDevices
          ? actionTypes.GET_BATCH_JOB_DETAILS_SUCCESS_ONLY_DEVICES
          : actionTypes.GET_BATCH_JOB_DETAILS_SUCCESS,
        payload: onlyDevices ? onlyDevicesPayload : payload,
      });

      setActivityHistoryDetailsQueryString({ currentPage });

      return onlyDevices ? onlyDevicesPayload : payload;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_BATCH_JOB_DETAILS_ERROR,
        error,
      });
    }
    return undefined;
  };
