import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Icon, Button, Tooltip } from '@holokit/core';
import { Input, TextArea, Submit } from '../components/form';
import FormHOC from './FormHOC';
import { formGenerator } from './formGenerator';
import { MessagePanel } from '../components';

class SimulateMessageForm extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    topics: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    formProcessing: PropTypes.bool,
    showValidationErrors: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    serverError: PropTypes.string,
  };

  componentDidMount() {
    const { resetState, setFields, topics, data } = this.props;

    resetState();

    setFields({
      topics: topics.value === '' ? 'FOO_BAR' : topics.value,
      data: data.value === '' ? '{"foo": "bar"}' : data.value,
    });
  }

  render() {
    const {
      form,
      data,
      topics,
      onFormSubmit,
      isSuccessful,
      formProcessing = false,
      showValidationErrors = false,
      serverError = null,
      isDirty,
      showCancelButton = false,
      onCancelClick = _.noop,
      isFlashing,
    } = this.props;

    return (
      <form
        className={`form SimulateMessageForm${isFlashing ? ' flash' : ''}`}
        onSubmit={onFormSubmit}
        noValidate
      >
        <div className="grid-row vertical-align">
          <div className="grid-item label">
            <div>
              <div>Topics</div>
              <div>
                <span>Optional</span>
                <Tooltip
                  content={
                    <div>
                      A comma-separated list of topics to be sent with the simulated message. All
                      system topics, such as _DEVICE_X_ will be stripped before sending.{' '}
                    </div>
                  }
                >
                  <Icon
                    name="Circle--info"
                    size="minor"
                    svgProps={{ style: { fill: '#8008F7' } }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="grid-item-3">
            <Input
              {...topics}
              type="text"
              name="fromNumber"
              error={form.topics.error}
              showValidation={showValidationErrors}
              classNames="grid-item-2"
            />
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-item label">
            <div className="grid-row">
              <div>
                Data
                <Tooltip
                  content={
                    <div>
                      The data payload of the simulated message. Can be a string or valid JSON
                      (double-quotes only).
                    </div>
                  }
                >
                  <Icon
                    name="Circle--info"
                    size="minor"
                    svgProps={{ style: { fill: '#8008F7' } }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="grid-item-3">
            <TextArea
              {...data}
              type="text"
              name="message"
              error={form.data.error}
              showValidation={showValidationErrors}
            />
          </div>
        </div>
        <div className="grid-row submit">
          <div className="grid-item label-text" />
          <div className="grid-item-3">
            {isSuccessful && !isDirty && (
              <MessagePanel fullWidth messageType="success">
                Simulation message sent successfully. Message will appear in the logs momentarily.
              </MessagePanel>
            )}
            {serverError && (
              <MessagePanel fullWidth messageType="error">
                {serverError}
              </MessagePanel>
            )}
            <Submit
              classNames="btn-brand"
              label="Simulate message"
              formProcessing={formProcessing}
            />
            {showCancelButton && (
              <Button onClick={onCancelClick} type="secondary">
                Cancel
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export const config = formGenerator({
  form: 'SimulateMessageForm',
  fields: ['topics', 'data'],
  validators: {},

  onSubmit(form, actionTypes, dispatch, state, HOC) {
    const topicsAlreadySent = ['_RESTAPI_', `_DEVICE_${HOC.props.deviceId}`];

    const topics = form.topics.value
      .split(',')
      .map((topic) => topic.trim())
      .filter((topic) => topicsAlreadySent.indexOf(topic) === -1 && topic !== '');

    HOC.props
      .simMessageFromDevice(HOC.props.deviceId, form.data.value, topics)
      .then(() => {
        HOC.props.revealDrawer();

        dispatch({ type: actionTypes.SUCCESS });
      })
      .catch((errorMessage) => {
        dispatch({
          type: actionTypes.ERROR,
          errorMessage,
        });
      });
  },
});

export default FormHOC(SimulateMessageForm, config);
