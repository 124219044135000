import find from 'lodash/find';
import isString from 'lodash/isString';
import uniq from 'lodash/uniq';
import { SYS_TOPICS } from '../../constants/sysTopics';
import { LOGIN_REQUEST, LOGOUT_REQUEST } from '../account/actionTypes';
import { GET_ALL_APP_DATA_SUCCESS } from '../apps/actionTypes';
import { FETCH_DEVICES_SUCCESS } from '../devices/actionTypes';
import { GET_ALL_SUCCESS } from '../log/actionTypes';
import { CHANGE_CONTEXT } from '../organization/actionTypes';
import { GET_TOPICS_SUCCESS, ADD_TOPIC } from './actionTypes';

export const initialState = {
  builtInTopics: SYS_TOPICS,
  deviceTopics: [],
  appTopics: [] as Array<any>,
  recentTopics: [],
  allTopics: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function topicReducer(state = initialState, action) {
  let { recentTopics } = state;
  let deDupedRecentTopics = [];

  switch (action.type) {
    case LOGOUT_REQUEST:
    case LOGIN_REQUEST:
      return { ...state, ...initialState };
    case CHANGE_CONTEXT:
      return { ...state, deviceTopics: [], recentTopics: [] };

    case FETCH_DEVICES_SUCCESS:
      return {
        ...state,
        deviceTopics: uniq(
          state.deviceTopics.concat(action.hits.map((device) => `_DEVICE_${device.id}_`))
        ),
      };

    case GET_TOPICS_SUCCESS:
      return { ...state, allTopics: action.topics };

    case GET_ALL_APP_DATA_SUCCESS: {
      const modifiedState = { ...state };
      modifiedState.appTopics = [];

      action.mergedApps.forEach((app) => {
        Object.keys(app?.userProperties).forEach((property) => {
          const matchingSchemaProperty = find(app?.appSchema?.userpropertieslist, { property });

          if (matchingSchemaProperty && matchingSchemaProperty.type === 'topic') {
            const topics = isString(app.userProperties[property])
              ? [app.userProperties[property]]
              : app.userProperties[property];

            topics.forEach((sPT) => {
              if (modifiedState.appTopics.indexOf(sPT) === -1) {
                modifiedState.appTopics.push(sPT);
              }
            });
          }
        });
      });

      return { ...modifiedState, appTopics: modifiedState.appTopics };
    }

    case GET_ALL_SUCCESS:
      action.logs.forEach((log) => {
        recentTopics = recentTopics.concat(log.tags);
      });

      deDupedRecentTopics = uniq(recentTopics);

      return { ...state, recentTopics: deDupedRecentTopics };

    case ADD_TOPIC:
      return { ...state };
    default:
      return state;
  }
}

export default topicReducer;
