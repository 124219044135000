const calculateLength = (segments: any[]) =>
  segments.reduce(
    (total: any, segment: { text: string | any[] }) => total + segment.text.length,
    0
  );

const ellipses = { text: '…', highlight: false };

const textChunkInArray = (text: any, highlight = false) =>
  text
    ? [
        {
          text,
          highlight,
        },
      ]
    : [];

const truncateUnhighlightedTextAtEnd = (text: string | any[], limit: number) => [
  {
    text: text.slice(0, limit),
    highlight: false,
  },
  ellipses,
];

const truncateLongHighlight = (
  highlight: { text: string | any[] },
  lengthBefore: number,
  lengthAfter: number,
  limit: number
) => {
  const moreBefore = lengthBefore >= lengthAfter;
  const text = moreBefore ? highlight.text.slice(-limit) : highlight.text.slice(0, limit);

  // If there's more text before the highlight than after, then we want to show
  // the ellipses before instead of after. Otherwise, if there's text before or
  // after the highlight, we show ellipses appropriately.
  return [
    ...(lengthBefore > 0 || moreBefore ? [ellipses] : []),
    { ...highlight, text },
    ...(lengthAfter > 0 ? [ellipses] : []),
  ];
};

const truncateTextAtBeginning = (
  segments: string | any[],
  lengthOfHighlight: any,
  lengthAfter: any,
  limit: number
) => {
  // Figure out how much of the first segment we should keep to stay under
  // the limit
  const offset = lengthOfHighlight + lengthAfter - limit;
  const text = offset < 0 ? segments[0].text.slice(offset) : '';

  const beforeChunk = textChunkInArray(text);
  const highlightAndRemainder = segments.slice(1);

  return [ellipses, ...beforeChunk, ...highlightAndRemainder];
};

const truncateTextOnBothSides = (
  segments: { text: string | any[] }[],
  indexOfHighlight: number,
  lengthOfHighlight: number,
  lengthOnSides: any,
  limit: number
) => {
  // Figure out how much of each segment before/after we can keep
  const offset = Math.floor((limit - lengthOfHighlight) / 2);
  const beforeText = offset > 0 ? segments[0].text.slice(-offset) : '';
  const afterText = segments[indexOfHighlight + 1].text.slice(0, offset);

  const beforeChunk = textChunkInArray(beforeText);
  const highlight = segments[indexOfHighlight];
  const afterChunk = textChunkInArray(afterText);

  return [ellipses, ...beforeChunk, highlight, ...afterChunk, ellipses];
};

const truncateTextAtEnd = (
  segments: string | any[],
  indexOfFirstHighlight: number,
  lengthOfHighlight: number,
  lengthBefore: number,
  limit: number
) => {
  // Figure out how much of the segment after the highlight we should keep to
  // stay under the limit
  const indexAfterHighlight = indexOfFirstHighlight + 1;
  const offset = limit - lengthOfHighlight - lengthBefore;
  const text = segments[indexAfterHighlight].text.slice(0, offset);

  const remainderAndHighlight = segments.slice(0, indexAfterHighlight);
  const afterChunk = textChunkInArray(text);

  return [...remainderAndHighlight, ...afterChunk, ellipses];
};

// truncateField takes an array of segments where each segment has this kind of
// structure:
// `{ text: 'fragment', highlight: true }`
// and truncates if necessary, using ellipses. It returns a modified array of
// segments.
const truncateField = (segments: any[], limit = 22) => {
  const totalLength = calculateLength(segments);
  const indexOfFirstHighlight = segments.findIndex(
    (segment: { highlight: any }) => segment.highlight
  );

  // If the length of the full string is less than our truncation limit, no
  // need to truncate, so just return the original list of segments
  if (totalLength <= limit) {
    return segments;
  }

  // If the query didn't match the field at all (so there are no highlighted
  // segments), then we truncate at the end (since by this point we already
  // know we're over the truncation limit)
  if (indexOfFirstHighlight === -1) {
    return truncateUnhighlightedTextAtEnd(segments[0].text, limit);
  }

  // The field could have multiple highlights, but we focus on the first
  // (because we literally can't focus on more than one if they're at opposite
  // ends of the match and the field length is longer than our truncation
  // limit)
  const highlight = segments[indexOfFirstHighlight];
  const lengths = {
    highlight: highlight.text.length,
    before: calculateLength(
      segments.filter((segment: any, index: number) => index < indexOfFirstHighlight)
    ),
    after: calculateLength(
      segments.filter((segment: any, index: number) => index > indexOfFirstHighlight)
    ),
  };

  // If the highlighted part of the text is itself longer than our truncation
  // limit, then we need to show just a section of the highlight and figure out
  // which side(s) we should show ellipses on
  if (lengths.highlight > limit) {
    return truncateLongHighlight(highlight, lengths.before, lengths.after, limit);
  }

  // If there's the same amount of text on both sides of the highlight, split the
  // difference and truncate on both sides
  if (lengths.before === lengths.after) {
    return truncateTextOnBothSides(
      segments,
      indexOfFirstHighlight,
      lengths.highlight,
      lengths.before,
      limit
    );
  }

  // If there's more text before the highlight than after, the highlight is
  // closer to the end so we want to truncate at the beginning
  if (lengths.before > lengths.after) {
    return truncateTextAtBeginning(segments, lengths.highlight, lengths.after, limit);
  }

  // There's more text after the highlight than before, so the highlight is
  // closer to the beginning and we therefore want to truncate at the end
  return truncateTextAtEnd(
    segments,
    indexOfFirstHighlight,
    lengths.highlight,
    lengths.before,
    limit
  );
};

export default truncateField;
