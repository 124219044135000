import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { FormField } from '@hologram-dimension/form-field';
import { Select } from '@hologram-dimension/select';
import { TextInput } from '@hologram-dimension/text-input';
import { Callout } from '@holokit/core';
import { Device } from 'common-js/types/Device';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';
import { toByteStringObject } from 'common-js/utils/numberFormatter';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useSubmitUsageAlertForm from './useSubmitUsageAlertForm';

interface DataUsageAlertFormProps {
  device: Device;
  onSuccess: () => void;
  onCancel: () => void;
}

interface UsageAlertFormProps {
  close: () => void;
  device: Device;
  onDataAlertUpdateSuccess: () => void;
}

type FormValues = {
  dataAlertAmount: string;
  amountUnit: number;
};

const unitToBytes = {
  KB: 1_000,
  MB: 1_000_000,
  GB: 1_000_000_000,
};

const unitOptions = Object.keys(unitToBytes).map((option) => ({
  value: unitToBytes[option],
  label: option,
}));

const OutcomeCallout = ({ calloutType, text, deviceName }) => (
  <Callout
    type={calloutType}
    text={
      <>
        {text} <strong className="UsageAlertForm__device-name">{deviceName}</strong>.
      </>
    }
    iconColor={Callout.THEME.COLOR}
    defaultIcon
  />
);

const DataUsageAlertForm: React.FC<DataUsageAlertFormProps> = ({ device, onSuccess, onCancel }) => {
  const dataLimitObject =
    device.data_threshold > 0 ? toByteStringObject(device.data_threshold, 0) : null;
  const defaultValues = useMemo(
    () => ({
      dataAlertAmount: dataLimitObject?.byteValue || '', // it's not good practice to pass null values to inputs
      amountUnit: dataLimitObject ? unitToBytes[dataLimitObject.unit] : 1000000,
    }),
    [dataLimitObject]
  );
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitted },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues, {
        keepValues: true,
        keepIsSubmitted: true,
      });
    }
  }, [isSubmitSuccessful, reset, defaultValues]);

  const {
    error: serverError,
    handleSubmit: onFormSubmit,
    loading,
  } = useSubmitUsageAlertForm({ device, onSuccess });

  const submissionSucceeded = isSubmitted && !serverError && !isDirty;
  const showError = serverError && !isDirty;

  return (
    <div className="UsageAlert">
      <div className="UsageAlertForm">
        <Form
          className="UsageAlertForm__form"
          onSubmit={handleSubmit(onFormSubmit)}
          footerActions={
            <>
              <Button className="UsageAlertForm__button" onClick={onCancel} variant="secondary">
                {submissionSucceeded ? 'Done' : 'Cancel'}
              </Button>
              <Button
                className="UsageAlertForm__button"
                loading={loading}
                type="submit"
                variant="primary"
              >
                Update data usage limit
              </Button>
            </>
          }
        >
          <FormField
            className={applyConditionalClassNames({
              'UsageAlertForm__custom-input': true,
              'UsageAlertForm__custom-input--error': errors.dataAlertAmount,
            })}
            invalid={!!errors.dataAlertAmount}
            label="Data usage alert"
            hiddenLabel
            controlsLayoutDirection="row"
            controlsLayoutGap="none"
            validationMessage={errors.dataAlertAmount?.message}
          >
            {({ primaryControlId, ariaDescribedbyIds, invalid }) => (
              <>
                <TextInput
                  id={primaryControlId}
                  ariaDescribedby={ariaDescribedbyIds}
                  ariaLabel="Data amount"
                  invalid={invalid}
                  placeholder="Data amount"
                  type="text"
                  {...register('dataAlertAmount', {
                    validate: {
                      numerical: (v) =>
                        (/^[0-9]*$/.test(v) && v !== '') || 'Please enter data alert threshold.',
                    },
                  })}
                />
                <Select
                  id="customAmountUnit"
                  className="UsageAlertForm__custom-unit"
                  ariaDescribedby={ariaDescribedbyIds}
                  ariaLabel="Data amount"
                  invalid={invalid}
                  defaultValue={unitToBytes.MB.toString()}
                  options={unitOptions}
                  {...register('amountUnit', {
                    valueAsNumber: true,
                  })}
                />
              </>
            )}
          </FormField>

          {submissionSucceeded && (
            <OutcomeCallout
              calloutType={Callout.TYPE.SUCCESS}
              text="The data alert threshold was changed for"
              deviceName={device.name}
            />
          )}
          {showError && (
            <OutcomeCallout
              calloutType={Callout.TYPE.ERROR}
              text="There was an error updating the data alert threshold for"
              deviceName={device.name}
            />
          )}
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({}, dispatch),
});

connect(null, mapDispatchToProps)(DataUsageAlertForm);

const UsageAlertForm: React.FC<UsageAlertFormProps> = ({
  close,
  device,
  onDataAlertUpdateSuccess,
}) => <DataUsageAlertForm device={device} onCancel={close} onSuccess={onDataAlertUpdateSuccess} />;

export default UsageAlertForm;
