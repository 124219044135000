import useApi from 'common-js/api/useApi';
import { useCallback } from 'react';
import {
  API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER,
  apiToClientPasswordErrors,
} from 'common-js/constants/setAccountPasswordFormStrings';

type ApiErrorCause = 'password' | 'userid' | 'unknown';

export const API_RESPONSE_CHANGE_PASSWORD_INVALID_PASSWORD = 'Invalid password request';
export const API_RESPONSE_CHANGE_PASSWORD_USERID_MISSING = `Required field 'userid' missing or empty`;

// If the API error message should be reformatted, add it to this object
export const apiResponseToMessage: Record<string, string> = {
  [API_RESPONSE_CHANGE_PASSWORD_INVALID_PASSWORD]: `Something went wrong on our end.`,
  [API_RESPONSE_CHANGE_PASSWORD_USERID_MISSING]: 'Something went wrong on our end.',
  ...apiToClientPasswordErrors,
};

// Specify which part of the request triggered the API error
const apiResponseToCause: Record<string, ApiErrorCause> = {
  [API_RESPONSE_CHANGE_PASSWORD_INVALID_PASSWORD]: 'unknown',
  [API_RESPONSE_CHANGE_PASSWORD_USERID_MISSING]: 'userid',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER]: 'password',
};
interface ApiErrorResponse {
  success: false;
  error: Partial<Record<ApiErrorCause, string>>;
}

interface ApiSuccessResponse {
  success: true;
}

type ChangePasswordCallback = (params: {
  password: string;
}) => Promise<ApiErrorResponse | ApiSuccessResponse>;

const useChangePassword = ({ userId }) => {
  const apiCall = useApi.call(`/users/${userId}/password?_id=${userId}&withCredentials=true`, {
    method: 'POST',
  });

  return useCallback<ChangePasswordCallback>(
    async ({ password }) => {
      try {
        await apiCall({ body: { password, password_confirmation: password } });
        const successResponse: ApiSuccessResponse = {
          success: true,
        };
        return successResponse;
      } catch (error) {
        const message = apiResponseToMessage[error as string] || error;
        const cause = apiResponseToCause[error as string] || 'unknown';

        const errorResponse: ApiErrorResponse = {
          success: false,
          error: {
            [cause]: message,
          },
        };

        return errorResponse;
      }
    },
    [apiCall]
  );
};

export default useChangePassword;
