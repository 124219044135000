const PREFIX = 'USAGE_';

export const RUN_REPORT_START = `${PREFIX}RUN_REPORT_START`;
export const RUN_REPORT_FINISH = `${PREFIX}RUN_REPORT_FINISH`;
export const SET_REPORT_DATE_TITLE = `${PREFIX}SET_REPORT_DATE_TITLE`;
export const PATCH_FILTERS = `${PREFIX}PATCH_FILTERS`;
export const SET_QUICK_FILTER = `${PREFIX}SET_QUICK_FILTER`;
export const SET_CUSTOM_DATE = `${PREFIX}SET_CUSTOM_DATE`;
export const SET_FILTERS_DATAUSEDDETAILS = `${PREFIX}SET_FILTERS_DATAUSEDDETAILS`;
export const SET_FILTERS_BILLINGREPORT = `${PREFIX}SET_FILTERS_BILLINGREPORT`;
export const VIEWMORE_LIVEUSAGE = `${PREFIX}VIEWMORE_LIVEUSAGE`;

export const GET_DATAUSED_REQUEST = `${PREFIX}GET_DATAUSED_REQUEST`;
export const GET_DATAUSED_SUCCESS = `${PREFIX}GET_DATAUSED_SUCCESS`;
export const GET_DATAUSED_ERROR = `${PREFIX}GET_DATAUSED_ERROR`;

export const GET_DATAUSED_INSPECT_REQUEST = `${PREFIX}GET_DATAUSED_INSPECT_REQUEST`;
export const GET_DATAUSED_INSPECT_SUCCESS = `${PREFIX}GET_DATAUSED_INSPECT_SUCCESS`;
export const GET_DATAUSED_INSPECT_ERROR = `${PREFIX}GET_DATAUSED_INSPECT_ERROR`;

export const GET_USAGESUMMARY_REQUEST = `${PREFIX}GET_USAGESUMMARY_REQUEST`;
export const GET_USAGESUMMARY_SUCCESS = `${PREFIX}GET_USAGESUMMARY_SUCCESS`;
export const GET_USAGESUMMARY_ERROR = `${PREFIX}GET_USAGESUMMARY_ERROR`;

export const GET_LIVEUSAGE_REQUEST = `${PREFIX}GET_LIVEUSAGE_REQUEST`;
export const GET_LIVEUSAGE_SUCCESS = `${PREFIX}GET_LIVEUSAGE_SUCCESS`;
export const GET_LIVEUSAGE_ERROR = `${PREFIX}GET_LIVEUSAGE_ERROR`;

export const GET_DEVICEBREAKDOWN_REQUEST = `${PREFIX}GET_DEVICEBREAKDOWN_REQUEST`;
export const GET_DEVICEBREAKDOWN_SUCCESS = `${PREFIX}GET_DEVICEBREAKDOWN_SUCCESS`;
export const GET_DEVICEBREAKDOWN_ERROR = `${PREFIX}GET_DEVICEBREAKDOWN_ERROR`;

export const EXPORT_DATAUSEDINSPECT_REQUEST = `${PREFIX}EXPORT_DATAUSEDINSPECT_REQUEST`;
export const EXPORT_DATAUSEDINSPECT_BATCH_SUCCESS = `${PREFIX}EXPORT_DATAUSEDINSPECT_BATCH_SUCCESS`;
export const EXPORT_DATAUSEDINSPECT_SUCCESS = `${PREFIX}EXPORT_DATAUSEDINSPECT_SUCCESS`;
export const EXPORT_DATAUSEDINSPECT_ERROR = `${PREFIX}EXPORT_DATAUSEDINSPECT_ERROR`;
export const EXPORT_DATAUSEDINSPECT_TOOLARGE = `${PREFIX}EXPORT_DATAUSEDINSPECT_TOOLARGE`;

export const EXPORT_DEVICEBREAKDOWN_REQUEST = `${PREFIX}EXPORT_DEVICEBREAKDOWN_REQUEST`;
export const EXPORT_DEVICEBREAKDOWN_BATCH_SUCCESS = `${PREFIX}EXPORT_DEVICEBREAKDOWN_BATCH_SUCCESS`;
export const EXPORT_DEVICEBREAKDOWN_SUCCESS = `${PREFIX}EXPORT_DEVICEBREAKDOWN_SUCCESS`;
export const EXPORT_DEVICEBREAKDOWN_ERROR = `${PREFIX}EXPORT_DEVICEBREAKDOWN_ERROR`;
export const EXPORT_DEVICEBREAKDOWN_TOOLARGE = `${PREFIX}EXPORT_DEVICEBREAKDOWN_ERROR`;

export const GET_BILLING_REPORT_REQUEST = `${PREFIX}GET_BILLING_REPORT_REQUEST`;
export const GET_BILLING_REPORT_SUCCESS = `${PREFIX}GET_BILLING_REPORT_SUCCESS`;
export const GET_BILLING_REPORT_ERROR = `${PREFIX}GET_BILLING_REPORT_ERROR`;

export const GET_DEVICES_REPORT_REQUEST = `${PREFIX}GET_DEVICES_REPORT_REQUEST`;
export const GET_DEVICES_REPORT_SUCCESS = `${PREFIX}GET_DEVICES_REPORT_SUCCESS`;
export const GET_DEVICES_REPORT_ERROR = `${PREFIX}GET_DEVICES_REPORT_ERROR`;

export const DOWNLOAD_USAGE_REPORT_REQUEST = `${PREFIX}DOWNLOAD_USAGE_REPORT_REQUEST`;
export const DOWNLOAD_USAGE_REPORT_SUCCESS = `${PREFIX}DOWNLOAD_USAGE_REPORT_SUCCESS`;
export const DOWNLOAD_USAGE_REPORT_ERROR = `${PREFIX}DOWNLOAD_USAGE_REPORT_ERROR`;
