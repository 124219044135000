import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'clsx';
import { Button } from '@holokit/core';

function ExpandCollapseToggle({ expanded, onExpandChange }) {
  return (
    <div className="ExpandCollapseToggle">
      <Button
        classes={ClassNames('ExpandCollapseToggle__button', {
          'ExpandCollapseToggle__button--expanded': expanded,
        })}
        iconRight="Chevron--double--south"
        onClick={onExpandChange}
        small
        type="secondary"
      />
    </div>
  );
}

ExpandCollapseToggle.propTypes = {
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
};

ExpandCollapseToggle.defaultProps = {
  expanded: true,
  onExpandChange: () => {},
};

export default ExpandCollapseToggle;
