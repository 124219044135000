import { getActivationQuote } from 'activation/sims/SimUtils';
import { getUserContextData } from 'common-js/api/util';
import { batchActivation } from 'common-js/api';
import { validateSimRangeRequest, validateSimRangeSuccess, validateSimRangeError } from './actions';
import { buildSimConfiguration, buildTasks, handleRangeErrors } from './actionUtils';

export default ({ start, end, plan, invalidatePreflightSims }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { promoBalance } = state.organization.balance;
    const { availableBalance } = state.organization.balance;

    const config = await buildSimConfiguration({
      plan: {
        id: plan?.id,
        zone: plan?.zone,
      },
      sims: {
        ranges: [
          {
            start,
            end,
          },
        ],
      },
      invalidatePreflightSims,
    });

    dispatch(validateSimRangeRequest());

    try {
      const result = await batchActivation(
        buildTasks(state, config),
        true,
        false,
        getUserContextData(state)
      );
      const { total } = getActivationQuote(
        result.valid_tasks,
        result.subtotal,
        promoBalance,
        true,
        availableBalance
      );

      const firstTask = result?.valid_tasks?.[0] ?? {};
      const orderData = firstTask.order_data?.[0] ?? {};
      const totalValidSims = orderData?.quantity ?? 0;

      dispatch(validateSimRangeSuccess(start, end, totalValidSims));

      return Promise.resolve({ totalValidSims, total });
    } catch (error) {
      const { error: validationError, totalValidSims, invalidSims } = handleRangeErrors(error);

      if (totalValidSims > 0) {
        dispatch(validateSimRangeSuccess(start, end, totalValidSims));
      }

      dispatch(validateSimRangeError({ error: validationError, invalidSims }));

      return Promise.resolve({
        invalidSims,
        totalValidSims,
        error: validationError,
        totalDueToday: error?.data?.subtotal,
      });
    }
  };
