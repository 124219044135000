import React from 'react';
import { useFormContext } from 'react-hook-form';
import CompletedStepHeader from '../common/scaffolding/CompletedStepHeader';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';
import SidebarAttribute from '../common/scaffolding/SidebarAttribute';

const STEP_NAME = 'Assign tags';

interface LimitsSidebarItemProps extends SidebarItemProps {}

function TagsSidebarItem({ step, currentStep }: LimitsSidebarItemProps) {
  const { tags = [] } = useFormContext().getValues('tags') ?? {};
  const tagNames = tags.map((tag) => tag?.name);
  const formattedTagNames = tagNames.length > 1 ? tagNames.join(', ') : tagNames;

  if (currentStep > step) {
    return (
      <CompletedStepHeader name={STEP_NAME}>
        <SidebarAttribute name="Tags" value={tags.length ? formattedTagNames : 'None'} />
      </CompletedStepHeader>
    );
  }

  return <IncompleteStepHeader step={step} currentStep={currentStep} name={STEP_NAME} />;
}

export default TagsSidebarItem;
