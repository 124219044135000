import * as DeviceStates from '../constants/deviceStates';

export const getDeviceStatusColor = (status, connected, releaseFlags) => {
  if (connected) {
    return 'blue';
  }
  switch (status) {
    case DeviceStates.LIVE_PENDING:
    case DeviceStates.PAUSED_PENDING_SYS:
    case DeviceStates.PAUSED_PENDING_USER:
    case DeviceStates.DEAD_PENDING:
      return 'yellow';
    case DeviceStates.PAUSED_SYS:
    case DeviceStates.PAUSED_USER:
      return 'red';
    case DeviceStates.LIVE:
      return 'green';
    case DeviceStates.CONNECTED:
      return releaseFlags.proplan ? 'blue' : 'green';
    case DeviceStates.DEAD:
      return 'black';
    case DeviceStates.TEST_ACTIVATE_PENDING:
    case DeviceStates.TEST_HIBERNATE_PENDING:
    case DeviceStates.TEST_AUTOACTIVATE_PENDING:
      return 'orange';
    case DeviceStates.TEST_ACTIVATE:
    case DeviceStates.TEST_HIBERNATE:
    case DeviceStates.TEST_AUTOACTIVATE:
      return 'purple';
    default:
      return 'gray';
  }
};

export const getDeviceStatusText = (status, connected = false) => {
  if (connected) {
    return 'Connected';
  }
  switch (status) {
    case DeviceStates.LIVE_PENDING:
      return 'Activating';
    case DeviceStates.PAUSED_PENDING_SYS:
    case DeviceStates.PAUSED_PENDING_USER:
      return 'Pausing';
    case DeviceStates.DEAD_PENDING:
      return 'Deactivating';
    case DeviceStates.PAUSED_SYS:
      // Removing status reason logic to make devices table, filter, and single device page match
      // Leaving this code here for now because we may want to use this to generate a tooltip or
      // other contextual data for the user.
      // if (
      //   statusReason === DeviceStates.REASON_BILLING ||
      //   statusReason === DeviceStates.REASON_USAGE_BILLING
      // ) {
      //   return 'Paused by Billing Issue'
      // } else if (statusReason === DeviceStates.REASON_USAGE_LIMIT) {
      //   return 'Paused by Data Limit'
      // } else {
      return 'Paused by System';
    // }
    case DeviceStates.PAUSED_USER:
      return 'Paused by User';
    case DeviceStates.LIVE:
      return 'Ready';
    case DeviceStates.CONNECTED:
      return 'Connected';
    case DeviceStates.DEAD:
      return 'Deactivated';
    case DeviceStates.INACTIVE:
      return 'Inactive';
    case DeviceStates.FROZEN:
      return 'Frozen';
    case DeviceStates.TEST_ACTIVATE_PENDING:
    case DeviceStates.TEST_HIBERNATE_PENDING:
    case DeviceStates.TEST_AUTOACTIVATE_PENDING:
      return 'Configuring';
    case DeviceStates.TEST_AUTOACTIVATE:
    case DeviceStates.TEST_ACTIVATE:
    case DeviceStates.TEST_HIBERNATE:
      return 'In testing';
    case DeviceStates.INACTIVE_TESTED:
      return 'Hibernating, Test Mode Complete';
    default:
      return 'Configuring';
  }
};

export const getDeviceStatusCode = (status) => {
  switch (status) {
    case 'Activating':
      return DeviceStates.LIVE_PENDING;
    case 'Pausing':
      return [DeviceStates.PAUSED_PENDING_SYS, DeviceStates.PAUSED_PENDING_USER];
    case 'Deactivating':
      return DeviceStates.DEAD_PENDING;
    case 'Paused by System':
      return DeviceStates.PAUSED_SYS;
    case 'Paused by User':
      return DeviceStates.PAUSED_USER;
    case 'Ready':
      return DeviceStates.LIVE;
    case 'Connected':
      return DeviceStates.CONNECTED;
    case 'Deactivated':
      return DeviceStates.DEAD;
    case 'Inactive':
      return DeviceStates.INACTIVE;
    case 'Frozen':
      return DeviceStates.FROZEN;
    case 'Configuring':
      return [
        DeviceStates.TEST_ACTIVATE_PENDING,
        DeviceStates.TEST_HIBERNATE_PENDING,
        DeviceStates.TEST_AUTOACTIVATE_PENDING,
      ];
    case 'Preflight Connected':
      return [DeviceStates.TEST_ACTIVATE, DeviceStates.TEST_HIBERNATE];
    case 'Preflight Ready':
      return [
        DeviceStates.TEST_ACTIVATE,
        DeviceStates.TEST_AUTOACTIVATE,
        DeviceStates.TEST_HIBERNATE,
      ];
    case 'Hibernating, Test Mode Complete':
      return DeviceStates.INACTIVE_TESTED;
    default:
      return '';
  }
};
