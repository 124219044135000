import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeader } from '../components';

class WhyPhoneNumberModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="WhyPhoneNumberModal">
        <ModalHeader title="WHY DO I NEED A PHONE NUMBER?" />
        <p>
          A phone number allows you to easily send an SMS to your device from another SMS-compatible
          device. Phone numbers are NOT necessary to deliver an SMS via API calls or via the
          Dashboard - they basically enable you to send an SMS to your device directly from another
          cell phone.
        </p>
        <p>
          The main reason to purchase a phone number is if you want to send an SMS from a phone to
          your device instead of using the Dashboard or API.
        </p>
        <p>
          Note that the device will be able to respond back to your SMS but the response may show up
          as being from a different number. This is a special, internal number that your phone may
          not be able to SMS directly so you should send all messages to the purchased phone number.
          This is something that we're working with our carrier partners to improve in the near
          future.
        </p>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({}),
  (dispatch) => bindActionCreators({}, dispatch)
)(WhyPhoneNumberModal);
