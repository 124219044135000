import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Icon } from '@holokit/core';
import classNames from 'clsx';

class SearchButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  render() {
    const {
      value,
      onChange,
      placeholder,
      searchLabel,
      showClearButton,
      alwaysFocused,
      autoFocus,
      disabled,
      className,
    } = this.props;
    const { focused } = this.state;

    const classes = classNames(className, {
      'has-text': value !== '',
      focused,
      disabled,
    });

    return (
      <div
        className={`SearchButton ${classes}`}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {alwaysFocused || focused || value !== '' ? (
          <div className="grid-row vertical-align SearchButton__opened">
            <Icon
              classes="SearchButton__search-icon"
              name="Search"
              size="major"
              svgProps={{ style: { fill: '#5071ff' } }}
            />
            <input
              type="text"
              value={value}
              onBlur={() => {
                this.onBlur();
              }}
              onChange={(e) => {
                onChange(e.currentTarget.value);
              }}
              placeholder={placeholder}
              autoFocus={autoFocus}
            />
            {showClearButton && (
              <button
                className="SearchButton__close-button Button--unstyled"
                onClick={() => {
                  onChange('');
                  this.setState({ focused: false });
                }}
              >
                <Icon name="Close" size="micro" svgProps={{ style: { fill: '#7d7d7d' } }} />
              </button>
            )}
          </div>
        ) : (
          <div className="grid-row vertical-align" onClick={() => this.onFocus()}>
            <Icon
              classes="icon-search"
              name="Search"
              size="major"
              svgProps={{ style: { fill: '#5071ff' } }}
            />
            <div className="grid-item search-label">{searchLabel}</div>
          </div>
        )}
      </div>
    );
  }

  onFocus() {
    this.setState({
      focused: true,
    });
  }

  onBlur() {
    if (this.props.value === '') {
      this.setState({
        focused: false,
      });
    }
  }
}

SearchButton.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  searchLabel: PropTypes.string,
  onChange: PropTypes.func,
};

SearchButton.defaultProps = {
  value: '',
  onChange: _.noop,
  placeholder: 'Search...',
  searchLabel: 'Search',
  showClearButton: true,
  alwaysFocused: false,
  autoFocus: true,
  disabled: false,
};

export default SearchButton;
