import { Plan } from 'common-js/types/Plan';
import { useMemo } from 'react';

export const PLAN_TABS = {
  CUSTOM: 'custom',
  STANDARD: 'standard',
};

interface UsePlanConfigProps {
  hasAnyStandardPlansBySims: boolean;
  onlyOneStandardRegion: boolean;
  filteredCustomPlansBySims: Array<Plan>;
  fetchingCarriers: boolean;
  hasCustomPlans: boolean;
  isPreflight: boolean;
  preflightPlanData: Plan;
  preflightPlanDataLoaded: boolean;
  preflightPlanId: number;
  selectedTab: string;
  selectedRegionId?: RegionId;
}

const usePlanConfig = ({
  hasAnyStandardPlansBySims,
  onlyOneStandardRegion,
  filteredCustomPlansBySims,
  fetchingCarriers,
  hasCustomPlans,
  isPreflight,
  preflightPlanData,
  preflightPlanDataLoaded,
  preflightPlanId,
  selectedTab,
  selectedRegionId = -1,
}: UsePlanConfigProps) =>
  useMemo(() => {
    const customPlanList = preflightPlanDataLoaded
      ? [
          {
            ...preflightPlanData,
            planName: preflightPlanData.displayName ?? preflightPlanData.planName,
          },
        ]
      : filteredCustomPlansBySims;

    // Defaults while loading
    const isLoading = fetchingCarriers;
    let canChangePlanView = false;
    let showCustomPlans = false;
    let hasSelectableRegions = false;
    let showStandardRegionSelect = false;
    let noAllowedPlansAndNoCoverage = false;

    if (isPreflight && preflightPlanId) {
      canChangePlanView = false;
      showCustomPlans = true;
      showStandardRegionSelect = false;
      hasSelectableRegions = false;
    } else if (!isLoading && hasCustomPlans) {
      // We're not loading anymore and we can theoretically change plan views.
      // Validate we have things to change to
      if ((!customPlanList || customPlanList.length === 0) && !hasAnyStandardPlansBySims) {
        canChangePlanView = false;
        showCustomPlans = false;
        hasSelectableRegions = false;
        showStandardRegionSelect = false;
        noAllowedPlansAndNoCoverage = true; // Special flag for custom orgs
      } else {
        canChangePlanView = true;
        showCustomPlans = selectedTab === PLAN_TABS.CUSTOM;

        hasSelectableRegions =
          !showCustomPlans && hasAnyStandardPlansBySims && !onlyOneStandardRegion;
        showStandardRegionSelect = hasSelectableRegions && selectedRegionId === -1;
      }
    }

    return {
      isLoading,
      customPlanList,
      canChangePlanView,
      showCustomPlans,
      showStandardRegionSelect,
      hasSelectableRegions,
      noAllowedPlansAndNoCoverage,
    };
  }, [
    preflightPlanDataLoaded,
    preflightPlanData,
    filteredCustomPlansBySims,
    fetchingCarriers,
    isPreflight,
    preflightPlanId,
    hasCustomPlans,
    hasAnyStandardPlansBySims,
    selectedTab,
    onlyOneStandardRegion,
    selectedRegionId,
  ]);

export default usePlanConfig;
