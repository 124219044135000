import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@holokit/core';
import { getDeviceStatusColor, getDeviceStatusText } from '../../common/utils/deviceStatus';
import { deviceStatusTooltipStrings } from '../../common/constants/deviceStatusTooltipStrings';

function CellDeviceState({ deviceState, connected, releaseFlags }) {
  const statusColor = getDeviceStatusColor(deviceState, connected, releaseFlags);
  const statusText = getDeviceStatusText(deviceState, connected);

  const tooltipTitle =
    statusText === 'In testing' ? 'Pre-deployment testing with preflight' : `${statusText} Status`;

  return (
    <Tooltip block content={deviceStatusTooltipStrings[deviceState]} title={tooltipTitle}>
      <div className="DevicesTable__cell">
        <div className="Orb__wrapper">
          <div className={`Orb Orb--${statusColor}`} />
        </div>
        {statusText}
      </div>
    </Tooltip>
  );
}

CellDeviceState.propTypes = {
  deviceState: PropTypes.string.isRequired,
  releaseFlags: PropTypes.shape({ proplan: PropTypes.bool }).isRequired,
};

export default CellDeviceState;
