import { Button, Icon } from '@holokit/core';
import classNames from 'clsx';
import { DeviceUsagePerNetwork } from 'common-js/types/Device';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import _ from 'lodash';
import React from 'react';

interface DeviceDataBreakdownTableRowProps {
  bytes: number;
  numSessions: number;
  sms?: number;
  networkName?: string;
  totalRow?: boolean;
  subdued?: boolean;
}

const DeviceDataBreakdownTableRow = ({
  bytes,
  numSessions,
  sms = 0,
  networkName,
  totalRow = false,
  subdued = false,
}: DeviceDataBreakdownTableRowProps) => (
  <tr
    className={classNames('body-data', {
      total: totalRow,
      'row-subdued': subdued,
    })}
  >
    <td className={classNames('left-padded', { 'align-top': totalRow })}>
      {totalRow ? 'Total' : networkName}
    </td>
    <td>{toByteStringFormatted(bytes / numSessions)}</td>
    <td>{toByteStringFormatted(bytes)}</td>
    <td>{numSessions}</td>
    <td className={classNames({ 'right-padded': totalRow })}>{sms}</td>
  </tr>
);

const buildRowSet = (device: DeviceUsagePerNetwork, idx: number, setLength: number) =>
  [
    <DeviceDataBreakdownTableRow
      key={idx}
      bytes={device.total_bytes}
      numSessions={device.sessions}
      sms={device.sms}
      totalRow
    />,
  ].concat(
    _.map(device.usage, (usageRow, uIdx) => (
      <DeviceDataBreakdownTableRow
        key={setLength * (idx + 1) + uIdx}
        networkName={usageRow.network_name}
        bytes={usageRow.bytes}
        numSessions={usageRow.sessions}
        sms={usageRow.sms}
        subdued
      />
    ))
  );

interface DeviceDataBreakdownTableProps {
  data: Array<DeviceUsagePerNetwork>;
  loading: boolean;
  loadingMore?: boolean;
  continues?: boolean;
  fromInspect?: boolean;
}
const DeviceDataBreakdownTable = ({
  data = [],
  loading,
  loadingMore = false,
  continues = false,
  fromInspect = false,
}: DeviceDataBreakdownTableProps) => {
  if (loading) {
    return (
      <div className="UsageTable DeviceBreakdownTable">
        <div className="table-loading">
          <div className="DimensionLoader__legacy-padding-override">Loading...</div>
        </div>
      </div>
    );
  }

  if (data.length === 0 && fromInspect) {
    return (
      <div className="UsageTable DeviceBreakdownTable">
        <div />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="UsageTable DeviceBreakdownTable">
        <div className="UsageTable-nodata">
          <Icon name="info-circle" />
          <div>
            There are no data sessions to display for this set of devices and specific time frame.
            Try adjusting tags or the selected date range.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="UsageTable DeviceBreakdownTable">
      <div>
        <table className="UsageTable">
          <tbody>
            <tr className="header">
              <th style={{ width: '50%' }} className="left-padded">
                Networks
              </th>
              <th style={{ width: '13%' }}>avg. daily usage</th>
              <th style={{ width: '13%' }}>total used</th>
              <th style={{ width: '13%' }}>sessions</th>
              <th style={{ width: '13%' }} className="right-padded">
                sms
              </th>
            </tr>
            {data.map((d, idx) => buildRowSet(d, idx, data.length))}
          </tbody>
        </table>
        {continues && (
          <div className="load-more-container">
            <Button loading={loadingMore} disabled={loadingMore} type="secondary">
              Load more
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceDataBreakdownTable;
