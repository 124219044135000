import * as actionTypes from './actionTypes';
import * as API from '../../api';
import { getUserContextData } from '../../api/util';

export function addTopic() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_TOPIC,
    });
  };
}

export function getTopics() {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_TOPICS_REQUEST,
    });

    return API.getTopics(getUserContextData(state))
      .then((topics) => {
        dispatch({
          type: actionTypes.GET_TOPICS_SUCCESS,
          topics,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_TOPICS_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}
