import { Icon } from '@hologram-dimension/icon';
import { Tooltip } from '@holokit/core';
import { Device } from 'common-js/types/Device';
import React from 'react';
import {
  CONNECTED,
  DEAD,
  DEAD_PENDING,
  DeviceState,
  INACTIVE,
  LIVE,
  LIVE_PENDING,
  PAUSED_PENDING_SYS,
  PAUSED_PENDING_USER,
  PAUSED_SYS,
  PAUSED_USER,
  TEST_ACTIVATE,
  TEST_ACTIVATE_PENDING,
  TEST_AUTOACTIVATE,
  TEST_AUTOACTIVATE_PENDING,
  TEST_HIBERNATE,
  TEST_HIBERNATE_PENDING,
} from '../constants/deviceStates';
import { deviceStatusTooltipStrings } from '../constants/deviceStatusTooltipStrings';

interface DeviceStatusIndicatorProps {
  device: Device;
}
interface DeviceStatusIndicatorState {}

class DeviceStatusIndicator extends React.PureComponent<
  DeviceStatusIndicatorProps,
  DeviceStatusIndicatorState
> {
  getDerivedStateInfo() {
    const { device } = this.props;
    const { state } = device;
    const activeStates: Array<DeviceState> = [
      LIVE,
      LIVE_PENDING,
      TEST_ACTIVATE,
      TEST_ACTIVATE_PENDING,
      TEST_AUTOACTIVATE,
      TEST_AUTOACTIVATE_PENDING,
      TEST_HIBERNATE,
      TEST_HIBERNATE_PENDING,
    ];
    const connected = device.lastsession.active && activeStates.includes(state);

    if (connected) {
      switch (state) {
        case TEST_ACTIVATE_PENDING:
        case TEST_HIBERNATE_PENDING:
        case TEST_AUTOACTIVATE_PENDING:
        case TEST_ACTIVATE:
        case TEST_HIBERNATE:
        case TEST_AUTOACTIVATE:
          return {
            className: 'purple',
            status: state,
            text: 'CONNECTED',
            preflightIcon: true,
          };

        default:
          return { className: 'blue', status: state, text: 'CONNECTED' };
      }
    }

    switch (state) {
      case INACTIVE:
        return { className: 'gray', status: state };
      case LIVE_PENDING:
        return { className: 'orange', status: state, text: 'ACTIVATING' };
      case LIVE:
        return { className: 'green', status: state, text: 'READY' };
      case CONNECTED:
        return { className: 'blue', status: state, text: 'CONNECTED' };
      case TEST_ACTIVATE_PENDING:
      case TEST_HIBERNATE_PENDING:
      case TEST_AUTOACTIVATE_PENDING:
      case TEST_ACTIVATE:
      case TEST_HIBERNATE:
      case TEST_AUTOACTIVATE:
        return {
          className: 'purple',
          status: state,
          text: 'READY',
          preflightIcon: true,
        };
      case PAUSED_PENDING_SYS:
      case PAUSED_PENDING_USER:
        return { className: 'orange', text: 'PAUSING', status: state };
      case PAUSED_USER:
        return { className: 'pink', text: 'PAUSED BY USER', status: state };
      case PAUSED_SYS:
        return { className: 'pink', status: state, text: 'PAUSED BY SYSTEM' };
      case DEAD_PENDING:
      case DEAD:
        return { className: 'black', status: state };
      default:
        return { className: 'gray', status: state };
    }
  }

  render() {
    const { device } = this.props;
    const stateInfo = this.getDerivedStateInfo();
    const tooltipParagraphKey = device.lastsession.active ? CONNECTED : device.sysState;
    let statusText = stateInfo.text || stateInfo.status;
    if (statusText === 'DEAD-PENDING') {
      statusText = 'DEACTIVATING';
    } else if (statusText === 'DEAD') {
      statusText = 'DEACTIVATED';
    }

    return (
      <div className={`DeviceStatusIndicator ${stateInfo.className}`}>
        {stateInfo.preflightIcon && (
          <div className="preflight">
            <Icon name="Airplane" size="large" fill="DdsColorPaletteWhite" />
          </div>
        )}
        <Tooltip
          block
          content={
            <div className="DeviceStatusIndicator__text">
              {deviceStatusTooltipStrings[tooltipParagraphKey]}
            </div>
          }
          position="bottom-left"
          title={`${statusText} Status`}
        >
          <div className="status">{statusText}</div>
        </Tooltip>
        {device.lastsession.radio_access_technology && (
          <div className="rat-type">{device.lastsession.radio_access_technology}</div>
        )}
      </div>
    );
  }
}

export default DeviceStatusIndicator;
