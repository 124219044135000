import Message from './message';
import { HEARTBEAT } from './types';

export default class HeartbeatMessage extends Message {
  type = HEARTBEAT;

  data = '';

  triggeredApps = [];

  heartbeatApp = null;

  topicsPublished = [];

  logId = null;

  constructor(log, apps = []) {
    super(log);

    this.data = log.data;
    this.triggeredApps = this.findTriggeredApps(log, apps);
    this.topicsPublished = log.tags;
    this.logId = log.id;
    this.heartbeatApp = this.findExistingHeartbeatApp(log, apps);
  }

  findExistingHeartbeatApp(log, apps) {
    const genHeartbeatTopicId = log.tags[0];
    let result;

    apps.forEach((app) => {
      if (app.publishesTo.includes(genHeartbeatTopicId)) {
        result = app;
      }
    });

    return result;
  }
}
