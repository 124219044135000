import {
  OrganizationProvider,
  SimDetailsPage,
  SimInventoryApiTypes,
  SimInventoryCommonTypes,
} from '@hologram-hyper-dashboard/components';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import sendAnalyticsEvent from 'common-js/analytics/analytics';
import { getUserContextDataMemoized } from 'common-js/api/util';
import { FullHeightCalculatorHOC } from 'common-js/components';
import {
  DEVICES_DEVICES,
  DEVICE_BASE,
  SIM_DETAILS,
  SIM_PROFILES,
  SIM_SIMS_AND_DEVICES,
  SIM_USAGE,
  pathMatchesWithIdsReplaced,
} from 'common-js/constants/paths';
import { useAppDispatch, useAppSelector, useUserRole } from 'common-js/hooks';
import useOnAllTasksSuccessful from 'common-js/hooks/useOnAllTasksSuccessful';
import { openModal } from 'common-js/reducers/modal/actions';
import {
  selectHasEureka0Flag,
  selectHasManageSingleProfileSwap,
} from 'common-js/reducers/releaseFlag/selectors';
import { FC, useCallback, useRef, useState } from 'react';
import { browserHistory } from 'react-router';
import { OpenModalCallback } from 'types/hyper-dashboard';
import SubMenu from '../components/SubMenu';

interface SimDetailsProps {
  params: { simId: string };
  location: { state: {}; pathname: string };
}

const SimDetails: FC<SimDetailsProps> = ({ params, location }) => {
  const dispatch = useAppDispatch();
  const hasManageSingleProfileSwap: boolean = useAppSelector(selectHasManageSingleProfileSwap);
  const hasInFlight: boolean = useAppSelector(selectHasEureka0Flag);
  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;
  const { simId } = params;

  const [deviceInfo, setDeviceInfo] = useState<
    SimInventoryApiTypes.SimsDaoDeviceOverview | undefined
  >();

  const path = location.pathname;

  const getViewFromUrl = useCallback((url) => {
    if (pathMatchesWithIdsReplaced(SIM_SIMS_AND_DEVICES, url)) {
      return 'simanddevices';
    }
    if (pathMatchesWithIdsReplaced(SIM_PROFILES, url)) {
      return 'profiles';
    }
    if (pathMatchesWithIdsReplaced(SIM_USAGE, url)) {
      return 'usage';
    }
    if (pathMatchesWithIdsReplaced(SIM_DETAILS, url)) {
      return 'details';
    }

    return 'details';
  }, []);

  const view: SimInventoryCommonTypes.SimDetailsView = getViewFromUrl(path);

  const userRole = useUserRole();

  // TODO: Refactor these to `to` links in Hyper Dashboard
  const onNavigate = useCallback(
    (url: string) => {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: `SIM Details - ${getViewFromUrl(url)}`,
        },
      });

      browserHistory.push({ pathname: url });
    },
    [getViewFromUrl]
  );

  const handleModalOpen = useCallback<OpenModalCallback>(
    ({ modalName, modalProps }) => {
      dispatch(openModal(modalName, modalProps, 'narrow no-style'));
    },
    [dispatch]
  );

  const refreshRef = useRef<SimInventoryCommonTypes.OnSimsModified>(null);

  const onSimsModified = useCallback<SimInventoryCommonTypes.OnSimsModified>(
    (simsModified?: Array<HologramSimId>) => {
      if (refreshRef.current) {
        refreshRef.current(simsModified);
      }
    },
    []
  );

  useOnAllTasksSuccessful(onSimsModified);

  const onDeviceChange = useCallback(
    (newDeviceInfo?: SimInventoryApiTypes.SimsDaoDeviceOverview) => {
      setDeviceInfo(newDeviceInfo);
    },
    []
  );

  return (
    <OrganizationProvider organizationId={orgId}>
      <SubMenu simId={simId} deviceInfo={deviceInfo} />
      <FullHeightCalculatorHOC accountForDrawer relative classNames="full-height-container--alt">
        <div className="container">
          <SimDetailsPage
            hologramSimId={parseInt(simId, 10)}
            viewKey={view}
            onNavigate={onNavigate}
            simDetailBaseUrl={SIM_DETAILS}
            deviceBaseUrl={DEVICE_BASE}
            devicesBaseUrl={DEVICES_DEVICES}
            handleModalOpen={handleModalOpen}
            ref={refreshRef}
            onDeviceChange={onDeviceChange}
            userRole={userRole}
            profileSwapAllowed={hasManageSingleProfileSwap}
            hasInFlight={hasInFlight}
          />
        </div>
      </FullHeightCalculatorHOC>
    </OrganizationProvider>
  );
};

export default SimDetails;
