import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { Picture } from '@hologram-hyper-dashboard/components';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { updateUserSettings } from 'common-js/reducers/account/actions';
import { selectUserSettings } from 'common-js/reducers/account/selectors';
import hyperImg from 'img/sim_details/hyper-intro.webp?&imagetools';
import React from 'react';

const HyperEducationalCallout = () => {
  const userSettings = useAppSelector(selectUserSettings);
  const dispatch = useAppDispatch();

  const showCallout = userSettings?.edu_sdp_hyper_show === 'yes';
  if (!showCallout) {
    return null;
  }

  const handleDismiss = () => {
    dispatch(
      updateUserSettings({
        edu_sdp_hyper_show: 'no',
      })
    );
  };

  return (
    <Panel
      className="Hyper__educational"
      headerActions={
        <Button iconStart="Close" onClick={handleDismiss} variant="secondary">
          Dismiss
        </Button>
      }
      header="You are using Hyper"
    >
      <div className="Hyper__educational__body">
        <Picture
          imgClassName="Hyper__educational__image"
          image={hyperImg}
          alt=""
          setDimensions={false}
        />

        <div className="Hyper__educational__text">
          <p className="Hyper__educational__paragraph">
            Hyper is an eUICC SIM and platform that future-proofs deployments through global network
            redundancy and remote coverage updates. Multiple profiles can exist on an eUICC Hyper
            SIM, but only one is active at a time. You can view your SIM’s profile history below.
          </p>

          <Button
            href="https://support.hologram.io/hc/en-us/articles/360050663554"
            iconStart="Hyper"
            variant="secondary"
            target="_blank"
          >
            Learn more about Hyper
          </Button>
        </div>
      </div>
    </Panel>
  );
};

export default HyperEducationalCallout;
