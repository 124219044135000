import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { Callout, Loader } from '@holokit/core';
import { Button } from '@hologram-dimension/button';
import { ModalHeader } from 'common-js/components';
import * as DeviceStates from 'common-js/constants/deviceStates';
import * as Paths from 'common-js/constants/paths';
import {
  setIsPreflightFlag,
  setPreloadedActivationDevice,
} from 'common-js/reducers/activation/actions';
import { getStateCountsByName } from 'common-js/reducers/deviceFilter/selectors';
import { clearSelection } from 'common-js/reducers/devices/actions';
import { close } from 'common-js/reducers/modal/actions';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import { BUTTON_CLICK, ERROR_RETURN } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as Permissions from 'common-js/utils/permissions';
import { Checkbox } from '@hologram-dimension/checkbox';
import ErrorModal from './devices/ErrorModal';
import LoadingChipsSection from './devices/LoadingChipsSection';
import LinksSection from './devices/LinksSection';
import OverLimitModal from './devices/OverLimitModal';

const NUM_CHIPS = 9;

class BulkActivatePreflightModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmed: false,
      runningAction: false,
    };
  }

  confirmAction = () => {
    const { hasPostpay, userPermissions } = this.props;

    const hasBilling = userPermissions.includes(Permissions.BILLING);

    this.setState((state) => ({
      confirmed: !state.confirmed,
    }));

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Bulk Activate Preflight Modal - Confirm Action',
        billing: hasBilling,
        postpay: hasPostpay,
      },
    });
  };

  handleBackToDashboardClick = () => {
    const { closeModal_ } = this.props;
    closeModal_();
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Bulk Activate Preflight Modal - Back to Dashboard - Close',
      },
    });
  };

  handleCloseModalClick = () => {
    const { closeModal_ } = this.props;
    closeModal_();
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Bulk Activate Preflight Modal - Back to Dashboard - Cancel',
      },
    });
  };

  handleCtaClick = () => {
    const { clearSelection_, closeModal_, setIsPreflightFlag_ } = this.props;
    const { preview } = this.props;
    const { sims } = preview;

    this.setState({ runningAction: true });
    setIsPreflightFlag_();
    setPreloadedActivationDevice([sims]);
    clearSelection_();
    closeModal_();
    browserHistory.push(Paths.ACTIVATION);
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Bulk Activate Preflight Modal - Next CTA',
      },
    });
  };

  render() {
    const { deviceStateCountsByName, hasPostpay, overLimit = false, preview = {} } = this.props;

    if (overLimit) {
      return (
        <OverLimitModal
          title="Activate SIMs"
          icon="Lightning"
          onClose={this.handleBackToDashboardClick}
          messageBodyVerb="activate SIMs"
          analyticsEvent={{
            type: BUTTON_CLICK,
            data: { name: 'Bulk Action - 10k Modal - X Close' },
          }}
        />
      );
    }

    const { error, loading, sims } = preview;
    const { confirmed, runningAction } = this.state;

    const links = (sims ?? []).map((sim) => sim.name);
    const linkCount = links?.length ?? 0;

    const isHibernate =
      deviceStateCountsByName[DeviceStates.TEST_HIBERNATE] > 0 ||
      deviceStateCountsByName[DeviceStates.INACTIVE_TESTED] > 0;

    if (error || (!loading && linkCount === 0)) {
      sendAnalyticsEvent({
        type: ERROR_RETURN,
        data: {
          name: 'Bulk Activate - Operation Failure',
        },
      });
      return <ErrorModal onClose={this.handleBackToDashboardClick} />;
    }

    const calloutText = hasPostpay
      ? 'If you continue, you will be prompted to manually make plan selections. Please note that device renewal fees are charged every 30 days, beginning at the time of activation.'
      : 'If you continue, you will be prompted to manually make plan selections and pay a monthly renewal fee for these SIMs. Renewal fees are charged every 30 days, beginning at the time of activation.';

    return (
      <>
        <Loader isLoading={loading} />

        <ModalHeader
          title="Manually activate pre-deployment testing devices"
          icon="Lightning"
          analyticsEvent={{
            type: BUTTON_CLICK,
            data: { name: 'Bulk Activate Preflight Modal - X Close' },
          }}
        />

        {!isHibernate && (
          <div className="BulkDataActionModal__info-panel">
            You are about to override your organization’s assigned pre-deployment testing
            configurations for these SIMs.{' '}
            <a
              href="https://support.hologram.io/hc/en-us/articles/360035696473-Activating-SIMs-in-Preflight"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        )}

        <div className="BulkDataActionModal__contents">
          {loading ? (
            <LoadingChipsSection numChips={NUM_CHIPS} />
          ) : (
            <>
              <LinksSection
                title={`Selected devices (${addCommasToNumber(linkCount, false)})`}
                devices={links}
                total={linkCount}
                limit={NUM_CHIPS}
                keyPrefix="validLinks"
              />

              <Callout
                className="BulkActivatePreflightModal__callout"
                type={Callout.TYPE.WARNING}
                text={calloutText}
                size={Callout.SIZE.LARGE}
                iconColor={Callout.THEME.COLOR}
                defaultIcon
              />

              <Callout
                type={Callout.TYPE.WARNING}
                text="Once a device is activated, you cannot undo this action."
                size={Callout.SIZE.LARGE}
                iconColor={Callout.THEME.COLOR}
                defaultIcon
              />

              <Checkbox
                name="confirmation"
                className="BulkActivatePreflightModal__checkbox"
                defaultChecked={confirmed}
                onChange={this.confirmAction}
                label="Yes, I want to manually activate these pre-deployment testing devices."
              />
            </>
          )}
        </div>

        <div className="BulkDataActionModal__footer">
          {!loading && (
            <Button onClick={this.handleCloseModalClick} variant="secondary">
              Cancel
            </Button>
          )}
          {!loading && (
            <Button
              onClick={this.handleCtaClick}
              loading={runningAction}
              variant="primary"
              disabled={!confirmed}
            >
              Continue
            </Button>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  deviceStateCountsByName: getStateCountsByName(state),
  hasPostpay: state.organization?.balance?.hasPostpay,
  preview: state.activation?.preflight,
  userPermissions: getUserPermissions(state),
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      closeModal_: close,
      clearSelection_: clearSelection,
      setIsPreflightFlag_: setIsPreflightFlag,
    },
    dispatch
  )
)(BulkActivatePreflightModal);
