import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import analyticsEventBuilder from 'common-js/analytics';
import useGetLiveUsage from 'common-js/api/devices/useGetLiveUsage';
import { HoloHelmet } from 'common-js/components';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectCurrentDevice, selectLiveUsage } from 'common-js/reducers/currentDevice/selectors';
import { getSelectedOrgId } from 'common-js/reducers/organization/selectors';
import { FC, useEffect } from 'react';
import DeviceLocationMap from './DeviceLocationMap';
import DeviceStatusOverview from './DeviceStatusOverview';
import DeviceStatusStates from './DeviceStatusStates';
import LiveUsageChart from './LiveUsageChart';
import LiveUsageTable from './LiveUsageTable';
import NetworkConnectionsChart from './NetworkConnectionsChart';
import OpenCellIdAttribution from './OpenCellIdAttribution';

const Status: FC = () => {
  const orgId = useAppSelector(getSelectedOrgId);
  const device = useAppSelector(selectCurrentDevice);

  // Some components on this tab need the live usage for the device, load that now
  useGetLiveUsage(device.id);
  useEffect(() => {
    analyticsEventBuilder.pageView().page('Single Device', 'Status').send();
  }, []);
  const { loading: last48hoursLoading } = useAppSelector(selectLiveUsage);

  return (
    <div className="Status__page">
      <HoloHelmet title={`${device.name} - Status`} />

      <DeviceStatusStates />

      <div className="grid-row">
        <div className="grid-item">
          <Panel className="OverviewPanel" header="Connection overview" noContentPadding>
            <DeviceStatusOverview />
          </Panel>
        </div>
        <DeviceLocationMap lastSession={device.lastsession} />
      </div>

      <Panel header="Data usage in last 48 hours" noContentPadding isLoading={last48hoursLoading}>
        <NetworkConnectionsChart />
      </Panel>

      <Panel noContentPadding header="Recent data sessions">
        <div className="Panel__padding">
          <LiveUsageChart />
        </div>
        <div className="Panel__footer">
          This chart reports recent data sessions completed by this device. Use this chart to
          troubleshoot device connectivity behavior.
        </div>
      </Panel>

      <Panel
        header="Recent data usage"
        noContentPadding
        headerActions={
          <div>
            <Button
              href={`${process.env.VITE_API_ROOT}/reports/latestusage?deviceid=${device.id}&limit=2000&orgid=${orgId}`}
              variant="secondary"
              className="Status__viewJson"
              target="_blank"
            >
              View JSON
            </Button>
          </div>
        }
      >
        <LiveUsageTable />
      </Panel>
      <OpenCellIdAttribution />
    </div>
  );
};

export default Status;
