import { type SimInventoryCommonTypes } from '@hologram-hyper-dashboard/components';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  getBalance,
  getPostPay,
  getUserPermissions,
  selectBillingInformation,
} from 'common-js/reducers/organization/selectors';
import {
  ACTIVATION,
  BILLING,
  BILLING_VISIBLE,
  labelForRole,
  translatePermissionsToRole,
  type Permission,
} from 'common-js/utils/permissions';

type UseCanActivateHook = (props: {
  deviceType: SimInventoryCommonTypes.SimInventorySegmentName;
}) => {
  canActivate: boolean;
  hide: boolean;
  errorMessage?: string;
};

const useCanActivate: UseCanActivateHook = ({ deviceType }) => {
  const userPermissions = useAppSelector(getUserPermissions) as Array<Permission>;
  const hasActivation = userPermissions.includes(ACTIVATION);
  const hasBillingVisible = userPermissions.includes(BILLING_VISIBLE);
  const hasBillingPermissions = userPermissions.includes(BILLING);
  const hasPostpay = useAppSelector(getPostPay) ?? false;
  const balance = useAppSelector(getBalance);
  const availableBalance: number = balance?.availableBalance ?? 0;
  const billingInformation = useAppSelector(selectBillingInformation);
  const isCardSet = billingInformation?.isCardset ?? false;
  const isBillingAddressSet = billingInformation?.isBillingAddressSet ?? false;

  let canActivate = hasActivation && hasBillingVisible;
  const roleLabel = labelForRole(translatePermissionsToRole(userPermissions));

  let errorMessage = '';
  const permissionsMessage = `As ${roleLabel.article} ${roleLabel.label}, you don't have permission to activate new devices.`;
  const activationEligibleStates = ['all', 'active', 'preflight'];

  if (!activationEligibleStates.includes(deviceType))
    return { canActivate: false, hide: true, errorMessage };

  if (!canActivate) {
    errorMessage = permissionsMessage;
  } else if (!hasBillingPermissions) {
    if (!isCardSet && !hasPostpay && availableBalance <= 0) {
      canActivate = false;
      errorMessage = `Your organization does not have a credit card on file and you do not have permission to update billing information. Contact your administrator to activate SIMs.`;
    } else if (!isBillingAddressSet) {
      canActivate = false;
      errorMessage = `Your organization does not have a billing address on file and you do not have permission to update billing information. Contact your administrator to activate SIMs.`;
    }
  }

  return { canActivate, hide: false, errorMessage };
};

export default useCanActivate;
