import { Button, Panel } from '@holokit/core';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { analyticsEventBuilder, sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { buildDeviceBreakdownHref, buildReportHref } from 'common-js/api/usage';
import useGetDataUsed from 'common-js/api/usage/useGetDataUsed';
import useGetDeviceBreakdown from 'common-js/api/usage/useGetDeviceBreakdown';
import { getUserContextDataMemoized } from 'common-js/api/util';
import { DateTagFilter, HoloHelmet } from 'common-js/components';
import * as Paths from 'common-js/constants/paths';
import { setCustomDate, setTimeQuickFilter } from 'common-js/reducers/currentDevice/actions';
import {
  selectCurrentDevice,
  selectCurrentDeviceChartFilters,
  selectCurrentDeviceTimeFilter,
  selectCurrentDeviceTimeQuickFilter,
} from 'common-js/reducers/currentDevice/selectors';
import { drawerTakerOverLink } from 'common-js/reducers/drawer/actions';
import { getReportDateTitleString } from 'common-js/reducers/usage/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { DataUsedChart } from 'usage/containers/parts';
import { DeviceUsagePerNetwork } from 'common-js/types/Device';
import DeviceDataBreakdownTable from './DeviceDataBreakdownTable';
import exportData from './exportData';

const Usage = ({ params: { deviceId } }) => {
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [runningReport, setRunningReport] = useState(false);
  const [reportDateTitle, setReportDateTitle] = useState('');

  const [dataUsed, setDataUsed] = useState([]);
  const [dataUsedLoading, setDataUsedLoading] = useState(false);
  const [dataUsedError, setDataUsedError] = useState(null);

  const [deviceBreakdownData, setDeviceBreakdownData] = useState<Array<DeviceUsagePerNetwork>>([]);
  const [deviceBreakdownLoading, setDeviceBreakdownLoading] = useState(false);

  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const timeFilter = useAppSelector(selectCurrentDeviceTimeFilter);
  const timeQuickFilter = useAppSelector(selectCurrentDeviceTimeQuickFilter);
  const reportFilters = useAppSelector((state) => selectCurrentDeviceChartFilters(state, deviceId));
  const userContextData = useAppSelector(getUserContextDataMemoized);

  const getDataUsed = useGetDataUsed();
  const getDeviceBreakdown = useGetDeviceBreakdown();

  const startDate = new Date(timeFilter.startDate);
  const endDate = new Date(timeFilter.endDate);

  const runReport = useCallback(
    ({ initialLoad = false } = {}) => {
      setRunningReport(true);
      setDataUsedLoading(true);
      setDeviceBreakdownLoading(true);
      setReportDateTitle(
        getReportDateTitleString(timeFilter.startDate, timeFilter.endDate, timeQuickFilter)
      );

      Promise.all([getDataUsed(reportFilters), getDeviceBreakdown(reportFilters)])
        .then(([dataUsedResponse, deviceBreakdownResponse]) => {
          setDataUsed(dataUsedResponse);
          setDataUsedLoading(false);
          setDataUsedError(null);

          setDeviceBreakdownData(deviceBreakdownResponse);
          setDeviceBreakdownLoading(false);
        })
        .catch((error) => {
          if (error) {
            setDataUsed([]);
            setDataUsedLoading(false);
            setDataUsedError(error.toString());
          }
        })
        .finally(() => {
          setRunningReport(false);

          if (initialLoad) {
            setInitiallyLoaded(true);
          }
        });
    },
    [
      getDataUsed,
      getDeviceBreakdown,
      reportFilters,
      timeFilter.endDate,
      timeFilter.startDate,
      timeQuickFilter,
    ]
  );

  const exportDataUsed = () => {
    exportData({
      apiCall: getDataUsed,
      exporting,
      setExporting,
      reportFilters,
    });
  };

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Single Device', 'Usage').send();
  }, []);

  useEffect(() => {
    if (!initiallyLoaded) {
      setInitiallyLoaded(true);
      runReport({ initialLoad: true });
    }
  }, [initiallyLoaded, runReport]);

  const runDateTagReport = () => {
    sendAnalyticsEvent({
      type: analyticsTypes.REPORT_SINGLE_DEVICE_USAGE,
      data: {
        start_date: startDate,
        end_date: endDate,
      },
    });

    runReport();
  };

  const onDataUsedInspectClick = (e, inspectLink) => {
    e.preventDefault();

    dispatch(drawerTakerOverLink(inspectLink));
  };

  const inspectLink = Paths.withContext(Paths.DEVICE_INSPECT_DATAUSED).replace(
    `:${Paths.DEVICE_PARAM_NAME}`,
    deviceId
  );

  const setCustomDateHandler = ({ startDate: startCustomDate, endDate: endCustomDate }) =>
    dispatch(setCustomDate(startCustomDate, endCustomDate));

  const setTimeQuickFilterHandler = (filter) => dispatch(setTimeQuickFilter(filter));

  return (
    <div>
      <HoloHelmet title={`${device?.name} - Usage`} />

      <Panel title={reportDateTitle} largeTitle headerBorder={false} noBodyPadding well>
        <DateTagFilter
          hideTags
          startDate={startDate}
          endDate={endDate}
          deviceId={deviceId}
          setCustomDate={setCustomDateHandler}
          onRunReport={runDateTagReport}
          runningReport={runningReport}
          setTimeQuickFilter={setTimeQuickFilterHandler}
          timeQuickFilter={timeQuickFilter}
        />
      </Panel>

      <Panel
        title="Data used"
        headerButtons={[
          <Button
            onClick={exportDataUsed}
            type="secondary"
            key="button-export-csv"
            loading={exporting}
          >
            Export CSV
          </Button>,
          <Button
            href={buildReportHref('/usage', userContextData, reportFilters)}
            external
            type="secondary"
            key="button-view-json"
          >
            View JSON
          </Button>,
          <Button
            classes="inspect"
            href={inspectLink}
            buttonProps={{
              onClick: (e) => onDataUsedInspectClick(e, inspectLink),
            }}
            iconLeft="Inspect"
            type="secondary"
            key="button-inspect"
          >
            Inspect
          </Button>,
        ]}
        isLoading={dataUsedLoading}
      >
        <DataUsedChart data={dataUsed} loading={dataUsedLoading} error={dataUsedError} />
      </Panel>

      <Panel
        title="Data breakdown"
        noBodyPadding
        headerButtons={
          <Button
            external
            href={buildDeviceBreakdownHref(userContextData, reportFilters)}
            type="secondary"
          >
            View JSON
          </Button>
        }
        isLoading={deviceBreakdownLoading}
      >
        <DeviceDataBreakdownTable data={deviceBreakdownData} loading={deviceBreakdownLoading} />
      </Panel>
    </div>
  );
};

export default Usage;
