import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { UpdateBillingAddress } from '@hologram-hyper-dashboard/components';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { AccountBalance, BillingEmailCC, HoloHelmet } from 'common-js/components';
import DefaultStripeElements from 'common-js/components/DefaultStripeElements';
import PaymentDetailsPanel from 'common-js/components/payment/PaymentDetailsPanel';
import { useAppDispatch, useAppSelector } from 'common-js/hooks';
import useUpdateBillingAddress from 'common-js/hooks/useUpdateBillingAddress';
import { BalanceModel } from 'common-js/models';
import { getPlans } from 'common-js/reducers/account/actions';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import { getAllBilling, getDeviceCount } from 'common-js/reducers/organization/actions';
import {
  getBalance,
  getUserPermissions,
  selectBillingAddress,
  selectBillingInformation,
} from 'common-js/reducers/organization/selectors';
import { selectHasCustomBilling } from 'common-js/reducers/releaseFlag/selectors';
import { BILLING, BILLING_VISIBLE } from 'common-js/utils/permissions';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AutoRefillSection from '../parts/AutoRefillSection';

const Account = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const balanceModel = useAppSelector(getBalance) || new BalanceModel();

  const userPermissions = useAppSelector(getUserPermissions);
  const hasBillingPermissions = userPermissions.includes(BILLING);
  const hasBillingVisible = userPermissions.includes(BILLING_VISIBLE);

  const customBilling = useAppSelector(selectHasCustomBilling);
  const billingAddress = useAppSelector(selectBillingAddress);

  // TODO: Whole form can go probably go now since most of what's left applies to Activation
  const methods = useForm({
    defaultValues: {
      creditCardInfo: {
        paymentType: 'card',
        hasError: false,
        invalidPaymentMethod: false,
      },
    },
  });

  useEffect(() => {
    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Billing - Account' },
    });

    try {
      Promise.all([dispatch(getAllBilling()), dispatch(getDeviceCount()), dispatch(getPlans())])
        .catch((e) => {
          dispatch(pushGlobalMessage(e, 'error'));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      dispatch(pushGlobalMessage(e as any, 'error'));
    }
  }, [dispatch]);

  const hasBillingAddress = !!(billingAddress && billingAddress.line1);

  const updateBillingAddress = useUpdateBillingAddress();
  const billingInformation = useAppSelector(selectBillingInformation);
  const isBillingAddressSet = billingInformation?.isBillingAddressSet ?? false;

  return (
    <DefaultStripeElements>
      <div className="page-account-billing">
        <HoloHelmet title="Billing Account" />
        {customBilling || balanceModel.hasPostpay ? null : (
          <AccountBalance balanceModel={balanceModel} />
        )}
        <FormProvider {...methods}>
          <PaymentDetailsPanel loading={loading} />
        </FormProvider>
        {hasBillingVisible && (
          <UpdateBillingAddress
            updateBillingAddress={updateBillingAddress}
            existingAddress={hasBillingAddress ? billingAddress : undefined}
            readOnly={!hasBillingPermissions}
            hasAddress={isBillingAddressSet}
          />
        )}
        {hasBillingPermissions && (
          <>
            {hasBillingAddress && (
              <Panel
                header="Custom invoice details"
                footerActions={
                  billingAddress.invoice_text ? (
                    <Button
                      className="update-btn"
                      onClick={() =>
                        dispatch(openModal('AddCustomInvoiceInfoModal', {}, 'narrow no-padding'))
                      }
                      variant="secondary"
                    >
                      Edit custom invoice details
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        dispatch(openModal('AddCustomInvoiceInfoModal', {}, 'narrow no-padding'))
                      }
                      variant="secondary"
                    >
                      Add custom invoice details
                    </Button>
                  )
                }
              >
                {billingAddress.invoice_text ? (
                  <div className="has-line-breaks">{billingAddress.invoice_text}</div>
                ) : (
                  <div className="PaymentMethod__emptyState PaymentMethod__emptyState-diminished">
                    Add custom details to invoices, including tax and VAT IDs
                  </div>
                )}
              </Panel>
            )}
            {!customBilling && !balanceModel.hasPostpay ? <AutoRefillSection /> : null}
            <BillingEmailCC />
          </>
        )}
      </div>
    </DefaultStripeElements>
  );
};

export default Account;
