import React from 'react';
import { Submit } from '../../components/form';

function Step8() {
  return (
    <div className="Step NewForm NewForm__ConfirmationPage">
      <div className="NewForm__ConfirmationPageWrapper">
        <div className="NewForm__Row">
          <div className="NewForm__DoubleColumn">
            <div className="NewForm__Header">
              <h1>Account setup complete!</h1>
            </div>
          </div>
        </div>
        <div className="NewForm__RowLeftAligned">
          <Submit classNames="NewButton-Primary" label="Go to your dashboard" />
        </div>
      </div>
    </div>
  );
}

export default Step8;
