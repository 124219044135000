import { Icon } from '@hologram-dimension/icon';
import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import TopMenu from 'common-js/components/menu/TopMenu';
import { activeStates, deactivatedStates, preflightStates } from 'common-js/constants/deviceStates';
import {
  DEVICES_ACTIVITY,
  DEVICES_ACTIVITY_HISTORY_DETAILS,
  DEVICES_DEACTIVATED,
  DEVICES_DEVICES,
  DEVICES_PLANS,
  DEVICES_PREFLIGHT,
  DEVICES_TAG_MANAGER,
  pathMatches,
  pathMatchesWithIdsReplaced,
  withContext,
} from 'common-js/constants/paths';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { isDeviceInState } from 'common-js/reducers/devices/selectors';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import {
  selectHasActivityHistory,
  selectHasFleetStatus,
} from 'common-js/reducers/releaseFlag/selectors';
import { getSingleDevice } from 'common-js/reducers/singleDevice/selectors';
import { BILLING_VISIBLE } from 'common-js/utils/permissions';
import { getDeviceActionString } from 'devices/util';
import { Component } from 'react';
import { connect } from 'react-redux';

class SubMenu extends Component<any, any> {
  getTopMenuBackBreadcrumb = () => {
    const { highlightActive, highlightPreflight, highlightDeactivated } = this.props;

    if (highlightActive) {
      return { text: 'All Active', link: DEVICES_DEVICES };
    }
    if (highlightPreflight) {
      return { text: 'All Preflight', link: DEVICES_PREFLIGHT };
    }
    if (highlightDeactivated) {
      return { text: 'All Deactivated', link: DEVICES_DEACTIVATED };
    }
    return { text: 'All Active', link: DEVICES_DEVICES };
  };

  render() {
    const {
      hasActiveDevices,
      hasActivityHistory,
      hasDeactivatedDevices,
      hasFleetStatus,
      hasPreflightDevices,
      singleDevice,
      singleDeviceContext,
      taskDetails,
      userPermissions,
    } = this.props;

    const hasDevices = hasActiveDevices || hasPreflightDevices || hasDeactivatedDevices;

    // If the user does not have fleet status (Home), then the devices table is their home page. If at that point they have no devices, they see the pseudo-Home screen, so we want to turn off breadcrumbs to match.
    if (pathMatches(DEVICES_DEVICES) && !hasDevices && !hasFleetStatus) {
      return <TopMenu rightContent={<SubMenuRightContent />} />;
    }

    const topMenuBackBreadcrumb = this.getTopMenuBackBreadcrumb();
    if (singleDeviceContext) {
      return (
        <TopMenu rightContent={<SubMenuRightContent />}>
          <TopMenu.Item
            text={topMenuBackBreadcrumb.text}
            href={withContext(topMenuBackBreadcrumb.link)}
            className="grid-row v-align grid-gap-halfx"
          >
            <Icon
              name="ChevronSingleWest"
              size="small"
              fill="DdsColorPaletteWhite"
              style={{ transform: 'translateY(-0.5px)' }}
            />
          </TopMenu.Item>

          <TopMenu.Item isDeviceName active text={singleDevice?.name} />
        </TopMenu>
      );
    }

    // Activity history details, but only show this if the user has access to activity history
    if (pathMatchesWithIdsReplaced(DEVICES_ACTIVITY_HISTORY_DETAILS) && hasActivityHistory) {
      const numberDevices = taskDetails.deviceCount ?? 0;
      const deviceActionString =
        numberDevices > 0 ? getDeviceActionString(numberDevices, taskDetails.action) : 'Loading...';

      return (
        <TopMenu rightContent={<SubMenuRightContent />}>
          <TopMenu.Item
            text="Activity history"
            href={withContext(DEVICES_ACTIVITY)}
            className="grid-row v-align grid-gap-halfx"
          >
            <Icon
              name="ChevronSingleWest"
              size="small"
              fill="DdsColorPaletteWhite"
              style={{ transform: 'translateY(-0.5px)' }}
            />
          </TopMenu.Item>

          <TopMenu.Item active isDeviceName text={deviceActionString} />
        </TopMenu>
      );
    }

    const hasBillingVisible = userPermissions.includes(BILLING_VISIBLE);

    return (
      <TopMenu titleHref={withContext(DEVICES_DEVICES)} rightContent={<SubMenuRightContent />}>
        <TopMenu.Item
          text="Devices"
          active={
            pathMatches(DEVICES_DEVICES) ||
            pathMatches(DEVICES_PREFLIGHT) ||
            pathMatches(DEVICES_DEACTIVATED)
          }
          href={withContext(DEVICES_DEVICES)}
        />

        {hasBillingVisible && (
          <TopMenu.Item
            text="Data plans"
            active={pathMatches(DEVICES_PLANS)}
            href={withContext(DEVICES_PLANS)}
          />
        )}

        <TopMenu.Item
          text="Tags"
          active={pathMatches(DEVICES_TAG_MANAGER)}
          href={withContext(DEVICES_TAG_MANAGER)}
        />

        <TopMenu.Item
          text="Activity history"
          // If the user doesn't have access to activity history details, this item is considered active
          active={
            pathMatches(DEVICES_ACTIVITY) ||
            pathMatchesWithIdsReplaced(DEVICES_ACTIVITY_HISTORY_DETAILS)
          }
          href={withContext(DEVICES_ACTIVITY)}
        />
      </TopMenu>
    );
  }
}

const SubMenuHoC = connect((state: any, props: any) => {
  const { deviceId } = props;
  // prefer the currentDevice slice, but fall back to old singleDevice for compatibility
  const singleDevice = selectCurrentDevice(state) ?? getSingleDevice(state, props.deviceId).device;
  let highlightActive = false;
  let highlightPreflight = false;
  let highlightDeactivated = false;

  if (deviceId) {
    highlightActive = isDeviceInState(state, {
      deviceId,
      deviceStates: activeStates,
    });
    highlightPreflight = isDeviceInState(state, {
      deviceId,
      deviceStates: preflightStates,
    });
    highlightDeactivated = isDeviceInState(state, {
      deviceId,
      deviceStates: deactivatedStates,
    });
  }

  return {
    highlightActive,
    highlightPreflight,
    highlightDeactivated,
    singleDevice,
    singleDeviceContext: !!props.deviceId,
    hasActiveDevices: state.organization.deviceCountActive > 0,
    hasActivityHistory: selectHasActivityHistory(state),
    hasPreflightDevices: state.organization.deviceCountPreflight > 0,
    hasDeactivatedDevices: state.organization.deviceCountDeactivated > 0,
    hasFleetStatus: selectHasFleetStatus(state),
    taskDetails: state.devices?.tasks?.details ?? {},
    userPermissions: getUserPermissions(state),
  };
})(SubMenu);

export default SubMenuHoC;
