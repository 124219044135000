import Tags from './Tags';
import TagsSidebarItem from './TagsSidebarItem';
import TagsFooter from './TagsFooter';

const tagsFixture = ({ isOnboarding, isPreflight, changingPlan }) => ({
  stepName: 'Tags',
  component: Tags,
  sidebarItem: TagsSidebarItem,
  footer: TagsFooter,
  title: 'Assign tags to devices',
  include: !isOnboarding && !isPreflight && !changingPlan,
  optional: true,
  formState: 'tags',
  defaultValues: {
    tags: [],
  },
});

export default tagsFixture;
