import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _classNames from 'clsx';
import { Icon } from '@holokit/core';
import { Link } from 'react-router';
import * as Paths from 'common-js/constants/paths';
import { setSeenFleetHome } from 'common-js/reducers/account/actions';
import { getAlertCountByType } from 'common-js/reducers/alerts/actions';
import * as alertTypes from 'common-js/constants/alertTypes';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import PropTypes from 'prop-types';

class FleetHomeEyebrow extends Component {
  constructor(props) {
    super(props);
    this.today = new Date()
      .toLocaleString([], {
        month: 'long',
        day: 'numeric',
        timeZoneName: 'short',
        hour: 'numeric',
        minute: 'numeric',
      })
      .replace(',', ' at');
    this.state = {
      alertCount: null,
    };
  }

  componentDidMount() {
    const { hasSeenFleetHome, setSeenFleetHome_, showInflight, getAlertCountByType_ } = this.props;

    if (showInflight) {
      Promise.all([
        getAlertCountByType_(alertTypes.DEVICE_DATA_LIMIT),
        getAlertCountByType_(alertTypes.DEVICE_USAGE),
        getAlertCountByType_(alertTypes.TAC_CHANGE),
      ]).then((res) => {
        this.setState({ alertCount: res[0] + res[1] + res[2] });
      });
    }

    if (!hasSeenFleetHome) {
      setTimeout(() => {
        setSeenFleetHome_(true);
      }, 1000);
    }
  }

  trackReviewActionItems = () => {
    const { alertCount } = this.state;
    sendAnalyticsEvent({
      type: analyticsTypes.FLEET_HOME_REVIEW_ACTION_ITEMS,
      data: {
        num_action_items: alertCount,
      },
    });
  };

  render() {
    const { firstName, hasSeenFleetHome, showInflight } = this.props;
    const { alertCount } = this.state;
    const welcomeMessage = `Here's your snapshot for ${this.today}`;

    return (
      <div className="FleetHomeEyebrow">
        <span
          className={_classNames({
            FleetHomeEyebrow__header: !hasSeenFleetHome,
            'FleetHomeEyebrow__header--secondary': !hasSeenFleetHome,
            FleetHomeEyebrow__subheader: hasSeenFleetHome,
          })}
        >
          {showInflight ? welcomeMessage : `Welcome back, ${firstName}`}
        </span>
        <div className="FleetHomeEyebrow__header__container">
          {showInflight ? (
            <div
              className={_classNames('FleetHomeEyebrow__header FleetHomeEyebrow__header--alerts', {
                'FleetHomeEyebrow__header--hidden': !hasSeenFleetHome,
              })}
            >
              <div className="FleetHomeEyebrow__header__alert-icon">
                <Icon
                  classes="FleetHomeEyebrow__header__alert-icon"
                  name="ActionItem"
                  size="major"
                  svgProps={{ style: { fill: '#A80000' } }}
                />
              </div>
              <h2 className="FleetHomeEyebrow__header__alert-headline">
                You have
                <Link
                  to={Paths.withContext(Paths.ACTIONS_ALERTS_DEVICE_TACCHANGE)}
                  className="FleetHomeEyebrow__header__alert-headline__highlight"
                  onClick={this.trackReviewActionItems}
                >
                  {alertCount} total Action items{' '}
                </Link>
                to review
              </h2>
              <Link
                className="FleetHomeEyebrow__header__alert-cta Button Button--primary Button--small Button--has-icon Button--has-icon-right"
                to={Paths.withContext(Paths.ACTIONS_ALERTS_DEVICE_TACCHANGE)}
                onClick={this.trackReviewActionItems}
              >
                <div className="Button__content">
                  <span className="Button__text">Review Events</span>
                  <div className="Button__icon Button__icon--right">
                    <Icon
                      name="Arrow--east"
                      size="minor"
                      svgProps={{ style: { fill: '#ffffff' } }}
                    />
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <span
              className={_classNames('FleetHomeEyebrow__header', {
                'FleetHomeEyebrow__header--hidden': !hasSeenFleetHome,
              })}
            >
              {welcomeMessage}
            </span>
          )}
        </div>
      </div>
    );
  }
}

FleetHomeEyebrow.propTypes = {
  hasSeenFleetHome: PropTypes.bool,
  firstName: PropTypes.string.isRequired,
  showInflight: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSeenFleetHome_: setSeenFleetHome,
      getAlertCountByType_: getAlertCountByType,
    },
    dispatch
  );

export default connect(() => ({}), mapDispatchToProps)(FleetHomeEyebrow);
