import React from 'react';
import ClassNames from 'clsx';

class AlertDrawerExpander extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ expanded: true }), 10);
  }

  render() {
    const { children } = this.props;
    const { expanded } = this.state;

    return (
      <div
        className={ClassNames('AlertsTable__drawer', {
          'AlertsTable__drawer--active': expanded,
        })}
      >
        {children}
      </div>
    );
  }
}

export default AlertDrawerExpander;
