import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import _classnames from 'clsx';
import PropTypes from 'prop-types';
import { Button, Checkbox, ComplexIcon, Icon } from '@holokit/core';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as DeviceFilterFields from 'common-js/constants/deviceFilterFields';
import {
  checkForSimReadyState,
  clearFilters,
  ensureDevicesPersisted,
  toggleSelectionPage,
  toggleSelection,
} from 'common-js/reducers/devices/actions';
import {
  getFilters,
  getCurrentPage,
  getLoadedDevices,
  getQuery,
  getSelectedDeviceState,
} from 'common-js/reducers/devices/selectors';
import PredeploymentUpsellContent from 'devices/components/PredeploymentUpsellContent';
import {
  CellDeviceImei,
  CellDeviceLastActive,
  CellDeviceName,
  CellDevicePlan,
  CellDevicePreflightConfig,
  CellDeviceState,
  CellDeviceUsage,
  DeviceListActivationNotification,
  DeviceSkeletonTable,
} from './index';

class DeviceTableContent extends PureComponent {
  constructor(props) {
    super(props);

    this.scroller = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;

    if (isLoading && !prevProps.isLoading && this.scroller.current) {
      this.scroller.current.scrollTop = 0;
    }
  }

  onSelectAll = () => {
    const { devices, ensureDevicesPersisted_, toggleSelectionPage_, selected, currentPage } =
      this.props;
    if (!selected.pagesSelected.includes(currentPage)) {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Select Page',
        },
      });
    } else {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Deselect Page',
        },
      });
    }
    toggleSelectionPage_();
    ensureDevicesPersisted_(devices.map((device) => device.id));
  };

  handleSelectRow = (deviceId) => {
    const { toggleSelection_, ensureDevicesPersisted_ } = this.props;

    toggleSelection_(deviceId);
    ensureDevicesPersisted_([deviceId]);
  };

  renderSortIcon = (column) => {
    const { sortColumn, sortDirection } = this.props;

    let iconName = 'Arrow--triangle--northsouth';
    let iconSize = 'minor';
    let iconClasses = '';
    if (sortColumn === column) {
      iconName = sortDirection === 'ASC' ? 'Arrow--triangle--north' : 'Arrow--triangle--south';
      iconSize = 'micro';
      iconClasses = 'Table__cell-content__icon--sorted';
    }

    return (
      <Icon
        classes={_classnames('Table__cell-content__icon', iconClasses)}
        name={iconName}
        size={iconSize}
      />
    );
  };

  render() {
    const {
      checkForSimReadyState_,
      clearFilters_,
      currentPage,
      devices,
      deviceCount,
      filters,
      isLoading,
      isPreflight,
      notificationBanner,
      releaseFlags,
      selected,
      showPredeploymentUpsellContent,
      stateSegment,
      query,
      updateSort,
    } = this.props;

    const hasPageSelected = selected.pagesSelected.includes(parseInt(currentPage, 10));
    const devicesExcludedOnPage = devices.filter((device) => selected.excludedIds[device.id]);
    const pageChecked =
      (hasPageSelected || selected.allSelected) && devicesExcludedOnPage.length === 0;

    const activeSelection =
      selected.pagesSelected.length > 0 || Object.keys(selected.byId).length > 0;
    const indeterminate = (!hasPageSelected && activeSelection) || devicesExcludedOnPage.length > 0;

    if (showPredeploymentUpsellContent) {
      return <PredeploymentUpsellContent deviceCount={deviceCount} />;
    }

    return (
      <div className="DevicesTable__scroll-wrap" ref={this.scroller}>
        {!isLoading && !devices.length && !notificationBanner.showNotificationBanner ? (
          <div className="DevicesTable__no-results">
            {!!query && (
              <>
                <ComplexIcon name="empty-search" />
                <h2 className="DevicesTable__no-results__header">
                  No devices matching &quot;{query}&quot;
                </h2>
                <p className="DevicesTable__no-results__text">
                  Try searching by the{' '}
                  <strong className="DevicesTable__no-results__text--strong">
                    last 4 or 5 digits of an IMEI
                  </strong>
                  , by{' '}
                  <strong className="DevicesTable__no-results__text--strong">device name</strong>,
                  or <strong className="DevicesTable__no-results__text--strong">ICCID</strong>
                  .<br />
                  It may take up to 10 minutes for recently activated devices to be searchable.
                </p>
                <a
                  className="DevicesTable__no-results__link"
                  href="https://support.hologram.io/hc/en-us/articles/1500001779421"
                  target="_blank"
                  rel="noreferrer"
                >
                  What can I search for?
                </a>
              </>
            )}
            {!_.isEmpty(filters) && (
              <>
                <ComplexIcon name="empty-search" />
                <h2 className="DevicesTable__no-results__header">
                  No devices matching your selected filters
                </h2>
                <Button
                  classes="DevicesTable__no-results__link"
                  type="minimal"
                  onClick={() => clearFilters_(stateSegment)}
                >
                  Clear all filters
                </Button>
              </>
            )}
          </div>
        ) : (
          <table className="Table Table--fixed">
            <thead className="Table__header Table__header--sticky">
              <tr>
                <th className="Table__cell--header DevicesTable__column DevicesTable__column--checkbox DevicesTable__column--select-all">
                  <div className="Table__cell-content Table__cell-content--centered">
                    <Checkbox
                      checked={pageChecked}
                      disabled={isLoading || selected.allSelected}
                      indeterminate={indeterminate ? 1 : 0}
                      onChange={this.onSelectAll}
                    />
                  </div>
                </th>
                <th
                  className="Table__cell--header DevicesTable__column DevicesTable__column--name"
                  onClick={() => updateSort('name')}
                >
                  <div className="Table__cell-content Table__cell-content--sort">
                    Device name
                    {this.renderSortIcon('name')}
                  </div>
                </th>
                <th
                  className="Table__cell--header DevicesTable__column DevicesTable__column--state"
                  onClick={() => updateSort('link_state_connected')}
                >
                  <div className="Table__cell-content Table__cell-content--sort">
                    Status
                    {this.renderSortIcon('link_state_connected')}
                  </div>
                </th>
                {isPreflight && (
                  <th className="Table__cell--header DevicesTable__column DevicesTable__column--preflight">
                    <div className="Table__cell-content">Configuration</div>
                  </th>
                )}
                <th
                  className="Table__cell--header DevicesTable__column DevicesTable__column--last-active"
                  onClick={() => updateSort(DeviceFilterFields.LAST_ACTIVE)}
                >
                  <div className="Table__cell-content Table__cell-content--sort">
                    Last active
                    {this.renderSortIcon(DeviceFilterFields.LAST_ACTIVE)}
                  </div>
                </th>
                <th
                  className="Table__cell--header DevicesTable__column DevicesTable__column--usage"
                  onClick={() => updateSort(DeviceFilterFields.USAGE)}
                >
                  <div className="Table__cell-content Table__cell-content--sort">
                    Data usage
                    {this.renderSortIcon(DeviceFilterFields.USAGE)}
                  </div>
                </th>
                <th
                  className="Table__cell--header DevicesTable__column DevicesTable__column--plan"
                  onClick={() => updateSort(DeviceFilterFields.PLANS)}
                >
                  <div className="Table__cell-content Table__cell-content--sort">
                    Data plan
                    {this.renderSortIcon(DeviceFilterFields.PLANS)}
                  </div>
                </th>
                {!isPreflight && (
                  <th
                    className="Table__cell--header DevicesTable__column DevicesTable__column--imei"
                    onClick={() => updateSort(DeviceFilterFields.IMEI)}
                  >
                    <div className="Table__cell-content Table__cell-content--sort">
                      IMEI
                      {this.renderSortIcon(DeviceFilterFields.IMEI)}
                    </div>
                  </th>
                )}
              </tr>
              {notificationBanner.showNotificationBanner && (
                <tr className="DevicesTable__row DevicesTable__row--notification">
                  <td colSpan="7" className="Table__cell DevicesTable__column">
                    <DeviceListActivationNotification
                      notificationBannerParams={notificationBanner}
                      checkIfPendingSimsActive={checkForSimReadyState_}
                    />
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              {isLoading ? (
                <DeviceSkeletonTable rowCount={deviceCount > 20 ? 20 : deviceCount} />
              ) : (
                devices.map((device) => {
                  const { id, imei, name } = device;
                  const simNumber = device.imsi ?? '';
                  const state = device.link_state_connected ?? '';
                  const lastConnectedTime = device.link_last_connect_time ?? '';
                  const dataUsed = device.link_cur_billing_data_used;
                  const plan = device.plan_name ?? '';

                  const deviceChecked =
                    !selected.excludedIds[id] &&
                    (selected.byId[id] || hasPageSelected || selected.allSelected);

                  return (
                    <tr className="Table__row" key={id}>
                      <td className="Table__cell Table__cell--no-border-right DevicesTable__column DevicesTable__column--checkbox">
                        <div className="Table__cell-content Table__cell-content--centered">
                          <Checkbox
                            checked={deviceChecked}
                            onChange={() => this.handleSelectRow(id)}
                          />
                        </div>
                      </td>
                      <td className="Table__cell DevicesTable__column DevicesTable__column--name">
                        <div className="Table__cell-content">
                          <CellDeviceName
                            deviceId={id}
                            deviceName={name}
                            deviceSimNumber={simNumber}
                            isActivating={false}
                          />
                        </div>
                      </td>
                      <td className="Table__cell DevicesTable__column DevicesTable__column--state">
                        <div className="Table__cell-content">
                          <CellDeviceState
                            connected={state === 'CONNECTED'}
                            deviceState={state}
                            releaseFlags={releaseFlags}
                          />
                        </div>
                      </td>
                      {isPreflight && (
                        <td className="Table__cell DevicesTable__column DevicesTable__column--preflight">
                          <div className="Table__cell-content">
                            <CellDevicePreflightConfig deviceState={state} />
                          </div>
                        </td>
                      )}
                      <td className="Table__cell DevicesTable__column DevicesTable__column--last-active">
                        <div className="Table__cell-content">
                          <CellDeviceLastActive lastConnectedTime={lastConnectedTime} />
                        </div>
                      </td>
                      <td className="Table__cell DevicesTable__column DevicesTable__column--usage">
                        <div className="Table__cell-content">
                          <CellDeviceUsage dataUsed={dataUsed} />
                        </div>
                      </td>
                      <td className="Table__cell DevicesTable__column DevicesTable__column--plan">
                        <div className="Table__cell-content">
                          <CellDevicePlan plan={plan} />
                        </div>
                      </td>
                      {!isPreflight && (
                        <td className="Table__cell DevicesTable__column DevicesTable__column--imei">
                          <div className="Table__cell-content">
                            <CellDeviceImei imei={imei} />
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

DeviceTableContent.propTypes = {
  isPreflight: PropTypes.bool,
  releaseFlags: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  devices: getLoadedDevices(state),
  filters: getFilters(state),
  currentPage: getCurrentPage(state),
  notificationBanner: state.devices.uiState.activateNotification,
  query: getQuery(state),
  releaseFlags: state.releaseFlag,
  selected: getSelectedDeviceState(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkForSimReadyState_: checkForSimReadyState,
      clearFilters_: clearFilters,
      ensureDevicesPersisted_: ensureDevicesPersisted,
      toggleSelectionPage_: toggleSelectionPage,
      toggleSelection_: toggleSelection,
    },
    dispatch
  );

DeviceTableContent.defaultProps = {
  isPreflight: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTableContent);
