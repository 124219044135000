import { FullHeightCalculatorHOC, HoloHelmet } from 'common-js/components';
import { TagManager } from 'devices/components';
import SubMenu from 'devices/components/SubMenu';
import { FC } from 'react';
import SimSubMenu from '../../sims/components/SubMenu';

interface TagManagerPageProps {
  simInventory?: boolean;
}

const TagManagerPage: FC<TagManagerPageProps> = ({ simInventory = false }) => (
  <>
    <HoloHelmet title="Tag Manager" />
    {simInventory ? <SimSubMenu /> : <SubMenu />}
    <FullHeightCalculatorHOC accountForDrawer backgroundColor="#F2F3F5">
      <TagManager className="TagManagerPage" />
    </FullHeightCalculatorHOC>
  </>
);

export default TagManagerPage;
