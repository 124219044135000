import Moment from 'moment-timezone';
import React from 'react';

const DATE_FORMAT = 'MMM D YYYY, HH:mm:ss';
const PARSE1 = 'MMM D YYYY, hh:mm:ss';

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: '',
      isValid: true,
      error: null,
    };
  }

  componentDidMount() {
    if (this.props.time) {
      this.setState({
        time: Moment(this.props.time).format(DATE_FORMAT),
        isValid: true,
        error: null,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.time.valueOf() !== Moment(this.state.time, DATE_FORMAT).valueOf() &&
      this.state.isValid
    ) {
      this.setState({
        time: Moment(nextProps.time).format(DATE_FORMAT),
        isValid: true,
        error: null,
      });
    }
  }

  render() {
    const { time, isValid, error } = this.state;
    const { classNames = null } = this.props;

    return (
      <div
        className={`DateTimePicker${!isValid ? ' invalid' : ''}${
          classNames ? ` ${classNames}` : ''
        }`}
      >
        <input
          value={time}
          onChange={(e) => this.onDateChange(e.currentTarget.value)}
          type="text"
        />
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  }

  onDateChange(newValue) {
    const { validWhen = () => true } = this.props;

    const newTime = Moment(newValue, DATE_FORMAT);

    const externalValidation = validWhen(newTime.valueOf());

    if (newTime.isValid() && externalValidation.isValid) {
      this.setState({ time: newValue, isValid: true, error: null });
      setTimeout(() => this.props.onChange(newTime.valueOf(), 10));
    } else {
      this.setState({
        time: newValue,
        isValid: false,
        error: !newTime.isValid() ? 'Invalid date format.' : externalValidation.error,
      });
    }
  }
}

export default DateTimePicker;
