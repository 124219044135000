import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as alertTypes from 'common-js/constants/alertTypes';
import { pausedStates } from 'common-js/constants/deviceStates';
import { updateUserSettings } from 'common-js/reducers/account/actions';
import { LOADMORE_PAGELENGTH } from 'common-js/reducers/alerts/reducer';
import * as alertActions from 'common-js/reducers/alerts/actions';
import {
  createGetActiveAlertIdByType,
  createGetAlertCountByType,
  createGetAlertDataByType,
  createGetAlertFilterDataByType,
  createGetAlertLoadingByType,
  createGetHasFetchedByType,
  createGetKeyedAlertsByType,
  createGetAckedAlertIds,
} from 'common-js/reducers/alerts/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { setChangePlanDevices, setIsChangePlanFlag } from 'common-js/reducers/activation/actions';
import { selectHasBizLayerPauseResumeFlag } from 'common-js/reducers/releaseFlag/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { setCurrentDevice } from 'common-js/reducers/currentDevice/actions';

const ALERT_TYPE_TO_EDUCATION_NAME = {
  [alertTypes.TAC_CHANGE]: 'edu_home_tacchange_show',
  [alertTypes.DEVICE_DATA_LIMIT]: 'edu_home_dlimit_show',
  [alertTypes.DEVICE_USAGE]: 'edu_home_usage_show',
};

const AlertsTableComponentHoc = (alertType) => (WrappedTable) => {
  class AlertsTableContainer extends React.Component {
    static showPauseToast = (count) => {
      if (count && count > 0) {
        const simString = count === 1 ? 'SIM' : 'SIMs';

        toast(
          <HeadlineToast
            body={`${count} ${simString} paused successfully`}
            headline={`${simString} Paused`}
            icon="Checkmark--single"
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            bodyClassName: 'ButtonToast__top-container',
            className: 'toastify-content--burnt toastify-content--success ButtonToast__container',
            closeButton: false,
            autoClose: 10000,
          }
        );
      } else {
        // Handle pause error
      }
    };

    static showResumeToast = (data, count) => {
      const simString = count === 1 ? 'SIM' : 'SIMs';

      toast(
        <HeadlineToast
          body={`${count} ${simString} resumed successfully`}
          headline={`${simString} Resumed`}
          icon="Checkmark--single"
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          bodyClassName: 'ButtonToast__top-container',
          className: 'toastify-content--burnt toastify-content--success ButtonToast__container',
          closeButton: false,
          autoClose: 10000,
        }
      );
    };

    constructor(props) {
      super(props);
      this.state = {
        bulkPause: true,
        dismissClearAndLoadMore: false,
        selectedAlerts: [],
        selectedDevices: [],
        theme: 'default',
        allUnloadedAlertsSelected: false,
      };
    }

    componentDidUpdate(prevProps, prevState) {
      const { selectedAlerts } = this.state;

      if (prevState.selectedAlerts.length !== selectedAlerts.length) {
        this.updateBulkPauseResume();
      }
    }

    onPlanChange = async (deviceId, page) => {
      const { onUpdatePlan, getDevice, setCurrentDevice_ } = this.props;

      const newDevice = await getDevice(deviceId);
      setCurrentDevice_(newDevice);
      onUpdatePlan(page);
    };

    // GENERAL EVENTS
    showEducationHeader = () => {
      const { userSettings } = this.props;

      const userSettingName = ALERT_TYPE_TO_EDUCATION_NAME[alertType];
      const userSetting = userSettings[userSettingName];
      return userSetting === 'yes';
    };

    handleHideEducationBanner = () => {
      const { updateUserSettings_ } = this.props;
      const userSettingName = ALERT_TYPE_TO_EDUCATION_NAME[alertType];
      updateUserSettings_({ [userSettingName]: 'no' });
    };

    handleChangeTheme = (newTheme) => {
      this.setState({
        selectedAlerts: [],
        selectedDevices: [],
        theme: newTheme,
      });
    };

    goToNextPage = () => {
      const { filterData, getAlertsByType } = this.props;
      const newOffset = filterData.offset + LOADMORE_PAGELENGTH;

      getAlertsByType(alertType, newOffset);
    };

    dismissClearBanner = () => {
      this.setState({ dismissClearAndLoadMore: true });
    };

    dismissClearBannerAndLoadMore = () => {
      const { clearAcknowledgedAlerts, getAlertsByType } = this.props;
      getAlertsByType(alertType);
      clearAcknowledgedAlerts(alertType);
    };

    selectAlerts = (alertIds) => {
      const { keyedAlerts } = this.props;
      const { selectedAlerts, selectedDevices } = this.state;
      const deviceIds = alertIds.map((alert) => keyedAlerts[alert].deviceid);
      this.setState({
        selectedAlerts: [...selectedAlerts, ...alertIds],
        selectedDevices: [...selectedDevices, ...deviceIds],
      });
    };

    deselectAlerts = (alertIds) => {
      const { keyedAlerts } = this.props;
      const { selectedAlerts, selectedDevices } = this.state;
      const deviceIds = alertIds.map((alert) => keyedAlerts[alert].deviceid);
      const newSelectedAlerts = selectedAlerts.filter((a) => !alertIds.includes(a));
      const newSelectedDevices = selectedDevices.filter((d) => !deviceIds.includes(d));
      this.setState({
        selectedAlerts: newSelectedAlerts,
        selectedDevices: newSelectedDevices,
        allUnloadedAlertsSelected: false,
      });
    };

    handleSelectAll = () => {
      const { alerts } = this.props;
      const { selectedDevices } = this.state;

      if (selectedDevices.length === alerts.length) {
        this.setState({
          selectedAlerts: [],
          selectedDevices: [],
        });
      } else {
        this.setState({
          selectedAlerts: alerts.map((a) => a.id),
          selectedDevices: alerts.map((a) => a.deviceid),
        });
      }
    };

    handleSingleSelection = (alertId) => {
      const { selectedAlerts } = this.state;

      if (selectedAlerts.includes(alertId)) {
        this.deselectAlerts([alertId]);
      } else {
        this.selectAlerts([alertId]);
      }
    };

    handleAcknowledgeAlerts = () => {
      const { acknowledgeAlert, acknowledgeAlerts, alerts } = this.props;
      const { selectedAlerts, theme, allUnloadedAlertsSelected } = this.state;

      const alertsToAcknowledge =
        theme === 'default' ? alerts.map((alert) => alert.id) : selectedAlerts;
      if (allUnloadedAlertsSelected) {
        this.handleAcknowledgeAllAlerts(alertType);
      } else if (alertsToAcknowledge.length === 1) {
        acknowledgeAlert(alertsToAcknowledge[0], alertType);
        this.deselectAlerts(alertsToAcknowledge);
      } else {
        acknowledgeAlerts(alertsToAcknowledge, alertType);
        this.deselectAlerts(alertsToAcknowledge);
      }
    };

    handleUnacknowledgeSingleAlert = (alertId) => {
      const { unacknowledgeAlert } = this.props;
      unacknowledgeAlert(alertId, alertType);
      this.deselectAlerts([alertId]);
    };

    handleAcknowledgeSingleAlert = (alertId) => {
      const { acknowledgeAlert } = this.props;
      acknowledgeAlert(alertId, alertType);
      this.deselectAlerts([alertId]);
    };

    handleAcknowledgeAllAlerts = (alertTypeToAcknowledge) => {
      const { acknowledgeAllAlerts } = this.props;
      this.setState({ dismissClearAndLoadMore: true });
      acknowledgeAllAlerts(alertTypeToAcknowledge);
    };

    limitedBulkActionAvailable = () => {
      const { selectedDevices } = this.state;

      const uniqueDevices = selectedDevices.filter((v, i, a) => a.indexOf(v) === i);

      return uniqueDevices.length <= 10;
    };

    updateBulkPauseResume = () => {
      const { alerts } = this.props;
      const { selectedAlerts } = this.state;

      const selectedDeviceStates = alerts
        .filter((alert) => selectedAlerts.includes(alert.id))
        .map((alert) => alert.state);
      const bulkPause =
        !selectedAlerts.length ||
        selectedDeviceStates.some((state) => !pausedStates.includes(state));
      this.setState({
        bulkPause,
      });
    };

    // PAUSE EVENTS
    handlePause = (alertId, deviceid) => {
      const { alerts, openModal_, pauseDevices, keyedAlerts, useBizLayerPauseResume } = this.props;
      const { selectedAlerts, theme } = this.state;

      const deviceIds = [];

      if (typeof alertId === 'number') {
        deviceIds.push(deviceid);
      } else {
        selectedAlerts.forEach((selectedAlert) => {
          if (!deviceIds.includes(keyedAlerts[selectedAlert].deviceid)) {
            deviceIds.push(keyedAlerts[selectedAlert].deviceid);
          }
        });
      }
      if (theme === 'default' || deviceIds.length === 1) {
        openModal_('PauseSimModal', {
          device: {
            id: deviceIds[0],
            linkId: deviceIds[0],
          },
          onConfirm: () => {
            pauseDevices(deviceIds, alerts, alertType, useBizLayerPauseResume)
              .then((data) => {
                AlertsTableContainer.showPauseToast(data, alertId);
                this.updateBulkPauseResume();
              })
              .catch((error) => {
                pushGlobalMessage(error, 'error');
              });
          },
        });
      } else if (this.limitedBulkActionAvailable()) {
        openModal_('BulkActionModal', {
          actionTitle: 'Pause Data',
          buttonLabel: 'Pause these devices',
          deviceIds,
          action: () =>
            pauseDevices(deviceIds, alerts, alertType, useBizLayerPauseResume).then((data) => {
              AlertsTableContainer.showPauseToast(data);
              this.updateBulkPauseResume();
            }),
        });
      }
    };

    // RESUME EVENTS
    handleResume = (deviceId) => {
      const { alerts, keyedAlerts, openModal_, resumeBulk, resumeDevice } = this.props;
      const { selectedAlerts, theme } = this.state;

      const deviceIds = [];

      if (typeof deviceId === 'number') {
        deviceIds.push(deviceId);
      } else {
        selectedAlerts.forEach((selectedAlert) => {
          if (!deviceIds.includes(keyedAlerts[selectedAlert].deviceid)) {
            deviceIds.push(keyedAlerts[selectedAlert].deviceid);
          }
        });
      }
      if (theme === 'default' || deviceIds.length === 1) {
        if (window.confirm('Are you sure you want to resume this device?')) {
          resumeDevice(deviceIds[0], alerts, alertType)
            .then((data) => {
              AlertsTableContainer.showResumeToast(data, 1);
              this.updateBulkPauseResume();
            })
            .catch((error) => {
              pushGlobalMessage(error, 'error');
            });
        }
      } else if (this.limitedBulkActionAvailable()) {
        openModal_('BulkActionModal', {
          actionTitle: 'Resume Data',
          buttonLabel: 'Resume these devices',
          action: () =>
            resumeBulk(deviceIds, alerts, alertType).then((data) => {
              AlertsTableContainer.showResumeToast(data, deviceIds.length);
              this.updateBulkPauseResume();
            }),
          deviceIds,
        });
      }
    };

    setAllUnloadedAlertsSelected = (areAllUnloadedAlertsSelected) => {
      this.setState({
        allUnloadedAlertsSelected: areAllUnloadedAlertsSelected,
      });
    };

    render() {
      const {
        activeAlertId,
        alertCount,
        alerts,
        filterData,
        isInitialPageLoad,
        isLoading,
        keyedAlerts,
        ackedAlertIds,
      } = this.props;
      const {
        bulkPause,
        dismissClearAndLoadMore,
        selectedAlerts,
        selectedDevices,
        theme,
        allUnloadedAlertsSelected,
      } = this.state;

      const allSelected = selectedAlerts.length === alerts.length;
      const limitedBulkActionAvailable = this.limitedBulkActionAvailable();

      return (
        <WrappedTable
          activeAlertId={activeAlertId}
          alerts={alerts}
          ackedAlertIds={ackedAlertIds}
          alertCount={alertCount}
          allSelected={allSelected}
          bulkPause={bulkPause}
          deselectAlerts={this.deselectAlerts}
          dismissClearBanner={this.dismissClearBanner}
          dismissClearBannerAndLoadMore={this.dismissClearBannerAndLoadMore}
          goToNextPage={this.goToNextPage}
          handleAcknowledgeAlerts={this.handleAcknowledgeAlerts}
          handleAcknowledgeSingleAlert={this.handleAcknowledgeSingleAlert}
          handleHideEducationBanner={this.handleHideEducationBanner}
          handlePauseSim={this.handlePause}
          handlePlanChange={this.onPlanChange}
          handleResume={this.handleResume}
          handleSingleSelection={this.handleSingleSelection}
          handleUnacknowledgeSingleAlert={this.handleUnacknowledgeSingleAlert}
          isInitialPageLoad={isInitialPageLoad}
          isLoading={isLoading}
          keyedAlerts={keyedAlerts}
          limitedBulkActionAvailable={limitedBulkActionAvailable}
          nextPageEnabled={filterData.continues}
          selectAlerts={this.selectAlerts}
          selectAll={this.handleSelectAll}
          selectedAlerts={selectedAlerts}
          selectedDevices={selectedDevices}
          setActiveAlert={this.setActiveAlert}
          setAllUnloadedAlertsSelected={this.setAllUnloadedAlertsSelected}
          allUnloadedAlertsSelected={allUnloadedAlertsSelected}
          showClearAndLoadMore={
            !dismissClearAndLoadMore &&
            filterData.continues &&
            alerts.filter((alert) => !!alert.acked).length === alerts.length
          }
          showEducationHeader={this.showEducationHeader()}
          theme={theme}
          updateTheme={this.handleChangeTheme}
          {...this.props}
        />
      );
    }
  }

  const createMapStateToProps = () => {
    const getActiveAlert = createGetActiveAlertIdByType(alertType);
    const getAlertCount = createGetAlertCountByType(alertType);
    const getAlertsData = createGetAlertDataByType(alertType);
    const getFilterData = createGetAlertFilterDataByType(alertType);
    const getHasFetched = createGetHasFetchedByType(alertType);
    const getIsLoading = createGetAlertLoadingByType(alertType);
    const getKeyedAlerts = createGetKeyedAlertsByType(alertType);
    const getAckedAlertIds = createGetAckedAlertIds(alertType);

    return (state) => ({
      alertCount: getAlertCount(state),
      alerts: getAlertsData(state),
      filterData: getFilterData(state),
      hasFetched: getHasFetched(state),
      isLoading: getIsLoading(state),
      activeAlertId: getActiveAlert(state),
      keyedAlerts: getKeyedAlerts(state),
      ackedAlertIds: getAckedAlertIds(state),
      releaseFlags: state.releaseFlag,
      userSettings: state.account.settings,
      useBizLayerPauseResume: selectHasBizLayerPauseResumeFlag(state),
    });
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        ...alertActions,
        openModal_: openModal,
        updateUserSettings_: updateUserSettings,
        setCurrentDevice_: setCurrentDevice,
        setChangePlanDevices_: setChangePlanDevices,
        setIsChangePlanFlag_: setIsChangePlanFlag,
      },
      dispatch
    );

  return connect(createMapStateToProps, mapDispatchToProps)(AlertsTableContainer);
};

export default AlertsTableComponentHoc;
