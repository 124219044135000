import { getUserFriendlyBytes } from '@hologram-hyper-dashboard/components';
import { FC } from 'react';

interface BytesProps {
  amount?: number;
  /**
   * @deprecated No longer in use
   */
  roundToDecimal?: number;
  classNames?: string;
}

// eslint-disable-next-line deprecation/deprecation, @typescript-eslint/no-unused-vars
const Bytes: FC<BytesProps> = ({ amount = 0, roundToDecimal = 2, classNames = '' }) => (
  <span className={`bytes-label${classNames ? ` ${classNames}` : ''}`}>
    {getUserFriendlyBytes(amount || 0)}
  </span>
);

export default Bytes;
