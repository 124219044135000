import { Panel } from '@hologram-dimension/panel';
import formatCurrencyWithRounding from 'common-js/utils/formatCurrencyWithRounding';
import { getLimitValue, toByteStringFormatted } from 'common-js/utils/numberFormatter';
import React from 'react';

interface PlanSelectionItemObj {
  label: string;
  value: string;
}

const PlanSelectionItem = ({ item }: { item: PlanSelectionItemObj }) => (
  <div className="PlanSelectionTable__Cell">
    <span className="PlanSelectionTable__ItemLabel">{item.label}</span>
    <span className="PlanSelectionTable__ItemValue">{item.value}</span>
  </div>
);

interface PlanSelectionRowProps {
  items: Array<PlanSelectionItemObj>;
}

const PlanSelectionRow = ({ items }: PlanSelectionRowProps) => (
  <div className="PlanSelectionTable__Row">
    {items.map((item) => (
      <PlanSelectionItem item={item} key={item.label} />
    ))}
  </div>
);

interface PlanSelectionTableProps {
  displayName: string;
  usageLimit?: { type: string };
  coverage: string;
  dataLimit: number;
  perLineCharge: number;
  overageCharge: number;
  outboundSms: number;
  hasPostpay?: boolean;
  hasDataUsageLimits?: boolean;
}

function PlanSelectionTable({
  displayName,
  coverage,
  dataLimit,
  perLineCharge,
  overageCharge,
  outboundSms,
  hasPostpay = false,
  hasDataUsageLimits = false,
  usageLimit,
}: PlanSelectionTableProps) {
  const items: Array<PlanSelectionItemObj> = [
    {
      label: 'Per device',
      value: `${formatCurrencyWithRounding(perLineCharge, 4)} / ${hasPostpay ? 'mo.' : '30 days'}`,
    },
    ...(dataLimit !== 0
      ? [{ label: 'Included data', value: toByteStringFormatted(dataLimit) }]
      : []),
    ...(hasDataUsageLimits && usageLimit?.type !== 'unlimited'
      ? [
          {
            label: 'Data limit',
            value: `${usageLimit?.type ? getLimitValue(usageLimit as any, dataLimit) : 'Never'}`,
          },
        ]
      : []),
    {
      label: 'Per MB',
      value: dataLimit >= 0 ? `${formatCurrencyWithRounding(overageCharge, 4)} / MB` : '-',
    },
    {
      label: 'Per Outbound SMS',
      value: `${formatCurrencyWithRounding(outboundSms, 4)} / SMS`,
    },
    {
      label: 'Coverage',
      value: coverage,
    },
  ];

  return (
    <Panel
      header="Data plan selection"
      className="PlanSelectionTable"
      contentWell={
        <PlanSelectionRow
          items={[
            {
              label: 'Name',
              value: displayName,
            },
          ]}
        />
      }
    >
      <PlanSelectionRow items={items} />
    </Panel>
  );
}

export default PlanSelectionTable;
