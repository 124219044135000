import React, { useCallback, useMemo, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { addTagToDevice, removeTagFromDevice } from 'common-js/reducers/currentDevice/actions';
import selectTagsForDropdown from './selectTagsForDropdown';

// see if a list of Tag IDs includes a particular tag's id
const hasTag = (tagsList: Array<number>, id: number) => tagsList.some((tagId) => tagId === id);

// The device already been selected from the redux store
// We pass it as a parameter to this hook, instead of selecting it again
const useDeviceTagDropdownItems = (device) => {
  const [loadingTags, setLoadingTags] = useState<Array<number>>([]);
  const setTagLoading = useCallback(
    (tagId) => setLoadingTags((currentLoadingTags) => [...currentLoadingTags, tagId]),
    [setLoadingTags]
  );
  const clearTagLoading = useCallback(
    (tagId) =>
      setLoadingTags((currentLoadingTags) => currentLoadingTags.filter((id) => id !== tagId)),
    [setLoadingTags]
  );

  const dispatch = useAppDispatch();
  const tags = useAppSelector(selectTagsForDropdown);

  const handleClick = useCallback(
    async (itemIndex) => {
      const tagId = tags[itemIndex].id;
      const isTagOnDevice = hasTag(device.tags, tagId);
      const toggleTagOnDevice = isTagOnDevice ? removeTagFromDevice : addTagToDevice;
      setTagLoading(tagId);
      await dispatch(toggleTagOnDevice(tagId, device.id));
      clearTagLoading(tagId);
    },
    [clearTagLoading, device, dispatch, setTagLoading, tags]
  );

  return useMemo(() => {
    if (!tags.length) {
      return [
        {
          header: true,
          children: <div className="DeviceTagDropdown__empty-state">No tags created yet.</div>,
        },
      ];
    }

    return tags.map((tag: Tag) => {
      const isTagOnDevice = tag.id ? hasTag(device.tags, tag.id) : true;
      const isTagLoading = tag.id ? hasTag(loadingTags, tag.id) : true;
      return {
        onClick: handleClick,
        hasCheckbox: true,
        checkboxProps: {
          checked: isTagOnDevice,
        },
        children: tag.name,
        isLoading: isTagLoading,
        disabled: isTagLoading,
      };
    });
  }, [device?.tags, handleClick, loadingTags, tags]);
};

export default useDeviceTagDropdownItems;
