import { Panel } from '@hologram-dimension/panel';
import { ExpandCollapse, ExpandCollapseToggle } from 'common-js/components';
import useYesNoAccountSetting from 'common-js/hooks/useYesNoAccountSetting';
import { useCallback, useEffect, useState } from 'react';
import DeviceStatusOverviewLights from './DeviceStatusOverviewLights';

const DeviceStatusStates = () => {
  const [setting, setSetting] = useYesNoAccountSetting('sdp_status_expanded');
  const [expanded, setExpanded] = useState(setting);

  const statusPanelChangeCallback = useCallback(() => {
    setExpanded((lastExpanded) => !lastExpanded);
  }, []);

  useEffect(() => {
    setSetting(expanded);
  }, [expanded, setSetting]);

  return (
    <Panel
      headerActions={
        <ExpandCollapseToggle expanded={expanded} onExpandChange={statusPanelChangeCallback} />
      }
      contentWell={!expanded ? <DeviceStatusOverviewLights expanded={expanded} small /> : null}
      noContentPadding
      header="Status"
    >
      <ExpandCollapse expanded={expanded} initialExpanded={expanded}>
        <DeviceStatusOverviewLights expanded={expanded} />
      </ExpandCollapse>
    </Panel>
  );
};

export default DeviceStatusStates;
