import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Select, TextSelect } from '@holokit/core';
import { throttle } from 'lodash';
import {
  clearSelection,
  removeFilter,
  setFilter,
  setFilterInputValue,
} from 'common-js/reducers/devices/actions';
import { getFilters } from 'common-js/reducers/devices/selectors';
import * as filterComparators from 'common-js/constants/filterComparators';
import { USAGE } from 'common-js/constants/deviceFilterFields';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

class UsageFilter extends Component {
  static existingFilterDoesNotMatch = (hasUsageFilter, usageBytes, filters, comparator) =>
    !hasUsageFilter ||
    !(usageBytes in filters.links_cur_billing_data_used) ||
    (usageBytes in filters.links_cur_billing_data_used &&
      filters.links_cur_billing_data_used[usageBytes].comparator !== comparator);

  dataNumToUnit = {
    1000: 'KB',
    1000000: 'MB',
    1000000000: 'GB',
  };

  constructor(props) {
    super(props);

    this.handleSearchDebounced = throttle(this.handleSearch, 1000);
    this.state = { updateAccordionHeight: false };
  }

  handleInputChange = (e, input) => {
    const { clearSelection_, setFilterInputValue_, setFilter_ } = this.props;
    const { target } = e;
    this.setState({ updateAccordionHeight: true });

    clearSelection_();

    // right now the only use case we have for equal filters is equal to zero
    if (target.value === filterComparators.EQUAL) {
      setFilterInputValue_(input, target.value);
      setFilter_(USAGE, 0, target.value);
      sendAnalyticsEvent({
        type: analyticsTypes.FILTERS_SET_FILTER,
        data: {
          filter_name: 'Usage',
          filter_value: 'equal to zero',
          filter_is_preset: true,
        },
      });
    } else {
      setFilterInputValue_(input, target.value).then(() => {
        this.handleSearchDebounced();
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleSearch();
  };

  handleSearch = () => {
    const { clearSelection_, filters, amount, amountType, comparator, removeFilter_, setFilter_ } =
      this.props;
    const hasUsageFilter = USAGE in filters;

    clearSelection_();

    // Clear filter if it had been previously set and is now empty, otherwise only apply filter if amount has a value
    if (amount === '' && hasUsageFilter) {
      removeFilter_(USAGE);
      sendAnalyticsEvent({
        type: analyticsTypes.FILTERS_SET_FILTER,
        data: {
          filter_name: 'Usage',
          filter_value: null,
        },
      });
    } else if (amount !== '') {
      const usageBytes = Number(amount) * Number(amountType);

      if (UsageFilter.existingFilterDoesNotMatch(hasUsageFilter, usageBytes, filters, comparator)) {
        setFilter_(USAGE, usageBytes, comparator);
        sendAnalyticsEvent({
          type: analyticsTypes.FILTERS_SET_FILTER,
          data: {
            filter_name: 'Usage',
            filter_value: `${comparator} ${amount} ${this.dataNumToUnit[amountType]}`,
          },
        });
      }
    }
    this.handleSearchDebounced.cancel();
  };

  updateAccordionHeightCallback = () => {
    this.setState({ updateAccordionHeight: false });
  };

  render() {
    const { amount, amountType, comparator, defaultOpen, filters } = this.props;
    const { updateAccordionHeight } = this.state;

    return (
      <div className="DevicesFilter">
        <Accordion
          defaultOpen={defaultOpen}
          header="Usage"
          iconLeft="Performance"
          isFiltered={'links_cur_billing_data_used' in filters}
          updateAccordionHeight={updateAccordionHeight}
          updateAccordionHeightCallback={this.updateAccordionHeightCallback}
          accordionToggleCallback={({ open }) => {
            sendAnalyticsEvent({
              type: analyticsTypes.FILTERS_TOGGLE_ACCORDION,
              data: {
                accordion_name: 'Usage Filter',
                accordion_open: open,
              },
            });
          }}
        >
          <form className="DevicesFilter__form" onSubmit={this.handleSubmit}>
            <Select
              classes="DevicesFilter__form__field"
              options={[
                {
                  value: filterComparators.GREATER_THAN,
                  name: 'Is greater than',
                },
                {
                  value: filterComparators.LESS_THAN,
                  name: 'Is less than',
                },
                {
                  value: filterComparators.EQUAL,
                  name: 'Is equal to zero',
                },
              ]}
              onChange={(e) => this.handleInputChange(e, 'usageComparator')}
              value={comparator}
            />
            {comparator !== filterComparators.EQUAL && (
              <TextSelect
                classes="DevicesFilter__form__field"
                selectOptions={[
                  {
                    name: 'KB',
                    value: '1000',
                  },
                  {
                    name: 'MB',
                    value: '1000000',
                  },
                  {
                    name: 'GB',
                    value: '1000000000',
                  },
                ]}
                selectOnChange={(e) => this.handleInputChange(e, 'usageAmountType')}
                selectValue={amountType}
                textId="usageFilterAmount"
                textName="usageFilterAmount"
                textOnChange={(e) => this.handleInputChange(e, 'usageAmount')}
                textPlaceholder="200"
                textValue={amount}
                type="number"
              />
            )}
          </form>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  amount: state.devices.uiState.filterInputs.usageAmount,
  amountType: state.devices.uiState.filterInputs.usageAmountType,
  comparator: state.devices.uiState.filterInputs.usageComparator,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      removeFilter_: removeFilter,
      setFilter_: setFilter,
      setFilterInputValue_: setFilterInputValue,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsageFilter);
