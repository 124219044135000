import analyticsEventBuilder from 'common-js/analytics';
import { pluralize } from 'common-js/utils/language';
import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import Footer, {
  BackButton,
  ContinueButton,
  SkipButton,
  StatusText,
} from '../common/scaffolding/Footer';

function TagsFooter({ nextStep, prevStep, skipStep }) {
  const tags = useWatch({
    name: 'tags.tags',
  });

  const { numOfTags, continueText } = useMemo(
    () => ({
      numOfTags: tags?.length ?? 0,
      continueText: `${pluralize('tag', tags?.length ?? 0)} selected`,
    }),
    [tags?.length]
  );

  const onContinue = useCallback(() => {
    analyticsEventBuilder
      .buttonClick('Returning', 'Activation Tag Addition')
      .send({ 'tags selected': numOfTags });
    nextStep();
  }, [nextStep, numOfTags]);

  return (
    <Footer
      left={<BackButton onClick={prevStep} />}
      right={
        <>
          {numOfTags === 0 ? (
            <SkipButton onClick={skipStep} />
          ) : (
            <StatusText>{continueText}</StatusText>
          )}
          <ContinueButton disabled={numOfTags === 0} onClick={onContinue} />
        </>
      }
    />
  );
}

export default TagsFooter;
