import React from 'react';
import { createSelector } from 'reselect';
import * as DeviceStates from 'common-js/constants/deviceStates';
import selectDeviceMetaInfo from './selectDeviceMetaInfo';

const MESSAGES = {
  RESUMING:
    'A resume command has been sent to local carriers. Your device will be ready for use on our network within 10 minutes.',
  ACTIVATING:
    'We’re activating your device on our global network. It should be able to connect with most local carriers within 15 minutes. In rare cases, it may take up to 24 hours for devices to become fully active.',
  LIVE: 'Your device is activated and ready for use on our network. It has successfully connected to the internet recently. Everything appears normal with this device.',
  LIVE_NO_DATA:
    'Your device is activated and ready for use on our network. We have not yet seen it connect to a local tower or pass data.',
  INACTIVE:
    'Your device is inactive. It is available for activation as part of your SIM inventory.',
  PAUSED_BY_USER:
    'This device has been manually paused by you or a team member. While paused, your device will not be able to send or receive data or SMS messages. Monthly access fees for this device still apply while it is paused.',
  PENDING_PAUSE:
    'A pause command has been sent to local carriers. While paused, your device will not be able to use data or access SMS services.',
  PAUSED_FOR_PREPAY: (
    <>
      Your Hologram account is prepaid. When your balance reached zero, we paused this device. While
      paused, your device will not be able to send or receive data or SMS messages. See
      <a
        target="_blank"
        rel="noreferrer"
        href="https://support.hologram.io/hc/en-us/articles/360035212774"
      >
        {' here '}
      </a>
      to learn more about adding balance to your account.
    </>
  ),
  PAUSED_FOR_USAGE_LIMIT: (overageLimitString) => (
    <>
      Your data limit of {overageLimitString} was reached and we have paused your device. While
      paused, your device will not be able to send or receive data or SMS messages. See
      <a
        target="_blank"
        rel="noreferrer"
        href="https://support.hologram.io/hc/en-us/articles/360035697313"
      >
        {' here '}
      </a>
      to learn more about managing data limits.
    </>
  ),
  PAUSED: 'Your device is paused and cannot send data or messages at this time.',
  TEST_MODE_HIBERNATE_NO_DATA:
    'This device is in pre-deployment testing, but has not passed data. After this device uses 100 KB of data or transmits 10 SMS messages, it will hibernate and be ready for manual activation.',
  TEST_MODE_HIBERNATE:
    'This device is in pre-deployment testing and has successfully passed data. After this device uses 100 KB of data or transmits 10 SMS messages, it will hibernate and be ready for manual activation.',
  TEST_MODE_HIBERNATE_ACTIVE_SESSION:
    'This device is in pre-deployment testing and currently passing data. After this device uses 100 KB of data or transmits 10 SMS messages, it will hibernate and be ready for manual activation.',
  AUTO_ACTIVATE:
    'This device is in pre-deployment testing and has not yet connected to our network. It will automatically activate when it connects to our network for the first time.',
  TEST_MODE_ACTIVATE_NO_DATA:
    'This device is in pre-deployment testing, but has not passed data. After this device uses 100 KB of data or transmits 10 SMS messages, it will activate.',
  TEST_MODE_ACTIVATE:
    'This device is in pre-deployment testing and has successfully passed data on our network. After this device uses 100 KB of data or transmits 10 SMS messages, it will activate.',
  TEST_MODE_ACTIVATE_ACTIVE_SESSION:
    'This device is in pre-deployment testing and currently passing data. After this device uses 100 KB of data or transmits 10 SMS messages, it will activate.',
  INACTIVE_TESTED:
    'Your device completed Test Mode and is available for activation as part of your SIM inventory.',
  DEACTIVATED_AT: (date) => (
    <>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      This device was deactivated on {date.format('LL')} at {date.format('h:mm:ss')} UTC. Please see
      <a
        target="_blank"
        href="https://support.hologram.io/hc/en-us/articles/360036997573-How-do-I-deactivate-delete-SIMs-I-no-longer-need"
        rel="noreferrer"
      >
        {' this '}
      </a>
      article in our Help Center for more information on deactivated SIMs.
    </>
  ),
  DEACTIVATED: 'This SIM is deactivated. Service has ended for this device.',
};

// utils for messages that apply to multiple states, to avoid duplicating logic
const getHibernateMessage = ({ hasSession, onlyEmptySessions, kbUsed }) => {
  if (onlyEmptySessions) return MESSAGES.TEST_MODE_HIBERNATE_NO_DATA;
  if (kbUsed >= 100) return null;
  return hasSession ? MESSAGES.TEST_MODE_HIBERNATE_ACTIVE_SESSION : MESSAGES.TEST_MODE_HIBERNATE;
};

const getActivateMessage = ({ hasSession, onlyEmptySessions, kbUsed }) => {
  if (onlyEmptySessions) return MESSAGES.TEST_MODE_ACTIVATE_NO_DATA;
  if (kbUsed >= 100) return null;
  return hasSession ? MESSAGES.TEST_MODE_ACTIVATE_ACTIVE_SESSION : MESSAGES.TEST_MODE_ACTIVATE;
};

const getDeactivatedMessage = ({ deactivatedDate }) =>
  deactivatedDate ? MESSAGES.DEACTIVATED_AT(deactivatedDate) : MESSAGES.DEACTIVATED;

// maps device state to a message or to a function that returns a message
const deviceStateToMessage = {
  [DeviceStates.LIVE_PENDING]: ({ deviceWasPaused }) =>
    deviceWasPaused ? MESSAGES.RESUMING : MESSAGES.ACTIVATING,
  [DeviceStates.LIVE]: ({ hasSession, onlyEmptySessions }) =>
    hasSession && !onlyEmptySessions ? MESSAGES.LIVE : MESSAGES.LIVE_NO_DATA,
  [DeviceStates.INACTIVE]: MESSAGES.INACTIVE,
  [DeviceStates.PAUSED_USER]: MESSAGES.PAUSED_BY_USER,
  [DeviceStates.PAUSED_PENDING_USER]: MESSAGES.PENDING_PAUSE,
  [DeviceStates.PAUSED_PENDING_SYS]: MESSAGES.PENDING_PAUSE,
  [DeviceStates.PAUSED_SYS]: ({ pausedForPrepay, pausedForUsageLimit, overageLimitString }) => {
    if (pausedForPrepay) return MESSAGES.PAUSED_FOR_PREPAY;
    if (pausedForUsageLimit) return MESSAGES.PAUSED_FOR_USAGE_LIMIT(overageLimitString);
    return MESSAGES.PAUSED;
  },
  [DeviceStates.TEST_HIBERNATE_PENDING]: getHibernateMessage,
  [DeviceStates.TEST_HIBERNATE]: getHibernateMessage,
  [DeviceStates.TEST_AUTOACTIVATE_PENDING]: MESSAGES.AUTO_ACTIVATE,
  [DeviceStates.TEST_AUTOACTIVATE]: MESSAGES.AUTO_ACTIVATE,
  [DeviceStates.TEST_ACTIVATE_PENDING]: getActivateMessage,
  [DeviceStates.TEST_ACTIVATE]: getActivateMessage,
  [DeviceStates.INACTIVE_TESTED]: MESSAGES.INACTIVE_TESTED,
  [DeviceStates.DEAD_PENDING]: getDeactivatedMessage,
  [DeviceStates.DEAD]: getDeactivatedMessage,
};

const getMessage = (deviceMetaInfo) => {
  const { deviceState, lastSessionIsActive } = deviceMetaInfo;
  if (lastSessionIsActive) {
    return 'Your device is currently connected to our network, or has connected recently to our network and has an open data session.';
  }
  const message =
    deviceStateToMessage[deviceState] ?? "We're configuring your SIM card for use on our network.";
  return typeof message === 'function' ? message(deviceMetaInfo) : message;
};

// As with selectLineColors, all of the info we need to derive this is contained within redux.
// Turning this into a selector function lets us inject it where we need it, with memoization of the derived state.
const selectStatusMessage = createSelector(selectDeviceMetaInfo, getMessage);

export default selectStatusMessage;
