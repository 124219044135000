import { configureStore } from '@reduxjs/toolkit';
import { dataDogInit } from 'common-js/analytics/dataDog';
import rootReducer from 'common-js/reducers';
import createDebounce from 'redux-debounced';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

let holoStore: any;

export default function getStore() {
  return holoStore;
}

export const clearAllStores = () => {
  Object.keys(window.localStorage).forEach((key) => {
    if (key.includes('persist:holo')) {
      window.localStorage.removeItem(key);
    }
  });
};
const loggerFactory = () => createLogger({ collapsed: () => true });

export const upsertStore = (context) => {
  dataDogInit();

  // Configure middleware chain.
  const middlewares = [createDebounce(), thunk];
  if (process.env.NODE_ENV === 'development') middlewares.push(loggerFactory());

  const persistedReducer = persistReducer(
    {
      key: `holo-v4${context ? `-${context}` : ''}`,
      storage,
      timeout: undefined,
      whitelist: [
        'deviceFilters',
        'drawer',
        'activate',
        'apps',
        'pools',
        'organization',
        'releaseFlag',
        'usage',
        'modals',
        'account',
      ],
    },
    rootReducer
  );

  // Redux 2+ doesn't allow stores being changed via createStore, so we must check to see if it's already created and do replaceReducer instead.
  if (!holoStore) {
    holoStore = configureStore({
      reducer: persistedReducer,
      devTools: process.env.NODE_ENV !== 'production',
      middleware: middlewares,
    });
    window.store = holoStore;
  } else {
    holoStore.replaceReducer(persistedReducer);
  }

  // persist initial state.
  const persistor = persistStore(holoStore);
  return { store: holoStore, persistor };
};

// https://redux.js.org/usage/usage-with-typescript This starts us along the path of making redux have types
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof holoStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof holoStore.dispatch;
