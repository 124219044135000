import React from 'react';
import ActivityHistoryDetailsTableRow from './ActivityHistoryDetailsTableRow';
import ActivityHistoryDetailsSkeletonTableRow from './ActivityHistoryDetailsSkeletonTableRow';

function ActivityHistoryDetailsTable({ rows, isLoading }) {
  return (
    <table className="Table ActivityTable ActivityHistoryDetailsTable">
      <thead className="Table__header Table__header--sticky">
        <tr className="Table__row">
          <th className="Table__cell--header ActivityHistoryDetailsTable__device-name">
            <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content--header">
              Device Name / Tags
            </div>
          </th>
          <th className="Table__cell--header ActivityHistoryDetailsTable__sim-number">
            <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content--header">
              Active SIM Number (ICCID) / IMEI
            </div>
          </th>
          <th className="Table__cell--header ActivityHistoryDetailsTable__device-id">
            <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content--header">
              Device ID
            </div>
          </th>
          <th className="Table__cell--header ActivityHistoryDetailsTable__link-id">
            <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content--header">
              Link ID
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoading &&
          new Array(16).fill().map((_, index) => (
            // eslint-disable-next-line
            <ActivityHistoryDetailsSkeletonTableRow key={index} />
          ))}
        {!isLoading &&
          rows.map(({ deviceName, tags, iccid, imei, deviceId, linkId }) => (
            <ActivityHistoryDetailsTableRow
              key={deviceId}
              deviceName={deviceName}
              tags={tags}
              iccid={iccid}
              imei={imei}
              deviceId={deviceId}
              linkId={linkId}
            />
          ))}
      </tbody>
    </table>
  );
}

export default ActivityHistoryDetailsTable;
