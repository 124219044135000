const PREFIX = 'RELEASEFLAGS_';

export const GET_RELEASEFLAGS_REQUEST = `${PREFIX}GET_REQUEST`;
export const GET_RELEASEFLAGS_SUCCESS = `${PREFIX}GET_SUCCESS`;
export const GET_RELEASEFLAGS_ERROR = `${PREFIX}GET_ERROR`;

export const GET_PUBLIC_RELEASEFLAGS_REQUEST = `${PREFIX}GET_PUBLIC_REQUEST`;
export const GET_PUBLIC_RELEASEFLAGS_SUCCESS = `${PREFIX}GET_PUBLIC_SUCCESS`;
export const GET_PUBLIC_RELEASEFLAGS_ERROR = `${PREFIX}GET_PUBLIC_ERROR`;

export const GETALL_REQUEST = `${PREFIX}GETALL_REQUEST`;
export const GETALL_SUCCESS = `${PREFIX}GETALL_SUCCESS`;
export const GETALL_ERROR = `${PREFIX}GETALL_ERROR`;

export const CLEAR = `${PREFIX}CLEAR`;
