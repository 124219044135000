import { Button, Callout, Icon } from '@holokit/core';
import classNames from 'clsx';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { RoleDropdown } from 'common-js/components';
import { Submit, TagCompleteInput } from 'common-js/components/form';
import { DEVICES_DEVICES, withContext } from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectIsSuperAdmin } from 'common-js/reducers/account/selectors';
import { addUsersToOrg } from 'common-js/reducers/organization/actions';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import {
  translatePermissionsToRole,
  translateRoleToPermissions,
} from 'common-js/utils/permissions';
import { required } from 'common-js/utils/validation/validators';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import FormHOC from '../FormHOC';
import { formGenerator } from '../formGenerator';

const OrgInviteCollaboratorsForm = ({
  collabs,
  form,
  formProcessing,
  isOrgCreation,
  isSuccessful,
  onFormSubmit,
  role,
  resetForm,
  serverError,
  setFields,
  showValidationErrors,
  userEmail,
}) => {
  const useUserRole = () => {
    const userPermissions = useAppSelector(getUserPermissions);
    return translatePermissionsToRole(userPermissions);
  };

  const useIsAdmin = () => {
    const isAdmin = useUserRole() === 'admin';

    return { isAdmin };
  };

  const userRole = useUserRole();
  const { isAdmin: isPermissionsAdmin } = useIsAdmin();

  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);

  const isAdmin = isPermissionsAdmin || isSuperAdmin;

  const [inviteButton, disableInviteButton] = useState(false);

  useEffect(() => {
    resetForm();

    const defaultRole = userRole === 'editor_limited' ? 'editor_limited' : 'editor';
    setFields({ role: defaultRole });
    setFields({ origin: isOrgCreation ? 'neworg' : 'settings' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRole = (newRole) => {
    role.onChange(newRole);
  };

  const isRoleConfigured = (selectedRole) => {
    const isValidRole = selectedRole !== 'placeholder';
    disableInviteButton(isValidRole);
  };

  if (isSuccessful) {
    return (
      <div
        className={classNames('OrgInviteCollaboratorsForm__success-panel', {
          'OrgInviteCollaboratorsForm__success-panel--new-org': isOrgCreation,
        })}
      >
        {!isOrgCreation && (
          <Icon name="Circle--check" size="major" svgProps={{ style: { fill: '#0A9959' } }} />
        )}

        <h2 className="OrgInviteCollaboratorsForm__success-panel__text">
          Your invites have been sent
        </h2>

        <footer className="OrgInviteCollaboratorsForm__success-panel__footer">
          {isOrgCreation && (
            <Button
              type="primary"
              buttonProps={{
                type: 'button',
              }}
              onClick={() => {
                browserHistory.push(withContext(`${DEVICES_DEVICES}?fv=1`));
              }}
            >
              Continue to dashboard
            </Button>
          )}

          <Button
            unstyled
            classes="OrgInviteCollaboratorsForm__footer-link"
            buttonProps={{
              type: 'button',
            }}
            onClick={() => {
              resetForm();
            }}
          >
            Invite more collaborators
          </Button>
        </footer>
      </div>
    );
  }

  return (
    <div className="OrgInviteCollaboratorsForm">
      <form onSubmit={onFormSubmit}>
        <div className="OrgInviteCollaboratorsForm__wrapper">
          {serverError && (
            <Callout
              text={serverError}
              type={Callout.TYPE.ERROR}
              iconColor={Callout.THEME.COLOR}
              defaultIcon
              className="OrgInviteCollaboratorsForm__callout"
            />
          )}

          <div className="OrgInviteCollaboratorsForm__row">
            <label className="OrgInviteCollaboratorsForm__label" htmlFor="collabs">
              Add collaborators
            </label>

            <TagCompleteInput
              {...collabs}
              id="collabs"
              error={form.collabs.error}
              showValidation={showValidationErrors}
              placeholder="Enter collaborator email"
              name="collabs"
              invalidTags={[userEmail]}
              classNames={classNames('OrgInviteCollaboratorsForm__input', {
                'OrgInviteCollaboratorsForm__input--error': !!form.collabs.error,
              })}
            />
          </div>
          <div className="OrgInviteCollaboratorsForm__row">
            <label className="OrgInviteCollaboratorsForm__label" htmlFor="role">
              Role
            </label>

            <input type="hidden" name="role" value={role} />

            <RoleDropdown
              onChange={changeRole}
              error={form.role.error}
              isAdmin={isAdmin}
              isRoleConfigured={isRoleConfigured}
            />

            {form.role.error && (
              <div className="OrgInviteCollaboratorsForm__error">{form.role.error}</div>
            )}
          </div>
          {!isAdmin && (
            <div className="OrgInviteCollaboratorsForm__info">
              <div className="OrgInviteCollaboratorsForm__info__text">
                You do not have permission to set roles. After the invite is sent, ask your admin to
                set or edit roles.
              </div>
            </div>
          )}
        </div>

        <footer className="OrgInviteCollaboratorsForm__footer">
          {isOrgCreation && (
            <Button
              unstyled
              classes="OrgInviteCollaboratorsForm__footer-link"
              buttonProps={{
                type: 'button',
              }}
              onClick={() => {
                browserHistory.push(withContext(`${DEVICES_DEVICES}?fv=1`));
              }}
            >
              Skip, go to dashboard
            </Button>
          )}

          <Submit
            formProcessing={formProcessing}
            formSuccessful={isSuccessful}
            label="Send invites"
            disabled={!inviteButton}
          />
        </footer>
      </form>
    </div>
  );
};

OrgInviteCollaboratorsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  collabs: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  formProcessing: PropTypes.bool,
  isDirty: PropTypes.bool,
  isOrgCreation: PropTypes.bool,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  permissionLevel: PropTypes.object.isRequired,
  serverError: PropTypes.string,
  showAccessLevelSelector: true,
  showValidationErrors: PropTypes.bool,
};

OrgInviteCollaboratorsForm.defaultProps = {
  formProcessing: false,
  isOrgCreation: false,
  serverError: null,
  showAccessLevelSelector: true,
  showValidationErrors: false,
};

export const config = formGenerator({
  form: 'OrgInviteCollaboratorsForm',
  fields: ['collabs', 'role', 'origin'],
  validators: {
    collabs: [required('Add one or more emails')],
  },
  onSubmit: (form, actionTypes, dispatch, state, HOC) => {
    const { userEmail } = HOC.props;

    const userEmails = form.collabs.value
      .split(',')
      .map((collab) => collab.trim())
      .filter((email) => email !== userEmail);

    if (userEmails.length === 0) {
      dispatch({
        type: actionTypes.ERROR,
        errorMessage: 'Enter an email other than your own',
      });
      return undefined;
    }

    const permissions = translateRoleToPermissions(form?.role?.value);

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Permissions - Send Collaborator Invites',
        origin: form?.origin?.value === 'neworg' ? 'New Org Creation Modal' : 'Settings Page',
      },
    });

    return HOC.props
      .addUsersToOrg(userEmails, permissions)
      .then(() => {
        dispatch({
          type: actionTypes.SUCCESS,
        });
      })
      .catch((errorMessage) => {
        dispatch({
          type: actionTypes.ERROR,
          errorMessage,
        });
      });
  },
});

const OrgInviteCollaboratorsFormHoC = connect(
  (state) => ({
    userEmail: state.account?.email,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        addUsersToOrg,
      },
      dispatch
    )
)(FormHOC(OrgInviteCollaboratorsForm, config));

export default OrgInviteCollaboratorsFormHoC;
