import { datadogRum } from '@datadog/browser-rum';
import * as actionTypes from './actionTypes';
import { AnalyticsAction, AnalyticsNames, AnalyticsSendParams } from './analyticsTypes';
import { sendDatadogAction, sendDatadogError } from './dataDog';

export const sendAnalyticsEvent = (action: AnalyticsAction) => {
  const isDevEnv = process.env.NODE_ENV === 'development';
  if (isDevEnv) {
    /* eslint-disable no-console */
    console.log(`Analytics: sending ${action.type}...`);
    console.table(action.data);
    /* eslint-enable no-console */
  }
  switch (action.type) {
    case actionTypes.IMPERSONATE:
      sendDatadogAction('Impersonation', action.data);
      break;

    case actionTypes.IMPERSONATE_STOP:
      sendDatadogAction('Impersonation Stop', action.data);
      break;

    case actionTypes.PAGE_VIEW:
      sendDatadogAction('Page View', action.data);
      break;

    case actionTypes.BUTTON_CLICK:
      sendDatadogAction('Button Click', action.data);
      break;

    case actionTypes.DATA_ENTRY:
      sendDatadogAction('Data Entry', action.data);
      break;

    case actionTypes.FILE_EXPORT:
      sendDatadogAction('File Export', action.data);
      break;

    case actionTypes.FILE_UPLOAD:
      sendDatadogAction('File Upload', action.data);
      break;

    case actionTypes.ERROR_RETURN:
      sendDatadogError(action.data);
      break;

    case actionTypes.SYSTEM_ERROR:
      sendDatadogError(action.data);
      break;

    case actionTypes.ACTIVATE_INITIATE:
      sendDatadogAction('Activate SIM - Activate SIM Button Clicked');
      break;

    case actionTypes.ACTIVATE_SELECT_PLAN:
      sendDatadogAction('Activate SIM - Select Plan Complete');
      break;

    case actionTypes.ACTIVATE_ADD_SIMS:
      sendDatadogAction('Activate SIM - Add Sims Complete');
      break;

    case actionTypes.ACTIVATE_ADD_PAYMENT:
      sendDatadogAction('Activate SIM - Add Payment Complete');
      break;

    case actionTypes.ACTIVATE_CONFIRM:
      sendDatadogAction('Activate SIM - Activation Complete', action.data);
      break;

    case actionTypes.DEACTIVATE_SIM:
      sendDatadogAction('Deactivate SIM', action.data);
      break;

    case actionTypes.PAUSE_SIM:
      sendDatadogAction('Pause SIM', action.data);
      break;

    case actionTypes.RESUME_SIM:
      sendDatadogAction('Resume SIM', action.data);
      break;

    case actionTypes.SPACEBRIDGE_TUNNELING_ENABLED:
      sendDatadogAction('Spacebridge Tunneling Enabled');
      break;

    case actionTypes.SPACEBRIDGE_TUNNELING_DISABLED:
      sendDatadogAction('Spacebridge Tunneling Disabled');
      break;

    case actionTypes.ORG_CREATED:
      sendDatadogAction('Org Created', action.data);
      break;

    case actionTypes.REGISTRATION:
      sendDatadogAction('Registration', action.data);
      break;

    case actionTypes.REGISTRATION_ONBOARDING_QUESTION:
      sendDatadogAction('Registration - Onboarding Question', action.data);
      break;

    case actionTypes.REGISTRATION_SKIP_TO_DASHBOARD:
      sendDatadogAction('Registration - Skip to Dashboard');
      break;

    case actionTypes.REPORT_USAGE_BREAKDOWN:
      sendDatadogAction('Run Report - Usage Breakdown', action.data);
      break;

    case actionTypes.REPORT_USAGE_OVERVIEW:
      sendDatadogAction('Run Report - Usage Overview', action.data);
      break;

    case actionTypes.REPORT_USAGE_INSPECT:
      sendDatadogAction('Run Report - Usage Inspect', action.data);
      break;

    case actionTypes.REPORT_SINGLE_DEVICE_USAGE:
      sendDatadogAction('Run Report - Single Device Usage', action.data);
      break;

    case actionTypes.REPORT_BILLING_HISTORY:
      sendDatadogAction('Run Report - Billing History', action.data);
      break;

    case actionTypes.REPORT_BILLING_STATEMENT:
      sendDatadogAction('Run Report - Billing Statement', action.data);
      break;

    case actionTypes.FILTERS_SWITCH_SEGMENT:
      sendDatadogAction('Device Filters - Switch Segment', action.data);
      break;

    case actionTypes.FILTERS_SEE_ALL_OPTIONS:
      sendDatadogAction('Device Filters - See All Options', action.data);
      break;

    case actionTypes.FILTERS_CLEAR_ALL:
      sendDatadogAction('Device Filters - Clear All Filters', action.data);
      break;

    case actionTypes.FILTERS_TOGGLE_ACCORDION:
      sendDatadogAction('Device Filters - Toggle Accordion', action.data);
      break;

    case actionTypes.FILTERS_SET_FILTER:
      sendDatadogAction('Device Filters - Set Filter', action.data);
      break;

    case actionTypes.FLEET_HOME_METRIC_BUTTON_CLICK:
      sendDatadogAction('Fleet Home - Metric Button Click', action.data);
      break;

    case actionTypes.FLEET_HOME_USAGE_FILTER_TAGS:
      sendDatadogAction('Fleet Home - Filter Usage Data', action.data);
      break;

    case actionTypes.FLEET_HOME_REVIEW_ACTION_ITEMS:
      sendDatadogAction('Fleet Home - Review Action Items', action.data);
      break;

    case actionTypes.FLEET_HOME_ONBOARD_FORM_ANSWER:
      sendDatadogAction('Fleet Home - Onboarding Form Question Answer', action.data);
      break;

    case actionTypes.BILLING_ACCOUNT_REMOVE_CARD:
      sendDatadogAction('Billing - Account Tab - Remove Card');
      break;

    case actionTypes.BILLING_ACCOUNT_UPDATE_CARD:
      sendDatadogAction('Billing - Account Tab - Update Card');
      break;

    case actionTypes.BILLING_ACCOUNT_UPDATE_ADDRESS:
      sendDatadogAction('Billing - Account Tab - Update Address');
      break;

    case actionTypes.BILLING_ACCOUNT_ADD_CUSTOM_INVOICE_DETAILS:
      sendDatadogAction('Billing - Account Tab - Add Custom Invoice Details');
      break;

    case actionTypes.BILLING_ACCOUNT_REMOVE_CUSTOM_INVOICE_DETAILS:
      sendDatadogAction('Billing - Account Tab - Remove Custom Invoice Details');
      break;

    case actionTypes.BILLING_ACCOUNT_EDIT_CUSTOM_INVOICE_DETAILS:
      sendDatadogAction('Billing - Account Tab - Edit Custom Invoice Details');
      break;

    case actionTypes.BILLING_ACCOUNT_ADD_EMAIL_TO_BILLING_NOTIFICATIONS:
      sendDatadogAction('Billing - Account Tab - Add Email To Billing Notifications');
      break;

    case actionTypes.BILLING_HISTORY_VIEW_ALL_BALANCE_HISTORY:
      sendDatadogAction('Billing - History Tab - View All Balance History');
      break;

    case actionTypes.BILLING_ORDERS_TOGGLE_DETAILS:
      sendDatadogAction('Billing - Orders Tab - Toggle Details');
      break;

    case actionTypes.BILLING_ORDERS_SHOW_MORE:
      sendDatadogAction('Billing - Orders Tab - Show More');
      break;

    case actionTypes.SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_SUCCESS:
      sendDatadogAction('SIMs - Details - Manage SIM - Switch profile success');
      break;

    case actionTypes.SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_ERROR:
      sendDatadogAction('SIMs - Details - Manage SIM - Switch profile error', action.data);
      break;

    default:
      // eslint-disable-next-line no-console
      console.warn(
        `Got analytics type ${action.type} but there is no tracking logic for that type`
      );
  }
};

const analyticsTrack = (...args: Parameters<typeof datadogRum.addAction>) => {
  const isDevEnv = process.env.NODE_ENV === 'development';
  if (isDevEnv) {
    /* eslint-disable no-console */
    console.log(`Analytics: sending '${args[0]}'...`);
    console.table(args[1]);
    /* eslint-enable no-console */
  }

  sendDatadogAction(...args);
};

type AnalyticsEventType =
  | 'Button Click'
  | 'Data Entry'
  | 'Error Return'
  | 'File Export'
  | 'File Upload'
  | 'Page View'
  | 'System Error'
  | 'User Error';

// creates a function that sends the analytics event, pre-populating the payload with some fields
const createSender =
  (eventType: AnalyticsEventType, fields) =>
  (data: AnalyticsSendParams = {}) => {
    const payload = {
      ...fields,
      ...data,
    };
    analyticsTrack(eventType, payload);
  };

const joinStrings = (propertyName: string, ...strings: Array<string | undefined>) => {
  const filteredStrings = strings.filter((s) => s !== undefined);
  if (filteredStrings.length === 0) return {};
  return { [propertyName]: filteredStrings.join(' - ') };
};

const combine = (...fields: Array<Record<string, any>>): Record<string, any> =>
  Object.assign({}, ...fields);

// creates an analytics event handler with ONLY a .send property
const createSimpleEventHandler =
  (eventType: AnalyticsEventType) =>
  (...names: AnalyticsNames) => ({
    send: createSender(eventType, joinStrings('name', ...names)),
  });

// builder pattern version of sendAnalyticsEvent, should reduce number of imports required
export const analyticsEventBuilder = {
  buttonClick: createSimpleEventHandler('Button Click'),
  dataEntry: createSimpleEventHandler('Data Entry'),
  errorReturn: createSimpleEventHandler('Error Return'),
  fileExport: createSimpleEventHandler('File Export'),
  fileUpload: createSimpleEventHandler('File Upload'),
  systemError: createSimpleEventHandler('System Error'),
  userError: createSimpleEventHandler('User Error'),
  pageView: (...names: Array<string | undefined>) => ({
    page: (...pages: Array<string | undefined>) => ({
      // all page view events should contain the page being viewed
      send: createSender(
        'Page View',
        combine(joinStrings('name', ...names), joinStrings('page', ...pages))
      ),
    }),
  }),
};

export default sendAnalyticsEvent;
