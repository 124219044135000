import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { OrgModel } from 'common-js/models';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { close } from 'common-js/reducers/modal/actions';
import { updateOrganization } from 'common-js/reducers/organization/actions';
import { getSelectedOrg } from 'common-js/reducers/organization/selectors';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ModalHeader } from '../components';

interface EditOrgNameModalProps {}

interface OrgNameFormInputs {
  orgName: string;
}

const EditOrgNameModal: React.FC<EditOrgNameModalProps> = () => {
  const selectedOrg = useAppSelector(getSelectedOrg) || new OrgModel();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const selectedOrgName = selectedOrg.name;
  const { register, handleSubmit, watch } = useForm<OrgNameFormInputs>({
    defaultValues: { orgName: selectedOrgName },
  });

  const newName = watch('orgName');

  const handleCancelClick = () => {
    dispatch(close());
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Settings - Change Org Name Modal Cancel',
      },
    });
  };

  const onSubmit: SubmitHandler<OrgNameFormInputs> = (data) => {
    const { id } = selectedOrg;

    if (newName === selectedOrgName) {
      return;
    }

    setLoading(true);

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Settings - Change Org Name Modal Confirm',
      },
    });

    dispatch(updateOrganization(id, data.orgName))
      .then(() => {
        dispatch(pushGlobalMessage('Organization name successfully changed.', 'success'));
        dispatch(close());
      })
      .catch((error) => {
        dispatch(pushGlobalMessage(error, 'error'));
        setLoading(false);
      });
  };

  return (
    <div className="EditOrgNameModal">
      <ModalHeader title="Edit organization name" />
      <Form
        onSubmit={handleSubmit(onSubmit)}
        footerActions={
          <>
            <Button onClick={handleCancelClick} variant="secondary">
              Cancel
            </Button>
            <Button disabled={newName === selectedOrgName} loading={loading} type="submit">
              Accept
            </Button>
          </>
        }
      >
        <TextInputField
          label="Enter new organization name"
          hiddenLabel
          id="org-name"
          type="text"
          defaultValue={selectedOrgName}
          {...register('orgName')}
        />
      </Form>
    </div>
  );
};

export default EditOrgNameModal;
