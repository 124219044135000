import { Button, Icon, Loader, Tooltip } from '@holokit/core';
import _classnames from 'clsx';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as alertTypes from '../../common/constants/alertTypes';
import { deactivatedStates, pausedStates } from '../../common/constants/deviceStates';
import { deviceStatusTooltipStrings } from '../../common/constants/deviceStatusTooltipStrings';
import { setActiveAlert, setActivePin } from '../../common/reducers/alerts/actions';
import { createGetActiveAlertByType } from '../../common/reducers/alerts/selectors';
import { getDeviceStatusColor, getDeviceStatusText } from '../../common/utils/deviceStatus';
import scrollTo from '../../common/utils/dom/scrollTo';
import { toByteString } from '../../common/utils/numberFormatter';
import AlertDrawerExpander from './AlertDrawerExpander';
import TacChangeAlertsDrawer from './TacChangeAlertsDrawer';

class AlertsTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerLoading: false,
      textHover: false,
    };
    this.alertsRow = React.createRef();
    this.preventUnfurl = false;
  }

  onDrawerLoad = () => {
    const { alert, setActivePin_ } = this.props;

    this.setState({ isDrawerLoading: false }, () => {
      setTimeout(() => {
        setActivePin_(alert.id, alertTypes.TAC_CHANGE);
      }, 200);
    });
  };

  handleAcknowledgeAlert = (e) => {
    e.stopPropagation();

    const { alert, handleAcknowledgeSingleAlert } = this.props;
    handleAcknowledgeSingleAlert(alert.id);
  };

  handleUnacknowledgeAlert = (e) => {
    e.stopPropagation();

    const { alert, handleUnacknowledgeSingleAlert } = this.props;
    handleUnacknowledgeSingleAlert(alert.id);
  };

  handlePause = (e) => {
    e.stopPropagation();
    const { alert, handlePause } = this.props;
    handlePause(alert.id, alert.deviceid);
  };

  handleResume = (e) => {
    e.stopPropagation();
    const { alert, handleResume } = this.props;
    handleResume(alert.deviceid);
  };

  handleRowClick = (e) => {
    const { activeAlert, alert, setActiveAlert_, setActivePin_, theme } = this.props;

    if (this.preventUnfurl) {
      this.preventUnfurl = false;
    } else if (theme === 'multi-select') {
      this.selectRow(alert);
    } else {
      const isActive = activeAlert.id === alert.id;

      if (isActive) {
        setActiveAlert_(null, alertTypes.TAC_CHANGE);
        setActivePin_(null, alertTypes.TAC_CHANGE);
      } else {
        this.setState({ isDrawerLoading: true });
        this.focusOnAlert(alert.id);

        setTimeout(() => {
          setActiveAlert_(alert.id, alertTypes.TAC_CHANGE);

          // In the case of the row being near the bottom of the table,
          // the height won't be calculated properly
          // until the alert drawer is being revealed.  So call focus
          // again to cover this case.  200 ms because that
          // is the length of the height animation.
          setTimeout(() => this.focusOnAlert(), 200);
        }, 200); // duration of the scrollto animation.
      }
    }

    e.stopPropagation();
  };

  focusOnAlert = () => {
    const { alert, scrollWrap } = this.props;
    const row = document.getElementById(`tac-change-alert-${alert.id}`);
    if (row) {
      const rowHeight = row.clientHeight || row.offsetHeight;
      if (rowHeight) {
        scrollTo(
          row.offsetTop + row.clientHeight - scrollWrap.current.offsetTop - 4,
          // The 4 accommdates the height of the loader, which is a row above the active row
          () => {},
          200,
          scrollWrap.current
        );
      }
    }
  };

  selectRow = () => {
    const { alert, handleSingleSelection } = this.props;
    handleSingleSelection(alert.id);
  };

  handleTextMouseDown = () => {
    window.addEventListener('mouseup', this.handleRowTextDragMouseUp);
  };

  handleRowTextDragMouseUp = () => {
    this.preventUnfurl = true;
    window.removeEventListener('mouseup', this.handleRowTextDragMouseUp);
  };

  handleTextMouseEnter = () => {
    this.setState({ textHover: true });
  };

  handleTextMouseBlur = () => {
    this.setState({ textHover: false });
  };

  render() {
    const { activeAlert, alert, colspan, handleDeactivate, releaseFlags, selectedAlerts, theme } =
      this.props;
    const {
      cur_billing_data_used: usedThisPeriod,
      device_name: deviceName,
      state,
      whencreated,
    } = alert;
    const { isDrawerLoading, textHover } = this.state;

    const date = moment(whencreated, 'YYYY-MM-DD HH:mm:ss');
    const statusColor = getDeviceStatusColor(state, false, releaseFlags); // To Do: add lastSession.active data to alerts API
    const statusText = getDeviceStatusText(state);
    const isActive = activeAlert.id === alert.id;
    const usedLastPeriod = alert.last_billing_data_used;
    const isMultiSelect = theme === 'multi-select';
    const isPaused = pausedStates.includes(state);
    const isDeactivated = deactivatedStates.includes(state);

    return (
      <>
        {isActive && (
          <tr>
            <td className="AlertsTable__loader" colSpan={colspan}>
              <div className="AlertsTable__loader__container">
                <Loader isLoading={isDrawerLoading} />
              </div>
            </td>
          </tr>
        )}
        <tr
          className={_classnames('AlertsTable__row', {
            'AlertsTable__row--acknowledged': alert.acked,
            'AlertsTable__row--active': isActive,
            'AlertsTable__row--text-hover': textHover,
          })}
          onClick={this.handleRowClick}
          ref={this.alertsRow}
          id={`tac-change-alert-${alert.id}`}
          key={alert.id}
        >
          {isMultiSelect && (
            <td className="AlertsTable__cell AlertsTable__cell__select">
              <input
                checked={selectedAlerts.includes(alert.id)}
                id={`select-row-${alert.deviceid}`}
                onClick={this.handleRowClick}
                type="checkbox"
              />
            </td>
          )}
          <td className="AlertsTable__cell">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="AlertsTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseDown={this.handleTextMouseDown}
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
            >
              <div className="AlertsTable__cell__key">{date.format('MMM D')}</div>
              {!alert.acked && (
                <div className="AlertsTable__cell__value">{date.format('h:mm A')} UTC</div>
              )}
            </div>
          </td>
          <td className="AlertsTable__cell AlertsTable__cell--imei_change">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="AlertsTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseDown={this.handleTextMouseDown}
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
            >
              <div className="AlertsTable__cell__key">
                <div className="Orb__wrapper">
                  <Tooltip
                    block
                    content={deviceStatusTooltipStrings[state]}
                    title={`${statusText} Status`}
                  >
                    <div className={`Orb Orb--${statusColor}`} />
                  </Tooltip>
                </div>
                {deviceName}
              </div>
              {!alert.acked && (
                <div className="AlertsTable__cell__value AlertsTable__cell__value--tac-change">
                  {alert.old_model === 'Unknown'
                    ? alert.old_tac
                    : `${alert.old_manufacturer} ${alert.old_model}`}
                  <Icon
                    name="Chevron--double--east"
                    minor="minor"
                    svgProps={{
                      style: { fill: isActive ? '#ffffff' : '#606677' },
                    }}
                  />
                  {alert.new_model === 'Unknown'
                    ? alert.new_tac
                    : `${alert.new_manufacturer} ${alert.new_model}`}
                </div>
              )}
            </div>
          </td>
          <td
            className={_classnames('AlertsTable__cell AlertsTable__cell--usage', {
              'AlertsTable__cell--usage--multi-select': isMultiSelect,
            })}
          >
            <div className="AlertsTable__cell__content">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                className="AlertsTable__cell__text"
                onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
                onMouseDown={this.handleTextMouseDown}
                onMouseEnter={this.handleTextMouseEnter}
                onMouseLeave={this.handleTextMouseBlur}
              >
                <div className="AlertsTable__cell__key">{toByteString(usedThisPeriod, 1)}</div>
                {!alert.acked && (
                  <div className="AlertsTable__cell__value">
                    Last Period {toByteString(usedLastPeriod, 1)}
                  </div>
                )}
              </div>
              {isActive || isMultiSelect ? null : (
                <div className="AlertsTable__cell__hover_cta">
                  {!alert.acked && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div
                      className="AlertsTable__cell__tooltip"
                      onClick={isDeactivated ? (e) => e.stopPropagation() : () => {}}
                    >
                      <Tooltip content={`${isPaused ? 'Resume' : 'Pause'} SIM`} position="top">
                        <Button
                          disabled={isDeactivated}
                          iconLeft={isPaused ? 'Play' : 'Pause'}
                          onClick={isPaused ? this.handleResume : this.handlePause}
                          small
                          type="secondary"
                        />
                      </Tooltip>
                    </div>
                  )}
                  <div className="AlertsTable__cell__tooltip">
                    <Tooltip content={alert.acked ? 'Unacknowledge' : 'Acknowledge'} position="top">
                      <Button
                        iconLeft="Checkmark--double"
                        onClick={
                          alert.acked ? this.handleUnacknowledgeAlert : this.handleAcknowledgeAlert
                        }
                        small
                        type="secondary"
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
              {alert.acked ? (
                <Icon
                  name="Checkmark--double"
                  classes={_classnames('icon-double-check-green', {
                    'icon-double-check-green--multi-select': isMultiSelect,
                  })}
                  svgProps={{ style: { fill: '#0fb068' } }}
                />
              ) : null}
              {!isMultiSelect ? (
                <div className="AlertsTable__arrow">
                  <Icon
                    classes="AlertsTable__arrow__icon AlertsTable__arrow__icon--default"
                    name="Chevron--single--south"
                    size="minor"
                    svgProps={{ style: { fill: '#969fb1' } }}
                  />
                  <Icon
                    classes="AlertsTable__arrow__icon AlertsTable__arrow__icon--hover"
                    name="Chevron--single--south"
                    size="minor"
                    svgProps={{ style: { fill: '#0a1435' } }}
                  />
                  <Icon
                    classes="AlertsTable__arrow__icon AlertsTable__arrow__icon--active"
                    name="Chevron--single--south"
                    size="minor"
                    svgProps={{ style: { fill: '#ffffff' } }}
                  />
                </div>
              ) : null}
            </div>
          </td>
        </tr>
        {isActive && (
          <tr>
            <td colSpan={colspan} className="AlertsDrawer__table-cell">
              <AlertDrawerExpander>
                <TacChangeAlertsDrawer
                  alert={alert}
                  deviceid={alert.deviceid}
                  handleAcknowledgeAlert={this.handleAcknowledgeAlert}
                  handleUnacknowledgeAlert={this.handleUnacknowledgeAlert}
                  handleDeactivate={handleDeactivate}
                  handlePause={this.handlePause}
                  handleResume={this.handleResume}
                  isDeactivated={isDeactivated}
                  isLoading={isDrawerLoading}
                  isOpen={isActive}
                  isPaused={isPaused}
                  onDrawerLoad={this.onDrawerLoad}
                />
              </AlertDrawerExpander>
            </td>
          </tr>
        )}
      </>
    );
  }
}

const createMapStateToProps = () => {
  const getActiveAlert = createGetActiveAlertByType(alertTypes.TAC_CHANGE);

  return (state) => ({
    activeAlert: getActiveAlert(state),
    releaseFlags: state.releaseFlag,
  });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveAlert_: setActiveAlert,
      setActivePin_: setActivePin,
    },
    dispatch
  );

export default connect(createMapStateToProps, mapDispatchToProps)(AlertsTableRow);
