import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, PopoverCover } from '@holokit/core';
import UsageAlertForm from '../../device/DataLimits/UsageAlertsSection/Forms';
import HeadlineToast from '../../common/toasts/HeadlineToast';
import { getDataThreshold, getSingleDevice } from '../../common/reducers/singleDevice/selectors';

function PopoverCoverContent(close, props) {
  const { device, onDataAlertUpdateSuccess } = props;
  return UsageAlertForm({
    close,
    onDataAlertUpdateSuccess,
    device,
  });
}

function PopoverCoverTrigger(open, props) {
  const { isDeactivated } = props;
  return (
    <Button disabled={isDeactivated} iconLeft="Gauge" onClick={open} type="reversed">
      Update Threshold
    </Button>
  );
}

class DeviceUsageUpdateThresholdPopover extends React.Component {
  static onDataAlertUpdateSuccess = () => {
    toast(<HeadlineToast headline="Usage Alert Saved" icon="Checkmark--single" />, {
      position: toast.POSITION.TOP_RIGHT,
      className: 'toastify-content--burnt toastify-content--success',
      autoClose: 10000,
    });
  };

  render() {
    const { device, isDeactivated } = this.props;

    return (
      <PopoverCover
        className="PopoverCover--bottom"
        content={(close) =>
          PopoverCoverContent(close, {
            device,
            onDataAlertUpdateSuccess: DeviceUsageUpdateThresholdPopover.onDataAlertUpdateSuccess,
          })
        }
        selfAligned={false}
        trigger={(open) => PopoverCoverTrigger(open, { isDeactivated })}
      />
    );
  }
}

const createMapStateToProps = () => (state, props) => {
  const singleDevice = getSingleDevice(state, props.deviceid);

  return {
    dataThreshold: getDataThreshold(singleDevice.device, state),
    device: singleDevice.device,
  };
};

export default connect(createMapStateToProps, null)(DeviceUsageUpdateThresholdPopover);
