import { combineReducers } from 'redux';
import formsReducer from '../forms';
import accountReducer from './account/reducer';
import activateReducer from './activate/reducer';
import activationReducer from './activation/reducer';
import adminReducer from './admin/reducer';
import alertsReducer from './alerts/reducer';
import appReducer from './app/reducer';
import appsReducer from './apps/reducer';
import consoleReducer from './console/reducer';
import currentDeviceReducer from './currentDevice/reducer';
import deviceFilterReducer from './deviceFilter/reducer';
import devicesReducer from './devices/reducer';
import drawerReducer from './drawer/reducer';
import logReducer from './log/reducer';
import logFilterReducer from './logFilter/reducer';
import messageReducer from './message/reducer';
import modalReducer from './modal/reducer';
import orgReducer from './organization/reducer';
import poolReducer from './pools/reducer';
import releaseFlagReducer from './releaseFlag/reducer';
import reportsReducer from './reports/reducer';
import searchReducer from './search/reducer';
import singleDeviceReducer from './singleDevice/reducer';
import topicReducer from './topic/reducer';
import usageReducer from './usage/reducer';

// TODO: Any is the only thing we can do here until we add types to redux
const rootReducer = combineReducers<any>({
  account: accountReducer,
  activate: activateReducer,
  activation: activationReducer,
  admin: adminReducer,
  alerts: alertsReducer,
  app: appReducer,
  apps: appsReducer,
  console: consoleReducer,
  currentDevice: currentDeviceReducer,
  devices: devicesReducer,
  deviceFilters: deviceFilterReducer,
  drawer: drawerReducer,
  forms: formsReducer,
  log: logReducer,
  logFilters: logFilterReducer,
  message: messageReducer,
  modals: modalReducer,
  organization: orgReducer,
  pools: poolReducer,
  releaseFlag: releaseFlagReducer,
  reports: reportsReducer,
  search: searchReducer,
  singleDevice: singleDeviceReducer,
  topic: topicReducer,
  usage: usageReducer,
});

export default rootReducer;
