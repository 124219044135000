import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  BMPContactUsButton,
  BMPUpsellPanel,
  FullHeightCalculatorHOC,
  HoloHelmet,
} from 'common-js/components';
import SubMenu from 'devices/components/SubMenu';
import { selectHasActivityHistory } from 'common-js/reducers/releaseFlag/selectors';
import * as Paths from 'common-js/constants/paths';
import SimSubMenu from '../../../../sims/components/SubMenu';
import ActivityHistoryDetailsPanel from './ActivityHistoryDetailsPanel';

function ActivityHistoryDetails({ params: { batchJobId } }) {
  let simInventory;

  if (Paths.pathMatchesWithIdsReplaced(Paths.SIM_ACTIVITY_HISTORY_DETAILS)) {
    simInventory = true;
  }

  const hasActivityHistory = useAppSelector(selectHasActivityHistory);
  // we want the ActivityHistoryDetailsPanel to fill the height, but the BMPUpsellPanel to fit its contents
  const activityHistoryDetailsClassName = `ActivityHistoryDetails${
    hasActivityHistory ? '' : ' ActivityHistoryDetails--shrinkwrap-height'
  }`;

  return (
    <>
      <HoloHelmet title="Activity" />
      {simInventory ? <SimSubMenu /> : <SubMenu />}
      <FullHeightCalculatorHOC accountForDrawer backgroundColor="#F2F3F5">
        <div className="container">
          <div
            className={activityHistoryDetailsClassName}
            data-testid="activity-history-details-wrapper"
          >
            {hasActivityHistory ? (
              <ActivityHistoryDetailsPanel batchJobId={batchJobId} simInventory={simInventory} />
            ) : (
              <BMPUpsellPanel
                classes="ActivityHistoryDetails__panel ActivityHistoryDetails__panel--upsell"
                title="Activity history"
                label="Plus and Pro feature"
                headline="Monitor device history across our network"
                copy="To unlock activity history, upgrade your Hologram plan."
                buttons={
                  <BMPContactUsButton
                    variant="primary"
                    analyticsName="Activity History"
                    flagLevel="lite"
                  />
                }
              />
            )}
          </div>
        </div>
      </FullHeightCalculatorHOC>
    </>
  );
}

export default ActivityHistoryDetails;
