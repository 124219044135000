import useApi from 'common-js/api/useApi';
import { useCallback } from 'react';

const useGetDeviceProfileStatusHistory = () => {
  const apiCall = useApi.call('/links/cellular/:linkId/history');

  return useCallback(
    async (linkId: LinkId) => {
      const { data } = await apiCall({ urlParams: { linkId } });
      return data;
    },
    [apiCall]
  );
};

export default useGetDeviceProfileStatusHistory;
