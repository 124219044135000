import useApi from 'common-js/api/useApi';
import { useCallback } from 'react';
import {
  API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH,
  API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER,
  apiToClientPasswordErrors,
} from 'common-js/constants/setAccountPasswordFormStrings';

type ApiErrorCause = 'userid' | 'code' | 'password' | 'unknown';

export const API_RESPONSE_VERIFY_ACCOUNT_USER_VERIFIED = 'User already verified';
export const API_RESPONSE_VERIFY_ACCOUNT_CODE_EXPIRED = 'Verification code expired';
export const API_RESPONSE_VERIFY_ACCOUNT_CODE_INCORRECT = 'Code is incorrect';
export const API_RESPONSE_VERIFY_ACCOUNT_PASSWORD_NOT_SET = 'Must set a password on account';

// If the API error message should be reformatted, add it to this object
export const apiResponseToMessage: Record<string, string> = {
  [API_RESPONSE_VERIFY_ACCOUNT_CODE_EXPIRED]: 'This verification code has expired.',
  [API_RESPONSE_VERIFY_ACCOUNT_CODE_INCORRECT]: 'We do not recognize the verification code.',
  ...apiToClientPasswordErrors,
};

// Specify which part of the request triggered the API error
const apiResponseToCause: Record<string, ApiErrorCause> = {
  [API_RESPONSE_VERIFY_ACCOUNT_CODE_EXPIRED]: 'code',
  [API_RESPONSE_VERIFY_ACCOUNT_CODE_INCORRECT]: 'code',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_EMAIL_PASSWORD_MATCH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_LETTER]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MAX_LENGTH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_MIN_LENGTH]: 'password',
  [API_RESPONSE_SET_ACCOUNT_PASSWORD_ONE_NUMBER]: 'password',
};

interface ApiErrorResponse {
  success: false;
  error: Partial<Record<ApiErrorCause, string>>;
}

const useVerifyAccount = () => {
  const apiCall = useApi.call('/register/verify', { method: 'POST' });

  return useCallback(
    async ({ userid, code, password }) => {
      try {
        return await apiCall({ body: { userid, code, password } });
      } catch (error) {
        if (apiResponseToMessage[error as string]) {
          // Error message should be formatted for better UI presentation
          const errorResponse: ApiErrorResponse = {
            success: false,
            error: {
              [apiResponseToCause[error as string]]: apiResponseToMessage[error as string],
            },
          };
          return errorResponse;
        }

        throw error; // Error message should be thrown as-is
      }
    },
    [apiCall]
  );
};

export default useVerifyAccount;
