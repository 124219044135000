import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Checkbox } from '@hologram-dimension/checkbox';
import { ComplexIcon, Loader } from '@holokit/core';
import { BUTTON_CLICK, ERROR_RETURN } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { ModalHeader } from 'common-js/components';
import { BILLING_FAQS } from 'common-js/constants/links';
import {
  PAUSE_DATA_BULK_PREVIEW_REQUEST,
  RESUME_DATA_BULK_PREVIEW_REQUEST,
} from 'common-js/reducers/devices/actionTypes';
import { clearSelection, fetchDevices, getTasks } from 'common-js/reducers/devices/actions';
import { TASK_START_DATE } from 'common-js/reducers/devices/reducer';
import { close } from 'common-js/reducers/modal/actions';
import { selectHasActivityHistory } from 'common-js/reducers/releaseFlag/selectors';
import {
  BulkChangeStatePreviewSummary,
  Device,
  DeviceStateChangeAllowedStates,
} from 'common-js/types/Device';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import type {
  useBulkChangeStateEvent,
  useBulkChangeStatePreviewEvent,
} from 'devices/ManageDropdown/useItems/useBulkChangeState';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSelectedLoadedDevices } from 'common-js/reducers/devices/selectors';
import ErrorModal from './devices/ErrorModal';
import LinksSection from './devices/LinksSection';
import LoadingChipsSection from './devices/LoadingChipsSection';
import OverLimitModal from './devices/OverLimitModal';
import Section from './devices/Section';
import SuccessModal from './devices/SuccessModal';

const NUM_CHIPS = 9;
const NUM_CHIPS_SHORT = 3;

// If # devices <= this value, don't show bulk confirmation checkbox
const DEVICE_COUNT_THRESHOLD = 9;

interface BulkPauseResumeModalProps {
  bulkPreview?: BulkChangeStatePreviewSummary;
  actionType: DeviceStateChangeAllowedStates;
  closeModal_: typeof close;
  fetchDevices_: typeof fetchDevices;
  getTasks_: typeof getTasks;
  clearSelection_: typeof clearSelection;
  overLimit?: boolean;
  isLoading: boolean;
  hasActivityHistory: boolean;
  action?: ReturnType<typeof useBulkChangeStateEvent>;
  getPreview?: ReturnType<typeof useBulkChangeStatePreviewEvent>;
  devices?: Array<Device>;
}

interface BulkPauseResumeModalState {
  confirmed: boolean;
  runningAction: boolean;
  success: boolean;
  deviceIdsWontBeModified: Array<DeviceId>;
  error: string;
}

class BulkPauseResumeModal extends React.Component<
  BulkPauseResumeModalProps,
  BulkPauseResumeModalState
> {
  constructor(props: BulkPauseResumeModalProps) {
    super(props);

    this.state = {
      confirmed: false,
      runningAction: false,
      success: false,
      deviceIdsWontBeModified: [],
      error: '',
    };
  }

  componentDidMount(): void {
    const { getPreview } = this.props;

    if (!getPreview) {
      return;
    }

    getPreview()
      .then((result) => {
        this.setState({
          deviceIdsWontBeModified: result.deviceIdsWontBeModified,
        });
      })
      .catch((error) => {
        this.setState({ error: `Preview failed: ${error}` });
      });
  }

  componentDidUpdate(prevProps) {
    const { bulkPreview } = this.props;
    const { error } = bulkPreview ?? {};
    if (error && error !== prevProps.bulkPreview.error) {
      sendAnalyticsEvent({
        type: ERROR_RETURN,
        data: {
          name: 'Bulk Action - Operation Failure',
        },
      });
    }
  }

  confirmAction = () => {
    const { actionType } = this.props;
    const { confirmed } = this.state;
    this.setState((state: any) => ({
      confirmed: !state.confirmed,
    }));
    if (actionType === 'pause' && !confirmed) {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Pause Confirm',
        },
      });
    }
    if (actionType === 'live' && !confirmed) {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Resume Confirm',
        },
      });
    }
  };

  handleBackToDashboardClick = () => {
    const { actionType } = this.props;
    this.closeConfirmationModal();
    if (actionType === 'pause') {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Pause Modal - Back to Dashboard - Close',
        },
      });
    }
    if (actionType === 'live') {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Resume Modal - Back to Dashboard - Close',
        },
      });
    }
  };

  handleCtaClick = () => {
    this.emitAction();
    const { actionType } = this.props;
    if (actionType === 'pause') {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Pause Next CTA',
        },
      });
    }
    if (actionType === 'live') {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Resume Next CTA',
        },
      });
    }
  };

  handleCancelClick = () => {
    const { closeModal_ } = this.props;

    closeModal_();

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Bulk Action - Back to Dashboard - Cancel',
      },
    });
  };

  emitAction = () => {
    const { action, fetchDevices_, getTasks_, clearSelection_, hasActivityHistory } = this.props;
    const { deviceIdsWontBeModified } = this.state;

    if (!action) {
      return;
    }

    this.setState({ runningAction: true });

    action({ deviceIdsWontBeModified }).then(() => {
      clearSelection_();
      // If the user doesn't have activity history, they don't have bulk tasks either
      if (hasActivityHistory) getTasks_(TASK_START_DATE);
      fetchDevices_();
      this.setState({ success: true, runningAction: false });
    });
  };

  closeConfirmationModal = () => {
    const { closeModal_ } = this.props;

    closeModal_();

    const triggerButton = document.querySelector<HTMLButtonElement>(
      '.TaskPopoverWrapper .bp4-popover-target'
    );
    if (triggerButton) {
      triggerButton.click();
    }
  };

  render() {
    const {
      actionType,
      closeModal_,
      overLimit = false,
      devices,
      bulkPreview = {
        error: '',
        validDeviceCount: 0,
        validDevicesSample: [],
        invalidDeviceCount: 0,
        invalidDevicesSample: [],
      },
      isLoading,
    } = this.props;
    const { confirmed, runningAction, success, error } = this.state;
    const {
      error: bulkPreviewError,
      validDeviceCount,
      validDevicesSample,
      invalidDeviceCount,
      invalidDevicesSample,
    } = bulkPreview;

    const capitalizedActionType = actionType === 'pause' ? 'Pause' : 'Resume';

    if (error || bulkPreviewError) {
      return <ErrorModal onClose={closeModal_} />;
    }
    if (success) {
      return (
        <SuccessModal
          title={`${capitalizedActionType} request sent`}
          icon={actionType === 'pause' ? 'Pause' : 'Play'}
          infoWell={`${capitalizedActionType} command has been issued to our carrier partners.`}
          onClose={this.handleBackToDashboardClick}
        >
          <ComplexIcon name="cell-tower" />

          <div className="BulkDataActionModal__success__message">
            You should see the status of your SIMs update within 10 minutes. In rare cases it may
            take up to 24 hours. If you have any questions, please reach out to{' '}
            <a href="mailto:support@hologram.io">support@hologram.io</a>.
          </div>

          <div className="BulkDataActionModal__success__message">
            <span className="BulkDataActionModal__success__message__emph">
              Monthly access fees will still apply while paused.
            </span>{' '}
            Learn more about paused SIMs and data plans in our{' '}
            <a href={BILLING_FAQS} target="_blank" rel="noreferrer">
              billing FAQs
            </a>
            .
          </div>
        </SuccessModal>
      );
    }

    let config: any = {};
    switch (actionType) {
      case 'pause':
        config = {
          modalTitle: 'Pause data',
          modalIcon: 'Pause',
          infoPanel: {
            verb: 'pause',
            fullVerb: 'pause the data',
          },
          invalidLinks: {
            title: `SIMs unable to be paused (${addCommasToNumber(invalidDeviceCount, false)})`,
            warning: `${
              invalidDeviceCount === 1 ? 'This SIM' : 'These SIMs'
            } cannot be paused at this time.`,
          },
          confirmation: {
            title: 'Confirm pausing',
            calloutText:
              'A “Pause” command will be issued to our carrier partners for these selected SIMs. Once paused, SIMs will not be able to use cellular data or send or receive SMS messages.',
            checkboxLabel: `Yes, I want to pause these ${addCommasToNumber(
              validDeviceCount,
              false
            )} SIMs.`,
          },
          actionButtonLabel: `Pause ${
            validDeviceCount === 1 ? '1 SIM' : `${addCommasToNumber(validDeviceCount, false)} SIMs`
          }`,
          overLimit: {
            modalTitle: 'Pause data',
            messageBodyVerb: 'pause',
          },
        };
        break;

      case 'live':
        config = {
          modalTitle: 'Resume data',
          modalIcon: 'Play',
          infoPanel: {
            verb: 'resume',
            fullVerb: 'resume the data',
          },
          invalidLinks: {
            title: `SIMs unable to be resumed (${addCommasToNumber(invalidDeviceCount, false)})`,
            warning: `${
              invalidDeviceCount === 1 ? 'This SIM' : 'These SIMs'
            } cannot be resumed at this time.`,
          },
          confirmation: {
            title: 'Confirm resume',
            calloutText:
              'Your SIMs will be ready for use on our network within 10 minutes. In rare cases it may take up to 24 hours.',
            checkboxLabel: `Yes, I want to resume these ${addCommasToNumber(
              validDeviceCount,
              false
            )} SIMs.`,
          },
          actionButtonLabel: `Resume ${
            validDeviceCount === 1 ? '1 SIM' : `${addCommasToNumber(validDeviceCount, false)} SIMs`
          }`,
          overLimit: {
            modalTitle: 'Resume data',
            messageBodyVerb: 'resume',
          },
        };
        break;

      default:
    }

    let infoPanelText: string;
    if (isLoading) {
      infoPanelText = 'Fetching SIMs...';
    } else if (validDeviceCount === 0) {
      const device = invalidDeviceCount === 1 ? 'this SIM' : 'the selected SIMs';
      infoPanelText = `You cannot ${config.infoPanel.verb} ${device}.`;
    } else {
      const device =
        validDeviceCount === 1 ? 'this SIM' : `${addCommasToNumber(validDeviceCount, false)} SIMs`;
      infoPanelText = `You are about to ${config.infoPanel.fullVerb} for ${device}.`;
    }

    if (overLimit) {
      return (
        <OverLimitModal
          title={config.overLimit.modalTitle}
          icon={config.modalIcon}
          analyticsEvent={{
            type: BUTTON_CLICK,
            data: { name: 'Bulk Action - 10k Modal - X Close' },
          }}
          onClose={closeModal_}
          messageBodyVerb={config.overLimit.messageBodyVerb}
        />
      );
    }

    let pauseResumeAnalyticsEvent:
      | {
          type: typeof BUTTON_CLICK;
          data: { name: string };
        }
      | undefined;
    if (actionType === 'pause') {
      pauseResumeAnalyticsEvent = {
        type: BUTTON_CLICK,
        data: { name: 'Bulk Action - Pause Modal - X Close' },
      };
    } else if (actionType === 'live') {
      pauseResumeAnalyticsEvent = {
        type: BUTTON_CLICK,
        data: { name: 'Bulk Action - Resume Modal - X Close' },
      };
    }

    return (
      <>
        <Loader isLoading={isLoading} />
        <ModalHeader
          title={config.modalTitle}
          icon={config.modalIcon}
          analyticsEvent={pauseResumeAnalyticsEvent}
        />
        <div className="BulkDataActionModal__info-panel">{infoPanelText}</div>
        <div className="BulkDataActionModal__contents">
          {isLoading ? (
            <LoadingChipsSection numChips={NUM_CHIPS} />
          ) : (
            <>
              {invalidDeviceCount > 0 && (
                <LinksSection
                  title={config.invalidLinks.title}
                  warning={config.invalidLinks.warning}
                  devices={
                    devices
                      ?.filter((deviceObj) => invalidDevicesSample.includes(deviceObj.id))
                      .map((deviceObj) => deviceObj.name) ?? invalidDevicesSample
                  }
                  total={invalidDeviceCount}
                  limit={validDeviceCount > 0 ? NUM_CHIPS_SHORT : NUM_CHIPS}
                />
              )}
              {validDeviceCount > 0 && (
                <>
                  <LinksSection
                    title={`Selected SIMs (${addCommasToNumber(validDeviceCount, false)})`}
                    devices={
                      devices
                        ?.filter((deviceObj) => validDevicesSample.includes(deviceObj.id))
                        .map((deviceObj) => deviceObj.name) ?? validDevicesSample
                    }
                    total={validDeviceCount}
                    limit={NUM_CHIPS}
                  />
                  <Section title={config.confirmation.title}>
                    <BannerNotification variant="note">
                      {config.confirmation.calloutText}
                    </BannerNotification>

                    {validDeviceCount > DEVICE_COUNT_THRESHOLD && (
                      <Checkbox
                        className="BulkDataActionModal__checkbox"
                        defaultChecked={confirmed}
                        onChange={() => {
                          this.confirmAction();
                        }}
                        label={config.confirmation.checkboxLabel}
                        name="confirmation"
                      />
                    )}
                  </Section>
                </>
              )}
            </>
          )}
        </div>
        <div className="BulkDataActionModal__footer">
          {!isLoading && (
            <Button onClick={this.handleCancelClick} variant="secondary">
              Cancel
            </Button>
          )}
          {!isLoading && validDeviceCount > 0 && (
            <Button
              onClick={this.handleCtaClick}
              loading={runningAction}
              disabled={!confirmed && validDeviceCount > DEVICE_COUNT_THRESHOLD}
            >
              {config.actionButtonLabel}
            </Button>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isLoading:
    PAUSE_DATA_BULK_PREVIEW_REQUEST in (state.devices?.uiState?.loading ?? {}) ||
    RESUME_DATA_BULK_PREVIEW_REQUEST in (state.devices?.uiState?.loading ?? {}),
  bulkPreview: state.devices?.bulkPreview,
  hasActivityHistory: selectHasActivityHistory(state),
  devices: getSelectedLoadedDevices(state),
});

export default connect(mapStateToProps, (dispatch: any) =>
  bindActionCreators(
    {
      closeModal_: close,
      clearSelection_: clearSelection,
      fetchDevices_: fetchDevices,
      getTasks_: getTasks,
    },
    dispatch
  )
)(BulkPauseResumeModal);
