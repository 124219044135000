import { downloadCSV } from '@hologram-hyper-dashboard/components';

export default function generateCSV(data: Array<Array<string>> = [], filename = 'download.csv') {
  let csvContent = '';

  data.forEach((infoArray, index) => {
    const dataString = infoArray.join(',');
    csvContent += index < data.length ? `${dataString}\n` : dataString;
  });

  downloadCSV({ data: csvContent, filename });
}
