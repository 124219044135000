import useAppSelector from 'common-js/hooks/useAppSelector';
import _classnames from 'clsx';
import { Icon } from '@hologram-dimension/icon';
import {
  getReleaseFlags,
  selectHasSimsPages,
  selectPromoteSimsPages,
} from 'common-js/reducers/releaseFlag/selectors';
import { getDeviceStatusColor } from 'common-js/utils/deviceStatus';
import HighlightMatch from './HighlightMatch';
import KEY_TRANSLATIONS from './keyTranslations';
import getResultLink from './searchUtils';

const translateKey = (key) => KEY_TRANSLATIONS[key] ?? 'Unknown';

const SearchModalResultItem = ({
  active,
  connected,
  handleResultSelect,
  handleRowHover,
  highlights,
  id,
  simCardId,
  lastResult,
  matchedFields,
  rowIndex,
  status,
}) => {
  const releaseFlags = useAppSelector(getReleaseFlags);
  const statusColor = getDeviceStatusColor(status, connected, matchedFields, releaseFlags);

  const hasSimInventoryPage = useAppSelector(selectHasSimsPages);
  const promoteSimInventoryPage = useAppSelector(selectPromoteSimsPages);
  const resultLink = getResultLink(
    { id, simcardid: simCardId },
    hasSimInventoryPage && promoteSimInventoryPage && simCardId
  );

  // Filter out device name and ID and see if we have any other matching fields
  const fields = matchedFields.filter((field) => !['name', 'id'].includes(field.key));
  const hasAdditionalColumn = fields.length > 0;

  return (
    <li
      className={_classnames('SearchModalResultItem', {
        'SearchModalResultItem--active': active,
        'SearchModalResultItem--hasadditionalcolumn': hasAdditionalColumn,
        'SearchModalResultItem--lastResult': lastResult,
      })}
      onMouseEnter={() => handleRowHover(rowIndex)}
      onMouseMove={() => handleRowHover(rowIndex)}
      onMouseLeave={() => handleRowHover(null)}
    >
      <a
        className="Button--unstyled SearchModalResultItem__wrapper"
        onClick={(e) => {
          e.preventDefault();
          handleResultSelect(id);
        }}
        href={resultLink}
      >
        <div className="SearchModalResultItem__left">
          <div className="Orb__wrapper SearchModalResultItem__orb">
            <div className={`Orb Orb--${statusColor}`} data-testid={`result-orb-${statusColor}`} />
          </div>

          <div className="SearchModalResultItem__name" data-testid="result-device-name">
            <HighlightMatch matches={highlights.name} truncate />
          </div>

          <div className="SearchModalResultItem__device-id">
            Device ID: <HighlightMatch matches={highlights.id} />
          </div>

          {hasAdditionalColumn && (
            <div className="SearchModalResultItem__field-matches">
              {fields.map(({ key, showEnabled, showDisabled }) => (
                <div className="SearchModalResultItem__field-match" key={key}>
                  {translateKey(key)}: <HighlightMatch matches={highlights[key]} />
                  {(showEnabled || showDisabled) && (
                    <span className="SearchModalResultItem__active-profile">
                      &nbsp;&middot; {showEnabled ? 'Enabled' : 'Disabled'} profile
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="SearchModalResultItem__right">
          Enter
          <Icon name="Back" size="small" style={{ display: 'inline' }} />
        </div>
      </a>
    </li>
  );
};

export default SearchModalResultItem;
