import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line no-restricted-imports
import { Button, ComplexIcon, Loader } from '@holokit/core';
import _ from 'lodash';
import * as Links from 'common-js/constants/links';
import { setFormFields, flashForm, resetForm } from 'common-js/forms/actions';
import { addSelectedTopic, clearAllAppSelections } from 'common-js/reducers/apps/actions';
import * as Messages from 'common-js/reducers/console/models';
import { minimizeDrawer, revealDrawer } from 'common-js/reducers/drawer/actions';
import { getLogs, unmountLogs, revealLogs, retryLoad } from 'common-js/reducers/log/actions';
import { selectRoutesFlag } from 'common-js/reducers/releaseFlag/selectors';
/* import {
  FILTER_HEIGHT,
  TOPBAR_HEIGHT,
  TOPBAR_HEIGHT_MAX,
} from 'common-js/reducers/console/dimensions'; */
import { OutboundMessage, HeartbeatMessage, ModifierMessage } from '..';

class Console extends React.Component {
  constructor() {
    super();
    this.tableInner = React.createRef();
  }

  componentDidMount() {
    const { pollLogData } = this.props;

    this.setupInfiniteLoading();

    if (pollLogData) this.setUpAutoRefresh();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pollLogData } = this.props;

    if (!pollLogData && nextProps.pollLogData) {
      this.setUpAutoRefresh();
    }

    if (pollLogData && !nextProps.pollLogData) {
      this.teardownAutoRefresh();
    }
  }

  componentWillUnmount() {
    const { unmountLogs_ } = this.props;
    unmountLogs_();
    this.teardownAutoRefresh();
    this.tableInner.current.removeEventListener('resize', this.onTableScroll);
  }

  onTableScroll() {
    const { isLoadingLogs, hasMoreLogs, getLogs_ } = this.props;
    if (this.tableInner) {
      const currentYPosition = this.tableInner.scrollTop + this.tableInner.offsetHeight;
      const { scrollHeight } = this.tableInner;
      const thresholdToLoadMore = 5;

      if (currentYPosition + thresholdToLoadMore > scrollHeight) {
        if (!isLoadingLogs && hasMoreLogs) getLogs_(true);
      }
    }
  }

  setUpAutoRefresh() {
    this.refreshIntervalHandle = window.setInterval(() => {
      const { isLoadingLogs, getLogs_, wasError } = this.props;
      if (!isLoadingLogs && !wasError) getLogs_(false, true);
    }, 10000);
  }

  setupInfiniteLoading() {
    this.onTableScroll = _.throttle(this.onTableScroll.bind(this), 300);
    this.tableInner.current.addEventListener('scroll', this.onTableScroll, false);
  }

  teardownAutoRefresh() {
    window.clearInterval(this.refreshIntervalHandle);
  }

  // eslint-disable-next-line class-methods-use-this
  displayMessage(message, messageProps) {
    switch (message.type) {
      case Messages.OUTBOUND:
        return (
          <OutboundMessage
            key={`consoledisplaymessage${message.logId}`}
            message={message}
            {...messageProps}
          />
        );
      case Messages.HEARTBEAT:
        return (
          <HeartbeatMessage
            key={`consoledisplaymessage${message.logId}`}
            message={message}
            {...messageProps}
          />
        );
      case Messages.MODIFIER:
        return (
          <ModifierMessage
            key={`consoledisplaymessage${message.logId}`}
            message={message}
            {...messageProps}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      messages,
      minimizeDrawer_,
      showFilters,
      addSelectedTopic_,
      clearAllAppSelections_,
      isLoadingLogs,
      isUpdating,
      routesReleaseFlag,
      isContinuing,
      hasMoreLogs,
      revealLogs_,
      builtInTopics,
      revealDrawer_,
      setFormFields_,
      flashForm_,
      resetForm_,
      tags,
      wasError,
      retryLoad_,
    } = this.props;
    // const consoleDimensions = this.getConsoleDimensions();
    const messageProps = {
      routesReleaseFlag,
      minimizeDrawer_,
      addSelectedTopic_,
      clearAllAppSelections_,
      builtInTopics,
      revealDrawer_,
      setFormFields_,
      flashForm_,
      resetForm_,
      tags,
    };

    const isLoading = isLoadingLogs && !isUpdating && !isContinuing;

    const filteredMessages = [];
    const unRevealedMessagesCount = messages.reduce((count, message) => {
      let newCount = count;
      if (message.unRevealed) {
        newCount += 1;
      } else {
        filteredMessages.push(message);
      }
      return newCount;
    }, 0);

    const logsIcon = (
      <svg className="Console__empty-icon" viewBox="0 0 25 20">
        <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <path d="M24 17c0 1.104-.894 2-2 2H3c-1.104 0-2-.896-2-2V3c0-1.104.896-2 2-2h19c1.106 0 2 .896 2 2v14zM2 6h22" />
          <path d="M5 3.5c0 .276-.224.5-.5.5S4 3.776 4 3.5s.224-.5.5-.5.5.224.5.5zM8 3.5c0 .276-.224.5-.5.5S7 3.776 7 3.5s.224-.5.5-.5.5.224.5.5zM11 3.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM1 13h5l2-3 2 5 4-6 3 7 2-3h5" />
        </g>
      </svg>
    );

    return (
      <div
        className={`Console${showFilters ? ' show-filters' : ''}`}
        /* style={{
          height: consoleDimensions.height,
          position: 'fixed',
          top: consoleDimensions.top,
        }} */
        ref={this.tableInner}
      >
        <Loader isLoading={isLoading} />
        {!isLoading && (
          <div className="full-height">
            {wasError && (
              <div className="empty-state">
                <div className="section">
                  {logsIcon}
                  <h2 className="no-margin">An error occurred when trying to load messages</h2>
                  <div className="f2">
                    Please try reloading the messages or apply different filters
                  </div>
                </div>
                <Button onClick={retryLoad_} type="secondary">
                  Reload
                </Button>
              </div>
            )}
            {!wasError &&
              (messages.length > 0 ? (
                <div>
                  {unRevealedMessagesCount > 0 && (
                    <div className="grid-row h-align v-align load-more-panel">
                      <Button onClick={revealLogs_} type="secondary">
                        View {unRevealedMessagesCount} new messages
                      </Button>
                    </div>
                  )}
                  {filteredMessages.map((message) => this.displayMessage(message, messageProps))}
                  {isContinuing && (
                    <div className="grid-row h-align v-align loading-more-panel">
                      <ComplexIcon name="spinner" />
                    </div>
                  )}
                  {!isContinuing && hasMoreLogs && (
                    <div className="grid-row h-align v-align loading-more-panel" />
                  )}
                  {!isContinuing && !hasMoreLogs && (
                    <div className="grid-row h-align v-align loading-more-panel">
                      <div className="f6">All messages loaded for this filter.</div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="empty-state">
                  <div className="section">
                    {logsIcon}
                    <h2 className="no-margin">
                      No messages with these filters have been logged yet.
                    </h2>
                    <div className="f2">
                      Data will be logged whenever a message from your device is sent to the cloud.
                    </div>
                  </div>
                  <a
                    className="a-underline"
                    rel="noreferrer"
                    target="_blank"
                    href={Links.LEARN_SEND_LOG}
                  >
                    Learn how to send messages to the cloud
                  </a>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    messages: state.console.messages,
    routesReleaseFlag: selectRoutesFlag(state),
    isDrawerFullscreen: state.drawer.isFullscreen,
    isLoadingLogs: state.log.isLoadingLogs,
    isContinuing: state.log.isContinuing,
    isUpdating: state.log.isUpdating,
    hasMoreLogs: state.log.hasMoreLogs,
    isFullscreen: state.drawer.isFullscreen,
    builtInTopics: state.topic.builtInTopics,
    tags: state.deviceFilters.tags,
    wasError: state.log.wasError,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        addSelectedTopic_: addSelectedTopic,
        clearAllAppSelections_: clearAllAppSelections,
        flashForm_: flashForm,
        getLogs_: getLogs,
        minimizeDrawer_: minimizeDrawer,
        resetForm_: resetForm,
        revealDrawer_: revealDrawer,
        revealLogs_: revealLogs,
        retryLoad_: retryLoad,
        setFormFields_: setFormFields,
        unmountLogs_: unmountLogs,
      },
      dispatch
    )
)(Console);
