import { openModal } from 'common-js/reducers/modal/actions';
import { useEffect } from 'react';
import useAppDispatch from './useAppDispatch';

interface UseShowOrgIntroModalProps {
  location: RouterLocation;
}

const useShowOrgIntroModal = ({ location }: UseShowOrgIntroModalProps) => {
  const dispatch = useAppDispatch();
  const { fv } = location.query;

  useEffect(() => {
    // Checking if org invite is done
    if (fv === '1') {
      dispatch(openModal('OrgIntroModal', undefined, 'narrow no-style'));
    }
  }, [dispatch, fv]);
};

export default useShowOrgIntroModal;
