import React from 'react';
import Formsy from 'formsy-react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Select, Callout, Icon, Tooltip } from '@holokit/core';
import { getOperatorDisplayName } from 'common-js/utils/operatorDisplayName';
import { ModalHeaderWithIcon } from '../components';
import HeadlineToast from '../toasts/HeadlineToast';

import { testDevice, getDeviceEUICCHistory } from '../reducers/singleDevice/actions';

import { close } from '../reducers/modal/actions';

class KickoffHyperCompatTestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedCycleCount: 1,
      selectedCarrier: this.setCarrierDefault(props.device),
    };
  }

  carrierSelectionLookup = (carrierSelectionOptions) =>
    Object.fromEntries(carrierSelectionOptions.map((selection) => [selection.value, selection]));

  setCarrierDefault = (device) => {
    const { carrierSelectionOptions } = this.props;
    const carrierIdMaps = this.carrierSelectionLookup(carrierSelectionOptions);
    const defaultCarrier = parseInt(carrierIdMaps[device?.carrierid.toString()]?.value, 10);
    if (!defaultCarrier) {
      return parseInt(carrierSelectionOptions[0].value, 10);
    }
    return defaultCarrier;
  };

  getCarrierObjectFromID = (id) => {
    const { carriers } = this.props;
    const carrier = carriers.find((carrierObject) => carrierObject.id === id);
    return carrier;
  };

  // TODO: is this used/necessary?
  // eslint-disable-next-line no-unused-vars
  handleSubmit = (model) => {
    const { getDeviceEUICCHistory_, testDevice_, closeModal, device } = this.props;
    this.setState({ isLoading: true });
    const { selectedCarrier, selectedCycleCount } = this.state;

    testDevice_(device.id, selectedCycleCount, selectedCarrier)
      .then(() => getDeviceEUICCHistory_(device.id))
      .then(closeModal)
      .then(() => {
        toast(
          <HeadlineToast
            headline="Test cycles started"
            body="Testing will take some time to complete. Refresh or return to this page to view the results."
            icon="Checkmark--single"
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toastify-content--burnt toastify-content--success',
            autoClose: 10000,
          }
        );
      })
      .catch((err) => {
        toast(
          <HeadlineToast
            headline="Failed to start test cycles"
            icon="Circle--alert"
            body={`An error occurred: "${err}" Please contact the engineering team.`}
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toastify-content--burnt toastify-content--error',
            autoClose: 10000,
          }
        );
        closeModal();
      });
  };

  handleCancel = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  handleProfileChange = (event) => {
    this.setState({ selectedCarrier: parseInt(event.target.value, 10) });
  };

  handleCycleChange = (event) => {
    this.setState({ selectedCycleCount: parseInt(event.target.value, 10) });
  };

  render() {
    const { isLoading, selectedCarrier, selectedCycleCount } = this.state;
    const { device, carrierSelectionOptions, cycleSelectionOptions } = this.props;
    const activeProfileName = getOperatorDisplayName(device?.sim_number);
    const activeCarrier = this.getCarrierObjectFromID(device?.carrierid);

    return (
      <div className="HyperModal">
        <ModalHeaderWithIcon
          classes="HyperModal__header"
          iconName="Chip"
          title="eUICC Testing Harness"
        />
        <div className="HyperModal__content">
          <Formsy onValidSubmit={this.handleSubmit}>
            <div className="HyperModal__select-label">Select an operational profile to test.</div>
            <div className="HyperModal__select-microcopy">
              Current Bootstrap profile: {activeProfileName}
            </div>
            <div className="HyperModal__select">
              <Select
                options={carrierSelectionOptions.map((carrierOption) => ({
                  ...carrierOption,
                  disabled: carrierOption.value === selectedCarrier.toString(),
                }))}
                onChange={this.handleProfileChange}
                value={selectedCarrier.toString()}
              />
              <Tooltip
                light
                content={
                  <>
                    <div>Current carrier: {activeCarrier?.name}</div>
                    <div>
                      Make/model of device: {device?.manufacturer}/{device?.model}
                    </div>
                  </>
                }
                classes="HyperModal--tooltip"
                title="CURRENT SETTINGS"
              >
                <span>
                  <Icon
                    name="Circle--info"
                    size="minor"
                    svgProps={{ style: { fill: '#8008f7' } }}
                  />
                </span>
              </Tooltip>
            </div>

            <div className="HyperModal__select-label">
              How many test cycles would you like to run?
            </div>
            <div className="HyperModal__select-microcopy">
              Estimated time to completion: {selectedCycleCount * 5}
              &thinsp;&ndash;&thinsp;{selectedCycleCount * 25} minutes
            </div>
            <div className="HyperModal__select">
              <Select
                options={cycleSelectionOptions.map((cycleOption) => ({
                  ...cycleOption,
                  disabled: cycleOption.value === selectedCycleCount.toString(),
                }))}
                onChange={this.handleCycleChange}
                value={selectedCycleCount.toString()}
              />
            </div>
            <Callout
              className="HyperModal__callout"
              text="Test cycles cannot be halted once started. Check back later when the test completes for details."
              type={Callout.TYPE.INFORMATIONAL}
              defaultIcon
            />
            <Button onClick={this.handleCancel} type="secondary">
              Cancel
            </Button>
            <div className="HyperModal__action-button">
              <Button
                buttonProps={{ type: 'submit' }}
                classes="HyperModal__action-button"
                iconLeft="Play"
                type="primary"
                loading={isLoading}
                disabled={isLoading}
              >
                {isLoading ? 'Starting' : 'Start test cycles'}
              </Button>
            </div>
          </Formsy>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  carriers: state.admin?.carriers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal: close,
      testDevice_: testDevice,
      getDeviceEUICCHistory_: getDeviceEUICCHistory,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(KickoffHyperCompatTestModal);
