function updateScrollPosition(amount, element) {
  if (element) {
    // eslint-disable-next-line no-param-reassign
    element.scrollTop = amount;
  } else {
    document.documentElement.scrollTop = amount;
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  }
}

function calculateStartingPosition(element) {
  return element
    ? element.scrollTop
    : document.documentElement.scrollTop ||
        document.body.parentNode.scrollTop ||
        document.body.scrollTop;
}

// Shim layer for requestAnimationFrame with setTimeout fallback.
const requestAnimationFrame = (() =>
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function requestAnimationFrameFallback(callback) {
    window.setTimeout(callback, 1000 / 60);
  })();

// TODO: Move duration to last and update all callers?
// eslint-disable-next-line default-param-last
export default function scrollTo(to, callback, duration = 300, element) {
  let currentTime = 0;
  const start = calculateStartingPosition(element);
  const change = to - start;
  const increment = 20;

  const animateScroll = () => {
    // Increment the time.
    currentTime += increment;

    // Calculate the scroll amount with the quadratic in-out easing function.
    const scrollAmount = Math.easeInOutQuad(currentTime, start, change, duration);

    // Update the body scroll position.
    updateScrollPosition(scrollAmount, element);

    // Perform the animation, unless its over.
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else if (callback && typeof callback === 'function') {
      callback();
    }
  };

  animateScroll();
}

// Easing functions http://goo.gl/5HLl8.
Math.easeInOutQuad = (t, b, c, d) => {
  let newT = t / d / 2;

  if (newT < 1) {
    return (c / 2) * newT * newT + b;
  }

  newT -= 1;

  return (-c / 2) * (newT * (newT - 2) - 1) + b;
};
Math.easeInCubic = (t, b, c, d) => {
  const newT = t / d;
  const tc = newT * newT * newT;
  return b + c * tc;
};
Math.inOutQuintic = (t, b, c, d) => {
  const newT = t / d;
  const ts = newT * newT;
  const tc = ts * newT;

  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
};
