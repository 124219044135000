export const LIVE_PENDING = 'LIVE-PENDING' as const;
export const LIVE = 'LIVE' as const;
export const CONNECTED = 'CONNECTED' as const;
export const INACTIVE = 'INACTIVE' as const;
export const INACTIVE_TESTED = 'INACTIVE-TESTED' as const;
export const PAUSED_PENDING_USER = 'PAUSED-PENDING-USER' as const;
export const PAUSED_USER = 'PAUSED-USER' as const;
export const PAUSED_PENDING_SYS = 'PAUSED-PENDING-SYS' as const;
export const PAUSED_SYS = 'PAUSED-SYS' as const;
export const DEAD_PENDING = 'DEAD-PENDING' as const;
export const DEAD = 'DEAD' as const;

export const REASON_USAGE_LIMIT = 'USAGE-LIMIT' as const;
export const REASON_USAGE_BILLING = 'USAGE-BILLING' as const;
export const REASON_BILLING = 'BILLING' as const;

export const FROZEN = 'FROZEN' as const;

export const TEST_ACTIVATE_PENDING = 'TEST-ACTIVATE-PENDING' as const;
export const TEST_ACTIVATE = 'TEST-ACTIVATE' as const;
export const TEST_HIBERNATE = 'TEST-HIBERNATE' as const;
export const TEST_HIBERNATE_PENDING = 'TEST-HIBERNATE-PENDING' as const;
export const TEST_AUTOACTIVATE_PENDING = 'TEST-AUTOACTIVATE-PENDING' as const;
export const TEST_AUTOACTIVATE = 'TEST-AUTOACTIVATE' as const;

export const EUICC_AUDIT_COMPLETE = 'AUDIT_COMPLETE' as const;
export const EUICC_AUDIT_PENDING = 'AUDIT_PENDING' as const;
export const EUICC_AVAILABLE = 'AVAILABLE' as const;
export const EUICC_COMPLETE = 'COMPLETE' as const;
export const EUICC_DELETED = 'DELETED' as const;
export const EUICC_DELETE_PENDING = 'DELETE_PENDING' as const;
export const EUICC_DISABLED = 'DISABLED' as const;
export const EUICC_DISABLE_PENDING = 'DISABLE_PENDING' as const;
export const EUICC_DOWNLOADED = 'DOWNLOADED' as const;
export const EUICC_DOWNLOAD_PENDING = 'DOWNLOAD_PENDING' as const;
export const EUICC_ENABLED = 'ENABLED' as const;
export const EUICC_ENABLE_PENDING = 'ENABLE_PENDING' as const;
export const EUICC_ERROR = 'ERROR' as const;
export const EUICC_RUNNING = 'RUNNING' as const;
export const EUICC_STARTED = 'STARTED' as const;
export const EUICC_TEST_REQUESTED = 'TEST_REQUESTED' as const;

export const EUICC_AUDIT = 'EUICC_AUDIT' as const;
export const EUICC_OPERATION = 'EUICC_OPERATION' as const;
export const EUICC_ROLLBACK = 'EUICC_ROLLBACK' as const;
export const EUICC_TEST = 'EUICC_TEST' as const;

export const activeStates = [
  LIVE_PENDING,
  LIVE,
  CONNECTED,
  PAUSED_PENDING_USER,
  PAUSED_USER,
  PAUSED_PENDING_SYS,
  PAUSED_SYS,
] as const;

export const deactivatedStates = [DEAD_PENDING, DEAD] as const;

export const preflightStates = [
  TEST_ACTIVATE_PENDING,
  TEST_ACTIVATE,
  TEST_HIBERNATE,
  TEST_HIBERNATE_PENDING,
  TEST_AUTOACTIVATE_PENDING,
  TEST_AUTOACTIVATE,
  INACTIVE,
  INACTIVE_TESTED,
] as const;

export const pausedStates = [
  PAUSED_PENDING_USER,
  PAUSED_USER,
  PAUSED_PENDING_SYS,
  PAUSED_SYS,
] as const;

export const eUICCStates = [
  EUICC_AUDIT_COMPLETE,
  EUICC_AUDIT_PENDING,
  EUICC_AVAILABLE,
  EUICC_COMPLETE,
  EUICC_DELETED,
  EUICC_DELETE_PENDING,
  EUICC_DISABLED,
  EUICC_DISABLE_PENDING,
  EUICC_DOWNLOADED,
  EUICC_DOWNLOAD_PENDING,
  EUICC_ENABLED,
  EUICC_ENABLE_PENDING,
  EUICC_ERROR,
  EUICC_RUNNING,
  EUICC_STARTED,
  EUICC_TEST_REQUESTED,
] as const;

export const homeStates = [
  CONNECTED,
  LIVE,
  PAUSED_USER,
  PAUSED_SYS,
  INACTIVE,
  INACTIVE_TESTED,
  TEST_ACTIVATE,
  TEST_AUTOACTIVATE,
  TEST_HIBERNATE,
] as const;

export const eUICCActiveStates = eUICCStates.filter(
  (s) => s.endsWith('_PENDING') || ['RUNNING', 'STARTED', 'TEST_REQUESTED'].includes(s)
);

export const eUICCActions = [EUICC_AUDIT, EUICC_OPERATION, EUICC_ROLLBACK, EUICC_TEST] as const;

// Create a distinct array of all states from the above lists
export const allStates = [
  ...new Set([
    ...activeStates,
    ...deactivatedStates,
    ...preflightStates,
    ...pausedStates,
    ...eUICCStates,
    ...homeStates,
    FROZEN,
  ]),
] as const;

export const allStatusReasons = [REASON_USAGE_LIMIT, REASON_USAGE_BILLING, REASON_BILLING];

// This creates a union of all the values in the allStates array
export type DeviceState = GetElementReadonlyType<typeof allStates>;

export type DeviceStatusReason = GetElementReadonlyType<typeof allStatusReasons>;
