import { Button, ComplexIcon, Icon } from '@holokit/core';
import classNames from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { MessagePanel } from '../components';
import { Select, StellarInput } from '../components/form';
import * as Paths from '../constants/paths';
import { getUserPermissions } from '../reducers/organization/selectors';
import * as singleDeviceActions from '../reducers/singleDevice/actions';
import FormHOC from './FormHOC';
import { formGenerator } from './formGenerator';

class PurchasePhoneNumberForm extends React.Component {
  getBillingAddressMarkup = (hasBillingPermissions, billingMismatch) => {
    const { billingAddress, close, isLoadingPriceData, loadingPriceError } = this.props;
    const hasBillingAddress = billingAddress && !!billingAddress.country;
    let billingAddressContent;

    // if there is an error with phone numbers, don't show the billing address
    if (loadingPriceError || isLoadingPriceData) return null;

    if (hasBillingPermissions) {
      if (!hasBillingAddress) {
        // the user has billing permission, however there is no billing
        // address set
        billingAddressContent = (
          <div className="grid-item-3">
            <div className="error-banner">
              <Icon
                classes="error-banner-icon"
                name="Circle--alert"
                size="minor"
                svgProps={{ style: { fill: '#ffffff' } }}
              />
              Billing address required
            </div>
            <div className="help-text">
              A billing address is required to purchase a phone number. Add a billing address in{' '}
              <Link to={Paths.withContext(Paths.BILLING_ACCOUNT)} onClick={close}>
                Billing settings.
              </Link>
            </div>
          </div>
        );
      } else if (billingMismatch) {
        // the user has billing permissions, but the billing address and the phone number country don't line up
        billingAddressContent = (
          <div className="grid-item-3">
            <div className="error-banner">
              <Icon
                classes="error-banner-icon"
                name="Circle--alert"
                size="minor"
                svgProps={{ style: { fill: '#ffffff' } }}
              />
              Billing address country mismatch
            </div>
            <div className="address">
              {billingAddress.line1} {billingAddress.line2}
            </div>
            <div className="address">
              {billingAddress.city}, {billingAddress.state} {billingAddress.zip}
            </div>
            <div className="address">{billingAddress.country}</div>
            <div className="help-text">
              Due to local regulations, your billing address must be located in the same country as
              the requested phone number&apos;s country of origin.
            </div>
            <div className="help-text">
              Change your billing address in{' '}
              <Link to={Paths.withContext(Paths.BILLING_ACCOUNT)} onClick={close}>
                Billing settings.
              </Link>
            </div>
            <div className="help-text">
              For assistance with international numbers, please email{' '}
              <a href="mailto:success@hologram.io">success@hologram.io.</a>
            </div>
          </div>
        );
      } else {
        // the user has billing permission and all is good with the billing
        // address for phone number purchase
        billingAddressContent = (
          <div className="grid-item-3">
            <div className="address">
              {billingAddress.line1} {billingAddress.line2}
            </div>
            <div className="address">
              {billingAddress.city}, {billingAddress.state} {billingAddress.zip}
            </div>
            <div className="address">{billingAddress.country}</div>
          </div>
        );
      }
      // the user does not have billing permissions, and there is no billing address set
    } else if (!hasBillingAddress) {
      billingAddressContent = (
        <div className="grid-item-3">
          <div className="error-banner">
            <Icon
              classes="error-banner-icon"
              name="Circle--alert"
              size="minor"
              svgProps={{ style: { fill: '#ffffff' } }}
            />
            Billing address required
          </div>
          <div className="help-text">
            Contact your organization&apos;s owner or administrator to have a billing address set.
          </div>
        </div>
      );
    } else if (billingMismatch) {
      // the user doesn't have billing permission and the country is wrong
      billingAddressContent = (
        <div className="grid-item-3">
          <div className="error-banner">
            <Icon
              classes="error-banner-icon"
              name="Circle--alert"
              size="minor"
              svgProps={{ style: { fill: '#ffffff' } }}
            />
            Billing address country mismatch
          </div>
          <div className="help-text">
            Due to local regulations, your billing address must be located in the same country as
            the requested phone number&apos;s country of origin.
          </div>
          <div className="help-text">
            Contact your organization&apos;s owner or administrator to have the billling address
            changed.
          </div>
        </div>
      );
    } else {
      // the user does not have billing permissions, but everything is set correctly,
      // so show nothing.
      return null;
    }

    return (
      <div className="form-row grid-row">
        <div className="label-text grid-item-2">Billing address</div>
        {billingAddressContent}
      </div>
    );
  };

  onCountryChange = (e) => {
    const { sendCustomFormAction, deviceId, getPhoneNumberCost, country } = this.props;

    sendCustomFormAction({
      type: 'PPNF_LOADING_COST_REQUEST',
    });

    getPhoneNumberCost(deviceId, e.currentTarget.value)
      .then((data) => {
        sendCustomFormAction({
          type: 'PPNF_LOADING_COST_SUCCESS',
          cost: data.total_cost,
        });
      })
      .catch((error) => {
        sendCustomFormAction({
          type: 'PPNF_LOADING_COST_ERROR',
          error,
        });
      });

    country.onChange(e);
  };

  render() {
    const {
      areaCode,
      balance,
      billingAddress,
      cost,
      country,
      form,
      formProcessing = false,
      hasLoadedPricingData,
      isLoadingPriceData,
      loadingPriceError,
      onCancelClick,
      onFormSubmit,
      serverError = null,
      showValidationErrors = false,
      userPermissions,
      countries,
    } = this.props;

    const hasBillingAddressSet = billingAddress && !!billingAddress.country;
    const hasBillingPermissions = userPermissions.includes('billing');
    const hasCountryValue = country.value !== '';
    const billingMismatch =
      hasCountryValue && hasBillingAddressSet && billingAddress.country !== country.value;
    const hasError = !hasBillingAddressSet || !hasLoadedPricingData || billingMismatch;

    const countryClassnames = classNames('form-row', 'grid-row', {
      'no-border no-margin': country.value === 'US',
    });

    let pricingDataElement;
    if (hasLoadedPricingData) {
      pricingDataElement = (
        <div className="form-text number">
          <div>
            <p>Phone number</p>
            <p>Account balance: ${balance.currentBalance}</p>
          </div>
          <div>${cost}/Month</div>
        </div>
      );
    } else if (isLoadingPriceData) {
      pricingDataElement = (
        <div className="form-text number">
          <ComplexIcon name="spinner" />
        </div>
      );
    } else if (loadingPriceError) {
      pricingDataElement = <div className="form-text number">{loadingPriceError}</div>;
    } else {
      pricingDataElement = (
        <div className="form-text number">Select a country to view the price.</div>
      );
    }

    return (
      <form className="form form-horizontal" onSubmit={onFormSubmit} noValidate>
        <div className={countryClassnames}>
          <label htmlFor="country">
            <div className="label-text grid-item">Country</div>
            <Select
              {...country}
              error={form.country.error}
              name="country"
              onChange={this.onCountryChange}
              showValidation={showValidationErrors}
              wrapperClassNames="grid-item-3"
            >
              {[{ code: '', name: 'Select country' }, ...countries].map((countryOpt, i) => (
                <option key={countryOpt.code ?? i} value={countryOpt.code}>
                  {countryOpt.name}
                </option>
              ))}
            </Select>
          </label>
        </div>
        {country.value === 'US' && (
          <div className="form-row">
            <label htmlFor="areaCode">
              <div className="label-text f5 grid-item">
                Area code
                <div className="optional">optional</div>
              </div>
              <StellarInput
                {...areaCode}
                type="text"
                name="areaCode"
                error={form.areaCode.error}
                showValidation={showValidationErrors}
                classNames="grid-item-3"
              />
            </label>
          </div>
        )}
        {this.getBillingAddressMarkup(hasBillingPermissions, billingMismatch)}
        <div className="form-row grid-row price">
          <div className="label-text">Price</div>
          <div className="grid-item-3">
            {pricingDataElement}
            {serverError && (
              <MessagePanel fullWidth messageType="error">
                {serverError}
              </MessagePanel>
            )}
            <div className="form-btns">
              <Button
                disabled={hasError}
                loading={formProcessing}
                buttonProps={{ type: 'submit' }}
                type="primary"
              >
                Purchase phone number
              </Button>
              <Button onClick={onCancelClick} type="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

PurchasePhoneNumberForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  country: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  areaCode: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  formProcessing: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  serverError: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string, name: PropTypes.string })),
};

export const config = formGenerator({
  form: 'PurchasePhoneNumberForm',

  fields: ['country', 'areaCode'],

  validators: {},
  /* eslint-disable no-param-reassign */
  customReducers: (state, action) => {
    switch (action.type) {
      case 'PPNF_LOADING_COST_REQUEST':
        state.isLoadingPriceData = true;
        state.loadingPriceError = null;
        state.cost = null;
        break;
      case 'PPNF_LOADING_COST_SUCCESS':
        state.isLoadingPriceData = false;
        state.hasLoadedPricingData = true;
        state.cost = action.cost;
        break;
      case 'PPNF_LOADING_COST_ERROR':
        state.isLoadingPriceData = false;
        state.hasLoadedPricingData = false;
        state.loadingPriceError = action.error;
        break;
      case 'PURCHASEPHONENUMBERFORM_RESET':
        state.cost = '';
        state.hasLoadedPricingData = false;
        state.isLoadingPriceData = false;
        state.loadingPriceError = null;
        break;
      default:
      // Nop
    }
    /* eslint-enable no-param-reassign */

    return state;
  },

  onSubmit(form, actionTypes, dispatch, state, HOC) {
    return HOC.props
      .addPhoneNumber(
        HOC.props.deviceId,
        form.country.value === 'US' ? form.areaCode.value : '',
        form.country.value
      )
      .then(() => {
        dispatch({
          type: actionTypes.SUCCESS,
        });

        dispatch({
          type: actionTypes.RESET,
        });

        bindActionCreators({ getDevice: singleDeviceActions.getDevice }, dispatch).getDevice(
          HOC.props.deviceId
        );
      })
      .catch((errorMessage) => {
        dispatch({
          type: actionTypes.ERROR,
          errorMessage,
        });

        return Promise.reject(errorMessage);
      });
  },
});

const mapStateToProps = (state) => ({
  balance: state.organization.balance,
  billingAddress: state.organization.billingInformation.billingAddress,
  userPermissions: getUserPermissions(state),
});

const PurchasePhoneNumberFormHoC = connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      addPhoneNumber: singleDeviceActions.addPhoneNumber,
      getPhoneNumberCost: singleDeviceActions.getPhoneNumberCost,
      sendCustomFormAction: singleDeviceActions.sendCustomFormAction,
    },
    dispatch
  )
)(FormHOC(PurchasePhoneNumberForm, config));

export default PurchasePhoneNumberFormHoC;
