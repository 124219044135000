import _ from 'lodash';

import * as actionTypes from './actionTypes';
import * as API from '../../api';
import { AppModel, LogFilterModel } from '../../models';
import { getUserContextData } from '../../api/util';
import { mergeAppsAndSchemas } from './selectors';

export function setSearchBy(searchBy) {
  return {
    type: actionTypes.SET_SEARCHBY,
    searchBy,
  };
}

export function setSortBy(sortBy) {
  return {
    type: actionTypes.SET_SORTBY,
    sortBy,
  };
}

export function setViewmode(viewMode) {
  return {
    type: actionTypes.SET_VIEWMODE,
    viewMode,
  };
}

export function setMatchAllTopics(matchAllTopics) {
  return {
    type: actionTypes.SET_MATCH_ALL_TOPICS,
    matchAllTopics,
  };
}

export function getApps() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_APPS_REQUEST });

    return API.getApps(getUserContextData(state))
      .then((apps) => {
        // // let's merge these into an appChain!!!
        const appChains = [];

        apps.forEach((app) => {
          const userProps = JSON.parse(app.userproperties);
          const actionChainId = userProps.action_chain_id;
          const actionChainIndex = userProps.action_chain_index;

          if (actionChainId) {
            const existingActionChain = _.find(appChains, {
              id: actionChainId,
            });

            if (existingActionChain) {
              existingActionChain.apps.push({ ...app, actionChainIndex });
            } else {
              appChains.push({
                id: actionChainId,
                nickname: app.nickname,
                apps: [{ ...app, actionChainIndex }],
              });
            }
          } else {
            appChains.push({
              id: actionChainId,
              nickname: app.nickname,
              apps: [app],
            });
          }
        });

        // sort by chain index because some components require that they are ordered correctly
        appChains.forEach((appChain) => {
          appChain.apps.sort((a, b) => (a.actionChainIndex < b.actionChainIndex ? -1 : 1));
        });

        dispatch({
          type: actionTypes.GET_APPS_SUCCESS,
          apps: apps.map((app) => new AppModel(app)),
          appChains,
        });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_APPS_ERROR, error });

        return Promise.reject(error);
      });
  };
}

export function getAllAppData() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_ALL_APP_DATA_REQUEST });

    return Promise.all([getApps()(dispatch, state), getAppSchemas()(dispatch, state)])
      .then(() => {
        dispatch({
          type: actionTypes.GET_ALL_APP_DATA_SUCCESS,
          mergedApps: mergeAppsAndSchemas(state()),
        });
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_APPS_ERROR, error });
        return Promise.reject(error);
      });
  };
}

export function addApp(appChain, createEnabled = true) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.ADD_APP_REQUEST,
    });

    return API.addApp(appChain, getUserContextData(state))
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_APP_SUCCESS,
        });

        if (!createEnabled) {
          return disableApp(response.appChain.map((app) => app.id))(dispatch, state);
        }
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_APP_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function editApp(appChain) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.EDIT_APP_REQUEST,
    });

    return API.editApp(appChain, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.EDIT_APP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_APP_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function deleteApp(appChainIds) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.DELETE_APP_REQUEST,
    });

    return API.deleteApp(appChainIds, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.DELETE_APP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_APP_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function getAppSchemas() {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_APP_SCHEMAS_REQUEST,
    });

    return API.getAppSchemas(getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_APP_SCHEMAS_SUCCESS,
          appSchemas: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APP_SCHEMAS_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function enableApp(appIds) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.ENABLE_APP_REQUEST,
      appIds,
    });

    return API.enableApp(appIds, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.ENABLE_APP_SUCCESS,
        });

        return getApps()(dispatch, state);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ENABLE_APP_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function disableApp(appIds) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.DISABLE_APP_REQUEST,
    });

    return API.disableApp(appIds, getUserContextData(state))
      .then(() => {
        dispatch({
          type: actionTypes.DISABLE_APP_SUCCESS,
        });

        return getApps()(dispatch, state);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DISABLE_APP_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function loadAppChain(actionAppId) {
  return {
    type: actionTypes.LOAD_APPCHAIN,
    actionAppId,
  };
}

export function addOperationApp(indexOfOperationApp, operationApp) {
  return {
    type: actionTypes.ADD_OPERATION,
    operationApp,
    indexOfOperationApp,
  };
}

export function updateOperationApp(indexOfOperationApp, operationApp) {
  return {
    type: actionTypes.UPDATE_OPERATION,
    operationApp,
    indexOfOperationApp,
  };
}

export function removeOperationApp(indexOfOperationApp) {
  return {
    type: actionTypes.REMOVE_OPERATION,
    indexOfOperationApp,
  };
}

export function addSelectedTopic(selectedTopic) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.ADD_SELECTED_TOPIC,
      selectedTopic,
    });

    dispatch({ type: actionTypes.UPDATE_PREVIEWED_TOPICS_REQUEST });
    getPreviewLogs(dispatch, state);
  };
}

export function removeSelectedTopic(selectedTopic) {
  return {
    type: actionTypes.REMOVE_SELECTED_TOPIC,
    selectedTopic,
  };
}

export function addActionApp(actionApp) {
  return {
    type: actionTypes.ADD_ACTION,
    actionApp,
  };
}

export function udpateActionApp(actionApp) {
  return {
    type: actionTypes.UPDATE_ACTION,
    actionApp,
  };
}

export function removeActionApp(actionApp) {
  return {
    type: actionTypes.REMOVE_ACTION,
    actionApp,
  };
}

export function clearAllAppSelections() {
  return {
    type: actionTypes.CLEAR_ALL_SELECTIONS,
  };
}

export function updateChainChangeValues(appChainValues, nickname) {
  return {
    type: actionTypes.UPDATE_CHAIN_VALUES,
    appChainValues,
    nickname,
  };
}

function _getPreviewLogs(dispatch, store) {
  let { topicPreviews, loadedTopicPreview } = store().apps;
  const topics = store().apps.selectedTopics;
  const topicsToGetPreview = topics.filter(
    (topic) => loadedTopicPreview.indexOf(topic.name) === -1
  );

  // build promises for the un-cached preview responses
  const promises = topicsToGetPreview.map((topic) =>
    API.getLogs(new LogFilterModel({ searchQuery: topic.name }), 2, 0, getUserContextData(store))
  );

  Promise.all(promises)
    .then((responses) => {
      responses.forEach((response, idx) => {
        loadedTopicPreview.push(topicsToGetPreview[idx].name);
        topicPreviews = topicPreviews.concat(
          response.logs.map((log) => ({
            ...log,
            previewForTopic: topicsToGetPreview[idx].name,
          }))
        );
      });

      dispatch({
        type: actionTypes.UPDATE_PREVIEWED_TOPICS_SUCCESS,
        loadedTopicPreview,
        topicPreviews,
      });
    })
    .catch((error) => {
      dispatch({ type: actionTypes.UPDATE_PREVIEWED_TOPICS_ERROR, error });
    });
}

var getPreviewLogs = _.debounce(_getPreviewLogs, 100);
