import { DEVICES_DEVICES } from 'common-js/constants/paths';
import queryString from 'query-string';
import { browserHistory } from 'react-router';
import { CHANGE_TAB, SET_LOCKED, SET_TAKEOVER, VIEW_FILTERS } from './actionTypes';

export const changeTab = (tab) => ({
  type: CHANGE_TAB,
  tab,
});

export const changeViewFilters = (viewFilters) => ({
  type: VIEW_FILTERS,
  viewFilters,
});

export const setDrawerTakeover = (drawerTakeover, returnLink = DEVICES_DEVICES) => ({
  type: SET_TAKEOVER,
  drawerTakeover,
  returnLink,
});

export function drawerTakerOverLink(link, returnLink = DEVICES_DEVICES) {
  return (dispatch) => {
    dispatch({
      type: SET_TAKEOVER,
      drawerTakeover: true,
      returnLink,
    });

    setTimeout(() => browserHistory.push(link), 500);
  };
}

export function fullscreenDrawer(updateQuerystring = true) {
  return (dispatch) => {
    const { location } = window;

    const query = queryString.parse(location.search);
    query.drawer = 'full';
    if (updateQuerystring) {
      browserHistory.push(`${location.pathname}?${queryString.stringify(query)}`);
    } else {
      dispatch({
        type: 'DRAWER_HASH_CHANGE',
        hash: { drawer: 'full' },
      });
    }
  };
}

export function minimizeDrawer(updateQuerystring = true) {
  return () => {
    const { location } = window;
    const query = queryString.parse(location.search);
    delete query.drawer;
    if (updateQuerystring) {
      browserHistory.push(`${location.pathname}?${queryString.stringify(query)}`);
    }
  };
}

export function revealDrawer(updateQuerystring = true) {
  return () => {
    const { location } = window;

    const query = queryString.parse(location.search);
    query.drawer = 'revealed';
    if (updateQuerystring) {
      browserHistory.push(`${location.pathname}?${queryString.stringify(query)}`);
    }
  };
}

export const setLocked = (isLocked) => ({
  type: SET_LOCKED,
  isLocked,
});
