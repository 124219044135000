import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Panel } from '@hologram-dimension/panel';
import analyticsEventBuilder from 'common-js/analytics';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  selectAllowedRegions,
  selectAllowedStandardPlans,
  selectChangePlanLinks,
} from 'common-js/reducers/activation/selectors';
import type { Device } from 'common-js/types/Device';
import type { Plan } from 'common-js/types/Plan';
import { useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useCarriersForSelectedSims } from '../common/hooks';
import RegionCard, { RegionOnChange } from './RegionCard';

interface RegionListProps {
  regions: Array<Region>;
  selectedRegionId: RegionId;
  onChange: RegionOnChange;
  isOnboarding?: boolean;
  disabledRegionId?: RegionId;
  changingPlan: boolean;
}
const RegionList = ({
  regions,
  selectedRegionId,
  onChange,
  isOnboarding,
  disabledRegionId,
  changingPlan,
}: RegionListProps) => (
  <fieldset aria-label="Select a coverage area" className="Region__fieldset">
    {regions.map(
      (region) =>
        region && (
          <RegionCard
            displayName={region.display_name}
            description={region.description}
            key={region.id}
            id={region.id}
            selected={selectedRegionId === region.id}
            onChange={onChange}
            isOnboarding={isOnboarding}
            disabled={disabledRegionId === region.id}
            changingPlan={changingPlan}
          />
        )
    )}
  </fieldset>
);

interface EmptyRegionListProps {
  goToPrevStep?: () => void;
  isOnboarding: boolean;
  changingPlan: boolean;
}

const EmptyRegionList = ({ goToPrevStep, isOnboarding, changingPlan }: EmptyRegionListProps) => {
  const onboardingText = isOnboarding ? '1st Time' : 'Returning';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';
  const handleContactSupportClick = useCallback(() => {
    analyticsEventBuilder
      .buttonClick(analyticsTitle, changingPlan ? undefined : onboardingText, 'Contact Support')
      .send();
  }, [analyticsTitle, changingPlan, onboardingText]);

  return (
    <div className="Region__emptyPanel">
      <Icon name="Warning" size="large" className="Region__warningIcon" />
      <p className="Region__heading">No coverage areas found.</p>
      <p className="Region__description">
        There are no compatible regions based on the SIMs that were added.
        <br />
        Please contact us for additional support.
      </p>
      <div className="Region__buttonContainer">
        <Button variant="secondary" onClick={goToPrevStep}>
          Go back
        </Button>
        <Button
          iconEnd="ExternalLink"
          href="https://support.hologram.io/hc/en-us"
          target="_blank"
          rel="noreferrer"
          onClick={handleContactSupportClick}
        >
          Contact support
        </Button>
      </div>
    </div>
  );
};

interface RegionProps extends StepProps {}

const Region = ({ goToPrevStep, isOnboarding = false, changingPlan = false }: RegionProps) => {
  const { getValues } = useFormContext();
  const { sims } = getValues();
  const changePlanLinks = useAppSelector(selectChangePlanLinks);
  const { fetchingCarriers, error, carrierIds } = useCarriersForSelectedSims(sims, changePlanLinks);
  const regions = useAppSelector((state) => selectAllowedRegions(state, carrierIds));

  const devices: Array<Device> = useAppSelector((state) => state.activation?.changePlan?.devices);
  const existingPlanId = (changingPlan && devices && devices[0] && devices[0].planId) || undefined;

  const filteredPlansBySims: ReturnType<typeof selectAllowedStandardPlans> = useAppSelector(
    (state) => selectAllowedStandardPlans(state, { carrierIds })
  );

  const disabledRegionId: RegionId | undefined = useMemo(() => {
    if (changingPlan && existingPlanId && !fetchingCarriers) {
      const plansByRegion = filteredPlansBySims
        .filter((plan) => plan.id !== existingPlanId && plan.cellularCoverageRegion?.id)
        .reduce<Record<RegionId, Array<Plan>>>((acc, plan) => {
          const regionId = plan.cellularCoverageRegion!.id;

          if (!acc[regionId]) {
            acc[regionId] = [];
          }
          acc[regionId].push(plan);

          return acc;
        }, []);

      return regions.find((region) => !plansByRegion[region.id])?.id;
    }
    return undefined;
  }, [changingPlan, existingPlanId, fetchingCarriers, filteredPlansBySims, regions]);

  // Our form values
  const {
    field: { value: selectedRegion, onChange },
  } = useController({ name: 'region.selected' });

  const hasRegions = useMemo(
    () => regions?.length > 0 && !fetchingCarriers,
    [fetchingCarriers, regions]
  );
  const onboardingText = isOnboarding ? '1st Time' : 'Returning';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';

  const handleContactSupportClick = useCallback(() => {
    analyticsEventBuilder
      .buttonClick(analyticsTitle, changingPlan ? undefined : onboardingText, 'Contact Support')
      .send();
  }, [analyticsTitle, changingPlan, onboardingText]);

  return (
    <div className="Region__wrapper">
      <Panel isLoading={fetchingCarriers}>
        {error && <BannerNotification variant="system-error">{error}</BannerNotification>}
        {!fetchingCarriers && hasRegions && (
          <RegionList
            regions={regions}
            selectedRegionId={selectedRegion?.id}
            onChange={onChange}
            isOnboarding={isOnboarding}
            disabledRegionId={disabledRegionId}
            changingPlan={changingPlan}
          />
        )}
        {!fetchingCarriers && !hasRegions && (
          <EmptyRegionList
            goToPrevStep={goToPrevStep}
            isOnboarding={isOnboarding}
            changingPlan={changingPlan}
          />
        )}
        {fetchingCarriers && <div className="LoadingPanelBody">Loading coverage areas...</div>}
      </Panel>
      <BannerNotification variant="note" className="Region__footer">
        Not seeing the region you&apos;re looking for?{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://support.hologram.io/hc/en-us"
          onClick={handleContactSupportClick}
        >
          Contact support
        </a>
      </BannerNotification>
    </div>
  );
};

export default Region;
