import { Panel } from '@holokit/core';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { FullHeightCalculatorHOC, HoloHelmet } from 'common-js/components';
import * as Paths from 'common-js/constants/paths';
import OrgCreationForm from 'common-js/forms/org/OrgCreationForm';
import OrgInviteCollaboratorsForm from 'common-js/forms/org/OrgInviteCollaboratorsForm';
import * as devicesActions from 'common-js/reducers/devices/actions';
import * as messageActions from 'common-js/reducers/message/actions';
import * as orgActions from 'common-js/reducers/organization/actions';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { CreateSteps } from '../components';

class CreateOrg extends React.Component {
  constructor(props) {
    super(props);

    const { getAllBilling, pushGlobalMessage } = props;

    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Create Org' } });

    getAllBilling().catch((e) => {
      pushGlobalMessage(e, 'error');
    });
  }

  handleOrgCreation = () => {
    const { hideActivationNotification } = this.props;

    hideActivationNotification();
    browserHistory.push(Paths.ORGS_CREATE_STEP2);
  };

  render() {
    const { route } = this.props;
    const stepNumber = route.path === Paths.ORGS_CREATE_STEP1 ? 1 : 2;

    return (
      <div className="page-org-create CreateOrg">
        <HoloHelmet title="Create Organization" />
        <FullHeightCalculatorHOC accountForDrawer>
          <div className="padded-page padded-page">
            <Panel
              title={
                <div className="grid-row vertical-align">
                  <div className="grid-item">
                    <h2 className="CreateOrg__header">New Organization</h2>
                  </div>
                  <div className="grid-item">
                    <CreateSteps step={stepNumber} />
                  </div>
                </div>
              }
              noBodyPadding
            >
              {stepNumber === 1 ? (
                <div>
                  <OrgCreationForm onOrgCreation={this.handleOrgCreation} />
                </div>
              ) : (
                <OrgInviteCollaboratorsForm isOrgCreation />
              )}
            </Panel>
          </div>
        </FullHeightCalculatorHOC>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllBilling: orgActions.getAllBilling,
      hideActivationNotification: devicesActions.hideActivationNotification,
      pushGlobalMessage: messageActions.pushGlobalMessage,
    },
    dispatch
  );

const CreateOrgHoC = connect(null, mapDispatchToProps)(CreateOrg);

export default CreateOrgHoC;
