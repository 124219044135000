import Moment from 'moment';

/**
 * Serialize a single filterKey for sending to the API.
 *
 * @returns the string formatted version of the filter tree.
 */
const serializeSingleFilterKeyValues =
  (filterKey) =>
  (acc, [filterValue, filterParams]) =>
    `${acc}&${filterKey}[${filterParams.comparator}][]=${filterValue}`;

/**
 * Serialize an entire filter key section for making an API request
 *  with the correct filters. To be used as a callback to a `reduce` function call.
 *
 * @param acc - the accumulated query string for the filters.
 * @param filterKey - the key used to filter. Examples are `link_state` or `tag_id`
 * @param filterValues - an object representing individual filter conditions.
 *  this will take the form of <NAME> : { comparator: <EQ|NEQ|et al> }
 *
 * @returns the string formatted version of the filter tree.
 */
const serializeFilters = (acc, [filterKey, filterValues]) => {
  const serializedValues = Object.entries(filterValues).reduce(
    serializeSingleFilterKeyValues(filterKey),
    ''
  );
  return `${acc}${serializedValues}`;
};

/**
 * We can have segments and filters on the same types of data -- currently only device state.
 * If both are present, we favor the filters. This function removes the segment if the data
 * type is also in the filters object.
 *
 * @segments - object - currently active segments
 * @filters - object - currently active filters
 *
 * @return - object - the filtered segment object
 */
const deDuplicateSegmentsAndFilters = (segments, filters) =>
  Object.fromEntries(Object.entries(segments).filter(([key]) => !filters[key]));

/**
 * Serialize all params for sending a request to the API to fetch devices.
 *
 * @state state - the current redux state for devices. This function relies on
 *  - state.filters
 *  - state.page
 *  - state.sort
 *  - state.query
 *
 * @returns the string formatted version of the filter tree.
 */
export const serializeDeviceFilterTree = (state) => {
  const { filters, page, segments, sort, query } = state;
  let constructedQuery = '';

  const filteredSegments = deDuplicateSegmentsAndFilters(segments, filters);

  // handle the filters for the current state
  constructedQuery += Object.entries(filters).reduce(serializeFilters, '');

  // handle the segments for the current state
  constructedQuery += Object.entries(filteredSegments).reduce(serializeFilters, '');

  // if we have a query, append it as the query key
  const queryQuery = query.value;
  if (queryQuery) {
    constructedQuery += `&query=${queryQuery}`;
  }

  // add in the number of hits per page and the page number
  const currentPage = page.current ?? 1;
  constructedQuery += `&hitsPerPage=${page.devicesPerPage}&page=${currentPage}`;

  // add sort data
  if (sort.column) {
    const { column, direction } = sort;
    constructedQuery += `&sort[${column}]=${direction}`;
  }

  return constructedQuery;
};

const findActiveProfile = (profiles) =>
  profiles.find(({ profile_state: euiccState }) => euiccState === 'ENABLED') ?? profiles[0] ?? {};

/**
 * Takes an incoming device format from our database, and outputs a format accepted by the devices table
 * This is a temporary utility, to be deleted upon full switchover to Elasticsearch, including for
 * non-search/sorted/queried requests.
 *
 * @device device - the incoming device format from our database
 *
 * @returns a normalized device format that can be applied to a device table & search result
 */
export const deviceDataAdapter = (device) => {
  const activeProfile = findActiveProfile(device.links?.cellular);
  const plan = activeProfile.plan ?? {};
  const lastSession = device.lastsession ?? {};

  return {
    id: device.id,
    name: device.name,
    imei: device.imei,
    iccid: activeProfile.sim,
    imsi: activeProfile.imsi,
    link_id: activeProfile.id,
    eid: activeProfile.eid,
    msisdn: activeProfile.msisdn,
    phonenumber: device.phonenumber,
    tags: device.tags,
    orgid: device.orgid,
    link_state_connected: lastSession.active ? 'CONNECTED' : activeProfile.state,
    link_whenexpires:
      activeProfile && activeProfile?.whenexpires
        ? Moment.utc(activeProfile.whenexpires).unix()
        : null,
    link_last_connect_time:
      activeProfile && activeProfile?.last_connect_time
        ? Moment.utc(activeProfile.last_connect_time).unix()
        : null,
    link_last_network_used: activeProfile.last_network_used,
    link_cur_billing_data_used: activeProfile.cur_billing_data_used ?? 0,
    plan_id: plan.id,
    plan_name: plan.name,
    plan_zone: plan.zone,
    plan_data: plan.data,
    // eslint-disable-next-line no-underscore-dangle
    _highlightResult: device._highlightResult,
  };
};

/**
 * Aggregates and seriaizes filter fields by their operators.
 *
 * @returns object representing the filter fields, their operators and values
 * in a shape such as { usage: GT: 10, LT 100 }
 * or { state: NEQ: [DEAD, DEAD-PENDING] }
 */
const aggregateFilterFields = (field) => {
  const fieldFilters = Object.entries(field);
  const aggregatedFilters = fieldFilters.reduce((acc, filter) => {
    const [value, comparatorObj] = filter;
    const { comparator } = comparatorObj;
    const prevComparatorValues = acc[comparator] ? [acc[comparator]].flat() : [];
    return {
      ...acc,
      [comparator]: prevComparatorValues.length > 0 ? [...prevComparatorValues, value] : value,
    };
  }, {});
  return aggregatedFilters;
};

/**
 * Serialize all params into a JSON shape for sending a request to the API to fetch devices.
 *
 * @state state - the current redux state for devices. This function relies on
 *  - state.filters
 *  - state.page
 *  - state.sort
 *  - state.query
 *
 * @returns the JSON formatted version of the filter tree.
 */
export const deviceFilterTreeToJson = (state) => {
  const { filters, page, segments, sort, query } = state;

  const filteredSegments = deDuplicateSegmentsAndFilters(segments, filters);

  // Merge segments and filters together because currently the back end treats them as the same thing
  const filterFields = [].concat(Object.entries(filters), Object.entries(filteredSegments));
  const aggregatedFilterFields = filterFields.reduce((acc, field) => {
    const [fieldName, fieldComparators] = field;
    const aggregated = aggregateFilterFields(fieldComparators);
    return {
      ...acc,
      [fieldName]: aggregated,
    };
  }, {});

  const bodyPayload = {
    ...aggregatedFilterFields,
    query: query?.value ?? '',
    hitsPerPage: page?.devicesPerPage,
    page: page.current ?? 1,
    sort: sort.column
      ? {
          [sort.column]: sort.direction,
        }
      : {},
  };

  return bodyPayload;
};
