import { useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import { selectPlanSelectionLimit } from 'common-js/reducers/releaseFlag/selectors';
import useBulkModal from '../useBulkModal';

const useRenameItem = ({ totalSelected }) => {
  const openModal = useBulkModal('BulkRenameModal');
  const planSelectionLimit = useAppSelector(selectPlanSelectionLimit);

  const onClick = useCallback(() => {
    if (totalSelected > 10_000) {
      openModal({ overLimit: true });
      analyticsEventBuilder.errorReturn('Bulk Action', '10k Error').send();
      return;
    }

    openModal();
  }, [openModal, totalSelected]);

  return {
    label: 'Rename device',
    icon: 'SIM',
    selectionLimit: planSelectionLimit,
    ignore10kLimit: true,
    onClick,
  };
};

export default useRenameItem;
