import React, { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button } from '@hologram-dimension/button';
import analyticsEventBuilder from 'common-js/analytics';
import { selectUserSettings } from 'common-js/reducers/account/selectors';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import { updateUserSettings } from 'common-js/reducers/account/actions';
import useOnButtonClickWithAnalytics from 'common-js/analytics/useOnButtonClickWithAnalytics';

const SearchModalEducationalCallout: React.FC = () => {
  const userSettings = useAppSelector(selectUserSettings);
  const { hyper_search_improvements: hyperSearchImprovementsFlag } =
    useAppSelector(getReleaseFlags);

  const shouldShowCallout =
    hyperSearchImprovementsFlag && userSettings.hyper_search_callout_show === 'yes';
  const dispatch = useAppDispatch();

  const handleDismiss = useCallback(() => {
    analyticsEventBuilder.buttonClick('Search', 'Dismiss Modal Bottom CTA').send();
    dispatch(
      updateUserSettings({
        hyper_search_callout_show: 'no',
      })
    );
  }, [dispatch]);

  const handleButtonClick = useOnButtonClickWithAnalytics({ pageName: 'Search' });

  return !shouldShowCallout ? null : (
    <div className="EducationalCallout">
      <div className="EducationalCallout__text">
        Your eUICC-enabled Hyper SIMs switch between multiple connectivity profiles, resulting in
        multiple unique identifiers. Only one profile can be active at a time, however. The “active
        profile” label identifies which unique identifier is currently in use on a Hyper SIM.
      </div>
      <div className="EducationalCallout__actions">
        <Button
          variant="secondary"
          onClick={() => {
            handleButtonClick(
              'Learn More CTA',
              'https://support.hologram.io/hc/en-us/articles/360050663554',
              '_blank'
            );
          }}
          size="small"
        >
          Learn more
        </Button>
        <Button variant="secondary" iconStart="Close" onClick={handleDismiss} size="small">
          Dismiss
        </Button>
      </div>
    </div>
  );
};

export default SearchModalEducationalCallout;
