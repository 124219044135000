import React from 'react';
import { ComplexIcon } from '@holokit/core';

interface CompletedStepHeaderProps {
  name: string;
  children?: React.ReactNode;
}
const CompletedStepHeader = ({ name, children }: CompletedStepHeaderProps) => (
  <div className="ActivationSidebar__item">
    <ComplexIcon name="checkmark-icon-custom" />
    <div className="ActivationSidebar__item__title">
      <div>{name}</div>
      {children}
    </div>
  </div>
);

export default CompletedStepHeader;
