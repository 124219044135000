import React from 'react';
import _ from 'lodash';
import { ComplexIcon } from '@holokit/core';

class RadioSwitch extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isProcessing = false, checked = false, onChange = _.noop, className = '' } = this.props;

    return (
      <div className="RadioSwitch">
        <label className="switch">
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => onChange(e.currentTarget.value)}
          />
          <div className="slider round">
            <div className="switch-label">
              <div className="on" />
              <div className="off" />
            </div>
          </div>
          {isProcessing && (
            <div className="switch-spinner">
              <ComplexIcon name="spinner" />
            </div>
          )}
        </label>
      </div>
    );
  }
}

export default RadioSwitch;
