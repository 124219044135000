import useApi from 'common-js/api/useApi';
import { useCallback } from 'react';

const useForgotPassword = () => {
  const apiCall = useApi.call('/register/forgotpassword', { method: 'POST' });

  return useCallback(
    async ({ email }) => {
      const response = await apiCall({ body: { email } });
      return response;
    },
    [apiCall]
  );
};

export default useForgotPassword;
