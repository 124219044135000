import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { Link } from '@hologram-dimension/link';
import { Panel } from '@hologram-dimension/panel';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { updateUserInformation } from 'common-js/api';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { UserInfoModel } from 'common-js/models';
import { setUserInformation } from 'common-js/reducers/account/actions';
import { selectAccount } from 'common-js/reducers/account/selectors';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import isValidEmailValidator from 'validator/lib/isEmail';

interface User {
  firstName: string;
  lastName: string;
  email: string;
}

const UserInformationForm = () => {
  const [serverError, setServerError] = useState<any>(null);

  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);

  const {
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      firstName: account?.firstName,
      lastName: account?.lastName,
      email: account?.email,
    },
  });
  const onReset = useCallback(() => {
    reset();
  }, [reset]);

  const onSubmit = useCallback(
    async (submission: User) => {
      const { firstName, lastName, email } = submission;
      setServerError('');

      try {
        const userData: User = await updateUserInformation(firstName, lastName, email);

        dispatch(pushGlobalMessage('Information changed successfully.', 'success'));

        dispatch(setUserInformation(new UserInfoModel(userData)));

        reset(submission);
      } catch (e) {
        setServerError(e);
      }
    },
    [dispatch, reset]
  );

  return (
    <Panel
      header="Your information"
      className="stellar-panel-form"
      footerActions={
        <>
          <Button onClick={onReset} variant="secondary" disabled={isSubmitting || !isDirty}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} loading={isSubmitting} disabled={!isDirty}>
            Update information
          </Button>
        </>
      }
    >
      <Form className="user-information-form" onSubmit={handleSubmit(onSubmit)}>
        {serverError && (
          <BannerNotification>
            Unable to save changes to this account. Please{' '}
            <Link href="https://support.hologram.io/hc/en-us" target="_blank" rel="noreferrer">
              contact support
            </Link>
          </BannerNotification>
        )}
        <TextInputField
          label="First name"
          layoutDirection="row"
          {...register('firstName', { required: 'First name is required.' })}
          invalid={!!errors.firstName}
          validationMessage={errors.firstName?.message?.toString()}
        />
        <TextInputField
          label="Last name"
          layoutDirection="row"
          {...register('lastName')}
          invalid={!!errors.lastName}
          validationMessage={errors.lastName?.message?.toString()}
        />
        <TextInputField
          label="Email"
          type="email"
          layoutDirection="row"
          {...register('email', {
            validate: {
              validEmail: (v) => isValidEmailValidator(v) || 'Please enter a valid email address.',
            },
            required: 'Please enter a valid email address.',
          })}
          invalid={!!errors.email}
          validationMessage={errors.email?.message?.toString()}
        />
        <Button type="submit" style={{ display: 'none' }} disabled={!isDirty}>
          Submit
        </Button>
      </Form>
    </Panel>
  );
};

export default UserInformationForm;
