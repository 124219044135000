interface MessageWrapper {
  message: string;
}

export const messages: Record<string, MessageWrapper> = {
  confirmation: {
    message: 'Your password was changed successfully.',
  },
};

export const submitLabel = 'Change password';
