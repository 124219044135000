import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Tooltip } from '@holokit/core';
import { Icon } from '@hologram-dimension/icon';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import Moment from 'moment-timezone';
import Bytes from 'common-js/components/Bytes';
import selectLastConnectedText from './selectLastConnectedText';

const EMPTY_LABEL_VALUE = '—';
const formatDate = (date) => {
  if (Moment.utc(date || null).isValid()) {
    const utcDate = Moment.utc(date).format('MM-DD-YY hh:mm a');
    return `${utcDate} UTC`;
  }
  return EMPTY_LABEL_VALUE;
};

const Section = ({ label, value }) => (
  <div className="DeviceStatusOverview__section">
    <div className="DeviceStatusOverview__label">{label}</div>
    <div className="DeviceStatusOverview__value">{value}</div>
  </div>
);

const LabelWithTooltip = ({ label, tooltipContent, tooltipTitle }) => (
  <>
    <span>{label}</span>
    <Tooltip content={tooltipContent} title={tooltipTitle}>
      <span>
        <Icon name="CircleInfo" size="medium" fill="DdsColorInteractivePrimaryDefault" />
      </span>
    </Tooltip>
  </>
);

const DeviceStatusOverview = () => {
  const device = useAppSelector(selectCurrentDevice);
  const lastConnectedText = useAppSelector(selectLastConnectedText);

  return (
    <div className="DeviceStatusOverview">
      <div className="DeviceStatusOverview__info">
        <div className="DeviceStatusOverview__column">
          <Section label="Carrier" value={device.lastsession.network_name || EMPTY_LABEL_VALUE} />
          <Section label="Last connected" value={lastConnectedText} />
          <Section label="Session start" value={formatDate(device?.lastsession?.session_begin)} />
          <Section label="Session end" value={formatDate(device?.lastsession?.session_end)} />
        </div>

        <div className="DeviceStatusOverview__column">
          <Section
            label="Required APN"
            value={
              <LabelWithTooltip
                label={device.apn || EMPTY_LABEL_VALUE}
                tooltipContent="Your device should be set to this APN based on your SIM's active profile. The APN may change when a SIM profile is swapped."
                tooltipTitle="Access Point Name"
              />
            }
          />
          <Section
            label="Data usage this billing period"
            value={<Bytes amount={device.cur_billing_data_used} />}
          />
          <Section
            label="Data usage this session"
            value={<Bytes amount={device.lastsession.bytes} />}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceStatusOverview;
