import _ from 'lodash';
import isLengthValidator from 'validator/lib/isLength';

export function required(message = 'This field is required', includeIf = null) {
  return (object, context) => {
    if (_.isFunction(includeIf) && !includeIf(context)) return { isValid: true };

    if (!object.value) {
      return { isValid: false, message };
    }

    return { isValid: true };
  };
}

export function isLength(
  message = 'Invalid length for field',
  min = undefined,
  max = undefined,
  includeIf = null
) {
  return (object, context) => {
    if (_.isFunction(includeIf) && !includeIf(context)) return { isValid: true };

    if (!isLengthValidator(object.value, { min, max })) {
      return { isValid: false, message };
    }

    return { isValid: true };
  };
}

export function isNumber(message = 'Invalid type for field', includeIf = null) {
  return (object, context) => {
    if (_.isFunction(includeIf) && !includeIf(context)) return { isValid: true };

    if (!/^\d+$/.test(object.value)) {
      return { isValid: false, message };
    }

    return { isValid: true };
  };
}

export function expirationValid(message = 'Expiration is invalid.', includeIf = null) {
  return (object, context) => {
    if (_.isFunction(includeIf) && !includeIf(context)) return { isValid: true };

    if (object.value && !object.value.valid) {
      return { isValid: false, message };
    }

    return { isValid: true };
  };
}
