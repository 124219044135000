import * as API from 'common-js/api';
import { getUserContextData } from 'common-js/api/util';
import * as actionTypes from '../actionTypes';
import { TASK_START_DATE } from '../reducer';

export const getTaskProgress = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_TASK_PROGRESS_REQUEST,
  });

  const state = getState();
  const userContextData = getUserContextData(state);

  return API.getTaskProgress(userContextData, TASK_START_DATE)
    .then((data) => {
      const taskProgressByJobId = data.reduce(
        (acc, task) => ({
          ...acc,
          [task.jobId]: task.progress,
        }),
        {}
      );

      dispatch({
        type: actionTypes.GET_TASK_PROGRESS_SUCCESS,
        data: taskProgressByJobId,
      });
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_TASK_PROGRESS_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export default getTaskProgress;
