import _ from 'lodash';
import * as filterComparators from 'common-js/constants/filterComparators';
import * as actionTypes from './actionTypes';
import * as accountActionTypes from '../account/actionTypes';

export const PAGE_SIZE = 250;
export const TASK_PAGE_SIZE = 16;
// 22A: We chose the this date because this is when we released the feature
// that surfaces if bulk actions were Dashboard or API initiated. Tasks before this
// did not have this feature so the data would look inconsistent otherwise
export const TASK_START_DATE = Math.floor(new Date(2022, 0, 21).getTime() / 1000);

export const initialState = {
  query: {
    value: null,
  },
  filters: {},
  segments: {},
  selection: {
    byId: {},
    allSelected: false,
    pagesSelected: [],
    excludedIds: {},
    totalSelected: 0,
  },
  sort: {
    column: null,
    direction: null,
  },
  loaded: [],
  uiState: {
    loading: {
      [actionTypes.FETCH_DEVICES]: true,
    },
    activateNotification: {
      showNotificationBanner: false,
      has30SecondsElapsedSinceActivate: false,
      simsHaveActivated: false,
      simsActivating: [],
    },
    filterCount: 0,
    filterInputs: {
      deviceName: '',
      imei: '',
      usageAmount: '',
      usageAmountType: '1000000',
      usageComparator: 'GT',
      lastActiveTimeNumber: '',
      lastActiveTimeUnit: 'Days',
      lastActiveCustomTimeNumber: '',
      activationDateTimeNumber: '',
      activationDateTimeUnit: 'Days',
      activationDateCustomTimeNumber: '',
    },
  },
  page: {
    current: 1,
    total: 0,
    devicesPerPage: PAGE_SIZE,
    totalDeviceCount: 0,
    pageIds: null,
    limit: 40,
  },
  tasks: {
    error: null,
    loading: false,
    completed: {
      error: null,
      loading: null,
      tasks: [],
      sort: {
        column: null,
        direction: null,
      },
    },
    page: {
      currentPage: 1,
      totalPages: 0,
      totalTasks: 0,
      tasksPerPage: TASK_PAGE_SIZE,
      startAfterIds: [],
    },
    details: {
      loading: false,
      error: null,
      deviceCount: 0,
      action: null,
      requester: null,
      devices: [],
      source: null,
    },
  },
  devicesCache: {},
  preloadedSims: [],
  exportCount: 0,
  historicData: {},
};

const updateSection = (filters, filterKey, filterValues, comparator = filterComparators.EQUAL) => {
  const valuesArray = _.isArray(filterValues) ? filterValues : [filterValues];
  const filterSectionValues = filters[filterKey] || {};

  if (valuesArray.length === 0) {
    return {};
  }

  return valuesArray.reduce(
    (acc, filterValue) => ({
      [filterValue]: { comparator },
      ...acc,
    }),
    filterSectionValues
  );
};

// eslint-disable-next-line default-param-last
const devicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DEVICES:
    case actionTypes.FETCH_SLIM_DEVICES: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          error: null,
          loading: {
            ...state.uiState.loading,
            [action.type]: true,
          },
        },
      };
    }

    case actionTypes.FETCH_DEVICES_SUCCESS: {
      return {
        ...state,
        loaded: action.hits,
        page: {
          ...state.page,
          total: action.totalPages,
          totalDeviceCount: action.totalHits,
          pageIds: action.pageIds,
        },
        uiState: {
          ...state.uiState,
          error: null,
          loading: _.omit(state.uiState.loading, [action.loadingKey]),
        },
      };
    }

    case actionTypes.FETCH_DEVICES_ERROR: {
      return {
        ...state,
        loaded: [],
        page: {
          ...state.page,
          ...initialState.page,
        },
        uiState: {
          ...state.uiState,
          loading: _.omit(state.uiState.loading, [action.loadingKey]),
          error: action.error,
        },
      };
    }

    case actionTypes.FETCH_SLIM_DEVICES_SUCCESS:
    case actionTypes.PERSIST_SEARCH_RESULT: {
      const { devices, loadingKey } = action;
      const loadedData = devices.reduce(
        (acc, device) => ({
          ...acc,
          [device.id]: device,
        }),
        {}
      );

      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: _.omit(state.uiState.loading, [loadingKey]),
          error: null,
        },
        devicesCache: {
          ...state.devicesCache,
          ...loadedData,
        },
      };
    }

    case actionTypes.UPDATE_QUERY: {
      const { query } = action;

      return {
        ...state,
        query: {
          ...state.query,
          value: query,
        },
      };
    }

    case actionTypes.SET_FILTER: {
      const { filterKey, filterValues, filterComparator } = action;
      const updatedSection = updateSection({}, filterKey, filterValues, filterComparator);
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterKey]: updatedSection,
        },
      };
    }

    case actionTypes.SET_MULTIPLE_FILTERS: {
      const { filterKey, comparatorsToValues } = action;
      const newFilters = Object.entries(comparatorsToValues).reduce((acc, curr) => {
        const [comparator, values] = curr;
        const parsedFilters = updateSection({}, filterKey, values, comparator);
        return { ...acc, ...parsedFilters };
      }, {});
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterKey]: newFilters,
        },
      };
    }

    case actionTypes.REMOVE_FILTER: {
      const { filterKey } = action;
      const prunedFilters = {};
      let filter;
      // eslint-disable-next-line no-restricted-syntax
      for (filter in state.filters) {
        if (filter !== filterKey) {
          prunedFilters[filter] = state.filters[filter];
        }
      }
      return {
        ...state,
        filters: prunedFilters,
      };
    }

    case actionTypes.REPLACE_SEGMENT_SECTION: {
      const { segmentKey, segmentValues } = action;

      return {
        ...state,
        segments: {
          ...state.segments,
          [segmentKey]: updateSection({}, segmentKey, segmentValues),
        },
      };
    }

    case actionTypes.TOGGLE_FILTER: {
      const { filterComparator, filterKey, filterValue } = action;
      const stateSection = state.filters[filterKey] || {};

      const updatedSelected =
        stateSection && stateSection[filterValue]
          ? _.omit(stateSection, filterValue)
          : updateSection(state.filters, filterKey, filterValue, filterComparator);

      if (_.isEmpty(updatedSelected)) {
        const { [filterKey]: filterValue, ...newFilters } = state.filters; // eslint-disable-line no-shadow
        return {
          ...state,
          filters: newFilters,
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [filterKey]: updatedSelected,
        },
      };
    }

    case actionTypes.TOGGLE_SELECTION: {
      const { deviceId } = action;
      const { current, totalDeviceCount } = state.page;
      const { byId, pagesSelected, allSelected, excludedIds, totalSelected } = state.selection;

      const bulkSelected = pagesSelected.includes(current) || allSelected;
      let updatedTotal;
      let updatedExcluded = excludedIds;
      let updatedSelected = byId;

      if (bulkSelected && excludedIds[deviceId]) {
        updatedExcluded = _.omit(excludedIds, deviceId);
        updatedTotal = totalSelected + 1;
      } else if (bulkSelected && !excludedIds[deviceId]) {
        updatedExcluded = { ...excludedIds, [deviceId]: true };
        updatedTotal = totalSelected - 1;
      } else if (byId[deviceId]) {
        updatedSelected = _.omit(byId, deviceId);
        updatedTotal = totalSelected - 1;
      } else if (!byId[deviceId]) {
        updatedSelected = { ...byId, [deviceId]: true };
        updatedTotal = totalSelected + 1;
      }

      if (updatedTotal >= totalDeviceCount) {
        return {
          ...state,
          selection: {
            ...state.selection,
            allSelected: true,
            byId: {},
            pagesSelected: [],
            excludedIds: {},
            totalSelected: totalDeviceCount,
          },
        };
      }
      return {
        ...state,
        selection: {
          ...state.selection,
          byId: updatedSelected,
          excludedIds: updatedExcluded,
          totalSelected: updatedTotal,
        },
      };
    }

    case actionTypes.TOGGLE_SELECTION_PAGE: {
      const { loaded } = state;
      const { allSelected, byId, excludedIds, pagesSelected, totalSelected } = state.selection;
      const { current, devicesPerPage, totalDeviceCount } = state.page;

      if (allSelected) {
        return { ...state };
      }

      if (devicesPerPage >= totalDeviceCount) {
        return {
          ...state,
          selection: {
            ...state.selection,
            allSelected: true,
            byId: {},
            pagesSelected: [],
            totalSelected: totalDeviceCount,
            excludedIds: {},
          },
        };
      }

      const hasPageSelected = pagesSelected.includes(current);
      const numDevicesLoaded = loaded.length;
      let updatedPagesSelected;
      let updatedTotal;
      let updatedExcluded = excludedIds;
      let updatedIds = byId;
      if (hasPageSelected) {
        updatedPagesSelected = pagesSelected.filter((page) => page !== current);
        updatedTotal = totalSelected - numDevicesLoaded;
        updatedTotal = updatedTotal < 0 ? 0 : updatedTotal;

        // for any device excluded in excludedIds on the current page, remove the id from the excludedIds object
        const excludedDevicesToRemove = loaded.filter((device) => excludedIds[device.id]);
        updatedExcluded = {};
        _.forIn(excludedIds, (value, deviceId) => {
          const keepIdExcluded = excludedDevicesToRemove.every(
            (device) => device.id !== parseInt(deviceId, 10)
          );
          if (keepIdExcluded && !updatedIds[deviceId]) {
            updatedExcluded[deviceId] = true;
          }
        });
      } else {
        updatedPagesSelected = [...pagesSelected, current];
        updatedTotal = totalSelected + numDevicesLoaded;

        // for any device selected in byId on the current page, remove the id from the byId object
        const selectedDevicesToRemove = loaded.filter((device) => byId[device.id]);
        updatedTotal -= selectedDevicesToRemove.length;
        updatedIds = {};
        _.forIn(byId, (value, deviceId) => {
          const keepIdSelected = selectedDevicesToRemove.every(
            (device) => device.id !== parseInt(deviceId, 10)
          );
          if (keepIdSelected && !updatedExcluded[deviceId]) {
            updatedIds[deviceId] = true;
          }
        });
      }

      if (updatedTotal >= totalDeviceCount) {
        return {
          ...state,
          selection: {
            ...state.selection,
            allSelected: true,
            byId: {},
            pagesSelected: [],
            totalSelected: totalDeviceCount,
            excludedIds: {},
          },
        };
      }

      return {
        ...state,
        selection: {
          ...state.selection,
          byId: updatedIds,
          pagesSelected: updatedPagesSelected.map((page) => parseInt(page, 10)),
          totalSelected: updatedTotal,
          excludedIds: updatedExcluded,
        },
      };
    }

    case actionTypes.SELECT_ALL_DEVICES: {
      const { totalDeviceCount } = state.page;

      return {
        ...state,
        selection: {
          ...state.selection,
          byId: {},
          allSelected: true,
          pagesSelected: [],
          excludedIds: {},
          totalSelected: totalDeviceCount,
        },
      };
    }

    case actionTypes.CLEAR_SELECTION: {
      return {
        ...state,
        selection: {
          ...state.selection,
          byId: {},
          allSelected: false,
          pagesSelected: [],
          excludedIds: {},
          totalSelected: 0,
        },
      };
    }

    case actionTypes.UPDATE_SORT: {
      const { column, direction } = action;

      return {
        ...state,
        sort: {
          ...state.sort,
          column,
          direction,
        },
      };
    }

    case actionTypes.UPDATE_ACTIVITY_HISTORY_SORT: {
      const { column, direction } = action;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          completed: {
            ...state.tasks?.completed,
            sort: {
              ...state.tasks?.completed?.sort,
              column,
              direction,
            },
          },
        },
      };
    }

    case actionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES: {
      const { tagId } = action;
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: {
            ...state.uiState.loading,
            [action.type]: {
              ...state.uiState.loading[action.type],
              [tagId]: true,
            },
          },
        },
      };
    }

    case actionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES_SUCCESS:
    case actionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES_ERROR: {
      const { tagId } = action;
      return {
        ...state,
        uiState: {
          ...state.uiState,
          loading: {
            ...state.uiState.loading,
            [action.loadingKey]: {
              ...state.uiState.loading[action.loadingKey],
              [tagId]: undefined,
            },
          },
        },
      };
    }

    case actionTypes.SET_CURRENT_PAGE: {
      const { page } = action;
      return {
        ...state,
        page: {
          ...state.page,
          current: page,
        },
      };
    }

    case actionTypes.RESET_PAGING: {
      const { resetPageIds } = action;

      if (resetPageIds) {
        return {
          ...state,
          page: {
            ...initialState.page,
          },
        };
      }

      return {
        ...state,
        page: {
          ...initialState.page,
          pageIds: state.page.pageIds,
        },
      };
    }

    case actionTypes.RESET_QUERY: {
      return {
        ...state,
        query: initialState.query,
      };
    }

    case actionTypes.RESET_SORTING: {
      return {
        ...state,
        sort: initialState.sort,
      };
    }

    case actionTypes.RESET_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }

    case actionTypes.SET_SIMS_BEING_ACTIVATED: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          activateNotification: {
            ...state.uiState.activateNotification,
            simsActivating: action.sims,
            showNotificationBanner: true,
          },
        },
      };
    }

    case actionTypes.SET_SIM_ACTIVATION_TIME: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          activateNotification: {
            ...state.uiState.activateNotification,
            has30SecondsElapsedSinceActivate: true,
          },
        },
      };
    }

    case actionTypes.SET_ACTIVATING_SIMS_ACTIVE: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          activateNotification: {
            ...state.uiState.activateNotification,
            simsHaveActivated: true,
          },
        },
      };
    }

    case actionTypes.HIDE_ACTIVATION_NOTIFICAITON: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          activateNotification: {
            ...state.uiState.activateNotification,
            showNotificationBanner: false,
            simsActivating: [],
          },
        },
      };
    }

    case actionTypes.SET_EXPORT_COUNT: {
      return {
        ...state,
        exportCount: action.exportCount,
      };
    }

    case actionTypes.SET_FILTER_INPUT_VALUE: {
      const { inputName, inputValue } = action;
      return {
        ...state,
        uiState: {
          ...state.uiState,
          filterInputs: {
            ...state.uiState.filterInputs,
            [inputName]: inputValue,
          },
        },
      };
    }

    case actionTypes.CLEAR_FILTER_INPUTS: {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          filterInputs: initialState.uiState.filterInputs,
        },
      };
    }

    case accountActionTypes.LOGOUT_REQUEST:
    case accountActionTypes.LOGIN_REQUEST:
      return { ...state, ...initialState };

    case actionTypes.GET_HISTORIC_DEVICE_COUNT_SUCCESS:
      return {
        ...state,
        historicData: {
          ...action.data,
        },
      };

    case actionTypes.BULK_DEACTIVATE_DEVICES_PREVIEW_REQUEST:
    case actionTypes.PAUSE_DATA_BULK_PREVIEW_REQUEST:
    case actionTypes.RESUME_DATA_BULK_PREVIEW_REQUEST:
      return {
        ...state,
        bulkPreview: {
          ...state.bulkPreview,
          error: null,
        },
        uiState: {
          ...state.uiState,
          loading: {
            ...state.uiState.loading,
            [action.type]: true,
          },
        },
      };

    case actionTypes.BULK_DEACTIVATE_DEVICES_PREVIEW_SUCCESS:
    case actionTypes.PAUSE_DATA_BULK_PREVIEW_SUCCESS:
    case actionTypes.RESUME_DATA_BULK_PREVIEW_SUCCESS:
      return {
        ...state,
        bulkPreview: {
          ...action.data,
          error: null,
        },
        uiState: {
          ...state.uiState,
          loading: _.omit(state.uiState.loading, [action.loadingKey]),
        },
      };

    case actionTypes.BULK_DEACTIVATE_DEVICES_PREVIEW_ERROR:
    case actionTypes.PAUSE_DATA_BULK_PREVIEW_ERROR:
    case actionTypes.RESUME_DATA_BULK_PREVIEW_ERROR:
      return {
        ...state,
        bulkPreview: {
          ...state.bulkPreview,
          error: action.error,
        },
        uiState: {
          ...state.uiState,
          loading: _.omit(state.uiState.loading, [action.loadingKey]),
        },
      };

    case actionTypes.GET_TASKS_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: null,
          loading: true,
        },
      };

    case actionTypes.GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: null,
          loading: false,
          groups: action.data,
        },
      };

    case actionTypes.GET_TASKS_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: action.error,
          loading: false,
        },
      };

    case actionTypes.GET_COMPLETED_TASKS_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          completed: {
            ...state.tasks.completed,
            error: null,
            loading: true,
          },
        },
      };

    case actionTypes.GET_COMPLETED_TASKS_SUCCESS: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          completed: {
            ...state.tasks.completed,
            error: null,
            loading: false,
            tasks: action.tasks,
          },
          page: {
            ...state.tasks.page,
            ...action.page,
          },
        },
      };
    }

    case actionTypes.GET_COMPLETED_TASKS_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          completed: {
            ...state.tasks.completed,
            error: action.error,
            loading: false,
            tasks: [],
          },
        },
      };

    case actionTypes.GET_TASK_PAGES_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          page: {
            ...state.tasks.page,
            error: null,
            loading: true,
          },
        },
      };

    case actionTypes.GET_TASK_PAGES_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          page: {
            ...state.tasks.page,
            error: null,
            loading: false,
            startAfterIds: action.data.afterIds || [],
            totalPages: (action.data.afterIds || []).length + 1,
            totalTasks: action.data.totalTasks || 0,
          },
        },
      };

    case actionTypes.GET_TASK_PAGES_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          page: {
            ...state.tasks.page,
            error: action.error,
            loading: false,
          },
        },
      };

    case actionTypes.GET_TASK_PROGRESS_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          progress: {
            ...state.tasks.progress,
            error: null,
            loading: true,
          },
        },
      };

    case actionTypes.GET_TASK_PROGRESS_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          progress: {
            ...state.tasks.progress,
            error: null,
            loading: false,
            ...action.data,
          },
        },
      };

    case actionTypes.GET_TASK_PROGRESS_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          progress: {
            ...state.tasks.progress,
            error: action.error,
            loading: false,
          },
        },
      };

    case actionTypes.SET_CURRENT_TASKS_PAGE: {
      const { page } = action;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          page: {
            ...state.tasks.page,
            currentPage: page,
          },
        },
      };
    }

    case actionTypes.GET_BATCH_JOB_DETAILS_REQUEST: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          details: {
            ...initialState.tasks.details,
            loading: true,
          },
        },
      };
    }

    case actionTypes.GET_BATCH_JOB_DETAILS_REQUEST_ONLY_DEVICES: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          details: {
            ...state.tasks.details,
            loading: true,
            devices: [],
          },
        },
      };
    }

    case actionTypes.GET_BATCH_JOB_DETAILS_ERROR: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          details: {
            ...initialState.tasks.details,
            loading: false,
            error: action.error,
          },
        },
      };
    }

    case actionTypes.GET_BATCH_JOB_DETAILS_SUCCESS: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          details: {
            ...state.tasks.details,
            loading: false,
            error: null,
            ...action.payload,
          },
        },
      };
    }

    case actionTypes.GET_BATCH_JOB_DETAILS_SUCCESS_ONLY_DEVICES: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          details: {
            ...state.tasks.details,
            loading: false,
            error: null,
            devices: action.payload.devices,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default devicesReducer;
