import { createSelector } from 'reselect';
import { getSelectedDeviceIds } from 'common-js/reducers/devices/selectors';

export const getTags = (state) => state.deviceFilters.tags;

export const getSelectedTagNames = (state) =>
  state.deviceFilters.selectedTags.map((tagId) => {
    const tag = state.deviceFilters.tags.find(({ id }) => id === tagId);
    return tag?.name;
  });

export const getFiltersByStateSegment = (state) => {
  if (!state.deviceFilters.link_state_connected?.length || !('state' in state.devices.segments)) {
    return [];
  }

  return state.deviceFilters.link_state_connected.filter(
    (status) => status.value in state.devices.segments.state
  );
};

export const getTagsWithDeviceSelectionMetadata = createSelector(
  [getTags, getSelectedDeviceIds],
  (tags, selectedDevices) =>
    tags.map((tag) => {
      const selectedIds = selectedDevices.map((id) => parseInt(id, 10));
      const { deviceids } = tag;

      const allSelected =
        selectedIds.length > 0 && selectedIds.every((dId) => deviceids.includes(dId));
      const someSelected = selectedIds.some((dId) => deviceids.includes(dId));

      return {
        ...tag,
        deviceSelection: {
          allSelected,
          someSelected,
        },
      };
    })
);

export const getTotalDeviceCount = (state) => {
  const linkValues = state.deviceFilters.link_state_connected || [];
  return linkValues.reduce((result, current) => {
    const nextValue = result + current.num_devices;
    return nextValue;
  }, 0);
};

export const getStateCountsByName = (state) => {
  const linkValues = state.deviceFilters.link_state_connected || [];
  const countsByName = linkValues.reduce((result, current) => {
    const nextValue = {
      ...result,
      [current.value]: current.num_devices,
    };
    return nextValue;
  }, {});

  return countsByName;
};
