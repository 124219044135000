import React, { useCallback } from 'react';
import { Button, Icon } from '@holokit/core';
import _ from 'lodash';
import * as Paths from 'common-js/constants/paths';
import { toByteString } from 'common-js/utils/numberFormatter';
import { Loader } from '@hologram-dimension/loader';

function TableRow({ device, numRows }) {
  return (
    <tr className="GenericUsageTable__row">
      <td
        rowSpan={numRows}
        className="GenericUsageTable__cell GenericUsageTable__cell--left-padded GenericUsageTable__cell--align-top"
      >
        <div>
          <a
            className="UsageBreakdownByDeviceTable__link"
            target="_blank"
            href={Paths.withContext(
              Paths.DEVICE_LANDING.replace(`:${Paths.DEVICE_PARAM_NAME}`, device.deviceid)
            )}
            rel="noreferrer"
          >
            {device.name}
          </a>
        </div>
        <div className="GenericUsageTable__subheader">{(device.tags ?? []).join(', ')}</div>
      </td>
      <td className="GenericUsageTable__cell">
        <div>{device.sim}</div>
        <div className="GenericUsageTable__subheader">{device.imei}</div>
      </td>
      <td className="GenericUsageTable__cell">{toByteString(device.total_bytes)}</td>
      <td className="GenericUsageTable__cell">{device.sessions}</td>
      <td className="GenericUsageTable__cell">
        {toByteString(device.total_bytes / device.sessions)}
      </td>
      <td className="GenericUsageTable__cell">{device.sms}</td>
      <td className="GenericUsageTable__cell GenericUsageTable__cell--right-padded">
        <div>{device.planname}</div>
      </td>
    </tr>
  );
}

function TableUsageRow({ networkName, bytes, sessions, sms }) {
  return (
    <tr className="GenericUsageTable__row GenericUsageTable__row--subdued">
      <td className="GenericUsageTable__cell">{networkName}</td>
      <td className="GenericUsageTable__cell">{toByteString(bytes)}</td>
      <td className="GenericUsageTable__cell">{sessions}</td>
      <td className="GenericUsageTable__cell">{toByteString(bytes / sessions)}</td>
      <td className="GenericUsageTable__cell">{sms}</td>
      <td className="GenericUsageTable__cell">{sms}</td>
    </tr>
  );
}

const buildRowSet = (device, idx, setLength) =>
  [
    <TableRow
      device={device}
      numRows={device?.usage?.length ? device.usage.length + 1 : 1}
      key={`${device.deviceid}-${idx}`}
    />,
  ].concat(
    _.map(device.usage, (usageRow, uIdx) => (
      <TableUsageRow
        networkName={usageRow.network_name}
        bytes={usageRow.bytes}
        sessions={usageRow.sessions}
        sms={usageRow.sms}
        key={setLength * (idx + 1) + uIdx}
      />
    ))
  );

function NoDataMessage() {
  return (
    <div className="GenericUsageTableNoData">
      <Icon
        classes="GenericUsageTableNoData__icon"
        name="Circle--info"
        size="major"
        svgProps={{ style: { fill: '#8008f7' } }}
      />
      <div className="GenericUsageTableNoData__message">
        There are no data sessions to display for this set of devices and specific time frame. Try
        adjusting tags or the selected date range.
      </div>
    </div>
  );
}

function TooBigMessage({ isExportingCsv, onExportCSV }) {
  return (
    <div className="UsageBreakdownByDeviceTableTooBig">
      <div className="UsageBreakdownByDeviceTableTooBig__main-message">
        Report too big to preview
      </div>
      <div className="UsageBreakdownByDeviceTableTooBig__sub-message">
        Data exceeds size limit to preview, export to view it
      </div>
      <Button type="secondary" loading={isExportingCsv} onClick={onExportCSV}>
        Export CSV
      </Button>
    </div>
  );
}

function UsageBreakdownByDeviceTable({
  continues,
  deviceData = [],
  error,
  exportCsvCallback,
  fromInspect = false,
  isExportingCsv,
  loading,
  loadingMore,
  onLoadMore,
}) {
  const onExportCSV = useCallback(() => {
    exportCsvCallback();
  }, [exportCsvCallback]);

  if (error) {
    return (
      <div className="UsageBreakdownByDeviceTable">
        <TooBigMessage isExportingCsv={isExportingCsv} onExportCSV={onExportCSV} />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="UsageBreakdownByDeviceTable">
        <div className="GenericUsageTable__loading">
          <Loader className="DimensionLoader__legacy-padding-override" />
        </div>
      </div>
    );
  }

  if (deviceData.length === 0) {
    return (
      <div className="UsageBreakdownByDeviceTable">{fromInspect ? <div /> : <NoDataMessage />}</div>
    );
  }

  return (
    <div className="UsageBreakdownByDeviceTable">
      <div>
        <table className="GenericUsageTable">
          <thead>
            <tr className="GenericUsageTable__header">
              <th className="GenericUsageTable__cell--header GenericUsageTable__cell--left-padded UsageBreakdownByDeviceTable__header--name-tags">
                device name / Tags
              </th>
              <th className="GenericUsageTable__cell--header UsageBreakdownByDeviceTable__header--sim-imei">
                sim number / IMEI
              </th>
              <th className="GenericUsageTable__cell--header UsageBreakdownByDeviceTable__header--total-used">
                total used
              </th>
              <th className="GenericUsageTable__cell--header UsageBreakdownByDeviceTable__header--sessions">
                sessions
              </th>
              <th className="GenericUsageTable__cell--header UsageBreakdownByDeviceTable__header--avg-usage">
                avg. usage
              </th>
              <th className="GenericUsageTable__cell--header UsageBreakdownByDeviceTable__header--sms">
                sms
              </th>
              <th className="GenericUsageTable__cell--header GenericUsageTable__cell--right-padded UsageBreakdownByDeviceTable__header--plan-coverage">
                plan / coverage
              </th>
            </tr>
          </thead>
          <tbody>{deviceData.map((d, idx) => buildRowSet(d, idx, deviceData.length))}</tbody>
        </table>
        {continues && (
          <div className="GenericUsageTable__load-more-container">
            <Button
              onClick={onLoadMore}
              loading={loadingMore}
              disabled={loadingMore}
              type="secondary"
            >
              Load more
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UsageBreakdownByDeviceTable;
