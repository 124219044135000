import useApi from 'common-js/api/useApi';
import { DeviceChangePlanResult, DeviceChangeWarning } from 'common-js/types/Device';
import { useCallback } from 'react';

interface UseDeviceChangePlanCallbackProps {
  newPlanId: PlanId;
  zone: string;
  orgId: OrgId;
  useAccountBalance?: boolean;
  overageLimit?: number;
}
interface UseDeviceChangePlanProps {
  deviceIds?: Array<DeviceId>;
  simObj?: Sim;
  preview?: boolean;
  useBusinessLayer?: boolean;
}
export interface UseDeviceChangePlanResult {
  warnings: Array<DeviceChangeWarning>;
  devices_modified: Array<DeviceId>;
  orderData: OrderData;
}

const useDeviceChangePlan = ({
  deviceIds,
  simObj,
  preview,
  useBusinessLayer = false,
}: UseDeviceChangePlanProps) => {
  let endpoint = useBusinessLayer ? `/sims/changeplan` : '/devices/changeplan';
  // useApi.call memoizes for us
  const useApiParams = useBusinessLayer ? { method: 'POST' } : { method: 'POST' };

  if (preview && !useBusinessLayer) {
    endpoint = `${endpoint}?preview=1`;
  }

  const apiCall = useApi.call(endpoint, useApiParams);

  return useCallback(
    async ({
      newPlanId,
      zone,
      orgId,
      useAccountBalance,
      overageLimit,
    }: UseDeviceChangePlanCallbackProps): Promise<UseDeviceChangePlanResult | undefined> => {
      const params: any = useBusinessLayer
        ? { body: { orgId, sims: [simObj?.simId] } }
        : {
            body: {
              deviceids: deviceIds,
              zone,
              orgid: orgId,
              overage_limit: overageLimit,
              planid: newPlanId,
            },
          };

      if (useAccountBalance && !useBusinessLayer) {
        // Revisit if business layer needs this when we add it
        params.body.useacctbalance = true;
      }
      const {
        data,
        error,
        order_data: orderData,
      } = (await apiCall(params)) as DeviceChangePlanResult;
      if (error) throw new Error(error);
      return data
        ? {
            warnings: data.warnings,
            devices_modified: data.devices_modified,
            orderData,
          }
        : undefined;
    },
    [apiCall, deviceIds, simObj?.simId, useBusinessLayer]
  );
};

export default useDeviceChangePlan;
