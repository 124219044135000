import React from 'react';

const DataSpendGraph = ({ data }) => {
  const percentCalc = (num, total) => (num / total) * 100;

  const safeToFixed = (num, fixed) => {
    try {
      return num.toFixed(fixed);
    } catch (e) {
      return 0;
    }
  };

  const activatePercent = percentCalc(data.activationCharges, data.totalCharges);
  const renewalPercent = percentCalc(data.renewalCharges, data.totalCharges);
  const usagePercent = percentCalc(data.usageCharges, data.totalCharges);

  return (
    <div className="DataSpendGraph">
      <div className="Panel__padding">
        <div className="DataSpendGraph-total h1">
          ${safeToFixed(data.totalCharges, 2)}{' '}
          <span className="type-micro uppercase type-weight-med">total charges</span>
        </div>
        <div className="barchart">
          <span className="blue" style={{ width: `${usagePercent}%` }} />
          <span className="pink" style={{ width: `${renewalPercent}%` }} />
          <span className="magenta" style={{ width: `${activatePercent}%` }} />
        </div>
      </div>
      <div className="Panel__footer chart-key">
        <div className="key">
          <div className="dot" />
          <div className="key-text h3">
            ${safeToFixed(data.usageCharges, 2)}{' '}
            <span className="type-micro uppercase type-weight-med">data usage</span>
          </div>
        </div>
        <div className="key">
          <div className="dot" />
          <div className="key-text h3">
            ${safeToFixed(data.renewalCharges, 2)}{' '}
            <span className="type-micro uppercase type-weight-med">
              platform fees (per existing device)
            </span>
          </div>
        </div>
        <div className="key">
          <div className="dot" />
          <div className="key-text h3">
            ${safeToFixed(data.activationCharges, 2)}{' '}
            <span className="type-micro uppercase type-weight-med">
              platform fees (per new device)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSpendGraph;
