import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@holokit/core';

function Submit({
  label,
  formProcessing,
  disabled,
  classNames,
  fullWidth,
  loadingLabel,
  formSuccessful,
  tabIndex,
  type,
}) {
  return formSuccessful ? (
    <Button classes={classNames} fullWidth={fullWidth} type={type} disabled>
      Success!
    </Button>
  ) : (
    <Button
      classes={classNames}
      disabled={formProcessing || disabled || formSuccessful}
      fullWidth={fullWidth}
      loading={formProcessing}
      buttonProps={{ type: 'submit' }}
      tabIndex={tabIndex}
      type={type}
    >
      {formProcessing ? (
        <div className="processing" key={2}>
          {loadingLabel || 'Sending...'}
        </div>
      ) : formSuccessful ? (
        <span>Success!</span>
      ) : (
        <span>{label || 'Submit'}</span>
      )}
    </Button>
  );
}

Submit.propTypes = {
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  formProcessing: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  loadingLabel: PropTypes.string,
  type: PropTypes.string,
};

Submit.defaultProps = {
  classNames: null,
  disabled: false,
  formProcessing: false,
  fullWidth: false,
  label: 'Submit',
  tabIndex: '',
  type: 'primary',
};

export default Submit;
