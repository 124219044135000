import { ACCOUNT_LOGIN, USER_PARAM_NAME } from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { impersonateUser } from 'common-js/reducers/admin/actions';
import React from 'react';
import { browserHistory } from 'react-router';

interface SpoofLandingProps {
  params: Record<string, string>;
}

const SpoofLanding: React.FC<SpoofLandingProps> = ({ params }) => {
  const userid = params[USER_PARAM_NAME];

  const dispatch = useAppDispatch();

  dispatch(impersonateUser({ userid })).catch((e: any) => {
    // They need to be logged in to spoof a user but if they get
    // redirected to the login screen information gets lost...
    // eslint-disable-next-line no-console
    console.log(e);
    browserHistory.push(`${ACCOUNT_LOGIN}?origin=adminfail`);
  });

  return null;
};

export default SpoofLanding;
