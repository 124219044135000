import { Button } from '@hologram-dimension/button';
import { Picture } from '@hologram-hyper-dashboard/components';
import analyticsEventBuilder from 'common-js/analytics';
import HoloHelmet from 'common-js/components/HoloHelmet';
import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import TopMenu from 'common-js/components/menu/TopMenu';
import { ACCOUNT_LOGIN, HOME_OVERVIEW, withContext } from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import fourOhFourImg from 'img/hero/404Computer.webp?&imagetools';
import { Layout } from 'layouts';
import { FC, useEffect } from 'react';
import LoggedOutTemplate from './LoggedOutTemplate';

interface NotFoundContentProps {
  loggedIn: boolean;
}

const NotFoundContent: FC<NotFoundContentProps> = ({ loggedIn = false }) => (
  <>
    <HoloHelmet title="Page not found" />
    <div className="wrapper-404">
      <div className="wrapper-404__inner">
        <Picture className="wrapper-404__illustration" image={fourOhFourImg} alt="" />
        <div className="wrapper-404__content">
          <h1 className="wrapper-404__heading">
            We couldn&apos;t find what you&apos;re looking for.
          </h1>
          {loggedIn && (
            <p className="wrapper-404_instructions">
              Use the navigation or the quick links below to keep exploring the site.
            </p>
          )}
          <div className="wrapper-404__button-group">
            <Button
              href="https://support.hologram.io/hc/en-us?_ga=2.261206158.2079876555.1670356263-914272263.1670356256"
              variant="secondary"
              target="_blank"
              rel="noreferrer"
            >
              Visit help center
            </Button>
            <Button href={loggedIn ? withContext(HOME_OVERVIEW) : ACCOUNT_LOGIN}>
              {loggedIn ? 'Back to home' : 'Log in to Dashboard'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default function FourZeroFour() {
  useEffect(() => {
    analyticsEventBuilder.pageView().page('404').send();
  }, []);

  const userId = useAppSelector((state) => state.account.userId);
  const loggedIn = userId !== null;

  return loggedIn ? (
    <Layout>
      <TopMenu rightContent={<SubMenuRightContent />} />
      <NotFoundContent loggedIn={loggedIn} />
    </Layout>
  ) : (
    <LoggedOutTemplate className="logged-out-template--alt-gradient">
      <NotFoundContent loggedIn={loggedIn} />
    </LoggedOutTemplate>
  );
}
