const PREFIX = 'DEVICES' as const;

export const FETCH_DEVICES = `${PREFIX}_FETCH_DEVICES` as const;
export const FETCH_DEVICES_SUCCESS = `${PREFIX}_FETCH_DEVICES_SUCCESS` as const;
export const FETCH_DEVICES_ERROR = `${PREFIX}_FETCH_DEVICES_ERROR` as const;

export const FETCH_DEVICES_COUNT = `${PREFIX}_FETCH_DEVICES_COUNT` as const;

export const TOGGLE_TAG_SELECTION_FOR_DEVICES =
  `${PREFIX}_TOGGLE_TAG_SELECTION_FOR_DEVICES` as const;
export const TOGGLE_TAG_SELECTION_FOR_DEVICES_SUCCESS =
  `${PREFIX}_TOGGLE_TAG_SELECTION_FOR_DEVICES_SUCCESS` as const;
export const TOGGLE_TAG_SELECTION_FOR_DEVICES_ERROR =
  `${PREFIX}_TOGGLE_TAG_SELECTION_FOR_DEVICES_ERROR` as const;

export const FETCH_SLIM_DEVICES = `${PREFIX}_FETCH_SLIM_DEVICES` as const;
export const FETCH_SLIM_DEVICES_SUCCESS = `${PREFIX}_FETCH_SLIM_DEVICES_SUCCESS` as const;
export const FETCH_SLIM_DEVICES_ERROR = `${PREFIX}_FETCH_SLIM_DEVICES_ERROR` as const;

export const REPLACE_SEGMENT_SECTION = `${PREFIX}_REPLACE_SEGMENT_SECTION` as const;

export const SET_FILTER_INPUT_VALUE = `${PREFIX}_SET_FILTER_INPUT_VALUE` as const;
export const CLEAR_FILTER_INPUTS = `${PREFIX}_CLEAR_FILTER_INPUTS` as const;

export const TOGGLE_FILTER = `${PREFIX}_TOGGLE_FILTER` as const;
export const TOGGLE_SELECTION = `${PREFIX}_TOGGLE_SELECTION` as const;
export const TOGGLE_SELECTION_PAGE = `${PREFIX}_TOGGLE_SELECTION_PAGE` as const;
export const SELECT_ALL_DEVICES = `${PREFIX}_SELECT_ALL_DEVICES` as const;
export const CLEAR_SELECTION = `${PREFIX}_CLEAR_SELECTION` as const;

export const UPDATE_SORT = `${PREFIX}_UPDATE_SORT` as const;
export const RESET_SORTING = `${PREFIX}_RESET_SORTING` as const;

export const UPDATE_ACTIVITY_HISTORY_SORT = `${PREFIX}_UPDATE_ACTIVITY_HISTORY_SORT` as const;

export const UPDATE_QUERY = `${PREFIX}_UPDATE_QUERY` as const;
export const RESET_QUERY = `${PREFIX}_RESET_QUERY` as const;

export const UPDATE_QUERY_INPUT = `${PREFIX}_UPDATE_QUERY_INPUT` as const;

export const SET_FILTER = `${PREFIX}_SET_FILTER` as const;
export const SET_MULTIPLE_FILTERS = `${PREFIX}_SET_MULTIPLE_FILTERS` as const;
export const RESET_FILTERS = `${PREFIX}_RESET_FILTERS` as const;
export const REMOVE_FILTER = `${PREFIX}_REMOVE_FILTER` as const;

export const ENSURE_SLIM_DEVICES_LOADED = `${PREFIX}_ENSURE_SLIM_DEVICES_LOADED` as const;
export const PERSIST_SEARCH_RESULT = `${PREFIX}_PERSIST_SEARCH_RESULT` as const;

export const SET_CURRENT_PAGE = `${PREFIX}_SET_CURRENT_PAGE` as const;
export const RESET_PAGING = `${PREFIX}_RESET_PAGING` as const;

export const SET_CURRENT_TASKS_PAGE = `${PREFIX}_SET_CURRENT_TASKS_PAGE` as const;

export const SET_SIMS_BEING_ACTIVATED = `${PREFIX}_SET_SIMS_BEING_ACTIVATED` as const;
export const SET_SIM_ACTIVATION_TIME = `${PREFIX}_SET_SIM_ACTIVATION_TIME` as const;
export const SET_ACTIVATING_SIMS_ACTIVE = `${PREFIX}_SET_ACTIVATING_SIMS_ACTIVE` as const;
export const HIDE_ACTIVATION_NOTIFICAITON = `${PREFIX}_HIDE_ACTIVATION_NOTIFICAITON` as const;

export const BULK_DEACTIVATE_DEVICES = `${PREFIX}BULK_DEACTIVATE_DEVICES` as const;
export const BULK_DEACTIVATE_DEVICES_SUCCESS = `${PREFIX}BULK_DEACTIVATE_DEVICES_SUCCESS` as const;
export const BULK_DEACTIVATE_DEVICES_ERROR = `${PREFIX}BULK_DEACTIVATE_DEVICES_ERROR` as const;

export const BULK_DEACTIVATE_DEVICES_PREVIEW_REQUEST =
  `${PREFIX}BULK_DEACTIVATE_DEVICES_PREVIEW_REQUEST` as const;
export const BULK_DEACTIVATE_DEVICES_PREVIEW_SUCCESS =
  `${PREFIX}BULK_DEACTIVATE_DEVICES_PREVIEW_SUCCESS` as const;
export const BULK_DEACTIVATE_DEVICES_PREVIEW_ERROR =
  `${PREFIX}BULK_DEACTIVATE_DEVICES_PREVIEW_ERROR` as const;

export const SEND_SMS_TO_DEVICE_REQUEST = `${PREFIX}SEND_SMS_TO_DEVICE_REQUEST` as const;
export const SEND_SMS_TO_DEVICE_SUCCESS = `${PREFIX}SEND_SMS_TO_DEVICE_SUCCESS` as const;
export const SEND_SMS_TO_DEVICE_ERROR = `${PREFIX}SEND_SMS_TO_DEVICE_ERROR` as const;

export const SEND_DATA_TO_DEVICE_REQUEST = `${PREFIX}SEND_DATA_TO_DEVICE_REQUEST` as const;
export const SEND_DATA_TO_DEVICE_SUCCESS = `${PREFIX}SEND_DATA_TO_DEVICE_SUCCESS` as const;
export const SEND_DATA_TO_DEVICE_ERROR = `${PREFIX}SEND_DATA_TO_DEVICE_ERROR` as const;

export const PAUSE_BULK_REQUEST = `${PREFIX}PAUSE_BULK_REQUEST` as const;
export const PAUSE_BULK_SUCCESS = `${PREFIX}PAUSE_BULK_SUCCESS` as const;
export const PAUSE_BULK_ERROR = `${PREFIX}PAUSE_BULK_ERROR` as const;

export const PAUSE_DATA_BULK_PREVIEW_REQUEST = `${PREFIX}_PAUSE_DATA_BULK_PREVIEW_REQUEST` as const;
export const PAUSE_DATA_BULK_PREVIEW_SUCCESS = `${PREFIX}_PAUSE_DATA_BULK_PREVIEW_SUCCESS` as const;
export const PAUSE_DATA_BULK_PREVIEW_ERROR = `${PREFIX}_PAUSE_DATA_BULK_PREVIEW_ERROR` as const;

export const PAUSE_DATA_BULK_REQUEST = `${PREFIX}_PAUSE_DATA_BULK_REQUEST` as const;
export const PAUSE_DATA_BULK_SUCCESS = `${PREFIX}_PAUSE_DATA_BULK_SUCCESS` as const;
export const PAUSE_DATA_BULK_ERROR = `${PREFIX}_PAUSE_DATA_BULK_ERROR` as const;

export const RESUME_BULK_REQUEST = `${PREFIX}RESUME_BULK_REQUEST` as const;
export const RESUME_BULK_SUCCESS = `${PREFIX}RESUME_BULK_SUCCESS` as const;
export const RESUME_BULK_ERROR = `${PREFIX}RESUME_BULK_ERROR` as const;

export const RESUME_DATA_BULK_PREVIEW_REQUEST =
  `${PREFIX}_RESUME_DATA_BULK_PREVIEW_REQUEST` as const;
export const RESUME_DATA_BULK_PREVIEW_SUCCESS =
  `${PREFIX}_RESUME_DATA_BULK_PREVIEW_SUCCESS` as const;
export const RESUME_DATA_BULK_PREVIEW_ERROR = `${PREFIX}_RESUME_DATA_BULK_PREVIEW_ERROR` as const;

export const RESUME_DATA_BULK_REQUEST = `${PREFIX}_RESUME_DATA_BULK_REQUEST` as const;
export const RESUME_DATA_BULK_SUCCESS = `${PREFIX}_RESUME_DATA_BULK_SUCCESS` as const;
export const RESUME_DATA_BULK_ERROR = `${PREFIX}_RESUME_DATA_BULK_ERROR` as const;

export const SET_TUNNELABLE_BULK_REQUEST = `${PREFIX}SET_TUNNELABLE_BULK_REQUEST` as const;
export const SET_TUNNELABLE_BULK_SUCCESS = `${PREFIX}SET_TUNNELABLE_BULK_SUCCESS` as const;
export const SET_TUNNELABLE_BULK_ERROR = `${PREFIX}SET_TUNNELABLE_BULK_ERROR` as const;

export const UPDATE_OVERAGE_BULK_REQUEST = `${PREFIX}UPDATE_OVERAGE_BULK_REQUEST` as const;
export const UPDATE_OVERAGE_BULK_SUCCESS = `${PREFIX}UPDATE_OVERAGE_BULK_SUCCESS` as const;
export const UPDATE_OVERAGE_BULK_ERROR = `${PREFIX}UPDATE_OVERAGE_BULK_ERROR` as const;

export const MOVE_BULK_REQUEST = `${PREFIX}MOVE_BULK_REQUEST` as const;
export const MOVE_BULK_SUCCESS = `${PREFIX}MOVE_BULK_SUCCESS` as const;
export const MOVE_BULK_ERROR = `${PREFIX}MOVE_BULK_ERROR` as const;

export const GET_TOPICS_REQUEST = `${PREFIX}GET_TOPICS_REQUEST` as const;
export const GET_TOPICS_SUCCESS = `${PREFIX}GET_TOPICS_SUCCESS` as const;
export const GET_TOPICS_ERROR = `${PREFIX}GET_TOPICS_ERROR` as const;

export const SET_EXPORT_COUNT = `${PREFIX}SET_EXPORT_COUNT` as const;

export const GET_HISTORIC_DEVICE_COUNT_REQUEST =
  `${PREFIX}_GET_HISTORIC_DEVICE_COUNT_REQUEST` as const;
export const GET_HISTORIC_DEVICE_COUNT_SUCCESS =
  `${PREFIX}_GET_HISTORIC_DEVICE_COUNT_SUCCESS` as const;
export const GET_HISTORIC_DEVICE_COUNT_ERROR = `${PREFIX}_GET_HISTORIC_DEVICE_COUNT_ERROR` as const;

export const GET_TASKS_REQUEST = `${PREFIX}_GET_TASKS_REQUEST` as const;
export const GET_TASKS_SUCCESS = `${PREFIX}_GET_TASKS_SUCCESS` as const;
export const GET_TASKS_ERROR = `${PREFIX}_GET_TASKS_ERROR` as const;

export const GET_COMPLETED_TASKS_REQUEST = `${PREFIX}_GET_COMPLETED_TASKS_REQUEST` as const;
export const GET_COMPLETED_TASKS_SUCCESS = `${PREFIX}_GET_COMPLETED_TASKS_SUCCESS` as const;
export const GET_COMPLETED_TASKS_ERROR = `${PREFIX}_GET_COMPLETED_TASKS_ERROR` as const;

export const GET_TASK_PAGES_REQUEST = `${PREFIX}_GET_TASK_PAGES_REQUEST` as const;
export const GET_TASK_PAGES_SUCCESS = `${PREFIX}_GET_TASK_PAGES_SUCCESS` as const;
export const GET_TASK_PAGES_ERROR = `${PREFIX}_GET_TASK_PAGES_ERROR` as const;

export const GET_TASK_PROGRESS_REQUEST = `${PREFIX}_GET_TASK_PROGRESS_REQUEST` as const;
export const GET_TASK_PROGRESS_SUCCESS = `${PREFIX}_GET_TASK_PROGRESS_SUCCESS` as const;
export const GET_TASK_PROGRESS_ERROR = `${PREFIX}_GET_TASK_PROGRESS_ERROR` as const;

export const FETCH_DEVICE_FIELDS_REQUEST = `${PREFIX}_FETCH_DEVICE_FIELDS_REQUEST` as const;
export const FETCH_DEVICE_FIELDS_SUCCESS = `${PREFIX}_FETCH_DEVICE_FIELDS_SUCCESS` as const;
export const FETCH_DEVICE_FIELDS_ERROR = `${PREFIX}_FETCH_DEVICE_FIELDS_ERROR` as const;

export const GET_BATCH_JOB_DETAILS_REQUEST = `${PREFIX}_GET_BATCH_JOB_DETAILS_REQUEST` as const;
export const GET_BATCH_JOB_DETAILS_SUCCESS = `${PREFIX}_GET_BATCH_JOB_DETAILS_SUCCESS` as const;
export const GET_BATCH_JOB_DETAILS_ERROR = `${PREFIX}_GET_BATCH_JOB_DETAILS_ERROR` as const;
export const GET_BATCH_JOB_DETAILS_REQUEST_ONLY_DEVICES =
  `${PREFIX}_GET_BATCH_JOB_DETAILS_REQUEST_ONLY_DEVICES` as const;
export const GET_BATCH_JOB_DETAILS_SUCCESS_ONLY_DEVICES =
  `${PREFIX}_GET_BATCH_JOB_DETAILS_SUCCESS_ONLY_DEVICES` as const;
