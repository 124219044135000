import moment from 'moment-timezone';
import {
  TIMEQUICKFILTER_24HOURS,
  TIMEQUICKFILTER_7DAYS,
  TIMEQUICKFILTER_LASTMONTH,
  TIMEQUICKFILTER_THISMONTH,
} from '../constants/quickFilters';

export const getQuickFilter = (type) => {
  const now = moment.utc();
  let endDate = moment(now).endOf('day');
  let startDate = moment(endDate);

  if (type === TIMEQUICKFILTER_24HOURS) {
    startDate = startDate.startOf('day');
  } else if (type === TIMEQUICKFILTER_7DAYS) {
    startDate = startDate.subtract(7, 'days');
    endDate = endDate.subtract(1, 'days');
  } else if (type === TIMEQUICKFILTER_THISMONTH) {
    startDate = startDate.startOf('month');
  } else if (type === TIMEQUICKFILTER_LASTMONTH) {
    startDate = startDate.subtract(1, 'month').startOf('month');
    endDate = endDate.subtract(1, 'month').endOf('month');
  }

  startDate = startDate.valueOf();
  endDate = endDate.valueOf();

  return { startDate, endDate };
};
