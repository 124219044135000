import { createSelector } from 'reselect';
import Moment from 'moment-timezone';
import { PAUSED_PENDING_USER } from 'common-js/constants/deviceStates';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';

const TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const READABLE_FORMAT = '[on] MMM D, YYYY [at] H:mm [UTC.]';

// Returns the formatted timestamp when the device was last paused
// returns an empty string if the device has never been paused
const selectLastPausedDate = createSelector(selectCurrentDevice, (device) => {
  const pauseEvents = device.statusHistory.filter(
    ({ newstate }) => newstate === PAUSED_PENDING_USER
  );

  if (pauseEvents.length === 0) {
    return '';
  }

  const lastPause = pauseEvents[0];

  return Moment(lastPause.timestamp, TIMESTAMP_FORMAT).format(READABLE_FORMAT);
});

export default selectLastPausedDate;
