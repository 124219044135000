const KEY_TRANSLATIONS = {
  eid: 'eID',
  iccid: 'ICCID',
  id: 'Device ID',
  imei: 'IMEI',
  imsi: 'IMSI',
  linkid: 'Link ID',
  msisdn: 'MSISDN',
  phonenumber: 'Phone Number',
  sim: 'ICCID',
  name: 'Device Name',
  simcardid: 'SIM ID',
} as const;

export default KEY_TRANSLATIONS;
