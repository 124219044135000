import React from 'react';
import { useFormContext } from 'react-hook-form';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { addCommasToNumber } from '../../common/utils/numberFormatter';
import Footer, { ContinueButton, StatusText } from '../common/scaffolding/Footer';

const actTypeToName = {
  list: 'Import SIM List',
  individual: 'Add SIMs',
  range: 'Add SIM Range',
};

function SimsFooter({ nextStep, hasBillingPermissions }) {
  const { watch } = useFormContext();
  const { total, inputOptionUsed } = watch('sims') ?? {};

  const { totalDue } = useAppSelector((state) => state.activation.general);
  const { hasPostpay } = useAppSelector((state) => state.organization.balance);
  const ccOnFile = useAppSelector((state) => state.organization.billingInformation.isCardSet);
  const availableBalance = useAppSelector(
    (state) => state?.organization?.balance?.availableBalance ?? 0
  );

  let isDisabled = total < 1;

  if (!isDisabled && !hasPostpay && !hasBillingPermissions && totalDue > 0) {
    if (ccOnFile) {
      if (totalDue < 5) {
        if (availableBalance > 0) {
          if (totalDue > availableBalance) {
            isDisabled = true;
          }
        } else {
          isDisabled = true;
        }
      }
    } else if (!availableBalance || totalDue > availableBalance) {
      isDisabled = true;
    }
  }

  let continueText = '';

  if (total > 1) {
    continueText = `${addCommasToNumber(total, false)} SIMs added`;
  } else if (total === 1) {
    continueText = '1 SIM added';
  } else {
    continueText = `${actTypeToName[inputOptionUsed] ?? 'Add SIMs'} to continue`;
  }

  return (
    <Footer
      right={
        <>
          <StatusText>{continueText}</StatusText>
          <ContinueButton onClick={nextStep} disabled={isDisabled} />
        </>
      }
    />
  );
}

export default SimsFooter;
