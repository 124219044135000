import Moment from 'moment-timezone';
import generateCSV from 'common-js/utils/generateCSV';

const exportCSVFile = (headers, rows, label) =>
  generateCSV([headers].concat(rows), `${label} ${Moment().format('YYYY-MM-DD')}.csv`);

export const exportCSV = (loadedData) => {
  const headers = ['dateofusage', 'numdevices', 'datausage'];

  const rows = loadedData.map((data) => [data.dateofusage, data.numDevices, data.datausage]);

  exportCSVFile(headers, rows, 'dataused');
};

export const exportInspectCSV = (loadedData) => {
  const headers = ['date', 'avgdailyusage', 'sessions', 'sms', 'total_bytes', 'total_devices'];

  const rows = loadedData.map((data) => [
    data.date,
    ((data.total_bytes * 1) / (data.sessions * 1)).toFixed(2), // cast to strings to avoid toFixed errors
    data.sessions,
    data.sms,
    data.total_bytes,
    data.total_devices,
  ]);

  exportCSVFile(headers, rows, 'datausedinspect');
};
