import ClassNames from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@holokit/core';

function HyperNotification({
  iconName,
  iconSize,
  iconClasses,
  iconSvgProps,
  iconIsVisible,
  heading,
  headingClasses,
  message,
  messageClasses,
  description,
  descriptionClasses,
  classes,
  style,
  rollback,
}) {
  return (
    <div
      className={ClassNames('HyperNotification', classes, {
        'HyperNotification--rollback': rollback,
      })}
      style={style}
    >
      {iconIsVisible && (
        <Icon
          name={iconName}
          size={iconSize}
          classes={ClassNames('HyperNotification__icon', iconClasses, {
            'HyperNotification__icon--rollback': rollback,
          })}
          svgProps={iconSvgProps}
        />
      )}
      <div className="HyperNotification__content grid-row">
        <div className="grid-item">
          {heading && (
            <div className={ClassNames('HyperNotification__heading', headingClasses)}>
              {heading}
            </div>
          )}
          <div className={ClassNames('HyperNotification__message', messageClasses)}>
            {message}
            {description ? ' - ' : ''}
            {description && (
              <span className={ClassNames('HyperNotification__description', descriptionClasses)}>
                {description}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

HyperNotification.propTypes = {
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  iconClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  // eslint-disable-next-line react/forbid-prop-types
  iconSvgProps: PropTypes.object,
  iconIsVisible: PropTypes.bool,
  heading: PropTypes.string,
  headingClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  message: PropTypes.string,
  messageClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  description: PropTypes.string,
  descriptionClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  rollback: PropTypes.bool,
};

HyperNotification.defaultProps = {
  iconName: 'Circle--check',
  iconSize: 'major',
  iconSvgProps: {},
  iconIsVisible: true,
  rollback: false,
};

export default HyperNotification;
