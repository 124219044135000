import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import find from 'lodash/find';
import * as API from '../../../api';
import { UserInfoModel } from '../../../models';
import * as orgActions from '../../organization/actions';
import * as actionTypes from '../actionTypes';
import { setRoadblocksFromUserSettings } from './actions';
import updateUserSettings from './updateUserSettings';

export const registerAccount =
  ({ email, firstName, lastName, password, terms, orgInviteId, orgInviteToken }) =>
  async (dispatch) => {
    // Inline validation fails and customer gets this far without agreeing to TOS, reject request.
    if (!terms) {
      return null;
    }

    // Initilizes state in redux store, doesn't do much else
    dispatch({
      type: actionTypes.REGISTER_REQUEST,
    });

    try {
      const response = await API.register(
        email,
        firstName,
        lastName,
        password,
        orgInviteId,
        orgInviteToken
      );
      dispatch({ type: actionTypes.REGISTER_SUCCESS, payload: { response } });

      return response;
    } catch (error) {
      dispatch({ type: actionTypes.REGISTER_ERROR, payload: { error } });
      return null;
    }
  };

export const configureAccount =
  (
    edition: 'my company' | 'myself',
    terms: boolean,
    orgInviteId: string,
    orgIdToJoin: OrgId | undefined,
    purpose: string,
    source: string | undefined
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const userInfo = new UserInfoModel(state?.account?.registrationResponse?.user);

    const userSettings = {
      'Pre Edition': edition,
      'Accepted TOS EULA': terms ? 'yes' : 'no',
    };

    dispatch({
      type: actionTypes.CONFIGURE,
      userInfo,
    });

    dispatch({
      type: actionTypes.SET_USER_INFO,
      ...userInfo,
    });

    sendAnalyticsEvent({
      type: analyticsTypes.REGISTRATION,
      data: {
        purpose,
        source,
        date: Date.now(),
      },
    });

    updateUserSettings(userSettings)(dispatch, getState)
      .then(() => orgActions.getOrganizations()(dispatch, getState))
      .then(() => {
        if (orgInviteId) {
          const orgToJoin = find(getState().organization.orgs, {
            id: orgIdToJoin,
          });

          if (orgToJoin) {
            return orgActions.changeContext(orgToJoin.id)(dispatch, getState);
          }
        } else {
          const sharedOrg = find(state().organization.orgs, {
            is_personal: 0,
          });

          if (sharedOrg) {
            return orgActions.changeContext(sharedOrg.id)(dispatch, getState);
          }
        }

        return Promise.resolve();
      })
      .then(() => setRoadblocksFromUserSettings()(dispatch, getState))
      .then(() => dispatch({ type: actionTypes.CONFIGURE_SUCCESS }))
      .catch((error) => dispatch({ type: actionTypes.CONFIGURE_ERROR, payload: error }));
  };
