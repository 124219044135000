import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UsageOverview as UsageOverviewComponent } from '../../components';
import { getUsageSummary } from '../../../common/reducers/usage/actions';

function UsageOverview({ loading, error, data, getUsageSummary_ }) {
  return (
    <UsageOverviewComponent
      loading={loading}
      data={data}
      error={error}
      getUsageSummary={getUsageSummary_}
    />
  );
}

export default connect(
  (state) => ({
    loading: state.usage.charts.usagesummary.loading,
    error: state.usage.charts.usagesummary.error,
    data: state.usage.charts.usagesummary.data,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getUsageSummary_: getUsageSummary,
      },
      dispatch
    )
)(UsageOverview);
