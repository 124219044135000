export const getOperatorDisplayName = (iccid) => {
  const unknownOperatorName = '—';
  if (!iccid || iccid.length < 3) {
    return unknownOperatorName;
  }
  const operatorNameMap = {
    894: 'Hologram Global',
  };
  return operatorNameMap[iccid.slice(0, 3)] || unknownOperatorName;
};
