import React from 'react';
import PropTypes from 'prop-types';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { browserHistory } from 'react-router';
import { Dropdown } from '@holokit/core';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { LAST_ACTIVE, USAGE } from 'common-js/constants/deviceFilterFields';
import * as Paths from 'common-js/constants/paths';
import { getFilterCount } from 'common-js/reducers/devices/selectors';
import {
  getReleaseFlags,
  selectBmpPredeploymentTestingFeatureLevel,
} from 'common-js/reducers/releaseFlag/selectors';
import ExportDevices from './ExportDevices';
import {
  ActivationDateFilter,
  DeviceNameFilter,
  ImeiFilter,
  LastActiveFilter,
  DataPlanFilter,
  SidebarHeader,
  StatusFilter,
  TagsFilter,
  UsageFilter,
} from './filters';

const DevicesSidebar = ({
  deviceCountActive,
  deviceCountDeactivated,
  deviceCountPreflight,
  filtersToDefaultOpen,
  isInitialLoad,
  setTimeFilters,
  stateSegment,
  stateSegmentName,
}) => {
  const filterCount = useAppSelector(getFilterCount);
  const releaseFlags = useAppSelector(getReleaseFlags);
  const bmpPredeploymentFlag = useAppSelector(selectBmpPredeploymentTestingFeatureLevel);

  const activeDisabled = deviceCountActive === 0;
  const preflightDisabled =
    !releaseFlags.preflight || (deviceCountPreflight === 0 && bmpPredeploymentFlag !== 'lite');
  const deactivatedDisabled = deviceCountDeactivated === 0;

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const dropdownText =
    stateSegmentName === 'preflight'
      ? 'Pre-deployment devices'
      : `${capitalizeFirstLetter(stateSegmentName)} devices`;

  return (
    <div className="DevicesSidebar">
      {!isInitialLoad && (
        <>
          <Dropdown
            buttonClasses="DevicesSidebar__segment__button"
            classes="DevicesSidebar__segment DevicesSidebar__segment--updated"
            dropdownText={dropdownText}
            // update disabled logic to disable dropdown if there is only one type of device available
            disabled={
              (stateSegmentName === 'active' && preflightDisabled && deactivatedDisabled) ||
              (stateSegmentName === 'preflight' && activeDisabled && deactivatedDisabled) ||
              (stateSegmentName === 'deactivated' && preflightDisabled && activeDisabled)
            }
            segmentDropdown
            legacySegmentDropdown={false}
            items={[
              { header: true, children: 'Select' },
              {
                children: 'Active devices',
                caption: 'Devices that are connected to the Hologram network.',
                disabled: activeDisabled,
                onClick: () => {
                  browserHistory.push(Paths.withContext(Paths.DEVICES_DEVICES));
                  sendAnalyticsEvent({
                    type: analyticsTypes.FILTERS_SWITCH_SEGMENT,
                    data: {
                      segment_name: 'active',
                    },
                  });
                },
                closeAfterClick: true,
                supportingLabel: deviceCountActive,
              },
              {
                children: 'Pre-deployment devices',
                caption: 'Devices that are in testing and have not been activated.',
                disabled: preflightDisabled,
                onClick: () => {
                  browserHistory.push(Paths.withContext(Paths.DEVICES_PREFLIGHT));
                  sendAnalyticsEvent({
                    type: analyticsTypes.FILTERS_SWITCH_SEGMENT,
                    data: {
                      segment_name: 'preflight',
                    },
                  });
                },
                closeAfterClick: true,
                supportingLabel: deviceCountPreflight,
              },
              {
                children: 'Deactivated devices',
                caption: 'Devices that have been deactivated.',
                disabled: deactivatedDisabled,
                onClick: () => {
                  browserHistory.push(Paths.withContext(Paths.DEVICES_DEACTIVATED));
                  sendAnalyticsEvent({
                    type: analyticsTypes.FILTERS_SWITCH_SEGMENT,
                    data: {
                      segment_name: 'deactivated',
                    },
                  });
                },
                closeAfterClick: true,
                supportingLabel: deviceCountDeactivated,
              },
            ]}
          />
          <SidebarHeader stateSegment={stateSegment} />
          <div className="DevicesFilter__wrapper">
            <TagsFilter defaultOpen={filtersToDefaultOpen.includes('tag_id')} />
            <DeviceNameFilter />
            <UsageFilter defaultOpen={filtersToDefaultOpen.includes(USAGE)} />
            <StatusFilter defaultOpen={filtersToDefaultOpen.includes('state')} />
            <LastActiveFilter
              defaultOpen={filtersToDefaultOpen.includes(LAST_ACTIVE)}
              setTimeFilters={setTimeFilters}
            />
            <DataPlanFilter />
            <ImeiFilter />
            <ActivationDateFilter setTimeFilters={setTimeFilters} />
          </div>
        </>
      )}
      {filterCount === 0 && <ExportDevices deviceStates={stateSegment} hasTooltip />}
    </div>
  );
};

DevicesSidebar.propTypes = {
  deviceCountActive: PropTypes.number.isRequired,
  deviceCountDeactivated: PropTypes.number.isRequired,
  deviceCountPreflight: PropTypes.number.isRequired,
  releaseFlags: PropTypes.shape({
    preflight: PropTypes.bool,
  }),
  stateSegment: PropTypes.arrayOf(PropTypes.string).isRequired,
  stateSegmentName: PropTypes.string.isRequired,
};

export default DevicesSidebar;
