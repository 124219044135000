import { Panel } from '@hologram-dimension/panel';
import BillingEmailCCForm from 'common-js/forms/BillingEmailCCForm';
import React from 'react';

const BillingEmailCC: React.FC = () => (
  <div className="BillingEmailCC">
    <Panel
      header="Billing email notifications"
      contentWell="Specify where notifications are sent when your credit card is charged or a new invoice is generated. Enter email addresses separated by commas. For example, “first.last@company.com, second.person@company.com”."
    >
      <BillingEmailCCForm />
    </Panel>
  </div>
);

export default BillingEmailCC;
