import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LiveUsageChart as LiveUsageChartComponent } from '../../../components';

function LiveUsageChart({ loading = false, error = null, data = [] }) {
  return <LiveUsageChartComponent loading={loading} error={error} data={data} />;
}

export default connect(
  (state) => ({
    loading: state.usage.charts.liveusage.loading,
    error: state.usage.charts.liveusage.error,
    data: state.usage.charts.liveusage.data,
  }),
  (dispatch) => bindActionCreators({}, dispatch)
)(LiveUsageChart);
