import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useOnDeactivateEvent from './useOnDeactivateEvent';

const useHandleDeactivate = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const onDeactivateEvent = useOnDeactivateEvent();

  return useCallback(() => {
    dispatch(
      openModal(
        'DeactivateSimModal',
        {
          devices: [device],
          action: onDeactivateEvent,
        },
        'medium no-padding vertical-center'
      )
    );
  }, [dispatch, device, onDeactivateEvent]);
};

export default useHandleDeactivate;
