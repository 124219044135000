import { BannerNotification } from '@hologram-dimension/banner-notification';
import analyticsEventBuilder from 'common-js/analytics';
import { useEffect, type FC } from 'react';

interface CreditCardErrors {
  paymentSaveError?: string;
}

interface PaymentErrorsProps {
  ccErrors: CreditCardErrors;
}

const PaymentErrors: FC<PaymentErrorsProps> = ({ ccErrors }) => {
  const { paymentSaveError } = ccErrors;

  useEffect(() => {
    if (paymentSaveError) {
      analyticsEventBuilder
        .dataEntry('Payment method entry', `Payment method save error: ${paymentSaveError}`)
        .send();
    }
  }, [paymentSaveError]);

  if (!ccErrors) {
    return null;
  }

  return (
    <div className="PaymentErrors__wrapper">
      {paymentSaveError && (
        <div className="PaymentPanel__error">
          <BannerNotification variant="error">
            We were unable to save your payment info: {paymentSaveError}
          </BannerNotification>
        </div>
      )}
    </div>
  );
};

export default PaymentErrors;
