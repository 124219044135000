import React, { useCallback, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { toast } from 'react-toastify';
import { Button } from '@hologram-dimension/button';
import { CheckboxField } from '@hologram-dimension/checkbox-field';
import { Loader } from '@holokit/core';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import useRenameDevices from 'common-js/api/devices/useRenameDevices';
import { ModalHeader } from 'common-js/components';
import DevicePrefixForm from 'common-js/components/DevicePrefixForm';
import {
  ErrorModal,
  LoadingChipsSection,
  LinksSection,
  OverLimitModal,
  Section,
} from 'common-js/modals/devices';
import { clearSelection, fetchDevices } from 'common-js/reducers/devices/actions';
import { close } from 'common-js/reducers/modal/actions';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import useFetchBulkRenamePreview from './useFetchBulkRenamePreview';

const NUM_CHIPS = 9;

interface BulkRenameModalProps {
  overLimit?: boolean;
}

const BulkRenameModal = ({ overLimit }: BulkRenameModalProps) => {
  const [confirmed, setConfirmed] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [showConfirmationError, setShowConfirmationError] = useState(false);

  const dispatch = useAppDispatch();

  const { loading, apiError, devices, totalDevicesCount, setApiError } =
    useFetchBulkRenamePreview();

  const fetchRenameDevices = useRenameDevices({ preview: false });

  const deviceCountWithCommas = addCommasToNumber(totalDevicesCount, false);
  const deviceCountLabel = `${deviceCountWithCommas} device${totalDevicesCount !== 1 ? 's' : ''}`;
  const deviceNames = devices.map((device) => device.name);
  const firstICCID = devices.at(0)?.iccid ?? '';
  const suffix = firstICCID.slice(-5) ?? '';

  const confirmAction = useCallback(() => {
    setConfirmed((value) => !value);
    analyticsEventBuilder.buttonClick('Bulk Action', 'Rename Modal', 'Confirm').send();
  }, [setConfirmed]);

  const handleBackToDashboardClick = useCallback(() => {
    dispatch(close());
    analyticsEventBuilder.buttonClick('Bulk Action', 'Rename Modal', 'Close').send();
  }, [dispatch]);

  const handleCloseModalClick = useCallback(() => {
    dispatch(close());
    analyticsEventBuilder.buttonClick('Bulk Action', 'Rename Modal', 'Cancel').send();
  }, [dispatch]);

  const handleCtaClick = useCallback(async () => {
    analyticsEventBuilder.buttonClick('Bulk Action', 'Rename Modal', 'Continue').send();

    if (!confirmed) {
      analyticsEventBuilder.userError('Rename modal continue error').send();
      setShowConfirmationError(true);
      return;
    }

    setRenaming(true);

    const { error } = await fetchRenameDevices(prefix);

    if (error) {
      setApiError(error);
      return;
    }

    toast(
      <HeadlineToast
        headline="Success!"
        body={`${deviceCountLabel} were renamed.`}
        icon="Checkmark--single"
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toastify-content--burnt toastify-content--success',
        autoClose: 10000,
      }
    );

    dispatch(clearSelection());
    dispatch(fetchDevices());
    dispatch(close());
  }, [
    confirmed,
    deviceCountLabel,
    dispatch,
    fetchRenameDevices,
    prefix,
    setApiError,
    setRenaming,
    setShowConfirmationError,
  ]);

  if (overLimit) {
    return (
      <OverLimitModal
        title="Rename SIM"
        icon="SIM"
        onClose={handleBackToDashboardClick}
        messageBodyVerb="rename SIMs"
        requestType={false}
        analyticsEvent={{
          type: BUTTON_CLICK,
          data: { name: 'Bulk Action - 10k Modal - X Close' },
        }}
      />
    );
  }

  if (apiError || (!loading && totalDevicesCount === 0)) {
    analyticsEventBuilder.errorReturn('Bulk Action', 'Rename Modal', 'Operation Failure').send();

    return <ErrorModal onClose={handleBackToDashboardClick} />;
  }

  const invalidAttemptToContinue = showConfirmationError && !confirmed;

  return (
    <>
      <Loader isLoading={loading} />

      <ModalHeader
        title={loading ? 'Rename devices' : `Rename ${deviceCountLabel}`}
        icon="SIM"
        analyticsEvent={{
          type: BUTTON_CLICK,
          data: { name: 'Bulk Action - Rename Modal - X Close' },
        }}
      />

      <div className="BulkDataActionModal__info-panel BulkRenameModal__info-panel">
        {loading ? (
          'Fetching devices...'
        ) : (
          <>
            Add a custom prefix (up to 24 characters) to the last five digits of your device&rsquo;s
            ICCID.
          </>
        )}
      </div>

      {loading && (
        <div className="BulkDataActionModal__contents">
          <LoadingChipsSection numChips={NUM_CHIPS} />
        </div>
      )}

      {!loading && (
        <>
          <div className="BulkDataActionModal__contents">
            <div className="BulkRenameModal__prefix-container">
              <DevicePrefixForm
                formClass="BulkRenameModal__prefix-form"
                onChange={(newPrefix) => setPrefix(newPrefix)}
                suffix={suffix}
                value={prefix}
              />
            </div>

            <LinksSection
              title="Selected devices"
              devices={deviceNames}
              total={totalDevicesCount}
              limit={NUM_CHIPS}
            />

            <Section title="Confirmation*">
              <CheckboxField
                label=""
                hiddenLabel
                defaultChecked={confirmed}
                invalid={invalidAttemptToContinue}
                onChange={confirmAction}
                options={[
                  {
                    label: `Yes, I want to rename the selected ${deviceCountLabel}.`,
                  },
                ]}
                validationMessage={invalidAttemptToContinue ? 'To continue, please confirm.' : ''}
              />
            </Section>
          </div>

          <div className="BulkDataActionModal__footer BulkRenameModal__footer">
            <Button onClick={handleCloseModalClick} disabled={renaming} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleCtaClick} loading={renaming} variant="primary">
              Continue
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default BulkRenameModal;
