import { FullHeightCalculatorHOC } from 'common-js/components';
import React from 'react';
import UsageMenu from 'usage/components/UsageMenu';
import BaseLayout from './BaseLayout';

interface LayoutUsageProps {
  children?: React.ReactNode;
}

const LayoutUsage: React.FC<LayoutUsageProps> = ({ children }) => (
  <BaseLayout>
    <UsageMenu />
    <FullHeightCalculatorHOC accountForDrawer>
      <div className="container">
        <FullHeightCalculatorHOC classNames="fullHeight-usage" accountForDrawer relative>
          {children}
        </FullHeightCalculatorHOC>
      </div>
    </FullHeightCalculatorHOC>
  </BaseLayout>
);

export default LayoutUsage;
