import _ from 'lodash';
import { generate as generateId } from 'shortid';
import * as accountActionTypes from '../account/actionTypes';
import * as actionTypes from './actionTypes';
import { VIEWMODE_PANEL, SORTBY_NAME_ASC } from './constants';

const initialState: any = {
  apps: [],
  viewMode: VIEWMODE_PANEL,
  sortBy: SORTBY_NAME_ASC,
  searchBy: '',
  appSchemas: [],
  operationApps: [],
  selectedTopics: [],
  appChains: [],
  loadedTopicPreview: [],
  topicPreviews: [],
  nickname: '',
  appChainId: null,
  actionApp: {
    schemaId: null,
    minimized: false,
    values: {},
  },
  matchAllTopics: false,
};

const getDefaultTopicPrefixBySchemaId = (schemas, schemaId) => {
  const schemasUserProps = _.find(schemas, { id: schemaId }).userpropertieslist;

  if (schemasUserProps) {
    const publishToProp = _.find(schemasUserProps, { property: 'publish_to' });

    if (publishToProp) {
      return publishToProp.default_topic_prefix;
    }
  }
  return undefined;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function deviceReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SET_VIEWMODE:
      return { ...state, viewMode: action.viewMode };

    case actionTypes.SET_SORTBY:
      return { ...state, sortBy: action.sortBy };

    case actionTypes.SET_SEARCHBY:
      return { ...state, searchBy: action.searchBy };

    case actionTypes.SET_MATCH_ALL_TOPICS:
      return { ...state, matchAllTopics: action.matchAllTopics };

    case accountActionTypes.LOGOUT_REQUEST:
    case accountActionTypes.LOGIN_REQUEST:
      return { ...state, ...initialState };

    case actionTypes.GET_APPS_SUCCESS:
      return { ...state, apps: action.apps, appChains: action.appChains };

    case actionTypes.GET_APP_SCHEMAS_SUCCESS:
      return { ...state, appSchemas: action.appSchemas };

    case actionTypes.DISABLE_APP_SUCCESS:
      return { ...state, actionApp: { ...state.actionApp, enabled: false } };

    case actionTypes.ENABLE_APP_SUCCESS:
      return { ...state, actionApp: { ...state.actionApp, enabled: true } };

    case actionTypes.LOAD_APPCHAIN: {
      let appChain;

      state.appChains.forEach((_appChain) => {
        _appChain.apps.forEach((app) => {
          if (app.id === action.actionAppId) appChain = _appChain;
        });
      });

      const selectedTopics = appChain.apps[0].tags.map((topic) => ({
        name: topic,
      }));
      const matchAllTopics = appChain.apps[0].match_all_tags;
      let actionApp = appChain.apps[appChain.apps.length - 1];
      const opApps = appChain.apps
        .filter((app, idx) => idx < appChain.apps.length - 1)
        .map((app) => {
          const values = JSON.parse(app.userproperties);
          const defaultTopicPrefix = getDefaultTopicPrefixBySchemaId(
            state.appSchemas,
            app.csrappid
          );
          let defaultRandom;

          if (defaultTopicPrefix && values.publish_to.length > 0) {
            defaultRandom = values.publish_to[0].replace(`${defaultTopicPrefix}_`, '');
          }

          return {
            defaultRandom,
            appId: app.id,
            enabled: actionApp.enabled,
            minimized: true,
            schemaId: app.csrappid,
            viewingAdvanced: false,
            values,
          };
        });

      // now we need to make the loaded data match what needs to be in the appforms schema.
      actionApp = {
        appId: actionApp.id,
        enabled: actionApp.enabled,
        minimized: true,
        schemaId: actionApp.csrappid,
        viewingAdvanced: false,
        values: JSON.parse(actionApp.userproperties),
      };

      return {
        ...state,
        actionApp,
        operationApps: opApps,
        selectedTopics,
        matchAllTopics,
        nickname: appChain.nickname,
        appChainId: appChain.id,
      };
    }

    case actionTypes.UPDATE_CHAIN_VALUES: {
      const opAppValues = action.appChainValues.filter((app) => app.name !== 'action');
      const actionAppValues = _.find(action.appChainValues, { name: 'action' });

      const actionApp = structuredClone(state.actionApp);
      if (actionAppValues) {
        actionApp.values = {
          ...actionApp.values,
          ...actionAppValues.values,
        };
      }

      const { nickname } = state;
      const copiedOperationApps = structuredClone(state.operationApps);

      const operationApps = copiedOperationApps.map((opApp, idx) => {
        const copiedApp = { ...opApp };
        const opValues = _.find(opAppValues, { index: idx });

        if (opValues) {
          copiedApp.values = { ...copiedApp.values, ...opValues.values };
        }

        return copiedApp;
      });

      return { ...state, actionApp, nickname: action.nickname ?? nickname, operationApps };
    }

    case actionTypes.ADD_OPERATION:
      state.operationApps.splice(action.indexOfOperationApp, 0, {
        ...action.operationApp,
        values: {},
        defaultRandom: generateId(),
      });

      return { ...state, operationApps: state.operationApps.slice(0) };
    case actionTypes.UPDATE_OPERATION: {
      const oldOpApp = state.operationApps[action.indexOfOperationApp];
      const updatedOpApp = action.operationApp;

      state.operationApps.splice(action.indexOfOperationApp, 1, {
        ...oldOpApp,
        ...updatedOpApp,
      });

      return { ...state, operationApps: state.operationApps.slice(0) };
    }
    case actionTypes.REMOVE_OPERATION:
      state.operationApps.splice(action.indexOfOperationApp, 1);
      return { ...state, operationApps: state.operationApps.slice(0) };
    case actionTypes.ADD_SELECTED_TOPIC:
      state.selectedTopics.push(action.selectedTopic);
      return { ...state, selectedTopics: state.selectedTopics.slice(0) };
    case actionTypes.REMOVE_SELECTED_TOPIC:
      return {
        ...state,
        selectedTopics: state.selectedTopics.filter(
          (selectedTopic) => selectedTopic !== action.selectedTopic
        ),
        loadedTopicPreview: state.loadedTopicPreview.filter(
          (topic) => topic !== action.selectedTopic.name
        ),
        topicPreviews: state.topicPreviews.filter(
          (topic) => topic.previewForTopic !== action.selectedTopic.name
        ),
      };

    case actionTypes.ADD_ACTION:
      return { ...state, actionApp: action.actionApp };

    case actionTypes.UPDATE_ACTION: {
      const actionAppValueDefaults = {};
      if (!state.actionApp.schemaId && action.actionApp.schemaId) {
        const schema = _.find(state.appSchemas, {
          id: action.actionApp.schemaId,
        });

        schema.userpropertieslist.forEach((prop) => {
          actionAppValueDefaults[prop.property] = prop.default || null;
        });
      }

      return {
        ...state,
        actionApp: {
          ...state.actionApp,
          ...action.actionApp,
          values: actionAppValueDefaults,
        },
      };
    }

    case actionTypes.REMOVE_ACTION:
      return { ...state, actionApp: { schemaId: null, minimized: false } };

    case actionTypes.UPDATE_PREVIEWED_TOPICS_REQUEST:
      return { ...state, isLoadingPreview: true };
    case actionTypes.UPDATE_PREVIEWED_TOPICS_SUCCESS:
      return {
        ...state,
        loadedTopicPreview: action.loadedTopicPreview,
        topicPreviews: action.topicPreviews,
        isLoadingPreview: false,
      };
    case actionTypes.UPDATE_PREVIEWED_TOPICS_ERROR:
      return { ...state, isLoadingPreview: false };
    case actionTypes.CLEAR_ALL_SELECTIONS:
      return {
        ...state,
        selectedTopics: [],
        actionApp: { schemaId: null, minimized: false, viewingAdvanced: false },
        operationApps: [],
        loadedTopicPreview: [],
        topicPreviews: [],
        nickname: '',
      };
    default:
      return state;
  }
}
export default deviceReducer;
