export const isArrayValid = (arg) => Array.isArray(arg) && arg.length > 0;

export const getInvalidSims = (invalid) => {
  if (isArrayValid(invalid)) {
    return invalid
      .filter((invalidSim) => invalidSim?.value)
      .map((sim) => (
        <div key={sim.key}>
          <span className="SimPanel__content__text--bold">{sim.key}</span>: {sim.value}
        </div>
      ));
  }

  return [];
};

export const getDuplicateSimsWarning = (sims) => {
  if (isArrayValid(sims)) {
    return sims.map((sim) => (
      <div key={`${sim}`}>
        <span className="SimPanel__content__text--bold">{sim}</span>: has already been added to the
        list of SIMs for activation
      </div>
    ));
  }

  return [];
};

export const getDuplicateRangeWarning = (range) => {
  if (range?.start && range?.end) {
    return (
      <div>
        <span className="SimPanel__content__text--bold">
          Box {range.start} - {range.end}
        </span>
        : has already been added to the list of SIMs for activation
      </div>
    );
  }

  return undefined;
};

export const getAlreadyActivatedSims = (alreadyActivated) =>
  isArrayValid(alreadyActivated)
    ? alreadyActivated.reduce((acc, curr) => {
        if (curr?.key) {
          return [...acc, curr.key];
        }
        return [...acc];
      }, [])
    : null;

const getQuote = (
  subtotal: number,
  newAutoRefillCharge: number,
  currentPromoBalance: number,
  useBalance: boolean,
  balance: number
) => {
  const newSubtotal = subtotal ? subtotal - newAutoRefillCharge : 0;

  // In case they have a promo balance larger than their subtotal, we don't let this go below 0
  // (this only applies to paying with balance; with credit card, we don't use promo balance at all)
  let newTotal = useBalance ? Math.max(subtotal - currentPromoBalance, 0) : subtotal;

  let newAvailableBalanceChange = 0.0;
  if (useBalance && newTotal > 0.0 && balance >= newTotal) {
    newAvailableBalanceChange = newTotal;
    newTotal = 0.0;
  }

  return { newSubtotal, newAvailableBalanceChange, newTotal };
};

export const getActivationQuote = (
  tasks: any[],
  subtotal: number,
  currentPromoBalance: number,
  useBalance: boolean,
  balance: number
) => {
  // The result will include any autorefill charge, or an upcharge to the minimum credit card charge, so we need to split that out
  let newAutoRefillCharge = 0;
  let newUpcharge = 0;
  tasks.forEach((validTask) => {
    if (validTask?.index) {
      newAutoRefillCharge += parseFloat(validTask.params?.addamount ?? 0);
    } else {
      newUpcharge += parseFloat(validTask.params?.addamount ?? 0);
    }
  });

  const { newSubtotal, newAvailableBalanceChange, newTotal } = getQuote(
    subtotal,
    newAutoRefillCharge,
    currentPromoBalance,
    useBalance,
    balance
  );

  return {
    autoRefillCharge: newAutoRefillCharge,
    subtotal: newSubtotal,
    total: newTotal,
    availableBalanceChange: newAvailableBalanceChange,
    upchargeToCreditCardMin: newUpcharge,
  };
};

export const getChangePlanQuote = (
  subtotal: number,
  currentPromoBalance: number,
  useBalance: boolean,
  balance: number
) => {
  const upchargeToCreditCardMin = useBalance || subtotal === 0 ? 0 : Math.max(5 - subtotal, 0);

  const { newSubtotal, newAvailableBalanceChange, newTotal } = getQuote(
    subtotal,
    0,
    currentPromoBalance,
    useBalance,
    balance
  );

  return {
    autoRefillCharge: 0,
    subtotal: newSubtotal,
    total: newTotal,
    availableBalanceChange: newAvailableBalanceChange,
    upchargeToCreditCardMin,
  };
};
