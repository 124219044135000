/* eslint-disable no-param-reassign */
import moment from 'moment-timezone';
import { TIMEQUICKFILTER_CUSTOM, TIMEQUICKFILTER_THISMONTH } from '../../constants/quickFilters';
import DeviceModel from '../../models/DeviceModel';
import { getQuickFilter } from '../../utils/quickFilter';
import * as accountActionTypes from '../account/actionTypes';
import * as actionTypes from './actionTypes';

export const VIEWMORE_ROWS = 50;
export const INITIAL_VIEWMORE_ROWS = 10;

const initialState = { softPausePolling: false };

export const getDeviceInitialState = () => ({
  device: new DeviceModel(),
  loading: false,
  error: null,
  updatingTags: false,
  exporting: false,
  exportError: null,
  runningReport: false,
  runningInspectReport: true,

  statusHistory: [],
  statusReason: null,
  liveusage: [],
  sessionSample: [],

  euiccHistory: [],
  euiccTestHistory: [],
  profiles: [],

  dataUsed: [],
  dataUsedInspect: [],
  dataBreakdown: [],

  timeQuickFilter: TIMEQUICKFILTER_THISMONTH,
  timeFilter: {
    startDate: moment.utc().startOf('month').valueOf(),
    endDate: moment.utc().endOf('day').valueOf(),
  },

  loaders: {
    device: {
      loading: false,
      error: null,
    },
    liveusage: {
      loading: false,
      error: null,
      viewCount: INITIAL_VIEWMORE_ROWS,
    },
    dataBreakdown: {
      loading: false,
      error: null,
      viewCount: INITIAL_VIEWMORE_ROWS,
      exporting: false,
      exportTooLarge: false,
    },
    dataUsed: {
      loading: false,
      error: null,
    },
    dataUsedInspect: {
      loading: false,
      error: null,
      exporting: false,
      exportTooLarge: false,
    },
    sessionSample: {
      loading: false,
      error: null,
    },
    statusHistory: {
      loading: false,
      error: null,
    },
    euiccHistory: {
      loading: false,
      error: null,
    },
    euiccTestHistory: {
      loading: false,
      error: null,
    },
    profiles: {
      loading: false,
      error: null,
    },
  },
});

const updateDevice = (state, deviceId, newData) => {
  if (!deviceId) {
    // eslint-disable-next-line
    console.error('ERROR, singleDevice reducer requires deviceId. Ignoring reducer update');
    return { ...state };
  }

  return { ...state, [deviceId]: { ...state[deviceId], ...newData } };
};

const setLoading = (state, deviceId, loaderName) => ({
  ...state[deviceId],
  loaders: {
    ...state[deviceId].loaders,
    [loaderName]: {
      ...getDeviceInitialState().loaders[loaderName],
      ...state[deviceId].loaders[loaderName],
      loading: true,
      error: null,
    },
  },
});

const setLoaded = (state, deviceId, loaderName) => ({
  ...state[deviceId],
  loaders: {
    ...state[deviceId].loaders,
    [loaderName]: {
      ...getDeviceInitialState().loaders[loaderName],
      ...state[deviceId].loaders[loaderName],
      loading: false,
      error: null,
    },
  },
});

const setError = (state, deviceId, loaderName, action) => ({
  ...state[deviceId],
  loaders: {
    ...state[deviceId].loaders,
    [loaderName]: {
      ...getDeviceInitialState().loaders[loaderName],
      ...state[deviceId].loaders[loaderName],
      loading: true,
      error: action.error,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/default-param-last
function singleDeviceReducer(state = initialState, action) {
  const { deviceId } = action;

  switch (action.type) {
    case accountActionTypes.LOGOUT_REQUEST:
    case accountActionTypes.LOGIN_REQUEST:
      return { ...state, ...initialState };

    case actionTypes.REGISTER_DEVICE:
      return { ...state, [deviceId]: getDeviceInitialState() };

    case actionTypes.UNREGISTER_DEVICE: {
      const newState = { ...state };
      delete newState[deviceId];
      return { ...newState };
    }

    // Basic Device Request
    case actionTypes.GET_DEVICE_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'device'),
      });
    case actionTypes.GET_DEVICE_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'device'),
        device: action.device,
      });
    case actionTypes.GET_DEVICE_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'device', action),
      });

    case actionTypes.GET_DEVICEEUICCHISTORY_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'euiccHistory'),
      });
    case actionTypes.GET_DEVICEEUICCHISTORY_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'euiccHistory'),
        euiccHistory: action.euiccHistory,
      });
    case actionTypes.GET_DEVICEEUICCHISTORY_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'euiccHistory', action),
      });

    case actionTypes.GET_DEVICEEUICCTESTHISTORY_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'euiccTestHistory'),
      });
    case actionTypes.GET_DEVICEEUICCTESTHISTORY_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'euiccTestHistory'),
        euiccTestHistory: action.euiccTestHistory,
      });
    case actionTypes.GET_DEVICEEUICCTESTHISTORY_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'euiccTestHistory', action),
      });

    case actionTypes.GET_DEVICEPROFILES_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'profiles'),
      });
    case actionTypes.GET_DEVICEPROFILES_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'profiles'),
        profiles: action.profiles,
      });
    case actionTypes.GET_DEVICEPROFILES_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'profiles', action),
      });

    case actionTypes.GET_LIVEUSAGE_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'liveusage'),
      });
    case actionTypes.GET_LIVEUSAGE_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'liveusage'),
        liveusage: action.liveUsage,
      });
    case actionTypes.GET_LIVEUSAGE_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'liveusage', action),
      });

    case actionTypes.GET_SESSIONSAMPLE_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'sessionSample'),
      });
    case actionTypes.GET_SESSIONSAMPLE_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'sessionSample'),
        sessionSample: action.sessionSample,
      });
    case actionTypes.GET_SESSIONSAMPLE_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'sessionSample', action),
      });

    case actionTypes.GET_DATAUSED_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'dataUsed'),
      });
    case actionTypes.GET_DATAUSED_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'dataUsed'),
        dataUsed: action.data,
      });
    case actionTypes.GET_DATAUSED_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'dataUsed', action),
      });

    case actionTypes.GET_DATAUSED_INSPECT_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'dataUsedInspect'),
      });
    case actionTypes.GET_DATAUSED_INSPECT_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'dataUsedInspect'),
        dataUsedInspect: action.data,
      });
    case actionTypes.GET_DATAUSED_INSPECT_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'dataUsedInspect', action),
      });

    case actionTypes.GET_DEVICEBREAKDOWN_REQUEST:
      return updateDevice(state, deviceId, {
        ...setLoading(state, deviceId, 'dataBreakdown'),
      });
    case actionTypes.GET_DEVICEBREAKDOWN_SUCCESS:
      return updateDevice(state, deviceId, {
        ...setLoaded(state, deviceId, 'dataBreakdown'),
        dataBreakdown: action.data,
      });
    case actionTypes.GET_DEVICEBREAKDOWN_ERROR:
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'dataBreakdown', action),
      });

    case actionTypes.SET_REPORT_DATE_TITLE:
      return updateDevice(state, deviceId, {
        reportDateTitle: action.reportDateTitle,
      });

    case actionTypes.RUN_REPORT_START:
      return updateDevice(state, deviceId, { runningReport: true });
    case actionTypes.RUN_REPORT_FINISH:
      return updateDevice(state, deviceId, { runningReport: false });

    case actionTypes.RUN_INSPECT_REPORT_START:
      return updateDevice(state, deviceId, { runningInspectReport: true });
    case actionTypes.RUN_INSPECT_REPORT_FINISH:
      return updateDevice(state, deviceId, { runningInspectReport: false });

    case actionTypes.EXPORT_DATAUSEDINSPECT_REQUEST: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataUsedInspect.exporting = true;
      deviceClone.loaders.dataUsedInspect.exportTooLarge = false;
      deviceClone.loaders.dataUsedInspect.error = null;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.EXPORT_DATAUSEDINSPECT_SUCCESS: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataUsedInspect.exporting = false;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.EXPORT_DATAUSEDINSPECT_TOOLARGE: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataUsedInspect.exporting = false;
      deviceClone.loaders.dataUsedInspect.exportTooLarge = true;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.EXPORT_DATAUSEDINSPECT_ERROR: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataUsedInspect.exporting = false;
      deviceClone.loaders.dataUsedInspect.error = action.error;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.EXPORT_DEVICEBREAKDOWN_REQUEST: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataBreakdown.exporting = true;
      deviceClone.loaders.dataBreakdown.exportTooLarge = false;
      deviceClone.loaders.dataBreakdown.error = null;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.EXPORT_DEVICEBREAKDOWN_SUCCESS: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataBreakdown.exporting = false;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.EXPORT_DEVICEBREAKDOWN_TOOLARGE: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataBreakdown.exporting = false;
      deviceClone.loaders.dataBreakdown.exportTooLarge = true;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.EXPORT_DEVICEBREAKDOWN_ERROR: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.dataBreakdown.exporting = false;
      deviceClone.loaders.dataBreakdown.error = action.error;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.SET_CUSTOM_DATE: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.timeQuickFilter = TIMEQUICKFILTER_CUSTOM;
      deviceClone.timeFilter.startDate = action.filters.startDate;
      deviceClone.timeFilter.endDate = action.filters.endDate;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.SET_QUICK_FILTER: {
      const quickFilters = getQuickFilter(action.quickFilter);

      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.timeFilter.startDate = quickFilters.startDate;
      deviceClone.timeFilter.endDate = quickFilters.endDate;
      deviceClone.timeQuickFilter = action.quickFilter;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.VIEWMORE_LIVEUSAGE: {
      const deviceClone = structuredClone(state[deviceId]);
      deviceClone.loaders.liveusage.viewCount += VIEWMORE_ROWS;

      state[deviceId] = deviceClone;
      return { ...state };
    }

    case actionTypes.TEST_DEVICE_ERROR: {
      return updateDevice(state, deviceId, {
        ...setError(state, deviceId, 'deviceTest', action),
      });
    }

    case actionTypes.SOFT_PAUSE_POLLING:
      return {
        ...state,
        softPausePolling: action.pause,
      };

    default:
      return state;
  }
}

export default singleDeviceReducer;
