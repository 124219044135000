import { openModal } from 'common-js/reducers/modal/actions';
import { autoJoinOrg, changeContext } from 'common-js/reducers/organization/actions';
import { useEffect } from 'react';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

interface UseAutoAcceptOrgInviteProps {
  location: RouterLocation;
}

const useAutoAcceptOrgInvite = ({ location }: UseAutoAcceptOrgInviteProps) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.account?.userId);

  const { orgs } = useAppSelector((state) => state.organization) ?? {};
  const { orgid, inviteid, token, origin } = location.query;

  useEffect(() => {
    if (origin === 'orginviteemail' && userId) {
      const inviteOrgId = parseInt(orgid as string, 10);

      if (!orgs?.some((org) => org.id === inviteOrgId)) {
        dispatch(autoJoinOrg(orgid, inviteid, token)).then(() => {
          dispatch(changeContext(inviteOrgId, true, null)).then(() => {
            dispatch(openModal('OrgAcceptedInviteModal', undefined, 'narrow no-style'));
          });
        });
      } else {
        // User already has the org, but now change context to it if you followed the link. No modal for this case.
        dispatch(changeContext(inviteOrgId, true, null));
      }
    }
  }, [dispatch, inviteid, orgid, orgs, origin, token, userId]);
};

export default useAutoAcceptOrgInvite;
