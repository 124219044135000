export default class UserInfoModel {
  userId = 0;

  firstName = '';

  lastName = '';

  email = '';

  role = null;

  orgId = null;

  hasPassword = false;

  isVerified = false;

  constructor(data) {
    if (!data) return;

    this.userId = data.userid || data.id;
    this.firstName = data.first;
    this.lastName = data.last;
    this.email = data.email;
    this.role = data.role;
    this.orgId = data.personal_org;
    this.impersonator = { userId: null, email: null, role: null };

    if (data.has_password === 1) {
      this.hasPassword = true;
    }

    if (data.is_verified === 1) {
      this.isVerified = true;
    }

    if (data.impersonated_by) {
      this.impersonator = {
        userId: data.impersonated_by.id,
        email: data.impersonated_by.email,
        role: data.impersonated_by.role,
      };
    }
  }
}
