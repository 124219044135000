import { Callout } from '@holokit/core';
import useChangePassword from 'common-js/api/account/useChangePassword';
import { messages, submitLabel } from 'common-js/constants/changePasswordFormStrings';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import React, { useCallback, useState } from 'react';
import SetAccountPasswordForm from './SetAccountPasswordForm';

export default function ChangePasswordForm() {
  const [formLevelError, setFormLevelError] = useState('');

  const userId = useAppSelector((state) => state.account.userId);
  const dispatch = useAppDispatch();

  /* API call */
  const changePasswordApiCall = useChangePassword({ userId });

  /* Form submit handler */
  const handleSubmitError = useCallback(
    (error) => {
      if (typeof error === 'object' && error.password) {
        // If the error is related to the password field, format it for display by the SetAccountPasswordForm
        return { setAccountPasswordFieldError: error.password };
      }
      // If the error is due to any other cause, or an exception was caught, display a form level error
      setFormLevelError(error?.unknown || error);
      return false;
    },
    [setFormLevelError]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        setFormLevelError(''); // Clear any form level errors
        // Call the resetPassword API
        const response = await changePasswordApiCall({
          password: data.password,
        });
        if (response.success) {
          // If the password reset was successful, display confirmation toast
          dispatch(pushGlobalMessage(messages.confirmation.message, 'success'));
          return true;
        }
        // Otherwise, handle the error display based on the API response
        return handleSubmitError(response.error);
      } catch (errorMessage) {
        // If something unexpected happens during form submission, display the error
        return handleSubmitError(errorMessage);
      }
    },
    [changePasswordApiCall, handleSubmitError, dispatch]
  );

  return (
    <>
      {formLevelError && (
        <Callout
          type="error"
          className="form-panel__message"
          text={formLevelError}
          defaultIcon
          iconColor={Callout.THEME.COLOR}
        />
      )}
      <SetAccountPasswordForm
        cancelButton
        fieldLayoutDirection="row"
        onSubmit={onSubmit}
        submitLabel={submitLabel}
      />
    </>
  );
}
