const PREFIX = 'LOG_FILTERS_' as const;

// single device filters
export const DEVICE_SET_DATERANGE = `${PREFIX}DEVICE_SET_DATERANGE` as const;
export const DEVICE_SET_VIEW_ALL = `${PREFIX}DEVICE_SET_VIEW_ALL` as const;
export const DEVICE_RESET = `${PREFIX}DEVICE_RESET` as const;
export const DEVICE_SET_QUERY = `${PREFIX}DEVICE_SET_QUERY` as const;
export const SEARCH_ENTERED = `${PREFIX}SEARCH_ENTERED` as const;
export const SET_LOG_CATEGORY = `${PREFIX}SET_LOG_CATEGORY` as const;
export const SET_DEVICE_FILTER = `${PREFIX}SET_DEVICE_FILTER` as const;
