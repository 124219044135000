import ColorTokens from '@hologram-dimension/tokens/colors';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo, type FC, type ReactNode } from 'react';

const stripePromise = loadStripe(process.env.VITE_STRIPE_PUB_KEY, {
  apiVersion: process.env.VITE_STRIPE_API_VERSION,
});

const {
  DdsColorBorderControlInvalid,
  DdsColorPaletteRed33,
  DdsColorTextPrimaryOndefault,
  DdsColorTextDiminishedOndefault,
  DdsColorBorderControlDefault,
  DdsColorInteractiveSecondaryHover,
} = ColorTokens;

interface DefaultStripeElementsProps {
  children?: ReactNode;
}

const DefaultStripeElements: FC<DefaultStripeElementsProps> = ({ children }) => {
  const elementsOptions: GetReactParameterBag<typeof Elements>['options'] = useMemo(
    () => ({
      appearance: {
        variables: {
          fontFamily: '"DIN Next LT Pro", sans-serif',
          colorText: DdsColorTextPrimaryOndefault,
          colorPrimaryText: DdsColorTextPrimaryOndefault,
          colorDanger: DdsColorBorderControlInvalid,
          colorDangerText: DdsColorPaletteRed33,
          colorTextPlaceholder: DdsColorTextDiminishedOndefault,
          borderRadius: '4px',
        },
        rules: {
          '.Input': {
            borderColor: DdsColorBorderControlDefault,
            boxShadow: '0px 2px 0px 0px rgba(0, 4, 15, 0.08)', // $dds-shadow-drop-xsmall
            fontSize: '0.875rem',
            fontWeight: '400',
            lineHeight: '1.429',
            padding: '8px 16px',
          },
          '.Input:focus': {
            boxShadow:
              '0 0 0 1px #fff, 0 0 0 3px #00040f, inset 0 0 0 1px #acb4c3, 0 2px #545d7914',
            outline: 'none',
            borderColor: 'transparent',
            backgroundColor: DdsColorInteractiveSecondaryHover,
          },
          '.Label': {
            fontSize: '0.875rem',
            fontWeight: '500',
            lineHeight: '1.3333',
          },
          '.Error': {
            paddingTop: '4px',
          },
        },
      },
      fonts: [
        {
          family: 'DIN Next LT Pro',
          src: 'url(/fonts/310614_1_0.eot) format("eot"),url(/fonts/310614_1_0.woff2) format("woff2"),url(/fonts/310614_1_0.woff) format("woff"),url(/fonts/310614_1_0.ttf) format("truetype"),url(/fonts/310614_1_0.svg#DIN_Next_LT_Pro) format("svg")',
          weight: '300',
          display: 'fallback',
        },
        {
          family: 'DIN Next LT Pro',
          src: 'url(/fonts/310614_3_0.eot) format("eot"),url(/fonts/310614_3_0.woff2) format("woff2"),url(/fonts/310614_3_0.woff) format("woff"),url(/fonts/310614_3_0.ttf) format("truetype"),url(/fonts/310614_3_0.svg#DIN_Next_LT_Pro) format("svg")',
          weight: '400',
          display: 'fallback',
        },
        {
          family: 'DIN Next LT Pro',
          src: 'url(/fonts/310614_2_0.eot) format("eot"),url(/fonts/310614_2_0.woff2) format("woff2"),url(/fonts/310614_2_0.woff) format("woff"),url(/fonts/310614_2_0.ttf) format("truetype"),url(/fonts/310614_2_0.svg#DIN_Next_LT_Pro) format("svg")',
          weight: '500',
          display: 'fallback',
        },
        {
          family: 'DIN Next LT Pro',
          src: 'url(/fonts/310614_0_0.eot) format("eot"),url(/fonts/310614_0_0.woff2) format("woff2"),url(/fonts/310614_0_0.woff) format("woff"),url(/fonts/310614_0_0.ttf) format("truetype"),url(/fonts/310614_0_0.svg#DIN_Next_LT_Pro) format("svg")',
          weight: '700',
          display: 'fallback',
        },
      ],
      loader: 'auto',
      mode: 'setup',
      currency: 'usd',
      paymentMethodTypes: ['card'],
      setupFutureUsage: 'off_session',
    }),
    []
  );

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      {children}
    </Elements>
  );
};

export default DefaultStripeElements;
