export default class AppSchemaModel {
  id = 0;

  description = '';

  name = '';

  partnerid = 0;

  userpropertieslist: Array<any> = [];

  isHydrated = false;

  icon = null;

  documentation = null;

  constructor(data?: {
    id: number;
    description: string;
    name: string;
    partnerid: number;
    userpropertieslist: any[];
    icon: any;
    documentation: any;
  }) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.description = data.description;
    this.name = data.name;
    this.partnerid = data.partnerid;
    this.userpropertieslist = data.userpropertieslist;
    this.isHydrated = true;
    this.icon = data.icon;
    this.documentation = data.documentation;
  }
}
