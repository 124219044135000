const getOrgFromPathName = () => {
  let orgId: OrgId | undefined;
  const pathTokens = window.location.pathname.split('/');

  if (pathTokens.length > 3) {
    if (pathTokens[1] === 'org') {
      orgId = parseInt(pathTokens[2], 10);
    }
  }

  return orgId;
};

export default getOrgFromPathName;
