const PREFIX = 'ANALYTICS_' as const;

// event categories
export const BUTTON_CLICK = `${PREFIX}BUTTON_CLICK` as const;
export const DATA_ENTRY = `${PREFIX}DATA_ENTRY` as const;
export const ERROR_RETURN = `${PREFIX}ERROR_RETURN` as const;
export const FILE_EXPORT = `${PREFIX}FILE_EXPORT` as const;
export const FILE_UPLOAD = `${PREFIX}FILE_UPLOAD` as const;
export const PAGE_VIEW = `${PREFIX}PAGE_VIEW` as const;
export const SYSTEM_ERROR = `${PREFIX}SYSTEM_ERROR` as const;

export const IMPERSONATE = `${PREFIX}IMPERSONATE` as const;
export const IMPERSONATE_STOP = `${PREFIX}IMPERSONATE_STOP` as const;

// one-time dashboard actions
export const ACTIVATE_INITIATE = `${PREFIX}ACTIVATE_INITIATE` as const;
export const ACTIVATE_SELECT_PLAN = `${PREFIX}ACTIVATE_SELECT_PLAN` as const;
export const ACTIVATE_ADD_SIMS = `${PREFIX}ACTIVATE_ADD_SIMS` as const;
export const ACTIVATE_ADD_PAYMENT = `${PREFIX}ACTIVATE_ADD_PAYMENT` as const;
export const ACTIVATE_CONFIRM = `${PREFIX}ACTIVATE_CONFIRM` as const;

export const DEACTIVATE_SIM = `${PREFIX}DEACTIVATE_SIM` as const;
export const PAUSE_SIM = `${PREFIX}PAUSE_SIM` as const;
export const RESUME_SIM = `${PREFIX}RESUME_SIM` as const;

export const SPACEBRIDGE_TUNNELING_ENABLED = `${PREFIX}SPACEBRIDGE_TUNNELING_ENABLED` as const;
export const SPACEBRIDGE_TUNNELING_DISABLED = `${PREFIX}SPACEBRIDGE_TUNNELING_DISABLED` as const;

export const DATA_MESSAGE_SENT = `${PREFIX}DATA_MESSAGE_SENT` as const;

export const ORG_CREATED = `${PREFIX}ORG_CREATED` as const;

export const REGISTRATION = `${PREFIX}REGISTRATION` as const;
export const REGISTRATION_ONBOARDING_QUESTION =
  `${PREFIX}REGISTRATION_ONBOARDING_QUESTION` as const;
export const REGISTRATION_SKIP_TO_DASHBOARD = `${PREFIX}REGISTRATION_SKIP_TO_DASHBOARD` as const;

export const REPORT_USAGE_BREAKDOWN = `${PREFIX}REPORT_USAGE_BREAKDOWN` as const;
export const REPORT_USAGE_OVERVIEW = `${PREFIX}REPORT_USAGE_OVERVIEW` as const;
export const REPORT_USAGE_INSPECT = `${PREFIX}REPORT_USAGE_INSPECT` as const;

export const REPORT_SINGLE_DEVICE_USAGE = `${PREFIX}REPORT_SINGLE_DEVICE_USAGE` as const;

export const REPORT_BILLING_HISTORY = `${PREFIX}REPORT_BILLING_HISTORY` as const;
export const REPORT_BILLING_STATEMENT = `${PREFIX}REPORT_BILLING_STATEMENT` as const;

export const FLEET_HOME_METRIC_BUTTON_CLICK = `${PREFIX}FLEET_HOME_METRIC_BUTTON_CLICK` as const;
export const FLEET_HOME_USAGE_FILTER_TAGS = `${PREFIX}FLEET_HOME_USAGE_FILTER_TAGS` as const;
export const FLEET_HOME_REVIEW_ACTION_ITEMS = `${PREFIX}FLEET_HOME_REVIEW_ACTION_ITEMS` as const;
export const FLEET_HOME_ONBOARD_FORM_ANSWER = `${PREFIX}FLEET_HOME_ONBOARD_FORM_ANSWER` as const;

export const FILTERS_SWITCH_SEGMENT = `${PREFIX}FILTERS_SWITCH_SEGMENT` as const;
export const FILTERS_SEE_ALL_OPTIONS = `${PREFIX}FILTERS_SEE_ALL_OPTIONS` as const;
export const FILTERS_CLEAR_ALL = `${PREFIX}FILTERS_CLEAR_ALL` as const;
export const FILTERS_TOGGLE_ACCORDION = `${PREFIX}FILTERS_TOGGLE_ACCORDION` as const;
export const FILTERS_SET_FILTER = `${PREFIX}FILTERS_SET_FILTER` as const;

export const ACTION_LOG_REWIND_CLICK = `${PREFIX}ACTION_LOG_REWIND_CLICK` as const;
export const ACTION_LOG_PREVIOUS_CLICK = `${PREFIX}ACTION_LOG_PREVIOUS_CLICK` as const;
export const ACTION_LOG_NEXT_CLICK = `${PREFIX}ACTION_LOG_NEXT_CLICK` as const;
export const ACTION_LOG_FASTFORWARD_CLICK = `${PREFIX}ACTION_LOG_FASTFORWARD_CLICK` as const;
export const ACTION_LOG_POPOVER_VIEW_COMPLETED_ACTIONS =
  `${PREFIX}ACTION_LOG_POPOVER_VIEW_COMPLETED_ACTIONS` as const;

export const BILLING_TOP_NAV_TAB_CLICK = `${PREFIX}BILLING_TOP_NAV_TAB_CLICK` as const;
export const BILLING_ACCOUNT_REMOVE_CARD = `${PREFIX}BILLING_ACCOUNT_REMOVE_CARD` as const;
export const BILLING_ACCOUNT_UPDATE_CARD = `${PREFIX}BILLING_ACCOUNT_UPDATE_CARD` as const;
export const BILLING_ACCOUNT_UPDATE_ADDRESS = `${PREFIX}BILLING_ACCOUNT_UPDATE_ADDRESS` as const;
export const BILLING_ACCOUNT_ADD_CUSTOM_INVOICE_DETAILS =
  `${PREFIX}BILLING_ACCOUNT_ADD_CUSTOM_INVOICE_DETAILS` as const;
export const BILLING_ACCOUNT_REMOVE_CUSTOM_INVOICE_DETAILS =
  `${PREFIX}BILLING_ACCOUNT_REMOVE_CUSTOM_INVOICE_DETAILS` as const;
export const BILLING_ACCOUNT_EDIT_CUSTOM_INVOICE_DETAILS =
  `${PREFIX}BILLING_ACCOUNT_EDIT_CUSTOM_INVOICE_DETAILS` as const;
export const BILLING_ACCOUNT_ADD_EMAIL_TO_BILLING_NOTIFICATIONS =
  `${PREFIX}BILLING_ACCOUNT_ADD_EMAIL_TO_BILLING_NOTIFICATIONS` as const;
export const BILLING_HISTORY_VIEW_ALL_BALANCE_HISTORY =
  `${PREFIX}BILLING_HISTORY_VIEW_ALL_BALANCE_HISTORY` as const;
export const BILLING_ORDERS_TOGGLE_DETAILS = `${PREFIX}BILLING_ORDERS_TOGGLE_DETAILS` as const;
export const BILLING_ORDERS_SHOW_MORE = `${PREFIX}BILLING_ORDERS_SHOW_MORE` as const;

export const SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_SUCCESS =
  `${PREFIX}SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_SUCCESS` as const;
export const SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_ERROR =
  `${PREFIX}SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_ERROR` as const;
