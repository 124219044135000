import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import Footer, { BackButton, ContinueButton, StatusText } from '../common/scaffolding/Footer';

function RegionFooter({ nextStep, prevStep, changingPlan }) {
  const [continueText, setContinueText] = useState('');
  const regionId = useWatch({
    name: 'region.selected.id',
  });

  useEffect(() => {
    if (regionId > -1) {
      setContinueText('');
    }
  }, [regionId]);

  const handleContinueClick = () => {
    if (regionId === -1) {
      setContinueText('Please choose a region to continue.');
    }
    if (regionId > -1) {
      nextStep();
    }
  };

  return (
    <Footer
      left={!changingPlan && <BackButton onClick={prevStep} />}
      right={
        <>
          <StatusText error>{continueText}</StatusText>
          <ContinueButton onClick={handleContinueClick} />
        </>
      }
    />
  );
}

export default RegionFooter;
