import { useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from 'common-js/api/useApi';
import { getSelectedOrgId } from 'common-js/reducers/organization/selectors';
import { getTerm } from 'common-js/utils/time';

const useGetDeviceBreakdownInspect = () => {
  const apiCall = useApi.call('/reports/:term/usageperdevice/top?:searchParams');
  const orgId = useAppSelector(getSelectedOrgId);

  return useCallback(
    async (filters) => {
      const term = getTerm(filters.timestart, filters.timeend);
      const searchParams = new URLSearchParams({ orgid: orgId, ...filters });

      try {
        const { data } = await apiCall({ urlParams: { searchParams, term } });
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [apiCall, orgId]
  );
};

export default useGetDeviceBreakdownInspect;
