import React, { ReactNode } from 'react';
import { Callout, Panel } from '@holokit/core';

export type MessageType = 'error' | 'success';
export interface Message {
  message: string;
  messageType: MessageType;
}
interface FormPanelProps {
  children?: ReactNode;
  footer?: ReactNode;
  message?: string;
  messageType?: MessageType;
  title?: string;
}

const FormPanel = ({
  children,
  footer,
  message,
  messageType,
  title = 'Form panel title',
}: FormPanelProps) => (
  <Panel classes="form-panel" title={title} footer={footer}>
    {message && (
      <div aria-live="assertive">
        <Callout
          type={messageType}
          className="form-panel__message"
          text={message}
          defaultIcon
          iconColor={Callout.THEME.COLOR}
        />
      </div>
    )}
    {children}
  </Panel>
);

export default FormPanel;
