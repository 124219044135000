import { OrgAcceptedInviteModal as HyperOrgAcceptedInviteModal } from '@hologram-hyper-dashboard/components';
import { ModalCommonProps } from 'common-js/components/Modal';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { close } from 'common-js/reducers/modal/actions';
import { getSelectedOrg } from 'common-js/reducers/organization/selectors';
import { FC, useCallback } from 'react';

interface OrgAcceptedInviteModalProps extends ModalCommonProps {}

const OrgAcceptedInviteModal: FC<OrgAcceptedInviteModalProps> = () => {
  const selectedOrg = useAppSelector(getSelectedOrg);
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  return (
    <HyperOrgAcceptedInviteModal
      orgName={selectedOrg?.name ?? ''}
      onClose={onClose}
      className="HyperDashboardModal"
      defaultOpen
    />
  );
};
export default OrgAcceptedInviteModal;
