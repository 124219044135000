import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const loadCategoryPage = (states, querystring) => (dispatch, getState) => {
  const { page, query, sort, sort_direction: sortDirection } = querystring;

  dispatch({
    type: actionTypes.REPLACE_SEGMENT_SECTION,
    segmentKey: 'state',
    segmentValues: states,
  });

  dispatch({
    type: actionTypes.REMOVE_FILTER,
    filterKey: 'state',
  });

  if (query) {
    dispatch({
      type: actionTypes.UPDATE_QUERY,
      query,
    });
  } else {
    dispatch({ type: actionTypes.RESET_QUERY });
  }

  if (sort) {
    dispatch({
      type: actionTypes.UPDATE_SORT,
      column: sort,
      direction: sortDirection?.toUpperCase() || 'ASC',
    });
  } else {
    dispatch({ type: actionTypes.RESET_SORTING });
  }

  // Currently, we get the start-after ID for each page when we fetch the devices,
  // so we have to fetch twice when we want to load a user onto a page > 1. We
  // should update this to have the backend handle pagination, so we only have to
  // fetch once for any page.
  if (page && page > 1) {
    return dispatch(fetchDevices()).then(() => {
      dispatch({
        type: actionTypes.SET_CURRENT_PAGE,
        page: page > getState().devices.page.limit ? 41 : page,
      });

      return dispatch(fetchDevices());
    });
  }

  dispatch({
    type: actionTypes.RESET_PAGING,
    resetPageIds: true,
  });

  return dispatch(fetchDevices());
};

export default loadCategoryPage;
