import { useEffect } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from 'common-js/api/useApi';
import { selectOrgId } from 'common-js/api/util';
import * as actionTypes from 'common-js/reducers/organization/actionTypes';

const useGetBillingAddress = () => {
  const orgId = useAppSelector(selectOrgId);
  const apiCall = useApi.call(`/organizations/${orgId}/billing/address`);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // The billing information is used in many spots, so we want to load the address into redux
    dispatch({ type: actionTypes.GET_BILLING_ADDRESS });
    apiCall()
      .then(({ data: address }) =>
        dispatch({
          type: actionTypes.GET_BILLING_ADDRESS_SUCCESS,
          address,
        })
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.GET_BILLING_ADDRESS_ERROR,
          error,
        })
      );
  }, [apiCall, dispatch]);
};

export default useGetBillingAddress;
