import { isObject, isString, isError } from 'lodash';

export type DashboardError = Error | { error: string } | string;

const getErrorMessage = (error: DashboardError): string | null => {
  if (isError(error)) {
    return error.message;
  }

  if (isString(error)) {
    return error;
  }

  if (isObject(error)) {
    return error.error;
  }

  return null;
};

export default getErrorMessage;
