import { PageState } from 'src/js/account/containers/VerifyEmail';
import { Message } from 'common-js/components/FormPanel';

export const pageTitles: Record<PageState, string> = {
  processing: 'Verifying account...',
  invalidVerificationLink: 'Invalid account verification',
  passwordNotSet: 'Set account password',
  confirmation: 'Account verification',
};

export const panelTitles: Record<PageState, string> = {
  processing: 'Verifying your account...',
  invalidVerificationLink: 'Account verification',
  passwordNotSet: 'Account password',
  confirmation: 'Account verification',
};

export const messages: Record<PageState, Message> = {
  processing: {
    message: '',
    messageType: 'error',
  },
  invalidVerificationLink: {
    message: 'This URL is no longer valid.',
    messageType: 'error',
  },
  passwordNotSet: {
    message: '',
    messageType: 'error',
  },
  confirmation: {
    message: 'Your account has been verified.',
    messageType: 'success',
  },
};

export const setAccountPasswordSubmitLabel = 'Set password';
