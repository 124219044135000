import React from 'react';
import { Icon, Tooltip } from '@holokit/core';
import * as LogAuthTypes from '../constants/logAuthTypes';

function LogAuthTypeIndicator({ type }) {
  let tooltipContent;
  let typeName;

  switch (type) {
    case LogAuthTypes.OTP:
      tooltipContent = (
        <>
          <b>OTP Verified</b>: Message authenticated by Hologram OTP.
        </>
      );
      typeName = 'OTP';
      break;
    case LogAuthTypes.MULTI_FACTOR:
      tooltipContent = (
        <>
          <b>Multi-factor Verified</b>: Message authenticated by Hologram OTP-M hardware factor.
        </>
      );
      typeName = 'MFA';
      break;
    case LogAuthTypes.KEY:
      tooltipContent = (
        <>
          <b>Device Key Verified</b>: Message authenticated by Hologram device key.
        </>
      );
      typeName = 'KEY';
      break;
    default:
      tooltipContent = null;
      typeName = null;
  }

  return tooltipContent && typeName ? (
    <div className="log-auth-type">
      <Tooltip content={tooltipContent}>
        <div>
          <Icon name="Lock--closed" size="minor" />
          {typeName}
        </div>
      </Tooltip>
    </div>
  ) : null;
}

LogAuthTypeIndicator.defaultProps = {
  type: LogAuthTypes.NONE,
};

export default LogAuthTypeIndicator;
