import clsx from 'clsx';
import { useAppSelector } from 'common-js/hooks';
import { selectDrawerIsOpen } from 'common-js/reducers/drawer/selectors';
import { type FC, type HTMLAttributes, type ReactNode } from 'react';

interface FullHeightCalculatorProps {
  accountForDrawer: boolean;
  backgroundColor?: string;
  children?: ReactNode;
  classNames?: string;
  handleScroll?: () => {};
  relative?: boolean;
}

const FullHeightCalculator: FC<FullHeightCalculatorProps> = ({
  backgroundColor,
  children,
  classNames,
  handleScroll = () => {},
  relative = false,
  accountForDrawer,
}) => {
  const styles: HTMLAttributes<HTMLDivElement>['style'] = {};

  const isDrawerOpen = useAppSelector(selectDrawerIsOpen);

  if (relative) {
    styles.position = 'relative';
  }

  if (backgroundColor) {
    styles.backgroundColor = backgroundColor;
  }

  return (
    <div
      className={clsx(
        `full-height-calculator`,
        isDrawerOpen && accountForDrawer && 'drawer-open',
        classNames
      )}
      style={styles}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
};

export default FullHeightCalculator;
