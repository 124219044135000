import React, { useEffect } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button } from '@hologram-dimension/button';
import { BUTTON_CLICK, PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { CONTACT_SALES, PLAN_DETAILS } from 'common-js/constants/links';
import PackageCard from './parts/PackageCard';

const descriptions = {
  1: 'Everything you need to easily connect and manage your devices.',
  2: 'Level up your business with flexible billing, advanced features, and hands-on support.',
  3: 'Access premium features, dedicated support, and customized billing.',
};

const SettingsSubscription = () => {
  const isAdmin = useAppSelector((state) => state.account.role) === 'adm';
  const orgBmpPackage = useAppSelector((state) => state.organization.package);
  const allBmpPackages = useAppSelector((state) => state.organization.allPackages);

  const packageDescriptions = allBmpPackages?.map((bmpPackage) => ({
    ...bmpPackage,
    description: descriptions[bmpPackage.id],
  }));

  useEffect(() => {
    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: {
        page: 'Settings - Subscription',
        package: `${orgBmpPackage?.name}`,
      },
    });
  }, [orgBmpPackage]);

  const onViewDetailsClick = () => {
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Settings - Subscription - View Subscription Details',
        package: `${orgBmpPackage?.name}`,
      },
    });

    window.open(PLAN_DETAILS, '_blank');
  };

  const onContactSalesClick = () => {
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Settings - Subscription - Contact Sales',
        package: `${orgBmpPackage?.name}`,
      },
    });

    window.open(CONTACT_SALES, '_blank');
  };

  return (
    <div className="SettingsSubscription">
      <div className="SettingsSubscription__heading">
        <h1>Your subscription</h1>
        <div className="SettingsSubscription__button">
          <Button variant="secondary" iconEnd="ExternalLink" onClick={onViewDetailsClick}>
            View details
          </Button>
          {isAdmin && (
            <Button iconEnd="ExternalLink" onClick={onContactSalesClick}>
              Contact sales to update
            </Button>
          )}
        </div>
      </div>
      <div className="SettingsSubscription__CardContainer">
        {packageDescriptions?.map((packageInfo) => (
          <PackageCard
            key={packageInfo.name}
            packageInfo={packageInfo}
            selected={orgBmpPackage?.package_id === packageInfo.id}
          />
        ))}
      </div>
    </div>
  );
};

export default SettingsSubscription;
