import React, { RefObject } from 'react';
import Moment, { Moment as MomentType } from 'moment-timezone';
import classNames from 'clsx';
import * as d3 from 'd3';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';

class DataUsedChartD3 extends React.Component<any, any> {
  chartcontainer: RefObject<any>;

  data: RefObject<any>;

  datacontainer: RefObject<any>;

  xaxislabels: RefObject<any>;

  xhelperbars: RefObject<any>;

  yaxis: RefObject<any>;

  yhelperbars: RefObject<any>;

  constructor(props) {
    super(props);

    this.chartcontainer = React.createRef();
    this.data = React.createRef();
    this.datacontainer = React.createRef();
    this.xaxislabels = React.createRef();
    this.xhelperbars = React.createRef();
    this.yaxis = React.createRef();
    this.yhelperbars = React.createRef();
  }

  componentDidMount() {
    if (this.isHourly()) this.buildHourlyChart();
    else this.buildDailyChart();
  }

  static getYAxisValues(minValue: number, values: Array<number>) {
    const maxValue = Math.max(...values);
    const valueDifference = maxValue - minValue;
    const basePower = `${Math.floor(valueDifference / 10)}`.length;
    const base = 10 ** basePower;
    const yValues = [minValue];

    let currentMultiplier = 1;

    while (currentMultiplier * base < maxValue + base) {
      yValues.push(currentMultiplier * base);
      currentMultiplier += 1;
    }

    return yValues.reverse();
  }

  buildHourlyChart() {
    const { chartDataHeight, data } = this.props;
    const isDataEmpty = data.length === 0;
    const timeZone = Moment.tz.guess();
    let chartData: Array<{ value: number; date: MomentType }>;

    if (isDataEmpty) {
      const currentDate = new Date().toISOString();
      const currentTime = new Date().getHours();

      chartData = [
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(1, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(2, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(3, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(4, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(5, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(6, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(7, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(8, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(9, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(10, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(11, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(12, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(13, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(14, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(15, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(16, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(17, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(18, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(19, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(20, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(21, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(22, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(23, 'hours').tz(timeZone),
        },
        {
          value: 0,
          date: Moment(currentDate).hour(currentTime).subtract(24, 'hours').tz(timeZone),
        },
      ];
    } else {
      chartData = data.map((d) => ({
        value: d.datausage,
        date: Moment(d.dateofusage).hour(d.hourofusage).tz(timeZone),
      }));
    }

    // Start chart building.
    const values = chartData.map((d) => d.value);
    const dates = chartData.map((d) => d.date);
    const yAxisValues = DataUsedChartD3.getYAxisValues(0, values);
    const yAxisValuesEmpty = [150000000, 125000000, 100000000, 75000000, 50000000, 25000000, 0];
    const max = Math.max(...yAxisValues);
    const dataArea = d3.select(this.data.current);

    // Tooltip
    const tooltip = d3
      .select(this.datacontainer.current)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    const XAXIS_TIME_FORMAT = 'ha, UTC';
    const XAXIS_DATE_FORMAT = 'MMM D';
    const DATE_TOOLTIP_FORMAT = 'h:mma, UTC';
    const DAY_TOOLTIP_FORMAT = 'MMM DD, YYYY';

    // Data bars
    dataArea
      .selectAll('div')
      .data(chartData)
      .enter()
      .append('div')
      .attr('class', 'outer-bar')
      .on('mouseover', function dataAreaOnMouseOver(_, d) {
        // use es5 function to keep d3's binding of 'this'

        // Give time for the previous tooltip to process a close, then open a new one here.
        setTimeout(() => {
          tooltip.transition().duration(100).style('opacity', 1);
        }, 4);

        const hoveredBar = d3.select(this).node()?.querySelector<HTMLDivElement>('.inner-bar');

        if (hoveredBar) {
          tooltip
            .html(
              `<div><div class="tooltip-date"><span>${d.date.format(
                DAY_TOOLTIP_FORMAT
              )}</span><span>${d.date.format(
                DATE_TOOLTIP_FORMAT
              )}</span></div><hr><div class="tooltip-data">${toByteStringFormatted(d.value)}<div></div>`
            )
            .style('left', `${hoveredBar.offsetLeft - 35}px`)
            .style('top', `${hoveredBar.offsetTop - 70}px`)
            .on('mouseover', () => {
              tooltip.node()?.setAttribute('locked', 'true');
            })
            .on('mouseout', () => {
              tooltip.node()?.removeAttribute('locked');
            });
        }
      })
      .on('mouseout', () => {
        // give time for the tooltip mouseout to remove the locked attribute if it needs to.
        setTimeout(() => {
          const tooltipLocked = tooltip.node()?.getAttribute('locked') === 'true';

          if (!tooltipLocked) {
            tooltip.transition().duration(200).style('opacity', 0);
          }
        }, 2);
      })
      .append('div')
      .attr('class', 'bar')
      .style('height', (d) => `${(d.value / max) * 100}%`)
      .append('div')
      .attr('class', 'inner-bar');

    // X-Axis Labels
    if (isDataEmpty) {
      d3.select(this.xaxislabels.current)
        .selectAll('div')
        .data(dates)
        .enter()
        .append('div')
        .attr('class', 'x-label')
        .append('div')
        .attr('class', 'x-label-text')
        .text((d, i) =>
          i === 0 || i % 8 === 0 || i === dates.length - 1 ? d.format(XAXIS_TIME_FORMAT) : ''
        )
        .append('div')
        .attr('class', 'x-label-day')
        .text((d, i) =>
          i === 0 || i % 8 === 0 || i === dates.length - 1 ? d.format(XAXIS_DATE_FORMAT) : ''
        );
    } else {
      d3.select(this.xaxislabels.current)
        .selectAll('div')
        .data(dates)
        .enter()
        .append('div')
        .attr('class', 'x-label')
        .append('div')
        .attr('class', 'x-label-text')
        .text((d, i) =>
          i === 0 || i % 8 === 0 || i === dates.length - 1 ? d.format(XAXIS_TIME_FORMAT) : ''
        )
        .append('div')
        .attr('class', 'x-label-day')
        .text((d, i) =>
          i === 0 || i % 8 === 0 || i === dates.length - 1 ? d.format(XAXIS_DATE_FORMAT) : ''
        );
    }

    // Y-Axis Labels
    if (isDataEmpty) {
      d3.select(this.yaxis.current)
        .selectAll('div')
        .data(yAxisValuesEmpty)
        .enter()
        .append('div')
        .attr('class', 'y-label')
        .append('div')
        .attr('class', 'y-label-text')
        .text((d) => toByteStringFormatted(d, 0));

      // Y-Axis Helper Bars
      d3.select(this.yhelperbars.current)
        .selectAll('div')
        .data(
          yAxisValuesEmpty.map(
            (yAxisValue, idx) => (chartDataHeight / (yAxisValuesEmpty.length - 1)) * idx
          )
        ) // Calculate the CSS "top" value for each bar.
        .enter()
        .append('div')
        .attr('class', 'y-bar')
        .style('top', (top) => `${top}px`);
    } else {
      d3.select(this.yaxis.current)
        .selectAll('div')
        .data(yAxisValues)
        .enter()
        .append('div')
        .attr('class', 'y-label')
        .append('div')
        .attr('class', 'y-label-text')
        .text((d) => toByteStringFormatted(d, 0));

      // Y-Axis Helper Bars
      d3.select(this.yhelperbars.current)
        .selectAll('div')
        .data(
          yAxisValues.map((yAxisValue, idx) => (chartDataHeight / (yAxisValues.length - 1)) * idx)
        ) // Calculate the CSS "top" value for each bar.
        .enter()
        .append('div')
        .attr('class', 'y-bar')
        .style('top', (top) => `${top}px`);
    }
  }

  buildDailyChart() {
    const { chartDataHeight, data, emptyWhenEmpty } = this.props;
    const { timeFilter, useTimeFilter } = this.props;
    const hasData = data.length;
    const timeZone = Moment.tz.guess();
    let chartData: Array<{ value: number; date: MomentType }>;

    if (hasData) {
      chartData = data.map((d) => ({
        value: d.datausage,
        date: Moment.utc(d.dateofusage),
      }));
    } else {
      chartData = [];
      for (let i = 6; i > -1; i -= 1) {
        chartData.push({
          value: 0,
          date: Moment().tz(timeZone).utc().subtract(i, 'day'),
        });
      }
    }

    if (useTimeFilter) {
      const dates: Array<MomentType> = [];
      const startDate = Moment(timeFilter.startDate, 'x').startOf('day');
      const endDate = Moment(timeFilter.endDate, 'x').startOf('day');

      while (startDate.diff(endDate) < 0) {
        dates.push(startDate.clone());
        startDate.add(1, 'days');
      }
      dates.push(endDate);

      const newData: Array<{ value: number; date: MomentType }> = [];
      dates.forEach((date) => {
        const newDate = {
          date,
          value: 0,
        };
        chartData.forEach((datum) => {
          if (date.isSame(datum.date, 'day')) {
            newDate.value = datum.value;
          }
        });
        newData.push(newDate);
      });
      chartData = newData;
    }

    // Start chart building.
    const values = chartData.map((d) => d.value);
    const dates = chartData.map((d) => d.date);
    const yAxisValues = DataUsedChartD3.getYAxisValues(0, values);
    const max = Math.max(...yAxisValues);
    const dataArea = d3.select(this.data.current);

    // Tooltip
    const tooltip = d3
      .select(this.datacontainer.current)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    const DATE_TOOLTIP_FORMAT = 'h:mma, UTC';
    const DAY_TOOLTIP_FORMAT = 'MMM DD, YYYY';
    const XAXIS_DATE_FORMAT = 'MMM D';

    if (hasData) {
      // Data bars
      dataArea
        .selectAll('div')
        .data(chartData)
        .enter()
        .append('div')
        .attr('class', 'outer-bar')
        .on('mouseover', function dataAreaOnMouseOver(_, d) {
          // Give time for the previous tooltip to process a close, then open a new one here.
          setTimeout(() => {
            tooltip.transition().duration(100).style('opacity', 1);
          }, 4);

          const hoveredBar = d3.select(this).node()?.querySelector<HTMLDivElement>('.inner-bar');
          if (hoveredBar) {
            tooltip
              .html(
                `<div><div class="tooltip-date"><span>${d.date.format(
                  DAY_TOOLTIP_FORMAT
                )}</span><span>${d.date.format(
                  DATE_TOOLTIP_FORMAT
                )}</span></div><hr><div class="tooltip-data">${toByteStringFormatted(d.value)}<div></div>`
              )
              .style('left', `${hoveredBar.offsetLeft - 35}px`)
              .style('top', `${hoveredBar.offsetTop - 70}px`)
              .on('mouseover', () => {
                tooltip.node()?.setAttribute('locked', 'true');
              })
              .on('mouseout', () => {
                tooltip.node()?.removeAttribute('locked');
              });
          }
        })
        .on('mouseout', () => {
          // give time for the tooltip mouseout to remove the locked attribute if it needs to.
          setTimeout(() => {
            const tooltipLocked = tooltip.node()?.getAttribute('locked') === 'true';

            if (!tooltipLocked) {
              tooltip.transition().duration(200).style('opacity', 0);
            }
          }, 2);
        })
        .append('div')
        .attr('class', 'bar')
        .style('height', (d) => `${(d.value / max) * 100}%`)
        .append('div')
        .attr('class', 'inner-bar')
        .style('background-size', 'auto auto');
    } else {
      dataArea
        .attr('class', 'bc-data bc-data--empty')
        .append('div')
        .attr('class', 'bc-data__message')
        .text(emptyWhenEmpty ? '' : 'No data sessions to display');
    }

    // X-Axis Labels
    d3.select(this.xaxislabels.current)
      .selectAll('div')
      .data(dates)
      .enter()
      .append('div')
      .attr('class', 'x-label')
      .append('div')
      .attr('class', 'x-label-day')
      .text((d) => d.format(XAXIS_DATE_FORMAT));

    // Y-Axis Labels
    d3.select(this.yaxis.current)
      .selectAll('div')
      .data(yAxisValues)
      .enter()
      .append('div')
      .attr('class', 'y-label')
      .append('div')
      .attr('class', 'y-label-text')
      .text((d) => toByteStringFormatted(d, 0));

    // Y-Axis Helper Bars
    d3.select(this.yhelperbars.current)
      .selectAll('div')
      .data(
        yAxisValues.map((yAxisValue, idx) => (chartDataHeight / (yAxisValues.length - 1)) * idx)
      ) // Calculate the CSS "top" value for each bar.
      .enter()
      .append('div')
      .attr('class', 'y-bar')
      .style('top', (top) => `${top}px`);
  }

  isHourly() {
    const { data, defaultToDaily } = this.props;

    return (data.length === 0 && !defaultToDaily) || (data.length > 0 && 'hourofusage' in data[0]);
  }

  render() {
    const { chartDataHeight, classes, data } = this.props;
    const isHourly = this.isHourly();
    const xAxisLength = data.length || isHourly ? 24 : 7;

    return (
      <div
        className={classNames('DataUsedChartD3', classes, {
          hourly: isHourly,
          daily: !isHourly,
        })}
        ref={this.chartcontainer}
      >
        <div className="bc-yscale" ref={this.yaxis} />
        <div className="bc-data-container" ref={this.datacontainer}>
          <div className="bc-data" ref={this.data} style={{ height: chartDataHeight }} />
          <div className="bc-yhelperbars" ref={this.yhelperbars} />
          <div className="bc-xhelperbars" ref={this.xhelperbars} />
        </div>
        <div className={`bc-xscale bc-xscale--${xAxisLength}`} ref={this.xaxislabels} />
      </div>
    );
  }
}

(DataUsedChartD3 as any).defaultProps = {
  classes: null,
  defaultToDaily: false,
  emptyWhenEmpty: false,
  timeFilter: [],
  useTimeFilter: false,
  chartDataHeight: 140,
};

export default DataUsedChartD3;
