import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'clsx';
import { Icon } from '@holokit/core';

function Step({ stepNumber, currentStep, iconName, iconColor, description }) {
  return (
    <div
      className={classNames('CreateOrg__step', {
        'CreateOrg__step--active': stepNumber === currentStep,
      })}
    >
      <Icon name={iconName} size="major" svgProps={{ style: { fill: iconColor } }} />
      <div className="CreateOrg__step__info">
        <div className="CreateOrg__step__info__header">
          Step {stepNumber}
          {stepNumber === 2 && (
            <span className="CreateOrg__step__info__header--optional"> (optional)</span>
          )}
        </div>
        <div className="CreateOrg__step__info__description">{description}</div>
      </div>
    </div>
  );
}

function CreateSteps({ step }) {
  return (
    <div className="CreateOrg__steps">
      <Step
        stepNumber={1}
        currentStep={step}
        iconName="Organization"
        iconColor="#8008F7"
        description="Create organization"
      />
      <Step
        stepNumber={2}
        currentStep={step}
        iconName="User"
        iconColor="#000"
        description="Invite collaborators"
      />
    </div>
  );
}

CreateSteps.propTypes = {
  step: PropTypes.number,
};

CreateSteps.defaultProps = {
  step: 1,
};

export default CreateSteps;
