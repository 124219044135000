import React from 'react';
import _ from 'lodash';
import { Button, Icon, Panel } from '@holokit/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { selectRoutesFlag } from 'common-js/reducers/releaseFlag/selectors';
import {
  clearAllAppSelections,
  disableApp,
  enableApp,
  getAllAppData,
  setSearchBy,
  setSortBy,
} from '../../common/reducers/apps/actions';
import { pushGlobalMessage } from '../../common/reducers/message/actions';
import { AppChainItem } from '../components';
import {
  FullHeightCalculatorHOC,
  HoloHelmet,
  PageLoadingErrorScreen,
  SearchButton,
} from '../../common/components';
import { StellarSelect } from '../../common/components/form';
import {
  SORTBY_NAME_ASC,
  SORTBY_NAME_DES,
  SORTBY_ACTIONTYPE_ASC,
  SORTBY_ACTIONTYPE_DES,
  SORTBY_STATUS_ASC,
  SORTBY_STATUS_DES,
} from '../../common/reducers/apps/constants';
import * as Paths from '../../common/constants/paths';
import * as Links from '../../common/constants/links';

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pageError: null,
    };

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Routes - Overview' },
    });
  }

  componentDidMount() {
    const { getAllAppData_, routesReleaseFlag } = this.props;

    if (!routesReleaseFlag) {
      browserHistory.push(Paths.ACCOUNT_LOGIN);
    }

    try {
      getAllAppData_()
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch((e) => {
          this.setState({ pageError: e, isLoading: false });
        });
    } catch (e) {
      this.setState({ pageError: e, isLoading: false });
    }
  }

  onAddApp() {
    const { clearAllAppSelections_ } = this.props;

    clearAllAppSelections_();
    browserHistory.push(Paths.APPS_ADD);
  }

  getSortedFilteredAppChains() {
    const { appChains, sortBy, searchBy, appSchemas } = this.props;

    return appChains
      .filter((appChain) =>
        searchBy === ''
          ? true
          : appChain.apps[0].tags.join(' ').toLowerCase().includes(searchBy.toLowerCase())
      )
      .sort((app1, app2) => {
        const actionApp1 = app1.apps[app1.apps.length - 1];
        const actionApp2 = app2.apps[app2.apps.length - 1];
        const actionApp1Schema = _.find(appSchemas, {
          id: actionApp1.csrappid,
        });
        const actionApp2Schema = _.find(appSchemas, {
          id: actionApp2.csrappid,
        });
        const app1Nick = app1.nickname || actionApp1Schema.name || '';
        const app2Nick = app2.nickname || actionApp2Schema.name || '';

        switch (sortBy) {
          case SORTBY_NAME_ASC:
            return app1Nick.toLowerCase() > app2Nick.toLowerCase() ? 1 : -1;
          case SORTBY_NAME_DES:
            return app1Nick.toLowerCase() > app2Nick.toLowerCase() ? -1 : 1;
          case SORTBY_ACTIONTYPE_ASC:
            return actionApp1.csrappid > actionApp2.csrappid ? 1 : -1;
          case SORTBY_ACTIONTYPE_DES:
            return actionApp1.csrappid > actionApp2.csrappid ? -1 : 1;
          case SORTBY_STATUS_ASC:
            return actionApp1.enabled ? -1 : 1;
          case SORTBY_STATUS_DES:
            return actionApp1.enabled ? 1 : -1;
          default:
            return app1Nick.toLowerCase() > app2Nick.toLowerCase() ? 1 : -1;
        }
      });
  }

  render() {
    const {
      appChains,
      disableApp_,
      enableApp_,
      pushGlobalMessage_,
      appSchemas,
      searchBy,
      sortBy,
      setSearchBy_,
      setSortBy_,
    } = this.props;
    const { isLoading, pageError } = this.state;
    const sortedFilteredAppChains = this.getSortedFilteredAppChains();

    return (
      <FullHeightCalculatorHOC accountForDrawer>
        <HoloHelmet title="Data Routes" />
        {pageError ? (
          <PageLoadingErrorScreen error={pageError} />
        ) : (
          <div className="apps full-height routes-page">
            {appChains.length > 0 ? (
              <div className="section section-large">
                <Panel>
                  <div className="grid-row">
                    <div className="grid-item h2">Data Routes</div>
                    <Button iconLeft="Plus" onClick={() => this.onAddApp()} type="primary">
                      Create Route
                    </Button>
                  </div>
                </Panel>

                <Panel
                  isLoading={isLoading}
                  title={
                    <div className="grid-row vertical-align form">
                      <div className="grid-row vertical-align">
                        <StellarSelect
                          onChange={(e) => setSortBy_(e.currentTarget.value)}
                          value={sortBy}
                          name="sort"
                        >
                          <option value={SORTBY_NAME_ASC}>Sorted by: Name Ascending</option>
                          <option value={SORTBY_NAME_DES}>Sorted by: Name Descending</option>
                          <option value={SORTBY_ACTIONTYPE_ASC}>
                            Sorted by: Action Type Ascending
                          </option>
                          <option value={SORTBY_ACTIONTYPE_DES}>
                            Sorted by: Action Type Descending
                          </option>
                          <option value={SORTBY_STATUS_ASC}>Sorted by: Status Ascending</option>
                          <option value={SORTBY_STATUS_DES}>Sorted by: Status Descending</option>
                        </StellarSelect>
                      </div>
                      <div className="grid-item" />
                      <div>
                        <SearchButton
                          onChange={(newValue) => setSearchBy_(newValue)}
                          value={searchBy}
                          searchLabel="Search by topics"
                        />
                      </div>
                    </div>
                  }
                  noBodyPadding
                >
                  {sortedFilteredAppChains.length > 0 ? (
                    sortedFilteredAppChains.map((appChain, i) => (
                      <AppChainItem
                        key={i}
                        appChain={appChain}
                        appSchemas={appSchemas}
                        disableApp={disableApp_}
                        enableApp={enableApp_}
                        pushGlobalMessage={pushGlobalMessage_}
                      />
                    ))
                  ) : (
                    <FullHeightCalculatorHOC>
                      <div className="empty-state empty-state-large grid-row vertical-align">
                        <div className="grid-item">
                          <div className="text-centered section section-large message ">
                            <div className="section">
                              <Icon
                                name="Routes"
                                size="major"
                                svgProps={{
                                  style: { fill: '#2F6AFF', stroke: '#2F6AFF' },
                                }}
                              />
                              <h2 className="no-margin">No routes found for these topics.</h2>
                            </div>
                            <div className="help-link">
                              <Icon
                                classes="help-link-icon"
                                name="Book--open"
                                size="minor"
                                svgProps={{ style: { fill: '#04a7ff' } }}
                              />
                              <a href={Links.LEARN_ROUTES} rel="noreferrer" target="_blank">
                                Learn more about using routes
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FullHeightCalculatorHOC>
                  )}
                </Panel>
              </div>
            ) : (
              <Panel isLoading={isLoading}>
                <div className="empty-state empty-state-large grid-row vertical-align">
                  <div className="grid-item">
                    <div className="text-centered section section-large message">
                      <div className="section">
                        <Icon
                          classes="routes-icon"
                          name="Routes"
                          size="major"
                          svgProps={{
                            style: { fill: '#2F6AFF', stroke: '#2F6AFF' },
                          }}
                        />
                        <h2 className="no-margin">You haven’t added any routes yet.</h2>
                        <div className="f2">
                          Routes allow you to route data from your devices to webhooks, slack bots,
                          email, and more!
                        </div>
                      </div>
                      <div className="help-link">
                        <Icon
                          classes="help-link-icon"
                          name="Book--open"
                          size="minor"
                          svgProps={{ style: { fill: '#04a7ff' } }}
                        />
                        <a href={Links.LEARN_ROUTES} rel="noreferrer" target="_blank">
                          Learn more about using routes
                        </a>
                      </div>
                      <Button iconLeft="Plus" onClick={() => this.onAddApp()} type="primary">
                        Add your first route
                      </Button>
                    </div>
                  </div>
                </div>
              </Panel>
            )}
          </div>
        )}
      </FullHeightCalculatorHOC>
    );
  }
}

function mapStateToProps(state) {
  return {
    appChains: state.apps.appChains,
    appSchemas: state.apps.appSchemas,
    routesReleaseFlag: selectRoutesFlag(state),
    searchBy: state.apps.searchBy,
    sortBy: state.apps.sortBy,
    viewMode: state.apps.viewMode,
    topics: state.topic.allTopics,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearAllAppSelections_: clearAllAppSelections,
      disableApp_: disableApp,
      enableApp_: enableApp,
      getAllAppData_: getAllAppData,
      pushGlobalMessage_: pushGlobalMessage,
      setSearchBy_: setSearchBy,
      setSortBy_: setSortBy,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
