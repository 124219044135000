import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeader } from '../components';
import * as accountActions from '../reducers/account/actions';
import * as messageActions from '../reducers/message/actions';

class WhyPhoneNumberModal extends React.Component {
  onSendAgain() {
    const { pushGlobalMessage, resendEmailVerification, userId } = this.props;

    resendEmailVerification(userId)
      .then(() => {
        pushGlobalMessage(
          'Email verification successfully resent. Please check your inbox',
          'success'
        );
      })
      .catch((e) => {
        pushGlobalMessage(e, 'error');
      });
  }

  render() {
    const { email } = this.props;

    return (
      <div className="WhyPhoneNumberModal">
        <ModalHeader title="PLEASE VERIFY YOUR ACCOUNT" />
        <p>
          Before you can contribute, we need to verify your email address. An email containing
          instructions was sent to <b>{email}</b>
        </p>
        <p>
          Didn&apos;t get the email? {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.onSendAgain();
            }}
          >
            Send it again
          </a>
        </p>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    email: state.account.email,
    userId: state.account.userId,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        pushGlobalMessage: messageActions.pushGlobalMessage,
        resendEmailVerification: accountActions.resendEmailVerification,
      },
      dispatch
    )
)(WhyPhoneNumberModal);
