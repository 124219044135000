export const getCookieScriptAcceptanceValue = (permissionName: string): boolean => {
  const cookieScriptState = window.CookieScript?.instance.currentState();

  if (cookieScriptState && cookieScriptState.action === 'accept') {
    return cookieScriptState.categories.includes(permissionName);
  }
  return false;
};

export const setCookieScriptAcceptanceCallback = (
  callback: EventListenerOrEventListenerObject,
  acceptanceType: string
) => {
  const eventName = `CookieScriptCategory-${acceptanceType}`;
  window.addEventListener(eventName, callback);
};
