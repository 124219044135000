import React from 'react';
import _ from 'lodash';
import { Button, Icon, Tooltip } from '@holokit/core';
import { browserHistory } from 'react-router';
import * as Paths from '../../common/constants/paths';
import { RadioSwitch, Topic } from '../../common/components';

const MAX_TOPICS_SHOWN = 2;

class AppChainItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChangingStatus: false,
    };
  }

  onEnableChange() {
    const { appChain, disableApp, enableApp } = this.props;
    const actionApp = appChain.apps[appChain.apps.length - 1];
    const appIds = appChain.apps.map((app) => app.id);

    this.setState({ isChangingStatus: true });
    if (actionApp.enabled) {
      disableApp(appIds).then(() => {
        this.setState({ isChangingStatus: false });
      });
    } else {
      enableApp(appIds).then(() => {
        this.setState({ isChangingStatus: false });
      });
    }
  }

  linkToConfigure() {
    const { appChain } = this.props;
    return Paths.APPS_CONFIGURE.replace(
      `:${Paths.APPS_PARAM_NAME}`,
      appChain.apps[appChain.apps.length - 1].id
    );
  }

  render() {
    const { appChain, appSchemas } = this.props;
    const { isChangingStatus } = this.state;

    const firstApp = appChain.apps[0];
    const actionApp = appChain.apps[appChain.apps.length - 1];
    const opApps = appChain.apps
      .filter((app, idx) => idx < appChain.apps.length - 1)
      .map((opApp) => ({
        ...opApp,
        schema: _.find(appSchemas, { id: opApp.csrappid }),
      }));

    const actionAppSchema = _.find(appSchemas, { id: actionApp.csrappid });

    return (
      <div className="AppChainItem grid-row">
        <div className="title-section">
          <div className="grid-row vertical-align section">
            <div className="grid-item app-nickname">
              <div className="app-nickname-wrap">{appChain.nickname || actionAppSchema.name}</div>
            </div>
          </div>
          <div className="section">
            <RadioSwitch
              onChange={() => this.onEnableChange()}
              checked={actionApp.enabled}
              isProcessing={isChangingStatus}
            />
          </div>
          <div>
            <Button
              iconLeft="Gear"
              onClick={() => browserHistory.push(this.linkToConfigure())}
              type="secondary"
            >
              CONFIGURE
            </Button>
          </div>
        </div>
        <div className="grid-item grid-row">
          <div className="topic-section grid-row v-align h-align grid-column">
            {firstApp.tags
              .filter((tag, idx) => idx < MAX_TOPICS_SHOWN)
              .map((topic, i) => (
                <Topic name={topic} classNames="topic-big" key={i} />
              ))}
            {firstApp.tags.length > MAX_TOPICS_SHOWN && (
              <Tooltip
                content={firstApp.tags
                  .filter((tag, idx) => idx >= MAX_TOPICS_SHOWN)
                  .map((topic, i) => (
                    <div>
                      <Topic name={topic} classNames="topic-big" key={i} />
                    </div>
                  ))}
                interactionKind="click"
                light
              >
                <div className="topic topic-more">
                  {firstApp.tags.length - MAX_TOPICS_SHOWN} more...
                </div>
              </Tooltip>
            )}
            <div className="grid-row">
              <span className="topic-logic-label">TOPIC MATCH LOGIC: </span>
              <span className="topic-logic">{firstApp.match_all_tags ? 'AND' : 'OR'}</span>
            </div>
          </div>
          <div className="chain-ball first-ball grid-item">
            <div className="orb grid-row h-align v-align">in</div>
          </div>
          {opApps.map((opApp, idx) => (
            <div key={idx} className="chain-ball grid-item grid-row v-align h-align">
              {opApp.schema && opApp.schema.icon ? (
                <img src={opApp.schema.icon} alt="" />
              ) : (
                <Icon
                  name="Routes"
                  size="major"
                  svgProps={{ style: { fill: '#8008F7', stroke: '#8008F7' } }}
                />
              )}
              <div className="orb grid-row h-align v-align">op</div>
            </div>
          ))}
          <div className="chain-ball last-ball grid-item grid-row r-align v-align">
            {actionAppSchema.icon ? (
              <img src={actionAppSchema.icon} alt="" />
            ) : (
              <Icon
                name="Routes"
                size="major"
                svgProps={{ style: { fill: '#8008F7', stroke: '#8008F7' } }}
              />
            )}
            <div className="orb grid-row h-align v-align">out</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppChainItem;
