import { ModalHeader } from 'common-js/components';
import AddBalanceForm from 'common-js/forms/AddBalanceForm';
import { useAppDispatch } from 'common-js/hooks';
import { close as closeModal } from 'common-js/reducers/modal/actions';
import { type FC } from 'react';

const AddBalanceModal: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <ModalHeader title="Add balance" />
      <AddBalanceForm
        onCancelClick={() => {
          dispatch(closeModal());
        }}
      />
    </>
  );
};

export default AddBalanceModal;
