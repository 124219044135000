import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import { MessagePanel } from '.';
import * as MessageActions from '../reducers/message/actions';

class GlobalMessage extends React.Component {
  UNSAFE_componentWillMount() {
    const { dismissAll } = this.props;
    dismissAll();
  }

  render() {
    const { messages, dismissOne } = this.props;
    return (
      <div className="GlobalMessage">
        <TransitionGroup>
          {messages.map((message, idx) => (
            <CSSTransition
              classNames="example"
              timeout={{ exit: 300, enter: 500 }}
              key={message.text ?? idx}
            >
              <MessagePanel
                messageType={message.messageType}
                onClick={() => dismissOne(message.id)}
                hasShadow
                hasDismiss
                alignText="center"
              >
                {message.text}
              </MessagePanel>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    messages: state.message.messages,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        dismissOne: MessageActions.dismissOne,
        dismissAll: MessageActions.dismissAll,
      },
      dispatch
    )
)(GlobalMessage);
