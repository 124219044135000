import queryString from 'query-string';
import { browserHistory } from 'react-router';

const removeQueryStringParameter = (parameter: string) => {
  const currentUrl = browserHistory.getCurrentLocation();

  let newUrl: string | undefined;

  try {
    newUrl = queryString.exclude(`${currentUrl.pathname}${currentUrl.search}`, [parameter]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  if (newUrl) {
    browserHistory.replace(newUrl);
  }
};
export default removeQueryStringParameter;
