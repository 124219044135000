import React from 'react';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';

interface StellarMoneyProps {
  amount?: number | string;
  decimalLength?: number;
  showTrailingZeros?: boolean;
  showAsCents?: boolean;
  showCommas?: boolean;
}

class StellarMoney extends React.PureComponent<StellarMoneyProps> {
  static toFixedDecimalLength(decimals, decimalLength) {
    if (decimals.length > decimalLength) {
      return decimals.slice(0, decimalLength);
    }
    return decimals.padEnd(decimalLength, 0);
  }

  static formatCents(amount) {
    const cents = amount;
    if (cents.length < 3) {
      return parseInt(cents, 10);
    }
    return `${cents.slice(0, 2)}.${cents.slice(2)}`;
  }

  static convertAmountToCents(integers, decimals) {
    let cents = decimals;
    let dollars = integers;
    if (cents.length === 1) cents = `${cents}0`;

    if (dollars === '0') {
      return StellarMoney.formatCents(cents);
    }

    dollars = parseInt(dollars, 10);
    cents = parseInt(cents, 10);

    return cents + dollars * 100;
  }

  formatAmount(amount) {
    const {
      decimalLength = 0,
      showTrailingZeros = false,
      showAsCents = false,
      showCommas = false,
    } = this.props;
    const money = parseFloat(amount);

    if (Number.isNaN(money)) {
      return 'Invalid number';
    }

    const stringAmount = money.toString();
    const splitAmount = stringAmount.split('.');
    let integers = splitAmount[0];
    let decimals = splitAmount[1];

    if (showCommas) {
      [integers] = addCommasToNumber(integers).split('.');
    }

    if (!decimals) return `$${integers}.00`;

    if (decimals.length === 1 && !showAsCents && decimalLength < 2) {
      return `$${integers}.${decimals}0`;
    }

    if (decimalLength > 0 && decimalLength !== decimals.length) {
      decimals = StellarMoney.toFixedDecimalLength(decimals, decimalLength);
    }

    if (!showTrailingZeros && decimals) {
      while (decimals.endsWith('0')) {
        decimals = decimals.substr(0, decimals.length - 1);
      }
    }

    if (showAsCents) {
      const cents = StellarMoney.convertAmountToCents(integers, decimals);
      return `${cents}¢`;
    }

    return decimals ? `$${integers}.${decimals}` : `$${integers}`;
  }

  render() {
    const { amount = 0 } = this.props;
    return <span>{this.formatAmount(amount)}</span>;
  }
}

export default StellarMoney;
