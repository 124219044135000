import * as API from '../../api';
import { getUserContextData } from '../../api/util';
import * as actionTypes from './actionTypes';

export function getPoolPlans() {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_POOL_PLANS_REQUEST,
    });

    return API.getPoolPlans(getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_POOL_PLANS_SUCCESS,
          poolPlans: data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_POOL_PLANS_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function subscribeToPool(poolPlanId, isPreview = false) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.SUBSCRIBE_POOL_REQUEST,
    });

    return API.suscribeToPool(poolPlanId, isPreview, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.SUBSCRIBE_POOL_SUCCESS,
          pool: data,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SUBSCRIBE_POOL_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function subscribeToPendingPool(poolPlanId, isPreview = false) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.SUBSCRIBE_PEND_POOL_REQUEST,
    });

    return API.suscribeToPendingPool(poolPlanId, isPreview, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.SUBSCRIBE_PEND_POOL_SUCCESS,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SUBSCRIBE_PEND_POOL_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function subscribeToPoolQuote(poolPlanId, isScheduled) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.QUOTE_REQUEST,
    });

    return API.suscribeToPool(poolPlanId, isScheduled, true, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.QUOTE_SUCCESS,
          quote: data,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.QUOTE_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function unsubscribeToPool(poolId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.UNSUBSCRIBE_POOL_REQUEST,
    });

    return API.unsubscribeToPool(poolId, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.UNSUBSCRIBE_POOL_SUCCESS,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UNSUBSCRIBE_POOL_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function unsubscribeToPending(poolId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.UNSUBSCRIBE_POOL_PENDING_REQUEST,
    });

    return API.unsubscribeToPending(poolId, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.UNSUBSCRIBE_POOL_PENDING_SUCCESS,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UNSUBSCRIBE_POOL_PENDING_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function changePoolSize(poolId, poolPlanId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.CHANGE_SIZE_REQUEST,
    });

    return API.changePoolSize(poolId, poolPlanId, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.CHANGE_SIZE_SUCCESS,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CHANGE_SIZE_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function renewPool(poolId, poolPlanId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.RENEW_POOL_REQUEST,
    });

    return API.renewPool(poolId, poolPlanId, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.RENEW_POOL_SUCCESS,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.RENEW_POOL_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function getPools() {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_POOLS_REQUEST,
    });

    return API.getPools(getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_POOLS_SUCCESS,
          pools: data,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_POOLS_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function getPoolUsage(poolId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_POOL_USAGE_REQUEST,
    });

    return API.getPoolUsage(poolId, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_POOL_USAGE_SUCCESS,
          usage: parseInt(data.bytes),
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_POOL_USAGE_ERROR,
          error,
        });
      });
  };
}

export function getAllPoolInfo(poolId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_ALL_POOL_INFO_REQUEST,
    });

    getPoolUsage(poolId)(dispatch, state);

    const promises = [
      API.getPoolChanges(poolId, getUserContextData(state)),
      API.getPoolPendingChanges(poolId, getUserContextData(state)),
    ];

    return Promise.all(promises)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_ALL_POOL_INFO_SUCCESS,
          changes: data[0],
          pendingChanges: data[1],
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ALL_POOL_INFO_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}
