import React from 'react';
import { FormGroup, InputGroup } from '@holokit/core';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';

const DevicePrefixForm = ({ formClass, inputProps, onChange, onBlur, suffix, value }) => {
  const blurHandler = (event) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const changeHandler = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="DevicePrefixForm">
      <FormGroup
        label="Custom prefix"
        classes={applyConditionalClassNames({
          DevicePrefixForm__form: true,
          [formClass]: !!formClass,
        })}
      >
        <InputGroup
          {...inputProps}
          inputProps={{
            maxLength: 24,
            onBlur: blurHandler,
          }}
          value={value}
          placeholder="Unnamed device"
          classes="DevicePrefixForm__form__input"
          onChange={changeHandler}
        />
        <span className="DevicePrefixForm__form__example_iccid">({suffix})</span>
      </FormGroup>

      <div className="DevicePrefixForm__preview">
        <div className="DevicePrefixForm__preview__label">Preview</div>
        <div className="DevicePrefixForm__preview__text">
          {value || 'Unnamed device'} ({suffix})
        </div>
      </div>
    </div>
  );
};

export default DevicePrefixForm;
