import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Tag from './Tag';
import Input from './Input';
import Suggestions from './Suggestions';

const TAG_NAME_MAX_LENGTH = 32;

const KEYS = {
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
};

const CLASS_NAMES = {
  root: 'react-tag-complete',
  rootFocused: 'is-focused',
  selected: 'react-tags__selected',
  selectedTag: 'react-tags__selected-tag',
  selectedTagName: 'react-tags__selected-tag-name',
  search: 'react-tags__search',
  searchInput: 'react-tags__search-input',
  suggestions: 'react-tags__suggestions',
  suggestionActive: 'is-active',
  suggestionDisabled: 'is-disabled',
};

class TagComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      focused: false,
      inputFocused: false,
      expandable: false,
      selectedIndex: -1,
      cursorPosition: 0,
      classNames: { ...CLASS_NAMES, ...this.props.classNames },
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      classNames: { ...CLASS_NAMES, ...newProps.classNames },
    });
  }

  handleChange(e) {
    const query = e.target.value;

    if (this.props.handleInputChange) {
      this.props.handleInputChange(query);
    }

    this.setState({ query });
  }

  handleKeyDown(e) {
    const { query, selectedIndex } = this.state;

    // when one of the terminating keys is pressed, add current query to the tags.
    if (e.keyCode === KEYS.ENTER || e.keyCode === KEYS.TAB) {
      query && e.preventDefault();

      if (query.length >= this.props.minQueryLength) {
        // Check if the user typed in an existing suggestion.
        const match = this.suggestions.state.options.findIndex(
          (suggestion) => suggestion.name.search(new RegExp(`^${query}$`, 'i')) === 0
        );

        const index = selectedIndex === -1 ? match : selectedIndex;

        if (index > -1) {
          this.addTag(this.suggestions.state.options[index]);
        } else if (this.props.allowNew) {
          this.addTag({ name: query });
        }
      }
    }

    // when backspace key is pressed and query is blank, delete the last tag
    if (e.keyCode === KEYS.BACKSPACE && query.length === 0) {
      if (this.props.tags[this.props.tags.length - 1])
        this.deleteTag(this.props.tags[this.props.tags.length - 1]);
    }

    if (e.keyCode === KEYS.UP_ARROW) {
      e.preventDefault();

      // if last item, cycle to the bottom
      if (selectedIndex <= 0) {
        this.setState({
          selectedIndex: this.suggestions.state.options.length - 1,
        });
      } else {
        this.setState({ selectedIndex: selectedIndex - 1 });
      }
    }

    if (e.keyCode === KEYS.DOWN_ARROW) {
      e.preventDefault();
      this.setState({
        selectedIndex: (selectedIndex + 1) % this.suggestions.state.options.length,
      });
    }
  }

  handleClick(e) {
    if (document.activeElement !== e.target) {
      this.input.input.focus();

      this.setState({
        cursorPosition: 0,
      });
    }
  }

  onAddCurrent() {
    const { query, selectedIndex } = this.state;

    if (query.length >= this.props.minQueryLength) {
      // Check if the user typed in an existing suggestion.
      const match = this.suggestions.state.options.findIndex(
        (suggestion) => suggestion.name.search(new RegExp(`^${query}$`, 'i')) === 0
      );

      const index = selectedIndex === -1 ? match : selectedIndex;

      if (index > -1) {
        this.addTag(this.suggestions.state.options[index]);
      } else if (this.props.allowNew) {
        this.addTag({ name: query });
      }
    }
  }

  handleFocus() {
    this.setState({ focused: true, cursorPosition: 0 });
  }

  addTag(tag) {
    if (tag.disabled) {
      return;
    }

    if (!this.props.allowDuplicates) {
      const matchingTag = this.props.tags.filter((existingTag) => existingTag.name === tag.name);

      if (matchingTag.length > 0) {
        return;
      }
    }

    this.props.onTagAdded(tag);

    // reset the state
    this.setState({
      query: '',
      selectedIndex: -1,
    });
  }

  deleteTag(tag) {
    this.props.onTagDeleted(tag);
    this.setState({ query: '' });
  }

  selectTag(e, i) {
    e.stopPropagation();

    this.setState({
      cursorPosition: this.props.tags.length - i,
      focused: true,
    });

    this.input.input.blur();
  }

  onReset() {
    const { onReset = _.noop } = this.props;
    this.setState({ query: '' });
    onReset();
  }

  onInputFocus() {
    this.setState({ inputFocused: true });
  }

  onInputBlur() {
    this.setState({ inputFocused: false });
  }

  tagNameMap(tag) {
    return tag.name;
  }

  render() {
    const {
      loadingIndicator = null,
      allowDuplicates = false,
      showResetButton = false,
      isTag,
      lightTheme,
    } = this.props;
    const listboxId = 'ReactTags-listbox';

    const tags = this.props.tags.map((tag, i) => (
      <Tag
        key={i}
        tag={tag}
        isValidClassPresent={this.props.validTags.includes(tag.name)}
        isInvalidClassPresent={this.props.invalidTags.includes(tag.name)}
        classNames={this.state.classNames}
        onDelete={this.deleteTag.bind(this, tag)}
        onSelectTag={(e) => this.selectTag(e, i)}
        isSelected={this.state.cursorPosition === this.props.tags.length - i}
      />
    ));

    const expandable = this.state.focused && this.state.query.length >= this.props.minQueryLength;
    const classNames = [this.state.classNames.root];
    const LoadingIndicator = loadingIndicator || (
      <div className="add-button processing">Adding...</div>
    );

    this.state.focused && classNames.push(this.state.classNames.rootFocused);

    let { suggestions } = this.props;

    if (allowDuplicates) {
      const tagIds = tags.map((tag) => tag.id);

      suggestions = suggestions.filter((suggestion) => tagIds.indexOf(suggestion.id) === -1);
    }

    return (
      <div className={classNames.join(' ')} onClick={this.handleClick.bind(this)}>
        {this.props.label && <div className="react-tags__label">{this.props.label}</div>}
        <div
          className={this.state.classNames.selected}
          aria-live="polite"
          aria-relevant="additions removals"
        >
          {tags}
        </div>
        <div
          className={this.state.classNames.search}
          onBlur={() => this.onAddCurrent()}
          onFocus={this.handleFocus.bind(this)}
          onChange={this.handleChange.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        >
          <Input
            {...this.state}
            ref={(c) => {
              this.input = c;
            }}
            listboxId={listboxId}
            autofocus={this.props.autofocus}
            autoresize={this.props.autoresize}
            expandable={expandable}
            placeholder={this.props.placeholder}
            onFocus={this.onInputFocus.bind(this)}
            onBlur={this.onInputBlur.bind(this)}
            maxLength={isTag ? TAG_NAME_MAX_LENGTH : this.props.maxLength}
          />
          <Suggestions
            {...this.state}
            ref={(c) => {
              this.suggestions = c;
            }}
            listboxId={listboxId}
            expandable={expandable}
            suggestions={suggestions}
            addTag={this.addTag.bind(this)}
            maxSuggestionsLength={this.props.maxSuggestionsLength}
          />
        </div>
        {!this.props.processing &&
          showResetButton &&
          (tags.length > 0 || this.state.query.length > 0) && (
            <div onClick={() => this.onReset()} className="reset-button">
              <svg
                className="icon icon-reset"
                viewBox="0 0 21 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Page-1" fill="none" fillRule="evenodd">
                  <g id="pt-icon-delete" transform="translate(.675781 .449219)">
                    <path
                      d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0z"
                      id="Shape"
                      fill="#D8D8D8"
                      fillRule="nonzero"
                    />
                    <path
                      d="M15 6c0-.55-.45-1-1-1-.28 0-.53.11-.71.29L10 8.59l-3.29-3.3C6.53 5.11 6.28 5 6 5c-.55 0-1 .45-1 1 0 .28.11.53.29.71L8.59 10 5.3 13.29c-.19.18-.3.43-.3.71 0 .55.45 1 1 1 .28 0 .53-.11.71-.29l3.29-3.3 3.29 3.29c.18.19.43.3.71.3.55 0 1-.45 1-1 0-.28-.11-.53-.29-.71L11.41 10l3.29-3.29c.19-.18.3-.43.3-.71z"
                      id="Path"
                      fill="#959595"
                    />
                  </g>
                </g>
              </svg>
            </div>
          )}
        {this.state.query.length > 0 && this.props.showAddButton && (
          <div className="add-button" onClick={() => this.onAddCurrent()}>
            Add
          </div>
        )}
        {this.props.processing && LoadingIndicator}
      </div>
    );
  }
}

TagComplete.defaultProps = {
  tags: [],
  placeholder: 'Add new tag',
  suggestions: [],
  autofocus: true,
  autoresize: true,
  minQueryLength: 1,
  maxSuggestionsLength: 6,
  allowNew: false,
  allowDuplicates: false,
  label: '',
  invalidTags: [],
  validTags: [],
  showAddButton: false,
  maxLength: 44000,
};

TagComplete.propTypes = {
  tags: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  suggestions: PropTypes.array,
  autofocus: PropTypes.bool,
  autoresize: PropTypes.bool,
  onTagDeleted: PropTypes.func,
  onTagAdded: PropTypes.func,
  handleInputChange: PropTypes.func,
  minQueryLength: PropTypes.number,
  maxSuggestionsLength: PropTypes.number,
  classNames: PropTypes.object,
  allowNew: PropTypes.bool,
  allowDuplicates: PropTypes.bool,
  showAddButton: PropTypes.bool,
  invalidTags: PropTypes.array,
  validTags: PropTypes.array,
};

export default TagComplete;
