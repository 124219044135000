import { useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from 'common-js/api/useApi';
import { selectBulkSelectionFields } from 'common-js/reducers/devices/selectors';
import { getSelectedOrgId } from 'common-js/reducers/organization/selectors';

const useRenameDevices = ({ preview = true } = {}) => {
  const apiCall = useApi.call(`/devices/names?preview=${preview ? 1 : 0}`, {
    method: 'POST',
  });
  const bulkSelectionFields = useAppSelector((state) => selectBulkSelectionFields(state, preview));
  const orgid = useAppSelector(getSelectedOrgId);

  return useCallback(
    async (prefix) => {
      try {
        const body = {
          ...bulkSelectionFields,
          orgid,
          prefix: prefix || 'Unnamed device',
        };
        const response = await apiCall({ body });

        if (preview) {
          const { data } = response;

          return {
            totalDevices: data?.total_devices_count,
            sampleDevices: data?.devices_sample,
          };
        }

        // The API just returns "success": true so there isn't anything to return
        return {};
      } catch (error) {
        return { error };
      }
    },
    // We're excluding bulkSelectionFields on purpose because it's not memoized and causes infinite rerender loops here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiCall, preview]
  );
};

export default useRenameDevices;
