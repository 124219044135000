import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { PopoverCover } from '@holokit/core';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectDataThreshold } from 'common-js/reducers/currentDevice/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { Device } from 'common-js/types/Device';
import { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import UsageAlertForm from './Forms';
import useSubmitUsageAlertForm from './Forms/useSubmitUsageAlertForm';
import UsageAlertButton from './UsageAlertButton';
import UsageAlertElement from './UsageAlertElement';

type UsageAlerts = {
  id: number;
  text: string;
  acked: number;
  acked_at: string;
  acked_by: number;
  cur_billing_data_used: number;
  data_threshold: number;
  device_name: string;
  deviceid: number;
  expires: string;
  last_billing_data_used: number;
  latitude: number;
  linkid: number;
  longitude: number;
  orgid: number;
  plan_amount: number;
  planid: number;
  sim: string;
  state: string;
  type: string;
  whencreated: string;
};

interface UsageAlertSectionProps {
  usageAlertData: Array<UsageAlerts>;
  device: Device;
}

const UsageAlertSection: FC<UsageAlertSectionProps> = ({ usageAlertData, device }) => {
  const dataThreshold: number = useAppSelector(selectDataThreshold);
  const hasDataThreshold = !!dataThreshold && dataThreshold > 0;

  const onDataAlertUpdateSuccess = useCallback(() => {
    toast(<HeadlineToast headline="Usage Alert Saved" icon="Checkmark--single" />, {
      position: toast.POSITION.TOP_RIGHT,
      className: 'toastify-content--burnt toastify-content--success',
      autoClose: 10000,
    });
  }, []);

  const {
    error,
    handleSubmit: removeAlert,
    loading,
  } = useSubmitUsageAlertForm({ device, onSuccess: onDataAlertUpdateSuccess });

  const handleRemoveAlert = useCallback(async () => {
    await removeAlert({ dataAlertAmount: -1, amountUnit: -1 });
    if (error) {
      toast(<HeadlineToast headline="Error Disabling Usage Alert" icon="Warning" />, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toastify-content--burnt toastify-content--error',
        autoClose: 10000,
      });
    }
  }, [error, removeAlert]);

  const onPopoverCover = useCallback((open) => <UsageAlertButton open={open} />, []);

  const popoverContent = useCallback(
    (close) => (
      <UsageAlertForm
        close={close}
        onDataAlertUpdateSuccess={onDataAlertUpdateSuccess}
        device={device}
      />
    ),
    [device, onDataAlertUpdateSuccess]
  );

  return (
    <Panel className="DataLimits DataLimits__visibleOverflow" header="Usage alert" noContentPadding>
      <div className="DataLimits__descriptor">
        You&apos;ll receive a notification when this device uses more than a set amount of data each
        billing period.
      </div>
      <div className="DataLimits__well-container">
        <div className="DataLimits__well">
          <UsageAlertElement
            dataThreshold={dataThreshold}
            usageAlertData={usageAlertData}
            device={device}
          />
          <div className="DataLimits__button-wrapper">
            <div className="DataLimits__button-wrapper__button">
              <PopoverCover
                className="UsageAlertForm__popover-cover"
                selfAligned={false}
                trigger={onPopoverCover}
                content={popoverContent}
              />
            </div>
            {hasDataThreshold && (
              <Button
                className="DataLimits__button-wrapper__button"
                loading={loading}
                onClick={handleRemoveAlert}
                variant="delete"
              >
                Disable alert
              </Button>
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default UsageAlertSection;
