import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import {
  fieldLabels,
  passwordHelpText,
  validationMessages,
} from 'common-js/constants/setAccountPasswordFormStrings';
import valuesMatch from 'common-js/utils/validation/valuesMatch';
import { useEffect, type FC } from 'react';
import { useForm } from 'react-hook-form';

/* Form validation patterns */
const passwordValidation = {
  numberRegex: /\d+/,
  letterRegex: /[a-zA-Z]+/,
  maxLength: 256,
  minLength: 6,
};

interface SetAccountPasswordFormProps {
  cancelButton?: boolean;
  fieldLayoutDirection?: 'column' | 'row';
  onSubmit: Function;
  submitLabel: string;
}

const SetAccountPasswordForm: FC<SetAccountPasswordFormProps> = ({
  cancelButton = false,
  fieldLayoutDirection = 'column',
  onSubmit,
  submitLabel,
}) => {
  /* React Hook Form methods */
  const {
    formState: { errors, isSubmitting, isSubmitSuccessful },
    getValues,
    handleSubmit,
    register,
    setError,
    reset,
  } = useForm();

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful, reset]);

  /* Form submit handler */
  const submitHandler = async (data) => {
    // call the provided onSubmit method
    const response = await onSubmit(data);
    // If the external submit handler detects a setPassword API error, it should return a response with a setAccountPasswordFieldError key
    if (response?.setAccountPasswordFieldError) {
      setError('password', {
        type: 'custom',
        message: response.setAccountPasswordFieldError,
      });
    }
  };

  return (
    <Form
      className={`set-account-password-form set-account-password-form--${fieldLayoutDirection}`}
      onSubmit={handleSubmit(submitHandler)}
      footerActions={
        <>
          {cancelButton && (
            <Button variant="secondary" onClick={() => reset()} disabled={isSubmitting}>
              Cancel
            </Button>
          )}
          <Button loading={isSubmitting} name={submitLabel} type="submit">
            {submitLabel}
          </Button>
        </>
      }
    >
      <TextInputField
        label={fieldLabels.newPassword}
        type="password"
        helpText={passwordHelpText}
        invalid={!!errors.password}
        layoutDirection={fieldLayoutDirection}
        validationMessage={errors.password?.message?.toString()}
        {...register('password', {
          required: validationMessages.passwordBlank,
          maxLength: {
            value: passwordValidation.maxLength,
            message: validationMessages.passwordMaxLength,
          },
          minLength: {
            value: passwordValidation.minLength,
            message: validationMessages.passwordMinLength,
          },
          validate: {
            atLeastOneNumber: (v) =>
              passwordValidation.numberRegex.test(v) ||
              validationMessages.passwordNumberRequirement,
            atLeastOneLetter: (v) =>
              passwordValidation.letterRegex.test(v) ||
              validationMessages.passwordLetterRequirement,
          },
        })}
      />
      <TextInputField
        label={fieldLabels.confirmPassword}
        type="password"
        invalid={!!errors.confirmPassword}
        layoutDirection={fieldLayoutDirection}
        validationMessage={errors.confirmPassword?.message?.toString()}
        {...register('confirmPassword', {
          validate: {
            mustMatchPasswordIfPasswordNotBlank: () =>
              valuesMatch(getValues('password'), getValues('confirmPassword')) ||
              validationMessages.passwordFieldMismatch,
          },
        })}
      />
    </Form>
  );
};

export default SetAccountPasswordForm;
