import CreditCardModel, { type CreditCard } from './CreditCardModel';

export default class BillingInformationModel {
  billingAddress = {};

  isBillingAddressSet = false;

  billingMethod = '';

  creditCard = new CreditCardModel();

  failedBillingAttempts = 0;

  isCardSet = false;

  constructor(data?: {
    billing_address: string;
    billing_address_set: boolean;
    billingmethod: string;
    cardset: boolean;
    card: CreditCard;
    failed_billing_attempts: number;
  }) {
    if (!data) {
      return;
    }

    this.billingAddress = data.billing_address ?? {};
    this.isBillingAddressSet = data.billing_address_set ?? false;
    this.billingMethod = data.billingmethod;
    this.isCardSet = data.cardset;
    if (data.card) this.creditCard = new CreditCardModel(data.card);
    if (data.failed_billing_attempts) this.failedBillingAttempts = data.failed_billing_attempts;
  }
}
