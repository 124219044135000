const PREFIX = 'DEVICE_FILTERS_';

export const GET_TAGS_REQUEST = `${PREFIX}GET_TAGS_REQUEST`;
export const GET_TAGS_SUCCESS = `${PREFIX}GET_TAGS_SUCCESS`;
export const GET_TAGS_ERROR = `${PREFIX}GET_TAGS_ERROR`;

export const FILTER_TOGGLE_TAG = `${PREFIX}TOGGLE_TAG`;
export const FILTER_TOGGLE_ALL_DEVICES = `${PREFIX}TOGGLE_ALL_DEVICES`;

export const TAGS_SELECT_ALL = `${PREFIX}TAGS_SELECT_ALL`;
export const TAGS_DESELECT_ALL = `${PREFIX}TAGS_DESELECT_ALL`;

export const TAGNAME_CHANGE_REQUEST = `${PREFIX}TAGNAME_CHANGE_REQUEST`;
export const TAGNAME_CHANGE_SUCCESS = `${PREFIX}TAGNAME_CHANGE_SUCCESS`;
export const TAGNAME_CHANGE_ERROR = `${PREFIX}TAGNAME_CHANGE_ERROR`;

export const ADD_NEW_TAG_REQUEST = `${PREFIX}ADD_NEW_TAG_REQUEST`;
export const ADD_NEW_TAG_SUCCESS = `${PREFIX}ADD_NEW_TAG_SUCCESS`;
export const ADD_NEW_TAG_ERROR = `${PREFIX}ADD_NEW_TAG_ERROR`;

export const REMOVE_TAG_REQUEST = `${PREFIX}REMOVE_TAG_REQUEST`;
export const REMOVE_TAG_SUCCESS = `${PREFIX}REMOVE_TAG_SUCCESS`;
export const REMOVE_TAG_ERROR = `${PREFIX}REMOVE_TAG_ERROR`;

export const PATCH_FILTERS = `${PREFIX}PATCH_FILTERS`;

export const UPDATE_SORT_DIRECTION = `${PREFIX}UPDATE_SORT_DIRECTION`;
export const UPDATE_SORT_BY = `${PREFIX}UPDATE_SORT_BY`;

export const GET_FILTER_VALUES_REQUEST = `${PREFIX}GET_FILTER_VALUES_REQUEST`;
export const GET_FILTER_VALUES_SUCCESS = `${PREFIX}GET_FILTER_VALUES_SUCCESS`;
export const GET_FILTER_VALUES_ERROR = `${PREFIX}GET_FILTER_VALUES_ERROR`;
