import { Button } from '@hologram-dimension/button';
import analyticsEventBuilder from 'common-js/analytics';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectActivationGeneral } from 'common-js/reducers/activation/selectors';
import { getPostPay, selectBillingInformation } from 'common-js/reducers/organization/selectors';
import { MouseEventHandler, useCallback, useState, type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Footer, { BackButton } from '../common/scaffolding/Footer';

const paymentTypeTranslations = {
  card: 'Credit card',
  balance: 'Balance',
};

interface ReviewFooterProps extends FooterProps {}

const ReviewFooter: FC<ReviewFooterProps> = ({
  prevStep,
  isOnboarding = false,
  changingPlan = false,
}) => {
  const { isActivating, totalDue } = useAppSelector(selectActivationGeneral);
  const hasPostpay = useAppSelector(getPostPay);
  const { isCardSet: ccOnFile } = useAppSelector(selectBillingInformation);
  const { getValues, watch } = useFormContext();
  const { enabled: isAutoRefillEnabled } = getValues('autoRefillParams') ?? {};
  const { invalidPaymentMethod, hasError } = watch('creditCardInfo');
  const onboardingText = isOnboarding ? '1st Time' : 'Returning';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';
  const [error, setError] = useState('');
  const billingInformation = useAppSelector(selectBillingInformation);
  const isBillingAddressSet = billingInformation?.isBillingAddressSet ?? false;

  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      if (hasError) {
        setError('Please clear any errors before continuing');
        e.preventDefault();
        return;
      }

      if ((totalDue || isAutoRefillEnabled) && !ccOnFile && !hasPostpay) {
        setError('Please add a payment method to continue.');
        e.preventDefault();
        return;
      }

      if (invalidPaymentMethod && !hasPostpay) {
        setError('Please select a valid payment method to continue.');
        e.preventDefault();
        return;
      }

      if (!isBillingAddressSet) {
        setError('Please add a billing address to continue.');
        e.preventDefault();
        return;
      }

      setError('');

      analyticsEventBuilder
        .buttonClick(
          analyticsTitle,
          changingPlan ? undefined : onboardingText,
          `${analyticsTitle} Pay and Activate`
        )
        .send({
          'Payment method': hasPostpay
            ? 'Post pay'
            : paymentTypeTranslations[getValues('creditCardInfo.paymentType')],
        });
    },
    [
      analyticsTitle,
      ccOnFile,
      changingPlan,
      getValues,
      hasError,
      hasPostpay,
      invalidPaymentMethod,
      isAutoRefillEnabled,
      isBillingAddressSet,
      onboardingText,
      totalDue,
    ]
  );

  let buttonCopy: string;

  if (hasPostpay) {
    buttonCopy = 'Finish and activate';
  } else if (changingPlan) {
    if (totalDue) {
      buttonCopy = 'Pay and change data plan';
    } else {
      buttonCopy = 'Change data plan';
    }
  } else {
    buttonCopy = 'Pay and activate';
  }

  return (
    <Footer
      left={<BackButton onClick={prevStep} />}
      right={
        <div className="ReviewFooter__actions">
          <span className="ReviewFooter__error">{error}</span>
          <Button loading={isActivating} iconEnd="Lightning" type="submit" onClick={handleClick}>
            {isActivating ? 'Processing...' : buttonCopy}
          </Button>
        </div>
      }
    />
  );
};

export default ReviewFooter;
