import { useEffect, useRef, useState } from 'react';
import getOrgFromPathName from './getPathnameContextInfo';
import { upsertStore } from './configureStore';

const useStore = () => {
  const orgId = getOrgFromPathName();
  const lastOrgIdRef = useRef<OrgId | undefined>(orgId);
  const [store, setStore] = useState<ReturnType<typeof upsertStore>>(upsertStore(orgId));

  useEffect(() => {
    if (lastOrgIdRef.current !== orgId) {
      lastOrgIdRef.current = orgId;

      setStore(upsertStore(orgId));
    }
  }, [orgId]);

  return store;
};

export default useStore;
