import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { browserHistory } from 'react-router';

import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as Paths from '../../constants/paths';
import generateCSV from '../../utils/generateCSV';
import * as API from '../../api';
import { getUserContextData } from '../../api/util';
import { patchFilters as patchDeviceFilters } from '../deviceFilter/actions';
import { getSelectedTagNames } from '../deviceFilter/selectors';

import * as OrgActions from '../organization/actions';
import * as actionTypes from './actionTypes';
import { VIEWMORE_ROWS } from './reducer';
import { buildChartFilters, getReportDateTitleString } from './selectors';

import { queryToStoreFilters, getFilterQueryObject } from './utils';

export function setReportDateTitle() {
  return (dispatch, state) => {
    const snapshot = state();

    const { startDate } = snapshot.usage.filters;
    const { endDate } = snapshot.usage.filters;
    const { timeQuickFilter } = snapshot.usage;

    dispatch({
      type: actionTypes.SET_REPORT_DATE_TITLE,
      reportDateTitle: getReportDateTitleString(startDate, endDate, timeQuickFilter),
    });
  };
}

export function updatePathQuery() {
  return (dispatch, state) => {
    const stateSnapshot = state();

    // Get the current query string filters as an object
    const queryParams = getFilterQueryObject();

    const storeParams = {
      tags: stateSnapshot.deviceFilters.selectedTags,
      startDate: stateSnapshot.usage.filters.startDate,
      endDate: stateSnapshot.usage.filters.endDate,
    };

    const mergedFilterParams = _.extend({}, queryParams, storeParams);
    const finalQuery = queryString.stringify(mergedFilterParams);
    const targetPath =
      window.location.pathname +
      (finalQuery.length > 0 ? `?${finalQuery}` : '') +
      window.location.hash;

    browserHistory.replace(targetPath);
  };
}

export function getDeviceBreakdown(storeFilterKey) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_DEVICEBREAKDOWN_REQUEST });
    return API.getDeviceBreakdown(
      getUserContextData(state),
      buildChartFilters(state, storeFilterKey),
      VIEWMORE_ROWS
    )
      .then((result) => {
        dispatch({
          type: actionTypes.GET_DEVICEBREAKDOWN_SUCCESS,
          data: result.data,
          continues: result.continues,
        });

        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DEVICEBREAKDOWN_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function getDataUsed() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_DATAUSED_REQUEST });

    return API.getDataUsed(getUserContextData(state), buildChartFilters(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_SUCCESS,
          data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function getUsageSummary() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_USAGESUMMARY_REQUEST });

    const MS_IN_DAY = 86400000;
    const SHORT_TERM_MAX = MS_IN_DAY * 3; // 3 day max
    const snapshot = state();
    const { startDate } = snapshot.usage.filters;
    const { endDate } = snapshot.usage.filters;
    const timeWindow = Math.abs(endDate - startDate);

    return API.getUsageSummary(
      getUserContextData(state),
      timeWindow <= SHORT_TERM_MAX ? 'shortterm' : 'longterm',
      buildChartFilters(state)
    )
      .then((data) => {
        dispatch({
          type: actionTypes.GET_USAGESUMMARY_SUCCESS,
          data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_USAGESUMMARY_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function getDataUsedInspect() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_DATAUSED_INSPECT_REQUEST });

    return API.getDataUsedInspect(getUserContextData(state), buildChartFilters(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_INSPECT_SUCCESS,
          data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_INSPECT_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function runReport() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.RUN_REPORT_START });
    setReportDateTitle()(dispatch, state);
    updatePathQuery()(dispatch, state);

    const promises = [];
    let analyticsEvent;

    if (Paths.pathMatches(Paths.USAGE_BREAKDOWN)) {
      promises.push(getDeviceBreakdown()(dispatch, state));
      analyticsEvent = analyticsTypes.REPORT_USAGE_BREAKDOWN;
    }

    // TODO: Retire this when we fully switch to looker
    if (Paths.pathMatches(Paths.USAGE_OVERVIEW)) {
      promises.push(getDataUsed()(dispatch, state));
      promises.push(getUsageSummary()(dispatch, state));
      analyticsEvent = analyticsTypes.REPORT_USAGE_OVERVIEW;
    }

    if (Paths.pathMatches(Paths.USAGE_INSPECT_DATAUSED)) {
      promises.push(getDataUsed()(dispatch, state));
      promises.push(getDataUsedInspect()(dispatch, state));
      analyticsEvent = analyticsTypes.REPORT_USAGE_INSPECT;
    }

    const snapshot = state();
    const tags = getSelectedTagNames(snapshot);
    sendAnalyticsEvent({
      type: analyticsEvent,
      data: {
        start_date: snapshot.usage.filters.startDate,
        end_date: snapshot.usage.filters.endDate,
        tags: tags.join(', '),
      },
    });

    Promise.all(promises)
      .then(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH });
      })
      .catch(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH });
      });
  };
}

export function getLiveUsage() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_LIVEUSAGE_REQUEST });

    return API.getLiveUsage(getUserContextData(state), buildChartFilters(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_LIVEUSAGE_SUCCESS,
          data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_LIVEUSAGE_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

export function runLiveUsageReport() {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.RUN_REPORT_START });

    setReportDateTitle()(dispatch, state);
    updatePathQuery()(dispatch, state);

    Promise.all([getLiveUsage()(dispatch, state)])
      .then(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH });
      })
      .catch(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH });
      });
  };
}

// eslint-disable-next-line default-param-last
export function getBillingReport(devices = [], tagIds = [], startDate, endDate) {
  return (dispatch, state) => {
    const userContextData = getUserContextData(state);

    dispatch({
      type: actionTypes.GET_BILLING_REPORT_REQUEST,
    });

    return API.getBillingReport(userContextData, startDate, endDate, tagIds, devices[0])
      .then((data) => {
        dispatch({
          type: actionTypes.GET_BILLING_REPORT_SUCCESS,
          billingReport: data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_BILLING_REPORT_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

// eslint-disable-next-line default-param-last
export function getDevicesReport(tagIds = [], startDate, endDate) {
  return (dispatch, state) => {
    const userContextData = getUserContextData(state);

    dispatch({
      type: actionTypes.GET_DEVICES_REPORT_REQUEST,
    });

    return API.getDevicesReport(userContextData, startDate, endDate, tagIds)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DEVICES_REPORT_SUCCESS,
          devicesReport: data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DEVICES_REPORT_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function runBillingReport(tagIds = []) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.RUN_REPORT_START });
    setReportDateTitle()(dispatch, state);

    const snapshot = state();
    let { startDate } = snapshot.usage.filters;
    let { endDate } = snapshot.usage.filters;

    startDate = moment(startDate).startOf('day').unix();
    endDate = moment(endDate).endOf('day').unix();

    Promise.all([
      getBillingReport([], tagIds, startDate, endDate)(dispatch, state),
      getDevicesReport(tagIds, startDate, endDate)(dispatch, state),
      OrgActions.getBalanceHistory({
        timestart: startDate,
        timeend: endDate,
        tagids: tagIds,
      })(dispatch, state),
    ])
      .then(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH });
      })
      .catch(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH });
      });
  };
}

export const patchFilters = (filters) => ({
  type: actionTypes.PATCH_FILTERS,
  filters,
});

export const setQuickFilter = (quickFilter) => ({
  type: actionTypes.SET_QUICK_FILTER,
  quickFilter,
});

export const setCustomDate = (filters) => ({
  type: actionTypes.SET_CUSTOM_DATE,
  filters,
});

export const setCustomDateInspect = (filters) => ({
  type: actionTypes.SET_FILTERS_DATAUSEDDETAILS,
  filters,
});

export const setCustomDateBillingReport = (filters) => ({
  type: actionTypes.SET_FILTERS_BILLINGREPORT,
  filters,
});

export function syncFiltersToQueryString() {
  return (dispatch, state) =>
    new Promise((resolve) => {
      const stateSnapshot = state();

      // Get the current query string filters as an object
      const queryParams = getFilterQueryObject();

      // Get the store filters
      const storeParams = {
        tags: stateSnapshot.deviceFilters.selectedTags,
        startDate: stateSnapshot.usage.filters.startDate,
        endDate: stateSnapshot.usage.filters.endDate,
      };

      // Merge them together, favoring the query string
      const mergedFilterParams = _.extend({}, storeParams, queryParams);

      // Convert query string param values to proper store keys/values
      const filtersToPersist = queryToStoreFilters(mergedFilterParams);

      // if there's a change from the url, persist it in the store.
      patchDeviceFilters(filtersToPersist, 'sync')(dispatch, state);
      dispatch(patchFilters(filtersToPersist));

      // Build new path, and redirect if different from the original path.
      const finalQuerystring = queryString.stringify(mergedFilterParams);
      const currentPath = window.location.pathname + window.location.search + window.location.hash;
      const targetPath =
        window.location.pathname +
        (finalQuerystring.length > 0 ? `?${finalQuerystring}` : '') +
        window.location.hash;

      if (currentPath !== targetPath) {
        browserHistory.replace(targetPath);
        setTimeout(() => resolve(), 20); // give time for this replace to properly happen.
      } else {
        resolve();
      }
    });
}

export const viewMoreLiveUsage = () => ({
  type: actionTypes.VIEWMORE_LIVEUSAGE,
});

export function exportDataUsedInspect() {
  return (dispatch, state) => {
    // Load all the data again for exporting.

    dispatch({ type: actionTypes.EXPORT_DATAUSEDINSPECT_REQUEST });

    const MAX_BATCHES = 3;
    let currentBatch = 0;
    let loadedData = [];

    const getBatch = () =>
      API.getDataUsedInspect(getUserContextData(state), buildChartFilters(state))
        .then((result) => {
          dispatch({ type: actionTypes.EXPORT_DATAUSEDINSPECT_BATCH_SUCCESS });
          currentBatch += 1;
          loadedData = loadedData.concat(result);

          if (result.continues && currentBatch < MAX_BATCHES) getBatch();
          else if (result.continues && currentBatch > MAX_BATCHES) {
            dispatch({ type: actionTypes.EXPORT_DATAUSEDINSPECT_TOOLARGE });
          } else {
            dispatch({ type: actionTypes.EXPORT_DATAUSEDINSPECT_SUCCESS });

            const headers = [
              'date',
              'avgdailyusage',
              'sessions',
              'sms',
              'total_bytes',
              'total_devices',
            ];

            const rows = loadedData.map((data) => [
              data.date,
              ((data.total_bytes * 1) / (data.sessions * 1)).toFixed(2), // cast to strings to avoid toFixed errors.
              data.sessions,
              data.sms,
              data.total_bytes,
              data.total_devices,
            ]);

            generateCSV(
              [headers].concat(rows),
              `datausedinspect ${moment().format('YYYY-MM-DD')}.csv`
            );
          }
        })
        .catch((e) => {
          dispatch({
            type: actionTypes.EXPORT_DATAUSEDINSPECT_ERROR,
            error: e,
          });
        });

    getBatch();
  };
}

export const requestUsageReport = () => (dispatch, state) => {
  const userContextData = getUserContextData(state);
  const filterData = buildChartFilters(state);

  return API.requestUsageReport(userContextData, {
    ...filterData,
    tag_ids: filterData.tagids,
  })
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};

export const requestDeviceBreakdownUsageReport = () => (dispatch, state) => {
  const userContextData = getUserContextData(state);
  const filterData = buildChartFilters(state);

  return API.requestDeviceBreakdownUsageReport(userContextData, {
    ...filterData,
    tag_ids: filterData.tagids,
  })
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};
