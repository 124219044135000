import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Panel } from '@holokit/core';

import { HoloHelmet } from 'common-js/components';
import SendSmsForm from 'common-js/forms/SendSmsForm';
import {
  sendSmsToDevice,
  sendDataToDevice,
  simMessageFromDevice,
} from 'common-js/reducers/currentDevice/actions';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { revealDrawer } from 'common-js/reducers/drawer/actions';
import SimulateMessageForm from 'common-js/forms/SimulateMessageForm';

function Message({
  builtInTopics,
  device,
  revealDrawer_,
  sendSmsToDevice_,
  sendDataToDevice_,
  simMessageFromDevice_,
}) {
  return (
    <div className="page-device-message full-height">
      <HoloHelmet title={`${device.name} - Messaging`} />
      <Panel title="Send a Message to This Device" noBodyPadding>
        <SendSmsForm
          deviceIds={[device.id]}
          sendSmsToDevice={sendSmsToDevice_}
          sendDataToDevice={sendDataToDevice_}
        />
      </Panel>
      <Panel title="Simulate a Message from This Device to API" noBodyPadding>
        <SimulateMessageForm
          deviceId={device.id}
          simMessageFromDevice={simMessageFromDevice_}
          builtInTopics={builtInTopics}
          revealDrawer={revealDrawer_}
        />
      </Panel>
    </div>
  );
}

export default connect(
  (state) => ({
    device: selectCurrentDevice(state),
    builtInTopics: state.topic.builtInTopics,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        revealDrawer_: revealDrawer,
        sendDataToDevice_: sendDataToDevice,
        sendSmsToDevice_: sendSmsToDevice,
        simMessageFromDevice_: simMessageFromDevice,
      },
      dispatch
    )
)(Message);
