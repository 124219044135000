import React from 'react';

function ActivitySkeletonTableRow() {
  return (
    <tr className="Table__row">
      <td className="Table__cell ActivityTable__cell">
        <div className="Table__cell-content ActivityTable__cell-content Table__cell-content--loading" />
      </td>
      <td className="Table__cell ActivityTable__cell">
        <div className="Table__cell-content ActivityTable__cell-content Table__cell-content--loading" />
      </td>
      <td className="Table__cell ActivityTable__cell">
        <div className="Table__cell-content ActivityTable__cell-content Table__cell-content--loading" />
      </td>
      <td className="Table__cell ActivityTable__cell">
        <div className="Table__cell-content ActivityTable__cell-content Table__cell-content--loading" />
      </td>
    </tr>
  );
}

function ActivitySkeletonTable({ rowCount }) {
  return Array.from({ length: rowCount }, (_value, index) => (
    <ActivitySkeletonTableRow key={`ActivitySkeletonTable${index}`} />
  ));
}

export default ActivitySkeletonTable;
