import { Button, Dropdown, Icon, MetricButton, Panel, Tooltip } from '@holokit/core';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { LAST_ACTIVE, USAGE } from 'common-js/constants/deviceFilterFields';
import { GREATER_THAN } from 'common-js/constants/filterComparators';
import * as Paths from 'common-js/constants/paths';
import {
  deselectAllTags,
  getTags,
  selectAllTags,
  toggleTag,
} from 'common-js/reducers/deviceFilter/actions';
import { getDataUsed, getUsageSummary, setCustomDate } from 'common-js/reducers/usage/actions';
import { toByteString } from 'common-js/utils/numberFormatter';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

const DataUsedChart = React.lazy(() =>
  import('@holokit/charts').then((module) => ({ default: module.DataUsedChart }))
);

class DataUsedPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areTagsLoading: true,
      averageUsage: null,
      devicesWithSessions: null,
      chartMessage: '',
      dataUsed: [],
      dropdownText: 'FILTER BY Tags',
      isLoading: true,
    };
  }

  componentDidMount() {
    const { setCustomDate_, getTags_, deselectAllTags_ } = this.props;
    const previousTimestamp = new Date();
    previousTimestamp.setDate(previousTimestamp.getDate() - 30);

    deselectAllTags_();
    setCustomDate_({
      startDate: previousTimestamp.getTime(),
      endDate: Date.now(),
    });
    getTags_().then(() => {
      this.setState({ areTagsLoading: false });
    });
    this.getDeviceData();
  }

  filterUsageByTags = (tag) => {
    const { toggleTag_ } = this.props;
    toggleTag_(tag, 'usage');
  };

  getDeviceData = () => {
    const { getDataUsed_, getUsageSummary_ } = this.props;
    this.setState({
      isLoading: true,
    });
    Promise.all([getDataUsed_(), getUsageSummary_()])
      .then((res) => {
        this.setState({
          averageUsage: res[1]?.averageperdevice_last ?? 0,
          devicesWithSessions: res[1]?.numdevices_usingdata_last ?? 0,
          chartMessage: res[0]?.length
            ? ''
            : 'There are no data usage metrics to display for your fleet over the last 7 days.',
          dataUsed: res[0],
          isLoading: false,
        });

        this.setDropdownText();
      })
      .catch(() => {
        this.setState({
          averageUsage: null,
          chartMessage: 'Unable to fetch usage data at this time.',
          dataUsed: [],
          isLoading: false,
        });
      });
  };

  handleSelectAllTags = () => {
    const { selectAllTags_ } = this.props;
    selectAllTags_();
  };

  handleDeselectAllTags = () => {
    const { deselectAllTags_ } = this.props;
    deselectAllTags_();
  };

  setDropdownText = () => {
    const { selectedTags, tags } = this.props;

    const dropdownText = 'FILTER BY Tags';

    if (!selectedTags.length) {
      this.setState({ dropdownText });
    } else {
      let tagsToShow = '';
      let moreTagsCount = 0;
      selectedTags.forEach((selectedTagId) => {
        const selectedTagName = tags.find((tag) => tag.id === selectedTagId)?.name;
        if (selectedTagName) {
          if (tagsToShow.length + selectedTagName.length <= 30) {
            tagsToShow = `${tagsToShow.length ? `${tagsToShow}, ` : ''}${selectedTagName}`;
          } else {
            moreTagsCount += 1;
          }
        }
      });
      this.setState({
        dropdownText: `FILTER BY ${tagsToShow} ${moreTagsCount ? `(+${moreTagsCount} more)` : ''}`,
      });
    }
  };

  handleDevicesClick = () => {
    const { router, selectedTags } = this.props;
    const { dataUsed } = this.state;
    const usageLength = dataUsed.length > 7 ? 30 : 7;

    const setFiltersOnLoad = [
      {
        key: LAST_ACTIVE,
        num: usageLength,
        unit: 'Days',
        filterInputKey: 'lastActive',
      },
      {
        key: USAGE,
        value: 0,
        comparator: GREATER_THAN,
      },
    ];

    if (selectedTags.length) {
      setFiltersOnLoad.push({
        key: 'tag_id',
        values: selectedTags,
      });
    }

    router.push({
      pathname: Paths.DEVICES_DEVICES,
      state: {
        setFiltersOnLoad,
      },
    });
    sendAnalyticsEvent({
      type: analyticsTypes.FLEET_HOME_METRIC_BUTTON_CLICK,
      data: {
        button_name: `Data Used Last ${usageLength} Days`,
      },
    });
  };

  render() {
    const {
      areTagsLoading,
      averageUsage,
      chartMessage,
      dataUsed,
      devicesWithSessions,
      dropdownText,
      isLoading,
    } = this.state;
    const { hasHistoricFleetHome, tags, selectedTags } = this.props;
    const usagePanelTitle = `Last ${dataUsed.length > 7 ? 30 : 7} Days`;
    const tagDropdownItems = tags.map((tag) => ({
      children: tag.name,
      checkboxProps: { checked: selectedTags.includes(tag.id) },
      iconLeft: 'Tag',
      iconLeftGivenColor: '#545D79',
      onClick: () => {
        this.filterUsageByTags({
          id: tag.id,
          name: tag.name,
        });
      },
      id: tag.id,
    }));

    return (
      <Panel
        dark
        fullWidth
        bodyClasses="DataUsedPanel"
        isLoading={isLoading}
        headerButtons={
          <Dropdown
            classes="DataUsedPanel__dropdown"
            small
            panelHasTopMargin
            footerIsButton
            loading={areTagsLoading}
            footer={
              <Button
                type="primary"
                small
                onClick={() => {
                  this.getDeviceData();
                  sendAnalyticsEvent({
                    type: analyticsTypes.FLEET_HOME_USAGE_FILTER_TAGS,
                    data: {
                      selected_tags_count: selectedTags.length,
                    },
                  });
                }}
              >
                Run Report
              </Button>
            }
            align="right"
            type="reversed"
            iconLeft="Tag"
            iconRightColor="#969FB1"
            dropdownText={dropdownText}
            items={[
              {
                children: (
                  <div className="DataUsedPanel__dropdown__options">
                    <span
                      className="DataUsedPanel__dropdown__option"
                      onClick={this.handleSelectAllTags}
                      onKeyUp={this.handleSelectAllTags}
                      role="menuitem"
                      tabIndex={0}
                    >
                      Select All
                    </span>{' '}
                    |{' '}
                    <span
                      className="DataUsedPanel__dropdown__option"
                      onClick={this.handleDeselectAllTags}
                      onKeyUp={this.handleDeselectAllTags}
                      role="menuitem"
                      tabIndex={0}
                    >
                      None
                    </span>
                  </div>
                ),
                header: true,
                id: 0,
              },
              ...tagDropdownItems,
            ]}
          />
        }
        title={
          <span className="DataUsedPanel__panelTitle">
            Data Usage {!isLoading ? usagePanelTitle : null}
            <div className="DataUsedPanel__info-icon">
              <Tooltip
                content="Data usage is the sum of all the bytes of each data session your devices have used as provided to us by our carrier partners, and is updated every 15 mins."
                light
                position="top"
              >
                <Icon name="Circle--info" size="minor" svgProps={{ style: { fill: '#ffffff' } }} />
              </Tooltip>
            </div>
          </span>
        }
      >
        <div>
          {!!devicesWithSessions && (
            <MetricButton
              number={devicesWithSessions}
              text="Devices Used Data"
              onClick={hasHistoricFleetHome && this.handleDevicesClick}
              isLoading={isLoading}
            />
          )}
          {!!averageUsage && (
            <div className="DataUsedPanel__dataUsed">
              <span className="DataUsedPanel__dataUsed__number">{toByteString(averageUsage)}</span>
              Average per device
            </div>
          )}
        </div>
        {!isLoading && (
          <Suspense>
            <DataUsedChart
              classes="DataUsedChartD3--alerts DataUsedPanel__chart"
              data={dataUsed}
              emptyMessageDaily={chartMessage}
              isAlerts
              margin={{
                top: 0,
                right: 10,
                bottom: 25,
                left: 10,
              }}
              style={{ style: { fill: 'black' } }}
              showMaxDataCallout
            />
          </Suspense>
        )}
      </Panel>
    );
  }
}

DataUsedPanel.defaultProps = {
  selectedTags: [],
};

const DataUsedPanelHoC = withRouter(
  connect(
    (state) => ({
      hasHistoricFleetHome: state.releaseFlag?.fleet_historic,
      selectedTags: state.deviceFilters.selectedTags,
      tags: state.deviceFilters.tags,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          deselectAllTags_: deselectAllTags,
          getDataUsed_: getDataUsed,
          getTags_: getTags,
          getUsageSummary_: getUsageSummary,
          selectAllTags_: selectAllTags,
          setCustomDate_: setCustomDate,
          toggleTag_: toggleTag,
        },
        dispatch
      )
  )(DataUsedPanel)
);

export default DataUsedPanelHoC;
