import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import _classNames from 'clsx';
import { Icon, Panel, Pagination, Tooltip } from '@holokit/core';
import {
  getActivityHistoryLoading,
  getActivityHistorySort,
  getTaskPageCompletedTasks,
  getTaskPageInfo,
} from 'common-js/reducers/devices/selectors';
import ActivityTable from './ActivityTable';
import useLoadActivity from './useLoadActivity';
import useActivityHistoryPaginationHandlers from './useActivityHistoryPaginationHandlers';
import useActivityHistoryHeaderClickHandler from './useActivityHistoryHeaderClickHandler';

const ActivityPanel = ({ simInventory }) => {
  // selectors
  const completedTasks = useAppSelector(getTaskPageCompletedTasks);
  const pageInfo = useAppSelector(getTaskPageInfo);
  const activityHistoryDetailsFlag = useAppSelector(
    (state) => !!state.releaseFlag?.activity_history_details
  );
  const activityHistoryTableSort = useAppSelector(getActivityHistorySort);
  const isLoading = useAppSelector(getActivityHistoryLoading);

  // side-effects
  useLoadActivity(simInventory);

  // event handlers
  const paginationHandlers = useActivityHistoryPaginationHandlers();
  const onHeaderColumnClick = useActivityHistoryHeaderClickHandler();

  // derived state
  const empty = completedTasks.length === 0 && isLoading === false;
  const { column: sortColumn, direction: sortDirection } = activityHistoryTableSort ?? {};

  return (
    <Panel
      classes="Activity__panel"
      bodyClasses={_classNames('Activity__panel__body', {
        'Activity__panel__body--empty': empty,
      })}
      noBodyPadding
      title={
        <>
          Activity history{' '}
          <Tooltip
            content="The following are manual paused, resumed, or activated actions initiated from the Dashboard or API, sorted by most recent."
            position="top"
          >
            <Icon name="Circle--info" size="minor" svgProps={{ style: { fill: '#8008F7' } }} />
          </Tooltip>
        </>
      }
      isLoading={isLoading}
    >
      {empty && (
        <>
          <h2 className="Activity__empty__header">No activity to report</h2>
          <div className="Activity__empty__text">
            Manual pause, resume, or activated actions taken from the dashboard or the API will show
            up here. up here.
          </div>
          <div className="Activity__empty__note">
            Device history data began on January 21, 2022.
          </div>
        </>
      )}
      {!empty && (
        <>
          <div className="Activity__table-scroll-wrap">
            <ActivityTable
              completedTasks={completedTasks}
              pageInfo={pageInfo}
              lastPage={pageInfo.currentPage === pageInfo.totalPages}
              includeLinks={activityHistoryDetailsFlag}
              onHeaderColumnClick={onHeaderColumnClick}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              isLoading={isLoading}
              simInventory={simInventory}
            />
          </div>
          <Pagination
            classes="ActivityTable__pagination"
            itemLabel="Completed Activity"
            itemTotal={pageInfo.totalTasks}
            pageCurrent={pageInfo.currentPage}
            pageLimit={pageInfo.totalPages}
            pageSize={pageInfo.tasksPerPage}
            totalPages={pageInfo.totalPages}
            {...paginationHandlers}
          />
        </>
      )}
    </Panel>
  );
};

export default ActivityPanel;
