import * as actionTypes from './actionTypes';
import { removeDuplicates } from './actions/actionUtils';

export const initialState = {
  general: {
    isActivating: false,
    error: null,
    totalDue: 0.0,
  },
  selection: {
    sims: {
      type: 'individual',
      individualSims: [],
      ranges: [],
    },
  },
  changePlan: {
    devices: [],
  },
  flags: {
    isProUser: false,
    isChangingPlan: false,
    isPreflight: false,
    defaultToLegacy: false,
    defaultToHighVolume: false,
    includePlan: true,
    includeSims: true,
    includeOverageLimits: true,
    includeTags: true,
    includePayment: true,
    overageLimitsSkippable: true,
    changingPlan: false,
  },
  sims: {
    validating: false,
    errors: {
      alreadyActivated: [],
      invalid: [],
      preflightSims: [],
      globalError: null,
    },
  },
};

const activationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.VALIDATE_SIM_RANGE_REQUEST:
    case actionTypes.VALIDATE_SIMS_REQUEST: {
      return {
        ...state,
        sims: {
          ...state.sims,
          validating: true,
        },
      };
    }

    case actionTypes.VALIDATE_SIMS_SUCCESS: {
      const { individualSims } = state.selection.sims;
      const validSims = [...individualSims, ...(action?.payload?.sims ?? [])];
      const sims = removeDuplicates(validSims);

      return {
        ...state,
        selection: {
          ...state.selection,
          sims: {
            ...state.selection?.sims,
            individualSims: sims,
          },
        },
        sims: {
          ...state.sims,
          validating: false,
        },
      };
    }

    case actionTypes.VALIDATE_SIMS_ERROR: {
      const { invalid, alreadyActivated, preflightSims } = state.sims.errors;
      const sims = action?.payload?.invalidSims ?? [];
      /* eslint-disable no-param-reassign */
      const simErrors = sims.reduce(
        (simErrorGroups, sim) => {
          if (sim?.value === 'SIM is already activated') {
            simErrorGroups.alreadyActivated = [...simErrorGroups.alreadyActivated, sim];
          } else if (sim?.value === 'Preflight SIM') {
            simErrorGroups.preflightSims = [...simErrorGroups.preflightSims, sim.key];
          } else {
            simErrorGroups.invalid = [
              ...simErrorGroups.invalid,
              { key: sim.key, ...(sim?.value && { value: sim?.value }) },
            ];
          }

          return simErrorGroups;
        },
        { invalid, alreadyActivated, preflightSims }
      );
      /* eslint-disable no-param-reassign */

      return {
        ...state,
        sims: {
          ...state.sims,
          validating: false,
          errors: {
            ...state.sims?.errors,
            alreadyActivated: removeDuplicates(simErrors.alreadyActivated),
            invalid: removeDuplicates(simErrors.invalid),
            preflightSims: removeDuplicates(simErrors.preflightSims),
            globalError: action.payload?.error,
          },
        },
      };
    }

    case actionTypes.VALIDATE_SIM_RANGE_SUCCESS: {
      const { ranges } = state.selection.sims;

      return {
        ...state,
        selection: {
          ...state.selection,
          sims: {
            ...state.selection?.sims,
            ranges: [
              ...ranges,
              {
                start: action?.start,
                end: action?.end,
                total: action?.totalValidSims,
              },
            ],
          },
        },
        sims: {
          ...state.sims,
          validating: false,
        },
      };
    }

    case actionTypes.VALIDATE_SIM_RANGE_ERROR: {
      const { invalid, alreadyActivated, preflightSims } = state.sims.errors;
      const sims = action?.invalidSims ?? [];

      /* eslint-disable no-param-reassign */
      const simErrors = sims.reduce(
        (simErrorGroups, sim) => {
          if (sim?.value === 'SIM is already activated') {
            simErrorGroups.alreadyActivated = [...(simErrorGroups.alreadyActivated || []), sim];
          } else if (sim?.value === 'Preflight SIM') {
            simErrorGroups.preflightSims = [...simErrorGroups.preflightSims, sim.key];
          } else {
            simErrorGroups.invalid = [
              ...(simErrorGroups.invalid || []),
              { key: sim.key, ...(sim?.value && { value: sim?.value }) },
            ];
          }

          return simErrorGroups;
        },
        { invalid, alreadyActivated, preflightSims }
      );
      /* eslint-disable no-param-reassign */

      return {
        ...state,
        sims: {
          ...state.sims,
          validating: false,
          errors: {
            ...state.sims?.errors,
            alreadyActivated: removeDuplicates(simErrors.alreadyActivated),
            invalid: removeDuplicates(simErrors.invalid),
            preflightSims: removeDuplicates(simErrors.preflightSims),
            globalError: action?.error,
          },
        },
      };
    }

    case actionTypes.ACTIVATE_SIMS_REQUEST: {
      return {
        ...state,
        general: {
          ...state.general,
          isActivating: true,
        },
      };
    }

    case actionTypes.ACTIVATE_SIMS_SUCCESS: {
      return {
        ...state,
        general: {
          ...state.general,
          isActivating: false,
        },
      };
    }

    case actionTypes.ACTIVATE_SIMS_ERROR: {
      return {
        ...state,
        general: {
          ...state.general,
          isActivating: false,
          error: true,
        },
      };
    }

    case actionTypes.CLEAR_INDIVIDUAL_SIM_SELECTION: {
      return {
        ...state,
        sims: {
          ...initialState.sims,
        },
        selection: {
          ...state.selection,
          sims: {
            ...state.selection?.sims,
            individualSims: [],
          },
        },
      };
    }

    case actionTypes.CLEAR_VALIDATION_ERROR_STATE: {
      return {
        ...state,
        sims: {
          validating: false,
          errors: {
            alreadyActivated: [],
            invalid: [],
            preflightSims: [],
            globalError: null,
          },
        },
      };
    }

    case actionTypes.CALCULATE_TOTAL_DUE_TODAY_ERROR: {
      return {
        ...state,
        general: {
          ...state.general,
          error: action?.payload?.error,
        },
      };
    }

    case actionTypes.UPDATE_TOTAL_DUE_TODAY: {
      const total = action.payload?.total ?? 0;
      return {
        ...state,
        general: {
          ...state.general,
          totalDue: total,
        },
      };
    }

    case actionTypes.SET_ISPREFLIGHT_FLAG: {
      return {
        ...state,
        flags: {
          ...state.flags,
          isPreflight: true,
        },
      };
    }

    case actionTypes.SET_ISCHANGEPLAN_FLAG: {
      return {
        ...state,
        flags: {
          ...state.flags,
          changingPlan: true,
        },
      };
    }

    case actionTypes.SET_CHANGEPLAN_DEVICES: {
      return {
        ...state,
        changePlan: {
          ...state.changePlan,
          devices: action.devices,
        },
      };
    }

    case actionTypes.RESET_ACTIVATION_STORE: {
      return initialState;
    }

    case actionTypes.SET_PRELOADED_ACTIVATION_DEVICES: {
      return {
        ...state,
        preflight: {
          ...state.preflight,
          sims: action.device,
        },
      };
    }

    case actionTypes.PREFLIGHT_ACTIVATION_PREVIEW_REQUEST: {
      return {
        ...state,
        preflight: {
          ...state.preflight,
          loading: true,
          sims: [],
          error: null,
        },
      };
    }

    case actionTypes.PREFLIGHT_ACTIVATION_PREVIEW_SUCCESS: {
      return {
        ...state,
        preflight: {
          ...state.preflight,
          loading: false,
          sims: action.devices,
          error: null,
        },
      };
    }

    case actionTypes.PREFLIGHT_ACTIVATION_PREVIEW_ERROR: {
      return {
        ...state,
        preflight: {
          ...state.preflight,
          loading: false,
          sims: [],
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default activationReducer;
