export const PRIVACY = '//hologram.io/privacy-policy/';
export const TERMS = '//hologram.io/terms-of-use/';
export const DOCS = '//support.hologram.io/hc/en-us';
export const NEW_SUPPORT_TICKET = '//support.hologram.io/hc/en-us/requests/new';
export const LEARN_SEND_LOG = '//support.hologram.io/hc/en-us/articles/360049985033';
export const LEARN_DEVICE_TUNNELING = '//support.hologram.io/hc/en-us/articles/360035697333';
export const DL_SPACEBRIDGE_CLIENT = '//support.hologram.io/hc/en-us/articles/9064879208855';
export const PLAN_DETAILS = '//hologram.io/pricing/';
export const LEARN_DEVICE_MESSAGING = '//support.hologram.io/hc/en-us/articles/360050139473';
export const LEARN_DEVICE_WEBHOOKS = '//https://support.hologram.io/hc/en-us/articles/360050139473';
export const LEARN_ROUTES = '//support.hologram.io/hc/en-us/articles/360035696573';
export const FEEDBACK = '//form.asana.com/?k=HOb2iQqhGUeSG1lj_PjIKQ&d=523429734671116';
export const SUPPORT = '//support.hologram.io/hc/en-us';
export const COMMUNITY = '//community.hologram.io/';
export const FAQ = '//support.hologram.io/hc/en-us';
export const DOCS_SET_APN = '//support.hologram.io/hc/en-us/articles/360035697853';
export const BILLING_FAQS = '//support.hologram.io/hc/en-us/sections/360007986333-Billing';
export const CONTACT_SALES = '//hologram.io/contact-sales';
export const PREFLIGHT_HELP = '//support.hologram.io/hc/en-us/articles/360035696873';
export const ACTIVATING_PREFLIGHT_HELP =
  '//support.hologram.io/hc/en-us/articles/360035696473-Activating-SIMs-in-Preflight';
export const SETTING_DATA_LIMITS_HELP =
  '//support.hologram.io/hc/en-us/articles/360035697313-Set-a-device-s-data-limit-';
export const FIND_SIM_ICCIDS_HELP = '//support.hologram.io/hc/en-us/articles/14839272819991';
