import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as DeviceStates from '../../constants/deviceStates';
import * as actionTypes from './actionTypes';
import { LOADMORE_PAGELENGTH } from './reducer';
import * as API from '../../api';
import { getCurrentOrgId, getUserContextData } from '../../api/util';

export const clearAlertsByType = (alertType) => ({
  type: actionTypes.CLEAR_ALERTS_BY_TYPE,
  alertType,
});

/* eslint-disable default-param-last */
export const getAlertsByType =
  (alertType, offset = 0, limit, sortBy = 'DESC') =>
  /* eslint-enable default-param-last */
  (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_ALERTS_BY_TYPE_REQUEST,
      alertType,
    });
    const orgId = getCurrentOrgId(state);

    return API.getAlertsByType(alertType, orgId, {
      offset,
      limit: limit || LOADMORE_PAGELENGTH,
      sortBy,
    })
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALERTS_BY_TYPE_SUCCESS,
          alertType,
          continues: response.continues,
          data: response.data,
          offset,
        });

        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ALERTS_BY_TYPE_ERROR,
          alertType,
          error,
        });
      });
  };

export const getAlertCountByType = (alertType) => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_ALERT_COUNT_BY_TYPE_REQUEST,
    alertType,
  });
  const orgId = getCurrentOrgId(state);

  return API.getAlertCountByType(alertType, orgId)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ALERT_COUNT_BY_TYPE_SUCCESS,
        alertType,
        count: response.data,
      });

      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_ALERT_COUNT_BY_TYPE_ERROR,
        alertType,
        error,
      });
    });
};

export const acknowledgeAlert = (alertId, alertType) => (dispatch, state) => {
  dispatch({
    type: actionTypes.ACKNOWLEDGE_ALERT_REQUEST,
    acknowledgedAlertIds: [alertId],
    alertType,
  });
  const contextData = getUserContextData(state);
  const orgId = getCurrentOrgId(state);
  return API.acknowledgeAlert(alertId, contextData.userId, orgId)
    .then((response) => {
      dispatch({
        type: actionTypes.ACKNOWLEDGE_ALERT_SUCCESS,
        acknowledgedAlertIds: [alertId],
        alertType,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.ACKNOWLEDGE_ALERT_ERROR,
        acknowledgedAlertIds: [alertId],
        error,
        alertType,
      });
      return Promise.reject(error);
    });
};

export const unacknowledgeAlert = (alertId, alertType) => (dispatch, state) => {
  dispatch({
    type: actionTypes.UNACKNOWLEDGE_ALERT_REQUEST,
    alertId,
    alertType,
  });

  const { userId } = getUserContextData(state);
  const orgId = getCurrentOrgId(state);

  return API.unacknowledgeAlert(alertId, userId, orgId)
    .then((response) => {
      dispatch({
        type: actionTypes.UNACKNOWLEDGE_ALERT_SUCCESS,
        alertId,
        alertType,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UNACKNOWLEDGE_ALERT_ERROR,
        error,
        alertType,
      });
    });
};

/* eslint-disable default-param-last */
export const acknowledgeAlerts =
  (alertIds = [], alertType) =>
  /* eslint-enable default-param-last */
  (dispatch, state) => {
    dispatch({
      type: actionTypes.ACKNOWLEDGE_ALERTS_REQUEST,
      acknowledgedAlertIds: alertIds,
      alertType,
    });
    const contextData = getUserContextData(state);
    const orgId = getCurrentOrgId(state);

    return API.acknowledgeAlerts(alertIds, contextData.userId, orgId)
      .then((response) => {
        dispatch({
          type: actionTypes.ACKNOWLEDGE_ALERTS_SUCCESS,
          acknowledgedAlertIds: alertIds,
          alertType,
        });

        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ACKNOWLEDGE_ALL_ALERTS_ERROR,
          error,
          alertType,
        });

        return Promise.reject(error);
      });
  };

export const acknowledgeAllAlerts = (alertType) => (dispatch, state) => {
  dispatch({
    type: actionTypes.ACKNOWLEDGE_ALL_ALERTS_REQUEST,
    alertType,
  });
  const contextData = getUserContextData(state);
  const orgId = getCurrentOrgId(state);
  return API.acknowledgeAllAlerts(contextData.userId, orgId, alertType)
    .then((data) => {
      dispatch({
        type: actionTypes.ACKNOWLEDGE_ALL_ALERTS_SUCCESS,
        alertType,
      });
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.ACKNOWLEDGE_ALL_ALERTS_ERROR,
        alertType,
        error,
      });
      return Promise.reject(error);
    });
};

export const setActiveAlert = (alertId, alertType) => ({
  type: actionTypes.SET_ACTIVE_ALERT,
  alertId,
  alertType,
});

export const setActivePin = (alertId, alertType) => ({
  type: actionTypes.SET_ACTIVE_PIN,
  alertId,
  alertType,
});

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export const pauseDevices = (deviceIds, alerts, alertType, useBizLayer) => (dispatch, state) => {
  dispatch({
    type: actionTypes.PAUSE_ALERT_DEVICES_REQUEST,
    alertType,
  });

  const userContextData = getUserContextData(state);

  const selection = {
    byId: deviceIds.reduce((acc, deviceId) => ({ ...acc, [deviceId]: true }), {}),
    byLinkId: {},
    excludedIds: [],
    allSelected: false,
    pagesSelected: [],
  };

  const filters = {
    limit: 1,
    pageIdsSelected: [],
  };

  return API.pauseDataBulk({
    selection,
    filters,
    isPreview: false,
    userContextData,
    useSearch: false,
    useBizLayer,
  })
    .then((data) => {
      const updatedAlerts = alerts.map((alert) => {
        const updated = { ...alert };
        if (deviceIds.includes(alert.deviceid)) {
          updated.state = DeviceStates.PAUSED_PENDING_USER;
        }
        return updated;
      });

      dispatch({
        type: actionTypes.PAUSE_ALERT_DEVICES_SUCCESS,
        alerts: updatedAlerts,
        alertType,
      });

      sendAnalyticsEvent({
        type: analyticsTypes.PAUSE_SIM,
        data: {
          page: 'Alerts',
        },
      });

      const successCount = useBizLayer
        ? (data.successes ?? []).length
        : (data?.validTasks?.[0].params?.deviceids ?? []).length;

      // Only consumer right now is TableContainer.js which only needs the number
      return Promise.resolve(successCount);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.PAUSE_ALERT_DEVICES_ERROR,
        error,
        alertType,
      });

      return Promise.reject(error);
    });
};

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export const resumeDevice = (deviceId, alerts, alertType) => (dispatch, state) => {
  dispatch({
    type: actionTypes.RESUME_ALERT_DEVICE_REQUEST,
    alertType,
  });

  const userContextData = getUserContextData(state);

  const selection = {
    byId: { [deviceId]: true },
    byLinkId: {},
    excludedIds: [],
    allSelected: false,
    pagesSelected: [],
  };

  const filters = {
    limit: 1,
    pageIdsSelected: [],
  };

  return API.resumeDataBulk({
    selection,
    filters,
    isPreview: false,
    userContextData,
    useSearch: false,
  })
    .then((data) => {
      const updatedAlerts = alerts.map((alert) => {
        const updated = { ...alert };
        if (alert.deviceid === deviceId) {
          updated.state = DeviceStates.LIVE_PENDING;
        }
        return updated;
      });

      dispatch({
        type: actionTypes.RESUME_ALERT_DEVICE_SUCCESS,
        alerts: updatedAlerts,
        alertType,
      });

      sendAnalyticsEvent({
        type: analyticsTypes.RESUME_SIM,
        data: {
          page: 'Alerts',
        },
      });

      const successes = data?.validTasks?.[0].params?.deviceids || [];

      return Promise.resolve(successes, successes.length);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.RESUME_ALERT_DEVICE_ERROR,
        error,
        alertType,
      });

      return Promise.reject(error);
    });
};

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export const resumeBulk = (deviceIds, alerts, alertType) => (dispatch, state) => {
  dispatch({
    type: actionTypes.RESUME_ALERT_BULK_REQUEST,
    alertType,
  });

  const userContextData = getUserContextData(state);

  const selection = {
    byId: deviceIds.reduce((acc, deviceId) => ({ ...acc, [deviceId]: true }), {}),
    byLinkId: {},
    excludedIds: [],
    allSelected: false,
    pagesSelected: [],
  };

  const filters = {
    limit: 1,
    pageIdsSelected: [],
  };

  return API.resumeDataBulk({
    selection,
    filters,
    isPreview: false,
    userContextData,
    useSearch: false,
  })
    .then((data) => {
      const updatedAlerts = alerts.map((alert) => {
        const updatedAlert = { ...alert };
        if (deviceIds.includes(alert.deviceid)) {
          updatedAlert.state = DeviceStates.LIVE_PENDING;
        }
        return updatedAlert;
      });

      dispatch({
        type: actionTypes.RESUME_ALERT_BULK_SUCCESS,
        alerts: updatedAlerts,
        alertType,
      });

      const successes = data?.validTasks?.[0].params?.deviceids || [];

      return Promise.resolve(successes, successes.length);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.RESUME_ALERT_BULK_ERROR,
        error,
        alertType,
      });

      return Promise.reject(error);
    });
};

/**
 * @deprecated Still currently used by Alerts. Can be deleted after they are upgraded away or removed.
 */
export const deactivateDevices = (devicesToDeactivate, alerts) => (dispatch) => {
  dispatch({
    type: actionTypes.DEACTIVATE_ALERT_DEVICES_REQUEST,
  });
  const deviceIds = devicesToDeactivate.map((device) => device.id);
  return API.bulkDeactivateDevices(deviceIds)
    .then(() => {
      const nextAlerts = alerts.map((alert) => {
        const updated = { ...alert };
        if (deviceIds.includes(alert.deviceid)) {
          updated.state = DeviceStates.DEAD_PENDING;
        }
        return updated;
      });
      dispatch({
        type: actionTypes.DEACTIVATE_ALERT_DEVICES_SUCCESS,
        alerts: nextAlerts,
      });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.DEACTIVATE_ALERT_DEVICES_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export const clearAcknowledgedAlerts = (alertType) => ({
  type: actionTypes.CLEAR_ACKNOWLEDGED_ALERTS,
  alertType,
});
