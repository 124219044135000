import React, { useCallback, useRef, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button } from '@holokit/core';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import useDeleteWebhook from 'common-js/api/devices/useDeleteWebhook';
import useRegenerateWebhook from 'common-js/api/devices/useRegenerateWebhook';
import { WebhookModel } from 'common-js/models';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import useCopyElement from './useCopyElement';

const ShowWebhook = ({ webhook, setWebhook, onUpdate }) => {
  const dispatch = useAppDispatch();
  const webhookUrlRef = useRef(null);
  const regenerateWebhook = useRegenerateWebhook();
  const deleteWebhook = useDeleteWebhook();
  const [regenerating, setRegenerating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const device = useAppSelector(selectCurrentDevice);

  const onCopyUrl = useCopyElement(webhookUrlRef, 'Webhook url');

  const onRegenerateWebhook = useCallback(async () => {
    setRegenerating(true);
    try {
      const hook = await regenerateWebhook(device.id);
      setWebhook(hook);
      dispatch(pushGlobalMessage('Webhook regenerated'));
    } catch (err) {
      dispatch(pushGlobalMessage(err, 'error'));
    }
    setRegenerating(false);
  }, [device.id, dispatch, regenerateWebhook, setRegenerating, setWebhook]);

  const onDeleteWebhook = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this webhook?')) {
      setDeleting(true);
      try {
        await deleteWebhook(device.id);
        // This pushes the webhook update (in the parent) to happen immediately after everything else is updated
        setTimeout(() => setWebhook(new WebhookModel()));
      } catch (err) {
        dispatch(pushGlobalMessage(err, 'error'));
      }
      setDeleting(false);
    }
  }, [device.id, dispatch, deleteWebhook, setDeleting, setWebhook]);

  return (
    <div className="form Panel__padding">
      <div className="form-row grid-row vertical-align section">
        <div className="type-paragraph grid-item">
          <div className="grid-row section-name">Webhook url</div>
        </div>
        <div className="grid-item-3">
          <div className="grid-row vertical-align">
            <div className="grid-item copy-container type-paragraph">{webhook.url}</div>
            <Button onClick={onCopyUrl} type="secondary">
              Copy URL
            </Button>
            <textarea ref={webhookUrlRef} value={webhook.url} className="hidden" readOnly />
          </div>
        </div>
      </div>
      <div className="form-row grid-row vertical-align section">
        <div className="type-paragraph grid-item">
          <div className="grid-row section-name">Port</div>
        </div>
        <div className="grid-item-3 type-paragraph section-value">{webhook.port}</div>
      </div>
      <div className="grid-row vertical-align section">
        <div className="type-paragraph grid-item">
          <div className="grid-row section-name">Protocol</div>
        </div>
        <div className="grid-item-3 type-paragraph section-value">{webhook.protocol}</div>
      </div>
      <div className="grid-row vertical-align section">
        <div className="type-paragraph grid-item">
          <div className="grid-row section-name">Manage</div>
        </div>
        <div className="grid-item-3">
          <Button onClick={onUpdate} type="secondary">
            Update
          </Button>
          <Button onClick={onRegenerateWebhook} type="secondary">
            {regenerating ? 'Regenerating...' : 'Regenerate'}
          </Button>
          <Button onClick={onDeleteWebhook} type="destructiveMajor">
            {deleting ? 'Deleting...' : 'Delete'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShowWebhook;
