import useAutoAcceptOrgInvite from 'common-js/hooks/useAutoAcceptOrgInvite';
import useShowOrgIntroModal from 'common-js/hooks/useShowOrgIntroModal';
import React from 'react';
import FleetHome from './FleetHome';

interface HomeWrapperProps {
  location: RouterLocation;
}

// This component handles routing the user between the new Fleet Home page and
// the old Alerts homepage. When `fleet` is turned on for everyone, this
// component can be deleted.
const HomeWrapper: React.FC<HomeWrapperProps> = ({ location }) => {
  useAutoAcceptOrgInvite({ location });
  useShowOrgIntroModal({ location });

  return <FleetHome />;
};
export default HomeWrapper;
