import { StartPage } from '@hologram-hyper-dashboard/components';
import useOnButtonClickWithAnalytics from 'common-js/analytics/useOnButtonClickWithAnalytics';
import useOnPageViewWithAnalytics from 'common-js/analytics/useOnPageViewWithAnalytics';
import { ACCOUNT_LOGIN, ACCOUNT_REGISTER } from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { fetchAccount, getUserInfo } from 'common-js/reducers/account/actions';
import React, { useCallback, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import Loading from './Loading';

const Start: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [loading, setIsLoading] = useState(false);

  const navToDashboard = useCallback(() => {
    setIsLoading(true);
    dispatch(fetchAccount());
  }, [dispatch]);

  const navToRegister = useCallback(() => browserHistory.push(ACCOUNT_REGISTER), []);

  const navToLogin = useCallback(() => browserHistory.push(ACCOUNT_LOGIN), []);

  useEffect(() => {
    const tryGetUserInfo = async () => {
      try {
        await dispatch(getUserInfo());
        setIsLoggedIn(true);
      } catch {
        setIsLoggedIn(false);
      }
    };

    tryGetUserInfo();
  }, [dispatch]);

  const onButtonClick = useOnButtonClickWithAnalytics({
    pageName: 'Start',
    sendParams: { isLoggedIn },
  });

  const onPageView = useOnPageViewWithAnalytics();

  return loading ? (
    <Loading />
  ) : (
    <StartPage
      isLoggedIn={isLoggedIn}
      navToDashboard={navToDashboard}
      navToRegister={navToRegister}
      navToLogin={navToLogin}
      onButtonClick={onButtonClick}
      onPageView={onPageView}
    />
  );
};

export default Start;
