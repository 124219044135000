import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import { Accordion, Button, Checkbox } from '@holokit/core';
import * as Paths from 'common-js/constants/paths';
import { getTags } from 'common-js/reducers/deviceFilter/actions';
import { toggleFilter, clearSelection } from 'common-js/reducers/devices/actions';
import { getFilters } from 'common-js/reducers/devices/selectors';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

class TagsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTruncated: true,
      updateAccordionHeight: false,
    };
  }

  componentDidMount() {
    const { getTags_ } = this.props;
    getTags_();
  }

  handleExpand = () => {
    this.setState({
      isTruncated: false,
      updateAccordionHeight: true,
    });
    sendAnalyticsEvent({
      type: analyticsTypes.FILTERS_SEE_ALL_OPTIONS,
      data: {
        filter_name: 'tags',
      },
    });
  };

  updateAccordionHeightCallback = () => {
    this.setState({ updateAccordionHeight: false });
  };

  render() {
    const { clearSelection_, defaultOpen, filters, tags, toggleFilter_ } = this.props;
    const { isTruncated, updateAccordionHeight } = this.state;
    const hasTags = !!tags.length;
    const isCollapsed = isTruncated && tags.length > 7;
    const tagsToDisplay = hasTags && isCollapsed ? tags.slice(0, 7) : tags;

    return (
      <div className="DevicesFilter">
        <Accordion
          defaultOpen={defaultOpen}
          footer={
            !!hasTags && (
              <Checkbox
                classes="DevicesFilter__checkbox"
                checked={!!filters.tags?.true}
                textLabel="Devices with no tags"
                name="untagged"
                onChange={() => {
                  toggleFilter_('tags', true, 'NO_VALUE');
                  clearSelection_();
                }}
              />
            )
          }
          header="Tags"
          iconLeft="Tag"
          isFiltered={'tag_id' in filters || 'tags' in filters}
          updateAccordionHeight={updateAccordionHeight}
          updateAccordionHeightCallback={this.updateAccordionHeightCallback}
          accordionToggleCallback={({ open }) => {
            sendAnalyticsEvent({
              type: analyticsTypes.FILTERS_TOGGLE_ACCORDION,
              data: {
                accordion_name: 'Tags Filter',
                accordion_open: open,
              },
            });
          }}
        >
          {hasTags ? (
            <>
              <ul className="DevicesFilter__list">
                {tagsToDisplay.map((tag) => {
                  const active = filters.tag_id?.[tag.id];

                  return (
                    <li className="DevicesFilter__list-item" key={tag.id}>
                      <Checkbox
                        classes="DevicesFilter__checkbox"
                        checked={active}
                        onChange={() => {
                          toggleFilter_('tag_id', tag.id);
                          clearSelection_();
                          sendAnalyticsEvent({
                            type: analyticsTypes.FILTERS_SET_FILTER,
                            data: {
                              filter_name: 'Tags Filter',
                              filter_value: tag.name,
                            },
                          });
                        }}
                        textLabel={tag.name}
                        name={tag.name}
                      />
                    </li>
                  );
                })}
              </ul>
              {isCollapsed && (
                <Button
                  classes="DevicesFilter__expand-toggle Button--minimal"
                  onClick={this.handleExpand}
                >
                  See all {tags.length} Tags
                </Button>
              )}
            </>
          ) : (
            <div className="DevicesFilter__empty">
              Add tags with the{' '}
              <Link to={Paths.withContext(Paths.DEVICES_TAG_MANAGER)}>Tag Manager</Link>, or select
              devices in the table, then click “Add or remove a tag” button
            </div>
          )}
        </Accordion>
      </div>
    );
  }
}

TagsFilter.defaultProps = {
  tags: [],
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  tags: state.deviceFilters.tags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      getTags_: getTags,
      toggleFilter_: toggleFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TagsFilter);
