import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, Accordion } from '@holokit/core';
import { LAST_ACTIVE } from 'common-js/constants/deviceFilterFields';
import {
  clearSelection,
  removeFilter,
  setFilterInputValue,
} from 'common-js/reducers/devices/actions';
import { getFilters } from 'common-js/reducers/devices/selectors';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

class LastActiveFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateAccordionHeight: false,
    };
  }

  handleSelect = (e) => {
    const { clearSelection_, setTimeFilters } = this.props;

    clearSelection_();

    const splitValue = e.currentTarget.value.split(',');
    if (splitValue.length > 1) {
      const [num, unit] = splitValue;
      setTimeFilters(num, unit, 'lastActive', LAST_ACTIVE);
      sendAnalyticsEvent({
        type: analyticsTypes.FILTERS_SET_FILTER,
        data: {
          filter_name: 'Last active filter',
          filter_value: `${num} ${unit}`,
          filter_is_preset: true,
        },
      });
    }
  };

  updateAccordionHeightCallback = () => {
    this.setState({ updateAccordionHeight: false });
  };

  render() {
    const { updateAccordionHeight } = this.state;
    const { defaultOpen, filters, userInput } = this.props;
    const { num, unit } = userInput;
    const latestSelectedOption = num !== '' && num !== '-1' ? `${num},${unit}` : '-1';
    const selectPrefix = 'In the last';
    const selectOptions = [
      {
        name: '—',
        value: '-1',
      },
      {
        name: `${selectPrefix} 24 hours`,
        value: '24,Hours',
      },
      {
        name: `${selectPrefix} 7 days`,
        value: '7,Days',
      },
      {
        name: `${selectPrefix} 30 days`,
        value: '30,Days',
      },
      {
        name: `${selectPrefix} 120 days`,
        value: '120,Days',
      },
      {
        name: `${selectPrefix} 365 days`,
        value: '365,Days',
      },
    ];

    return (
      <div className="DevicesFilter">
        <Accordion
          defaultOpen={defaultOpen}
          header="Last active"
          iconLeft="History"
          isFiltered={LAST_ACTIVE in filters}
          updateAccordionHeight={updateAccordionHeight}
          updateAccordionHeightCallback={this.updateAccordionHeightCallback}
          accordionToggleCallback={({ open }) => {
            sendAnalyticsEvent({
              type: analyticsTypes.FILTERS_TOGGLE_ACCORDION,
              data: {
                accordion_name: 'Last active filter',
                accordion_open: open,
              },
            });
          }}
        >
          <form className="DevicesFilter__form">
            <Select
              classes="DevicesFilter__form__field"
              id="select"
              onChange={this.handleSelect}
              options={selectOptions}
              value={latestSelectedOption}
            />
          </form>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  userInput: {
    num: state.devices.uiState.filterInputs.lastActiveTimeNumber,
    unit: state.devices.uiState.filterInputs.lastActiveTimeUnit,
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      removeFilter_: removeFilter,
      setFilterInputValue_: setFilterInputValue,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LastActiveFilter);
