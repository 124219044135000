import React from 'react';
import ClassNames from 'clsx';
import PropTypes from 'prop-types';

/*
 * This component uses the window.navigator.platform to determine which Command Key icon
 * to display. As per https://developer.mozilla.org/en-US/docs/Web/API/NavigatorID/platform, this
 * property has been deprecated and we shouldn't rely it to always return something accurate
 * The component will default to displaying the Mac OS Command icon unless it detects a
 * windows platform. List of potential platforms is listed here
 * https://stackoverflow.com/questions/19877924/what-is-the-list-of-possible-values-for-navigator-platform-as-of-today
 */
function CommandKey({ className, actionKey }) {
  const macPlatforms = ['iPad', 'iPad Simulator', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const currentPlatform = window.navigator.platform;
  const isRunningMac = macPlatforms.includes(currentPlatform);

  return (
    <kbd
      className={ClassNames(`CommandKey ${className}`, {
        [`${className}--windows`]: !isRunningMac && className,
      })}
    >
      {isRunningMac ? `${String.fromCharCode(8984)}${actionKey}` : `Ctrl+${actionKey}`}
    </kbd>
  );
}

CommandKey.defaultProps = {
  className: '',
  actionKey: '',
};

CommandKey.propTypes = {
  className: PropTypes.string,
  actionKey: PropTypes.string,
};

export default CommandKey;
