import * as searchActionTypes from './actionTypes';

export const initialState = {
  uiState: {
    modal: {
      opened: false,
    },
  },
};

const searchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case searchActionTypes.OPEN_MODAL:
      return {
        ...state,
        uiState: {
          ...state.uiState,
          modal: {
            ...state.uiState.modal,
            opened: true,
          },
        },
      };
    case searchActionTypes.CLOSE_MODAL:
      return {
        ...state,
        uiState: {
          ...state.uiState,
          modal: {
            ...state.uiState.modal,
            opened: false,
          },
        },
      };
    default:
      return state;
  }
};

export default searchReducer;
