import React from 'react';
import { Chip } from '@holokit/core';
import { addCommasToNumber } from '../../common/utils/numberFormatter';

function SimRangeDisplay({ ranges, numSims, totalSims, onDelete }) {
  const numRanges = ranges?.length ?? 0;

  return (
    <div className="SimsDisplay">
      {numRanges === 0 && (
        <div className="SimsDisplay--empty-state">No SIM(s) added for activation.</div>
      )}
      {numRanges > 0 && (
        <>
          <div className="SimsDisplay__text">
            {`${addCommasToNumber(numSims, false)} of ${addCommasToNumber(totalSims, false)}`}{' '}
            {totalSims === 1 ? 'SIM' : 'SIMs'} added for activation
          </div>
          <div className="SimRangeDisplay__chips">
            {ranges.map((range, index) => (
              <Chip
                key={`${range?.start}-${range?.end}`}
                classes="SimRangeDisplay__chip"
                onDelete={onDelete}
                text={`Box ${index + 1}: ${range?.start} – ${range?.end}`}
                type="sim"
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default SimRangeDisplay;
