import React from 'react';
import SimsDisplay from './SimsDisplay';

function AlreadyActivated({ sims }) {
  return (
    <div className="SimPanel__errors">
      <SimsDisplay
        sims={sims}
        onDelete={() => null}
        text="added for activation"
        variant="alreadyActivated"
      />
    </div>
  );
}

export default AlreadyActivated;
