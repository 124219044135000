import { selectHasBizLayerPauseFlag } from 'common-js/reducers/releaseFlag/selectors';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useDeviceStateChange from './useDeviceStateChange';

const usePauseDevice = () => {
  const useBusinessLayer: boolean = useAppSelector(selectHasBizLayerPauseFlag);

  return useDeviceStateChange({
    newState: 'pause',
    useBusinessLayer,
  });
};

export default usePauseDevice;
