import PropTypes from 'prop-types';
import React from 'react';

class Radio extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultMessage: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    error: PropTypes.string,
    showValidation: PropTypes.bool,
  };

  render() {
    const {
      checked,
      children,
      defaultMessage,
      disabled,
      error,
      inputValue,
      name,
      onChange,
      showValidation,
      value,
    } = this.props;

    return (
      <div className={`input-container radio${disabled ? ' disabled' : ''}`}>
        <label>
          <input
            checked={checked || value === inputValue}
            disabled={disabled}
            name={name}
            onChange={(e) => onChange(e.currentTarget.value)}
            type="radio"
            value={inputValue}
          />
          {children}
        </label>
      </div>
    );
  }
}

Radio.defaultProps = {
  children: null,
  defaultMessage: 'Please wait\u2026',
  disabled: false,
  error: null,
  showValidation: true,
};

export default Radio;
