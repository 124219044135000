import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Panel } from '@hologram-dimension/panel';
import { PanelNotification } from '@hologram-dimension/panel-notification';
import type { Plan } from 'common-js/types/Plan';
import { useCallback, useMemo } from 'react';
import PlanTypeSection from './PlanTypeSection';
import PlanTypes from './planTypes';

interface CustomPlansPanelProps {
  customPlans: Array<Plan>;
  isLoading: boolean;
  onChange: (...arg: Array<any>) => any;
  selectedPlan?: Plan;
  preflightMode: boolean;
  numSims: number;
  existingPlanId?: PlanId;
}

function CustomPlansPanel({
  customPlans,
  isLoading,
  onChange,
  selectedPlan,
  preflightMode,
  numSims,
  existingPlanId,
}: CustomPlansPanelProps) {
  const selectPlan = useCallback(
    (newPlan) => {
      onChange(newPlan);
    },
    [onChange]
  );

  const hasCustomPlans = !!(customPlans && customPlans.length > 0);

  const emptyState = useMemo(() => {
    if (!hasCustomPlans) {
      return (
        <PanelNotification
          label="The SIMs added are not compatible with your organization's custom plans"
          icon={<Icon name="CircleInfo" size="large" fill="DdsColorFillNotificationNote" />}
          actions={
            <Button
              iconEnd="ExternalLink"
              href="https://support.hologram.io/hc/en-us"
              target="_blank"
              variant="secondary"
            >
              Contact support
            </Button>
          }
        >
          Please try our Standard plans or contact us for additional support.
        </PanelNotification>
      );
    }

    return null;
  }, [hasCustomPlans]);

  return (
    <Panel isLoading={isLoading} noContentPadding className="ActivationPlans__custom">
      <div className="ActivationPlans__panel-body">
        {!isLoading && (
          <PlanTypeSection
            selectedPlan={selectedPlan}
            plans={customPlans}
            planType={preflightMode ? PlanTypes.Preflight : PlanTypes.Custom}
            onChange={selectPlan}
            numSims={numSims}
            existingPlanId={existingPlanId}
          />
        )}
      </div>
      {!isLoading && emptyState}
    </Panel>
  );
}

export default CustomPlansPanel;
