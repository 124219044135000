import { Loader } from '@hologram-dimension/loader';
import FullScreenModal from 'common-js/components/full-screen-modal';
import React from 'react';
import SkeletonSidebar from './SkeletonSidebar';

interface LoadingScreenProps {
  content?: string;
  changingPlan?: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ content, changingPlan }) => (
  <FullScreenModal
    showProgress={false}
    title={changingPlan ? 'Change data plan' : 'Activate SIMs'}
    sidebar={<SkeletonSidebar />}
  >
    <Loader className="LoadingScreen">{`Hang tight! ${content}`}</Loader>
  </FullScreenModal>
);

export default LoadingScreen;
