const PREFIX = 'ACCOUNT_' as const;

export const SET_USER_INFO = `${PREFIX}SET_USER_INFO` as const;
export const SET_USER_SETTING = `${PREFIX}SET_USER_SETTING` as const;
export const SET_DISMISSED_ALERTS = `${PREFIX}SET_DISMISSED_ALERTS` as const;
export const UNMOUNT_ORDERS = `${PREFIX}UNMOUNT_ORDERS` as const;
export const SET_SHOW_ALL_DEVICES = `${PREFIX}SET_SHOW_ALL_DEVICES` as const;
export const TOGGLE_VIEW_BALANCEHISTORY = `${PREFIX}GET_ALL_BILLING_ERROR` as const;

export const GET_ORDERS_REQUEST = `${PREFIX}GET_ORDERS_REQUEST` as const;
export const GET_ORDERS_SUCCESS = `${PREFIX}GET_ORDERS_SUCCESS` as const;
export const GET_ORDERS_ERROR = `${PREFIX}GET_ORDERS_ERROR` as const;

export const GET_APIKEY_REQUEST = `${PREFIX}GET_APIKEY_REQUEST` as const;
export const GET_APIKEY_SUCCESS = `${PREFIX}GET_APIKEY_SUCCESS` as const;
export const GET_APIKEY_ERROR = `${PREFIX}GET_APIKEY_ERROR` as const;

export const GENERATE_KEY_REQUEST = `${PREFIX}GENERATE_KEY_REQUEST` as const;
export const GENERATE_KEY_SUCCESS = `${PREFIX}GENERATE_KEY_SUCCESS` as const;
export const GENERATE_KEY_ERROR = `${PREFIX}GENERATE_KEY_ERROR` as const;

export const LOGOUT_REQUEST = `${PREFIX}LOGOUT_REQUEST` as const;
export const LOGOUT_SUCCESS = `${PREFIX}LOGOUT_SUCCESS` as const;
export const LOGOUT_ERROR = `${PREFIX}LOGOUT_ERROR` as const;

export const LOGIN_REQUEST = `${PREFIX}LOGIN_REQUEST` as const;
export const LOGIN_SUCCESS = `${PREFIX}LOGIN_SUCCESS` as const;
export const LOGIN_CLEAR_LOADER = `${PREFIX}LOGIN_CLEAR_LOADER` as const;
export const LOGIN_ERROR = `${PREFIX}LOGIN_ERROR` as const;

export const REGISTER_REQUEST = `${PREFIX}REGISTER_REQUEST` as const;
export const REGISTER_SUCCESS = `${PREFIX}REGISTER_SUCCESS` as const;
export const REGISTER_ERROR = `${PREFIX}REGISTER_ERROR` as const;

export const RESET_PASSWORD_REQUEST = `${PREFIX}RESET_PASSWORD_REQUEST` as const;
export const RESET_PASSWORD_SUCCESS = `${PREFIX}RESET_PASSWORD_SUCCESS` as const;
export const RESET_PASSWORD_ERROR = `${PREFIX}RESET_PASSWORD_ERROR` as const;

export const VERIFY_ACCOUNT_REQUEST = `${PREFIX}VERIFY_ACCOUNT_REQUEST` as const;
export const VERIFY_ACCOUNT_SUCCESS = `${PREFIX}VERIFY_ACCOUNT_SUCCESS` as const;
export const VERIFY_ACCOUNT_ERROR = `${PREFIX}VERIFY_ACCOUNT_ERROR` as const;

export const VERIFY_ACCOUNT_STATUS_REQUEST = `${PREFIX}VERIFY_ACCOUNT_STATUS_REQUEST` as const;
export const VERIFY_ACCOUNT_STATUS_SUCCESS = `${PREFIX}VERIFY_ACCOUNT_STATUS_SUCCESS` as const;
export const VERIFY_ACCOUNT_STATUS_ERROR = `${PREFIX}VERIFY_ACCOUNT_STATUS_ERROR` as const;

export const GET_PLANS_REQUEST = `${PREFIX}GET_PLANS_REQUEST` as const;
export const GET_PLANS_SUCCESS = `${PREFIX}GET_PLANS_SUCCESS` as const;
export const GET_PLANS_ERROR = `${PREFIX}GET_PLANS_ERROR` as const;

export const ADD_BALANCE_REQUEST = `${PREFIX}ADD_BALANCE_REQUEST` as const;
export const ADD_BALANCE_SUCCESS = `${PREFIX}ADD_BALANCE_SUCCESS` as const;
export const ADD_BALANCE_ERROR = `${PREFIX}ADD_BALANCE_ERROR` as const;

export const UPDATE_BALANCE_PARAMS_REQUEST = `${PREFIX}UPDATE_BALANCE_PARAMS_REQUEST` as const;
export const UPDATE_BALANCE_PARAMS_SUCCESS = `${PREFIX}UPDATE_BALANCE_PARAMS_SUCCESS` as const;
export const UPDATE_BALANCE_PARAMS_ERROR = `${PREFIX}UPDATE_BALANCE_PARAMS_ERROR` as const;

export const ACCEPT_INVITE_REQUEST = `${PREFIX}ACCEPT_INVITE_REQUEST` as const;
export const ACCEPT_INVITE_SUCCESS = `${PREFIX}ACCEPT_INVITE_SUCCESS` as const;
export const ACCEPT_INVITE_ERROR = `${PREFIX}ACCEPT_INVITE_ERROR` as const;

export const GET_PENDING_ORGS_REQUEST = `${PREFIX}GET_PENDING_ORGS_REQUEST` as const;
export const GET_PENDING_ORGS_SUCCESS = `${PREFIX}GET_PENDING_ORGS_SUCCESS` as const;
export const GET_PENDING_ORGS_ERROR = `${PREFIX}GET_PENDING_ORGS_ERROR` as const;

export const RESEND_EMAIL_VERIFY_REQUEST = `${PREFIX}RESEND_EMAIL_VERIFY_REQUEST` as const;
export const RESEND_EMAIL_VERIFY_SUCCESS = `${PREFIX}RESEND_EMAIL_VERIFY_SUCCESS` as const;
export const RESEND_EMAIL_VERIFY_ERROR = `${PREFIX}RESEND_EMAIL_VERIFY_ERROR` as const;

export const GET_USER_INFO_REQUEST = `${PREFIX}GET_USER_INFO_REQUEST` as const;
export const GET_USER_INFO_SUCCESS = `${PREFIX}GET_USER_INFO_SUCCESS` as const;
export const GET_USER_INFO_ERROR = `${PREFIX}GET_USER_INFO_ERROR` as const;

export const UPDATE_USER_INFO_REQUEST = `${PREFIX}UPDATE_USER_INFO_REQUEST` as const;
export const UPDATE_USER_INFO_SUCCESS = `${PREFIX}UPDATE_USER_INFO_SUCCESS` as const;
export const UPDATE_USER_INFO_ERROR = `${PREFIX}UPDATE_USER_INFO_ERROR` as const;

export const GET_BALANCE_REQUEST = `${PREFIX}GET_BALANCE_REQUEST` as const;
export const GET_BALANCE_SUCCESS = `${PREFIX}GET_BALANCE_SUCCESS` as const;
export const GET_BALANCE_ERROR = `${PREFIX}GET_BALANCE_ERROR` as const;

export const CHANGE_PASSWORD_REQUEST = `${PREFIX}CHANGE_PASSWORD_REQUEST` as const;
export const CHANGE_PASSWORD_SUCCESS = `${PREFIX}CHANGE_PASSWORD_SUCCESS` as const;
export const CHANGE_PASSWORD_ERROR = `${PREFIX}CHANGE_PASSWORD_ERROR` as const;

export const APPLY_PROMO_REQUEST = `${PREFIX}APPLY_PROMO_REQUEST` as const;
export const APPLY_PROMO_SUCCESS = `${PREFIX}APPLY_PROMO_SUCCESS` as const;
export const APPLY_PROMO_ERROR = `${PREFIX}APPLY_PROMO_ERROR` as const;

export const UPDATE_CC_REQUEST = `${PREFIX}UPDATE_CC_REQUEST` as const;
export const UPDATE_CC_SUCCESS = `${PREFIX}UPDATE_CC_SUCCESS` as const;
export const UPDATE_CC_ERROR = `${PREFIX}UPDATE_CC_ERROR` as const;

export const UPDATE_BILLING_ADDRESS_REQUEST = `${PREFIX}UPDATE_BILLING_ADDRESS_REQUEST` as const;
export const UPDATE_BILLING_ADDRESS_SUCCESS = `${PREFIX}UPDATE_BILLING_ADDRESS_SUCCESS` as const;
export const UPDATE_BILLING_ADDRESS_ERROR = `${PREFIX}UPDATE_BILLING_ADDRESS_ERROR` as const;

export const UPDATE_INVOICE_INFO_REQUEST = `${PREFIX}UPDATE_INVOICE_INFO_REQUEST` as const;
export const UPDATE_INVOICE_INFO_SUCCESS = `${PREFIX}UPDATE_INVOICE_INFO_SUCCESS` as const;
export const UPDATE_INVOICE_INFO_ERROR = `${PREFIX}UPDATE_INVOICE_INFO_ERROR` as const;

export const REMOVE_INVOICE_INFO_REQUEST = `${PREFIX}REMOVE_INVOICE_INFO_REQUEST` as const;
export const REMOVE_INVOICE_INFO_SUCCESS = `${PREFIX}REMOVE_INVOICE_INFO_SUCCESS` as const;
export const REMOVE_INVOICE_INFO_ERROR = `${PREFIX}REMOVE_INVOICE_INFO_ERROR` as const;

export const REMOVE_CARD_REQUEST = `${PREFIX}REMOVE_CARD_REQUEST` as const;
export const REMOVE_CARD_SUCCESS = `${PREFIX}REMOVE_CARD_SUCCESS` as const;
export const REMOVE_CARD_ERROR = `${PREFIX}REMOVE_CARD_ERROR` as const;

export const UPDATE_SETTINGS_REQUEST = `${PREFIX}UPDATE_SETTINGS_REQUEST` as const;
export const UPDATE_SETTINGS_SUCCESS = `${PREFIX}UPDATE_SETTINGS_SUCCESS` as const;
export const UPDATE_SETTINGS_ERROR = `${PREFIX}UPDATE_SETTINGS_ERROR` as const;

export const GET_SETTINGS_REQUEST = `${PREFIX}GET_SETTINGS_REQUEST` as const;
export const GET_SETTINGS_SUCCESS = `${PREFIX}GET_SETTINGS_SUCCESS` as const;
export const GET_SETTINGS_ERROR = `${PREFIX}GET_SETTINGS_ERROR` as const;

export const DELETE_ACCOUNT = `${PREFIX}DELETE_ACCOUNT` as const;

export const SET_SEEN_FLEET_HOME = `${PREFIX}SET_SEEN_FLEET_HOME` as const;

export const CONFIGURE = `${PREFIX}CONFIGURE` as const;
export const CONFIGURE_SUCCESS = `${PREFIX}CONFIGURE_SUCCESS` as const;
export const CONFIGURE_ERROR = `${PREFIX}CONFIGURE_ERROR` as const;

export const INIT_MFA_FLOW = `${PREFIX}MFA_FLOW` as const;
export const FINISH_MFA_FLOW = `${PREFIX}FINISH_MFA_FLOW` as const;
export const START_MFA_AUTHENTICATION = `${PREFIX}START_MFA_AUTHENTICATION` as const;
export const HANDLE_MFA_ERROR = `${PREFIX}HANDLE_MFA_ERROR` as const;
export const CLEAR_MFA_ERROR = `${PREFIX}CLEAR_MFA_ERROR` as const;
export const CLEAR_MFA = `${PREFIX}CLEAR_MFA` as const;
