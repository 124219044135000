import { Button } from '@hologram-dimension/button';
import { Link } from '@hologram-dimension/link';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeader } from '../components';
import * as accountActions from '../reducers/account/actions';
import * as modalActions from '../reducers/modal/actions';

class AcceptTermsModal extends React.Component {
  onAccept = () => {
    const { updateUserSettings, closeModal } = this.props;
    const userSettings = {
      'Accepted TOS EULA': 'yes',
    };

    updateUserSettings(userSettings).then(() => {
      closeModal();
    });
  };

  render() {
    const { name } = this.props;

    return (
      <div>
        <ModalHeader title="We've updated our Terms of Service and Privacy Policy" />
        <p>Hi{name ? ` ${name}` : ''},</p>
        <p>
          We have refined and reorganized our policies to support the implementation of the GDPR
          (General Data Protection Regulation) which regulates the control and/or processing of
          personal data or data subjects in the European Union.
        </p>
        <p>
          Please agree to the fully updated{' '}
          <Link href="https://hologram.io/terms-of-use" target="_blank" rel="noreferrer">
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link href="https://hologram.io/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </Link>{' '}
          here to proceed to your Hologram dashboard. If you have any questions about these changes,
          please feel free to contact us through{' '}
          <Link href="mailto:privacy@hologram.io">privacy@hologram.io</Link>.
        </p>
        <Button onClick={this.onAccept}>Agree & Continue</Button>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    name: state.account.firstName,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        closeModal: modalActions.close,
        updateUserSettings: accountActions.updateUserSettings,
      },
      dispatch
    )
)(AcceptTermsModal);
