import { login as loginApiCall, logout as logoutApiCall } from 'common-js/api/account/index';
import * as releaseFlagActions from '../../releaseFlag/actions';
import * as actionTypes from '../actionTypes';
import { initMfaFlow, loginRequest } from './actions';
import fetchAccount from './fetchAccount';

const confirmLoggedOut = (redirectOnForbidden) =>
  new Promise<void>((resolve) => {
    logoutApiCall(redirectOnForbidden)
      .then(() => resolve())
      .catch(() => resolve());
  });

export const login =
  (email, password, rememberme, redirectOnForbidden = false) =>
  async (dispatch, state) => {
    dispatch(loginRequest());
    confirmLoggedOut(redirectOnForbidden);
    try {
      const response = await loginApiCall(email, password, rememberme);

      if (response?.mfa) {
        return dispatch(initMfaFlow({ email, rememberme, ...response }));
      }

      return dispatch(fetchAccount());
    } catch (error) {
      if (error instanceof Error || typeof error === 'string') {
        dispatch({
          type: actionTypes.LOGIN_ERROR,
          error: error.toString(),
        });

        releaseFlagActions.getAllReleaseFlags()(dispatch, state);
        return Promise.reject(error.toString());
      }
      throw error;
    }
  };

export default login;
