import Limits from './Limits';
import LimitsSidebarItem from './LimitsSidebarItem';
import LimitsFooter from './LimitsFooter';

const limitsFixture = ({ hasDataUsageLimits }) => ({
  stepName: 'Limits',
  component: Limits,
  sidebarItem: LimitsSidebarItem,
  footer: LimitsFooter,
  title: 'Set data usage limits for devices',
  formState: 'usageLimit',
  optional: true,
  include: hasDataUsageLimits,
  defaultValues: {
    type: '',
    amount: '',
    unit: 'MB',
  },
});

export default limitsFixture;
