import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion } from '@holokit/core';
import { throttle } from 'lodash';
import {
  clearSelection,
  removeFilter,
  setFilter,
  setFilterInputValue,
} from 'common-js/reducers/devices/actions';
import { getFilters, getLoadedDevices } from 'common-js/reducers/devices/selectors';
import { LIKE } from 'common-js/constants/filterComparators';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

class ImeiFilter extends Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.handleSearchDebounced = throttle(this.handleSearch, 1000, {
      leading: false,
    });
  }

  componentWillUnmount() {
    this.handleSearchDebounced.cancel();
  }

  handleInputChange = (e) => {
    const { clearSelection_, setFilterInputValue_ } = this.props;
    const { target } = e;

    clearSelection_();

    setFilterInputValue_('imei', target.value).then(() => {
      this.handleSearchDebounced();
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleSearch();
  };

  handleSearch = () => {
    const { filters, inputValue, removeFilter_, setFilter_ } = this.props;
    const imeiFilter = 'imei' in filters ? Object.keys(filters.imei)[0] : '';
    let filterValue;

    if (imeiFilter !== inputValue) {
      if (inputValue === '') {
        removeFilter_('imei');
        filterValue = '';
      } else {
        setFilter_('imei', inputValue, LIKE);
        filterValue = inputValue;
      }
    }
    sendAnalyticsEvent({
      type: analyticsTypes.FILTERS_SET_FILTER,
      data: {
        filter_name: 'IMEI Filter',
        filter_value: filterValue,
      },
    });
  };

  render() {
    const { devices, filters, inputValue } = this.props;
    let placeholder = '';
    if (devices.length > 0) {
      const d = devices.find((device) => typeof device.imei === 'number' && device.imei !== 0);
      placeholder = d?.imei ?? '';
    }
    return (
      <div className="DevicesFilter">
        <Accordion
          header="IMEI"
          iconLeft="Chip"
          isFiltered={'imei' in filters}
          accordionToggleCallback={({ open }) => {
            sendAnalyticsEvent({
              type: analyticsTypes.FILTERS_TOGGLE_ACCORDION,
              data: {
                accordion_name: 'IMEI Filter',
                accordion_open: open,
              },
            });
          }}
        >
          <form className="DevicesFilter__form" onSubmit={this.handleSubmit}>
            <input
              className="DevicesFilter__input TextInput TextInput--full-width"
              onChange={this.handleInputChange}
              placeholder={placeholder}
              ref={this.input}
              type="text"
              value={inputValue}
            />
          </form>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: getLoadedDevices(state),
  filters: getFilters(state),
  inputValue: state.devices.uiState.filterInputs.imei,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      removeFilter_: removeFilter,
      setFilter_: setFilter,
      setFilterInputValue_: setFilterInputValue,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImeiFilter);
