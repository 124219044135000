import { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { API_URL, BIZ_API_URL } from './config';
import { fetchHandler, fetchErrorHandler } from './middleware';

const formatUrl = (url: string, isBizLayerUrl = false) => {
  const ROOT_URL = isBizLayerUrl ? BIZ_API_URL : API_URL;
  return [ROOT_URL, url].join(url.startsWith('/') ? '' : '/');
};

const replaceUrlParams = (url: string, urlParams: Record<string, string>) =>
  Object.keys(urlParams)
    // this prevents edge cases where a substring of a key is present (:abcd and :abc)
    // if we replace the shorter key first, it leaves the rest of the long key in our URL (not good)...
    // ...so, we replace the keys starting at the longest and moving to the shortest
    // it's the difference between outputs /devices/123d/links/123 and /devices/456/links/123
    // for replaceUrlParams('/devices/:abcd/links/:abc', { abc: 123, abcd: 456 })
    .sort((a, b) => b.length - a.length)
    .reduce((acc, cur) => acc.replaceAll(`:${cur}`, urlParams[cur]), url);

interface ApiOptions {
  redirectOnForbidden?: boolean;
  includingErrorHandlingInSuccess?: boolean;
  headers?: any;
  body?: any;
  useBusinessLayerApi?: boolean;
  method?: string;
}

// Returns a function which will call the API
const useApiCallback = <TResult = any>(
  url = '',
  options: ApiOptions = { useBusinessLayerApi: false }
) => {
  const memoizedOptions = useRef(options);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // This memoizes the options for the user (as with all memoization, at the cost of performance)
    // because if the user of this hook fails to memoize options, it can create a render loop
    if (!isEqual(memoizedOptions.current, options)) {
      memoizedOptions.current = options;

      // Updating state forces a re-render
      setCounter((c) => c + 1);
    }
  }, [options]);

  const doRequest = useCallback(
    ({
      body: dynamicBody,
      options: dynamicOptions = {},
      urlParams = {},
    }: any = {}): Promise<TResult> => {
      const {
        redirectOnForbidden,
        includingErrorHandlingInSuccess,
        headers,
        body,
        useBusinessLayerApi,
        ...rest
      } = memoizedOptions.current;

      const fetchOptions: any = {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        ...rest,
        ...dynamicOptions, // If any options are specified when callback is invoked, those are top priority
      };

      if (body || dynamicBody) {
        fetchOptions.body = JSON.stringify(dynamicBody || body);
      }

      return fetch(formatUrl(replaceUrlParams(url, urlParams), useBusinessLayerApi), fetchOptions)
        .then(
          fetchHandler(
            undefined,
            includingErrorHandlingInSuccess ?? true,
            redirectOnForbidden ?? true
          )
        )
        .catch(fetchErrorHandler);
    },
    // A bit hacky, but we toss counter into the deps array
    // Now, when counter is changed (when we update memoizedOptions), it forces this callback to update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url, memoizedOptions, counter]
  );

  return doRequest;
};

// Call an API request when the hook is rendered
const useApiImmediately = <TResult = any>(url: string, options: ApiOptions = {}) => {
  const [state, setState] = useState<{
    loading: boolean;
    initialLoadComplete: boolean;
    data: TResult | null;
    error: any | null;
  }>({
    loading: true,
    initialLoadComplete: false,
    data: null,
    error: null,
  });

  const callback = useApiCallback(url, options);

  useEffect(() => {
    callback()
      .then((data) =>
        setState({
          loading: false,
          initialLoadComplete: true,
          data,
          error: null,
        })
      )
      .catch((error) =>
        setState({
          loading: false,
          initialLoadComplete: true,
          data: null,
          error,
        })
      );
  }, [callback]);

  return state;
};

const useApi = {
  now: useApiImmediately,
  call: useApiCallback,
};

export default useApi;
