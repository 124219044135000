import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Link } from 'react-router';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import * as DeviceStates from 'common-js/constants/deviceStates';
import * as Links from 'common-js/constants/links';
import * as Paths from 'common-js/constants/paths';

const PlanChangeReason = () => {
  const device = useAppSelector(selectCurrentDevice);
  const pausedBySystem =
    device.state === DeviceStates.PAUSED_PENDING_SYS || device.state === DeviceStates.PAUSED_SYS;

  if (pausedBySystem && device.statusReason === DeviceStates.REASON_BILLING) {
    return (
      <span>
        Please resolve any <Link to={Paths.ACCOUNT_BILLING}>billing</Link> issues before changing
        this plan.
      </span>
    );
  }

  if (pausedBySystem && device.statusReason === DeviceStates.REASON_USAGE_LIMIT) {
    return <span>Please increase or clear usage data before changing this plan.</span>;
  }

  if (
    device.state === DeviceStates.PAUSED_PENDING_USER ||
    device.state === DeviceStates.PAUSED_USER
  ) {
    return <span>Please unpause your device before changing this plan.</span>;
  }

  if (device.state === DeviceStates.LIVE_PENDING) {
    return <span>Please wait for device status to be LIVE before changing this plan.</span>;
  }

  // Fallback case for DEAD_PENDING, DEAD, INACTIVE statuses
  return (
    <span>
      Please{' '}
      <a href={Links.SUPPORT} target="_blank" rel="noreferrer">
        contact support
      </a>{' '}
      to change this plan.
    </span>
  );
};

export default PlanChangeReason;
