import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectOrgId } from 'common-js/api/util';
import { RouterCredModel } from 'common-js/models';

// we could make this pull the device ID from the currentDevice state
// this hook is more reusable if we accept the deviceId as a callback parameter
const useGenerateRouterCreds = () => {
  const apiCall = useApi.call('/csr/sources:queryParams', { method: 'POST' });
  const orgId = useAppSelector(selectOrgId);

  return useCallback(
    async (deviceId) => {
      const queryParams = orgId ? `?orgid=${orgId}` : '';
      const { data } = await apiCall({
        urlParams: { queryParams },
        body: { deviceid: deviceId },
      });
      return new RouterCredModel(data);
    },
    [apiCall, orgId]
  );
};

export default useGenerateRouterCreds;
