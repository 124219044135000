import { useCallback } from 'react';
import { selectOrgId } from 'common-js/api/util';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from '../useApi';
import highlightWordMatch from './highlightWordMatch';

const coalesceKeys = (key) =>
  key.replace(/active_|bootstrap_|operational_profiles_/, '').replace('iccids', 'iccid');

const transformHighlights = ({ query, id, name, fields }) => ({
  id: highlightWordMatch(String(id), query),
  name: highlightWordMatch(name, query),
  ...fields.reduce(
    (response, field) => ({
      ...response,
      [coalesceKeys(field.key)]: highlightWordMatch(field.match, query),
    }),
    {}
  ),
});

const transformFields = ({ fields, totalProfiles }) =>
  fields
    .map((field) => {
      const fieldKey = coalesceKeys(field.key);

      return {
        ...field,
        key: fieldKey,
        showEnabled: totalProfiles > 1 && !!fields.find(({ key }) => key === `active_${fieldKey}`), // >1 profile and the query matches the active key
        showDisabled: totalProfiles > 1 && !fields.find(({ key }) => key === `active_${fieldKey}`), // >1 profile and the query does not match the active key
      };
    })
    // De-duplicate matched fields with the same key
    .filter(
      (field, index, mappedFields) => index === mappedFields.findIndex((f) => f.key === field.key)
    );

const transformSearchResults = (searchResults, query) =>
  searchResults.map(
    ({
      name,
      _highlightResult: matchedFields,
      link_state_connected: status,
      id,
      is_hyper: isHyper,
      active_iccid: activeIccid,
      bootstrap_iccid: bootstrapIccid,
      operational_profiles_iccids: operationalProfilesIccids,
      simcardid,
    }) => {
      // combine all unique active, bootstrap, and operational profile iccids
      const totalProfiles = [
        ...new Set([activeIccid, bootstrapIccid, ...(operationalProfilesIccids ?? [])]),
      ].length;
      return {
        device: {
          id,
          simcardid,
          name,
          status,
          connected: status === 'CONNECTED',
          isHyper,
          highlights: transformHighlights({
            query,
            id,
            name,
            fields: matchedFields,
          }),
          matchedFields: transformFields({ fields: matchedFields, totalProfiles }),
        },
      };
    }
  );

const useFetchSearchResults = () => {
  const orgid = useAppSelector(selectOrgId);

  const callSearchApi = useApi.call('/devices/search', { method: 'POST' });

  const fetchSearchResults = useCallback(
    async (query) => {
      // No need to do an API call if there is no query
      if (!query || query === '') return [];

      const results = await callSearchApi({
        body: {
          query,
          hitsPerPage: 8,
          orgid,
        },
      });

      return transformSearchResults(results?.hits || [], query);
    },
    [callSearchApi, orgid]
  );

  return fetchSearchResults;
};

export default useFetchSearchResults;
