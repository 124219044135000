import React from 'react';
import { Callout } from '@holokit/core';

function SimInfo({ message, messageList }) {
  return (
    <div className="SimPanel__info">
      <Callout
        type={Callout.TYPE.INFORMATIONAL}
        text={message || <>{messageList}</>}
        defaultIcon
        iconColor={Callout.THEME.COLOR}
      />
    </div>
  );
}

export default SimInfo;
