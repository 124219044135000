import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { ComplexIcon } from '@holokit/core';
import _classnames from 'clsx';
import { Component } from 'react';

class DeviceListActivationNotification extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      buttonLoading: false,
    };
  }

  onButtonClick = () => {
    const { checkIfPendingSimsActive } = this.props;

    this.setState({ buttonLoading: true }, () => {
      checkIfPendingSimsActive();
      setTimeout(() => {
        this.setState({ buttonLoading: false });
      }, 2 * 1000);
    });
  };

  render() {
    const { notificationBannerParams } = this.props;
    const { buttonLoading } = this.state;

    let content = (
      <>
        <ComplexIcon name="spinner" />
        <span className="DeviceListNotification__content">
          An activation signal is being sent through our global network of providers
        </span>
      </>
    );

    if (notificationBannerParams.has30SecondsElapsedSinceActivate) {
      content = (
        <>
          <Icon name="Lightning" size="large" fill="DdsColorFillNotificationCaution" />
          <span className="DeviceListNotification__content">
            Your devices are propagating through our network
          </span>
          <Button
            className="DeviceListNotification__button"
            disabled={buttonLoading}
            loading={buttonLoading}
            onClick={this.onButtonClick}
            variant="secondary"
          >
            Detect New Devices
          </Button>
        </>
      );
    }

    if (notificationBannerParams.simsHaveActivated) {
      content = (
        <>
          <Icon name="CircleCheckmark" size="large" fill="DdsColorFillNotificationSuccess" />
          <span className="DeviceListNotification__content">Your devices have been activated!</span>
        </>
      );
    }

    return (
      <div
        className={_classnames('DeviceListNotification', {
          'DeviceListNotification--success': notificationBannerParams.simsHaveActivated,
          'DeviceListNotification--warning': !notificationBannerParams.simsHaveActivated,
        })}
      >
        {content}
      </div>
    );
  }
}

export default DeviceListActivationNotification;
