import React from 'react';
import classNames from 'clsx';

import { Icon } from '@holokit/core';

function TaskPopoverTrigger({ pendingTasksCount, onClick }) {
  return (
    <button
      className={classNames('TaskPopoverTrigger', {
        'TaskPopoverTrigger--has-pending': pendingTasksCount > 0,
      })}
      onClick={onClick}
      type="submit"
    >
      {pendingTasksCount > 0 && (
        <span className="TaskPopoverTrigger__count">{pendingTasksCount}</span>
      )}
      <Icon
        name="Sync"
        size="minor"
        svgProps={{
          style: {
            fill: pendingTasksCount > 0 ? '#fff' : '#c3cad6', // #c3cad6 is $color-gray-100
            transform: 'scaleX(-1) rotate(330deg)',
          },
        }}
        classes="TaskPopoverTrigger__icon"
      />
    </button>
  );
}

export default TaskPopoverTrigger;
