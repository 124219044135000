const PREFIX = 'APPS_';

export const GET_APPS_REQUEST = `${PREFIX}GET_APPS_REQUEST`;
export const GET_APPS_SUCCESS = `${PREFIX}GET_APPS_SUCCESS`;
export const GET_APPS_ERROR = `${PREFIX}GET_APPS_ERROR`;

export const GET_APP_SCHEMAS_REQUEST = `${PREFIX}GET_APP_SCHEMAS_REQUEST`;
export const GET_APP_SCHEMAS_SUCCESS = `${PREFIX}GET_APP_SCHEMAS_SUCCESS`;
export const GET_APP_SCHEMAS_ERROR = `${PREFIX}GET_APP_SCHEMAS_ERROR`;

export const ADD_APP_REQUEST = `${PREFIX}ADD_APP_REQUEST`;
export const ADD_APP_SUCCESS = `${PREFIX}ADD_APP_SUCCESS`;
export const ADD_APP_ERROR = `${PREFIX}ADD_APP_ERROR`;

export const EDIT_APP_REQUEST = `${PREFIX}EDIT_APP_REQUEST`;
export const EDIT_APP_SUCCESS = `${PREFIX}EDIT_APP_SUCCESS`;
export const EDIT_APP_ERROR = `${PREFIX}EDIT_APP_ERROR`;

export const DELETE_APP_REQUEST = `${PREFIX}DELETE_APP_REQUEST`;
export const DELETE_APP_SUCCESS = `${PREFIX}DELETE_APP_SUCCESS`;
export const DELETE_APP_ERROR = `${PREFIX}DELETE_APP_ERROR`;

export const DELETE_APPS_REQUEST = `${PREFIX}DELETE_APPS_REQUEST`;
export const DELETE_APPS_SUCCESS = `${PREFIX}DELETE_APPS_SUCCESS`;
export const DELETE_APPS_ERROR = `${PREFIX}DELETE_APPS_ERROR`;

export const ENABLE_APP_REQUEST = `${PREFIX}ENABLE_APP_REQUEST`;
export const ENABLE_APP_SUCCESS = `${PREFIX}ENABLE_APP_SUCCESS`;
export const ENABLE_APP_ERROR = `${PREFIX}ENABLE_APP_ERROR`;

export const DISABLE_APP_REQUEST = `${PREFIX}DISABLE_APP_REQUEST`;
export const DISABLE_APP_SUCCESS = `${PREFIX}DISABLE_APP_SUCCESS`;
export const DISABLE_APP_ERROR = `${PREFIX}DISABLE_APP_ERROR`;

export const GET_ALL_APP_DATA_REQUEST = `${PREFIX}GET_ALL_APP_DATA_REQUEST`;
export const GET_ALL_APP_DATA_SUCCESS = `${PREFIX}GET_ALL_APP_DATA_SUCCESS`;
export const GET_ALL_APP_DATA_ERROR = `${PREFIX}GET_ALL_APP_DATA_ERROR`;

export const UPDATE_PREVIEWED_TOPICS_REQUEST = `${PREFIX}UPDATE_PREVIEWED_TOPICS_REQUEST`;
export const UPDATE_PREVIEWED_TOPICS_SUCCESS = `${PREFIX}UPDATE_PREVIEWED_TOPICS_SUCCESS`;
export const UPDATE_PREVIEWED_TOPICS_ERROR = `${PREFIX}UPDATE_PREVIEWED_TOPICS_ERROR`;

export const ADD_OPERATION = `${PREFIX}ADD_OPERATION`;
export const UPDATE_OPERATION = `${PREFIX}UPDATE_OPERATION`;
export const REMOVE_OPERATION = `${PREFIX}REMOVE_OPERATION`;
export const ADD_SELECTED_TOPIC = `${PREFIX}ADD_SELECTED_TOPIC`;
export const REMOVE_SELECTED_TOPIC = `${PREFIX}REMOVE_SELECTED_TOPIC`;
export const ADD_ACTION = `${PREFIX}ADD_ACTION`;
export const UPDATE_ACTION = `${PREFIX}UPDATE_ACTION`;
export const REMOVE_ACTION = `${PREFIX}REMOVE_ACTION`;
export const CLEAR_ALL_SELECTIONS = `${PREFIX}CLEAR_ALL_SELECTIONS`;
export const UPDATE_CHAIN_VALUES = `${PREFIX}UPDATE_CHAIN_VALUES`;
export const LOAD_APPCHAIN = `${PREFIX}LOAD_APPCHAIN`;
export const SET_VIEWMODE = `${PREFIX}SET_VIEWMODE`;
export const SET_SORTBY = `${PREFIX}SET_SORTBY`;
export const SET_SEARCHBY = `${PREFIX}SET_SEARCHBY`;

export const SET_MATCH_ALL_TOPICS = `${PREFIX}SET_MATCH_ALL_TOPICS`;
