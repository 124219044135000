import { allCountries } from 'country-region-data';
import { useMemo } from 'react';

export interface Place {
  code: string;
  name: string;
}

const useCountries = () =>
  useMemo<Array<Place>>(
    () =>
      allCountries
        .map(([name, slug]) => ({
          code: slug,
          name,
        }))
        .sort((a, b) => (a.code === 'US' ? -1 : a.name.localeCompare(b.name))),
    []
  );

export default useCountries;
