import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import BulkSims from './BulkSims';
import IndividualSimsPanel from './IndividualSimsPanel';

function Sims({ isOnboarding, hasBillingPermissions }) {
  const isPreflight = useAppSelector((state) => state.activation.flags.isPreflight);

  return (
    <div>
      {(isOnboarding || isPreflight) && (
        <IndividualSimsPanel isOnboarding hasBillingPermissions={hasBillingPermissions} />
      )}
      {!isOnboarding && !isPreflight && <BulkSims hasBillingPermissions={hasBillingPermissions} />}
    </div>
  );
}

export default Sims;
