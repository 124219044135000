import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { FormField } from '@hologram-dimension/form-field';
import { Select } from '@hologram-dimension/select';
import { SelectField } from '@hologram-dimension/select-field';
import { TextInput } from '@hologram-dimension/text-input';
import { SETTING_DATA_LIMITS_HELP } from 'common-js/constants/links';
import { bulkUpdateOverage as bulkUpdateOverage_ } from 'common-js/reducers/devices/actions';
import { required } from 'common-js/utils/validation/validators';
import { noop } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BannerNotificationType } from 'types/dimension';
import FormHOC from './FormHOC';
import { formGenerator } from './formGenerator';

const unitToBytes = {
  KB: 1_000,
  MB: 1_000_000,
  GB: 1_000_000_000,
};

const customUnitOptions = Object.keys(unitToBytes).map((option) => ({
  label: option,
  value: unitToBytes[option],
}));

interface OutcomeCalloutProps {
  variant: BannerNotificationType;
  text: string;
  devices: Array<{ deviceid: DeviceId; name: string }>;
}
const OutcomeCallout = ({ variant, text, devices }: OutcomeCalloutProps) => (
  <BannerNotification variant={variant}>
    <>
      <div>{text}</div>
      <ul className="BulkUpdateOverageForm__device-list">
        {(devices ?? []).map((device) => (
          <li key={`${device.deviceid}-${device.name}`}>{device.name}</li>
        ))}
      </ul>
    </>
  </BannerNotification>
);

interface BulkUpdateOverageFormProps {
  form: any;
  resetState: AnyFunction;
  devices: Array<any>;
  customLimit: any;
  customLimitUnit: any;
  limitType: any;
  onFormSubmit: AnyFunction;
  onCancel: AnyFunction;
  formProcessing?: boolean;
  serverError?: any;
  isDirty: boolean;
  isSuccessful: boolean;
  isProcessing: boolean;
  successObject: {
    successes: Array<{
      deviceId: DeviceId;
      item: DeviceId | string;
    }>;
  };
}

interface BulkUpdateOverageFormState {}

class BulkUpdateOverageForm extends React.Component<
  BulkUpdateOverageFormProps,
  BulkUpdateOverageFormState
> {
  componentDidMount() {
    const { resetState } = this.props;
    resetState();
  }

  getDeviceById(deviceId: DeviceId) {
    const { devices } = this.props;

    const device = (devices || []).find((d) => d.id === deviceId);

    return (
      device || {
        name: '',
        sim_number: '',
      }
    );
  }

  render() {
    const {
      form,
      customLimit,
      customLimitUnit,
      limitType,
      onFormSubmit,
      formProcessing = false,
      serverError = null,
      isDirty,
      isSuccessful,
      isProcessing,
      successObject,
      onCancel = noop,
      devices,
    } = this.props;

    let overageOptionAvailable = true;

    devices.forEach((device) => {
      if (device.plan_data === 0) overageOptionAvailable = false;
    });

    // Note: successObject.successes returns a .linkId field but devices has a .link_id field
    const successfulDevices = (successObject.successes ?? []).map((device) => device.deviceId);
    // Note: the API doesn't return device names in the `failures` list so we need to filter instead
    const failedDevices = devices.filter((device) => !successfulDevices.includes(device.id));

    const showDone = isSuccessful && successfulDevices.length > 0;

    return (
      <div className="BulkUpdateOverageForm">
        <Form
          className="BulkUpdateOverageForm__usage-limit-form"
          onSubmit={onFormSubmit}
          footerActions={
            <>
              <Button onClick={onCancel} variant="secondary" disabled={formProcessing}>
                {showDone ? 'Done' : 'Cancel'}
              </Button>
              <Button type="submit" loading={formProcessing}>
                Update
              </Button>
            </>
          }
        >
          <SelectField
            className="BulkUpdateOverageForm__dropdown"
            defaultValue="-1"
            inputProps={limitType}
            label="Change usage limit"
            name="usagelimit"
            options={[
              {
                label: 'Unlimited',
                value: '-1',
              },
              ...(overageOptionAvailable
                ? [
                    {
                      label: 'No additional usage',
                      value: '0',
                    },
                  ]
                : []),
              {
                label: 'Custom amount',
                value: 'custom',
              },
            ]}
          />

          {form.limitType.value === 'custom' && (
            <FormField
              className="BulkUpdateOverageForm__custom-container"
              label="Custom amount"
              hiddenLabel
              invalid={!!form.customLimit.error}
              controlsLayoutDirection="row"
              controlsLayoutGap="none"
              validationMessage={form.customLimit.error}
            >
              {({ primaryControlId, ariaDescribedbyIds, invalid }) => (
                <>
                  <TextInput
                    id={primaryControlId}
                    name="customLimit"
                    inputProps={{
                      ...customLimit,
                      'aria-describedby': ariaDescribedbyIds,
                      'aria-invalid': invalid ? 'true' : 'false',
                    }}
                    placeholder="Data amount"
                  />
                  <Select
                    id="customLimitUnit"
                    className="BulkUpdateOverageForm__custom-unit"
                    name="customLimitUnit"
                    inputProps={{
                      ...customLimitUnit,
                      'aria-describedby': ariaDescribedbyIds,
                      'aria-invalid': invalid ? 'true' : 'false',
                    }}
                    defaultValue={unitToBytes.MB.toString()}
                    options={customUnitOptions}
                  />
                </>
              )}
            </FormField>
          )}

          <div className="BulkUpdateOverageForm__help-link-container">
            <a
              className="BulkUpdateOverageForm__help-link"
              href={SETTING_DATA_LIMITS_HELP}
              target="_blank"
              rel="noreferrer"
            >
              Questions about data usage limits?
            </a>
          </div>

          {serverError && (
            <BannerNotification variant="system-error">{serverError}</BannerNotification>
          )}

          {isSuccessful && !isProcessing && successObject && !isDirty && (
            <div className="BulkUpdateOverageForm__callouts">
              {successObject?.successes?.length > 0 && (
                <OutcomeCallout
                  variant="success"
                  text="The data usage limit was changed for:"
                  devices={successObject.successes.map((device) =>
                    this.getDeviceById(device.deviceId)
                  )}
                />
              )}
              {failedDevices.length > 0 && (
                <OutcomeCallout
                  variant="error"
                  text="There was an error updating the data usage limit for:"
                  devices={failedDevices.map((device) => this.getDeviceById(device.deviceId))}
                />
              )}
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export const config = formGenerator({
  form: 'BulkUpdateOverageForm',
  fields: ['limitType', 'customLimit', 'customLimitUnit'],
  validators: {
    customLimit: [
      required('Please enter data usage limit.', (context) => context.limitType.value === 'custom'),
    ],
  },
  initialState: () => ({
    limitType: {
      value: '-1',
    },
    customLimit: {
      value: '',
    },
    customLimitUnit: {
      value: unitToBytes.MB,
    },
  }),
  onSubmit: (form, actionTypes, dispatch, state, HOC) => {
    const customLimitValue = parseInt(form.customLimit.value || 0, 10) * form.customLimitUnit.value;
    const dataUsageLimitValue =
      form.limitType.value === 'custom' ? customLimitValue : form.limitType.value;

    return HOC.props
      .bulkUpdateOverage(
        HOC.props.devices.map((device) => device.id),
        parseInt(dataUsageLimitValue, 10)
      )
      .then((data) => {
        dispatch({
          type: actionTypes.SUCCESS,
          successObject: {
            successes: data.successes,
            failures: data.failures,
          },
        });
      })
      .catch((errorMessage) => {
        dispatch({
          type: actionTypes.ERROR,
          errorMessage,
        });

        return Promise.reject(errorMessage);
      });
  },
});

export default connect(null, (dispatch: any) =>
  bindActionCreators(
    {
      bulkUpdateOverage: bulkUpdateOverage_,
    },
    dispatch
  )
)(FormHOC(BulkUpdateOverageForm, config)) as any;
