import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectHasDataUsageLimits } from 'common-js/reducers/releaseFlag/selectors';
import useBulkModal from '../useBulkModal';

const useSetDataUsageLimitsItem = () => {
  const openModal = useBulkModal('BulkDataUsageLimitsModal');
  const hasDataUsageLimits = useAppSelector(selectHasDataUsageLimits);

  return {
    label: 'Set data usage limits',
    icon: 'Gauge',
    include: hasDataUsageLimits,
    selectionLimit: 10,
    onClick: openModal,
  };
};

export default useSetDataUsageLimitsItem;
