import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Icon } from '@hologram-dimension/icon';
import { Button } from '@hologram-dimension/button';
import submitDashboardMarketoForm from 'common-js/utils/marketing';
import { Input } from '../../components/form';
import SubIndustryDropdown from './SubIndustryDropdown';

function Step7(props) {
  const { handleAnalyticsCall, nextStep } = props;
  const {
    getValues,
    register,
    trigger,
    control,
    formState: { errors },
  } = useFormContext();

  const handleClick = async () => {
    await trigger();
    if (!errors.company && !errors.useCase && !errors.endUserSubIndustry && !errors.phoneNumber) {
      handleAnalyticsCall('company', getValues('company'));
      handleAnalyticsCall('useCase', getValues('useCase'));
      handleAnalyticsCall('endUserSubIndustry', getValues('endUserSubIndustry'));
      handleAnalyticsCall('phoneNumber', getValues('phoneNumber'));
      submitDashboardMarketoForm(getValues());
      nextStep();
    }
  };
  const errorMessage =
    errors.company?.message ||
    errors.useCase?.message ||
    errors.endUserSubIndustry?.message ||
    errors.phoneNumber?.message;

  return (
    <div className="Step NewForm">
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__Header">
            <h2>We’re ready to help you deploy your cellular devices anywhere in the world</h2>
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__SubHeader">
            By providing your details below our team can create a personalized solution based on
            your company’s needs once you’re ready to scale up
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__InfoBanner">
            <span>Please note: Hologram will not to sell any private data to any third party</span>
            <a href="https://www.hologram.io/privacy-policy" target="_blank" rel="noreferrer">
              Learn more{' '}
            </a>
          </div>
        </div>
      </div>
      <div className="NewForm__RowLeftAligned">
        <div className="NewForm__Column">
          <div className="NewForm__EntryField">
            <label htmlFor="Register-CompanyName">Enter your company name</label>
            <Input
              name="Register-CompanyName"
              type="text"
              error={!!errors.company}
              {...register('company', {
                required: 'Please enter your company name',
              })}
            />
          </div>
        </div>
        <div className="NewForm__Column">
          <div className="NewForm__EntryField">
            <label htmlFor="Register-PhoneNumber">Enter your phone number</label>
            <Input
              name="phoneNumber"
              type="text"
              error={!!errors.phoneNumber}
              {...register('phoneNumber', {
                required: 'Please include a valid phone number',
                minLength: {
                  value: 10,
                  message: 'Please include a valid phone number',
                },
              })}
            />
          </div>
        </div>
      </div>
      <div className="NewForm__RowLeftAligned">
        <div className="NewForm__Column">
          <div className="NewForm__EntryField">
            <label htmlFor="Register-Industry">Select your company’s industry</label>
            <select
              name="Register-Industry"
              className={errors.useCase ? 'error' : ''}
              {...register('useCase', {
                required: 'Please select an industry or focus',
              })}
            >
              <option disabled value="">
                Choose an option
              </option>
              <option>Automation</option>
              <option>Fleet &amp; Asset Tracking/Management</option>
              <option>Industrial Monitoring</option>
              <option>Point of Transaction</option>
              <option>Sensors</option>
              <option>Wearables</option>
              <option>Other</option>
            </select>
          </div>
        </div>
        <div className="NewForm__Column">
          <div className="NewForm__EntryField">
            <label htmlFor="Register-SubIndustry">Select your industry specialization</label>
            <SubIndustryDropdown
              name="Register-SubIndustry"
              className={errors.endUserSubIndustry ? 'error' : ''}
              disabled={!getValues('useCase')}
              {...register('endUserSubIndustry', {
                required: 'Please select a sub-industry',
              })}
            />
          </div>
        </div>
      </div>
      <div className="NewForm__RowLeftAligned">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__EntryFieldRow NewForm__TextContent">
            <Controller
              name="phoneCallOptIn"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <Input
                    type="checkbox"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    innerRef={ref}
                    name={name}
                    id={name}
                    minScore
                  />
                  <label htmlFor={name}>
                    <span className="NewForm__Terms">
                      Yes, I’d like to receive calls from Hologram’s solutions team via the phone
                      number I’ve provided. I understand that I can opt-out any time and that my
                      information will be processed according to Hologram’s{' '}
                      <span className="NewForm__Terms">
                        <a
                          href="https://hologram.io/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </span>
                      <span className="NewForm__Terms__Italic"> (optional)</span>
                    </span>
                  </label>
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="newForm__RowLeftAligned">
        <div className="NewForm__DoubleColumn">
          <div
            className={`NewForm__ErrorBanner${
              errorMessage ? ' NewForm__ErrorBanner--Invalid' : ''
            }`}
          >
            <Icon name="CircleAlert" size="medium" fill="DdsColorPaletteRed43" />
            <span>{errorMessage}</span>
          </div>
        </div>
      </div>
      <div className="NewForm__RowLeftAligned">
        <div className="NewForm_Column">
          <Button className="complete-setup" onClick={handleClick} type="button">
            Complete account setup
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Step7;
