import { BannerNotification } from '@hologram-dimension/banner-notification';
import { UpdateBillingAddress } from '@hologram-hyper-dashboard/components';
import PlanSelectionTable from 'activation/review/PlanSelectionTable';
import SummaryPanel from 'activation/review/SummaryPanel';
import { getActivationQuote, getChangePlanQuote } from 'activation/sims/SimUtils';
import { batchActivation } from 'common-js/api';
import useDeviceChangePlan from 'common-js/api/devices/useDeviceChangePlan';
import { getUserContextDataMemoized } from 'common-js/api/util';
import DefaultStripeElements from 'common-js/components/DefaultStripeElements';
import PaymentDetailsPanel from 'common-js/components/payment/PaymentDetailsPanel';
import ZONE_TRANSLATIONS from 'common-js/constants/zoneTranslations';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useUpdateBillingAddress from 'common-js/hooks/useUpdateBillingAddress';
import { DeviceModel } from 'common-js/models';
import {
  buildSimConfiguration,
  buildTasks,
  buildUsageLimit,
  filterValidTasks,
  getErrorMessage,
  reduceOrderData,
} from 'common-js/reducers/activation/actions/actionUtils';
import { updateTotalDueToday } from 'common-js/reducers/activation/actions/actions';
import { selectSimErrors } from 'common-js/reducers/activation/selectors';
import {
  getBalance,
  getPromoBalance,
  selectBillingAddress,
  selectBillingInformation,
} from 'common-js/reducers/organization/selectors';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useStore } from 'react-redux';

interface ReviewProps {
  hasPostpay: boolean;
  hasBillingPermissions: boolean;
  hasDataUsageLimits: boolean;
  isOnboarding: boolean;
  changingPlan: boolean;
}

const Review: FC<ReviewProps> = ({
  hasPostpay,
  hasBillingPermissions,
  hasDataUsageLimits,
  isOnboarding,
  changingPlan,
}) => {
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({
    total: 0.0,
    subtotal: 0.0,
    autoRefillCharge: 0.0,
    promoBalance: 0.0,
    upchargeToCreditCardMin: 0.0,
    availableBalanceChange: 0.0,
  });
  const {
    total,
    subtotal,
    autoRefillCharge,
    promoBalance,
    upchargeToCreditCardMin,
    availableBalanceChange,
  } = fields;
  const [lineItems, setLineItems] = useState<Array<any>>([]);
  const [quoteError, setQuoteError] = useState('');
  const { setValue, getValues } = useFormContext();
  const formState = getValues();

  const { plan, sims, autoRefillParams, devicePrefix, tags, usageLimit, region } = formState;
  const { selected: selectedRegion } = region ?? {};
  const paymentType = useWatch({ name: 'creditCardInfo.paymentType' });
  const useBalance = paymentType === 'balance';

  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;
  const store = useStore();
  const currentPromoBalance = useAppSelector(getPromoBalance);
  const balance = useAppSelector(getBalance)?.availableBalance ?? 0;
  const { alreadyActivated: alreadyActivatedSims } = useAppSelector(selectSimErrors);
  const excludeSims = useMemo(
    () => (alreadyActivatedSims ?? []).map((sim) => sim.key),
    [alreadyActivatedSims]
  );

  const { displayName, planName, dataLimit, zone, perLineCharge, overageCharge, outboundSms } =
    plan;

  const dispatch = useAppDispatch();

  let totalToUse;
  if (availableBalanceChange > 0) {
    totalToUse = availableBalanceChange;
  } else {
    totalToUse = total - (upchargeToCreditCardMin ?? 0);
  }

  const getQuote = useCallback(async () => {
    if (paymentType === 'card' && !hasBillingPermissions && totalToUse < 5) {
      setFields({
        total: 0,
        subtotal: 0,
        autoRefillCharge: 0,
        promoBalance: 0,
        availableBalanceChange: 0,
        upchargeToCreditCardMin: 0,
      });
      setLineItems(filterValidTasks([]));
      return;
    }

    const config = await buildSimConfiguration({
      autoRefillParams,
      devicePrefix,
      tags,
      plan,
      sims,
      usageLimit,
      userContext,
      excludeSims,
      excludeBalanceFill: false,
      hasDataUsageLimits,
    });
    const tasks = buildTasks(store.getState(), config);
    const result = await batchActivation(tasks, true, useBalance, userContext);

    const { valid_tasks: validTasks, subtotal: resultSubtotal } = result;
    const formattedResult = getActivationQuote(
      validTasks,
      resultSubtotal,
      currentPromoBalance,
      useBalance,
      balance
    );
    const {
      autoRefillCharge: resAutoRefillCharge,
      subtotal: resSubtotal,
      total: resTotal,
      availableBalanceChange: resAvailableBalanceChange,
      upchargeToCreditCardMin: resUpchargeToCreditCardMin,
    } = formattedResult;
    dispatch(updateTotalDueToday(resTotal));

    const newPromoBalance =
      currentPromoBalance > resultSubtotal ? resultSubtotal : currentPromoBalance;

    setFields({
      total: resTotal,
      subtotal: resSubtotal,
      autoRefillCharge: resAutoRefillCharge,
      promoBalance: newPromoBalance,
      availableBalanceChange: resAvailableBalanceChange,
      upchargeToCreditCardMin: resUpchargeToCreditCardMin,
    });
    setLineItems(filterValidTasks(validTasks));
  }, [
    autoRefillParams,
    balance,
    currentPromoBalance,
    devicePrefix,
    dispatch,
    excludeSims,
    hasBillingPermissions,
    hasDataUsageLimits,
    paymentType,
    plan,
    sims,
    store,
    tags,
    totalToUse,
    usageLimit,
    useBalance,
    userContext,
  ]);

  const devices: Array<DeviceModel> | undefined = useAppSelector(
    (state) => state.activation?.changePlan?.devices
  );

  const deviceIds = useMemo(() => devices?.map((device) => device.id), [devices]);

  const changePlanPreviewCallback = useDeviceChangePlan({
    deviceIds,
    preview: true,
  });

  const getChangePlanQuoteLocal = useCallback(async () => {
    const result = await changePlanPreviewCallback({
      newPlanId: plan?.id,
      orgId,
      zone,
      useAccountBalance: useBalance,
      overageLimit: buildUsageLimit(dataLimit, usageLimit),
    });

    const { orderData, warnings, devices_modified: devicesModified } = result ?? {};
    const formattedResult = getChangePlanQuote(
      orderData?.total_cost ?? 0,
      currentPromoBalance,
      useBalance,
      balance
    );

    const {
      autoRefillCharge: resAutoRefillCharge,
      subtotal: resSubtotal,
      total: resTotal,
      availableBalanceChange: resAvailableBalanceChange,
      upchargeToCreditCardMin: resUpchargeToCreditCardMin,
    } = formattedResult;

    dispatch(updateTotalDueToday(resTotal));

    const newPromoBalance = currentPromoBalance > resSubtotal ? resSubtotal : currentPromoBalance;

    setFields({
      total: resTotal,
      subtotal: resSubtotal,
      autoRefillCharge: resAutoRefillCharge,
      promoBalance: newPromoBalance,
      availableBalanceChange: resAvailableBalanceChange,
      upchargeToCreditCardMin: resUpchargeToCreditCardMin,
    });

    if (!devicesModified || devicesModified.length === 0) {
      setQuoteError(
        'The selected plan would result in no changes or all SIMs are ineligible to change plans.'
      );
      // eslint-disable-next-line no-console
      console.error({ warnings });
      return;
    }

    const summaryLines = orderData ? [orderData].reduce(reduceOrderData, []) : [];
    if (summaryLines.length > 0) {
      // To match what the user expected from the devices table.
      summaryLines[0].quantity = result?.devices_modified.length ?? 0;
    }
    setLineItems(summaryLines);
  }, [
    balance,
    changePlanPreviewCallback,
    currentPromoBalance,
    dataLimit,
    dispatch,
    orgId,
    plan?.id,
    usageLimit,
    useBalance,
    zone,
  ]);

  useEffect(() => {
    if (plan?.id && (sims?.total > 0 || changingPlan)) {
      setLoading(true);
      setQuoteError('');
      if (changingPlan) {
        getChangePlanQuoteLocal()
          .catch((reason) => {
            setValue('creditCardInfo.hasError', true);
            setQuoteError(getErrorMessage(reason) ?? 'Unknown');
          })
          .finally(() => setLoading(false));
      } else {
        getQuote()
          .catch((reason) => {
            setValue('creditCardInfo.hasError', true);
            setQuoteError(getErrorMessage(reason) ?? 'Unknown');
          })
          .finally(() => setLoading(false));
      }
    }
  }, [
    changingPlan,
    getChangePlanQuoteLocal,
    getQuote,
    paymentType,
    plan?.id,
    setLoading,
    setQuoteError,
    setValue,
    sims?.total,
  ]);

  useEffect(() => {
    if (!loading && changingPlan && balance < total && !hasPostpay) {
      setQuoteError(
        'Changing data plans requires using your account balance. Add balance on the billing page to continue.'
      );
    }
  }, [balance, changingPlan, hasPostpay, loading, total]);

  const billingAddress = useAppSelector(selectBillingAddress);

  const hasPaymentDetails = !hasPostpay && !changingPlan;
  const hasBillingAddress = !!(billingAddress && billingAddress.line1);

  const billingInformation = useAppSelector(selectBillingInformation);
  const isBillingAddressSet = billingInformation?.isBillingAddressSet ?? false;
  const updateBillingAddress = useUpdateBillingAddress();

  return (
    <DefaultStripeElements>
      <div className="Review__wrapper">
        <PlanSelectionTable
          displayName={displayName ?? planName}
          coverage={selectedRegion?.displayName ?? ZONE_TRANSLATIONS[zone] ?? zone}
          dataLimit={dataLimit}
          perLineCharge={perLineCharge}
          overageCharge={overageCharge}
          outboundSms={outboundSms}
          hasPostpay={hasPostpay}
          hasDataUsageLimits={hasDataUsageLimits}
          usageLimit={usageLimit}
        />
        {hasPaymentDetails && (
          <PaymentDetailsPanel
            total={total}
            availableBalanceChange={availableBalanceChange}
            upchargeToCreditCardMin={upchargeToCreditCardMin}
            balance={balance}
            loading={loading}
            isOnboarding={isOnboarding}
            changingPlan={changingPlan}
            mode="activation"
          />
        )}
        <UpdateBillingAddress
          updateBillingAddress={updateBillingAddress}
          existingAddress={hasBillingAddress ? billingAddress : undefined}
          readOnly={!hasBillingPermissions}
          hasAddress={isBillingAddressSet}
        />
        {quoteError && (
          <BannerNotification className="Review__callout" variant="error">
            {quoteError}
          </BannerNotification>
        )}
        <SummaryPanel
          autoRefillCharge={autoRefillCharge}
          hasPostpay={hasPostpay}
          lineItems={lineItems}
          promoBalance={useBalance ? promoBalance : 0}
          availableBalanceChange={availableBalanceChange}
          upchargeToCreditCardMin={upchargeToCreditCardMin}
          subtotal={subtotal}
          total={total}
          changingPlan={changingPlan}
        />
      </div>
    </DefaultStripeElements>
  );
};

export default Review;
