import { FC } from 'react';

/* eslint-disable jsx-a11y/control-has-associated-label */
const SkeletonTableBody: FC = () => (
  <tbody>
    {new Array(10).fill(0).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <tr className="Table__row" key={`report${index}`}>
        <td className="Table__cell">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
        <td className="Table__cell">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
      </tr>
    ))}
  </tbody>
);

export default SkeletonTableBody;
