import _ from 'lodash';

import * as accountActionTypes from '../account/actionTypes';
import * as orgActionTypes from '../organization/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  poolPlans: [],
  pools: [],
  currentPool: {
    changes: [],
    pendingChanges: [],
    usage: null,
    plan: {},
  },
  isPoolUsageLoading: false,
  isPoolInfoLoading: false,
  quote: {},
};

function poolReducer(state = initialState, action) {
  switch (action.type) {
    case accountActionTypes.LOGOUT_REQUEST:
    case accountActionTypes.LOGIN_REQUEST:
    case accountActionTypes.REGISTER_REQUEST:
    case orgActionTypes.CHANGE_CONTEXT:
      return { ...state, ...initialState };

    case actionTypes.GET_ALL_POOL_INFO_REQUEST:
      return { ...state, isPoolInfoLoading: true };
    case actionTypes.GET_POOL_USAGE_REQUEST:
      return { ...state, isPoolUsageLoading: true };

    case actionTypes.GET_ALL_POOL_INFO_ERROR:
      return { ...state, isPoolInfoLoading: false };
    case actionTypes.GET_POOL_USAGE_ERROR:
      return { ...state, isPoolUsageLoading: false };

    case actionTypes.QUOTE_SUCCESS:
      return { ...state, quote: action.quote };

    case actionTypes.GET_POOL_PLANS_SUCCESS:
      return { ...state, poolPlans: action.poolPlans };

    case actionTypes.GET_POOLS_SUCCESS:
      if (action.pools.length > 0) {
        return {
          ...state,
          currentPool: {
            ...state.currentPool,
            ...action.pools[0],
          },
        };
      }
      return {
        ...state,
        currentPool: {
          ...initialState.currentPool,
        },
      };

    case actionTypes.SUBSCRIBE_POOL_SUCCESS:
      return {
        ...state,
        currentPool: { ...initialState.currentPool, ...action.pool },
      };

    case actionTypes.GET_POOL_USAGE_SUCCESS:
      if (state.currentPool) {
        return {
          ...state,
          currentPool: {
            ...state.currentPool,
            usage: action.usage,
          },
          isPoolUsageLoading: false,
        };
      }
      return state;

    case actionTypes.GET_ALL_POOL_INFO_SUCCESS:
      if (state.currentPool) {
        return {
          ...state,
          currentPool: {
            ...state.currentPool,
            changes: action.changes,
            pendingChanges: action.pendingChanges,
            plan: _.find(state.poolPlans, { id: state.currentPool.poolplanid }),
          },
          isPoolInfoLoading: false,
        };
      }
      return state;

    default:
      return state;
  }
}

export default poolReducer;
