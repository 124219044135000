import React from 'react';
import { Icon, Loader, Panel } from '@holokit/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { getApps, getAppSchemas, loadAppChain } from '../../common/reducers/apps/actions';
import * as Links from '../../common/constants/links';
import * as Paths from '../../common/constants/paths';
import AppForm from 'apps/appform/AppForm';
import {
  FullHeightCalculatorHOC,
  HoloHelmet,
  PageLoadingErrorScreen,
} from '../../common/components';

class Configure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pageError: null,
    };

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Routes - Configure Route' },
    });
  }

  componentDidMount() {
    const { getApps_, getAppSchemas_, loadAppChain_, actionAppId } = this.props;

    try {
      Promise.all([getApps_(), getAppSchemas_()])
        .then(() => {
          // load up the app chain.
          loadAppChain_(actionAppId);
          this.setState({ isLoading: false });
        })
        .catch((e) => {
          this.setState({ pageError: e, isLoading: false });
        });
    } catch (e) {
      this.setState({ pageError: e, isLoading: false });
    }
  }

  render() {
    const { nickname } = this.props;
    const { isLoading, pageError } = this.state;

    return (
      <FullHeightCalculatorHOC accountForDrawer>
        <HoloHelmet title={nickname ? `Routes - ${nickname}` : 'Routes - Configure'} />
        {pageError ? (
          <PageLoadingErrorScreen error={pageError} />
        ) : (
          <div>
            <Loader isLoading={isLoading} />
            <div className="edit-app">
              <Panel
                isLoading={isLoading}
                title={
                  <div className="h2">
                    Configure New Route
                    <span className="help-link pull-right">
                      <Icon
                        classes="help-link-icon"
                        name="Book--open"
                        size="minor"
                        svgProps={{ style: { fill: '#04a7ff' } }}
                      />
                      <a href={Links.LEARN_ROUTES} rel="noreferrer" target="_blank">
                        Learn about creating routes
                      </a>
                    </span>
                  </div>
                }
                noBodyPadding
              >
                <AppForm isEditingApp />
              </Panel>
            </div>
          </div>
        )}
      </FullHeightCalculatorHOC>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    actionAppId: parseInt(props.routeParams[Paths.APPS_PARAM_NAME]),
    nickname: state.apps.nickname,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getApps_: getApps,
      getAppSchemas_: getAppSchemas,
      loadAppChain_: loadAppChain,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Configure);
