import { Picture } from '@hologram-hyper-dashboard/components';
import { Button, ComplexIcon, Icon, Loader } from '@holokit/core';
import _classnames from 'clsx';
import * as alertTypes from 'common-js/constants/alertTypes';
import { deactivatedStates } from 'common-js/constants/deviceStates';
import { setActiveAlert } from 'common-js/reducers/alerts/actions';
import scrollTo from 'common-js/utils/dom/scrollTo';
import usageAlertImg from 'img/alerts/usagealerticon.webp?&imagetools';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlertsTableContainer from '../containers/TableContainer';
import AlertsActionItemsAd from './AlertsActionItemsAd';
import ClearAcknowledgedBanner from './ClearAcknowledgedBanner';
import DeviceUsageAlertsTableRow from './DeviceUsageAlertsTableRow';
import LoadMoreFooter from './LoadMoreFooter';
import SelectMultipleToolbar from './SelectMultipleToolbar';
import withPlanHooksHoc from './withPlanHooksHoc';

class DeviceUsageAlertsTable extends Component {
  constructor(props) {
    super(props);

    this.scrollWrap = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { alerts } = this.props;

    if (
      prevProps.alerts &&
      alerts &&
      prevProps.alerts.length &&
      alerts.length &&
      prevProps.alerts.length !== alerts.length
    ) {
      this.scrollToNewAlerts(prevProps.alerts.length);
    }
  }

  componentWillUnmount() {
    const { setActiveAlert_, setActivePin } = this.props;

    setActiveAlert_(null, alertTypes.DEVICE_USAGE);
    setActivePin(null, alertTypes.DEVICE_USAGE);
  }

  scrollToNewAlerts = (lastAlert) => {
    const { alerts } = this.props;
    const row = document.getElementById(`device-usage-alert-${alerts[lastAlert].id}`);

    if (row) {
      scrollTo(
        row.offsetTop - this.scrollWrap.current.offsetTop,
        () => {},
        200,
        this.scrollWrap.current
      );
    }
  };

  render() {
    const {
      activeAlertId,
      alerts,
      alertCount,
      ackedAlertIds,
      allSelected,
      bulkPause,
      deselectAlerts,
      deviceCount,
      dismissClearBanner,
      dismissClearBannerAndLoadMore,
      goToNextPage,
      handleAcknowledgeSingleAlert,
      handleHideEducationBanner,
      handlePauseSim,
      handlePlanChange,
      handleResume,
      handleSingleSelection,
      handleUnacknowledgeSingleAlert,
      isInitialPageLoad,
      isLoading,
      keyedAlerts,
      limitedBulkActionAvailable,
      nextPageEnabled,
      selectAlerts,
      selectAll,
      selectedAlerts,
      selectedDevices,
      showClearAndLoadMore,
      showEducationHeader,
      theme,
      updateTheme,
      handleAcknowledgeAlerts,
      setAllUnloadedAlertsSelected,
      allUnloadedAlertsSelected,
    } = this.props;
    const colspan = theme === 'multi-select' ? '4' : '3';
    const isUnfurled = !!activeAlertId;
    const canBulkDeactivate = selectedAlerts.some(
      (alert) => !deactivatedStates.includes(keyedAlerts[alert].state)
    );

    let alertsTableHeaderCTA;
    if (theme === 'default') {
      let acknowledgeButton = null;
      if (alerts.length) {
        acknowledgeButton = (
          <Button
            disabled={isUnfurled}
            iconLeft="Checkmark--double"
            onClick={handleAcknowledgeAlerts}
            type="secondary"
          >
            {alerts.length === alertCount ? 'Acknowledge All' : `Acknowledge ${alerts.length}`}
          </Button>
        );
      }
      alertsTableHeaderCTA = (
        <>
          {acknowledgeButton}
          <Button
            disabled={isUnfurled}
            iconLeft="Select"
            onClick={() => updateTheme('multi-select')}
            type="secondary"
          >
            Select Multiple
          </Button>
        </>
      );
    } else {
      let acknowledgeButton = null;

      if (alerts.length) {
        acknowledgeButton = (
          <Button
            iconLeft="Checkmark--double"
            onClick={() => handleAcknowledgeAlerts()}
            disabled={
              !selectedDevices.length ||
              selectedAlerts.every((alert) => ackedAlertIds.includes(alert))
            }
            type="reversed"
          >
            {allUnloadedAlertsSelected
              ? `Acknowledge ${alertCount}`
              : `Acknowledge ${selectedAlerts.length}`}
          </Button>
        );
      }
      alertsTableHeaderCTA = (
        <>
          <Button
            iconLeft={bulkPause ? 'Pause' : 'Play'}
            onClick={bulkPause ? handlePauseSim : handleResume}
            disabled={
              !selectedDevices.length ||
              !limitedBulkActionAvailable ||
              !canBulkDeactivate ||
              (allUnloadedAlertsSelected && alerts.length !== alertCount)
            }
            type="reversed"
          >
            {bulkPause ? 'Pause' : 'Resume'}
          </Button>
          {acknowledgeButton}
          <div className="AlertsTable__header__cta-divider" />
          <Button
            iconLeft="Checkmark--double"
            onClick={() => updateTheme('default')}
            type="secondary"
          >
            Done
          </Button>
        </>
      );
    }

    let content;
    if (!isInitialPageLoad && deviceCount === 0) {
      content = <AlertsActionItemsAd />;
    } else if (!isInitialPageLoad && alerts.length === 0) {
      content = (
        <div className="AlertsTable__no-alerts-container">
          <ComplexIcon name="green-ribbon" />
          <div>All is well. None of your</div>
          <div>devices require attention.</div>
        </div>
      );
    } else {
      content = (
        <div className={`AlertsTable AlertsTable--column3 AlertsTable--${theme}`}>
          <Loader isLoading={isInitialPageLoad || isLoading} />
          <div className="AlertsTable__header">
            <div className="AlertsTable__header__count">{isInitialPageLoad ? '' : alertCount}</div>
            <div className="AlertsTable__header__headline">
              <h3>
                <span>Usage Alerts</span>
              </h3>
            </div>
            <div className="AlertsTable__header__cta">{alertsTableHeaderCTA}</div>
          </div>
          <div className="AlertsTable__scroll-wrap">
            <table
              className={_classnames('AlertsTable__body', {
                'AlertsTable__body--multi-select': theme === 'multi-select',
              })}
            >
              {!isInitialPageLoad ? (
                <>
                  <thead className="AlertsTable__section AlertsTable__section--header">
                    <tr className="AlertsTable__row AlertsTable__row">
                      {theme === 'multi-select' && (
                        <th className="AlertsTable__cell AlertsTable__cell__select">
                          <div className="AlertsTable__cell__content">
                            <input
                              checked={allSelected}
                              id="select-all"
                              name="select-all"
                              onClick={selectAll}
                              // eslint-disable-next-line no-return-assign
                              ref={(el) =>
                                // eslint-disable-next-line no-param-reassign
                                el && (el.indeterminate = selectedDevices.length && !allSelected)
                              }
                              type="checkbox"
                            />
                          </div>
                        </th>
                      )}
                      <th className="AlertsTable__cell AlertsTable__cell--sortable">
                        <div className="AlertsTable__cell__content">
                          Timestamp
                          <Icon
                            name="Arrow--triangle--south"
                            size="micro"
                            svgProps={{ style: { fill: '#606677' } }}
                          />
                        </div>
                      </th>
                      <th className="AlertsTable__cell">
                        <div className="AlertsTable__cell__content">Affected Device</div>
                      </th>
                      <th className="AlertsTable__cell">
                        <div className="AlertsTable__cell__content">Alert Threshold</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="AlertsTable__section AlertsTable__section--body"
                    ref={this.scrollWrap}
                  >
                    <ClearAcknowledgedBanner
                      colSpan={colspan}
                      close={dismissClearBanner}
                      show={showClearAndLoadMore}
                      loadMore={dismissClearBannerAndLoadMore}
                    />
                    {showEducationHeader && (
                      <tr>
                        <td colSpan={colspan}>
                          <div className="AlertsTable__education-header">
                            <Picture image={usageAlertImg} alt="Alert" />
                            <span className="AlertsTable__education-header__title">
                              Custom SIM Usage Alerts
                            </span>
                            <div className="AlertsTable__education-header__description">
                              These SIMs hit a data threshold determined by someone in your
                              organization. Their status has not changed, but they should be
                              reviewed.
                            </div>
                            <div
                              className="AlertsTable__education-header__icon-container"
                              onClick={handleHideEducationBanner}
                              onKeyUp={handleHideEducationBanner}
                              role="button"
                              tabIndex={0}
                            >
                              <Icon
                                name="Close"
                                size="minor"
                                svgProps={{ style: { fill: '#040815' } }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    {alerts.map((alert, idx) => (
                      <DeviceUsageAlertsTableRow
                        alert={alert}
                        scrollWrap={this.scrollWrap}
                        colspan={colspan}
                        deselectAlerts={deselectAlerts}
                        handleAcknowledgeSingleAlert={handleAcknowledgeSingleAlert}
                        handlePauseSim={handlePauseSim}
                        handleResume={handleResume}
                        handlePlanChange={handlePlanChange}
                        handleSingleSelection={handleSingleSelection}
                        handleUnacknowledgeSingleAlert={handleUnacknowledgeSingleAlert}
                        key={alert?.id ?? idx}
                        selectAlerts={selectAlerts}
                        selectedAlerts={selectedAlerts}
                        selectedDevices={selectedDevices}
                        theme={theme}
                        isFirstRow={idx === 0}
                      />
                    ))}
                  </tbody>
                </>
              ) : null}
            </table>
          </div>
          {theme === 'multi-select' ? (
            <SelectMultipleToolbar
              selectedAlerts={selectedAlerts}
              loaded={alerts.length}
              alertCount={alertCount}
              setAllUnloadedAlertsSelected={setAllUnloadedAlertsSelected}
              selectAll={selectAll}
              deselectAlerts={deselectAlerts}
              allUnloadedAlertsSelected={allUnloadedAlertsSelected}
            />
          ) : (
            <LoadMoreFooter
              continues={nextPageEnabled}
              loaded={alerts.length}
              total={alertCount}
              loadMore={goToNextPage}
            />
          )}
        </div>
      );
    }

    return (
      <div
        className={_classnames('AlertsTable__wrapper', {
          'AlertsTable__wrapper--empty': deviceCount === 0,
          'AlertsTable__wrapper--expanded': theme === 'multi-select',
        })}
        id="AlertsTable__wrapper"
      >
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveAlert_: setActiveAlert,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withPlanHooksHoc(AlertsTableContainer(alertTypes.DEVICE_USAGE)(DeviceUsageAlertsTable)));
