import React from 'react';

const SearchModalSkeletonRow = () => (
  <div className="SearchModal__skeleton__row">
    <div className="SearchModal__skeleton__row__item" />
    <div className="SearchModal__skeleton__row__item" />
  </div>
);

const SearchModalSkeleton = () => (
  <div className="SearchModal__skeleton" data-testid="search-modal-skeleton">
    <SearchModalSkeletonRow />
    <SearchModalSkeletonRow />
    <SearchModalSkeletonRow />
    <SearchModalSkeletonRow />
  </div>
);

export default SearchModalSkeleton;
