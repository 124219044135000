import { type SimInventoryCommonTypes } from '@hologram-hyper-dashboard/components';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx/lite';
import useApi from 'common-js/api/useApi';
import { getUserContextDataMemoized } from 'common-js/api/util';
import { useAppDispatch } from 'common-js/hooks';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { getAllBilling } from 'common-js/reducers/organization/actions';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

interface CreditCardEditFormProps {
  classNames?: string;
  setLoading: (loading: boolean) => void;
  setError: (error: string) => void;
  onSuccess: () => void;
  onCancel: () => void;
}

const CreditCardEditForm = forwardRef<SimInventoryCommonTypes.OnSubmit, CreditCardEditFormProps>(
  ({ classNames, setLoading, setError, onSuccess, onCancel }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    const userContext = useAppSelector(getUserContextDataMemoized);
    const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;

    const createSetupIntentApi = useApi.call(`/organizations/${orgId}/billing/setupintent/create`, {
      method: 'POST',
    });

    // TODO: Ideally listen on webhooks in API
    const finalizeSetupIntentApi = useApi.call(
      `/organizations/${orgId}/billing/setupintent/finalize`,
      {
        method: 'POST',
      }
    );

    const [clientSecret, setClientSecret] = useState<string | undefined>();

    useEffect(() => {
      const getClientSecret = async () => {
        const response = await createSetupIntentApi();

        setClientSecret(response?.data ?? null);
      };
      getClientSecret();
    }, [createSetupIntentApi]);

    const dispatch = useAppDispatch();

    const focusPaymentElement = useCallback(() => {
      elements?.getElement('payment')?.focus();
    }, [elements]);

    const handleSubmit = useCallback(async () => {
      if (!stripe || !elements || !clientSecret) {
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        setError(submitError.message ?? 'Unknown error');
        setLoading(false);
        focusPaymentElement();
        return;
      }

      setLoading(true);

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        clientSecret,
        confirmParams: { return_url: 'https://dashboard.hologram.io/' },
      });

      if (error) {
        setError(error.message ?? 'Unknown error');
        setLoading(false);
        focusPaymentElement();
        return;
      }

      try {
        // Since there is nothing a customer can do about errors generated here we will rely on server tracking to find and fix them. Mostly this just results in Stripe account clutter
        await finalizeSetupIntentApi({ body: { setupIntentId: setupIntent?.id } });
      } catch (_e) {
        /* empty */
      }

      await dispatch(getAllBilling());
      onSuccess();

      setLoading(false);
    }, [
      clientSecret,
      dispatch,
      elements,
      finalizeSetupIntentApi,
      focusPaymentElement,
      onSuccess,
      setError,
      setLoading,
      stripe,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        onSubmit: handleSubmit,
      }),
      [handleSubmit]
    );

    return (
      <div className={clsx('CreditCardEditForm', classNames)}>
        <PaymentElement onEscape={onCancel} onReady={focusPaymentElement} />
      </div>
    );
  }
);

export default CreditCardEditForm;
