import { useEffect } from 'react';

const unloadHandler = (event: Event) => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue = true;
  return event.returnValue;
};

const useConfirmOnPageUnload = (skip = false) => {
  useEffect(() => {
    if (skip) return undefined;
    window.addEventListener('beforeunload', unloadHandler);
    return () => window.removeEventListener('beforeunload', unloadHandler);
  }, [skip]);
};

export default useConfirmOnPageUnload;
