export default {
  cn: {
    // Chinese
    january: '一月',
    february: '二月',
    march: '三月',
    april: '四月',
    may: '五月',
    june: '六月',
    july: '七月',
    august: '八月',
    september: '九月',
    october: '十月',
    november: '十一月',
    december: '十二月',
    su: '日',
    mo: '一',
    tu: '二',
    we: '三',
    th: '四',
    fr: '五',
    sa: '六',
  },
  jp: {
    // Japanese
    january: '1月',
    february: '2月',
    march: '3月',
    april: '4月',
    may: '5月',
    june: '6月',
    july: '7月',
    august: '8月',
    september: '9月',
    october: '10月',
    november: '11月',
    december: '12月',
    su: '日',
    mo: '月',
    tu: '火',
    we: '水',
    th: '木',
    fr: '金',
    sa: '土',
  },
  fr: {
    // French
    january: 'janvier',
    february: 'février',
    march: 'mars',
    april: 'avril',
    may: 'mai',
    june: 'juin',
    july: 'juillet',
    august: 'août',
    september: 'septembre',
    october: 'octobre',
    november: 'novembre',
    december: 'décembre',
    su: 'Dimanche',
    mo: 'Lundi',
    tu: 'Mardi',
    we: 'Mercredi',
    th: 'Jeudi',
    fr: 'Vendredi',
    sa: 'Samedi',
  },
  it: {
    // Italian
    january: 'gennaio',
    february: 'febbraio',
    march: 'marzo',
    april: 'aprile',
    may: 'maggio',
    june: 'giugno',
    july: 'luglio',
    august: 'agosto',
    september: 'settembre',
    october: 'ottobre',
    november: 'novembre',
    december: 'dicembre',
    su: 'Domenica',
    mo: 'Lunedì',
    tu: 'Mardi',
    we: 'Mercoledì',
    th: 'Giovedì',
    fr: 'Venerdì',
    sa: 'Sabato',
  },
  de: {
    // German
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    su: 'Sonntag',
    mo: 'Montag',
    tu: 'Dienstag',
    we: 'Mittwoch',
    th: 'Donnerstag',
    fr: 'Freitag',
    sa: 'Samstag',
  },
  ko: {
    // Korean
    january: '1월',
    february: '2월',
    march: '3월',
    april: '4월',
    may: '5월',
    june: '6월',
    july: '7월',
    august: '8월',
    september: '9월',
    october: '10월',
    november: '11월',
    december: '12월',
    su: '일',
    mo: '월',
    tu: '화',
    we: '수',
    th: '목',
    fr: '금',
    sa: '토',
  },
  es: {
    // Spanish
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    su: 'Do',
    mo: 'Lu',
    tu: 'Ma',
    we: 'Mi',
    th: 'Ju',
    fr: 'Vi',
    sa: 'Sa',
  },
  ru: {
    // Russian
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
    su: 'Вс',
    mo: 'Пн',
    tu: 'Вт',
    we: 'Ср',
    th: 'Чт',
    fr: 'Пт',
    sa: 'Сб',
  },
  tr: {
    // Turkish
    january: 'Ocak',
    february: 'Şubat',
    march: 'Mart',
    april: 'Nisan',
    may: 'Mayıs',
    june: 'Haziran',
    july: 'Temmuz',
    august: 'Ağustos',
    september: 'Eylül',
    october: 'Ekim',
    november: 'Kasım',
    december: 'Aralık',
    su: 'Pz',
    mo: 'Pts',
    tu: 'Sa',
    we: 'Ça',
    th: 'Pe',
    fr: 'Cu',
    sa: 'Cts',
  },
};
