import React from 'react';

const EMPTY_DASH = '—';

const HyperProfileHistoryTableRow = ({
  name,
  type,
  enabledOn,
  simNumber,
  linkId,
  imei,
  apn,
  deviceId,
  showActiveProfileIndicator,
}) => (
  <tr className="Table__row">
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content HyperProfileHistoryTable__name">
        {showActiveProfileIndicator && (
          <div className="HyperProfileHistoryTable__active-profile">Active Profile</div>
        )}
        <div>{name ?? EMPTY_DASH}</div>
      </div>
    </td>
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content">
        {type ?? EMPTY_DASH}
      </div>
    </td>
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content">
        {enabledOn ?? EMPTY_DASH}
      </div>
    </td>
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content">
        {simNumber ?? EMPTY_DASH}
      </div>
    </td>
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content">
        {linkId ?? EMPTY_DASH}
      </div>
    </td>
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content">
        {imei ?? EMPTY_DASH}
      </div>
    </td>
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content">
        {apn ?? EMPTY_DASH}
      </div>
    </td>
    <td className="Table__cell Table__cell--no-border-right HyperProfileHistoryTable__cell">
      <div className="Table__cell-content HyperProfileHistoryTable__cell-content">
        {deviceId ?? EMPTY_DASH}
      </div>
    </td>
  </tr>
);

export default HyperProfileHistoryTableRow;
