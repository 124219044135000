import { cloneDeep } from 'lodash';
import moment from 'moment';

export function getElapsedDays(startTimestamp, endTimestamp) {
  const DAY_DIVISOR = 86400; // In seconds

  if (startTimestamp && endTimestamp) return (endTimestamp - startTimestamp) / DAY_DIVISOR;
  if (startTimestamp) return (Date.now() / 1000 - startTimestamp) / DAY_DIVISOR;
  return -1;
}

export const getTerm = (timestart, timeend) => {
  const elapsedDays = getElapsedDays(timestart, timeend);

  return elapsedDays > 0 && elapsedDays < 2 ? 'shortterm' : 'longterm';
};

export function getBillingPeriod(date, currentEndDate) {
  const _start = moment.utc(currentEndDate).subtract(30, 'days');
  const _date = moment.utc(date);

  if (_date.isBefore(_start)) {
    const newStart = cloneDeep(_start);
    return getBillingPeriod(date, newStart);
  }

  return {
    start: _start,
    end: currentEndDate,
  };
}
