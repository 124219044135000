import isFunction from 'lodash/isFunction';
import { createSelector } from 'reselect';

interface UserContextData {
  showAllDevices: boolean;
  isInOrgContext: boolean;
  orgId?: number | null;
  userEmail?: string;
  userId?: number;
  userOrgId?: number;
  isSuperUser: boolean;
}

export function getUserContextData(getState): UserContextData {
  const state = isFunction(getState) ? getState() : getState;

  return {
    showAllDevices: !!state.account.showAllDevices,
    isInOrgContext: !!state.organization.selectedContext,
    orgId: state.organization.selectedContext,
    userEmail: state.account.email,
    userId: state.account.userId,
    userOrgId: state.account.orgId,
    isSuperUser: state.account.role === 'adm' || state.account.role === 'hdm',
  };
}

export const getUserContextDataMemoized = createSelector(
  [(state: any) => state.account, (state) => state.organization],
  (account, organization): UserContextData => ({
    showAllDevices: account.showAllDevices,
    isInOrgContext: !!organization.selectedContext,
    orgId: organization.selectedContext,
    userEmail: account.email,
    userId: account.userId,
    userOrgId: account.orgId,
    isSuperUser: account.role === 'adm' || account.role === 'hdm',
  })
);

export function getCurrentOrgId(state): Nullable<Optional<OrgId>> {
  const userContext = getUserContextData(state);
  return userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;
}

export const getOrgIdFromContextData = (contextData: UserContextData): Nullable<Optional<OrgId>> =>
  contextData.isInOrgContext ? contextData.orgId : contextData.userOrgId;

export const selectOrgId = createSelector(
  getUserContextDataMemoized,
  ({ isInOrgContext, orgId, userOrgId }) => (isInOrgContext ? orgId : userOrgId)
);

interface QueryStringFromContextDataProps {
  userContextData: UserContextData;
}

export const queryStringFromContextData = ({
  userContextData,
}: QueryStringFromContextDataProps) => {
  const orgid = getOrgIdFromContextData(userContextData as UserContextData)?.toString() ?? '';

  return new URLSearchParams({
    orgid,
  }).toString();
};
