import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HoloHelmet } from '../../common/components';
import { DeviceUsageAlertsTable } from '../components';
import { getTotalDeviceCount } from '../../common/reducers/organization/actions';
import { createGetAlertLoadingByType } from '../../common/reducers/alerts/selectors';
import * as alertTypes from '../../common/constants/alertTypes';
import {
  getAlertsByType,
  getAlertCountByType,
  clearAlertsByType,
} from '../../common/reducers/alerts/actions';
import { pushGlobalMessage } from '../../common/reducers/message/actions';

class HighUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      pushGlobalMessage_,
      clearAlertsByType_,
      getAlertsByType_,
      getAlertCountByType_,
      getTotalDeviceCount_,
    } = this.props;

    clearAlertsByType_(alertTypes.DEVICE_USAGE);

    Promise.all([
      getAlertsByType_(alertTypes.DEVICE_USAGE),
      getAlertCountByType_(alertTypes.DEVICE_USAGE),
      getTotalDeviceCount_(),
    ])
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        pushGlobalMessage_(error, 'error');
      });
  }

  render() {
    const { deviceCount } = this.props;

    const { isLoading } = this.state;

    return (
      <>
        <HoloHelmet title="Action items - Data usage alerts" />
        <DeviceUsageAlertsTable isInitialPageLoad={isLoading} deviceCount={deviceCount} />
      </>
    );
  }
}

const createMapStateToProps = () => {
  const getDataLimitsLoading = createGetAlertLoadingByType(alertTypes.DEVICE_DATA_LIMIT);

  return (state) => ({
    deviceCount: state.organization.deviceCount,
    isLoading: getDataLimitsLoading(state),
    releaseFlags: state.releaseFlag,
  });
};

export default connect(createMapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      getTotalDeviceCount_: getTotalDeviceCount,
      getAlertsByType_: getAlertsByType,
      pushGlobalMessage_: pushGlobalMessage,
      getAlertCountByType_: getAlertCountByType,
      clearAlertsByType_: clearAlertsByType,
    },
    dispatch
  )
)(HighUsage);
