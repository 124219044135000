import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { InlineNotification } from '@hologram-dimension/inline-notification';
import { TextareaField } from '@hologram-dimension/textarea-field';
import { type LegacyDashboardTypes } from '@hologram-hyper-dashboard/components';
import { BILLING_ACCOUNT_ADD_EMAIL_TO_BILLING_NOTIFICATIONS } from 'common-js/analytics/actionTypes';
import sendAnalyticsEvent from 'common-js/analytics/analytics';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { updateOrganization } from 'common-js/reducers/organization/actions';
import { getSelectedOrg } from 'common-js/reducers/organization/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface FormInputValues {
  billingEmailCC: string;
}

const BillingEmailCCForm: React.FC = () => {
  const currentOrg: LegacyDashboardTypes.LegacyOrganization | undefined =
    useAppSelector(getSelectedOrg);

  const dispatch = useAppDispatch();

  const [requestError, setRequestError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<FormInputValues>({
    defaultValues: {
      billingEmailCC: currentOrg?.ccemails,
    },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormInputValues> = useCallback(
    (data) => {
      setProcessing(true);
      setRequestError(null);

      const { billingEmailCC } = data;

      if (!currentOrg?.id) {
        return;
      }

      dispatch(updateOrganization(currentOrg.id, null, billingEmailCC))
        .then(() => {
          sendAnalyticsEvent({
            type: BILLING_ACCOUNT_ADD_EMAIL_TO_BILLING_NOTIFICATIONS,
          });

          toast(
            <HeadlineToast
              headline="Billing email notifications"
              body="Your billing email notifications have been saved."
              icon="Checkmark--single"
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              className: 'toastify-content--burnt toastify-content--success',
              autoClose: 10000,
            }
          );
          reset(data);

          setProcessing(false);
        })
        .catch((e) => {
          setProcessing(false);
          setRequestError(e);
        });
    },
    [currentOrg?.id, dispatch, reset]
  );

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      footerActions={
        isDirty ? (
          <Button type="submit" loading={processing}>
            Save emails
          </Button>
        ) : undefined
      }
    >
      {requestError && <InlineNotification variant="error">{requestError}</InlineNotification>}
      <TextareaField
        disabled={processing}
        label="Send credit card receipts to"
        {...register('billingEmailCC')}
      />
    </Form>
  );
};

export default BillingEmailCCForm;
