import React from 'react';

function SummaryTable({ children, footerMessage }) {
  return (
    <table className="SummaryTable">
      <thead>
        <tr className="SummaryTable__row--header">
          <th className="SummaryTable__cell--header" style={{ width: '40%' }}>
            Description
          </th>
          <th className="SummaryTable__cell--header" style={{ width: '15%' }}>
            Quantity
          </th>
          <th className="SummaryTable__cell--header" style={{ width: '29%' }}>
            30-day recurring charge
          </th>
          <th
            className="SummaryTable__cell--header SummaryTable__cell--total"
            style={{ width: '16%' }}
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {children}
        {footerMessage && (
          <tr>
            <td colSpan={4} className="SummaryTable__cell SummaryTable__cell--footer">
              {footerMessage}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default SummaryTable;
