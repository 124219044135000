import { ComplexIcon } from '@holokit/core';
import { FC, ReactNode } from 'react';

interface ReportsErrorProps {
  caption?: ReactNode;
  message?: ReactNode;
  button?: ReactNode;
}

const ReportsError: FC<ReportsErrorProps> = ({ caption, message, button }) => (
  <div className="ReportsError">
    <ComplexIcon name="missing-document" />

    <div className="ReportsError__message--caption">{caption}</div>

    {message && <div className="ReportsError__message">{message}</div>}

    <div className="ReportsError__button">{button}</div>
  </div>
);

export default ReportsError;
