import BalanceModel from '../../models/BalanceModel';
import BillingInformationModel from '../../models/BillingInformationModel';
import * as accountActionTypes from '../account/actionTypes';
import * as actionTypes from './actionTypes';

export const initialState = {
  balance: new BalanceModel(),
  balanceHistory: [],
  billingInformation: new BillingInformationModel(),
  billingStatements: [],
  invoices: [],
  deviceCount: 0,
  deviceCountActive: 0,
  deviceCountDeactivated: 0,
  deviceCountPreflight: 0,
  devicePlanSummary: [],
  orgs: [],
  package: null,
  selectedContext: null,
  triggerContextSwitcher: false,
  upgradeError: null,
  upgradeProcessing: false,
  upgradeSuccess: false,
};

// eslint-disable-next-line default-param-last
function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case accountActionTypes.LOGOUT_REQUEST:
    case accountActionTypes.REGISTER_REQUEST:
    case accountActionTypes.LOGIN_REQUEST:
      return { ...state, ...initialState };

    case actionTypes.GET_ALL_SUCCESS:
      return { ...state, orgs: action.orgs };

    case actionTypes.GET_ORGGROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.groups,
      };
    }

    case actionTypes.UPDATE_PERMISSIONS_SUCCESS:
    case actionTypes.REMOVE_USERS_SUCCESS:
    case actionTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        orgs: state.orgs.map((org) => (org.id === action.updatedOrg.id ? action.updatedOrg : org)),
      };

    case actionTypes.CHANGE_CONTEXT:
      return {
        ...state,
        selectedContext: action.org.isMyAccount ? null : action.org.id,
        triggerContextSwitcher: !state.triggerContextSwitcher,
        balance: new BalanceModel(),
        billingInformation: new BillingInformationModel(),
      };

    case actionTypes.CREATE_ORG_SUCCESS:
    case actionTypes.CONVERT_PERSONAL_TO_ORG_SUCCESS:
      return {
        ...state,
        orgs: [...state.orgs, action.org],
        triggerContextSwitcher: !state.triggerContextSwitcher,
      };

    case actionTypes.UPDATE_ORG_SUCCESS:
      return {
        ...state,
        orgs: state.orgs.map((org) => (org.id === action.org.id ? action.org : org)),
      };

    case actionTypes.GET_BALANCEHISTORY_SUCCESS:
      return { ...state, balanceHistory: action.balanceHistory };

    case actionTypes.GET_ALL_BILLING_SUCCESS: {
      const billingUpdates = {
        balance: action.balance,
        balanceHistory: action.balanceHistory,
      };

      if (action.billingInformation) {
        billingUpdates.billingInformation = action.billingInformation;
      }

      const packageName = action?.allPackages?.find(
        (pkg) => pkg.id === action?.package?.package_id
      )?.name;

      const packageInfo = {
        ...action.package,
        name: packageName,
      };

      return {
        ...state,
        ...billingUpdates,
        ...(action.package ? { package: packageInfo } : {}),
        allPackages: action.allPackages ?? [],
      };
    }

    case actionTypes.GET_BILLING_ADDRESS_SUCCESS: {
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          billingAddress: action.address,
        },
      };
    }

    case actionTypes.GET_BILLING_INFORMATION_SUCCESS: {
      return {
        ...state,
        billingInformation: action.billingInformation,
      };
    }

    case actionTypes.UPDATE_BALANCE_PARAMS_SUCCESS:
      return {
        ...state,
        balance: {
          ...state.balance,
          minBalance: action.minBalance,
          topOffAmount: action.topOffAmount,
        },
      };

    case actionTypes.GET_BALANCE_SUCCESS:
      return { ...state, balance: action.balance };

    case actionTypes.GET_PLANDEVICE_SUMMARY_SUCCESS:
      return { ...state, devicePlanSummary: action.devicePlanSummary };

    case actionTypes.GET_DEVICE_COUNT_SUCCESS:
      return {
        ...state,
        deviceCount: action.deviceCount,
        deviceCountActive: action.deviceCountActive,
        deviceCountPreflight: action.deviceCountPreflight,
        deviceCountDeactivated: action.deviceCountDeactivated,
      };

    case actionTypes.GET_TOTAL_DEVICE_COUNT_SUCCESS:
      return {
        ...state,
        deviceCount: action.deviceCount,
      };

    case actionTypes.PUT_UPGRADE_REQUEST:
      return {
        ...state,
        upgradeProcessing: true,
        upgradeSuccess: false,
        upgradeError: null,
      };

    case actionTypes.PUT_UPGRADE_SUCCESS:
      return {
        ...state,
        upgradeProcessing: false,
        upgradeSuccess: true,
        upgradeError: null,
      };

    case actionTypes.PUT_UPGRADE_ERROR:
      return {
        ...state,
        upgradeProcessing: false,
        upgradeSuccess: false,
        upgradeError: action.error,
      };

    case actionTypes.GET_BILLING_STATEMENTS_SUCCESS:
      return {
        ...state,
        billingStatements: action.statements,
      };

    case actionTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.invoices,
      };

    default:
      return state;
  }
}

export default organizationReducer;
