import { batchActivation } from 'common-js/api';
import { getUserContextDataMemoized } from 'common-js/api/util';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  buildSimConfiguration,
  buildTasks,
  getErrorMessage,
} from 'common-js/reducers/activation/actions/actionUtils';
import { SelectedSims } from 'common-js/reducers/activation/actions/types';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { useEffect, useMemo, useState } from 'react';
import { useStore } from 'react-redux';
import type { ReduxLink, ReduxSim } from 'types/redux';

const useCarriersForSelectedSims = (
  selectedSims?: SelectedSims,
  changePlanLinks?: Array<ReduxLink>
) => {
  // state
  const [fetchingCarriers, setFetchingCarriers] = useState(true);
  const [carrierIds, setCarrierIds] = useState<Array<CarrierId>>([]);
  const [error, setError] = useState('');

  const alreadyActivatedSims: Array<ReduxSim> = useAppSelector(
    (state) => state.activation?.sims?.errors?.alreadyActivated
  );
  const excludeSims = useMemo(
    () => (alreadyActivatedSims ?? []).map((sim: ReduxSim) => sim.key),
    [alreadyActivatedSims]
  );
  const store = useStore();
  const userContext = useAppSelector(getUserContextDataMemoized);

  useEffect(() => {
    const getCarrierIds = async (sims: any) => {
      const config = await buildSimConfiguration({
        sims,
        userContext,
        excludeSims,
      });

      const tasks = buildTasks(store.getState(), config);
      const result = await batchActivation(tasks, true, false, userContext);

      const newCarriers = new Set<CarrierId>();
      result.valid_tasks.forEach((task) => {
        task.order_data.forEach((order) => {
          if (isArray(order.carrierids)) {
            order.carrierids.forEach((carrierId: CarrierId) => {
              newCarriers.add(carrierId);
            });
          } else if (
            isObject(order.carrierids) &&
            typeof Object.values(order.carrierids)[0] === 'number'
          ) {
            Object.values<CarrierId>(order.carrierids).forEach((carrierId) => {
              newCarriers.add(carrierId);
            });
          }
        });
      });

      setCarrierIds([...newCarriers.values()]);
    };

    const reduceLinksToCarrierIds = (
      carrierIdsAcc: Array<CarrierId>,
      link: ReduxLink
    ): Array<CarrierId> => [...new Set([...carrierIdsAcc, link.carrierid])];

    if (selectedSims) {
      getCarrierIds(selectedSims)
        .catch((reason) => {
          if (reason) {
            setError(`Error occurred loading regions: ${getErrorMessage(reason)}`);
          } else {
            setError(`Error occurred loading regions`);
          }
        })
        .finally(() => setFetchingCarriers(false));
    } else if (changePlanLinks) {
      setCarrierIds(changePlanLinks.reduce(reduceLinksToCarrierIds, []));
      setFetchingCarriers(false);
    } else {
      setFetchingCarriers(false);
    }
    // This effect should only run once on the use of the hook.
    // We don't allow changing sims selected on any of the pages that this could run.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fetchingCarriers, error, carrierIds };
};

export default useCarriersForSelectedSims;
