import { MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { Icon } from '@hologram-dimension/icon';
import { ComplexIcon } from '@holokit/core';
import { AppDispatch } from 'common-js/configureStore';
import {
  addTagToDevice as addTagToDevice_,
  removeTagFromDevice as removeTagFromDevice_,
} from 'common-js/reducers/currentDevice/actions';
import { selectUpdatingTags } from 'common-js/reducers/currentDevice/selectors';
import { addNewTag as addNewTag_ } from 'common-js/reducers/deviceFilter/actions';
import { Device } from 'common-js/types/Device';
import { find } from 'lodash';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

type DeviceTagInputPropsFromRedux = ConnectedProps<typeof connector>;

interface DeviceTagInputOwnProps {
  device: Device;
  selectedTags: Array<Tag>;
  tags: Array<Tag>;
  updatingTags: boolean;
}

interface DeviceTagInputState {
  deviceFilters: { tags: Array<Tag> };
}

class DeviceTagInput extends React.Component<
  DeviceTagInputOwnProps & DeviceTagInputPropsFromRedux,
  DeviceTagInputState
> {
  static onCreateTag(tagName: string): Tag {
    return {
      name: tagName,
    };
  }

  static renderCreateTag(
    query: string,
    active: boolean,
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  ) {
    return (
      <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={onClick}
        shouldDismissPopover={false}
      />
    );
  }

  static renderSelectedTag(tag: Tag) {
    return tag.name;
  }

  static onComputeMatch(inputValue: string, tag: Tag) {
    return tag.name.toLowerCase().includes(inputValue.toLowerCase());
  }

  onRemoveTag(idx: number) {
    const { removeTagFromDevice, device, selectedTags } = this.props;
    const tagToRemove = selectedTags[idx];

    if (!tagToRemove.id) {
      // eslint-disable-next-line no-console
      console.error('Failed to remove tag, it has no id');
      return;
    }

    removeTagFromDevice(tagToRemove.id, device.id);
  }

  onItemSelect(tag: Tag) {
    const { addTagToDevice, removeTagFromDevice, device, selectedTags, addNewTag } = this.props;

    // Tag not created, add it now & link it directly.
    if (!tag.id) return addNewTag(tag.name, [device.id], device.id);

    // Modify an existing device/tag link.
    const idxOfExistingSelectedTag = selectedTags.indexOf(tag);

    if (idxOfExistingSelectedTag > -1) {
      removeTagFromDevice(tag.id, device.id);
    } else {
      addTagToDevice(tag.id, device.id);
    }

    return undefined;
  }

  renderDropdownItem(
    tag: Tag,
    {
      modifiers,
      handleClick,
    }: {
      modifiers: { matchesPredicate: boolean; active: boolean };
      handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    }
  ) {
    const { selectedTags } = this.props;

    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={tag.id}
        icon={selectedTags.includes(tag) ? 'tick' : 'blank'}
        onClick={handleClick}
        text={tag.name}
        shouldDismissPopover={false}
      />
    );
  }

  render() {
    const { tags, selectedTags, updatingTags } = this.props;

    return (
      <div className={`DeviceTagInput${updatingTags ? ' processing' : ''}`}>
        <div className="floating-label">TAGS</div>
        <MultiSelect
          placeholder="Add a tag +"
          selectedItems={selectedTags}
          itemRenderer={(tag, modifiers) => this.renderDropdownItem(tag, modifiers)}
          items={tags}
          noResults={<MenuItem disabled text="No results." />}
          itemPredicate={(inputValue, tag) => DeviceTagInput.onComputeMatch(inputValue, tag)}
          onItemSelect={(tag) => this.onItemSelect(tag)}
          tagRenderer={(tag) => DeviceTagInput.renderSelectedTag(tag)}
          popoverProps={{ minimal: true }}
          tagInputProps={{
            tagProps: { minimal: true },
            onRemove: (_, idx) => this.onRemoveTag(idx),
            rightElement: updatingTags ? (
              <ComplexIcon classes="DeviceTagInput__loader" name="spinner" />
            ) : undefined,
            leftIcon: (
              <Icon
                className="DeviceTagInput__icon"
                name="Tag"
                size="small"
                fill="DdsColorPaletteTeal40"
              />
            ),
          }}
          createNewItemFromQuery={DeviceTagInput.onCreateTag}
          createNewItemRenderer={DeviceTagInput.renderCreateTag}
        />
      </div>
    );
  }
}

const connector = connect(
  (state: DeviceTagInputState, props: any) => {
    const updatingTags = selectUpdatingTags(state);

    const selectedTags = props.device.tags
      .map((tag) => find(state.deviceFilters.tags, { id: tag }))
      .filter((tag) => typeof tag !== 'undefined')
      .sort((next, prev) => ((next?.id ?? 0) > (prev?.id ?? 0) ? 1 : -1));

    return {
      tags: state.deviceFilters.tags,
      selectedTags,
      updatingTags,
    };
  },
  (dispatch: AppDispatch) =>
    bindActionCreators(
      {
        addTagToDevice: addTagToDevice_,
        removeTagFromDevice: removeTagFromDevice_,
        addNewTag: addNewTag_,
      },
      dispatch
    )
);

export default connector(DeviceTagInput);
