import * as API from '../../api';
import { getUserContextData } from '../../api/util';
import * as actionTypes from './actionTypes';

export const clearReleaseFlags = () => ({
  type: actionTypes.CLEAR,
});

export const getReleaseFlags = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_RELEASEFLAGS_REQUEST,
  });

  return API.getReleaseFlags(getUserContextData(state))
    .then((result) => {
      dispatch({
        type: actionTypes.GET_RELEASEFLAGS_SUCCESS,
        flags: result,
      });
      return Promise.resolve(result);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_RELEASEFLAGS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getPublicReleaseFlags = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_PUBLIC_RELEASEFLAGS_REQUEST,
  });

  return API.getPublicReleaseFlags()
    .then((result) => {
      dispatch({
        type: actionTypes.GET_PUBLIC_RELEASEFLAGS_SUCCESS,
        flags: result,
      });

      return Promise.resolve(result);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_PUBLIC_RELEASEFLAGS_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export const getAllReleaseFlags = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GETALL_REQUEST,
  });

  return Promise.all([getReleaseFlags()(dispatch, state), getPublicReleaseFlags()(dispatch)])
    .then(() => {
      dispatch({
        type: actionTypes.GETALL_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GETALL_ERROR,
        error,
      });
    });
};
