import { useCallback } from 'react';
import useAccountSetting from './useAccountSetting';

// A special version of useAccountSetting that converts yes/no settings into plain booleans
const useYesNoAccountSetting = (accountSetting: string) => {
  const [setting, setSetting] = useAccountSetting(accountSetting);
  const updateSetting = useCallback(
    (value: boolean) => {
      setSetting(value ? 'yes' : 'no');
    },
    [setSetting]
  );
  return [setting === 'yes', updateSetting] as const;
};

export default useYesNoAccountSetting;
