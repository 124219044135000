import { Button } from '@hologram-dimension/button';
import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import { ModalHeader } from 'common-js/components';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';
import React from 'react';

const OverLimitModal = ({
  title,
  icon,
  analyticsEvent,
  onClose,
  messageBodyVerb,
  requestType = true,
}) => {
  const sendRequestAnalytics = () => {
    analyticsEventBuilder.buttonClick('Bulk Action', '10k Modal', 'Submit').send();
  };

  const handleCancelClick = () => {
    analyticsEventBuilder.buttonClick('Bulk Action', '10k Modal', 'Cancel').send();
    onClose();
  };

  const handleBackToDashboard = () => {
    analyticsEventBuilder.buttonClick('Bulk Action', '10k Modal', 'Back to dashboard').send();
    onClose();
  };

  return (
    <>
      <ModalHeader title={title} icon={icon} analyticsEvent={analyticsEvent} />

      <div
        className={applyConditionalClassNames({
          BulkDataActionModal__contents: true,
          'BulkDataActionModal__10k-limit': true,
          'BulkDataActionModal__10k-limit__request-modal-contents': requestType,
        })}
      >
        <div className="BulkDataActionModal__10k-limit__heading">
          This action is limited to 10,000 SIMs.
        </div>

        {requestType ? (
          <div className="BulkDataActionModal__10k-limit__detail BulkDataActionModal__10k-limit__request-modal-detail">
            To {messageBodyVerb}, select fewer SIMs or submit a SIM support request. Your Customer
            Success Manager will be in touch within the next two business days to help complete this
            action.
          </div>
        ) : (
          <div className="BulkDataActionModal__10k-limit__detail">
            To {messageBodyVerb}, select fewer SIMs or contact support@hologram.io.
          </div>
        )}
      </div>

      <div className="BulkDataActionModal__footer">
        {requestType ? (
          <>
            <Button onClick={handleCancelClick} variant="secondary">
              Cancel
            </Button>
            <Button
              href="https://support.hologram.io/hc/en-us/requests/new"
              onClick={sendRequestAnalytics}
              target="_blank"
            >
              Submit request
            </Button>
          </>
        ) : (
          <Button onClick={handleBackToDashboard} variant="secondary">
            Close
          </Button>
        )}
      </div>
    </>
  );
};

export default OverLimitModal;
