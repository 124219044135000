import { getCompletedTasks } from './getCompletedTasks';
import * as actionTypes from '../actionTypes';

export const setCurrentTasksPage = (page) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_TASKS_PAGE,
    page,
  });

  dispatch(getCompletedTasks());

  return Promise.resolve();
};
