import { Button, Panel } from '@holokit/core';
import analyticsEventBuilder from 'common-js/analytics';
import { HoloHelmet } from 'common-js/components';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import { FC, useCallback, useEffect } from 'react';
import ConfigureWebhookForm from './ConfigureWebhookForm';
import useGetCurrentDeviceRouterCreds from './useGetCurrentDeviceRouterCreds';
import useGetCurrentDeviceWebhook from './useGetCurrentDeviceWebhook';

const Webhooks: FC = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  // Even though the ConfigureWebhookForm needs the webhook and the RouterCredsModal needs the router creds,
  // we load these in the parent container.  This helps with aggregating loading state for a better UX.
  // It also means the router creds have already been loaded before the user opens the modal.
  const [webhook, setWebhook, webhookLoading] = useGetCurrentDeviceWebhook();
  const [routerCreds, setRouterCreds, routerCredsLoading] = useGetCurrentDeviceRouterCreds();

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Single Device', 'Webhooks').send();
  }, []);

  const openRouterCredsModal = useCallback(() => {
    dispatch(
      openModal('RouterCredsModal', {
        routerCreds,
        setRouterCreds,
        deviceId: device.id,
      })
    );
  }, [device.id, dispatch, routerCreds, setRouterCreds]);

  return (
    <div className="page-device-spacebridge full-height">
      <HoloHelmet title={`${device.name} - Webhooks`} />
      <Panel title="Configure Inbound Webhook" noBodyPadding isLoading={webhookLoading}>
        {!webhookLoading && (
          <div className="webhook">
            <div className="section-row">
              <ConfigureWebhookForm setWebhook={setWebhook} webhook={webhook} />
            </div>
          </div>
        )}
      </Panel>
      <Panel title="Data Engine" isLoading={routerCredsLoading}>
        <div className="data-engine">
          <div className="data-engine__label">Device Key</div>
          <Button onClick={openRouterCredsModal} type="secondary">
            Show Device Key
          </Button>
        </div>
      </Panel>
    </div>
  );
};

export default Webhooks;
