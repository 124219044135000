import submitDashboardMarketoForm from 'common-js/utils/marketing';
import { useFormContext } from 'react-hook-form';
import { IconCard } from '../../components';

function Step4(props) {
  const { handleAnalyticsCall, nextStep } = props;
  const { setValue, getValues } = useFormContext();

  const handleClick = (countriesNeedingCoverage) => () => {
    setValue('countriesNeedingCoverage', countriesNeedingCoverage);
    handleAnalyticsCall('countriesNeedingCoverage', countriesNeedingCoverage);
    submitDashboardMarketoForm(getValues());
    nextStep();
  };

  return (
    <div className="Step NewForm">
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__Header">
            <h2>Where do you need coverage?</h2>
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__SubHeader">
            Please select a region that best represents your needs
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q4_united-states.svg"
            onClick={handleClick('United States')}
            mainText="United States"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q4_global.svg"
            onClick={handleClick('Global')}
            mainText="Global"
          />
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q4_north-america.svg"
            onClick={handleClick('North America')}
            mainText="North America"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q4_south-america.svg"
            onClick={handleClick('South America')}
            mainText="South America"
          />
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q4_europe.svg"
            onClick={handleClick('Europe')}
            mainText="Europe"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q4_asia.svg"
            onClick={handleClick('Asia')}
            mainText="Asia"
          />
        </div>
      </div>
      <div className="NewForm__BottomRow">
        <div className="NewForm__DoubleColumn">
          <IconCard mainText="Other region" onClick={handleClick()} />
        </div>
      </div>
    </div>
  );
}

export default Step4;
