import queryString from 'query-string';
import { clearFilterInputs } from './actions';
import { loadCategoryPage } from './loadCategoryPage';
import * as actionTypes from '../actionTypes';

export const clearFilters = (stateSegment) => (dispatch) => {
  dispatch({ type: actionTypes.RESET_FILTERS });
  dispatch(clearFilterInputs());
  const querystring = queryString.parse(window.location.search);
  dispatch(loadCategoryPage(stateSegment, querystring));
};

export default clearFilters;
