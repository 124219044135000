import React from 'react';

interface SectionProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

const Section = ({ title, children }: SectionProps) => (
  <div className="BulkDataActionModal__section">
    <div className="BulkDataActionModal__section__heading">{title}</div>
    <div className="BulkDataActionModal__section__contents">{children}</div>
  </div>
);

export default Section;
