const ZONE_TRANSLATIONS = {
  1: 'Zone 1',
  2: 'Zone 2',
  USA: 'United States',
  usaall: 'United States (all major carriers)',
  global: 'Global',
  globalexclca: 'Global (except Canada)',
} as const;

export default ZONE_TRANSLATIONS;
