import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { supportMessage } from 'common-js/reducers/analytics/actions';
import getErrorMessage, { DashboardError } from 'common-js/utils/getErrorMessage';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Should be: ConnectedProps<typeof connector>;
type PropsFromRedux = {
  supportMessage_: typeof supportMessage;
};

type Props = PropsFromRedux & {
  error: DashboardError;
};

const PageLoadingErrorScreen = (props: Props) => {
  const { error, supportMessage_ } = props;
  const errorMessage = getErrorMessage(error);

  return (
    <div className="empty-state empty-state-large grid-row vertical-align">
      <div className="grid-item">
        <div className="text-centered section section-large message">
          <div className="section">
            <Icon
              className="empty-state-icon"
              name="Warning"
              size="large"
              fill="DdsColorPaletteRed33"
            />
            <div className="f2">
              We&apos;re sorry, there was an issue loading this page!
              <br />
              <Button onClick={() => window.location.reload()} iconStart="Sync">
                Refresh
              </Button>
            </div>
          </div>
          {error && (
            <div className="section">
              Error details:
              <pre>{errorMessage}</pre>
            </div>
          )}
          <Button
            iconStart="CircleAlert"
            onClick={() =>
              supportMessage_(
                `I'm getting an error on the "${window.location.pathname}" page that says: ${error}`,
                'Page error encountered'
              )
            }
            variant="secondary"
          >
            Tell us about the issue...
          </Button>
        </div>
      </div>
    </div>
  );
};

const connector = connect(null, (dispatch: any) =>
  bindActionCreators(
    {
      supportMessage_: supportMessage,
    },
    dispatch
  )
);

export default connector(PageLoadingErrorScreen);
