export default class BalanceModel {
  currentBalance = 0;

  minBalance = 0;

  pendingCharges = 0;

  promoBalance = 0;

  topOffAmount = 0;

  availableBalance = 0;

  hasPostpay = false;

  isHydrated = false;

  constructor(data?: {
    balance: string;
    minbalance: string;
    pendingcharges: string;
    promobalance: string;
    topoffamount: string;
    has_postpay: number;
  }) {
    if (!data) {
      return;
    }

    this.currentBalance = parseFloat(data.balance);
    this.minBalance = parseFloat(data.minbalance);
    this.pendingCharges = parseFloat(data.pendingcharges);
    this.promoBalance = parseFloat(data.promobalance);
    this.topOffAmount = parseFloat(data.topoffamount);
    this.hasPostpay = data.has_postpay === 1;
    this.availableBalance = this.currentBalance + this.promoBalance + this.pendingCharges;

    this.isHydrated = true;
  }
}
