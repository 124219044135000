import { Icon } from '@holokit/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { AutoComplete } from '.';

class SearchAutoComplete extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { value, onChange, showClearButton = true, topics } = this.props;

    return (
      <div
        className="SearchButton autocomplete"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="grid-row vertical-align">
          <Icon name="Search" size="major" svgProps={{ style: { fill: '#8008f7' } }} />
          <AutoComplete
            suggestions={topics.map((suggestion) => ({ name: suggestion }))}
            onChange={(value, source) => {
              onChange(value, source);
            }}
            value={value}
            allowNew
            allowDuplicates
            autofocus={false}
            autoresize
            placeholder="Search by topics published"
          />
          {showClearButton && (
            <button classes="Button--unstyled" onClick={() => onChange('')}>
              <Icon name="Close" size="micro" svgProps={{ style: { fill: '#8008f7' } }} />
            </button>
          )}
        </div>
      </div>
    );
  }
}

SearchAutoComplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  topics: PropTypes.array,
};

SearchAutoComplete.defaultProps = {
  value: '',
  onChange: _.noop,
  topics: [],
};

export default SearchAutoComplete;
