import _ from 'lodash';
import Moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { Button, Icon } from '@holokit/core';
import { ModalHeader, DropdownSelect, Money, Bytes } from '../components';
import * as Paths from '../constants/paths';
import * as modalActions from '../reducers/modal/actions';
import * as poolsActions from '../reducers/pools/actions';
import { getUserContextData } from '../api/util';

const SCHEDULE_TODAY = 'SCHEDULE_TODAY';
const SCHEDULE_MONTH = 'SCHEDULE_MONTH';
const NO_BILLING_ERROR = 'NO_BILLING_ERROR';

class SetupDataPoolModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIdx: props.changing
        ? _.findIndex(this.getOptions(), { id: props.currentPool.poolplanid })
        : 0,
      selectedSchedule: props.changing ? SCHEDULE_MONTH : SCHEDULE_TODAY,
      isProcessing: false,
      serverError: null,
    };
  }

  onSelect(newIdx) {
    this.setState({ selectedIdx: newIdx });
  }

  onUpdateSumbit() {
    const { selectedIdx } = this.state;
    const { renewPool, changePoolSize, getAllPoolInfo, currentPool, closeModal, renewing } =
      this.props;
    const options = this.getOptions();

    this.setState({ serverError: null, isProcessing: true });
    (renewing
      ? renewPool(currentPool.id, options[selectedIdx].id)
      : changePoolSize(currentPool.id, options[selectedIdx].id)
    )
      .then(() => getAllPoolInfo(currentPool.id))
      .then(() => closeModal())
      .catch((e) => this.setState({ serverError: e, isProcessing: false }));
  }

  getOptions() {
    const { poolPlans } = this.props;

    return poolPlans
      .sort((p, n) => (p.data > n.data ? 1 : -1))
      .map((poolPlan) => ({
        id: poolPlan.id,
        data: poolPlan.data,
        price: parseFloat(poolPlan.amount),
      }));
  }

  onSubscribeSubmit = () => {
    const { selectedIdx, selectedSchedule } = this.state;
    const { subscribeToPool, getAllPoolInfo, closeModal, subscribeToPendingPool } = this.props;
    const options = this.getOptions();

    this.setState({ serverError: null, isProcessing: true });

    if (selectedSchedule === SCHEDULE_TODAY) {
      subscribeToPool(options[selectedIdx].id)
        .then((pool) => getAllPoolInfo(pool.id))
        .then(() => closeModal())
        .catch((e) => {
          if (e.includes('Please add a payment method')) {
            this.setState({
              serverError: NO_BILLING_ERROR,
              isProcessing: false,
            });
          } else {
            this.setState({ serverError: e, isProcessing: false });
          }
        });
    } else if (selectedSchedule === SCHEDULE_MONTH) {
      subscribeToPendingPool(options[selectedIdx].id)
        .then((pool) => getAllPoolInfo(pool.id))
        .then(() => closeModal())
        .catch((e) => {
          if (e.includes('Please add a payment method')) {
            this.setState({
              serverError: NO_BILLING_ERROR,
              isProcessing: false,
            });
          } else {
            this.setState({ serverError: e, isProcessing: false });
          }
        });
    }
  };

  canSubmit() {
    const { selectedIdx } = this.state;
    const { changing, renewing, currentPool, creditCard } = this.props;
    const options = this.getOptions();

    let canSubmit = true;

    if (
      !creditCard.hasHydrated ||
      (changing && !renewing && options[selectedIdx].id === currentPool.poolplanid)
    )
      canSubmit = false;

    return canSubmit;
  }

  render() {
    const { closeModal, deviceCount, changing = false, renewing = false, creditCard } = this.props;
    const { selectedIdx, serverError, isProcessing, selectedSchedule } = this.state;
    const options = this.getOptions();
    const selectedOption = options[selectedIdx];

    return (
      <div className="SetupDataPoolModal">
        <ModalHeader title="Setup data pool" />
        <p>
          Select the size of pool to enable for your organization. This pool is reset and billed on
          the first of every month.
        </p>
        <p>Monthly active device fees billed at scaled rates for each device separately.</p>
        <div className="message message-success full-width align-left section section-med">
          <div className="message-panel">
            <div className="message-icon">
              <Icon name="Circle--info" size="minor" svgProps={{ style: { fill: '#8008f7' } }} />
            </div>
            <div className="message-text">
              You have {deviceCount} device
              {deviceCount > 1 || deviceCount === 0 ? 's' : ''}.
            </div>
          </div>
        </div>
        <div className="grid-row section section-med">
          <div className="grid-item">
            <DropdownSelect
              selectedIdx={selectedIdx}
              onSelect={(newIdx) => this.onSelect(newIdx)}
              options={options
                .map((option, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={idx} className="option">
                    <div className="inner-option">
                      <div className="grid-item grid-row grid-column">
                        <div className="total">
                          <Bytes amount={option.data} />
                        </div>
                        <div className="per-device-average">
                          AVG <Bytes amount={parseInt(option.data / deviceCount, 10)} />
                          /DEVICE
                        </div>
                      </div>
                      <div>
                        <Money amount={option.price} showLongFloats />
                      </div>
                    </div>
                  </div>
                ))
                .concat([
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    className="help-option text-centered"
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    Need more?{' '}
                    <a target="_blank" href="https://hologram.io/contact-sales/" rel="noreferrer">
                      Contact sales.
                    </a>
                  </div>,
                ])}
            />
          </div>
          <div className="schedule-date grid-item grid-row grid-column h-align">
            <div className="schedule-option">
              {!changing && (
                <label htmlFor="start-today">
                  <input
                    type="radio"
                    name="startDate"
                    id="start-today"
                    value={SCHEDULE_TODAY}
                    onChange={() => this.setState({ selectedSchedule: SCHEDULE_TODAY })}
                    checked={selectedSchedule === SCHEDULE_TODAY}
                  />
                  Start today
                </label>
              )}
            </div>
            <div className="schedule-option">
              <label htmlFor="start-month">
                <input
                  type="radio"
                  name="startDate"
                  id="start-month"
                  disabled={changing}
                  value={SCHEDULE_MONTH}
                  onChange={() => this.setState({ selectedSchedule: SCHEDULE_MONTH })}
                  checked={selectedSchedule === SCHEDULE_MONTH}
                />
                Schedule for {Moment().endOf('month').add(1, 'days').format('MMM. D')}
              </label>
            </div>
          </div>
        </div>
        <div className="grid-row section">
          <div className="grid-item-2" />
          <div className="grid-item-3 overview grid-row">
            <div className="grid-item grid-row grid-column">
              {selectedSchedule === SCHEDULE_MONTH ? (
                <div className="total-due-label">
                  Total due {Moment().endOf('month').add(1, 'days').format('MMM. D')}
                </div>
              ) : (
                <div className="total-due-label">Total due today</div>
              )}
              <div className="visa-preview">
                {creditCard.issuer}-{creditCard.shortNumber}
              </div>
            </div>
            <div className="total-due-label">
              <Money showLongFloats amount={selectedOption?.price} />
            </div>
          </div>
        </div>
        {!creditCard.hasHydrated && (
          <div className="message message-error full-width align-left section section-med">
            <div className="message-panel">
              <div className="message-icon">
                <Icon name="Circle--info" size="minor" svgProps={{ style: { fill: '#8008f7' } }} />
              </div>
              <div className="message-text">
                Please add a payment method on the{' '}
                <Link to={Paths.BILLING_ACCOUNT} onClick={() => closeModal()}>
                  billing page
                </Link>{' '}
                activating a pool plan.
              </div>
            </div>
          </div>
        )}
        {serverError && (
          <div className="message message-error full-width align-left section section-med">
            <div className="message-panel">
              <div className="message-icon">
                <Icon name="Circle--info" size="minor" svgProps={{ style: { fill: '#8008f7' } }} />
              </div>
              <div className="message-text">{serverError}</div>
            </div>
          </div>
        )}
        {changing ? (
          <div className="grid-row">
            <div className="grid-item" />
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="cancel-button"
                onClick={() => closeModal()}
                onKeyUp={() => closeModal()}
                role="button"
                tabIndex={0}
              >
                Cancel
              </a>
              {!renewing ? (
                <Button
                  disabled={isProcessing || !this.canSubmit()}
                  onClick={() => this.onUpdateSumbit()}
                  type="primary"
                >
                  {isProcessing ? 'Changing...' : 'Change pool size'}
                </Button>
              ) : (
                <Button
                  disabled={isProcessing || !this.canSubmit()}
                  onClick={() => this.onUpdateSumbit()}
                  type="primary"
                >
                  {isProcessing ? 'Subscribing...' : 'Subscribe to pool'}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="grid-row">
            <div className="grid-item" />
            <div>
              <Button onClick={closeModal} type="secondary">
                Cancel
              </Button>
              <Button
                disabled={isProcessing || !this.canSubmit()}
                onClick={this.onSubscribeSubmit}
                type="primary"
              >
                {isProcessing ? 'Subscribing...' : 'Subscribe to pool'}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    poolPlans: state.pools.poolPlans,
    deviceCount: state.organization.deviceCount,
    creditCard: state.organization.billingInformation.creditCard,
    currentPool: state.pools.currentPool,
    inOrg: getUserContextData(state).isInOrgContext,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        closeModal: modalActions.close,
        subscribeToPool: poolsActions.subscribeToPool,
        subscribeToPendingPool: poolsActions.subscribeToPendingPool,
        renewPool: poolsActions.renewPool,
        changePoolSize: poolsActions.changePoolSize,
        getAllPoolInfo: poolsActions.getAllPoolInfo,
      },
      dispatch
    )
)(SetupDataPoolModal);
