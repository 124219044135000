import useBulkDeviceStateChange from 'common-js/api/devices/useBulkDeviceStateChange';
import {
  BULK_DEACTIVATE_DEVICES_PREVIEW_ERROR,
  BULK_DEACTIVATE_DEVICES_PREVIEW_REQUEST,
  BULK_DEACTIVATE_DEVICES_PREVIEW_SUCCESS,
  PAUSE_DATA_BULK_PREVIEW_ERROR,
  PAUSE_DATA_BULK_PREVIEW_REQUEST,
  PAUSE_DATA_BULK_PREVIEW_SUCCESS,
  RESUME_DATA_BULK_PREVIEW_ERROR,
  RESUME_DATA_BULK_PREVIEW_REQUEST,
  RESUME_DATA_BULK_PREVIEW_SUCCESS,
} from 'common-js/reducers/devices/actionTypes';
import { selectHasBizLayerPauseResumeFlag } from 'common-js/reducers/releaseFlag/selectors';
import {
  BulkChangeStatePreviewError,
  BulkChangeStatePreviewSummary,
  DeviceFilters,
  DeviceSelection,
  DeviceStateChangeAllowedStates,
} from 'common-js/types/Device';
import { useCallback, useMemo } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';

interface UseUseBulkChangeStatePreviewEventProps {
  selection: DeviceSelection;
  filters?: DeviceFilters;
  useSearch: boolean;
  orgId: OrgId;
  newState: DeviceStateChangeAllowedStates;
}

const useBulkChangeStatePreviewEvent = ({
  selection,
  filters,
  useSearch,
  orgId,
  newState,
}: UseUseBulkChangeStatePreviewEventProps) => {
  const dispatch = useAppDispatch();
  const useBizLayer: boolean = useAppSelector(selectHasBizLayerPauseResumeFlag);
  const pauseDataBulkApiCall = useBulkDeviceStateChange({
    newState,
    preview: true,
    useBusinessLayer: useBizLayer,
    orgId,
  });

  const { requestType, successType, errorType } = useMemo(() => {
    switch (newState) {
      case 'live':
        return {
          requestType: RESUME_DATA_BULK_PREVIEW_REQUEST,
          successType: RESUME_DATA_BULK_PREVIEW_SUCCESS,
          errorType: RESUME_DATA_BULK_PREVIEW_ERROR,
        };
      case 'pause':
        return {
          requestType: PAUSE_DATA_BULK_PREVIEW_REQUEST,
          successType: PAUSE_DATA_BULK_PREVIEW_SUCCESS,
          errorType: PAUSE_DATA_BULK_PREVIEW_ERROR,
        };
      case 'deactivate':
        return {
          requestType: BULK_DEACTIVATE_DEVICES_PREVIEW_REQUEST,
          successType: BULK_DEACTIVATE_DEVICES_PREVIEW_SUCCESS,
          errorType: BULK_DEACTIVATE_DEVICES_PREVIEW_ERROR,
        };
      default:
        throw new Error(`Unsupported state: ${newState}`);
    }
  }, [newState]);

  return useCallback(async () => {
    dispatch({
      type: requestType,
    });

    try {
      const data = await pauseDataBulkApiCall({
        selection,
        filters,
        useSearch,
      });

      const successfulDeviceIds = (data?.valid_tasks ?? []).reduce<Array<DeviceId>>(
        (accDeviceIds, currentTaskData) => [
          ...accDeviceIds,
          ...currentTaskData.task_results.devices_modified,
        ],
        []
      );

      const successfulButWarned = (data?.valid_tasks ?? []).reduce<Array<DeviceId>>(
        (accDeviceWarnings, currentTaskData) => [
          ...accDeviceWarnings,
          ...currentTaskData.task_results.warnings.reduce<Array<DeviceId>>(
            (accWarnDeviceIds, currentWarning) => [
              ...new Set([...accWarnDeviceIds, currentWarning.deviceid]),
            ],
            []
          ),
        ],
        []
      );

      const deviceIdsWontBeModified = successfulButWarned.filter(
        (warnedId) => !successfulDeviceIds.find((succesfulId) => succesfulId === warnedId)
      );

      const invalidTaskDeviceIds = (data?.invalid_tasks ?? []).reduce<Array<DeviceId>>(
        (accDeviceIds, currentTaskData) => [
          ...accDeviceIds,
          ...currentTaskData.task_results.devices_modified,
        ],
        []
      );
      const allInvalidDeviceIds = [
        ...new Set([...deviceIdsWontBeModified, ...invalidTaskDeviceIds]),
      ];

      const bulkPreview: BulkChangeStatePreviewSummary = {
        validDeviceCount: successfulDeviceIds.length,
        validDevicesSample: successfulDeviceIds.slice(0, 9),
        invalidDeviceCount: allInvalidDeviceIds.length,
        invalidDevicesSample: allInvalidDeviceIds.slice(0, 9),
      };

      dispatch({
        type: successType,
        loadingKey: requestType,
        data: bulkPreview,
      });

      return { data, deviceIdsWontBeModified, error: null };
    } catch (error) {
      const errorObj: BulkChangeStatePreviewError = error as any;
      dispatch({
        type: errorType,
        loadingKey: requestType,
        error: errorObj,
      });

      return { data: null, deviceIdsWontBeModified: [], error: errorObj };
    }
  }, [
    dispatch,
    errorType,
    filters,
    pauseDataBulkApiCall,
    requestType,
    selection,
    successType,
    useSearch,
  ]);
};

export default useBulkChangeStatePreviewEvent;
