const PREFIX = 'ALERTS_';

export const ACKNOWLEDGE_ALL_ALERTS_REQUEST = `${PREFIX}ACKNOWLEDGE_ALL_ALERTS_REQUEST`;
export const ACKNOWLEDGE_ALL_ALERTS_SUCCESS = `${PREFIX}ACKNOWLEDGE_ALL_ALERTS_SUCCESS`;
export const ACKNOWLEDGE_ALL_ALERTS_ERROR = `${PREFIX}ACKNOWLEDGE_ALL_ALERTS_ERROR`;

export const ACKNOWLEDGE_ALERTS_REQUEST = `${PREFIX}ACKNOWLEDGE_ALERTS_REQUEST`;
export const ACKNOWLEDGE_ALERTS_SUCCESS = `${PREFIX}ACKNOWLEDGE_ALERTS_SUCCESS`;
export const ACKNOWLEDGE_ALERTS_ERROR = `${PREFIX}ACKNOWLEDGE_ALERTS_ERROR`;

export const ACKNOWLEDGE_ALERT_REQUEST = `${PREFIX}ACKNOWLEDGE_ALERT_REQUEST`;
export const ACKNOWLEDGE_ALERT_SUCCESS = `${PREFIX}ACKNOWLEDGE_ALERT_SUCCESS`;
export const ACKNOWLEDGE_ALERT_ERROR = `${PREFIX}ACKNOWLEDGE_ALERT_ERROR`;

export const UNACKNOWLEDGE_ALERT_REQUEST = `${PREFIX}UNACKNOWLEDGE_ALERT_REQUEST`;
export const UNACKNOWLEDGE_ALERT_SUCCESS = `${PREFIX}UNACKNOWLEDGE_ALERT_SUCCESS`;
export const UNACKNOWLEDGE_ALERT_ERROR = `${PREFIX}UNACKNOWLEDGE_ALERT_ERROR`;

export const SET_ACTIVE_ALERT = `${PREFIX}SET_ACTIVE_ALERT`;
export const SET_ACTIVE_PIN = `${PREFIX}SET_ACTIVE_PIN`;

export const PAUSE_ALERT_DEVICES_REQUEST = `${PREFIX}PAUSE_ALERT_DEVICES_REQUEST`;
export const PAUSE_ALERT_DEVICES_SUCCESS = `${PREFIX}PAUSE_ALERT_DEVICES_SUCCESS`;
export const PAUSE_ALERT_DEVICES_ERROR = `${PREFIX}PAUSE_ALERT_DEVICES_ERROR`;

export const RESUME_ALERT_DEVICE_REQUEST = `${PREFIX}RESUME_ALERT_DEVICE_REQUEST`;
export const RESUME_ALERT_DEVICE_SUCCESS = `${PREFIX}RESUME_ALERT_DEVICE_SUCCESS`;
export const RESUME_ALERT_DEVICE_ERROR = `${PREFIX}RESUME_ALERT_DEVICE_ERROR`;

export const RESUME_ALERT_BULK_REQUEST = `${PREFIX}RESUME_ALERT_BULK_REQUEST`;
export const RESUME_ALERT_BULK_SUCCESS = `${PREFIX}RESUME_ALERT_BULK_SUCCESS`;
export const RESUME_ALERT_BULK_ERROR = `${PREFIX}RESUME_ALERT_BULK_ERROR`;

export const DEACTIVATE_ALERT_DEVICES_REQUEST = `${PREFIX}DEACTIVATE_ALERT_DEVICES_REQUEST`;
export const DEACTIVATE_ALERT_DEVICES_SUCCESS = `${PREFIX}DEACTIVATE_ALERT_DEVICES_SUCCESS`;
export const DEACTIVATE_ALERT_DEVICES_ERROR = `${PREFIX}DEACTIVATE_ALERT_DEVICES_ERROR`;

export const GET_ALERTS_BY_TYPE_REQUEST = `${PREFIX}GET_ALERTS_BY_TYPE_REQUEST`;
export const GET_ALERTS_BY_TYPE_SUCCESS = `${PREFIX}GET_ALERTS_BY_TYPE_SUCCESS`;
export const GET_ALERTS_BY_TYPE_ERROR = `${PREFIX}GET_ALERTS_BY_TYPE_ERROR`;

export const GET_ALERT_COUNT_BY_TYPE_REQUEST = `${PREFIX}GET_ALERT_COUNT_BY_TYPE_REQUEST`;
export const GET_ALERT_COUNT_BY_TYPE_SUCCESS = `${PREFIX}GET_ALERT_COUNT_BY_TYPE_SUCCESS`;
export const GET_ALERT_COUNT_BY_TYPE_ERROR = `${PREFIX}GET_ALERT_COUNT_BY_TYPE_ERROR`;

export const CLEAR_ACKNOWLEDGED_ALERTS = `${PREFIX}CLEAR_ACKNOWLEDGED_ALERTS`;
export const CLEAR_ALERTS_BY_TYPE = `${PREFIX}CLEAR_ALERTS_BY_TYPE`;
