import { createSelector } from 'reselect';

export const selectAccount = createSelector(
  (state: any) => state.account,
  (account) => account
);

export const selectAccountRole = createSelector(selectAccount, (account) => account.role);

export const selectIsSuperAdmin = createSelector(
  selectAccountRole,
  (role) => role === 'adm' || role === 'hdm'
);

export const selectFirstName = createSelector(selectAccount, (account) => {
  let name = account.email;

  if (account.firstName !== '') {
    name = account.firstName;
  } else if (account.lastName !== '') {
    name = account.lastName;
  } else {
    return '';
  }

  return name;
});

export const selectName = createSelector(selectAccount, (account) => {
  let name = account.email;

  if (account.firstName !== '') {
    name = account.firstName;

    if (account.lastName !== '') {
      name += ` ${account.lastName}`;
    }
  } else if (account.lastName !== '') {
    name = account.lastName;
  }

  return name;
});

export const selectAccountPlans = createSelector(selectAccount, (account) => account.plans);

export const selectUserSettings = createSelector(selectAccount, (account) => account.settings);

export const selectUserIsInternal = createSelector(selectAccount, (account) => {
  const impersonatorRole = account?.impersonator?.role;
  const roleList = ['adm', 'hdm'];

  return roleList.includes(impersonatorRole) || roleList.includes(account?.role);
});

export const selectShowDatalimitsEduBig = createSelector(
  selectAccount,
  (account) => account.settings.edu_sdp_dlimit_big_show === 'yes'
);

export const selectUserEmail = createSelector(selectAccount, (account) => account?.email);

export const selectImpersonator = createSelector(selectAccount, (account) => account.impersonator);

export const selectUiState = createSelector(selectAccount, (account) => account?.uiState);

export const selectDismissedAlerts = createSelector(
  selectUiState,
  (uiState) => uiState?.dismissedAlerts
);
