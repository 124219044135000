import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const removeFilter = (filterKey) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_FILTER,
    filterKey,
  });

  dispatch({
    type: actionTypes.RESET_PAGING,
  });

  dispatch(fetchDevices());
};
