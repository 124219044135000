import _classNames from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class StellarInput extends React.PureComponent {
  render() {
    const {
      autoFocus = false,
      disabled = false,
      classNames,
      error = null,
      fullWidth,
      hideFromHotjar = false,
      icon,
      inputClass = 'string',
      maxLength = null,
      name,
      onBlur = _.noop(),
      placeholder = '',
      showValidation = true,
      subText = '',
      tabIndex = '',
      type = 'string',
      value,
    } = this.props;

    const inputAttributes = {
      name,
      value,
      onBlur,
      disabled,
      autoFocus,
      tabIndex,
      placeholder,
      maxLength,
      checked: value === true || null,
      onChange: this.onChange,
      className: error ? 'error' : null,
    };

    if (hideFromHotjar) inputAttributes['data-hj-masked'] = true;
    const containerClassnames = _classNames('st-input-container', classNames, type, {
      disabled,
      'has-icon': icon,
      'full-width': fullWidth,
    });

    return (
      <div className={containerClassnames}>
        <div>
          <input type={type} className={inputClass} {...inputAttributes} />
        </div>
        {subText && <div className="inline-info">{subText}</div>}
        {showValidation && error && <div className="inline-error">{error}</div>}
      </div>
    );
  }

  onChange = (e) => {
    this.props.onChange(e);
    this.props.onChangeProxy(e);
  };
}

StellarInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeProxy: PropTypes.func,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.string,
  showValidation: PropTypes.bool,
  autoFocus: PropTypes.bool,
  classNames: PropTypes.string,
  placeholder: PropTypes.string,
};

StellarInput.defaultProps = {
  classNames: '',
  onChangeProxy: _.noop,
  type: 'text',
};

export default StellarInput;
