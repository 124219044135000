import ZONE_TRANSLATIONS from 'common-js/constants/zoneTranslations';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectPlanData } from 'common-js/reducers/activation/selectors';
import type { Device } from 'common-js/types/Device';
import type { Plan } from 'common-js/types/Plan';
import { useFormContext } from 'react-hook-form';
import CompletedStepHeader from '../common/scaffolding/CompletedStepHeader';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';
import SidebarAttribute from '../common/scaffolding/SidebarAttribute';

const STEP_NAME = 'Select data plan';

const CompletedPlanSidebarItem = ({ hasCustomPlans }) => {
  const plan = useFormContext().getValues('plan');
  const { displayName, planName, zone, cellularCoverageRegion } = plan;

  return (
    <CompletedStepHeader name={STEP_NAME}>
      <SidebarAttribute name="Name" value={displayName ?? planName} />
      {hasCustomPlans && (
        <SidebarAttribute
          name="Coverage"
          value={cellularCoverageRegion?.display_name ?? ZONE_TRANSLATIONS[zone] ?? zone}
        />
      )}
    </CompletedStepHeader>
  );
};

interface PlanSidebarItemProps extends SidebarItemProps {}
const PlanSidebarItem = ({
  step,
  currentStep,
  hasCustomPlans,
  changingPlan,
}: PlanSidebarItemProps) => {
  const devices: Array<Device> = useAppSelector((state) => state.activation?.changePlan?.devices);
  const existingPlanId = (changingPlan && devices && devices[0] && devices[0].planId) || undefined;
  const existingPlan: Plan = useAppSelector((state) => selectPlanData(state, existingPlanId));

  if (currentStep > step) return <CompletedPlanSidebarItem hasCustomPlans={hasCustomPlans} />;

  return (
    <IncompleteStepHeader step={step} currentStep={currentStep} name={STEP_NAME}>
      {changingPlan && (
        <SidebarAttribute name="Current data plan" value={existingPlan.planName ?? ''} />
      )}
    </IncompleteStepHeader>
  );
};

export default PlanSidebarItem;
