import * as devicesAPI from 'common-js/api/devices';
import * as actionTypes from '../actionTypes';

export const getSimsFromCSV = (csvFile) => (dispatch) => {
  dispatch({
    type: actionTypes.GET_SIMS_FROM_CSV_REQUEST,
  });

  return devicesAPI
    .getSimsFromCSV(csvFile)
    .then((simNumbers) => {
      dispatch({
        type: actionTypes.GET_SIMS_FROM_CSV_SUCCESS,
        simNumbers,
      });
      return Promise.resolve(simNumbers);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_SIMS_FROM_CSV_ERROR,
        error,
      });
    });
};

export default getSimsFromCSV;
