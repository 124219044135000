import React, { useState } from 'react';
import submitDashboardMarketoForm from 'common-js/utils/marketing';
import { useFormContext } from 'react-hook-form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { IconCard } from '../../components';

function Step2(props) {
  const { handleAnalyticsCall, nextStep } = props;
  const {
    register,
    getFieldState,
    formState,
    formState: { errors },
    getValues,
    trigger,
  } = useFormContext();
  const [nextDisabled, setNextDisabled] = useState(true);

  const handleClick = async () => {
    const fieldValid = await trigger('devices');
    if (fieldValid) {
      const devices = getValues('devices');
      handleAnalyticsCall('deviceCount', devices);
      submitDashboardMarketoForm(getValues());
      nextStep();
    }
  };

  const validate = () => {
    trigger('devices').then(() => {
      const { isDirty, invalid } = getFieldState('devices', formState);
      if (isDirty && !invalid) {
        setNextDisabled(false);
      } else if (invalid) {
        setNextDisabled(true);
      }
    });
  };

  return (
    <div className="Step NewForm">
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__Header">
            <h2>How many devices will you be deploying?</h2>
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__SubHeader">
            Please tell us how many devices you expect to deploy in the next 6 months.
          </div>
        </div>
      </div>
      <div className="NewForm__RowLeftAligned">
        <TextInputField
          label="Number of devices"
          hiddenLabel
          type="number"
          required
          invalid={!!errors.devices}
          validationMessage={errors.devices?.message}
          className="NewForm__RowLeftAligned__DevicesInput"
          {...register('devices', {
            required: 'Number of devices must be a whole number greater than zero.',
            min: {
              value: 1,
              message: 'Number of devices must be a whole number greater than zero.',
            },
            pattern: {
              value: /^[1-9]+[0-9]*$/,
              message: 'Number of devices must be a whole number greater than zero.',
            },
            onChange: validate,
          })}
        />
      </div>
      <div className="NewForm__BottomRow">
        <div className="NewForm__DoubleColumn">
          <IconCard mainText="Next" onClick={handleClick} disabled={nextDisabled} />
        </div>
      </div>
    </div>
  );
}

export default Step2;
