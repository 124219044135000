import _ from 'lodash';

import updateUserSettings from './updateUserSettings';
import * as actionTypes from '../actionTypes';

export const setUserSetting = (keyValues) => (dispatch, state) => {
  const serializedKeyValues = _.isArray(keyValues) ? keyValues : [keyValues];

  dispatch({
    type: actionTypes.SET_USER_SETTING,
    keyValues: serializedKeyValues,
  });

  return updateUserSettings(state().account.settings)(dispatch, state);
};
