import {
  SimInventoryBulkUpdateStateModal as HyperBulkUpdateStateModal,
  OrganizationProvider,
} from '@hologram-hyper-dashboard/components';
import { getUserContextDataMemoized } from 'common-js/api/util';
import { ModalCommonProps } from 'common-js/components/Modal';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { fetchDevices, getTasks } from 'common-js/reducers/devices/actions';
import { TASK_START_DATE } from 'common-js/reducers/devices/reducer';
import { close } from 'common-js/reducers/modal/actions';
import { useCallback, type FC } from 'react';
import type { SimInventoryBulkStateAction } from 'types/hyper-dashboard';

interface SimInventoryBulkUpdateStateModalProps extends ModalCommonProps {
  sims: Set<HologramSimId>;
  action: SimInventoryBulkStateAction;
  onModalSuccess?: (sims: Array<HologramSimId>) => void;
}

const SimInventoryBulkUpdateStateModal: FC<SimInventoryBulkUpdateStateModalProps> = ({
  sims,
  action,
  onModalSuccess,
}) => {
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onSuccess = useCallback(
    (newSims: Array<HologramSimId>) => {
      // TODO: when we add our own task management UI to Hyper Dashboard, we should no longer update redux
      window.setTimeout(() => {
        dispatch(getTasks(TASK_START_DATE));
        dispatch(fetchDevices());
      }, 1000);

      if (onModalSuccess) {
        onModalSuccess(newSims);
      }
    },
    [dispatch, onModalSuccess]
  );

  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;

  return (
    <OrganizationProvider organizationId={orgId}>
      <HyperBulkUpdateStateModal
        onClose={onClose}
        onSuccess={onSuccess}
        className="HyperDashboardModal"
        defaultOpen
        sims={sims}
        action={action}
      />
    </OrganizationProvider>
  );
};
export default SimInventoryBulkUpdateStateModal;
