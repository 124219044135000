import { useEffect, useState } from 'react';

const useDebouncedShowError = (
  invalid: boolean,
  alwaysShowValidationErrors: boolean = false,
  debounceTime = 1000
): boolean => {
  const [showError, setShowError] = useState(false);

  // Disable because we want this to run every time, possibly resetting the existing timer
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!invalid) {
      setShowError(false);
      return undefined;
    }

    if (alwaysShowValidationErrors) {
      setShowError(true);
      return undefined;
    }

    const timeout = setTimeout(() => {
      setShowError(true);
    }, debounceTime);

    return () => clearTimeout(timeout);
  });

  return showError;
};

export default useDebouncedShowError;
