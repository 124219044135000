import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import hologramBlackLogoImg from 'img/logo/hologram_logo_black.svg';
import React, { ReactNode } from 'react';
import { browserHistory } from 'react-router';

interface LoggedOutTemplateProps {
  backLinkText?: string;
  backLinkUrl?: string;
  backLinkOnclick?: React.MouseEventHandler;
  children: ReactNode;
  className?: string;
}

const LoggedOutTemplate: React.FC<LoggedOutTemplateProps> = ({
  backLinkText,
  backLinkUrl,
  backLinkOnclick,
  children,
  className,
}) => (
  <div className={`logged-out-template${className ? ` ${className}` : ''}`}>
    {backLinkUrl && (
      <Button
        className="logged-out-template__back-link"
        iconStart={
          <Icon
            className="logged-out-template__back-link-icon"
            name="ChevronSingleWest"
            size="small"
          />
        }
        onClick={() => browserHistory.push(backLinkUrl)}
        variant="tertiary"
      >
        {backLinkText}
      </Button>
    )}
    {backLinkOnclick && (
      <Button
        name={backLinkText}
        iconStart={<Icon name="ChevronSingleWest" size="small" />}
        className="logged-out-template__back-link"
        onClick={backLinkOnclick}
        variant="tertiary"
      >
        {backLinkText}
      </Button>
    )}
    <div className="logged-out-template__logo">
      <img src={hologramBlackLogoImg} alt="Hologram logo" />
    </div>
    <div className="logged-out-template__content">{children}</div>
  </div>
);

export default LoggedOutTemplate;
