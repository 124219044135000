import { Button, Icon, Loader, Tooltip } from '@holokit/core';
import _classnames from 'clsx';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as alertTypes from '../../common/constants/alertTypes';
import { deviceStatusTooltipStrings } from '../../common/constants/deviceStatusTooltipStrings';
import * as alertActions from '../../common/reducers/alerts/actions';
import { createGetActiveAlertByType } from '../../common/reducers/alerts/selectors';
import { getDeviceStatusColor, getDeviceStatusText } from '../../common/utils/deviceStatus';
import scrollTo from '../../common/utils/dom/scrollTo';
import { toByteString } from '../../common/utils/numberFormatter';
import { getBillingPeriod } from '../../common/utils/time';
import AlertDrawerExpander from './AlertDrawerExpander';
import DeviceDataLimitAlertsDrawer from './DeviceDataLimitAlertsDrawer';

class DeviceDataLimitAlertsTableRow extends Component {
  constructor(props) {
    super(props);
    this.alertsRow = React.createRef();

    const { expires, whencreated } = props.alert;
    this.state = {
      billingPeriod: getBillingPeriod(whencreated, expires),
      isDrawerLoading: false,
      textHover: false,
    };
    this.preventUnfurl = false;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedOnResized);
  }

  onDrawerLoad = () => {
    const { alert, setActivePin } = this.props;

    this.setState({ isDrawerLoading: false }, () => {
      setTimeout(() => {
        setActivePin(alert.id, alertTypes.DEVICE_DATA_LIMIT);
      }, 200);
    });
  };

  onResize() {
    const { resizeAlertsTableHeader } = this.props;
    if (this.alertsRow.current !== null) {
      resizeAlertsTableHeader(this.alertsRow.current.offsetWidth);
    }
  }

  handleAcknowledgeAlert = (e) => {
    e.stopPropagation();
    const { alert, handleAcknowledgeSingleAlert } = this.props;
    handleAcknowledgeSingleAlert(alert.id);
  };

  handleUnacknowledgeAlert = (e) => {
    e.stopPropagation();
    const { alert, handleUnacknowledgeSingleAlert } = this.props;
    handleUnacknowledgeSingleAlert(alert.id);
  };

  handlePlanChange = (e) => {
    const { alert, handlePlanChange } = this.props;
    e.stopPropagation();
    handlePlanChange(alert.deviceid, 'Device Data Limit Alerts Table Page');
  };

  handleCheckboxSelection = () => {
    const { alert, deselectAlerts, selectAlerts, selectedAlerts } = this.props;
    if (selectedAlerts.includes(alert.id)) {
      deselectAlerts([alert.id]);
    } else {
      selectAlerts([alert.id]);
    }
  };

  handleChangeDataLimit = () => {
    const { alert, openDataLimitModal } = this.props;
    openDataLimitModal(alert.deviceid);
  };

  handleRowClick = (e) => {
    const { activeAlert, alert, setActiveAlert, setActivePin, theme } = this.props;

    if (this.preventUnfurl) {
      this.preventUnfurl = false;
    } else if (theme === 'multi-select') {
      this.selectRow(alert);
    } else {
      const isActive = activeAlert.id === alert.id;

      if (isActive) {
        setActiveAlert(null, alertTypes.DEVICE_DATA_LIMIT);
        setActivePin(null, alertTypes.DEVICE_DATA_LIMIT);
      } else {
        this.setState({ isDrawerLoading: true });
        this.focusOnAlert(alert.id);

        setTimeout(() => {
          setActiveAlert(alert.id, alertTypes.DEVICE_DATA_LIMIT);

          // In the case of the row being near the bottom of the table, the height won't be calculated properly
          // until the alert drawer is being revealed.  So call focus again to cover this case.  200 ms because that
          // is the length of the height animation.
          setTimeout(() => this.focusOnAlert(), 200);
        }, 200); // duration of the scrollto animation.
      }
    }

    e.stopPropagation();
  };

  focusOnAlert = () => {
    const { alert, scrollWrap } = this.props;
    const row = document.getElementById(`data-limit-alert-${alert.id}`);

    if (row) {
      const rowHeight = row.clientHeight || row.offsetHeight;
      if (rowHeight) {
        scrollTo(
          row.offsetTop + row.clientHeight - scrollWrap.current.offsetTop,
          () => {},
          200,
          scrollWrap.current
        );
      }
    }
  };

  selectRow = () => {
    const { alert, handleSingleSelection } = this.props;
    handleSingleSelection(alert.id);
  };

  handleTextMouseDown = () => {
    window.addEventListener('mouseup', this.handleRowTextDragMouseUp);
  };

  handleRowTextDragMouseUp = () => {
    this.preventUnfurl = true;
    window.removeEventListener('mouseup', this.handleRowTextDragMouseUp);
  };

  handleTextMouseEnter = () => {
    this.setState({ textHover: true });
  };

  handleTextMouseBlur = () => {
    this.setState({ textHover: false });
  };

  debouncedOnResized() {
    this.onResize();
  }

  render() {
    const { activeAlert, alert, colspan, releaseFlags, selectedAlerts, theme } = this.props;
    const {
      cur_billing_data_used: curBillingDataUsed,
      device_name: deviceName,
      overage_limit: overageLimit,
      plan_amount: planAmount,
      state,
      whencreated,
    } = alert;

    const { isDrawerLoading, billingPeriod, textHover } = this.state;

    const timestamp = moment(whencreated, 'YYYY-MM-DD HH:mm:ss');
    const dataEnds = moment(billingPeriod.end).utc();
    const daysRemaining = dataEnds.diff(moment(), 'days');
    const statusColor = getDeviceStatusColor(state, false, releaseFlags); // To Do: add lastSession.active data to alerts API
    const statusText = getDeviceStatusText(alert.state);
    const dataLimit = overageLimit + planAmount;
    const isMultiSelect = theme === 'multi-select';
    const isActive = activeAlert.id === alert.id;
    const dayDisplay = daysRemaining === 1 ? 'day' : 'days';

    return (
      <>
        <tr>
          <td className="AlertsTable__loader" colSpan={colspan}>
            <div className="AlertsTable__loader__container">
              <Loader isLoading={isDrawerLoading} />
            </div>
          </td>
        </tr>
        <tr
          className={_classnames('AlertsTable__row', {
            'AlertsTable__row--acknowledged': alert.acked,
            'AlertsTable__row--active': isActive,
            'AlertsTable__row--text-hover': textHover,
          })}
          id={`data-limit-alert-${alert.id}`}
          onClick={this.handleRowClick}
          ref={this.alertsRow}
        >
          {isMultiSelect && (
            <td className="AlertsTable__cell AlertsTable__cell--select">
              <input
                checked={selectedAlerts.includes(alert.id)}
                id={`select-row-${alert.deviceid}`}
                onChange={this.handleCheckboxSelection}
                type="checkbox"
              />
            </td>
          )}
          <td className="AlertsTable__cell">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="AlertsTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
              onMouseDown={this.handleTextMouseDown}
            >
              <div className="AlertsTable__cell__key">{timestamp.format('MMM D')}</div>
              {!alert.acked && (
                <div className="AlertsTable__cell__value">{timestamp.format('h:mm A')} UTC</div>
              )}
            </div>
          </td>
          <td className="AlertsTable__cell">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="AlertsTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
              onMouseDown={this.handleTextMouseDown}
            >
              <div className="AlertsTable__cell__key">
                <Tooltip
                  content={deviceStatusTooltipStrings[alert.state]}
                  title={`${statusText} Status`}
                >
                  <div className={`Orb Orb--inline Orb--${statusColor}`} />
                </Tooltip>
                {deviceName}
              </div>
              {!alert.acked && <div className="AlertsTable__cell__value">Sim Paused</div>}
            </div>
          </td>
          <td className="AlertsTable__cell AlertsTable__cell--overage">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="AlertsTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
              onMouseDown={this.handleTextMouseDown}
            >
              <div className="AlertsTable__cell__key AlertsTable__cell__key--overage">
                <Icon
                  name="Arrow--increase"
                  size="minor"
                  svgProps={{ style: { fill: '#A80000' } }}
                />
                {toByteString(curBillingDataUsed, 0)}
              </div>
              {!alert.acked && dataLimit !== -1 && (
                <div className="AlertsTable__cell__value">{toByteString(dataLimit, 0)} Limit</div>
              )}
            </div>
          </td>
          <td
            className={_classnames('AlertsTable__cell AlertsTable__cell--usage', {
              'AlertsTable__cell--usage--multi-select': isMultiSelect,
            })}
          >
            <div className="AlertsTable__cell__content">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                className="AlertsTable__cell__text"
                onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
                onMouseEnter={this.handleTextMouseEnter}
                onMouseLeave={this.handleTextMouseBlur}
                onMouseDown={this.handleTextMouseDown}
              >
                <div className="AlertsTable__cell__key">
                  {daysRemaining < 0
                    ? `Period ended ${dataEnds.format('M/DD')}`
                    : `${daysRemaining} ${dayDisplay} remaining`}
                </div>
                {!alert.acked && (
                  <div className="AlertsTable__cell__value">
                    {daysRemaining < 0
                      ? `${Math.abs(daysRemaining)} ${dayDisplay} ago`
                      : `${dataEnds.format('M/DD')} at ${dataEnds.format('h:mm A')} UTC`}
                  </div>
                )}
              </div>
              {isActive || isMultiSelect ? null : (
                <div className="AlertsTable__cell__hover_cta">
                  {!alert.acked ? (
                    <>
                      <div className="AlertsTable__cell__tooltip">
                        <Tooltip content="Change data usage limit" position="top">
                          <Button
                            iconLeft="Gauge"
                            onClick={this.handleChangeDataLimit}
                            small
                            type="secondary"
                          />
                        </Tooltip>
                      </div>
                      <div className="AlertsTable__cell__tooltip">
                        <Tooltip content="Change Plan" position="top">
                          <Button
                            iconLeft="Transfer"
                            onClick={this.handlePlanChange}
                            small
                            type="secondary"
                          />
                        </Tooltip>
                      </div>
                    </>
                  ) : null}
                  <div className="AlertsTable__cell__tooltip">
                    <Tooltip content={alert.acked ? 'Unacknowledge' : 'Acknowledge'} position="top">
                      <Button
                        iconLeft="Checkmark--double"
                        onClick={
                          alert.acked ? this.handleUnacknowledgeAlert : this.handleAcknowledgeAlert
                        }
                        small
                        type="secondary"
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
              {alert.acked ? (
                <Icon
                  name="Checkmark--double"
                  classes={_classnames('icon-double-check-green', {
                    'icon-double-check-green--multi-select': isMultiSelect,
                  })}
                  svgProps={{ style: { fill: '#0fb068' } }}
                />
              ) : null}
              {!isMultiSelect ? (
                <div className="AlertsTable__arrow">
                  <Icon
                    classes="AlertsTable__arrow__icon AlertsTable__arrow__icon--default"
                    name="Chevron--single--south"
                    size="minor"
                    svgProps={{ style: { fill: '#969fb1' } }}
                  />
                  <Icon
                    classes="AlertsTable__arrow__icon AlertsTable__arrow__icon--hover"
                    name="Chevron--single--south"
                    size="minor"
                    svgProps={{ style: { fill: '#0a1435' } }}
                  />
                  <Icon
                    classes="AlertsTable__arrow__icon AlertsTable__arrow__icon--active"
                    name="Chevron--single--south"
                    size="minor"
                    svgProps={{ style: { fill: '#ffffff' } }}
                  />
                </div>
              ) : null}
            </div>
          </td>
        </tr>
        {isActive && (
          <tr>
            <td colSpan={colspan} className="AlertsDrawer__table-cell">
              <AlertDrawerExpander>
                <DeviceDataLimitAlertsDrawer
                  alert={alert}
                  dataLimit={dataLimit}
                  deviceid={alert.deviceid}
                  handleAcknowledgeAlert={this.handleAcknowledgeAlert}
                  handleUnacknowledgeAlert={this.handleUnacknowledgeAlert}
                  handleChangeDataLimit={this.handleChangeDataLimit}
                  handlePlanChange={this.handlePlanChange}
                  isOpen={isActive}
                  isLoading={isDrawerLoading}
                  onDrawerLoad={this.onDrawerLoad}
                />
              </AlertDrawerExpander>
            </td>
          </tr>
        )}
      </>
    );
  }
}

const createMapStateToProps = () => {
  const getActiveAlert = createGetActiveAlertByType(alertTypes.DEVICE_DATA_LIMIT);

  return (state) => ({
    activeAlert: getActiveAlert(state),
    releaseFlags: state.releaseFlag,
  });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...alertActions,
    },
    dispatch
  );

export default connect(createMapStateToProps, mapDispatchToProps)(DeviceDataLimitAlertsTableRow);
