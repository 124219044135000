import { Button, Icon } from '@holokit/core';
import { TagComplete, Topic } from 'common-js/components';
import createReactClass from 'create-react-class';
import { Mixin } from 'formsy-react19';
import _ from 'lodash';
import React from 'react';

// TODO: Changing this would require not using mixins (or finding a way to add them to the prototype)
// eslint-disable-next-line react/prefer-es6-class
const TopicInput = createReactClass({
  mixins: [Mixin],

  getInitialState() {
    return {
      isAddingMoreTopics: false,
    };
  },

  onValueChange(tag, action) {
    const currentTags = this.getTagValue();

    if (action === 'add') {
      currentTags.push(tag);
    }

    if (action === 'remove') {
      const indexOfTag = _.findIndex(currentTags, { name: tag.name });
      currentTags.splice(indexOfTag, 1);
    }

    const { defaultTopic } = this.props;
    const newTags = currentTags.map((v) => v.name);
    newTags.unshift(defaultTopic);

    this.setValue(newTags);
  },

  getTagValue() {
    const value = this.getValue();

    if (_.isArray(value)) {
      return value
        .slice(1)
        .filter((v) => v !== '')
        .map((v) => ({ name: v.trim() }));
    }
    return [value];
  },

  render() {
    const { requiredMessage } = this.props;

    let className;
    if (this.showRequired()) {
      className = 'label required';
    } else if (this.showError()) {
      className = 'label error';
    } else {
      className = 'label';
    }

    const errorMessage = this.showRequired() ? requiredMessage : this.getErrorMessage();
    const { description, labelName, showErrors, defaultTopic, suggestions = [] } = this.props;
    const { isAddingMoreTopics } = this.state;

    return (
      <div className={className}>
        <div className="label-text f5">{labelName}</div>
        {defaultTopic && (
          <div className="section section-sm">
            <Topic name={defaultTopic} wrapperClasses="default-topic" />
          </div>
        )}
        {description && (
          <div className="section section-sm topic-note f6 f-subdued">
            <Icon
              classes="topic-note-icon"
              name="Circle--info"
              size="minor"
              svgProps={{ style: { fill: '#8008f7' } }}
            />
            {description}
          </div>
        )}
        {defaultTopic && (
          <div>
            <div className="section section-sm">
              <Button
                iconLeft="Circle--plus"
                onClick={() => this.setState({ isAddingMoreTopics: !isAddingMoreTopics })}
                small
                type="secondary"
              >
                ADD MORE TOPICS
              </Button>
            </div>
            <div
              className={`section section-sm${
                isAddingMoreTopics || this.getTagValue().length > 0 ? '' : ' hidden'
              }`}
            >
              <div className="input-container topic-tagcomplete">
                <TagComplete
                  suggestions={suggestions.map((s) => ({ name: s }))}
                  onTagDeleted={(tag) => this.onValueChange(tag, 'remove')}
                  onTagAdded={(tag) => this.onValueChange(tag, 'add')}
                  allowNew
                  allowDuplicates
                  autofocus={false}
                  autoresize
                  placeholder={'  '}
                  tags={this.getTagValue()}
                />
                {showErrors && <div className="inline-error">{errorMessage}</div>}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
});

export default TopicInput;
