import { createSelector } from 'reselect';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { toByteString } from 'common-js/utils/numberFormatter';

const selectOverageLimitString = createSelector(
  selectCurrentDevice,
  ({ overagelimit: overageLimit, data }) => {
    if (overageLimit === 0) return toByteString(data, 0);
    if (overageLimit !== -1) return toByteString(data + overageLimit, 2);
    return 'unlimited data';
  }
);

export default selectOverageLimitString;
