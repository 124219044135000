import useWindowSize from 'common-js/hooks/useWindowSize';
import { type FC, type ReactNode } from 'react';
import DeviceContext from './DeviceContext';

interface DeviceContextProviderProps {
  children?: ReactNode;
}

const DeviceContextProvider: FC<DeviceContextProviderProps> = ({ children }) => {
  const device = useWindowSize();
  return <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>;
};

export default DeviceContextProvider;
