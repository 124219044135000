import ClassNames from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

function HyperTestingTimelineTableRow({ first, last, test }) {
  const hasError = test.state === 'ERROR';
  const cycleString =
    test.currentCycle || test.requestedCycles
      ? `${test.currentCycle} of ${test.requestedCycles}`
      : null;

  return (
    <div
      id={`HyperTestingTimelineTable--${test.id}`}
      key={test.id}
      className="HyperTestingTimelineTable"
    >
      <div className="HyperTestingTimelineTable__row">
        {last && <div className="HyperTestingTimelineTable__timeline-stop" />}
        <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--timestamp">
          {first && (
            <>
              <div>{test.timestamp.format('YYYY-MM-DD')}</div>
              <div>{test.timestamp.format('HH:mm:ss')}</div>
            </>
          )}
        </div>
        <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--dot">
          <div
            id={`HyperTestingTimelineStep--${test.id}`}
            className={ClassNames({
              HyperTestingTimelineTable__timeline__point: first,
              'HyperTestingTimelineTable__cell__text--stacked': test.concurrentWith.length > 0,
            })}
          />
        </div>
        <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--action">
          <div className="HyperTestingTimelineTable__cell__key">Action</div>
          <div className="HyperTestingTimelineTable__cell__value">{test.actionName}</div>
        </div>
        <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--state">
          <div className="HyperTestingTimelineTable__cell__key">State</div>
          <div
            className={ClassNames('HyperTestingTimelineTable__cell__value', {
              'HyperTestingTimelineTable__cell__value--error': hasError,
            })}
          >
            {test.stateName}
          </div>
        </div>
        <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--deviceId">
          <div className="HyperTestingTimelineTable__cell__key">Device Id</div>
          <div className="HyperTestingTimelineTable__cell__value">{test.deviceid}</div>
        </div>
        {cycleString && (
          <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--cycles">
            <div className="HyperTestingTimelineTable__cell__key">Cycles</div>
            <div className="HyperTestingTimelineTable__cell__value">{cycleString}</div>
          </div>
        )}
      </div>
      {hasError && (
        <div className="HyperTestingTimelineTable__row">
          <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--timestamp" />
          <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__cell--dot" />
          <div className="HyperTestingTimelineTable__cell HyperTestingTimelineTable__error-message">
            {test.formattedErrorMessage}
          </div>
        </div>
      )}
    </div>
  );
}

HyperTestingTimelineTableRow.propTypes = {
  test: PropTypes.shape({
    id: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    timestamp: PropTypes.object,
    deviceid: PropTypes.string,
    actionName: PropTypes.string,
    stateName: PropTypes.string,
    formattedErrorMessage: PropTypes.string,
    concurrentWith: PropTypes.shape({ length: PropTypes.number }), // TODO: This looks like an array, but we don't use more than that here
    currentCycle: PropTypes.string,
    requestedCycles: PropTypes.string,
  }).isRequired,
};

export default HyperTestingTimelineTableRow;
