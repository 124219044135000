import { Checkbox } from '@holokit/core';
import createReactClass from 'create-react-class';
import { Mixin } from 'formsy-react19';
import React from 'react';

// TODO: Changing this would require not using mixins (or finding a way to add them to the prototype)
// eslint-disable-next-line react/prefer-es6-class
const Input = createReactClass({
  mixins: [Mixin],

  changeValue(event) {
    const { prefix } = this.props;

    this.setValue(prefix ? prefix + event.currentTarget.value : event.currentTarget.value);
  },

  changeCheckbox(event) {
    this.setValue(event.currentTarget.checked);
  },

  render() {
    const { requiredMessage, type: propType } = this.props;

    let className;
    if (this.showRequired()) {
      className = 'required';
    } else if (this.showError()) {
      className = 'error';
    } else {
      className = null;
    }
    const errorMessage = this.showRequired() ? requiredMessage : this.getErrorMessage();
    const type = propType || 'text';
    const { description, labelName, showErrors, prefix = null } = this.props;

    return (
      <div className={className}>
        {type === 'checkbox' ? (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label>
            <div className="label-text f5">{labelName}</div>
            <div className={`input-container ${type}`}>
              <Checkbox onChange={this.changeCheckbox} checked={this.getValue()} />
              {description}
              {showErrors && <div className="inline-error">{errorMessage}</div>}
            </div>
          </label>
        ) : (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label>
            <div className="label-text f5">{labelName}</div>
            {description && <div className="f6 section section-sm f-subdued">{description}</div>}
            <div className={`input-container ${type}`}>
              <div className="grid-row v-align">
                {prefix && <div className="prefix">{prefix}</div>}
                <div className="grid-item">
                  {type === 'textarea' ? (
                    <textarea onChange={this.changeValue} value={this.getValue()} />
                  ) : (
                    <input
                      type="text"
                      onChange={this.changeValue}
                      value={prefix ? this.getValue().replace(prefix, '') : this.getValue()}
                    />
                  )}
                  {showErrors && errorMessage && <div className="inline-error">{errorMessage}</div>}
                </div>
              </div>
            </div>
          </label>
        )}
      </div>
    );
  },
});

export default Input;
