import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { InlineNotification } from '@hologram-dimension/inline-notification';
import { Panel } from '@hologram-dimension/panel';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { HoloHelmet } from 'common-js/components';
import {
  impersonateUser as impersonateUser_,
  orgSearch as orgSearch_,
} from 'common-js/reducers/admin/actions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class General extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      orgId: '',
      orgName: '',
    };
  }

  onSubmit = (e) => {
    const { impersonateUser } = this.props;
    const { email, orgId } = this.state;

    e.preventDefault();
    impersonateUser({ email, orgId });
  };

  onClick = (orgId) => {
    const { impersonateUser } = this.props;
    impersonateUser({ orgId });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSearchSubmit = (e) => {
    const { orgSearch } = this.props;
    const { orgName } = this.state;

    e.preventDefault();
    orgSearch(orgName);
  };

  render() {
    const { isAdmin, searchResults, error, impersonateProcessing, searchProcessing } = this.props;
    const { email, orgId, orgName } = this.state;

    if (!isAdmin) return <div />;

    return (
      <div className="admin padded-page-wide">
        <HoloHelmet title="Admin - Impersonate user" />
        {error && <InlineNotification variant="error">{error}</InlineNotification>}
        <Panel header="Impersonate using email">
          <Form
            onSubmit={this.onSubmit}
            footerActions={
              <Button type="submit" loading={impersonateProcessing || searchProcessing}>
                Impersonate
              </Button>
            }
          >
            <TextInputField
              label="User email"
              id="user-email  "
              name="email"
              defaultValue={email}
              onChange={this.onChange}
              autocomplete="email"
              inputProps={{ 'data-1p-ignore': true, 'data-lpignore': true } as any}
              className="input"
            />
          </Form>
        </Panel>
        <Panel header="Impersonate using organization">
          <div className="impersonatePanel">
            <Form
              onSubmit={this.onSubmit}
              footerActions={
                <Button type="submit" loading={impersonateProcessing || searchProcessing}>
                  Impersonate
                </Button>
              }
            >
              <TextInputField
                label="Organization ID"
                id="org-id"
                name="orgId"
                defaultValue={orgId}
                onChange={this.onChange}
                autocomplete="off"
                inputProps={{ 'data-1p-ignore': true, 'data-lpignore': true } as any}
                className="input"
              />
            </Form>
            <Form
              onSubmit={this.onSearchSubmit}
              footerActions={
                <Button type="submit" loading={impersonateProcessing || searchProcessing}>
                  Search
                </Button>
              }
            >
              <TextInputField
                label="Search organizations by name"
                id="org-name"
                name="orgName"
                defaultValue={orgName}
                onChange={this.onChange}
                autocomplete="organization"
                inputProps={{ 'data-1p-ignore': true, 'data-lpignore': true } as any}
                className="input"
              />
            </Form>
            <ul>
              {searchResults.map((org: any) => (
                <li key={org.id}>
                  <Button onClick={() => this.onClick(org.id)} variant="tertiary">
                    {`Impersonate as ${org.name} (${org.id})`}
                  </Button>
                </li>
              ))}
            </ul>{' '}
          </div>
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAdmin: state.account.role === 'adm' || state.account.role === 'hdm',
  searchResults: state.admin.orgResults,
  error: state.admin.error,
  impersonateProcessing: state.admin.impersonateProcessing,
  searchProcessing: state.admin.searchProcessing,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      impersonateUser: impersonateUser_,
      orgSearch: orgSearch_,
    },
    dispatch
  );

const ImpersonateHOC = connect(mapStateToProps, mapDispatchToProps)(General);

export default ImpersonateHOC;
