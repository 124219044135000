import { browserHistory } from 'react-router';
import getStore from 'common-js/configureStore';
import { getCurrentOrgId } from 'common-js/api/util';

export const AUTH_PARAM_NAME = 'orgid';
export const USER_PARAM_NAME = 'userid';
export const AUTH_PREFIX = `/org/:${AUTH_PARAM_NAME}`;

export const ADMIN_RELEASEFLAGS = `${AUTH_PREFIX}/admin/releaseflags`;
export const ADMIN_IMPERSONATE = `${AUTH_PREFIX}/admin/impersonate`;
export const ADMIN_DEBUGDEVICE = `${AUTH_PREFIX}/admin/debugdevice`;
// have to use a permutation of admin since our
// cloudfront redirects requests with admin at the root url
export const ADMIN_SPOOF_LANDING = `/hadmin/viewas/:${USER_PARAM_NAME}`;

export const DEVICES_DEVICES = `${AUTH_PREFIX}/`;

export const DEVICES_USAGE = `${AUTH_PREFIX}/devices/usages`;
export const DEVICES_PREFLIGHT = `${AUTH_PREFIX}/preflight`;
export const DEVICES_DEACTIVATED = `${AUTH_PREFIX}/deactivated`;
export const DEVICES_LOGS = `${AUTH_PREFIX}/devices/logs`;
export const DEVICES_PLANS = `${AUTH_PREFIX}/devices/plans`;
export const DEVICES_TAG_MANAGER = `${AUTH_PREFIX}/tags`;
export const DEVICES_ACTIVITY = `${AUTH_PREFIX}/activity`;
export const BATCH_JOB_PARAM_NAME = 'batchJobId';
export const DEVICES_ACTIVITY_HISTORY_DETAILS = `${AUTH_PREFIX}/activity/:${BATCH_JOB_PARAM_NAME}/details`;

export const SIM_PARAM_NAME = 'simId'; // For now, this can just be device ID, but let's hope?
export const SIM_INVENTORY = `${AUTH_PREFIX}/sims`;
export const SIM_INVENTORY_ACTIVE = `${AUTH_PREFIX}/sims/active`;
export const SIM_INVENTORY_PREDEPLOYMENT = `${AUTH_PREFIX}/sims/predeployment`;
export const SIM_INVENTORY_DEACTIVATED = `${AUTH_PREFIX}/sims/deactivated`;
export const SIM_DETAILS = `${AUTH_PREFIX}/sims/details/:${SIM_PARAM_NAME}`;
export const SIM_PROFILES = `${AUTH_PREFIX}/sims/details/:${SIM_PARAM_NAME}/profiles`;
export const SIM_SIMS_AND_DEVICES = `${AUTH_PREFIX}/sims/details/:${SIM_PARAM_NAME}/simanddevices`;
export const SIM_USAGE = `${AUTH_PREFIX}/sims/details/:${SIM_PARAM_NAME}/usage`;
export const SIM_PLANS = `${AUTH_PREFIX}/sims/plans`;
export const SIM_TAG_MANAGER = `${AUTH_PREFIX}/sims/tags`;
export const SIM_ACTIVITY = `${AUTH_PREFIX}/sims/activity`;
export const SIM_ACTIVITY_HISTORY_DETAILS = `${AUTH_PREFIX}/sims/activity/:${BATCH_JOB_PARAM_NAME}/details`;

export const DEVICE_PARAM_NAME = 'deviceId';
export const DEVICE_BASE = `${AUTH_PREFIX}/device/:${DEVICE_PARAM_NAME}`;
export const DEVICE_DATA_LIMITS = `${DEVICE_BASE}/limits`;
export const DEVICE_CHANGE_PLAN = `${DEVICE_BASE}/changeplan`;
export const DEVICE_INSPECT_DATAUSED = `${DEVICE_BASE}/dataused/inspect`;
export const DEVICE_LANDING = `${DEVICE_BASE}/status`;
export const DEVICE_LOGS = `${DEVICE_BASE}/logs`;
export const DEVICE_MESSAGE = `${DEVICE_BASE}/message`;
export const DEVICE_PLAN = `${DEVICE_BASE}/plan`;
export const DEVICE_PROFILES = `${DEVICE_BASE}/profiles`;
export const DEVICE_ROUTES = `${DEVICE_BASE}/routes`;
export const DEVICE_SPACEBRIDGE = `${DEVICE_BASE}/spacebridge`;
export const DEVICE_STATUS = `${DEVICE_BASE}/status`;
export const DEVICE_USAGE = `${DEVICE_BASE}/usage`;
export const DEVICE_WEBHOOKS = `${DEVICE_BASE}/webhooks`;
export const DEVICE_HYPER = `${DEVICE_BASE}/hyper`;

export const ACCOUNT_GENERAL = `${AUTH_PREFIX}/billing`;
export const ACCOUNT_ORDERS = `${AUTH_PREFIX}/billing/orders`;
export const ACCOUNT_BILLING = `${AUTH_PREFIX}/billing`;
export const ACCOUNT_APIKEY = `${AUTH_PREFIX}/settings/api`;
export const ACCOUNT_LOGIN = '/account/login';
export const ACCOUNT_OLD_LOGIN = '/login';
export const ACCOUNT_REGISTER = '/account/register';
export const ACCOUNT_FORGOT_PASSWORD = '/account/forgotpassword';
export const ACCOUNT_ORGS = `${AUTH_PREFIX}/settings/org`;
export const ACCOUNT_RESET_PASSWORD = '/reset-password';
export const ACCOUNT_VERIFY_EMAIL = '/verify';
export const ACCOUNT_START = '/start';

export const BILLING_ACCOUNT = `${AUTH_PREFIX}/billing`;
export const BILLING_HISTORY = `${AUTH_PREFIX}/billing/history`;
export const BILLING_ORDERS = `${AUTH_PREFIX}/billing/orders`;
export const BILLING_STATEMENTS = `${AUTH_PREFIX}/billing/statements`;
export const BILLING_INVOICES = `${AUTH_PREFIX}/billing/invoices`;

export const SETTINGS_LANDING = `${AUTH_PREFIX}/settings/org`;
export const SETTINGS_ORG = `${AUTH_PREFIX}/settings/org`;
export const SETTINGS_ACCOUNT = `${AUTH_PREFIX}/settings/account`;
export const SETTINGS_API = `${AUTH_PREFIX}/settings/api`;
export const SETTINGS_SUBSCRIPTION = `${AUTH_PREFIX}/settings/subscription`;

export const ORGS_GENERAL = `${AUTH_PREFIX}/settings/org`;
export const ORGS_COLLABORATORS = `${AUTH_PREFIX}/settings/org`;
export const ORGS_BILLING = `${AUTH_PREFIX}/billing`;
export const ORGS_ORDERS = `${AUTH_PREFIX}/billing/orders`;

export const APPS_PARAM_NAME = 'routeId';
export const APPS_OVERVIEW = `${AUTH_PREFIX}/routes`;
export const APPS_CONFIGURE = `${AUTH_PREFIX}/routes/:${APPS_PARAM_NAME}/configure`;
export const APPS_ADD = `${AUTH_PREFIX}/routes/add`;
export const OLD_APPS_OVERVIEW = '/routes';
export const OLD_APPS_CONFIGURE = `/routes/:${APPS_PARAM_NAME}/configure`;
export const OLD_APPS_ADD = '/routes/add';

export const ORGS_CREATE_STEP1 = `${AUTH_PREFIX}/orgs/create/1`;
export const ORGS_CREATE_STEP2 = `${AUTH_PREFIX}/orgs/create/2`;

export const ACTIVATION = `${AUTH_PREFIX}/activation`;

export const USAGE = `${AUTH_PREFIX}/usage`;
export const USAGE_OVERVIEW = `${USAGE}/overview`;
export const USAGE_BY_DAY = `${USAGE}/byday`;
export const USAGE_BY_SIM = `${USAGE}/bysim`;
export const USAGE_BY_SIM_AND_DAY = `${USAGE}/bysimandday`;
export const USAGE_BY_SESSION = `${USAGE}/bysession`;
export const USAGE_PREDEPLOYMENT = `${USAGE}/predeployment`;

// TODO: Legacy: to retire after rollout
export const USAGE_INSPECT_DATAUSED = `${AUTH_PREFIX}/inspect/dataused`;
export const USAGE_LIVE = `${AUTH_PREFIX}/usage/live`;
export const USAGE_BREAKDOWN = `${AUTH_PREFIX}/usage/breakdown`;

export const HOME_OVERVIEW = `${AUTH_PREFIX}/home`;
export const ACTIONS_ALERTS_DEVICE_TACCHANGE = `${AUTH_PREFIX}/actions/alerts/devices/simmoved`;
export const ACTIONS_ALERTS_DEVICE_DATALIMIT = `${AUTH_PREFIX}/actions/alerts/devices/datalimit`;
export const ACTIONS_ALERTS_DEVICE_HIGHUSAGE = `${AUTH_PREFIX}/actions/alerts/devices/highusage`;

export const DOWNLOAD = `${AUTH_PREFIX}/documents/:documentId/download`;

export const REPORT_PARAM_NAME = 'reportId';
export const REPORTS_OVERVIEW = `${AUTH_PREFIX}/reports`;
export const REPORTS_DETAIL = `${AUTH_PREFIX}/reports/:${REPORT_PARAM_NAME}`;

export const withContext = (path?: Nullable<string>, orgId?: Nullable<OrgId>): string => {
  /*
  Takes these types of urls and hydrates /org/<orgid>. e.g.

    within orgid 123 (from either function param or store)

    1. '/org/123/devices' => '/org/123/devices'
    2. '/org/456/devices' => '/org/123/devices'
    3. '/org/:orgid/devices' => '/org/123/devices'
    3. 'devices' => '/org/123/devices'
   */

  // If the org wasn't specified, pull the current org ID from the store
  let thisOrgId: Nullable<Optional<OrgId>> = orgId;
  if (!thisOrgId) thisOrgId = getCurrentOrgId(getStore().getState);
  const orgIdString = (thisOrgId ?? '').toString();

  // Strip off any /org/[whatever] at the beginning of the path since we're going to rebuild it
  // The final slash is stripped off it's there (in /org/123 it wouldn't be there)
  const tokens = (path ?? '').split('/');
  const pathTokens = tokens.length > 2 && tokens[1] === 'org' ? tokens.slice(3) : tokens;
  const remainingPath = pathTokens.length > 0 ? `/${pathTokens.join('/')}` : '';

  return `/org/${orgIdString}${remainingPath}`;
};

export const getPathnameOrgId = (): Optional<number> => {
  let orgId: Optional<number>;
  const pathTokens = window.location.pathname.split('/');

  if (pathTokens.length > 2) {
    if (pathTokens[1] === 'org') orgId = parseInt(pathTokens[2], 10);
  }

  return orgId;
};

export const isCurrentUrl = (urlToMatch: string): boolean => {
  const path = window.location.pathname;

  if (path === urlToMatch) {
    return true;
  }

  // single device pages hack... couldn't find a better way.
  if (path.includes('/device/')) {
    if (path.includes('/usage')) return urlToMatch === DEVICE_USAGE;
    if (path.includes('/plan')) return urlToMatch === DEVICE_PLAN;
    if (path.includes('/logs')) return urlToMatch === DEVICE_LOGS;
    if (path.includes('/message')) return urlToMatch === DEVICE_MESSAGE;
  }

  // app pages
  if (path.includes('/routes/')) {
    if (path.includes('/configure')) return urlToMatch === DEVICE_USAGE;
  }

  return false;
};

export const pathMatches = (matchPath, path?: string): boolean => {
  const thisPath = typeof path !== 'undefined' ? path : window.location.pathname;
  const matchPathTokens = matchPath.split('/');
  const pathTokens = thisPath.split('/');

  let thisMatchPath;
  if (matchPathTokens.length > 3 && matchPathTokens[1] === 'org') {
    thisMatchPath = matchPathTokens.slice(3).join('/');
  }

  let derivedPath;
  if (pathTokens.length > 3 && pathTokens[1] === 'org') {
    derivedPath = pathTokens.slice(3).join('/');
  } else {
    derivedPath = window.location.pathname;
  }

  return derivedPath === thisMatchPath;
};

export const buildSDPPath = (deviceId: string, path: string): string =>
  withContext(path.replace(`:${DEVICE_PARAM_NAME}`, deviceId));

export const onInternalLinkClick = (e) => {
  e.preventDefault();
  browserHistory.push(e.currentTarget.pathname);
};

export const pathMatchesRegex = (matchPathRegex: RegExp, path?: string): boolean => {
  const thisPath = path !== undefined ? path : window.location.pathname;
  return (thisPath.match(matchPathRegex) || []).length > 0;
};

export const pathMatchesWithIdsReplaced = (matchPath: string, path?: string): boolean => {
  // This replaces ID placeholders (:orgid, :batchJobId, etc.) in the path so we can test against actual URLs that have numbered IDs (3981, etc.)
  const regex = matchPath.replace(/\/:[^/]+/g, '/[^/]+');
  return pathMatchesRegex(new RegExp(regex), path);
};

export const pathMatchesNewUsageTab = () =>
  pathMatches(USAGE_BY_DAY) ||
  pathMatches(USAGE_BY_SIM) ||
  pathMatches(USAGE_BY_SIM_AND_DAY) ||
  pathMatches(USAGE_BY_SESSION);
