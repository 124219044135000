import { getUserInfo as getUserInfoApi } from 'common-js/api';
import { getAllReleaseFlags } from 'common-js/reducers/releaseFlag/actions';
import {
  GET_USER_INFO_ERROR,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  SET_USER_INFO,
} from '../actionTypes';
import getUserSettings from './getUserSettings';

export const getUserInfo = () => (dispatch, state) => {
  dispatch({
    type: GET_USER_INFO_REQUEST,
  });

  return getUserInfoApi()
    .then((result) => {
      dispatch({
        type: GET_USER_INFO_SUCCESS,
        ...result.userInfo,
      });

      dispatch({
        type: SET_USER_INFO,
        ...result.userInfo,
      });

      return Promise.all([
        getUserSettings()(dispatch, state),
        getAllReleaseFlags()(dispatch, state),
        result,
      ]);
    })
    .then((results) => Promise.resolve(results[2]))
    .catch((error) => {
      dispatch({
        type: GET_USER_INFO_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};
