import { FC } from 'react';
import { Helmet } from 'react-helmet';

interface HoloHelmetProps {
  title?: string;
}

const HoloHelmet: FC<HoloHelmetProps> = ({ title = 'Hologram Dashboard' }) => (
  <Helmet>
    <title>{`${title} - Hologram`}</title>
  </Helmet>
);

export default HoloHelmet;
