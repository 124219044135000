import useGetDeviceProfileStatusHistory from 'common-js/api/devices/useGetDeviceProfileStatusHistory';
import usePauseDevice from 'common-js/api/devices/usePauseDevice';
import { setCurrentDeviceWithMetadata } from 'common-js/reducers/currentDevice/actions';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { close } from 'common-js/reducers/modal/actions';
import { getSelectedOrgId } from 'common-js/reducers/organization/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { Device } from 'common-js/types/Device';
import React, { useCallback, useMemo } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { toast } from 'react-toastify';

const useOnPauseEvent = () => {
  const dispatch = useAppDispatch();
  const device: Device = useAppSelector(selectCurrentDevice);
  const orgId: OrgId = useAppSelector(getSelectedOrgId);
  const getDeviceProfileStatusHistory = useGetDeviceProfileStatusHistory();
  // this is to be replaced by sim id input provided by the business layer
  const simObj: Sim = useMemo(
    () => ({
      simId: '',
    }),
    []
  );
  const pauseDevice = usePauseDevice();

  return useCallback(async () => {
    try {
      // we want to wait for the device to be paused
      await pauseDevice({ deviceId: device.id, simObj, orgId });

      // we don't want to wait for the status history to be loaded, that can happen in the background
      getDeviceProfileStatusHistory(device.linkId)
        .then((statusHistory) => dispatch(setCurrentDeviceWithMetadata({ statusHistory })))
        .catch((error) => {
          dispatch(pushGlobalMessage(error, 'error'));
          dispatch(close());
        });
      toast(
        <HeadlineToast
          headline="Success"
          body="Device pause request received"
          icon="Checkmark--single"
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toastify-content--burnt toastify-content--success',
          autoClose: 5000,
        }
      );
      dispatch(close());
    } catch (error: any) {
      toast(
        <HeadlineToast
          headline="Error"
          body={`An error occurred pausing the device: "${error}". Please contact support.`}
          icon="Circle--alert"
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toastify-content--burnt toastify-content--error',
        }
      );
      dispatch(close());
    }
  }, [
    pauseDevice,
    device.id,
    device.linkId,
    dispatch,
    getDeviceProfileStatusHistory,
    orgId,
    simObj,
  ]);
};

export default useOnPauseEvent;
