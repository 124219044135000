import DeviceContext from 'common-js/components/DeviceContext';
import { useContext, type FC, type ReactNode } from 'react';
import Header from './modules/Header';
import ProgressIndicator from './modules/ProgressIndicator';

interface FullScreenModalProps {
  title: ReactNode;
  onClose?: AnyFunction;
  showQuit?: boolean;
  sidebar?: ReactNode;
  showProgress?: boolean;
  progressIndicatorType?: GetReactParameterBag<typeof ProgressIndicator>['variant'];
  optionalStep?: boolean;
  totalSteps?: number;
  currentStep?: number;
  overrideTitleRight?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
}

const FullScreenModal: FC<FullScreenModalProps> = ({
  title,
  onClose,
  showQuit,
  sidebar = null,
  showProgress = true,
  progressIndicatorType,
  optionalStep = false,
  totalSteps,
  currentStep,
  overrideTitleRight,
  children,
  footer,
}) => {
  const device = useContext(DeviceContext);

  return (
    <div className="FullScreenModal">
      <Header
        title={title}
        showQuit={showQuit}
        onClose={onClose}
        overrideTitleRight={overrideTitleRight}
      />
      <div className="FullScreenModal__page">
        {!device?.isMobile && sidebar}
        <div className="FullScreenModal__page__content">
          <div className="FullScreenModal__page__content__container">
            <div className="FullScreenModal__page__content__container__children">
              {showProgress && (totalSteps ?? 0) > 0 && (currentStep ?? -1) > -1 && (
                <ProgressIndicator
                  variant={progressIndicatorType}
                  totalSteps={totalSteps!}
                  currentStep={currentStep!}
                  optionalStep={optionalStep}
                />
              )}
              {children}
            </div>
          </div>
          {footer && <div className="FullScreenModal__page__content__footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};

export default FullScreenModal;
