import _ from 'lodash';

export default class OrgModel {
  id = null;

  is_personal = false;

  name = '';

  ownerid = null;

  ownerInfo = {
    first: '',
    last: '',
    email: '',
  };

  users = [];

  pendingUsers = [];

  initial = '';

  isHydrated = false;

  ccemails = '';

  isMyAccount = false;

  proPlan = null;

  constructor(data, userId, prettifyPersonal = true) {
    if (!data) return;

    this.isHydrated = true;
    this.id = data.id;
    this.is_personal = data.is_personal;
    this.name = data.name;
    this.ownerid = data.ownerid;
    this.ccemails = data.ccemails;
    this.users = data.users.map(this.prepUser);
    this.pendingUsers = data.pending.map(this.prepPendingUsers);
    this.proPlan = data.proplan;

    this.isMyAccount = !!(data.ownerid === userId && this.is_personal === 1);

    if (prettifyPersonal && this.is_personal) {
      this.ownerInfo = _.find(this.users, { id: this.ownerid });
      this.initial =
        this.ownerInfo.first !== ''
          ? this.ownerInfo.first.charAt(0).toUpperCase()
          : this.ownerInfo.email.charAt(0).toUpperCase();
      this.name =
        this.ownerInfo.first !== '' && this.ownerInfo.last !== ''
          ? `${this.ownerInfo.first} ${this.ownerInfo.last}`
          : this.ownerInfo.email;
    } else {
      this.initial = this.name.charAt(0).toUpperCase();
    }
  }

  prepPendingUsers(pendingUser) {
    return {
      ...pendingUser,
      initial: pendingUser.email.charAt(0).toUpperCase(),
    };
  }

  prepUser(user) {
    user.name = user.first !== '' && user.last !== '' ? `${user.first} ${user.last}` : user.email;
    user.initial = user.name.charAt(0).toUpperCase();

    return { ...user };
  }
}
