import { combineReducers } from 'redux';
import { config as SendSmsFormConfig } from './SendSmsForm';
import { config as SimulateMessageForm } from './SimulateMessageForm';
import { config as PurchasePhoneNumberFormConfig } from './PurchasePhoneNumberForm';
import { config as OrgCreationFormConfig } from './org/OrgCreationForm';
import { config as OrgInviteCollaboratorsFormConfig } from './org/OrgInviteCollaboratorsForm';
import { config as BulkUpdateOverageForm } from './BulkUpdateOverageForm';

const reducers = combineReducers({
  SendSmsForm: SendSmsFormConfig.reducer,
  SimulateMessageForm: SimulateMessageForm.reducer,
  PurchasePhoneNumberForm: PurchasePhoneNumberFormConfig.reducer,
  OrgCreationForm: OrgCreationFormConfig.reducer,
  OrgInviteCollaboratorsForm: OrgInviteCollaboratorsFormConfig.reducer,
  BulkUpdateOverageForm: BulkUpdateOverageForm.reducer,
});

export default reducers;
