import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Moment from 'moment';
import EmptyCell from './EmptyCell';

function CellDeviceLastActive({ lastConnectedTime }) {
  let lastActive;
  try {
    lastActive = Moment.utc(lastConnectedTime * 1000)
      .local()
      .fromNow();
    if (lastActive === 'Invalid date' || !_.isNumber(lastConnectedTime)) {
      lastActive = lastConnectedTime;
    }
  } catch (e) {
    _.noop();
  }
  return <div className="DevicesTable__cell">{lastActive || <EmptyCell />}</div>;
}

CellDeviceLastActive.propTypes = {
  lastConnectedTime: PropTypes.string.isRequired,
};

export default CellDeviceLastActive;
