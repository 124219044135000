import { GlobalMessage, Modal } from 'common-js/components';
import CloseButton from 'common-js/toasts/CloseButton';
import React from 'react';
import { ToastContainer } from 'react-toastify';

interface LayoutFullDrawerProps {
  params?: any;
  children?: React.ReactNode;
}

const LayoutFullDrawer: React.FC<LayoutFullDrawerProps> = ({ params, children }) => (
  <div className="LayoutFullDrawer full-height">
    <Modal params={params} />
    <GlobalMessage />
    <ToastContainer
      position="bottom-right"
      type="warning"
      autoClose={false}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnHover
      closeButton={<CloseButton />}
    />
    {children}
  </div>
);

export default LayoutFullDrawer;
