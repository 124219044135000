import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Panel } from '@hologram-dimension/panel';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectPlanData } from 'common-js/reducers/activation/selectors';
import type { Device } from 'common-js/types/Device';
import type { Plan } from 'common-js/types/Plan';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import DataUsageLimitCallout from './DataUsageLimitCallout';

const FOOTER_TEXT =
  'SIMs will continue to consume data until they are paused. A data usage limit is a cap, or amount of data, that when reached, pauses a device. For pay monthly data plans, any data usage over the included data amount will be charged at your overage rate (billed per KB) until your SIMs are paused.';

interface LimitsProps {
  changingPlan?: boolean;
}
const Limits = ({ changingPlan = false }: LimitsProps) => {
  const devices: Array<Device> = useAppSelector((state) => state.activation?.changePlan?.devices);
  const existingPlanId = (changingPlan && devices && devices[0] && devices[0].planId) || undefined;
  const existingPlan: Plan = useAppSelector((state) => selectPlanData(state, existingPlanId));

  return (
    <div className="UsageLimit__wrapper">
      <Panel header="Data usage limits" className="UsageLimit__panel" footer={FOOTER_TEXT}>
        {changingPlan && (devices[0].overagelimit ?? -1) > -1 && (
          <BannerNotification variant="caution">
            The existing data usage limit for this SIM — pause at{' '}
            {toByteStringFormatted(devices[0].overagelimit)}
            {existingPlan.dataLimit > 0 ? ' over included data' : ''} — will be removed by changing
            data plans.
          </BannerNotification>
        )}
        <DataUsageLimitCallout />
      </Panel>

      <BannerNotification className="UsageLimit__overageCallout" variant="note">
        SIMs with an active data session open cannot be paused until the session closes. Charges may
        still occur over your data usage limit.
      </BannerNotification>
    </div>
  );
};

export default Limits;
