import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { FormField } from '@hologram-dimension/form-field';
import { Select } from '@hologram-dimension/select';
import { SelectField } from '@hologram-dimension/select-field';
import { TextInput } from '@hologram-dimension/text-input';
import { Callout } from '@holokit/core';
import { Device } from 'common-js/types/Device';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';
import { toByteStringObject } from 'common-js/utils/numberFormatter';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import useAppSelector from 'common-js/hooks/useAppSelector';
import selectDataLimitOptions from './selectDataLimitOptions';
import useSubmitDataLimitsForm from './useSubmitDataLimitsForm';

const unitToBytes = {
  KB: 1_000,
  MB: 1_000_000,
  GB: 1_000_000_000,
};

const customUnitOptions = Object.keys(unitToBytes).map((option) => ({
  value: unitToBytes[option],
  label: option,
}));

const OutcomeCallout = ({ calloutType, text, deviceName }) => (
  <Callout
    type={calloutType}
    text={
      <>
        {text} <strong className="DataLimitsForm__device-name">{deviceName}</strong>.
      </>
    }
    iconColor={Callout.THEME.COLOR}
    defaultIcon
  />
);

interface DataLimitsFormProps {
  device: Device;
  onCancel: () => void;
  onSuccess: () => void;
  overageBytes: number;
}

type FormValues = {
  dataLimit: string;
  customAmountValue: string;
  customAmountUnit: number;
};

const DataLimitsForm = ({ device, onCancel, onSuccess, overageBytes }: DataLimitsFormProps) => {
  const dataLimitOptions = useAppSelector(selectDataLimitOptions);
  const dataLimitInitialValue = useMemo(
    () =>
      dataLimitOptions.find((option: { value: string }) => option.value === `${overageBytes}`)
        ? `${overageBytes}`
        : 'custom',
    [dataLimitOptions, overageBytes]
  );
  const overageBytesObject =
    overageBytes + device.data > 0 ? toByteStringObject(overageBytes + device.data, 0) : null;

  const defaultValues = useMemo(
    () => ({
      dataLimit: dataLimitInitialValue,
      customAmountValue: overageBytesObject?.byteValue || '', // it's not good practice to pass null values to inputs
      customAmountUnit: overageBytesObject ? unitToBytes[overageBytesObject.unit] : 1000000,
    }),
    [dataLimitInitialValue, overageBytesObject]
  );
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitted },
    reset,
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const showCustomField = watch('dataLimit') === 'custom';

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues, {
        keepValues: true,
        keepIsSubmitted: true,
      });
    }
  }, [isSubmitSuccessful, reset, defaultValues]);

  const {
    error: serverError,
    handleSubmit: onFormSubmit,
    loading,
  } = useSubmitDataLimitsForm({ device, onSuccess });

  const onChangeDropdown = useCallback(() => {
    reset(defaultValues, { keepValues: true });
  }, [defaultValues, reset]);

  const submissionSucceeded = isSubmitted && !serverError && !isDirty;
  const showError = serverError && !isDirty;

  return (
    <div className="DataLimitsForm">
      <Form
        className="DataLimitsForm__form"
        onSubmit={handleSubmit(onFormSubmit)}
        footerActions={
          <>
            <Button className="DataLimitsForm__button" onClick={onCancel} variant="secondary">
              {submissionSucceeded ? 'Done' : 'Cancel'}
            </Button>
            <Button
              className="DataLimitsForm__button"
              loading={loading}
              type="submit"
              variant="primary"
            >
              Update data usage limit
            </Button>
          </>
        }
      >
        <SelectField
          id="dataLimit"
          label="This device can use"
          options={dataLimitOptions}
          {...register('dataLimit', { onChange: onChangeDropdown })}
        />
        {showCustomField && (
          <FormField
            className={applyConditionalClassNames({
              'DataLimitsForm__custom-input': true,
              'DataLimitsForm__custom-input--error': errors.customAmountValue,
            })}
            invalid={!!errors.customAmountValue}
            label="Custom amount"
            hiddenLabel
            controlsLayoutDirection="row"
            controlsLayoutGap="none"
            validationMessage={errors.customAmountValue?.message}
          >
            {({ primaryControlId, ariaDescribedbyIds, invalid }) => (
              <>
                <TextInput
                  id={primaryControlId}
                  ariaDescribedby={ariaDescribedbyIds}
                  ariaLabel="Data amount"
                  invalid={invalid}
                  placeholder="Data amount"
                  type="text"
                  {...register('customAmountValue', {
                    validate: {
                      numerical: (v) =>
                        (/^[0-9]*$/.test(v) && v !== '') || 'Please enter data usage limit.',
                    },
                  })}
                />
                <Select
                  id="customAmountUnit"
                  className="DataLimitsForm__custom-unit"
                  ariaDescribedby={ariaDescribedbyIds}
                  ariaLabel="Data amount"
                  invalid={invalid}
                  defaultValue={unitToBytes.MB.toString()}
                  options={customUnitOptions}
                  {...register('customAmountUnit', { valueAsNumber: true })}
                />
              </>
            )}
          </FormField>
        )}
        {submissionSucceeded && (
          <OutcomeCallout
            calloutType={Callout.TYPE.SUCCESS}
            text="The data usage limit was changed for"
            deviceName={device.name}
          />
        )}
        {showError && (
          <OutcomeCallout
            calloutType={Callout.TYPE.ERROR}
            text="There was an error updating the data usage limit for"
            deviceName={device.name}
          />
        )}
      </Form>
    </div>
  );
};

export default DataLimitsForm;
