import React, { useState } from 'react';
import analyticsEventBuilder from 'common-js/analytics';
import { DeepMenu, DeepMenuItem } from '@holokit/core';
import { useFormContext } from 'react-hook-form';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  updateTotalDueToday,
  clearValidationErrorState,
} from 'common-js/reducers/activation/actions/actions';
import { selectSimErrors } from '../../common/reducers/activation/selectors';
import IndividualSimsPanel from './IndividualSimsPanel';
import RangeOfSimsPanel from './RangeOfSimsPanel';
import ListOfSimsPanel from './ListOfSimsPanel';

const CLEAR_SIM_TEXT =
  'Are you sure you want switch to a different method? Any selections and information you have provided here, will not be saved when you switch.';

const simInitialState = {
  total: 0,
  inputOptionUsed: '',
  sims: [],
  ranges: [],
  input: {
    range: {
      start: '',
      end: '',
    },
  },
};

const methodToString = {
  individual: 'Individual SIMs',
  range: 'Range of SIMs',
  list: 'CSV upload',
};

function BulkSims({ hasBillingPermissions }) {
  const { getValues, setValue } = useFormContext();
  const simInputMethod = getValues('sims.inputOptionUsed');
  const [method, setMethod] = useState(simInputMethod || 'individual');
  const dispatch = useAppDispatch();
  const { alreadyActivated, invalid, globalError } = useAppSelector(selectSimErrors);

  const isArrayEmpty = (arg) => Array.isArray(arg) && arg.length === 0;

  const simErrorsEmpty = () =>
    isArrayEmpty(alreadyActivated) && isArrayEmpty(invalid) && !globalError;

  const simValidationEmpty = () => getValues('sims.total') === 0;

  const hideAlertOnChange = () => simValidationEmpty() && simErrorsEmpty();

  const clearFormState = (simType) => {
    if (window.confirm(CLEAR_SIM_TEXT)) {
      setValue('sims', { ...simInitialState });
      dispatch(clearValidationErrorState());
      dispatch(updateTotalDueToday(null));
      setMethod(simType);
    }
  };

  const handleMethodChange = (methodName) => {
    const hideAlert = hideAlertOnChange();
    if (hideAlert) {
      setMethod(methodName);
    } else {
      clearFormState(methodName);
    }

    analyticsEventBuilder
      .buttonClick('Activation', 'Returning', 'Activation Multi-SIM Addition Toggle')
      .send({ 'Toggle selection': methodToString[methodName] || '' });
  };

  return (
    <>
      <DeepMenu>
        <DeepMenuItem
          text="Individual SIMs"
          active={method === 'individual'}
          onClick={() => handleMethodChange('individual')}
        />
        <DeepMenuItem
          text="Range of SIMs"
          active={method === 'range'}
          onClick={() => handleMethodChange('range')}
        />
        <DeepMenuItem
          text="CSV upload"
          active={method === 'list'}
          onClick={() => handleMethodChange('list')}
        />
      </DeepMenu>
      {method === 'individual' && (
        <IndividualSimsPanel hasBillingPermissions={hasBillingPermissions} />
      )}
      {method === 'range' && <RangeOfSimsPanel hasBillingPermissions={hasBillingPermissions} />}
      {method === 'list' && <ListOfSimsPanel hasBillingPermissions={hasBillingPermissions} />}
    </>
  );
}

export default BulkSims;
