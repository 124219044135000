import React from 'react';

interface IncompleteStepHeaderProps {
  step: number;
  currentStep: number;
  name: string;
  children?: React.ReactNode;
}

const IncompleteStepHeader = ({ step, currentStep, name, children }: IncompleteStepHeaderProps) => (
  <div className="ActivationSidebar__item">
    <div className={step === currentStep ? 'circle-selected' : 'circle'}>{step}</div>
    <div className="ActivationSidebar__item__title">
      <div className={step === currentStep ? 'selected' : 'not-selected'}>{name}</div>
      {children}
    </div>
  </div>
);

export default IncompleteStepHeader;
