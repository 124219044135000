import React from 'react';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';

interface ReviewSidebarItemProps extends SidebarItemProps {}

const ReviewSidebarItem = ({ step, currentStep, changingPlan }: ReviewSidebarItemProps) => (
  <IncompleteStepHeader
    step={step}
    currentStep={currentStep}
    name={changingPlan ? 'Review changes' : 'Review & activate'}
  />
);

export default ReviewSidebarItem;
