import {
  DEVICE_LANDING,
  DEVICE_PARAM_NAME,
  SIM_DETAILS,
  SIM_PARAM_NAME,
  withContext,
} from 'common-js/constants/paths';

export interface MatchedDevice {
  id: DeviceId;
  simcardid: number;
  name: string;
  status: string;
  connected: boolean;
  isHyper: boolean;
  highlights: Record<string, Array<{ text: string; highlight: boolean }>>;
  matchedFields: Array<{ key: string; match: string; showEnabled: boolean; showDisabled: boolean }>;
}

const getResultLink = (
  device: { id: MatchedDevice['id']; simcardid: MatchedDevice['simcardid'] },
  simInventory = false
) =>
  simInventory
    ? withContext(SIM_DETAILS.replace(`:${SIM_PARAM_NAME}`, `${device.simcardid}`))
    : withContext(DEVICE_LANDING.replace(`:${DEVICE_PARAM_NAME}`, `${device.id}`));

export default getResultLink;
