import { Panel } from '@hologram-dimension/panel';
import { OrganizationProvider, TagManager } from '@hologram-hyper-dashboard/components';
import { getUserContextDataMemoized } from 'common-js/api/util';
import { FullHeightCalculatorHOC } from 'common-js/components';
import { useAppSelector } from 'common-js/hooks';
import { type FC } from 'react';
import ColorTokens from '@hologram-dimension/tokens/colors';
import SubMenu from '../components/SubMenu';

const SimTagManager: FC = () => {
  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;

  return (
    <OrganizationProvider organizationId={orgId}>
      <SubMenu />
      <FullHeightCalculatorHOC
        accountForDrawer
        relative
        backgroundColor={ColorTokens.DdsColorBackgroundAlternative}
      >
        <div className="billing-container SimsTagManager">
          <Panel
            header="Tags"
            className="SimsTagManager__panel"
            contentClassName="SimsTagManager__content"
          >
            <TagManager onTagsMutated={() => {}} />
          </Panel>
        </div>
      </FullHeightCalculatorHOC>
    </OrganizationProvider>
  );
};

export default SimTagManager;
