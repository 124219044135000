import React from 'react';
import { Icon, Tooltip } from '@holokit/core';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';

const Status = ({ small, tooltip, icon }) => (
  <div className="light-line-status">
    <Tooltip block content={tooltip}>
      <div
        className={applyConditionalClassNames({
          'icon-wrapper': true,
          'icon-wrapper--expanded': !small,
        })}
      >
        <Icon name={icon} size="minor" svgProps={{ style: { fill: '#ffffff' } }} />
      </div>
    </Tooltip>
  </div>
);

const Line = ({ paused, deactivated, timedOut, color, small }) => (
  <div
    className={applyConditionalClassNames({
      'light-line': true,
      [color]: true,
      paused,
      deactivated,
      timedOut,
    })}
  >
    {deactivated && (
      <Status
        small={small}
        icon="Lightning--slash"
        tooltip="This device was deactivated and service has permanently ended. It can no longer use data or SMS services."
      />
    )}
    {timedOut && (
      <Status
        small={small}
        icon="History"
        tooltip="It's been over 30 days since we have seen this device on the network."
      />
    )}
    {paused && (
      <Status
        small={small}
        icon="Pause"
        tooltip="Your device is paused and will not be able to send or receive data or SMS messages."
      />
    )}
  </div>
);

export default Line;
