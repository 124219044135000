import {
  TagManagerModal as HyperTagManagerModal,
  OrganizationProvider,
} from '@hologram-hyper-dashboard/components';
import { getUserContextDataMemoized } from 'common-js/api/util';
import { ModalCommonProps } from 'common-js/components/Modal';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { fetchDevices, getTasks } from 'common-js/reducers/devices/actions';
import { TASK_START_DATE } from 'common-js/reducers/devices/reducer';
import { close } from 'common-js/reducers/modal/actions';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { useCallback, type FC } from 'react';
import { toast } from 'react-toastify';

interface TagManagerModalProps extends ModalCommonProps {
  sims: Set<HologramSimId>;
  onModalSuccess?: (sims: Array<HologramSimId>) => void;
}

const TagManagerModal: FC<TagManagerModalProps> = ({ sims, onModalSuccess }) => {
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onSuccess = useCallback(
    (newSims: Array<HologramSimId>) => {
      if (onModalSuccess) {
        onModalSuccess(newSims);
      }

      // TODO: when we add our own task management UI to Hyper Dashboard, we should no longer update redux
      window.setTimeout(() => {
        dispatch(getTasks(TASK_START_DATE));
        dispatch(fetchDevices());
      }, 1000);

      toast(
        <HeadlineToast
          headline="Success"
          body={`Tags successfully updated for ${newSims.length} SIMs.`}
          icon="Checkmark--single"
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toastify-content--burnt toastify-content--success',
          autoClose: 10000,
        }
      );
    },
    [dispatch, onModalSuccess]
  );

  const onError = useCallback(() => {
    toast(
      <HeadlineToast
        headline="Error"
        body="Unable to update tags for these SIMs."
        icon="Circle--alert"
      />,
      {
        className: 'toastify-content--burnt toastify-content--error',
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  }, []);

  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId;

  return (
    <OrganizationProvider organizationId={orgId}>
      <HyperTagManagerModal
        onClose={onClose}
        onSuccess={onSuccess}
        onError={onError}
        defaultOpen
        sims={sims}
      />
    </OrganizationProvider>
  );
};
export default TagManagerModal;
