import { Pagination } from '@holokit/core';
import { resetQueryAndSorting, setCurrentPage } from 'common-js/reducers/devices/actions';
import {
  getCurrentPage,
  getDevicesPageLimit,
  getDevicesPerPage,
  getTotalDevices,
  getTotalPages,
} from 'common-js/reducers/devices/selectors';
import { removeQueryStringParameter } from 'common-js/utils/url';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class DevicesPagination extends Component {
  onFastForwardClick = () => {
    const { currentPage, setCurrentPage_, totalPages } = this.props;

    if (totalPages !== currentPage) {
      setCurrentPage_(totalPages);
    }
  };

  onNextClick = () => {
    const { currentPage, setCurrentPage_, pageLimit, totalPages } = this.props;

    const nextPage = currentPage + 1;
    if (currentPage !== nextPage && nextPage <= totalPages) {
      const blockFetch = pageLimit === currentPage;
      setCurrentPage_(nextPage, blockFetch);
    }
  };

  onPreviousClick = () => {
    const { currentPage, setCurrentPage_ } = this.props;

    const nextPage = currentPage - 1;

    if (currentPage !== nextPage && nextPage > 0) {
      setCurrentPage_(currentPage - 1);
    }
  };

  onRewindClick = () => {
    const { currentPage, setCurrentPage_ } = this.props;

    if (currentPage !== 1) {
      setCurrentPage_(1);
    }
  };

  onResetClick = () => {
    const { resetQueryAndSorting_ } = this.props;

    removeQueryStringParameter('query');
    resetQueryAndSorting_();
  };

  render() {
    const { totalDevices, currentPage, devicesPerPage, orgTotalDevices, pageLimit, totalPages } =
      this.props;

    return (
      <Pagination
        classes="DevicesTable__pagination"
        itemLabel="Devices"
        isFuzzy={orgTotalDevices > 10000}
        itemTotal={totalDevices}
        onFastForwardClick={this.onFastForwardClick}
        onNextClick={this.onNextClick}
        onPreviousClick={this.onPreviousClick}
        onResetClick={this.onResetClick}
        onRewindClick={this.onRewindClick}
        pageCurrent={currentPage}
        pageLimit={pageLimit}
        pageSize={devicesPerPage}
        totalPages={totalPages}
      />
    );
  }
}

DevicesPagination.propTypes = {
  totalDevices: PropTypes.number,
  currentPage: PropTypes.number,
  devicesPerPage: PropTypes.number,
  totalPages: PropTypes.number,
};

const mapStateToProps = (state) => ({
  currentPage: getCurrentPage(state),
  devicesPerPage: getDevicesPerPage(state),
  pageLimit: getDevicesPageLimit(state),
  totalDevices: getTotalDevices(state),
  totalPages: getTotalPages(state),
  releaseFlags: state.releaseFlag,
  orgTotalDevices: state.organization.deviceCount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentPage_: setCurrentPage,
      resetQueryAndSorting_: resetQueryAndSorting,
    },
    dispatch
  );

const DevicesPaginationHoC = connect(mapStateToProps, mapDispatchToProps)(DevicesPagination);

export default DevicesPaginationHoC;
