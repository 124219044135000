import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';

const useGetDeviceProfiles = () => {
  const apiCall = useApi.call('/links/cellular?:searchParams');
  return useCallback(
    async (deviceId) => {
      const searchParams = new URLSearchParams({
        witheuicc: 1,
        deviceid: deviceId,
      });
      const { data } = await apiCall({
        urlParams: { searchParams: searchParams.toString() },
      });
      return data;
    },
    [apiCall]
  );
};

export default useGetDeviceProfiles;
