export const VIEWMODE_PANEL = 'VIEWMODE_PANEL';
export const VIEWMODE_LIST = 'VIEWMODE_LIST';

export const SORTBY_NAME_DES = 'SORTBY_NAME_DES';
export const SORTBY_NAME_ASC = 'SORTBY_NAME_ASC';

export const SORTBY_STATUS_ASC = 'SORTBY_STATUS_ASC';
export const SORTBY_STATUS_DES = 'SORTBY_STATUS_DES';

export const SORTBY_ACTIONTYPE_ASC = 'SORTBY_ACTIONTYPE_ASC';
export const SORTBY_ACTIONTYPE_DES = 'SORTBY_ACTIONTYPE_DES';
