import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button } from '@holokit/core';
import { MessagePanel } from 'common-js/components';
import { Input, Select, Submit } from 'common-js/components/form';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import useUpdateWebhook from 'common-js/api/devices/useUpdateWebhook';
import useCreateWebhook from 'common-js/api/devices/useCreateWebhook';
import useCopyElement from './useCopyElement';

const UpdateWebhookForm = ({ webhook, setWebhook, onCancelUpdate }) => {
  const hasWebhook = webhook.url !== '';
  const device = useAppSelector(selectCurrentDevice);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      port: hasWebhook ? webhook.port : '',
      protocol: hasWebhook ? webhook.protocol : 'TCP',
    },
  });
  const [formProcessing, setFormProcessing] = useState(false);
  const [serverError, setServerError] = useState(null);
  const webhookUrlRef = useRef(null);
  const onCopyWebhookUrl = useCopyElement(webhookUrlRef, 'Webhook url');
  const updateWebhook = useUpdateWebhook();
  const createWebhook = useCreateWebhook();

  const onFormSubmit = useCallback(
    async ({ port, protocol }) => {
      setFormProcessing(true);
      try {
        const webhookFn = hasWebhook ? updateWebhook : createWebhook;
        const hook = await webhookFn(device.id, port, protocol);
        setTimeout(() => {
          setWebhook(hook);
          onCancelUpdate();
        });
      } catch (err) {
        setServerError(err);
      }
      setFormProcessing(false);
    },
    [
      createWebhook,
      device.id,
      hasWebhook,
      onCancelUpdate,
      setFormProcessing,
      setWebhook,
      updateWebhook,
    ]
  );

  return (
    <form className="form Panel__padding" onSubmit={handleSubmit(onFormSubmit)} noValidate>
      {hasWebhook && (
        <div className="form-row grid-row vertical-align section">
          <div className="type-paragraph grid-item">
            <div className="grid-row section-name">Webhook url</div>
          </div>
          <div className="grid-item-3">
            <div className="grid-row vertical-align">
              <div className="grid-item webhook-url f6">{webhook.url}</div>
              <Button onClick={onCopyWebhookUrl} type="secondary">
                Copy URL
              </Button>
              <textarea ref={webhookUrlRef} value={webhook.url} className="hidden" readOnly />
            </div>
          </div>
        </div>
      )}
      <div className="form-row grid-row vertical-align section">
        <div className="type-paragraph grid-item">
          <div className="grid-row section-name">Port</div>
        </div>
        <div className="grid-item-3">
          <Input type="text" classNames="grid-item-2" {...register('port')} />
        </div>
      </div>
      <div className="grid-row vertical-align section">
        <div className="type-paragraph grid-item">
          <div className="grid-row section-name">Protocol</div>
        </div>
        <div className="grid-item-3">
          <Select {...register('protocol')}>
            <option value="TCP">TCP</option>
            <option value="UDP">UDP</option>
          </Select>
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-item label-text" />
        <div className="grid-item-3">
          {serverError && (
            <MessagePanel fullWidth messageType="error">
              {serverError}
            </MessagePanel>
          )}
          <Submit
            classNames="btn-medium"
            label={hasWebhook ? 'Update' : 'Generate webhook'}
            formProcessing={formProcessing}
          />
          <Button onClick={onCancelUpdate} type="secondary">
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UpdateWebhookForm;
