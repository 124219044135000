import React from 'react';
import classNames from 'clsx';

interface PackageCardProps {
  packageInfo: { name: string; description: string };
  selected?: boolean;
}

const PackageCard = ({ packageInfo, selected }: PackageCardProps) => {
  const { name, description } = packageInfo;
  return (
    <div>
      <div className={classNames(name, 'top-border-only')} />
      <div className="PackageCard">
        {selected && <span className="PackageCard__ribbon">Current plan</span>}
        <p className="PackageCard--heading">{name}</p>
        <p className="PackageCard--description">{description}</p>
      </div>
    </div>
  );
};

export default PackageCard;
