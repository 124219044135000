import { Loader } from '@holokit/core';
import _classNames from 'clsx';
import {
  ContextSwitcherOverlay,
  Drawer,
  GlobalMessage,
  MainMenu,
  Modal,
} from 'common-js/components';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { getSelectedOrgIndex } from 'common-js/reducers/organization/selectors';
import { selectSearchModalOpen } from 'common-js/reducers/search/selectors';
import CloseButton from 'common-js/toasts/CloseButton';
import { FC, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import SearchModal from 'search/components/SearchModal';
import { Console, ConsoleFilters } from '../console';

interface BaseLayoutProps {
  children?: ReactNode;
  isLoading?: boolean;
  layoutName?: string;
  params?: any;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children, isLoading = false, layoutName, params }) => {
  const isSearchModalOpen = useAppSelector(selectSearchModalOpen);
  const isDrawerMinimized = useAppSelector((state) => !!state.drawer?.isMinimized);
  const isMenuCollapsed = useAppSelector(
    (state) => state.account?.settings?.menu_collapsed === 'yes'
  );
  const drawerTakeover = useAppSelector((state) => !!state.drawer?.drawerTakeover);
  const selectedOrgIndex = useAppSelector(getSelectedOrgIndex);

  return (
    <ContextSwitcherOverlay>
      <div className={_classNames('container', 'Layout', `selectedOrgIdx${selectedOrgIndex % 7}`)}>
        <Modal params={params} />
        {isSearchModalOpen && <SearchModal />}
        {!drawerTakeover && (
          <div
            className={_classNames('main-sidebar', {
              'main-sidebar--collapsed': isMenuCollapsed,
            })}
          >
            <MainMenu params={params} layoutName={layoutName} />
          </div>
        )}
        <div className="main-content">
          <Loader isLoading={isLoading} />
          <GlobalMessage />
          {children}
          <Drawer fadetowhite>
            <ConsoleFilters />
            <Console pollLogData={!isDrawerMinimized} />
          </Drawer>
        </div>
        <div>
          <ToastContainer
            position="bottom-right"
            type="warning"
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            pauseOnHover
            closeButton={<CloseButton />}
          />
        </div>
      </div>
    </ContextSwitcherOverlay>
  );
};

export default BaseLayout;
