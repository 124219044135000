import { Button } from '@holokit/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeaderWithIcon } from '../components';
import * as modalActions from '../reducers/modal/actions';

function AutoRefillDisabledModal(props) {
  const { closeModal } = props;
  return (
    <div className="AutoRefillDisabledModal">
      <ModalHeaderWithIcon title="Auto-refill disabled" />
      <div className="modal-content">
        <p>
          Auto-refill has been disabled. If your balance drops to zero, your devices may be
          automatically paused.
        </p>
        <p>Re-enable auto-refill to recharge your account should it drop below a set threshold.</p>
      </div>
      <div className="modal-buttons">
        <Button type="primary" onClick={closeModal}>
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ closeModal: modalActions.close }, dispatch),
});

export default connect(null, mapDispatchToProps)(AutoRefillDisabledModal);
