import React, { useMemo } from 'react';
import { Dropdown } from '@holokit/core';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Icon } from '@hologram-dimension/icon';
import { Button } from '@hologram-dimension/button';
import { browserHistory } from 'react-router';
import * as Paths from 'common-js/constants/paths';
import selectCurrentDeviceTags from './selectCurrentDeviceTags';
import useDeviceTagDropdownItems from './useDeviceTagDropdownItems';

const onManageTagsButtonClick = () =>
  browserHistory.push(Paths.withContext(Paths.DEVICES_TAG_MANAGER));

const DeviceTagDropdown = ({ device }) => {
  const deviceTags = useAppSelector(selectCurrentDeviceTags);
  const items = useDeviceTagDropdownItems(device);

  const dropdownText = useMemo(
    () =>
      deviceTags.length
        ? deviceTags.map((tag) => (
            <span className="DeviceTagDropdown__tag" key={tag.id}>
              {tag.name}
            </span>
          ))
        : 'Add a tag...',
    [deviceTags]
  );

  return (
    <div className="DeviceTagDropdown">
      <div className="floating-label">TAGS</div>
      <Dropdown
        dropdownText={dropdownText}
        items={items}
        footer={
          <div className="DeviceTagDropdown__footer">
            <Button
              type="button"
              variant="secondary"
              className="DeviceTagDropdown__footer__button"
              onClick={onManageTagsButtonClick}
              iconStart={<Icon name="Gear" />}
            >
              Manage tags
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default DeviceTagDropdown;
