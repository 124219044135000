import { Button } from '@hologram-dimension/button';
import { ComplexIcon } from '@holokit/core';
import useOnButtonClickWithAnalytics from 'common-js/analytics/useOnButtonClickWithAnalytics';
import React from 'react';

const SearchModalError: React.FC = () => {
  const handleClick = useOnButtonClickWithAnalytics({ pageName: 'Search' });

  return (
    <div className="SearchModal__no-results">
      <div className="SearchModal__no-results__icon">
        <ComplexIcon name="search-error" />
      </div>
      <div className="SearchModal__no-results__caption">We&rsquo;ve encountered an error</div>
      <div className="SearchModal__no-results__text">
        Something went wrong on our end. Please try your search again.
      </div>
      <Button
        className="SearchModal__no-results__link"
        variant="tertiary"
        onClick={() => {
          handleClick(
            'Error - Learn more search tips',
            'https://support.hologram.io/hc/en-us/articles/1500001779421',
            '_blank'
          );
        }}
      >
        Learn more search tips
      </Button>
    </div>
  );
};

export default SearchModalError;
