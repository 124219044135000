import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { RadioField } from '@hologram-dimension/radio-field';
import { TextInputField } from '@hologram-dimension/text-input-field';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { close } from 'common-js/reducers/modal/actions';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ModalHeader } from '../components';

const AUTH_TYPES = { BASIC: 'BASIC' } as const;

type AuthTypes = typeof AUTH_TYPES;

interface AuthHeaderModalProps {
  onSubmit: (data: Array<{ key: string; value: string }>) => void;
}

interface FormValues {
  basicAuthValues: {
    username: string;
    password: string;
  };
  selectedType: AuthTypes[keyof AuthTypes];
}

const AuthHeaderModal: React.FC<AuthHeaderModalProps> = ({ onSubmit }) => {
  const { register, handleSubmit, getValues } = useForm<FormValues>({
    defaultValues: {
      basicAuthValues: {
        username: '',
        password: '',
      },
      selectedType: AUTH_TYPES.BASIC,
    },
  });

  const dispatch = useAppDispatch();
  const closeModal = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onFormSubmit = useCallback<SubmitHandler<FormValues>>(
    (data) => {
      if (data.selectedType === AUTH_TYPES.BASIC) {
        onSubmit([
          {
            key: 'Authorization',
            value: `Basic ${btoa(`${data.basicAuthValues.username} ${data.basicAuthValues.password}`)}`,
          },
        ]);
        closeModal();
      }
    },
    [closeModal, onSubmit]
  );
  const submit = handleSubmit(onFormSubmit);

  const { selectedType } = getValues();

  return (
    <div className="AuthHeaderModal">
      <ModalHeader title="Add auth header" />
      <Form
        onSubmit={submit}
        className="AuthHeaderModal-Form"
        footerActions={
          <>
            <Button onClick={submit} type="submit">
              Submit
            </Button>
            <Button onClick={closeModal} variant="secondary">
              Cancel
            </Button>
          </>
        }
      >
        <RadioField
          {...register('selectedType', { required: true })}
          id="selectedType"
          label="Auth type"
          defaultValue={selectedType}
          options={[{ label: 'Basic auth', value: AUTH_TYPES.BASIC }]}
        />
        {selectedType === AUTH_TYPES.BASIC ? (
          <>
            <TextInputField
              {...register('basicAuthValues.username', { required: true })}
              label="Username"
              type="text"
              id="username"
            />
            <TextInputField
              {...register('basicAuthValues.password', { required: true })}
              label="Password"
              type="password"
              id="password"
            />
          </>
        ) : null}
      </Form>
    </div>
  );
};

export default AuthHeaderModal;
