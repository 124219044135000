import React from 'react';

class Label extends React.PureComponent {
  render() {
    return (
      <div className={`Label ${this.props.type ? this.props.type : ''}`}>{this.props.children}</div>
    );
  }
}

export default Label;
