import type { MessageType } from 'common-js/components/FormPanel';
import queryStringMessages from './queryStringMessages';

const getLoginFormMessage = ({ search }) => {
  const params = new URLSearchParams(search);
  const origin = params.get('origin');
  const redirectto = params.get('redirectto');

  if (origin === 'orginviteemail') {
    return {
      message: queryStringMessages.orginvite.message,
      messageType: queryStringMessages.orginvite.messageType,
    };
  }
  if (origin === 'adminfail') {
    return {
      message: queryStringMessages.adminfail.message,
      messageType: queryStringMessages.adminfail.messageType,
    };
  }
  if (redirectto && redirectto.includes('download')) {
    return {
      message: queryStringMessages.download.message,
      messageType: queryStringMessages.download.messageType,
    };
  }
  return {
    message: '',
    messageType: 'success' as MessageType,
  };
};

export default getLoginFormMessage;
