import React from 'react';
import { Panel } from '@holokit/core';
import analyticsEventBuilder from 'common-js/analytics';
import ExpandableWell from 'common-js/components/ExpandableWell';
import HyperProfileHistoryError from './HyperProfileHistoryError';
import HyperProfileHistoryTable from './HyperProfileHistoryTable';

const onFooterToggle = (expandedAfterClick) => {
  analyticsEventBuilder
    .buttonClick('SDP Profiles Tab', 'Ed Well', expandedAfterClick ? 'Open' : 'Close')
    .send();
};

const footer = (
  <ExpandableWell
    title="What are bootstrap and operational profiles?"
    content="Cellular networks use a SIM’s profile to authenticate a device and provide access to networks. eUICC technology allows multiple profiles to exist on a single SIM. Every Hyper SIM ships with a preinstalled bootstrap profile. This ensures cellular connectivity is immediately available. An operational profile is added when the eUICC platform downloads and enables a new profile on the SIM."
    contentClassName="HyperProfileHistoryPanel__footer"
    callback={onFooterToggle}
  />
);

const HyperProfileHistoryPanel = ({ profileHistory, device, loading, error }) => (
  <Panel
    title="Profile history"
    noBodyPadding
    isLoading={loading}
    classes="HyperProfileHistoryPanel"
    bodyClasses="HyperProfileHistoryPanel__body"
    footer={footer}
  >
    {error ? (
      <HyperProfileHistoryError />
    ) : (
      <HyperProfileHistoryTable profileHistory={profileHistory} device={device} />
    )}
  </Panel>
);

export default HyperProfileHistoryPanel;
