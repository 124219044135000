import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import TopMenu from 'common-js/components/menu/TopMenu';
import {
  ADMIN_IMPERSONATE,
  ADMIN_RELEASEFLAGS,
  pathMatches,
  withContext,
} from 'common-js/constants/paths';
import React from 'react';

const SubMenu: React.FC = () => (
  <TopMenu rightContent={<SubMenuRightContent />} titleHref={withContext(ADMIN_IMPERSONATE)}>
    <TopMenu.Item
      text="Impersonate user"
      active={pathMatches(ADMIN_IMPERSONATE)}
      href={withContext(ADMIN_IMPERSONATE)}
    />
    <TopMenu.Item
      text="Release flags"
      active={pathMatches(ADMIN_RELEASEFLAGS)}
      href={withContext(ADMIN_RELEASEFLAGS)}
    />
  </TopMenu>
);

export default SubMenu;
