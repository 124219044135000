import { Drawer } from '@blueprintjs/core';
import { Button, Icon } from '@holokit/core';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import Moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { DateTagFilter, HoloHelmet } from '../../common/components';
import * as Paths from '../../common/constants/paths';
import {
  deselectAllTags,
  getTags,
  selectAllTags,
  toggleTag,
} from '../../common/reducers/deviceFilter/actions';
import {
  exportDataUsedInspect,
  getDataUsed,
  getDataUsedInspect,
  getDeviceBreakdown,
  runReport,
  setCustomDate,
  setQuickFilter,
  syncFiltersToQueryString,
} from '../../common/reducers/usage/actions';
import InspectDrawer from '../components/InspectDrawer';
import { DataUsedChartWrapper, DataUsedInspectTable, UsageBreakdownByDeviceTable } from './parts';

class InspectDataUsed extends React.Component {
  constructor(props) {
    super(props);

    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Usage - Inspect' } });
    this.state = {
      redirecting: true,
      detailsDrawerOpen: false,
      isDateLongTerm: true,
    };
  }

  componentDidMount() {
    const { syncFiltersToQueryString_, getDataUsed_, getDataUsedInspect_ } = this.props;
    syncFiltersToQueryString_().finally(() => {
      getDataUsed_();
      getDataUsedInspect_();
      this.setState({ redirecting: false });
    });
  }

  openModal(isLongTerm) {
    const { getDeviceBreakdown_ } = this.props;
    getDeviceBreakdown_('filtersDataUsedDetails');

    this.setState({
      detailsDrawerOpen: true,
      isDateLongTerm: isLongTerm,
    });
  }

  render() {
    const { redirecting, detailsDrawerOpen, isDateLongTerm } = this.state;
    const {
      dataUsedDetailsStartDate,
      deselectAllTags_,
      endDate,
      exportDataUsedInspect_,
      runningReport,
      runReport_,
      selectAllTags_,
      selectedTags,
      setCustomDate_,
      setQuickFilter_,
      startDate,
      tags,
      timeQuickFilter,
      toggleTag_,
    } = this.props;

    if (redirecting) return null;

    return (
      <div>
        <HoloHelmet title="Usage Reports - Total Data Used" />
        <InspectDrawer
          topFloat={
            <div>
              <div className="inspect-title">
                <div className="inner grid-row">
                  <div className="grid-item">Total Data Used</div>
                  <Button onClick={exportDataUsedInspect_} small type="secondary">
                    Export
                  </Button>
                </div>
              </div>
              <div className="InspectFilters inspect-filters">
                <div className="inner grid-row v-align">
                  <div className="header">Filter</div>
                  <DateTagFilter
                    deselectAllTags={deselectAllTags_}
                    disabled={runningReport}
                    endDate={endDate}
                    onRunReport={runReport_}
                    runningReport={runningReport}
                    selectAllTags={selectAllTags_}
                    selectedTags={selectedTags}
                    setCustomDate={setCustomDate_}
                    setTimeQuickFilter={setQuickFilter_}
                    startDate={startDate}
                    tags={tags}
                    timeQuickFilter={timeQuickFilter}
                    toggle
                    toggleTag={toggleTag_}
                  />
                </div>
              </div>
            </div>
          }
          onClose={() => browserHistory.push(Paths.withContext(Paths.USAGE_OVERVIEW))}
        >
          <div className="inspect-content">
            <DataUsedChartWrapper fromInspect />
            <DataUsedInspectTable
              openModal={(isLongTerm, data) => this.openModal(isLongTerm, data)}
              fromInspect
            />
          </div>
        </InspectDrawer>
        <Drawer
          isOpen={detailsDrawerOpen}
          canOutsideClickClose={false}
          size="70%"
          onClose={() => this.setState({ detailsDrawerOpen: false })}
        >
          <div className="InspectDrawer">
            <div className="drawer fullscreen">
              <div className="top-bar">
                <div className="grid-item">
                  Data Used By Device on{' '}
                  {Moment.utc(dataUsedDetailsStartDate).format('MMMM D, YYYY')}
                  {isDateLongTerm === false &&
                    `at ${Moment.utc(dataUsedDetailsStartDate).format('h:ssA')} UTC`}
                </div>
                <div
                  className="close-btn"
                  onClick={() => this.setState({ detailsDrawerOpen: false })}
                  onKeyDown={() => this.setState({ detailsDrawerOpen: false })}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="Close" size="minor" svgProps={{ style: { fill: '#040815' } }} />
                </div>
              </div>
              <div className="drawer-window">
                {detailsDrawerOpen && <UsageBreakdownByDeviceTable />}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataUsedDetailsStartDate: new Date(state.usage.filtersDataUsedDetails.startDate),
    startDate: new Date(state.usage.filters.startDate),
    endDate: new Date(state.usage.filters.endDate),
    selectedTags: state.deviceFilters.selectedTags,
    tags: state.deviceFilters.tags,
    runningReport: state.usage.runningReport,
    timeQuickFilter: state.usage.timeQuickFilter,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        deselectAllTags_: deselectAllTags,
        exportDataUsedInspect_: exportDataUsedInspect,
        getDataUsed_: getDataUsed,
        getDataUsedInspect_: getDataUsedInspect,
        getDeviceBreakdown_: getDeviceBreakdown,
        getTags,
        runReport_: runReport,
        selectAllTags_: selectAllTags,
        setCustomDate_: setCustomDate,
        setQuickFilter_: setQuickFilter,
        syncFiltersToQueryString_: syncFiltersToQueryString,
        toggleTag_: toggleTag,
      },
      dispatch
    )
)(InspectDataUsed);
