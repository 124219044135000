import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Callout } from '@holokit/core';
import { BMPContactUsButton } from 'common-js/components';
import * as DeviceStates from 'common-js/constants/deviceStates';
import { selectBmpPredeploymentTestingFeatureLevel } from 'common-js/reducers/releaseFlag/selectors';
import selectDeviceMetaInfo from './selectDeviceMetaInfo';

const BMPUpsellCallout = () => {
  const bmpFlag = useAppSelector(selectBmpPredeploymentTestingFeatureLevel);
  const deviceMetaInfo = useAppSelector(selectDeviceMetaInfo);
  const isPreflight = DeviceStates.preflightStates.includes(deviceMetaInfo.deviceState);

  if (bmpFlag !== 'plus' || !isPreflight) {
    return null;
  }

  return (
    <Callout
      className="DeviceStatusOverview__upsell-callout"
      text="To get 12 months of pre-deployment testing, upgrade to Hologram Pro."
      rightElement={<BMPContactUsButton variant="secondary" />}
    />
  );
};

export default BMPUpsellCallout;
