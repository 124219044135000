import { Link } from '@hologram-dimension/link';
import { Sidebar, SidebarItem } from '@holokit/core';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import {
  ADMIN_DEBUGDEVICE,
  ADMIN_IMPERSONATE,
  ADMIN_RELEASEFLAGS,
  APPS_OVERVIEW,
  BILLING_ACCOUNT,
  BILLING_HISTORY,
  BILLING_ORDERS,
  BILLING_STATEMENTS,
  DEVICES_ACTIVITY,
  DEVICES_ACTIVITY_HISTORY_DETAILS,
  DEVICES_DEACTIVATED,
  DEVICES_DEVICES,
  DEVICES_PLANS,
  DEVICES_PREFLIGHT,
  DEVICES_TAG_MANAGER,
  HOME_OVERVIEW,
  pathMatches,
  pathMatchesWithIdsReplaced,
  REPORTS_DETAIL,
  REPORTS_OVERVIEW,
  SETTINGS_ACCOUNT,
  SETTINGS_API,
  SETTINGS_LANDING,
  SETTINGS_ORG,
  SIM_ACTIVITY,
  SIM_ACTIVITY_HISTORY_DETAILS,
  SIM_DETAILS,
  SIM_INVENTORY,
  SIM_INVENTORY_ACTIVE,
  SIM_INVENTORY_DEACTIVATED,
  SIM_INVENTORY_PREDEPLOYMENT,
  SIM_PLANS,
  SIM_PROFILES,
  SIM_SIMS_AND_DEVICES,
  SIM_TAG_MANAGER,
  USAGE_BREAKDOWN,
  USAGE_BY_DAY,
  USAGE_BY_SESSION,
  USAGE_BY_SIM,
  USAGE_BY_SIM_AND_DAY,
  USAGE_LIVE,
  USAGE_OVERVIEW,
  USAGE_PREDEPLOYMENT,
  withContext,
} from 'common-js/constants/paths';
import { setUserSetting } from 'common-js/reducers/account/actions';
import { selectUserIsInternal } from 'common-js/reducers/account/selectors';
import { minimizeDrawer } from 'common-js/reducers/drawer/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import {
  selectHasSimsPages,
  selectPromoteSimsPages,
  selectIsBmpCustomer,
} from 'common-js/reducers/releaseFlag/selectors';
import { BILLING_VISIBLE } from 'common-js/utils/permissions';
import logoWhiteWithText from 'img/logo/hologram_logo_white.svg';
import logoWhite from 'img/logo/logo_white.svg';
import AdminImg from 'img/navigation/Admin.svg';
import BillingImg from 'img/navigation/Billing.svg';
import DevicesImg from 'img/navigation/Devices.svg';
import HomeImg from 'img/navigation/Home.svg';
import ReportsImg from 'img/navigation/Reports.svg';
import RoutesImg from 'img/navigation/Routes.svg';
import SettingsImg from 'img/navigation/Settings.svg';
import SIMsImg from 'img/navigation/SIMs.svg';
import SupportImg from 'img/navigation/Support.svg';
import UsageImg from 'img/navigation/Usage.svg';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';

const devicesHighlighted = () => {
  const pages = [
    DEVICES_DEVICES,
    DEVICES_PREFLIGHT,
    DEVICES_DEACTIVATED,
    DEVICES_PLANS,
    DEVICES_TAG_MANAGER,
    DEVICES_ACTIVITY,
  ];
  const matched = pages.filter((page) => pathMatches(page));

  const subpages = [DEVICES_ACTIVITY_HISTORY_DETAILS];
  const matchedSubpages = subpages.filter((page) => pathMatchesWithIdsReplaced(page));

  const singleDevice = window.location.pathname.includes('/device/');

  return (
    (matched.length > 0 || matchedSubpages.length > 0 || singleDevice) &&
    window.location.search.indexOf('drawer=full') === -1
  );
};

const simsHighlighted = () => {
  const pages = [
    SIM_INVENTORY,
    SIM_INVENTORY_ACTIVE,
    SIM_INVENTORY_DEACTIVATED,
    SIM_INVENTORY_PREDEPLOYMENT,
    SIM_DETAILS,
    SIM_PROFILES,
    SIM_SIMS_AND_DEVICES,
    SIM_PLANS,
    SIM_TAG_MANAGER,
    SIM_ACTIVITY,
    SIM_ACTIVITY_HISTORY_DETAILS,
  ];
  const matched = pages.filter((page) => pathMatchesWithIdsReplaced(page));

  return matched.length > 0 && window.location.search.indexOf('drawer=full') === -1;
};

const pageMatchesList = (pages) => {
  const matched = pages.filter((page) => pathMatches(page));

  return matched.length > 0 && window.location.search.indexOf('drawer=full') === -1;
};

const reportsHighlighted = () => pageMatchesList([REPORTS_OVERVIEW, REPORTS_DETAIL]);

const usageHighlighted = () =>
  pageMatchesList([
    USAGE_OVERVIEW,
    USAGE_BREAKDOWN,
    USAGE_LIVE,
    USAGE_BY_DAY,
    USAGE_BY_SESSION,
    USAGE_BY_SIM,
    USAGE_BY_SIM_AND_DAY,
    USAGE_PREDEPLOYMENT,
  ]);

const billingHighlighted = () =>
  pageMatchesList([BILLING_ACCOUNT, BILLING_STATEMENTS, BILLING_HISTORY, BILLING_ORDERS]);

const settingsHighlighted = () => pageMatchesList([SETTINGS_ORG, SETTINGS_ACCOUNT, SETTINGS_API]);

const adminHighlighted = () =>
  pageMatchesList([ADMIN_IMPERSONATE, ADMIN_RELEASEFLAGS, ADMIN_DEBUGDEVICE]);

class MainMenu extends React.Component<any> {
  onNonConsoleClick = (path: string, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const { minimizeDrawer_, isDrawerFullscreen, isInternalUser } = this.props;
    if (isDrawerFullscreen) minimizeDrawer_();

    if (path === REPORTS_OVERVIEW) {
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Reports - Main Nav',
        },
      });
    }

    if (!isInternalUser && pathMatches(path, SIM_INVENTORY)) {
      browserHistory.push(withContext(SIM_INVENTORY_ACTIVE));
    } else {
      browserHistory.push(withContext(path));
    }
  };

  static onExternalClick = (
    path: string,
    destination: 'Feedback' | 'Support',
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: `${destination} - Main Nav`,
      },
    });

    window.open(path, '_blank');
  };

  toggleMenuExpand = () => {
    const { isMenuCollapsed, setUserSetting_ } = this.props;

    setUserSetting_({
      key: 'menu_collapsed',
      value: isMenuCollapsed ? 'no' : 'yes',
    });
  };

  homeHighlighted = () => {
    const { layoutName } = this.props;
    return layoutName === 'home';
  };

  routesHighlighted = () => {
    const { layoutName } = this.props;
    return layoutName === 'routes';
  };

  render() {
    const {
      isAdmin,
      isBmpCustomer,
      isMenuCollapsed,
      releaseFlags,
      userPermissions,
      hasPageSimInventoryFlag,
      hasPromoteSimInventoryFlag,
      openModal_,
      hasEureka0,
    } = this.props;
    const { embedded_looker_reporting: embeddedLookerReportingFlag, fleet, routes } = releaseFlags;

    const hasBillingVisible = userPermissions.includes(BILLING_VISIBLE);
    const simInventoryLink = isAdmin ? SIM_INVENTORY : SIM_INVENTORY_ACTIVE;

    const reportHref = hasPromoteSimInventoryFlag
      ? withContext(USAGE_BY_DAY)
      : withContext(REPORTS_OVERVIEW);

    const simInventoryMenuItem = (
      <SidebarItem
        text="SIMs"
        subtext="Beta"
        iconName={<img src={SIMsImg} alt="" className="menu-Icon" />}
        active={simsHighlighted()}
        onClick={(e) => this.onNonConsoleClick(simInventoryLink, e)}
        href={withContext(simInventoryLink)}
        hidden={!hasPageSimInventoryFlag}
      />
    );

    let defaultPath;
    if (hasEureka0) {
      defaultPath = HOME_OVERVIEW;
    } else if (hasPromoteSimInventoryFlag) {
      defaultPath = SIM_INVENTORY;
    } else {
      defaultPath = DEVICES_DEVICES;
    }

    return (
      <Sidebar
        isCollapsed={isMenuCollapsed}
        collapseSidebar={this.toggleMenuExpand}
        logoIcon={
          <Link to={withContext(defaultPath)}>
            {
              (
                <img
                  src={isMenuCollapsed ? logoWhite : logoWhiteWithText}
                  style={{ height: '26px' }}
                  alt="Hologram logo"
                />
              ) as any
            }
          </Link>
        }
        footerActions={
          <SidebarItem
            text="Support"
            iconName={<img src={SupportImg} alt="" className="menu-Icon" />}
            onClick={() => openModal_('SupportModal')}
          />
        }
      >
        <SidebarItem
          text="Home"
          iconName={<img src={HomeImg} alt="" className="menu-Icon" />}
          active={this.homeHighlighted()}
          onClick={(e) => this.onNonConsoleClick(HOME_OVERVIEW, e)}
          href={withContext(HOME_OVERVIEW)}
          hidden={!fleet}
        />
        {!!hasPromoteSimInventoryFlag && simInventoryMenuItem}
        <SidebarItem
          text="Devices"
          iconName={<img src={DevicesImg} alt="" className="menu-Icon" />}
          active={devicesHighlighted()}
          onClick={(e) => this.onNonConsoleClick(DEVICES_DEVICES, e)}
          href={withContext(DEVICES_DEVICES)}
        />
        {!hasPromoteSimInventoryFlag && simInventoryMenuItem}
        <SidebarItem
          text="Reports"
          subtext="Beta"
          iconName={<img src={ReportsImg} alt="" className="menu-Icon" />}
          active={reportsHighlighted()}
          onClick={(e) => this.onNonConsoleClick(REPORTS_OVERVIEW, e)}
          href={reportHref}
          hidden={!embeddedLookerReportingFlag || isBmpCustomer}
        />
        <SidebarItem
          text="Usage"
          iconName={<img src={UsageImg} alt="" className="menu-Icon" />}
          active={usageHighlighted()}
          onClick={(e) =>
            this.onNonConsoleClick(
              hasPromoteSimInventoryFlag ? withContext(USAGE_BY_DAY) : withContext(USAGE_OVERVIEW),
              e
            )
          }
          href={
            hasPromoteSimInventoryFlag ? withContext(USAGE_BY_DAY) : withContext(USAGE_OVERVIEW)
          }
        />
        <SidebarItem
          text="Routes"
          iconName={<img src={RoutesImg} alt="" className="menu-Icon" />}
          active={this.routesHighlighted()}
          onClick={(e) => this.onNonConsoleClick(APPS_OVERVIEW, e)}
          href={withContext(APPS_OVERVIEW)}
          hidden={!routes}
        />
        <SidebarItem
          text="Billing"
          iconName={<img src={BillingImg} alt="" className="menu-Icon" />}
          active={billingHighlighted()}
          onClick={(e) => this.onNonConsoleClick(BILLING_ACCOUNT, e)}
          href={withContext(BILLING_ACCOUNT)}
          hidden={!hasBillingVisible}
        />
        <SidebarItem
          text="Settings"
          iconName={<img src={SettingsImg} alt="" className="menu-Icon" />}
          active={settingsHighlighted()}
          onClick={(e) => this.onNonConsoleClick(SETTINGS_LANDING, e)}
          href={withContext(SETTINGS_LANDING)}
        />
        <hr style={{ width: '90%', borderBottom: '1px solid #454A52' }} />
        <SidebarItem
          text="Admin"
          iconName={<img src={AdminImg} alt="" className="menu-Icon" />}
          active={adminHighlighted()}
          onClick={(e) => this.onNonConsoleClick(ADMIN_IMPERSONATE, e)}
          href={withContext(ADMIN_IMPERSONATE)}
          hidden={!isAdmin}
        />
      </Sidebar>
    );
  }
}

const MainMenuHOC = connect(
  (state: any, props: any) => ({
    isAdmin: state.account.role === 'adm' || state.account.role === 'hdm',
    isBmpCustomer: selectIsBmpCustomer(state),
    hasPageSimInventoryFlag: selectHasSimsPages(state),
    hasPromoteSimInventoryFlag: selectPromoteSimsPages(state),
    hasEureka0: state.releaseFlag.eureka0,
    isDrawerFullscreen: state.drawer.isFullscreen,
    isMenuCollapsed: state.account.settings.menu_collapsed === 'yes',
    params: props.params, // Do not remove - helps menu re-render enough to look performant.
    releaseFlags: state.releaseFlag,
    userPermissions: getUserPermissions(state),
    isInternalUser: selectUserIsInternal(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        minimizeDrawer_: minimizeDrawer,
        setUserSetting_: setUserSetting,
        openModal_: openModal,
      },
      dispatch
    )
)(MainMenu);

export default MainMenuHOC;
