import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleSelectionPage, ensureDevicesPersisted } from 'common-js/reducers/devices/actions';
import {
  getLoadedDevices,
  getSelectedDeviceState,
  getSelectedPages,
  getCurrentPage,
} from 'common-js/reducers/devices/selectors';
import ManageDropdown from '../ManageDropdown';
import UpdateTagDropdown from './UpdateTagDropdown';
import BulkSelect from './BulkSelect';
import ActivationButton from './ActivationButton';

const DeviceActions = ({ hasBulkSelect, deviceType, hideActivateButton, selected }) => (
  <div className="DeviceActions">
    <div className="DeviceActions__main DeviceActions__main">
      <ManageDropdown deviceType={deviceType} />

      <UpdateTagDropdown selectedDevices={selected.byId} />

      {!hideActivateButton && <ActivationButton deviceType={deviceType} />}
    </div>
    <div className="DeviceActions__bulk-actions">
      <BulkSelect pagesSelected={selected.pagesSelected} visible={hasBulkSelect} />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  currentPage: getCurrentPage(state),
  devices: getLoadedDevices(state),
  selected: getSelectedDeviceState(state),
  pagesSelected: getSelectedPages(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSelectionPage_: toggleSelectionPage,
      ensureDevicesPersisted_: ensureDevicesPersisted,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeviceActions);
