import { find } from 'lodash';
import { createSelector } from 'reselect';

const getApps = (state) => state.apps.apps;
const getAppSchemas = (state) => state.apps.appSchemas;

const mergeAppsAndSchemas = createSelector([getApps, getAppSchemas], (apps, appSchemas) => {
  const mergedObjects = apps.map((app) => {
    const appSchema = find(appSchemas, { id: app.appId });

    return { ...app, appSchema };
  });

  return mergedObjects;
});

export { mergeAppsAndSchemas };
