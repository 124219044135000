import React from 'react';
import { useFormContext } from 'react-hook-form';
import analyticsEventBuilder from 'common-js/analytics';
import Footer, { ContinueButton, BackButton, SkipButton } from '../common/scaffolding/Footer';

function DevicePrefixFooter({ nextStep, prevStep, skipStep }) {
  const { watch } = useFormContext();
  const { prefix } = watch('devicePrefix');

  const onContinue = () => {
    analyticsEventBuilder
      .buttonClick('Returning', 'Activation Name Addition')
      .send({ 'names added': prefix });
    nextStep();
  };

  return (
    <Footer
      left={<BackButton onClick={prevStep} />}
      right={
        <>
          <SkipButton onClick={skipStep} />
          <ContinueButton disabled={!prefix} onClick={onContinue} />
        </>
      }
    />
  );
}

export default DevicePrefixFooter;
