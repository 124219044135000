import React from 'react';
import { truncateField } from 'search/util';

const DEVICE_NAME_TRUNCATION_LIMIT = 22;

const HighlightMatch = ({ matches, truncate = false }) => {
  const matchList = truncate ? truncateField(matches, DEVICE_NAME_TRUNCATION_LIMIT) : matches;

  return (
    <span>
      {matchList.map(({ text, highlight }, index) =>
        highlight ? (
          // `text` is often not unique (device names are often similar) so we need something else here
          // eslint-disable-next-line react/no-array-index-key
          <span key={`highlight--${text}-${index}`} className="SearchModalResultItem__highlight">
            {text}
          </span>
        ) : (
          text
        )
      )}
    </span>
  );
};

export default HighlightMatch;
