import { createSelector } from 'reselect';
import { getTags } from 'common-js/reducers/deviceFilter/selectors';

// This will be the most recent tag first, the oldest tag last
const sortTagsByIdDesc = (tag1: Tag, tag2: Tag) => (tag2.id as any) - (tag1.id as any);

const selectTagsForDropdown = createSelector(getTags, (allTags) =>
  (allTags as any).sort(sortTagsByIdDesc)
);

export default selectTagsForDropdown;
