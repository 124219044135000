import analyticsEventBuilder from 'common-js/analytics';
import TagManager from 'devices/components/TagManager';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

const sendTagAnalytics = (action: string) => {
  analyticsEventBuilder
    .buttonClick('Activation', 'Returning', 'Activation Tags')
    .send({ 'Tag action': action });
};

const onCreate = () => sendTagAnalytics('created');
const onEdit = () => sendTagAnalytics('edited');

const Tags = () => {
  const { setValue, watch } = useFormContext();
  const tags = watch('tags.tags');
  const tagIds = tags?.map((tag: Tag) => tag.id);

  const selectTag = useCallback((tag) => setValue('tags.tags', [...tags, tag]), [setValue, tags]);

  const deselectTag = useCallback(
    (tagId) => {
      setValue(
        'tags.tags',
        tags.filter((existingTag: Tag) => existingTag.id !== tagId)
      );
    },
    [setValue, tags]
  );

  const onToggleCheckbox = useCallback(
    (tag, checked) => {
      if (checked) {
        selectTag(tag);
      } else {
        deselectTag(tag.id);
      }
    },
    [deselectTag, selectTag]
  );

  const onDelete = useCallback(
    (tagId) => {
      deselectTag(tagId);
      sendTagAnalytics('removed');
    },
    [deselectTag]
  );

  return (
    <div className="ActivationTagsStep">
      <TagManager
        allowDeletingActiveTags={false}
        className="ActivationTags"
        noBodyPadding
        onToggleCheckbox={onToggleCheckbox}
        onDelete={onDelete}
        onCreate={onCreate}
        onEdit={onEdit}
        selectedTagIds={tagIds}
        showCheckboxes
        showContactUs={false}
        title="Tags"
      />
    </div>
  );
};

export default Tags;
