import HeartbeatMessage from './heartbeatMessage';
import Message from './message';
import ModifierMessage from './modifierMessage';
import OutboundMessage from './outboundMessage';
import { UNKNOWN, OUTBOUND, HEARTBEAT, MODIFIER } from './types';

const detectMessageType = (log) => {
  if (!log.deviceid) {
    if (log.tags[0] && log.tags[0].includes('MOD')) {
      return MODIFIER;
    }
    return HEARTBEAT;
  }
  return OUTBOUND;
};

export {
  detectMessageType,
  HEARTBEAT,
  HeartbeatMessage,
  Message,
  MODIFIER,
  ModifierMessage,
  OUTBOUND,
  OutboundMessage,
  UNKNOWN,
};
