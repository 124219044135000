import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Button, Icon, Tooltip } from '@holokit/core';
import { Input, TextArea, Submit, Select } from '../components/form';
import FormHOC from './FormHOC';
import { formGenerator } from './formGenerator';
import { required, isLength, isNumber } from '../utils/validation/validators';
import { MessagePanel } from '../components';

class SendSmsForm extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    fromNumber: PropTypes.object.isRequired,
    payload: PropTypes.object.isRequired,
    port: PropTypes.object.isRequired,
    protocol: PropTypes.object.isRequired,
    messageType: PropTypes.object.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    formProcessing: PropTypes.bool,
    showValidationErrors: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    serverError: PropTypes.string,
  };

  componentDidMount() {
    const { resetState, setFields } = this.props;

    resetState();

    setFields({
      protocol: 'TCP',
      messageType: 'sms',
      port: '4010',
    });
  }

  onSelectTab(tab) {
    const { resetState, setFields } = this.props;

    resetState();

    setFields({
      messageType: tab,
    });
  }

  render() {
    const {
      form,
      message,
      fromNumber,
      messageType,
      payload,
      port,
      protocol,
      onFormSubmit,
      isSuccessful,
      formProcessing = false,
      showValidationErrors = false,
      serverError = null,
      isDirty,
      showCancelButton = false,
      onCancelClick = _.noop,
    } = this.props;

    return (
      <form
        className="form form-dark form-with-tabs SendSmsForm"
        onSubmit={onFormSubmit}
        noValidate
      >
        <div className="tabs grid-row">
          <div className="grid-item label">Method</div>
          <div className="grid-item-3">
            <label>
              <input
                type="radio"
                value="sms"
                checked={messageType.value === 'sms'}
                name="tabs"
                onChange={(e) => this.onSelectTab(e.currentTarget.value)}
              />
              <span className="radio-circle" />
              SMS
            </label>
            <label>
              <input
                type="radio"
                value="cloud"
                checked={messageType.value === 'cloud'}
                name="tabs"
                onChange={(e) => this.onSelectTab(e.currentTarget.value)}
              />
              <span className="radio-circle" />
              Cloud data
            </label>
          </div>
        </div>
        {messageType.value === 'sms' && (
          <div className="SendSmsForm-panel">
            <div className="grid-row">
              <div className="grid-item label">
                <div className="grid-row">
                  <div>
                    Message
                    <Tooltip
                      content={
                        <div>
                          <p>
                            All SMS messages to your device are FREE! SMS rates only apply to
                            messages sent outbound from your device.
                          </p>
                          <p>
                            Messages sent from the dashboard or API, by default, will contain the
                            "from number" of our internal servers. When your device responds to this
                            number, the message will be inserted into your data logs. To allow your
                            device to respond to a different number, be sure to configure the "from
                            number"- or, alternatively, you can purchase a phone number for your
                            device and text that directly from a phone (i.e. without using the
                            dashboard or API).
                          </p>
                        </div>
                      }
                    >
                      <Icon
                        name="Circle--info"
                        size="minor"
                        svgProps={{ style: { fill: '#8008f7' } }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="grid-item-3">
                <TextArea
                  {...message}
                  type="text"
                  name="message"
                  error={form.message.error}
                  showValidation={showValidationErrors}
                />
              </div>
            </div>
            <div className="grid-row">
              <div className="grid-item label">
                <div>
                  <div>Configure the "from number"</div>
                  <div>
                    (optional)
                    <Tooltip
                      content={
                        <div>
                          Set the "from number" of the SMS, in case you need your device to respond
                          somewhere special (must include country code).
                        </div>
                      }
                    >
                      <Icon
                        name="Circle--info"
                        size="minor"
                        svgProps={{ style: { fill: '#8008f7' } }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="grid-item-3">
                <Input
                  {...fromNumber}
                  type="text"
                  name="fromNumber"
                  error={form.fromNumber.error}
                  showValidation={showValidationErrors}
                  classNames="grid-item-2"
                />
              </div>
            </div>
            <div className="grid-row submit">
              <div className="grid-item label-text" />
              <div className="grid-item-3">
                {isSuccessful && !isDirty && (
                  <MessagePanel fullWidth messageType="success">
                    SMS sent successfully
                  </MessagePanel>
                )}
                {serverError && (
                  <MessagePanel fullWidth messageType="error">
                    {serverError}
                  </MessagePanel>
                )}
                <Submit
                  classNames="btn-brand"
                  label="Send SMS message"
                  formProcessing={formProcessing}
                />
                {showCancelButton && (
                  <Button onClick={onCancelClick} type="secondary">
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
        {(messageType.value === 'cloud' || !messageType.value) && (
          <div className="SendSmsForm-panel">
            <div className="grid-row">
              <div className="grid-item">
                <div className="grid-row label">Data</div>
              </div>
              <div className="grid-item-3">
                <TextArea
                  {...payload}
                  type="text"
                  name="payload"
                  error={form.payload.error}
                  showValidation={showValidationErrors}
                />
              </div>
            </div>
            <div className="grid-row">
              <div className="grid-item">
                <div className="grid-row label">Port</div>
              </div>
              <div className="grid-item-3">
                <Input
                  {...port}
                  type="text"
                  name="port"
                  error={form.port.error}
                  showValidation={showValidationErrors}
                  classNames="grid-item-2"
                />
              </div>
            </div>
            <div className="grid-row">
              <div className="grid-item">
                <div className="grid-row label">Protocol</div>
              </div>
              <div className="grid-item-3">
                <Select
                  {...protocol}
                  name="protocol"
                  error={form.protocol.error}
                  showValidation={showValidationErrors}
                >
                  <option value="TCP">TCP</option>
                  <option value="UDP">UDP</option>
                </Select>
              </div>
            </div>
            <div className="grid-row submit">
              <div className="grid-item label-text" />
              <div className="grid-item-3">
                {isSuccessful && !isDirty && (
                  <MessagePanel fullWidth messageType="success">
                    Data sent successfully
                  </MessagePanel>
                )}
                {serverError && (
                  <MessagePanel fullWidth messageType="error">
                    {serverError}
                  </MessagePanel>
                )}
                <Submit
                  classNames="btn-brand btn-medium"
                  label="Send data message"
                  formProcessing={formProcessing}
                />
                {showCancelButton && (
                  <Button onClick={onCancelClick} type="secondary">
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </form>
    );
  }
}

export const config = formGenerator({
  form: 'SendSmsForm',
  fields: ['message', 'fromNumber', 'messageType', 'payload', 'protocol', 'port'],
  validators: {
    message: [isLength('Message must be between 1 and 160 characters.', 1, 160, isSMSMessage)],
    payload: [required('Payload is required', isCloudMessage)],
    protocol: [required('Protocol is required', isCloudMessage)],
    port: [
      required('Port is required', isCloudMessage),
      isNumber('Port should be a number', isCloudMessage),
    ],
  },

  onSubmit(form, actionTypes, dispatch, state, HOC) {
    (form.messageType.value === 'sms'
      ? HOC.props.sendSmsToDevice(HOC.props.deviceIds, form.message.value, form.fromNumber.value)
      : HOC.props.sendDataToDevice(
          HOC.props.deviceIds,
          form.payload.value,
          form.port.value,
          form.protocol.value
        )
    )
      .then(() => {
        dispatch({
          type: actionTypes.SUCCESS,
        });

        dispatch({
          type: actionTypes.CLEAR_FIELDS,
          fields: ['message', 'payload'],
        });
      })
      .catch((errorMessage) => {
        dispatch({
          type: actionTypes.ERROR,
          errorMessage,
        });
      });
  },
});

function isCloudMessage(context) {
  return context.messageType.value === 'cloud' || !context.messageType.value;
}

function isSMSMessage(context) {
  return context.messageType.value === 'sms';
}

export default FormHOC(SendSmsForm, config);
