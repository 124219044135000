import React from 'react';
import { useFormContext } from 'react-hook-form';
import CompletedStepHeader from '../common/scaffolding/CompletedStepHeader';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';
import SidebarAttribute from '../common/scaffolding/SidebarAttribute';

const STEP_NAME = 'Name devices';

function DevicePrefixSidebarItem({ step, currentStep }) {
  const { prefix } = useFormContext().getValues('devicePrefix') ?? {};

  if (currentStep > step) {
    return (
      <CompletedStepHeader name={STEP_NAME}>
        <SidebarAttribute name="Prefix" value={prefix || 'None'} />
      </CompletedStepHeader>
    );
  }

  return <IncompleteStepHeader step={step} currentStep={currentStep} name={STEP_NAME} />;
}

export default DevicePrefixSidebarItem;
