import { Button, Icon } from '@holokit/core';
import _classNames from 'clsx';
import { updateBalanceParams as updateBalanceParams_ } from 'common-js/reducers/organization/actions';
import _ from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function InlineAutoRefillForm({
  minBalance,
  onCancel,
  onSuccess,
  topOffAmount,
  updateBalanceParams,
}) {
  const [requestError, setRequestError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amountToAdd: topOffAmount < 5 ? '5' : topOffAmount.toString(),
      threshold: minBalance < 5 ? '5' : minBalance.toString(),
    },
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    setProcessing(true);
    setRequestError(null);

    updateBalanceParams(data.amountToAdd, data.threshold)
      .then(() => {
        setProcessing(false);
        onSuccess();
      })
      .catch((e) => {
        setProcessing(false);
        setRequestError(e);
      });
  };

  return (
    <div className="InlineAutoRefillForm">
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        <div className="grid-item grid-row">
          <div className="grid-row grid-column InlineAutoRefillForm__from">
            <label className="InlineAutoRefillForm__number-label" htmlFor="amountToAdd">
              Automatically Add
            </label>
            <div
              className={_classNames('MoneyInput', {
                'MoneyInput--error': !!errors.amountToAdd,
              })}
            >
              <div>
                <input
                  {...register('amountToAdd', {
                    min: {
                      value: 5,
                      message: 'Must be at least $5.00',
                    },
                    validate: {
                      greaterThanOrEqualToThreshold: (value) => {
                        const { threshold } = getValues();
                        return (
                          value >= parseFloat(threshold) ||
                          'Must be equal to or greater than the threshold trigger amount.'
                        );
                      },
                    },
                  })}
                  id="amountToAdd"
                  name="amountToAdd"
                  type="number"
                />
              </div>
              {!!errors.amountToAdd && (
                <div className="MoneyInput__inline-error">{errors.amountToAdd.message}</div>
              )}
            </div>
          </div>
          <div className="InlineAutoRefillForm__arrow">
            <Icon name="Arrow--east" size="major" svgProps={{ style: { fill: '#cdcfd6' } }} />
          </div>
          <div className="grid-row grid-column InlineAutoRefillForm__to">
            <label className="InlineAutoRefillForm__number-label" htmlFor="threshold">
              When balance is below
            </label>
            <div
              className={_classNames('MoneyInput', {
                'MoneyInput--error': !!errors.threshold,
              })}
            >
              <div>
                <input
                  {...register('threshold', {
                    min: {
                      value: 5,
                      message: 'Must be at least $5.00',
                    },
                    onChange: () => trigger('amountToAdd'),
                  })}
                  id="threshold"
                  name="threshold"
                  type="number"
                />
              </div>
              {!!errors.threshold && (
                <div className="MoneyInput__inline-error">{errors.threshold.message}</div>
              )}
            </div>
          </div>
          <div className="InlineAutoRefillForm__buttons">
            <Button
              disabled={!!errors.threshold || !!errors.amountToAdd}
              loading={processing}
              buttonProps={{ type: 'submit' }}
              type="primary"
            >
              Enable auto-refill
            </Button>
            <Button
              classes="InlineAutoRefillForm__buttons__secondary"
              type="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
      {requestError && <div className="InlineAutoRefillForm__server-error">{requestError}</div>}
    </div>
  );
}

InlineAutoRefillForm.defaultProps = {
  onCancel: _.noop,
  onSuccess: _.noop,
};

export default connect(
  (state) => ({
    topOffAmount: state.organization.balance.topOffAmount,
    minBalance: state.organization.balance.minBalance,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateBalanceParams: updateBalanceParams_,
      },
      dispatch
    )
)(InlineAutoRefillForm);
