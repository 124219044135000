import * as actionTypes from './actions/actionTypes';

export const initialState = {
  details: {},
  loading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORT_DETAILS_REQUEST: {
      return {
        ...initialState,
        details: {},
        loading: true,
        error: null,
      };
    }

    case actionTypes.FETCH_REPORT_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.payload,
        loading: false,
        error: null,
      };
    }

    case actionTypes.FETCH_REPORT_DETAILS_ERROR: {
      return {
        ...state,
        details: {},
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default reportsReducer;
