import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as orgActions from 'common-js/reducers/organization/actions';
import { getSelectedOrg } from 'common-js/reducers/organization/selectors';
import { OrgModel } from 'common-js/models';
import { HoloHelmet, PageLoadingErrorScreen } from 'common-js/components';
import { CollaboratorList, OrgNamePanel, OrgIdPanel } from './parts';

class SettingsOrg extends React.Component {
  constructor(props) {
    super(props);

    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Settings - Org' } });
    this.state = {
      pageError: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { getOrganizations, getOrgGroups, getDeviceCount } = this.props;

    try {
      Promise.all([getOrganizations(), getOrgGroups(), getDeviceCount()]).catch((e) => {
        this.setState({ pageError: e });
      });
    } catch (e) {
      this.setState({ pageError: e });
    }
  }

  render() {
    const { selectedOrg } = this.props;
    const { pageError } = this.state;

    return (
      <div className="settings-page SettingsOrg">
        <HoloHelmet title="Settings - Your Organization" />
        {pageError ? (
          <PageLoadingErrorScreen error={pageError} />
        ) : (
          <div>
            <OrgNamePanel />
            <OrgIdPanel />
            {!selectedOrg.isMyAccount && <CollaboratorList />}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    selectedOrg: getSelectedOrg(state) || new OrgModel(),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getOrganizations: orgActions.getOrganizations,
        getOrgGroups: orgActions.getOrgGroups,
        getDeviceCount: orgActions.getDeviceCount,
      },
      dispatch
    )
)(SettingsOrg);
