import Sims from './Sims';
import SimsSidebarItem from './SimsSidebarItem';
import SimsFooter from './SimsFooter';

const simsFixture = ({ changingPlan }) => ({
  stepName: 'SIMs',
  component: Sims,
  sidebarItem: SimsSidebarItem,
  footer: SimsFooter,
  title: 'Add SIMs for activation',
  formState: 'sims',
  include: !changingPlan,
  defaultValues: {
    total: 0,
    inputOptionUsed: '',
    sims: [],
    ranges: [],
    input: {
      range: {
        start: '',
        end: '',
      },
    },
    preflight: {
      loaded: false,
    },
  },
});

export default simsFixture;
