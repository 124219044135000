import { getActivationQuote } from 'activation/sims/SimUtils';
import { ERROR_RETURN } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { getUserContextData } from 'common-js/api/util';
import { batchActivation } from 'common-js/api';
import { validateSimsRequest, validateSimsSuccess, validateSimsError } from './actions';
import { isArrayEmpty, buildSimConfiguration, buildTasks, handleErrors } from './actionUtils';

export default ({ sims, plan, isOnboarding, individualSims, invalidatePreflightSims }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { promoBalance } = state.organization.balance;
    const { availableBalance } = state.organization.balance;

    const config = await buildSimConfiguration({
      plan: {
        id: plan?.id,
        zone: plan?.zone,
      },
      sims: {
        sims,
      },
      invalidatePreflightSims,
    });

    dispatch(validateSimsRequest());

    if (isArrayEmpty(sims)) {
      dispatch(validateSimsSuccess(sims));

      return Promise.resolve();
    }

    try {
      const result = await batchActivation(
        buildTasks(state, config),
        true,
        false,
        getUserContextData(state)
      );
      const { total } = getActivationQuote(
        result?.valid_tasks,
        result?.subtotal,
        promoBalance,
        true,
        availableBalance
      );

      const validSims = (result?.valid_tasks || []).reduce(
        (acc, curr) => [...acc, ...(curr?.params?.sims || [])],
        []
      );

      dispatch(validateSimsSuccess(sims));

      return Promise.resolve({
        totalValidSims: validSims.length,
        validSims,
        total,
      });
    } catch (error) {
      const errorResponse = handleErrors(error, sims);
      const { error: validationError, validSims, invalidSims } = errorResponse;

      if (validSims) {
        dispatch(validateSimsSuccess(validSims));
      }

      if (individualSims) {
        const invalidIccids = invalidSims.filter((sim) => sim.value === 'Invalid SIM Number');
        invalidIccids.forEach((obj) => {
          sendAnalyticsEvent({
            type: ERROR_RETURN,
            data: {
              name: `Activation - ${
                isOnboarding ? '1st Time' : 'Returning'
              } - Activation Error - Invalid ICCID`,
              'Last entered value': obj.key,
            },
          });
        });
      }

      dispatch(validateSimsError({ error: validationError, invalidSims }));

      return Promise.resolve({
        invalidSims,
        validSims,
        totalValidSims: (validSims || []).length,
        error: validationError,
      });
    }
  };
