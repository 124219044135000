import { Button } from '@hologram-dimension/button';
import analyticsEventBuilder from 'common-js/analytics';
import AlertBanner from 'common-js/components/AlertBanner';
import FormPanel from 'common-js/components/FormPanel';
import HoloHelmet from 'common-js/components/HoloHelmet';
import { ACCOUNT_REGISTER } from 'common-js/constants/paths';
import LoginForm from 'common-js/forms/LoginForm';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import MfaLoginModal from 'common-js/modals/MfaLoginModal';
import { clearLoginLoader } from 'common-js/reducers/account/actions';
import omit from 'lodash/omit';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import LoggedOutTemplate from './LoggedOutTemplate';
import getLoginFormMessage from './utils/getLoginFormMessage';

interface LoginProps {
  location: Location;
}

const Login: React.FC<LoginProps> = ({ location }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearLoginLoader());
    analyticsEventBuilder.pageView().page('Login').send();
  }, [dispatch]);

  const getSignupLink = useCallback(() => {
    const query = queryString.parse(location.search);
    const queryStringBlacklist = ['e', 'f', 'l', 'c'];
    const queryObject = omit(query, queryStringBlacklist);
    return `${ACCOUNT_REGISTER}?${queryString.stringify(queryObject)}`;
  }, [location.search]);

  const { message, messageType } = getLoginFormMessage(location);

  const isLoading = useAppSelector((state) => state.account?.loading);
  const showMfaModal = useAppSelector((state) => state.account?.mfa?.showModal);

  return showMfaModal ? (
    <MfaLoginModal />
  ) : (
    <>
      <AlertBanner unauthenticatedOnly />
      <LoggedOutTemplate>
        <HoloHelmet title="Log In" />
        <FormPanel
          title="Welcome back to Hologram"
          message={message}
          messageType={messageType}
          footer={
            <p className="login--footer">
              Don&apos;t have an account yet?{' '}
              <Button to={getSignupLink()} variant="tertiary">
                Create one
              </Button>
            </p>
          }
        >
          <LoginForm isLoading={isLoading} />
        </FormPanel>
      </LoggedOutTemplate>
    </>
  );
};

export default Login;
