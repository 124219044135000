import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ClickOutside extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { callback } = this.props;

    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      callback();
    }
  };

  render() {
    const { children } = this.props;

    return <div ref={this.wrapperRef}>{children}</div>;
  }
}

ClickOutside.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ClickOutside;
