import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import Footer, { BackButton, ContinueButton, StatusText } from '../common/scaffolding/Footer';

function PlanFooter({ prevStep, nextStep, changingPlan, hasCustomPlans }) {
  const plan = useWatch({ name: 'plan' });
  const [continueText, setContinueText] = useState('');

  useEffect(() => {
    if (plan.id) {
      setContinueText('');
    }
  }, [plan.id]);

  const handleContinueClick = () => {
    if (!plan?.id) {
      setContinueText('Please choose a plan to continue.');
    }
    if (plan.id) {
      nextStep();
    }
  };

  return (
    <Footer
      left={(!changingPlan || !hasCustomPlans) && <BackButton onClick={prevStep} />}
      right={
        <>
          <StatusText error>{continueText}</StatusText>
          <ContinueButton onClick={handleContinueClick} />
        </>
      }
    />
  );
}

export default PlanFooter;
