import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';
import { WebhookModel } from '../../models';

// we could make this pull the device ID from the currentDevice state
// this hook is more reusable if we accept the deviceId as a callback parameter
const useGetWebhook = () => {
  const apiCall = useApi.call('/devices/:deviceId/webhook');

  return useCallback(
    async (deviceId) => {
      try {
        const response = await apiCall({ urlParams: { deviceId } });
        return new WebhookModel(response.success ? response.data : undefined);
      } catch (error) {
        return new WebhookModel();
      }
    },
    [apiCall]
  );
};

export default useGetWebhook;
