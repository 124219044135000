import { Button } from '@holokit/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeaderWithIcon } from '../components';
import * as Links from '../constants/links';
import * as accountActions from '../reducers/account/actions';
import * as modalActions from '../reducers/modal/actions';

class ConfirmedPauseSimModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hideModal: 'off' };
  }

  componentDidMount() {
    const MODAL_CONTAINER = document.getElementsByClassName('modal-container')[0];
    MODAL_CONTAINER.setAttribute('style', 'width:490px');
  }

  closeModal = () => {
    const { closeModal, updateUserSettings } = this.props;
    const { hideModal } = this.state;
    updateUserSettings({ modal_pause_show: hideModal });
    closeModal();
  };

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.checked;
    this.setState({ hideModal: value });
  };

  render() {
    const { isMultipleDevices, closeModal } = this.props;
    return (
      <div className="ActivateResendEmailModal apn">
        <ModalHeaderWithIcon close={closeModal} iconName="Pause" title="Pausing SIM Data" />
        <div>
          <p>
            A ‘Pause’ command has been issued to our carrier partners for your{' '}
            {isMultipleDevices ? 'devices' : 'device'}. You should see the status update shortly.
            While paused, your device will be no longer use cellular data or send/receive SMS
            messages.
          </p>
          <p>
            <span>
              Monthly access fees for {isMultipleDevices ? 'these SIMs' : 'this SIM'}
              still apply while it is paused.{' '}
            </span>
            Learn more about paused SIMs and data plans{' '}
            <a href={Links.BILLING_FAQS} target="_blank" rel="noreferrer">
              in our billing FAQs
            </a>
            .
          </p>
          <div className="modal-buttons">
            <Button onClick={this.closeModal} type="primary">
              Back to Dashboard
            </Button>
            <div className="apn-checkbox">
              <input type="checkbox" name="hideModal" onChange={this.handleInputChange} />
              <span>Don&apos;t show again</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal: modalActions.close,
      updateUserSettings: accountActions.updateUserSettings,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ConfirmedPauseSimModal);
