import { ApnModal as HyperApnModal } from '@hologram-hyper-dashboard/components';
import { ModalCommonProps } from 'common-js/components/Modal';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { updateUserSettings } from 'common-js/reducers/account/actions';
import { close } from 'common-js/reducers/modal/actions';
import { FC, useCallback } from 'react';

interface APNModalProps extends ModalCommonProps {
  apns?: Array<string>;
}

const APNModal: FC<APNModalProps> = ({ apns }) => {
  const dispatch = useAppDispatch();

  const userSettings = useAppSelector((state) => state.account?.settings);

  // Temporary detection of managed state
  const orgBmpPackage = useAppSelector((state) => state.organization.package);
  const { hasPostpay } = useAppSelector((state) => state.organization.balance);
  const isManaged = hasPostpay || (orgBmpPackage?.package_id ?? 0) > 1;

  const { modal_apn_seen: modalApnSeen } = userSettings ?? {};

  const onClose = useCallback(
    (dontShowModal: boolean) => {
      const newUserSettings = {
        modal_apn_seen: 'yes',
        modal_apn_show: dontShowModal ? 'yes' : 'no',
      };
      dispatch(updateUserSettings(newUserSettings));
      dispatch(close());
    },
    [dispatch]
  );

  return (
    <HyperApnModal
      apns={apns}
      onClose={onClose}
      className="HyperDashboardModal"
      defaultOpen
      showDontShowAgain={modalApnSeen}
      isManaged={isManaged}
    />
  );
};
export default APNModal;
