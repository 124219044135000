import { batchActivation } from 'common-js/api';
import { getUserContextData, getCurrentOrgId } from 'common-js/api/util';
import { setSimsBeingActivated } from 'common-js/reducers/devices/actions';
import { activateSimsRequest, activateSimsSuccess, activateSimsError } from './actions';
import { buildTasks, getErrorMessage, buildSimConfiguration } from './actionUtils';

export const activateSims = (formValues) => async (dispatch, getState) => {
  const state = getState();
  const userContext = getUserContextData(state);
  const excludeSims = state.activation.sims?.errors?.alreadyActivated.map((sim) => sim.key);
  const form = {
    ...formValues,
    excludeSims,
    excludeBalanceFill: false,
    createTags: true,
    userContext,
  };
  const simConfiguration = await buildSimConfiguration(form);

  if (simConfiguration?.error) {
    dispatch(activateSimsError(getErrorMessage(simConfiguration.error)));
    return Promise.reject(new Error(simConfiguration.error));
  }

  if (!simConfiguration.plan?.id) {
    dispatch(activateSimsError(getErrorMessage('No plan selected')));
    return Promise.reject(new Error('No plan selected'));
  }

  dispatch(activateSimsRequest());

  try {
    await batchActivation(
      buildTasks(state, simConfiguration),
      false,
      form.creditCardInfo?.paymentType === 'balance',
      userContext
    );

    dispatch(activateSimsSuccess(getCurrentOrgId(state)));
    dispatch(setSimsBeingActivated(simConfiguration?.sims));

    return Promise.resolve();
  } catch (error) {
    dispatch(activateSimsError(getErrorMessage(error)));

    return Promise.reject(error);
  }
};

export default activateSims;
