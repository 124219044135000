/**
 * this is an updated util function to format a currency in usd value with commas and decimals
 * note: if the amount (100.23) only has 2 decimals, and the rounding value is 4, it will return 100.23 (not 100.2300)
 * it will only extend decimals to the rounding value if the amount has decimals that far
 */

const formatCurrencyWithRounding = (amount: any, roundingValue = 2) => {
  const value = parseFloat(amount);

  if (Number.isNaN(value)) {
    return undefined;
  }

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: roundingValue,
  });
};

export default formatCurrencyWithRounding;
