import { ToastNotification } from '@hologram-dimension/toast-notification';
import {
  SimSwitchProfilesModal as HyperSimSwitchProfilesModal,
  type SimInventoryAnalyticsTypes,
  type SimInventoryCommonTypes,
} from '@hologram-hyper-dashboard/components';
import {
  SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_ERROR,
  SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_SUCCESS,
} from 'common-js/analytics/actionTypes';
import sendAnalyticsEvent from 'common-js/analytics/analytics';
import useOnButtonClickWithAnalytics from 'common-js/analytics/useOnButtonClickWithAnalytics';
import { ModalCommonProps } from 'common-js/components/Modal';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { fetchDevices } from 'common-js/reducers/devices/actions';
import { close } from 'common-js/reducers/modal/actions';
import { useCallback, type FC } from 'react';
import { toast } from 'react-toastify';

interface SimInventorySwitchProfilesModalProps extends ModalCommonProps {
  sims: Set<HologramSimId>;
  onModalSuccess?: (sims: Array<HologramSimId>) => void;
}

const SimInventorySwitchProfilesModal: FC<SimInventorySwitchProfilesModalProps> = ({
  sims,
  onModalSuccess,
}) => {
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const onSuccess = useCallback(
    (newSims: Array<SimInventoryCommonTypes.SuccessfulSwitch>) => {
      // TODO: when we add our own task management UI to Hyper Dashboard, we should no longer update redux
      // TODO: This is a synchronous single SIM action, so no tasks will be initated. When we make changes to that update here.
      // dispatch(getTasks(TASK_START_DATE));
      dispatch(fetchDevices());

      toast(
        <ToastNotification variant="success" className="dimension-toastify">
          {`${newSims[0].newProfileName} profile successfully enabled for ${newSims[0].deviceName} with SIM ID ${newSims[0].hologramSimId}.`}
        </ToastNotification>,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'dimension-toastify-wrapper',
          autoClose: 10000,
        }
      );

      if (onModalSuccess) {
        onModalSuccess(newSims.map((successSim) => successSim.hologramSimId));
      }
    },
    [dispatch, onModalSuccess]
  );

  const sendAnalyticsOnAction = useCallback<SimInventoryAnalyticsTypes.SendAnalyticsEvent>(
    ({ event, error, message }) => {
      const data: Record<string, string> = {};

      if (error) {
        data.error = error;
      }

      if (message) {
        data.message = message;
      }

      sendAnalyticsEvent({
        type:
          event === 'SimDetailsManageSimSwitchProfileSuccess'
            ? SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_SUCCESS
            : SIM_DETAILS_MANAGE_SIMS_SWITCH_PROFILE_ERROR,
        data,
      });
    },
    []
  );

  const onButtonClick = useOnButtonClickWithAnalytics({
    pageName: 'SIM Details - Switch profile modal',
  });

  return (
    <HyperSimSwitchProfilesModal
      onClose={onClose}
      onSuccess={onSuccess}
      className="HyperDashboardModal"
      defaultOpen
      sims={sims}
      sendAnalyticsOnAction={sendAnalyticsOnAction}
      onButtonClick={onButtonClick}
    />
  );
};
export default SimInventorySwitchProfilesModal;
