import { Icon } from '@hologram-dimension/icon';
import SearchModalEducationalCallout from './SearchModalEducationalCallout';
import SearchModalResultItem from './SearchModalResultItem';

const getHeaderText = (length: number) => {
  if (length === 1) {
    return 'Result';
  }
  if (length > 7) {
    return 'First 8 results...';
  }
  return 'Results';
};

const SearchModalResults = ({ results, activeResult, onSelectResult, onRowHover }) => {
  const shouldShowEducationalCallout = results.some(({ device }) => device.isHyper);
  return (
    <>
      <div className="SearchModal__legend">
        <div className="SearchModal__legend__header SearchModal__legend__device-name-header">
          {getHeaderText(results.length)}
        </div>
        <div className="SearchModal__legend__header SearchModal__legend__unique-identifiers-header">
          Unique identifiers
        </div>
        <div className="SearchModal__legend__navigation">
          <span className="SearchModal__legend__navigation__label">
            Navigate results using arrow keys
          </span>
          <Icon
            className="SearchModal__legend__navigation__icon"
            name="ArrowTriangleNorth"
            size="small"
            fill="DdsColorTextDiminishedOndefault"
          />
          <Icon
            className="SearchModal__legend__navigation__icon"
            name="ArrowTriangleSouth"
            size="small"
            fill="DdsColorTextDiminishedOndefault"
          />
        </div>
      </div>
      <ul className="SearchModal__results">
        {results.map((result, i) => (
          <SearchModalResultItem
            active={i === activeResult}
            connected={result.device.connected}
            handleResultSelect={onSelectResult}
            handleRowHover={onRowHover}
            highlights={result.device.highlights}
            id={result.device.id}
            simCardId={result.device.simcardid}
            key={`SearchModalResultItem-${result.device.id ?? i}`}
            lastResult={i === results.length - 1}
            matchedFields={result.device.matchedFields}
            rowIndex={i}
            status={result.device.status}
          />
        ))}
      </ul>
      {shouldShowEducationalCallout && <SearchModalEducationalCallout />}
    </>
  );
};

export default SearchModalResults;
