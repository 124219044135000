import queryString from 'query-string';
import _ from 'lodash';

/*
Converts a query string into all the appropriate store values;
 */
export function queryToStoreFilters(queryObject) {
  const result = {};

  const mapping = {
    tags: 'selectedTags',
  };

  for (const key in queryObject) {
    const value = queryObject[key];
    const mapped = mapping[key];

    if (mapped) {
      result[mapped] = value;
    } else {
      result[key] = value;
    }
  }

  for (const i in result) {
    if (i === 'startDate' || i === 'endDate') result[i] = parseInt(result[i]);
  }

  return result;
}

export function getFilterQueryObject() {
  const queryParams = queryString.parse(window.location.search);
  const result = {};

  if (_.isString(queryParams.tags)) result.tags = [parseInt(queryParams.tags)];
  if (_.isArray(queryParams.tags)) {
    result.tags = queryParams.tags.map((tagId) => parseInt(tagId));
  }

  if (_.isString(queryParams.startDate)) result.startDate = parseInt(queryParams.startDate);
  if (_.isString(queryParams.endDate)) result.endDate = parseInt(queryParams.endDate);

  return result;
}
