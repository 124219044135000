import { Dropdown } from '@holokit/core';
import BMPContactUsButton from 'common-js/components/BMPContactUsButton';
import { getTotalSelectedDevices } from 'common-js/reducers/devices/selectors';
import { selectBmpBulkActionsFeatureLevel } from 'common-js/reducers/releaseFlag/selectors';
import applyConditionalClassNames from 'common-js/utils/applyConditionalClassNames';
import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { ButtonVariantType } from 'types/dimension';
import useItems from './useItems';

interface UpsellFooterProps {
  bmpLevel: any;
  overLimit?: boolean;
  variant?: ButtonVariantType;
}

const UpsellFooter = ({
  bmpLevel,
  overLimit = false,
  variant = 'secondary',
}: UpsellFooterProps) => (
  <div className="ManageDropdown__upsell-footer">
    <div className="ManageDropdown__upsell-footer-text">
      Upgrade your Hologram plan to access more dashboard features.
    </div>

    <BMPContactUsButton
      className="ManageDropdown__upsell-cta"
      variant={variant}
      flagLevel={bmpLevel}
      analyticsName="Bulk Actions"
      analyticsData={{ limit: overLimit ? 'over' : 'under' }}
    />
  </div>
);

interface ManageDropdownProps {
  deviceType: string;
}
const ManageDropdown = ({ deviceType }: ManageDropdownProps) => {
  const totalSelected = useAppSelector(getTotalSelectedDevices);
  const bmpFlag = useAppSelector(selectBmpBulkActionsFeatureLevel);

  const hasSelection = () => totalSelected > 0;

  const liteOverLimit = bmpFlag === 'lite' && totalSelected > 10;
  const plusOverLimit = bmpFlag === 'plus' && totalSelected > 100;
  const overLimit = liteOverLimit || plusOverLimit;

  const hasUpsell = bmpFlag === 'lite' || plusOverLimit;

  const items = [
    ...useItems({ deviceType }),
    ...(hasUpsell
      ? [
          {
            children: (
              <UpsellFooter
                bmpLevel={bmpFlag}
                variant={totalSelected > 10 ? 'primary' : 'secondary'}
                overLimit={overLimit}
              />
            ),
            customItem: true,
          },
        ]
      : []),
  ];

  return (
    <Dropdown
      classes={applyConditionalClassNames({
        ManageDropdown: true,
        'ManageDropdown--has-upsell': hasUpsell,
      })}
      disabled={!hasSelection()}
      dropdownText="Manage"
      iconLeft="Gear"
      items={items}
    />
  );
};

export default ManageDropdown;
