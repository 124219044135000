import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import { openModal } from 'common-js/reducers/modal/actions';
import { selectPlanSelectionLimit } from 'common-js/reducers/releaseFlag/selectors';

const useSendSMSItem = ({ totalSelected }) => {
  const dispatch = useAppDispatch();

  const planSelectionLimit = useAppSelector(selectPlanSelectionLimit);

  const onClick = useCallback(() => {
    if (totalSelected > 10_000) {
      analyticsEventBuilder.errorReturn('Bulk Action', '10k Error').send();
      dispatch(openModal('SendBulkMessageModal', { overLimit: true }, 'BulkDataActionModal'));
      return;
    }

    dispatch(openModal('SendBulkMessageModal', null, 'no-padding'));
  }, [dispatch, totalSelected]);

  return {
    label: 'Send SMS to device',
    icon: 'Chat',
    selectionLimit: planSelectionLimit,
    ignore10kLimit: true,
    onClick,
  };
};

export default useSendSMSItem;
