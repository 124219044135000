import * as API from 'common-js/api';
import { getUserContextData } from 'common-js/api/util';
import * as taskStatuses from 'common-js/constants/taskStatuses';
import { setActivityTableQueryString } from 'devices/util';
import { getTaskPageInfo } from 'common-js/reducers/devices/selectors';
import * as actionTypes from '../actionTypes';
import { TASK_PAGE_SIZE, TASK_START_DATE } from '../reducer';

const translateSortColumn = (column) =>
  ({
    when: 'timestamp_end',
    action_type: 'action',
    devices: 'device_count',
    team_member: 'requester_first_name,requester_last_name,requester_org_name',
  })[column] ?? null;

export const getCompletedTasks = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_COMPLETED_TASKS_REQUEST,
  });

  const state = getState();
  const userContextData = getUserContextData(state);

  const page = getTaskPageInfo(state);
  const currentPage = page?.currentPage ?? 1;
  const startAfterId = currentPage > 1 ? page?.startAfterIds[currentPage - 2] : null;

  const actionTypesFilter = 'pause,resume,activate';

  const { column: sortColumn, direction: sortDirection } =
    state.devices?.tasks?.completed?.sort ?? {};

  return API.getTasks({
    userContextData,
    limit: TASK_PAGE_SIZE,
    startAfterId,
    status: taskStatuses.COMPLETED,
    timeStart: TASK_START_DATE,
    actionTypes: actionTypesFilter,
    sortColumn: translateSortColumn(sortColumn),
    sortDirection,
  })
    .then((data) => {
      // We can't trust pagination from the backend to return the correct number of pages
      // Instead, we calculate the correct number here and update the pagination data accordingly
      const totalTasks = data?.page?.totalTasks || 0;
      const totalPages = Math.ceil(totalTasks / TASK_PAGE_SIZE);

      dispatch({
        type: actionTypes.GET_COMPLETED_TASKS_SUCCESS,
        tasks: data?.tasks ?? [],
        page: {
          startAfterIds: (data?.page?.startAfterIds ?? []).slice(0, totalPages),
          totalPages,
          totalTasks,
        },
      });

      setActivityTableQueryString(page);

      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_COMPLETED_TASKS_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export default getCompletedTasks;
