/* eslint-disable camelcase */
import { createSelector } from 'reselect';

// This could possibly be improved by inferring from the reducer state.
export const getReleaseFlags = createSelector(
  (state: any) => state.releaseFlag,
  (releaseFlags: Record<string, boolean>) => releaseFlags
);

export const selectHasFleetFlag = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.fleet
);

export const selectHasEureka0Flag = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.eureka0
);

export const selectHasCustomBilling = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.custom_billing
);

export const selectHasBizLayerPauseResumeFlag = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.biz_layer_pause_resume
);

export const selectHasPredeploymentTestingFlags = createSelector(
  getReleaseFlags,
  (releaseFlags) =>
    !!releaseFlags.preflight && !!releaseFlags.preflight_api && !!releaseFlags.preflight_tab
);

export const selectIsBmpCustomer = createSelector(getReleaseFlags, (releaseFlags) =>
  Object.keys(releaseFlags).some((flag) => flag.startsWith('bmp_') && releaseFlags[flag])
);

const createBmpFeatureLevelSelector = (featureName) =>
  createSelector(getReleaseFlags, (releaseFlags) => {
    if (releaseFlags[`bmp_feature_lite_${featureName}`]) return 'lite';
    if (releaseFlags[`bmp_feature_plus_${featureName}`]) return 'plus';
    if (releaseFlags[`bmp_feature_pro_${featureName}`]) return 'pro';
    return 'none';
  });

export const selectBmpActivityHistoryFeatureLevel =
  createBmpFeatureLevelSelector('activity_history');
export const selectBmpAPIFeatureLevel = createBmpFeatureLevelSelector('api');
export const selectBmpBulkActionsFeatureLevel = createBmpFeatureLevelSelector('bulk_actions');
export const selectBmpDataUsageLimitsFeatureLevel =
  createBmpFeatureLevelSelector('data_usage_limits');
export const selectBmpDeviceTunnelingFeatureLevel =
  createBmpFeatureLevelSelector('device_tunneling');
export const selectBmpFleetStatusFeatureLevel = createBmpFeatureLevelSelector('fleet_status');
export const selectBmpPredeploymentTestingFeatureLevel =
  createBmpFeatureLevelSelector('predeployment_testing');
export const selectBmpTagsFeatureLevel = createBmpFeatureLevelSelector('tags');

export const selectHasAPI = createSelector(selectBmpAPIFeatureLevel, (level) => level !== 'lite');
export const selectHasDataUsageLimits = createSelector(
  selectBmpDataUsageLimitsFeatureLevel,
  (level) => level !== 'lite'
);
export const selectHasFleetStatus = createSelector(
  selectBmpFleetStatusFeatureLevel,
  selectHasFleetFlag,
  (level, hasFleetFlag) => (level === 'pro' || level === 'none') && hasFleetFlag
);
export const selectHasPredeploymentTesting = createSelector(
  selectBmpPredeploymentTestingFeatureLevel,
  selectHasPredeploymentTestingFlags,
  (level, hasFlags) => level !== 'lite' && hasFlags
);
export const selectHasTunneling = createSelector(
  selectBmpDeviceTunnelingFeatureLevel,
  (level) => level === 'pro' || level === 'none'
);

export const selectPlanSelectionLimit = createSelector(
  selectBmpBulkActionsFeatureLevel,
  (level) => {
    if (level === 'lite') return 10;
    if (level === 'plus') return 100;
    if (level === 'pro') return 10_000;
    return 10_000;
  }
);

export const selectHasActivityHistory = createSelector(
  selectBmpActivityHistoryFeatureLevel,
  (level) => level !== 'lite'
);

export const selectHasBizLayerDeactivateFlag = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.biz_layer_deactivate
);

export const selectHasBizLayerPauseFlag = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.biz_layer_pause
);

export const selectHasBizLayerResumeFlag = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.biz_layer_resume
);

export const selectRoutesFlag = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.routes
);

export const selectHasSimsPages = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.sims_pages
);

export const selectPromoteSimsPages = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.promote_sims_pages
);

export const selectHasManageSingleProfileSwap = createSelector(
  getReleaseFlags,
  (releaseFlags) => !!releaseFlags.manage_single_profile_swap
);
