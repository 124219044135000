import React, { useState } from 'react';
import { Button } from '@hologram-dimension/button';
import useResendEmailVerification from 'common-js/api/account/useResendEmailVerification';
import {
  messages,
  submitButtonText,
  SentState,
} from 'common-js/constants/resendEmailVerificationComponentStrings';
import FormPanel from './FormPanel';

const ResendEmailVerification = ({ error, userId }) => {
  const [sentState, setSentState] = useState<SentState>('default');
  const [message, setMessage] = useState(error || messages.default.message);

  const resendEmailVerificationApiCall = useResendEmailVerification();

  const onResend = async () => {
    setMessage(''); // Clear the initial error message
    setSentState('sending');
    try {
      const response = await resendEmailVerificationApiCall({ userid: userId });
      if (response.success) {
        setSentState('sent');
      } else {
        setSentState('failed');
      }
    } catch (resendError) {
      setSentState('failed');
      setMessage(resendError as string);
    }
  };

  return (
    <FormPanel
      title="Account verification"
      messageType={messages[sentState].messageType}
      message={message || messages[sentState].message}
    >
      {(sentState === 'default' || sentState === 'sending') && (
        <Button onClick={onResend} loading={sentState === 'sending'} name={submitButtonText}>
          {submitButtonText}
        </Button>
      )}
      {sentState === 'failed' && (
        <p>
          The verification email could not be resent, please contact{' '}
          <a href="mailto:support@hologram.io">support@hologram.io</a> for help.
        </p>
      )}
    </FormPanel>
  );
};
export default ResendEmailVerification;
