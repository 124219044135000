import moment from 'moment';
import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Icon, Tooltip } from '@holokit/core';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import {
  selectCurrentDevice,
  selectProfileHistory,
  selectProfiles,
} from 'common-js/reducers/currentDevice/selectors';
import { getOperatorDisplayName } from 'common-js/utils/operatorDisplayName';

const API_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const TIME_FORMAT = 'MMM DD, YYYY HH:mm z';

const getProfileEnabledOn = (profile) => {
  if (!profile) {
    return '—';
  }
  return moment.utc(profile.timestamp, API_TIME_FORMAT).format(TIME_FORMAT);
};

const HyperInfoRowItem = ({
  label,
  value,
  tooltipContent = undefined,
  tooltipTitle = undefined,
}) => (
  <div className="HyperPanel__row__item">
    <div className="HyperPanel__data-label">{label}</div>
    {tooltipContent ? (
      <div className="HyperPanel__data-value HyperPanel__data-value--has-tooltip">
        <span className="HyperPanel__tooltipped">{value}</span>
        <Tooltip content={tooltipContent} title={tooltipTitle}>
          <span>
            <Icon name="Circle--info" size="minor" svgProps={{ style: { fill: '#8008f7' } }} />
          </span>
        </Tooltip>
      </div>
    ) : (
      <div className="HyperPanel__data-value">{value}</div>
    )}
  </div>
);

const titleCase = (string) => string[0].toUpperCase() + string.slice(1).toLowerCase();

const HyperInfo = () => {
  const device = useAppSelector(selectCurrentDevice);
  const profiles = useAppSelector(selectProfiles);
  const profileHistory = useAppSelector(selectProfileHistory);
  const mostRecentProfile = profileHistory[0];
  const releaseFlags = useAppSelector(getReleaseFlags);

  const profileEnabledOn = getProfileEnabledOn(mostRecentProfile);
  const activeProfileName = mostRecentProfile?.operator_name ?? '—';
  const activeProfileType = titleCase(device.euicc_type || '—');

  const bootstrapProfile = (profiles || []).find((profile) => profile.euicc_type === 'BOOTSTRAP');
  const bootstrapProfileName = bootstrapProfile
    ? getOperatorDisplayName(bootstrapProfile.sim)
    : 'Not found';

  return (
    <div className="HyperInfo">
      <div className="Panel__info HyperPanel__info">
        <div className="HyperPanel__row">
          <HyperInfoRowItem label="Active Profile" value={activeProfileName} />

          <HyperInfoRowItem label="Profile Type" value={activeProfileType} />

          <HyperInfoRowItem label="Enabled On" value={profileEnabledOn} />
        </div>
      </div>

      <div className="Panel__padding">
        <div className="HyperPanel__row HyperPanel__row--multi">
          <HyperInfoRowItem
            label="EUICCID"
            value={device.eid || '—'}
            tooltipContent="Each Hyper capable SIM card has this 32 digit unique identifier that is tied to the physical SIM hardware. An eUICC SIM's eUICCID does not change, even when new profiles are enabled."
            tooltipTitle="eUICCID"
          />

          <HyperInfoRowItem
            label="Active APN"
            value={device.apn || '—'}
            tooltipContent="The APN tells your device where to route data traffic to the Hologram network."
            tooltipTitle="Access Point Name"
          />

          <HyperInfoRowItem
            label="Active ICCID"
            value={device.sim_number || '—'}
            tooltipContent="This is the 25 digit unique identifier that represents the active profile on this card. This is the same as the SIM Number on non-Hyper capable SIM cards. This number will change when the eUICC platform enables a new profile on the eUICC SIM."
            tooltipTitle="Active ICCID"
          />

          <HyperInfoRowItem label="PIN/Password" value="No PIN, No Password" />

          {!releaseFlags.hyper_sdp_improvements && (
            <HyperInfoRowItem
              label="Bootstrap Profile"
              value={bootstrapProfileName}
              tooltipContent="Each eUICC capable SIM is shipped with a default profile at time of manufacturing to enable the best initial connectivity and network access."
              tooltipTitle="Bootstrap Profile"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HyperInfo;
