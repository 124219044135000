import { Link } from '@hologram-dimension/link';
import { alphabeticSortCompare } from '@hologram-hyper-dashboard/components';
import { REPORTS_DETAIL, REPORT_PARAM_NAME, withContext } from 'common-js/constants/paths';
import type { FC } from 'react';
import { browserHistory } from 'react-router';

interface ReportsTableBodyProps {
  reports?: Array<{ id: string; name: string; description: string }>;
}

const ReportsTableBody: FC<ReportsTableBodyProps> = ({ reports }) => (
  <tbody>
    {(reports || [])
      .sort((a, b) => alphabeticSortCompare(a.name, b.name))
      .map(({ id, name, description }) => (
        <tr className="Table__row" key={id}>
          <td className="Table__cell">
            <div className="Table__cell-content">
              <Link
                className="ReportsOverview__link"
                onClick={() => {
                  browserHistory.push(
                    withContext(REPORTS_DETAIL.replace(`:${REPORT_PARAM_NAME}`, id))
                  );
                }}
              >
                {name}
              </Link>
            </div>
          </td>
          <td className="Table__cell">
            <div className="Table__cell-content">{description}</div>
          </td>
        </tr>
      ))}
  </tbody>
);

export default ReportsTableBody;
