const PREFIX = 'ACTIVATION_';

export const VALIDATE_SIMS_REQUEST = `${PREFIX}VALIDATE_SIMS_REQUEST` as const;
export const VALIDATE_SIMS_SUCCESS = `${PREFIX}VALIDATE_SIMS_SUCCESS` as const;
export const VALIDATE_SIMS_ERROR = `${PREFIX}VALIDATE_SIMS_ERROR` as const;

export const VALIDATE_SIM_RANGE_REQUEST = `${PREFIX}VALIDATE_SIM_RANGE_REQUEST` as const;
export const VALIDATE_SIM_RANGE_SUCCESS = `${PREFIX}VALIDATE_SIM_RANGE_SUCCESS` as const;
export const VALIDATE_SIM_RANGE_ERROR = `${PREFIX}VALIDATE_SIM_RANGE_ERROR` as const;

export const GET_SIMS_FROM_CSV_REQUEST = `${PREFIX}GET_SIMS_FROM_CSV_REQUEST` as const;
export const GET_SIMS_FROM_CSV_SUCCESS = `${PREFIX}GET_SIMS_FROM_CSV_SUCCESS` as const;
export const GET_SIMS_FROM_CSV_ERROR = `${PREFIX}GET_SIMS_FROM_CSV_ERROR` as const;

export const ACTIVATE_SIMS_REQUEST = `${PREFIX}ACTIVATE_SIMS_REQUEST` as const;
export const ACTIVATE_SIMS_SUCCESS = `${PREFIX}ACTIVATE_SIMS_SUCCESS` as const;
export const ACTIVATE_SIMS_ERROR = `${PREFIX}ACTIVATE_SIMS_ERROR` as const;

export const CLEAR_INDIVIDUAL_SIM_SELECTION = `${PREFIX}CLEAR_INDIVIDUAL_SIM_SELECTION` as const;
export const CLEAR_VALIDATION_ERROR_STATE = `${PREFIX}CLEAR_VALIDATION_ERROR_STATE` as const;

export const UPDATE_TOTAL_DUE_TODAY = `${PREFIX}UPDATE_TOTAL_DUE_TODAY` as const;

export const CALCULATE_TOTAL_DUE_TODAY_REQUEST =
  `${PREFIX}CALCULATE_TOTAL_DUE_TODAY_REQUEST` as const;
export const CALCULATE_TOTAL_DUE_TODAY_SUCCESS =
  `${PREFIX}CALCULATE_TOTAL_DUE_TODAY_SUCCESS` as const;
export const CALCULATE_TOTAL_DUE_TODAY_ERROR = `${PREFIX}CALCULATE_TOTAL_DUE_TODAY_ERROR` as const;

export const SET_ISPREFLIGHT_FLAG = `${PREFIX}SET_ISPREFLIGHT_FLAG` as const;
export const SET_ISCHANGEPLAN_FLAG = `${PREFIX}SET_ISCHANGEPLAN_FLAG` as const;
export const SET_CHANGEPLAN_DEVICES = `${PREFIX}SET_CHANGEPLAN_DEVICES` as const;
export const SET_PRELOADED_ACTIVATION_DEVICES =
  `${PREFIX}SET_PRELOADED_ACTIVATION_DEVICES` as const;

export const RESET_ACTIVATION_STORE = `${PREFIX}RESET_ACTIVATION_STORE` as const;

export const PREFLIGHT_ACTIVATION_PREVIEW_REQUEST =
  `${PREFIX}PREFLIGHT_ACTIVATION_PREVIEW_REQUEST` as const;
export const PREFLIGHT_ACTIVATION_PREVIEW_SUCCESS =
  `${PREFIX}PREFLIGHT_ACTIVATION_PREVIEW_SUCCESS` as const;
export const PREFLIGHT_ACTIVATION_PREVIEW_ERROR =
  `${PREFIX}PREFLIGHT_ACTIVATION_PREVIEW_ERROR` as const;
