import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import _classnames from 'clsx';
import analyticsEventBuilder from 'common-js/analytics';
import { DEVICES_DEVICES, SIM_INVENTORY_ACTIVE, withContext } from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { resetActivationStoreToDefault } from 'common-js/reducers/activation/actions';
import { selectAllPlans } from 'common-js/reducers/activation/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import {
  selectHasSimsPages,
  selectPromoteSimsPages,
} from 'common-js/reducers/releaseFlag/selectors';
import { useCallback } from 'react';
import { browserHistory } from 'react-router';

interface ConfirmationProps {
  success?: boolean;
  error?: any;
  isOnboarding?: boolean;
  hasPostpay?: boolean;
  changingPlan?: boolean;
  activatedPlan?: any;
}

function Confirmation({
  success = true,
  error,
  isOnboarding = false,
  hasPostpay = false,
  changingPlan = false,
  activatedPlan,
}: ConfirmationProps) {
  let title: string | undefined;

  if (success) {
    title = "You're on your way...";
  } else if (changingPlan) {
    title = 'Change plan failed';
  } else {
    title = 'Activation failed';
  }

  const dispatch = useAppDispatch();
  const userSettings = useAppSelector((state) => state.account?.settings);
  const hasSimInventoryPage = useAppSelector(selectHasSimsPages);
  const promoteSimInventoryPage = useAppSelector(selectPromoteSimsPages);
  const onboardingText = isOnboarding ? '1st Time' : 'Returning';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';

  const allPlans: ReturnType<typeof selectAllPlans> = useAppSelector(selectAllPlans);

  const handleReturnToDashboardClick = useCallback(() => {
    analyticsEventBuilder
      .buttonClick(
        analyticsTitle,
        changingPlan ? undefined : onboardingText,
        hasPostpay ? ' Postpay' : undefined,
        `${analyticsTitle} ${success ? 'Success' : 'Failure'}`,
        'Go to Dashboard'
      )
      .send();

    dispatch(resetActivationStoreToDefault());
    browserHistory.push(
      withContext(
        hasSimInventoryPage && promoteSimInventoryPage ? SIM_INVENTORY_ACTIVE : DEVICES_DEVICES
      )
    );

    if (changingPlan || !success || userSettings?.modal_apn_show !== 'yes') {
      return;
    }

    // Get details about the plan
    let apns: Array<string> | undefined;

    if (activatedPlan) {
      const accountPlan = allPlans.find((searchPlan) => searchPlan.id === activatedPlan.id);

      if (accountPlan) {
        apns = accountPlan.apns;
      }
    }

    dispatch(openModal('APNModal', { apns }));
  }, [
    activatedPlan,
    allPlans,
    analyticsTitle,
    changingPlan,
    dispatch,
    hasPostpay,
    onboardingText,
    success,
    userSettings?.modal_apn_show,
    hasSimInventoryPage,
    promoteSimInventoryPage,
  ]);

  const handleAPNHelpClick = () => {
    analyticsEventBuilder
      .buttonClick(
        'Activation',
        `${isOnboarding ? '1st Time' : 'Returning'}${hasPostpay ? ' Postpay' : ''}`,
        'Activation APN Help'
      )
      .send();
  };

  const handleContactSupportClick = () => {
    analyticsEventBuilder
      .buttonClick(
        analyticsTitle,
        changingPlan ? undefined : onboardingText,
        `${analyticsTitle} ${success ? 'Success' : 'Failure'}`,
        'Contact Support'
      )
      .send();
  };

  const reminderCopy =
    userSettings?.modal_apn_show !== 'yes'
      ? "Here's an article in our help center if you have any questions:"
      : "We'll remind you again when you go to the dashboard but here's an article if you have any questions:";

  return (
    <Panel
      className={_classnames('Confirmation__panel', {
        'Confirmation__panel--failure': !success,
      })}
      header={title}
    >
      <div className="Confirmation__panel__content">
        {success && changingPlan && (
          <BannerNotification className="Confirmation__panel__callout" variant="success">
            Your change data plan request was successfully processed.
          </BannerNotification>
        )}
        {success && !changingPlan && (
          <>
            The last step in completing the activation of your SIMs is to set the device&apos;s APN
            to hologram. {reminderCopy}{' '}
            <a
              className="Confirmation__panel__link"
              target="_blank"
              rel="noreferrer"
              href="https://support.hologram.io/hc/en-us/articles/360035697853-How-do-I-set-the-APN-"
              onClick={handleAPNHelpClick}
            >
              How do I set my APN?
            </a>
            <BannerNotification variant="reminder" className="Confirmation__panel__callout">
              Once your APN has been properly configured, your SIMs should be up and running usually
              in 10 minutes. You&apos;ll start to see your devices propagate through the network as
              they come online.
            </BannerNotification>
          </>
        )}
        {!success && (
          <>
            {`Sorry, there was an error on our end. Please retry ${
              changingPlan ? 'changing your data plan' : 'activating your SIMs'
            } from the dashboard or reach out to our team at `}
            <a
              className="Confirmation__panel__link"
              href="mailto:support@hologram.io"
              onClick={handleContactSupportClick}
            >
              support@hologram.io
            </a>{' '}
            if you have any questions.
            <BannerNotification className="Confirmation__panel__callout" variant="system-error">
              {error}
            </BannerNotification>
          </>
        )}
      </div>
      <footer className="Confirmation__panel__footer">
        <div className="Confirmation__panel__button">
          <Button onClick={handleReturnToDashboardClick}>Go back to dashboard</Button>
        </div>
      </footer>
    </Panel>
  );
}

export default Confirmation;
