import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { Picture } from '@hologram-hyper-dashboard/components';
import { Callout, Icon, Tooltip } from '@holokit/core';
import analyticsEventBuilder from 'common-js/analytics';
import useGetBillingAddress from 'common-js/api/organizations/useGetBillingAddress';
import { HoloHelmet, Money } from 'common-js/components';
import { deactivatedStates, preflightStates } from 'common-js/constants/deviceStates';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { updateUserSettings } from 'common-js/reducers/account/actions';
import { selectCurrentDevice, selectStatusInfo } from 'common-js/reducers/currentDevice/selectors';
import { close } from 'common-js/reducers/modal/actions';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import { DEACTIVATE } from 'common-js/utils/permissions';
import dataLimitsImgSmall from 'img/sim_details/data_limit_diagram.webp?&imagetools';
import Moment from 'moment-timezone';
import React, { useEffect } from 'react';
import DataLimitsSection from '../DataLimits/DataLimitsSection';
import GridItem from './GridItem';
import ManagePhoneNumberButton from './ManagePhoneNumberButton';
import PlanButton from './PlanButton';
import useHandleDeactivate from './useHandleDeactivate';

const Plan = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const statusInfo = useAppSelector(selectStatusInfo);
  const userPermissions = useAppSelector(getUserPermissions);
  const releaseFlags = useAppSelector((state) => state.releaseFlag);
  const showEducationalSmall =
    useAppSelector((state) => state.account.settings.edu_sdp_dlimit_small_show) === 'yes';

  useGetBillingAddress();
  const handleDeactivate = useHandleDeactivate();

  const handleDismiss = () => {
    dispatch(
      updateUserSettings({
        edu_sdp_dlimit_small_show: 'no',
      })
    );
  };

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Single Device', 'Plan').send();
    return () => dispatch(close());
  }, [dispatch]);

  if (deactivatedStates.includes(device.state)) {
    return (
      <Panel className="coverage-panel" header="Data plan">
        <Callout
          type={Callout.TYPE.WARNING}
          text={
            statusInfo
              ? `This device was deactivated on ${Moment.utc(statusInfo.timestamp).format(
                  'MMMM D, YYYY'
                )} at ${Moment.utc(statusInfo.timestamp).format('h:mma')} UTC.`
              : 'This device was deactivated.'
          }
          defaultIcon
          iconColor={Callout.THEME.COLOR}
        />
      </Panel>
    );
  }

  return (
    <div className="page-device-plan full-height">
      <HoloHelmet title={`${device.name} - Data plan`} />
      <Panel
        className="coverage-panel"
        header="Data plan"
        noContentPadding
        headerActions={<PlanButton />}
      >
        <div className="Panel__info data-plan-panel-content">
          <div className="grid-row">
            <div className="grid-item">
              <div className="name">Name</div>
              <div className="value">{device.plan}</div>
            </div>
          </div>
        </div>
        <div className="Panel__padding">
          <div className="grid-row data-plan-panel-base">
            <GridItem
              name="per device"
              subtext={
                <>
                  Renews {Moment.utc(device.whenexpires).tz(Moment.tz.guess()).format('MM/DD/YYYY')}{' '}
                  <Tooltip content="Plan-related charges are billed every 30 days from activation.">
                    <span>
                      <Icon
                        name="Circle--info"
                        size="minor"
                        svgProps={{ style: { fill: '#8008f7' } }}
                      />
                    </span>
                  </Tooltip>
                </>
              }
            >
              <Money showLongFloats amount={device.cost} />
            </GridItem>
            <GridItem name="per mb">
              <Money showLongFloats amount={device.overage} />
            </GridItem>
            <GridItem name="per outbound sms">
              <Money showLongFloats amount={device.sms} />
            </GridItem>
            <GridItem name="coverage">
              <a href="https://www.hologram.io/coverage/" target="_blank" rel="noreferrer">
                {device.zone === 'global' ? 'Global coverage' : `Zone ${device.zone}`}
              </a>
            </GridItem>
          </div>
        </div>
      </Panel>
      {/* Educational panel for orgs with data usage limits only */}
      {!releaseFlags.eureka0 && showEducationalSmall && (
        <Panel
          header="How to use data usage limits"
          className="DataLimits__educational"
          headerActions={
            <Button iconStart="Close" onClick={() => handleDismiss()} variant="secondary">
              Dismiss
            </Button>
          }
        >
          <div className="DataLimits__educationWellWrapper">
            <p className="DataLimits__educationWellText">
              Data usage limits automatically pause your SIM’s data usage when it exceeds a set
              amount. They are commonly used to prevent data overages on data plans with included
              data.
            </p>
            <div className="DataLimits__educationWellActions">
              <Button
                iconStart="Gauge"
                variant="secondary"
                href="https://support.hologram.io/hc/en-us/articles/360035697313-Set-a-device-s-data-usage-limit"
                target="_blank"
              >
                Learn about data usage limits
              </Button>
            </div>
            <div className="DataLimits__educationWellPicture">
              <Picture image={dataLimitsImgSmall} setDimensions={false} alt="" />
            </div>
          </div>
        </Panel>
      )}
      {!releaseFlags.eureka0 && <DataLimitsSection />}
      <Panel header="SIM details">
        <div className="sim-details-panel">
          <div className="grid-row">
            <GridItem name="Sim number">{device.sim_number}</GridItem>
            <GridItem name="IMEI">{device.imei?.length > 0 ? device.imei : '–'}</GridItem>
          </div>
          <div className="grid-row">
            <GridItem name="Device Id">{device.id}</GridItem>
            <GridItem name="Link Id">{device.linkId}</GridItem>
          </div>
          <div className="grid-row">
            <GridItem name="APN">
              {device.apn}
              <Tooltip
                content="The APN tells your device how to connect to the Hologram network."
                title="ACCESS POINT NAME (APN)"
              >
                <span>
                  <Icon
                    name="Circle--info"
                    size="minor"
                    svgProps={{ style: { fill: '#8008f7' } }}
                  />
                </span>
              </Tooltip>
            </GridItem>
            <GridItem name="Pin / Password" placeholder>
              No PIN or password applied
            </GridItem>
          </div>
        </div>
      </Panel>
      <Panel className="phone-panel" header="Phone number">
        <ManagePhoneNumberButton device={device} />
      </Panel>
      {userPermissions.includes(DEACTIVATE) && !preflightStates.includes(device.sysState) && (
        <Panel className="deactivate-panel">
          <div className="grid-row flex-row">
            To permanently end service for this device, deactivate this SIM card.
            <Button iconStart="LightningSlash" onClick={handleDeactivate} variant="destructive">
              Deactivate SIM
            </Button>
          </div>
        </Panel>
      )}
    </div>
  );
};

export default Plan;
