import Plan from './Plan';
import PlanSidebarItem from './PlanSidebarItem';
import PlanFooter from './PlanFooter';

const planFixture = () => ({
  stepName: 'Plan',
  component: Plan,
  sidebarItem: PlanSidebarItem,
  footer: PlanFooter,
  title: 'Select data plan',
  formState: 'plan',
  defaultValues: {},
});

export default planFixture;
