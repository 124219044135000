import React from 'react';
import * as Paths from '../../common/constants/paths';

import TopMenu from '../../common/components/menu/TopMenu';
import SubMenuRightContent from '../../common/components/menu/SubMenuRightContent';

function SubMenu() {
  return (
    <TopMenu
      titleHref={Paths.withContext(Paths.APPS_OVERVIEW)}
      rightContent={<SubMenuRightContent />}
    >
      <TopMenu.Item text="Data Routes" active href={Paths.withContext(Paths.APPS_OVERVIEW)} />
    </TopMenu>
  );
}

export default SubMenu;
