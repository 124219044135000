import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { updateActivityHistorySort } from 'common-js/reducers/devices/actions/updateActivityHistorySort';
import { getActivityHistorySort } from 'common-js/reducers/devices/selectors';
import analyticsEventBuilder from 'common-js/analytics';

const sortedColumnMap = {
  when: 'Date',
  action_type: 'Type',
  devices: 'Devices',
  team_member: 'Person',
};
const nextDirectionMap = {
  asc: 'Descending',
  desc: 'Clear',
};

const useActivityHistoryHeaderClickHandler = () => {
  const dispatch = useAppDispatch();
  const activityHistoryTableSort = useAppSelector(getActivityHistorySort);
  const nextDirection = activityHistoryTableSort?.direction;
  return useCallback(
    (column: keyof typeof sortedColumnMap) => {
      dispatch(updateActivityHistorySort(column));
      analyticsEventBuilder.buttonClick('Activity History', 'Sort').send({
        column: sortedColumnMap[column],
        // if nextDirection is null, then sorting is currently cleared, next direction will be ascending
        direction: nextDirectionMap[nextDirection] || 'Ascending',
      });
    },
    [nextDirection, dispatch]
  );
};

export default useActivityHistoryHeaderClickHandler;
