import { Picture } from '@hologram-hyper-dashboard/components';
import { Button, ComplexIcon, Icon, Loader, Tooltip } from '@holokit/core';
import _classnames from 'clsx';
import { close as closeModal_, openModal as openModal_ } from 'common-js/reducers/modal/actions';
import tacChangeIconImg from 'img/alerts/tacchangealerticon.webp?&imagetools';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as alertTypes from '../../common/constants/alertTypes';
import { deactivatedStates } from '../../common/constants/deviceStates';
import { setActiveAlert } from '../../common/reducers/alerts/actions';
import { pushGlobalMessage } from '../../common/reducers/message/actions';
import HeadlineToast from '../../common/toasts/HeadlineToast';
import scrollTo from '../../common/utils/dom/scrollTo';
import alertsTableContainer from '../containers/TableContainer';
import AlertsActionItemsAd from './AlertsActionItemsAd';
import ClearAcknowledgedBanner from './ClearAcknowledgedBanner';
import LoadMoreFooter from './LoadMoreFooter';
import SelectMultipleToolbar from './SelectMultipleToolbar';
import TacChangeAlertsTableRow from './TacChangeAlertsTableRow';
import withPlanHooksHoc from './withPlanHooksHoc';

class AlertsTable extends Component {
  static showDeactivateToast(devices) {
    const isSingleDeactivate = devices.length === 1;

    toast(
      <HeadlineToast
        headline={
          isSingleDeactivate ? 'Device Deactivated' : `${devices.length} Devices Deactivated`
        }
        body={
          isSingleDeactivate
            ? 'You have successfully deactivated this device.'
            : `You have successfully deactivated ${devices.length} devices.`
        }
        icon="Checkmark--single"
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toastify-content--burnt toastify-content--success',
        autoClose: 10000,
      }
    );
  }

  constructor(props) {
    super(props);
    this.scrollWrap = React.createRef();
    this.checkbox = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentDidUpdate(prevProps) {
    const { alerts, allSelected, selectedAlerts, selectedDevices } = this.props;

    if (
      prevProps.alerts &&
      alerts &&
      prevProps.alerts.length &&
      alerts.length &&
      prevProps.alerts.length !== alerts.length
    ) {
      this.scrollToNewAlerts(prevProps.alerts.length);
    }
    if (prevProps.selectedAlerts.length !== selectedAlerts.length) {
      if (this.checkbox.current) {
        this.checkbox.current.indeterminate = selectedDevices.length && !allSelected;
      }
    }
  }

  componentWillUnmount() {
    const { setActiveAlert_, setActivePin } = this.props;
    setActiveAlert_(null, alertTypes.TAC_CHANGE);
    setActivePin(null, alertTypes.TAC_CHANGE);
  }

  componentCleanup = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  scrollToNewAlerts = (lastAlert) => {
    const { alerts } = this.props;
    const row = document.getElementById(`tac-change-alert-${alerts[lastAlert].id}`);

    if (row) {
      scrollTo(
        row.offsetTop - this.scrollWrap.current.offsetTop,
        () => {},
        200,
        this.scrollWrap.current
      );
    }
  };

  // DEACTIVATE EVENTS
  handleDeactivate = (singleAlertId) => {
    const {
      alerts,
      closeModal,
      deactivateDevices,
      deselectAlerts,
      keyedAlerts,
      limitedBulkActionAvailable,
      openModal,
      pushGlobalMessage_,
      selectedAlerts,
    } = this.props;

    let devicesToDeactivate = [];

    // multi-select deactivation:
    if (selectedAlerts.length > 0) {
      const selectedDevices = [];
      selectedAlerts.forEach((selectedAlert) => {
        if (!selectedDevices.includes(keyedAlerts[selectedAlert].deviceid)) {
          selectedDevices.push(keyedAlerts[selectedAlert].deviceid);
          devicesToDeactivate.push({
            ...keyedAlerts[selectedAlert],
            sim_number: keyedAlerts[selectedAlert].sim,
            name: keyedAlerts[selectedAlert].device_name,
            id: keyedAlerts[selectedAlert].deviceid,
          });
        }
      });
    } else if (singleAlertId) {
      // single device deactivation
      const singleAlert = keyedAlerts[singleAlertId];
      if (!singleAlert) return;

      devicesToDeactivate = [
        {
          ...singleAlert,
          sim_number: singleAlert.sim,
          name: singleAlert.device_name,
          id: singleAlert.deviceid,
        },
      ];
    }

    if (devicesToDeactivate.length > 0 && limitedBulkActionAvailable) {
      openModal(
        'DeactivateSimModal',
        {
          devices: devicesToDeactivate,
          action: () => {
            deactivateDevices(devicesToDeactivate, alerts)
              .then(() => {
                AlertsTable.showDeactivateToast(devicesToDeactivate);
                closeModal();
                deselectAlerts(selectedAlerts);
              })
              .catch((error) => {
                pushGlobalMessage_(error, 'error');
                closeModal();
              });
          },
        },
        'medium no-padding vertical-center'
      );
    }
  };

  render() {
    const {
      activeAlertId,
      alertCount,
      ackedAlertIds,
      alerts,
      allSelected,
      bulkPause,
      deselectAlerts,
      deviceCount,
      dismissClearBanner,
      dismissClearBannerAndLoadMore,
      goToNextPage,
      handleAcknowledgeAlerts,
      handleAcknowledgeSingleAlert,
      handleHideEducationBanner,
      handlePauseSim,
      handleResume,
      handleSingleSelection,
      handleUnacknowledgeSingleAlert,
      isInitialPageLoad,
      isLoading,
      keyedAlerts,
      limitedBulkActionAvailable,
      nextPageEnabled,
      selectAlerts,
      selectAll,
      selectedAlerts,
      selectedDevices,
      showClearAndLoadMore,
      showEducationHeader,
      theme,
      updateTheme,
      setAllUnloadedAlertsSelected,
      allUnloadedAlertsSelected,
    } = this.props;
    const colspan = theme === 'multi-select' ? '4' : '3';
    const isUnfurled = !!activeAlertId;
    const canBulkDeactivate = selectedAlerts.some(
      (alert) => !deactivatedStates.includes(keyedAlerts[alert].state)
    );
    let content = (
      <div className={`AlertsTable AlertsTable--column3 AlertsTable--${theme}`}>
        <Loader isLoading={isInitialPageLoad || isLoading} />
        <div className="AlertsTable__header">
          <div className="AlertsTable__header__count">{isInitialPageLoad ? '' : alertCount}</div>
          <div className="AlertsTable__header__headline">
            <h3>
              <span>SIMs moved</span>
              <Tooltip content="Hologram has detected that the following SIMs have been moved into a new device as indicted by the IMEI on the cellular module. This may indicate device tampering or prototyping in new hardware. Pause to avoid data charges if the change is unintended.">
                <div className="AlertsTable__tooltip">
                  <Icon
                    name="Circle--info"
                    size="minor"
                    classes="AlertsTable__header--help"
                    svgProps={{ style: { fill: '#8008f7' } }}
                  />
                </div>
              </Tooltip>
            </h3>
          </div>
          <div className="AlertsTable__header__cta">
            {theme === 'multi-select' ? (
              <>
                <Button
                  iconLeft={bulkPause ? 'Pause' : 'Play'}
                  onClick={bulkPause ? handlePauseSim : handleResume}
                  disabled={
                    !selectedDevices.length ||
                    !limitedBulkActionAvailable ||
                    !canBulkDeactivate ||
                    (allUnloadedAlertsSelected && alerts.length !== alertCount)
                  }
                  type="reversed"
                >
                  {bulkPause ? 'Pause' : 'Resume'}
                </Button>
                <Button
                  iconLeft="Lightning--slash"
                  onClick={this.handleDeactivate}
                  disabled={
                    !selectedDevices.length ||
                    !limitedBulkActionAvailable ||
                    !canBulkDeactivate ||
                    (allUnloadedAlertsSelected && alerts.length !== alertCount)
                  }
                  type="reversed"
                >
                  Deactivate
                </Button>
                {alerts.length ? (
                  <Button
                    iconLeft="Checkmark--double"
                    onClick={() => handleAcknowledgeAlerts()}
                    disabled={
                      !selectedDevices.length ||
                      selectedAlerts.every((alert) => ackedAlertIds.includes(alert))
                    }
                    type="reversed"
                  >
                    {allUnloadedAlertsSelected
                      ? `Acknowledge ${alertCount}`
                      : `Acknowledge ${selectedAlerts.length}`}
                  </Button>
                ) : null}

                <div className="AlertsTable__header__cta-divider" />
                <Button
                  iconLeft="Select"
                  onClick={() => {
                    updateTheme('default');
                    setAllUnloadedAlertsSelected(false);
                  }}
                  type="secondary"
                >
                  Done
                </Button>
              </>
            ) : (
              <>
                {alerts.length ? (
                  <Button
                    disabled={isUnfurled}
                    iconLeft="Checkmark--double"
                    onClick={handleAcknowledgeAlerts}
                    type="secondary"
                  >
                    {alerts.length === alertCount
                      ? 'Acknowledge All'
                      : `Acknowledge ${alerts.length}`}
                  </Button>
                ) : null}
                <Button
                  disabled={isUnfurled}
                  iconLeft="Select"
                  onClick={() => updateTheme('multi-select')}
                  type="secondary"
                >
                  Select Multiple
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="AlertsTable__scroll-wrap" ref={this.scrollWrap}>
          <table
            className={_classnames('AlertsTable__body', {
              'AlertsTable__body--multi-select': theme === 'multi-select',
            })}
          >
            {!isInitialPageLoad ? (
              <>
                <thead className="AlertsTable__section AlertsTable__section--header">
                  <tr className="AlertsTable__row AlertsTable__row">
                    {theme === 'multi-select' ? (
                      <th className="AlertsTable__cell AlertsTable__cell__select">
                        <div className="AlertsTable__cell__content">
                          <input
                            checked={allSelected}
                            id="select-all"
                            name="select-all"
                            onClick={selectAll}
                            ref={this.checkbox}
                            type="checkbox"
                          />
                        </div>
                      </th>
                    ) : null}
                    <th className="AlertsTable__cell AlertsTable__cell--sortable">
                      <div className="AlertsTable__cell__content">
                        Timestamp
                        <Icon
                          name="Arrow--triangle--south"
                          size="micro"
                          svgProps={{ style: { fill: '#606677' } }}
                        />
                      </div>
                    </th>
                    <th className="AlertsTable__cell">
                      <div className="AlertsTable__cell__content">Affected Device</div>
                    </th>
                    <th className="AlertsTable__cell">
                      <div className="AlertsTable__cell__content">Usage this Period</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="AlertsTable__section AlertsTable__section--body">
                  <ClearAcknowledgedBanner
                    colspan={colspan}
                    close={dismissClearBanner}
                    show={showClearAndLoadMore}
                    loadMore={dismissClearBannerAndLoadMore}
                  />
                  {showEducationHeader && (
                    <tr>
                      <td colSpan={colspan}>
                        <div className="AlertsTable__education-header">
                          <Picture image={tacChangeIconImg} alt="" />
                          <span className="AlertsTable__education-header__title">
                            SIMs moved to unfamiliar devices
                          </span>
                          <div className="AlertsTable__education-header__description">
                            These Hologram SIMs were moved to a device we haven’t seen before. This
                            could indicate new hardware in your fleet or unintended tampering with
                            your devices.
                          </div>
                          <div
                            className="AlertsTable__education-header__icon-container"
                            onClick={handleHideEducationBanner}
                            onKeyUp={handleHideEducationBanner}
                            role="button"
                            tabIndex={0}
                          >
                            <Icon
                              name="Close"
                              size="minor"
                              svgProps={{ style: { fill: '#040815' } }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {alerts.map((alert, idx) => (
                    <TacChangeAlertsTableRow
                      alert={alert}
                      colspan={colspan}
                      deselectAlerts={deselectAlerts}
                      handleAcknowledgeSingleAlert={handleAcknowledgeSingleAlert}
                      handleDeactivate={this.handleDeactivate}
                      handlePause={handlePauseSim}
                      handleResume={handleResume}
                      handleSingleSelection={handleSingleSelection}
                      handleUnacknowledgeSingleAlert={handleUnacknowledgeSingleAlert}
                      isFirstRow={idx === 0}
                      key={alert?.id ?? idx}
                      scrollWrap={this.scrollWrap}
                      selectAlerts={selectAlerts}
                      selectedAlerts={selectedAlerts}
                      selectedDevices={selectedDevices}
                      theme={theme}
                    />
                  ))}
                </tbody>
              </>
            ) : null}
          </table>
        </div>
        {theme === 'multi-select' ? (
          <SelectMultipleToolbar
            selectedAlerts={selectedAlerts}
            loaded={alerts.length}
            alertCount={alertCount}
            setAllUnloadedAlertsSelected={setAllUnloadedAlertsSelected}
            selectAll={selectAll}
            deselectAlerts={deselectAlerts}
            allUnloadedAlertsSelected={allUnloadedAlertsSelected}
          />
        ) : (
          <LoadMoreFooter
            continues={nextPageEnabled}
            loaded={alerts.length}
            total={alertCount}
            loadMore={goToNextPage}
          />
        )}
      </div>
    );

    if (!isInitialPageLoad) {
      if (deviceCount === 0) {
        content = <AlertsActionItemsAd />;
      } else if (alerts.length === 0) {
        content = (
          <div className="AlertsTable__no-alerts-container">
            <ComplexIcon name="green-ribbon" />
            <div>All is well. None of your</div>
            <div>devices require attention.</div>
          </div>
        );
      }
    }

    return (
      <div
        className={_classnames('AlertsTable__wrapper', {
          'AlertsTable__wrapper--empty': deviceCount === 0,
          'AlertsTable__wrapper--expanded': theme === 'multi-select',
        })}
        id="AlertsTable__wrapper"
      >
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushGlobalMessage_: pushGlobalMessage,
      setActiveAlert_: setActiveAlert,
      closeModal: closeModal_,
      openModal: openModal_,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withPlanHooksHoc(alertsTableContainer(alertTypes.TAC_CHANGE)(AlertsTable)));
