import queryString from 'query-string';
import { browserHistory } from 'react-router';

export const setDeviceTableQueryString = (devices) => {
  const query = devices.query.value;
  const { column, direction } = devices.sort;
  const page = devices.page.current;
  const parsed = queryString.parse(window.location.search);

  if (query) {
    parsed.query = query;
  } else {
    delete parsed.query;
  }
  if (column) {
    parsed.sort = column;
  } else {
    delete parsed.sort;
  }
  if (direction) {
    parsed.sort_direction = direction;
  } else {
    delete parsed.sort_direction;
  }
  if (page) {
    parsed.page = page;
  } else {
    delete parsed.page;
  }

  browserHistory.replace({
    pathname: window.location.pathname,
    search: `?${queryString.stringify(parsed)}`,
  });
};

export default setDeviceTableQueryString;
