import { useCallback, useState } from 'react';

// a counter that also tracks the furthest it has gone
const useStepCounter = (defaultValue: number) => {
  const [step, setStep] = useState({
    current: defaultValue,
    max: defaultValue,
  });
  const nextStep = useCallback(() => {
    setStep(({ current, max }) => ({
      current: current + 1,
      max: Math.max(current + 1, max),
    }));
  }, [setStep]);
  const prevStep = useCallback(() => {
    setStep(({ current, max }) => ({
      current: current - 1,
      max,
    }));
  }, [setStep]);
  const resetMaxStep = useCallback(() => {
    setStep(({ current }) => ({
      current,
      max: current,
    }));
  }, [setStep]);
  return {
    step,
    nextStep,
    prevStep,
    resetMaxStep,
  };
};

export default useStepCounter;
