import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { TagComplete } from '..';

class TagCompleteInput extends React.PureComponent {
  onTagAdded(tag) {
    const { onChange, onTagAdded, value } = this.props;

    const currentTags = value.split(',');

    if (currentTags.indexOf(tag.name) === -1) {
      currentTags.push(tag.name);
    }

    onChange(currentTags.filter((t) => t !== '').join(','));
    onTagAdded(tag.name);
  }

  onTagDeleted(tag) {
    const { onChange, onTagDeleted } = this.props;
    const currentTagNames = this.getTagsFromValueProp().map((t) => t.name);
    const indexOfTagToDelete = currentTagNames.indexOf(tag.name);

    if (indexOfTagToDelete !== -1) {
      currentTagNames.splice(indexOfTagToDelete, 1);
    }

    onChange(currentTagNames.join(','));
    onTagDeleted(tag.name);
  }

  getTagsFromValueProp() {
    const { value } = this.props;

    return value
      .split(',')
      .filter((tag) => tag !== '')
      .map((tag, idx) => ({
        id: idx,
        name: tag.trim(),
      }));
  }

  render() {
    const {
      name,
      label,
      placeholder,
      type = 'string',
      error = null,
      showValidation = true,
      invalidTags,
      validTags,
      showAddButton,
      autofocus = false,
      classNames,
    } = this.props;

    const tags = this.getTagsFromValueProp();

    return (
      <div className={`input-container ${classNames} ${type}`}>
        <TagComplete
          tags={tags}
          suggestions={[]}
          onTagDeleted={(tag) => this.onTagDeleted(tag)}
          onTagAdded={(tag) => this.onTagAdded(tag)}
          invalidTags={invalidTags}
          validTags={validTags}
          allowNew
          autofocus={autofocus}
          autoresize
          label={label}
          placeholder={placeholder}
          showAddButton={showAddButton}
          id={name}
          classNames={classNames}
        />
        {showValidation && error && <div className="inline-error">{error}</div>}
      </div>
    );
  }
}

TagCompleteInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  onTagAdded: PropTypes.func,
  onTagDeleted: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.string,
  showValidation: PropTypes.bool,
  showAddButton: PropTypes.bool,
  classNames: PropTypes.string,
  invalidTags: PropTypes.array,
  validTags: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

TagCompleteInput.defaultProps = {
  classNames: '',
  onTagAdded: _.noop,
  onTagDeleted: _.noop,
  invalidTags: [],
  validTags: [],
  label: null,
  placeholder: null,
  showAddButton: false,
};

export default TagCompleteInput;
