import { Button, ComplexIcon, Panel } from '@holokit/core';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';

import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { HoloHelmet, PageLoadingErrorScreen } from '../../common/components';
import * as Paths from '../../common/constants/paths';
import { getBillingStatements } from '../../common/reducers/organization/actions';
import { addCommasToNumber } from '../../common/utils/numberFormatter';

class Statements extends Component {
  constructor(props) {
    super(props);

    const { releaseFlags } = props;

    this.state = {
      isLoading: true,
      pageError: null,
    };

    if (!releaseFlags.billing_statements) {
      browserHistory.push(Paths.withContext(Paths.BILLING_ACCOUNT));
    } else {
      sendAnalyticsEvent({
        type: analyticsTypes.PAGE_VIEW,
        data: { page: 'Billing - Statements' },
      });
    }
  }

  componentDidMount() {
    const { getBillingStatements_ } = this.props;

    try {
      getBillingStatements_()
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch((e) => {
          this.setState({ pageError: e });
        });
    } catch (e) {
      this.setState({ pageError: e });
    }
  }

  onCSVDownload = (statementId, orgId, readableDate) => {
    sendAnalyticsEvent({
      type: analyticsTypes.REPORT_BILLING_STATEMENT,
      data: {
        statement: statementId,
        statement_date: readableDate,
      },
    });
  };

  render() {
    const { billingStatements } = this.props;
    const { isLoading, pageError } = this.state;
    const futureFirstBillingDate =
      billingStatements && billingStatements.length
        ? false
        : moment().add(1, 'months').startOf('month').format('MMMM D');

    return (
      <div className="BillingTable">
        <HoloHelmet title="Billing Statements" />
        {pageError ? (
          <PageLoadingErrorScreen error={pageError} />
        ) : (
          <>
            <Panel largeTitle noBodyPadding title="Statements" well>
              <p>
                After each calendar month, a billing statement will become available with detailed
                breakdowns of your spending.
              </p>
            </Panel>
            <Panel
              headerBorder={false}
              isLoading={isLoading}
              noBodyPadding
              title="Billing statements"
            >
              {futureFirstBillingDate ? (
                <div className="BillingTable__empty">
                  <div className="BillingTable__empty__icon">
                    <ComplexIcon name="missing-document" />
                  </div>
                  <div className="BillingTable__empty__headline">No statements available</div>
                  {futureFirstBillingDate && futureFirstBillingDate !== 'Invalid date' ? (
                    <div className="BillingTable__empty__text">
                      {`Your first billing statement will be available on ${futureFirstBillingDate}`}
                    </div>
                  ) : null}
                </div>
              ) : (
                <table className="UsageTable BillingTable__table">
                  <thead>
                    <tr className="header">
                      <th className="BillingTable__table__cell">Billing Period</th>
                      <th className="BillingTable__table__cell">Charges</th>
                      <th className="BillingTable__table__cell">Balance Added</th>
                      <th className="BillingTable__table__cell" colSpan="2">
                        Statement Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="BillingTable__table__body">
                    {billingStatements.map((statement) => {
                      const { id, orgid, report_start_date, net_total, credit_total, debit_total } =
                        statement;

                      if (!id || !orgid) return;

                      const readableDate = moment(report_start_date, 'YYYY/MM/DD').format(
                        'MMMM YYYY'
                      );

                      return (
                        <tr key={id} className="BillingTable__table__row">
                          <td className="BillingTable__table__cell">
                            {readableDate && readableDate !== 'Invalid date' ? readableDate : '-'}
                          </td>
                          <td className="BillingTable__table__cell">
                            $-{addCommasToNumber(debit_total)}
                          </td>
                          <td className="BillingTable__table__cell">
                            ${addCommasToNumber(Math.abs(credit_total))}
                          </td>
                          <td className="BillingTable__table__cell">
                            ${addCommasToNumber(net_total)}
                          </td>
                          <td className="BillingTable__table__cell">
                            <Button
                              href={`${process.env.VITE_API_ROOT}/organizations/${orgid}/statements/${id}/csv`}
                              icon="Arrow--lineinto--south"
                              onMouseDown={() => this.onCSVDownload(id, orgid, readableDate)}
                              type="secondary"
                            >
                              Download CSV
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </Panel>
          </>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    billingStatements: state.organization.billingStatements,
    releaseFlags: state.releaseFlag,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getBillingStatements_: getBillingStatements,
      },
      dispatch
    )
)(Statements);
