import clsx from 'clsx/lite';
import { useAppSelector } from 'common-js/hooks';
import { OrgModel } from 'common-js/models';
import { selectName } from 'common-js/reducers/account/selectors';
import {
  getSelectedOrg,
  selectTriggerContextSwitcher,
} from 'common-js/reducers/organization/selectors';
import { useContext, useEffect, useRef, useState, type FC, type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import AlertBanner from './AlertBanner';
import HoloStoreContext from './HoloStoreContext';

interface ContextSwitcherOverlayProps {
  children?: ReactNode;
}

const ContextSwitcherOverlay: FC<ContextSwitcherOverlayProps> = ({ children }) => {
  // Redux state
  const triggerContextSwitcher = useAppSelector(selectTriggerContextSwitcher);
  const selectedOrg = useAppSelector(getSelectedOrg) || new OrgModel();
  const accountName = useAppSelector(selectName);

  // Local state
  const [isTriggered, setIsTriggered] = useState(false);

  const lastTriggerContextSwitcher = useRef(triggerContextSwitcher);
  const hideTimer = useRef<number | null>(null);

  // Only show for one second
  useEffect(() => {
    if (triggerContextSwitcher !== lastTriggerContextSwitcher.current) {
      lastTriggerContextSwitcher.current = triggerContextSwitcher;

      setIsTriggered(true);

      if (hideTimer.current) {
        clearTimeout(hideTimer.current);
      }

      hideTimer.current = window.setTimeout(() => {
        setIsTriggered(false);
      }, 1000);
    }
  }, [triggerContextSwitcher]);

  const overlayClassnames = clsx('ContextSwitcherOverlay', 'active' && isTriggered);

  const holoStoreRef = useContext(HoloStoreContext);

  if (isTriggered) {
    return (
      <div className={overlayClassnames}>
        <div className="grid-row vertical-align">
          <div className="message-container">
            <div className="context-change-spinner" />
            <div className="user-logo-container">
              <div className="user-logo">
                {selectedOrg.isMyAccount ? accountName.charAt(0) : selectedOrg.initial}
              </div>
            </div>
            <div className="f2">
              Switching to{' '}
              <span className="bold">
                {selectedOrg.isMyAccount ? accountName : selectedOrg.name}
              </span>{' '}
              ...
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="full-height">
      {holoStoreRef?.current && createPortal(<AlertBanner />, holoStoreRef.current)}
      {children}
    </div>
  );
};

export default ContextSwitcherOverlay;
