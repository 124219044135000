import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';
import { WebhookModel } from 'common-js/models';

const useRegenerateWebhook = () => {
  const apiCall = useApi.call('/devices/:deviceId/webhook/guid', {
    method: 'POST',
  });
  return useCallback(
    async (deviceId) => {
      const { data, error } = await apiCall({ urlParams: { deviceId } });
      if (error) throw new Error(error);
      return new WebhookModel(data);
    },
    [apiCall]
  );
};

export default useRegenerateWebhook;
