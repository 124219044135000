import { Button } from '@holokit/core';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Paths from '../../common/constants/paths';
import * as alertActions from '../../common/reducers/alerts/actions';
import {
  getDataUsed,
  getDevice,
  registerDeviceId,
  setCustomDate,
} from '../../common/reducers/singleDevice/actions';
import { getBillingPeriod } from '../../common/utils/time';
import DataLimitChartD3 from '../../usage/components/charts/DataLimitChartD3';
import DeviceUsageUpdateThresholdPopover from './DeviceUsageUpdateThresholdPopover';

class DeviceUsageAlertsDrawer extends Component {
  componentDidMount() {
    const { deviceid, deviceRegistered, registerDeviceId_ } = this.props;

    if (!deviceRegistered) {
      registerDeviceId_(deviceid).then(() => {
        this.getDeviceData();
      });
    } else {
      this.getDeviceData();
    }
  }

  getDeviceData() {
    const { alert, deviceid, getDevice_, getDataUsed_, onDrawerLoad, setCustomDate_ } = this.props;

    const deviceBillingPeriodEnd = moment.utc(alert.expires);
    const { start, end } = getBillingPeriod(alert.whencreated, deviceBillingPeriodEnd);

    setCustomDate_(
      {
        startDate: parseInt(start.format('x'), 10),
        endDate: parseInt(end.format('x'), 10),
      },
      deviceid
    );
    Promise.all([getDevice_(deviceid), getDataUsed_(deviceid)]).then(() => {
      onDrawerLoad();
    });
  }

  render() {
    const {
      alert,
      dataThreshold,
      dataUsed,
      deviceid,
      handleAcknowledgeAlert,
      handleUnacknowledgeAlert,
      handlePause,
      handleResume,
      isDeactivated,
      isLoading,
      isPaused,
      timeFilter,
    } = this.props;

    return (
      <div className="AlertsDrawer AlertsDrawer--highusage">
        {!isLoading && (
          <>
            <div className="AlertsDrawer__panel">
              <div className="AlertsDrawer__panel__header">
                <div className="AlertsDrawer__panel__header__headline">Usage Alert Report</div>
              </div>
              {dataUsed.length === 0 ? (
                <div className="AlertsDrawer__panel__body">
                  <div className="AlertsDrawer__panel__message">No data sessions to display</div>
                </div>
              ) : (
                <DataLimitChartD3
                  className="DataLimitChartD3--alerts"
                  dataUsed={dataUsed}
                  limitTypeLabel="Threshold Hit"
                  primaryColor={DataLimitChartD3.PRIMARY_COLORS.ORANGE}
                  showDataHighValue
                  showProjectedDataUsage
                  showTodayMarker
                  threshold={dataThreshold}
                  thresholdMetDate={alert.whencreated}
                  timeFilter={timeFilter}
                />
              )}
            </div>
            <div className="AlertsDrawer__footer">
              <Button
                iconRight="Arrow--northeast"
                exernal
                href={Paths.withContext(
                  Paths.DEVICE_LANDING.replace(`:${Paths.DEVICE_PARAM_NAME}`, deviceid)
                )}
                type="reversed"
              >
                {alert.device_name}
              </Button>
              <div className="AlertsDrawer__footer__btn-wrapper">
                <Button
                  buttonProps={{
                    'data-linkid': alert.linkid,
                    'data-alertid': alert.id,
                  }}
                  disabled={isDeactivated}
                  iconLeft={isPaused ? 'Play' : 'Pause'}
                  onClick={isPaused ? handleResume : handlePause}
                  type="reversed"
                >
                  {isPaused ? 'Resume' : 'Pause'}
                </Button>
                <div className="AlertsDrawer__footer__popover-cover">
                  <DeviceUsageUpdateThresholdPopover
                    isDeactivated={isDeactivated}
                    deviceid={deviceid}
                  />
                </div>
                <Button
                  iconLeft="Checkmark--double"
                  onClick={alert.acked ? handleUnacknowledgeAlert : handleAcknowledgeAlert}
                  type="reversed"
                >
                  {alert.acked ? 'Unacknowledge' : 'Acknowledge'}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const createMapStateToProps = (state, props) => ({
  dataUsed: props.deviceid in state.singleDevice ? state.singleDevice[props.deviceid].dataUsed : [],
  deviceRegistered: !!state.singleDevice[props.deviceid],
  timeFilter:
    props.deviceid in state.singleDevice ? state.singleDevice[props.deviceid].timeFilter : [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...alertActions,
      getDevice_: getDevice,
      getDataUsed_: getDataUsed,
      registerDeviceId_: registerDeviceId,
      setCustomDate_: setCustomDate,
    },
    dispatch
  );

export default connect(createMapStateToProps, mapDispatchToProps)(DeviceUsageAlertsDrawer);
