import { Panel } from '@holokit/core';
import analyticsEventBuilder from 'common-js/analytics';
import DevicePrefixForm from 'common-js/components/DevicePrefixForm';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const footer = (
  <div className="DevicePrefixPanel__footer">
    You can add a prefix (up to 24 characters) to your device name that gets applied to each SIM
    with the last five digits of the ICCID, upon activation. We recommend utilizing naming
    conventions centric to the hardware version, end-user, geographical location, or similarly
    memorable.
  </div>
);

const trackAnalyticsEvent = (wasExpanded) => {
  analyticsEventBuilder
    .buttonClick('Activation', 'Returning', 'Activation Name Device Toggle')
    .send({ 'toggle status': wasExpanded ? 'Not set' : 'Set' });
};

function DevicePrefixPanel() {
  const { setValue, register, watch } = useFormContext();
  const prefix = watch('devicePrefix.prefix');
  const simsInForm = watch('sims.sims');
  const suffix = simsInForm?.length > 0 ? simsInForm[0].slice(-5) : '01234';
  const { onBlur, ...rest } = register('devicePrefix.prefix');

  const onBlurHandler = (event) => {
    analyticsEventBuilder
      .dataEntry('Activation', 'Returning', 'Activation Device Name Prefix Entry')
      .send({ prefix });

    onBlur(event);
  };

  const onChangeHandler = (value) => {
    setValue('devicePrefix.prefix', value);
  };

  return (
    <Panel
      title="Customize names"
      footer={footer}
      hasExpandableContent
      classes="DevicePrefixPanel"
      defaultExpanded={prefix !== ''}
      onToggleExpand={({ expanded }) => {
        if (expanded) {
          setValue('devicePrefix.prefix', '');
        }
        trackAnalyticsEvent(expanded);
      }}
    >
      <div className="DevicePrefixPanel__content">
        <DevicePrefixForm
          inputProps={rest}
          onBlur={(e) => onBlurHandler(e)}
          onChange={(e) => onChangeHandler(e)}
          suffix={suffix}
          value={prefix}
        />
      </div>
    </Panel>
  );
}

export default DevicePrefixPanel;
