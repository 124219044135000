import React from 'react';
import { Button } from '@hologram-dimension/button';
import analyticsEventBuilder from 'common-js/analytics';
import { BMPContactUsButton, BMPUpsellPanel } from 'common-js/components';
import { PREFLIGHT_HELP } from 'common-js/constants/links';

const learnMoreClickHandler = () => {
  analyticsEventBuilder.buttonClick('BMP', 'Preflight', 'Learn More CTA').send({ flag: 'lite' });
  window.open(PREFLIGHT_HELP, '_blank');
};

const PredeploymentUpsellContent = ({ deviceCount }) => (
  <BMPUpsellPanel
    label="Plus and Pro feature"
    headline="Test devices before they deploy"
    copy={
      deviceCount === 0
        ? 'To unlock pre-deployment device testing, upgrade your Hologram plan.'
        : 'To turn this feature back on, upgrade your Hologram plan.'
    }
    buttons={
      <>
        {deviceCount === 0 && (
          <Button variant="secondary" iconEnd="ExternalLink" onClick={learnMoreClickHandler}>
            Learn about this feature
          </Button>
        )}
        <BMPContactUsButton variant="primary" flagLevel="lite" analyticsName="Preflight" />
      </>
    }
  />
);

export default PredeploymentUpsellContent;
