import { Button } from '@hologram-dimension/button';
import { Popover } from '@hologram-dimension/popover';
import { type SimInventoryCommonTypes } from '@hologram-hyper-dashboard/components';
import useActivateCallback from 'common-js/hooks/useActivateCallback';
import { type FC, type ReactNode } from 'react';

interface ActivationButtonProps {
  children?: ReactNode;
  deviceType: SimInventoryCommonTypes.SimInventorySegmentName;
  variant?: GetReactParameterBag<typeof Button>['variant'];
}

const ActivationButton: FC<ActivationButtonProps> = ({
  deviceType,
  children = 'Activate SIMs',
  variant = 'primary',
}) => {
  const { canActivate, errorMessage, onActivate, hide } = useActivateCallback({ deviceType });

  if (hide) {
    return null;
  }

  const activateButton = (
    <Button
      iconStart="Lightning"
      variant={variant}
      disabled={!canActivate}
      onClick={canActivate ? onActivate : undefined}
      className="DeviceActions__activate-button"
    >
      {children as any}
    </Button>
  );

  return errorMessage ? (
    <Popover
      id="activation-button-popover"
      className="DeviceActions__activate-button"
      content={errorMessage}
    >
      {activateButton}
    </Popover>
  ) : (
    activateButton
  );
};

export default ActivationButton;
