import { getPendingTasks } from 'common-js/reducers/devices/selectors';
import { useEffect, useState } from 'react';
import useAppSelector from './useAppSelector';

const useOnAllTasksSuccessful = (callback: () => void) => {
  const pendingTasks = useAppSelector(getPendingTasks);
  const [lastPendingTasksCount, setLastPendingTasksCount] = useState(pendingTasks?.length || 0);

  const pendingTasksCount = pendingTasks?.length ?? 0;

  useEffect(() => {
    if (pendingTasksCount !== lastPendingTasksCount) {
      if (pendingTasksCount === 0) {
        callback();
      }
      setLastPendingTasksCount(pendingTasksCount);
    }
  }, [callback, lastPendingTasksCount, pendingTasksCount]);
};

export default useOnAllTasksSuccessful;
