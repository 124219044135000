import AutoRefill from './AutoRefill';
import AutoRefillFooter from './AutoRefillFooter';
import AutoRefillSidebarItem from './AutoRefillSidebarItem';

const autoRefillFixture = ({
  hasPostpay,
  autoRefillSettings,
  hasBillingPermissions,
  changingPlan,
}) => ({
  stepName: 'Auto-Refill',
  component: AutoRefill,
  sidebarItem: AutoRefillSidebarItem,
  footer: AutoRefillFooter,
  title: 'Set auto-refill preferences',
  include: !hasPostpay && hasBillingPermissions && !changingPlan,
  formState: 'autoRefillParams',
  optional: !autoRefillSettings?.isAutoRefillEnabled,
  defaultValues: {
    amountToAdd: autoRefillSettings?.topOffAmount || '',
    threshold: autoRefillSettings?.minBalance || '',
    enabled: autoRefillSettings?.isAutoRefillEnabled,
  },
});

export default autoRefillFixture;
