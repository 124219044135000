import { LandingEmptyPage } from '@hologram-hyper-dashboard/components';
import analyticsEventBuilder from 'common-js/analytics';
import useOnButtonClickWithAnalytics from 'common-js/analytics/useOnButtonClickWithAnalytics';
import { getOrgIdFromContextData, getUserContextDataMemoized } from 'common-js/api/util';
import useActivateCallback from 'common-js/hooks/useActivateCallback';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectFirstName } from 'common-js/reducers/account/selectors';
import { getSelectedOrg } from 'common-js/reducers/organization/selectors';
import { selectHasPredeploymentTesting } from 'common-js/reducers/releaseFlag/selectors';
import React, { useEffect, useMemo } from 'react';

interface LandingPageEmptyProps {
  isFleet?: boolean;
}

const LandingPageEmpty: React.FC<LandingPageEmptyProps> = ({ isFleet = false }) => {
  const {
    canActivate,
    onActivate,
    errorMessage: canActivateErrorMessage,
  } = useActivateCallback({});

  const firstName = useAppSelector(selectFirstName);
  const hasPredeploymentTesting = useAppSelector(selectHasPredeploymentTesting);

  // Temporary detection of managed state
  const orgBmpPackage = useAppSelector((state) => state.organization.package);
  const { hasPostpay } = useAppSelector((state) => state.organization.balance);
  const isManaged = hasPostpay || (orgBmpPackage?.package_id ?? 0) > 1;

  // Used to fill out form fields
  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = getOrgIdFromContextData(userContext);
  const email = userContext.userEmail;
  const selectedOrg = useAppSelector(getSelectedOrg);

  const pageName = useMemo(() => `${isFleet ? 'Fleet - ' : ''}Empty Landing Page`, [isFleet]);
  const onButtonClick = useOnButtonClickWithAnalytics({ pageName });

  useEffect(() => {
    analyticsEventBuilder.pageView().page(pageName).send();
  }, [pageName]);

  return (
    <LandingEmptyPage
      firstName={firstName}
      onActivate={onActivate}
      canActivate={canActivate}
      canActivateErrorMessage={canActivateErrorMessage}
      hasPredeploymentTesting={hasPredeploymentTesting}
      isManaged={isManaged}
      orgId={orgId ?? undefined}
      orgName={selectedOrg?.name ?? undefined}
      email={email}
      onButtonClick={onButtonClick}
    />
  );
};

export default LandingPageEmpty;
