import { Button } from '@hologram-dimension/button';
import { getSelectedLoadedDevices } from 'common-js/reducers/devices/selectors';
import { Device } from 'common-js/types/Device';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MessagePanel, ModalHeader } from '../components';
import { close, openModal } from '../reducers/modal/actions';

interface BulkActionModalDevice {
  id: DeviceId;
  name?: string;
}
interface BulkActionModalProps {
  actionTitle: string;
  userSettings: Record<string, string>; // TODO: Add a shared type somewhere for userSettings
  openModal_: typeof openModal;
  action: AnyAsyncFunction;
  selectedDevices?: Array<Device>;
  deviceIds?: Array<DeviceId>;
  closeModal: typeof close;
  buttonLabel: string;
}

interface BulkActionModalState {
  isSuccessful: boolean;
  isMultipleDevices: boolean;
  isProcessing: boolean;
  serverError: any;
  successObject: any;
}

class BulkActionModal extends React.Component<BulkActionModalProps, BulkActionModalState> {
  constructor(props: BulkActionModalProps) {
    super(props);

    this.state = {
      isProcessing: false,
      serverError: null,
      isSuccessful: false,
      successObject: null,
      isMultipleDevices: false,
    };

    this.onAction = this.onAction.bind(this);
  }

  componentDidUpdate() {
    const { actionTitle, userSettings, openModal_ } = this.props;
    const { isSuccessful, isMultipleDevices } = this.state;
    if (isSuccessful && userSettings.modal_pause_show !== '1' && actionTitle === 'Pause Data') {
      openModal_('ConfirmedPauseSimModal', { isMultipleDevices });
    }
  }

  onAction() {
    const { action, selectedDevices, deviceIds } = this.props;

    const devicesToUse: Array<BulkActionModalDevice> | undefined =
      (deviceIds && deviceIds.length > 0 && deviceIds?.map((id) => ({ id }))) || selectedDevices;

    this.setState({
      isProcessing: true,
      serverError: null,
      isSuccessful: false,
      successObject: null,
      isMultipleDevices: (devicesToUse?.length ?? 0) > 1,
    });

    action()
      .then((successObject) => {
        this.setState({
          isProcessing: false,
          isSuccessful: true,
          successObject,
        });
      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          isSuccessful: false,
          serverError: e,
          successObject: null,
        });
      });
  }

  generateProcessingButtonElement() {
    const { closeModal, buttonLabel } = this.props;
    const { isProcessing, isSuccessful } = this.state;
    if (isProcessing) {
      return <Button>Working...</Button>;
    }
    if (isSuccessful) {
      return <Button onClick={closeModal}>Done</Button>;
    }
    return <Button onClick={this.onAction}>{buttonLabel}</Button>;
  }

  render() {
    const { actionTitle, closeModal, selectedDevices, deviceIds } = this.props;
    const { serverError, isSuccessful, isProcessing, successObject } = this.state;
    const devicesToUse: Array<BulkActionModalDevice> | undefined =
      (deviceIds && deviceIds.length > 0 && deviceIds?.map((id) => ({ id }))) || selectedDevices;

    return (
      <div className="BulkActionModal">
        <ModalHeader title={`Bulk action: ${actionTitle}`} />
        <div className="f2 section">Devices:</div>
        <div className="device-tags section">
          {devicesToUse?.slice(0, 10).map(({ id, name }) => (
            <div className="device-tag-cont" key={id}>
              <span className="tag" key={id}>
                {name ?? id}
              </span>
            </div>
          ))}
          {(devicesToUse?.length ?? 0) > 10 && (
            <div className="device-tag-cont">
              <span className="tag">{devicesToUse!.length - 10} more...</span>
            </div>
          )}
        </div>
        <div className="f2 section">Action:</div>
        {serverError && (
          <div className="section">
            <MessagePanel fullWidth messageType="error">
              {serverError}
            </MessagePanel>
          </div>
        )}
        {successObject && (
          <div>
            {successObject.successes.length > 0 && (
              <div className="section">
                <MessagePanel messageType="success" fullWidth>
                  {successObject.successes.map(({ item, deviceId }) => (
                    <div key={deviceId}>
                      <b>{item}: </b> Successfully completed operation.
                    </div>
                  ))}
                </MessagePanel>
              </div>
            )}
            {successObject.failures.length > 0 && (
              <div className="section">
                <MessagePanel messageType="error" fullWidth>
                  <div>There were errors: </div>
                  {successObject.failures.map(({ item, reason, deviceId }) => (
                    <div key={deviceId}>
                      {item ? (
                        <span>
                          <b>{item}: </b>
                          {reason}
                        </span>
                      ) : (
                        <b>{reason}</b>
                      )}
                    </div>
                  ))}
                </MessagePanel>
              </div>
            )}
          </div>
        )}
        {isSuccessful && !successObject && (
          <div className="section">
            <MessagePanel fullWidth messageType="success">
              Successfully completed the bulk action.
            </MessagePanel>
          </div>
        )}
        <div className="BulkActionModal__Actions">
          {this.generateProcessingButtonElement()}
          {!isSuccessful && (
            <Button disabled={isProcessing} onClick={closeModal} variant="secondary">
              Cancel
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  selectedDevices: getSelectedLoadedDevices(state),
  userSettings: state.account.settings,
});

export default connect(mapStateToProps, (dispatch: any) =>
  bindActionCreators(
    {
      closeModal: close,
      openModal_: openModal,
    },
    dispatch
  )
)(BulkActionModal);
