import { setDataDogUserConfiguration } from 'common-js/analytics/dataDog';
import {
  DEVICES_DEVICES,
  HOME_OVERVIEW,
  SIM_INVENTORY_ACTIVE,
  withContext,
} from 'common-js/constants/paths';
import { LOGIN_ERROR, LOGIN_SUCCESS } from 'common-js/reducers/account/actionTypes';
import { selectAccount } from 'common-js/reducers/account/selectors';
import { getAllBilling } from 'common-js/reducers/organization/actions';
import { getSelectedOrg } from 'common-js/reducers/organization/selectors';
import { getAllReleaseFlags, getReleaseFlags } from 'common-js/reducers/releaseFlag/actions';
import { getReleaseFlags as selectReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import queryString from 'query-string';
import { browserHistory } from 'react-router';
import { clearLoginLoader } from '.';
import { setRoadblocksFromUserSettings } from './actions';
import { getUserInfo } from './getUserInfo';
import { switchToDefaultOrg } from './switchToDefaultOrg';

const fetchAccount =
  (impersonating = false) =>
  (dispatch, state) =>
    getUserInfo()(dispatch, state)
      .then(() => switchToDefaultOrg()(dispatch, state))
      .then(() => {
        getAllBilling()(dispatch, state).finally(() => Promise.resolve());
      })
      .then(() => setRoadblocksFromUserSettings()(dispatch, state))
      .then(() => getReleaseFlags()(dispatch, state))
      .then(() => {
        const store = state();
        const userInfo = selectAccount(store) ?? {};
        const releaseFlags = selectReleaseFlags(store) ?? {};
        const currentOrg = getSelectedOrg(store) ?? {};

        if (!currentOrg.id) {
          currentOrg.id = userInfo.orgId;
        }

        dispatch({ type: LOGIN_SUCCESS, userInfo });

        if (!currentOrg) {
          Promise.reject(
            new Error(
              `Failed to log in: organization not set while ${
                impersonating ? 'impersonating' : 'logging in'
              }`
            )
          );
        }

        setDataDogUserConfiguration(userInfo, currentOrg.id);

        const hasHomepage = !!releaseFlags?.fleet;
        const hasSimInventory = !!releaseFlags.sims_pages && !!releaseFlags.promote_sims_pages;

        let defaultPath = hasSimInventory ? SIM_INVENTORY_ACTIVE : DEVICES_DEVICES;
        if (hasHomepage) defaultPath = HOME_OVERVIEW;
        const currentOrgId = currentOrg.id;
        const parsedQuery = queryString.parse(window.location.search);
        const redirectTo = parsedQuery.redirectto;

        let nextPath = defaultPath;
        if (redirectTo && !isArray(redirectTo)) {
          nextPath = redirectTo;
        }

        // make sure email & password are not included in the forwarded query string.
        const forwardedQuery = omit(parsedQuery, ['email', 'password', 'redirectto']);
        forwardedQuery.from = 'login';

        const forwardedQueryString = queryString.stringify(forwardedQuery);

        browserHistory.push(
          withContext(nextPath, currentOrgId) +
            (forwardedQueryString.length > 0 ? `?${forwardedQueryString}` : '')
        );
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_ERROR,
          error: error.toString(),
        });

        getAllReleaseFlags()(dispatch, state);
        return Promise.reject(new Error(error));
      })
      .finally(() => {
        // We don't actually care when this occurs, so long as it occurs after afer the screen is gone
        setTimeout(() => {
          dispatch(clearLoginLoader());
        }, 2000);
      });

export default fetchAccount;
