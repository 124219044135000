import { Button } from '@hologram-dimension/button';
import {
  CONNECTED,
  LIVE,
  LIVE_PENDING,
  PAUSED_PENDING_SYS,
  PAUSED_PENDING_USER,
  PAUSED_SYS,
  PAUSED_USER,
  REASON_BILLING,
  REASON_USAGE_BILLING,
} from 'common-js/constants/deviceStates';
import { ACCOUNT_BILLING } from 'common-js/constants/paths';
import { Device } from 'common-js/types/Device';
import useHandlePause from 'device/Plan/useHandlePause';
import useHandleResume from 'device/Plan/useHandleResume';
import React, { useMemo } from 'react';

interface PauseResumeDeviceProps {
  device: Device;
  onStateChange: (newState: 'PAUSE' | 'RESUME') => Promise<void>;
  loading: boolean;
}

const PauseResumeDevice = ({ device, onStateChange, loading }: PauseResumeDeviceProps) => {
  const handlePause = useHandlePause({
    onStateChange: () => onStateChange('PAUSE'),
  });
  const handleResume = useHandleResume({
    onStateChange: () => onStateChange('RESUME'),
  });

  const button = useMemo(() => {
    const variant = 'secondary';
    if (loading) {
      return (
        <Button disabled variant={variant}>
          Checking...
        </Button>
      );
    }

    switch (device.state) {
      case PAUSED_PENDING_SYS:
      case PAUSED_PENDING_USER:
        return (
          <Button disabled variant={variant}>
            Pausing...
          </Button>
        );
      case LIVE_PENDING:
        return (
          <Button disabled variant={variant}>
            Resuming...
          </Button>
        );
      case LIVE:
      case CONNECTED:
        return (
          <Button iconStart="Pause" onClick={handlePause} variant={variant}>
            Pause
          </Button>
        );
      case PAUSED_USER:
        return (
          <Button iconStart="Play" onClick={handleResume} variant={variant}>
            Resume
          </Button>
        );
      case PAUSED_SYS:
        if (
          device.statusReason === REASON_BILLING ||
          device.statusReason === REASON_USAGE_BILLING
        ) {
          <Button href={ACCOUNT_BILLING} variant={variant}>
            Update billing
          </Button>;
        }
        return (
          <Button disabled variant={variant}>
            Unknown device state
          </Button>
        );
      default:
        return (
          <Button disabled variant={variant}>
            Unknown device state
          </Button>
        );
    }
  }, [device.state, device.statusReason, handlePause, handleResume, loading]);

  return <div className="PauseResumeDevice">{button}</div>;
};

export default PauseResumeDevice;
