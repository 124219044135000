import { Icon } from '@hologram-dimension/icon';
import { type FC, useEffect, useState, lazy, Suspense } from 'react';

const DEFAULT_MAP_HEIGHT = 345;

const Mapbox = lazy(() =>
  import('@hologram-hyper-dashboard/components').then((module) => ({ default: module.Mapbox }))
);

interface DeviceLocationMapProps {
  lastSession: { latitude?: number; longitude?: number };
}

const DeviceLocationMap: FC<DeviceLocationMapProps> = ({ lastSession }) => {
  const [mapHeight, setMapHeight] = useState<number | null>(null);

  useEffect(() => {
    // give the display a little rendering time so the billing panel has the right height.
    const timeout = setTimeout(() => {
      setMapHeight(
        document.querySelector<HTMLDivElement>('.OverviewPanel')?.offsetHeight ?? DEFAULT_MAP_HEIGHT
      );
    }, 32); // approximately 2 frames for rendering.

    return () => clearTimeout(timeout);
  }, [setMapHeight]);

  return (
    <div className={`DeviceLocationMap${!lastSession.latitude ? ' empty' : ''}`}>
      {lastSession.latitude && lastSession.longitude ? (
        mapHeight && (
          <Suspense
            fallback={
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  justifyContent: 'center',
                  alignContent: 'center',
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                }}
              >
                Loading...
              </div>
            }
          >
            <div className="map-section">
              <Mapbox
                lat={lastSession.latitude}
                long={lastSession.longitude}
                zoom={10}
                width={300}
                height={mapHeight}
              />
              <div className="longlat">
                <div>APPROX. CELL TOWER LOCATION</div>
                <div>
                  {lastSession.latitude} {lastSession.latitude > 0 ? 'N.' : 'S.'}{' '}
                  {lastSession.longitude} {lastSession.longitude > 0 ? 'E.' : 'W.'}
                </div>
              </div>
            </div>
          </Suspense>
        )
      ) : (
        <div className="grid-row grid-column v-align h-align">
          <Icon
            className="DeviceLocationMap__empty-icon"
            name="CircleInfo"
            size="large"
            fill="DdsColorInteractivePrimaryDefault"
          />
          There is no location data to display for this device right now.
        </div>
      )}
    </div>
  );
};

export default DeviceLocationMap;
