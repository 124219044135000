import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import analyticsEventBuilder from 'common-js/analytics';
import Footer, { ContinueButton, BackButton, SkipButton } from '../common/scaffolding/Footer';

interface LimitsFooterProps extends FooterProps {}

const LimitsFooter = ({
  nextStep,
  prevStep,
  skipStep,
  isOnboarding = false,
  changingPlan = false,
}: LimitsFooterProps) => {
  const {
    formState: { errors },
    getValues,
  } = useFormContext();
  const curUsageLimit = useWatch({
    name: 'usageLimit',
  });

  const isDisabled =
    !curUsageLimit.type ||
    (curUsageLimit.type === 'custom' && (curUsageLimit.amount < 1 || !!errors.usageLimit));

  const { amount, unit } = getValues('usageLimit');

  const onboardingText = isOnboarding ? '1st Time' : 'Returning';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';
  const trackAnalyticsEvent = useCallback(() => {
    analyticsEventBuilder
      .dataEntry(
        analyticsTitle,
        changingPlan ? undefined : onboardingText,
        `${analyticsTitle} Custom Usage Limit Entry`
      )
      .send({ 'Usage limit entered': `${amount} ${unit}` });
  }, [amount, analyticsTitle, changingPlan, onboardingText, unit]);

  const handleContinueClick = useCallback(() => {
    if (amount) {
      trackAnalyticsEvent();
    }
    nextStep!();
  }, [amount, nextStep, trackAnalyticsEvent]);

  return (
    <Footer
      left={<BackButton onClick={prevStep} />}
      right={
        <>
          <SkipButton onClick={skipStep} />
          <ContinueButton onClick={handleContinueClick} disabled={isDisabled} />
        </>
      }
    />
  );
};

export default LimitsFooter;
