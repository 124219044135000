import { Icon } from '@hologram-dimension/icon';
import { Checkbox } from '@holokit/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

function renderValidation(alwaysRenderValidation, error) {
  if (alwaysRenderValidation) {
    return <div className={`inline-error${error ? ' hasError' : ''}`}>{error || 'X'}</div>;
  }
  if (error) {
    return <div className="inline-error">{error}</div>;
  }

  return null;
}

class Input extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
    };
  }

  onChange(e) {
    const { onChange, onChangeProxy } = this.props;

    this.setState({
      dirty: true,
    });
    onChange(e);
    onChangeProxy(e);
  }

  render() {
    const {
      value,
      name,
      disabled = false,
      inputClass = 'string',
      onBlur = _.noop(),
      type = 'string',
      error = null,
      showValidation = true,
      alwaysRenderValidation = false,
      showValidIcon = false,
      autoFocus = false,
      tabIndex = '',
      placeholder = '',
      subText = '',
      maxLength = null,
      hideFromHotjar = false,
      innerRef,
      id,
      classNames,
      autoComplete,
    } = this.props;

    const { dirty } = this.state;

    const inputAttributes = {
      name,
      onBlur,
      disabled,
      autoFocus,
      tabIndex,
      placeholder,
      maxLength,
      autoComplete,
      checked: value ?? false,
      onChange: (e) => this.onChange(e),
      className: `${inputClass}${error ? ' error' : ''}`,
      ref: innerRef,
    };
    if (hideFromHotjar) inputAttributes['data-hj-masked'] = true;
    if (id) inputAttributes.inputProps = { id };

    return (
      <div className={`input-container ${classNames} ${type}${disabled ? ' disabled' : ''}`}>
        {type === 'checkbox' ? (
          <Checkbox {...inputAttributes} />
        ) : (
          <input type={type} {...inputAttributes} />
        )}
        {showValidIcon && dirty && !error && (
          <Icon name="CircleCheckmark" size="medium" className="InputValidIcon" />
        )}
        {subText && <div className="inline-info">{subText}</div>}
        {showValidation && renderValidation(alwaysRenderValidation, dirty && error)}
      </div>
    );
  }
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeProxy: PropTypes.func,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.string,
  showValidation: PropTypes.bool,
  alwaysRenderValidation: PropTypes.bool,
  showValidIcon: PropTypes.bool,
  autoFocus: PropTypes.bool,
  classNames: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  autoComplete: PropTypes.string,
};

Input.defaultProps = {
  classNames: '',
  onChangeProxy: _.noop,
};

export default React.forwardRef((props, ref) => <Input innerRef={ref} {...props} />);
