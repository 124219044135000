import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HoloHelmet } from '../../common/components';
import { TacChangeAlertsTable } from '../components';
import { getTotalDeviceCount } from '../../common/reducers/organization/actions';
import * as alertTypes from '../../common/constants/alertTypes';
import {
  getAlertsByType,
  getAlertCountByType,
  clearAlertsByType,
} from '../../common/reducers/alerts/actions';
import { pushGlobalMessage } from '../../common/reducers/message/actions';

class TacChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      clearAlertsByType_,
      getAlertsByType_,
      getAlertCountByType_,
      pushGlobalMessage_,
      getTotalDeviceCount_,
    } = this.props;

    clearAlertsByType_(alertTypes.TAC_CHANGE);

    Promise.all([
      getAlertsByType_(alertTypes.TAC_CHANGE),
      getAlertCountByType_(alertTypes.TAC_CHANGE),
      getTotalDeviceCount_(),
    ])
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        pushGlobalMessage_(error, 'error');
      });
  }

  render() {
    const { deviceCount } = this.props;
    const { isLoading } = this.state;

    return (
      <>
        <HoloHelmet title="Action items - SIMs moved" />
        <TacChangeAlertsTable isInitialPageLoad={isLoading} deviceCount={deviceCount} />
      </>
    );
  }
}

const createMapStateToProps = () => (state) => ({
  deviceCount: state.organization.deviceCount,
  releaseFlags: state.releaseFlag,
});

export default connect(createMapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      getTotalDeviceCount_: getTotalDeviceCount,
      getAlertsByType_: getAlertsByType,
      pushGlobalMessage_: pushGlobalMessage,
      getAlertCountByType_: getAlertCountByType,
      clearAlertsByType_: clearAlertsByType,
    },
    dispatch
  )
)(TacChange);
