import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from '@holokit/core';
import { ModalHeader, MessagePanel } from 'common-js/components';
import { bulkMoveDevices, clearSelection, fetchDevices } from 'common-js/reducers/devices/actions';
import { getSelectedLoadedDevices } from 'common-js/reducers/devices/selectors';
import { close } from 'common-js/reducers/modal/actions';
import { getUnSelectedOrgs } from 'common-js/reducers/organization/selectors';

class BulkMoveDevicesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      serverError: null,
      isSuccessful: false,
      successObject: null,
      destinationOrgId: '',
    };
  }

  onAction = () => {
    const { bulkMoveDevices_, clearSelection_, selectedDevices, fetchDevices_ } = this.props;
    const { destinationOrgId } = this.state;

    if (destinationOrgId === '') {
      this.setState({
        serverError: 'Please select an organization.',
      });
      return;
    }

    this.setState({
      isProcessing: true,
      serverError: null,
      isSuccessful: false,
      successObject: null,
    });

    bulkMoveDevices_(
      selectedDevices.map(({ id }) => id),
      destinationOrgId
    )
      .then((successObject) => {
        clearSelection_();
        this.setState({
          isProcessing: false,
          isSuccessful: true,
          successObject,
        });
        fetchDevices_();
      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          isSuccessful: false,
          serverError: e,
          successObject: null,
        });
      });
  };

  generateProcessingButtonElement() {
    const { closeModal } = this.props;
    const { isProcessing, isSuccessful } = this.state;
    if (isProcessing) {
      return <Button type="primary">Working...</Button>;
    }
    if (isSuccessful) {
      return (
        <Button onClick={closeModal} type="primary">
          Done
        </Button>
      );
    }
    return (
      <Button onClick={this.onAction} type="primary">
        Transfer devices
      </Button>
    );
  }

  render() {
    const { unSelectedOrgs, selectedDevices } = this.props;
    const { serverError, successObject, destinationOrgId } = this.state;

    return (
      <div className="BulkOverageModal">
        <ModalHeader title="Bulk action: Transfer devices to organization" />
        <div className="f2 section">Devices</div>
        <div className="device-tags section">
          {selectedDevices.slice(0, 10).map(({ id, name }) => (
            <div className="tag" key={id}>
              <Icon
                classes="device-tags__icon"
                name="Project"
                size="minor"
                svgProps={{ style: { fill: '#000000' } }}
              />
              {name}
            </div>
          ))}
        </div>
        <div className="f2 section">Move devices to:</div>
        <div className="form section">
          <div className="select-wrap">
            <select
              value={destinationOrgId}
              onChange={(e) => this.setState({ destinationOrgId: e.currentTarget.value })}
            >
              <option default value="">
                Select an organization...
              </option>
              {unSelectedOrgs.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {serverError && (
          <div className="section">
            <MessagePanel fullWidth messageType="error">
              {serverError}
            </MessagePanel>
          </div>
        )}
        {successObject && (
          <div>
            {successObject.successes?.length > 0 && (
              <div className="section">
                <MessagePanel messageType="success" fullWidth>
                  {successObject.successes.map(({ item, deviceId }) => (
                    <div key={deviceId}>
                      <b>{item}: </b> Successfully completed operation.
                    </div>
                  ))}
                </MessagePanel>
              </div>
            )}
            {successObject.failures?.length > 0 && (
              <div className="section">
                <MessagePanel messageType="error" fullWidth>
                  <div>There were errors: </div>
                  {successObject.failures.map(({ item, reason, deviceId }) => (
                    <div key={deviceId}>
                      {item ? (
                        <span>
                          <b>{item}: </b>
                          {reason}
                        </span>
                      ) : (
                        <b>{reason}</b>
                      )}
                    </div>
                  ))}
                </MessagePanel>
              </div>
            )}
          </div>
        )}
        {this.generateProcessingButtonElement()}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    selectedDevices: getSelectedLoadedDevices(state),
    unSelectedOrgs: getUnSelectedOrgs(state) || [],
  }),
  (dispatch) =>
    bindActionCreators(
      {
        clearSelection_: clearSelection,
        closeModal: close,
        bulkMoveDevices_: bulkMoveDevices,
        fetchDevices_: fetchDevices,
      },
      dispatch
    )
)(BulkMoveDevicesModal);
