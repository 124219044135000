import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Callout } from '@holokit/core';
import { ModalHeader } from '../components';

import { close } from '../reducers/modal/actions';

class DeleteTagModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleDeleteTag = () => {
    const { action } = this.props;

    this.setState({ isLoading: true }, () => action());
  };

  render() {
    const { closeModal, tagName, devicesCount } = this.props;
    const { isLoading } = this.state;
    const hasEffectedDevices = devicesCount > 0;

    return (
      <div className="DeleteTagModal">
        <ModalHeader title="Delete tag" />
        <p className="DeleteTagModal__text">
          Are you sure you want to delete the tag &quot;{tagName}?&quot;
        </p>
        <Callout
          type={hasEffectedDevices ? Callout.TYPE.ERROR : Callout.TYPE.DEFAULT}
          text={
            hasEffectedDevices
              ? `${tagName} will be removed from ${devicesCount} of your devices`
              : `${tagName} is not in use by any devices`
          }
          iconLeft={hasEffectedDevices ? 'Circle--alert' : 'Circle--info'}
          iconColor={hasEffectedDevices ? Callout.THEME.COLOR : Callout.THEME.BLACK}
          className="DeleteTagModal__callout"
        />
        <div className="DeleteTagModal__buttons">
          <Button loading={isLoading} onClick={this.handleDeleteTag} type="destructiveMajor">
            Yes, delete tag
          </Button>
          <Button onClick={closeModal} type="secondary">
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      closeModal: close,
    },
    dispatch
  )
)(DeleteTagModal);
