import { IMPERSONATE, IMPERSONATE_STOP } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as API from 'common-js/api';
import * as AdminAPI from 'common-js/api/admin';
import { fetchAccount } from 'common-js/reducers/account/actions';
import * as actionTypes from './actionTypes';

export function getAllReleaseFlags() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALLRELEASEFLAGS_REQUEST,
    });

    return API.getAllReleaseFlags()
      .then((result) => {
        dispatch({
          type: actionTypes.GET_ALLRELEASEFLAGS_SUCCESS,
          flags: result,
        });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ALLRELEASEFLAGS_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function makeReleaseFlagPublic(flag) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.POST_RELEASEFLAG_PUBLIC_REQUEST,
    });

    return API.makeReleaseFlagPublic(flag)
      .then((result) => {
        dispatch({
          type: actionTypes.POST_RELEASEFLAG_PUBLIC_SUCCESS,
          flags: result,
        });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.POST_RELEASEFLAG_PUBLIC_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function removeReleaseFlagFromPublic(flag) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_RELEASEFLAG_PUBLIC_REQUEST,
    });

    return API.removeReleaseFlagFromPublic(flag)
      .then((result) => {
        dispatch({
          type: actionTypes.DELETE_RELEASEFLAG_PUBLIC_SUCCESS,
          flags: result,
        });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_RELEASEFLAG_PUBLIC_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function setReleaseFlagForOrg(orgid, flag, flagstate) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PUT_RELEASEFLAG_FOR_ORG_REQUEST,
    });

    return API.setReleaseFlagForOrg(orgid, flag, flagstate)
      .then((result) => {
        dispatch({
          type: actionTypes.PUT_RELEASEFLAG_FOR_ORG_SUCCESS,
          flag,
          orgs: result,
        });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.PUT_RELEASEFLAG_FOR_ORG_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function stopImpersonating() {
  return (dispatch) => {
    dispatch({ type: actionTypes.EXIT_IMPERSONATE_REQUEST });

    return API.stopImpersonating()
      .then(() => {
        dispatch({
          type: actionTypes.EXIT_IMPERSONATE_SUCCESS,
        });

        return API.getUserInfo().then((userData) => {
          const orgId = userData?.userInfo?.orgId;
          const userId = userData?.userInfo?.userId;

          sendAnalyticsEvent({
            type: IMPERSONATE_STOP,
            data: {
              user_id: userId,
              org_id: orgId,
            },
          });

          return dispatch(fetchAccount(true));
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EXIT_IMPERSONATE_ERROR,
          error,
        });

        return Promise.reject(error);
      });
  };
}

interface ImpersonateUserProps {
  email?: string;
  orgId?: OrgId;
  userid?: string;
}

export const impersonateUser =
  ({ email, orgId, userid }: ImpersonateUserProps) =>
  (dispatch) => {
    dispatch({ type: actionTypes.IMPERSONATE_REQUEST });

    const errorHandler = (error) => {
      dispatch({
        type: actionTypes.IMPERSONATE_ERROR,
        error,
      });

      return Promise.reject(error);
    };

    const handleSuccess = () => {
      dispatch({ type: actionTypes.IMPERSONATE_SUCCESS });

      sendAnalyticsEvent({
        type: IMPERSONATE,
        data: {
          user_id: userid,
          org_id: orgId,
        },
      });

      dispatch(fetchAccount(true)).catch((e) => {
        // If we fail, try to return to the logged in user
        dispatch(stopImpersonating()).finally(() => {
          Promise.reject(new Error(`Failed to impersonate: ${e}`));
        });
      });
    };

    if (orgId || email) {
      return API.impersonateUser({ orgId, email }).then(handleSuccess).catch(errorHandler);
    }
    return AdminAPI.getUserById(userid)
      .then((result) => result.email)
      .then((updatedEmail) => API.impersonateUser({ email: updatedEmail }))
      .then(handleSuccess)
      .catch(errorHandler);
  };

export function orgSearch(query) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ORGSEARCH_REQUEST });

    const encoded = encodeURIComponent(query);
    return API.searchOrganizations(encoded, false)
      .then((result) => {
        const orgs = result.orgs.filter((org) => !org.is_personal);
        dispatch({ type: actionTypes.ORGSEARCH_SUCCESS, orgs });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.ORGSEARCH_ERROR, error });

        return Promise.reject(error);
      });
  };
}

export function getAllCarriers() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALLCARRIERS_REQUEST,
    });

    return AdminAPI.getAllCarriers()
      .then((result) => {
        dispatch({
          type: actionTypes.GET_ALLCARRIERS_SUCCESS,
          carriers: result,
        });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ALLCARRIERS_ERROR,
          error,
        });
        return Promise.reject(error);
      });
  };
}
