import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@holokit/core';
import * as messageActions from 'common-js/reducers/message/actions';
import { setTunnelable as setTunnelableActionCreator } from 'common-js/reducers/currentDevice/actions';

class EnableSpaceBridgeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
    };
  }

  enableTunneling = () => {
    const { device, pushGlobalMessage, setTunnelable } = this.props;

    this.setState({ isProcessing: true });

    setTunnelable(device.id, true)
      .then(() => {
        pushGlobalMessage('Tunneling successfully enabled for this device.', 'success');
        this.setState({ isProcessing: false });
      })
      .catch((e) => {
        pushGlobalMessage(e, 'error');
        this.setState({ isProcessing: false });
      });
  };

  disableTunneling = () => {
    const { device, pushGlobalMessage, setTunnelable } = this.props;

    this.setState({ isProcessing: true });

    setTunnelable(device.id, false)
      .then(() => {
        pushGlobalMessage('Tunneling successfully disabled for this device.', 'success');
        this.setState({
          isProcessing: false,
        });
      })
      .catch((e) => {
        pushGlobalMessage(e, 'error');
        this.setState({
          isProcessing: false,
        });
      });
  };

  render() {
    const { device } = this.props;
    const { isProcessing } = this.state;

    if (isProcessing) {
      return <Button type="destructiveMinor">Processing...</Button>;
    }

    if (device.tunnelable) {
      return (
        <Button onClick={this.disableTunneling} type="destructiveMinor">
          Disable tunneling
        </Button>
      );
    }

    return (
      <Button onClick={this.enableTunneling} type="primary">
        Enable tunneling
      </Button>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setTunnelable: setTunnelableActionCreator,
      pushGlobalMessage: messageActions.pushGlobalMessage,
    },
    dispatch
  )
)(EnableSpaceBridgeButton);
