import useBulkDeviceStateChange from 'common-js/api/devices/useBulkDeviceStateChange';
import {
  BULK_DEACTIVATE_DEVICES,
  BULK_DEACTIVATE_DEVICES_ERROR,
  BULK_DEACTIVATE_DEVICES_SUCCESS,
  PAUSE_DATA_BULK_ERROR,
  PAUSE_DATA_BULK_REQUEST,
  PAUSE_DATA_BULK_SUCCESS,
  RESUME_DATA_BULK_ERROR,
  RESUME_DATA_BULK_REQUEST,
  RESUME_DATA_BULK_SUCCESS,
} from 'common-js/reducers/devices/actionTypes';
import { selectHasBizLayerPauseResumeFlag } from 'common-js/reducers/releaseFlag/selectors';
import {
  DeviceFilters,
  DeviceSelection,
  DeviceStateChangeAllowedStates,
} from 'common-js/types/Device';
import { useCallback, useMemo } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';

interface UseBulkChangeStateEventProps {
  selection: DeviceSelection;
  filters?: DeviceFilters;
  useSearch: boolean;
  orgId: OrgId;
  newState: DeviceStateChangeAllowedStates;
}
export interface PauseDataBulkError {
  error: any;
}

interface UseBulkChangeStateEventCallbackProps {
  deviceIdsWontBeModified?: Array<DeviceId>;
}

const useBulkChangeStateEvent = ({
  selection,
  filters,
  useSearch,
  orgId,
  newState,
}: UseBulkChangeStateEventProps) => {
  const dispatch = useAppDispatch();
  const useBizLayer: boolean = useAppSelector(selectHasBizLayerPauseResumeFlag);
  const pauseDataBulkApiCall = useBulkDeviceStateChange({
    newState,
    preview: false,
    useBusinessLayer: useBizLayer,
    orgId,
  });

  const { requestType, successType, errorType } = useMemo(() => {
    switch (newState) {
      case 'live':
        return {
          requestType: RESUME_DATA_BULK_REQUEST,
          successType: RESUME_DATA_BULK_SUCCESS,
          errorType: RESUME_DATA_BULK_ERROR,
        };
      case 'pause':
        return {
          requestType: PAUSE_DATA_BULK_REQUEST,
          successType: PAUSE_DATA_BULK_SUCCESS,
          errorType: PAUSE_DATA_BULK_ERROR,
        };
      case 'deactivate':
        return {
          requestType: BULK_DEACTIVATE_DEVICES,
          successType: BULK_DEACTIVATE_DEVICES_SUCCESS,
          errorType: BULK_DEACTIVATE_DEVICES_ERROR,
        };
      default:
        throw new Error(`Unsupported state: ${newState}`);
    }
  }, [newState]);

  return useCallback(
    async ({ deviceIdsWontBeModified }: UseBulkChangeStateEventCallbackProps) => {
      dispatch({
        type: requestType,
      });

      try {
        const selectionParams = { ...selection };

        selectionParams.excludedIds = selection.excludedIds ?? {};
        deviceIdsWontBeModified?.forEach((deviceId) => {
          selectionParams.excludedIds![deviceId] = undefined;
        });

        const data = await pauseDataBulkApiCall({
          selection: selectionParams,
          filters,
          useSearch,
        });

        dispatch({
          type: successType,
          loadingKey: requestType,
          data: {
            preview: data.preview,
            validTasks: data.valid_tasks,
            jobId: data.jobId,
          },
        });

        return data;
      } catch (error) {
        const errorObj: PauseDataBulkError = error as any;
        dispatch({
          type: errorType,
          loadingKey: requestType,
          error: errorObj,
        });

        return errorObj;
      }
    },
    [
      dispatch,
      errorType,
      filters,
      pauseDataBulkApiCall,
      requestType,
      selection,
      successType,
      useSearch,
    ]
  );
};

export default useBulkChangeStateEvent;
