import { Button } from '@hologram-dimension/button';
import { Radio } from '@hologram-dimension/radio';
import useHandleCoverageLink from 'activation/useHandleCoverageLink';
import _classNames from 'clsx';
import React, { FC, useCallback } from 'react';

export type RegionOnChange = (params: { id: RegionId; displayName: string }) => void;
interface RegionCardProps {
  id: RegionId;
  displayName: string;
  description: string;
  onChange: RegionOnChange;
  selected?: boolean;
  isOnboarding?: boolean;
  disabled?: boolean;
  changingPlan: boolean;
}

const RegionCard: FC<RegionCardProps> = ({
  id,
  displayName,
  description,
  onChange,
  selected = false,
  isOnboarding = false,
  disabled,
  changingPlan,
}) => {
  const changeRegion = useCallback(() => {
    onChange({
      id,
      displayName,
    });
  }, [displayName, id, onChange]);

  const handleCoverageLink = useHandleCoverageLink({ isOnboarding, changingPlan });

  if (!id || !displayName) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor={`region_select_${id}`}>
      <div
        className={_classNames(
          'RegionCard',
          selected ? 'RegionCard--selected' : null,
          disabled ? 'RegionCard--disabled' : null
        )}
      >
        <Radio
          name="region"
          id={`region_select_${id}`}
          value={id.toString()}
          className="RegionCard__input"
          onChange={changeRegion}
          defaultChecked={selected}
          label=""
          hiddenLabel
          disabled={disabled}
        />
        <div className="RegionCard__label">
          <div>
            <p className="RegionCard__displayName">{displayName}</p>
            <p className="RegionCard__description">{description}</p>
          </div>
          <div>
            <Button
              iconEnd="ExternalLink"
              variant="secondary"
              size="small"
              onClick={handleCoverageLink}
            >
              Learn more
            </Button>
          </div>
        </div>
      </div>
    </label>
  );
};

const RegionCardMemoized = React.memo(RegionCard, (prev, cur) => prev.selected === cur.selected);

export default RegionCardMemoized;
