import React, { useEffect, useMemo, useState, useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Dropdown } from '@holokit/core';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { labelForRole } from 'common-js/utils/permissions';

function RoleDropdown({ onChange, error, isAdmin, isRoleConfigured }) {
  const defaultValue = isAdmin ? 'placeholder' : 'editor';
  const [selectedRole, setSelectedRole] = useState(defaultValue);

  const dropdownLabel = labelForRole(selectedRole).label;

  const releaseFlags = useAppSelector(getReleaseFlags);
  const hasEditorLimited = releaseFlags?.add_editor_limited_role;

  useEffect(() => {
    isRoleConfigured(selectedRole);
  }, [selectedRole, isRoleConfigured]);

  const handleClick = useCallback(
    (role) => {
      setSelectedRole(role);
      onChange(role);
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Permissions - Select Role',
          role,
        },
      });
    },
    [onChange]
  );

  const roleDropdownItems = useMemo(
    () => [
      {
        children: 'Admin',
        caption: 'Assign roles, activate and deactivate devices, change plans, manage billing',
        onClick: () => handleClick('admin'),
        sectionId: 1,
        closeAfterClick: true,
      },
      {
        children: 'Manager',
        caption: 'Activate and deactivate devices, change plans, access limited billing details',
        onClick: () => handleClick('manager'),
        sectionId: 2,
        closeAfterClick: true,
      },
      {
        children: 'Editor',
        caption:
          'View devices and cloud messaging, manage device settings and app integrations, access limited billing details',
        onClick: () => handleClick('editor'),
        sectionId: 3,
        closeAfterClick: true,
      },
      ...(hasEditorLimited
        ? [
            {
              children: 'Editor Limited',
              caption:
                'View devices and cloud messaging, manage device settings and app integrations',
              onClick: () => handleClick('editor_limited'),
              sectionId: 4,
              closeAfterClick: true,
            },
          ]
        : []),
    ],
    [handleClick, hasEditorLimited]
  );

  return (
    <div className="RoleDropdown">
      <Dropdown
        dropdownText={dropdownLabel}
        items={roleDropdownItems}
        buttonClasses={error && 'RoleDropdown--error'}
        buttonProps={{ type: 'button' }}
        disabled={!isAdmin}
      />
    </div>
  );
}

export default RoleDropdown;
