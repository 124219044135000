import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@holokit/core';
import * as DeviceStates from 'common-js/constants/deviceStates';
import EmptyCell from './EmptyCell';

function CellDevicePreflightConfig({ deviceState }) {
  switch (deviceState) {
    case DeviceStates.TEST_ACTIVATE_PENDING:
    case DeviceStates.TEST_ACTIVATE:
      return (
        <>
          <span>Test</span>
          <Icon
            name="Arrow--triangle--east"
            size="micro"
            svgProps={{ style: { fill: '#acb4c3' } }}
          />
          <span>Activate</span>
        </>
      );
    case DeviceStates.TEST_HIBERNATE_PENDING:
    case DeviceStates.TEST_HIBERNATE:
    case DeviceStates.INACTIVE_TESTED:
      return (
        <>
          <span>Test</span>
          <Icon name="Arrow--triangle--east" size="micro" />
          <span>Hibernate</span>
        </>
      );
    case DeviceStates.TEST_AUTOACTIVATE_PENDING:
    case DeviceStates.TEST_AUTOACTIVATE:
      return 'Auto-activate';
    default:
      return <EmptyCell />;
  }
}

CellDevicePreflightConfig.propTypes = {
  deviceState: PropTypes.string.isRequired,
};

export default CellDevicePreflightConfig;
