import { Button } from '@hologram-dimension/button';
import { REPORTS_OVERVIEW, withContext } from 'common-js/constants/paths';
import { FC } from 'react';
import { browserHistory } from 'react-router';
import ReportsError from '../ReportsError';

const ReportNotFoundError: FC = () => {
  const handleClick = () => browserHistory.push(withContext(REPORTS_OVERVIEW));
  return (
    <ReportsError
      caption="This report does not exist"
      button={
        <Button
          variant="secondary"
          iconStart="ChevronSingleWest"
          size="small"
          onClick={handleClick}
        >
          Back to Reports
        </Button>
      }
    />
  );
};

export default ReportNotFoundError;
