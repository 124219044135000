import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { Callout, DeepMenu, DeepMenuItem } from '@holokit/core';
import Region from 'activation/region/Region';
import analyticsEventBuilder from 'common-js/analytics';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { SelectedSims } from 'common-js/reducers/activation/actions/types';
import {
  selectAllowedRegions,
  selectAllowedStandardPlans,
  selectChangePlanLinks,
  selectCustomPlans,
  selectPlanData,
} from 'common-js/reducers/activation/selectors';
import { Device } from 'common-js/types/Device';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import type { ReduxLink } from 'types/redux';
import { useCarriersForSelectedSims } from '../common/hooks';
import CustomPlansPanel from './CustomPlansPanel';
import DefaultPlansPanel from './DefaultPlansPanel';
import usePlanConfig, { PLAN_TABS } from './usePlanConfig';

interface PlanProps extends StepProps {}

const Plan = ({
  isOnboarding = false,
  hasCustomPlans = false,
  changingPlan = false,
}: PlanProps) => {
  const { getValues, control } = useFormContext();
  const sims = getValues().sims as SelectedSims;
  const [selectedTab, setSelectedTab] = useState(PLAN_TABS.CUSTOM);
  const changePlanLinks: Array<ReduxLink> | undefined = useAppSelector(selectChangePlanLinks);

  // Forms
  const {
    field: { value: plan, onChange },
  } = useController({ name: 'plan', control });
  const {
    field: { onChange: onTypeChange },
  } = useController({
    name: 'usageLimit.type',
    control,
  });
  const {
    field: { onChange: onRegionChange },
  } = useController({ name: 'region.selected', control });

  const { id: regionId } = useWatch({
    name: 'region.selected',
    defaultValue: {},
  });

  const devices: Array<Device> = useAppSelector((state) => state.activation?.changePlan?.devices);
  const existingPlanId = (changingPlan && devices && devices[0] && devices[0].planId) || undefined;

  // Plans
  const { fetchingCarriers, error, carrierIds } = useCarriersForSelectedSims(sims, changePlanLinks);

  // Used on custom orgs to determine if we'll ever have any available regardless of region
  const filteredPlansBySims = useAppSelector((state) =>
    selectAllowedStandardPlans(state, { carrierIds })
  );
  const allowedStandardPlans = useAppSelector((state) =>
    selectAllowedStandardPlans(state, { carrierIds, regionId })
  );
  const filteredCustomPlansBySims = useAppSelector((state) =>
    selectCustomPlans(state, { carrierIds })
  );

  // Regions
  const allowedRegions = useAppSelector((state) => selectAllowedRegions(state, carrierIds));

  // Pre-flight
  const isPreflight = useAppSelector((state) => state.activation?.flags?.isPreflight);
  const selectedPreflightSims = useAppSelector((state) => state.activation?.preflight?.sims);
  const preflightPlanId = useMemo(() => {
    if (isPreflight && selectedPreflightSims?.length > 0) {
      const preflightSimPlanId = selectedPreflightSims[0].planId;

      if (preflightSimPlanId !== 0) {
        return preflightSimPlanId;
      }
    }
    return null;
  }, [isPreflight, selectedPreflightSims]);

  const deviceIds = useMemo(() => devices?.map((device) => device.id), [devices]);
  const numSims = (changingPlan ? deviceIds && deviceIds.length : sims?.total) ?? 1;

  const preflightPlanData = useAppSelector((state) => selectPlanData(state, preflightPlanId));
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';

  // Callbacks
  const handleTabChange = useCallback(
    (newSelectedTab) => {
      const onboardingText = isOnboarding ? '1st Time' : 'Returning';
      if (newSelectedTab === PLAN_TABS.CUSTOM) {
        onRegionChange({});

        analyticsEventBuilder
          .buttonClick(
            analyticsTitle,
            changingPlan ? undefined : onboardingText,
            `${analyticsTitle} Plan Toggle`
          )
          .send({ 'Toggle selection': 'To Custom Plans' });
      } else {
        analyticsEventBuilder
          .buttonClick(
            analyticsTitle,
            changingPlan ? undefined : onboardingText,
            `${analyticsTitle} Plan Toggle`
          )
          .send({ 'Toggle selection': 'To Hologram Standard Plans' });
      }
      onChange({});
      setSelectedTab(newSelectedTab);
    },
    [isOnboarding, onChange, onRegionChange, analyticsTitle, changingPlan]
  );

  const handleChange = useCallback(
    (selectedPlan) => {
      onChange(selectedPlan);
      onTypeChange(selectedPlan.dataLimit === 0 ? 'custom' : '');
    },
    [onChange, onTypeChange]
  );

  const handleRegionChange = useCallback(
    (newRegion) => {
      onChange({});
      onRegionChange(newRegion);
    },
    [onChange, onRegionChange]
  );

  const clearRegion = useCallback(() => {
    handleRegionChange({});
  }, [handleRegionChange]);

  // Select the pre-flight plan if we need to
  const preflightPlanDataLoaded = !!preflightPlanData?.id;
  useEffect(() => {
    if (!fetchingCarriers && preflightPlanDataLoaded && plan?.id !== preflightPlanData.id) {
      handleChange({
        ...preflightPlanData,
        planName: preflightPlanData.name,
        planType: 'custom',
      });
    }
  }, [handleChange, fetchingCarriers, plan?.id, preflightPlanData, preflightPlanDataLoaded]);

  const {
    isLoading,
    customPlanList,
    canChangePlanView,
    showCustomPlans,
    hasSelectableRegions,
    showStandardRegionSelect,
    noAllowedPlansAndNoCoverage,
  } = usePlanConfig({
    hasAnyStandardPlansBySims: filteredPlansBySims && filteredPlansBySims.length > 0,
    onlyOneStandardRegion: allowedRegions?.length === 1,
    filteredCustomPlansBySims,
    fetchingCarriers,
    hasCustomPlans,
    isPreflight,
    preflightPlanData,
    preflightPlanDataLoaded,
    preflightPlanId,
    selectedTab,
    selectedRegionId: regionId,
  });

  // If the selected plan is not on this tab, switch tabs
  useEffect(() => {
    if (
      selectedTab === PLAN_TABS.CUSTOM &&
      plan &&
      allowedStandardPlans?.some((defaultPlan) => plan.id === defaultPlan.id)
    ) {
      setSelectedTab(PLAN_TABS.STANDARD);
    }
  }, [allowedStandardPlans, plan, selectedTab]);

  useEffect(() => {
    if (!regionId && allowedRegions?.length === 1) {
      handleRegionChange(allowedRegions[0]);
    }
  }, [allowedRegions, handleRegionChange, regionId]);

  return (
    <>
      {canChangePlanView && (
        <DeepMenu className="ActivationPlans__deepMenu">
          <DeepMenuItem
            text="Custom"
            active={selectedTab === PLAN_TABS.CUSTOM}
            onClick={() => handleTabChange(PLAN_TABS.CUSTOM)}
          />
          <DeepMenuItem
            text="Standard"
            active={selectedTab === PLAN_TABS.STANDARD}
            onClick={() => handleTabChange(PLAN_TABS.STANDARD)}
          />
        </DeepMenu>
      )}
      {error && <Callout type={Callout.TYPE.ERROR} text={error} />}
      {isLoading && (
        <Panel isLoading>
          <div className="LoadingPanelBody">Loading data plans...</div>
        </Panel>
      )}
      {!isLoading && showCustomPlans && (
        <CustomPlansPanel
          isLoading={!customPlanList || fetchingCarriers}
          customPlans={customPlanList}
          selectedPlan={plan}
          onChange={handleChange}
          preflightMode={preflightPlanId !== null}
          numSims={numSims}
          existingPlanId={existingPlanId}
        />
      )}
      {!isLoading && !showCustomPlans && (
        <>
          {showStandardRegionSelect && <Region />}
          {!showStandardRegionSelect && (
            <div className="ActivationPlans__StandardWrapper">
              {hasSelectableRegions && (
                <Button variant="tertiary" iconStart="ArrowWestShort" onClick={clearRegion}>
                  Change coverage area
                </Button>
              )}
              <DefaultPlansPanel
                isLoading={fetchingCarriers}
                allowedStandardPlans={allowedStandardPlans}
                allowedRegions={allowedRegions}
                noAllowedPlansAndNoCoverage={noAllowedPlansAndNoCoverage}
                hasCustomPlans={hasCustomPlans}
                selectedPlan={plan}
                onChange={handleChange}
                numSims={numSims}
                existingPlanId={existingPlanId}
                isOnboarding={isOnboarding}
                changingPlan={changingPlan}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Plan;
