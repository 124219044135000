import { browserHistory } from 'react-router';

export const setActivityHistoryDetailsQueryString = ({ currentPage }) => {
  const searchParams = new URLSearchParams(window.location.search);

  if (currentPage) {
    searchParams.set('page', currentPage);
  } else {
    searchParams.delete('page');
  }

  browserHistory.replace({
    pathname: window.location.pathname,
    search: `?${searchParams.toString()}`,
  });
};

export default setActivityHistoryDetailsQueryString;
