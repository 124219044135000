import React from 'react';
import Section from './Section';

function LoadingChipsSection({ numChips }) {
  return (
    <Section
      title={
        <div className="BulkDataActionModal__loading__placeholder BulkDataActionModal__loading__title" />
      }
    >
      <div className="BulkDataActionModal__links">
        {[...new Array(numChips)].map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`LoadingPlaceholder-${index}`}
            className="BulkDataActionModal__loading__placeholder BulkDataActionModal__loading__chip"
          />
        ))}
      </div>
    </Section>
  );
}

export default LoadingChipsSection;
