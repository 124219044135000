import { Icon } from '@hologram-dimension/icon';
import { Tooltip } from '@holokit/core';
import React, { memo } from 'react';

interface LiveUsageTableWindowCellProps {
  isWindow: boolean;
}

const LiveUsageTableWindowCell = ({ isWindow }: LiveUsageTableWindowCellProps) =>
  isWindow ? (
    <>
      Reporting window
      <Tooltip content="Represents total usage in the time frame. Reporting times on this APN may vary.">
        <Icon name="CircleInfo" size="small" fill="DdsColorPalettePurple50" />
      </Tooltip>
    </>
  ) : (
    <>Session</>
  );

export default memo(LiveUsageTableWindowCell);
