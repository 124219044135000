import { SimInventoryDetailsNotification } from '@hologram-hyper-dashboard/components';
import { FullHeightCalculatorHOC } from 'common-js/components';
import {
  DEVICE_PARAM_NAME,
  SIM_DETAILS,
  SIM_PARAM_NAME,
  withContext,
} from 'common-js/constants/paths';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { setLoading } from 'common-js/reducers/currentDevice/actions';
import {
  selectCurrentDevice,
  selectCurrentDeviceLoading,
} from 'common-js/reducers/currentDevice/selectors';
import { getTags } from 'common-js/reducers/deviceFilter/actions';
import { setDeviceFilter } from 'common-js/reducers/logFilter/actions';
import { selectHasSimsPages } from 'common-js/reducers/releaseFlag/selectors';
import DeviceHeader from 'device/DeviceHeader';
import DeviceMenu from 'device/DeviceMenu';
import useGetAllDeviceData from 'device/useGetAllDeviceData';
import usePollDevice from 'device/usePollDevice';
import SubMenu from 'devices/components/SubMenu';
import { FC, ReactNode, useEffect, useState } from 'react';
import BaseLayout from './BaseLayout';

interface LayoutSingleDeviceProps {
  children?: ReactNode;
  location: RouterLocation;
  params: any;
}

const LayoutSingleDevice: FC<LayoutSingleDeviceProps> = ({ children, location, params }) => {
  const deviceId = params[DEVICE_PARAM_NAME];

  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectCurrentDeviceLoading);
  const device = useAppSelector(selectCurrentDevice);
  const hasSimInventoryPage = useAppSelector(selectHasSimsPages);
  const getAllDeviceData = useGetAllDeviceData();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    const effect = async () => {
      dispatch(setLoading(true));
      await dispatch(getTags()); // the device header requires the current org's tags to work, load it now
      await dispatch(setDeviceFilter(deviceId)); // the logs must be updated to filter to the current device ID
      await getAllDeviceData(deviceId); // sets loading to false once it is done
      setInitialLoadComplete(true);
    };
    effect();

    return () => {
      dispatch(setDeviceFilter(null)); // we have to update the log filters again...
    };
  }, [dispatch, deviceId, getAllDeviceData, setInitialLoadComplete]);

  usePollDevice(deviceId, initialLoadComplete);

  return (
    <BaseLayout isLoading={loading}>
      <SubMenu deviceId={deviceId} />
      <FullHeightCalculatorHOC accountForDrawer>
        <div className="container">
          {!loading && (
            <FullHeightCalculatorHOC classNames="LayoutSingleDevice" accountForDrawer relative>
              <DeviceHeader deviceId={deviceId} />
              {hasSimInventoryPage && (
                <div className="DeviceBanner">
                  <SimInventoryDetailsNotification
                    navigateText="View this SIM"
                    url={withContext(
                      SIM_DETAILS.replace(`:${SIM_PARAM_NAME}`, device.hologramSimId)
                    )}
                  />
                </div>
              )}
              <DeviceMenu deviceId={deviceId} currentPath={location.pathname} />
              <div className="DeviceContent">{children}</div>
            </FullHeightCalculatorHOC>
          )}
        </div>
      </FullHeightCalculatorHOC>
    </BaseLayout>
  );
};

export default LayoutSingleDevice;
