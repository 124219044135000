/* eslint-disable react/no-unused-class-component-methods */
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@holokit/core';
import HyperTestingTimelineTableRow from './HyperTestingTimelineTableRow';

class HyperTestingTableDrawer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timelineRef = React.createRef();
    this.viewboxRef = React.createRef();
    this.rows = [];
  }

  componentDidMount() {
    const { onDrawerLoad, test } = this.props;

    if (this.rows.length < 1) {
      const rowGroups = new Map();
      test.operations.forEach((t) => {
        rowGroups.set(t.concurrentGroupKey, [t, ...t.concurrentWith]);
      });

      // this.rows is now an Array of [[key, [test, test, ...]], [key, [test, test, ...], ...]
      this.rows = Array.from(rowGroups.entries());

      onDrawerLoad();
    }
  }

  renderGroup = (key, group, i) => {
    const lastRowIndex = this.rows.length - 1;

    // if this row/key pairs with multiple tests, stack them inside this div
    // if this is the last test in the last group, apply the last prop to the row component
    if (group?.length > 1) {
      return (
        <div
          className="HyperTestingTimelineTable--stacked"
          key={`HyperTestingTimelineTableRow--${key}`}
        >
          {group.map((test, idx) => (
            <HyperTestingTimelineTableRow
              first={idx === 0}
              id={`HyperTestingTimelineTableRow--${test.id}`}
              key={test.id}
              test={test}
              last={group.length === idx + 1 && lastRowIndex === i}
            />
          ))}
        </div>
      );
    }

    // if this row/key pairs with only one test, render only one row component for each test in the group & the lastRow should get the last prop
    if (group) {
      return group.map((test) => (
        <HyperTestingTimelineTableRow
          first
          id={`HyperTestingTimelineTableRow--${test.id}`}
          key={test.id}
          test={test}
          last={lastRowIndex === i}
        />
      ));
    }

    return undefined;
  };

  render() {
    const { test } = this.props;

    return (
      <div className="HyperTestingDrawer">
        <div className="HyperTestingDrawer__panel">
          <div className="HyperTestingDrawer__panel__header">
            <div className="HyperTestingDrawer__panel__header__headline">Event Timeline</div>
            <div className="HyperTestingDrawer__panel__header__btn-wrapper">
              <Button
                href={`${process.env.VITE_API_ROOT}/devices/${test.deviceid}/euicc/history`}
                iconLeft="Arrow--lineinto--south"
                type="reversed"
              >
                View JSON
              </Button>
            </div>
          </div>
          <div className="HyperTestingDrawer__panel__body">
            <div className="HyperTestingDrawer__timeline-table">
              <div className="HyperTestingDrawer__timeline-table__top-row" />
              <div className="HyperTestingDrawer__timeline-table__middle-row">
                <div className="HyperTestingDrawer__timeline-table__middle-row__left" />
                <div className="HyperTestingDrawer__timeline-table__middle-row__right" />
              </div>
            </div>

            {this.rows.map(([key, group], i) => this.renderGroup(key, group, i))}
          </div>
        </div>
      </div>
    );
  }
}

HyperTestingTableDrawer.propTypes = {
  onDrawerLoad: PropTypes.func.isRequired,
  test: PropTypes.shape({
    id: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    timestamp: PropTypes.object,
    deviceid: PropTypes.string,
    actionName: PropTypes.string,
    stateName: PropTypes.string,
    formattedErrorMessage: PropTypes.string,
    concurrentWith: PropTypes.shape({ length: PropTypes.number }), // TODO: This looks like an array, but we don't use more than that here
    currentCycle: PropTypes.string,
    requestedCycles: PropTypes.string,
  }).isRequired,
};

export default HyperTestingTableDrawer;
