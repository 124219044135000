import _ from 'lodash';
import * as actionTypes from './actionTypes';
import * as accountActionTypes from '../account/actionTypes';
import * as orgActionTypes from '../organization/actionTypes';
import * as devicesActionTypes from '../devices/actionTypes';

const initialState: any = {
  tags: [],
  selectedTags: [],
  link_state_connected: [],
  isLoadingFilterValues: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function deviceFilterReducer(state = initialState, action) {
  switch (action.type) {
    case accountActionTypes.LOGOUT_REQUEST:
    case accountActionTypes.LOGIN_REQUEST:
      window.tagsCacheById = {};
      return { ...state, ...initialState };

    case orgActionTypes.CHANGE_CONTEXT:
      window.tagsCacheById = {};
      return { ...state, ...initialState };

    case actionTypes.GET_TAGS_SUCCESS:
      window.tagsCacheById = {};
      action.tags.forEach((tag) => {
        window.tagsCacheById[tag.id] = { name: tag.name };
      });

      return { ...state, tags: action.tags };

    case actionTypes.GET_FILTER_VALUES_REQUEST:
      return { ...state, isLoadingFilterValues: true };

    case actionTypes.GET_FILTER_VALUES_ERROR:
      return { ...state, isLoadingFilterValues: false };

    case actionTypes.GET_FILTER_VALUES_SUCCESS:
      return { ...state, ...action.filters, isLoadingFilterValues: false };

    case actionTypes.TAGS_SELECT_ALL:
      return { ...state, selectedTags: state.tags.map((tag) => tag.id) };

    case actionTypes.TAGS_DESELECT_ALL:
      return { ...state, selectedTags: [] };

    case devicesActionTypes.TOGGLE_TAG_SELECTION_FOR_DEVICES_SUCCESS: {
      const { updatedTag } = action;
      const { id } = updatedTag;
      return {
        ...state,
        tags: state.tags.map((tag) => (tag.id === id ? updatedTag : tag)),
      };
    }

    case actionTypes.TAGNAME_CHANGE_SUCCESS: {
      const { updatedTag } = action;
      return {
        ...state,
        tags: state.tags.map((tag) => (tag.id === updatedTag.id ? updatedTag : tag)),
      };
    }

    case actionTypes.FILTER_TOGGLE_TAG: {
      const selectedTags = structuredClone(state.selectedTags);

      const indexOfTagToToggle = selectedTags.indexOf(action.tagToToggle.id);

      if (indexOfTagToToggle !== -1) {
        selectedTags.splice(indexOfTagToToggle, 1);
      } else {
        selectedTags.push(action.tagToToggle.id);
      }

      return { ...state, selectedTags };
    }

    case actionTypes.FILTER_TOGGLE_ALL_DEVICES:
      return { ...state, isAllDevices: !state.isAllDevices, selectedTags: [] };

    case actionTypes.ADD_NEW_TAG_SUCCESS:
      return {
        ...state,
        tags: [action.newTag, ...state.tags],
      };

    case actionTypes.REMOVE_TAG_SUCCESS: {
      const tagsIndex = _.findIndex(state.tags, { id: action.tagId });
      const selectedTagsIndex = state.selectedTags.indexOf(action.tagId);

      if (tagsIndex !== -1) {
        state.tags.splice(tagsIndex, 1);
      }

      if (selectedTagsIndex !== -1) {
        state.selectedTags.splice(selectedTagsIndex, 1);
      }

      return {
        ...state,
        tags: state.tags.slice(0),
        selectedTags: state.selectedTags.slice(0),
      };
    }

    case actionTypes.PATCH_FILTERS: {
      const newFilters = _.pick(action.filters, 'selectedTags');

      return { ...state, ...newFilters };
    }

    default:
      return state;
  }
}

export default deviceFilterReducer;
