import React, { Component } from 'react';
import _classNames from 'clsx';
import moment from 'moment';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Panel } from '@holokit/core';
import { FullHeightCalculatorHOC, HoloHelmet, PageLoadingErrorScreen } from 'common-js/components';
import * as filterComparators from 'common-js/constants/filterComparators';
import { LAST_ACTIVE, USAGE } from 'common-js/constants/deviceFilterFields';
import {
  clearFilters,
  clearSelection,
  loadCategoryPage,
  removeFilter,
  resetQueryAndSorting,
  setFilter,
  setFilterInputValue,
  setMultipleFilters,
  toggleFilters,
  updateSort,
} from 'common-js/reducers/devices/actions';
import * as loadingTypes from 'common-js/reducers/devices/actionTypes';
import {
  getCurrentPage,
  getLoadedDevices,
  getDevicesPageLimit,
  getFilterCount,
  getSelectedDeviceState,
  getTotalPages,
} from 'common-js/reducers/devices/selectors';
import { getFilterValues } from 'common-js/reducers/deviceFilter/actions';
import { close } from 'common-js/reducers/modal/actions';
import { getAllBilling } from 'common-js/reducers/organization/actions';
import { selectBmpPredeploymentTestingFeatureLevel } from 'common-js/reducers/releaseFlag/selectors';
import DeviceTableContent from 'devices/components/DeviceTableContent';
import SubMenu from 'devices/components/SubMenu';
import {
  DeviceActions,
  DevicesSidebar,
  DevicesPagination,
  PageLimit,
  DevicesEmptyState,
} from 'devices/components';

class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialLoad: true,
      filtersToDefaultOpen: [],
    };
  }

  componentDidMount() {
    this.loadDevices();

    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentDidUpdate(prevProps) {
    const { stateSegmentName } = this.props;

    if (prevProps.stateSegmentName !== stateSegmentName) {
      this.loadDevices();
    }
  }

  componentWillUnmount() {
    const { clearFilters_, stateSegment } = this.props;

    clearFilters_(stateSegment);

    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup = () => {
    const { close_ } = this.props;

    close_();
  };

  setTimeFilters = (num, unit, filterInputKey, key) => {
    const { setMultipleFilters_, setFilterInputValue_, removeFilter_ } = this.props;
    setFilterInputValue_(`${filterInputKey}TimeNumber`, num);
    setFilterInputValue_(`${filterInputKey}TimeUnit`, unit);
    if (num > 0) {
      const now = moment();
      const selectedTime = moment().subtract(num, unit.toLowerCase());
      setMultipleFilters_(key, {
        [filterComparators.GREATER_THAN]: selectedTime.unix(),
        [filterComparators.LESS_THAN]: now.unix(),
      });
    } else {
      removeFilter_(key);
    }
  };

  loadDevices() {
    const {
      clearSelection_,
      getAllBilling_,
      getFilterValues_,
      loadCategoryPage_,
      setFilter_,
      setFilterInputValue_,
      stateSegment,
      router,
      toggleFilters_,
    } = this.props;
    const querystring = queryString.parse(window.location.search);
    const setFiltersOnLoad = router.location?.state?.setFiltersOnLoad;

    clearSelection_();
    getFilterValues_(['link_state_connected', 'plan_name']);
    getAllBilling_();
    loadCategoryPage_(stateSegment, querystring)
      .then(() => {
        if (setFiltersOnLoad) {
          const filtersToDefaultOpen = [];
          setFiltersOnLoad.forEach((filter) => {
            if (filter.key === LAST_ACTIVE) {
              this.setTimeFilters(filter.num, filter.unit, filter.filterInputKey, filter.key);
            } else if (filter.key === USAGE) {
              setFilterInputValue_('usageAmount', filter.value);
              setFilter_(USAGE, filter.value, filter.comparator);
            } else {
              toggleFilters_(filter.key, filter.values);
            }
            filtersToDefaultOpen.push(filter.key);
          });
          this.setState({ filtersToDefaultOpen });
        }
      })
      .finally(() => {
        this.setState({ isInitialLoad: false });
      });
  }

  render() {
    const {
      bmpPredeploymentFlag,
      deviceCount,
      deviceCountActive,
      deviceCountDeactivated,
      deviceCountPreflight,
      currentPage,
      devices,
      error,
      filterCount,
      isLoading,
      pageLimit,
      selectedDevices,
      sortColumn,
      sortDirection,
      stateSegment,
      stateSegmentName,
      updateSort_,
    } = this.props;
    const { filtersToDefaultOpen, isInitialLoad } = this.state;
    const segmentNameCapitalized =
      stateSegmentName.charAt(0).toUpperCase() + stateSegmentName.slice(1);
    const hasBulkSelect =
      filterCount ||
      Object.keys(selectedDevices.byId).length > 0 ||
      selectedDevices.allSelected ||
      selectedDevices.pagesSelected.length > 0;

    const isPreflight = stateSegmentName === 'preflight';
    const showPredeploymentUpsellContent = isPreflight && bmpPredeploymentFlag === 'lite';

    let tableContent;
    if (deviceCountActive === 0 && stateSegmentName === 'active') {
      tableContent = <DevicesEmptyState deviceType={stateSegmentName} />;
    } else if (currentPage > pageLimit) {
      tableContent = <PageLimit />;
    } else {
      tableContent = (
        <DeviceTableContent
          devices={devices}
          deviceCount={deviceCount}
          isLoading={isLoading}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          stateSegment={stateSegment}
          updateSort={updateSort_}
          isPreflight={isPreflight}
          showPredeploymentUpsellContent={showPredeploymentUpsellContent}
        />
      );
    }

    return (
      <>
        <HoloHelmet title={`${segmentNameCapitalized} Devices`} />
        <SubMenu />
        {error ? (
          <PageLoadingErrorScreen error={error} />
        ) : (
          <FullHeightCalculatorHOC backgroundColor="#F2F3F5" accountForDrawer relative>
            <div className="container">
              <DevicesSidebar
                deviceCountActive={deviceCountActive}
                deviceCountDeactivated={deviceCountDeactivated}
                deviceCountPreflight={deviceCountPreflight}
                filtersToDefaultOpen={filtersToDefaultOpen}
                isInitialLoad={isInitialLoad}
                setTimeFilters={this.setTimeFilters}
                stateSegment={stateSegment}
                stateSegmentName={stateSegmentName}
              />
              <div className="DevicesTable">
                <Panel
                  bodyClasses={_classNames('DevicesTable__panel-body', {
                    'DevicesTable__panel-body--has-bulk-select': hasBulkSelect,
                  })}
                  classes="DevicesTable__panel"
                  headerCenterContent={
                    <DeviceActions
                      deviceType={stateSegmentName}
                      isLoading={isLoading}
                      hasBulkSelect={hasBulkSelect}
                    />
                  }
                  isLoading={isLoading}
                  noBodyPadding
                  noTitleLeftPadding
                  noTitleRightPadding
                >
                  {!isLoading && tableContent}
                  {!showPredeploymentUpsellContent && devices.length >= 1 && <DevicesPagination />}
                </Panel>
              </div>
            </div>
          </FullHeightCalculatorHOC>
        )}
      </>
    );
  }
}

Devices.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  devices: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  bmpPredeploymentFlag: selectBmpPredeploymentTestingFeatureLevel(state),
  currentPage: getCurrentPage(state),
  devices: getLoadedDevices(state),
  error: state.devices.uiState.error,
  filterCount: getFilterCount(state),
  isLoading: state.devices.uiState.loading[loadingTypes.FETCH_DEVICES],
  orgs: state.organization.orgs,
  pageLimit: getDevicesPageLimit(state),
  pageTotal: getTotalPages(state),
  selectedDevices: getSelectedDeviceState(state),
  sortColumn: state.devices.sort.column,
  sortDirection: state.devices.sort.direction,
  userId: state.account.userId,
});

const mapDispatchToProps = {
  clearFilters_: clearFilters,
  clearSelection_: clearSelection,
  close_: close,
  getAllBilling_: getAllBilling,
  getFilterValues_: getFilterValues,
  loadCategoryPage_: loadCategoryPage,
  removeFilter_: removeFilter,
  resetQueryAndSorting_: resetQueryAndSorting,
  setFilter_: setFilter,
  setFilterInputValue_: setFilterInputValue,
  setMultipleFilters_: setMultipleFilters,
  toggleFilters_: toggleFilters,
  updateSort_: updateSort,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Devices));
