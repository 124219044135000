import { LogFilterModel } from 'common-js/models';
import { LOGIN_REQUEST, LOGOUT_REQUEST } from 'common-js/reducers/account/actionTypes';
import {
  DEVICE_RESET,
  DEVICE_SET_DATERANGE,
  DEVICE_SET_QUERY,
  DEVICE_SET_VIEW_ALL,
  SET_DEVICE_FILTER,
  SET_LOG_CATEGORY,
} from './actionTypes';

const initialState = {
  allLogFilters: new LogFilterModel(),
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function logFilterReducer(state = initialState, action) {
  let deviceLogFilters = state.allLogFilters;

  switch (action.type) {
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
      return { ...state, ...initialState };

    case SET_DEVICE_FILTER:
      deviceLogFilters.deviceFilter = action.deviceId;

      return { ...state };

    case SET_LOG_CATEGORY:
      deviceLogFilters = state.allLogFilters;
      deviceLogFilters.logCategory = action.logCategory;

      return { ...state };

    case DEVICE_SET_DATERANGE:
      deviceLogFilters = state.allLogFilters;

      deviceLogFilters.startDate = action.startDate;
      deviceLogFilters.endDate = action.endDate;
      deviceLogFilters.isViewingAllLogs = false;

      return { ...state };

    case DEVICE_SET_VIEW_ALL:
      deviceLogFilters = state.allLogFilters;
      deviceLogFilters.isViewingAllLogs = action.isViewingAllLogs;

      return { ...state };

    case DEVICE_RESET:
      deviceLogFilters = state.allLogFilters;

      deviceLogFilters.isViewingAllLogs = true;
      deviceLogFilters.startDate = null;
      deviceLogFilters.endDate = null;

      return { ...state };

    case DEVICE_SET_QUERY:
      deviceLogFilters = state.allLogFilters;

      deviceLogFilters.searchQuery = action.searchQuery;

      return { ...state };

    default:
      return state;
  }
}

export default logFilterReducer;
