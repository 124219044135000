import { DEVICES_DEVICES } from 'common-js/constants/paths';
import { LOGIN_REQUEST, LOGOUT_REQUEST } from 'common-js/reducers/account/actionTypes';
import {
  CHANGE_FULLSCREEN,
  CHANGE_MINIMIZED,
  CHANGE_TAB,
  HASH_CHANGE,
  SET_LOCKED,
  SET_TAKEOVER,
  VIEW_FILTERS,
} from './actionTypes';

const initialState = {
  tab: 'all',
  isMinimized: true,
  isFullscreen: false,
  viewFilters: false,
  viewSearch: false,
  isLocked: false,
  drawerTakeover: false,
  returnLink: DEVICES_DEVICES,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function drawerReducer(state: any = initialState, action) {
  switch (action.type) {
    case CHANGE_TAB:
      return { ...state, tab: action.tab };

    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
      return { ...state, ...initialState };

    case VIEW_FILTERS:
      return { ...state, viewFilters: action.viewFilters };
    case CHANGE_MINIMIZED:
      return { ...state, isMinimized: action.isMinimized };
    case CHANGE_FULLSCREEN:
      return { ...state, isFullscreen: action.isFullscreen };
    case HASH_CHANGE:
      return {
        ...state,
        isFullscreen: action.hash.drawer === 'full',
        isMinimized: action.hash.drawer !== 'full' && action.hash.drawer !== 'revealed',
      };
    case SET_LOCKED:
      return { ...state, isLocked: action.isLocked };
    case SET_TAKEOVER:
      return {
        ...state,
        drawerTakeover: action.drawerTakeover,
        isFullscreen: action.drawerTakeover,
        returnLink: action.returnLink,
      };
    default:
      return state;
  }
}

export default drawerReducer;
