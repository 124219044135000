import ClassNames from 'clsx';
import React from 'react';
import { Link } from 'react-router';

function Item({ active, className, disabled, href, text, isDeviceName, children }) {
  const classes = ClassNames('TopMenu__item', className, { active, disabled });

  if (isDeviceName) {
    return (
      <div
        className={ClassNames('TopMenu__links__highlight', {
          active,
        })}
      >
        <div className={classes}>{text}</div>
      </div>
    );
  }

  return href ? (
    <div
      className={ClassNames('TopMenu__links__highlight', {
        active,
      })}
    >
      <Link to={href || ''} className={classes}>
        {children}
        {text}
      </Link>
    </div>
  ) : (
    <div className={classes}>{text}</div>
  );
}

Item.defaultProps = {
  active: false,
  text: '',
  isDeviceName: false,
};

class TopMenu extends React.Component {
  static Item = Item;

  render() {
    const { classModifier, rightContent, children } = this.props;

    return (
      <div
        className={ClassNames('TopMenu', {
          [`TopMenu--${classModifier}`]: classModifier,
        })}
      >
        <div className="TopMenu__content">
          <div className="TopMenu__content_wrapper">
            <div className="TopMenu__links">{children}</div>
          </div>
          <div className="TopMenu__right_content">{rightContent}</div>
        </div>
      </div>
    );
  }
}

export default TopMenu;

TopMenu.defaultProps = {
  classModifier: null,
};
