import { Icon } from '@hologram-dimension/icon';
import ColorTokens from '@hologram-dimension/tokens/colors';
import { DeviceUsageData } from 'common-js/types/Device';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import * as d3 from 'd3';
import Moment from 'moment-timezone';
import React, { RefObject, createRef } from 'react';

interface NetworkConnectionsChartD3Props {
  data: Array<DeviceUsageData>;
}

interface NetworkConnectionsChartD3State {
  isEmpty: boolean;
  hasReportingWindow: boolean;
}

interface ConvertedData {
  value: number;
  startTime: Moment.Moment;
  endTime: Moment.Moment;
  network: string;
  tech: string;
  isWindow: boolean;
}

class NetworkConnectionsChartD3 extends React.Component<
  NetworkConnectionsChartD3Props,
  NetworkConnectionsChartD3State
> {
  chartContainerRef: RefObject<HTMLDivElement>;

  xAxisLabelsRef: RefObject<HTMLDivElement>;

  constructor(props: NetworkConnectionsChartD3Props) {
    super(props);
    this.state = {
      isEmpty: false,
      hasReportingWindow: false,
    };

    this.chartContainerRef = createRef();
    this.xAxisLabelsRef = createRef();
  }

  componentDidMount() {
    this.buildChart();
  }

  buildChart() {
    const getChartContainer = d3.select('.NetworkConnectionsChart').node();
    const { data } = this.props;
    const timeZone = Moment.tz.guess();
    const last48 = Moment().subtract(2, 'days');
    const fmt = 'YYYY-MM-DD HH:mm:ss';

    const convertedData: Array<ConvertedData> = data
      .filter((d) => {
        const startTime = Moment.utc(d.session_begin, fmt).tz(timeZone);
        return startTime >= last48;
      })
      .map((d) => ({
        value: d.bytes,
        startTime: Moment.utc(d.session_begin, fmt).tz(timeZone),
        endTime: Moment.utc(d.timestamp, fmt).tz(timeZone),
        network: d.network_name,
        tech: d.radio_access_technology,
        isWindow: d.is_reporting_window_with_usage === 1,
      }));

    if (convertedData.length > 0 && this.chartContainerRef.current) {
      const dataArea = d3.select(this.chartContainerRef.current);

      // Start chart building.
      // var values = data.map((d) => d.value);
      // var startTimes = data.map((d) => d.startTime);
      // var endTimes = data.map((d) => d.endTime);
      const xAxisValues = [
        Moment().tz(timeZone),
        Moment().subtract(8, 'hours').tz(timeZone),
        Moment().subtract(16, 'hours').tz(timeZone),
        Moment().subtract(24, 'hours').tz(timeZone),
        Moment().subtract(32, 'hours').tz(timeZone),
        Moment().subtract(40, 'hours').tz(timeZone),
        Moment().subtract(48, 'hours').tz(timeZone),
      ];

      const svg = d3
        .select('svg.NetworkConnectionsChart')
        .attr('width', (getChartContainer as any).getBoundingClientRect().width - 50);
      const margin = { top: 20, right: 0, bottom: 25, left: 60 };
      const width = +svg.attr('width') - margin.left - margin.right;
      const height = +svg.attr('height') - margin.top - margin.bottom;

      const x = d3.scaleTime().range([0, width]);

      x.domain([
        d3.min(xAxisValues, (d) => Moment(d)),
        d3.max(xAxisValues, (d) => Moment(d)),
      ] as any);

      const currentTZ = Moment.tz(timeZone).zoneAbbr();
      const xAxis = d3
        .axisBottom(x)
        .tickSize(-height)
        .tickFormat(d3.timeFormat(`%-I%p ${currentTZ}`) as any);

      const tooltip = dataArea.append('div').attr('class', 'tooltip').style('opacity', 0);

      const TOOLTIP_DATE_FORMAT = 'MMM D, YYYY h:mm:ss A zz';
      const DAY_TOOLTIP_FORMAT = 'MMM DD, YYYY';

      // eslint-disable-next-line no-inner-declarations
      function customXAxis(g) {
        g.call(xAxis);
        g.select('.domain').remove();
        g.selectAll('.tick line').attr('stroke', '#E0E7FF');
      }

      svg
        .append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', `translate(0,${height + 20})`)
        .call(customXAxis);

      const focus = svg
        .append('g')
        .attr('class', 'focus')
        .attr('transform', `translate(${0},${margin.top})`);

      focus
        .selectAll('line')
        .data(convertedData)
        .enter()
        .append('g')
        .attr('class', 'bar-container')
        .attr('stroke', 'transparent')
        .attr('cursor', 'pointer')
        // eslint-disable-next-line prefer-arrow-callback
        .on('mouseover', function onSVGMouseOver(_, d) {
          // Give time for the previous tooltip to process a close, then open a new one here.
          // const tooltip = d3.select(this.chartContainerRef.current).select('.tooltip');
          setTimeout(() => {
            tooltip.transition().duration(100).style('opacity', 1);
          }, 4);

          const hoveredBar = d3.select(this).node()?.querySelector<SVGLineElement>('line.svg-line');

          if (hoveredBar) {
            const parentRect = hoveredBar.viewportElement?.getBoundingClientRect()!;
            const rect = hoveredBar.getBoundingClientRect();

            if (parentRect && rect) {
              tooltip
                .html(
                  `<div><div class="tooltip__headline">${toByteStringFormatted(
                    d.value
                  )} session</div><div class="tooltip__date"><div>${d.startTime.format(
                    TOOLTIP_DATE_FORMAT
                  )} —</div><div>${d.endTime.format(
                    TOOLTIP_DATE_FORMAT
                  )}</div></div><div class="tooltip__network"><div>${d.network}</div><div>${
                    d.tech
                  }</div></div></div>`
                )
                .style('left', `${Math.round(rect.left - parentRect.left + rect.width / 2 + 20)}px`)
                .style('top', `${Math.round(rect.top - parentRect.top + 10)}px`)
                .style('display', null)
                .on('mouseover', () => {
                  tooltip.node()?.setAttribute('locked', 'true');
                })
                .on('mouseout', () => {
                  tooltip.node()?.removeAttribute('locked');
                });
            }
          }
        })
        .on('mouseout', () => {
          setTimeout(async () => {
            const tooltipLocked = tooltip.node()?.getAttribute('locked') === 'true';
            if (!tooltipLocked) {
              try {
                await tooltip.transition().duration(200).style('opacity', 0).end();
                tooltip.style('display', 'none');
              } catch {
                /* empty */
              }
            }
          }, 2);
        })
        .append('line')
        .attr('class', 'svg-line')
        .attr('x1', (d: ConvertedData) => x(d.startTime))
        .attr('y1', (d: ConvertedData, i) => (i % 2 === 0 ? '30' : '45'))
        .attr('x2', (d: ConvertedData) => x(d.endTime))
        .attr('y2', (d: ConvertedData, i) => (i % 2 === 0 ? '30' : '45'))
        .style('stroke', (d: ConvertedData) => {
          if (d.isWindow) {
            this.setState({ hasReportingWindow: true });
            return ColorTokens.DdsColorPaletteBlue30;
          }
          return d.value > 0
            ? ColorTokens.DdsColorPaletteBlue59
            : ColorTokens.DdsColorPalettePink55;
        })
        .style('stroke-width', '10')
        .style('stroke-linecap', 'round')
        .style('opacity', 1);

      // X-Axis Labels
      d3.select(this.xAxisLabelsRef.current)
        .append('div')
        .attr('class', 'x-label')
        .append('div')
        .attr('class', 'x-label-text')
        .text(last48.format(DAY_TOOLTIP_FORMAT));

      d3.select(this.xAxisLabelsRef.current)
        .append('div')
        .attr('class', 'x-label')
        .append('div')
        .attr('class', 'x-label-text')
        .text('Today');
      this.setState({ isEmpty: false });
    } else {
      this.setState({ isEmpty: true });
    }
  }

  render() {
    const { isEmpty, hasReportingWindow } = this.state;
    return (
      <div
        className={`NetworkConnectionsChartD3${isEmpty ? '' : ' padding'}`}
        ref={this.chartContainerRef}
      >
        {isEmpty ? (
          <div className="NetworkConnectionsChart-nodata">
            <Icon name="CircleInfo" fill="DdsColorInteractivePrimaryDefault" size="large" />
            <div className="NetworkConnectionsChart-nodata-message">
              There are no network connection metrics to display for this device over the last 48
              hours.
            </div>
          </div>
        ) : (
          <div>
            <div className="legend">
              <div className="legend-data legend-data-zero">Zero-byte session</div>
              <div className="legend-data legend-data-productive">Productive session</div>
              {hasReportingWindow && (
                <div className="legend-data legend-data-window">Reporting window</div>
              )}
            </div>
            <svg className="NetworkConnectionsChart" width="936" height="120" />
            <div className="bc-xscale" ref={this.xAxisLabelsRef} />
          </div>
        )}
      </div>
    );
  }
}

export default NetworkConnectionsChartD3;
