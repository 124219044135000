import analyticsEventBuilder from 'common-js/analytics';
import { HoloHelmet } from 'common-js/components';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { getPlans } from 'common-js/reducers/account/actions';
import { clearValidationErrorState } from 'common-js/reducers/activation/actions/actions';
import { selectCustomPlans, selectIsPreflight } from 'common-js/reducers/activation/selectors';
import { getAllBilling, getBalance, getOrgGroups } from 'common-js/reducers/organization/actions';
import {
  getDeviceCount,
  getPostPay,
  getUserPermissions,
  getBalance as selectOrgBalance,
} from 'common-js/reducers/organization/selectors';
import {
  selectHasDataUsageLimits,
  selectHasSimsPages,
  selectPromoteSimsPages,
} from 'common-js/reducers/releaseFlag/selectors';
import { BILLING } from 'common-js/utils/permissions';
import { ReactNode, useEffect, useMemo, useState, type FC } from 'react';
import ActivationForm from './ActivationForm';
import LoadingScreen from './LoadingScreen';
import ConfirmationScreen from './confirmation/ConfirmationScreen';
import GetStarted from './getstarted/GetStarted';

interface ActivationLandingPageProps {
  loadingScreenTimeout?: number;
}
const ActivationLandingPage: FC<ActivationLandingPageProps> = ({ loadingScreenTimeout = 3000 }) => {
  // state
  const [isLoading, setLoading] = useState(true);
  const orgDeviceCount = useAppSelector(getDeviceCount) ?? 0;
  const hasPostpay = useAppSelector(getPostPay);
  const balance = useAppSelector(selectOrgBalance);
  const customPlans = useAppSelector(selectCustomPlans);
  const userPermissions = useAppSelector(getUserPermissions);
  const isPreflight = useAppSelector(selectIsPreflight);
  const hasDataUsageLimits = useAppSelector(selectHasDataUsageLimits);
  const hasBillingPermissions = userPermissions.includes(BILLING);
  const hasSimInventoryPage = useAppSelector(selectHasSimsPages);
  const promoteSimInventoryPage = useAppSelector(selectPromoteSimsPages);
  const [activationSuccess, setActivationSuccess] = useState<boolean | null>(null);
  const [activationError, setActivationError] = useState('');
  const [activatedPlan, setActivatedPlan] = useState(null);

  // Change plan SIMs
  const changingPlan: boolean = useAppSelector((state) => state.activation?.flags?.changingPlan);

  // next step functionality for get started page
  const [nextStep, setNextStep] = useState(false);

  // load anything we need before activation
  const dispatch = useAppDispatch();
  useEffect(() => {
    const effect = async () => {
      const isDevEnv = process.env.NODE_ENV === 'development';
      const start = isDevEnv ? window.performance.now() : 0;
      await Promise.all([
        dispatch(getPlans()),
        dispatch(getBalance()),
        dispatch(getAllBilling()),
        dispatch(getOrgGroups()),
        isDevEnv || loadingScreenTimeout === 0
          ? null
          : new Promise((res) => {
              setTimeout(res, loadingScreenTimeout);
            }),
      ]);

      if (isDevEnv) {
        const elapsed = window.performance.now() - start;
        // eslint-disable-next-line no-console
        console.info(`Activation form loading completed in ${(elapsed / 1000).toFixed(3)}s`);
      }
      setLoading(false);
    };
    effect();
  }, [setLoading, dispatch, loadingScreenTimeout]);

  // On mount and unmount, clear out validation errors
  useEffect(() => {
    dispatch(clearValidationErrorState());

    return () => {
      dispatch(clearValidationErrorState());
    };
  }, [dispatch]);

  const autoRefillSettings = useMemo(
    () => ({
      isAutoRefillEnabled: !!balance?.minBalance && !!balance?.topOffAmount,
      minBalance: balance?.minBalance,
      topOffAmount: balance?.topOffAmount,
    }),
    [balance]
  );

  const { isOnboarding, hasCustomPlans } = useMemo(
    () => ({
      isOnboarding: orgDeviceCount === 0,
      hasCustomPlans: customPlans.length > 0 || false,
    }),
    [customPlans, orgDeviceCount]
  );

  const onboardingText = isOnboarding ? '1st Time' : 'Returning';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';

  useEffect(() => {
    if (!isLoading || activationSuccess === true || activationSuccess === false) {
      analyticsEventBuilder
        .pageView()
        .page(
          analyticsTitle,
          changingPlan ? undefined : onboardingText,
          `${analyticsTitle} Landing Page`
        )
        .send();
    }
  }, [isOnboarding, isLoading, activationSuccess, changingPlan, analyticsTitle, onboardingText]);

  let content: ReactNode;

  if (isLoading)
    content = (
      <LoadingScreen
        content={
          changingPlan
            ? "We're setting up the change plan process..."
            : "We're setting up the activation process..."
        }
        changingPlan={changingPlan}
      />
    );
  else if (isOnboarding && !nextStep) {
    content = <GetStarted setNextStep={() => setNextStep(true)} />;
  } else if (activationSuccess !== null) {
    content = (
      <ConfirmationScreen
        isOnboarding={isOnboarding}
        success={activationSuccess}
        error={activationError}
        changingPlan={changingPlan}
        activatedPlan={activatedPlan}
      />
    );
  } else {
    content = (
      <ActivationForm
        autoRefillSettings={autoRefillSettings}
        hasCustomPlans={hasCustomPlans}
        hasBillingPermissions={hasBillingPermissions}
        hasDataUsageLimits={hasDataUsageLimits}
        hasPostpay={hasPostpay}
        isOnboarding={isOnboarding}
        isPreflight={isPreflight}
        hasSimInventoryPage={hasSimInventoryPage}
        promoteSimInventoryPage={promoteSimInventoryPage}
        setActivationError={setActivationError}
        setActivationSuccess={setActivationSuccess}
        changingPlan={changingPlan}
        setActivatedPlan={setActivatedPlan}
      />
    );
  }

  return (
    <>
      <HoloHelmet title={changingPlan ? 'Change Data Plan' : 'Activation'} />
      {content}
    </>
  );
};

export default ActivationLandingPage;
