import _ from 'lodash';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@holokit/core';
import { DropdownMenu, DateTimePicker } from '.';
import { DateRange } from './dateRange';

const RADIO_RECENT_LOGS = '0';
const RADIO_FILTERDATE_LOGS = '1';

class LogDateRangeDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startTime: Moment().valueOf(),
      endTime: Moment().valueOf(),
      isViewingAllLogs: true,
    };
  }

  componentDidMount() {
    this.resetFromProps();
  }

  onDateChange(dates) {
    const { startTime, endTime } = this.state;

    const startOfDay = dates.startDate.clone().startOf('day');

    if (startTime !== startOfDay.valueOf() || endTime !== dates.endDate.valueOf()) {
      this.setState({
        startTime: startOfDay.valueOf(),
        endTime: dates.endDate.valueOf(),
      });
    }
  }

  onApply() {
    if (this.state.isViewingAllLogs) {
      this.props.onAllLogsSelected();
    } else {
      this.props.onDateRangeSelected();
      this.props.onDateRangeChange(this.state.startTime, this.state.endTime);
    }
  }

  onRadioChange(e) {
    if (e.currentTarget.value === RADIO_RECENT_LOGS) {
      this.setState({ isViewingAllLogs: true });
    } else {
      this.setState({
        isViewingAllLogs: false,
        startTime: this.state.startTime ? this.state.startTime : Moment().valueOf(),
        endTime: this.state.endTime ? this.state.endTime : Moment().valueOf(),
      });
    }
  }

  onRemoveNewFilters() {
    this.resetFromProps();
  }

  resetFromProps() {
    this.setState({
      startTime: this.props.startDate,
      endTime: this.props.endDate,
      isViewingAllLogs: this.props.isViewingAllLogs,
    });
  }

  onContainerClick(e) {
    if (e.nativeEvent.target !== this.refs.applyButton) {
      e.stopPropagation();
    }
  }

  getSelectedDateLabel() {
    const { isViewingAllLogs, startDate, endDate } = this.props;

    if (isViewingAllLogs) {
      return 'Most recent logs';
    }
    if (startDate === endDate) {
      return Moment(startDate).format('MMM Do, YYYY HH:mm:ss');
    }
    return `${Moment(startDate).format('MMM Do, YYYY HH:mm:ss')} - ${Moment(endDate).format(
      'MMM Do, YYYY HH:mm:ss'
    )}`;
  }

  render() {
    const { direction } = this.props;
    const { startTime, endTime, isViewingAllLogs } = this.state;

    const startDateMoment = startTime ? Moment(startTime) : Moment();
    const endDateMoment = endTime ? Moment(endTime) : Moment();

    return (
      <div className="DateRangeDropdown LogDateRange">
        <DropdownMenu
          buttonLabel={this.getSelectedDateLabel()}
          iconName="calendar"
          direction={direction}
          onOpen={() => this.resetFromProps()}
          buttonClasses="btn btn-medium btn-dropdown"
          classNames="border-connected"
          leftOffset={30}
        >
          <div className="grid-row" onClick={(e) => this.onContainerClick(e)}>
            <div className="filtertype-container">
              <label>
                <input
                  type="radio"
                  value={RADIO_RECENT_LOGS}
                  name="dateRangeRadio"
                  checked={isViewingAllLogs}
                  onChange={this.onRadioChange.bind(this)}
                />
                Most recent
              </label>
              <label>
                <input
                  type="radio"
                  value={RADIO_FILTERDATE_LOGS}
                  name="dateRangeRadio"
                  checked={!isViewingAllLogs}
                  onChange={this.onRadioChange.bind(this)}
                />
                Custom date
              </label>
            </div>
            <div className={isViewingAllLogs ? 'disabled' : ''}>
              <div className="section section-sm">
                <DateRange
                  startDate={startDateMoment}
                  endDate={endDateMoment}
                  onChange={this.onDateChange.bind(this)}
                  theme={calendarStyles}
                  maxDate={Moment()}
                  linkedCalendars
                />
              </div>
              <div className="grid-row section section-sm">
                <div className="from-to-label">from:</div>
                <DateTimePicker
                  classNames="grid-item"
                  time={startDateMoment.valueOf()}
                  onChange={(startTime) => this.setState({ startTime })}
                  validWhen={(startTime) => ({
                    isValid: startTime < endTime,
                    error: 'Date must be before the from date.',
                  })}
                />
                <div className="from-to-label">to:</div>
                <DateTimePicker
                  classNames="grid-item"
                  time={endDateMoment.valueOf()}
                  onChange={(endTime) => this.setState({ endTime })}
                  validWhen={(endTime) => ({
                    isValid: startTime < endTime,
                    error: 'Date must be after the to date.',
                  })}
                />
              </div>
            </div>
          </div>
          <div className="grid-row" onClick={(e) => this.onContainerClick(e)}>
            <div className="btn-container">
              <Button
                disabled={!this.isDirty()}
                ref="applyButton"
                onClick={() => this.onApply()}
                type="secondary"
              >
                Apply
              </Button>
              <Button
                disabled={!this.isDirty()}
                onClick={() => {
                  this.onRemoveNewFilters();
                }}
                type="secondary"
              >
                cancel
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </div>
    );
  }

  isDirty() {
    return (
      this.props.startDate !== this.state.startTime ||
      this.props.endDate !== this.state.endTime ||
      this.props.isViewingAllLogs !== this.state.isViewingAllLogs
    );
  }
}

const calendarStyles = {
  DayInRange: {
    background: '#a0deff',
    color: '#fff',
  },
  DaySelected: {
    background: '#04A7FF',
  },
  Day: {
    height: '25px',
    lineHeight: '25px',
  },
};

LogDateRangeDropdown.propTypes = {
  direction: PropTypes.string,
  onDateRangeChange: PropTypes.func,
};

LogDateRangeDropdown.defaultProps = {
  onDateRangeChange: _.noop,
  direction: 'down',
};

export default LogDateRangeDropdown;
