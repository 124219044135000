import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';

const useGetDeviceEUICCHistory = () => {
  const apiCall = useApi.call('/devices/:deviceId/euicc/history');
  return useCallback(
    async (deviceId) => {
      const { data } = await apiCall({ urlParams: { deviceId } });
      return data;
    },
    [apiCall]
  );
};

export default useGetDeviceEUICCHistory;
