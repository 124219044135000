import { createSelector } from '@reduxjs/toolkit';

export const selectDrawer = createSelector(
  (state: any) => state.drawer,
  (drawer) => drawer
);

export const selectDrawerIsMinimized = createSelector(selectDrawer, (drawer) => drawer.isMinimized);
export const selectDrawerIsFullscreen = createSelector(
  selectDrawer,
  (drawer) => drawer.isFullscreen
);

export const selectDrawerTakeover = createSelector(selectDrawer, (drawer) => drawer.drawerTakeover);
export const selectIsLocked = createSelector(selectDrawer, (drawer) => drawer.isLocked);
export const selectViewFilters = createSelector(selectDrawer, (drawer) => drawer.viewFilters);

export const selectDrawerIsOpen = createSelector(
  [selectDrawerIsMinimized, selectDrawerIsFullscreen],
  (isMinimized, isFullscreen) => !isMinimized && !isFullscreen
);
