import { useEffect } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { getCompletedTasks, setCurrentTasksPage } from 'common-js/reducers/devices/actions';
import analyticsEventBuilder from 'common-js/analytics';
import queryString from 'query-string';

// utility to handle getting the first page URL param, defaults to page 1 if no param doesn't exist
const getPageParameter = () => {
  const { page } = queryString.parse(window.location.search);
  if (Array.isArray(page)) {
    return page.length === 0 ? 1 : parseInt(page[0], 10);
  }
  return page ? parseInt(page, 10) : 1;
};

// sets redux state to match the page parameter in the URL if page isn't 1
const loadPage = (dispatch): Promise<void> => {
  const currentPage = getPageParameter();
  return currentPage === 1 ? Promise.resolve() : dispatch(setCurrentTasksPage(currentPage));
};

// loads the activity into redux when mounted
const useLoadActivity = (simInventory = false) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    loadPage(dispatch).then(() => dispatch(getCompletedTasks()));
    analyticsEventBuilder
      .pageView()
      .page(simInventory ? 'Sims' : 'Devices', 'Activity History')
      .send();
  }, [dispatch, simInventory]);
};

export default useLoadActivity;
