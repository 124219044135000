import pick from 'lodash/pick';
import { fetchSlimDevices } from './actions';
import { ENSURE_SLIM_DEVICES_LOADED, PERSIST_SEARCH_RESULT } from '../actionTypes';

const ensureDevicesPersisted = (deviceIds: Array<DeviceId>) => async (dispatch, getState) => {
  dispatch({ type: ENSURE_SLIM_DEVICES_LOADED });

  const { devices } = getState();

  // remove ids that we've already saved
  const sansPersistedIds = deviceIds.filter((id) => !devices.devicesCache[id]);

  const loadedSearchResults = devices.loaded.reduce((acc, d) => ({ ...acc, [d.id]: d }), {});

  // first try and find devices that are loaded as a search match
  const toPersist = pick(loadedSearchResults, sansPersistedIds);
  const toPersistArray = Object.values(toPersist);

  // and then get a list to load from the api
  const unloadedSims = sansPersistedIds.reduce<Array<DeviceId>>((acc, deviceId) => {
    if (toPersist[deviceId]) {
      return acc;
    }
    return [...acc, deviceId];
  }, []);

  // and then save them
  if (unloadedSims.length !== 0) {
    await dispatch(fetchSlimDevices(unloadedSims));
  }
  if (toPersistArray.length !== 0) {
    dispatch({
      type: PERSIST_SEARCH_RESULT,
      devices: toPersistArray,
    });
  }
};

export default ensureDevicesPersisted;
