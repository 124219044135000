/* eslint-disable no-empty */
import { NONE } from '../constants/logAuthTypes';

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
}
export default class LogModel {
  rawData = '';

  parsedRawData = {};

  matchedRules = [];

  deviceid = 0;

  id = 0;

  logged = '';

  record_id = '';

  tags = [];

  base64 = '';

  data = '';

  type = 'string';

  authtype = NONE;

  constructor(log) {
    if (!log) return;

    this.rawData = log.data;
    this.deviceid = log.deviceid;
    this.id = log.id;
    this.logged = log.logged;
    this.matchedRules = log.matched_rules;
    this.record_id = log.record_id;
    this.tags = log.tags;

    try {
      this.parsedRawData = JSON.parse(log.data);
    } catch (e) {}
    try {
      this.data = b64DecodeUnicode(this.parsedRawData.data);
    } catch (e) {}
    try {
      this.base64 = this.parsedRawData.data;
    } catch (e) {}

    if (this.parsedRawData.received) {
      this.logged = this.parsedRawData.received;
    } else {
      this.logged = log.logged;
    }

    if (log.matched_rules) {
      this.matchedRules = log.matched_rules;
    }

    if (this.parsedRawData.authtype) {
      this.authtype = this.parsedRawData.authtype;
    }
  }
}
