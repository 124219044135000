import { Icon } from '@holokit/core';
import createReactClass from 'create-react-class';
import { Mixin } from 'formsy-react19';
import React from 'react';

// TODO: Changing this would require not using mixins (or finding a way to add them to the prototype)
// eslint-disable-next-line react/prefer-es6-class
const ListInput = createReactClass({
  mixins: [Mixin],

  onAddAuthHeader(e) {
    const { openModal } = this.props;
    e.stopPropagation();
    openModal('AuthHeaderModal', {
      onSubmit: (authHeaders) => this.onAddRows(authHeaders),
    });
  },

  onAddRows(newRows = []) {
    let rows = this.getValue();
    rows = rows.concat(newRows);
    this.setValue(rows);
  },

  onRemoveRow(idx) {
    const rows = this.getValue();
    rows.splice(idx, 1);
    this.setValue(rows);
  },

  onChangeRow(idx, type, value) {
    const rows = this.getValue();

    if (rows[idx]) {
      rows[idx][type] = value;
    } else {
      const newRow = { key: '', value: '' };
      newRow[type] = value;
      rows.push(newRow);
    }

    this.setValue(rows);
  },

  render() {
    const { requiredMessage } = this.props;

    let className;
    if (this.showRequired()) {
      className = 'required';
    } else if (this.showError()) {
      className = 'error';
    } else {
      className = null;
    }
    const errorMessage = this.showRequired() ? requiredMessage : this.getErrorMessage();
    const { description, labelName, showErrors, property } = this.props;
    const rows = this.getValue();
    const isHeaders = property === 'headers';

    return (
      <div className={className}>
        {/* Adding a name to this would be a functional change */
        /* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label>
          <div className="label-text f5">{labelName}</div>
          {description && <div className="f6 section section-sm f-subdued">{description}</div>}
          <div className={`list-input${isHeaders ? ' section section-sm' : ''}`}>
            {rows.concat([{ key: '', value: '' }]).map((row, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={idx} className="grid-row v-align">
                <div className="grid-item input-item">
                  <input
                    type="text"
                    value={row.key}
                    onChange={(e) => this.onChangeRow(idx, 'key', e.currentTarget.value)}
                    placeholder={rows[idx] ? 'Key' : 'New key'}
                  />
                </div>
                <div className="grid-item input-item">
                  <input
                    type="text"
                    value={row.value}
                    onChange={(e) => this.onChangeRow(idx, 'value', e.currentTarget.value)}
                    placeholder="Value"
                  />
                </div>
                <div
                  className={`close-button${!rows[idx] ? ' invisible' : ''}`}
                  onClick={() => (rows[idx] ? this.onRemoveRow(idx) : null)}
                  onKeyUp={() => (rows[idx] ? this.onRemoveRow(idx) : null)}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="Close" size="minor" svgProps={{ style: { fill: '#d0cfcf' } }} />
                </div>
              </div>
            ))}
            {showErrors && errorMessage && <div className="inline-error">{errorMessage}</div>}
          </div>
          {isHeaders && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              onClick={(e) => this.onAddAuthHeader(e)}
              onKeyUp={(e) => this.onAddAuthHeader(e)}
              role="button"
              tabIndex={0}
            >
              + Add auth header
            </a>
          )}
        </label>
      </div>
    );
  },
});

export default ListInput;
