import { useCallback, useState } from 'react';

// Just like useState, but the setter function uses an onchange event instead of the direct value
const useControlledInput = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);
  const setter = useCallback((e) => setValue(e?.target?.value), [setValue]);
  return [value, setter];
};

export default useControlledInput;
