import { useEffect } from 'react';

const useDebounceCallback = <T extends Function>(callback: T, debounceTime = 1000) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      callback();
    }, debounceTime);

    return () => clearTimeout(timeout);
  }, [debounceTime, callback]);
};

export default useDebounceCallback;
