import React from 'react';

function markIt(input, query) {
  const escaped = encodeURIComponent(query);
  const regex = RegExp(escaped, 'gi');

  return {
    __html: input.replace(regex, '<mark>$&</mark>'),
  };
}

function filterSuggestions(query, suggestions, length) {
  const escapedQuery = query.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  const regex = new RegExp(`${escapedQuery}`, 'ig');
  return suggestions.filter((item) => regex.test(item.name)).slice(0, length);
}

class Suggestions extends React.Component {
  constructor(props) {
    super(props);

    const { query, suggestions, maxSuggestionsLength } = this.props;

    this.state = {
      options: filterSuggestions(query, suggestions, maxSuggestionsLength),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      options: filterSuggestions(
        newProps.query,
        newProps.suggestions,
        newProps.maxSuggestionsLength
      ),
    });
  }

  render() {
    const { addTag, classNames, expandable, listboxId, query, selectedIndex } = this.props;
    const { options } = this.state;

    if (!expandable || !options.length) {
      return null;
    }

    const optionsListItem = options.map((item, i) => {
      const key = `${listboxId}-${i}`;
      const classes = [];

      if (selectedIndex === i) {
        classes.push(classes.suggestionActive);
      }

      if (item.disabled) {
        classes.push(classes.suggestionDisabled);
      }

      return (
        <li
          id={key}
          key={key}
          role="option"
          className={classes.join(' ')}
          aria-disabled={item.disabled === true}
          aria-selected={false}
          onMouseDown={() => addTag(item)}
        >
          <span dangerouslySetInnerHTML={markIt(item.name, query)} />
        </li>
      );
    });

    return (
      <div className={classNames.suggestions}>
        <ul role="listbox" id={listboxId}>
          {optionsListItem}
        </ul>
      </div>
    );
  }
}

export default Suggestions;
