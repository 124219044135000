import Moment from 'moment-timezone';
import { stringify as convertToQueryString } from 'query-string';

export default class LogFilterModel {
  startDate = Moment().subtract(6, 'days').subtract(1, 'seconds').valueOf(); // in ms from epoch

  endDate = Moment().add(1, 'days').subtract(1, 'seconds').valueOf();

  searchQuery = '';

  logCategory = 'all'; // all, or error, or single device

  deviceFilter = null;

  isViewingAllLogs = true;

  devices = [];

  constructor(data) {
    if (!data) return;

    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.searchQuery = data.searchQuery;
    this.isViewingAllLogs = data.isViewingAllLogs;
    this.devices = data.devices;

    if (data.logCategory) this.logCategory = data.logCategory;
    if (data.deviceFilter) this.deviceFilter = data.deviceFilter;

    return this;
  }

  setDevices(devices = []) {
    this.devices = devices;
    return this;
  }

  toGetLogsQueryString() {
    const qObject = {};
    let queryString = '';

    if (!this.isViewingAllLogs) {
      if (this.startDate) {
        qObject.timestampstart = Math.trunc(this.startDate / 1000);
      }

      if (this.endDate && this.startDate === this.endDate) {
        qObject.timestampend = Math.trunc(Moment(this.startDate).valueOf() / 1000);
      } else if (this.endDate) {
        // start and end time will be equal if only one day is selected.
        qObject.timestampend = Math.trunc(Moment(this.endDate).valueOf() / 1000);
      }
    }

    if (this.searchQuery !== '') {
      qObject.tagname = this.searchQuery;
    }

    if (this.logCategory === 'error') {
      qObject.error = '1';
    }

    if (this.deviceFilter) {
      this.devices.push(this.deviceFilter);
    }

    queryString += convertToQueryString(qObject);

    if (this.devices && this.devices.length > 0) {
      this.devices.forEach((deviceId, idx) => {
        queryString += `&deviceids[]=${deviceId}`;

        if (idx !== this.devices.length - 1) {
          queryString += '&';
        }
      });
    }

    return queryString;
  }
}
