import { Button } from '@hologram-dimension/button';
import { ComplexIcon } from '@holokit/core';
import useOnButtonClickWithAnalytics from 'common-js/analytics/useOnButtonClickWithAnalytics';
import React from 'react';

const SearchModalNoResults: React.FC = () => {
  const handleClick = useOnButtonClickWithAnalytics({ pageName: 'Search' });

  return (
    <div className="SearchModal__no-results">
      <div className="SearchModal__no-results__icon">
        <ComplexIcon name="empty-search" />
      </div>
      <div className="SearchModal__no-results__caption">We didn&rsquo;t find a match</div>
      <div className="SearchModal__no-results__text">
        Try searching by the last 4 or 5 digits of an IMEI, by device name, device ID, or ICCID.
      </div>
      <Button
        className="SearchModal__no-results__link"
        variant="tertiary"
        onClick={() => {
          handleClick(
            'No results - Learn more search tips',
            'https://support.hologram.io/hc/en-us/articles/1500001779421',
            '_blank'
          );
        }}
      >
        Learn more search tips
      </Button>
    </div>
  );
};

export default SearchModalNoResults;
