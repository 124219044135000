import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EmptyCell from './EmptyCell';

function CellDeviceImei({ imei }) {
  return <div className="DevicesTable__cell">{imei ? _.escape(imei) : <EmptyCell />}</div>;
}

CellDeviceImei.propTypes = {
  imei: PropTypes.string.isRequired,
};

export default CellDeviceImei;
