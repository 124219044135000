import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Loader } from '@hologram-dimension/loader';
import { Panel } from '@hologram-dimension/panel';
import { Popover } from '@hologram-dimension/popover';
import { SelectField } from '@hologram-dimension/select-field';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { HoloHelmet, PageLoadingErrorScreen } from 'common-js/components';
import { ORGS_CREATE_STEP1, withContext } from 'common-js/constants/paths';
import ChangePassword from 'common-js/forms/ChangePasswordForm';
import UserInformation from 'common-js/forms/UserInformationForm';
import { useAppDispatch, useAppSelector } from 'common-js/hooks';
import {
  getPendingOrgs,
  getUserInfo,
  getUserSettings,
  updateUserSettings,
} from 'common-js/reducers/account/actions';
import { selectUserSettings } from 'common-js/reducers/account/selectors';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { getOrganizations, getOrgGroups } from 'common-js/reducers/organization/actions';
import { selectOrganization } from 'common-js/reducers/organization/selectors';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import MyOrganizations from './parts/MyOrganizations';

const SettingsAccount: FC = () => {
  const [pageError, setPageError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const loadRelevantData = useCallback(async () => {
    try {
      Promise.all([
        dispatch(getUserInfo()),
        dispatch(getOrgGroups()),
        dispatch(getOrganizations()),
        dispatch(getUserSettings()),
        dispatch(getPendingOrgs()),
      ])
        .catch((e) => {
          dispatch(pushGlobalMessage(e, 'error'));
          setPageError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setPageError(e);
    }
  }, [dispatch]);

  useEffect(() => {
    loadRelevantData();

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Settings - Account' },
    });
  }, [loadRelevantData]);

  const onDefaultOrgChange = useCallback(
    (e) => {
      const settings = {
        'Default Org': e.target.value,
      };

      dispatch(updateUserSettings(settings))
        .then(() => {
          dispatch(pushGlobalMessage('Default organization successfully set.', 'success'));
        })
        .catch((error) => {
          dispatch(pushGlobalMessage(error, 'error'));
        });
    },
    [dispatch]
  );

  const { orgs } = useAppSelector(selectOrganization) ?? {};
  const userSettings = useAppSelector(selectUserSettings);
  const releaseFlags = useAppSelector(getReleaseFlags);

  const nonPersonalOrgOptions = useMemo(
    () =>
      orgs
        ?.filter((org) => org.is_personal === 0)
        .map((org) => ({
          key: org.id,
          value: org.id,
          label: org.name,
        })),
    [orgs]
  );

  if (loading) {
    return <Loader />;
  }

  if (pageError) {
    return <PageLoadingErrorScreen error={pageError} />;
  }

  return (
    <div className="settings-page SettingsAccount">
      <HoloHelmet title="Settings - Your Account" />
      <UserInformation />
      <Panel header="Change password" className="stellar-panel-form" noContentPadding>
        <ChangePassword />
      </Panel>
      {releaseFlags?.mfa && (
        <Panel header="Multi-factor authentication (MFA)">
          <div className="MFAPanel">
            <div className="MFAPanel__text">
              MFA is currently <strong>enabled</strong>.
              <Popover
                id="MFA Icon"
                content="To change MFA settings, contact Hologram support."
                style={{ display: 'inline-flex' }}
              >
                <Icon name="CircleInfo" fill="DdsColorInteractivePrimaryDefault" />
              </Popover>
            </div>
            <p className="MFAPanel__subtext">
              A verification code is required to log in to the Dashboard.
            </p>
          </div>
        </Panel>
      )}
      <Panel header="View settings" className="stellar-panel-form">
        <div className="ViewSettingsForm">
          <SelectField
            label="Default organization"
            helpText="This will change which organization is opened automatically when you log into your account."
            defaultValue={userSettings['Default Org']}
            layoutDirection="row"
            options={['My Personal Account', ...nonPersonalOrgOptions]}
            onChange={onDefaultOrgChange}
            name="defaultOrganizationSelect"
          />
        </div>
      </Panel>
      <Panel
        header="Organizations"
        headerActions={
          <Button variant="secondary" to={withContext(ORGS_CREATE_STEP1)}>
            Create new organization
          </Button>
        }
        noContentPadding
      >
        <MyOrganizations />
      </Panel>
      <Panel header="Delete account" contentClassName="SettingsAccount__delete">
        <div>
          Deleting your account is a manual process. Your account must be in good standing without a
          negative balance, and have no active SIMs. Instructions are outlined in the linked
          article.
        </div>
        <div>
          <Button
            href="https://support.hologram.io/hc/en-us/articles/360035212674-Delete-your-account"
            variant="delete"
            className="SettingsAccount__deleteButton"
            iconEnd="ArrowEastShort"
            target="_blank"
            rel="noreferrer"
          >
            Delete this account
          </Button>
        </div>
      </Panel>
    </div>
  );
};

export default SettingsAccount;
