import findIndex from 'lodash/findIndex';
import { LOGOUT_REQUEST } from '../account/actionTypes';
import {
  GET_ALL_ERROR,
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ONE_ERROR,
  GET_ONE_REQUEST,
  GET_ONE_SUCCESS,
  REVEAL_LOGS,
  REVEAL_LOGS_FOR_ONE,
  UNMOUNT_LOGS,
} from './actionTypes';

const initialState = {
  logs: [],
  isLoadingLogs: false,
  logsPerDevice: {},
  hasMoreLogs: false,
  wasError: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function logReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_REQUEST:
    case UNMOUNT_LOGS:
      return { ...state, ...initialState, logsPerDevice: {} };

    case GET_ALL_REQUEST:
      return {
        ...state,
        isLoadingLogs: true,
        isContinuing: action.isContinuing,
        isUpdating: action.isUpdating,
        wasError: false,
      };

    case GET_ALL_SUCCESS: {
      if (action.isContinuing) {
        // infinite scrolling here.  append them to the current logs.
        return {
          ...state,
          logs: state.logs?.concat(action.logs),
          hasMoreLogs: action.hasMoreLogs,
          isLoadingLogs: false,
          isContinuing: false,
          isUpdating: false,
          wasError: false,
        };
      }
      if (action.isUpdating) {
        // logs auto updating.  patch them at the beginning of the current logs and mark as "unrevealed".
        const newLogs = (action.logs ?? [])
          .filter((log) => findIndex(action.currentLogs, { id: log.id }) === -1)
          .map((log) => ({ ...log, unRevealed: (state.logs?.length ?? 0) > 0 }));
        return {
          ...state,
          logs: newLogs.concat(state.logs ?? []),
          isLoadingLogs: false,
          isContinuing: false,
          isUpdating: false,
          wasError: false,
        };
      }
      // logs loading for first time. replace all logs with new logs.
      return {
        ...state,
        logs: action.logs,
        hasMoreLogs: action.hasMoreLogs,
        isLoadingLogs: false,
        isContinuing: false,
        isUpdating: false,
      };
    }

    case GET_ALL_ERROR:
      return {
        ...state,
        logs: action.logs,
        isLoadingLogs: false,
        isContinuing: false,
        isUpdating: false,
        wasError: true,
      };

    case GET_ONE_REQUEST:
      return {
        ...state,
        isLoadingLogs: true,
        isContinuing: action.isContinuing,
        isUpdating: action.isUpdating,
        wasError: false,
      };

    case GET_ONE_ERROR:
      return {
        ...state,
        isLoadingLogs: false,
        isContinuing: false,
        isUpdating: false,
        wasError: true,
      };

    case GET_ONE_SUCCESS: {
      if (action.isContinuing) {
        // infinite scrolling here.  append them to the current logs.
        return {
          ...state,
          logsPerDevice: {
            ...state.logsPerDevice,
            [action.deviceId]: [...state.logsPerDevice[action.deviceId], ...action.logs],
          },
          hasMoreLogs: action.hasMoreLogs,
          isLoadingLogs: false,
          isContinuing: false,
          isUpdating: false,
          wasError: false,
        };
      }
      if (action.isUpdating) {
        // logs auto updating.  patch them at the beginning of the current logs.

        const newLogs = action.logs
          .filter(
            (log) =>
              findIndex(state.logsPerDevice[action.deviceId], {
                id: log.id,
              }) === -1
          )
          .map((log) => ({ ...log, unRevealed: true }));

        return {
          ...state,
          logsPerDevice: {
            ...state.logsPerDevice,
            [action.deviceId]: [...newLogs, ...state.logsPerDevice[action.deviceId]],
          },
          hasMoreLogs: action.hasMoreLogs,
          isLoadingLogs: false,
          isContinuing: false,
          isUpdating: false,
          wasError: false,
        };
      }
      // logs loading for first time. replace all logs with new logs.
      return {
        ...state,
        logsPerDevice: {
          ...state.logsPerDevice,
          [action.deviceId]: action.logs,
        },
        hasMoreLogs: action.hasMoreLogs,
        isLoadingLogs: false,
        isContinuing: false,
        isUpdating: false,
        wasError: false,
      };
    }

    case REVEAL_LOGS:
      return {
        ...state,
        logs: state.logs?.map((log: any) => ({ ...log, unRevealed: false })),
      };

    case REVEAL_LOGS_FOR_ONE: {
      const newDeviceLogs = state.logsPerDevice[action.deviceId].map((log) => ({
        ...log,
        unRevealed: false,
      }));

      return {
        ...state,
        logsPerDevice: {
          ...state.logsPerDevice,
          [action.deviceId]: newDeviceLogs,
        },
      };
    }

    default:
      return state;
  }
}

export default logReducer;
