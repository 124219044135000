/* eslint-disable prefer-promise-reject-errors */
import { setDataDogUserConfiguration } from 'common-js/analytics/dataDog';
import { LOGIN_CLEAR_LOADER } from 'common-js/reducers/account/actionTypes';
import { selectAccount } from 'common-js/reducers/account/selectors';
import { getSelectedOrg } from 'common-js/reducers/organization/selectors';
import { isBoolean, isFunction, isObject, isString, omit } from 'lodash';
import queryString from 'query-string';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import getStore, { clearAllStores } from '../configureStore';
import * as Paths from '../constants/paths';
import * as accountActions from '../reducers/account/actions';
import * as appsActions from '../reducers/apps/actions';
import * as logActions from '../reducers/log/actions';
import * as modalActions from '../reducers/modal/actions';
import * as organizationActions from '../reducers/organization/actions';
import * as releaseFlagActions from '../reducers/releaseFlag/actions';
import * as topicActions from '../reducers/topic/actions';
import { getCurrentOrgId } from './util';

export function fetchHandler(
  cb?: (responseBody: any, response: any) => any,
  includingErrorHandlingInSuccess = true,
  redirectOnForbidden = true
) {
  return (response: Response) => {
    if (response.status === 403 && redirectOnForbidden) {
      if (!response.url?.includes('auth/sessiondestroy')) {
        setTimeout(() => {
          bindActionCreators({ logout: accountActions.logout }, getStore().dispatch).logout(true);

          if (window.location.pathname !== Paths.ACCOUNT_LOGIN) {
            browserHistory.replace(
              `${Paths.ACCOUNT_LOGIN}?message=expired&redirectto=${encodeURIComponent(
                window.location.pathname
              )}`
            );
          }
        }, 1);
      }

      return Promise.reject({
        error: 'Session has expired. Please log in again to continue.',
        response,
      });
    }

    if (response.ok) {
      return response.json().then((responseBody) => {
        // sometimes an error can happen in a response of 200.
        // Returning reject here will delegate the error handling to the
        // "fetchErrorHandler" method below.
        if (
          includingErrorHandlingInSuccess &&
          isBoolean(responseBody.success) &&
          responseBody.success === false &&
          !isObject(responseBody.data)
        ) {
          return Promise.reject({ error: responseBody, response });
        }

        if (isFunction(cb)) {
          return cb(responseBody, response);
        }

        return Promise.resolve(responseBody);
      });
    }

    return response.json().then((error) => Promise.reject({ error, response }));
  };
}

export function fetchErrorHandler(data?: FetchError) {
  const thisData = data;
  // Catches JS and fetch errors
  if (thisData instanceof Error) {
    /* eslint-disable no-console */
    if (console.error) {
      console.error(thisData.message || thisData);
    }
    /* eslint-enable no-console */

    return Promise.reject(thisData.message || thisData);
  }

  // Catches a 500 internal server error with no message
  if (!thisData || !thisData.error) {
    return Promise.reject('Something went wrong! Please try again shortly.');
  }

  // API errors: (thisData.error is the body. thisData.response is the raw request/response object)
  if (!thisData.error.success && isString(thisData.error.error)) {
    const errorTranslaterMap = {
      'No billing data': 'Please add a payment method.',
    };

    if (errorTranslaterMap[thisData.error.error]) {
      thisData.error.error = errorTranslaterMap[thisData.error.error];
    }

    // bulk action case:
    if (
      thisData.error.data &&
      thisData.error.data.invalid_tasks &&
      thisData.error.data.invalid_tasks.length > 0
    ) {
      const bulkErrorMessages = thisData.error.data.invalid_tasks
        .filter((task) => task.reason)
        .map((task) => task.reason);

      return Promise.reject(bulkErrorMessages.join(', '));
    }

    return Promise.reject(thisData.error.error);
  }

  // Catch all in case we missed an error state.
  if (isString(thisData.error)) {
    return Promise.reject(thisData.error);
  }

  return Promise.reject('Something went wrong! Please try again shortly.');
}

export function extendedFetchErrorHandler(cb) {
  return (e) => {
    const response = cb(e);

    if (response) {
      return response;
    }
    return fetchErrorHandler(e);
  };
}

let hasPageDataLoaded = false;
let hasAuthPageDataLoaded = false;

export function loadPageData() {
  if (hasPageDataLoaded) return;
  hasPageDataLoaded = true;
  bindActionCreators(
    { getPublicReleaseFlags: releaseFlagActions.getPublicReleaseFlags },
    getStore().dispatch
  ).getPublicReleaseFlags();
}

// After page has rendered
export function onPageRouteChange() {
  loadPageData();

  const store = getStore().getState();

  window.scrollTo(0, 0); // scroll window to the top.

  // Roadblock functionality:
  bindActionCreators(
    { triggerModalQueue: modalActions.triggerModalQueue },
    getStore().dispatch
  ).triggerModalQueue();

  const userInfo = selectAccount(store) ?? {};
  const currentOrg = getSelectedOrg(store) ?? {};
  setDataDogUserConfiguration(userInfo, currentOrg.id);

  // TODO: since this is store conditional stuff, it should be registered
  // and implemented in the drawer actions file.
  //
  // Do a conditional based upon a "islocked" flag from the drawer reducer.
  if (
    (!isBoolean(store.drawer.drawerTakeover) || !store.drawer.drawerTakeover) &&
    store.drawer.isLocked &&
    queryString.parse(window.location.search).drawer !== 'full'
  ) {
    getStore().dispatch({
      type: 'DRAWER_HASH_CHANGE',
      hash: { drawer: 'revealed' },
    });
  } else if (!isBoolean(store.drawer.drawerTakeover) || !store.drawer.drawerTakeover) {
    getStore().dispatch({
      type: 'DRAWER_HASH_CHANGE',
      hash: queryString.parse(window.location.search),
    });
  }
}

export function redirectToContext() {
  // make sure not to forward any email/password combos

  const parsedQuery = queryString.parse(window.location.search);
  const forwardedQuery = omit(parsedQuery, ['email', 'password']);
  const forwardedQueryString = queryString.stringify(forwardedQuery);

  browserHistory.replace(
    Paths.withContext(window.location.pathname) +
      (forwardedQueryString.length > 0 ? `?${forwardedQueryString}` : '') +
      window.location.hash
  );
}

function checkSession() {
  const state = getStore().getState();
  const hasUserInLocalStorage = !!state.account.userId;
  const AccountActions = bindActionCreators(
    {
      getUserInfo: accountActions.getUserInfo,
      loginSequence: accountActions.loginSequence,
    },
    getStore().dispatch
  );

  if (hasUserInLocalStorage) {
    return Promise.resolve();
  }
  // user is not currently logged in, so do a blocking call to get user info
  return AccountActions.getUserInfo()
    .then((data) => AccountActions.loginSequence(data.userInfo))
    .catch(() => Promise.reject({ type: 'SESSION_EXPIRED' }));
}

function loadAuthedPageData() {
  if (hasAuthPageDataLoaded) return Promise.resolve();

  const actions = bindActionCreators(
    {
      getOrganizations: organizationActions.getOrganizations,
      getReleaseFlags: releaseFlagActions.getReleaseFlags,
      clearReleaseFlags: releaseFlagActions.clearReleaseFlags,
      getAllAppData: appsActions.getAllAppData,
      getLogs: logActions.getLogs,
      getTopics: topicActions.getTopics,
      getDeviceCount: organizationActions.getDeviceCount,
      getUserInfo: accountActions.getUserInfo,
    },
    getStore().dispatch
  );

  actions.clearReleaseFlags();

  const renderBlockingPromises = [
    actions.getOrganizations(),
    actions.getReleaseFlags(),
    actions.getUserInfo(),
  ];

  const nonBlockingPromises = [
    actions.getLogs(),
    actions.getDeviceCount(),
    actions.getAllAppData(),
    actions.getTopics(),
  ];

  // eslint-disable-next-line no-console
  Promise.all(nonBlockingPromises).catch((e) => console.error(e));

  return Promise.all(renderBlockingPromises)
    .then(() => {
      hasAuthPageDataLoaded = true;
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
}

function setContext() {
  // checks the orgid in the querystring, and makes sure the store is loaded up properly.
  const currentOrgId = getCurrentOrgId(getStore().getState);
  const pathOrgId = Paths.getPathnameOrgId();

  // There should always be a pathOrgId because this runs after redirectToContext
  if (currentOrgId !== pathOrgId) {
    // yikes! store and query contexts don't match.  we need to switch contexts for this tab:
    organizationActions.changeContext(
      pathOrgId,
      true,
      window.location.pathname
    )(getStore().dispatch, getStore().getState);

    return Promise.reject({ type: 'END_OF_PROCESS' });
  }

  // This isn't necessary: we do this on the HoloStore component
  // make sure store is currently set correctly.
  // upsertStore(currentOrgId);

  return Promise.resolve();
}

function verifyContext() {
  const pathOrgId = Paths.getPathnameOrgId();
  const storeOrgId = getCurrentOrgId(getStore().getState);

  if (pathOrgId) {
    return Promise.resolve();
  }
  if (storeOrgId) {
    const queryObject = queryString.parse(window.location.search);
    if (queryObject) {
      const currentLocation = browserHistory.getCurrentLocation();
      browserHistory.replace({
        pathname: Paths.withContext(window.location.pathname + window.location.search, storeOrgId),
        state: currentLocation.state,
      });
    } else {
      const currentLocation = browserHistory.getCurrentLocation();
      browserHistory.replace({
        pathname: Paths.withContext(window.location.pathname, storeOrgId),
        state: currentLocation.state,
      });
    }
    return Promise.reject({ type: 'END_OF_PROCESS' });
  }
  return Promise.reject({ type: 'SESSION_EXPIRED' });
}

function authErrorHandler(e?: AuthError) {
  if (isString(e)) {
    const error = new Error(e);
    throw error;
  } else if (e instanceof Error) {
    throw e;
  } else if (isObject(e)) {
    if (e.type === 'SESSION_EXPIRED') {
      clearAllStores();

      const queryObject = queryString.parse(window.location.search);
      if (e.redirect) {
        queryObject.redirectto = encodeURIComponent(
          window.location.pathname + window.location.search
        );
      }

      let query = queryString.stringify(queryObject);
      query = query.length > 0 ? `?${query}` : '';

      if (queryObject.userisnew === '1') {
        return browserHistory.push(Paths.ACCOUNT_REGISTER + query);
      }
      return browserHistory.push(Paths.ACCOUNT_LOGIN + query);
    }
  }
  return undefined;
}

// Before the page renders
export function auth(nextState, replace, next) {
  verifyContext() // Potentially redirects /oldroute to /org/<orgid from store>/oldroute...
    .then(() => checkSession()) // Checks user session
    .then(() => setContext()) // Switches context if /org/orgid doesn't match store orgid.
    .then(() => loadAuthedPageData())
    .then(() => next())
    .catch(authErrorHandler);
}

const tryToRedirectOnLoggedIn = () =>
  new Promise((resolve) => {
    try {
      checkSession()
        .then((result) => {
          if (!result) {
            const store = getStore();
            if (store && typeof store.dispatch === 'function') {
              try {
                store.dispatch(accountActions.loginRequest());
                store
                  .dispatch(accountActions.fetchAccount())
                  .then(() => {
                    resolve(true);
                  })
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  .catch((error) => {});
              } catch (redirectLoginErr) {
                // eslint-disable-next-line no-console
                console.log(`Benign redirect error: ${redirectLoginErr}`);
              }
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error) => {});
    } catch (e: any) {
      // Ok to NOP here
    }
    resolve(false);
  });

export const redirectOnAuth = (nextState, replace, next) => {
  tryToRedirectOnLoggedIn().then((successfulRedirect) => {
    if (!successfulRedirect) {
      // Entry to this route should never result in the MFA login modal
      clearAllStores();
      const store = getStore();
      if (store && typeof store.dispatch === 'function') {
        store.dispatch(accountActions.clearMfa());
        store.dispatch({ type: LOGIN_CLEAR_LOADER });
      }

      next();
    }
  });
};
