import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import analyticsEventBuilder from 'common-js/analytics';
import { setCurrentTasksPage } from 'common-js/reducers/devices/actions';
import { getTaskPageInfo } from 'common-js/reducers/devices/selectors';

/**
 * A utility hook that returns pagination handlers for when the user presses a pagination button
 * on the activity history page.
 * TODO: make a generalized version of this, to cut down on boilerplate
 */
const useActivityHistoryPaginationHandlers = () => {
  const dispatch = useAppDispatch();
  const { currentPage, totalPages } = useAppSelector(getTaskPageInfo);

  const onNextClick = useCallback(() => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      dispatch(setCurrentTasksPage(nextPage));
    }
    analyticsEventBuilder.buttonClick('Activity History', 'Next Click').send();
  }, [currentPage, totalPages, dispatch]);

  const onPreviousClick = useCallback(() => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      dispatch(setCurrentTasksPage(prevPage));
    }
    analyticsEventBuilder.buttonClick('Activity History', 'Previous Click').send();
  }, [currentPage, dispatch]);

  const onFastForwardClick = useCallback(() => {
    if (currentPage !== totalPages) {
      dispatch(setCurrentTasksPage(totalPages));
    }
    analyticsEventBuilder.buttonClick('Activity History', 'Fast-forward Click').send();
  }, [currentPage, totalPages, dispatch]);

  const onRewindClick = useCallback(() => {
    if (currentPage !== 1) {
      dispatch(setCurrentTasksPage(1));
    }
    analyticsEventBuilder.buttonClick('Activity History', 'Rewind Click').send();
  }, [currentPage, dispatch]);

  return {
    onNextClick,
    onPreviousClick,
    onFastForwardClick,
    onRewindClick,
  };
};

export default useActivityHistoryPaginationHandlers;
