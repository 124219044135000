import { useEffect } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useGetDeviceById from 'common-js/api/devices/useGetDeviceById';
import { setCurrentDevice } from 'common-js/reducers/currentDevice/actions';
import { selectCurrentDevicePollingPause } from 'common-js/reducers/currentDevice/selectors';

const usePollDevice = (deviceId, enabled = false) => {
  const dispatch = useAppDispatch();
  const paused = useAppSelector(selectCurrentDevicePollingPause);
  const getDevice = useGetDeviceById();

  useEffect(() => {
    if (!enabled || paused) return () => {};

    const interval = window.setInterval(async () => {
      const device = await getDevice(deviceId);
      dispatch(setCurrentDevice(device));
    }, 10000);

    return () => window.clearInterval(interval);
  }, [deviceId, dispatch, enabled, getDevice, paused]);
};

export default usePollDevice;
