import React from 'react';
import { StellarMoney } from 'common-js/components';

interface ActivationMoneyProps {
  amount: number;
}
const ActivationMoney = ({ amount }: ActivationMoneyProps) => (
  <StellarMoney amount={amount} decimalLength={2} showTrailingZeros showCommas />
);

export default ActivationMoney;
