import { OrganizationProvider, UsageReportsPage } from '@hologram-hyper-dashboard/components';
import { getOrgIdFromContextData, getUserContextDataMemoized } from 'common-js/api/util';
import { HoloHelmet } from 'common-js/components';
import {
  USAGE,
  USAGE_BY_DAY,
  USAGE_BY_SESSION,
  USAGE_BY_SIM,
  USAGE_BY_SIM_AND_DAY,
  pathMatchesNewUsageTab,
  pathMatchesWithIdsReplaced,
} from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectHasSimsPages } from 'common-js/reducers/releaseFlag/selectors';
import { useCallback, useMemo, type FC, type ReactNode } from 'react';
import { browserHistory } from 'react-router';
import UsageOverview from './UsageOverview';

type UsageView = GetReactParameterBag<typeof UsageReportsPage>['viewKey'];

interface UsageProps {
  location: { pathname: string };
}

const UsageOverviewWrapper: FC<UsageProps> = ({ location }) => {
  const hasPageSimInventory = useAppSelector(selectHasSimsPages) as boolean;
  const userContext = useAppSelector(getUserContextDataMemoized);
  const orgId = getOrgIdFromContextData(userContext);
  const newUsageTabActive = pathMatchesNewUsageTab();

  const path = location.pathname;
  const view: { key?: UsageView; title: string } | null = useMemo(() => {
    // Note: this has to be ordered here based on the the way path matching is implemented
    if (pathMatchesWithIdsReplaced(USAGE_BY_SIM_AND_DAY, path)) {
      return { key: 'bysimandday', title: 'By SIM' };
    }
    if (pathMatchesWithIdsReplaced(USAGE_BY_SIM, path)) {
      return { key: 'bysim', title: 'By SIM and day' };
    }
    if (pathMatchesWithIdsReplaced(USAGE_BY_SESSION, path)) {
      return { key: 'bysession', title: 'By session' };
    }
    if (pathMatchesWithIdsReplaced(USAGE_BY_DAY, path)) {
      return { key: 'overview', title: 'Overview' };
    }

    return { title: 'Overview' };
  }, [path]);

  const handleTabClick = useCallback((url: string) => {
    // this passing of state (read: device info) is passed to the sim details page as a temp method
    // once the endpoints work for individual sims, we can modify SimDetails page to do a fetch request,
    // rather than pass it this way. this is to show the nav works!!
    browserHistory.push({ pathname: url.replace('overview', 'byday') });
  }, []);

  let contents: ReactNode;
  if (newUsageTabActive && hasPageSimInventory) {
    if (!orgId || !view.key) {
      contents = null;
    } else {
      contents = (
        <UsageReportsPage
          handleTabClick={handleTabClick}
          viewKey={view?.key}
          usageBaseUrl={USAGE}
        />
      );
    }
  } else {
    contents = <UsageOverview />;
  }

  return (
    <>
      <HoloHelmet title={`Usage reports - ${view.title}`} />
      <OrganizationProvider organizationId={orgId ?? undefined}>{contents}</OrganizationProvider>
    </>
  );
};

export default UsageOverviewWrapper;
