import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from 'common-js/api/useApi';
import { selectOrgId } from 'common-js/api/util';

// This API call had redux actions that were never referenced anywhere
// Seems safe to keep the API call local
const useRemovePhoneNumber = (deviceId) => {
  const orgId = useAppSelector(selectOrgId);
  return useApi.call(`/devices/${deviceId}/removenumber?orgid=${orgId}`, {
    method: 'POST',
  });
};

export default useRemovePhoneNumber;
