import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { LiveUsageChart as LiveUsageChartComponent } from 'usage/components';
import { selectLiveUsage } from 'common-js/reducers/currentDevice/selectors';

const LiveUsageChart = () => {
  const { data, error, loading } = useAppSelector(selectLiveUsage);

  return <LiveUsageChartComponent data={data} error={error} loading={loading} />;
};

export default LiveUsageChart;
