/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button } from '@hologram-dimension/button';
import { Link } from '@hologram-dimension/link';
import { Loader } from '@hologram-dimension/loader';
import { Panel } from '@hologram-dimension/panel';
import { PanelNotification } from '@hologram-dimension/panel-notification';
import {
  BILLING_ORDERS_SHOW_MORE,
  BILLING_ORDERS_TOGGLE_DETAILS,
  PAGE_VIEW,
} from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { HoloHelmet, PageLoadingErrorScreen } from 'common-js/components';
import * as accountActions from 'common-js/reducers/account/actions';
import Moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Orders extends React.Component<any, any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isViewingAllOrders: false,
      pageError: null,
    };

    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Billing - Orders' } });
  }

  componentDidMount() {
    const { getOrders } = this.props;

    try {
      getOrders()
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch((e) => {
          this.setState({ isLoading: false, pageError: e });
        });
    } catch (e) {
      this.setState({ pageError: e });
    }
  }

  componentWillUnmount() {
    const { unmountOrders } = this.props;
    unmountOrders();
  }

  static onDetailsClick = (e) => {
    e.preventDefault();

    // Can't find an efficient way to do this in react... go vanilla JS baby!
    const nextTr = e.currentTarget.parentNode.parentNode.nextSibling;
    const currentTr = e.currentTarget.parentNode.parentNode;
    const classesOnElement = nextTr.className;
    const indexOfHiddenClass = classesOnElement.indexOf('hidden');

    if (indexOfHiddenClass !== -1) {
      currentTr.classList.add('opened');
      nextTr.classList.remove('hidden');
    } else {
      nextTr.classList.add('hidden');
      currentTr.classList.remove('opened');
    }

    sendAnalyticsEvent({ type: BILLING_ORDERS_TOGGLE_DETAILS });
  };

  getOrdersToDisplay = () => {
    const { orders } = this.props;
    const { isViewingAllOrders } = this.state;

    return isViewingAllOrders ? orders : orders.filter((item, idx) => idx < 10);
  };

  render() {
    const { isLoading, isViewingAllOrders, pageError } = this.state;
    const { orders } = this.props;

    return (
      <div className="page-billing-orders">
        <HoloHelmet title="Order history" />
        {pageError ? (
          <PageLoadingErrorScreen error={pageError} />
        ) : (
          <Panel header="Order history" noContentPadding>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                {orders.length === 0 ? (
                  <PanelNotification label="No order history yet.">
                    When you have placed an order, you will see an itemized list of everything you
                    purchased.
                  </PanelNotification>
                ) : (
                  <div>
                    <table className="UsageTable OrdersTable">
                      <thead>
                        <tr className="header">
                          <th>id</th>
                          <th>description</th>
                          <th>date & time (UTC)</th>
                          <th className="merge-to-right">amount</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.getOrdersToDisplay().map((order) => [
                          <tr key={`order-${order.id}`} className="body-data">
                            <td>{order.id}</td>
                            <td>
                              {order.items.map((item, idx) => (
                                <span key={`orderData-${item.id}`}>
                                  {item.quantity} X {item.description}{' '}
                                  {idx < order.items.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                            </td>
                            <td>{Moment.utc(order.whenplaced).format('MM-DD-YY HH:mm:ss')}</td>
                            <td>${order.amount}</td>
                            <td>
                              <Link
                                className="details"
                                onClick={Orders.onDetailsClick}
                                type="inline"
                              >
                                Details
                              </Link>
                            </td>
                          </tr>,
                          <tr className="body-data hidden">
                            <td className="OrdersRowInfo" colSpan={4}>
                              <table className="UsageTable OrdersRowInfoTable">
                                <thead>
                                  <tr className="header">
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order.items.map((item) => (
                                    <tr key={`hiddenOrder-${item.id}`}>
                                      <td>{item.description}</td>
                                      <td className="notes">{item.extra_description}</td>
                                      <td>${item.total_cost}</td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td />
                                    <td className="total-label">Subtotal</td>
                                    <td>${order.amount}</td>
                                  </tr>
                                  <tr>
                                    <td />
                                    <td className="total-label">Sales tax</td>
                                    <td>${order.sales_tax}</td>
                                  </tr>
                                  <tr>
                                    <td />
                                    <td className="total-label">Shipping</td>
                                    <td>${order.shipping_cost}</td>
                                  </tr>
                                  <tr>
                                    <td />
                                    <td className="total-label">Total</td>
                                    <td>${order.amount}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>,
                        ])}
                      </tbody>
                    </table>
                    {!isViewingAllOrders && orders.length > 10 ? (
                      <div className="OrdersTable__more-btn">
                        <Button
                          onClick={() => {
                            sendAnalyticsEvent({
                              type: BILLING_ORDERS_SHOW_MORE,
                            });
                            this.setState({ isViewingAllOrders: true });
                          }}
                          variant="secondary"
                        >
                          Show more
                        </Button>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </Panel>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.account.orders,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrders: accountActions.getOrders,
      unmountOrders: accountActions.unmountOrders,
    },
    dispatch
  );

const ConnectedOrders = connect(mapStateToProps, mapDispatchToProps)(Orders);

export default ConnectedOrders;
