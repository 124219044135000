import { useEffect, useState } from 'react';
import useRenameDevices from 'common-js/api/devices/useRenameDevices';

const useFetchBulkRenamePreview = () => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [devices, setDevices] = useState([]);
  const [totalDevicesCount, setTotalDevicesCount] = useState(0);

  const fetchRenameDevicesPreview = useRenameDevices({ preview: true });

  useEffect(() => {
    const loadPreview = async () => {
      setLoading(true);
      const { sampleDevices, totalDevices, error } = await fetchRenameDevicesPreview();
      setLoading(false);

      if (error) {
        setApiError(error);
        setDevices([]);
        setTotalDevicesCount(0);
      } else {
        setDevices(sampleDevices);
        setTotalDevicesCount(totalDevices);
        setApiError(null);
      }
    };

    loadPreview();
  }, [fetchRenameDevicesPreview, setApiError, setDevices, setTotalDevicesCount, setLoading]);

  return {
    loading,
    devices,
    totalDevicesCount,
    apiError,
    setApiError,
  };
};

export default useFetchBulkRenamePreview;
