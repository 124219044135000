import PropTypes from 'prop-types';
import React from 'react';
import Input from './Input';
import Suggestions from './Suggestions';

const KEYS = {
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
};

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedIndex: -1, suggestionsEnabled: false };
  }

  handleKeyDown(e) {
    const { selectedIndex } = this.state;

    if (e.keyCode === KEYS.ENTER || e.keyCode === KEYS.TAB) {
      e.currentTarget.blur();
    } else if (e.keyCode === KEYS.UP_ARROW) {
      e.preventDefault();
      // if last item, cycle to the bottom
      if (selectedIndex <= 0) {
        this.setState({
          selectedIndex: this.suggestions.state.options.length - 1,
        });
      } else {
        this.setState({ selectedIndex: selectedIndex - 1 });
      }
    } else if (e.keyCode === KEYS.DOWN_ARROW) {
      e.preventDefault();
      this.setState({
        selectedIndex: (selectedIndex + 1) % this.suggestions.state.options.length,
      });
    } else {
      this.setState({ suggestionsEnabled: true });
    }
  }

  handleClick(e) {
    if (document.activeElement !== e.target) {
      this.input.input.focus();
    }
  }

  handleBlur() {
    const { selectedIndex } = this.state;
    const { value, onBlur } = this.props;

    this.setState({ selectedIndex: -1 });

    if (value.length >= this.props.minQueryLength) {
      // Check if the user typed in an existing suggestion.
      const match = this.suggestions.state.options.findIndex(
        (suggestion) => suggestion.name.search(new RegExp(`^${value}$`, 'i')) === 0
      );

      const index = selectedIndex === -1 ? match : selectedIndex;

      if (index > -1) {
        this.setValue(this.suggestions.state.options[index].name);
      } else if (this.props.allowNew) {
        this.setValue(value);
      }

      this.setState({ suggestionsEnabled: false });
    }

    if (onBlur) onBlur();
  }

  setValue(value, source) {
    if (value !== this.props.value) this.props.onChange(value, source);
  }

  render() {
    const listboxId = 'ReactTags-listbox';
    const expandable =
      this.state.suggestionsEnabled && this.props.value.length >= this.props.minQueryLength;
    const { suggestions } = this.props;

    return (
      <div className="react-tag-complete" onClick={this.handleClick.bind(this)}>
        <div className="react-tags__search">
          <Input
            value={this.props.value}
            onChange={this.setValue.bind(this)}
            handleKeyDown={this.handleKeyDown.bind(this)}
            ref={(c) => {
              this.input = c;
            }}
            listboxId={listboxId}
            autofocus={this.props.autofocus}
            autoresize={this.props.autoresize}
            expandable={expandable}
            placeholder={this.props.placeholder}
            onBlur={this.handleBlur.bind(this)}
          />
          <Suggestions
            value={this.props.value}
            ref={(c) => {
              this.suggestions = c;
            }}
            listboxId={listboxId}
            expandable={expandable}
            suggestions={suggestions}
            setValue={this.setValue.bind(this)}
            maxSuggestionsLength={this.props.maxSuggestionsLength}
            selectedIndex={this.state.selectedIndex}
          />
        </div>
      </div>
    );
  }
}

AutoComplete.defaultProps = {
  tags: [],
  placeholder: 'Add new tag',
  suggestions: [],
  autofocus: true,
  autoresize: true,
  minQueryLength: 1,
  maxSuggestionsLength: 6,
  allowNew: false,
  allowDuplicates: false,
  label: '',
  invalidTags: [],
  validTags: [],
};

AutoComplete.propTypes = {
  tags: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  suggestions: PropTypes.array,
  autofocus: PropTypes.bool,
  autoresize: PropTypes.bool,
  onTagDeleted: PropTypes.func,
  onTagAdded: PropTypes.func,
  handleInputChange: PropTypes.func,
  minQueryLength: PropTypes.number,
  maxSuggestionsLength: PropTypes.number,
  classNames: PropTypes.object,
  allowNew: PropTypes.bool,
  allowDuplicates: PropTypes.bool,
  invalidTags: PropTypes.array,
  validTags: PropTypes.array,
};

export default AutoComplete;
