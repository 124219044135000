import { addTag } from 'common-js/api';
import { getUserContextData } from 'common-js/api/util';
import { Device } from 'common-js/types/Device';
import getErrorMessage from 'common-js/utils/getErrorMessage';
import { isNumber } from 'lodash';
import { merge } from 'lodash/fp';
import { getPostPay, getRunningBalance, getUserPermissions } from '../../organization/selectors';
import errorCodeToMessage from '../errorCodeToMessage';

// TODO: Update references to this to `getErrorMessage.ts`
export { getErrorMessage };

const NUMBER_OF_SIMS_LIMIT = 2500;

// use the luhn algorithm to generate the check digit for a given SIM
export const generateCheckDigit = (x) =>
  (10 -
    (x
      .split('')
      .map((n) => parseInt(n, 10))
      .map((n, i) => ((x.length - i) % 2 === 0 ? n : [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][n]))
      .reduce((a, c) => a + c, 0) %
      10)) %
  10;

export const removeDuplicates = (args) => {
  const uniqueValuesSet = new Set();

  const filteredSims = args.filter((sim) => {
    const simId = typeof sim === 'object' ? sim.key : sim;
    const isPresentInSet = uniqueValuesSet.has(simId);
    uniqueValuesSet.add(simId);

    return !isPresentInSet;
  });

  return filteredSims;
};

export const isArrayEmpty = (arg) => Array.isArray(arg) && arg.length === 0;

const refillAccount = (tasks, state, config) => {
  const threshold = config?.autoRefillParams.threshold ?? 0;
  const amountToAdd = config?.autoRefillParams.amountToAdd ?? 0;
  const excludeBalanceFill = config?.excludeBalanceFill ?? false;
  const runningBalance = getRunningBalance(state);
  const userContext = getUserContextData(state);

  const initialFillTask = {
    task: {
      endpoint: `/1/organizations/${
        userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId
      }/balance`,
      params: {
        addamount: amountToAdd,
      },
    },
    type: 'initialfill',
    result: null,
  };

  const setAutoRefillTask = {
    task: {
      endpoint: `/1/organizations/${
        userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId
      }/balanceparams`,
      params: {
        topoffamount: amountToAdd,
        minbalance: threshold,
      },
    },
    result: null,
  };

  const doInitialFill = !excludeBalanceFill && runningBalance < threshold;

  return [...tasks, ...(doInitialFill ? [initialFillTask] : []), setAutoRefillTask];
};

const applyDevicePrefix = (tasks, devicePrefix) =>
  devicePrefix
    ? tasks.map((task) => merge({ task: { params: { deviceprefix: devicePrefix } } }, task))
    : tasks;

const canRefillAccount = (tasks, state, config) => {
  const hasPostPay = getPostPay(state);
  const userPermissions = getUserPermissions(state);
  const autoRefillEnabled = config?.autoRefillParams?.enabled ?? false;
  const amountToAdd = config?.autoRefillParams?.amountToAdd ?? 0;

  return !hasPostPay && autoRefillEnabled && amountToAdd && userPermissions.includes('billing')
    ? refillAccount(tasks, state, config)
    : tasks;
};

const chunkSims = (sims) => {
  const size = NUMBER_OF_SIMS_LIMIT;

  return Array.from({ length: Math.ceil(sims.length / size) }, (_value, index) =>
    sims.slice(index * size, index * size + size)
  );
};

const simParamsForTask = (simConfig, task) => merge({ task: { params: simConfig } }, task);

export const buildTasks = (state, config) => {
  let tasks = [];

  if (!config) return tasks;

  const configHasUsageLimit = config.usageLimit !== null && config.usageLimit !== undefined;

  const bulkClaimTask = {
    task: {
      endpoint: '/1/links/cellular/bulkclaim',
      params: {
        plan: config.plan?.id,
        zone: config.plan?.zone,
        tagids: config.tagids ?? [],
        ...(configHasUsageLimit ? { overage_limit: config.usageLimit } : {}),
        ...(config.excludeSims && {
          excludeSims: config.excludeSims,
        }),
        invalidatePreflightSims: config.invalidatePreflightSims,
      },
    },
    type: 'sims',
    result: null,
  };

  if (Array.isArray(config.sims) && config.sims.length > 0) {
    const simChunks = chunkSims(config.sims);
    tasks = simChunks.reduce(
      (allTasks, simChunk) => [...allTasks, simParamsForTask({ sims: simChunk }, bulkClaimTask)],
      []
    );
  } else if (Array.isArray(config.simranges) && config.simranges.length > 0) {
    tasks = config.simranges.reduce(
      (allTasks, simrange) => [...allTasks, simParamsForTask({ simrange }, bulkClaimTask)],
      []
    );
  }

  const configuredTasks = canRefillAccount(
    applyDevicePrefix(tasks, config.devicePrefix),
    state,
    config
  );
  const configuredTaskList = configuredTasks.map((taskObject) => taskObject.task);

  return configuredTaskList;
};

const handleInvalidTask = (invalidTask) => {
  const sims = invalidTask.params?.sims ?? [];

  if (!invalidTask.payload) {
    return {
      invalidSims: sims.map((item) => ({ key: item })),
      error: invalidTask.reason,
    };
  }

  return sims.reduce(
    (acc, curr) => {
      // If the user didn't enter a check digit, the API may return the payload with the check digit appended
      const simWithCheckDigit = Object.keys(invalidTask.payload).find(
        (key) => key === curr || key === curr + generateCheckDigit(curr)
      );
      const message = invalidTask.payload[simWithCheckDigit ?? ''];

      if (message) {
        const errorMessage = errorCodeToMessage[invalidTask.error_code] ?? message;

        acc.invalidSims = [...(acc.invalidSims || []), { key: curr, value: errorMessage }];

        return acc;
      }

      acc.validSims = [...(acc.validSims || []), curr];
      return acc;
    },
    { validSims: [], invalidSims: [] }
  );
};

const handleInvalidTasks = (invalidTasks) =>
  invalidTasks.reduce(
    (allInvalidTasks, currInvalidTask) => {
      const { validSims, invalidSims, error } = handleInvalidTask(currInvalidTask);

      return {
        validSims: [...allInvalidTasks.validSims, ...(validSims ?? [])],
        invalidSims: [...allInvalidTasks.invalidSims, ...(invalidSims ?? [])],
        error: allInvalidTasks.error ?? error,
      };
    },
    { validSims: [], invalidSims: [] }
  );

const handleValidTasks = (validTasks) =>
  validTasks.reduce(
    (allValidTasks, currValidTask) => [...allValidTasks, ...currValidTask.params.sims],
    []
  );

export const handleErrors = (error, sims) => {
  const invalidTasks = error?.data?.invalid_tasks;
  const validTasks = error?.data?.valid_tasks;

  if (!validTasks && !invalidTasks) {
    return {
      error: error.error,
      invalidSims: sims.map((sim) => ({ key: sim })),
    };
  }

  let errorResponse: any = {};
  if (invalidTasks) {
    errorResponse = { ...handleInvalidTasks(invalidTasks) };
  }
  if (validTasks) {
    errorResponse.validSims = [...errorResponse.validSims, ...handleValidTasks(validTasks)];
  }
  return errorResponse;
};

const rangeErrorTemplate = (error, totalValidSims) => ({
  error,
  invalidSims: [],
  totalValidSims,
});

export const handleRangeErrors = (error) => {
  const invalidTasks = error?.data?.invalid_tasks;

  if (!invalidTasks || invalidTasks.length === 0) {
    return rangeErrorTemplate(error?.error, 0);
  }

  return invalidTasks.reduce(
    (acc, task) => {
      const errorMessage = !task.payload ? task.reason : null;
      const hasError = acc.error || errorMessage;
      const totalValidSims = task.payload?.valid_sims_count ?? 0;
      const invalidSims = Object.keys(task.payload ?? {})
        .filter((key) => key !== 'valid_sims_count')
        .map((key) => ({
          key,
          value: errorCodeToMessage[task.error_code] ?? task.payload[key],
        }));

      return {
        totalValidSims: acc.totalValidSims + totalValidSims,
        invalidSims: [...acc.invalidSims, ...(invalidSims ?? [])],
        ...(hasError && { error: acc.error ?? errorMessage }),
      };
    },
    { totalValidSims: 0, invalidSims: [] }
  );
};

const addNewTags = async (deviceTags: Array<Device>, userContext: any) => {
  const existingDeviceTags = deviceTags.filter((tag) => isNumber(tag.id));
  const existingTagIds = existingDeviceTags.map((tag) => tag.id);

  const newDeviceTags = deviceTags.filter((tag) => !isNumber(tag.id));
  const newTags = await Promise.all(
    newDeviceTags.map((tag) => addTag(tag.name, null, userContext))
  );
  const newTagIds = newTags?.map((tag) => tag?.id) ?? [];

  return [...newTagIds, ...existingTagIds];
};

export const buildUsageLimit = (
  dataLimit?: number,
  usageLimit: { type?: string; unit?: string; amount?: number } = {}
) => {
  if (!usageLimit.type || usageLimit.type === 'unlimited' || dataLimit === undefined) {
    return -1; // -1 means no limit
  }

  const multipliers = {
    KB: 1000,
    MB: 1000000,
    GB: 1000000000,
  };

  const unitMultiplier = multipliers[usageLimit.unit ?? ''] ?? 1;

  switch (usageLimit.type) {
    case 'equal':
      return 0;
    case '1mb':
      return 1_000_000;
    case '1.5mb':
      return 1_500_000;
    case '5mb':
      return 5_000_000;
    case '10mb':
      return 10_000_000;
    case 'custom':
      return Math.max((usageLimit.amount ?? 0) * unitMultiplier - dataLimit, 0);
    default:
      return -1;
  }
};

export const buildSimConfiguration = async (config) => {
  try {
    if (!config) return { error: 'Config undefined' };

    const createTags = config.createTags ?? false;
    const deviceTags = config.tags?.tags ?? [];
    const tagIds = createTags ? await addNewTags(deviceTags, config.userContext) : [];

    return {
      autoRefillParams: {
        enabled: config.autoRefillParams?.enabled ?? false,
        amountToAdd: config.autoRefillParams?.amountToAdd,
        threshold: config.autoRefillParams?.threshold,
      },
      devicePrefix: config.devicePrefix?.prefix,
      excludeBalanceFill: config.excludeBalanceFill ?? true, // default to turning this off, safer
      excludeSims: config.excludeSims ?? [],
      plan: config.plan,
      simranges: (config.sims?.ranges || []).map(({ start, end }) => `${start}-${end}`),
      sims: config.sims?.sims,
      tagids: tagIds,
      ...(config.hasDataUsageLimits
        ? {
            usageLimit: buildUsageLimit(config.plan?.dataLimit, config.usageLimit),
          }
        : {}),
      invalidatePreflightSims: config.invalidatePreflightSims,
    };
  } catch (error) {
    return { error };
  }
};

export const reduceOrderData = (acc: Array<OrderData>, orderData: OrderData) => {
  let found = false;

  acc.forEach((existingOrderData) => {
    const toUpdate = existingOrderData;
    if (toUpdate.unit_cost === orderData.unit_cost) {
      toUpdate.linkids.push(...orderData.linkids);
      toUpdate.quantity += orderData.quantity;
      toUpdate.total_cost += orderData.total_cost;
      found = true;
    }
  });

  if (!found) acc.push(orderData);

  return acc;
};

export const filterValidTasks = (tasks: Array<{ endpoint: string; order_data: OrderData }>) =>
  tasks
    .filter(({ endpoint }) => endpoint === '/1/links/cellular/bulkclaim')
    .flatMap(({ order_data }) => order_data)
    .reduce(reduceOrderData, []);
