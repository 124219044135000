import { type SimInventoryCommonTypes } from '@hologram-hyper-dashboard/components';
import { FC } from 'react';
import ActivationButton from './ActivationButton';

interface DevicesEmptyStateProps {
  deviceType: SimInventoryCommonTypes.SimInventorySegmentName;
}

const DevicesEmptyState: FC<DevicesEmptyStateProps> = ({ deviceType }) => (
  <div className="DevicesEmptyState">
    <h2 className="DevicesEmptyState__headline">Activate SIMs to get started</h2>
    <p className="DevicesEmptyState__subtext">Devices with activated SIMs will appear here.</p>
    <ActivationButton deviceType={deviceType} />
  </div>
);

export default DevicesEmptyState;
