import { Icon } from '@holokit/core';
import React from 'react';

interface HeadlineToastProps {
  body?: React.ReactNode;
  headline?: React.ReactNode;
  icon?: (typeof Icon)['name'];
  size?: (typeof Icon)['size'];
}

const HeadlineToast: React.FC<HeadlineToastProps> = ({ body, headline, icon, size = 'major' }) => (
  <>
    {icon && (
      <div className="toastify__icon">
        <Icon
          classes="toastify__icon__image"
          name={icon}
          size={size}
          svgProps={{ style: { fill: '#ffffff' } }}
        />
      </div>
    )}
    <div className="toastify__text">
      {headline && <h5 className="toastify__headline">{headline}</h5>}
      {body && <p className="toastify__copy">{body}</p>}
    </div>
  </>
);

export default HeadlineToast;
