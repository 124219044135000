import { API_URL, getHeaders } from '../config';

import { fetchHandler, fetchErrorHandler } from '../middleware';

export function getTopics(userContextData) {
  return fetch(
    `${API_URL}/csr/tags?orgid=${
      userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
    }`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}
