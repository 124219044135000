import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectHasActivityHistory } from 'common-js/reducers/releaseFlag/selectors';
import {
  BMPContactUsButton,
  BMPUpsellPanel,
  FullHeightCalculatorHOC,
  HoloHelmet,
} from 'common-js/components';
import SimSubMenu from '../../../sims/components/SubMenu';
import SubMenu from '../SubMenu';
import ActivityPanel from './ActivityPanel';

const Activity = ({ simInventory }) => {
  const hasActivityHistory = useAppSelector(selectHasActivityHistory);
  // we want the ActivityPanel to fill the height, but the BMPUpsellPanel to fit its contents
  const activityClassName = `Activity${hasActivityHistory ? '' : ' Activity--shrinkwrap-height'}`;

  return (
    <>
      <HoloHelmet title="Activity history" />
      {simInventory ? <SimSubMenu /> : <SubMenu />}
      <FullHeightCalculatorHOC accountForDrawer backgroundColor="#F2F3F5">
        <div className="container">
          <div className={activityClassName}>
            {hasActivityHistory ? (
              <ActivityPanel simInventory={simInventory} />
            ) : (
              <BMPUpsellPanel
                classes="Activity__panel"
                title="Activity history"
                label="Plus and Pro feature"
                headline="Monitor device history across our network"
                copy="To unlock activity history, upgrade your Hologram plan."
                buttons={
                  <BMPContactUsButton
                    variant="primary"
                    analyticsName="Activity History"
                    flagLevel="lite"
                  />
                }
              />
            )}
          </div>
        </div>
      </FullHeightCalculatorHOC>
    </>
  );
};

export default Activity;
