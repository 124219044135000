import { BackgroundGradient, Picture } from '@hologram-hyper-dashboard/components';
import analyticsEventBuilder from 'common-js/analytics';
import MarketoForm from 'common-js/components/MarketoForm';
import { DEVICES_DEVICES, withContext } from 'common-js/constants/paths';
import RegisterForm from 'common-js/forms/RegisterForm';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { updateUserSettings } from 'common-js/reducers/account/actions';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import submitDashboardMarketoForm from 'common-js/utils/marketing';
import dashboardImage from 'img/registration/hero/dashboard.webp?&imagetools';
import { useCallback, useEffect, useState, type FC } from 'react';
import { browserHistory } from 'react-router';
import Loading from './Loading';

const BODY_CLASS = 'signup' as const;
const BODY_CLASS_DEFAULT = 'register-default' as const;

interface RegisterProps {
  location: RouterLocation;
}

const Register: FC<RegisterProps> = ({ location }) => {
  const [success, setSuccess] = useState(false);
  const [loadingMarketo, setLoadingMarketo] = useState(true);

  const { inviteid, token, orgid } = location.query;
  const querySource = location.query.utm_source || 'dashboardDirectSignup';

  const dispatch = useAppDispatch();

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Register').send();

    document.body.classList.add(BODY_CLASS);
    document.body.classList.add(BODY_CLASS_DEFAULT);

    return () => {
      document.body.classList.remove(BODY_CLASS);
      document.body.classList.remove(BODY_CLASS_DEFAULT);
    };
  }, []);

  const successfulRegister = useCallback(
    (traits: any) => {
      // submit marketo form
      if (loadingMarketo) {
        submitDashboardMarketoForm(traits);
      } else {
        window.setTimeout(() => {
          submitDashboardMarketoForm(traits);
        }, 5000);
      }

      if (orgid) {
        const settings = {
          'Default Org': orgid,
        };
        dispatch(updateUserSettings(settings))
          .then(() => {
            dispatch(pushGlobalMessage('Default organization successfully set.', 'success'));
          })
          .catch((e) => {
            dispatch(pushGlobalMessage(e, 'error'));
          });
      }

      setSuccess(true);
      setTimeout(() => {
        browserHistory.push(
          withContext(
            DEVICES_DEVICES + window.location.search,
            orgid ? parseInt(orgid, 10) : undefined
          )
        );
      }, 3750);
    },
    [dispatch, loadingMarketo, orgid]
  );

  if (success) {
    return <Loading />;
  }

  return (
    <div className="page-register Register">
      <MarketoForm
        onFormReady={() => {
          setLoadingMarketo(false);
        }}
      />
      <BackgroundGradient
        backgroundGradient="b"
        overlayGrid
        pinTo="tablet"
        backgroundClass="Register_BackgroundImage-bg"
        className="Register__BackgroundImage"
      >
        <Picture image={dashboardImage} alt="IOT Collage" setDimensions={false} />
      </BackgroundGradient>
      <div className="Register__FormWrapper">
        <RegisterForm
          onSuccessfulRegister={successfulRegister}
          orgIdToJoin={orgid ? parseInt(orgid, 10) : undefined}
          orgInviteId={inviteid}
          orgInviteToken={token}
          passedValues={{ email: location.query.email }}
          source={querySource}
        />
      </div>
    </div>
  );
};

export default Register;
