import _classnames from 'clsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  CollapseButton,
  ClickOutside,
  Icon,
  DropdownPanel,
  DropdownSection,
} from '@holokit/core';

/**
 * This is a stateless version of the Holokit Dropdown.
 * Instead of keeping item checkbox state internally, it simply renders the items passed in faithfully, and
 * expects the parent rendering it to manage checkbox state as needed.
 */
class StatelessDropdown extends Component {
  static ensureActiveItemVisible = (item, panelId) => {
    // itemId format is hardcoded here and in DropdownSection component
    const itemId = `Section-${item?.sectionId}-item-${item?.id}`;
    const activeElement = document.getElementById(itemId);
    const panelElement = document.getElementById(panelId);

    if (activeElement && panelElement) {
      const panel = panelElement.getBoundingClientRect();
      const activeItem = activeElement.getBoundingClientRect();

      if (activeItem.bottom > panel.bottom) {
        const currentScroll = panelElement.scrollTop;
        panelElement.scrollTop = currentScroll + (activeItem.top - panel.top);
      }

      if (activeItem.top < panel.top) {
        const currentScroll = panelElement.scrollTop;
        const panelHeight = panel.bottom - panel.top;
        panelElement.scrollTop = currentScroll - panelHeight + (activeItem.bottom - activeItem.top);
      }
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      activeItemId: null,
      isOpen: false,
    };
  }

  handleKeyDown = (e, itemArray, panelId) => {
    const { activeItemId, isOpen } = this.state;

    if (e.keyCode === 13) {
      e.preventDefault();
      if (activeItemId === null || !isOpen) {
        this.togglePanel();
      } else {
        this.handleItemSelect(itemArray);
      }
    }

    if (e.keyCode === 27) {
      e.preventDefault();
      if (isOpen) {
        this.closePanel();
        this.setState({ activeItemId: null });
      }
    }

    if (itemArray) {
      if (e.keyCode === 40) {
        e.preventDefault();

        let remainingItems = [];
        if (activeItemId === null) {
          remainingItems = itemArray;
        } else if (activeItemId < itemArray.length - 1) {
          remainingItems = itemArray.slice(activeItemId + 1);
        }

        const nextItem = remainingItems.find((item) => !item.header && !item.disabled);
        if (nextItem) {
          this.setState(
            { activeItemId: nextItem.id },
            StatelessDropdown.ensureActiveItemVisible(itemArray[nextItem.id], panelId)
          );
        }
      }

      if (e.keyCode === 38 && activeItemId !== null) {
        e.preventDefault();

        if (activeItemId === 0) {
          this.setState({ activeItemId: null });
        } else {
          let newIndex = activeItemId - 1;
          while (
            newIndex !== null &&
            (itemArray[newIndex].header || itemArray[newIndex].disabled)
          ) {
            if (newIndex === 0) {
              newIndex = null;
            } else {
              newIndex -= 1;
            }
          }
          this.setState(
            { activeItemId: newIndex },
            StatelessDropdown.ensureActiveItemVisible(itemArray[newIndex], panelId)
          );
        }
      }
    }
  };

  handleItemSelect = (itemArray) => {
    const { activeItemId } = this.state;
    const itemId = itemArray[activeItemId].id;

    if (itemId !== null) {
      const itemSelected = itemArray.find((item) => item.id === itemId);
      if (itemSelected.onClick) {
        itemSelected.onClick(itemId);
      }
    }
  };

  handleRowHover = (id) => {
    const { activeItemId } = this.state;

    if (activeItemId !== id) {
      this.setState({ activeItemId: id });
    }
  };

  closePanel = () => {
    this.setState({ isOpen: false });
  };

  togglePanel = () => {
    const { onToggle } = this.props;
    const { isOpen } = this.state;

    if (isOpen) {
      this.setState({ activeItemId: null });
    }

    this.setState({ isOpen: !isOpen });

    if (onToggle) {
      onToggle({ isOpen: !isOpen });
    }
  };

  render() {
    const {
      align,
      buttonProps,
      checkbox,
      classes,
      collapseButton,
      buttonClasses,
      disabled,
      dropdownText,
      footer,
      footerIsButton,
      iconLeft,
      iconRightColor,
      inputCallback,
      inputPlaceholder,
      items,
      legacySegmentDropdown,
      loading,
      panelHasTopMargin,
      segmentDropdown,
      small,
      type,
      withTextInput,
    } = this.props;
    const { activeItemId, isOpen } = this.state;

    if (disabled && isOpen) {
      this.closePanel();
    }

    const itemArray = [];
    const sectionIds = [];
    if (items) {
      items.forEach((item, i) => {
        let itemIconRight = item.iconRight;
        let itemIconLeftGivenColor = item.iconLeftGivenColor;
        if (item.checkboxProps && !item.hasCheckbox) {
          if (item.checkboxProps.checked) {
            itemIconRight = 'Checkmark--single';
            itemIconLeftGivenColor = '#8008F7';
          }
        }

        itemArray.push({
          active: i === activeItemId,
          caption: item.caption,
          checkboxProps: item.checkboxProps,
          children: item.children,
          closeAfterClick: item.closeAfterClick,
          closePanel: this.closePanel,
          customItem: item.customItem,
          destructive: item.destructive,
          disabled: item.disabled,
          handleRowHover: this.handleRowHover,
          hasCheckbox: item.hasCheckbox,
          header: item.header,
          href: item.href,
          iconLeft: item.iconLeft,
          iconLeftGivenColor: itemIconLeftGivenColor,
          iconRight: itemIconRight,
          id: i,
          isLoading: item.isLoading,
          onClick: item.onClick,
          // because this component doesn't track state, this handler is a no-op
          // previously this would've been the handler which updates the checkedItems local state
          onCheckboxClick: () => {},
          sectionId: item.sectionId,
          small: item.small,
          supportingLabel: item.supportingLabel,
          type: item.type,
        });
        if (!sectionIds.includes(item.sectionId)) {
          sectionIds.push(item.sectionId);
        }
      });
    }

    const dropdownButtonProps = {
      checkbox,
      classes: buttonClasses,
      buttonProps,
      disabled,
      iconLeft,
      iconRight: 'Chevron--single--south',
      iconRightColor,
      loading,
      onClick: this.togglePanel,
      type,
      small,
    };

    let dropdownButton = <Button {...dropdownButtonProps}>{dropdownText}</Button>;
    if (segmentDropdown) {
      dropdownButton = (
        <Button
          classes={_classnames('Dropdown__button', buttonClasses)}
          onClick={this.togglePanel}
          loading={loading}
          disabled={disabled}
          type="secondary"
        >
          <div className="Dropdown__button__content">
            <div className="Dropdown__button__text">{dropdownText}</div>
            <Icon name="Chevron--northsouth" svgProps={{ style: { fill: '#8008F7' } }} />
          </div>
        </Button>
      );
    }
    if (legacySegmentDropdown) {
      dropdownButton = (
        <Button
          classes={_classnames('Dropdown__button', buttonClasses)}
          onClick={this.togglePanel}
          loading={loading}
          disabled={disabled}
          unstyled
        >
          <div className="Dropdown__button__content">
            <div className="Dropdown__button__text Dropdown__button__legacyText">
              {dropdownText}
            </div>
            {!disabled && (
              <div className="Dropdown__button__icon">
                <Icon
                  name="Chevron--single--south"
                  svgProps={{ style: { fill: isOpen ? '#8008F7' : '#969fb1' } }}
                />
              </div>
            )}
          </div>
        </Button>
      );
    }
    if (collapseButton) {
      dropdownButton = (
        <CollapseButton
          collapseAt={collapseButton.collapseAt}
          isCheckbox={checkbox}
          {...dropdownButtonProps}
        >
          {dropdownText}
        </CollapseButton>
      );
    }

    const panelId = `DropdownPanel-${dropdownText}-${Date.now()}`;

    return (
      <ClickOutside callback={this.closePanel}>
        <div
          className={_classnames('Dropdown', classes, {
            'Dropdown--rightalign': align === 'right',
            'Dropdown--segmented': segmentDropdown || legacySegmentDropdown,
            'Dropdown--legacySegmented': legacySegmentDropdown,
            'Dropdown--open': isOpen,
          })}
          onKeyDown={(e) => this.handleKeyDown(e, itemArray, panelId)}
          tabIndex="0"
          role="button"
        >
          {dropdownButton}
          <DropdownPanel
            bodyClasses={_classnames('Dropdown__panel__body', 'Dropdown__panel__body--open')}
            footer={footer}
            footerIsButton={footerIsButton}
            inputCallback={inputCallback}
            inputPlaceholder={inputPlaceholder}
            isOpen={isOpen && !disabled}
            panelHasTopMargin={panelHasTopMargin}
            panelId={panelId}
            withTextInput={withTextInput}
          >
            {sectionIds.map((section, i) => (
              <DropdownSection
                items={itemArray.filter((item) => item.sectionId === section)}
                lastSection={sectionIds.length === i + 1}
                key={`DropdownSection-${section}`}
              />
            ))}
          </DropdownPanel>
        </div>
      </ClickOutside>
    );
  }
}

StatelessDropdown.propTypes = {
  /**
   * Set to `'right'` to align the dropdown and its panel along the right edge.
   */
  align: PropTypes.string,
  /**
   * The Dropdown button is a Holokit `Button` component. Any additional `Button` props you would
   * like to pass to the button can be passed here.
   */
  buttonProps: PropTypes.instanceOf(Button),
  /**
   * If the Dropdown button should contain a checkbox, the checkbox node can be passed here.
   */
  checkbox: PropTypes.node,
  /**
   * A space-separated list of classes to be applied to the `div` wrapping the Dropdown component.
   */
  classes: PropTypes.string,
  /**
   * If the Dropdown button should collapse to accommodate varying screen widths, provide an
   * object with key `collapseAt` and a number value for the max screen width to accommodate.
   */
  // eslint-disable-next-line react/forbid-prop-types
  collapseButton: PropTypes.object,
  /**
   * A space-separated list of classes to be applied to the `Button` component.
   */
  buttonClasses: PropTypes.string,
  /**
   * Dropdown button is disabled and panel may not be opened.
   */
  disabled: PropTypes.bool,
  /**
   * The text inside the dropdown button.
   */
  dropdownText: PropTypes.string,
  /**
   * Footer displayed at the bottom of the `DropdownPanel`. Footer is sticky (always present while
   * content scrolls above) and does not include any particular format or styling within the
   * footer container.
   */
  footer: PropTypes.node,
  /**
   * Adds styles for centering a button within the footer. See the Tags Dropdown story for
   * an example.
   */
  footerIsButton: PropTypes.bool,
  /**
   * Optional left icon in Dropdown button.
   */
  iconLeft: PropTypes.string,
  /**
   * Customize the color of the carat icon in the Dropdown button with a hex value.
   */
  iconRightColor: PropTypes.string,
  /**
   * If the Dropdown panel contains an input (`withTextInput` prop), you may include a callback
   * which will run with the new input value as an argument any time the input value is changed.
   */
  inputCallback: PropTypes.func,
  /**
   * An array of items to include in the `DropdownPanel`. NOTE: Each item is normalized within the
   * `Dropdown` component and therefore not all `DropdownItem` props directly correspond to the
   * objects in the items array.
   *
   * Each item is an object that can include any of the following keys corresponding to
   * `DropdownItem` component props:
   *
   * `caption`
   *
   * `checkboxProps`
   *
   * `children`
   *
   * `closeAfterClick`
   *
   * `customItem`
   *
   * `destructive`
   *
   * `disabled`
   *
   * `hasCheckbox`
   *
   * `header`
   *
   * `href`
   *
   * `iconLeft`
   *
   * `iconRight`
   *
   * `isLoading`
   *
   * `onClick`
   *
   * `sectionId`
   *
   * `small`
   *
   * `supportingLabel`
   *
   * `type`
   */
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  /**
   * Applies legacy segmented dropdown styles to the Dropdown component (`segmentDropdown` prop now references updated styling).
   */
  legacySegmentDropdown: PropTypes.bool,
  /**
   * Applies a loading state to the dropdown button.
   */
  loading: PropTypes.bool,
  /**
   * Applies our updated segmented dropdown styles to the Dropdown component (see story example).
   */
  segmentDropdown: PropTypes.bool,
  /**
   * Applies our `small` button styles to the Dropdown button.
   */
  small: PropTypes.bool,
  /**
   * Applies any of our `Button` type styles to the Dropdown button.
   */
  type: PropTypes.oneOf([
    'primary',
    'transactional',
    'secondary',
    'destructiveMajor',
    'destructiveMinor',
    'reversed',
  ]),
  /**
   * Includes a text input at the top of the `DropdownPanel` component.
   */
  withTextInput: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  panelHasTopMargin: PropTypes.bool,
  /**
   * Handler called when the dropdown is opened or closed.
   */
  onToggle: PropTypes.func,
};

StatelessDropdown.defaultProps = {
  align: 'left',
  buttonProps: null,
  checkbox: null,
  collapseButton: null,
  buttonClasses: '',
  disabled: false,
  iconLeft: null,
  inputCallback: () => {},
  loading: false,
  type: 'secondary',
  withTextInput: false,
  inputPlaceholder: '',
  legacySegmentDropdown: false,
  panelHasTopMargin: false,
  classes: undefined,
  dropdownText: undefined,
  footer: undefined,
  footerIsButton: false,
  iconRightColor: undefined,
  items: [],
  segmentDropdown: undefined,
  small: false,
  onToggle: null,
};

export default StatelessDropdown;
