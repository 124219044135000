const PREFIX = 'ADMIN_' as const;

export const GET_ALLRELEASEFLAGS_REQUEST = `${PREFIX}GET_ALLRELEASEFLAGS_REQUEST` as const;
export const GET_ALLRELEASEFLAGS_SUCCESS = `${PREFIX}GET_ALLRELEASEFLAGS_SUCCESS` as const;
export const GET_ALLRELEASEFLAGS_ERROR = `${PREFIX}GET_ALLRELEASEFLAGS_ERROR` as const;

export const POST_RELEASEFLAG_PUBLIC_REQUEST = `${PREFIX}POST_RELEASEFLAG_PUBLIC_REQUEST` as const;
export const POST_RELEASEFLAG_PUBLIC_SUCCESS = `${PREFIX}POST_RELEASEFLAG_PUBLIC_SUCCESS` as const;
export const POST_RELEASEFLAG_PUBLIC_ERROR = `${PREFIX}POST_RELEASEFLAG_PUBLIC_ERROR` as const;

export const DELETE_RELEASEFLAG_PUBLIC_REQUEST =
  `${PREFIX}DELETE_RELEASEFLAG_PUBLIC_REQUEST` as const;
export const DELETE_RELEASEFLAG_PUBLIC_SUCCESS =
  `${PREFIX}DELETE_RELEASEFLAG_PUBLIC_SUCCESS` as const;
export const DELETE_RELEASEFLAG_PUBLIC_ERROR = `${PREFIX}DELETE_RELEASEFLAG_PUBLIC_ERROR` as const;

export const PUT_RELEASEFLAG_FOR_ORG_REQUEST = `${PREFIX}POST_RELEASEFLAG_FOR_ORG_REQUEST` as const;
export const PUT_RELEASEFLAG_FOR_ORG_SUCCESS = `${PREFIX}POST_RELEASEFLAG_FOR_ORG_SUCCESS` as const;
export const PUT_RELEASEFLAG_FOR_ORG_ERROR = `${PREFIX}POST_RELEASEFLAG_FOR_ORG_ERROR` as const;

export const IMPERSONATE_REQUEST = `${PREFIX}IMPERSONATE_REQUEST` as const;
export const IMPERSONATE_SUCCESS = `${PREFIX}IMPERSONATE_SUCCESS` as const;
export const IMPERSONATE_ERROR = `${PREFIX}IMPERSONATE_ERROR` as const;

export const EXIT_IMPERSONATE_REQUEST = `${PREFIX}EXIT_IMPERSONATE_REQUEST` as const;
export const EXIT_IMPERSONATE_SUCCESS = `${PREFIX}EXIT_IMPERSONATE_SUCCESS` as const;
export const EXIT_IMPERSONATE_ERROR = `${PREFIX}EXIT_IMPERSONATE_ERROR` as const;

export const ORGSEARCH_REQUEST = `${PREFIX}ORGSEARCH_REQUEST` as const;
export const ORGSEARCH_SUCCESS = `${PREFIX}ORGSEARCH_SUCCESS` as const;
export const ORGSEARCH_ERROR = `${PREFIX}ORGSEARCH_ERROR` as const;

export const GET_ALLCARRIERS_REQUEST = `${PREFIX}GET_ALLCARRIERS_REQUEST` as const;
export const GET_ALLCARRIERS_SUCCESS = `${PREFIX}GET_ALLCARRIERS_SUCCESS` as const;
export const GET_ALLCARRIERS_ERROR = `${PREFIX}GET_ALLCARRIERS_ERROR` as const;
