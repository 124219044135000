import React, { useCallback, useEffect, useState, useMemo } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { UploadArea } from '@holokit/core';
import { Panel } from '@hologram-dimension/panel';
import { Icon } from '@hologram-dimension/icon';
import { useFormContext } from 'react-hook-form';
import { FIND_SIM_ICCIDS_HELP } from 'common-js/constants/links';
import analyticsEventBuilder from 'common-js/analytics';
import { updateTotalDueToday } from 'common-js/reducers/activation/actions/actions';
import { calculateTotalDueToday } from 'common-js/reducers/activation/actions/calculateTotalDueToday';
import {
  getSimsFromCSV,
  clearIndividualSimSelection,
  validateSims,
} from 'common-js/reducers/activation/actions';
import { selectSimErrors } from 'common-js/reducers/activation/selectors';
import SimListDisplay from './SimListDisplay';
import { getDuplicateSimsWarning } from './SimUtils';

const ACTIVATION_TYPE = 'list';

function ListOfSimsPanel({ hasBillingPermissions }) {
  const [isFileImported, setIsFileImported] = useState(false);
  const [uploadAreaError, setUploadAreaError] = useState('');
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [uploadMethod, setUploadMethod] = useState(null);

  const { setValue, getValues, watch } = useFormContext();
  const simErrors = useAppSelector(selectSimErrors);
  const dispatch = useAppDispatch();
  const totalSimsInForm = watch('sims.total');

  const { availableBalance } = useAppSelector((state) => state?.organization?.balance);

  const [duplicateSims, setDuplicateSims] = useState([]);
  const duplicateSimsList = useMemo(() => getDuplicateSimsWarning(duplicateSims), [duplicateSims]);

  const clearSims = useCallback(() => {
    dispatch(clearIndividualSimSelection());
    dispatch(updateTotalDueToday(null));
    setIsFileImported(false);
    setDuplicateSims([]);
    setValue('sims.sims', []);
    setValue('sims.total', 0);
  }, [dispatch, setValue]);

  useEffect(() => {
    setValue('sims.inputOptionUsed', ACTIVATION_TYPE);
  }, [setValue]);

  useEffect(() => {
    if (totalSimsInForm === 0) clearSims();
  }, [clearSims, totalSimsInForm]);

  const validateUploadedSims = useCallback(
    async (sims, unSetFileImported = false) => {
      const simConfiguration = { plan: {}, sims, invalidatePreflightSims: true };
      const response = await dispatch(validateSims(simConfiguration));
      const { totalValidSims: numberOfValidSims, validSims } = response;

      if (numberOfValidSims > 0) {
        setValue('sims.sims', validSims);
        setValue('sims.total', numberOfValidSims);

        const config = {
          sims: getValues('sims.sims'),
          plan: {},
          type: ACTIVATION_TYPE,
          excludeSims: duplicateSimsList,
          invalidatePreflightSims: true,
        };
        if (!hasBillingPermissions) {
          await dispatch(calculateTotalDueToday(config));
        }
      }

      // Because file data doesn't persist across step movement, we need to set this to false so the user can upload a new file on top of it, otherwise they get stuck
      setIsFileImported(!unSetFileImported);
    },
    [dispatch, duplicateSimsList, getValues, hasBillingPermissions, setValue]
  );

  useEffect(() => {
    // If we're coming back to the SIM step, revalidate
    if (totalSimsInForm > 0) {
      const sims = getValues('sims.sims');
      clearSims();
      setTimeout(() => validateUploadedSims(sims, true), 0);
    }

    // One time only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSims = useCallback(
    async (fileData) => {
      analyticsEventBuilder
        .buttonClick('Activation', 'Returning', 'Activation Import SIMs from CSV')
        .send();

      clearSims();
      const simNumbers = await dispatch(getSimsFromCSV(fileData));
      const dedupedSims = [];
      const dupes = [];
      if (!simNumbers || simNumbers?.length === 0) {
        const errorMessage =
          "Sorry, we didn't find any SIM numbers in your file. Please use the CSV template above.";
        analyticsEventBuilder
          .errorReturn('Activation', 'Returning', 'Activation Error', 'Import Failure')
          .send({
            'Error message': errorMessage,
            'Number of files imported': selectedFileData.length ? selectedFileData.length : 1,
            'File extension': selectedFileData.name.split('.').pop(),
            'File size': selectedFileData.size,
          });
        setUploadAreaError(errorMessage);
        return;
      }
      simNumbers.forEach((sim) => {
        if (!dedupedSims.includes(sim)) dedupedSims.push(sim);
        else if (!dupes.includes(sim)) dupes.push(sim);
      });
      setDuplicateSims(dupes);

      validateUploadedSims(dedupedSims);
    },
    [
      clearSims,
      dispatch,
      selectedFileData?.length,
      selectedFileData?.name,
      selectedFileData?.size,
      validateUploadedSims,
    ]
  );

  const onFileDrop = useCallback(() => {
    setUploadMethod('Drag and drop');
  }, []);

  const onSelectFile = useCallback(() => {
    setUploadMethod('Search and select');
  }, []);

  const onReplaceFile = useCallback(() => {
    setUploadMethod('Search and select');
    analyticsEventBuilder
      .buttonClick('Activation', 'Returning', 'Activation Replace CSV File')
      .send();
  }, []);

  const onRemoveFile = useCallback(() => {
    analyticsEventBuilder
      .buttonClick('Activation', 'Returning', 'Activation Remove CSV File')
      .send({
        'File size': selectedFileData?.size,
      });
    clearSims();
  }, [clearSims, selectedFileData?.size]);

  const onSelectNewFile = useCallback(() => {
    setUploadMethod('Search and select');
    analyticsEventBuilder
      .buttonClick('Activation', 'Returning', 'Activation Select New CSV')
      .send();
  }, []);

  const onFileChange = useCallback(
    (fileData) => {
      if (fileData?.name !== selectedFileData?.name) {
        setUploadAreaError('');
      }
      setSelectedFileData(fileData);
      if (!fileData) return;
      analyticsEventBuilder.fileUpload('Activation', 'Returning', 'Activation CSV Upload').send({
        'Upload method': uploadMethod,
        'File size in bytes': fileData.size,
      });
    },
    [selectedFileData?.name, uploadMethod]
  );

  const handleDownloadTemplateClick = useCallback(() => {
    analyticsEventBuilder
      .buttonClick('Activation', 'Returning', 'Activation CSV Template Download')
      .send();
  }, []);

  const onMultipleFiles = useCallback((numberOfFiles) => {
    analyticsEventBuilder
      .errorReturn('Activation', 'Returning', 'Activation Error', 'Cannot Read Multi Files')
      .send({ 'Number of files uploaded': numberOfFiles });
  }, []);

  const onFileTypeError = useCallback((fileExtension) => {
    analyticsEventBuilder
      .errorReturn('Activation', 'Returning', 'Activation Error', 'Unsupported File Type')
      .send({ 'File type': fileExtension });
  }, []);

  return (
    <Panel
      header="Upload CSV"
      className="SimPanel"
      footer={
        <a href={FIND_SIM_ICCIDS_HELP} target="_blank" rel="noreferrer">
          Where do I find ICCIDs for my SIMs?
          <Icon name="ExternalLink" size="small" fill="DdsColorInteractivePrimaryDefault" />
        </a>
      }
      footerVariant="alternative"
    >
      <div className="SimPanel__content">
        <div className="ListOfSims__explanation">
          To successfully upload a CSV file:
          <ul className="ListOfSims__explanation__list">
            <li>
              <a
                href="https://cdn1.hologram.io/dashboard/ICCID+list+for+activation.csv"
                rel="noreferrer"
                target="_blank"
                onClick={handleDownloadTemplateClick}
              >
                Download this template
              </a>
            </li>
            <li>Upload a saved CSV containing ICCIDs for all your SIMs</li>
          </ul>
        </div>
        <UploadArea
          onUpload={setSims}
          actionText="Import SIMs from file"
          onRemoveFile={onRemoveFile}
          uploadSuccessful={(isFileImported && totalSimsInForm > 1) || uploadAreaError}
          infoText="Select your CSV file or drag and drop here."
          acceptFileType="text/csv"
          uploadError={uploadAreaError}
          fileNotCompatible={uploadAreaError}
          onFileChange={onFileChange}
          onDrop={onFileDrop}
          onSelectFile={onSelectFile}
          onReplace={onReplaceFile}
          onSelectNew={onSelectNewFile}
          onMultipleFiles={onMultipleFiles}
          onFileTypeError={onFileTypeError}
        />

        <hr className="SimPanel__content__line" />

        <SimListDisplay
          simErrors={simErrors}
          duplicateSimsList={duplicateSimsList}
          hasBillingPermissions={hasBillingPermissions}
          availableBalance={availableBalance}
        />
      </div>
    </Panel>
  );
}

export default ListOfSimsPanel;
