import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Checkbox } from '@holokit/core';
import { clearSelection, toggleFilter, toggleFilters } from 'common-js/reducers/devices/actions';
import { getFiltersByStateSegment } from 'common-js/reducers/deviceFilter/selectors';
import { getFilters } from 'common-js/reducers/devices/selectors';
import { getDeviceStatusCode, getDeviceStatusText } from 'common-js/utils/deviceStatus';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

class StatusFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTruncated: true,
      updateAccordionHeight: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { statuses } = this.props;

    if (prevProps.statuses.length && statuses.length !== prevProps.statuses.length) {
      this.resetAccordionHeight();
    }
  }

  handleExpand = () => {
    this.setState({
      isTruncated: false,
      updateAccordionHeight: true,
    });
  };

  handleFilterChange = (status) => {
    const { clearSelection_, toggleFilter_, toggleFilters_ } = this.props;

    clearSelection_();

    const filterToApply = getDeviceStatusCode(status);
    if (typeof filterToApply === 'string') {
      toggleFilter_('state', filterToApply);
    } else {
      toggleFilters_('state', filterToApply);
    }
  };

  resetAccordionHeight = () => {
    this.setState({ updateAccordionHeight: true });
  };

  updateAccordionHeightCallback = () => {
    this.setState({ updateAccordionHeight: false });
  };

  render() {
    const { filters, statuses, defaultOpen } = this.props;
    const { isTruncated, updateAccordionHeight } = this.state;
    const hasStatuses = !!statuses.length;

    if (!hasStatuses) return null;

    const isCollapsed = isTruncated && statuses.length > 7;
    const statusesToDisplay = hasStatuses && isCollapsed ? statuses.slice(0, 7) : statuses;

    return (
      <div className="DevicesFilter">
        <Accordion
          header="Status"
          iconLeft="CellTower"
          isFiltered={'state' in filters}
          updateAccordionHeight={updateAccordionHeight}
          updateAccordionHeightCallback={this.updateAccordionHeightCallback}
          defaultOpen={defaultOpen}
          accordionToggleCallback={({ open }) => {
            sendAnalyticsEvent({
              type: analyticsTypes.FILTERS_TOGGLE_ACCORDION,
              data: {
                accordion_name: 'Status Filter',
                accordion_open: open,
              },
            });
          }}
        >
          {hasStatuses && (
            <>
              <ul className="DevicesFilter__list">
                {statusesToDisplay.map((status) => {
                  const active = filters.state?.[status.value];
                  return (
                    <li
                      className="DevicesFilter__list-item"
                      key={`${status.value}${status.num_devices}`}
                    >
                      <Checkbox
                        classes="DevicesFilter__checkbox DevicesFilter__checkbox--titlecase"
                        checked={active}
                        onChange={() => {
                          this.handleFilterChange(status.displayName);
                          sendAnalyticsEvent({
                            type: analyticsTypes.FILTERS_SET_FILTER,
                            data: {
                              filter_name: 'Status Filter',
                              filter_value: status.displayName,
                            },
                          });
                        }}
                        textLabel={status.displayName}
                        name={status.displayName}
                      />
                    </li>
                  );
                })}
              </ul>
              {isCollapsed && (
                <Button
                  classes="DevicesFilter__expand-toggle Button--minimal"
                  onClick={this.handleExpand}
                >
                  See all {statuses.length} Statuses
                </Button>
              )}
            </>
          )}
        </Accordion>
      </div>
    );
  }
}

StatusFilter.defaultProps = {
  statuses: [],
};

StatusFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  statuses: PropTypes.array,
  defaultOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const statuses = getFiltersByStateSegment(state);
  const addedStatuses = [];
  const filteredStatuses = [];
  statuses.forEach((singleStatus) => {
    const displayName = getDeviceStatusText(singleStatus.value);
    if (!addedStatuses.includes(displayName)) {
      filteredStatuses.push({
        ...singleStatus,
        displayName,
      });
    }
    addedStatuses.push(displayName);
  });

  return {
    filters: getFilters(state),
    statuses: filteredStatuses,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      toggleFilter_: toggleFilter,
      toggleFilters_: toggleFilters,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StatusFilter);
