import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import TopMenuComponent from 'common-js/components/menu/TopMenu';
import {
  ACTIONS_ALERTS_DEVICE_TACCHANGE,
  HOME_OVERVIEW,
  withContext,
} from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectHasEureka0Flag } from 'common-js/reducers/releaseFlag/selectors';

interface TopMenuProps {
  hasAlertType?: boolean;
}

const TopMenu: React.FC<TopMenuProps> = ({ hasAlertType = false }) => {
  const hasInFlight = useAppSelector(selectHasEureka0Flag);

  return (
    <TopMenuComponent
      rightContent={<SubMenuRightContent />}
      classModifier={!hasAlertType ? '' : 'home'}
    >
      <>
        <TopMenuComponent.Item
          text="Home"
          href={withContext(HOME_OVERVIEW)}
          active={!hasAlertType}
        />
        {hasInFlight && (
          <TopMenuComponent.Item
            text="Action items"
            href={withContext(ACTIONS_ALERTS_DEVICE_TACCHANGE)}
            active={hasAlertType}
          />
        )}
      </>
    </TopMenuComponent>
  );
};

export default TopMenu;
