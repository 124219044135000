import React from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { useFormContext } from 'react-hook-form';
import analyticsEventBuilder from 'common-js/analytics';
import Footer, { ContinueButton, BackButton, SkipButton } from '../common/scaffolding/Footer';

function AutoRefillFooter({ nextStep, prevStep, skipStep, isOnboarding }) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const { enabled, amountToAdd, threshold } = getValues('autoRefillParams');

  const isDisabled = !enabled || !amountToAdd || !threshold || !!errors.autoRefillParams;

  const balance = useAppSelector((state) => state.organization.balance);
  const isAutoRefillEnabled = !!balance.minBalance && !!balance.topOffAmount;

  const handleContinueClick = () => {
    const amountAddValue = getValues('autoRefillParams.amountToAdd');
    const thresholdValue = getValues('autoRefillParams.threshold');
    analyticsEventBuilder
      .dataEntry(
        'Activation',
        isOnboarding ? '1st Time' : 'Returning',
        'Activation Autorefill Condition Setting'
      )
      .send({
        'Amount add value': amountAddValue,
        'Threshold value': thresholdValue,
      });
    nextStep();
  };

  return (
    <Footer
      left={<BackButton onClick={prevStep} />}
      right={
        <>
          {!isAutoRefillEnabled && <SkipButton onClick={skipStep} />}
          <ContinueButton onClick={handleContinueClick} disabled={isDisabled} />
        </>
      }
    />
  );
}

export default AutoRefillFooter;
