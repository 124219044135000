import * as actionTypes from './actionTypes';

export const initialState = {
  flags: {},
  loadingFlags: false,
  impersonator: {
    userId: null,
    email: null,
  },
  orgResults: [],
  error: null,
  impersonateProcessing: false,
  searchProcessing: false,
  carriers: [],
};

// eslint-disable-next-line default-param-last
function adminReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.POST_RELEASEFLAG_PUBLIC_SUCCESS: {
      const name = action.flags[0];
      const flag = state.flags[name] || {};
      flag.public = 1;
      return { ...state, flags: { ...state.flags, flag } };
    }

    case actionTypes.DELETE_RELEASEFLAG_PUBLIC_SUCCESS: {
      const flag = state.flags[action.flags.name] || {};
      flag.public = 0;
      return { ...state, flags: { ...state.flags, flag } };
    }

    case actionTypes.GET_ALLRELEASEFLAGS_REQUEST: {
      return {
        ...state,
        flags: [],
        loadingFlags: true,
      };
    }

    case actionTypes.GET_ALLRELEASEFLAGS_SUCCESS: {
      return {
        ...state,
        flags: { ...action.flags },
        loadingFlags: false,
      };
    }

    case actionTypes.PUT_RELEASEFLAG_FOR_ORG_SUCCESS: {
      return {
        ...state,
        flags: {
          ...state.flags,
          [action.flag]: {
            ...state.flags[action.flag],
            organizations: action.orgs,
          },
        },
      };
    }

    case actionTypes.ORGSEARCH_REQUEST: {
      return {
        ...state,
        error: null,
        searchProcessing: true,
      };
    }

    case actionTypes.ORGSEARCH_SUCCESS: {
      return {
        ...state,
        orgResults: action.orgs,
        searchProcessing: false,
      };
    }

    case actionTypes.ORGSEARCH_ERROR: {
      return {
        ...state,
        searchProcessing: false,
        error: action.error,
      };
    }

    case actionTypes.IMPERSONATE_REQUEST: {
      return {
        ...state,
        error: null,
        impersonateProcessing: true,
      };
    }

    case actionTypes.IMPERSONATE_SUCCESS: {
      return {
        ...state,
        impersonateProcessing: false,
      };
    }

    case actionTypes.IMPERSONATE_ERROR: {
      return {
        ...state,
        impersonateProcessing: false,
        error: action.error,
      };
    }

    case actionTypes.GET_ALLCARRIERS_SUCCESS: {
      return {
        ...state,
        carriers: action.carriers,
      };
    }

    case actionTypes.GET_ALLCARRIERS_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    default:
      return state;
  }
}

export default adminReducer;
