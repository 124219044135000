import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { Button, Icon, Panel } from '@holokit/core';

import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import * as Paths from 'common-js/constants/paths';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { selectRoutesFlag } from 'common-js/reducers/releaseFlag/selectors';
import {
  getAllAppData,
  addSelectedTopic,
  clearAllAppSelections,
  enableApp,
  disableApp,
} from 'common-js/reducers/apps/actions';
import { minimizeDrawer } from 'common-js/reducers/drawer/actions';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { HoloHelmet, Topic } from 'common-js/components';
import { AppChainItem } from '../../apps/components';

class Routes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingRoutes: true,
    };

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Single Device - Routes' },
    });
  }

  componentDidMount() {
    const { getAllAppData_, routesReleaseFlag } = this.props;

    if (!routesReleaseFlag) {
      browserHistory.push(Paths.ACCOUNT_LOGIN);
    }

    getAllAppData_()
      .then(() => {
        this.setState({ isLoadingRoutes: false });
      })
      .catch(() => {
        this.setState({ isLoadingRoutes: false });
      });
  }

  onForkClick(topic) {
    const { addSelectedTopic_, clearAllAppSelections_, minimizeDrawer_ } = this.props;

    minimizeDrawer_(false);
    clearAllAppSelections_();
    addSelectedTopic_({ name: topic });
    browserHistory.push(Paths.APPS_ADD);
  }

  getRouteGroups(type) {
    const { device, appChains } = this.props;
    const DEVICE_TOPIC = `_DEVICE_${device.id}_`;

    // find all topics that relate to this device topic
    // also tag topics
    let routeGroups = [];
    if (type === 'device') {
      routeGroups = [
        {
          topic: `_DEVICE_${device.id}_`,
          appChains: appChains.filter((appChain) => appChain.apps[0].tags.includes(DEVICE_TOPIC)),
        },
      ];
    } else {
      routeGroups = routeGroups.concat(
        device.tags.map((tagId) => ({
          topic: `_TAG_${tagId}_`,
          appChains: appChains.filter((appChain) =>
            appChain.apps[0].tags.includes(`_TAG_${tagId}_`)
          ),
        }))
      );
    }
    return routeGroups;
  }

  render() {
    const { isLoadingRoutes } = this.state;
    const { device, disableApp_, enableApp_, pushGlobalMessage_, appSchemas } = this.props;

    return (
      <div className="page-device-message full-height">
        <HoloHelmet title={`${device.name} - Data Routes`} />
        <Panel title="Routes for This Device" isLoading={isLoadingRoutes}>
          <div className="section">
            <div className="section-row">
              <div className="full-height">
                <div className="section">
                  {this.getRouteGroups('device').map((routeGroup, idx) => (
                    <div className="section" key={idx}>
                      <div className="routes-section section grid-row v-align">
                        <div className="routes-subscribed">
                          <span className="f2 h-section">Subscribed to</span>
                          <div className="topics">
                            <Topic name={routeGroup.topic} />
                            <div
                              className="fork-button"
                              onClick={() => this.onForkClick(routeGroup.topic)}
                            >
                              <Icon name="Arrow--branch" size="major" />
                            </div>
                          </div>
                        </div>
                        <div className="new-route">
                          <Button
                            onClick={() => this.onForkClick(routeGroup.topic)}
                            type="secondary"
                          >
                            Add Route
                          </Button>
                        </div>
                      </div>
                      {routeGroup.appChains.length ? (
                        routeGroup.appChains.map((appChain, i) => (
                          <AppChainItem
                            key={i}
                            appChain={appChain}
                            appSchemas={appSchemas}
                            disableApp={disableApp_}
                            enableApp={enableApp_}
                            pushGlobalMessage={pushGlobalMessage_}
                          />
                        ))
                      ) : (
                        <div className="routes-empty">No routes added</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Panel>
        <Panel title="Routes for Attached tags" isLoading={isLoadingRoutes}>
          <div className="section">
            <div className="section-row">
              <div className="full-height">
                <div className="section">
                  {this.getRouteGroups('tag').map((routeGroup, idx) => (
                    <div className="section" key={idx}>
                      <div className="routes-section section grid-row v-align">
                        <div className="routes-subscribed">
                          <span className="f2 h-section">Subscribed to</span>
                          <div className="topics">
                            <Topic name={routeGroup.topic} />
                            <div
                              className="fork-button"
                              onClick={() => this.onForkClick(routeGroup.topic)}
                            >
                              <Icon name="Arrow--branch" size="major" />
                            </div>
                          </div>
                        </div>
                        <div className="new-route">
                          <Button
                            onClick={() => this.onForkClick(routeGroup.topic)}
                            type="secondary"
                          >
                            Add Route
                          </Button>
                        </div>
                      </div>
                      {routeGroup.appChains.length ? (
                        routeGroup.appChains.map((appChain, i) => (
                          <AppChainItem
                            key={i}
                            appChain={appChain}
                            appSchemas={appSchemas}
                            disableApp={disableApp_}
                            enableApp={enableApp_}
                            pushGlobalMessage={pushGlobalMessage_}
                          />
                        ))
                      ) : (
                        <div className="routes-empty">No routes added</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    appChains: state.apps.appChains,
    appSchemas: state.apps.appSchemas,
    routesReleaseFlag: selectRoutesFlag(state),
    searchBy: state.apps.searchBy,
    sortBy: state.apps.sortBy,
    topics: state.topic.allTopics,
    device: selectCurrentDevice(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        addSelectedTopic_: addSelectedTopic,
        clearAllAppSelections_: clearAllAppSelections,
        disableApp_: disableApp,
        enableApp_: enableApp,
        getAllAppData_: getAllAppData,
        minimizeDrawer_: minimizeDrawer,
        pushGlobalMessage_: pushGlobalMessage,
      },
      dispatch
    )
)(Routes);
