import { createSelector } from 'reselect';
import Moment from 'moment-timezone';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';

const EMPTY_LABEL_VALUE = '—';

// 1. Connected right now with open session: "Right Now" with session start time (hide end time)
// 2. Connected recently with closed session: "X mins/hours/days ago" (from session end) with session start/end time.
// 3. Never connected: empty state with — for start/end times. (hide end time just show start dash)
const selectLastConnectedText = createSelector(selectCurrentDevice, (device) => {
  if (!device.lastsession) return EMPTY_LABEL_VALUE;
  const sessionBegin = device.lastsession.session_begin;
  const sessionEnd = device.lastsession.session_end;

  if (sessionBegin && !sessionEnd) {
    return 'Right Now';
  }
  if (sessionBegin && sessionEnd) {
    if (Moment(sessionBegin).isValid() && Moment(sessionEnd).isValid()) {
      return Moment.utc(sessionEnd).local().fromNow();
    }
    return EMPTY_LABEL_VALUE;
  }
  return EMPTY_LABEL_VALUE;
});

export default selectLastConnectedText;
