import React from 'react';
import submitDashboardMarketoForm from 'common-js/utils/marketing';
import { useFormContext } from 'react-hook-form';
import { IconCard } from '../../components';

function Step5(props) {
  const { handleAnalyticsCall, nextStep } = props;
  const { setValue, getValues } = useFormContext();
  const handleClick = (coverageType) => () => {
    setValue('coverageRequested', coverageType);
    handleAnalyticsCall('coverageRequested', coverageType);
    submitDashboardMarketoForm(getValues());
    nextStep();
  };

  return (
    <div className="Step NewForm">
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__Header">
            <h2>What type of coverage do you need?</h2>
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__SubHeader">
            Please select the option that best represents your needs
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q5_2g.svg"
            onClick={handleClick('2G')}
            mainText="2G"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q5_3g.svg"
            onClick={handleClick('3G')}
            mainText="3G"
          />
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q5_4g.svg"
            onClick={handleClick('4G')}
            mainText="4G"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q5_lte.svg"
            onClick={handleClick('LTE')}
            mainText="LTE"
          />
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q5_cat-m1.svg"
            onClick={handleClick('CAT-M1')}
            mainText="CAT - M1"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q5_nb-iot.svg"
            onClick={handleClick('NB-IoT')}
            mainText="NB - IoT"
          />
        </div>
      </div>
      <div className="NewForm__BottomRow">
        <div className="NewForm__DoubleColumn">
          <IconCard mainText="Not sure" onClick={handleClick()} />
        </div>
      </div>
    </div>
  );
}

export default Step5;
