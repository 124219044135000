import React from 'react';
import Moment from 'moment-timezone';
import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { BILLING_HISTORY_VIEW_ALL_BALANCE_HISTORY } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { PanelNotification } from '@hologram-dimension/panel-notification';

class BalanceHistory extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isViewingAllBalanceHistory: false,
    };
  }

  getViewedBalanceHistory() {
    const { isViewingAllBalanceHistory } = this.state;
    const { balanceHistory } = this.props;

    if (!isViewingAllBalanceHistory) {
      return balanceHistory.filter((bhis, idx) => idx <= 5);
    }
    return balanceHistory;
  }

  render() {
    const { balanceHistory } = this.props;
    const { isViewingAllBalanceHistory } = this.state;

    return (
      <div className="BalanceHistory">
        <Panel header="Transaction history" noContentPadding>
          {balanceHistory.length === 0 ? (
            <PanelNotification label="No balance history.">
              Balance history will be logged after applying a promo or adding balance.
            </PanelNotification>
          ) : (
            <div>
              <table className="UsageTable BalanceHistoryTable">
                <thead>
                  <tr className="header">
                    <th>Date & time (UTC)</th>
                    <th>description</th>
                    <th>amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.getViewedBalanceHistory().map((bhist) => (
                    <tr className="body-data" key={bhist.id}>
                      <td>{Moment.utc(bhist.time).format('MM-DD-YY HH:mm:ss')}</td>
                      <td className="desc" style={{ width: '70%' }}>
                        {bhist.description}
                      </td>
                      <td style={{ width: '10%' }}>
                        {bhist.amount < 0 ? (
                          <>&minus;{`${bhist.amount.replace('-', '$')}`}</>
                        ) : (
                          `$${bhist.amount}`
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {balanceHistory.length > 5 && (
                <div className="more-btn">
                  {isViewingAllBalanceHistory ? (
                    <Button
                      onClick={() => {
                        sendAnalyticsEvent({
                          type: BILLING_HISTORY_VIEW_ALL_BALANCE_HISTORY,
                        });
                        this.setState({ isViewingAllBalanceHistory: false });
                      }}
                      variant="secondary"
                    >
                      Collapse list
                    </Button>
                  ) : (
                    <Button
                      onClick={() => this.setState({ isViewingAllBalanceHistory: true })}
                      variant="secondary"
                    >
                      View all
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

export default BalanceHistory;
