import React from 'react';
import _classnames from 'clsx';

function ActiveDeviceOverview({ data }) {
  const showPreflight = data.total_preflight > 0;
  const showDeactivated = data.total_deactivated > 0;

  return (
    <div
      className={_classnames('ActiveDeviceOverview', {
        'ActiveDeviceOverview--wrap': showPreflight && showDeactivated,
      })}
    >
      <div className="ActiveDeviceOverview__section h1">
        {data.total}
        <div className="type-micro uppercase type-weight-med">total sims</div>
      </div>
      <div className="ActiveDeviceOverview__section h1">
        {data.total_active}
        <div className="type-micro uppercase type-weight-med">active sims</div>
      </div>
      {showPreflight ? (
        <div className="ActiveDeviceOverview__section h1">
          {data.total_preflight}
          <div className="type-micro uppercase type-weight-med">preflight sims</div>
        </div>
      ) : null}
      {showDeactivated ? (
        <div className="ActiveDeviceOverview__section h1">
          {data.total_deactivated}
          <div className="type-micro uppercase type-weight-med">deactivated sims</div>
        </div>
      ) : null}
      <div className="ActiveDeviceOverview__section h1">
        {data.new}
        <div className="type-micro uppercase type-weight-med">new sims</div>
      </div>
      <div className="ActiveDeviceOverview__section h1">
        {data.using_data}
        <div className="type-micro uppercase type-weight-med">sims using data</div>
      </div>
    </div>
  );
}

ActiveDeviceOverview.defaultProps = {
  data: {},
};

export default ActiveDeviceOverview;
