import { Button, Loader } from '@holokit/core';
import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import useGetDataUsed from 'common-js/api/usage/useGetDataUsed';
import useGetDeviceBreakdownInspect from 'common-js/api/usage/useGetDeviceBreakdownInspect';
import { DateTagFilter, HoloHelmet } from 'common-js/components';
import * as Paths from 'common-js/constants/paths';
import { setCustomDate, setTimeQuickFilter } from 'common-js/reducers/currentDevice/actions';
import {
  selectCurrentDevice,
  selectCurrentDeviceChartFilters,
  selectCurrentDeviceTimeFilter,
  selectCurrentDeviceTimeQuickFilter,
} from 'common-js/reducers/currentDevice/selectors';
import useGetAllDeviceData from 'device/useGetAllDeviceData';
import React, { useCallback, useEffect, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { browserHistory } from 'react-router';
import InspectDrawer from 'usage/components/InspectDrawer';
import { DataUsedChart } from 'usage/containers/parts';
import DeviceBreakdownTableInspect from './DeviceBreakdownTableInspect';
import exportData from './exportData';

const InspectDataUsed = ({ params: { deviceId } }) => {
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [runningReport, setRunningReport] = useState(false);

  const [dataUsed, setDataUsed] = useState([]);
  const [dataUsedLoading, setDataUsedLoading] = useState(true);
  const [dataUsedError, setDataUsedError] = useState(null);

  const [deviceBreakdownData, setDeviceBreakdownData] = useState([]);
  const [deviceBreakdownLoading, setDeviceBreakdownLoading] = useState(true);

  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const timeFilter = useAppSelector(selectCurrentDeviceTimeFilter);
  const timeQuickFilter = useAppSelector(selectCurrentDeviceTimeQuickFilter);
  const reportFilters = useAppSelector((state) => selectCurrentDeviceChartFilters(state, deviceId));

  const getDataUsed = useGetDataUsed();
  const getDeviceBreakdownInspect = useGetDeviceBreakdownInspect();

  const startDate = new Date(timeFilter.startDate);
  const endDate = new Date(timeFilter.endDate);

  const exportCSV = () => {
    exportData({
      apiCall: getDeviceBreakdownInspect,
      exporting,
      setExporting,
      reportFilters,
      fromInspect: true,
    });
  };

  const runReport = useCallback(
    ({ initialLoad = false }) => {
      setRunningReport(true);
      setDataUsedLoading(true);
      setDeviceBreakdownLoading(true);

      Promise.all([getDataUsed(reportFilters), getDeviceBreakdownInspect(reportFilters)])
        .then(([dataUsedResponse, deviceBreakdownResponse]) => {
          setDataUsed(dataUsedResponse);
          setDataUsedLoading(false);
          setDataUsedError(null);

          setDeviceBreakdownData(deviceBreakdownResponse);
          setDeviceBreakdownLoading(false);
        })
        .catch((error) => {
          if (error) {
            setDataUsed([]);
            setDataUsedLoading(false);
            setDataUsedError(error);
          }
        })
        .finally(() => {
          setRunningReport(false);
          setLoading(false);

          if (initialLoad) {
            setInitiallyLoaded(true);
          }
        });
    },
    [
      getDataUsed,
      getDeviceBreakdownInspect,
      setDataUsed,
      setDataUsedLoading,
      setDataUsedError,
      setDeviceBreakdownData,
      setDeviceBreakdownLoading,
      setInitiallyLoaded,
      setRunningReport,
      reportFilters,
    ]
  );

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Single Device', 'Inspect Data Used').send();
  }, []);

  const getAllDeviceData = useGetAllDeviceData();
  useEffect(() => {
    if (initiallyLoaded) return;

    const runInitialReport = async () => {
      // This page is outside of the LayoutSingleDevice, so we need to ensure data is loaded in case of deep-linking.
      if (!device || device.id === 0) {
        await getAllDeviceData(deviceId);
      }

      runReport({ initialLoad: true });
    };

    runInitialReport();
  }, [device, deviceId, getAllDeviceData, initiallyLoaded, runReport]);

  const setCustomDateHandler = ({ startDate: startCustomDate, endDate: endCustomDate }) =>
    dispatch(setCustomDate(startCustomDate, endCustomDate));

  const setTimeQuickFilterHandler = (filter) => dispatch(setTimeQuickFilter(filter));

  return (
    <div>
      <HoloHelmet title={`${device.name} - Total Data Used`} />

      <InspectDrawer
        topFloat={
          <div>
            <Loader isLoading={loading} />

            <div className="inspect-title">
              <div className="inner grid-row">
                <div className="grid-item">Total Data Used</div>
                <Button loading={exporting} onClick={exportCSV} type="secondary">
                  Export
                </Button>
              </div>
            </div>

            <div className="InspectFilters inspect-filters">
              <div className="inner grid-row v-align">
                <div className="header">Filter</div>
                <DateTagFilter
                  startDate={startDate}
                  endDate={endDate}
                  disabled={runningReport}
                  onRunReport={runReport}
                  runningReport={runningReport}
                  setCustomDate={setCustomDateHandler}
                  hideTags
                  setTimeQuickFilter={setTimeQuickFilterHandler}
                  timeQuickFilter={timeQuickFilter}
                  deviceId={deviceId}
                />
              </div>
            </div>
          </div>
        }
        onClose={() =>
          browserHistory.push(
            Paths.withContext(Paths.DEVICE_USAGE).replace(`:${Paths.DEVICE_PARAM_NAME}`, device.id)
          )
        }
      >
        <div className="inspect-content">
          <DataUsedChart
            data={dataUsed}
            loading={dataUsedLoading}
            error={dataUsedError}
            fromInspect
          />

          <DeviceBreakdownTableInspect
            data={deviceBreakdownData}
            loading={deviceBreakdownLoading}
          />
        </div>
      </InspectDrawer>
    </div>
  );
};

export default InspectDataUsed;
