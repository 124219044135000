import findIndex from 'lodash/findIndex';
import isNumber from 'lodash/isNumber';
import { LOGIN_REQUEST, LOGOUT_REQUEST } from '../account/actionTypes';
import { GET_ALL_BILLING_SUCCESS } from '../organization/actionTypes';
import {
  ADD_DEVICE_TAG,
  CLEAR_STATE,
  GET_DEVICE_COUNT_SUCCESS,
  REMOVE_DEVICE_TAG,
} from './actionTypes';

export const initialState = {
  deviceCount: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function activateReducer(state: any = initialState, action) {
  switch (action.type) {
    case GET_DEVICE_COUNT_SUCCESS:
      return {
        ...state,
        deviceCount: action.payload.deviceCount,
        deviceCountActive: action.payload.deviceCountActive,
        deviceCountPreflight: action.payload.deviceCountPreflight,
        deviceCountDeactivated: action.payload.deviceCountDeactivated,
      };

    case GET_ALL_BILLING_SUCCESS:
      return {
        ...state,
        isAddingNewCC:
          action.billingInformation && !action.billingInformation.creditCard.hasHydrated,
      };

    case ADD_DEVICE_TAG: {
      const existingTagIdx = findIndex(state.deviceTags, {
        name: action.tag.name,
      });

      if (existingTagIdx === -1) {
        return { ...state, deviceTags: state.deviceTags.concat([action.tag]) };
      }
      if (!('id' in state.deviceTags[existingTagIdx]) && 'id' in action.tag) {
        return {
          ...state,
          deviceTags: state.deviceTags.map((tag, i) => (i === existingTagIdx ? action.tag : tag)),
        };
      }
      return state;
    }

    case REMOVE_DEVICE_TAG:
      return {
        ...state,
        deviceTags: state.deviceTags.filter(
          (tag) =>
            (isNumber(action.tag.id) && action.tag.id !== tag.id) || action.tag.name !== tag.name
        ),
      };

    case LOGOUT_REQUEST:
    case LOGIN_REQUEST:
    case CLEAR_STATE:
      return { ...state, ...initialState };

    default:
      return state;
  }
}

export default activateReducer;
