import getElementScrollPosition from '../dom/getElementScrollPosition';
import scrollTo from '../dom/scrollTo';

export function composeValidationMessages(state, fields, result) {
  const validatedFields = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of fields) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      const message = typeof result[key][0] !== 'undefined' ? result[key][0].message : null;

      validatedFields[key] = { ...state.form[key], error: message };
    }
  }

  return validatedFields;
}

function getFirstErrorElement(errors) {
  return document.getElementsByName(Object.keys(errors)[0])[0];
}

export function scrollToFirstError(errors) {
  const elementToScrollTo = getFirstErrorElement(errors);

  if (elementToScrollTo) {
    scrollTo(getElementScrollPosition(elementToScrollTo));
  }
}
