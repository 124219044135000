/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { Icon } from '@hologram-dimension/icon';
import { type FC, type ReactNode } from 'react';

interface HeaderProps {
  title: ReactNode;
  showQuit?: boolean;
  overrideTitleRight?: ReactNode;
  onClose?: AnyFunction;
}

const Header: FC<HeaderProps> = ({
  title,
  showQuit = true,
  overrideTitleRight = false,
  onClose,
}) => (
  <div className="FullScreenModal__header">
    <div className="FullScreenModal__header__left">
      <div className="FullScreenModal__header__left__title">{title}</div>
    </div>
    <div className="FullScreenModal__header__right">
      {overrideTitleRight}
      {showQuit && (
        <div
          className="FullScreenModal__header__right__quit"
          onClick={onClose}
          data-testid="modal-close-button"
        >
          <Icon name="Close" fill="DdsColorPaletteWhite" />
        </div>
      )}
    </div>
  </div>
);

export default Header;
