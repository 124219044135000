import { FeedbackModal as HyperFeedbackModal } from '@hologram-hyper-dashboard/components';
import { ModalCommonProps } from 'common-js/components/Modal';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { close } from 'common-js/reducers/modal/actions';
import { FC, useCallback } from 'react';

interface SupportModalProps extends ModalCommonProps {}

const SupportModal: FC<SupportModalProps> = () => {
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  return <HyperFeedbackModal onClose={onClose} className="HyperDashboardModal" defaultOpen />;
};
export default SupportModal;
