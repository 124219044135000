import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Panel } from '@holokit/core';

import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { getSelectedTagNames } from 'common-js/reducers/deviceFilter/selectors';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { BalanceHistory, HoloHelmet } from 'common-js/components';
import DateTagFilter from 'common-js/components/DateTagFilter';
import * as usageActions from 'common-js/reducers/usage/actions';
import * as deviceFilterActions from 'common-js/reducers/deviceFilter/actions';
import * as messageActions from 'common-js/reducers/message/actions';
import { DataSpendGraph, ActiveDeviceOverview } from '../components';

class History extends React.Component {
  constructor(props) {
    super(props);

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Billing - History' },
    });
  }

  componentDidMount() {
    const { runBillingReport, getTags, pushGlobalMessage } = this.props;

    const promises = [getTags(), runBillingReport()];

    try {
      Promise.all(promises).catch((e) => {
        pushGlobalMessage(e, 'error');
      });
    } catch (e) {
      pushGlobalMessage(e, 'error');
    }
  }

  render() {
    const {
      balanceHistory,
      startDate,
      endDate,
      runningReport,
      runBillingReport,
      billingReport,
      devicesReport,
      timeQuickFilter,
      setQuickFilter,
      setCustomDate,
      selectAllTags,
      deselectAllTags,
      toggleTag,
      reportDateTitle,
      selectedTags,
      tagNames,
      tags,
    } = this.props;

    return (
      <div className="page-billing-history">
        <HoloHelmet title="Billing History" />
        <Panel title={reportDateTitle} largeTitle headerBorder={false} noBodyPadding well>
          <DateTagFilter
            deselectAllTags={deselectAllTags}
            selectAllTags={selectAllTags}
            selectedTags={selectedTags}
            tags={tags}
            startDate={startDate}
            endDate={endDate}
            setCustomDate={setCustomDate}
            toggleTag={toggleTag}
            onRunReport={(deviceid, tagids) => {
              sendAnalyticsEvent({
                type: analyticsTypes.REPORT_BILLING_HISTORY,
                data: {
                  start_date: startDate,
                  end_date: endDate,
                  tags: tagNames,
                },
              });
              runBillingReport(tagids);
            }}
            runningReport={runningReport}
            disabled={runningReport}
            setTimeQuickFilter={setQuickFilter}
            timeQuickFilter={timeQuickFilter}
          />
        </Panel>
        <Panel title="Spend overview" noBodyPadding isLoading={runningReport}>
          <DataSpendGraph data={billingReport} />
        </Panel>
        <Panel title="Device overview" isLoading={runningReport}>
          <ActiveDeviceOverview data={devicesReport} />
        </Panel>
        <BalanceHistory balanceHistory={balanceHistory} isLoading={runningReport} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    balanceHistory: state.organization.balanceHistory || [],
    startDate: new Date(state.usage.filters.startDate),
    endDate: new Date(state.usage.filters.endDate),
    tags: state.deviceFilters.tags || [],
    tagNames: getSelectedTagNames(state),
    selectedTags: state.deviceFilters.selectedTags,
    timeQuickFilter: state.usage.timeQuickFilter,
    runningReport: state.usage.runningReport,
    reportDateTitle: state.usage.reportDateTitle,
    billingReport: state.usage.billingReport,
    devicesReport: state.usage.devicesReport,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        runBillingReport: usageActions.runBillingReport,
        setCustomDate: usageActions.setCustomDate,
        setQuickFilter: usageActions.setQuickFilter,
        selectAllTags: deviceFilterActions.selectAllTags,
        deselectAllTags: deviceFilterActions.deselectAllTags,
        toggleTag: deviceFilterActions.toggleTag,
        getTags: deviceFilterActions.getTags,
        pushGlobalMessage: messageActions.pushGlobalMessage,
      },
      dispatch
    )
)(History);
