import { getOrganizations } from '../../organization/actions';
import { LOGIN_SUCCESS, SET_USER_INFO } from '../actionTypes';

export const loginSequence = (userInfo) => (dispatch, state) => {
  dispatch({
    type: LOGIN_SUCCESS,
    userInfo,
  });

  dispatch({
    type: SET_USER_INFO,
    ...userInfo,
  });

  return getOrganizations()(dispatch, state);
};
