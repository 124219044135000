import { Button } from '@hologram-dimension/button';
import { Loader, Tooltip } from '@holokit/core';
import classNames from 'clsx';
import { BUTTON_CLICK, ERROR_RETURN } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { DEVICES_ACTIVITY } from 'common-js/constants/paths';
import { COMPLETED, PENDING } from 'common-js/constants/taskStatuses';
import {
  PAUSE_DATA_BULK_REQUEST,
  RESUME_DATA_BULK_REQUEST,
} from 'common-js/reducers/devices/actionTypes';
import { getTaskProgress, getTasks } from 'common-js/reducers/devices/actions';
import { TASK_START_DATE } from 'common-js/reducers/devices/reducer';
import {
  getCompletedTasks,
  getPendingTasks,
  getProgressForTasks,
} from 'common-js/reducers/devices/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import Moment from 'moment';
import { Component, FC } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { TaskPopoverTrigger } from '.';

const PROGRESS_PING_INTERVAL_IN_MS = 3000;
const TASK_PING_INTERVAL_IN_MS = 15_000;
const RECOGNIZED_ACTIONS = ['pause', 'resume', 'activate'];

const TaskCard: FC<any> = ({
  action,
  deviceCount,
  status,
  requesterFirstName: firstName,
  requesterLastName: lastName,
  requesterOrgName: orgName,
  source,
  timestampStart,
  timestampEnd,
  loading,
  progress,
}) => {
  const timestamp = status === PENDING ? timestampStart : timestampEnd;
  const deviceText = `${addCommasToNumber(deviceCount, false)} ${
    deviceCount === 1 ? 'device' : 'devices'
  }`;

  let config;
  switch (action) {
    case 'pause':
      config = {
        title: {
          inProgress: `${deviceText} ${deviceCount === 1 ? 'is' : 'are'} pausing`,
          pending: `Pause ${deviceText}`,
          completed: `Paused ${deviceText}`,
        },
      };
      break;

    case 'resume':
      config = {
        title: {
          inProgress: `${deviceText} ${deviceCount === 1 ? 'is' : 'are'} resuming`,
          pending: `Resume ${deviceText}`,
          completed: `Resumed ${deviceText}`,
        },
      };
      break;

    case 'activate':
      config = {
        title: {
          inProgress: `${deviceText} ${deviceCount === 1 ? 'is' : 'are'} activating`,
          pending: `Activate ${deviceText}`,
          completed: `Activated ${deviceText}`,
        },
      };
      break;

    default:
      config = {
        title: {
          inProgress: `${deviceText} ${deviceCount === 1 ? 'is' : 'are'} being processed`,
          pending: `Process ${deviceText}`,
          completed: `Processed ${deviceText}`,
        },
      };
  }

  let title;
  if (status === PENDING) {
    title = loading ? config.title.inProgress : config.title.pending;
  } else if (status === COMPLETED) {
    title = config.title.completed;
  }

  const firstInitial = typeof firstName === 'string' && firstName.length > 0 ? firstName[0] : '';
  const showTeamMember = firstInitial || lastName || orgName;
  let teamMember;
  if (source === 'api') {
    teamMember = `${orgName} team via API`;
  } else if (orgName === 'Hologram') {
    teamMember = 'Hologram team member';
  } else {
    teamMember = `${firstInitial}. ${lastName}`;
  }

  const localDate = Moment.utc(timestamp).local();

  const progressText = () => {
    const pending = progress?.pending || 0;
    const completed = progress?.completed || 0;
    // Make sure the total is at least 1 so we don't divide by 0
    const total = Math.max(pending + completed, 1);
    const percentage = Math.floor((pending / total) * 100);

    return percentage === 0 ? 'Starting...' : `${percentage}% complete...`;
  };

  return (
    <div
      className={classNames('TaskPopover__card', {
        'TaskPopover__card--loading': loading,
      })}
    >
      <Loader isLoading={loading} />
      <div className="TaskPopover__card__wrapper">
        <div className="TaskPopover__card__title">{title}</div>
        <div className="TaskPopover__card__status">
          {status === PENDING && (
            <span className="TaskPopover__card__status__item TaskPopover__card__status__progress">
              {loading ? progressText() : 'Pending'}
            </span>
          )}
          {status === COMPLETED && (
            <>
              <span className="TaskPopover__card__status__item TaskPopover__card__date">
                {localDate.format('MMM D, YYYY')}
              </span>
              <span className="TaskPopover__card__status__item TaskPopover__card__date">
                {localDate.format('h:mm a')} ({Moment.tz(Moment.tz.guess()).zoneAbbr()})
              </span>
            </>
          )}
        </div>
        {showTeamMember && (
          <div className="TaskPopover__card__status">
            <span className="TaskPopover__card__status__item TaskPopover__card__status__item__name">
              {teamMember}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const TaskSection: FC<any> = ({ title, emptyText, tasks, progress }) => (
  <div className="TaskPopover__section">
    {tasks.length > 0 ? (
      <>
        <h2 className="TaskPopover__section__heading">{title}</h2>
        {tasks.map((task, index) => (
          <TaskCard
            key={`TaskCard-${task.jobId}`}
            loading={index === 0 && task.status === PENDING}
            progress={progress?.[task.jobId] || {}}
            {...task}
          />
        ))}
      </>
    ) : (
      <h2 className="TaskPopover__section__heading TaskPopover__section__heading--empty">
        {emptyText}
      </h2>
    )}
  </div>
);

class TaskPopover extends Component<any, any> {
  static handleReviewCompletedActionsClick = () => {
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Action Log - Popover View - Completed Actions',
      },
    });
    browserHistory.push(DEVICES_ACTIVITY);
  };

  static showSuccessToast = () => {
    toast(
      <HeadlineToast
        headline="Success!"
        body="All pending actions have been completed."
        icon="Checkmark--single"
        size="minor"
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toastify-content--burnt toastify-content--success',
        autoClose: 10000,
      }
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      taskPingInterval: null,
      progressPingInterval: null,
      pinging: false,
    };
  }

  componentDidMount() {
    const { getTasks_, getTaskProgress_ } = this.props;

    getTasks_(TASK_START_DATE);
    getTaskProgress_();

    this.startPingersIfPending();
  }

  componentDidUpdate(prevProps) {
    const { pendingTasks } = this.props;
    const pendingTasksCount = pendingTasks?.length || 0;
    const prevPendingTasksCount = prevProps.pendingTasks?.length || 0;

    this.startPingersIfPending();

    if (pendingTasksCount === 0 && pendingTasksCount !== prevPendingTasksCount) {
      this.clearPingers();
      TaskPopover.showSuccessToast();
    }
  }

  componentWillUnmount() {
    const { taskPingInterval, progressPingInterval } = this.state;

    window.clearInterval(taskPingInterval);
    window.clearInterval(progressPingInterval);
  }

  handleTaskPopoverClick = () => {
    const { pendingTasks } = this.props;
    const unknownAction = pendingTasks.find(({ action }) => !RECOGNIZED_ACTIONS.includes(action));

    if (unknownAction) {
      sendAnalyticsEvent({
        type: ERROR_RETURN,
        data: {
          name: 'Action Log - Popover View - Unrecognized Action Error',
          action: unknownAction?.action,
        },
      });
    }

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Bulk Action - Actions Header Icon',
      },
    });
  };

  startPingersIfPending = () => {
    const { getTasks_, getTaskProgress_, pendingTasks } = this.props;
    const { pinging } = this.state;

    const pendingTasksCount = pendingTasks?.length || 0;

    if (pendingTasksCount > 0 && !pinging) {
      const taskPingInterval = window.setInterval(getTasks_ as () => {}, TASK_PING_INTERVAL_IN_MS);
      const progressPingInterval = window.setInterval(
        getTaskProgress_ as () => {},
        PROGRESS_PING_INTERVAL_IN_MS
      );

      this.setState({ taskPingInterval, progressPingInterval, pinging: true });
    }
  };

  clearPingers = () => {
    const { taskPingInterval, progressPingInterval } = this.state;

    window.clearInterval(taskPingInterval);
    window.clearInterval(progressPingInterval);

    this.setState({
      taskPingInterval: null,
      progressPingInterval: null,
      pinging: false,
    });
  };

  render() {
    const { getTasks_, isLoading, pendingTasks, completedTasks, progress } = this.props;

    const pendingTasksCount = pendingTasks?.length || 0;
    const completedTasksCount = completedTasks?.length || 0;

    const showButton = pendingTasksCount > 0 || isLoading;

    return (
      showButton && (
        <div className="TaskPopoverWrapper" onClick={this.handleTaskPopoverClick} role="none">
          <Tooltip
            content={
              <>
                <TaskSection
                  title={`Pending actions (${pendingTasksCount})`}
                  emptyText="No pending actions"
                  tasks={pendingTasks}
                  progress={progress}
                />
                {completedTasksCount > 0 && (
                  <div className="TaskPopover__section TaskPopover__section__button">
                    <Button
                      variant="secondary"
                      onClick={TaskPopover.handleReviewCompletedActionsClick}
                    >
                      Review completed actions
                    </Button>
                  </div>
                )}
              </>
            }
            classes="TaskPopover--tooltip"
            contentClasses="TaskPopover"
            interactionKind="click"
            light
            position="top"
          >
            <TaskPopoverTrigger pendingTasksCount={pendingTasksCount} onClick={getTasks_} />
          </Tooltip>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading:
    PAUSE_DATA_BULK_REQUEST in (state.devices?.uiState?.loading ?? {}) ||
    RESUME_DATA_BULK_REQUEST in (state.devices?.uiState?.loading ?? {}),
  completedTasks: getCompletedTasks(state),
  pendingTasks: getPendingTasks(state),
  progress: getProgressForTasks(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTasks_: getTasks,
      getTaskProgress_: getTaskProgress,
    },
    dispatch
  );

const TaskPopoverHoC = connect(mapStateToProps, mapDispatchToProps)(TaskPopover);

export default TaskPopoverHoC;
