import React, { useCallback, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { toast } from 'react-toastify';
import _classnames from 'clsx';
import { Button, Tooltip } from '@holokit/core';
import { attemptDocumentDownload } from 'common-js/api';
import { requestDevicesReport } from 'common-js/reducers/devices/actions';
import HeadlineToast from 'common-js/toasts/HeadlineToast';

const showToast = ({ headline, body, icon, className }) =>
  toast(<HeadlineToast headline={headline} body={body} icon={icon} />, {
    position: toast.POSITION.TOP_RIGHT,
    className: ['toastify-content--burnt', className].join(' '),
    autoClose: 10000,
  });

const showErrorToast = () =>
  showToast({
    headline: 'Export Error',
    body: 'This report could not be generated automatically. Please contact success@hologram.io.',
    icon: 'Circle--alert',
    className: 'toastify-content--error',
  });

const ExportDevices = ({ deviceStates, hasTooltip = true }) => {
  const [loading, setLoading] = useState(false);

  const email = useAppSelector((state) => state.account?.email);
  const exportCount = useAppSelector((state) => state.devices?.exportCount ?? 0);
  const dispatch = useAppDispatch();

  const handleDocumentRequestReturn = useCallback(
    (data) => {
      if (data.finishedGenerating && data.documentId) {
        try {
          attemptDocumentDownload(data.documentId);
          showToast({
            headline: 'CSV downloaded',
            body: 'Check your browser downloads to view the file.',
            icon: 'Checkmark--single',
            className: 'toastify-content--success',
          });
        } catch {
          showErrorToast();
        }
      } else {
        showToast({
          headline: 'Generating CSV',
          body: `Your download is being prepared. We'll email ${email} when it's ready.`,
          icon: 'Envelope',
          className: 'toastify-content--success',
        });
      }

      setLoading(false);
    },
    [email, setLoading]
  );

  const onExportCSV = useCallback(() => {
    setLoading(true);

    dispatch(requestDevicesReport(deviceStates))
      .then((data) => handleDocumentRequestReturn(data))
      .catch(showErrorToast);
  }, [deviceStates, dispatch, handleDocumentRequestReturn, setLoading]);

  return (
    <div
      className={_classnames('ExportDevices', {
        'ExportDevices--sidebar': hasTooltip,
      })}
    >
      {hasTooltip ? (
        <div className="ExportDevices__wrapper">
          <Tooltip content="By default total device count will be exported." light>
            <Button
              classes="ExportDevices__button"
              fullWidth
              loading={loading}
              onClick={onExportCSV}
              small
              type="secondary"
            >
              Export CSV ({exportCount.toLocaleString()})
            </Button>
          </Tooltip>
        </div>
      ) : (
        <Button
          classes="ExportDevices__button"
          iconLeft="Arrow--lineinto--south"
          loading={loading}
          onClick={onExportCSV}
          small
          type="secondary"
        >
          Export CSV
        </Button>
      )}
    </div>
  );
};

export default ExportDevices;
