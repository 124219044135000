import { Picture } from '@hologram-hyper-dashboard/components';
import clsx from 'clsx';
import ccAmex from 'img/billing/credit-card-icons/Amex.png?&imagetools';
import ccDiscover from 'img/billing/credit-card-icons/Discover.png?&imagetools';
import ccJCB from 'img/billing/credit-card-icons/JCB.png?&imagetools';
import ccMastercard from 'img/billing/credit-card-icons/Mastercard.png?&imagetools';
import ccMaestro from 'img/billing/credit-card-icons/Maestro.png?&imagetools';
import ccUnionPay from 'img/billing/credit-card-icons/UnionPay.png?&imagetools';
import ccVisa from 'img/billing/credit-card-icons/Visa.png?&imagetools';
import ccUnknown from 'img/billing/credit-card-icons/Unknown.png?&imagetools';
import { DateTime } from 'luxon';
import { useMemo, type FC, type ReactNode } from 'react';

interface CreditCardSummaryProps {
  issuer?: string;
  cardNumberLastFourDigits?: string;
  classNames?: string;
  expirationMonth?: string;
  expirationYear?: string;
  children?: ReactNode;
  mode: 'standalone' | 'activation';
}

const STRIPE_CARD_TO_ICON = new Map<string, any>([
  ['amex', ccAmex],
  ['mastercard', ccMastercard],
  ['maestro', ccMaestro],
  ['visa', ccVisa],
  ['discover', ccDiscover],
  ['jcb', ccJCB],
  ['unionpay', ccUnionPay],
]);

const CreditCardSummary: FC<CreditCardSummaryProps> = ({
  issuer,
  cardNumberLastFourDigits,
  classNames,
  expirationMonth,
  expirationYear,
  children,
  mode,
}) => {
  const expiration = useMemo(() => {
    const year = parseInt(expirationYear ?? '', 10);
    const month = parseInt(expirationMonth ?? '', 10);

    if (Number.isNaN(year) || Number.isNaN(month)) {
      return '';
    }

    return DateTime.fromObject({ year, month }).toFormat('M/yy');
  }, [expirationMonth, expirationYear]);

  const ccImg = issuer ? STRIPE_CARD_TO_ICON.get(issuer) : undefined;

  return (
    <div
      className={clsx(
        'CreditCardSummary',
        mode === 'activation' && 'CreditCardSummary__padded',
        classNames
      )}
    >
      <div className="CreditCardSummary__card-details">
        <Picture
          className="CreditCardSummary__cardIcon"
          imgClassName="CreditCardSummary__cardIcon-img"
          image={ccImg ?? ccUnknown}
          alt=""
          setDimensions={false}
        />
        <div className="CreditCardSummary__card-details-text">
          <span className="CreditCardSummary__card-number">
            Ending in <strong>{cardNumberLastFourDigits ?? ''}</strong>
          </span>
          <span className="CreditCardSummary__card-expiration">Expires {expiration}</span>
        </div>
      </div>
      <div className="CreditCardSummary__actions">{children}</div>
    </div>
  );
};

export default CreditCardSummary;
