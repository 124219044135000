import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { ACCOUNT_LOGIN, ORGS_CREATE_STEP1, withContext } from 'common-js/constants/paths';
import { useAppDispatch, useAppSelector } from 'common-js/hooks';
import { OrgModel } from 'common-js/models';
import { logout } from 'common-js/reducers/account/actions';
import {
  selectImpersonator,
  selectName,
  selectUserEmail,
} from 'common-js/reducers/account/selectors';
import { stopImpersonating } from 'common-js/reducers/admin/actions';
import { changeContext, getAllBilling } from 'common-js/reducers/organization/actions';
import { getSelectedOrg, getUnSelectedOrgs } from 'common-js/reducers/organization/selectors';
import { useCallback, type FC } from 'react';
import { Link, browserHistory } from 'react-router';
import DropdownMenu from './DropdownMenu';

const OrgChange: FC = () => {
  const dispatch = useAppDispatch();

  const accountName = useAppSelector(selectName);
  const selectedOrg = useAppSelector(getSelectedOrg) || new OrgModel();
  const unSelectedOrgs = useAppSelector(getUnSelectedOrgs) || [];
  const userEmail = useAppSelector(selectUserEmail);
  const impersonator = useAppSelector(selectImpersonator);

  const userLogout = useCallback(() => {
    dispatch(logout()).then(() => {
      browserHistory.push(ACCOUNT_LOGIN);
    });
  }, [dispatch]);

  const changeOrg = useCallback(
    async (orgId) => {
      await dispatch(changeContext(orgId, true, null));
      dispatch(getAllBilling()).catch(() => Promise.resolve());
    },
    [dispatch]
  );

  return (
    <div className="orgChange">
      <DropdownMenu
        buttonClasses="DropdownMenu__profile main-sidebar-dropdown orgChange-SubMenu"
        button={
          <div className="user-logo-container">
            <div className="user-logo current">
              <div className="user-logo-letter">
                {selectedOrg.isMyAccount ? accountName.charAt(0) : selectedOrg.initial}
              </div>
            </div>
            <div className="user-name">
              {selectedOrg.isMyAccount ? accountName : selectedOrg.name}
              <Icon
                className="user-name__icon"
                name="ChevronSingleSouth"
                size="small"
                fill="DdsColorInteractiveSecondaryDefault"
              />
            </div>
          </div>
        }
        direction="down-cover"
        placement="right"
      >
        <div style={{ maxHeight: `${window.innerHeight - 50}px` }}>
          <div className="dropdown-button grid-row vertical-align primary-logo">
            <div className="user-logo current">
              <div className="user-logo-letter">{selectedOrg.initial}</div>
            </div>
            <span className="f1 logo-label account-fullname">
              <div>{selectedOrg.isMyAccount ? accountName : selectedOrg.name}</div>
              <div className="account-orgid">Org id {selectedOrg.id}</div>
            </span>
          </div>
          <div className="header logged-in-as">
            Logged in as <span>{userEmail}</span>
          </div>
          <div className="switch-heading">
            <Icon
              className="switch-heading__icon"
              name="ArrowNortheastSouthwest"
              size="small"
              fill="DdsColorInteractivePrimaryDefault"
            />{' '}
            Switch organization
          </div>
          <div className="other-orgs scrollable">
            {unSelectedOrgs
              .sort((last) => (last.isMyAccount ? -1 : 1))
              .map((org) => (
                <div
                  key={org.id}
                  className="dropdown-button grid-row vertical-align secondary-logo"
                  onClick={() => changeOrg(org.id)}
                  onKeyUp={() => changeOrg(org.id)}
                  role="button"
                  tabIndex={-1}
                >
                  <span className="logo-label f4">{org.isMyAccount ? accountName : org.name}</span>
                </div>
              ))}
            <div className="dropdown-btns">
              <Link
                className="Button Button--secondary Button--has-icon Button--has-icon-left Button--full-width"
                to={withContext(ORGS_CREATE_STEP1)}
              >
                <div className="Button__content">
                  <div className="Button__icon Button__icon--left">
                    <Icon name="Organization" size="small" fill="DdsColorTextDiminishedOndefault" />
                  </div>
                  <span className="Button__text">Create Organization</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="dropdown-btns dropdown-btns__footer">
            <Button className="account-logout" variant="secondary" onClick={userLogout}>
              Sign out
            </Button>
          </div>
          {impersonator?.userId && (
            <>
              <div className="header logged-in-as">
                Impersonator <span>{impersonator.email}</span>
              </div>
              <div className="dropdown-impersonator">
                <Button
                  className="account-stop-impersonating"
                  onClick={() => dispatch(stopImpersonating())}
                  variant="primary"
                >
                  Stop Impersonating
                </Button>
              </div>
            </>
          )}
        </div>
      </DropdownMenu>
    </div>
  );
};

export default OrgChange;
