import { Icon } from '@hologram-dimension/icon';
import SubMenuRightContent from 'common-js/components/menu/SubMenuRightContent';
import TopMenu from 'common-js/components/menu/TopMenu';
import { REPORTS_OVERVIEW, pathMatches, withContext } from 'common-js/constants/paths';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectReportDetails, selectReportError } from 'common-js/reducers/reports/selectors';
import { FC } from 'react';

const ReportsMenu: FC = () => {
  const isOverview = pathMatches(REPORTS_OVERVIEW);

  const reportDetails = useAppSelector(selectReportDetails);
  const reportError = useAppSelector(selectReportError);

  return (
    <div>
      <TopMenu titleHref={withContext(REPORTS_OVERVIEW)} rightContent={<SubMenuRightContent />}>
        {isOverview ? (
          <TopMenu.Item text="Reports (Beta)" isDeviceName active />
        ) : (
          <>
            {!reportError && (
              <TopMenu.Item
                text="All Reports"
                href={withContext(REPORTS_OVERVIEW)}
                className="grid-row v-align"
              >
                <Icon name="ChevronSingleWest" size="medium" fill="DdsColorPaletteWhite" />
              </TopMenu.Item>
            )}
            <TopMenu.Item
              isDeviceName
              active
              text={reportError ? 'Reports (Beta)' : (reportDetails?.name ?? 'Loading...')}
            />
          </>
        )}
      </TopMenu>
    </div>
  );
};

export default ReportsMenu;
