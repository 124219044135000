import { Button } from '@hologram-dimension/button';
import { Panel } from '@holokit/core';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { getSelectedOrg, getUserPermissions } from 'common-js/reducers/organization/selectors';
import { translatePermissionsToRole } from 'common-js/utils/permissions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrgModel } from '../../../common/models';
import { openModal } from '../../../common/reducers/modal/actions';

class OrgNamePanel extends React.Component {
  handleOrgNameChangeClick() {
    const { openModal_ } = this.props;
    openModal_('EditOrgNameModal', {}, 'narrow2');
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Settings - Edit Org Name',
      },
    });
  }

  render() {
    const { selectedOrg, userPermissions } = this.props;

    const isAdmin = translatePermissionsToRole(userPermissions) === 'admin';

    return (
      <div className="OrgNamePanel">
        <Panel>
          <div className="grid-row v-align">
            <div className="org-initial-container">
              <div className="org-initial">{selectedOrg.name.charAt(0)}</div>
            </div>
            <div className="org-name grid-item">
              {selectedOrg.name}
              {selectedOrg.isMyAccount && ' (Personal Organization)'}
            </div>
            {isAdmin && !selectedOrg.isMyAccount && (
              <Button onClick={() => this.handleOrgNameChangeClick()} variant="secondary">
                Edit Name
              </Button>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    selectedOrg: getSelectedOrg(state) || new OrgModel(),
    userPermissions: getUserPermissions(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openModal_: openModal,
      },
      dispatch
    )
)(OrgNamePanel);
