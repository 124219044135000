import { useCallback, useState } from 'react';
import { setCurrentDevice } from 'common-js/reducers/currentDevice/actions';
import useUpdateOverageLimit from 'common-js/api/devices/useUpdateOverageLimit';
import useGetDeviceById from 'common-js/api/devices/useGetDeviceById';
import useAppDispatch from 'common-js/hooks/useAppDispatch';

const useSubmitDataLimitsForm = ({ device, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const updateOverageLimit = useUpdateOverageLimit();
  const getDeviceById = useGetDeviceById();
  const handleSubmit = useCallback(
    async ({ dataLimit, customAmountValue, customAmountUnit }) => {
      const limit =
        dataLimit === 'custom' ? customAmountValue * customAmountUnit - device.data : dataLimit;
      setLoading(true);
      try {
        if (await updateOverageLimit(device.id, limit)) {
          // update the device in redux now, even though we're polling every 10 seconds
          const updatedDevice = await getDeviceById(device.id);
          dispatch(setCurrentDevice(updatedDevice));

          onSuccess();
          setError(null);
        }
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [device?.data, device?.id, dispatch, getDeviceById, onSuccess, updateOverageLimit]
  );

  return {
    loading,
    error,
    handleSubmit,
  };
};

export default useSubmitDataLimitsForm;
