import { Link } from '@hologram-dimension/link';
import analyticsEventBuilder from 'common-js/analytics';
import useResetPassword from 'common-js/api/account/useResetPassword';
import FormPanel, { MessageType } from 'common-js/components/FormPanel';
import HoloHelmet from 'common-js/components/HoloHelmet';
import { ACCOUNT_FORGOT_PASSWORD, ACCOUNT_LOGIN } from 'common-js/constants/paths';
import { messages, pageTitles, submitLabel } from 'common-js/constants/resetPasswordPageStrings';
import SetAccountPasswordForm from 'common-js/forms/SetAccountPasswordForm';
import { useCallback, useEffect, useState, type FC } from 'react';
import LoggedOutTemplate from './LoggedOutTemplate';

export type PageState = 'invalidResetLink' | 'confirmation' | 'default';

interface Query {
  reqid?: string;
  userid?: string;
  token?: string;
}

interface ResetPasswordProps {
  location: { query: Query };
}

const ResetPassword: FC<ResetPasswordProps> = ({ location: { query } }) => {
  const isResetLinkValid = ({ reqid, userid, token }: Query) => reqid && userid && token;

  /* Page state */
  const [pageState, setPageState] = useState<PageState>(
    isResetLinkValid(query) ? 'default' : 'invalidResetLink'
  );
  const [formLevelError, setFormLevelError] = useState();

  /* API call */
  const resetPasswordApiCall = useResetPassword();

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Reset Password').send();
  }, []);

  /* Form submit handler */
  const handleSubmitError = useCallback((error) => {
    if (typeof error === 'object') {
      if (error.reqid || error.userid || error.token) {
        // If the error is related to the reqid, userid, or token, the reset link is invalid, hide the form
        setPageState('invalidResetLink');
        return false;
      }

      if (error.password) {
        // If the error is related to the password field, format it for display by the SetAccountPasswordForm
        return { setAccountPasswordFieldError: error.password };
      }
    }
    // If the error is due to any other cause, or an exception was caught, display a form level error
    setFormLevelError(error?.unknown || error);
    return false;
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      const { reqid, userid, token } = query;
      try {
        // Call the resetPassword API
        const response = await resetPasswordApiCall({
          password: data.password,
          reqid, // Forward reqid, userid, and token along from the url to the API call.
          userid,
          token,
        });
        if (response.success) {
          // If the password reset was successful, display the confirmation page
          setPageState('confirmation');
          return true;
        }
        // Otherwise, handle the error display based on the API response
        return handleSubmitError(response.error);
      } catch (errorMessage) {
        // If something unexpected happens during form submission, display the error
        return handleSubmitError(errorMessage);
      }
    },
    [handleSubmitError, query, resetPasswordApiCall]
  );

  return (
    <LoggedOutTemplate backLinkText="Log in" backLinkUrl={ACCOUNT_LOGIN}>
      <HoloHelmet title={pageTitles[pageState]} />
      <FormPanel
        title={pageTitles[pageState]}
        message={pageState === 'default' ? formLevelError : messages[pageState]?.message}
        messageType={(messages[pageState]?.messageType as MessageType) || 'error'}
      >
        {pageState === 'default' && (
          <SetAccountPasswordForm onSubmit={onSubmit} submitLabel={submitLabel} />
        )}
        {pageState === 'invalidResetLink' && (
          <p aria-live="polite">
            Try <Link to={ACCOUNT_FORGOT_PASSWORD}>resetting your password</Link> again.
          </p>
        )}
        {pageState === 'confirmation' && (
          <p aria-live="polite">
            <Link to={ACCOUNT_LOGIN}>Log in now</Link>
          </p>
        )}
      </FormPanel>
    </LoggedOutTemplate>
  );
};
export default ResetPassword;
