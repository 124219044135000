import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import { translatePermissionsToRole } from 'common-js/utils/permissions';
import useAppSelector from './useAppSelector';

const useUserRole = () => {
  const userPermissions = useAppSelector(getUserPermissions);
  return translatePermissionsToRole(userPermissions);
};

export default useUserRole;
