const pastTenseMap = {
  pause: 'paused',
  resume: 'resumed',
  activate: 'activated',
};

export const translateDeviceAction = (action, defaultValue = null) => {
  const pastTense = pastTenseMap[action] ?? defaultValue;

  return {
    actionPastTense: pastTense,
    actionPastTenseCapitalized:
      typeof pastTense === 'string' ? `${pastTense[0].toUpperCase()}${pastTense.slice(1)}` : null,
  };
};

export default translateDeviceAction;
