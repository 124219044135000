/* eslint-disable react/no-string-refs */
import createReactClass from 'create-react-class';
import React from 'react';
import { Mixin } from 'formsy-react19';

// TODO: Changing this would require not using mixins (or finding a way to add them to the prototype)
// eslint-disable-next-line react/prefer-es6-class
const TimeDurationInput = createReactClass({
  mixins: [Mixin],

  getDurationSelectValues() {
    const rawValue = this.getValue();
    let seconds = 0;

    if (rawValue !== '') {
      seconds = parseInt(this.getValue(), 10);
    }

    return {
      days: `${parseInt(seconds / 86400, 10)}`,
      hours: `${parseInt((seconds % 86400) / 3600, 10)}`,
      mins: `${parseInt(((seconds % 86400) % 3600) / 60, 10)}`,
    };
  },

  getDurationSelectOptions(pluralUnit, singularUnit, max) {
    const options = [];

    for (let i = 0; i < max; i++) {
      options.push({
        label: `${i} ${i !== 1 ? pluralUnit : singularUnit}`,
        value: i,
      });
    }

    return options;
  },

  changeTimeInterval() {
    const day = parseInt(this.refs.dayselect.value, 10);
    const hour = parseInt(this.refs.hourselect.value, 10);
    const min = parseInt(this.refs.minselect.value, 10);

    const seconds = 86400 * day + 3600 * hour + 60 * min;

    this.setValue(`${seconds}`);
  },

  render() {
    const { requiredMessage } = this.props;

    let className;
    if (this.showRequired()) {
      className = 'required';
    } else if (this.showError()) {
      className = 'error';
    } else {
      className = null;
    }

    const errorMessage = this.showRequired() ? requiredMessage : this.getErrorMessage();
    const { description, labelName, showErrors } = this.props;
    const durationSelectValues = this.getDurationSelectValues();

    return (
      <div className={className}>
        <label htmlFor="hours">
          <div className="label-text f5">{labelName}</div>
          {description && <div className="f6 section section-sm f-subdued">{description}</div>}
          <div className="grid-row">
            <div className="grid-item time-duration-input">
              <div className="select-wrapper">
                <select
                  name="days"
                  onChange={this.changeTimeInterval}
                  ref="dayselect"
                  value={durationSelectValues.days}
                >
                  {this.getDurationSelectOptions('days', 'day', 100).map((option, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="grid-item time-duration-input">
              <div className="select-wrapper">
                <select
                  name="hours"
                  onChange={this.changeTimeInterval}
                  ref="hourselect"
                  value={durationSelectValues.hours}
                >
                  {this.getDurationSelectOptions('hours', 'hour', 24).map((option, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="grid-item time-duration-input">
              <div className="select-wrapper">
                <select
                  name="minutes"
                  onChange={this.changeTimeInterval}
                  ref="minselect"
                  value={durationSelectValues.mins}
                >
                  {this.getDurationSelectOptions('mins', 'min', 60).map((option, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="grid-item-2" />
          </div>
          {showErrors && <div className="inline-error">{errorMessage}</div>}
        </label>
      </div>
    );
  },
});

export default TimeDurationInput;
