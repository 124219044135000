import { Message } from 'common-js/components/FormPanel';
import { PageState } from 'src/js/account/containers/ResetPassword';

export const pageTitles = {
  default: 'Change your password',
  invalidResetLink: 'Password change link invalid',
  confirmation: 'Password changed',
};

export const messages: Record<PageState, Message> = {
  default: {
    message: '',
    messageType: 'error',
  },
  invalidResetLink: {
    message: 'This URL is no longer valid.',
    messageType: 'error',
  },
  confirmation: {
    message: 'Your password has been changed.',
    messageType: 'success',
  },
};

export const submitLabel = 'Change password';
