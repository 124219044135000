import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Button } from '@holokit/core';

import * as alertActions from 'common-js/reducers/alerts/actions';
import {
  getDataUsed,
  registerDeviceId,
  setCustomDate,
} from 'common-js/reducers/singleDevice/actions';
import { getBillingPeriod } from 'common-js/utils/time';

import * as Paths from 'common-js/constants/paths';
import DataLimitChartD3 from '../../usage/components/charts/DataLimitChartD3';

class DeviceDataLimitAlertsDrawer extends Component {
  componentDidMount() {
    const { deviceid, deviceRegistered, registerDeviceId_ } = this.props;

    if (!deviceRegistered) {
      registerDeviceId_(deviceid).then(() => {
        this.getDeviceData();
      });
    } else {
      this.getDeviceData();
    }
  }

  getDeviceData() {
    const { alert, deviceid, getDataUsed_, onDrawerLoad, setCustomDate_ } = this.props;

    const deviceBillingPeriodEnd = moment.utc(alert.expires);
    const { start, end } = getBillingPeriod(alert.whencreated, deviceBillingPeriodEnd);

    setCustomDate_(
      {
        startDate: parseInt(start.format('x'), 10),
        endDate: parseInt(end.format('x'), 10),
      },
      deviceid
    );
    getDataUsed_(deviceid).then(() => {
      onDrawerLoad();
    });
  }

  render() {
    const {
      alert,
      dataLimit,
      dataUsed,
      deviceid,
      handleAcknowledgeAlert,
      handleUnacknowledgeAlert,
      handleChangeDataLimit,
      handlePlanChange,
      isLoading,
      timeFilter,
    } = this.props;

    return (
      <div className="AlertsDrawer AlertsDrawer--datalimit">
        {!isLoading && (
          <>
            <div className="AlertsDrawer__panel">
              <div className="AlertsDrawer__panel__header">
                <div className="AlertsDrawer__panel__header__headline">Data usage limit report</div>
              </div>
              {dataUsed.length === 0 ? (
                <div className="AlertsDrawer__panel__body">
                  <div className="AlertsDrawer__panel__message">No data sessions to display</div>
                </div>
              ) : (
                <DataLimitChartD3
                  className="DataLimitChartD3--alerts"
                  dataUsed={dataUsed}
                  threshold={dataLimit}
                  thresholdMetDate={alert.whencreated}
                  timeFilter={timeFilter}
                  showTodayMarker
                  hideDataAfterThreshMetDate
                />
              )}
            </div>
            <div className="AlertsDrawer__footer">
              <Button
                href={Paths.withContext(
                  Paths.DEVICE_LANDING.replace(`:${Paths.DEVICE_PARAM_NAME}`, deviceid)
                )}
                iconRight="Arrow--northeast"
                target="_blank"
                type="reversed"
              >
                {alert.device_name}
              </Button>
              <div className="AlertsDrawer__footer__btn-wrapper">
                <Button
                  data={{
                    'data-linkid': alert.linkid,
                    'data-alertid': alert.id,
                  }}
                  iconLeft="Gauge"
                  onClick={handleChangeDataLimit}
                  type="reversed"
                >
                  Change data usage limit
                </Button>
                <Button iconLeft="Transfer" onClick={handlePlanChange} type="reversed">
                  Change plan
                </Button>
                <Button
                  iconLeft="Checkmark--double"
                  onClick={alert.acked ? handleUnacknowledgeAlert : handleAcknowledgeAlert}
                  type="reversed"
                >
                  {alert.acked ? 'Unacknowledge' : 'Acknowledge'}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  dataUsed: props.deviceid in state.singleDevice ? state.singleDevice[props.deviceid].dataUsed : [],
  deviceRegistered: !!state.singleDevice[props.deviceid],
  timeFilter:
    props.deviceid in state.singleDevice ? state.singleDevice[props.deviceid].timeFilter : [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...alertActions,
      getDataUsed_: getDataUsed,
      registerDeviceId_: registerDeviceId,
      setCustomDate_: setCustomDate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDataLimitAlertsDrawer);
