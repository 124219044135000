import React from 'react';
import { useFormContext } from 'react-hook-form';
import CompletedStepHeader from '../common/scaffolding/CompletedStepHeader';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';
import SidebarAttribute from '../common/scaffolding/SidebarAttribute';

function CompletedRegionSidebarItem({ regionDisplayName }) {
  return (
    <CompletedStepHeader name="Select coverage">
      <SidebarAttribute name="Region" value={regionDisplayName} />
    </CompletedStepHeader>
  );
}
function RegionSidebarItem({ currentStep, step }) {
  const { getValues } = useFormContext();
  const { displayName: regionDisplayName } = getValues('region.selected');

  if (currentStep > step)
    return <CompletedRegionSidebarItem regionDisplayName={regionDisplayName} />;

  return <IncompleteStepHeader step={step} currentStep={currentStep} name="Select coverage" />;
}

export default RegionSidebarItem;
