import React, { useCallback, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button } from '@holokit/core';
import useRemovePhoneNumber from 'common-js/api/devices/useRemovePhoneNumber';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';

const ManagePhoneNumberButton = () => {
  const dispatch = useAppDispatch();
  const [isProcessing, setProcessing] = useState(false);
  const device = useAppSelector(selectCurrentDevice);
  const removePhoneNumber = useRemovePhoneNumber();

  const handleRemovePhoneNumber = useCallback(async () => {
    setProcessing(true);
    try {
      await removePhoneNumber(device.id);
      // dispatch(pushGlobalMessage("Successfully removed phone number from this device.", "success"));
    } catch (e) {
      dispatch(pushGlobalMessage(e, 'error'));
    }
    setProcessing(false);
  }, [device.id, dispatch, removePhoneNumber]);

  const openPurchaseModal = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(openModal('PurchasePhoneNumberModal', { deviceId: device.id }, 'medium no-padding'));
    },
    [device.id, dispatch]
  );

  return (
    <span className="ManagePhoneNumberButton">
      {isProcessing && (
        <div className="well purchased-number">
          {device.phonenumber}
          <Button
            classes="pull-right"
            loading
            onClick={handleRemovePhoneNumber}
            type="destructiveMinor"
          >
            Processing...
          </Button>
        </div>
      )}
      {!isProcessing && device.phonenumber === '' && (
        <div className="grid-row flex-row">
          No phone number added to this device
          <Button onClick={openPurchaseModal} type="secondary">
            Purchase phone number
          </Button>
        </div>
      )}
      {!isProcessing && device.phonenumber !== '' && (
        <div className="well purchased-number flex-row">
          <span>{device.phonenumber}</span>
          <Button onClick={handleRemovePhoneNumber} type="destructiveMinor">
            Remove
          </Button>
        </div>
      )}
    </span>
  );
};

export default ManagePhoneNumberButton;
