import React from 'react';
import { Icon } from '@holokit/core';

function ClearAcknowledgedBanner(props) {
  const { show, colspan, loadMore, close } = props;
  if (show)
    return (
      <tr>
        <td className="AlertsTable__banner__row" colSpan={colspan}>
          <div className="AlertsTable__banner">
            <div className="AlertsTable__banner__content-container">
              <div>
                There are more events to review.
                <span onClick={loadMore} onKeyUp={loadMore} role="button" tabIndex={0}>
                  Clear acknowledged and load more.
                </span>
              </div>
              <div
                onClick={close}
                onKeyUp={close}
                role="button"
                tabIndex={0}
                className="AlertsTable__banner__close"
              >
                <Icon name="Close" size="micro" svgProps={{ style: { fill: '#ffffff' } }} />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );

  return null;
}

export default ClearAcknowledgedBanner;
