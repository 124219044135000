import { exportCSV, exportInspectCSV } from './exportCSV';

const exportData = ({ apiCall, exporting, setExporting, reportFilters, fromInspect = false }) => {
  if (exporting) return;

  setExporting(true);

  const exportFn = fromInspect ? exportInspectCSV : exportCSV;

  const MAX_BATCHES = 3;
  let currentBatch = 0;
  let loadedData = [];

  const getBatch = () =>
    apiCall(reportFilters)
      .then((result) => {
        currentBatch += 1;
        loadedData = loadedData.concat(result);

        if (result.continues && currentBatch < MAX_BATCHES) {
          getBatch();
        } else if (result.continues && currentBatch > MAX_BATCHES) {
          // We need some kind of error state here
        } else {
          exportFn(loadedData);
        }
      })
      .catch(() => {
        // We need some kind of error state here, too
      })
      .finally(() => {
        setExporting(false);
      });

  getBatch();
};

export default exportData;
