import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import React from 'react';
import { openModal } from '../../reducers/modal/actions';
import { getUserPermissions } from '../../reducers/organization/selectors';
import { BILLING } from '../../utils/permissions';
import Money from '../Money';

interface AccountBalanceProps {
  balanceModel: any;
}
// TODO: https://www.figma.com/file/KePYy3sNG1xhSkemUIvujY/Billing?type=design&node-id=495-12542&mode=dev

const AccountBalance: React.FC<AccountBalanceProps> = ({ balanceModel }) => {
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector(getUserPermissions);
  const { currentBalance, pendingCharges, promoBalance, availableBalance } = balanceModel;

  return (
    <div className="AccountBalance">
      <Panel
        header="Account balance"
        footer="Add a one-time balance recharge via your credit card or a promo code"
        footerActions={
          userPermissions.includes(BILLING) && (
            <div className="btns">
              <Button
                onClick={() => dispatch(openModal('AddBalanceModal', {}, 'narrow'))}
                variant="secondary"
              >
                Add balance
              </Button>
              <Button
                onClick={() => dispatch(openModal('ApplyPromoCodeModal', {}, 'narrow'))}
                variant="secondary"
              >
                Apply promo code
              </Button>
            </div>
          )
        }
        footerVariant="alternative"
      >
        <div className="container Panel__padding">
          <div className="side left-side">
            <div className="vertical-middle">
              <div className="balance-desc">Available balance</div>
              <h2
                className={`available-balance balance-${
                  availableBalance < 0 ? 'negative' : 'positive'
                } `}
              >
                <Money amount={availableBalance} />
              </h2>
            </div>
          </div>
          <div className="side right-side">
            <table className="no-styling">
              <tbody>
                <tr>
                  <td className="type-details">Original balance</td>
                  <td className="type-details imp">
                    <Money amount={currentBalance} />
                  </td>
                </tr>
                <tr>
                  <td className="type-details">Pending charges</td>
                  <td className="type-details imp">
                    (<Money amount={pendingCharges} />)
                  </td>
                </tr>
                <tr className="last-row">
                  <td className="type-details">Promotional credit</td>
                  <td className="type-details imp">
                    <Money amount={promoBalance} />
                  </td>
                </tr>
                <tr className="ending-row">
                  <td className="type-details">Available balance</td>
                  <td className="type-details">
                    <Money amount={availableBalance} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default AccountBalance;
