import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { ComplexIcon, Panel } from '@holokit/core';
import _classnames from 'clsx';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropdownMenu, HoloHelmet, PageLoadingErrorScreen } from '../../common/components';
import {
  getActiveInvoices,
  getBillingStatements,
} from '../../common/reducers/organization/actions';
import {
  getInvoicesForInvoiceList,
  getStatementsByBillingPeriod,
} from '../../common/reducers/organization/selectors';
import { addCommasToNumber } from '../../common/utils/numberFormatter';

interface InvoicesProps {
  getBillingStatements_: typeof getBillingStatements;
  getActiveInvoices_: typeof getActiveInvoices;
  invoices: Array<any>;
  statementsByBillingPeriod: Array<any>;
}

interface InvoicesState {
  pageError: any;
  isLoading: boolean;
}

class Invoices extends PureComponent<InvoicesProps, InvoicesState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pageError: null,
    };

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Billing - Invoices' },
    });
  }

  componentDidMount() {
    const { getBillingStatements_, getActiveInvoices_ } = this.props;

    Promise.all([getActiveInvoices_(), getBillingStatements_()])
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ pageError: e, isLoading: false });
      });
  }

  static getInvoiceStatusElement(invoice) {
    const { status, due_date: dueDate, status_transitions: statusTransitions } = invoice;
    let dateText: string | null = null;
    let messageIcon: any = null;
    let messageText: string | null = null;

    if (status === 'paid') {
      const readableDate = moment.unix(statusTransitions.paid_at).format('MMMM DD');

      messageIcon = <Icon name="CircleCheckmark" fill="DdsColorFillNotificationSuccess" />;
      messageText = 'Paid';
      dateText = `On ${readableDate}`;
    } else {
      const isOverdue = moment.unix(dueDate).diff(new Date()) < 0;
      const readableDate = moment.unix(dueDate).format('MMMM DD');

      if (isOverdue) {
        messageIcon = <Icon name="CircleAlert" fill="DdsColorFillNotificationError" />;
        messageText = 'Overdue';
      } else {
        messageIcon = (
          <div className="BillingTable__invoice-status__container__message__empty-icon" />
        );
        messageText = 'Unpaid';
      }
      dateText = `Due ${readableDate}`;
    }

    return (
      <div className="BillingTable__invoice-status__container">
        <div className="BillingTable__invoice-status__container__message">
          {messageIcon}
          <div className="BillingTable__invoice-status__container__message__text">
            {messageText}
          </div>
        </div>
        <div className="BillingTable__invoice-status__container__date">{dateText}</div>
      </div>
    );
  }

  render() {
    const { pageError, isLoading } = this.state;
    const { invoices, statementsByBillingPeriod } = this.props;

    return (
      <div className="BillingTable">
        <HoloHelmet title="Invoices" />
        {pageError ? (
          <PageLoadingErrorScreen error={pageError} />
        ) : (
          <>
            <Panel largeTitle noBodyPadding title="Invoices" well>
              <p>
                After each calendar month, an invoice will become available with detailed breakdowns
                of your spending.
              </p>
            </Panel>
            <Panel
              headerBorder={false}
              noBodyPadding
              title="Monthly invoices"
              isLoading={isLoading}
            >
              {invoices.length === 0 ? (
                <div className="BillingTable__empty">
                  <div className="BillingTable__empty__icon">
                    <ComplexIcon name="missing-document" />
                  </div>
                  <div className="BillingTable__empty__headline">No invoices available</div>
                </div>
              ) : (
                <table className="UsageTable Invoices__table">
                  <thead>
                    <tr className="header">
                      <th className="BillingTable__table__cell">Billing Period</th>
                      <th className="BillingTable__table__cell">Amount</th>
                      <th className="BillingTable__table__cell" colSpan={2}>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="BillingTable__table__body">
                    {!isLoading &&
                      invoices.map((invoice) => {
                        const {
                          id,
                          total,
                          metadata,
                          hosted_invoice_url: hostedInvoiceUrl,
                          invoice_pdf: invoicePdf,
                          paid,
                        } = invoice;

                        const readableDate = moment(metadata.billing_month, 'YYYY-MM').format(
                          'MMMM YYYY'
                        );

                        const relatedStatement = statementsByBillingPeriod[metadata.billing_month];

                        return (
                          <tr key={id} className="BillingTable__table__row">
                            <td className="BillingTable__table__cell BillingTable__table__invoice-cell-short">
                              {readableDate && readableDate !== 'Invalid date' ? readableDate : '-'}
                            </td>
                            <td className="BillingTable__table__cell BillingTable__table__invoice-cell-short">
                              ${addCommasToNumber(Math.abs(total / 100))}
                            </td>
                            <td className="BillingTable__table__cell BillingTable__table__invoice-cell-tall">
                              {Invoices.getInvoiceStatusElement(invoice)}
                            </td>
                            <td className="BillingTable__table__cell BillingTable__table__invoice-cell-short">
                              <div className="BillingTable__table__cell__contentWrapper">
                                {(relatedStatement || invoicePdf) && (
                                  <DropdownMenu
                                    button={
                                      <Button iconEnd="ChevronSingleSouth" variant="secondary">
                                        Download
                                      </Button>
                                    }
                                    direction="down"
                                  >
                                    <div className="gutter" />
                                    {invoicePdf && (
                                      <a
                                        className={_classnames('dropdown-button no-flex', {
                                          disabled: !invoicePdf,
                                        })}
                                        target="_blank"
                                        href={invoicePdf}
                                        rel="noreferrer"
                                      >
                                        <div className="grid-row v-align">
                                          <div className="grid-item Font__body Font__body Font__body--20 Font__color--text">
                                            Monthly invoice
                                          </div>
                                          <div className="Font__color--light Font__caption Font__caption--10">
                                            PDF
                                          </div>
                                        </div>
                                      </a>
                                    )}
                                    {relatedStatement && (
                                      <a
                                        className={_classnames('dropdown-button no-flex', {
                                          disabled: relatedStatement,
                                        })}
                                        target="_blank"
                                        href={`${process.env.VITE_API_ROOT}/organizations/${relatedStatement.orgid}/statements/${relatedStatement.id}/csv`}
                                        rel="noreferrer"
                                      >
                                        <div className="grid-row v-align">
                                          <div className="grid-item">
                                            <div className="grid-item Font__body Font__body Font__body--20 Font__color--text">
                                              Billing statement
                                            </div>
                                            <div className="Font__body Font__body--10 Font__color--light">
                                              Detailed usage report
                                            </div>
                                          </div>
                                          <div className="grid-row v-align">
                                            <div className="Font__color--light Font__caption Font__caption--10">
                                              CSV
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    )}
                                    <div className="gutter" />
                                  </DropdownMenu>
                                )}
                                {hostedInvoiceUrl && !paid && (
                                  <Button href={hostedInvoiceUrl}>Pay Invoice</Button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </Panel>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: getInvoicesForInvoiceList(state) || [],
  statementsByBillingPeriod: getStatementsByBillingPeriod(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getActiveInvoices_: getActiveInvoices,
      getBillingStatements_: getBillingStatements,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
