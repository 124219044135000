import React from 'react';

function ActivityHistoryDetailsSkeletonTitle() {
  return (
    <div className="ActivityHistoryDetails__title" data-testid="title-skeleton">
      <div className="ActivityHistoryDetails__title-metadata">
        <div className="ActivityHistoryDetails__title-metadata--skeleton" />
        <div className="ActivityHistoryDetails__byline--skeleton" />
      </div>
    </div>
  );
}

export default ActivityHistoryDetailsSkeletonTitle;
