import { Picture } from '@hologram-hyper-dashboard/components';
import { Button, Checkbox, ComplexIcon, Icon, Loader } from '@holokit/core';
import _classnames from 'clsx';
import { ensureDevicesPersisted } from 'common-js/reducers/devices/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import scrollTo from 'common-js/utils/dom/scrollTo';
import dataLimitImg from 'img/alerts/datalimitalerticon.webp?&imagetools';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as alertTypes from '../../common/constants/alertTypes';
import { setActiveAlert, setActivePin } from '../../common/reducers/alerts/actions';
import AlertsTableContainer from '../containers/TableContainer';
import AlertsActionItemsAd from './AlertsActionItemsAd';
import ClearAcknowledgedBanner from './ClearAcknowledgedBanner';
import DeviceDataLimitAlertsTableRow from './DeviceDataLimitAlertsTableRow';
import LoadMoreFooter from './LoadMoreFooter';
import SelectMultipleToolbar from './SelectMultipleToolbar';
import withPlanHooksHoc from './withPlanHooksHoc';

class DeviceDataLimitAlertsTable extends Component {
  constructor(props) {
    super(props);
    this.scrollWrap = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { alerts } = this.props;

    if (
      prevProps.alerts &&
      alerts &&
      prevProps.alerts.length &&
      alerts.length &&
      prevProps.alerts.length !== alerts.length
    ) {
      this.scrollToNewAlerts(prevProps.alerts.length);
    }
  }

  componentWillUnmount() {
    const { setActiveAlert_, setActivePin_ } = this.props;
    setActiveAlert_(null, alertTypes.DEVICE_DATA_LIMIT);
    setActivePin_(null, alertTypes.DEVICE_DATA_LIMIT);
  }

  scrollToNewAlerts = (lastAlert) => {
    const { alerts } = this.props;
    const row = document.getElementById(`data-limit-alert-${alerts[lastAlert].id}`);

    if (row) {
      scrollTo(
        row.offsetTop - this.scrollWrap.current.offsetTop,
        () => {},
        200,
        this.scrollWrap.current
      );
    }
  };

  openBulkDataLimitModal = async () => {
    const { limitedBulkActionAvailable, openModal_, selectedDevices, ensureDevicesPersisted_ } =
      this.props;

    if (limitedBulkActionAvailable) {
      await ensureDevicesPersisted_(selectedDevices);
      openModal_('BulkDataUsageLimitsModal', { deviceOverrides: selectedDevices });
    }
  };

  openDataLimitModal = async (deviceId) => {
    const { openModal_, ensureDevicesPersisted_ } = this.props;

    await ensureDevicesPersisted_([deviceId]);
    openModal_('BulkDataUsageLimitsModal', { deviceOverrides: [deviceId] });
  };

  render() {
    const {
      activeAlertId,
      alertCount,
      ackedAlertIds,
      alerts,
      allSelected,
      deselectAlerts,
      deviceCount,
      dismissClearBanner,
      dismissClearBannerAndLoadMore,
      goToNextPage,
      handleAcknowledgeAlerts,
      handleAcknowledgeSingleAlert,
      handleHideEducationBanner,
      handlePlanChange,
      handleSingleSelection,
      handleUnacknowledgeSingleAlert,
      isInitialPageLoad,
      isLoading,
      limitedBulkActionAvailable,
      nextPageEnabled,
      pageHeight,
      releaseFlags,
      selectAlerts,
      selectAll,
      selectedAlerts,
      selectedDevices,
      showClearAndLoadMore,
      showEducationHeader,
      theme,
      updateTheme,
      setAllUnloadedAlertsSelected,
      allUnloadedAlertsSelected,
    } = this.props;
    const colspan = theme === 'multi-select' ? '5' : '4';
    const isUnfurled = !!activeAlertId;

    return (
      <div
        className={_classnames('AlertsTable__wrapper', {
          'AlertsTable__wrapper--expanded': theme === 'multi-select',
        })}
        id="AlertsTable__wrapper"
        style={{ minHeight: pageHeight }}
      >
        {!isInitialPageLoad && deviceCount === 0 && <AlertsActionItemsAd />}
        {!isInitialPageLoad && alerts.length === 0 ? (
          <div className="AlertsTable__no-alerts-container">
            <ComplexIcon name="green-ribbon" />
            <div>All is well. None of your</div>
            <div>devices require attention.</div>
          </div>
        ) : (
          <div className={`AlertsTable AlertsTable--column4 AlertsTable--${theme}`}>
            <Loader isLoading={isInitialPageLoad || isLoading} />
            <div className="AlertsTable__header">
              <div className="AlertsTable__header__count">
                {isInitialPageLoad ? '' : alertCount}
              </div>
              <div className="AlertsTable__header__headline">
                <h3>
                  <span>Data usage limit reached</span>
                </h3>
              </div>
              <div className="AlertsTable__header__cta">
                {theme === 'default' ? (
                  <>
                    {alerts.length ? (
                      <Button
                        disabled={isUnfurled}
                        iconLeft="Checkmark--double"
                        onClick={handleAcknowledgeAlerts}
                        type="secondary"
                      >
                        {alerts.length === alertCount
                          ? 'Acknowledge All'
                          : `Acknowledge ${alerts.length}`}
                      </Button>
                    ) : null}
                    <Button
                      disabled={isUnfurled}
                      iconLeft="Select"
                      onClick={() => updateTheme('multi-select')}
                      type="secondary"
                    >
                      Select Multiple
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={
                        !selectedDevices.length ||
                        !limitedBulkActionAvailable ||
                        (allUnloadedAlertsSelected && alerts.length !== alertCount)
                      }
                      iconLeft="Gauge"
                      onClick={this.openBulkDataLimitModal}
                      type="reversed"
                    >
                      Update Overage
                    </Button>
                    {alerts.length ? (
                      <Button
                        iconLeft="Checkmark--double"
                        onClick={() => handleAcknowledgeAlerts()}
                        disabled={
                          !selectedDevices.length ||
                          selectedAlerts.every((alert) => ackedAlertIds.includes(alert))
                        }
                        type="reversed"
                      >
                        {allUnloadedAlertsSelected
                          ? `Acknowledge ${alertCount}`
                          : `Acknowledge ${selectedAlerts.length}`}
                      </Button>
                    ) : null}
                    <div className="AlertsTable__header__cta-divider" />
                    <Button
                      iconLeft="Select"
                      onClick={() => updateTheme('default')}
                      type="secondary"
                    >
                      Done
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="AlertsTable__scroll-wrap">
              <table
                className={_classnames('AlertsTable__body', {
                  'AlertsTable__body--multi-select': theme === 'multi-select',
                })}
              >
                {!isInitialPageLoad ? (
                  <>
                    <thead className="AlertsTable__section AlertsTable__section--header">
                      <tr className="AlertsTable__row AlertsTable__row">
                        {theme === 'multi-select' && (
                          <th className="AlertsTable__cell AlertsTable__cell__select">
                            <div className="AlertsTable__cell__content">
                              <Checkbox
                                checked={allSelected}
                                inputProps={{
                                  id: 'select-all',
                                  name: 'select-all',
                                }}
                                onChange={selectAll}
                                indeterminate={selectedDevices.length && !allSelected}
                              />
                            </div>
                          </th>
                        )}
                        <th className="AlertsTable__cell AlertsTable__cell--sortable">
                          <div className="AlertsTable__cell__content">
                            Timestamp
                            <Icon
                              name="Arrow--triangle--south"
                              size="micro"
                              svgProps={{ style: { fill: '#606677' } }}
                            />
                          </div>
                        </th>
                        <th className="AlertsTable__cell">
                          <div className="AlertsTable__cell__content">Affected Device</div>
                        </th>
                        <th className="AlertsTable__cell AlertsTable__cell--overage">
                          <div className="AlertsTable__cell__content">Overage</div>
                        </th>
                        <th className="AlertsTable__cell">
                          <div className="AlertsTable__cell__content">Billing Period</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      className="AlertsTable__section AlertsTable__section--body"
                      ref={this.scrollWrap}
                    >
                      <ClearAcknowledgedBanner
                        colSpan={colspan}
                        close={dismissClearBanner}
                        show={showClearAndLoadMore}
                        loadMore={dismissClearBannerAndLoadMore}
                      />
                      {showEducationHeader && (
                        <tr>
                          <td colSpan={colspan}>
                            <div className="AlertsTable__education-header">
                              <Picture image={dataLimitImg} alt="Data Limit Icon" />
                              <span className="AlertsTable__education-header__title">
                                SIMs that reached their Data usage limit
                              </span>
                              <div className="AlertsTable__education-header__description">
                                These Hologram SIMs reached their data usage limit and were
                                automatically paused after the active data session was closed to
                                prevent data loss.
                              </div>
                              <div
                                className="AlertsTable__education-header__icon-container"
                                onClick={handleHideEducationBanner}
                                onKeyUp={handleHideEducationBanner}
                                role="button"
                                tabIndex={0}
                              >
                                <Icon
                                  name="Close"
                                  size="minor"
                                  svgProps={{ style: { fill: '#040815' } }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                      {alerts.map((alert, idx) => (
                        <DeviceDataLimitAlertsTableRow
                          alert={alert}
                          colspan={colspan}
                          deselectAlerts={deselectAlerts}
                          handleAcknowledgeSingleAlert={handleAcknowledgeSingleAlert}
                          handleSingleSelection={handleSingleSelection}
                          handlePlanChange={handlePlanChange}
                          handleUnacknowledgeSingleAlert={handleUnacknowledgeSingleAlert}
                          isFirstRow={idx === 0}
                          key={alert?.id ?? idx}
                          openDataLimitModal={this.openDataLimitModal}
                          releaseFlags={releaseFlags}
                          resizeAlertsTableHeader={(width) => this.resizeAlertsTableHeader(width)}
                          scrollWrap={this.scrollWrap}
                          selectAlerts={selectAlerts}
                          selectedAlerts={selectedAlerts}
                          theme={theme}
                        />
                      ))}
                    </tbody>
                  </>
                ) : null}
              </table>
            </div>
            {theme === 'multi-select' ? (
              <SelectMultipleToolbar
                selectedAlerts={selectedAlerts}
                loaded={alerts.length}
                alertCount={alertCount}
                setAllUnloadedAlertsSelected={setAllUnloadedAlertsSelected}
                selectAll={selectAll}
                deselectAlerts={deselectAlerts}
                allUnloadedAlertsSelected={allUnloadedAlertsSelected}
              />
            ) : (
              <LoadMoreFooter
                continues={nextPageEnabled}
                loaded={alerts.length}
                total={alertCount}
                loadMore={goToNextPage}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal_: openModal,
      setActiveAlert_: setActiveAlert,
      setActivePin_: setActivePin,
      ensureDevicesPersisted_: ensureDevicesPersisted,
    },
    dispatch
  );

const DeviceDataLimitAlertsTableHoC = connect(
  null,
  mapDispatchToProps
)(withPlanHooksHoc(AlertsTableContainer(alertTypes.DEVICE_DATA_LIMIT)(DeviceDataLimitAlertsTable)));

export default DeviceDataLimitAlertsTableHoC;
