import { Button } from '@hologram-dimension/button';
import { CheckboxButton, Dropdown, Panel } from '@holokit/core';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { OrgModel } from 'common-js/models';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { openModal } from 'common-js/reducers/modal/actions';
import {
  cancelInvitation,
  getOrganizations,
  removeUsers,
  resendInvitation,
  updateUserPermissions,
} from 'common-js/reducers/organization/actions';
import { getSelectedOrg, getUserPermissions } from 'common-js/reducers/organization/selectors';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';
import {
  translatePermissionsToRole,
  translateRoleToPermissions,
} from 'common-js/utils/permissions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CollaboratorItem from './CollaboratorItem';
import PendingUserItem from './PendingUserItem';

class CollaboratorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIds: [],
      collabSelectedCount: 0,
    };
  }

  onCollabSelectChange = (id, checked) => {
    const { selectedIds } = this.state;

    const newSelectedIds = selectedIds;

    if (checked) {
      newSelectedIds.push(id);
    } else {
      newSelectedIds.splice(newSelectedIds.indexOf(id), 1);
    }

    this.setState({ selectedIds: newSelectedIds });
  };

  onSelectAllChange = () => {
    const { collabs } = this.props;

    this.setState({
      selectedIds: !this.areAllUsersSelected() ? collabs.map((user) => user.id) : [],
    });
  };

  onRemoveUsers = () => {
    const { removeUsers_, pushGlobalMessage_ } = this.props;
    const { selectedIds } = this.state;

    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you'd like to remove these users?")) {
      removeUsers_(selectedIds).catch((e) => {
        pushGlobalMessage_(e, 'error');
      });
    }
  };

  changeRoles = (role) => {
    const { updateUserPermissions_, pushGlobalMessage_ } = this.props;
    const { selectedIds } = this.state;

    updateUserPermissions_(selectedIds, translateRoleToPermissions(role)).catch((e) => {
      pushGlobalMessage_(e, 'error');
    });
  };

  areAllUsersSelected = () => {
    const { selectedIds } = this.state;
    const { collabs } = this.props;

    if (selectedIds.length > 0 && selectedIds.length === collabs.length) {
      return true;
    }

    return false;
  };

  handleInviteCollaboratorsClick = () => {
    const { openModal_ } = this.props;
    openModal_('InviteCollaboratorsModal', null, 'InviteCollaboratorsModal');
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Permissions - Invite Collaborators',
        origin: 'Settings Page Modal',
      },
    });
  };

  handleSelectAllClick = () => {
    this.onSelectAllChange();
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Permissions - Select All Collaborators',
      },
    });
  };

  handleManageAccessClick = (role) => {
    this.changeRoles(role);
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Permissions - Team manage access',
        role,
      },
    });
  };

  handleCollabSelectClick = (id, checked) => {
    this.onCollabSelectChange(id, checked);
    const { collabSelectedCount } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      collabSelectedCount: prevState.collabSelectedCount + 1,
    }));
    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: 'Permissions - Select Individual Checkbox',
        'collaborators selected': collabSelectedCount + 1,
      },
    });
  };

  render() {
    const {
      currentOrg,
      resendInvitation_,
      cancelInvitation_,
      pushGlobalMessage_,
      getOrganizations_,
      updateUserPermissions_,
      removeUsers_,
      releaseFlags,
      userPermissions,
      collabs,
      owner,
    } = this.props;
    const { pendingUsers } = currentOrg;
    const { selectedIds } = this.state;

    const hasEditorLimited = releaseFlags?.add_editor_limited_role;
    const isAdmin = translatePermissionsToRole(userPermissions) === 'admin';

    const topBar = (
      <div className="grid-row top-bar CollaboratorList__headerItems">
        {isAdmin && (
          <>
            <CheckboxButton
              checked={this.areAllUsersSelected()}
              classes="CollaboratorList__header-button"
              onClick={this.handleSelectAllClick}
              type="secondary"
            >
              Select all
            </CheckboxButton>
            <Dropdown
              classes="CollaboratorList__header-button"
              disabled={selectedIds.length === 0}
              iconLeft="Gear"
              dropdownText="Manage access"
              items={[
                {
                  children: 'Admin',
                  caption:
                    'Assign roles, activate and deactivate devices, change plans, manage billing',
                  onClick: () => this.handleManageAccessClick('admin'),
                  sectionId: 1,
                  closeAfterClick: true,
                },
                {
                  children: 'Manager',
                  caption:
                    'Activate and deactivate devices, change plans, access limited billing details',
                  onClick: () => this.handleManageAccessClick('manager'),
                  sectionId: 2,
                  closeAfterClick: true,
                },
                {
                  children: 'Editor',
                  caption:
                    'View devices and cloud messaging, manage device settings and app integrations, access limited billing details',
                  onClick: () => this.handleManageAccessClick('editor'),
                  sectionId: 3,
                  closeAfterClick: true,
                },
                ...(hasEditorLimited
                  ? [
                      {
                        children: 'Editor Limited',
                        caption:
                          'View devices and cloud messaging, manage device settings and app integrations',
                        onClick: () => this.handleManageAccessClick('editor_limited'),
                        sectionId: 4,
                        closeAfterClick: true,
                      },
                    ]
                  : []),
                {
                  children: 'Remove',
                  destructive: true,
                  onClick: this.onRemoveUsers,
                  sectionId: hasEditorLimited ? 5 : 4,
                  closeAfterClick: true,
                },
              ]}
            />
          </>
        )}

        <Button onClick={this.handleInviteCollaboratorsClick} variant="secondary">
          Invite collaborators
        </Button>
      </div>
    );

    return (
      <Panel title="Team" classes="CollaboratorList" noBodyPadding headerButtons={topBar}>
        <div>
          {owner && <CollaboratorItem col={owner} isOwner />}
          {collabs
            .filter((col) => !!col)
            .map((col) => (
              <CollaboratorItem
                key={`CollaboratorItem-${col.id}`}
                col={col}
                isSelected={selectedIds.includes(col.id)}
                onCollabSelectChange={this.handleCollabSelectClick}
                currentOrg={currentOrg}
                pushGlobalMessage={pushGlobalMessage_}
                updateUserPermissions={updateUserPermissions_}
                removeUsers={removeUsers_}
                hasEditorLimited={hasEditorLimited}
                isAdmin={isAdmin}
              />
            ))}
          {pendingUsers.map((pendingUser) => (
            <PendingUserItem
              key={`PendingUserItem-${pendingUser.inviteid}`}
              pendingUser={pendingUser}
              resendInvitation={resendInvitation_}
              cancelInvitation={cancelInvitation_}
              pushGlobalMessage={pushGlobalMessage_}
              getOrganizations={getOrganizations_}
              isAdmin={isAdmin}
            />
          ))}
        </div>
      </Panel>
    );
  }
}

export default connect(
  (state) => {
    const currentOrg = getSelectedOrg(state) || new OrgModel();

    return {
      currentOrg,
      collabs: currentOrg.users.filter((user) => user.id !== currentOrg.ownerid),
      owner: currentOrg.users.filter((user) => user.id === currentOrg.ownerid)[0],
      releaseFlags: getReleaseFlags(state),
      userPermissions: getUserPermissions(state),
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        openModal_: openModal,
        resendInvitation_: resendInvitation,
        cancelInvitation_: cancelInvitation,
        pushGlobalMessage_: pushGlobalMessage,
        getOrganizations_: getOrganizations,
        updateUserPermissions_: updateUserPermissions,
        removeUsers_: removeUsers,
      },
      dispatch
    )
)(CollaboratorList);
