import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { InlineNotification } from '@hologram-dimension/inline-notification';
import { RadioField } from '@hologram-dimension/radio-field';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { Loader } from '@holokit/core';
import { DEACTIVATE_SIM } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import {
  BULK_DEACTIVATE_DEVICES,
  BULK_DEACTIVATE_DEVICES_PREVIEW_REQUEST,
} from 'common-js/reducers/devices/actionTypes';
import { clearSelection, fetchDevices, getTasks } from 'common-js/reducers/devices/actions';
import { TASK_START_DATE } from 'common-js/reducers/devices/reducer';
import { getSelectedLoadedDevices } from 'common-js/reducers/devices/selectors';
import { selectHasActivityHistory } from 'common-js/reducers/releaseFlag/selectors';
import { BulkChangeStatePreviewSummary, Device } from 'common-js/types/Device';
import type {
  useBulkChangeStateEvent,
  useBulkChangeStatePreviewEvent,
} from 'devices/ManageDropdown/useItems/useBulkChangeState';
import Moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalHeaderWithIcon } from '../components';
import { close as closeModal_ } from '../reducers/modal/actions';
import { SuccessModal } from './devices';
import LinksSection from './devices/LinksSection';
import LoadingChipsSection from './devices/LoadingChipsSection';

const NUM_CHIPS = 10;
const EMPTY_LABEL_VALUE = <>&mdash;</>;

interface DeactivateSimModalProps {
  bulkPreview?: BulkChangeStatePreviewSummary;
  closeModal: typeof closeModal_;
  activeDeviceCount: number;
  devices?: Array<Device>;
  action: ReturnType<typeof useBulkChangeStateEvent>;
  getPreview?: ReturnType<typeof useBulkChangeStatePreviewEvent>;
  isLoading?: boolean;
  fetchDevices_: typeof fetchDevices;
  getTasks_: typeof getTasks;
  clearSelection_: typeof clearSelection;
  hasActivityHistory: boolean;
  supportsPreview?: boolean;
}

interface DeactivateSimModalState {
  confirmationTextFieldValue: string;
  deactivateReason: string;
  deactivateTextFieldValue: string;
  otherDeactivationReason: string;
  deactivating: boolean;
  deactivateButtonText: string;
  lastTimeout?: number;
  deviceIdsWontBeModified: Array<DeviceId>;
  runningAction: boolean;
  success: boolean;
  error: string;
}

// TODO: This should be hooks and RHF

class DeactivateSimModal extends Component<DeactivateSimModalProps, DeactivateSimModalState> {
  static getLastConnectedLabel = (device: Device) => {
    // 1. Connected right now with open session: "Right Now" with session start time (hide end time)
    // 2. Connected recently with closed session: "X mins/hours/days ago" (from session end) with session start/end time.
    // 3. Never connected: empty state with — for start/end times. (hide end time just show start dash)
    const sessionBegin = device.lastsession && device.lastsession.session_begin;
    const sessionEnd = device.lastsession && device.lastsession.session_end;

    if (sessionBegin && !sessionEnd) {
      return 'Right Now';
    }
    if (sessionBegin && sessionEnd) {
      if (Moment(sessionBegin).isValid() && Moment(sessionEnd).isValid()) {
        const lastConnectedTime = Moment.utc(sessionEnd).local().fromNow();
        return lastConnectedTime;
      }
      return EMPTY_LABEL_VALUE;
    }

    return EMPTY_LABEL_VALUE;
  };

  constructor(props: DeactivateSimModalProps) {
    super(props);
    this.state = {
      confirmationTextFieldValue: '',
      deactivateReason: '',
      deactivateTextFieldValue: '',
      otherDeactivationReason: '',
      deactivateButtonText: 'Hold to deactivate',
      deactivating: false,
      deviceIdsWontBeModified: [],
      runningAction: false,
      success: false,
      error: '',
    };
  }

  componentDidMount(): void {
    const { getPreview } = this.props;

    if (!getPreview) {
      return;
    }

    getPreview()
      .then((result) => {
        this.setState({
          deviceIdsWontBeModified: result.deviceIdsWontBeModified,
        });
      })
      .catch((error) => {
        this.setState({ error: `Preview failed: ${error}` });
      });
  }

  onDeactivateReasonUpdate = (e) => {
    const reason = e.target.value;
    this.setState({ deactivateReason: reason });
  };

  onOtherDeactivateReasonUpdate = (e) => {
    this.setState({ otherDeactivationReason: e.target.value });
  };

  onConfirmationTextFieldChange = (e) => {
    this.setState({ confirmationTextFieldValue: e.target.value });
  };

  onDeleteTextFieldChange = (e) => {
    this.setState({ deactivateTextFieldValue: e.target.value });
  };

  sendAnalytics = () => {
    const { activeDeviceCount, bulkPreview } = this.props;
    const { deactivateReason, otherDeactivationReason } = this.state;
    const deactivationReason =
      deactivateReason === 'Other'
        ? `${deactivateReason} - ${otherDeactivationReason}`
        : deactivateReason;
    const devicesLength = bulkPreview?.validDeviceCount ?? 0;
    sendAnalyticsEvent({
      type: DEACTIVATE_SIM,
      data: {
        amount: devicesLength,
        reason: deactivationReason,
        new_active_device_count: activeDeviceCount - devicesLength,
      },
    });
  };

  handleSuccess = () => {
    const { action, fetchDevices_, getTasks_, clearSelection_, hasActivityHistory } = this.props;
    const { deviceIdsWontBeModified } = this.state;

    if (!action) {
      return;
    }

    this.setState({
      runningAction: true,
      deactivateButtonText: 'Submitting deactivate...',
      error: '',
    });

    const result = action({ deviceIdsWontBeModified });

    // Backwards compatibility with TAC (their action doesn't have a promise)
    if (typeof result?.then === 'function') {
      result
        .then(() => {
          clearSelection_();
          this.sendAnalytics();
          // If the user doesn't have activity history, they don't have bulk tasks either
          if (hasActivityHistory) getTasks_(TASK_START_DATE);
          fetchDevices_();
          this.setState({
            success: true,
            runningAction: false,
          });
        })
        .catch((reason) => {
          this.setState({
            success: false,
            runningAction: false,
            deactivateButtonText: 'Hold to deactivate',
            error: reason,
          });
        });
    }
  };

  onBeginDeactivate = () => {
    const lastTimeout = window.setTimeout(this.handleSuccess, 2000);
    this.setState((prev) => {
      if (prev.lastTimeout) {
        window.clearTimeout(prev.lastTimeout);
      }
      return {
        deactivating: true,
        deactivateButtonText: 'Keep holding to deactivate...',
        lastTimeout,
      };
    });
  };

  onEndDeactivate = () => {
    const { lastTimeout } = this.state;
    if (lastTimeout) {
      window.clearTimeout(lastTimeout);
    }

    this.setState({
      deactivating: false,
      deactivateButtonText: 'Hold to deactivate',
      lastTimeout: undefined,
    });
  };

  handleBackToDashboardClick = () => {
    this.closeConfirmationModal();
  };

  handleCancelClick = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  closeConfirmationModal = () => {
    const { closeModal } = this.props;

    closeModal();

    const triggerButton = document.querySelector<HTMLButtonElement>(
      '.TaskPopoverWrapper .bp4-popover-target'
    );
    if (triggerButton) {
      triggerButton.click();
    }
  };

  render() {
    const { devices, bulkPreview, isLoading, supportsPreview = false } = this.props;
    const {
      confirmationTextFieldValue,
      deactivateReason,
      deactivateTextFieldValue,
      otherDeactivationReason,
      deactivating,
      deactivateButtonText,
      runningAction,
      success,
      error,
    } = this.state;

    const {
      validDeviceCount,
      validDevicesSample,
      invalidDeviceCount,
      invalidDevicesSample,
      error: bulkPreviewError,
    } = supportsPreview && bulkPreview
      ? bulkPreview
      : {
          error: '',
          validDeviceCount: devices?.length ?? 0,
          validDevicesSample: devices?.map((device) => device.id) ?? [],
          invalidDeviceCount: 0,
          invalidDevicesSample: [],
        };

    const isSingleDeactivate = validDeviceCount === 1;
    const [device] = devices ?? [];
    const simNumber = device?.iccid || device?.sim_number;
    const lastSimNumbers = simNumber ? simNumber.substring(simNumber.length - 4) : '';
    const deleteButtonEnabled =
      isLoading ||
      (isSingleDeactivate
        ? confirmationTextFieldValue === lastSimNumbers && deactivateReason !== ''
        : confirmationTextFieldValue === validDeviceCount.toString() &&
          deactivateReason !== '' &&
          deactivateTextFieldValue.toLowerCase() === 'deactivate');

    const errorMessage = error || bulkPreviewError || undefined;

    if (success) {
      return (
        <SuccessModal
          title="Deactivate request sent"
          icon="Lightning--slash"
          infoWell="Deactivate command has been issued to our carrier partners."
          onClose={this.handleBackToDashboardClick}
        />
      );
    }

    return (
      <div className="DeactivateSimModal">
        <Loader isLoading={isLoading || runningAction} />
        <ModalHeaderWithIcon
          title={`Deactivate ${isSingleDeactivate ? 'SIM' : 'SIMs'}`}
          iconName="Lightning--slash"
        />
        <div className="BulkDataActionModal__info-panel">
          <div>
            {isLoading
              ? 'Fetching SIMs...'
              : `Deactivation is a permanent process. Be careful to double check you have selected the correct ${
                  isSingleDeactivate ? 'SIM' : 'SIMs'
                }. Contact us with any questions.`}
          </div>
          <InlineNotification variant="caution">
            Deactivation is a permanent and irreversible process.{' '}
            <strong>SIMs cannot be reactivated.</strong>
          </InlineNotification>
          <InlineNotification variant="caution">
            SIMs will be deactivated immediately.
          </InlineNotification>
          <InlineNotification variant="caution">
            All deactivated SIMs will be moved to the &quot;Deactivated&quot; tab under the Devices
            page.
          </InlineNotification>
        </div>
        <div className="BulkDataActionModal__contents">
          {isLoading ? (
            <LoadingChipsSection numChips={NUM_CHIPS} />
          ) : (
            <>
              {invalidDeviceCount > 0 && (
                <LinksSection
                  title="SIMs unable to be deactivated"
                  warning
                  devices={
                    devices
                      ?.filter((deviceObj) => invalidDevicesSample.includes(deviceObj.id))
                      .map((deviceObj) => deviceObj.name) ?? invalidDevicesSample
                  }
                  total={invalidDeviceCount}
                  limit={NUM_CHIPS}
                />
              )}
              {validDeviceCount > 0 && (
                <>
                  <LinksSection
                    title="Selected SIMs"
                    devices={
                      devices
                        ?.filter((deviceObj) => validDevicesSample.includes(deviceObj.id))
                        .map((deviceObj) => deviceObj.name) ?? validDevicesSample
                    }
                    total={validDeviceCount}
                    limit={NUM_CHIPS}
                  />
                  <Form className="BulkDataActionModal__form">
                    <RadioField
                      label="Reason for deactivation"
                      name="deactivateReason"
                      onChange={this.onDeactivateReasonUpdate}
                      disabled={isLoading}
                      defaultValue=""
                      required
                      options={[
                        {
                          label: 'Lost, stolen, or damaged',
                          value: 'Lost/Stolen/Damaged',
                        },
                        {
                          label: 'Changing technologies',
                          value: 'Changing Technologies',
                        },
                        {
                          label: 'Project ended',
                          value: 'Project Ended',
                        },
                        {
                          label: 'Swapping network provider',
                          value: 'Swapping Network Provider',
                        },
                        {
                          label: 'Other',
                          value: 'Other',
                        },
                      ]}
                    />
                    {deactivateReason === 'Other' && (
                      <TextInputField
                        label="Why are you deactivating this device?"
                        optional
                        onChange={this.onOtherDeactivateReasonUpdate}
                        defaultValue={otherDeactivationReason}
                        placeholder="This device..."
                      />
                    )}
                    <div className="BulkDataActionModal__confirmation">
                      <span className="BulkDataActionModal__confirmationDesc">
                        Confirm the {isSingleDeactivate ? 'field' : 'two fields'} below, then hold
                        down the confirmation button to deactivate.
                      </span>
                      <div className="BulkDataActionModal__confirmationFields">
                        {!isSingleDeactivate && (
                          <TextInputField
                            label="Type DEACTIVATE"
                            required
                            onChange={this.onDeleteTextFieldChange}
                            placeholder="DEACTIVATE"
                            defaultValue={deactivateTextFieldValue}
                            className="BulkDataActionModal__confirmationField"
                          />
                        )}
                        <TextInputField
                          label={
                            isSingleDeactivate
                              ? `Type the last 4 digits of the SIM ID (${lastSimNumbers})`
                              : `Type the number of SIMs (${validDeviceCount})`
                          }
                          required
                          onChange={this.onConfirmationTextFieldChange}
                          placeholder={(isSingleDeactivate
                            ? lastSimNumbers
                            : validDeviceCount
                          ).toString()}
                          defaultValue={confirmationTextFieldValue}
                          className="BulkDataActionModal__confirmationField"
                        />
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </>
          )}
        </div>
        <div className="BulkDataActionModal__footer">
          {errorMessage && (
            <BannerNotification variant="system-error">{errorMessage}</BannerNotification>
          )}
          <Button variant="secondary" onClick={this.handleCancelClick} disabled={runningAction}>
            Cancel
          </Button>
          <Button
            disabled={!deleteButtonEnabled}
            loading={deactivating || runningAction}
            variant="destructive"
            onMouseDown={this.onBeginDeactivate}
            onMouseLeave={this.onEndDeactivate}
            onMouseUp={this.onEndDeactivate}
            className="BulkDataActionModal__deactivateButton"
          >
            {deactivateButtonText}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: any, props: DeactivateSimModalProps) => ({
    isLoading:
      BULK_DEACTIVATE_DEVICES_PREVIEW_REQUEST in (state.devices?.uiState?.loading ?? {}) ||
      BULK_DEACTIVATE_DEVICES in (state.devices?.uiState?.loading ?? {}),
    devices:
      props?.devices && props.devices.length > 0 ? props.devices : getSelectedLoadedDevices(state),
    bulkPreview: state.devices?.bulkPreview,
    activeDeviceCount: state.organization.deviceCountActive,
    hasActivityHistory: selectHasActivityHistory(state),
  }),
  (dispatch: any) =>
    bindActionCreators(
      {
        closeModal: closeModal_,
        clearSelection_: clearSelection,
        fetchDevices_: fetchDevices,
        getTasks_: getTasks,
      },
      dispatch
    )
)(DeactivateSimModal);
