import React, { useState, useRef } from 'react';
import { TextareaField } from '@hologram-dimension/textarea-field';
import { Button } from '@hologram-dimension/button';

const serialize = (input) => input.replace(/[,\n]/g, ' ').replace(/\s+/g, ' ').split(' ');

function SimInput({ onChange, onSubmit, clearOnSubmit = false, error }) {
  const [sim, setSim] = useState('');
  const inputRef = useRef();

  const handleChange = (e) => {
    const { value } = e.currentTarget;
    const validCharacters = /^[0-9 \n,]*$/;

    if (validCharacters.test(value)) {
      setSim(value);
    }

    if (typeof onChange === 'function') {
      onChange(sim);
    }
  };

  const handleSubmit = () => {
    if (typeof onSubmit === 'function') {
      const sims = serialize(sim);

      onSubmit(sims);
    }

    if (clearOnSubmit) {
      inputRef.current.value = '';
      setSim('');
    }
  };

  const KEYS = {
    ENTER: 13,
  };

  const handleKeyDown = (e) => {
    const { value } = e.currentTarget;

    if (e.keyCode === KEYS.ENTER) {
      e.preventDefault();
    }

    if (e.keyCode === KEYS.ENTER && sim.length > 17) {
      handleSubmit(value);
    }
  };

  const inputProps = {
    onKeyDown: handleKeyDown,
    onChange: handleChange,
  };

  return (
    <div className="SimInput__form">
      <TextareaField
        name="sim"
        defaultValue={sim}
        inputProps={inputProps}
        label="SIM numbers (ICCIDs)"
        helpText="Separate IDs with a comma, space, or line break"
        ref={inputRef}
      />
      <Button
        className="SimInput__form__button"
        disabled={sim.length < 18 || error}
        onClick={handleSubmit}
        type="button"
      >
        Add SIMs
      </Button>
    </div>
  );
}

export default SimInput;
