import React from 'react';
import Moment from 'moment-timezone';
import HyperProfileHistoryTableRow from './HyperProfileHistoryTableRow';

const API_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const TIME_FORMAT = 'MM-DD-YY HH:mm:ss';

const formatDate = (date) => (date ? Moment.utc(date, API_TIME_FORMAT).format(TIME_FORMAT) : '');

const sentenceCase = (string = '') =>
  string.length === 0 ? '' : `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`;

const casePreservationList = ['UICC']; // We don't want to sentence case profile types in this list

const profileTypeMapping = (string) =>
  casePreservationList.includes(string) ? string : sentenceCase(string);

// creates a row in the profile history table based on the device's current state
// this is purely defensive; if for some reason the API returns no history, we can at least show the current state
const rowFromDevice = (device) => ({
  id: 1, // This doesn't matter since there's only one
  deviceid: device.id,
  operator_name: device.operator_name,
  type: device.euicc_type,
  timestamp: device.whencreated,
  iccid: device.sim_number,
  linkid: device.linkId,
  imei: device.imei,
  apn: device.apn,
});

const HyperProfileHistoryTable = ({ profileHistory, device }) => {
  const historyRows = profileHistory.length > 0 ? profileHistory : [rowFromDevice(device)];
  // We want to only show the first history event which uses the current active profile
  const activeProfileIndex = historyRows.findIndex((event) => event.linkid === device.linkId);

  return (
    <table className="Table">
      <thead className="Table__header Table__header--sticky">
        <tr className="Table__row">
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              Name
            </div>
          </th>
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              Type
            </div>
          </th>
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              Enabled on
            </div>
          </th>
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              SIM number (ICCID)
            </div>
          </th>
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              Link ID
            </div>
          </th>
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              IMEI
            </div>
          </th>
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              APN
            </div>
          </th>
          <th className="Table__cell--header HyperProfileHistoryTable__cell--header">
            <div className="Table__cell-content HyperProfileHistoryTable__cell-content--header">
              Device ID
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {historyRows.map((event, index) => (
          <HyperProfileHistoryTableRow
            key={event.id}
            name={event.operator_name}
            type={profileTypeMapping(event.type)}
            enabledOn={formatDate(event.timestamp)}
            simNumber={event.iccid}
            linkId={event.linkid}
            imei={event.imei}
            apn={event.apn}
            deviceId={event.deviceid}
            showActiveProfileIndicator={index === activeProfileIndex}
          />
        ))}
      </tbody>
    </table>
  );
};

export default HyperProfileHistoryTable;
