import { FC } from 'react';
import { ModalHeader } from '../components';
import AddPromoCodeForm from '../forms/AddPromoCodeForm';

const ApplyPromoCodeModal: FC = () => (
  <div className="ApplyPromoCodeModal">
    <ModalHeader title="Apply promo code" />
    <AddPromoCodeForm />
  </div>
);
export default ApplyPromoCodeModal;
