import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { useAppDispatch } from 'common-js/hooks';
import { applyPromoCode } from 'common-js/reducers/account/actions';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { close } from 'common-js/reducers/modal/actions';
import { getAllBilling } from 'common-js/reducers/organization/actions';
import { FC, useCallback, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  code: string;
}

const AddPromoCodeForm: FC = () => {
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [serverError, setServerError] = useState<string>();
  const { register, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: { code: '' },
  });
  const { errors, isValid } = formState;

  const dispatch = useAppDispatch();

  const onInvalid: SubmitErrorHandler<FormValues> = useCallback(() => {
    setSubmitted(true);
  }, []);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      const submitCode = async () => {
        setProcessing(true);
        setServerError(undefined);

        dispatch(applyPromoCode(data.code))
          .then(dispatch(getAllBilling()))
          .then(() => {
            dispatch(close());
            dispatch(pushGlobalMessage(`Successfully added ${data.code}`, 'success'));
          })
          .catch((errorMessage) => {
            setServerError(errorMessage);
          })
          .finally(() => {
            setProcessing(false);
          });
      };

      submitCode();
    },
    [dispatch]
  );

  const submit = handleSubmit(onSubmit, onInvalid);

  const cancel = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  return (
    <Form
      onSubmit={submit}
      footerActions={
        <>
          <Button type="submit" loading={processing}>
            Apply promo code
          </Button>
          <Button onClick={cancel} variant="secondary">
            Cancel
          </Button>
        </>
      }
    >
      <TextInputField
        type="text"
        autoFocus
        label="Code"
        validationMessage={errors.code ? 'Code is required' : undefined}
        required
        autocomplete="off"
        invalid={!isValid && submitted}
        {...register('code', { required: true })}
      />
      {serverError && <BannerNotification variant="error">{serverError}</BannerNotification>}
    </Form>
  );
};

export default AddPromoCodeForm;
