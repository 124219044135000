/* eslint-disable react/no-unstable-nested-components */
import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Panel } from '@hologram-dimension/panel';
import { Picture } from '@hologram-hyper-dashboard/components';
import { PopoverCover } from '@holokit/core';
import { Money } from 'common-js/components';
import { pushGlobalMessage as pushGlobalMessage_ } from 'common-js/reducers/message/actions';
import { openModal as openModal_ } from 'common-js/reducers/modal/actions';
import { updateBalanceParams as updateBalanceParams_ } from 'common-js/reducers/organization/actions';
import autoRefillIllustrativeImg from 'img/billing/auto-refill_diagram.webp?&imagetools';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InlineAutoRefillForm from './InlineAutoRefillForm';

class AutoRefillSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabling: false,
    };
  }

  onDisable = () => {
    const { pushGlobalMessage, openModal, updateBalanceParams } = this.props;

    this.setState({
      disabling: true,
    });

    updateBalanceParams(0, 5)
      .then(() => {
        this.setState({
          disabling: false,
        });

        openModal('AutoRefillDisabledModal', {}, 'narrow no-padding');
      })
      .catch((e) => {
        this.setState({
          disabling: false,
        });

        pushGlobalMessage(e, 'error');
      });
  };

  render() {
    const { postpay, topOffAmount, minBalance, refillEnabled } = this.props;

    const { disabling } = this.state;

    return (
      <div className="AutoRefillSection">
        <Panel
          header="Account auto-refill"
          className="AutoRefillSection__override"
          noContentPadding
        >
          {!postpay && (
            <div className="AutoRefillSection__panel-info">
              <p className="AutoRefillSection__panel-text">
                {refillEnabled
                  ? 'Your Hologram account is prepaid. If your balance drops to zero, your devices may be automatically paused. Auto-refill automatically recharges your account when it drops below a set threshold.'
                  : 'Your Hologram account is prepaid. If your balance drops to zero, your devices may be automatically paused. Enable auto-refill to automatically recharge your account when it drops below a set threshold.'}
              </p>
              <div className="AutoRefillSection__helper-img">
                <Picture image={autoRefillIllustrativeImg} alt="Auto refill illustration" />
              </div>
            </div>
          )}
          <div className="AutoRefillSection__panel-content">
            {!refillEnabled ? (
              <div className="well padded full-width grid-row vertical-align">
                <div className="grid-item grid-row vertical-align">
                  <Icon
                    className="AutoRefillSection__panel-content__icon"
                    name="Warning"
                    fill="DdsColorPalettePurple50"
                  />
                  <div>Enable auto-refill to recharge your account.</div>
                </div>
                <PopoverCover
                  selfAligned={false}
                  trigger={(open) => (
                    <Button onClick={open} iconEnd="ChevronSingleSouth">
                      Enable auto-refill
                    </Button>
                  )}
                  content={(close) => <InlineAutoRefillForm onCancel={close} onSuccess={close} />}
                />
              </div>
            ) : (
              <div className="well padded full-width grid-row vertical-align">
                <div className="grid-item">
                  <p className="no-margin">
                    Add{' '}
                    <span className="type-weight-reg">
                      <Money amount={topOffAmount} />
                    </span>{' '}
                    to my balance as soon as it drops below{' '}
                    <span className="type-weight-reg">
                      <Money amount={minBalance} />
                    </span>
                    .
                  </p>
                </div>
                <div className="grid-row">
                  <PopoverCover
                    selfAligned={false}
                    trigger={(open) => (
                      <Button onClick={open} iconEnd="ChevronSingleSouth">
                        Edit auto-refill
                      </Button>
                    )}
                    content={(close) => <InlineAutoRefillForm onCancel={close} onSuccess={close} />}
                  />
                  <Button
                    className="AutoRefillSection__button"
                    onClick={this.onDisable}
                    loading={disabling}
                    variant="delete"
                  >
                    Disable auto-refill
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    postpay: state.organization.balance.hasPostpay,
    topOffAmount: state.organization.balance.topOffAmount,
    minBalance: state.organization.balance.minBalance,
    refillEnabled: state.organization.balance.topOffAmount > 0,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openModal: openModal_,
        updateBalanceParams: updateBalanceParams_,
        pushGlobalMessage: pushGlobalMessage_,
      },
      dispatch
    )
)(AutoRefillSection);
