import * as allPermissions from './allPermissions';

export * from './allPermissions';

export type Role = keyof typeof ROLE_LABELS | '';
export type Permission = (typeof allPermissions)[keyof typeof allPermissions];

const ROLE_LABELS = {
  admin: {
    label: 'Admin',
    article: 'an',
  },
  manager: {
    label: 'Manager',
    article: 'a',
  },
  editor: {
    label: 'Editor',
    article: 'an',
  },
  editor_limited: {
    label: 'Editor Limited',
    article: 'an',
  },
  placeholder: {
    label: "Please select the user's role",
    article: 'an',
  },
};

export const getAllPerms = (): Array<Permission> => Object.values(allPermissions);

export const translateRoleToPermissions = (role: Role): Array<Permission> => {
  if (role === 'admin') {
    return getAllPerms();
  }
  if (role === 'manager') {
    return [
      allPermissions.ACTIVATION,
      allPermissions.BILLING_VISIBLE,
      allPermissions.CHANGEPLAN,
      allPermissions.DEACTIVATE,
      allPermissions.SBCONFIG,
      allPermissions.SBTUNNEL,
    ];
  }
  if (role === 'editor') {
    return [allPermissions.BILLING_VISIBLE, allPermissions.SBCONFIG, allPermissions.SBTUNNEL];
  }
  if (role === 'editor_limited') {
    return [allPermissions.SBCONFIG, allPermissions.SBTUNNEL];
  }

  // If not specified, default to lowest (editor_limited)
  return [allPermissions.SBCONFIG, allPermissions.SBTUNNEL];
};

export const translatePermissionsToRole = (permissions?: Array<Permission>): Role => {
  if (!permissions) {
    return 'editor';
  }
  if (permissions.includes(allPermissions.ORG_MANAGEMENT)) {
    return 'admin';
  }
  if (
    permissions.includes(allPermissions.ACTIVATION) &&
    permissions.includes(allPermissions.CHANGEPLAN) &&
    permissions.includes(allPermissions.DEACTIVATE)
  ) {
    return 'manager';
  }
  if (permissions.includes(allPermissions.BILLING_VISIBLE)) {
    return 'editor';
  }
  if (permissions.includes(allPermissions.SBCONFIG)) {
    return 'editor_limited';
  }

  // Defaulting to editor as the lowest common denominator role
  return 'editor';
};

export const labelForRole = (role: Role) => ROLE_LABELS[role] ?? ROLE_LABELS.editor;
