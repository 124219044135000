import Review from './Review';
import ReviewFooter from './ReviewFooter';
import ReviewSidebarItem from './ReviewSidebarItem';

const reviewFixture = ({ changingPlan }) => ({
  stepName: 'Review',
  component: Review,
  sidebarItem: ReviewSidebarItem,
  footer: ReviewFooter,
  title: changingPlan ? 'Review changes' : 'Review selections',
  formState: 'creditCardInfo',
  defaultValues: {
    paymentType: 'balance',
    hasError: false,
    invalidPaymentMethod: false,
  },
});

export default reviewFixture;
