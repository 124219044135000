import { API_URL, getHeaders } from '../config';
import { fetchErrorHandler, fetchHandler } from '../middleware';

export function getReleaseFlags(userContextData) {
  const orgId = userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId;

  if (!orgId) {
    return Promise.reject(new Error('Organization is not set: cannot retrieve flags'));
  }

  return fetch(`${API_URL}/releaseflags?orgid=${orgId}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data), true, false))
    .catch(fetchErrorHandler);
}

export function getPublicReleaseFlags() {
  return fetch(`${API_URL}/releaseflags/public`, {
    method: 'GET',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data), true, false))
    .catch(fetchErrorHandler);
}
