import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import { ModalHeader } from 'common-js/components';
import SendSmsForm from 'common-js/forms/SendSmsForm';
import OverLimitModal from 'common-js/modals/devices/OverLimitModal';
import { sendSmsToDevice, sendDataToDevice } from 'common-js/reducers/devices/actions';
import { getSelectedLoadedDevices } from 'common-js/reducers/devices/selectors';
import { close } from 'common-js/reducers/modal/actions';
import { Device } from 'common-js/types/Device';

interface SendBulkMessageModalProps {
  closeModal: typeof close;
  overLimit?: boolean;
  selectedDevices: Array<Device>;
  sendSmsToDevice_: typeof sendSmsToDevice;
  sendDataToDevice_: typeof sendDataToDevice;
}

interface SendBulkMessageModalState {}

class SendBulkMessageModal extends React.Component<
  SendBulkMessageModalProps,
  SendBulkMessageModalState
> {
  handleCloseClick = () => {
    const { closeModal } = this.props;
    closeModal();
    analyticsEventBuilder.buttonClick('Bulk Action', 'Message Modal', 'Close').send();
  };

  render() {
    const { closeModal, overLimit, selectedDevices, sendSmsToDevice_, sendDataToDevice_ } =
      this.props;

    if (overLimit) {
      return (
        <OverLimitModal
          title="Send SMS to SIM"
          icon="Chat"
          onClose={this.handleCloseClick}
          messageBodyVerb="send an SMS"
          requestType={false}
          analyticsEvent={{
            type: BUTTON_CLICK,
            data: { name: 'Bulk Action - 10k Modal - X Close' },
          }}
        />
      );
    }

    return (
      <div className="SendBulkMessageModal">
        <div className="Panel__padding">
          <ModalHeader icon="Chat" title="Send message to SIMs" />
        </div>
        <div className="grid-row section Panel__padding">
          <div className="grid-item-1">
            <div className="h4 type-weight-light">Devices</div>
          </div>
          <div className="grid-item-3">
            <div className="device-tags">
              {selectedDevices.slice(0, 10).map(({ id, name }) => (
                <div className="device-tag-cont" key={id}>
                  <span className="tag" key={id}>
                    {name}
                  </span>
                </div>
              ))}
              {selectedDevices.length > 10 && (
                <div className="device-tag-cont">
                  <span className="tag">{selectedDevices.length - 10} more...</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid-row section">
          <SendSmsForm
            deviceIds={selectedDevices.map(({ id }) => id)}
            sendSmsToDevice={sendSmsToDevice_}
            sendDataToDevice={sendDataToDevice_}
            showCancelButton
            onCancelClick={closeModal}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  selectedDevices: getSelectedLoadedDevices(state),
});

export default connect(mapStateToProps, (dispatch: any) =>
  bindActionCreators(
    {
      closeModal: close,
      sendSmsToDevice_: sendSmsToDevice,
      sendDataToDevice_: sendDataToDevice,
    },
    dispatch
  )
)(SendBulkMessageModal);
