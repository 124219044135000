import React, { useState } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import { Chip } from '@holokit/core';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import useContainerSize from '../../../common/hooks/useContainerSize';

// This component is built around the idea that each SIM chip will be fixed size.  We scale how many we show instead of
// scaling the size of each SIM chip.
const CHIP_WIDTH = 198; // 190px width + 8px margin
const CHIP_WIDTH_ACTIVATED = 167; // 159px width + 8px margin
const CHIP_HEIGHT = 38; // 30px height + 8px margin
const NUMBER_OF_UNEXPANDED_ROWS = 3; // We show this many rows of SIMs when not expanded
const NUMBER_OF_EXPANDED_ROWS = 6; // We show this many rows of SIMs at once when expanded

function SimsGrid({ numSims, sims, text, onDelete, variant = 'activated' }) {
  const [containerRef, containerDimensions] = useContainerSize();
  const [expanded, setExpanded] = useState(false);

  // Each SimChip is FIXED 190px + 8px spacing.  We fit as many as we can in the width of the container.
  const columnCount = Math.max(Math.floor(containerDimensions.width / CHIP_WIDTH), 1);
  // Always at least one row, otherwise total sims / columnCount rounded up.
  const rowCount = Math.max(Math.ceil(numSims / columnCount), 1);
  // We only show up to 3 rows of SIMs until the user clicks the expand option
  // This will be negative or zero if all the SIMs can be contained in the first 3 rows
  const countOfSimsInExpandedView = numSims - columnCount * NUMBER_OF_UNEXPANDED_ROWS;

  return (
    <>
      <div className="SimsDisplay__text">
        {addCommasToNumber(numSims, false)} {numSims === 1 ? 'SIM' : 'SIMs'}{' '}
        {variant === 'activated' ? text : 'ALREADY ACTIVATED'}
      </div>
      <div className="GridContainer" ref={containerRef}>
        <Grid
          columnCount={columnCount}
          rowCount={
            !expanded && rowCount > NUMBER_OF_UNEXPANDED_ROWS ? NUMBER_OF_UNEXPANDED_ROWS : rowCount
          }
          width={containerDimensions.width}
          height={
            Math.min(rowCount, expanded ? NUMBER_OF_EXPANDED_ROWS : NUMBER_OF_UNEXPANDED_ROWS) *
            CHIP_HEIGHT
          }
          rowHeight={CHIP_HEIGHT}
          columnWidth={variant === 'alreadyActivated' ? CHIP_WIDTH_ACTIVATED : CHIP_WIDTH}
        >
          {({ rowIndex, columnIndex, style }) => {
            const index = columnCount * rowIndex + columnIndex;
            // not already expanded, have SIMs to show in expanded view, and this is where the expand button goes
            const shouldShowExpand =
              !expanded &&
              countOfSimsInExpandedView > 0 &&
              index === columnCount * NUMBER_OF_UNEXPANDED_ROWS - 1;
            return (
              <div style={style}>
                {/* We must add one to the count of SIMs in expanded view because this button takes up 1 spot */}
                {shouldShowExpand && (
                  <div
                    className="SimsDisplay--expand"
                    onClick={() => setExpanded(true)}
                    onKeyUp={() => setExpanded(true)}
                    role="button"
                    tabIndex={0}
                  >
                    +{' '}
                    <span className="SimsDisplay--expand__underlined">
                      {addCommasToNumber(countOfSimsInExpandedView + 1, false)} more
                    </span>
                  </div>
                )}
                {!shouldShowExpand && index < numSims && (
                  <Chip
                    onDelete={variant === 'activated' ? onDelete : null}
                    text={sims[index]}
                    type={variant === 'activated' ? 'sim' : 'warning'}
                  />
                )}
              </div>
            );
          }}
        </Grid>
      </div>
      {expanded && countOfSimsInExpandedView > 0 && (
        <div className="SimsDisplay--collapse">
          <span
            style={{
              right: `${containerDimensions.width - columnCount * CHIP_WIDTH + 16}px`,
            }}
            onClick={() => setExpanded(false)}
            onKeyUp={() => setExpanded(false)}
            role="button"
            tabIndex={0}
          >
            Collapse list
          </span>
        </div>
      )}
    </>
  );
}

export default SimsGrid;
