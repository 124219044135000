import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { Link } from '@hologram-dimension/link';
import { Panel } from '@hologram-dimension/panel';
import { PanelNotification } from '@hologram-dimension/panel-notification';
import useHandleCoverageLink from 'activation/useHandleCoverageLink';
import type { Plan } from 'common-js/types/Plan';
import { FC, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import PlanTypeSection from './PlanTypeSection';
import PlanTypes from './planTypes';

interface DefaultPlansPanelProps {
  selectedPlan: Plan;
  onChange: (...args: Array<any>) => any;
  isLoading: boolean;
  allowedStandardPlans: Array<Plan>;
  numSims: number;
  allowedRegions: Array<Region>;
  noAllowedPlansAndNoCoverage: boolean;
  hasCustomPlans: boolean;
  existingPlanId?: PlanId;
  changingPlan: boolean;
  isOnboarding: boolean;
}

const DefaultPlansPanel: FC<DefaultPlansPanelProps> = ({
  selectedPlan,
  onChange,
  isLoading,
  allowedStandardPlans,
  numSims,
  allowedRegions,
  noAllowedPlansAndNoCoverage,
  hasCustomPlans,
  existingPlanId,
  changingPlan,
  isOnboarding,
}) => {
  // Group default plans by type (pay as you go or monthly)
  const { flexPlans, monthlyPlans, hasAllowedPlans } = useMemo(() => {
    if (!allowedStandardPlans || allowedStandardPlans.length === 0) {
      return { flexPlans: [], monthlyPlans: [], hasAllowedPlans: false };
    }

    const newFlexPlans = allowedStandardPlans.filter(
      (somePlan) => !somePlan.dataLimit || somePlan.dataLimit === 0
    );
    return {
      flexPlans: newFlexPlans,
      monthlyPlans: allowedStandardPlans
        .filter((somePlan) => somePlan.dataLimit > 0)
        .sort((a, b) => a.dataLimit - b.dataLimit),
      hasAllowedPlans: true,
    };
  }, [allowedStandardPlans]);

  const { id: regionId } = useWatch({
    name: 'region.selected',
    defaultValue: {},
  });

  const hasAllowedRegions = !!allowedRegions && allowedRegions.length > 0;

  const selectedRegion = useMemo(
    () => allowedRegions?.find((region) => region.id === regionId),
    [allowedRegions, regionId]
  );

  const handleCoverageLink = useHandleCoverageLink({ isOnboarding, changingPlan });

  const emptyState = useMemo(() => {
    if (noAllowedPlansAndNoCoverage) {
      return (
        <PanelNotification
          label="The SIMs you added are not compatible with any data plans"
          icon={<Icon name="CircleInfo" size="large" fill="DdsColorFillNotificationNote" />}
          actions={
            <Button
              iconEnd="ExternalLink"
              href="https://support.hologram.io/hc/en-us"
              target="_blank"
              variant="secondary"
            >
              Contact support
            </Button>
          }
        >
          Please contact us for additional support.
        </PanelNotification>
      );
    }
    if (!hasAllowedPlans) {
      if (hasCustomPlans && !regionId) {
        return (
          <PanelNotification
            label="The SIMs you added are not compatible with any standard data plans"
            icon={<Icon name="CircleInfo" size="large" fill="DdsColorFillNotificationNote" />}
            actions={
              <Button
                iconEnd="ExternalLink"
                href="https://support.hologram.io/hc/en-us"
                target="_blank"
                variant="secondary"
              >
                Contact support
              </Button>
            }
          >
            Please contact us for additional support.
          </PanelNotification>
        );
      }
    }
    if (!hasAllowedRegions) {
      return (
        <PanelNotification
          label="No coverage areas found"
          icon={<Icon name="CircleInfo" size="large" fill="DdsColorFillNotificationNote" />}
          actions={
            <Button
              iconEnd="ExternalLink"
              href="https://support.hologram.io/hc/en-us"
              target="_blank"
              variant="secondary"
            >
              Contact support
            </Button>
          }
        >
          There are no compatible regions based on the SIMs that were added. Please contact us for
          additional support.
        </PanelNotification>
      );
    }
    return null;
  }, [hasAllowedPlans, hasAllowedRegions, hasCustomPlans, noAllowedPlansAndNoCoverage, regionId]);

  const showPlans =
    !isLoading &&
    allowedStandardPlans.length > 0 &&
    !!regionId &&
    hasAllowedRegions &&
    hasAllowedPlans;

  const hasFlexPlans = flexPlans && flexPlans.length > 0;
  const hasMonthlyPlans = monthlyPlans && monthlyPlans.length > 0;

  return (
    <div className="ActivationPlans__default">
      <Panel
        isLoading={isLoading}
        header={
          hasCustomPlans && selectedRegion && `${selectedRegion?.display_name ?? ''} data plans`
        }
        contentWell={
          selectedRegion &&
          hasCustomPlans && (
            <div>
              {selectedRegion.description}.{' '}
              <Link onClick={handleCoverageLink} type="inline">
                Learn more
              </Link>
            </div>
          )
        }
      >
        {showPlans && (
          <>
            {hasFlexPlans && (
              <PlanTypeSection
                selectedPlan={selectedPlan}
                plans={flexPlans}
                planType={PlanTypes.PayAsYouGo}
                onChange={onChange}
                numSims={numSims}
                existingPlanId={existingPlanId}
              />
            )}
            {hasFlexPlans && hasMonthlyPlans && <hr className="ActivationPlans__planDivider" />}
            {hasMonthlyPlans && (
              <PlanTypeSection
                selectedPlan={selectedPlan}
                plans={monthlyPlans}
                planType={PlanTypes.Monthly}
                onChange={onChange}
                numSims={numSims}
                existingPlanId={existingPlanId}
              />
            )}
          </>
        )}
        {!isLoading && emptyState}
      </Panel>
    </div>
  );
};

export default DefaultPlansPanel;
