const PREFIX = 'LOGS_' as const;

export const GET_ALL_REQUEST = `${PREFIX}GET_ALL_REQUEST` as const;
export const GET_ALL_SUCCESS = `${PREFIX}GET_ALL_SUCCESS` as const;
export const GET_ALL_ERROR = `${PREFIX}GET_ALL_ERROR` as const;

export const GET_ONE_REQUEST = `${PREFIX}GET_ONE_REQUEST` as const;
export const GET_ONE_SUCCESS = `${PREFIX}GET_ONE_SUCCESS` as const;
export const GET_ONE_ERROR = `${PREFIX}GET_ONE_ERROR` as const;

export const REVEAL_LOGS = `${PREFIX}REVEAL_LOGS` as const;
export const REVEAL_LOGS_FOR_ONE = `${PREFIX}REVEAL_LOGS_FOR_ONE` as const;
export const UNMOUNT_LOGS = `${PREFIX}UNMOUNT_LOGS` as const;
export const TOGGLE_LOGROW_VIEW = `${PREFIX}TOGGLE_LOGROW_VIEW` as const;
