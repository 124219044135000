import React, { useCallback, useRef, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { Button } from '@holokit/core';
import useGenerateRouterCreds from 'common-js/api/devices/useGenerateRouterCreds';
import { ModalHeader } from 'common-js/components';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import useCopyElement from 'device/Webhooks/ConfigureWebhookForm/useCopyElement';

const RouterCredsModal = ({
  routerCreds: initialRouterCreds,
  setRouterCreds: updateRouterCreds,
  deviceId,
}) => {
  const dispatch = useAppDispatch();
  const deviceKeyRef = useRef(null);
  const [isProcessing, setProcessing] = useState(false);
  // our modals are a bit of an anti-pattern in that they aren't rendered like normal components
  // instead their props are pushed to redux, which means changes to their original props will never cause re-renders
  // so, we duplicate the initial state here and make sure to keep it synchronized with the state in the parent
  // in the future, we should aim for a simple modal component that behaves like any other react component
  const [routerCreds, setRouterCreds] = useState(initialRouterCreds);
  const generateRouterCreds = useGenerateRouterCreds();

  const onCopyToClipboard = useCopyElement(deviceKeyRef, 'Device key');

  const onGenerateRouterCreds = useCallback(async () => {
    setProcessing(true);

    try {
      const creds = await generateRouterCreds(deviceId);
      setRouterCreds(creds); // update local state
      updateRouterCreds(creds); // update parent state
    } catch (error) {
      dispatch(pushGlobalMessage(error.message, 'error'));
    }

    setProcessing(false);
  }, [deviceId, dispatch, generateRouterCreds, setProcessing, setRouterCreds, updateRouterCreds]);

  return (
    <div className="RouterCredsModal">
      <ModalHeader title="Router credentials" />
      <div className="form form-horizontal">
        <div className="form-row">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
          <label className="grid-row vertical-align">
            <div className="label-text f5 grid-item">Device Key</div>
            <div className="grid-item-2 grid-row v-align">
              {routerCreds.devicekey ? (
                <>
                  <div className="copy-container font-mono f6">{routerCreds.devicekey}</div>
                  <Button onClick={onCopyToClipboard} type="secondary">
                    Copy Key
                  </Button>
                  <textarea
                    ref={deviceKeyRef}
                    value={routerCreds.devicekey}
                    className="hidden"
                    readOnly
                  />
                </>
              ) : (
                <div className="copy-container font-mono f6">Not generated yet.</div>
              )}
            </div>
          </label>
        </div>
        {isProcessing ? (
          <Button type="secondary">Generating...</Button>
        ) : (
          <Button onClick={onGenerateRouterCreds} type="secondary">
            Generate New Device Key
          </Button>
        )}
      </div>
    </div>
  );
};

export default RouterCredsModal;
