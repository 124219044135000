import Region from './Region';
import RegionSidebarItem from './RegionSidebarItem';
import RegionFooter from './RegionFooter';

const regionsFixture = ({ hasCustomPlans }) => ({
  stepName: 'Regions',
  component: Region,
  sidebarItem: RegionSidebarItem,
  footer: RegionFooter,
  title: 'Select a coverage area',
  subtitle: 'Successfully added SIMs can be activated in any of the following regions.',
  include: !hasCustomPlans,
  formState: 'region',
  optional: false,
  defaultValues: {
    selected: {
      id: -1,
      displayName: '',
    },
  },
});

export default regionsFixture;
