import ClassNames from 'clsx';
import FullHeightCalculatorHOC from 'common-js/components/FullHeightCalculatorHOC';
import { DEVICE_DATA_LIMIT, DEVICE_USAGE, TAC_CHANGE } from 'common-js/constants/alertTypes';
import {
  ACTIONS_ALERTS_DEVICE_DATALIMIT,
  ACTIONS_ALERTS_DEVICE_HIGHUSAGE,
  ACTIONS_ALERTS_DEVICE_TACCHANGE,
  pathMatches,
} from 'common-js/constants/paths';
import {
  createGetActiveAlertByType,
  createGetActiveAlertIdByType,
  createGetActivePinByType,
  createGetAlertMapDataByType,
} from 'common-js/reducers/alerts/selectors';
import { AlertsMap, SubMenu, TopMenu } from 'home/components';
import { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseLayout from './BaseLayout';

interface LayoutHomeProps {
  children?: ReactNode;
  location: RouterLocation;
  mapData?: any;
  alertType?: any;
  activeAlert?: any;
  activePin?: any;
  activeAlertId?: any;
}

const LayoutHome: FC<LayoutHomeProps> = ({
  children,
  location,
  mapData,
  alertType,
  activeAlert,
  activePin,
  activeAlertId,
}) => (
  <BaseLayout layoutName="home">
    <div
      className={ClassNames('AlertsMenu', {
        'AlertsMenu--has-submenu': alertType,
      })}
    >
      <TopMenu hasAlertType={!!alertType} />
      {alertType && <SubMenu pathname={location.pathname} />}
    </div>
    <FullHeightCalculatorHOC accountForDrawer backgroundColor="#d5e0ed">
      {children}
      {alertType && (
        <AlertsMap
          alertType={alertType}
          mapData={mapData}
          mapType="alerts"
          activeAlert={activeAlert}
          activePin={activePin}
          activeAlertId={activeAlertId}
        />
      )}
    </FullHeightCalculatorHOC>
  </BaseLayout>
);

const LayoutHomeHoC = connect(
  (state, props: LayoutHomeProps) => {
    const { pathname } = props.location;

    let alertType: any = null;

    if (pathMatches(ACTIONS_ALERTS_DEVICE_TACCHANGE, pathname)) {
      alertType = TAC_CHANGE;
    } else if (pathMatches(ACTIONS_ALERTS_DEVICE_HIGHUSAGE, pathname)) {
      alertType = DEVICE_USAGE;
    } else if (pathMatches(ACTIONS_ALERTS_DEVICE_DATALIMIT, pathname)) {
      alertType = DEVICE_DATA_LIMIT;
    }

    const getActiveAlert = createGetActiveAlertByType(props.alertType);
    const getActivePin = createGetActivePinByType(props.alertType);
    const getActiveAlertId = createGetActiveAlertIdByType(props.alertType);

    return {
      mapData: alertType ? createGetAlertMapDataByType(alertType)(state) : null,
      alertType,
      activeAlert: getActiveAlert(state),
      activePin: getActivePin(state),
      activeAlertId: getActiveAlertId(state),
    };
  },
  (dispatch) => bindActionCreators({}, dispatch)
)(LayoutHome);

export default LayoutHomeHoC;
