import { Loader } from '@holokit/core';
import _classnames from 'clsx';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { FullHeightCalculatorHOC, HoloHelmet } from 'common-js/components';
import LandingPageEmpty from 'common-js/components/LandingPageEmpty';
import * as DeviceStates from 'common-js/constants/deviceStates';
import { DEVICES_DEVICES, withContext } from 'common-js/constants/paths';
import { selectFirstName } from 'common-js/reducers/account/selectors';
import { getFilterValues } from 'common-js/reducers/deviceFilter/actions';
import { getStateCountsByName } from 'common-js/reducers/deviceFilter/selectors';
import { getHistoricDeviceCounts } from 'common-js/reducers/devices/actions';
import { getOrgHasZeroDevices } from 'common-js/reducers/organization/selectors';
import {
  selectHasFleetStatus,
  selectHasPredeploymentTesting,
} from 'common-js/reducers/releaseFlag/selectors';
import { getDeviceStatusText } from 'common-js/utils/deviceStatus';
import {
  DataUsedPanel,
  FleetHomeEyebrow,
  MetricPanel,
  MetricPanelFullWidth,
} from 'home/components';
import { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

class FleetHome extends Component<any, any> {
  constructor(props) {
    super(props);

    sendAnalyticsEvent({ type: PAGE_VIEW, data: { page: 'Fleet Home' } });
  }

  componentDidMount() {
    const { getFilterValues_, getHistoricDeviceCounts_, hasFleetStatus } = this.props;

    if (!hasFleetStatus) {
      browserHistory.push(withContext(DEVICES_DEVICES));
      return;
    }

    getFilterValues_(['link_state_connected']);

    const timestamp = new Date();
    const pastDate = timestamp.getDate() - 7;
    timestamp.setDate(pastDate);
    const unixTimestamp = Math.floor(timestamp.getTime() / 1000);

    const deviceStates = DeviceStates.homeStates.join(',');
    getHistoricDeviceCounts_(unixTimestamp, deviceStates);
  }

  getDeviceStateCountByName = (name) => {
    const { deviceStateCountsByName } = this.props;
    return deviceStateCountsByName[name] ? deviceStateCountsByName[name] : 0;
  };

  getHistoricCountByName = (name) => {
    const { historicDeviceData } = this.props;
    if (!historicDeviceData || typeof historicDeviceData[name] === 'undefined') {
      return 0;
    }
    return historicDeviceData[name];
  };

  getHistoricActiveDevices = () => {
    const activeDeviceStates = DeviceStates.activeStates;
    const totalActiveDevices = activeDeviceStates.reduce(
      (acc, curr) => acc + this.getHistoricCountByName(DeviceStates[curr]),
      0
    );
    return totalActiveDevices;
  };

  renderEyebrowAndHero = () => {
    const {
      activeDeviceCount,
      firstName,
      hasSeenFleetHome,
      hasEureka,
      hasDevices,
      isLoading,
      historicFlag,
    } = this.props;

    if (!hasDevices) {
      return null;
    }

    return (
      <>
        <FleetHomeEyebrow
          firstName={firstName}
          hasSeenFleetHome={hasSeenFleetHome}
          showInflight={hasEureka}
        />
        <div className="FleetHome__hero__metrics">
          <MetricPanelFullWidth
            keyMetric={{
              name: 'Active devices',
              number: activeDeviceCount,
              historicDevicesNumber: this.getHistoricActiveDevices(),
            }}
            comparingDataFromPeriod="Last week"
            historicFlag={historicFlag}
            // TODO: revisit whether these states are represented correctly
            deviceSegments={[
              {
                name: getDeviceStatusText(DeviceStates.CONNECTED),
                deviceStateFilters: [DeviceStates.CONNECTED],
                number: this.getDeviceStateCountByName(DeviceStates.CONNECTED),
                color: 'linear-gradient(90deg, #099FC3 0%, #088BAB 100%)',
                historicDevicesNumber: this.getHistoricCountByName(DeviceStates.CONNECTED),
              },
              {
                name: getDeviceStatusText(DeviceStates.LIVE),
                deviceStateFilters: [DeviceStates.LIVE],
                number: this.getDeviceStateCountByName(DeviceStates.LIVE),
                color: 'linear-gradient(90deg, #0ECB76 0%, #0CB66A 100%)',
                historicDevicesNumber: this.getHistoricCountByName(DeviceStates.LIVE),
              },
              {
                name: 'Paused',
                deviceStateFilters: [DeviceStates.PAUSED_USER, DeviceStates.PAUSED_SYS],
                number:
                  this.getDeviceStateCountByName(DeviceStates.PAUSED_USER) +
                  this.getDeviceStateCountByName(DeviceStates.PAUSED_SYS),
                color: 'linear-gradient(90deg, #DB0000 0%, #C20000 100%)',
                historicDevicesNumber:
                  this.getHistoricCountByName(DeviceStates.PAUSED_USER) +
                  this.getHistoricCountByName(DeviceStates.PAUSED_SYS),
              },
            ]}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  };

  render() {
    const {
      hasDevices,
      hasFleetStatus,
      hasPredeploymentTesting,
      deviceStateCountsByName,
      isLoading,
      historicFlag,
    } = this.props;

    if (!hasFleetStatus) return null;

    const totalDeviceSegments = [
      {
        title: 'Paused devices',
        toolTipText:
          "Breakdown of your organization's paused devices that are not currently passing any data. They have been paused manually by a member of your team, or by the system due to a billing issue or data limit reached.",
        devices: [
          {
            // TODO: Iron out status naming conventions across the dashboard so this name doesn't have to be hard coded
            name: 'Paused by User',
            deviceStateFilters: [DeviceStates.PAUSED_USER],
            number: this.getDeviceStateCountByName(DeviceStates.PAUSED_USER),
            color: '#DB0000',
            historicDevicesNumber: this.getHistoricCountByName(DeviceStates.PAUSED_USER),
          },
          {
            name: getDeviceStatusText(DeviceStates.PAUSED_SYS),
            deviceStateFilters: [DeviceStates.PAUSED_SYS],
            number: this.getDeviceStateCountByName(DeviceStates.PAUSED_SYS),
            color: '#FF8282',
            historicDevicesNumber: this.getHistoricCountByName(DeviceStates.PAUSED_SYS),
          },
        ],
      },
    ];

    let inactiveHibernateMetric: any | null = null;

    if (
      deviceStateCountsByName[DeviceStates.INACTIVE] &&
      deviceStateCountsByName[DeviceStates.INACTIVE_TESTED]
    ) {
      inactiveHibernateMetric = {
        name: 'Inactive & Hibernating',
        deviceStateFilters: [DeviceStates.INACTIVE_TESTED, DeviceStates.INACTIVE],
        number:
          deviceStateCountsByName[DeviceStates.INACTIVE_TESTED] +
          deviceStateCountsByName[DeviceStates.INACTIVE],
        color: '#DEDFE4',
        historicDevicesNumber:
          this.getHistoricCountByName(DeviceStates.INACTIVE) +
          this.getHistoricCountByName(DeviceStates.INACTIVE_TESTED),
      };
    } else if (deviceStateCountsByName[DeviceStates.INACTIVE]) {
      inactiveHibernateMetric = {
        name: 'Inactive',
        deviceStateFilters: [DeviceStates.INACTIVE],
        number: deviceStateCountsByName[DeviceStates.INACTIVE],
        color: '#DEDFE4',
        historicDevicesNumber: this.getHistoricCountByName(DeviceStates.INACTIVE),
      };
    } else if (deviceStateCountsByName[DeviceStates.INACTIVE_TESTED]) {
      inactiveHibernateMetric = {
        name: 'Hibernating',
        deviceStateFilters: [DeviceStates.INACTIVE_TESTED],
        number: deviceStateCountsByName[DeviceStates.INACTIVE_TESTED],
        color: '#DEDFE4',
        historicDevicesNumber: this.getHistoricCountByName(DeviceStates.INACTIVE_TESTED),
      };
    }

    if (
      hasPredeploymentTesting &&
      (deviceStateCountsByName[DeviceStates.TEST_ACTIVATE] ||
        deviceStateCountsByName[DeviceStates.TEST_AUTOACTIVATE] ||
        deviceStateCountsByName[DeviceStates.TEST_HIBERNATE])
    ) {
      const preflightSegmentDevices: any = [
        {
          name: getDeviceStatusText(DeviceStates.TEST_AUTOACTIVATE),
          deviceStateFilters: [
            DeviceStates.TEST_AUTOACTIVATE,
            DeviceStates.TEST_ACTIVATE,
            DeviceStates.TEST_HIBERNATE,
          ],
          number:
            this.getDeviceStateCountByName(DeviceStates.TEST_AUTOACTIVATE) +
            this.getDeviceStateCountByName(DeviceStates.TEST_ACTIVATE) +
            this.getDeviceStateCountByName(DeviceStates.TEST_HIBERNATE),
          color: '#C780FF',
          historicDevicesNumber:
            this.getHistoricCountByName(DeviceStates.TEST_AUTOACTIVATE) +
            this.getHistoricCountByName(DeviceStates.TEST_ACTIVATE) +
            this.getHistoricCountByName(DeviceStates.TEST_HIBERNATE),
        },
      ];
      if (inactiveHibernateMetric) {
        preflightSegmentDevices.push(inactiveHibernateMetric);
      }
      totalDeviceSegments.push({
        title: 'Pre-deployment devices',
        toolTipText:
          'Your subset of preconfigured devices with Test mode enabled that have access to free data and SMS messages prior to full activation.',
        devices: preflightSegmentDevices,
      });
    }

    const oneDeviceSegment = totalDeviceSegments.length === 1;

    return (
      <FullHeightCalculatorHOC accountForDrawer backgroundColor="#202a4a">
        <HoloHelmet title="Home" />
        <Loader isLoading={isLoading} />
        <div className="FleetHome">
          {hasDevices ? (
            <>
              <div className="FleetHome__hero">
                <section className="FleetHome__hero__container">
                  {this.renderEyebrowAndHero()}
                </section>
                <div className="FleetHome__hero__bottom-border" />
              </div>
              <section className="FleetHome__body">
                <div
                  className={_classnames('FleetHome__body__row', 'FleetHome__body__row--metrics', {
                    'FleetHome__body__row--metrics--single': oneDeviceSegment,
                  })}
                >
                  {totalDeviceSegments.map((segment, i) => (
                    <MetricPanel
                      buttonClasses={_classnames('FleetHome__body__panel__buttons__button', {
                        'FleetHome__body__panel__buttons__button--fullWidth': oneDeviceSegment,
                      })}
                      comparingDataFromPeriod="Last week"
                      deviceSegments={segment.devices}
                      isLoading={isLoading}
                      key={`FleetHome__body__metricPanel--${segment.title ?? i}`}
                      metricTitle={segment.title}
                      toolTipText={segment.toolTipText}
                      historicFlag={historicFlag}
                    />
                  ))}
                </div>
                <div className="FleetHome__body__row">
                  <DataUsedPanel />
                </div>
              </section>
            </>
          ) : (
            <LandingPageEmpty isFleet />
          )}
        </div>
      </FullHeightCalculatorHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  firstName: selectFirstName(state),
  hasSeenFleetHome: state.account.uiState.hasSeenFleetHome,
  hasEureka: state.releaseFlag?.eureka0,
  hasFleetStatus: selectHasFleetStatus(state),
  hasDevices: !getOrgHasZeroDevices(state),
  hasPredeploymentTesting: selectHasPredeploymentTesting(state),
  activeDeviceCount: state.organization.deviceCountActive,
  deviceStateCountsByName: getStateCountsByName(state),
  isLoading: state.deviceFilters.isLoadingFilterValues,
  historicDeviceData: state.devices?.historicData,
  historicFlag: state.releaseFlag.fleet_historic,
});

const mapDispatchToProps = {
  getFilterValues_: getFilterValues,
  getHistoricDeviceCounts_: getHistoricDeviceCounts,
};

const FleetHomeHoC = connect(mapStateToProps, mapDispatchToProps)(FleetHome);

export default FleetHomeHoC;
