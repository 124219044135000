import { type FC } from 'react';

interface ProgressIndicatorProps {
  variant?: 'bar' | 'text';
  totalSteps: number;
  currentStep: number;
  optionalStep?: boolean;
}

const ProgressIndicator: FC<ProgressIndicatorProps> = ({
  variant = 'bar',
  totalSteps,
  currentStep,
  optionalStep = false,
}) => {
  const percent = Math.round(((currentStep + 1) / totalSteps) * 100);
  const currentProgress = percent > 100 ? '100%' : `${percent}%`;

  return (
    <div className="ProgressIndicator">
      {variant === 'bar' ? (
        <div className="ProgressIndicator__bar">
          <div className="ProgressIndicator__bar__line" style={{ width: `${currentProgress}` }} />
        </div>
      ) : (
        <div className="ProgressIndicator__text">
          step {currentStep} of {totalSteps}
          {optionalStep && (
            <span className="ProgressIndicator__text--optional">(optional)</span>
          )}{' '}
        </div>
      )}
    </div>
  );
};

export default ProgressIndicator;
