export default class WebhookModel {
  url = '';

  protocol = '';

  port = '';

  hasHydrated = false;

  constructor(data) {
    if (!data) return;

    this.hasHydrated = true;
    this.url = data.url;
    this.protocol = data.protocol;
    this.port = data.port;
  }
}
