import type { Action, Reducer } from 'redux';
import * as accountActionTypes from '../account/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  currentModal: '',
  style: '',
  modalProps: {},
  queue: [
    // {
    //   name: "StellarOptoutModal",s
    //   roadblock: true
    // }
  ],
};

type ActionType =
  | GetObjectValueType<typeof accountActionTypes>
  | GetObjectValueType<typeof actionTypes>;

interface ModalReducerAction extends Action {
  type: ActionType;
  message: string;
  id: number;
  modalName: string;
  modalProps: {};
  style: string;
}

const modalReducer = ((
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: any = initialState,
  action: ModalReducerAction
) => {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        ...state,
        currentModal: action.modalName,
        modalProps: action.modalProps,
        style: action.style,
      };

    case actionTypes.CLOSE:
      return {
        ...state,
        currentModal: '',
      };

    case actionTypes.PUSH_QUEUE: {
      const queue = structuredClone(state.queue);

      queue.push({
        modalName: action.modalName,
        modalProps: action.modalProps,
        style: action.style,
      });

      return { ...state, queue };
    }

    case actionTypes.POP_QUEUE: {
      const queue = structuredClone(state.queue);

      queue.shift();

      return { ...state, queue };
    }

    case accountActionTypes.LOGIN_REQUEST:
    case accountActionTypes.REGISTER_REQUEST:
    case accountActionTypes.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}) as Reducer<any>;

export default modalReducer;
