import { Button } from '@hologram-dimension/button';
import React from 'react';
import { ModalHeader } from '../../components';

interface SuccessModalProps {
  title: string;
  icon: string;
  infoWell?: React.ReactNode;
  children?: React.ReactNode;
  onClose: AnyFunction;
}

const SuccessModal = ({ title, icon, infoWell, children, onClose }: SuccessModalProps) => (
  <>
    <ModalHeader title={title} icon={icon} />

    {infoWell && <div className="BulkDataActionModal__info-panel">{infoWell}</div>}

    <div className="BulkDataActionModal__success">{children}</div>

    <div className="BulkDataActionModal__footer">
      <Button onClick={onClose}>Back to dashboard</Button>
    </div>
  </>
);

export default SuccessModal;
