import React from 'react';

function SummaryTableLineItemRow({ description, quantity, recurringCharge, total }) {
  return (
    <tr className="SummaryTable__row">
      <td className="SummaryTable__cell">{description}</td>
      <td className="SummaryTable__cell">{quantity}</td>
      <td className="SummaryTable__cell">{recurringCharge}</td>
      <td className="SummaryTable__cell SummaryTable__cell--total">{total}</td>
    </tr>
  );
}

export default SummaryTableLineItemRow;
