import useApi from 'common-js/api/useApi';
import { useCallback } from 'react';

const useResendEmailVerification = () => {
  const apiCall = useApi.call('/register/resendverify/', { method: 'POST' });

  return useCallback(
    async ({ userid }) => {
      const response = await apiCall({ body: { userid } });

      return response;
    },
    [apiCall]
  );
};

export default useResendEmailVerification;
