import { API_URL, getHeaders, headers } from 'common-js/api/config';
import { fetchErrorHandler, fetchHandler } from 'common-js/api/middleware';
import { getOrgIdFromContextData } from 'common-js/api/util';
import { isNull, isObject, omitBy, pick } from 'lodash';
import queryString from 'query-string';

export function getBalance(userContextData: any = {}) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/balance`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function updateBalanceParams(amountToAdd, threshold, userContextData: any = {}) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/balanceparams`;

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      minbalance: threshold,
      topoffamount: amountToAdd,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function updateCreditCardWithToken(token, userContextData: any = {}) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/billing`;

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      cardtoken: token,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function removeCreditCard(userContextData) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/billing`;

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      billingmethod: 0,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function updateBillingAddress(updatedAddress, userContextData) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/billing/address`;

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(updatedAddress),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function updateInvoiceInfo(updatedInfo, userContextData) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }`;

  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      invoice_text: updatedInfo,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function addBalance(amount, userContextData: any = {}) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/balance?orgId=${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }`;

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      addamount: amount,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function applyPromoCode(code, userContextData: any = {}) {
  const url = `${API_URL}/bonuscodes/apply${
    userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
  }`;

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      code,
    }),
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getBalanceHistory(userContextData: any = {}, filterOptions = {}) {
  const defaultFilters = {
    limit: 2000,
    offset: null,
    timestart: null,
    timeend: null,
    tagids: [],
  };

  let filters: any = isObject(filterOptions)
    ? Object.assign(defaultFilters, filterOptions)
    : defaultFilters;

  filters = omitBy(filters, isNull);

  const qs = queryString.stringify(pick(filters, ['limit', 'timestart', 'timeend', 'tagids']), {
    arrayFormat: 'bracket',
  });

  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/balancehistory?${qs}`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getBillingInformation(userContextData: any = {}) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/billing`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getBillingStatements(userContextData: any = {}) {
  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/statements`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getInvoices(userContextData: any = {}, filterOptions = {}) {
  const defaultFilters = {
    limit: 2000,
    starting_after: null,
    status: null,
  };

  let filters: any = isObject(filterOptions)
    ? Object.assign(defaultFilters, filterOptions)
    : defaultFilters;

  filters = omitBy(filters, isNull);

  const qs = queryString.stringify(pick(filters, ['limit', 'starting_after', 'status']), {
    arrayFormat: 'bracket',
  });

  const url = `${API_URL}/organizations/${
    userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
  }/billing/invoices?${qs}`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getAllPackages() {
  const url = `${API_URL}/productConfiguration/packages`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getPackage(userContextData: any = {}) {
  const orgid = getOrgIdFromContextData(userContextData);
  const url = `${API_URL}/organizations/${orgid}/billing/packages`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}
