import * as actionTypes from '../actionTypes';

export const openSearchModal = () => ({
  type: actionTypes.OPEN_MODAL,
});

export const closeSearchModal = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE_MODAL,
  });

  return Promise.resolve();
};
