import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';

const useUpdateOverageLimit = () => {
  const apiCall = useApi.call('/devices/:deviceId/usagelimit', {
    method: 'POST',
  });

  // Right now we don't do anything with this API response, so just return whether or not it succeeds
  return useCallback(
    async (deviceId, limit) => {
      const { success } = await apiCall({
        urlParams: { deviceId },
        body: {
          overagelimit: limit,
        },
      });
      return success;
    },
    [apiCall]
  );
};

export default useUpdateOverageLimit;
