import React from 'react';

function DeviceSkeletonTable({ rowCount }) {
  const rows = [];
  for (let i = 0; i < rowCount; i++) {
    rows.push(
      <tr className="Table__row" key={`DeviceSkeletonTable${i}`}>
        <td className="Table__cell DevicesTable__column DevicesTable__column--checkbox">
          <div className="Table__cell-content Table__cell-content--loading Table__cell-content--loading-checkbox Table__cell-content--centered" />
        </td>
        <td className="Table__cell DevicesTable__column DevicesTable__column--name">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
        <td className="Table__cell DevicesTable__column DevicesTable__column--state">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
        <td className="Table__cell DevicesTable__column DevicesTable__column--last-active">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
        <td className="Table__cell DevicesTable__column DevicesTable__column--usage">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
        <td className="Table__cell DevicesTable__column DevicesTable__column--plan">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
        <td className="Table__cell DevicesTable__column DevicesTable__column--imei">
          <div className="Table__cell-content Table__cell-content--loading" />
        </td>
      </tr>
    );
  }
  return rows;
}

export default DeviceSkeletonTable;
