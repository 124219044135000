import { useEffect } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { browserHistory } from 'react-router';
import { getReleaseFlags } from 'common-js/reducers/releaseFlag/selectors';

const useRedirectIfFlagNotSet = (flag, path) => {
  const { [flag]: releaseFlag } = useAppSelector(getReleaseFlags);

  useEffect(() => {
    if (!releaseFlag) {
      browserHistory.push(path);
    }
  }, [path, releaseFlag]);
};

export default useRedirectIfFlagNotSet;
