import { selectAccount } from 'common-js/reducers/account/selectors';
import { getAllPerms, Permission } from 'common-js/utils/permissions';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import { createSelector } from 'reselect';

export const selectOrganization = createSelector(
  (state: any) => state?.organization,
  (organization) => organization
);

export const getBalance = createSelector(
  selectOrganization,
  (organization) => organization.balance
);

export const getPostPay = createSelector(getBalance, (balance) => balance?.hasPostpay);

export const getPromoBalance = createSelector(getBalance, (balance) => balance?.promoBalance);

const getCurrentBalance = createSelector(getBalance, (balance) => balance?.currentBalance);

export const getRunningBalance = createSelector(
  [getPromoBalance, getCurrentBalance],
  (promoBalance, currentBalance) => promoBalance + currentBalance
);

export const selectTriggerContextSwitcher = createSelector(
  selectOrganization,
  (organization) => organization.triggerContextSwitcher
);

const getSelectedOrg = createSelector(selectOrganization, (organization) => {
  if (!organization.selectedContext) {
    return find(organization.orgs, { isMyAccount: true });
  }

  return find(organization.orgs, {
    id: organization.selectedContext,
  });
});

const getSelectedOrgId = createSelector(getSelectedOrg, (selectedOrg) =>
  selectedOrg ? selectedOrg.id : null
);

const getSelectedOrgIndex = createSelector(selectOrganization, (organization) => {
  if (!organization.selectedContext) {
    return -1;
  }

  const index = findIndex(organization.orgs, {
    id: organization.selectedContext,
  });

  if (organization.orgs[index] && organization.orgs[index].isMyAccount) {
    return -1;
  }

  return index;
});

const getUnSelectedOrgs = createSelector(selectOrganization, (organization) => {
  if (!organization.orgs) {
    return [];
  }

  // if selectedContext is null, that means the users private account is selected as the context.
  if (organization.selectedContext) {
    return organization.orgs.filter((org) => org.id !== organization.selectedContext);
  }

  return organization.orgs.filter(
    (org) => org.id !== organization.selectedContext && !org.isMyAccount
  );
});

const getUserPermissions = createSelector(
  [selectAccount, selectOrganization],
  (account, organization): Array<Permission> => {
    const { selectedContext, orgs } = organization ?? {};
    const { userId } = account ?? {};

    if (!selectedContext) {
      return getAllPerms();
    }

    const currentSelectedOrg = find(orgs, { id: selectedContext });

    if (currentSelectedOrg) {
      if (currentSelectedOrg.ownerid === userId) {
        return getAllPerms();
      }

      const user = find(currentSelectedOrg.users, { id: userId });

      if (user) {
        return user.permissions;
      }
      return [];
    }
    return [];
  }
);

export const getDevicePlanSummary = createSelector(
  selectOrganization,
  (organization): any => organization.devicePlanSummary ?? []
);

const getStatementsByBillingPeriod = (state) => {
  const statementByDateMap = {};

  state.organization.billingStatements.forEach((statement) => {
    const date = moment(statement.report_start_date, 'YYYY/MM/DD').format('YYYY-MM');
    statementByDateMap[date] = statement;
  });

  return statementByDateMap;
};

const getInvoices = (state) => state.organization.invoices;

const getInvoicesForInvoiceList = createSelector([getInvoices], (invoices) => {
  const onlyOpenAndPaidInvoices = (invoice) =>
    invoice.status === 'open' || invoice.status === 'paid';

  const onlyInvoicesWithBillingMonth = (invoice) =>
    moment(invoice?.metadata?.billing_month, 'YYYY-MM').isValid();

  const sortByBillingMonthDesc = (a, b) => {
    const aBillingMonthTimestamp = moment(a.metadata.billing_month, 'YYYY-MM').unix();
    const bBillingMonthTimestamp = moment(b.metadata.billing_month, 'YYYY-MM').unix();
    return aBillingMonthTimestamp < bBillingMonthTimestamp ? 1 : -1;
  };

  const sortedInvoices = invoices
    .filter(onlyOpenAndPaidInvoices)
    .filter(onlyInvoicesWithBillingMonth)
    .sort(sortByBillingMonthDesc);

  return sortedInvoices;
});

export const getDeviceCount = createSelector(
  selectOrganization,
  (organization) => organization.deviceCount
);
export const getDeviceCountActive = createSelector(
  selectOrganization,
  (organization) => organization.deviceCountActive
);

export const selectDeviceCountDeactivated = createSelector(
  selectOrganization,
  (organization) => organization.deviceCountDeactivated
);
export const selectDeviceCountPreflight = createSelector(
  selectOrganization,
  (organization) => organization.deviceCountPreflight
);

const getOrgHasZeroDevices = createSelector(
  [getDeviceCountActive, selectDeviceCountDeactivated, selectDeviceCountPreflight],
  (deviceCountActive, deviceCountDeactivated, deviceCountPreflight) =>
    deviceCountActive === 0 && deviceCountDeactivated === 0 && deviceCountPreflight === 0
);

export const selectBillingInformation = createSelector(
  selectOrganization,
  (organization) => organization?.billingInformation
);

export const selectBillingAddress = createSelector(
  selectBillingInformation,
  (billingInformation) => billingInformation?.billingAddress
);

export {
  getInvoicesForInvoiceList,
  getOrgHasZeroDevices,
  getSelectedOrg,
  getSelectedOrgId,
  getSelectedOrgIndex,
  getStatementsByBillingPeriod,
  getUnSelectedOrgs,
  getUserPermissions,
};
