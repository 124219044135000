import { Button } from '@holokit/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _classNames from 'clsx';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

import {
  getFilterCount,
  getIsAllSelected,
  getTotalDevices,
  getTotalSelectedDevices,
} from 'common-js/reducers/devices/selectors';
import { selectAllDevices, clearSelection } from 'common-js/reducers/devices/actions';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';

class BulkSelect extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    const container = this.container.current;

    if (visible && !prevProps.visible) {
      container.style.height = `${container.scrollHeight}px`;
    } else if (!visible && prevProps.visible) {
      container.style.height = '0px';
    }
  }

  handleClick = () => {
    const { hasAllSelected, clearSelection_, selectAllDevices_ } = this.props;
    if (hasAllSelected) {
      clearSelection_();
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Deselect All',
        },
      });
    } else {
      selectAllDevices_();
      sendAnalyticsEvent({
        type: BUTTON_CLICK,
        data: {
          name: 'Bulk Action - Select All',
        },
      });
    }
  };

  createInfoString = () => {
    const { filterCount, hasAllSelected, pagesSelected, totalSelectedDevices } = this.props;

    const formattedTotalSelectedDevices = addCommasToNumber(totalSelectedDevices, false);
    const formattedFilterCount = addCommasToNumber(filterCount, false);

    if (hasAllSelected || (totalSelectedDevices > 1 && pagesSelected.length >= 1)) {
      return `${formattedTotalSelectedDevices} Selected`;
    }
    if (totalSelectedDevices > 1) {
      return `${formattedTotalSelectedDevices} Devices Selected`;
    }
    if (totalSelectedDevices === 1) {
      return `${formattedTotalSelectedDevices} Device Selected`;
    }
    if (filterCount > 1) {
      return `${formattedFilterCount} Filters Applied`;
    }
    if (filterCount === 1) {
      return `${formattedFilterCount} Filter Applied`;
    }

    return undefined;
  };

  createButtonString = () => {
    const { filterCount, hasAllSelected, totalDevices } = this.props;

    const formattedTotalDevices = addCommasToNumber(totalDevices, false);

    if (hasAllSelected) {
      return 'Deselect All';
    }
    if (filterCount > 0 && totalDevices === 1) {
      return 'Select Matching Device';
    }
    if (filterCount > 0 && totalDevices > 1) {
      return `Select All ${formattedTotalDevices} Matching Devices`;
    }
    if (totalDevices > 1) {
      return `Select All ${formattedTotalDevices} Devices`;
    }

    return undefined;
  };

  render() {
    const { filterCount, hasAllSelected, pagesSelected, totalSelectedDevices } = this.props;

    const showFilterInfo = totalSelectedDevices === 0 && filterCount > 0;
    const showButton = filterCount > 0 || pagesSelected.length >= 1 || hasAllSelected;

    return (
      <div className="BulkSelect__container" ref={this.container}>
        <div className="BulkSelect">
          <div
            className={_classNames({
              'BulkSelect__number-selected': !showFilterInfo,
              'BulkSelect__filters-applied': showFilterInfo,
            })}
          >
            {this.createInfoString()}
          </div>
          {showButton && (
            <Button unstyled onClick={this.handleClick} classes="BulkSelect__button">
              {this.createButtonString()}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filterCount: getFilterCount(state),
  hasAllSelected: getIsAllSelected(state),
  totalDevices: getTotalDevices(state),
  totalSelectedDevices: getTotalSelectedDevices(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      selectAllDevices_: selectAllDevices,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BulkSelect);
