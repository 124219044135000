import { useCallback } from 'react';
import useApi from 'common-js/api/useApi';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectOrgId } from 'common-js/api/util';
import { RouterCredModel } from '../../models';

// we could make this pull the device ID from the currentDevice state
// this hook is more reusable if we accept the deviceId as a callback parameter
const useGetRouterCreds = () => {
  const apiCall = useApi.call('/csr/sources?:queryParams');
  const orgId = useAppSelector(selectOrgId);

  return useCallback(
    async (deviceId) => {
      const queryParams = new URLSearchParams({ deviceid: deviceId });
      if (orgId) queryParams.append('orgid', orgId);
      try {
        const { data } = await apiCall({
          urlParams: { queryParams: queryParams.toString() },
        });
        return new RouterCredModel(data?.length ? data[0] : undefined);
      } catch (err) {
        return new RouterCredModel();
      }
    },
    [apiCall, orgId]
  );
};

export default useGetRouterCreds;
