import analyticsEventBuilder from 'common-js/analytics';
import { MouseEventHandler, useCallback } from 'react';

interface UseHandleCoverageLinkProps {
  isOnboarding: boolean;
  changingPlan: boolean;
}
const useHandleCoverageLink = ({ isOnboarding, changingPlan }: UseHandleCoverageLinkProps) => {
  const onboardingText = isOnboarding ? '1st Time' : 'Returning';
  const analyticsTitle = changingPlan ? 'Change Data Plan' : 'Activation';
  const handleCoverageLink: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      analyticsEventBuilder
        .buttonClick(
          analyticsTitle,
          changingPlan ? undefined : onboardingText,
          `${analyticsTitle} Region Link to Coverage Page`
        )
        .send();
      window.open('https://www.hologram.io/coverage/', '_blank', 'rel=noreferrer');
    },
    [analyticsTitle, changingPlan, onboardingText]
  );

  return handleCoverageLink;
};

export default useHandleCoverageLink;
