import { find } from 'lodash';

import { UNKNOWN } from './types';

export default class Message {
  type = UNKNOWN;

  unRevealed = false;

  date = '';

  parsedRawData = {};

  constructor(log) {
    if (log.parsedRawData) this.parsedRawData = log.parsedRawData;
    if (log.logged) this.date = log.logged;
    if (log.unRevealed) this.unRevealed = log.unRevealed;
  }

  findTriggeredApps(log, apps) {
    return log.matchedRules.map((matchedRule) => {
      const matchedApp = find(apps, { id: matchedRule.ruleid });

      if (matchedApp) {
        return {
          ...matchedRule,
          nickname: matchedApp.nickname || matchedRule.ruleid,
          id: matchedRule.ruleid,
        };
      }
      return { ...matchedRule, id: matchedRule.ruleid };
    });
  }
}
