import { Pagination, Panel } from '@holokit/core';
import Moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { toast } from 'react-toastify';
import { getUserContextDataMemoized } from 'common-js/api/util';
import {
  BUTTON_CLICK,
  ERROR_RETURN,
  FILE_EXPORT,
  PAGE_VIEW,
} from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { attemptDocumentDownload, fetchActivityHistoryDetailsCSVExportReport } from 'common-js/api';
import * as Paths from 'common-js/constants/paths';
import useRedirectIfFlagNotSet from 'common-js/hooks/useRedirectIfFlagNotSet';
import { getBatchJobDetails } from 'common-js/reducers/devices/actions';
import { TASK_PAGE_SIZE } from 'common-js/reducers/devices/reducer';
import { selectBatchJobDetails } from 'common-js/reducers/devices/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import { getQueryString } from 'common-js/utils/dom/getQueryString';
import formatRequester from '../../../util/formatRequester';
import ActivityHistoryDetailsError from './ActivityHistoryDetailsError';
import ActivityHistoryDetailsSkeletonTitle from './ActivityHistoryDetailsSkeletonTitle';
import ActivityHistoryDetailsTable from './ActivityHistoryDetailsTable';
import ActivityHistoryDetailsTitle from './ActivityHistoryDetailsTitle';

const getToastSettings = (toastClass) => ({
  autoClose: 10000,
  className: ['toastify-content--burnt', toastClass, 'ActivityHistoryDetails__toast'].join(' '),
  position: toast.POSITION.TOP_RIGHT,
});

const handleError = (error) => {
  toast(
    <HeadlineToast
      headline="Export Error"
      body="This report could not be generated automatically. Please contact success@hologram.io."
      icon="Circle--alert"
    />,
    getToastSettings('toastify-content--error')
  );

  sendAnalyticsEvent({
    type: ERROR_RETURN,
    data: {
      name: 'Devices - Activity History - Export Failure',
      error: error.toString(),
    },
  });
};

const handleDocumentRequestReturn = ({ data, userEmail, setIsExporting }) => {
  if (data.finishedGenerating && data.documentId) {
    try {
      attemptDocumentDownload(data.documentId);
      toast(
        <HeadlineToast
          headline="CSV downloaded"
          body="Check your browser downloads to view the file."
          icon="Checkmark--single"
        />,
        getToastSettings('toastify-content--success')
      );

      sendAnalyticsEvent({
        type: FILE_EXPORT,
        data: {
          name: 'Devices - Activity History - Export',
          method: 'download',
        },
      });
    } catch (error) {
      handleError(error);
    }
  } else {
    toast(
      <HeadlineToast
        headline="Emailing export link..."
        body={`You will receive an email to ${userEmail} with a link to your export. This may take a few minutes.`}
        icon="Circle--info"
      />,
      getToastSettings('toastify-content--info')
    );

    sendAnalyticsEvent({
      type: FILE_EXPORT,
      data: {
        name: 'Devices - Activity History - Export',
        method: 'email',
      },
    });
  }

  setIsExporting(false);
};

const ActivityHistoryDetailsPanel = ({ batchJobId, simInventory }) => {
  useRedirectIfFlagNotSet(
    'activity_history_details',
    simInventory ? Paths.SIM_ACTIVITY : Paths.DEVICES_ACTIVITY
  );

  const [isExporting, setIsExporting] = useState(false);

  const userContext = useAppSelector(getUserContextDataMemoized);
  const dispatch = useAppDispatch();

  const { page } = queryString.parse(getQueryString());
  const currentPage = parseInt(page, 10) || 1;

  useEffect(() => {
    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: {
        name: `${simInventory ? 'Sims' : 'Devices'} - Activity History - Details Page`,
      },
    });
  }, [simInventory]);

  const loadPage = useCallback(
    ({ pageNumber, onlyDevices = false }) =>
      dispatch(getBatchJobDetails({ batchJobId, currentPage: pageNumber, onlyDevices })),
    [batchJobId, dispatch]
  );

  const details = useAppSelector(selectBatchJobDetails);
  const { totalPages } = details;

  const [initialPageLoadComplete, setInitialPageLoadComplete] = useState(false);

  useEffect(() => {
    if (!initialPageLoadComplete) {
      loadPage({ pageNumber: currentPage }).then(() => {
        setInitialPageLoadComplete(true);

        if (currentPage && currentPage > 1) {
          // We have to do it again now that we have the startAfterIds
          // This is clearly not optimal, but until the backend changes there isn't another way
          // This time, though, we just want to replace the devices
          loadPage({ pageNumber: currentPage, onlyDevices: true });
        }
      });
    }
  }, [currentPage, initialPageLoadComplete, loadPage, setInitialPageLoadComplete]);

  const moveToPage = (newPage, analyticsName) => {
    loadPage({ pageNumber: newPage, onlyDevices: true });

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: `Activity History Details - ${analyticsName}`,
      },
    });
  };

  const onNextClick = () => moveToPage(currentPage + 1, 'Next Click');

  const onPreviousClick = () => moveToPage(currentPage - 1, 'Previous Click');

  const onFastForwardClick = () => moveToPage(totalPages, 'Fast-forward Click');

  const onRewindClick = () => moveToPage(1, 'Rewind Click');
  // Only show an error if the initial page load has happened and we're not currently loading, but the device count is still 0
  const shouldShowError =
    details.devices.length === 0 && !details.loading && initialPageLoadComplete;

  const onExportCsv = useCallback(async () => {
    setIsExporting(true);

    sendAnalyticsEvent({
      type: BUTTON_CLICK,
      data: {
        name: `${simInventory ? 'Sims' : 'Devices'} - Activity History - Export CSV Button Click`,
      },
    });

    try {
      const response = await fetchActivityHistoryDetailsCSVExportReport({
        userContextData: userContext,
        batchJobId,
      });
      const { userEmail } = userContext;

      handleDocumentRequestReturn({
        data: response?.data,
        userEmail,
        setIsExporting,
      });
    } catch (error) {
      handleError(error);
    }
  }, [batchJobId, setIsExporting, userContext, simInventory]);

  const panelTitle = useMemo(() => {
    const { deviceCount, action, date, requester, source } = details;
    return !initialPageLoadComplete ? (
      <ActivityHistoryDetailsSkeletonTitle />
    ) : (
      <ActivityHistoryDetailsTitle
        numDevices={deviceCount}
        action={action}
        date={Moment.utc(date).format('MMMM D, YYYY h:mm A z')}
        teamMember={formatRequester(source, requester)}
        isExporting={isExporting}
        onExportCsv={onExportCsv}
      />
    );
  }, [details, initialPageLoadComplete, isExporting, onExportCsv]);

  return shouldShowError ? (
    <ActivityHistoryDetailsError error={details.error} />
  ) : (
    <Panel
      classes="ActivityHistoryDetails__panel"
      bodyClasses="ActivityHistoryDetails__panel-body"
      isLoading={details.loading}
      noBodyPadding
      title={panelTitle}
    >
      <div className="ActivityHistoryDetails__table-scroll-wrap">
        <ActivityHistoryDetailsTable rows={details.devices} isLoading={details.loading} />
      </div>
      <Pagination
        classes="ActivityHistoryDetailsTable__pagination"
        itemLabel="Devices"
        itemTotal={details.deviceCount}
        pageCurrent={currentPage}
        pageLimit={totalPages}
        pageSize={TASK_PAGE_SIZE}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        onFastForwardClick={onFastForwardClick}
        onRewindClick={onRewindClick}
        totalPages={totalPages}
      />
    </Panel>
  );
};

export default ActivityHistoryDetailsPanel;
