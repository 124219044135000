import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { PanelNotification } from '@hologram-dimension/panel-notification';
import { RadioField } from '@hologram-dimension/radio-field';
import { Field, Picture, type SimInventoryCommonTypes } from '@hologram-hyper-dashboard/components';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import analyticsEventBuilder from 'common-js/analytics';
import useAppSelector from 'common-js/hooks/useAppSelector';
import {
  getPostPay,
  getUserPermissions,
  selectBillingInformation,
} from 'common-js/reducers/organization/selectors';
import { BILLING } from 'common-js/utils/allPermissions';
import formatCurrencyWithRounding from 'common-js/utils/formatCurrencyWithRounding';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';
import ccUnknown from 'img/billing/credit-card-icons/Unknown.png?&w=32&imagetools';
import { DateTime } from 'luxon';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
  type MouseEventHandler,
  type ReactNode,
} from 'react';
import { useFormContext } from 'react-hook-form';
import CreditCardEditForm from './CreditCardEditForm';
import CreditCardSummary from './CreditCardSummary';
import PaymentErrors from './PaymentErrors';

interface ActivationProps {
  mode: 'activation';
  total: number;
  availableBalanceChange: number;
  upchargeToCreditCardMin: number;
  balance: number;
  loading: boolean;
  isOnboarding: boolean;
  changingPlan: boolean;
}

interface StandaloneProps {
  mode?: 'standalone';
  total?: number;
  availableBalanceChange?: number;
  upchargeToCreditCardMin?: number;
  balance?: number;
  loading: boolean;
  isOnboarding?: boolean;
  changingPlan?: boolean;
}

// TODO: I'm not super happy with this component structure. Preferably, we'd write a layer of abstraction onto the Activation version. For now this is sufficient
type PaymentDetailsPanelProps = ActivationProps | StandaloneProps;

const PaymentDetailsPanel: FC<PaymentDetailsPanelProps> = ({
  total = 0,
  availableBalanceChange = 0,
  upchargeToCreditCardMin = 0,
  balance = 0,
  loading,
  isOnboarding = false,
  changingPlan = false,
  mode = 'standalone',
}) => {
  const [paymentSaveError, setPaymentSaveError] = useState('');
  const [saving, setSaving] = useState(false);

  const {
    clearErrors,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const hasPostpay = useAppSelector(getPostPay);
  const creditCardEditState = getValues('creditCardInfo');
  const { paymentType } = creditCardEditState ?? {};

  const billingInformation = useAppSelector(selectBillingInformation);

  const { creditCard, isCardSet, failedBillingAttempts } = billingInformation ?? {};
  const { issuer, shortNumber, expirationMonth, expirationYear, hasHydrated } = creditCard ?? {};

  const userPermissions = useAppSelector(getUserPermissions);
  const hasBillingPermissions = userPermissions.includes(BILLING);

  const cardExp = useMemo(() => {
    if (!hasBillingPermissions || !isCardSet || !hasHydrated) {
      return false;
    }

    const expirationDate = DateTime.fromObject({
      year: expirationYear,
      month: expirationMonth,
    }).endOf('month');

    return expirationDate < DateTime.local();
  }, [expirationMonth, expirationYear, hasBillingPermissions, hasHydrated, isCardSet]);

  const autoRefillParams = getValues('autoRefillParams');
  const { threshold: autoRefillThreshold, enabled: isAutoRefillEnabled } = autoRefillParams ?? {};

  let totalToUse;
  if (availableBalanceChange > 0) {
    totalToUse = availableBalanceChange;
  } else {
    totalToUse = total - (upchargeToCreditCardMin ?? 0);
  }

  const isCCRequired =
    !changingPlan &&
    (isAutoRefillEnabled || (totalToUse > 0 && balance < totalToUse) || paymentType === 'card');

  const canChooseCreditCard =
    !changingPlan && (hasBillingPermissions || (isCardSet && totalToUse >= 5));
  const showBlankCard = !hasBillingPermissions && isCardSet && !hasHydrated;

  let contentWell: ReactNode | undefined;

  if (mode === 'standalone') {
    contentWell = undefined;
  } else if (isCardSet) {
    if (hasPostpay) {
      contentWell =
        'This credit card will only be charged if you have a past due invoice that exceeds your net payment terms.';
    } else {
      contentWell =
        'This credit card will be used to add account balance, set up Auto-Refill, and when changing data plans.';
    }
  } else if (isAutoRefillEnabled) {
    contentWell = `This credit card will be used for Auto-Refill to refill your balance drops below $${addCommasToNumber(
      autoRefillThreshold
    )}, to add account balance, and when changing data plans.`;
  }

  const [editing, setEditing] = useState(
    isCCRequired && !isCardSet && hasBillingPermissions && mode === 'activation'
  );

  const paymentMethodValidationMessage =
    !loading && !isOnboarding && balance < totalToUse ? 'Balance insufficient to cover cost.' : '';

  // TODO: Move to a hook to allow saving of credit card details in one place
  const stripe = useStripe();
  const elements = useElements();

  const beginEdit = useCallback(() => {
    if (isCardSet) {
      setValue(
        'creditCardInfo',
        {
          expiration: {
            rawValue: `${creditCard.expirationMonth}/${creditCard.expirationYear}`,
            month: creditCard.expirationMonth,
            year: creditCard.expirationYear,
            valid: true,
          },
          zip: creditCard.zip,
          paymentType: 'card',
        },
        { shouldValidate: true }
      );
    }
    setEditing(true);
  }, [setEditing, creditCard, setValue, isCardSet]);

  const cancelChanges = useCallback(() => {
    setEditing(false);
    setValue(
      'creditCardInfo',
      {
        paymentType: creditCardEditState.paymentType,
      },
      { shouldValidate: false }
    );
    clearErrors('creditCardInfo');
    setPaymentSaveError('');
    analyticsEventBuilder
      .buttonClick(mode === 'activation' ? 'Activation' : 'Billing', `Cancel payment method update`)
      .send();
  }, [clearErrors, creditCardEditState.paymentType, mode, setValue]);

  // TODO: This is an anti-pattern that should be refactored
  useEffect(() => {
    if (!loading && mode === 'activation') {
      if (canChooseCreditCard && balance < totalToUse && paymentType === 'balance') {
        setValue('creditCardInfo.paymentType', 'card');
      }

      if (cardExp && !editing) {
        setEditing(true);
      }

      if (!isCardSet) {
        if (isCCRequired && !editing) {
          setEditing(true);
        }
        if (!isCCRequired && editing) {
          cancelChanges();
        }
      }
    }
  }, [
    loading,
    balance,
    totalToUse,
    paymentType,
    isCardSet,
    isCCRequired,
    editing,
    setEditing,
    setValue,
    canChooseCreditCard,
    cancelChanges,
    cardExp,
    mode,
  ]);

  const ccValidationErrors = errors?.creditCardInfo || {};
  const ccErrors = { ...ccValidationErrors, paymentSaveError };

  const balanceFormatted = formatCurrencyWithRounding(balance ?? 0);

  const showMinimumChargeError =
    !hasBillingPermissions &&
    isCardSet &&
    !cardExp &&
    totalToUse < 5 &&
    paymentType === 'card' &&
    mode === 'activation';

  const showMinimumChargeNoCardError =
    !hasBillingPermissions &&
    !isCardSet &&
    totalToUse < 5 &&
    paymentType === 'card' &&
    mode === 'activation';

  const showCCPermissionEmptyState = !hasBillingPermissions && !isCardSet;

  const showAutoRefillCcNote = !!(isAutoRefillEnabled && !isCardSet && hasBillingPermissions);

  useEffect(() => {
    setValue(
      'creditCardInfo.invalidPaymentMethod',
      showMinimumChargeError || cardExp || showCCPermissionEmptyState || showAutoRefillCcNote
    );
  }, [cardExp, setValue, showAutoRefillCcNote, showCCPermissionEmptyState, showMinimumChargeError]);

  const showEmptyState = !isCardSet && mode === 'standalone' && !editing && hasBillingPermissions;

  const submitRef = useRef<SimInventoryCommonTypes.OnSubmit>();
  const handleSubmit: MouseEventHandler &
    MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    if (submitRef.current) {
      await submitRef.current.onSubmit();
    }
  };

  const onSuccess = useCallback(() => {
    setEditing(false);
    analyticsEventBuilder
      .dataEntry('Payment method entry', `Payment method saved successfully`)
      .send();
  }, []);

  const canCancel = ((!isCCRequired || !!isCardSet) && !cardExp) || mode === 'standalone';
  const handleEscape = useCallback(() => {
    if (canCancel) {
      cancelChanges();
    }
  }, [canCancel, cancelChanges]);

  // TODO: Conver this batch of <value> && <component> to an if block in a useMemo
  return (
    <Panel
      header={hasPostpay ? 'Backup payment method' : 'Payment details'}
      className="ReviewPaymentDetails"
      contentWell={contentWell}
      noContentPadding={showEmptyState || showCCPermissionEmptyState}
      isLoading={!stripe || !elements || loading}
      footer={
        showBlankCard &&
        'Contact an admin in your organization to make changes or for more details.'
      }
      footerVariant={showBlankCard ? 'alternative' : 'default'}
      footerActions={
        editing && (
          <>
            {canCancel && (
              <Button variant="delete" onClick={cancelChanges} disabled={saving}>
                Cancel
              </Button>
            )}
            <Button
              disabled={!stripe || !elements}
              loading={saving || loading}
              onClick={handleSubmit}
            >
              Save credit card
            </Button>
          </>
        )
      }
    >
      {showEmptyState ? (
        <PanelNotification
          className="ReviewPaymentDetails__empty"
          actions={
            <Button
              iconStart="CreditCard"
              onClick={() => {
                setEditing(true);
              }}
            >
              Add credit card
            </Button>
          }
        >
          This credit card will be used to refill your account balance and when changing data plans.
        </PanelNotification>
      ) : (
        <div className="ReviewPaymentDetails__summaryWrapper">
          {mode === 'activation' && (!isOnboarding || !editing) && (
            <div className="ReviewPaymentDetails__payment-methods">
              <RadioField
                id="pay-with-balance"
                defaultValue={paymentType}
                disabled={loading}
                validationMessage={paymentMethodValidationMessage}
                invalid={!!paymentMethodValidationMessage}
                label="Payment method"
                {...register('creditCardInfo.paymentType')}
                options={[
                  {
                    label: `Pay with balance: ${balanceFormatted}`,
                    value: 'balance',
                    disabled: changingPlan || balance < totalToUse,
                  },
                  {
                    label: 'Pay with credit card',
                    value: 'card',
                    disabled: changingPlan,
                  },
                ]}
              />
            </div>
          )}
          <div className="ReviewPaymentDetails__credit-card-form">
            {paymentType === 'balance' && !showAutoRefillCcNote && (
              <Field
                label="Account balance"
                direction="column"
                value={balanceFormatted}
                valueClassname="ReviewPaymentDetails__balanceValue"
              />
            )}
            {showMinimumChargeNoCardError && (
              <BannerNotification variant="error">
                The minimum credit card charge amount is $5.00 and you do not have permissions to
                add a credit card. Contact an admin in your organization to make changes or for more
                details.
              </BannerNotification>
            )}
            {showMinimumChargeError && (
              <BannerNotification variant="error">
                The minimum credit card charge amount is $5.00. If you want to pay using a credit
                card, you will need to activate more SIMs or choose a different data plan.
              </BannerNotification>
            )}
            {cardExp && (
              <BannerNotification variant="error">
                Your previously saved credit card expired.
              </BannerNotification>
            )}
            {(failedBillingAttempts ?? 0) > 0 && (
              <BannerNotification variant="error">
                {hasBillingPermissions
                  ? 'We were unable to charge your card on file. Please update your credit card.'
                  : 'We were unable to charge your card on file. Please contact an admin in your organization update your credit card.'}
              </BannerNotification>
            )}
            {showCCPermissionEmptyState && (
              <PanelNotification
                className="ReviewPaymentDetails__emptyUnactionable"
                label="No saved credit card"
              >
                Your account does not have permissions to add a credit card. Contact an admin in
                your organization to make changes or for more details.
              </PanelNotification>
            )}
            {showAutoRefillCcNote && (
              <BannerNotification variant="note">
                Please enter a credit card to refill your account balance with Auto-Refill.
              </BannerNotification>
            )}
            {editing && <PaymentErrors ccErrors={ccErrors} />}
            {showBlankCard && (
              <div className="CreditCardSummary__card-details">
                <Picture
                  className="CreditCardSummary__cardIcon"
                  imgClassName="CreditCardSummary__cardIcon-img"
                  image={ccUnknown}
                  alt=""
                />
                <div className="CreditCardSummary__card-details-text">
                  <span className="CreditCardSummary__card-number">
                    Saved card ending in <strong>••••</strong>
                  </span>
                  <span className="CreditCardSummary__card-expiration">Expires ••/••</span>
                </div>
              </div>
            )}
            {editing && (
              <CreditCardEditForm
                setLoading={setSaving}
                setError={setPaymentSaveError}
                ref={submitRef}
                onSuccess={onSuccess}
                onCancel={handleEscape}
              />
            )}
            {!editing &&
              !(paymentType === 'balance' || showAutoRefillCcNote) &&
              isCardSet && // It exists in the API
              hasHydrated && ( // We actually know the details to show
                <CreditCardSummary
                  issuer={issuer}
                  cardNumberLastFourDigits={shortNumber}
                  expirationMonth={expirationMonth}
                  expirationYear={expirationYear}
                  mode={mode}
                >
                  {hasBillingPermissions && (isCCRequired || isCardSet) && (
                    <Button variant="secondary" onClick={beginEdit}>
                      Update credit card
                    </Button>
                  )}
                </CreditCardSummary>
              )}
          </div>
        </div>
      )}
    </Panel>
  );
};

export default PaymentDetailsPanel;
