import * as API from 'common-js/api';
import { getUserContextData } from 'common-js/api/util';
import * as actionTypes from '../actionTypes';

export const getTasks = (timeStart) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_TASKS_REQUEST,
  });

  const state = getState();
  const userContextData = getUserContextData(state);

  return API.getTasks({ userContextData, timeStart })
    .then((data) => {
      const tasksGroupedByStatus = data?.tasks?.reduce(
        (acc, task) => ({
          ...acc,
          [task.status]: [...(acc[task.status] || []), task],
        }),
        {}
      );

      // Tasks come through in date descending order, so we need to reverse the pending tasks (which should be in date ascending)
      dispatch({
        type: actionTypes.GET_TASKS_SUCCESS,
        data: {
          ...tasksGroupedByStatus,
          pending: (tasksGroupedByStatus?.pending || []).slice().reverse(),
        },
      });
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_TASKS_ERROR,
        error,
      });

      return Promise.reject(error);
    });
};

export default getTasks;
