import React from 'react';
import { useFormContext } from 'react-hook-form';
import ActivationMoney from '../common/ActivationMoney';
import CompletedStepHeader from '../common/scaffolding/CompletedStepHeader';
import IncompleteStepHeader from '../common/scaffolding/IncompleteStepHeader';
import SidebarAttribute from '../common/scaffolding/SidebarAttribute';

const STEP_NAME = 'Set up auto-refill';

const AutoRefillSidebarItem = ({ step, currentStep }) => {
  const { enabled, amountToAdd, threshold } = useFormContext().getValues('autoRefillParams') ?? {};

  if (currentStep > step) {
    return (
      <CompletedStepHeader name={STEP_NAME}>
        <SidebarAttribute name="Auto-refill" value={enabled ? 'Enrolled' : 'Not enrolled'} />
        {enabled && (
          <SidebarAttribute
            name="Amount"
            value={
              <>
                <ActivationMoney amount={parseFloat(amountToAdd)} />
                {' added at '}
                <ActivationMoney amount={parseFloat(threshold)} />
                {' balance '}
              </>
            }
          />
        )}
      </CompletedStepHeader>
    );
  }

  return <IncompleteStepHeader step={step} currentStep={currentStep} name={STEP_NAME} />;
};

export default AutoRefillSidebarItem;
