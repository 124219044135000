export default function calculateElementPosition(element) {
  let currentTop = 0;

  let curElement = element;
  if (curElement.offsetParent) {
    do {
      currentTop += curElement.offsetTop;
      curElement = curElement.offsetParent;
    } while (curElement);

    return [currentTop];
  }
  return undefined;
}
