import React from 'react';
import * as Paths from 'common-js/constants/paths';
import { Link } from 'react-router';
import { BUTTON_CLICK } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

function sendAnalyticsEventForLinkClick(deviceId) {
  sendAnalyticsEvent({
    type: BUTTON_CLICK,
    data: {
      name: 'Devices - Activity History - Single Device Page',
      deviceId,
    },
  });
}

function ActivityHistoryDetailsTableRow({ deviceName, tags, iccid, imei, deviceId, linkId }) {
  return (
    <tr className="Table__row">
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          {deviceName ? (
            <Link
              className="ActivityHistoryDetailsTable__link"
              href={Paths.withContext(
                Paths.DEVICE_LANDING.replace(`:${Paths.DEVICE_PARAM_NAME}`, deviceId)
              )}
              onClick={() => sendAnalyticsEventForLinkClick(deviceId)}
              id={`device-link-${deviceId}`}
              target="_blank"
            >
              {deviceName}
            </Link>
          ) : (
            <div>No access to device</div>
          )}
          <div className="ActivityHistoryDetailsTable__subheader">
            {tags ? tags.map(({ name }) => name).join(', ') : 'SIM moved to new org'}
          </div>
        </div>
      </td>
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          {iccid ? (
            <>
              <div>{iccid}</div>
              <div className="ActivityHistoryDetailsTable__subheader">{imei}</div>
            </>
          ) : (
            <>&ndash;</>
          )}
        </div>
      </td>
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          {deviceId ?? <>&ndash;</>}
        </div>
      </td>
      <td className="Table__cell ActivityHistoryDetailsTable__cell">
        <div className="Table__cell-content ActivityHistoryDetailsTable__cell-content">
          {linkId ?? <>&ndash;</>}
        </div>
      </td>
    </tr>
  );
}

export default ActivityHistoryDetailsTableRow;
