import useApi from 'common-js/api/useApi';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectOrgId } from 'common-js/api/util';

const useAllReports = () => {
  const orgid = useAppSelector(selectOrgId);
  const { loading, data, error } = useApi.now(
    `/reports/embedded?${new URLSearchParams({ orgid }).toString()}`
  );
  return {
    data: data?.data,
    error,
    loading,
  };
};

export default useAllReports;
