import { Button, Panel } from '@holokit/core';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { attemptDocumentDownload, getDeviceBreakdown } from 'common-js/api';
import { buildDeviceBreakdownHref } from 'common-js/api/usage';
import { getUserContextData } from 'common-js/api/util';
import { HoloHelmet } from 'common-js/components';
import { setDrawerTakeover } from 'common-js/reducers/drawer/actions';
import {
  requestDeviceBreakdownUsageReport,
  syncFiltersToQueryString,
} from 'common-js/reducers/usage/actions';
import { VIEWMORE_ROWS } from 'common-js/reducers/usage/reducer';
import { buildChartFilters } from 'common-js/reducers/usage/selectors';
import HeadlineToast from 'common-js/toasts/HeadlineToast';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import UsageModeSelector from '../components/UsageModeSelector';
import { UsageBreakdownByDeviceTable } from './parts';

const getToastSettings = (toastClass) => ({
  autoClose: 10000,
  className: ['toastify-content--burnt', toastClass, 'UsageBreakdown__toast'].join(' '),
  position: toast.POSITION.TOP_RIGHT,
});

class Usage extends React.Component {
  static showErrorToast() {
    toast(
      <HeadlineToast
        headline="Export Error"
        body="This report could not be generated automatically. Please contact success@hologram.io."
        icon="Circle--alert"
      />,
      getToastSettings('toastify-content--error')
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      isExportingCsv: false,
      redirecting: true,
      deviceData: [],
      continues: false,
      fetchError: null,
      loading: true,
      loadingMore: false,
    };

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Usage - Breakdown' },
    });
  }

  componentDidMount() {
    const { setDrawerTakeover_, syncFiltersToQueryString_ } = this.props;

    setDrawerTakeover_(false);

    syncFiltersToQueryString_().finally(() => {
      this.setState({ redirecting: false });
      this.loadDeviceBreakdownData();
    });
  }

  onExportCSV = () => {
    const { requestDeviceBreakdownUsageReport_ } = this.props;

    this.setState({ isExportingCsv: true }, () => {
      requestDeviceBreakdownUsageReport_()
        .then(this.handleDocumentRequestReturn)
        .catch(Usage.showErrorToast);
    });
  };

  handleDocumentRequestReturn = (data) => {
    const { userContextData } = this.props;
    const { userEmail } = userContextData;

    if (data.finishedGenerating && data.documentId) {
      try {
        attemptDocumentDownload(data.documentId);
        toast(
          <HeadlineToast
            headline="CSV downloaded"
            body="Check your browser downloads to view the file."
            icon="Checkmark--single"
          />,
          getToastSettings('toastify-content--success')
        );
      } catch {
        Usage.showErrorToast();
      }
    } else {
      toast(
        <HeadlineToast
          headline="Emailing export link..."
          body={`You will receive an email to ${userEmail} with a link to your export. This may take a few minutes.`}
          icon="Circle--info"
        />,
        getToastSettings('toastify-content--info')
      );
    }
    this.setState({ isExportingCsv: false });
  };

  loadDeviceBreakdownData = () => {
    const { reportFilters, userContextData } = this.props;
    const { deviceData } = this.state;

    if (deviceData.length > 0) {
      this.setState({ loadingMore: true });
    }

    getDeviceBreakdown(userContextData, reportFilters, VIEWMORE_ROWS, deviceData.length)
      .then((response) => {
        this.setState((state) => ({
          deviceData: state.deviceData.concat(response.data),
          continues: response.continues,
        }));

        if (deviceData.length === 0) {
          this.setState({ loading: false });
        } else {
          this.setState({ loadingMore: false });
        }
      })
      .catch((fetchError) => {
        this.setState({
          fetchError,
          loading: false,
          loadingMore: false,
        });
      });
  };

  render() {
    const { continues, deviceData, fetchError, isExportingCsv, loading, loadingMore, redirecting } =
      this.state;
    const { userContextData, reportFilters } = this.props;

    if (redirecting) return null;

    return (
      <div>
        <HoloHelmet title="Usage Reports - Device Breakdown" />
        <UsageModeSelector mode="breakdown" />
        <Panel
          title="Data Usage by Device"
          noBodyPadding
          isLoading={loading}
          headerButtons={
            <div>
              <Button
                href={buildDeviceBreakdownHref(userContextData, reportFilters)}
                type="secondary"
              >
                View JSON
              </Button>
              <Button type="secondary" loading={isExportingCsv} onClick={this.onExportCSV}>
                Export CSV
              </Button>
            </div>
          }
        >
          <UsageBreakdownByDeviceTable
            deviceData={deviceData}
            exportCsvCallback={this.onExportCSV}
            isExportingCsv={isExportingCsv}
            error={fetchError}
            continues={continues}
            loading={loading}
            loadingMore={loadingMore}
            onLoadMore={this.loadDeviceBreakdownData}
          />
        </Panel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    reportFilters: buildChartFilters(state),
    userContextData: getUserContextData(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setDrawerTakeover_: setDrawerTakeover,
        syncFiltersToQueryString_: syncFiltersToQueryString,
        requestDeviceBreakdownUsageReport_: requestDeviceBreakdownUsageReport,
      },
      dispatch
    )
)(Usage);
