import React from 'react';
import FullScreenModal from 'common-js/components/full-screen-modal';

interface ActivationStepProps {
  title: string;
  subtitle?: string;
  currentStep: number;
  totalSteps: number;
  onClose: AnyFunction;
  optional?: boolean;
  sidebar?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  changingPlan?: boolean;
}

const ActivationStep = ({
  title,
  subtitle,
  currentStep,
  totalSteps,
  onClose,
  optional = false,
  sidebar = null,
  footer = null,
  children,
  changingPlan,
}: ActivationStepProps) => (
  <FullScreenModal
    title={changingPlan ? 'Change data plan' : 'Activate SIMs'}
    progressIndicatorType="text"
    currentStep={currentStep}
    totalSteps={totalSteps}
    optionalStep={optional}
    sidebar={sidebar}
    onClose={onClose}
    footer={footer}
  >
    <div className="ActivationStep">
      <h1 className="ActivationStep__title">{title}</h1>
      {subtitle && <h2 className="ActivationStep__subtitle">{subtitle}</h2>}
      {children}
    </div>
  </FullScreenModal>
);

export default ActivationStep;
