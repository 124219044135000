import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useGetDeviceById from 'common-js/api/devices/useGetDeviceById';
import useGetDeviceProfileSessionSample from 'common-js/api/devices/useGetDeviceProfileSessionSample';
import useGetDeviceProfileStatusHistory from 'common-js/api/devices/useGetDeviceProfileStatusHistory';
import useGetDeviceEUICCHistory from 'common-js/api/devices/useGetDeviceEUICCHistory';
import useGetDeviceEUICCTestHistory from 'common-js/api/devices/useGetDeviceEUICCTestHistory';
import useGetDeviceProfiles from 'common-js/api/devices/useGetDeviceProfiles';
import { setCurrentDeviceWithMetadata } from 'common-js/reducers/currentDevice/actions';

// Returns a function which does many API calls to gather everything we need to know about the current device.
// It places the results of those API calls into redux, since that data will be shared across the single device page.
const useGetAllDeviceData = () => {
  const dispatch = useAppDispatch();
  const getDevice = useGetDeviceById();
  const getSessionSample = useGetDeviceProfileSessionSample();
  const getStatusHistory = useGetDeviceProfileStatusHistory();
  const getEUICCHistory = useGetDeviceEUICCHistory();
  const getEUICCTestHistory = useGetDeviceEUICCTestHistory();
  const getProfiles = useGetDeviceProfiles();

  return useCallback(
    async (deviceId) => {
      const device = await getDevice(deviceId);
      const [sessionSample, statusHistory, euiccHistory, euiccTestHistory, profiles] =
        // TODO: real error state, instead of swallowing any errors here
        await Promise.allSettled([
          getSessionSample(device.id),
          getStatusHistory(device.linkId),
          getEUICCHistory(deviceId),
          getEUICCTestHistory(deviceId),
          getProfiles(deviceId),
        ]);
      const error =
        sessionSample.rejected ??
        statusHistory.rejected ??
        euiccHistory.rejected ??
        euiccTestHistory.rejected ??
        profiles.rejected ??
        null; // clear out any old errors if all succeed
      dispatch(
        setCurrentDeviceWithMetadata({
          device,
          error,
          euiccHistory: euiccHistory.value ?? [],
          euiccTestHistory: euiccTestHistory.value ?? [],
          profiles: profiles.value ?? [],
          sessionSample: sessionSample.value ?? [],
          statusHistory: statusHistory.value ?? [],
        })
      );
    },
    [
      // many deps, such memo, very wow
      dispatch,
      getDevice,
      getEUICCHistory,
      getEUICCTestHistory,
      getProfiles,
      getSessionSample,
      getStatusHistory,
    ]
  );
};

export default useGetAllDeviceData;
