import { getNumberDevicesString } from 'devices/util/getNumberDevicesString';
import { translateDeviceAction } from 'devices/util/translateDeviceAction';

// Given a number of devices and an action, returns a string like "2,345 devices resumed"
// If the action has no translation, it will be omitted ("2,345 devices")
export const getDeviceActionString = (numberDevices, action) => {
  const { actionPastTense } = translateDeviceAction(action);
  const actionString = actionPastTense ? ` ${actionPastTense}` : '';

  const devicesString = getNumberDevicesString(numberDevices);

  return `${devicesString}${actionString}`;
};

export default getDeviceActionString;
