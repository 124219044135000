import ClassNames from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Loader } from '@holokit/core';
import { eUICCActiveStates } from 'common-js/constants/deviceStates';
import { eUICCTestModel } from 'common-js/models';
import scrollTo from 'common-js/utils/dom/scrollTo';
import { getOperatorDisplayName } from 'common-js/utils/operatorDisplayName';
import HyperTestingTableDrawer from './HyperTestingTableDrawer';
import HyperDrawerExpander from './HyperDrawerExpander';

class HyperTestingTableRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.isActive || false,
      isDrawerLoading: false,
    };
    this.testRow = React.createRef();
    this.preventUnfurl = false;

    this.pendingTimeouts = {
      focusOnTest: null,
      onDrawerLoad: null,
      onRowClick: null,
    };
  }

  componentWillUnmount() {
    Object.values(this.pendingTimeouts)
      .filter((timeoutId) => timeoutId !== null)
      .forEach((timeoutId) => clearTimeout(timeoutId));
  }

  onDrawerLoad = () => {
    const { onSelectedTestChange, test } = this.props;

    this.setState({ isDrawerLoading: false }, () => {
      this.pendingTimeouts.onDrawerLoad = setTimeout(() => {
        this.pendingTimeouts.onDrawerLoad = null;
        onSelectedTestChange(this, test.id);
      }, 200);
    });
  };

  handleRowClick = (e) => {
    const { isActive } = this.state;
    const { onSelectedTestChange, test } = this.props;

    if (this.preventUnfurl) {
      this.preventUnfurl = false;
    } else if (isActive) {
      onSelectedTestChange(this, null);
    } else {
      this.setState({ isDrawerLoading: true });
      this.focusOnTest();

      this.pendingTimeouts.onRowClick = setTimeout(() => {
        this.pendingTimeouts.onRowClick = null;
        onSelectedTestChange(this, test.id);

        this.pendingTimeouts.focusOnTest = setTimeout(() => {
          this.pendingTimeouts.focusOnTest = null;
          this.focusOnTest();
        }, 200);
      }, 200);
    }

    e.stopPropagation();
  };

  focusOnTest = () => {
    const { test, scrollWrap } = this.props;
    const row = document.getElementById(`HyperTestingTableRow-${test.id}`);

    if (row) {
      const rowHeight = row.clientHeight || row.offsetHeight;

      if (rowHeight) {
        scrollTo(
          row.offsetTop + row.clientHeight - scrollWrap.current.offsetTop - 4,
          () => {},
          200,
          scrollWrap.current
        );
      }
    }
  };

  handleTextMouseDown = () => {
    this.preventUnfurl = true;
    window.addEventListener('mouseup', this.handleRowTextDragMouseUp);
  };

  handleRowTextDragMouseUp = () => {
    this.preventUnfurl = false;
    window.removeEventListener('mouseup', this.handleRowTextDragMouseUp);
  };

  render() {
    const { isActive, isDrawerLoading } = this.state;
    const { test } = this.props;
    const {
      currentCycle,
      iccid = 'Unknown',
      imei = 'Unknown',
      requestedCycles = '—',
      state,
      timestamp,
    } = test.latestOperation;

    const duration = test.duration || 'Unknown';

    const cycleError =
      !eUICCActiveStates.includes(state) &&
      typeof requestedCycles !== 'string' &&
      requestedCycles - currentCycle > 0;

    return (
      <>
        {isActive && (
          <tr>
            <td className="HyperTestingTable__loader" colSpan={5}>
              <div className="HyperTestingTable__loader__container">
                <Loader isLoading={isDrawerLoading} />
              </div>
            </td>
          </tr>
        )}
        <tr
          className={ClassNames('HyperTestingTable__row', {
            'HyperTestingTable__row--active': isActive,
          })}
          onClick={this.handleRowClick}
          ref={this.testRow}
          id={`euicc-profile-history-${test.id}`}
          key={test.id}
        >
          <td className="HyperTestingTable__cell">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="HyperTestingTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseDown={this.handleTextMouseDown}
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
            >
              <div className="HyperTestingTable__cell__key HyperTestingTable__cell__key--timestamp">
                {eUICCTestModel.formatTimestamp(timestamp)}
              </div>
              <div className="HyperTestingTable__cell__value">
                {duration && duration.format('H[h] m[m] ss[s]')}
              </div>
            </div>
          </td>
          <td className="HyperTestingTable__cell">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="HyperTestingTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseDown={this.handleTextMouseDown}
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
            >
              <div
                className={ClassNames('HyperTestingTable__cell__key', {
                  'HyperTestingTable__cell__key--error': cycleError,
                })}
              >
                {requestedCycles > 0 ? currentCycle : '—'}/
                {requestedCycles > 0 ? requestedCycles : '—'}
              </div>
            </div>
          </td>
          <td className="HyperTestingTable__cell">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="HyperTestingTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseDown={this.handleTextMouseDown}
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
            >
              <div className="HyperTestingTable__cell__key">{iccid}</div>
              <div className="HyperTestingTable__cell__value">
                {getOperatorDisplayName(test.iccid)}
              </div>
            </div>
          </td>
          <td className="HyperTestingTable__cell">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="HyperTestingTable__cell__text"
              onClick={(e) => e.stopPropagation()} /* Prevent unfurl */
              onMouseDown={this.handleTextMouseDown}
              onMouseEnter={this.handleTextMouseEnter}
              onMouseLeave={this.handleTextMouseBlur}
            >
              <div className="HyperTestingTable__cell__key">{imei}</div>
            </div>
          </td>
          <td className="HyperTestingTable__cell">
            <div className="HyperTestingTable__cell__arrow">
              <div className="HyperTestingTable__arrow">
                <Icon
                  classes="HyperTestingTable__arrow__icon HyperTestingTable__arrow__icon--default"
                  name="Chevron--single--south"
                  size="minor"
                  svgProps={{ style: { fill: '#969fb1' } }}
                />
                <Icon
                  classes="HyperTestingTable__arrow__icon HyperTestingTable__arrow__icon--hover"
                  name="Chevron--single--south"
                  size="minor"
                  svgProps={{ style: { fill: '#0a1435' } }}
                />
                <Icon
                  classes="HyperTestingTable__arrow__icon HyperTestingTable__arrow__icon--active"
                  name="Chevron--single--south"
                  size="minor"
                  svgProps={{ style: { fill: '#ffffff' } }}
                />
              </div>
            </div>
          </td>
        </tr>
        {isActive && (
          <tr
            className="HyperTestingTable__row"
            id={`euicc-profile-history-${test.id}--active`}
            key={`euicc-profile-history-${test.id}--active`}
          >
            <td className="HyperTestingDrawer__table-cell" colSpan={5}>
              <HyperDrawerExpander>
                <HyperTestingTableDrawer
                  test={test}
                  isLoading={isDrawerLoading}
                  onDrawerLoad={this.onDrawerLoad}
                />
              </HyperDrawerExpander>
            </td>
          </tr>
        )}
      </>
    );
  }
}

HyperTestingTableRow.propTypes = {
  onSelectedTestChange: PropTypes.func.isRequired,
  scrollWrap: PropTypes.shape({
    current: PropTypes.element.isRequired,
  }),
  test: PropTypes.shape({
    id: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    timestamp: PropTypes.object,
    deviceid: PropTypes.string,
    actionName: PropTypes.string,
    stateName: PropTypes.string,
    formattedErrorMessage: PropTypes.string,
    concurrentWith: PropTypes.shape({ length: PropTypes.number }), // TODO: This looks like an array, but we don't use more than that here
    currentCycle: PropTypes.string,
    requestedCycles: PropTypes.string,
  }).isRequired,
};

export default HyperTestingTableRow;
