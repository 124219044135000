import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from '@holokit/core';
import { ModalHeaderWithIcon } from '../components';
import HeadlineToast from '../toasts/HeadlineToast';

import { requestHyperTest } from '../reducers/singleDevice/actions';

import { close } from '../reducers/modal/actions';

class RequestHyperCompatTestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  onRequestTest = () => {
    const { device, close_, requestHyperTest_ } = this.props;

    this.setState({ isLoading: true });
    requestHyperTest_(device.id)
      .then(() => {
        close_();
        toast(
          <HeadlineToast
            headline="Test Requested"
            icon="Checkmark--single"
            body="Hologram Success will be in touch with your results."
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toastify-content--burnt toastify-content--success',
            autoClose: 10000,
          }
        );
      })
      .catch(() => {
        toast(
          <HeadlineToast
            headline="Test Requested"
            icon="Circle--alert"
            body="Failed to request test."
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toastify-content--burnt toastify-content--error',
            autoClose: 10000,
          }
        );
        close_();
      });
  };

  render() {
    const { close_ } = this.props;
    const { isLoading } = this.state;

    return (
      <div>
        <ModalHeaderWithIcon
          classes="HyperModal__header"
          iconName="Chip"
          title="Request Hyper Compatibility Test"
        />
        <div className="HyperModal__content">
          <p className="section modal-description">
            To enable full Hyper platform features, we need to test each hardware module you plan to
            use to ensure it is compatible with our eUICC services. Once requested, Hologram Success
            will prepare and run an series of tests remotely for this device.
          </p>
          <div className="HyperModal__checklist">
            <div className="HyperModal__list-check">
              <Icon
                name="Checkmark--single"
                size="minor"
                svgProps={{ style: { fill: '#8008F7' } }}
              />
              <p>Keep this device powered on</p>
            </div>
            <div className="HyperModal__list-check">
              <Icon
                name="Checkmark--single"
                size="minor"
                svgProps={{ style: { fill: '#8008F7' } }}
              />
              <p>Ensure this device has a strong network signal</p>
            </div>
            <div className="HyperModal__list-check">
              <Icon
                name="Checkmark--single"
                size="minor"
                svgProps={{ style: { fill: '#8008F7' } }}
              />
              <p>Double check your device can connect to the internet</p>
            </div>
          </div>
          <Button onClick={close_} type="secondary">
            Cancel
          </Button>
          <div className="HyperModal__action-button">
            <Button
              className="HyperInfo__test-button"
              iconLeft="Circle--check"
              onClick={this.onRequestTest}
              type="primary"
              loading={isLoading}
              disabled={isLoading}
            >
              {isLoading ? 'Requesting' : 'This device is ready for testing'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      close_: close,
      requestHyperTest_: requestHyperTest,
    },
    dispatch
  )
)(RequestHyperCompatTestModal);
