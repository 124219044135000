import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useOnPauseEvent from './useOnPauseEvent';

interface UseHandlePauseProps {
  onStateChange?: () => void;
}

const useHandlePause = ({ onStateChange }: UseHandlePauseProps) => {
  const dispatch = useAppDispatch();
  const device = useAppSelector(selectCurrentDevice);
  const onPauseEvent = useOnPauseEvent();

  const onConfirm = useCallback(async () => {
    await onPauseEvent();
    if (onStateChange) {
      onStateChange();
    }
  }, [onPauseEvent, onStateChange]);

  return useCallback(() => {
    dispatch(
      openModal('PauseSimModal', {
        devices: [device],
        onConfirm,
      })
    );
  }, [dispatch, device, onConfirm]);
};

export default useHandlePause;
