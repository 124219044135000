import React from 'react';
import _ from 'lodash';
import onClickOutsideHOC from 'react-onclickoutside';
import ClassNames from 'clsx';

class DropDownMenu2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  handleClickOutside() {
    this.close();
  }

  UNSAFE_componentWillMount() {
    this.onBodyKeyDown = function (e) {
      if (e.keyCode === 27) {
        this.setState({
          opened: false,
        });
      }
    }.bind(this);

    document.addEventListener('keydown', this.onBodyKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onBodyKeyDown);
  }

  open() {
    this.setState({ opened: true });
  }

  close() {
    this.setState({ opened: false });
  }

  render() {
    const { button, classNames, children } = this.props;
    const { opened } = this.state;

    const containerClassNames = ClassNames('DropdownMenu2', classNames);

    return (
      <div className={containerClassNames}>
        {button(this.open.bind(this), this.close.bind(this), opened)}
        {opened && children}
      </div>
    );
  }
}

DropDownMenu2.defaultProps = {
  button: _.noop,
};

export default onClickOutsideHOC(DropDownMenu2);
