import { createSelector } from 'reselect';

export const getSearchResults = (state) => state.search.searchResults;
export const getModalState = (state) => state.search.uiState.modal.opened;
export const getSearchInputValue = (state) => state.search.uiState.modal.inputValue;

export const selectSearch = (state) => state.search;
export const selectSearchModalState = createSelector(
  selectSearch,
  (search) => search.uiState.modal
);
export const selectSearchModalOpen = createSelector(
  selectSearchModalState,
  (modal) => modal.opened
);
export const selectSearchQuery = createSelector(selectSearch, (search) => search.query);
