import { fetchAccount } from '.';
import * as API from '../../../api';
import { clearMfaErrors, finishMfaFlow, handleMfaError, startMfaAuthentication } from './actions';

const authenticateTotp = (totpCode) => async (dispatch, getState) => {
  dispatch(clearMfaErrors());
  dispatch(startMfaAuthentication());
  const state = getState();
  const methodId = state?.account.mfa?.method.email_id;
  const rememberme = state?.account.mfa?.method.rememberme ?? false;
  const { email } = state.account.mfa.method;

  try {
    const response = await API.authenticateTotp(email, methodId, totpCode, rememberme);
    if (!response.error) {
      return dispatch(fetchAccount()).then(() => {
        dispatch(finishMfaFlow());
      });
    }
    return response;
  } catch (e) {
    return dispatch(handleMfaError(e));
  }
};

export default authenticateTotp;
