import ClassNames from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Icon } from '@holokit/core';

import { selectCurrentDeviceEuiccTestHistory } from 'common-js/reducers/currentDevice/selectors';
import { eUICCTestModel } from 'common-js/models';
import HyperNotification from './HyperNotification';
import HyperTestingTableRow from './HyperTestingTableRow';

class HyperTestingHarness extends React.Component {
  static generateLastResultValues = (lastResult) => {
    const result = {
      icon: null,
      color: null,
      message: null,
      description: null,
    };

    if (!lastResult) {
      return result;
    }

    if (lastResult.state === 'COMPLETE') {
      result.icon = 'Circle--check';
      result.color = '#06603C';
      result.message = `Completed ${lastResult.currentCycle}/${lastResult.requestedCycles} cycles successfully. 🙌`;
    } else {
      result.icon = 'Circle--minus';
      result.color = '#A80000';
      result.message =
        lastResult.errorReason ||
        `An error occurred on cycle ${lastResult.currentCycle}/${lastResult.requestedCycles}. 🥀`;
    }

    if (lastResult.isRollbackPending) {
      result.description = 'Attempting to restore previous stable state';
      if (lastResult.state === 'ERROR' || lastResult.currentState) {
        result.description = 'An error occurred, attempting to restore previous stable state';
      }
    }

    return result;
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      selectedTestId: null,
    };

    this.testHistory = [];
    this.lastResult = null;

    // Try to group eUICC Test history entries together by "TEST_REQUESTED"
    if (props.euiccTestHistory.length) {
      this.testHistory = eUICCTestModel.groupByTest(props.euiccTestHistory);
      this.lastResult = this.testHistory
        .map((test) => test.latestOperation)
        .find((test) => test.action === 'EUICC_TEST');
    }

    const { icon, color, message, description } = HyperTestingHarness.generateLastResultValues(
      this.lastResult
    );

    this.lastResultIcon = icon;
    this.lastResultColor = color;
    this.lastResultMessage = message;
    this.rollbackDescription = description;
  }

  onSelectedTestChange = (row, testId) => {
    const { selectedRow, selectedTestId } = this.state;
    const newIsActive = !row.state.isActive;

    if (testId !== selectedTestId) {
      if (selectedRow) {
        selectedRow.setState({ isActive: false });
      }
      this.setState(
        {
          selectedRow: row,
          selectedTestId: testId,
        },
        () => {
          const { selectedRow: updatedRow } = this.state;
          updatedRow.setState({ isActive: newIsActive });
        }
      );
    }
  };

  render() {
    if (!this.lastResult) {
      return (
        <div className={ClassNames('Panel__info')}>
          <div className={ClassNames('HyperTestingHarness__no-data')}>
            <Icon
              name="Circle--info"
              size="major"
              classes={ClassNames('HyperTestingHarness__info-icon')}
              svgProps={{ style: { fill: '#8008f7' } }}
            />
            <p className={ClassNames('HyperTestingHarness__paragraph')}>
              This device has not been tested for eUICC compatibility yet.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="HyperTestingHarness">
        {this.rollbackDescription && (
          <HyperNotification
            iconName="Circle--info"
            iconSize="major"
            iconSvgProps={{ style: { fill: '#8008f7' } }}
            message="Rollback in progress"
            description={this.rollbackDescription}
            rollback
          />
        )}
        <HyperNotification
          iconName={this.lastResultIcon}
          iconSize="major"
          iconSvgProps={{ style: { fill: this.lastResultColor } }}
          heading="Most Recent Test Results"
          message={this.lastResultMessage}
          description={this.lastResult.errorMessage}
        />
        <div className="HyperTestingHarness">
          <table className="HyperTestingTable">
            <thead className="HyperTestingTable__section HyperTestingTable__section--header">
              <tr className="HyperTestingTable__row">
                <th className="HyperTestingTable__cell--header--date">Date / Duration</th>
                <th className="HyperTestingTable__cell--header--cycles">Successful Cycles</th>
                <th className="HyperTestingTable__cell--header--operator">
                  Operational ICCID / Operator
                </th>
                <th className="HyperTestingTable__cell--header--imei">IMEI</th>
                <th className="HyperTestingTable__cell"> </th>
              </tr>
            </thead>
            <tbody className="HyperTestingTable__section HyperTestingTable__section--body">
              {this.testHistory.map((test) => (
                <HyperTestingTableRow
                  id={`HyperTestingTableRow--${test.id}`}
                  key={test.id}
                  onSelectedTestChange={this.onSelectedTestChange}
                  test={test}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

HyperTestingHarness.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  euiccTestHistory: PropTypes.array,
};

HyperTestingHarness.defaultProps = {
  euiccTestHistory: [],
};

const mapStateToProps = (state) => ({
  euiccTestHistory: selectCurrentDeviceEuiccTestHistory(state),
});

export default connect(mapStateToProps)(HyperTestingHarness);
