import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { HelpText } from '@hologram-dimension/help-text';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { useAppDispatch } from 'common-js/hooks';
import { pushGlobalMessage } from 'common-js/reducers/message/actions';
import { addBalance, getAllBilling } from 'common-js/reducers/organization/actions';
import { type FC, useCallback, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Money } from '../components';

interface AddBalanceFormProps {
  onCancelClick: () => void;
}

interface FormProps {
  amount: number;
}

const AddBalanceForm: FC<AddBalanceFormProps> = ({ onCancelClick }) => {
  const [serverError, setServerError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setFocus,
  } = useForm<FormProps>({ defaultValues: { amount: 0 } });

  const dispatch = useAppDispatch();
  const amount = watch('amount');

  const onSubmit = useCallback<SubmitHandler<FormProps>>(
    async (data) => {
      setLoading(true);
      setServerError(null);
      try {
        await dispatch(addBalance(data.amount));
      } catch (error) {
        setServerError(`Payment failed: ${error}`);
        setLoading(false);
        return;
      }

      await dispatch(getAllBilling());

      dispatch(pushGlobalMessage('Balance refilled', 'success'));
      setLoading(false);
      onCancelClick();
    },
    [dispatch, onCancelClick]
  );

  const submit = handleSubmit(onSubmit);

  useEffect(() => {
    setFocus('amount', { shouldSelect: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasError = !!errors.amount;
  const errorMessage =
    (errors.amount?.message?.length ?? 0) > 0
      ? errors.amount!.message
      : 'Please enter a valid, positive amount';

  return (
    <Form
      onSubmit={submit}
      footerActions={
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '1rem' }}>
          <Button onClick={onCancelClick} disabled={loading} variant="secondary">
            Cancel
          </Button>
          <Button type="submit" loading={loading}>
            Add balance
          </Button>
        </div>
      }
    >
      {serverError && <BannerNotification variant="error">{serverError}</BannerNotification>}
      <TextInputField
        label="Amount"
        invalid={hasError}
        validationMessage={hasError ? errorMessage : undefined}
        type="number"
        required
        {...register('amount', {
          required: 'Amount is required',
          valueAsNumber: true,
          min: 1,
          max: 1000000,
        })}
      />
      {amount > 0 && (
        <HelpText id="balance-add-help-text">
          You agree to charge your credit card the amount of <Money amount={amount} />
        </HelpText>
      )}
    </Form>
  );
};

export default AddBalanceForm;
