import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import AppSchemaModel from './AppSchemaModel';

export default class AppModel {
  id = 0;

  appId = 0;

  enabled = false;

  tags = [];

  publishesTo = [];

  userProperties = {};

  appSchema = new AppSchemaModel();

  isHydrated = false;

  nickname = null;

  pubData = { publishTo: [], type: 'none' };

  constructor(data: {
    id: number;
    csrappid: number;
    enabled: boolean;
    tags: any;
    userproperties: string;
    nickname: any;
  }) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.appId = data.csrappid;
    this.enabled = isBoolean(data.enabled) ? data.enabled : data.enabled === 1;
    this.tags = data.tags;
    this.userProperties = JSON.parse(data.userproperties);
    this.pubData = AppModel.buildPubData(this.userProperties);
    this.publishesTo = this.pubData.publishTo;
    this.isHydrated = true;
    this.nickname = data.nickname;
  }

  static buildPubData(userproperties) {
    let publishTo: any = [];
    let type = 'none';

    if (userproperties.url) {
      // webhook
      publishTo = userproperties.url;
      type = 'webhook';
    }

    if (userproperties.recipients) {
      // email
      publishTo = userproperties.recipients;
      type = 'email';
    }

    if (userproperties.to_number) {
      // sms
      publishTo = userproperties.to_number;
      type = 'phone';
    }

    if (userproperties.publish_to) {
      // heartbeat
      publishTo = isArray(userproperties.publish_to)
        ? userproperties.publish_to
        : [userproperties.publish_to];
      type = 'topic';
    }

    return {
      publishTo,
      type,
    };
  }
}
