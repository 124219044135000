import { useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectOrgId } from 'common-js/api/util';
import useApi from 'common-js/api/useApi';

/**
 * Gets a sample of the usage for a given profile on a device
 * @returns {function(*=): *} An async function which takes the linkId of a profile and returns the data sample for it
 */
const useGetDeviceProfileSessionSample = () => {
  const orgId = useAppSelector(selectOrgId);
  const apiCall = useApi.call('/usage/data?:searchParams');

  return useCallback(
    async (deviceId) => {
      const queryParams = new URLSearchParams({
        limit: 10,
        orgid: orgId,
        deviceid: deviceId,
      });
      const { data } = await apiCall({
        urlParams: { searchParams: queryParams.toString() },
      });
      return data;
    },
    [apiCall, orgId]
  );
};

export default useGetDeviceProfileSessionSample;
