import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { Icon as HolokitIcon, InputGroup } from '@holokit/core';
import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import { analyticsEventBuilder } from 'common-js/analytics/analytics';
import Dropdown from 'common-js/components/StatelessDropdown';
import * as Paths from 'common-js/constants/paths';
import useControlledInput from 'common-js/hooks/useControlledInput';
import { getTagsWithDeviceSelectionMetadata } from 'common-js/reducers/deviceFilter/selectors';
import { toggleTagSelectionForDevices } from 'common-js/reducers/devices/actions';
import { TOGGLE_TAG_SELECTION_FOR_DEVICES } from 'common-js/reducers/devices/actionTypes';
import { getSelectedDeviceIds } from 'common-js/reducers/devices/selectors';
import { selectPlanSelectionLimit } from 'common-js/reducers/releaseFlag/selectors';
import { addCommasToNumber } from 'common-js/utils/numberFormatter';

const SearchTagsInput = ({ value, onChange }) => {
  // clear value when dropdown is closed
  useEffect(() => () => onChange({ target: { value: '' } }), [onChange]);

  const onFocus = () => {
    analyticsEventBuilder.dataEntry('Tags', 'Search Clicked').send({ origin: 'Devices Table' });
  };

  return (
    <InputGroup
      inputProps={{
        maxLength: 32,
        onFocus,
      }}
      onChange={onChange}
      placeholder="Search for a tag"
      type="text"
      value={value}
    />
  );
};

const onManageTagsButtonClick = () => {
  analyticsEventBuilder.buttonClick('Tags', 'Manage Tags CTA').send({
    origin: 'Devices Table',
  });

  browserHistory.push(Paths.withContext(Paths.DEVICES_TAG_MANAGER));
};

const ManageTagsButton = ({ bulkTag }) =>
  bulkTag ? (
    <Button
      type="button"
      variant="secondary"
      className="DeviceActions__action__footer__button"
      onClick={onManageTagsButtonClick}
      iconStart={<Icon className="DeviceActions__action__footer__link__icon" name="Gear" />}
    >
      Manage tags
    </Button>
  ) : (
    <Link
      className="DeviceActions__action__footer__link"
      to={Paths.withContext(Paths.DEVICES_TAG_MANAGER)}
    >
      <HolokitIcon
        classes="DeviceActions__action__footer__link__icon"
        name="Gear"
        size="minor"
        svgProps={{ style: { fill: '#8008F7' } }}
      />
      <span>Manage tags</span>
    </Link>
  );

function UpdateTagDropdown({
  allDevicesSelected,
  tagLoading,
  tags,
  toggleTagSelectionForDevices_,
  totalSelected,
  pagesSelected,
  selectedDeviceIds,
  bulkTag,
  bulkTagDeviceLimit,
}) {
  const bulkTagDeviceLimitString = addCommasToNumber(bulkTagDeviceLimit, false);

  // we want to preserve the casing the user entered, but search in a case-insensitive manner
  const [tagsFilterRaw, setTagsFilter] = useControlledInput('');
  const tagsFilter = tagsFilterRaw.toLowerCase();
  const tagButtonDisabled = () =>
    bulkTag
      ? totalSelected === 0
      : pagesSelected.length > 1 ||
        selectedDeviceIds.length === 0 ||
        totalSelected > 250 ||
        allDevicesSelected;

  const onToggleDropdown = ({ isOpen }) => {
    if (isOpen && totalSelected > bulkTagDeviceLimit) {
      analyticsEventBuilder.errorReturn('Bulk Action', '10k Error', 'Tag').send({
        origin: 'Devices Table',
      });
    }
  };

  const onToggleTag = useCallback(
    ({ tag, checked }) => {
      analyticsEventBuilder.buttonClick('Tags', 'Select Checkbox').send({
        origin: 'Devices Table',
        checked: !checked, // We want to log the new state of the checkbox
      });

      try {
        toggleTagSelectionForDevices_(tag, selectedDeviceIds);
      } catch {
        analyticsEventBuilder.systemError('Tags', 'Selection Error').send({
          origin: 'Devices Table',
        });
      }
    },
    [selectedDeviceIds, toggleTagSelectionForDevices_]
  );

  const items = useMemo(() => {
    const returnValue = [
      {
        header: true,
        children: `${Intl.NumberFormat().format(totalSelected)} device${
          totalSelected !== 1 ? 's' : ''
        } selected`,
        caption: bulkTag ? undefined : 'Use tags to group and organize your devices.',
      },
    ];

    if (bulkTag) {
      returnValue.push({
        children: (
          <span className="UpdateTagDropdown__header-text">
            Available for {bulkTagDeviceLimitString} devices or less
          </span>
        ),
        header: true,
      });

      if (totalSelected <= bulkTagDeviceLimit) {
        returnValue.push({
          children: <SearchTagsInput value={tagsFilterRaw} onChange={setTagsFilter} />,
          header: false,
        });
      } else {
        returnValue.push({
          children: (
            <div className="UpdateTagDropdown__error-state">
              Select {bulkTagDeviceLimitString} devices or less in the dashboard to tag devices.
            </div>
          ),
          header: true,
        });

        return returnValue; // The dropdown only shows an error if too many devices are selected
      }
    }

    tags.forEach((tag) => {
      const isLoading = !!tagLoading?.[tag.id];
      const { allSelected, someSelected } = tag.deviceSelection;
      const checked = allSelected;
      const indeterminate = someSelected && !allSelected;

      if (!tag.name.toLowerCase().includes(tagsFilter)) {
        return;
      }

      returnValue.push({
        onClick: () => onToggleTag({ tag, checked, indeterminate }),
        hasCheckbox: true,
        checkboxProps: {
          checked,
          disabled: isLoading,
          indeterminate,
        },
        children: (
          <>
            {tag.name}
            {bulkTag && (
              <span className="UpdateTagDropdown__tag-count"> ({tag.deviceids.length})</span>
            )}
          </>
        ),
        isLoading,
      });
    });

    if (!tags.length) {
      returnValue.push({
        header: true,
        children: <div className="UpdateTagDropdown__empty-state">No tags created yet.</div>,
      });
    }

    return returnValue;
  }, [
    bulkTag,
    bulkTagDeviceLimit,
    bulkTagDeviceLimitString,
    onToggleTag,
    setTagsFilter,
    tags,
    tagsFilterRaw,
    tagLoading,
    tagsFilter,
    totalSelected,
  ]);

  const shouldShowFooter = !bulkTag || totalSelected <= bulkTagDeviceLimit;

  return (
    <Dropdown
      classes="DeviceActions__action UpdateTagDropdown"
      disabled={tagButtonDisabled()}
      dropdownText="Add or remove tags"
      footer={
        shouldShowFooter && (
          <div className="DeviceActions__action__footer">
            <ManageTagsButton bulkTag={bulkTag} />
          </div>
        )
      }
      iconLeft="Tag"
      items={items}
      onToggle={onToggleDropdown}
    />
  );
}

const mapStateToProps = (state) => ({
  tags: getTagsWithDeviceSelectionMetadata(state),
  tagLoading: state.devices.uiState.loading[TOGGLE_TAG_SELECTION_FOR_DEVICES],
  pagesSelected: state.devices.selection.pagesSelected,
  allDevicesSelected: state.devices.selection.allSelected,
  totalSelected: state.devices.selection.totalSelected,
  selectedDeviceIds: getSelectedDeviceIds(state),
  bulkTag: state.releaseFlag?.bulk_tag,
  bulkTagDeviceLimit: selectPlanSelectionLimit(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleTagSelectionForDevices_: toggleTagSelectionForDevices,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTagDropdown);
