import _ from 'lodash';
import { API_URL, headers, getHeaders } from '../config';
import { fetchHandler, fetchErrorHandler } from '../middleware';

export function getApps(userContextData) {
  return fetch(
    `${API_URL}/csr/rules?limit=2000&orgid=${
      userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
    }`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(fetchHandler((body) => Promise.resolve(body.data)))
    .catch(fetchErrorHandler);
}

export function getAppSchemas(userContextData) {
  return fetch(
    `${API_URL}/csr/apps?orgid=${
      userContextData.isInOrgContext ? userContextData.orgId : userContextData.userOrgId
    }`,
    {
      method: 'GET',
      credentials: 'include',
      headers: getHeaders,
    }
  )
    .then(
      fetchHandler((body) =>
        // Mock for adding a list input to the custom webhook app.

        // var webhook = _.find(body.data, {id: 3});
        // webhook.userpropertieslist.push({
        //   property: "headers",
        //   type: "list",
        //   name: "Headers",
        //   mandatory: true,
        //   description: "Header key/value pairs to be included in the POST request."
        // });

        Promise.resolve(body.data)
      )
    )
    .catch(fetchErrorHandler);
}

export function addApp(appChain, userContextData) {
  let responses;

  return Promise.all(
    appChain.map((app) =>
      fetch(
        `${API_URL}/csr/rules${userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''}`,
        {
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify(app),
        }
      ).catch((error) => error)
    )
  )
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then((parsedBodies) => {
      const successes = [];
      const failures = [];

      responses.forEach((response) => {
        if (response.ok) {
          successes.push({ error: false });
        } else {
          failures.push({ error: true });
        }
      });

      return Promise.resolve({
        appChain: parsedBodies.map((body) => body.data),
      });
    })
    .catch((error) => error);
}

export function editApp(appChain, userContextData) {
  let responses;
  const appsToUpdate = appChain.filter((app) => !!app.appId);
  const appsToAdd = appChain.filter((app) => !app.appId);
  const promises = [];

  promises
    .concat(
      appsToUpdate.map((appToUpdate) =>
        fetch(
          `${API_URL}/csr/rules/${appToUpdate.appId}${
            userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
          }`,
          {
            method: 'PUT',
            credentials: 'include',
            headers,
            body: JSON.stringify(appToUpdate),
          }
        ).catch((error) => error)
      )
    )
    .concat(
      appsToAdd.map((appToAdd) =>
        fetch(
          `${API_URL}/csr/rules${userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''}`,
          {
            method: 'POST',
            credentials: 'include',
            headers,
            body: JSON.stringify(appToAdd),
          }
        ).catch((error) => error)
      )
    );

  return Promise.all(promises)
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then((parsedBodies) => {
      const successes = [];
      const failures = [];

      responses.forEach((response) => {
        if (response.ok) {
          successes.push({ error: false });
        } else {
          failures.push({ error: true });
        }
      });
    });
}

export function deleteApp(appChainIds, userContextData) {
  let responses;

  return Promise.all(
    appChainIds.map((appId) =>
      fetch(
        `${API_URL}/csr/rules/${appId}${
          userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
        }`,
        {
          method: 'DELETE',
          credentials: 'include',
          headers,
        }
      ).catch((error) => error)
    )
  )
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then(() => {
      const successes = [];
      const failures = [];

      responses.forEach((response) => {
        if (response.ok) {
          successes.push({});
        } else {
          failures.push({ error: true });
        }
      });
    });
}

export function enableApp(appIds, userContextData) {
  let responses;

  return Promise.all(
    appIds.map((appId) =>
      fetch(
        `${API_URL}/csr/rules/${appId}/enable${
          userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
        }`,
        {
          method: 'POST',
          credentials: 'include',
          headers,
        }
      ).catch((error) => error)
    )
  )
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then(() => {
      const successes = [];
      const failures = [];

      responses.forEach((response) => {
        if (response.ok) {
          successes.push({});
        } else {
          failures.push({ error: true });
        }
      });
    });
}

export function disableApp(appIds, userContextData) {
  let responses;

  return Promise.all(
    appIds.map((appId) =>
      fetch(
        `${API_URL}/csr/rules/${appId}/disable${
          userContextData.orgId ? `?orgid=${userContextData.orgId}` : ''
        }`,
        {
          method: 'POST',
          credentials: 'include',
          headers,
        }
      ).catch((error) => error)
    )
  )
    .then((_responses) => {
      responses = _responses;
      return Promise.all(responses.map((r) => r.json()));
    })
    .then(() => {
      const successes = [];
      const failures = [];

      responses.forEach((response) => {
        if (response.ok) {
          successes.push({});
        } else {
          failures.push({ error: true });
        }
      });
    });
}
