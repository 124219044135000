import React from 'react';

function LoadMoreFooter(props) {
  const { continues, loaded, loadMore, total } = props;
  return continues ? (
    <div className="AlertsTable__load-more-footer">
      {loaded} of {total} events
      <span onClick={loadMore} onKeyUp={loadMore} role="button" tabIndex={0}>
        Load More
      </span>
    </div>
  ) : null;
}

export default LoadMoreFooter;
