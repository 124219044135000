import * as API from '../../../api';
import { getUserContextData } from '../../../api/util';
import { clearAllStores } from '../../../configureStore';
import * as ModalActions from '../../modal/actions';
import * as actionTypes from '../actionTypes';

export const setUserInformation = (userModel) => ({
  type: actionTypes.SET_USER_INFO,
  ...userModel,
});

export const setRoadblocksFromUserSettings = () => (dispatch, getState) => {
  const snapshot = getState();
  const userSettings = snapshot.account.settings;

  if (userSettings['Accepted TOS EULA'] === 'no') {
    dispatch(ModalActions.pushToQueue('AcceptTermsModal', null, 'AcceptTermsModal'));
  }
};

export const logout =
  (withoutApiCall = false) =>
  (dispatch) =>
    new Promise((resolve) => {
      if (withoutApiCall) {
        clearAllStores();
        dispatch({ type: actionTypes.LOGOUT_REQUEST });
        dispatch({ type: actionTypes.LOGOUT_SUCCESS });
        setTimeout(() => resolve(), 50); // requires timeout before moving on to allow the cookies to set properly.
      }

      resolve(
        API.logout()
          .then((response) => {
            if (!response?.success) {
              throw new Error('Logout failed');
            }
            clearAllStores();

            dispatch({ type: actionTypes.LOGOUT_REQUEST });
            dispatch({ type: actionTypes.LOGOUT_SUCCESS });

            resolve();
          })
          .catch((error) => {
            dispatch({
              type: actionTypes.LOGOUT_ERROR,
              error,
            });

            resolve();
          })
      );
    });

export const unmountOrders = () => ({
  type: actionTypes.UNMOUNT_ORDERS,
});

export const getOrders = () => (dispatch, state) => {
  const userContext = getUserContextData(state);

  dispatch({
    type: actionTypes.GET_ORDERS_REQUEST,
  });

  return API.getOrders(userContext.isInOrgContext ? userContext.orgId : userContext.userOrgId)
    .then((data) => {
      dispatch({
        type: actionTypes.GET_ORDERS_SUCCESS,
        orders: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_ORDERS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const deleteAccount = () => ({
  type: actionTypes.DELETE_ACCOUNT,
});

export const getApiKey = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_APIKEY_REQUEST,
  });

  return API.getApiKey()
    .then((data) => {
      dispatch({
        type: actionTypes.GET_APIKEY_SUCCESS,
        apiKey: data.apikey,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_APIKEY_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const generateApiKey = () => (dispatch) => {
  dispatch({
    type: actionTypes.GENERATE_KEY_REQUEST,
  });

  return API.generateApiKey()
    .then((data) => {
      dispatch({
        type: actionTypes.GENERATE_KEY_SUCCESS,
        apiKey: data.apikey,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GENERATE_KEY_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getPlans = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_PLANS_REQUEST,
  });

  return API.getPlans(1, null, 1000, getUserContextData(state)) // hardcoded to partnerid 1 (hologram)
    .then((data) => {
      dispatch({
        type: actionTypes.GET_PLANS_SUCCESS,
        plans: data,
      });

      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_PLANS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const addBalance = (amount) => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.ADD_BALANCE_REQUEST,
    amount,
  });

  return API.addBalance(amount, userContextData)
    .then((balance) => {
      dispatch({
        type: actionTypes.ADD_BALANCE_SUCCESS,
        balance,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.ADD_BALANCE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const updateBalanceParams = (amountToAdd, threshold) => (dispatch, state) => {
  const userContextData = getUserContextData(state);

  dispatch({
    type: actionTypes.UPDATE_BALANCE_PARAMS_REQUEST,
  });

  return API.updateBalanceParams(amountToAdd, threshold, userContextData)
    .then(() => {
      dispatch({
        type: actionTypes.UPDATE_BALANCE_PARAMS_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_BALANCE_PARAMS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const verifyAccountStatus = (userId) => (dispatch) => {
  dispatch({ type: actionTypes.VERIFY_ACCOUNT_STATUS_REQUEST });

  return API.verifyAccountStatus(userId)
    .then((result) => {
      dispatch({ type: actionTypes.VERIFY_ACCOUNT_STATUS_SUCCESS });
      return Promise.resolve(result);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.VERIFY_ACCOUNT_STATUS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getPendingOrgs = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.GET_PENDING_ORGS_REQUEST,
  });

  return API.getPendingOrgs(getUserContextData(state))
    .then((pendingOrgs) => {
      dispatch({
        type: actionTypes.GET_PENDING_ORGS_SUCCESS,
        pendingOrgs,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_PENDING_ORGS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const acceptInvitation = (inviteId, orgId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.ACCEPT_INVITE_REQUEST,
  });

  return API.acceptInvitation(inviteId)
    .then(() => {
      const { userEmail, userId } = getUserContextData(state);
      dispatch({
        type: actionTypes.ACCEPT_INVITE_SUCCESS,
        orgId,
        userId,
        userEmail,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.ACCEPT_INVITE_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const resendEmailVerification = (userId) => (dispatch) => {
  dispatch({
    type: actionTypes.RESEND_EMAIL_VERIFY_REQUEST,
  });

  return API.resendEmailVerification(userId)
    .then(() => {
      // No reducer is catching this action
      dispatch({
        type: actionTypes.RESEND_EMAIL_VERIFY_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.RESEND_EMAIL_VERIFY_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const getSessionInfo = () => (dispatch) =>
  API.getUserInfo().then((result) => {
    dispatch({
      type: actionTypes.SET_USER_INFO,
      ...result.userInfo,
    });

    return Promise.resolve(result);
  });

export const updateUserInformation = (firstName, lastName, email) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_USER_INFO_REQUEST,
  });

  return API.updateUserInformation(firstName, lastName, email)
    .then((data) => {
      dispatch({
        type: actionTypes.UPDATE_USER_INFO_SUCCESS,
      });
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_USER_INFO_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const changePassword = (newPassword) => (dispatch, state) => {
  dispatch({
    type: actionTypes.CHANGE_PASSWORD_REQUEST,
  });

  return API.changePassword(newPassword, getUserContextData(state))
    .then(() => {
      dispatch({
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CHANGE_PASSWORD_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const applyPromoCode = (code) => (dispatch, state) => {
  dispatch({
    type: actionTypes.APPLY_PROMO_REQUEST,
    code,
  });

  return API.applyPromoCode(code, getUserContextData(state))
    .then(() => {
      dispatch({
        type: actionTypes.APPLY_PROMO_SUCCESS,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.APPLY_PROMO_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const updateBillingAddress = (updatedAddress) => (dispatch, state) => {
  dispatch({ type: actionTypes.UPDATE_BILLING_ADDRESS_REQUEST });

  return API.updateBillingAddress(updatedAddress, getUserContextData(state))
    .then(() => {
      dispatch({ type: actionTypes.UPDATE_BILLING_ADDRESS_SUCCESS });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_BILLING_ADDRESS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const updateInvoiceInfo = (updatedInfo) => (dispatch, state) => {
  dispatch({ type: actionTypes.UPDATE_INVOICE_INFO_REQUEST });

  return API.updateInvoiceInfo(updatedInfo, getUserContextData(state))
    .then(() => {
      dispatch({ type: actionTypes.UPDATE_INVOICE_INFO_SUCCESS });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_INVOICE_INFO_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const removeInvoiceInfo = () => (dispatch, state) => {
  dispatch({ type: actionTypes.REMOVE_INVOICE_INFO_REQUEST });

  return API.updateInvoiceInfo('', getUserContextData(state))
    .then(() => {
      dispatch({ type: actionTypes.REMOVE_INVOICE_INFO_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REMOVE_INVOICE_INFO_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const removeCard = () => (dispatch, state) => {
  dispatch({
    type: actionTypes.REMOVE_CARD_REQUEST,
  });

  return API.removeCreditCard(getUserContextData(state))
    .then(() => {
      dispatch({
        type: actionTypes.REMOVE_CARD_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REMOVE_CARD_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const updateUserSettings = (settings) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_SETTINGS_REQUEST,
    settings,
  });

  return API.updateUserSettings(settings)
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_SETTINGS_SUCCESS,
        settings: response.data,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_SETTINGS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export const clearLoginLoader = () => ({
  type: actionTypes.LOGIN_CLEAR_LOADER,
});

export const setSeenFleetHome = (hasBeenSeen) => ({
  type: actionTypes.SET_SEEN_FLEET_HOME,
  hasBeenSeen,
});

export const setDismissedAlerts = (dismissedAlerts) => ({
  type: actionTypes.SET_DISMISSED_ALERTS,
  dismissedAlerts,
});

export const loginRequest = () => ({
  type: actionTypes.LOGIN_REQUEST,
});

export const initMfaFlow = (mfaMethod) => ({
  type: actionTypes.INIT_MFA_FLOW,
  method: mfaMethod,
});

export const finishMfaFlow = () => ({
  type: actionTypes.FINISH_MFA_FLOW,
});

export const handleMfaError = (error) => ({
  type: actionTypes.HANDLE_MFA_ERROR,
  error,
});

export const startMfaAuthentication = () => ({
  type: actionTypes.START_MFA_AUTHENTICATION,
});

export const clearMfaErrors = () => ({
  type: actionTypes.CLEAR_MFA_ERROR,
});

export const clearMfa = () => ({
  type: actionTypes.CLEAR_MFA,
});
