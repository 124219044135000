import Moment from 'moment-timezone';

import { getUserContextData } from '../../api/util';
import generateCSV from '../../utils/generateCSV';
import { VIEWMORE_ROWS } from '../usage/reducer';
import { getReportDateTitleString } from '../usage/selectors';
import * as actionTypes from './actionTypes';
import { buildChartFilters } from './selectors';
import * as API from '../../api';

export function registerDeviceId(deviceId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REGISTER_DEVICE, deviceId });
    return Promise.resolve();
  };
}

export function getDevice(deviceId) {
  return (dispatch, state) => {
    const isPollingPaused = state().singleDevice.softPausePolling;

    if (!isPollingPaused) {
      dispatch({ type: actionTypes.GET_DEVICE_REQUEST, deviceId });
      return API.getDevice(deviceId, getUserContextData(state))
        .then((data) => {
          dispatch({
            type: actionTypes.GET_DEVICE_SUCCESS,
            device: data,
            deviceId,
          });

          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: actionTypes.GET_DEVICE_ERROR,
            error,
            deviceId,
          });

          return Promise.reject(error);
        });
    }

    return undefined;
  };
}

export const softPausePolling = (pause) => ({
  type: actionTypes.SOFT_PAUSE_POLLING,
  pause,
});

export function getSessionSample(linkId, deviceId) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_SESSIONSAMPLE_REQUEST, deviceId });

    return API.getUsage(getUserContextData(state), 10, [{ linkId, id: deviceId }])
      .then((data) => {
        dispatch({
          type: actionTypes.GET_SESSIONSAMPLE_SUCCESS,
          sessionSample: data,
          deviceId,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_SESSIONSAMPLE_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function getDeviceEUICCHistory(deviceId) {
  return (dispatch, state) => {
    if (!state().releaseFlag.euicc) return Promise.resolve();

    dispatch({ type: actionTypes.GET_DEVICEEUICCHISTORY_REQUEST, deviceId });

    return API.getDeviceEUICCHistory(deviceId)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DEVICEEUICCHISTORY_SUCCESS,
          euiccHistory: data,
          deviceId,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DEVICEEUICCHISTORY_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function getDeviceEUICCTestHistory(deviceId) {
  return (dispatch, state) => {
    if (!state().releaseFlag.euicc) return Promise.resolve();

    dispatch({
      type: actionTypes.GET_DEVICEEUICCTESTHISTORY_REQUEST,
      deviceId,
    });

    return API.getDeviceEUICCTestHistory(deviceId)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DEVICEEUICCTESTHISTORY_SUCCESS,
          euiccTestHistory: data,
          deviceId,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DEVICEEUICCTESTHISTORY_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function getProfiles(deviceId) {
  return (dispatch, state) => {
    if (!state().releaseFlag.euicc) return Promise.resolve();

    dispatch({ type: actionTypes.GET_DEVICEPROFILES_REQUEST, deviceId });

    return API.getProfiles(deviceId)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DEVICEPROFILES_SUCCESS,
          profiles: data,
          deviceId,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DEVICEPROFILES_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function getLiveUsage(deviceId) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_LIVEUSAGE_REQUEST, deviceId });

    return API.getLiveUsage(getUserContextData(state), { deviceid: deviceId }, 100)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_LIVEUSAGE_SUCCESS,
          liveUsage: data,
          deviceId,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_LIVEUSAGE_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function sendCustomFormAction(customAction) {
  return customAction;
}

export function getPhoneNumberCost(deviceId, country) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.GET_PHONE_NUMBER_COST_REQUEST,
      deviceId,
    });

    return API.getPhoneNumberCost(deviceId, country, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_PHONE_NUMBER_COST_SUCCESS,
          pricingData: data,
          deviceId,
        });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_PHONE_NUMBER_COST_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function addPhoneNumber(deviceId, areaCode, country) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.ADD_PHONE_NUMBER_REQUEST,
      deviceId,
    });

    return API.addPhoneNumber(deviceId, areaCode, country, getUserContextData(state))
      .then((data) => {
        dispatch({
          type: actionTypes.ADD_PHONE_NUMBER_SUCCESS,
          phonenumber: data,
          deviceId,
        });

        return getDevice(deviceId)(dispatch, state);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_PHONE_NUMBER_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function setReportDateTitle(deviceId) {
  return (dispatch, state) => {
    const snapshot = state();
    const { endDate, startDate, timeQuickFilter } = snapshot.singleDevice[deviceId].timeFilter;

    dispatch({
      type: actionTypes.SET_REPORT_DATE_TITLE,
      reportDateTitle: getReportDateTitleString(startDate, endDate, timeQuickFilter),
      deviceId,
    });
  };
}

export function getDataUsed(deviceId) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_DATAUSED_REQUEST, deviceId });

    return API.getDataUsed(getUserContextData(state), buildChartFilters(state, deviceId))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_SUCCESS,
          data,
          deviceId,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function getDataUsedInspect(deviceId) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_DATAUSED_INSPECT_REQUEST, deviceId });

    return API.getDataUsedInspect(getUserContextData(state), buildChartFilters(state, deviceId))
      .then((data) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_INSPECT_SUCCESS,
          data,
          deviceId,
        });

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DATAUSED_INSPECT_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function getDeviceBreakdown(deviceId) {
  return (dispatch, state) => {
    dispatch({ type: actionTypes.GET_DEVICEBREAKDOWN_REQUEST, deviceId });

    return API.getDeviceBreakdown(
      getUserContextData(state),
      buildChartFilters(state, deviceId),
      VIEWMORE_ROWS
    )
      .then((result) => {
        dispatch({
          type: actionTypes.GET_DEVICEBREAKDOWN_SUCCESS,
          data: result.data,
          continues: result.continues,
          deviceId,
        });

        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DEVICEBREAKDOWN_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function runReport(deviceId) {
  return (dispatch, state) => {
    setReportDateTitle(deviceId)(dispatch, state);

    dispatch({ type: actionTypes.RUN_REPORT_START, deviceId });

    Promise.all([
      getDataUsed(deviceId)(dispatch, state),
      getDeviceBreakdown(deviceId)(dispatch, state),
    ])
      .then(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH, deviceId });
      })
      .catch(() => {
        dispatch({ type: actionTypes.RUN_REPORT_FINISH, deviceId });
      });
  };
}

export function runInspectReport(deviceId) {
  return (dispatch, state) => {
    setReportDateTitle(deviceId)(dispatch, state);

    dispatch({ type: actionTypes.RUN_INSPECT_REPORT_START, deviceId });

    Promise.all([
      getDataUsed(deviceId)(dispatch, state),
      getDataUsedInspect(deviceId)(dispatch, state),
    ])
      .then(() => {
        dispatch({ type: actionTypes.RUN_INSPECT_REPORT_FINISH, deviceId });
      })
      .catch(() => {
        dispatch({ type: actionTypes.RUN_INSPECT_REPORT_FINISH, deviceId });
      });
  };
}

export function setQuickFilter(quickFilter, deviceId) {
  return {
    type: actionTypes.SET_QUICK_FILTER,
    quickFilter,
    deviceId,
  };
}

export const setCustomDate = (filters, deviceId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CUSTOM_DATE,
    filters,
    deviceId,
  });
  return Promise.resolve();
};

export function exportDataUsed(deviceId) {
  return (dispatch, state) => {
    // Load all the data again for exporting.

    dispatch({ type: actionTypes.EXPORT_DATAUSED_REQUEST, deviceId });

    const MAX_BATCHES = 3;
    let currentBatch = 0;
    let loadedData = [];

    const getBatch = () =>
      API.getDataUsed(getUserContextData(state), buildChartFilters(state, deviceId))
        .then((result) => {
          dispatch({
            type: actionTypes.EXPORT_DATAUSED_BATCH_SUCCESS,
            deviceId,
          });
          currentBatch += 1;
          loadedData = loadedData.concat(result);

          if (result.continues && currentBatch < MAX_BATCHES) getBatch();
          else if (result.continues && currentBatch > MAX_BATCHES) {
            dispatch({ type: actionTypes.EXPORT_DATAUSED_TOOLARGE, deviceId });
          } else {
            dispatch({ type: actionTypes.EXPORT_DATAUSED_SUCCESS, deviceId });

            const headers = ['dateofusage', 'numdevices', 'datausage'];

            const rows = loadedData.map((data) => [
              data.dateofusage,
              data.numDevices,
              data.datausage,
            ]);

            generateCSV([headers].concat(rows), `dataused ${Moment().format('YYYY-MM-DD')}.csv`);
          }
        })
        .catch((e) => {
          dispatch({
            type: actionTypes.EXPORT_DATAUSED_ERROR,
            error: e,
            deviceId,
          });
        });

    getBatch();
  };
}

export function exportDataUsedInspect(deviceId) {
  return (dispatch, state) => {
    // Load all the data again for exporting.

    dispatch({ type: actionTypes.EXPORT_DATAUSEDINSPECT_REQUEST, deviceId });

    const MAX_BATCHES = 3;
    let currentBatch = 0;
    let loadedData = [];

    const getBatch = () =>
      API.getDataUsedInspect(getUserContextData(state), buildChartFilters(state, deviceId))
        .then((result) => {
          dispatch({
            type: actionTypes.EXPORT_DATAUSEDINSPECT_BATCH_SUCCESS,
            deviceId,
          });
          currentBatch += 1;
          loadedData = loadedData.concat(result);

          if (result.continues && currentBatch < MAX_BATCHES) getBatch();
          else if (result.continues && currentBatch > MAX_BATCHES) {
            dispatch({
              type: actionTypes.EXPORT_DATAUSEDINSPECT_TOOLARGE,
              deviceId,
            });
          } else {
            dispatch({
              type: actionTypes.EXPORT_DATAUSEDINSPECT_SUCCESS,
              deviceId,
            });

            const headers = [
              'date',
              'avgdailyusage',
              'sessions',
              'sms',
              'total_bytes',
              'total_devices',
            ];

            const rows = loadedData.map((data) => [
              data.date,
              ((data.total_bytes * 1) / (data.sessions * 1)).toFixed(2), // cast to strings to avoid toFixed errors.
              data.sessions,
              data.sms,
              data.total_bytes,
              data.total_devices,
            ]);

            generateCSV(
              [headers].concat(rows),
              `datausedinspect ${Moment().format('YYYY-MM-DD')}.csv`
            );
          }
        })
        .catch((e) => {
          dispatch({
            type: actionTypes.EXPORT_DATAUSEDINSPECT_ERROR,
            error: e,
            deviceId,
          });
        });

    getBatch();
  };
}

export function requestHyperTest(deviceId) {
  return (dispatch, state) =>
    API.requestHyperTest(deviceId, getUserContextData(state))
      .then(() => getDevice(deviceId)(dispatch, state))
      .catch((error) => Promise.reject(error));
}

export function testDevice(deviceId, cycles, carrierId) {
  return (dispatch, state) => {
    dispatch({
      type: actionTypes.TEST_DEVICE_REQUEST,
      deviceId,
    });

    return API.testDevice(deviceId, cycles, carrierId)
      .then((newDeviceState) => {
        dispatch({
          type: actionTypes.TEST_DEVICE_SUCCESS,
          deviceId,
          newDeviceState,
        });

        return getDevice(deviceId)(dispatch, state);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.TEST_DEVICE_ERROR,
          error,
          deviceId,
        });

        return Promise.reject(error);
      });
  };
}

export function viewDeviceConnectionInfo(deviceId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.VIEW_CONNECTION_INFO,
      deviceId,
    });
  };
}

export const viewMoreLiveUsage = (deviceId) => ({
  type: actionTypes.VIEWMORE_LIVEUSAGE,
  deviceId,
});

let pollingIntervalId;

export function startDevicePolling(deviceId) {
  return (dispatch, store) => {
    dispatch({
      type: actionTypes.START_DEVICE_POLLING,
      deviceId,
    });

    // clear already running interval
    if (pollingIntervalId) {
      window.clearInterval(pollingIntervalId);
    }

    pollingIntervalId = window.setInterval(() => {
      getDevice(deviceId)(dispatch, store);
    }, 10000);
  };
}

export function stopDevicePolling(deviceId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.STOP_DEVICE_POLLING,
      deviceId,
    });

    window.clearInterval(pollingIntervalId);
  };
}
