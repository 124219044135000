import { Button, ComplexIcon } from '@holokit/core';
import { openSearchModal } from 'common-js/reducers/search/actions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ExportDevices from './ExportDevices';

function PageLimit({ openSearchModal_ }) {
  return (
    <div className="DevicesPageLimit">
      <div className="DevicesPageLimit__content">
        <ComplexIcon classes="DevicesPageLimit__icon" name="sims-circle" />
        <h2 className="DevicesPageLimit__headline">
          Phew, that was 10,000 devices!
          <br />
          Here are some tips to help you find what you need.
        </h2>
        <ul className="DevicesPageLimit__list">
          <li className="DevicesPageLimit__list__item">
            <div className="DevicesPageLimit__list-number">1</div>
            <div>Search for a device by it’s name, IMEI, device ID and more</div>
          </li>
          <li className="DevicesPageLimit__list__item">
            <div className="DevicesPageLimit__list-number">2</div>
            <div>Try utilizing tagging to help organize and filter devices</div>
          </li>
          <li className="DevicesPageLimit__list__item">
            <div className="DevicesPageLimit__list-number">3</div>
            <div>Download your device list via CSV Export</div>
          </li>
        </ul>
        <div className="DevicesPageLimit__buttons">
          <Button
            classes="DevicesPageLimit__buttons__button"
            iconLeft="Search"
            onClick={openSearchModal_}
            small
            type="secondary"
          >
            Search Now
          </Button>
          <ExportDevices hasTooltip={false} />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSearchModal_: openSearchModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PageLimit);
