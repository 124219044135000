import React from 'react';
import SimsGrid from './SimsGrid';

function SimsDisplay({ sims, text, onDelete, variant = 'activated' }) {
  const numSims = sims?.length ?? 0;

  return (
    <div className="SimsDisplay">
      {numSims === 0 && (
        <div className="SimsDisplay--empty-state">No SIM(s) added for activation.</div>
      )}
      {numSims > 0 && (
        <SimsGrid numSims={numSims} sims={sims} text={text} onDelete={onDelete} variant={variant} />
      )}
    </div>
  );
}

export default SimsDisplay;
