import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion } from '@holokit/core';
import { throttle } from 'lodash';
import {
  clearSelection,
  removeFilter,
  setFilter,
  setFilterInputValue,
} from 'common-js/reducers/devices/actions';
import { getFilters, getLoadedDevices } from 'common-js/reducers/devices/selectors';
import { LIKE } from 'common-js/constants/filterComparators';
import * as analyticsTypes from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';

class DeviceNameFilter extends Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.handleSearchDebounced = throttle(this.handleSearch, 1000, {
      leading: false,
    });
  }

  componentWillUnmount() {
    this.handleSearchDebounced.cancel();
  }

  handleInputChange = (e) => {
    const { clearSelection_, setFilterInputValue_ } = this.props;
    const { target } = e;

    clearSelection_();

    setFilterInputValue_('deviceName', target.value).then(() => {
      this.handleSearchDebounced();
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleSearch();
  };

  handleSearch = () => {
    const { filters, inputValue, removeFilter_, setFilter_ } = this.props;
    const nameFilter = 'name' in filters ? Object.keys(filters.name)[0] : '';
    let filterValue;

    if (nameFilter !== inputValue) {
      if (inputValue === '') {
        removeFilter_('name');
        filterValue = '';
      } else {
        setFilter_('name', inputValue, LIKE);
        filterValue = inputValue;
      }
    }
    sendAnalyticsEvent({
      type: analyticsTypes.FILTERS_SET_FILTER,
      data: {
        filter_name: 'Device name filter',
        filter_value: filterValue,
      },
    });
  };

  render() {
    const { devices, filters, inputValue } = this.props;

    return (
      <div className="DevicesFilter">
        <Accordion
          header="Device name"
          iconLeft="Aa"
          isFiltered={'name' in filters}
          accordionToggleCallback={({ open }) => {
            sendAnalyticsEvent({
              type: analyticsTypes.FILTERS_TOGGLE_ACCORDION,
              data: {
                accordion_name: 'Device name filter',
                accordion_open: open,
              },
            });
          }}
        >
          <form className="DevicesFilter__form" onSubmit={this.handleSubmit}>
            <input
              className="DevicesFilter__form__field TextInput TextInput--full-width"
              onChange={this.handleInputChange}
              placeholder={devices[0] ? devices[0].name : ''}
              ref={this.input}
              type="text"
              value={inputValue}
            />
          </form>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: getLoadedDevices(state),
  filters: getFilters(state),
  inputValue: state.devices.uiState.filterInputs.deviceName,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelection_: clearSelection,
      removeFilter_: removeFilter,
      setFilter_: setFilter,
      setFilterInputValue_: setFilterInputValue,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNameFilter);
