import { createSelector } from 'reselect';
import { preflightStates } from 'common-js/constants/deviceStates';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';

const selectCanUserActivatePreflightDevice = createSelector(
  getUserPermissions,
  selectCurrentDevice,
  (userPermissions, device) =>
    preflightStates.includes(device.state) && userPermissions.includes('activation')
);

export default selectCanUserActivatePreflightDevice;
