import { GET_SETTINGS_REQUEST, GET_SETTINGS_SUCCESS, GET_SETTINGS_ERROR } from '../actionTypes';
import { getUserSettings as getUserSettingsApiCall } from '../../../api/account';

const getUserSettings = () => (dispatch) => {
  dispatch({
    type: GET_SETTINGS_REQUEST,
  });

  return getUserSettingsApiCall()
    .then((result) => {
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        settings: result.data,
      });
      return Promise.resolve(result.data);
    })
    .catch((error) => {
      dispatch({
        type: GET_SETTINGS_ERROR,
        error,
      });
      return Promise.reject(error);
    });
};

export default getUserSettings;
