import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { getLogs, getLogsForOne } from '../log/actions';
import {
  DEVICE_RESET,
  DEVICE_SET_DATERANGE,
  DEVICE_SET_QUERY,
  DEVICE_SET_VIEW_ALL,
  SEARCH_ENTERED,
  SET_DEVICE_FILTER,
  SET_LOG_CATEGORY,
} from './actionTypes';

const updateLogsInternal = (
  deviceId,
  dispatch,
  _getStore,
  sendAnalytics = false,
  searchQuery = ''
) => {
  if (sendAnalytics) {
    dispatch({
      type: SEARCH_ENTERED,
      searchQuery,
    });
  }

  if (deviceId === 'allLogs') {
    bindActionCreators({ getLogs }, dispatch).getLogs();
  } else {
    bindActionCreators({ getLogsForOne }, dispatch).getLogsForOne(parseInt(deviceId, 10));
  }
};

const debouncedUpdateLogs = debounce(updateLogsInternal, 1200);

export function viewDateRange(deviceId, startDate, endDate) {
  return (dispatch, getStore) => {
    dispatch({
      type: DEVICE_SET_DATERANGE,
      deviceId,
      startDate,
      endDate,
      isViewingAllLogs: false,
    });

    updateLogsInternal('allLogs', dispatch, getStore);
  };
}

export function setViewingAllLogs(deviceId, isViewingAllLogs) {
  return (dispatch, getStore) => {
    dispatch({
      type: DEVICE_SET_VIEW_ALL,
      deviceId,
      isViewingAllLogs,
    });

    updateLogsInternal(deviceId, dispatch, getStore);
  };
}

export function setDeviceFilter(deviceId) {
  return (dispatch, getStore) => {
    dispatch({
      type: SET_DEVICE_FILTER,
      deviceId,
    });

    updateLogsInternal('allLogs', dispatch, getStore);
  };
}

export function setLogCategory(logCategory) {
  return (dispatch, getStore) => {
    dispatch({
      type: SET_LOG_CATEGORY,
      logCategory,
    });

    updateLogsInternal('allLogs', dispatch, getStore);
  };
}

export function clearAll(deviceId) {
  return (dispatch, getStore) => {
    dispatch({
      type: DEVICE_RESET,
      deviceId,
      isViewingAllLogs: true,
    });

    updateLogsInternal(deviceId, dispatch, getStore);
  };
}

export function setSearchQuery(deviceId, searchQuery, isDelayed = true) {
  return (dispatch, getStore) => {
    dispatch({
      type: DEVICE_SET_QUERY,
      deviceId,
      searchQuery,
    });

    if (isDelayed) {
      debouncedUpdateLogs(deviceId, dispatch, getStore, true, searchQuery);
    } else {
      updateLogsInternal(deviceId, dispatch, getStore, true, searchQuery);
    }
  };
}
