import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const toggleFilters =
  (filterKey, filterValues, filterComparator = 'EQ') =>
  (dispatch) => {
    filterValues.forEach((filterValue) => {
      dispatch({
        type: actionTypes.TOGGLE_FILTER,
        filterKey,
        filterValue,
        filterComparator,
      });
    });

    dispatch({
      type: actionTypes.RESET_PAGING,
    });

    dispatch(fetchDevices());
  };
