import useAppSelector from 'common-js/hooks/useAppSelector';
import { selectHasActivityHistory } from 'common-js/reducers/releaseFlag/selectors';
import TaskPopover from 'devices/components/TaskPopover';
import React from 'react';
import SearchButton from 'search/components/SearchButton';
import { OrgChange } from '..';

const SubMenuRightContent: React.FC = () => {
  // If the user doesn't have the activity history feature, they don't have bulk tasks either, so we hide task popover
  const hasActivityHistory = useAppSelector(selectHasActivityHistory);

  return (
    <div className="grid-row v-align">
      <SearchButton />
      {hasActivityHistory && <TaskPopover />}
      <OrgChange />
    </div>
  );
};

export default SubMenuRightContent;
