import React, { useEffect } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { Button, Panel } from '@holokit/core';
import analyticsEventBuilder from 'common-js/analytics';
import { BMPContactUsButton, BMPUpsellPanel, HoloHelmet } from 'common-js/components';
import { LEARN_DEVICE_TUNNELING, DL_SPACEBRIDGE_CLIENT } from 'common-js/constants/links';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import {
  selectBmpDeviceTunnelingFeatureLevel,
  selectHasTunneling,
} from 'common-js/reducers/releaseFlag/selectors';

import EnableSpaceBridgeButton from './EnableSpaceBridgeButton';

const Spacebridge = () => {
  const device = useAppSelector(selectCurrentDevice);
  const bmpLevel = useAppSelector(selectBmpDeviceTunnelingFeatureLevel);
  const hasTunneling = useAppSelector(selectHasTunneling);

  useEffect(() => {
    analyticsEventBuilder.pageView().page('Single Device', 'Spacebridge').send();
  }, []);

  if (!hasTunneling) {
    return (
      <BMPUpsellPanel
        title="Device tunneling"
        label="Pro feature"
        headline="Send data to devices with secure, authenticated tunnels"
        copy="To unlock device tunneling, upgrade your Hologram plan."
        buttons={
          <BMPContactUsButton
            variant="primary"
            flagLevel={bmpLevel}
            analyticsName="SDP Device Tunneling"
          />
        }
      />
    );
  }

  return (
    <div className="page-device-spacebridge full-height">
      <HoloHelmet title={`${device.name} - Spacebridge`} />
      <Panel title="Device tunneling">
        <div className="spacebridge">
          <div className="spacebridge-status">
            Device tunneling is currently <span>{device.tunnelable ? 'enabled' : 'disabled'}</span>
          </div>
          <EnableSpaceBridgeButton device={device} />
        </div>
      </Panel>
      <Panel classes="spacebridge-infopanel" title="How to use device tunneling" noBodyPadding>
        <div className="Panel__info">
          Create secure, authenticated tunnels to send data to connected devices via SSH.
        </div>
        <div className="Panel__padding">
          <div className="infopanel-section">
            <p>
              Download the latest version of the Spacebridge device tunneling client for your
              computer
            </p>
            <Button href={DL_SPACEBRIDGE_CLIENT} type="secondary">
              Download client
            </Button>
          </div>
          <div className="infopanel-section">
            <a target="_blank" rel="noreferrer" href={LEARN_DEVICE_TUNNELING}>
              Read our device tunneling guide
            </a>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default Spacebridge;
