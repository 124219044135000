import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import useForgotPassword from 'common-js/api/account/useForgotPassword';
import FormPanel from 'common-js/components/FormPanel';
import HoloHelmet from 'common-js/components/HoloHelmet';
import {
  confirmationMessagePrefix,
  pageTitles,
  serverErrorPrefix,
  submitLabel,
  validationMessages,
} from 'common-js/constants/forgotPasswordFormStrings';
import { ACCOUNT_REGISTER } from 'common-js/constants/paths';
import { useEffect, useState, type FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import isValidEmailValidator from 'validator/lib/isEmail';

interface ForgotPasswordEmailFormProps {
  email: string;
}

const ForgotPasswordForm: FC = () => {
  /* API call */
  const forgotPasswordApiCall = useForgotPassword();

  /* React Hook Form methods */
  const {
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    register,
    setError,
    setFocus,
  } = useForm<ForgotPasswordEmailFormProps>();

  /* Autofocus email field onload */
  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  /* Form state */
  const [userEmail, setUserEmail] = useState('');
  const [submitError, setSubmitError] = useState('');

  /* Form submit handler */
  const onSubmit: SubmitHandler<ForgotPasswordEmailFormProps> = async (data) => {
    try {
      setSubmitError(''); // Clear a previous submit (server) error before attempting the submission again
      await forgotPasswordApiCall({ email: data.email });
      setUserEmail(data.email); // Used as part of the confirmation message
    } catch (errorMessage) {
      if (errorMessage === 'Invalid email address') {
        // If the server returns an error due to an Invalid email address, show a field level error using React Hook Form's setError method
        setError('email', {
          type: 'custom',
          message: validationMessages.invalidEmail,
        });
      } else {
        setSubmitError(`${serverErrorPrefix} ${errorMessage}`);
      }
    }
  };

  /* UI */
  const showConfirmation = !submitError && isSubmitSuccessful;
  return (
    <>
      <HoloHelmet title={showConfirmation ? pageTitles.confirmation : pageTitles.default} />
      <FormPanel
        title={showConfirmation ? pageTitles.confirmation : pageTitles.default}
        message={showConfirmation ? `${confirmationMessagePrefix} ${userEmail}` : submitError}
        messageType={showConfirmation ? 'success' : 'error'}
        footer={
          showConfirmation ? undefined : (
            <p className="login--footer">
              Don&apos;t have an account yet?{' '}
              <Button to={ACCOUNT_REGISTER} variant="tertiary">
                Create one
              </Button>
            </p>
          )
        }
      >
        {showConfirmation ? (
          <p aria-live="polite">
            Use the link in your inbox to reset the password. If you need additional help,
            email&nbsp;
            <a href="mailto:success@hologram.io">success@hologram.io</a>.
          </p>
        ) : (
          <>
            <p>Enter your email and we&apos;ll send you a reset link shortly.</p>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              footerActions={
                <Button name={submitLabel} type="submit" loading={isSubmitting}>
                  {submitLabel}
                </Button>
              }
            >
              <TextInputField
                label="Email"
                type="email"
                invalid={!!errors.email}
                validationMessage={errors.email?.message?.toString()}
                {...register('email', {
                  validate: {
                    validEmail: (v) => isValidEmailValidator(v) || validationMessages.invalidEmail,
                  },
                  required: validationMessages.invalidEmail,
                })}
              />
            </Form>
          </>
        )}
      </FormPanel>
    </>
  );
};

export default ForgotPasswordForm;
