import _ from 'lodash';
import { createSelector } from 'reselect';
import * as DeviceStates from 'common-js/constants/deviceStates';
import * as loadingTypes from 'common-js/reducers/devices/actionTypes';
import { PAGE_SIZE } from 'common-js/reducers/devices/reducer';
import { getSelectedOrgId } from 'common-js/reducers/organization/selectors';
import { deviceFilterTreeToJson } from 'common-js/utils/devices';

export const getLoadedDevices = (state) => state.devices.loaded;
export const getCachedDevices = (state) => state.devices.devicesCache;
export const getSelectedDeviceState = (state) => state.devices.selection;
export const getFilters = (state) => state.devices.filters;
export const getDeviceSegmentStates = (state) => state.devices.segments.state ?? {};
export const getTotalDevices = (state) => state.devices.page.totalDeviceCount;
export const getTotalSelectedDevices = (state) => state.devices.selection.totalSelected;
export const getSelectedPages = (state) => state.devices.selection.pagesSelected;
export const getTotalPages = (state) => state.devices.page.total;
export const getCurrentPage = (state) => state.devices.page.current;
export const getDevicesPerPage = (state) => state.devices.page.devicesPerPage;
export const getDevicesPageLimit = (state) => state.devices.page.limit;
export const getQuery = (state) => state.devices.query.value;
export const getQueryInputValue = (state) => state.devices.uiState?.queryInputValue;
export const getFilterCount = (state) => {
  const filters = getFilters(state);
  return _.isEmpty(filters)
    ? 0
    : Object.keys(filters).reduce((acc, curr) => {
        if (curr === 'tag_id' || curr === 'plans' || curr === 'state') {
          return acc + Object.keys(filters[curr]).length;
        }
        return acc + 1;
      }, 0);
};
export const getIsAllSelected = (state) => state.devices.selection.allSelected;
export const getSelectedDeviceIds = (state) =>
  state.devices.selection.allSelected ||
  state.devices.selection.pagesSelected.includes(state.devices.page.current)
    ? state.devices.loaded.reduce((deviceIds, device) => {
        if (!(device.id in state.devices.selection.excludedIds)) {
          deviceIds.push(String(device.id));
        }
        return deviceIds;
      }, [])
    : Object.keys(state.devices.selection.byId);

export const getSelectedLoadedDevices = createSelector(
  [getSelectedDeviceState, getLoadedDevices, getCurrentPage, getSelectedPages],
  (selection, loaded, currentPage, selectedPages) => {
    const { allSelected, excludedIds } = selection;
    let selectedDevices = [];

    if (selectedPages.includes(currentPage) || allSelected) {
      selectedDevices = loaded;
    } else {
      const idsFromSelectedDevices = Object.keys(selection.byId);
      selectedDevices = loaded.filter(({ id }) => idsFromSelectedDevices.includes(id.toString()));
    }

    return selectedDevices.filter(({ id }) => !Object.keys(excludedIds).includes(id.toString()));
  }
);

export const getDevicesDataByIds = (deviceIds) =>
  createSelector([getCachedDevices], (cachedDevices) => _.pick(cachedDevices, deviceIds));

export const getKeyedSelectedDevicesData = createSelector(
  [getSelectedDeviceIds, getCachedDevices],
  (deviceIds, cachedDevices) => _.pick(cachedDevices, deviceIds)
);
export const getSelectedDevicesFromCache = createSelector(
  [getSelectedDeviceIds, getCachedDevices],
  (deviceIds, cachedDevices) => {
    const keyedDeviceData = _.pick(cachedDevices, deviceIds);
    return Object.values(keyedDeviceData);
  }
);

export const isDeviceInState = (state, props) => {
  const { deviceId, deviceStates } = props;
  let singleDevice = state.currentDevice.device ?? state.singleDevice[deviceId]?.device;

  if (!singleDevice) {
    const cache = getCachedDevices(state);
    singleDevice = cache[deviceId];
  }

  if (singleDevice) {
    return deviceStates.includes(singleDevice.state);
  }
  return undefined;
};

export const getIsResumeDisabled = (state) => {
  const selectedDevices = getSelectedDevicesFromCache(state);
  return selectedDevices.some((device) => {
    const deviceState = device.link_state_connected;
    return (
      deviceState === DeviceStates.PAUSED_PENDING_SYS ||
      deviceState === DeviceStates.PAUSED_PENDING_SYS ||
      deviceState === DeviceStates.PAUSED_SYS
    );
  });
};

export const getIsPageSelected = createSelector(
  [getLoadedDevices, getSelectedDeviceState],
  (loaded, selected) => !!loaded.length && loaded.every((device) => selected.byId[device.id])
);

export const getSelectedTagIds = createSelector([getFilters], (filters) => {
  const tags = filters.tag_id ?? {};
  const tagIds = Object.keys(tags);
  return tagIds.map((tag) => Number(tag));
});

export const getIsQueried = (state) => {
  const { devices } = state;
  return devices.query?.value !== '' && !_.isNull(devices.query?.value);
};

export const getCompletedTasks = (state) => state.devices.tasks?.groups?.completed;

export const getPendingTasks = createSelector(
  (state) => state.devices.tasks?.groups?.pending,
  (pending) => pending
);
export const getProgressForTasks = (state) => state.devices.tasks?.progress;

export const getActivityHistory = (state) => state.devices.tasks?.completed;
export const getActivityHistoryLoading = (state) => state.devices.tasks?.completed?.loading;
export const getActivityHistorySort = (state) => state.devices.tasks?.completed?.sort;
export const getTaskPageCompletedTasks = (state) => state.devices.tasks?.completed?.tasks;

export const getTaskPageInfo = (state) => state.devices.tasks?.page;
export const getTasksCurrentPage = (state) => state.devices.tasks?.page?.currentPage;
export const getTasksTotalPages = (state) => state.devices.tasks?.page?.totalPages;
export const getTasksTotalTasks = (state) => state.devices.tasks?.page?.totalTasks;
export const getTasksPerPage = (state) => state.devices.tasks?.page?.tasksPerPage;
export const getTasksStartAfterIds = (state) => state.devices.tasks?.page?.startAfterIds;

export const shouldUseSearchEndpoint = (state) =>
  (state.devices.query?.value !== '' && !_.isNull(state.devices.query?.value)) ||
  !_.isNull(state.devices.sort?.column) ||
  (!!state.devices.filters && Object.keys(state.devices.filters).length > 0);

export const getSelectedPageStartAfterIds = (state) => {
  const selectedPages = getSelectedPages(state);
  const selectedPageStartAfterIds = [];
  const { pageIds } = state.devices.page;
  const firstLoadedDevice = state.devices.loaded[0];
  if (!_.isEmpty(pageIds) && selectedPages.length > 0) {
    selectedPages.forEach((pageNumber) => {
      if (pageNumber > 1) {
        selectedPageStartAfterIds.push(pageIds[pageNumber - 2].startAfterId);
      } else {
        selectedPageStartAfterIds.push(firstLoadedDevice.id + 1);
      }
    });
  }
  return selectedPageStartAfterIds;
};

export const selectBatchJobDetails = createSelector(
  (state) => state.devices?.tasks?.details,
  (details) => details
);

export const selectActivityHistoryDetailsStartAfterId = createSelector(
  selectBatchJobDetails,
  (state, currentPage) => currentPage,
  (details, currentPage) =>
    currentPage > 1 ? details?.pages?.[currentPage - 2].startAfterId : null
);

export const selectIsLoadingPauseResume = createSelector(
  (state) => state.devices?.uiState?.loading,
  (loading) =>
    loading[loadingTypes.PAUSE_DATA_BULK_PREVIEW_REQUEST] ||
    loading[loadingTypes.RESUME_DATA_BULK_PREVIEW_REQUEST]
);

export const selectSearchFilters = createSelector(
  (state) => state.devices,
  (devices) => deviceFilterTreeToJson(devices)
);

export const selectDeviceFilters = createSelector(
  getDeviceSegmentStates,
  getIsAllSelected,
  getSelectedPageStartAfterIds,
  shouldUseSearchEndpoint,
  selectSearchFilters,
  (deviceStates, isAllSelected, pageIdsSelected, shouldUseSearch, searchFilters) => {
    const apiFilters = {
      states: Object.keys(deviceStates),
      limit: isAllSelected ? null : PAGE_SIZE,
      pageIdsSelected,
    };

    return {
      filters: shouldUseSearch ? searchFilters : apiFilters,
      shouldUseSearch,
    };
  }
);

export const selectSelectedLinkIds = createSelector(
  getLoadedDevices,
  getSelectedDeviceIds,
  (devices, selectedDeviceIds) => {
    const mapDeviceIdToLinkId = (deviceId) =>
      devices.find(({ id }) => String(id) === deviceId)?.link_id;
    return selectedDeviceIds.map(mapDeviceIdToLinkId).filter(Boolean);
  }
);

export const selectBulkSelectionFields = createSelector(
  getSelectedDeviceState,
  getSelectedOrgId,
  shouldUseSearchEndpoint,
  selectDeviceFilters,
  (state, isPreview) => isPreview,
  (selection, orgid, useSearch, deviceFilters, isPreview) => ({
    deviceids: Object.keys(selection?.byId || {}).map(Number),
    excludedDeviceIds: Object.keys(selection?.excludedIds || {}).map(Number),
    linkids: Object.keys(selection?.byLinkId || {}).map(Number),
    allSelected: selection?.allSelected,
    pagesSelected: selection?.pagesSelected,
    useSearch,
    ignoreInvalidLinks: !isPreview,
    deviceFilters: {
      ...deviceFilters?.filters,
      hitsPerPage: selection?.allSelected ? 10000 : PAGE_SIZE,
      orgid,
    },
  })
);
