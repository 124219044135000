import { useCallback } from 'react';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useApi from 'common-js/api/useApi';
import { getSelectedOrgId } from 'common-js/reducers/organization/selectors';
import { getElapsedDays } from 'common-js/utils/time';

const useGetDeviceBreakdown = () => {
  const apiCall = useApi.call('/reports/longterm/usageperdevice:recent?:searchParams');
  const orgId = useAppSelector(getSelectedOrgId);

  return useCallback(
    async (filters) => {
      const daysSinceStartTime = getElapsedDays(filters.timestart);
      const recent = daysSinceStartTime < 90 ? '/network' : '';
      const searchParams = new URLSearchParams({ orgid: orgId, ...filters });

      try {
        const { data } = await apiCall({ urlParams: { searchParams, recent } });
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [apiCall, orgId]
  );
};

export default useGetDeviceBreakdown;
