import React from 'react';
import submitDashboardMarketoForm from 'common-js/utils/marketing';
import { useFormContext } from 'react-hook-form';
import { IconCard } from '../../components';

function Step6(props) {
  const { handleAnalyticsCall, nextStep } = props;
  const { setValue, getValues } = useFormContext();
  const handleClick = (usage) => () => {
    setValue('usage', usage);
    handleAnalyticsCall('usage', usage);
    submitDashboardMarketoForm(getValues());
    nextStep();
  };

  return (
    <div className="Step NewForm">
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__Header">
            <h2>What’s your estimated monthly data usage?</h2>
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__DoubleColumn">
          <div className="NewForm__SubHeader">
            Please select the data option that best describes your situation
          </div>
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q6_0-50mb.svg"
            onClick={handleClick('0-50 MB')}
            mainText="0 - 50 MB"
            subText="PER SIM"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q6_50-250mb.svg"
            onClick={handleClick('50-250 MB')}
            mainText="50 - 250 MB"
            subText="PER SIM"
          />
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q6_250-500mb.svg"
            onClick={handleClick('250-500 MB')}
            mainText="250 - 500 MB"
            subText="PER SIM"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q6_500-1gb.svg"
            onClick={handleClick('500 MB-1 GB')}
            mainText="500 MB - 1 GB"
            subText="PER SIM"
          />
        </div>
      </div>
      <div className="NewForm__Row">
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q6_1-10gb.svg"
            onClick={handleClick('1-10 GB')}
            mainText="1 - 10 GB"
            subText="PER SIM"
          />
        </div>
        <div className="NewForm__Column">
          <IconCard
            imgPath="https://cdn1.hologram.io/dashboard/account/q6_10-plus-gb.svg"
            onClick={handleClick('10 GB+')}
            mainText="10+ GB"
            subText="PER SIM"
          />
        </div>
      </div>
      <div className="NewForm__BottomRow">
        <div className="NewForm__DoubleColumn">
          <IconCard mainText="Not sure" onClick={handleClick()} />
        </div>
      </div>
    </div>
  );
}

export default Step6;
