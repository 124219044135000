import { openModal } from 'common-js/reducers/modal/actions';
import { DeviceStateChangeAllowedStates } from 'common-js/types/Device';
import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import type {
  useBulkChangeStateEvent,
  useBulkChangeStatePreviewEvent,
} from './useItems/useBulkChangeState';

interface UseBulkModalCallbackProps {
  actionType?: DeviceStateChangeAllowedStates;
  overLimit?: boolean;
  action?: ReturnType<typeof useBulkChangeStateEvent>;
  getPreview?: ReturnType<typeof useBulkChangeStatePreviewEvent>;
}

const useBulkModal = (
  modalName: 'BulkPauseResumeModal' | 'BulkActivatePreflightModal' | 'BulkDataUsageLimitsModal'
) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (props?: UseBulkModalCallbackProps) => {
      dispatch(openModal(modalName, props, `BulkDataActionModal ${modalName}`));
    },
    [dispatch, modalName]
  );
};

export default useBulkModal;
