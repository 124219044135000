import React from 'react';
import { ModalHeader } from '../components';

function PermissionsErrorModal() {
  return (
    <>
      <ModalHeader title="Permissions Error" />
      <p>
        You do not have permission to perform this operation. As an Editor you cannot activate SIMs.
        You will need your organization Owner or an Admin to update your collaborator privileges,
        please{' '}
        <a
          href="https://support.hologram.io/hc/en-us/articles/360035213714-Manage-organizations-and-additional-users"
          rel="noreferrer"
          target="_blank"
        >
          see here
        </a>{' '}
        for more details on roles and permissions.
      </p>
    </>
  );
}

export default PermissionsErrorModal;
