import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';

const CloseButton: React.FC<{
  closeToast?: React.MouseEventHandler<HTMLButtonElement & HTMLAnchorElement>;
}> = ({ closeToast }) => (
  <div className="toastify__close">
    <Button
      onClick={closeToast}
      iconStart={<Icon name="Close" size="small" fill="DdsColorInteractiveSecondaryDefault" />}
      variant="tertiary"
    />
  </div>
);

export default CloseButton;
