import PropTypes from 'prop-types';
import React from 'react';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { close as closeModal } from 'common-js/reducers/modal/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from '@holokit/core';

class ModalHeader extends React.Component {
  handleClose = () => {
    const { analyticsEvent, close, closeModal_ } = this.props;
    if (close) {
      close();
    }
    closeModal_();
    if (analyticsEvent) {
      sendAnalyticsEvent(analyticsEvent);
    }
  };

  render() {
    const { title, icon } = this.props;

    return (
      <div className={`ModalHeader${title ? '' : ' no-title'}`}>
        {icon && (
          <div className="title-icon">
            <Icon name={icon} size="major" svgProps={{ style: { fill: '#8008F7' } }} />
          </div>
        )}
        {title && <div className="title">{title}</div>}
        <div className="close-button" onClick={this.handleClose}>
          <Icon name="Close" size="minor" svgProps={{ style: { fill: '#606677' } }} />
        </div>
      </div>
    );
  }
}

ModalHeader.propTypes = {
  close: PropTypes.func,
  title: PropTypes.string,
};

ModalHeader.defaultProps = {};

export default connect(
  () => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        closeModal_: closeModal,
      },
      dispatch
    )
)(ModalHeader);
