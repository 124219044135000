import { Icon } from '@holokit/core';
import _classnames from 'clsx';
import { toByteStringFormatted } from '../../../../common/utils/numberFormatter';
import DataUsedChartD3 from '../../../components/charts/DataUsedChartD3';

interface DataUsedChartProps {
  data: Array<{ datausage: string }>;
  loading?: boolean;
  error?: any;
  fromInspect?: boolean;
  loadingHeight?: number;
}
const DataUsedChart = ({
  loading = false,
  error = null,
  data = [],
  loadingHeight = 170,
  fromInspect = false,
}: DataUsedChartProps) => {
  if (error) {
    return (
      <div className="DataUsedChart">
        <div className="panel-error">
          {fromInspect ? (
            <div>
              <div className="panel-error-title">
                <Icon name="Warning" size="major" svgProps={{ style: { fill: '#A80000' } }} />{' '}
                <span className="h4">Error</span>
              </div>
              <div className="panel-error-body">
                We had a problem loading data usage for these devices.
              </div>
            </div>
          ) : (
            <div>
              <Icon name="Warning" size="major" svgProps={{ style: { fill: '#A80000' } }} /> We had
              a problem loading data usage for these devices.
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="DataUsedChart">
      {data.length === 0 && fromInspect && !loading ? (
        <div className="grid-row">
          <div className="usage-inspect-empty-state">
            <Icon name="Circle--icon" />
            <div>
              There are no usage metrics to display for this set of devices and specific timeframe.
              Try adjusting tags or the selected date range.
            </div>
          </div>
        </div>
      ) : (
        <div className="grid-row">
          <div
            className={_classnames('Panel__loading-overlay', {
              'DataUsedChart__loading-overlay': loading,
              'Panel__loading-overlay--active': loading,
            })}
          >
            {loading && <div className="DimensionLoader__legacy-padding-override">Loading...</div>}
          </div>
          {!loading && (
            <div className="grid-row grid-column data-section">
              <div className="data-amount">
                {toByteStringFormatted(
                  data.reduce((total, next) => total + parseInt(next.datausage, 10), 0)
                )}
              </div>
              <div className="data-label type-micro uppercase type-weight-medium">
                Total data used
              </div>
            </div>
          )}
          <div className="grid-item overflow-chart">
            {!loading ? (
              <DataUsedChartD3 data={data} />
            ) : (
              <div style={{ height: `${loadingHeight}px` }} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataUsedChart;
