import { Icon } from '@hologram-dimension/icon';
import { Panel } from '@hologram-dimension/panel';
import { PanelNotification } from '@hologram-dimension/panel-notification';
import ColorTokens from '@hologram-dimension/tokens/colors';
import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { ActivePlanList, FullHeightCalculatorHOC, HoloHelmet } from 'common-js/components';
import { useAppDispatch, useAppSelector } from 'common-js/hooks';
import { getPlans } from 'common-js/reducers/account/actions';
import {
  getAllBilling,
  getDeviceCount,
  getOrgGroups,
  getPlanDeviceSummary,
} from 'common-js/reducers/organization/actions';
import { getAllPoolInfo, getPoolPlans, getPools } from 'common-js/reducers/pools/actions';
import { FC, ReactNode, useEffect, useState } from 'react';
import SimSubMenu from '../../sims/components/SubMenu';
import SubMenu from '../components/SubMenu';

interface PlansProps {
  simInventory?: boolean;
}

const Plans: FC<PlansProps> = ({ simInventory }) => {
  const [poolDataLoading, setPoolDataLoading] = useState(true);
  const [planDataLoading, setPlanDataLoading] = useState(true);

  const devicePlanSummary = useAppSelector((state) => state.organization.devicePlanSummary);

  const dispatch = useAppDispatch();

  useEffect(() => {
    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: `${simInventory ? 'Sims' : 'Devices'} - Plans` },
    });
  }, [simInventory]);

  useEffect(() => {
    const load = async () => {
      const planPromises = [dispatch(getPlans()), dispatch(getPlanDeviceSummary())];

      const poolPromises = [
        dispatch(getPoolPlans()),
        dispatch(getOrgGroups()),
        dispatch(getAllBilling()),
        dispatch(getDeviceCount()),
        dispatch(getPools()).then((pools) => {
          if (pools.length > 0) {
            dispatch(getAllPoolInfo(pools[0].id)).then(() => {
              Promise.resolve();
            });
          } else {
            Promise.resolve();
          }
        }),
      ];

      Promise.all(poolPromises).finally(() => {
        setPoolDataLoading(false);
      });

      Promise.all(planPromises).finally(() => {
        setPlanDataLoading(false);
      });
    };
    load();
  }, [dispatch]);

  let content: ReactNode;
  if (devicePlanSummary.length > 0) {
    content = <ActivePlanList />;
  } else if (planDataLoading || poolDataLoading) {
    content = <PanelNotification>Loading...</PanelNotification>;
  } else {
    content = (
      <PanelNotification
        label="No plan data"
        icon={<Icon name="CircleInfo" size="large" fill="DdsColorFillNotificationNote" />}
      >
        After activating a SIM, your plan breakdown will be displayed here
      </PanelNotification>
    );
  }

  return (
    <>
      <HoloHelmet title="Data Plans" />
      {simInventory ? <SimSubMenu /> : <SubMenu />}
      <FullHeightCalculatorHOC
        accountForDrawer
        backgroundColor={ColorTokens.DdsColorBackgroundAlternative}
      >
        <div className="billing-container SimsTagManager">
          <Panel
            className="ActivePlanList__panel"
            header="Active data plans"
            noContentPadding
            isLoading={planDataLoading || poolDataLoading}
          >
            {content}
          </Panel>
        </div>
      </FullHeightCalculatorHOC>
    </>
  );
};

export default Plans;
