import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Panel } from '@holokit/core';

import { PAGE_VIEW } from 'common-js/analytics/actionTypes';
import { sendAnalyticsEvent } from 'common-js/analytics/analytics';
import { FullHeightCalculatorHOC, HoloHelmet } from 'common-js/components';
import * as Links from 'common-js/constants/links';
import AppForm from 'apps/appform/AppForm';
import * as appActions from 'common-js/reducers/apps/actions';
import * as messageActions from 'common-js/reducers/message/actions';

class Add extends React.Component {
  componentDidMount() {
    const { getAppSchemas, getApps, pushGlobalMessage } = this.props;

    sendAnalyticsEvent({
      type: PAGE_VIEW,
      data: { page: 'Routes - Add Route' },
    });

    try {
      Promise.all([getApps(), getAppSchemas()]).catch((e) => {
        pushGlobalMessage(e, 'error');
      });
    } catch (e) {
      pushGlobalMessage(e, 'error');
    }
  }

  render() {
    return (
      <FullHeightCalculatorHOC accountForDrawer>
        <HoloHelmet title="Data Routes - Create New Route" />
        <div className="add-app">
          <Panel
            title={
              <div className="h2">
                Create new route
                <span className="help-link pull-right">
                  <Icon
                    classes="help-link-icon"
                    name="Book--open"
                    size="minor"
                    svgProps={{ style: { fill: '#04a7ff' } }}
                  />
                  <a href={Links.LEARN_ROUTES} target="_blank" rel="noreferrer">
                    Learn about creating routes
                  </a>
                </span>
              </div>
            }
            noBodyPadding
          >
            <AppForm />
          </Panel>
        </div>
      </FullHeightCalculatorHOC>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      getAppSchemas: appActions.getAppSchemas,
      getApps: appActions.getApps,
      pushGlobalMessage: messageActions.pushGlobalMessage,
    },
    dispatch
  )
)(Add);
