import { stringify } from 'query-string';

import * as alertTypes from '../../constants/alertTypes';
import { API_URL, headers, getHeaders } from '../config';
import { fetchHandler, fetchErrorHandler } from '../middleware';

const ALERT_TYPE_TO_API_URI = {
  [alertTypes.DEVICE_DATA_LIMIT]: 'datalimit',
  [alertTypes.DEVICE_USAGE]: 'usage',
  [alertTypes.TAC_CHANGE]: 'tacchange',
};

export const getAlertsByType = (alertType, orgId, options) => {
  const queryParams = {
    orgid: orgId,
    acked: false,
    acked_by: null,
    offset: 0,
    ...options,
  };
  const qString = stringify(queryParams, { arrayFormat: 'bracket' });
  const alertUrl = ALERT_TYPE_TO_API_URI[alertType];

  return fetch(`${API_URL}/alerts/device/${alertUrl}?${qString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((resp) => Promise.resolve(resp)))
    .catch(fetchErrorHandler);
};

export const getAlertCountByType = (alertType, orgId) => {
  const queryParams = {
    orgid: orgId,
    type: alertType,
    acked: false,
  };
  const qString = stringify(queryParams);

  return fetch(`${API_URL}/alerts/count?${qString}`, {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders,
  })
    .then(fetchHandler((resp) => Promise.resolve(resp)))
    .catch(fetchErrorHandler);
};

export function acknowledgeAlert(alertId, userId, orgId) {
  const queryParams = { orgid: orgId, userid: userId };
  const qString = stringify(queryParams, { arrayFormat: 'bracket' });
  return fetch(`${API_URL}/alerts/${alertId}/acknowledge?${qString}`, {
    method: 'PUT',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((response) => Promise.resolve(response)))
    .catch(fetchErrorHandler);
}

export function unacknowledgeAlert(alertId, userId, orgId) {
  const queryParams = { orgid: orgId, userid: userId };
  const qString = stringify(queryParams, { arrayFormat: 'bracket' });
  return fetch(`${API_URL}/alerts/${alertId}/unacknowledge?${qString}`, {
    method: 'PUT',
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((response) => Promise.resolve(response)))
    .catch(fetchErrorHandler);
}

export function acknowledgeAlerts(alertIds, userId, orgId) {
  const queryParams = { orgid: orgId, userid: userId };
  const qString = stringify(queryParams, { arrayFormat: 'bracket' });
  return fetch(`${API_URL}/alerts/acknowledge?${qString}`, {
    method: 'PUT',
    body: JSON.stringify({ alertIds }),
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((response) => Promise.resolve(response)))
    .catch(fetchErrorHandler);
}

export function acknowledgeAllAlerts(userId, orgId, alertType) {
  const queryParams = { orgid: orgId, userid: userId };
  const qString = stringify(queryParams, { arrayFormat: 'bracket' });
  return fetch(`${API_URL}/alerts/acknowledgeall?${qString}`, {
    method: 'PUT',
    body: JSON.stringify({ type: alertType }),
    credentials: 'include',
    headers,
  })
    .then(fetchHandler((response) => Promise.resolve(response.data)))
    .catch(fetchErrorHandler);
}
