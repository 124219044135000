import Skeleton from 'common-js/components/Skeleton';
import React from 'react';
import ActivationSidebar from './ActivationSidebar';

const skeletonStyles = {
  width: '100%',
  height: '1.25rem',
  marginBottom: '1.5rem',
};

const SkeletonSidebar = () => (
  <ActivationSidebar>
    <Skeleton style={skeletonStyles} />
    <Skeleton style={skeletonStyles} />
    <Skeleton style={skeletonStyles} />
    <Skeleton style={skeletonStyles} />
    <Skeleton style={skeletonStyles} />
    <Skeleton style={skeletonStyles} />
    <Skeleton style={skeletonStyles} />
  </ActivationSidebar>
);

export default SkeletonSidebar;
