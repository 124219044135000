export const confirmationMessagePrefix = 'An email has been sent to';
export const serverErrorPrefix = 'There was an error submitting the form.';
export const submitLabel = 'Reset my password';
export const validationMessages = {
  invalidEmail: 'Please enter a valid email address.',
};
export const pageTitles = {
  default: 'Forgot password?',
  confirmation: 'Password reset requested',
};
