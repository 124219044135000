import React, { useCallback, useEffect, useRef } from 'react';

const noop = () => {};
type OnChangeHandler = (value: string) => void;
interface EditableInputProps {
  onChange?: OnChangeHandler;
  value?: string;
  maxLength: number;
}
const EditableInput = ({ value = '', maxLength, onChange = noop }: EditableInputProps) => {
  const inputRef = useRef<{ value: string } | null>(null);
  useEffect(() => {
    // when component is mounted, set the initial input value
    if (inputRef.current) {
      inputRef.current.value = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = useCallback(() => {
    if (inputRef?.current?.value !== value) {
      // now that a change has been made, call the onChange handler
      onChange(inputRef?.current?.value ?? '');
    }
  }, [onChange, value]);

  return (
    <input
      className="EditableInput"
      ref={inputRef as any}
      maxLength={maxLength}
      onBlur={handleBlur}
    />
  );
};

export default EditableInput;
