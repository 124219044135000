import { FullHeightCalculatorHOC } from 'common-js/components';
import React from 'react';
import ReportsMenu from 'reports/ReportsMenu';
import BaseLayout from './BaseLayout';

interface LayoutReportsProps {
  children?: React.ReactNode;
}

const LayoutReports: React.FC<LayoutReportsProps> = ({ children }) => (
  <BaseLayout>
    <ReportsMenu />
    <FullHeightCalculatorHOC accountForDrawer>
      <div className="container">
        <FullHeightCalculatorHOC classNames="fullHeight-usage" accountForDrawer relative>
          {children}
        </FullHeightCalculatorHOC>
      </div>
    </FullHeightCalculatorHOC>
  </BaseLayout>
);

export default LayoutReports;
