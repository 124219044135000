import React from 'react';
import { Button } from '@hologram-dimension/button';

const HyperProfileHistoryError = () => (
  <div className="HyperProfileHistoryError">
    <h1 className="HyperProfileHistoryError__message">There was an error loading this data</h1>

    <Button
      variant="secondary"
      classes="HyperProfileHistoryError__button"
      onClick={() => window.location.reload()}
    >
      Reload profile history
    </Button>
  </div>
);

export default HyperProfileHistoryError;
