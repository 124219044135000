import React from 'react';
import PropTypes from 'prop-types';
import _classnames from 'clsx';
import { Link } from 'react-router';
import * as Paths from '../../common/constants/paths';

function CellDeviceName({ deviceId, deviceName, deviceSimNumber, isActivating }) {
  let name;
  if (deviceName) {
    name = deviceName;
  } else if (deviceSimNumber) {
    name = deviceSimNumber;
  } else {
    name = '(no name)';
  }
  return (
    <div
      className={_classnames('DevicesTable__cell DevicesTable__name', {
        'DevicesTable__name--activating': isActivating,
      })}
    >
      {isActivating ? (
        <>
          Activating: {name}{' '}
          <button
            className="Button Button--small Button--primary DevicesTable__name__button"
            type="submit"
          >
            <div className="Button__text">Update</div>
          </button>
        </>
      ) : (
        <Link
          className="DevicesTable__name__link"
          to={Paths.withContext(
            Paths.DEVICE_LANDING.replace(`:${Paths.DEVICE_PARAM_NAME}`, deviceId)
          )}
          id={`device-link-${deviceId}`}
        >
          {name}
        </Link>
      )}
    </div>
  );
}

CellDeviceName.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  deviceSimNumber: PropTypes.string,
  isActivating: PropTypes.string.isRequired,
};

export default CellDeviceName;
