import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { Panel } from '@hologram-dimension/panel';
import { Icon } from '@hologram-dimension/icon';
import { FIND_SIM_ICCIDS_HELP } from 'common-js/constants/links';
import { useLogGlobalSimError } from 'activation/common/hooks/useLogGlobalSimError';
import analyticsEventBuilder from 'common-js/analytics';
import { clearValidationErrorState } from 'common-js/reducers/activation/actions/actions';
import { calculateTotalDueToday } from 'common-js/reducers/activation/actions/calculateTotalDueToday';
import { selectSimErrors } from 'common-js/reducers/activation/selectors';
import validateSims from 'common-js/reducers/activation/actions/validateSims';
import AlreadyActivatedSims from './AlreadyActivatedSims';
import InvalidatedPreflightSims from './InvalidatedPreflightSims';
import SimsDisplay from './SimsDisplay';
import SimError from './SimError';
import SimInfo from './SimInfo';
import SimInput from './SimInput';
import {
  getInvalidSims,
  getAlreadyActivatedSims,
  isArrayValid,
  getDuplicateSimsWarning,
} from './SimUtils';

const ACTIVATION_TYPE = 'individual';

function IndividualSimsPanel({ isOnboarding, hasBillingPermissions }) {
  const { getValues, setValue, watch } = useFormContext() ?? {};

  const [revalidatedSims, setRevalidatedSims] = useState(false);

  const { sims: simsInForm, preflight } = watch('sims') ?? {};
  const [numSimsRemoved, setNumSimsRemoved] = useState(0);

  const simErrors = useAppSelector(selectSimErrors);
  const dispatch = useAppDispatch();
  const {
    invalid,
    globalError,
    alreadyActivated,
    preflightSims: invalidatedPreflightSims,
  } = simErrors;

  useLogGlobalSimError({ globalError, isOnboarding });

  const invalidSims = useMemo(() => getInvalidSims(invalid), [invalid]);
  const alreadyActivatedSims = useMemo(
    () => getAlreadyActivatedSims(alreadyActivated),
    [alreadyActivated]
  );

  const [duplicateSims, setDuplicateSims] = useState([]);
  const duplicateSimsList = useMemo(() => getDuplicateSimsWarning(duplicateSims), [duplicateSims]);

  const clearSelection = useCallback(() => {
    setValue('sims.sims', []);
    setValue('sims.total', 0);
    setDuplicateSims([]);
  }, [setDuplicateSims, setValue]);

  // Preflight SIMs
  const isPreflight = useAppSelector((state) => state.activation?.flags?.isPreflight);
  const selectedPreflightSims = useAppSelector((state) => state.activation?.preflight?.sims);

  const handleSubmit = useCallback(
    async (sims) => {
      setRevalidatedSims(true); // Once the user has entered SIMs, we don't want to pull in existing SIMs for revalidation
      dispatch(clearValidationErrorState());

      const simsAlreadyInForm = getValues('sims.sims');
      const dedupedSims = [];
      const dupes = [];
      const activatedSims = alreadyActivated.map((sim) => sim.key);

      sims.forEach((sim) => {
        const formattedSim = sim.trim();

        const isDuplicate =
          dedupedSims.includes(formattedSim) ||
          simsAlreadyInForm.includes(formattedSim) ||
          activatedSims.includes(formattedSim);

        if (!isDuplicate) {
          dedupedSims.push(formattedSim);
        } else {
          dupes.push(formattedSim);
        }
      });
      setDuplicateSims([...dupes]);

      if (dedupedSims.length > 0) {
        const simConfiguration = {
          plan: {},
          sims: dedupedSims,
          isOnboarding,
          individualSims: true,
          invalidatePreflightSims: !isPreflight, // invalidate manual entry, allow devices table selection
        };
        const response = await dispatch(validateSims(simConfiguration));
        const { totalValidSims: numberOfValidSims, validSims } = response;

        if (numberOfValidSims > 0) {
          // If validSims came through, not all SIMs were valid so we want to use
          // validSims; if it didn't come through, all SIMs were valid
          const simsToAdd = validSims ?? dedupedSims;

          const currentNumberOfSims = getValues('sims.total');
          setValue('sims.total', currentNumberOfSims + numberOfValidSims);

          const currentSims = getValues('sims.sims');
          setValue('sims.sims', [...currentSims, ...simsToAdd]);

          analyticsEventBuilder
            .dataEntry(
              'Activation',
              isOnboarding ? '1st Time' : 'Returning',
              'Activation SIM Addition'
            )
            .send({ 'SIM addition count': simsToAdd.length });

          const config = {
            sims: getValues('sims.sims'),
            plan: {},
            type: ACTIVATION_TYPE,
            excludeSims: null,
          };

          if (!hasBillingPermissions) {
            await dispatch(calculateTotalDueToday(config));
          }
        }
      }
    },
    [
      alreadyActivated,
      dispatch,
      getValues,
      hasBillingPermissions,
      isOnboarding,
      isPreflight,
      setDuplicateSims,
      setValue,
    ]
  );

  useEffect(() => {
    // If we're coming back to the SIM step, revalidate
    if (!revalidatedSims && simsInForm?.length > 0) {
      dispatch(clearValidationErrorState());

      clearSelection();
      setTimeout(() => handleSubmit(simsInForm), 0);
    }
  }, [clearSelection, dispatch, handleSubmit, revalidatedSims, setRevalidatedSims, simsInForm]);

  useEffect(() => {
    setValue('sims.inputOptionUsed', ACTIVATION_TYPE);
  }, [setValue]);

  useEffect(() => {
    if (isPreflight && selectedPreflightSims?.length > 0 && preflight?.loaded === false) {
      const iccids = selectedPreflightSims.map((sim) => sim.sim_number ?? sim.iccid);

      setValue('sims.sims', iccids);
      setValue('sims.total', iccids.length);
      setValue('sims.preflight.loaded', true);
      clearSelection();
      setTimeout(() => handleSubmit(iccids), 0);
    }
  }, [isPreflight, preflight, selectedPreflightSims, setValue, clearSelection, handleSubmit]);

  const removeSim = useCallback(
    async (sim) => {
      const filteredSims = simsInForm.filter((validatedSim) => validatedSim !== sim);
      setValue('sims.sims', filteredSims);

      const config = {
        sims: filteredSims,
        plan: {},
        type: ACTIVATION_TYPE,
        excludeSims: null,
      };

      if (!hasBillingPermissions) {
        await dispatch(calculateTotalDueToday(config));
      }
      const currentTotal = getValues('sims.total');
      setValue('sims.total', currentTotal - 1);

      analyticsEventBuilder
        .buttonClick(
          'Activation',
          isOnboarding ? '1st Time' : 'Returning',
          'Activation SIM Removal'
        )
        .send({ 'SIM removal count': numSimsRemoved + 1 });
      setNumSimsRemoved(numSimsRemoved + 1);
    },
    [dispatch, getValues, hasBillingPermissions, isOnboarding, numSimsRemoved, setValue, simsInForm]
  );

  return (
    <Panel
      header="Add SIMs"
      className="SimPanel"
      footer={
        <a href={FIND_SIM_ICCIDS_HELP} target="_blank" rel="noreferrer">
          Where do I find ICCIDs for my SIMs?
          <Icon
            className="SimPanel__footer__icon"
            name="ExternalLink"
            size="small"
            fill="DdsColorInteractivePrimaryDefault"
          />
        </a>
      }
      footerVariant="alternative"
    >
      <div className="SimPanel__content">
        <SimInput onSubmit={handleSubmit} clearOnSubmit />

        <hr className="SimPanel__content__line" />

        {isArrayValid(duplicateSimsList) && <SimInfo messageList={duplicateSimsList} />}

        {globalError && <SimError error={globalError} />}

        {isArrayValid(invalidSims) && <SimError error={invalidSims} />}

        {isArrayValid(invalidatedPreflightSims) && (
          <InvalidatedPreflightSims sims={invalidatedPreflightSims} />
        )}

        {alreadyActivatedSims && <AlreadyActivatedSims sims={alreadyActivatedSims} />}

        <SimsDisplay sims={simsInForm} onDelete={removeSim} text="added for activation" />
      </div>
    </Panel>
  );
}

export default IndividualSimsPanel;
