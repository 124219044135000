import { fetchDevices } from './actions';
import * as actionTypes from '../actionTypes';

export const updateSort = (column) => (dispatch, getState) => {
  const snapshot = getState();
  const columnIsSame = column === snapshot.devices.sort.column;
  const { direction } = snapshot.devices.sort;
  let nextColumn = column;
  let nextDirection = 'ASC';
  if (columnIsSame && direction === 'DESC') {
    nextColumn = null;
    nextDirection = null;
  } else if (columnIsSame && direction === 'ASC') {
    nextDirection = 'DESC';
  }

  dispatch({
    type: actionTypes.UPDATE_SORT,
    column: nextColumn,
    direction: nextDirection,
  });

  dispatch({
    type: actionTypes.RESET_PAGING,
  });

  dispatch(fetchDevices());
};
