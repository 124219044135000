import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import { openModal } from 'common-js/reducers/modal/actions';

const useTransferToOrganizationItem = () => {
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(openModal('BulkMoveDevicesModal'));
  }, [dispatch]);

  return {
    label: 'Transfer to organization',
    icon: 'Transfer',
    selectionLimit: 10,
    onClick,
  };
};

export default useTransferToOrganizationItem;
