import { getOrgIdFromContextData, getUserContextData } from 'common-js/api/util';
import { getSelectedDeviceState, selectDeviceFilters } from 'common-js/reducers/devices/selectors';
import { openModal } from 'common-js/reducers/modal/actions';
import { getUserPermissions } from 'common-js/reducers/organization/selectors';
import { DEACTIVATE } from 'common-js/utils/permissions';
import { useCallback } from 'react';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { useBulkChangeStateEvent, useBulkChangeStatePreviewEvent } from './useBulkChangeState';

const SELECTION_LIMIT = 10;

interface UseDeactivateSIMItemProps {
  deviceType: string;
  totalSelected: number;
}

const useDeactivateSIMItem = ({ deviceType, totalSelected }: UseDeactivateSIMItemProps) => {
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector(getUserPermissions);
  const selection = useAppSelector(getSelectedDeviceState);
  const { filters, shouldUseSearch } = useAppSelector(selectDeviceFilters);
  const userContextData = useAppSelector(getUserContextData);
  const orgId = getOrgIdFromContextData(userContextData)!;

  const deactivateDevicesPreview = useBulkChangeStatePreviewEvent({
    selection,
    filters,
    useSearch: shouldUseSearch,
    orgId,
    newState: 'deactivate',
  });

  const deactivateDevices = useBulkChangeStateEvent({
    selection,
    filters,
    useSearch: shouldUseSearch,
    orgId,
    newState: 'deactivate',
  });

  const onClick = useCallback(() => {
    dispatch(
      openModal(
        'DeactivateSimModal',
        {
          action: deactivateDevices,
          getPreview: deactivateDevicesPreview,
          supportsPreview: true,
        },
        'medium no-padding vertical-center'
      )
    );
  }, [deactivateDevices, deactivateDevicesPreview, dispatch]);

  return {
    label: `Deactivate ${totalSelected === 1 ? 'SIM' : 'SIMs'}`,
    icon: 'Lightning--slash',
    include: userPermissions.includes(DEACTIVATE) && deviceType !== 'preflight',
    selectionLimit: SELECTION_LIMIT,
    onClick,
    destructive: totalSelected <= SELECTION_LIMIT,
  };
};

export default useDeactivateSIMItem;
