import PropTypes from 'prop-types';
import React from 'react';
import Classnames from 'clsx';

class Select extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultMessage: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    error: PropTypes.string,
    wrapperClassNames: PropTypes.string,
    showValidation: PropTypes.bool,
  };

  render() {
    const {
      value,
      name,
      onChange,
      defaultMessage = 'Please wait\u2026',
      disabled = false,
      children = null,
      error = null,
      showValidation = true,
      wrapperClassNames = '',
      innerRef,
    } = this.props;

    const wrapperClasses = Classnames({
      'select-wrap': true,
      disabled,
    });

    return (
      <div className={wrapperClasses + (wrapperClassNames ? ` ${wrapperClassNames}` : '')}>
        <select
          name={name}
          value={value}
          onChange={onChange}
          className={error ? 'error' : null}
          disabled={!children || disabled}
          ref={innerRef}
        >
          {children || <option value="">{defaultMessage}</option>}
        </select>
        {showValidation && error && <div className="inline-error">{error}</div>}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <Select innerRef={ref} {...props} />);
