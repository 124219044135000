import analyticsEventBuilder from 'common-js/analytics';
import { useEffect } from 'react';

export const useLogGlobalSimError = ({ globalError, isOnboarding }) => {
  useEffect(() => {
    if (!globalError) {
      return;
    }

    analyticsEventBuilder
      .errorReturn('Activation', isOnboarding ? '1st Time' : 'Returning', 'SIM Backend Error')
      .send({ error: globalError });
  }, [globalError, isOnboarding]);
};

export default useLogGlobalSimError;
