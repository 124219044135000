import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EmptyCell from './EmptyCell';

function CellDevicePlan({ plan }) {
  return (
    <div className="DevicesTable__cell">
      <div>{plan ? _.escape(plan) : <EmptyCell />}</div>
    </div>
  );
}

CellDevicePlan.propTypes = {
  plan: PropTypes.string.isRequired,
};

export default CellDevicePlan;
