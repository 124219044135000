const PREFIX = 'CURRENT_DEVICE/';

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_CURRENT_DEVICE = `${PREFIX}SET_CURRENT_DEVICE`;
export const SET_CURRENT_DEVICE_WITH_METADATA = `${PREFIX}SET_CURRENT_DEVICE_WITH_METADATA`;
export const SET_CURRENT_DEVICE_LIVE_USAGE = `${PREFIX}SET_LIVE_USAGE`;
export const SET_TIME_QUICK_FILTER = `${PREFIX}SET_TIME_QUICK_FILTER`;
export const SET_CUSTOM_DATE = `${PREFIX}SET_CUSTOM_DATE`;
export const SET_POLLING_PAUSE = `${PREFIX}SET_POLLING_PAUSE`;

// TODO: anything below this line was brought over from singleDevice and should be refactored

// spacebridge tab
export const SET_TUNNELABLE_REQUEST = `${PREFIX}SET_TUNNELABLE_REQUEST`;
export const SET_TUNNELABLE_SUCCESS = `${PREFIX}SET_TUNNELABLE_SUCCESS`;
export const SET_TUNNELABLE_ERROR = `${PREFIX}SET_TUNNELABLE_ERROR`;

// message tab
export const SEND_SMS_TO_DEVICE_REQUEST = `${PREFIX}SEND_SMS_TO_DEVICE_REQUEST`;
export const SEND_SMS_TO_DEVICE_SUCCESS = `${PREFIX}SEND_SMS_TO_DEVICE_SUCCESS`;
export const SEND_SMS_TO_DEVICE_ERROR = `${PREFIX}SEND_SMS_TO_DEVICE_ERROR`;

export const SEND_DATA_TO_DEVICE_REQUEST = `${PREFIX}SEND_DATA_TO_DEVICE_REQUEST`;
export const SEND_DATA_TO_DEVICE_SUCCESS = `${PREFIX}SEND_DATA_TO_DEVICE_SUCCESS`;
export const SEND_DATA_TO_DEVICE_ERROR = `${PREFIX}SEND_DATA_TO_DEVICE_ERROR`;

export const SIM_MESSAGE_REQUEST = `${PREFIX}SIM_MESSAGE_REQUEST`;
export const SIM_MESSAGE_SUCCESS = `${PREFIX}SIM_MESSAGE_SUCCESS`;
export const SIM_MESSAGE_ERROR = `${PREFIX}SIM_MESSAGE_ERROR`;

// data limits tab
export const SET_DATA_USAGE_ALERT_REQUEST = `${PREFIX}SET_DATA_USAGE_ALERT_REQUEST`;
export const SET_DATA_USAGE_ALERT_SUCCESS = `${PREFIX}SET_DATA_USAGE_ALERT_SUCCESS`;
export const SET_DATA_USAGE_ALERT_ERROR = `${PREFIX}SET_DATA_USAGE_ALERT_ERROR`;

// DeviceHeader
export const ADD_TAG_TO_DEVICE_REQUEST = `${PREFIX}ADD_TAG_TO_DEVICE_REQUEST`;
export const ADD_TAG_TO_DEVICE_SUCCESS = `${PREFIX}ADD_TAG_TO_DEVICE_SUCCESS`;
export const ADD_TAG_TO_DEVICE_ERROR = `${PREFIX}ADD_TAG_TO_DEVICE_ERROR`;

export const REMOVE_TAG_FROM_DEVICE_REQUEST = `${PREFIX}REMOVE_TAG_FROM_DEVICE_REQUEST`;
export const REMOVE_TAG_FROM_DEVICE_SUCCESS = `${PREFIX}REMOVE_TAG_FROM_DEVICE_SUCCESS`;
export const REMOVE_TAG_FROM_DEVICE_ERROR = `${PREFIX}REMOVE_TAG_FROM_DEVICE_ERROR`;

export const CHANGE_DEVICE_NAME_REQUEST = `${PREFIX}CHANGE_DEVICE_NAME_REQUEST`;
export const CHANGE_DEVICE_NAME_SUCCESS = `${PREFIX}CHANGE_DEVICE_NAME_SUCCESS`;
export const CHANGE_DEVICE_NAME_ERROR = `${PREFIX}CHANGE_DEVICE_NAME_ERROR`;

export const PAUSE_DEVICE_REQUEST = `${PREFIX}PAUSE_DEVICE_REQUEST`;
export const PAUSE_DEVICE_SUCCESS = `${PREFIX}PAUSE_DEVICE_SUCCESS`;
export const PAUSE_DEVICE_ERROR = `${PREFIX}PAUSE_DEVICE_ERROR`;

export const RESUME_DEVICE_REQUEST = `${PREFIX}RESUME_DEVICE_REQUEST`;
export const RESUME_DEVICE_SUCCESS = `${PREFIX}RESUME_DEVICE_SUCCESS`;
export const RESUME_DEVICE_ERROR = `${PREFIX}RESUME_DEVICE_ERROR`;
