import React from 'react';

interface GridItemProps {
  name: string;
  subtext?: React.ReactNode;
  placeholder?: boolean;
  children: React.ReactNode;
}

const GridItem = ({ name, children, subtext, placeholder }: GridItemProps) => (
  <div className="grid-item">
    <div className="name">{name}</div>
    <div className={`value${placeholder ? ' placeholder' : ''}`}>{children}</div>
    {subtext && <div className="subtext">{subtext}</div>}
  </div>
);

export default GridItem;
