import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { Picture } from '@hologram-hyper-dashboard/components';
import { HoloHelmet } from 'common-js/components';
import { DEVICE_USAGE } from 'common-js/constants/alertTypes';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import { updateUserSettings } from 'common-js/reducers/account/actions';
import { selectShowDatalimitsEduBig } from 'common-js/reducers/account/selectors';
import { getAlertsByType } from 'common-js/reducers/alerts/actions';
import { selectAlertDataByType } from 'common-js/reducers/alerts/selectors';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import { Device } from 'common-js/types/Device';
import { toByteStringFormatted } from 'common-js/utils/numberFormatter';
import dataLimitsImg from 'img/sim_details/data_limit_and_alerts_diagram.webp?&imagetools';
import { FC, useCallback, useEffect } from 'react';
import DataLimitsSection from './DataLimitsSection';
import UsageAlertSection from './UsageAlertsSection';

type UsageAlerts = {
  id: number;
  text: string;
  acked: number;
  acked_at: string;
  acked_by: number;
  cur_billing_data_used: number;
  data_threshold: number;
  device_name: string;
  deviceid: number;
  expires: string;
  last_billing_data_used: number;
  latitude: number;
  linkid: number;
  longitude: number;
  orgid: number;
  plan_amount: number;
  planid: number;
  sim: string;
  state: string;
  type: string;
  whencreated: string;
};

const DataLimits: FC = () => {
  const dispatch = useAppDispatch();

  const device: Device = useAppSelector(selectCurrentDevice);
  const showEducationalBig: boolean = useAppSelector(selectShowDatalimitsEduBig);

  const usageAlertData: Array<UsageAlerts> = useAppSelector((state) =>
    selectAlertDataByType(state, DEVICE_USAGE)
  );

  useEffect(() => {
    dispatch(getAlertsByType(DEVICE_USAGE));
  }, [dispatch]);

  const handleDismiss = useCallback(() => {
    dispatch(
      updateUserSettings({
        edu_sdp_dlimit_big_show: 'no',
      })
    );
  }, [dispatch]);

  return (
    <div className="DataLimits__wrapper">
      <HoloHelmet title={`${device.name} - Data usage limits`} />
      <Panel header="Current plan">
        <div className="DataLimits__quick-info-wrapper">
          {device.plan && (
            <div className="DataLimits__quick-info">
              <h6 className="DataLimits__quick-info__headline">Plan</h6>
              <div className="DataLimits__quick-info__body">{device.plan}</div>
            </div>
          )}
          <div className="DataLimits__quick-info">
            <h6 className="DataLimits__quick-info__headline">Coverage</h6>
            <div className="DataLimits__quick-info__body">
              {device.zone === 'global' ? 'Global' : `Zone ${device.zone}`}
            </div>
          </div>
          {(device.data ?? 0) > 0 && (
            <div className="DataLimits__quick-info">
              <h6 className="DataLimits__quick-info__headline">Included data</h6>
              <div className="DataLimits__quick-info__body">
                {toByteStringFormatted(device.data, 0)}
              </div>
            </div>
          )}
        </div>
      </Panel>
      {/* Educational panel for orgs with data usage limits AND inflight/data usage alert */}
      {showEducationalBig && (
        <Panel
          className="DataLimits__educational"
          header="How to use data usage limits and alerts"
          headerActions={
            <Button iconStart="Close" onClick={handleDismiss} variant="secondary">
              Dismiss
            </Button>
          }
        >
          <div className="DataLimits__educationWellWrapper">
            <p className="DataLimits__educationWellText">
              Data usage alerts let you know when a SIM’s data usage exceeds a certain amount of
              data. Data usage limits automatically pause your SIM’s data usage when it exceeds a
              set amount. Both reset at the start of the SIM’s billing period.
            </p>
            <div className="DataLimits__educationWellActions">
              <Button
                iconStart="Gauge"
                variant="secondary"
                href="https://support.hologram.io/hc/en-us/articles/360035697313-Set-a-device-s-data-usage-limit"
                target="_blank"
              >
                Learn about data usage limits
              </Button>
              <Button
                iconStart="ActionItem"
                variant="secondary"
                href="https://support.hologram.io/hc/en-us/articles/360042755573"
                target="_blank"
              >
                Learn about usage alerts and Inflight
              </Button>
            </div>
            <div className="DataLimits__educationWellPicture">
              <Picture image={dataLimitsImg} alt="" setDimensions={false} />
            </div>
          </div>
        </Panel>
      )}
      <UsageAlertSection usageAlertData={usageAlertData} device={device} />
      <DataLimitsSection />
    </div>
  );
};

export default DataLimits;
