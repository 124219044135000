import { Button } from '@hologram-dimension/button';
import { CONNECTED, LIVE } from 'common-js/constants/deviceStates';
import useAppDispatch from 'common-js/hooks/useAppDispatch';
import useAppSelector from 'common-js/hooks/useAppSelector';
import useCanActivate from 'common-js/hooks/useCanActivate';
import {
  setIsPreflightFlag,
  setPreloadedActivationDevice,
} from 'common-js/reducers/activation/actions';
import { selectCurrentDevice } from 'common-js/reducers/currentDevice/selectors';
import type { Device } from 'common-js/types/Device';
import useBulkModal from 'devices/ManageDropdown/useBulkModal';
import { useCallback, type FC } from 'react';
import PlanChangeReason from './PlanChangeReason';
import selectCanUserActivatePreflightDevice from './selectCanUserActivatePreflightDevice';
import useOnUpdatePlan from './useOnUpdatePlan';

const PlanButton: FC = () => {
  const dispatch = useAppDispatch();
  const device: Device = useAppSelector(selectCurrentDevice);
  const canUserActivatePreflightDevice = useAppSelector(selectCanUserActivatePreflightDevice);
  const onUpdatePlan = useOnUpdatePlan('Devices Page');
  const deviceIsActive = device.state === LIVE || device.state === CONNECTED;

  const { canActivate, errorMessage } = useCanActivate({
    deviceType: device.state?.toLocaleLowerCase() as any,
  });

  const openModal = useBulkModal('BulkActivatePreflightModal');

  const action = useCallback(async () => {
    if (deviceIsActive) {
      onUpdatePlan();
    } else if (canUserActivatePreflightDevice) {
      dispatch(setIsPreflightFlag());
      dispatch(setPreloadedActivationDevice([device]));
      openModal();
    }
  }, [dispatch, device, canUserActivatePreflightDevice, deviceIsActive, onUpdatePlan, openModal]);

  return (
    <div className="page-device-plan-button-wrapper">
      <Button
        className="pull-right"
        onClick={action}
        disabled={!(deviceIsActive || canActivate || canUserActivatePreflightDevice)}
        title={!deviceIsActive && !canActivate ? errorMessage : ''}
      >
        {canUserActivatePreflightDevice ? 'Activate' : 'Change data plan'}
      </Button>
      {!deviceIsActive && !(canUserActivatePreflightDevice || canActivate) && (
        <div className="planchange-warning">
          <PlanChangeReason />
        </div>
      )}
    </div>
  );
};

export default PlanButton;
