import getUserSettings from './getUserSettings';
import * as orgActions from '../../organization/actions';

// eslint-disable-next-line import/prefer-default-export
export const switchToDefaultOrg = () => (dispatch, state) =>
  Promise.all([
    getUserSettings()(dispatch, state),
    orgActions.getOrganizations()(dispatch, state),
  ]).then(() => {
    const store = state();
    const defaultOrgId =
      store.account.settings && store.account.settings['Default Org']
        ? store.account.settings['Default Org']
        : store.account.orgId;

    if (defaultOrgId) {
      return orgActions.changeContext(defaultOrgId)(dispatch, state);
    }

    return Promise.resolve();
  });
