import { reduce } from 'lodash';
import { createSelector } from 'reselect';
import * as alertTypes from 'common-js/constants/alertTypes';
import { ALERT_TYPE_TO_STATE } from './reducer';

type AlertType = (typeof alertTypes)[keyof typeof alertTypes];

const selectAlerts = createSelector(
  [(state: any) => state?.alerts],
  (alerts): Array<any> => alerts
);

// TODO: Every single one of these that starts with a lambda will generate a new selector
//       on EVERY render. This can cause significant re-render issues. Slowly convert to look like selectAlertDataByType

export const createGetHasFetchedByType = (alertType) => (state) => {
  const alerts = selectAlerts(state);
  const alertState = ALERT_TYPE_TO_STATE[alertType];
  return (alerts[alertState] && alerts[alertState].hasFetched) || false;
};

export const createGetAlertDataByType = (alertType) =>
  createSelector([(state: any) => state.alerts], (alerts) => {
    const alertState = ALERT_TYPE_TO_STATE[alertType];
    return (alerts[alertState] && alerts[alertState].data) || [];
  });

export const selectAlertDataByType = createSelector(
  [selectAlerts, (_: any, alertType: AlertType) => alertType],
  (alerts, alertType) => {
    const alertState = ALERT_TYPE_TO_STATE[alertType];
    return (alerts[alertState] && alerts[alertState].data) || [];
  }
);

export const createGetAlertCountByType = (alertType) => (state) => {
  const alerts = selectAlerts(state);
  const alertState = ALERT_TYPE_TO_STATE[alertType];
  return (alerts[alertState] && alerts[alertState].count) || 0;
};

export const createGetAlertLoadingByType = (alertType) => (state) => {
  const alerts = selectAlerts(state);
  const alertState = ALERT_TYPE_TO_STATE[alertType];
  return alerts[alertState] && 'loading' in alerts[alertState] ? alerts[alertState].loading : true;
};

export const createGetAlertMapDataByType = (alertType) => (state) => {
  const alerts = selectAlerts(state);
  const alertState = ALERT_TYPE_TO_STATE[alertType];
  return (alerts[alertState] && alerts[alertState].mapData) || {};
};

export const createGetAlertFilterDataByType = (alertType) => (state) => {
  const alerts = selectAlerts(state);
  const alertState = ALERT_TYPE_TO_STATE[alertType];
  return (alerts[alertState] && alerts[alertState].filterData) || {};
};

export const createGetActiveAlertIdByType = (alertType) =>
  function (state) {
    const alerts = selectAlerts(state);
    const alertState = ALERT_TYPE_TO_STATE[alertType];
    return (alerts[alertState] && alerts[alertState].activeAlert) || null;
  };

export const createGetActivePinByType = (alertType) =>
  function (state) {
    const alerts = selectAlerts(state);
    const alertState = ALERT_TYPE_TO_STATE[alertType];
    return (alerts[alertState] && alerts[alertState].activePin) || null;
  };

export const createGetActiveAlertByType = (alertType) =>
  createSelector(
    [createGetAlertDataByType(alertType), createGetActiveAlertIdByType(alertType)],
    (alerts, activeAlertId) => (activeAlertId ? alerts.find((a) => a.id === activeAlertId) : {})
  );

export const createGetKeyedAlertsByType = (alertType) =>
  createSelector([createGetAlertDataByType(alertType)], (alerts) =>
    reduce(alerts, (res, val) => ({ ...res, [val.id]: val }), {})
  );

export const getActiveAlertByType = (state, alertType) => {
  const alertId = state.alerts[ALERT_TYPE_TO_STATE[alertType]].activeAlert;
  return state.alerts[ALERT_TYPE_TO_STATE[alertType]].data.find((a) => a.id === alertId);
};

export const createGetAckedAlertIds = (alertType) =>
  createSelector([createGetAlertDataByType(alertType)], (alerts) => {
    const ackedAlertIds: Array<any> = [];
    alerts.forEach((alert: any) => {
      if (alert.acked) {
        ackedAlertIds.push(alert.id);
      }
    });
    return ackedAlertIds;
  });
