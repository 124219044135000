import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@holokit/core';
import _ from 'lodash';
import classNames from 'clsx';

class MessagePanel extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    // messageType can be "success", "warn", or "error"

    const {
      messageType = 'success',
      onClick = _.noop,
      children,
      alignText = 'left',
      hasShadow = false,
      hasDismiss = false,
      fullWidth = false,
      className,
    } = this.props;

    const containerClassNames = classNames(className, {
      message: true,
      [`message-${messageType}`]: true,
      [`align-${alignText}`]: true,
      'has-shadow': hasShadow,
      'full-width': fullWidth,
    });
    let iconColor;
    if (messageType === 'success') {
      iconColor = '#099FC3';
    } else if (messageType === 'warn') {
      iconColor = '#d5902b';
    } else if (messageType === 'error') {
      iconColor = '#A80000';
    } else {
      iconColor = '#8008f7';
    }

    return (
      <div className={containerClassNames}>
        <div className="message-panel">
          <Icon
            classes="message-icon"
            name={messageType === 'success' ? 'Circle--info' : 'Circle--alert'}
            size="minor"
            svgProps={{ style: { fill: iconColor } }}
          />
          <div className="message-text">{children}</div>
          {hasDismiss && (
            <div className="dismiss" onClick={(e) => onClick(e)}>
              <Icon name="Close" size="micro" svgProps={{ style: { fill: iconColor } }} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

MessagePanel.propTypes = {
  messageType: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  hasShadow: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasDismiss: PropTypes.bool,
  alignText: PropTypes.string,
};

export default MessagePanel;
