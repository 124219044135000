import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClassNames from 'clsx';
import { Button, Icon } from '@holokit/core';
import * as currentDeviceActions from 'common-js/reducers/currentDevice/actions';
import { DropdownMenu2, DateRangePicker } from '.';

class DateTagFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changesSinceLastRun: false };
  }

  handleRunReport = () => {
    const { onRunReport, deviceId, selectedTags, setPollingPause } = this.props;
    onRunReport(deviceId, selectedTags);
    this.setState({ changesSinceLastRun: false });
    setPollingPause(false);
  };

  handleTagChange = (tag) => {
    const { toggleTag } = this.props;
    toggleTag(tag, 'usage');
    this.setState({ changesSinceLastRun: true });
  };

  handleSelectAllTags = () => {
    const { selectAllTags } = this.props;
    this.setState({ changesSinceLastRun: true });
    selectAllTags();
  };

  handleDeselectAllTags = () => {
    const { deselectAllTags } = this.props;
    this.setState({ changesSinceLastRun: true });
    deselectAllTags();
  };

  onTimeQuickFilterChange = (value) => {
    const { setTimeQuickFilter, deviceId } = this.props;

    setTimeQuickFilter(value, deviceId);
    this.setState({ changesSinceLastRun: true });
  };

  onDateChange = (dates) => {
    const { setCustomDate, deviceId } = this.props;
    const { from, to } = dates;

    setCustomDate({ startDate: from.valueOf(), endDate: to.valueOf() }, deviceId);
    this.setState({ changesSinceLastRun: true });
  };

  getSelectedTagDropdownTitle() {
    const MAX_CHAR_LENGTH = 50;
    const { selectedTags } = this.props;
    const tagIdToNameMap = this.getTagIdToNameMap();

    let dropdownTitle = '';

    if (selectedTags.length === 0) {
      dropdownTitle = 'No tags selected';
    } else {
      for (let i = 0; i < selectedTags.length; i++) {
        const tagId = selectedTags[i];
        const tagName = tagIdToNameMap[tagId];

        if (dropdownTitle.length + tagName.length < MAX_CHAR_LENGTH) {
          // If first tag, don't add a comma.
          if (i === 0) {
            dropdownTitle = tagName;
          } else {
            dropdownTitle += `, ${tagName}`;
          }
        } else {
          dropdownTitle += ` (${selectedTags.length - i} more)`;
          break;
        }
      }
    }

    return dropdownTitle;
  }

  getTagIdToNameMap() {
    const { tags } = this.props;
    const map = {};

    tags.forEach((tag) => {
      map[tag.id] = tag.name;
    });

    return map;
  }

  render() {
    const {
      className,
      disabled,
      endDate,
      hideDatePicker,
      hideTags,
      runningReport,
      selectedTags,
      startDate,
      tags,
      timeQuickFilter,
    } = this.props;

    const { changesSinceLastRun } = this.state;
    const classes = ClassNames('DateTagFilter', className);

    return (
      <div className={classes}>
        {!hideDatePicker && (
          <div className={ClassNames('date-picker', { disabled })}>
            <DateRangePicker
              endDate={endDate}
              onDateChange={this.onDateChange}
              quickFilter={timeQuickFilter}
              setQuickFilter={this.onTimeQuickFilterChange}
              startDate={startDate}
            />
          </div>
        )}
        {!hideTags && (
          <div className={ClassNames('tag-picker', { disabled })}>
            <DropdownMenu2
              // eslint-disable-next-line react/no-unstable-nested-components
              button={(open, close, opened) => (
                // eslint-disable-next-line react/button-has-type
                <button
                  className={`button grid-row${
                    selectedTags.length > 0 || opened ? ' outline' : ''
                  }`}
                  onMouseDown={() => (!opened ? open() : close())}
                >
                  <Icon
                    classes="primary-icon primary-icon--left"
                    name="Tag"
                    size="minor"
                    svgProps={{ style: { fill: '#8008f7' } }}
                  />
                  {this.getSelectedTagDropdownTitle()}
                  <Icon
                    classes="primary-icon primary-icon--right"
                    name={opened ? 'Chevron--single--north' : 'Chevron--single--south'}
                    size="minor"
                    svgProps={{ style: { fill: '#8008f7' } }}
                  />
                </button>
              )}
            >
              <div className="dropdown">
                <div className="mass-selection-area">
                  <span
                    className="option"
                    onClick={this.handleSelectAllTags}
                    onKeyUp={this.handleSelectAllTags}
                    role="button"
                    tabIndex={0}
                  >
                    Select All
                  </span>
                  <span className="spacer" />
                  <span
                    className="option"
                    onClick={this.handleDeselectAllTags}
                    onKeyUp={this.handleDeselectAllTags}
                    role="button"
                    tabIndex={0}
                  >
                    None
                  </span>
                </div>
                {tags.map((tag, idx) => {
                  const isActive = selectedTags.includes(tag.id);
                  return (
                    <div
                      key={tag.id ?? idx}
                      className={`row${isActive ? ' active' : ''}`}
                      onClick={() => this.handleTagChange(tag)}
                      onKeyUp={() => this.handleTagChange(tag)}
                      role="button"
                      tabIndex={0}
                    >
                      <Icon
                        classes="row-icon"
                        name="Tag"
                        size="minor"
                        svgProps={{
                          style: { fill: isActive ? '#2F6AFF' : '#b2b2b2' },
                        }}
                      />
                      {tag.name}
                    </div>
                  );
                })}
              </div>
            </DropdownMenu2>
          </div>
        )}
        <Button
          disabled={!changesSinceLastRun && !runningReport}
          loading={runningReport}
          onClick={this.handleRunReport}
          type="primary"
        >
          Run report
        </Button>
      </div>
    );
  }
}

DateTagFilter.defaultProps = {
  deselectAllTags: _.noop,
  disabled: false,
  hideDatePicker: false,
  hideTags: false,
  runningReport: false,
  selectAllTags: _.noop,
  selectedTags: [],
  setTimeQuickFilter: _.noop,
  tags: [],
  timeQuickFilter: 'TIMEQUICKFILTER_CUSTOM',
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setPollingPause: currentDeviceActions.setPollingPause,
    },
    dispatch
  )
)(DateTagFilter);
